import React from 'react';
import styles from './styles/FilterJob.module.scss';

function FilterBy({ filterStack, setStackFilter }) {
  return (
    <div className={styles.filterByContainer}>
      <div >
        <h3 className={styles.filterByText}> Filter By: </h3>
      </div>
      <div className={styles.filterByInputWrapper}>
        <div className={styles.checkBox}>
          <input
            type="checkbox"
            checked={filterStack.isAnd}
            onChange={() =>
              setStackFilter({
                ...filterStack,
                isAnd: !filterStack.isAnd,
              })
            }
          />
          <label>AND</label>
        </div>
        <div className={styles.checkBox}>
          <input
            type="checkbox"
            checked={!filterStack.isAnd}
            onChange={() =>
              setStackFilter({
                ...filterStack,
                isAnd: !filterStack.isAnd,
              })
            }
          />
          <label>OR</label>
        </div>
      </div>
    </div>
  );
}

export default FilterBy;
