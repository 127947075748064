import React, { Fragment, useState, useEffect } from 'react';
import Select from 'react-select';

import { Row, Container, Box, Icon } from './styles/TimeSheet.styled';
import Text from '../../common/Styled components/Text';
import Input from '../../common/Styled components/Input';
import { getOrganizations } from '../../api/recruiter.api';
import DateSelector from '../../common/DateSelector.js/DateSelector';

const hours = [
  { label: '1Hr', value: '1' },
  { label: '2Hr', value: '2' },
  { label: '3Hr', value: '3' },
  { label: '4Hr', value: '4' },
];

const colourStyles = {
  control: (styles) => ({
    ...styles,
    height: 'auto',
    backgroundColor: '#F6F7FC',
    minHeight: '40px',
  }),
};

function TimeEntryFields({
  timeSheetData,
  setTimeSheetData,
  data,
  index,
  isCreating,
  organizationId,
}) {
  const [organizationList, setOrganizationList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [candidateList, setCandidateList] = [];

  useEffect(() => {
    console.log('> rendering time entry fields ...')
  }, []);

  const multiFields = [
    {
      label: 'Submitted Candidate',
      key: { field: 'submitted', value: 'submitted' },
    },
    {
      label: 'Confirmed Interview',
      key: { field: 'confirmed', value: 'confirmed' },
    },
    {
      label: 'Interviewed',
      key: { field: 'interviewed', value: 'Interviewed' },
    },
    { label: 'Hired', key: { field: 'hired', value: 'hired' } },
  ];

  const handleCandidates = (event, field) => {
    // let cloneData = data;
    // cloneData = { ...cloneData, [field]: [...event] };
    // timeSheetData[index] = cloneData;
    // setTimeSheetData([...timeSheetData]);
  };

  const onSelect = (event, field) => {
    // let cloneData = data;
    // cloneData = { ...cloneData, [field]: event };
    // timeSheetData[index] = cloneData;
    // setTimeSheetData([...timeSheetData]);
  };

  const handleHour = (event, field) => {
    // let cloneData = data;
    // cloneData = { ...cloneData, [field]: event.target.value };
    // timeSheetData[index] = cloneData;
    // setTimeSheetData([...timeSheetData]);
  };

  const handleDelete = (deleteIndex) => {
    // const filterData = timeSheetData.filter(
    //   (_, index) => index !== deleteIndex
    // );
    // setTimeSheetData(filterData);
  };

  return (
    <>
      <Row aItems="center" padding="10px" jContent="space-between" gutterButtom>
        <Box width="20%" direction="column" gutterButtom>
          <Text>Date</Text>
          <DateSelector
            handleOnSubmit={(date) => onSelect(date, 'date')}
            dateselected={data.date}
          />
        </Box>

        <Box width={true ? '20%' : '30%'} direction="column" gutterButtom>
          <Text>Organization</Text>
          <Select
            options={[{label: 'sample', value: 'xxxx'}]}
            onChange={(event) => onSelect(event, 'organization')}
            value={data.organization}
            placeholder="Select Organization"
            isDisabled={isCreating}
          />
        </Box>
        <Box width={true ? '20%' : '30%'} direction="column" gutterButtom>
          <Text>Total Hours</Text>
          <Input
            onChange={(event) => handleHour(event, 'hour')}
            value={10}
          />
        </Box>
        <Box width={true ? '20%' : '30%'} direction="column" gutterButtom>
          <Text>Jobs</Text>
          <Select
            
            isSearisMultichable={true}
            minMenuHeight={6}
            onChange={(event) => handleCandidates(event, 'jobs')}
            options={[{label: '1 hr', value: '1'}, {label: '2 hr', value: '2'}]}
            value={{label: 'sample jobs', value: 'sample jobs'}}
          />
        </Box>
      </Row>
      <Container
        width="100%"
        padding="10px"
        direction="row"
        aItems="center"
        jContent="space-between"
        gutterButtom
      >
        {multiFields.map(({ label, key }, index) => {
          return (
            <Fragment key={index}>
              <Box width="45%" direction="column" gutterButtom>
                <Text>{label}</Text>
                <Select
                  isMulti
                  isSearchable={true}
                  minMenuHeight={6}
                  onChange={(event) => handleCandidates(event, key.field)}
                  options={candidateList}
                  styles={colourStyles}
                  placeholder={`Select ${label} candiidates`}
                  value={key.value}
                />
              </Box>
            </Fragment>
          );
        })}
      </Container>

      <Row direction="row" jContent="flex-end">
        <Icon onClick={() => handleDelete(index)}>
          <i data-feather="trash-2" />
        </Icon>
      </Row>
      <div
        style={{
          width: '100%',
          height: '2px',
          background: '#d6d9e8',
          marginBottom: 20,
          marginTop: 10,
        }}
      />
    </>
  );
}

export default TimeEntryFields;
