import React, { useState, useEffect } from 'react'
import Card from '../shared/Card'
import Table from '../shared/Table'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../shared/Button'
import P from '../shared/P'
import { Row, Col, Spinner, Tooltip, SafeAnchor, OverlayTrigger, Form } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal"
import Select from 'react-select'
import Badge from "react-bootstrap/Badge";
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import moment from 'moment'
import DataTable from 'react-data-table-component-footer'
import '../styles/DataTableCustom.scss'
import feather from 'feather-icons'
import { capitalize } from '../../../../utils'
import { CSVLink } from "react-csv"
import { DisplayPagination } from '../styles/AdminDashboard.styled'

const index = ({ recruiter_org, campaign_manager, recruiter_list, account_list }) => {

  var lead_status_start_date = sessionStorage.getItem('lead_status_start_date')
  var lead_status_end_date = sessionStorage.getItem('lead_status_end_date')
  var select_recruiter = sessionStorage.getItem('select_Recruiter')
  let recruiter_data = select_recruiter && JSON.parse(select_recruiter)
  var select_account_manager = sessionStorage.getItem('select_account_manager')
  let select_account_manager_data = select_account_manager && JSON.parse(select_account_manager)
  var select_campaign_management = sessionStorage.getItem('select_campaign_management')
  let select_campaign_data = select_campaign_management && JSON.parse(select_campaign_management)
  var select_organization = sessionStorage.getItem('select_organization')
  let organization_data = select_organization && JSON.parse(select_organization)
  var select_status = sessionStorage.getItem('select_status')
  let status_data = select_status && JSON.parse(select_status)

  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState()
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(25)
  const [leadStatus, setLeadStatus] = useState([])
  const startDate = lead_status_start_date ? moment(lead_status_start_date).toDate() : moment().subtract(2,'w').toDate()
  const endDate = lead_status_end_date ? moment(lead_status_end_date).toDate() : moment().toDate()
  const [dateRange, setDateRange] = useState({ startDate: startDate, endDate: endDate })
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [showMessage, setShowMessage] = useState(false)
  const [showApproved, setShowApproved] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [Status, setStatus] = useState([{value: '', label: 'All'},{value: 'pending', label: 'Pending'},{value: 'approve', label: 'Approve'}])
  const [selectedStatus, setSelectedStatus] = useState((status_data) ? status_data.value : '')
  const [defaultStatus,setDefaultStatus] = useState(status_data ? status_data : [{value: 'pending', label: 'Pending'}])
  const [selectedOrganization, setSelectedOrganization] = useState((organization_data) ? organization_data.value : '')
  const [defaultSelectedOrganization,setDefaultSelectedOrganization] = useState(organization_data ? organization_data : {})
  const [recruiter,setRecruiter] = useState([{value: '', label: 'All'},...recruiter_list])
  const [organizationLists,setOrganizationLists] = useState([{value: '', label: 'All'},...recruiter_org])
  const [selectedRecruiter, setSelectedRecruiter] = useState( (recruiter_data) ? recruiter_data.value : '')
  const [defaultSelectedRecruiter,setDefaultSelectedRecruiter] = useState(recruiter_data ? recruiter_data : {})
  const [accountManager, setAccountManager] = useState([{value: '', label: 'All'},...account_list])
  const [selectedAccountManager, setSelectedAccountManager] = useState((select_account_manager_data) ? select_account_manager_data.value : '')
  const [defaultAccountManager,setDefaultAccountManager] = useState(select_account_manager_data ? select_account_manager_data : {})
  const [campaignManager, setCampaignManager] = useState([{value: '', label: 'All'},...campaign_manager])
  const [selectedCampaignManager, setSelectedCampaignManager] = useState(select_campaign_data ? select_campaign_data.value : '')
  const [defaultCampaignManager,setDefaultCampaignManager] = useState( select_campaign_data? select_campaign_data : [{value: '', label: 'All'}])
  const [recepientOptions, setRecepientOptions] = useState()
  const [selectRecepient, setSelectRecepient] = useState('')
  const [selectMethod, setSelectMethod] = useState([])
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [validationError, setValidationError] = useState({})
  const [leadFileId, setLeadFileId] = useState()
  const [exportDatas, setExportDatas] = useState([])
  const [filename, setFilename] = useState('')
  const csvExportRef = React.useRef()
  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto', backgroundColor: '#fff',width: '12em',marginRight: '11px !important'})
  }
  const headers = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' }
  ];

  useEffect(() => {
    feather.replace()
  })

  const fetchData = () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    sessionStorage.setItem('lead_status_start_date', moment(dateRange.startDate).format('YYYY-MM-DD'))
    sessionStorage.setItem('lead_status_end_date', moment(dateRange.endDate).format('YYYY-MM-DD'))
    const url = `/admin/lead_status.json`
    makeRequest(url, 'get', { params: { 
      page: activePage, 
      organization: selectedOrganization,
      recruiter: selectedRecruiter,
      account_manager: selectedAccountManager,
      campaign_manager: selectedCampaignManager,
      status: selectedStatus,
      start_date: start_date,
      end_date: end_date,
      sortField: sortField, 
      sortDirection: sortDirection } }, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        let sr_no = (activePage - 1) * res.data.per_page
        setLeadStatus(res.data.lead_status_list.map((data, key) => (sr_no = sr_no + 1, {
          lead_file_id: data.leadfile_id,
          id: sr_no,
          recruiter: data.recruiter,
          job_name: data.job_name,
          organization_name: data.organization_name,
          campaign_manager: data.campaign_manager,
          account_manager: data.account_manager,
          lead_status: capitalize(data.lead_status),
          lead_file: <a onClick={()=>{
            setFilename(`${data?.organization_name.replaceAll(' ','_')}_${data?.job_name.replaceAll(' ','_')}.csv`)
            exportDataMethod(data.leadfile_id)}
          } style={{cursor:"pointer",color:"#667eff"}}>Download</a>,
          lead_date: data.lead_date,
          actions: (<div className='w-100 d-inline-flex justify-content-around'>
            <OverlayTrigger overlay={<Tooltip>Show</Tooltip>}><SafeAnchor href={`/admin/lead_status/${data.leadfile_id}`}><i data-feather="eye" /></SafeAnchor></OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Message</Tooltip>}><a onClick={() => {
              setSelectMethod([])
              let option = []
              data.account_manager_id && option.push({value: `${data.account_manager_id}`, label: `Account Manager (${data.account_manager})`})
              data.campaign_manager_id && option.push({value: `${data.campaign_manager_id}`, label: `Campaign Manager (${data.campaign_manager})`})
              data.recruiter_id && option.push({value: `${data.recruiter_id}`, label: `Recruiter (${data.recruiter})`})
              setRecepientOptions(option)
              setShowMessage(true)
              }} href={`javascript::void()`}><i data-feather="mail" /></a></OverlayTrigger>
            <div style={{width: '15%'}}><OverlayTrigger overlay={<Tooltip>Approve</Tooltip>}><a className={`approve_${data.leadfile_id}`} style={{display: data.lead_status == "approve" && 'none'}} onClick={() => {
              setLeadFileId(data.leadfile_id)
              setShowApproved(true)
            }} href={`javascript::void()`}><i data-feather="check" /></a></OverlayTrigger></div>
          </div>)
        })))
        setTotalCount(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage > 1 ? ((activePage-1) * res.data.per_page + 1) : activePage)
        setLoading(false)
      },
    })
  }

  const exportDataMethod = async (id) => {
    const url = `/admin/lead_status/download_lead_file/${id}.json`
    const response = await makeRequest(
        `${url}`,'get',{}
    )
    setExportDatas(response?.data?.job_leads.map((data,key)=> ({
        id: key,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email1
    })))
    csvExportRef?.current?.link.click();
 }

  const handleSort = async (column, sortDirection) => {
    if (column.sortField != undefined && sortDirection != undefined) {
      setSortField(column.sortField)
      setSortDirection(sortDirection)
    }
  }

  const handleOrganization = (str) => {
    sessionStorage.setItem('select_organization',JSON.stringify(str))
    setSelectedOrganization(str.value)
  }

  const handleRecruiter = (str) => {
    sessionStorage.setItem('select_Recruiter',JSON.stringify(str))
    setSelectedRecruiter(str.value)
  }

  const handleAccountManager = (str) => {
    sessionStorage.setItem('select_account_manager',JSON.stringify(str))
    setSelectedAccountManager(str.value)
  }

  const handleCampaignManager = (str) => {
    sessionStorage.setItem('select_campaign_management',JSON.stringify(str))
    setSelectedCampaignManager(str.value)
  }

  const handleStatus = (str) => {
    sessionStorage.setItem('select_status',JSON.stringify(str))
    setSelectedStatus(str.value)
  }

  const handleRecepient = (str) => {
    setSelectRecepient(str.value)
  }

  const handleMethod = (e) => {
    if(e.target.checked){
      setSelectMethod([...selectMethod, e.target.value])
    }
    else{
      setSelectMethod([...selectMethod.filter((el)=>el != e.target.value).map(el => el)])
    }
  }
 
  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activePage,selectedOrganization,selectedRecruiter,selectedAccountManager,selectedCampaignManager,dateRange,selectedStatus,sortDirection,sortField])

  const handleDate = (date) => {
    setDateRange(date)
  }

  const onHide = () => {
    setShowMessage(false)
    setShowApproved(false)
    setValidationError({})
  }

  const validateForm = (e, method) => {
    let errors = {}
		let formIsValid = true
    if(method == "post"){
      if(!e?.target?.to?.value?.trim() && method == 'post'){
        formIsValid = false;
        errors["to"] = "Recepient is required field."
      }
      if(selectMethod.length == 0 && method == 'post'){
        formIsValid = false;
        errors["method"] = "Method is required field."
      }
      if(!e?.target?.subject?.value?.trim() && method == 'post'){
        formIsValid = false;
        errors["subject"] = "Subject is required field."
      }
      if(!e?.target?.message?.value?.trim() && method == 'post'){
        formIsValid = false;
        errors["message"] = "Message is required field."
      }
    }
    else if(method == "put"){
      if(!e?.target?.message?.value?.trim() && method == 'put'){
        formIsValid = false;
        errors["message"] = "Message is required field."
      }
    }
    setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    if(e.target._method.value == "post")
    {
      if(validateForm(e,e.target._method.value)){
        let URL = `/admin/lead_status.json`
        const payload = new FormData()
        payload.append('lead_status[to]',e.target.to.value)
        payload.append('lead_status[method]',selectMethod)
        payload.append('lead_status[subject]',e.target.subject.value)
        payload.append('lead_status[message]',e.target.message.value)
        const CSRF_Token = document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content')
        const headers = {
          headers: {
              'content-type': 'application/json',
              'X-CSRF-Token': CSRF_Token,
          },
        }
        makeRequest(URL,e.target._method.value, payload, headers, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createSuccessMessage: (response) => response.data.message,
        }).then((res) => {
          if(res.data.type == "success"){
              onHide()
          }
        })
      }
    }
    else if(e.target._method.value == "put"){
      if(validateForm(e,e.target._method.value)){
        let URL = `/admin/lead_status/${leadFileId}.json`
        const payload = new FormData()
        payload.append('lead_status[message]',e.target.message.value)
        const CSRF_Token = document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content')
        const headers = {
          headers: {
              'content-type': 'application/json',
              'X-CSRF-Token': CSRF_Token,
          },
        }
        makeRequest(URL,e.target._method.value, payload, headers, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createSuccessMessage: (response) => response.data.message,
        }).then((res) => {
          if(res.data.type == "success"){
            fetchData()
            onHide()
          }
        })
      }
    }
    setBtnDisabled(false)
  }

  const columns = [
    {
      name: 'No.',
      selector: row => row.id,
      maxWidth: '4%',
      minWidth: '4%'
    },
    {
      name: 'Recruiter Name',
      selector: row => row.recruiter,
      wrap: true,
      sortable: true,
      sortField: 'recruiter'
    },
    {
      name: 'Organization Name',
      selector: row => row.organization_name,
      wrap: true,
      sortable: true,
      sortField: 'organization_name'
    },
    {
      name: 'Job Name',
      selector: row => row.job_name,
      wrap: true,
      sortable: true,
      sortField: 'job_name'
    },
    {
      name: 'Campaign Manager',
      selector: row => row.campaign_manager,
      wrap: true,
      sortable: true,
      sortField: 'campaign_manager'
    },
    {
      name: 'Account Manager',
      selector: row => row.account_manager,
      wrap: true,
      sortable: true,
      sortField: 'account_manager'
    },
    {
      name: 'Lead File',
      selector: row => row.lead_file,
      wrap: true,
      maxWidth: '8%',
      minWidth: '8%'
    },
    {
      name: 'Lead Status',
      selector: row => row.lead_status,
      wrap: true,
      sortable: true,
      sortField: 'lead_status',
      maxWidth: '8%',
      minWidth: '8%'
    },
    {
      name: 'Lead Date',
      selector: row => moment(row.lead_date).format('MMM DD, YYYY'),
      wrap: true,
      sortable: true,
      sortField: 'lead_date',
      maxWidth: '8%',
      minWidth: '8%'
    },
    {
      name: 'Actions',
      cell: row => row.actions,
      wrap: true,
      sortable: true,
      sortField: 'lead_status'
    }
  ]

  return (
    <>
      <Card>
        <div className="d-flex justify-content-between align-items-center w-100 mb-1">
          <P size="28px">Sourced Lead Status</P>
        </div>
        <Row className="w-100" style={{ marginBottom: '10px' }}>
          <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
            <div>
              <label>Select Organization</label>
              <Select
                options={organizationLists}
                name='organization'
                id='organization'
                placeholder={'Select Organization'}
                styles={colourStyles}
                onChange={handleOrganization}
                defaultValue={defaultSelectedOrganization}
              />
            </div>
            <div>
              <label>Select Recruiter</label>
              <Select
                options={recruiter}
                name='recruiter'
                id='recruiter'
                placeholder={'Select Recruiter'}
                styles={colourStyles}
                defaultValue={defaultSelectedRecruiter}
                onChange={handleRecruiter}
              />
            </div>
            <div>
              <label>Select Account Manager</label>
              <Select
                options={accountManager}
                name='account_manager'
                id='account_manager'
                placeholder={'Select Account Manager'}
                styles={colourStyles}
                onChange={handleAccountManager}
                defaultValue={defaultAccountManager}
              />
            </div>
            <div>
              <label>Select Campaign Manager</label>
              <Select
                options={campaignManager}
                defaultValue={defaultCampaignManager}
                name='campaign_manager'
                id='campaign_manager'
                placeholder={'Select Campaign Manager'}
                styles={colourStyles}
                onChange={handleCampaignManager}
              />
            </div>
            <div>
              <label>Select Status</label>
              <Select
                name='status'
                id='status'
                options={Status}
                defaultValue={defaultStatus}
                onChange={handleStatus}
                placeholder={'Select Status'}
                styles={colourStyles}
              />
            </div>
            <div style={{display:'grid'}}>
              <label>Select Date Range</label>
              <DateRangePicker
                handleOnSubmit={handleDate}
                defaultDate={dateRange}
              />
            </div>
          </Col>
        </Row>
        {/* </Row> */}
        <Row className="d-flex justify-content-start align-items-center w-100 mb-1">
        {totalCount > 0 &&
            <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalCount} jobs
            </DisplayPagination>
        }
        </Row>
        <DataTable
          persistTableHead={true}
          noDataComponent={<p className="text-center p-3">No record found</p>}
          columns={columns}
          data={leadStatus}
          progressPending={loading}
          progressComponent={<P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </P>}
          onSort={handleSort}
          sortServer
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          paginationPerPage={perPage}
          paginationComponentOptions={{
            noRowsPerPage: true
          }}
          responsive={true}
          onChangePage={page => setActivePage(page)}
        />
      </Card>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={headers}
        filename={filename}
        ref={csvExportRef}
      >
      </CSVLink>
      <Modal
        onHide={onHide}
        show={showMessage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='modal-entry-form'
      >
        <Modal.Header closeButton className='mt-2 mb-2'>
          <Modal.Title>
            Send message regarding these leads
          </Modal.Title>
        </Modal.Header>
        <Form method='post' onSubmit={(e) => { handleSubmit(e) }} style={{ width: "100%" }}>
        <input type="hidden" name="_method" value="post" />
        <Modal.Body>
            <Row className="w-100" style={{minHeight:'120px'}}>
              <Col xs={12} sm={12} lg={6}>
                <label>To<span className='text-danger'>*</span></label>
                <Select
                    name='to'
                    id='to'
                    options={recepientOptions}
                    onChange={handleRecepient}
                    placeholder={'Select Recepient'}
                    styles={colourStyles}
                    menuPosition="fixed"
                />
                <label className='text-danger'>
                  {validationError?.to && validationError.to} 
                </label>
              </Col>
              <Col xs={12} sm={12} lg={6}>
                <span>Method<span className='text-danger'>*</span></span>
                <div className='d-flex align-items-center' style={{minHeight: '38px'}}>
                  <Form.Check
                    label='Email'
                    id='email'
                    className='pr-2'
                    value={'email'}
                    onChange={(e)=>handleMethod(e)}
                  />
                  <Form.Check
                    label='SMS'
                    id='sms'
                    className='pr-2'
                    value={'sms'}
                    onChange={(e)=>handleMethod(e)}
                  />
                  {/* <Form.Check
                    hidden={true}
                    label='Slack'
                    id='slack'
                    className='pr-2'
                    value={'slack'}
                    onChange={(e)=>handleMethod(e)}
                  /> */}
                </div>
                <label className='text-danger'>
                  {validationError?.method && validationError.method} 
                </label>
              </Col>
              <Col xs={12} sm={12} lg={12}>
                <span>Subject<span className='text-danger'>*</span></span>
                <Form.Control
                  id='subject'
                  name='subject'
                  placeholder='Enter Subject'
                  onChange={(e)=> setSubject(e.target.value)}
                />
                <label className='text-danger'>
                  {validationError?.subject && validationError.subject} 
                </label>
              </Col>
              <Col xs={12} sm={12} lg={12} className="mt-1">
                <span>Message<span className='text-danger'>*</span></span>
                <Form.Control
                  name='message'
                  as="textarea"
                  placeholder='Enter Message'
                  id='message'
                  onChange={(e)=> setMessage(e.target.value)}
                />
                <label className='text-danger'>
                  {validationError?.message && validationError.message} 
                </label>
              </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
        <Row className="w-100">
          <Col xs={12} sm={12} lg={12} className="d-flex justify-content-end">
            <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Send</Button>
              <Button type='button' onClick={onHide} className='btn btn-primary btn-radius ml-1'>Cancel</Button>
          </Col>
        </Row>
        </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        onHide={onHide}
        show={showApproved}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='modal-entry-form'
      >
        <Modal.Header closeButton className='mt-2 mb-2'>
          <Modal.Title>
            Mark Leads - Recruiter Approved
          </Modal.Title>
        </Modal.Header>
        <Form method='put' onSubmit={(e) => { handleSubmit(e) }} style={{ width: "100%" }}>
        <input type="hidden" name="_method" value="put" />
        <Modal.Body>
          <p className='text-center'>You are about to approve these leads for outreach campaign. Once approved,<br /> system will notify campaign manager to start the process.</p>
            <Row className="w-100" style={{minHeight:'120px'}}>
              <Col xs={12} sm={12} lg={12} className="mt-1">
                <span>Message* </span>
                <Form.Control
                  name='message'
                  as="textarea"
                  placeholder='Enter Message'
                  id='message'
                  onChange={(e)=> setMessage(e.target.value)}
                />
                <label className='text-danger'>
                  {validationError?.message && validationError.message} 
                </label>
              </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
        <Row className="w-100">
          <Col xs={12} sm={12} lg={12} className="d-flex justify-content-end">
            <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Confirm</Button>
              <Button type='button' onClick={onHide} className='btn btn-primary btn-radius ml-1'>Cancel</Button>
          </Col>
        </Row>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default index