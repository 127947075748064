import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/InMailsAssignments.scss'
import Button from '../shared/Button'

const InMailsAssignmentsAdd = ({organizations,recruiters,inMailEntry,onHide,method,fetchData}) => {
	const [validationError, setValidationError] = useState({})
	const [entryData, setEntryData] = useState({
		organization_id: inMailEntry?.organization_id ? inMailEntry?.organization_id : '',
		user_id: inMailEntry?.user_id ? inMailEntry?.user_id : '',
		daily_target: inMailEntry?.daily_target ? inMailEntry?.daily_target : '',
		monthly_availability: inMailEntry?.monthly_availability ? inMailEntry?.monthly_availability : '',
		response_request: inMailEntry?.response_request ? inMailEntry?.response_request : false,
	})
	const [btnDisabled, setBtnDisabled] = useState(false)
	const handleChange = (e) => {
		setValidationError({})
	}
	const [modal,setModal] = useState('')
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/admin/in_mails_assignments'
			if(method === 'put'){
				URL = `/admin/in_mails_assignments/${inMailEntry.id}`
			}
			const payload = new FormData()
			payload.append('in_mails[organization_id]', e.target.organization_id.value)
			payload.append('in_mails[user_id]', e.target.user_id.value)
			payload.append('in_mails[daily_target]', e.target.daily_target.value)
			payload.append('in_mails[monthly_availability]', e.target.monthly_availability.value)
			payload.append('in_mails[response_request]', e.target.response_request.checked)
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
						onHide()
						fetchData()
				}
			}) 
		}
		setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		if(!e.target.user_id.value){
				formIsValid = false;
				errors["user_id"] = "Recruiter is required field."
		}
		if(!e.target.organization_id.value){
			formIsValid = false;
			errors["organization_id"] = "Organization is required field."
		}
		if(!e.target.daily_target.value){
			formIsValid = false;
			errors["daily_target"] = "Daily target is required field."
		}else{
			if (!(new RegExp("^[0-9\b]+$").test(e.target.daily_target.value))){
				formIsValid = false;
				errors["daily_target"] = "Invalid Daily target value"
			}
		}
		if(!e.target.monthly_availability.value){
			formIsValid = false;
			errors["monthly_availability"] = "Monthly availability is required field."
		}
		else{
			if (!(new RegExp("^[0-9\b]+$").test(e.target.monthly_availability.value))){
				formIsValid = false;
				errors["monthly_availability"] = "Invalid Monthly availability value"
			}
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<Container> 
				<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
						<Col xs={12} sm={12} lg={6}>
							<span>Select Recruiter* : </span>
							<Select
								options={recruiters}
								name='user_id'
								id='user_id'
								placeholder={'Select Recruiter'}
								onChange={handleChange}
								defaultValue={recruiters.filter((row) => row.value == entryData.user_id)}
								menuPortalTarget={modal}
							/>
							<label className='error'>
								{validationError && validationError.user_id} 
							</label>
						</Col>
						<Col xs={12} sm={12} lg={6}>
							<span>Select Organization* : </span>
							<Select
								options={organizations}
								name='organization_id'
								id='organization_id'
								placeholder={'Select Organization'}
								onChange={handleChange}
								defaultValue={organizations.filter((row) => row.value == entryData.organization_id)}
								menuPortalTarget={modal}
							/>
							<label className='error'>
								{validationError && validationError.organization_id} 
							</label>
						</Col>
						<Col xs={12} sm={12} lg={6}>
							<span>Daily Target* : </span>
							<input type='number' defaultValue={entryData.daily_target} onChange={handleChange} className='form-control w-100' name='daily_target' id="daily_target" onInput={(e) => {
									if (e.target.value.length > 8)
									e.target.value = e.target.value.slice(0,8);
									}} />
							<label className='error'>
								{validationError && validationError.daily_target} 
							</label>
						</Col>
						<Col xs={12} sm={12} lg={6}>
							<span>Monthly Availability* : </span>
							<input type='number'  defaultValue={entryData.monthly_availability} onChange={handleChange} className='form-control w-100' name='monthly_availability' id="monthly_availability" onInput={(e) => {
									if (e.target.value.length > 8)
									e.target.value = e.target.value.slice(0,8);
									}} />
							<label className='error'>
								{validationError && validationError.monthly_availability} 
							</label>
						</Col>
						<Col xs={12} sm={12} lg={6}>
						<input type='checkbox' defaultChecked={entryData.response_request} onChange={handleChange} className='' name='response_request' id="response_request" />  <span>Response Request</span>
							<label className='error'>
								{validationError && validationError.response_request} 
							</label>
						</Col>
					</Row>
					<Row className="w-100 modal-entry-form-row mt-4">
						<Col xs={12} sm={12} lg={12}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius mr-2'>Save</Button>
						</Col>
					</Row>
				</form>
				</Container>
		</>
  )
}

export default InMailsAssignmentsAdd