import React, { useState } from 'react'
import { Row,Col,Spinner } from 'react-bootstrap'
import moment from 'moment'
import DataTable from "react-data-table-component-footer"
import   '../styles/DataTableCustom.scss'
import Card from '../shared/Card'
import P from '../shared/P'


const index = ({aws_error_logs}) => {
  const [totalCount, setTotalCount] = useState(aws_error_logs.length)
  const [awsErrorLogs, setAwsErrorLogs] = useState(aws_error_logs.map((data,index)=>({
    id: (index+1),
    email_subject: data.email_subject,
    error_log: data.error_log,
    error_from: data.error_from,
    created_at: data.created_at,
  })))
  const columns = [
    {
      name: 'No.',
      selector: row => row.id,
      maxWidth: '4%',
      minWidth: '4%'
    },
    {
      name: 'Subject',
      selector: row => row.email_subject,
      maxWidth: '30%',
      minWidth: '30%'
    },
    {
      name: 'Error',
      cell: row => row.error_log,
      maxWidth: '45%',
      minWidth: '45%'
    },
    {
      name: 'From',
      selector: row => row.error_from,
      maxWidth: '8%',
      minWidth: '8%'
    },
    {
      name: 'Date',
      selector: row => moment(row.created_at).format('MMM DD, YYYY HH:mm:ss'),
      maxWidth: '13%',
      minWidth: '13%'
    },
  ]

  return (
    <Card>
      <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{ marginBottom: '42px' }}
      >
          <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
              AWS Email Error Logs
          </P>
      </div>
      <DataTable
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={awsErrorLogs}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        highlightOnHover
        pagination
        paginationTotalRows={totalCount}
        paginationPerPage={25}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
      />
    </Card>
  )
}

export default index