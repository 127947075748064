import React from 'react'
import styled from 'styled-components'

const varientsTypes = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
}

const alignTypes = {
  center:'center',
  inherit: 'inherit',
  justify: 'justify',
  left: 'left',
  right: 'right',
}

const colorTypes = {
  primary: '#1D2447',
  secondary: '#768BFF',
}

const fontWeightTypes = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 800,
}
const Component = styled.div`
  margin: 0;
  padding: 0;
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  margin-bottom: ${({ gutterBottom }) => gutterBottom && '10px'};
  padding: ${({ givePadding }) => givePadding && '10px'};
  font-size: ${({ size }) => size};
  font-family: Avenir, "Lato", sans-serif;
`

function Text({ varient, color, size, align, children, weight, lineHeight, givePadding, gutterBottom, style, className}) {
  const tag = varient ? varientsTypes[varient] : 'span'
  const textAlign = align ? alignTypes[textAlign] : 'inherit'
  const textColor = color ? colorTypes[color] ? colorTypes[color] : color : colorTypes['primary']
  const fontWeight = weight ? fontWeightTypes[weight] : '400'

  return (
    <Component
      as={tag}
      color={color}
      textAlign={textAlign}
      color={textColor}
      size={size}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      gutterBottom={gutterBottom}
      givePadding={givePadding}
      style={style}
      className={className}
    >
      {children}
    </Component>
  )
}

export default Text
