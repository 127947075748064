import React, { useState, useRef, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Resizer from 'react-image-file-resizer'
import axios from 'axios'
import Select from 'react-select'
import stateCities from 'state-cities'
import { Spinner,Row,Col } from 'react-bootstrap'

// import TextInput from '../../common/TextInput/TextInput'
import TextInput from '../../common/TextInput/TextInputV2'
import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import FileButton from '../../common/FileButton/FileButton'
import { capitalizeFirstLetter } from '../../../utils'
import styles from '../SignupPage/styles/Signup.module.scss'
import {Button,Logo,Logo2,A} from './styles/CreateProfilePage.styled'
import zIndex from '@material-ui/core/styles/zIndex'
import botImage from '../../../../assets/images/bot_img.jpg'
import maryImage from '../../../../assets/images/mary.png';
import stevenImage from '../../../../assets/images/steven.png';

const CONTACT_DETAILS = 'CONTACT_DETAILS'

const CreateOrganizationPage = ({
    formData,
    setFormData,
    submitData,
    loading,
    result,
    setResult,
    selectedRole,
    hidePrevious,
    showInfoPanel
}) => {
    const [roleDescription, setRoleDescription] = useState('')
    const inputRef = useRef()
    const [logo, setLogo] = useState(formData.organization.logo)
    const [logoError, setLogoError] = useState(null)
    const [stateError, setStateError] = useState(null)
    const [cityError, setCityError] = useState(null)
    const [logoUrl, setLogoUrl] = useState(
        logo ? URL.createObjectURL(logo) : null
    )
    const [compnanySizes, setCompnanySizes] = useState([])
    const [industries, setIndustries] = useState([])
    const [states, setStates] = useState([])
    const [selectState, setSelectState] = useState(null)
    const [citys, setCitys] = useState([])
    const [selectCity, setSelectCity] = useState(null)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const [agreement, setAgreement] = useState(null)
    const [agreementError, setAgreementError] = useState(null)

    const [step, setStep] = useState(1);

    const [selectedInterviewer, setSelectedInterviewer] = useState(formData.organization.ai_interviewer);

    // Handle radio button change
    const handleInterviewerChange = (e) => {
        const ai_bot = e.target.value
        setSelectedInterviewer(e.target.value);
        setFormData((prev) => ({
            ...prev,
            organization: {
                ...prev.organization,
                ai_interviewer: ai_bot,
            },
        }))
    };

    // Handle next step
    const nextStep = () => {
        setStep(step + 1);
    };

    // Handle previous step
    const prevStep = () => {
        setStep(step - 1);
    };
    
    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            organization: {
                ...prev.organization,
            },
        }))
        fetchLookUp()
    }, [])

    useEffect(()=>{
        setFormData((prev) => ({
            ...prev,
            organization: {
                ...prev.organization,
                logo: logo,
            },
        }))
    },[logo])
    
    useEffect(() => {
        let stateOption = []
        stateCities.getStates().map((value) => {
            stateOption.push({
                label: capitalizeFirstLetter(value),
                value: capitalizeFirstLetter(value),
            })
        })
        setStates(stateOption)
        if (formData.organization.region && formData.organization.city) {
            setSelectState(formData.organization.region)
            setSelectCity(formData.organization.city)
            setCitys([])
        }
    }, [])

    useEffect(() => {
        setResult({})
        if (isSubmitted) {
            setFormData((prev) => ({
                ...prev,
                // step: 'ORGANIZATION_TYPE',
                organization: { 
                    ...prev.organization,     
                    logo: logo,
                },
            }))
            submitData()
        }
        setIsSubmitted(false)
    }, [isSubmitted])


    const fetchLookUp = async () => {
        const url = `/signup/contracts?name=org_note&role=${selectedRole}`
        await axios.get(url).then((res) => {
            setRoleDescription(res.data?.content)
        })

        const lookupsUrl = '/signup/lookups'
        await axios.get(lookupsUrl).then((res) => {
            setCompnanySizes([...res.data.company_sizes])
            setIndustries([...res.data.industries])
        })
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                150,
                150,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri)
                },
                'file',
                150,
                150
            )
        })

    const addLogo = async (e) => {
        e.persist()
        const compressedImage = await resizeFile(e.target.files[0])
        setLogoError(null)
        setLogo(compressedImage)
        setLogoUrl(URL.createObjectURL(compressedImage))
    }

    const onSelectState = (event) => {
        setSelectCity(null)
        setSelectState(event)
        let cityOption = []
        stateCities.getCities(event.value).map((value, key) => {
            cityOption.push({
                value: capitalizeFirstLetter(value),
                label: capitalizeFirstLetter(value),
            })
        })
        cityOption.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : -1)
        setCitys(cityOption)
        setFormData((prev) => ({
            ...prev,
            organization: {
                ...prev.organization,
                region: event,
                city:''
            },
        }))
    }
    const onSelectCity = (event) => {
        setSelectCity(event)
        setFormData((prev) => ({
            ...prev,
            organization: { 
                ...prev.organization,     
                city: event,
            },
        }))
    }

    const handleOnSubmit = (values) => {
        setFormData((prev) => ({
            ...prev,
            organization: {
                name: values.name,
                industry: values.industry,
                companySize: values.companySize,
                country: values.country,
                region: selectState,
                city: selectCity,
                description: values.description,
                logo: logo,
                agreement:agreement,
                website_url: values.website_url,
                subdomain: ((values.name).replace(" ","_")).toLowerCase(),
                slug: ((values.name).replace(" ","-")).toLowerCase(),
                organization_type: 'self-service',
                ai_interviewer: selectedInterviewer
            },
        }))
        nextStep()
        // setIsSubmitted(true)
    }

    return (
        <>
            <MainPanel>
                <section className={`container d-flex justify-content-center align-items-center ${styles.sectionMargin}`}>
                    <Row className="d-flex w-75 shadow-lg">
                        <Col className="col-md-6 justify-content-center d-flex flex-column text-left justify-content-between " style={{ background: "linear-gradient(270deg, #02315F 0%, #022D57 98.46%)",borderRadius: "20px 0px 0px 20px" }}>
                            <div className={styles.mainwelcomeSpace} style={{margin: 'auto'}}>
                                <h1 className='text-white'>Welcome to CardinalTalent.AI!</h1>
                                <p className='text-white'>
                                You're just a few easy steps away from completing your profile and
                                launching your AI interview.
                                </p>
                            </div>
                            <div className="cardinal-image mt-5 row">
                                <img src={botImage} className='w-100' alt="Cardinal AI Bot" style={{borderRadius: "20px 0px 0px 20px",maxHeight: "300px"}} />
                            </div>
                        </Col>
                        <Col className="col-md-6 px-5 py-4">
                            {step == 1 ? (
                                <h5 class="font-weight-bold mb-3 text-left">Organization Information</h5>
                            ): (
                                <h5 class="font-weight-bold mb-3 text-left">Select Logo</h5>
                            )}
                            {step == 1 ? (
                            <Formik
                                initialValues={{
                                    name: formData.organization.name,
                                    industry: formData.organization.industry,
                                    companySize: formData.organization.companySize,
                                    country: 'USA',
                                    city: formData.organization.city,
                                    region: formData.organization.region,
                                    description: formData.organization.description,
                                    logo: formData.organization.logo,
                                    website_url: formData.organization.website_url,
                                    subdomain: formData.organization.subdomain,
                                    slug: formData.organization.slug,
                                }}
                                validateOnBlur={true}
                                validationSchema={Yup.object({
                                    name: Yup.string().trim()
                                        .max(100, 'Must be exactly 100 characters')
                                        .required('Organization name is required')
                                        .test(
                                            'name-unique',
                                            'This Organization name is already in use',
                                            async function (value) {
                                                // check if user exists with email
                                                // call backend with email and see if it returns user
                                                const res = await axios.get(
                                                    `/organizations/exists?name=${encodeURIComponent(
                                                        value
                                                    )}`
                                                )
                                                return !res.data.organization_exists
                                            }
                                        ),
                                    industry: Yup.string()
                                        .required('Industry is required')
                                        .oneOf(
                                            industries.map(({ key, value }) => key),
                                            'Invalid Industry'
                                        ),
                                    companySize: Yup.string()
                                        .required('Organization size is required')
                                        .oneOf(
                                            compnanySizes.map(({ key, value }) => key),
                                            'Invalid Organization Size'
                                        ),
                                    country: Yup.string().required(
                                        'Country is required'
                                    ),
                                    description: Yup.string().required(
                                        'About Organization is required'
                                    ).trim(),
                                    website_url: Yup.string()
                                        .required(
                                            'Organization Website Url is required'
                                        )
                                        .trim(
                                            'Organization website cannot include leading and trailing spaces'
                                        ).matches(
                                            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                            'Enter correct url!'
                                        ),
                                })}
                                validate={(values) => {
                                    const errors = {}
                                    setFormData((prev) => ({
                                        ...prev,
                                        organization: {
                                            name: values.name,
                                            industry: values.industry,
                                            companySize: values.companySize,
                                            country: values.country,
                                            region:selectState ,
                                            city:selectCity ,
                                            description: values.description,
                                            logo: logo,
                                            website_url: values.website_url,
                                            subdomain: ((values.name).replace(" ","_")).toLowerCase(),
                                            slug: ((values.name).replace(" ","-")).toLowerCase(),
                                        },
                                    }))

                                    if (!selectState) {
                                        errors.region = 'State is required'
                                    }
                                    setStateError(errors.region)

                                    if (!selectCity) {
                                        errors.city = 'City is required'
                                    }
                                    setCityError(errors.city)

                                    return errors
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    handleOnSubmit(values)
                                }}
                            >
                                <Form>
                                    {/* <div className={styles.form_flexDiv}>
                                        <Row>
                                            <Col className="col-md-6">
                                                <Logo2 image={logoUrl}>
                                                    {logoUrl
                                                        ? ''
                                                        : 'Upload Organizations Logo'}
                                                </Logo2>
                                                {!!logoError && (
                                                    <span
                                                        style={{
                                                            fontSize: '10px',
                                                            color: 'red',
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        {logoError}
                                                    </span>
                                                )}
                                            </Col>
                                            <Col className="col-md-6">
                                                <p>Recommended: 128x128</p>
                                                <input
                                                    type="file"
                                                    name="logo"
                                                    id="logo"
                                                    ref={inputRef}
                                                    hidden
                                                    onChange={addLogo}
                                                />
                                                <button
                                                    style={{ marginTop: '10px',borderRadius: '20px' }}
                                                    className='btn btn-outline-primary'
                                                    type="button"
                                                    onClick={() => inputRef.current.click()}
                                                >
                                                    Upload File
                                                </button>       
                                            </Col>
                                        </Row>
                                        
                                    </div> */}
                                    <TextInput
                                        className={styles.fullwidthInput}
                                        label="Organization Name*"
                                        name="name"
                                        type="text"
                                        id="name"
                                        placeholder="Organization Name*"
                                        width="20rem"
                                    />
                                    <TextInput
                                        className={styles.fullwidthInput}
                                        as="select"
                                        label="Industry*"
                                        name="industry"
                                        type="text"
                                        id="industry"
                                        width="20rem"
                                    >
                                        <option value="">Select Industry</option>
                                        {industries.map(
                                            ({ key, value }) => {
                                                return (
                                                    <option
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {value}
                                                    </option>
                                                )
                                            }
                                        )}
                                    </TextInput>
                                    <TextInput
                                        className={styles.fullwidthInput}
                                        as="select"
                                        label="Organization Size*"
                                        name="companySize"
                                        type="text"
                                        id="companySize"
                                        width="20rem"
                                    >
                                        <option value="">Select Organization Size</option>
                                        {compnanySizes.map(
                                            ({ key, value }) => {
                                                return (
                                                    <option
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {value}
                                                    </option>
                                                )
                                            }
                                        )}
                                    </TextInput>
                                    
                                    <TextInput
                                        className={styles.fullwidthInput}
                                        label="Organization Website URL*"
                                        name="website_url"
                                        type="text"
                                        id="website_url"
                                        placeholder="Organization Website URL*"
                                        width="18rem"
                                    />
                                    <TextInput
                                        className={styles.fullwidthInput}
                                        name="country"
                                        type="text"
                                        id="country"
                                        placeholder="Country*"
                                        width="10rem"
                                        readOnly={true}
                                    />
                                    <Select
                                        options={states}
                                        onChange={onSelectState}
                                        name="region"
                                        placeholder="Select State*"
                                        value={formData.organization.region}
                                        className={`${styles.colourStylesSelect} react-select-2`}
                                        classNamePrefix={
                                            styles.colourStylesSelect
                                        }
                                        
                                    />
                                    {
                                    !!stateError && 
                                        <span className={styles.formError}>
                                            {stateError} 
                                        </span>
                                    }
                                    <Select
                                        options={citys}
                                        onChange={onSelectCity}
                                        name="city"
                                        placeholder="Select City*"
                                        value={formData.organization.city}
                                        className={
                                            styles.colourStylesSelectCity
                                        }
                                        classNamePrefix={
                                            styles.colourStylesSelectCity
                                        }
                                    />
                                    {!!cityError && (
                                        <span className={styles.formError}>
                                            {cityError}
                                        </span>
                                    )}
                                    <TextInput
                                        className={styles.fullwidthInput}
                                        as="textarea"
                                        name="description"
                                        placeholder="About Organization*"
                                        type="text"
                                        style={{ marginTop: '10px',marginBottom: '5px' }}
                                        id="description"
                                        width="31rem"
                                    />

                                    <div className='d-flex justify-content-between mt-3'>  
                                    {step === 1 && ( 
                                        <>       
                                        <button type="button" 
                                            className="mr-3 btn btn-outline-primary"
                                            style={{ marginRight: '20px',borderRadius: '20px' }}
                                            onClick={() =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    step: CONTACT_DETAILS,
                                                }))
                                            }
                                        >
                                        {!hidePrevious &&  'Previous'}
                                        </button>
                                        <Button type="submit" disabled={loading}>
                                            {loading ? (
                                                <Spinner
                                                    animation="border"
                                                    variant="light"
                                                />
                                            ) : (
                                                (selectedRole === 'employer') ? 'Next' : 'Submit'
                                            )}
                                        </Button>
                                        </>
                                    )}

                                    </div>
                                </Form>
                            </Formik>
                            ): (
                                <div>
                                    <Row>
                                        <Col className="col-md-6">
                                            <Logo2 image={logoUrl}>
                                                {logoUrl
                                                    ? ''
                                                    : 'Upload Organizations Logo'}
                                            </Logo2>
                                            {!!logoError && (
                                                <span
                                                    style={{
                                                        fontSize: '10px',
                                                        color: 'red',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    {logoError}
                                                </span>
                                            )}
                                        </Col>
                                        <Col className="col-md-6">
                                            <p>Recommended: 128x128</p>
                                            <input
                                                type="file"
                                                name="logo"
                                                id="logo"
                                                ref={inputRef}
                                                hidden
                                                onChange={addLogo}
                                            />
                                            <button
                                                style={{ marginTop: '10px',borderRadius: '20px' }}
                                                className='btn btn-outline-primary'
                                                type="button"
                                                onClick={() => inputRef.current.click()}
                                            >
                                                Upload File
                                            </button>       
                                        </Col>
                                    </Row>
                                    <h5 class="font-weight-bold mt-3 mb-3 text-left">{'Select AI Interviewer(Optional)'}</h5>      
                                        <div className="cc-selector">
                                            <input
                                                id="mary"
                                                type="radio"
                                                name="ai_interviewer"
                                                value="mary"
                                                checked={selectedInterviewer === 'mary'}
                                                onChange={handleInterviewerChange}
                                            />
                                            <label className="drinkcard-cc mary" htmlFor="mary" 
                                                style={{
                                                    backgroundImage: `url(${maryImage})`,
                                                    backgroundSize: 'cover',
                                                    width: '150px',
                                                    height: '150px',
                                                }}
                                            >
                                                {/* <span className="mary-bottom">Mary</span> */}
                                            </label>

                                            <input
                                                id="steven"
                                                type="radio"
                                                name="ai_interviewer"
                                                value="steven"
                                                checked={selectedInterviewer === 'steven'}
                                                onChange={handleInterviewerChange}
                                            />
                                            <label className="drinkcard-cc steven" htmlFor="steven"
                                                style={{
                                                    backgroundImage: `url(${stevenImage})`,
                                                    backgroundSize: 'cover',
                                                    width: '150px',
                                                    height: '150px',
                                                }}
                                            >
                                                {/* <span className="steven-bottom">Steven</span> */}
                                            </label>
                                        </div>       
                                    <div className='d-flex justify-content-between mt-3'>  
                                        <button type="button" 
                                            className="mr-3 btn btn-outline-primary"
                                            style={{ marginRight: '20px',borderRadius: '20px' }}
                                            onClick={() =>
                                                prevStep()
                                            }
                                        >
                                        {!hidePrevious &&  'Previous'}
                                        </button>
                                        <Button type='button' disabled={loading} onClick={() =>{
                                            // const errors = {}
                                            // if (!logo) {
                                            //     errors.logo = 'Logo is required'
                                            // } else if (
                                            //     ![
                                            //         'image/jpg',
                                            //         'image/jpeg',
                                            //         'image/gif',
                                            //         'image/png',
                                            //     ].includes(logo.type)
                                            // ) {
                                            //     errors.logo =
                                            //         'Logo can only ne of type jpg, png, gif, jpeg'
                                            // } else {
                                            //     setIsSubmitted(true)
                                            // }
                                            // setLogoError(errors.logo)
                                            setIsSubmitted(true)
                                            
                                        }
                                        
                                            }>
                                            {loading ? (
                                                <Spinner
                                                    animation="border"
                                                    variant="light"
                                                />
                                            ) : (
                                                (selectedRole === 'employer') ? 'Submit' : 'Next'
                                            )}
                                            
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </section>
            </MainPanel>
        </>
    )
}

export default CreateOrganizationPage
