import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SearchResultsBlock from './../JobSearchPage/SearchResultsBlock/SearchResultsBlock'
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import UploadResumeModal from './UploadResumeModal'
import moment from 'moment'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import './styles/cardinal_jobs.scss'

const RESULTS_PER_PAGE = 25

const CardinalJobs = ({ data, totalJobs, pagesCount, currentUser, job_groups }) => {
	const { jobs, meta } = JSON.parse(data)
	const user = currentUser;
	const [resumeFile, setResumeFile] = useState([])
	const [resumeFileName, setResumeFileName] = useState((sessionStorage.getItem('cardinalGuestUserAttachment')) ? sessionStorage.getItem('cardinalGuestUserAttachment') : '')
	const [validationErrors, setValidationErrors] = useState({})
	const [cardinalJobs, setCardinalJobs] = useState(jobs)
	const [loading, setLoading] = useState(false)
	const [inputValue, setInputValue] = useState('')
	console.log("🚀 ~ file: CardinalJobs.js:21 ~ CardinalJobs ~ inputValue:", inputValue)
	const [activePage, setActivePage] = useState(0)
	const [totalJobsCount, setTotalJobsCount] = useState(totalJobs)
	const [totalPages, setTotalPages] = useState(pagesCount)
	const [errorSearchingJobs, setErrorSearchingJobs] = useState(null)
	const [selectOrganizationType, setSelectOrganizationType] = useState('')
	const [selectOriginalJob, setSelectOriginalJob] = useState('')
	const [selectJobGroup, setSelectJobGroup] = useState('')
	const [jobFilter, setJobFilter] = useState('')
	const [show, setShow] = useState(false)
	const [buttonDisabled, setButtonDisabled] = useState(false)
	const [personId, setPersonId] = useState((sessionStorage.getItem('cardinalGuestUser')) ? sessionStorage.getItem('cardinalGuestUser') : '')
	const [bulkJobIds, setBulkJobIds] = useState([])

	// Upload File Code
	const fileTypes = [
		'application/msword',
		'application/pdf',
		'application/docx',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	]

	const submitJobSearch = (event) => {
		event.preventDefault()
		setActivePage(0)
		handleSearch()
	}

	const removeResume = () => {
		setResumeFile([])
		setValidationErrors({})
		setButtonDisabled(false)
		setPersonId('')
		sessionStorage.removeItem('cardinalGuestUser')
		sessionStorage.removeItem('cardinalGuestUserAttachment')
		setResumeFileName('')
		setBulkJobIds([])
	}

	const handleOnRemoveResumes = (removeIndex) => {
		const newResumes = resumeFile.filter(
			(_, index) => removeIndex !== index
		)
		setResumeFile([])
		setValidationErrors({})
		setButtonDisabled(false)
		setPersonId('')
		sessionStorage.removeItem('cardinalGuestUser')
		sessionStorage.removeItem('cardinalGuestUserAttachment')
		setBulkJobIds([])

	}
	const handleResumeFiles = (files) => {
		setResumeFile([...files])
		setBulkJobIds([])
		setValidationErrors({})
		setButtonDisabled(false)
		sessionStorage.removeItem('cardinalGuestUser')
		sessionStorage.removeItem('cardinalGuestUserAttachment')
	}

	const handleSubmitResume = async () => {
		setValidationErrors({})
		if (resumeFile.length <= 0) {
			setValidationErrors({
				...validationErrors,
				resume: 'Please Upload Resume files.',
			})
			setResumeFile([])
			return
		}
		if (!fileTypes?.includes(resumeFile[0]?.type)) {
			setValidationErrors({
				...validationErrors,
				resume: 'Please upload pdf or docx file only.',
			})
			setResumeFile([])
			return
		}
		if (Object.values(validationErrors).length === 0) {
			setButtonDisabled(true)
			setLoading(true)
			const url = '/match_scores/from_resume_anon'
			const formData = new FormData()
			formData.append('resume', resumeFile[0])
			const responce = await makeRequest(url, 'post', formData, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if (res?.data?.msg) {
					setValidationErrors({
						...validationErrors,
						resume: res?.data?.msg,
					})
					setResumeFile([])
				}
				if (typeof res?.data?.person != 'undefined') {
					sessionStorage.setItem('cardinalGuestUser', res?.data?.person)
					sessionStorage.setItem('cardinalGuestUserAttachment', resumeFile[0].name)
					window.location.reload()
				}
				else {
					setPersonId(null)
					setShow(false)
					setResumeFileName('')
				}
				setLoading(false)
			}).catch((e) => {
				setValidationErrors({
					...validationErrors,
					resume: 'Kindly upload a different file.',
				})
				setLoading(false)
				setButtonDisabled(false)
			})
		}
	}

	const moveToATS = () => {
		const url = '/job/cardinal_jobs.json'
		const formData = new FormData()
		formData.append('cardinal_job[candidate_id]', personId)
		formData.append('cardinal_job[job_ids]', bulkJobIds)
		makeRequest(url, 'post', formData, {
			contentType: 'application/json',
			loadingMessage: 'Submitting...',
			createResponseMessage: () => {
				return {
					message: `Moved Successfully`,
					messageType: 'success',
					loading: false,
					autoClose: true,
				}
			},
			createErrorMessage: (e) => 'Failed',
			onSuccess: (res) => {
				console.log(res)
				if(res.data.type == 'success'){
					setBulkJobIds([])
					dismiss()
				}
			},
		})
	}

	const onChangeBulkJobIds = (id, checked) => {
		if (checked)
			setBulkJobIds([...bulkJobIds, id])
		else {
			setBulkJobIds([...bulkJobIds.filter((el) => el != id)])
		}
	}

	const dismiss = () => {
		bulkJobIds.map((id, index) => {
			document.getElementById(`chk_${id}`).checked = false
		})
		setBulkJobIds([])
	}

	useEffect(() => {
		handleSearch()
	}, [activePage, selectOrganizationType, jobFilter, personId, selectJobGroup, selectOriginalJob])

	const onHide = () => {
		setShow(false)
		setValidationErrors({})
	}

	const handleInputChange = (value) => {
		setInputValue(value)
	}
	const filter_status = [
		{ value: '', label: 'Active / Pending' },
		{ value: 'closed', label: 'Closed' },
		{ value: 'expired', label: 'Expired' },
		{ value: 'rejected', label: 'Rejected' }
	];
	const organization_type_list = [
		{ value: 'all', label: 'All' },
		{ value: 'hourly', label: 'Hourly' },
		{ value: 'contingency', label: 'Contingency' }
	];

	const handleSearch = async () => {
		const encodedValue = encodeURIComponent(inputValue)
		const url = `cardinal_jobs.json?keyword=${encodedValue}&page=${activePage + 1}&organization_type=${selectOrganizationType}&job_group=${selectJobGroup}&status=${jobFilter}&person_id=${personId}&is_original_job=${selectOriginalJob}`
		setLoading(true)
		const CSRF_Token = document
			.querySelector('meta[name="csrf-token"]')
			.getAttribute('content')
		try {
			const response = await axios.get(url,
				{
					headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
					},
				}
			);
			const jobsData = response.data
			const jobs = jobsData.jobs
			const totalCount = response.data.meta.jobs_total_count
			const totalPages = response.data.meta.total_pages
			setTotalJobsCount(totalCount)
			setTotalPages(totalPages)
			setCardinalJobs(jobs)
		} catch (e) {
			setErrorSearchingJobs(e.message)
		}
		setLoading(false)
	}
	return (
		<div className="job-search-page">
			<div>
				<>
					<SearchResultsBlock
						totalJobsCount={totalJobsCount}
						pageCount={totalPages}
						activePage={activePage}
						jobs={cardinalJobs}
						loading={loading}
						submitJobSearch={submitJobSearch}
						handleInputChange={handleInputChange}
						inputValue={inputValue}
						setActivePage={setActivePage}
						errorSearchingJobs={errorSearchingJobs}
						setErrorSearchingJobs={setErrorSearchingJobs}
						user={user}
						perPage={RESULTS_PER_PAGE}
						filter_status={filter_status}
						jobFilter={jobFilter}
						setJobFilter={setJobFilter}
						organization_type_list={organization_type_list}
						setSelectOrganizationType={setSelectOrganizationType}
						selectOrganizationType={selectOrganizationType}
						uploadResumeFlage={true}
						setShow={setShow}
						resumeFileName={resumeFileName}
						removeResume={removeResume}
						personId={personId}
						onChangeBulkJobIds={onChangeBulkJobIds}
						job_groups={job_groups}
						selectJobGroup={selectJobGroup}
						setSelectJobGroup={setSelectJobGroup}
						setSelectOriginalJob={setSelectOriginalJob}
						selectOriginalJob={selectOriginalJob}
					/>
				</>
			</div>
			<Modal
				onHide={onHide}
				show={show}
				className='modal-entry-form'
				backdrop="static"
				centered={true}
				keyboard={false}
				size='sm'
			>
				<Modal.Body>
					<UploadResumeModal
						onHide={onHide}
						validationErrors={validationErrors}
						resumeFile={resumeFile}
						handleOnRemoveResumes={handleOnRemoveResumes}
						handleResumeFiles={handleResumeFiles}
						handleSubmitResume={handleSubmitResume}
						buttonDisabled={buttonDisabled}
					/>
				</Modal.Body>
			</Modal>
			{bulkJobIds && bulkJobIds.length > 0 && (
				<div className='w-100 d-flex justify-content-center'>
					<div className='action-bar'>
						<Row className='h-100'>
							<Col xs={8} sm={8} lg={8} className='align-self-center'>
								<span className='text-light font-weight-bold ml-3'>{`${bulkJobIds.length} Jobs Selected`}</span>
							</Col>
							<Col xs={4} sm={4} lg={4} className='align-self-center'>
								<div className='d-flex justify-content-end mr-3'>
									<ul className='mt-auto mb-auto'>
										<OverlayTrigger
											placement='top'
											overlay={
												<Tooltip>Move to ATS</Tooltip>
											}
										>
											<a className=' cursor-pointer mr-1' onClick={() => moveToATS()}><i className='text-light mr-3' data-feather="file-plus"/></a>
										</OverlayTrigger>
										<Button style={{borderColor: '#0062cc'}} className='text-light font-weight-bold text-decoration-none' onClick={() => dismiss()}>Cancel</Button>
									</ul>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			)}
		</div>
	)
}

export default CardinalJobs
