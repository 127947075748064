import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Modal from "react-bootstrap/Modal"
import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import './styles/TimeReportEmployee'
import AddTimeEntry from './AddTimeEntry'
import axios from 'axios'
import Util from "../../../../utils/util";

const TimeReportEmployee = ({employee,user,manager,ethod,message}) => {
  
    var active_page = window.sessionStorage.getItem('activePage')
    var query = window.sessionStorage.getItem('query')
    const localStorageDateRange = JSON.parse(sessionStorage.getItem('timeReportDateRange'))
    const startDate = (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate()
    const endDate = (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate()
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const [timeEntrys, setTimeEntrys] = useState([])
    const [totalJobs,setTotalJobs] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
    const [show,setShow] = useState(false)
    const [method,setMethod] = useState('post')
    const [entry,setEntry] = useState({})
    const [loading, setLoading] = useState(true)

    const fetchData = (sDate,eDate) => {
        setLoading(true)
        const url = `/cardinal/time_report_employees.json`
        window.sessionStorage.setItem('activePage',activePage)
        makeRequest(url, 'get',  {params: {page: (activePage+1),startDate: sDate ,endDate: eDate ,user_id :employee.user_id, query: query}}, {   
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setTimeEntrys(
                    res.data.time_reports.map((entry) => ({
                        id: entry.id,
                        user_id: entry.user_id,
                        entry_date: entry.entry_date,
                        start_1:entry.start_1,
                        end_1:entry.end_1,
                        start_2:entry.start_2,
                        end_2:entry.end_2,
                        client_hr:setClientHr(entry.entry_date,res.data.time_report_items,res.data.time_report_non_client),
                        non_client_hr:setNonClientHr(entry.entry_date,res.data.time_report_non_client),
                        break_1_start:entry.break_1_start,
                        break_1_end:entry.break_1_end,
                        break_2_start:entry.break_2_start,
                        break_2_end:entry.break_2_end,
                        calls:entry.calls ? entry.calls : 0,
                        emails:entry.emails ? entry.emails :0,
                        submissions:entry.submissions ? entry.submissions :0,
                        inmail_sent:entry.inmail_sent ? entry.inmail_sent :0,
                        confirmed_interview:entry.confirmed_interview ? entry.confirmed_interview :0,
                        total_time:entry.total_time ? entry.total_time : 0

                    }))
                )
                setPageCount(res.data.total_pages)
                setTotalJobs(res.data.total_count)
                setCurrentCounts(res.data.current_counts)
                setStartRecord(activePage * res.data.per_page)
                setLoading(false)
            },
        })
    }

    useEffect(() => {
        const startDate = moment(dateRange.startDate).format('MMM,DD, YYYY')
        const endDate = moment(dateRange.endDate).format('MMM,DD, YYYY')
        fetchData(startDate,endDate)
    }, [activePage, dateRange])

    const handleDate = (date) => {
        setDateRange(date)
    }

    const setClientHr = (date,timeEntryClient,timeEntryNonClient) => {
        let client_hr = 0;
        timeEntryClient.map((entry) => {
          if(entry.entry_date == date){
            client_hr = entry.total_hrs
          }
        })
        if(client_hr > 0){
            timeEntryNonClient.map((entry) => {
                if(entry.entry_date == date){
                    client_hr = client_hr - entry.total_hrs
                }
            })
        }
        
        return client_hr
    }

    const setNonClientHr = (date,timeEntryNonClient) => {
        let client_hr = 0;
        timeEntryNonClient.map((entry) => {
          if(entry.entry_date == date){
            client_hr = entry.total_hrs
          }
        })
        return client_hr
    }

    const onAddEntry = () => {
        setEntry({})
        setMethod('post')
        setShow(true)
    }
    
    const onHide = () => {
        setEntry({})
        setMethod('post')
        setShow(false)
        fetchData(moment(dateRange.startDate).format('MMM,DD, YYYY'),moment(dateRange.endDate).format('MMM,DD, YYYY'))
    }

    const getUpdateData = (id) => {
        const URL = `/cardinal/time_report_employees/${id}`
        axios.get(URL).then((res) => {
            setMethod('put')
            setEntry(res.data.entry)
            setShow(true)
        })
    }

    const deleteEntry = async (id) => {
        const URL = `/cardinal/time_report_employees/${id}`
        await makeRequest(URL, 'delete', '', {
            createResponseMessage: () => {
                return {
                    message: `Deleted successful`,
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: () => {      
                fetchData(moment(dateRange.startDate).format('MMM,DD, YYYY'),moment(dateRange.endDate).format('MMM,DD, YYYY'))
            },      
        })
    }

    return (
        <>
          
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                   {Util.capitalize(user.first_name)} {Util.capitalize(user.last_name)} - Time Report
                </P>
                <div style={{flexGrow:1}}/>
                <Button className="ml-3" onClick={onAddEntry}>
                    Add Time Entry
                </Button>
            </div>
            <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
                <Col xs={12} sm={12} lg={4} className='p-0'>
                    <P size="22px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Type : <span>{ Util.capitalize(employee.employee_type) }</span>
                    </P>
                </Col>
                <Col xs={12} sm={12} lg={6}>
                    <P size="22px" height="38px" color="#1D2447" style={{width:'540px'}}>
                       <span> { (manager.first_name) ? '  Manager : ' + manager.first_name +' ' + manager.last_name : '' }</span>
                    </P>
                </Col>
               
            </Row>
            <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
                <Col xs={12} sm={12} lg={5} className='p-0'>
                    {totalJobs > 0  &&
                        <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalJobs} entry
                        </DisplayPagination>
                    }
                 </Col>
                
                <Col  xs={12} sm={12} lg={7}  className='text-right p-0'>
                    <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                   
                </Col>
                 
            </Row>
             
            <Table
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Date',
                        field: 'entry_date',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Start 1',
                        field: 'start_1',
                        editable: true,
                        type: 'text',
                    },{
                        name: 'End 1',
                        field: 'end_1',
                        editable: false,
                        type: 'text',
                        nowrap:''
                    },
                    {
                        name: 'Start 2',
                        field: 'start_2',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'End 2',
                        field: 'end_2',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Total Hr',
                        field: 'total_time',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Client Hr',
                        field: 'client_hr',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Non-clients Hr',
                        field: 'non_client_hr',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Break 1 Start',
                        field: 'break_1_start',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Break 1 End',
                        field: 'break_1_end',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Break 2 Start',
                        field: 'break_2_start',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Break 2 End',
                        field: 'break_2_end',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Calls',
                        field: 'calls',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Emails',
                        field: 'emails',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Submission',
                        field: 'submissions',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Inmail Sent',
                        field: 'inmail_sent',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Confirmed Interviews',
                        field: 'confirmed_interview',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Action',
                        field: 'options',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap',
                    },
                
                ]}
                rowValues={timeEntrys.map((entry) => ({
                    ...entry,
                    entry_date: moment(entry.entry_date).format('MMM DD, YYYY'),
                }))}
                showEditOption
                activePage={activePage}
                deleteAction={deleteEntry}
                goToEditPage={(id) =>
                    getUpdateData(id)
                }
                loading={loading}
            />

            {pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}

            {timeEntrys.length == 0 && (
                <div>
                    <table className='mt-3'>
                        <tr><td>No Record Found</td></tr>
                    </table>

                </div>

            )}
           
        </Card>

        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            scrollable
            className='modal-entry-form'
            >
            <Modal.Header closeButton className='mt-2 mb-2'>
                <Modal.Title id="contained-modal-title-vcenter" >
                  { method ==='put' ?'Update' : '' } Time Entry
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddTimeEntry employee={employee} timeEntry={entry} onHide={onHide} method={method}></AddTimeEntry>
            </Modal.Body>
        </Modal>
        </>
        
    )
}

export default TimeReportEmployee