import React, { useState, useEffect } from 'react'
import { Alert, Col, OverlayTrigger, Popover } from 'react-bootstrap'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from 'react-share'
import CompanyPlaceholderImage from '../../../../assets/images/talent_page_assets/company-placeholder.png'
import {
    Row,
    Container,
    Button2,
    Body,
    ImageContainer,
    Box,
    HeaderRow,
    List,
    Dot,
    Button,
    Wrapper,
    Section,
    Skill,
    W4text,
    W9text,
    W5text,
    Header,
} from './styles/JobDescriptionPage.styled'
import './styles/JobDescriptionPage.scss'
import ReferralModal from '../../common/modals/JobDescriptionModal/ReferralModal'
import { handelBrokenUrl } from '../../common/Utils'
import SweetAlert from 'react-bootstrap-sweetalert'
import { firstCharacterCapital, skillIsNotEmpty } from '../../../utils'
import styles from './styles/JobDescriptionPage.module.scss'
import {ApplyButton} from '../../common/ApplyJob/styles/Apply.styled'
import { Modal} from 'react-bootstrap'
import axios from 'axios'
import Moment from 'moment'
import CandidateDetails from '../../common/CandidateForm/CandidateDetails'
import EmailConfirm from '../../common/EmailConfirm/EmailConfirm'


const popover = (shareUrl) => {
    const content = (
        <Popover id="popover-basic">
            <Popover.Content>
                <FacebookShareButton
                    openShareDialogOnClick={false}
                    onClick={(_, url) => {
                        window.open(url, '_blank')
                    }}
                    style={{ marginLeft: 10 }}
                    url={shareUrl}
                    target="_blank"
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>

                <TwitterShareButton
                    openShareDialogOnClick={false}
                    onClick={(_, url) => {
                        window.open(url, '_blank')
                    }}
                    style={{ marginLeft: 10 }}
                    url={shareUrl}
                    target="_blank"
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>

                <LinkedinShareButton
                    openShareDialogOnClick={false}
                    onClick={(_, url) => {
                        window.open(url, '_blank')
                    }}
                    style={{ marginLeft: 10 }}
                    url={shareUrl}
                    target="_blank"
                >
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </Popover.Content>
        </Popover>
    )

    return content
}

const JobDescriptionPage = ({
    jobModel: job,
    currentUser,
    isApplied,
    showInviteFriends,
    appliedDate,
    organizationId,
    publicUrl,
    organization,
    isProfileComplete,
}) => {
    const {
        name,
        location,
        compensation: salary,
        portalcompanyname: company,
        description,
        niceToHaveSkills,
        skills,
        gen_reqs: requirements,
        pref_skills: preferredSkills,
        logo,
        department,
        experience_years,
        created_at,
        organization_id
    } = job
    const [errorCreatingSubmission, setErrorCreatingSubmission] = useState(null)
    const [alertApplyForJob, setAlertApplyForJob] = useState(null)
    const [showReferralModal, setShowReferralModal] = useState(showInviteFriends)
    const [applied, setApplied] = useState(isApplied || false)
    let prefSkills = skills.length && skills.split(',')
    let niceToHave = niceToHaveSkills?.length && niceToHaveSkills.split(',')
    const [candidateModel, setCandidateModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [emailVerify, setEmailVerify] = useState(false)
    const [applyedDate, setApplyedDate] = useState(appliedDate)
    const [checkCurrentUserPersonId, setCheckCurrentUserPersonId] = useState(false)

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href)
        return function cleanup() {
            window.onpopstate = null
        }
    }, [])
    
    const redirectToCompleteProfile = () => {
        window.location.href = `/users/profile/${currentUser.id}`
        setCheckCurrentUserPersonId(false)
    }
    const handleApply = async () => {
        if (currentUser) {
            if (!isProfileComplete) {
                window.location.href = '/complete/profile'
            } else if (!currentUser.email_confirmed) {
                setEmailVerify(true)
            } else {
                if(currentUser.person_id){

                const token = document.querySelector(
                    'meta[name="csrf-token"]'
                ).content
                let payload = {
                    authenticity_token: token,
                }

                payload['submission'] = {
                    job_id: job.id,
                    organization_id: job.organization_id,
                    submission_type: 'applicant',
                    source: 'talent_user'
                }

                try {
                    setLoading(true)
                    setApplied(true)
                    const { data } = await axios.post(
                        '/submissions.json',
                        payload
                    )

                   
                    if (data.hasOwnProperty('error')) {
                        setErrorCreatingSubmission(data.error)
                        setLoading(false)
                        setApplied(false)
                    } else {
                        setApplyedDate(new Date())
                        setLoading(false)
                        setAlertApplyForJob(
                            'You successfully applied for this job!'
                        )
                    }
                } catch (error) {
                    setErrorCreatingSubmission(error.response.data.error)
                    setLoading(false)
                }
            }else{
                setCheckCurrentUserPersonId(true)
                console.log('empty')
            }
               
            }
        } else {
            setCandidateModal(true)
        }
    }
    return (
        <Wrapper>
            <Section flex="1">
                <Header></Header>
            </Section>
            <Section flex="2">
                <Body className={styles.job_detailmain}>
                    <HeaderRow direction="row">
                        <Row direction="row" jContent="flex-end">
                            <ImageContainer className={styles.image_div}>
                                <img
                                    src={organization.image_url ? organization.image_url : CompanyPlaceholderImage}
                                    onError={(e) => {
                                        handelBrokenUrl(e)
                                    }}
                                />
                            </ImageContainer>
                            <Section
                                direction="row"
                                aSelf="flex-end"
                                mTop="35px"
                            >
                                <Box>
                                    <div>
                                        {!currentUser ? (
                                            <ApplyButton
                                                lr="35px"
                                                tb="10px"
                                                disabled={applied ? true : false}
                                                onClick={handleApply}
                                            >
                                                <W5text size="12px" color="#ffff">
                                                    {!applied ? `Apply for this job` : `Applied`}
                                                </W5text>
                                            </ApplyButton>
                                        ) : ( (currentUser.role != 'recruiter' && currentUser.role != 'employer') ? (
                                                <ApplyButton
                                                    lr="40px"
                                                    tb="10px"
                                                    disabled={applied ? true : false}
                                                    onClick={handleApply}
                                                >
                                                    <W5text size="12px" color="#ffff">
                                                        {applied && applyedDate
                                                            ? `Applied on ${Moment(applyedDate).format(
                                                                'DD-MM-YYYY hh:mm:ss'
                                                            )}`
                                                            : 'Apply for this job'}
                                                    </W5text>
                                                </ApplyButton>
                                             ) : ''
                                        )}
                                    </div>
                                </Box>
                                <Box>
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="top"
                                        overlay={popover(publicUrl)}
                                    >
                                        <Button
                                            lr="23px"
                                            tb="10px"
                                            className={styles.apply_job_btns}
                                        >
                                            <W5text size="12px" color="#ffff">
                                                Share this job
                                            </W5text>
                                        </Button>
                                    </OverlayTrigger>
                                </Box>
                                <Box>
                                    <Button
                                        className={styles.apply_job_btns}
                                        lr="23px"
                                        tb="10px"
                                        onClick={() => {
                                            !currentUser
                                                ? (window.location =
                                                      '/users/sign_in?page=/&invite_friends=true&job_id=' +
                                                      job.id)
                                                : setShowReferralModal(true)
                                        }}
                                    >
                                        <W5text size="12px" color="#ffff">
                                            Invite to apply
                                        </W5text>
                                    </Button>
                                     </Box>
                                     <Box>
                                    <Button
                                        className={styles.apply_job_btns}
                                        lr="23px"
                                        tb="10px"
                                        onClick={() => {(window.location ='/job_search?page=prew')}}
                                            >
                                        <W5text size="12px" color="#ffff">
                                            Back
                                        </W5text>
                                    </Button>
                                        </Box>
                            </Section>
                        </Row>
                    </HeaderRow>
                    <Row direction="column" style={{ marginBottom: '34px' }}>
                        {alertApplyForJob && (
                            <Alert
                                variant="success"
                                onClose={() => setAlertApplyForJob(null)}
                                dismissible
                            >
                                {alertApplyForJob}
                            </Alert>
                        )}
                        {errorCreatingSubmission && (
                            <Alert
                                variant="danger"
                                onClose={() => setErrorCreatingSubmission(null)}
                                dismissible
                            >
                                {errorCreatingSubmission}
                            </Alert>
                        )}
                        <Box>
                            <W4text size="24px" color="#1D2447">
                                <a
                                    href={
                                        organizationId
                                            ? `/organizations/${organizationId}/careers`
                                            : '#'
                                    }
                                >
                                    {organization?.name}
                                </a>
                            </W4text>
                        </Box>
                        <Box>
                            <W9text size="32px" color="#1D2447">
                                {name}
                            </W9text>
                        </Box>

                        <Container direction="row">
                            {!!requirements?.length && (
                                <Box>
                                    <Button2>{requirements}</Button2>
                                </Box>
                            )}
                            <Box>
                                <Container direction="row">
                                    <List>
                                        {!!department?.length && (
                                            <>
                                                <Dot />
                                                <W4text
                                                    size="20px"
                                                    color="#1D2447"
                                                    style={{ marginTop: '2px' }}
                                                >
                                                    {department}
                                                </W4text>
                                            </>
                                        )}
                                    </List>
                                    <List>
                                        {!!location?.length && (
                                            <>
                                                <Dot />
                                                <W4text
                                                    size="20px"
                                                    color="#1D2447"
                                                    style={{ marginTop: '2px' }}
                                                >
                                                    {location !== "null" ? location : 'No location found'}
                                                </W4text>
                                            </>
                                        )}
                                    </List>
                                    <List>
                                        {!!experience_years && experience_years != "null"  &&  (
                                            <>
                                                <Dot />
                                                    <W4text
                                                        size="20px"
                                                        color="#1D2447"
                                                        style={{ marginTop: '2px' }}
                                                    >
                                                    { experience_years !== "null" ? experience_years +" Years Experince" : '' } 
                                                    
                                                </W4text>
                                            </>
                                        )}
                                    </List>
                                </Container>
                            </Box>
                        </Container>
                    </Row>
               {checkCurrentUserPersonId && <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="danger"
                    title="Please Complete Your Profile"
                    onConfirm={redirectToCompleteProfile}
                    onCancel={()=>setCheckCurrentUserPersonId(false)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />}

                    <Row direction="row" aItems="center">
                        <Row
                            direction="column"
                            jContent="center"
                            aItems="center"
                        >
                            <Box mBot>
                                <W5text size="32px" color="#A3AEE8">
                                   Skills
                                </W5text>
                            </Box>
                            <Container>
                                {!!prefSkills &&
                                    prefSkills?.map(
                                        (item, index) =>
                                            skillIsNotEmpty(item) && (
                                                <Skill key={index}>
                                                    {firstCharacterCapital(item)}
                                                </Skill>
                                            )
                                    )}
                            </Container>
                        </Row>
                        {/* <Row
                            direction="column"
                            jContent="center"
                            aItems="center"
                        >
                            <Box mBot>
                                <W5text size="32px" color="#A3AEE8">
                                    Nice to have
                                </W5text>
                            </Box>
                            <Container>
                                {!!niceToHave &&
                                    niceToHave.map(
                                        (item, index) =>
                                            skillIsNotEmpty(item) && (
                                                <Skill key={index}>
                                                    {item}
                                                </Skill>
                                            )
                                    )}
                            </Container>
                        </Row> */}
                        <Row direction="row" jContent="center">
                            <W5text size="32px" color="#A3AEE8">
                                About the position
                            </W5text>
                        </Row>
                        <Row direction="row" jContent="center">
                            <div className='description'
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </Row>
                    </Row>
                    <Row> 
                        <Col className='p-0' lg={3} md={3}>
                        <hr></hr>
                        <p className={''}><b>Posted:</b> {Moment(created_at).fromNow()}</p>
                        </Col>
                    </Row>
                    <Row jContent="center" style={{ marginTop: '50px' }}>
                        <div>
                            {!currentUser ? (
                                <ApplyButton
                                    lr="35px"
                                    tb="10px"
                                    disabled={applied ? true : false}
                                    onClick={() => handleApply()}
                                >
                                    <W5text size="12px" color="#ffff">
                                        {!applied ? `Apply for this job` : `Applied`}
                                    </W5text>
                                </ApplyButton>
                            ) : ( (currentUser.role != 'recruiter' &&  currentUser.role != 'employer') ? (
                                    <ApplyButton
                                        lr="40px"
                                        tb="10px"
                                        disabled={applied ? true : false}
                                        onClick={() => handleApply()}
                                    >
                                        <W5text size="12px" color="#ffff">
                                            {applied && applyedDate
                                                ? `Applied on ${Moment(applyedDate).format(
                                                    'DD-MM-YYYY hh:mm:ss'
                                                )}`
                                                : 'Apply for this job'}
                                        </W5text>
                                    </ApplyButton>
                                    ) : ''
                            )}
                        </div>
                        <Modal
                            onHide={() => setCandidateModal(false)}
                            show={candidateModel}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            scrollable
                        >
                            <CandidateDetails
                                job={job}
                                setCandidateModal={setCandidateModal}
                                setLoading={setLoading}
                                setApplied={setApplied}
                            />
                        </Modal>
                        <Modal
                            onHide={() => setEmailVerify(false)}
                            show={emailVerify}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            scrollable
                        >
                            <EmailConfirm
                                currentUser={currentUser}
                                setEmailVerify={setEmailVerify}
                            />
                        </Modal>
                    </Row>
                </Body>
            </Section>
            <ReferralModal
                job={job}
                show={showReferralModal}
                onHide={() => setShowReferralModal(false)}
                onShow={() => setShowReferralModal(true)}
                setErrorCreatingSubmission={(data) => {
                    setErrorCreatingSubmission(data)
                }}
                setAlertApplyForJob={(data) => {
                    setAlertApplyForJob(data)
                }}
            />
        </Wrapper>
    )
}

export default JobDescriptionPage
