import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from '../shared/Card'
import P from '../shared/P'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Table from '../shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import Select from 'react-select'
import { DisplayPagination } from '../styles/AdminDashboard.styled'
import SearchBar from '../../../common/SearchBar/SearchBar'
import { Button, Col, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import Util from '../../../../utils/util'
import styles from './styles/candidate.module.scss'
import SweetAlert from 'react-bootstrap-sweetalert'
import './styles/candidate.scss'
import ActionBar from '../shared/ActionBar'
import feather from 'feather-icons'

const CandidateManagement = (props) => {
    var active_page = window.sessionStorage.getItem('can_activePage')
    var type = window.sessionStorage.getItem('can_type_query')
    var query = window.sessionStorage.getItem('can_search_query')
    const [flag, setFlag] = useState(props.flag)
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const [candidates, setCandidates] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [totalCandidate,setTotalCandidate] = useState()
    const [currentCounts,setCurrentCounts] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [selectedType, setSelectedType]  = useState(type || 'true')
    const [selected, setSelected] = useState(type == null ? { value: 'true', label: 'Active' } :  type === 'true' ? {value: 'true', label: 'Active'} : { value: 'false', label: 'Passive' })
    const [bulkIds, setBulkIds] = useState([])
    console.log("🚀 ~ file: index.js ~ line 34 ~ CandidateManagement ~ bulkIds", bulkIds)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showTypeConfirmation, setShowTypeConfirmation] = useState(false)
    const [loading, setLoading] = useState(true)

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const multipleIdsFunc = value => {
        if(bulkIds.includes(value)){
            let arr = bulkIds.filter(item => item !== value)
            setBulkIds([...arr])
        }
        else
            setBulkIds([...bulkIds, value])
    }

    const filterOptions = [
        { value: 'true', label: 'Active' },
        { value: 'false', label: 'Passive' },
    ]

    const colourStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',marginLeft:'10px',border: 'none',minHeight:'40px' })
    };

    const handleSelectType = (selectedval) =>{
        setSearchTerm('')
        setActivePage(0)
        setBulkIds([])
        setSelectedType(selectedval.value)
        window.sessionStorage.setItem('can_type_query',selectedval.value)
        window.sessionStorage.setItem('can_search_query','')
    }

    const onChangeSearchTerm = (value) => {
        window.sessionStorage.setItem('can_search_query',value)
        setSearchTerm(value)
    }

    const deleteCandidate = async (id) => {
        const url = `/admin/candidates/${id}`
        await makeRequest(url, 'delete','', {
            createSuccessMessage: () => 'Candidate deleted successfully',
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: () => {
                setTimeout(()=>{
                    fetchData()
                },2000)
            },
        })
    }

    const destroyBulk = async () => {
        const url = `/admin/candidates/${bulkIds}`
        await makeRequest(url, 'delete','', {
            // createSuccessMessage: () => 'Candidates deleted successfully',
            createResponseMessage: () => {
                return {
                  message: 'Delete Successfully',
                  messageType: 'success',
                  loading: false,
                  autoClose: true,
              }
            },
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: () => {
                setBulkIds([])
                setShowRemoveConfirmation(false)
                setTimeout(()=>{
                    fetchData()
                },2000)
            },
        })
    }

    const changeCandidateTypeBulk = async () => {
        const url = '/admin/candidates/change_candidates_type_bulk.json'
        await makeRequest(url, 'get', {params: {ids : bulkIds, type: selectedType === 'true' ? false : true}},
            {
                contentType: 'application/json',
                loadingMessage: 'Updating...',
                // createSuccessMessage: () => 'Candidates update successfully',
                createResponseMessage: () => {
                    return {
                      message: 'Updated Successfully',
                      messageType: 'success',
                      loading: false,
                      autoClose: true,
                  }
                },
                onSuccess: () => {
                    setBulkIds([])
                    setShowTypeConfirmation(false)
                    setTimeout(()=>{
                        fetchData()
                    },2000)
                },
            })
    }

    const fetchData = () => {
        setLoading(true)
        window.sessionStorage.setItem('can_activePage',activePage)
        const url = `/admin/candidates.json`
        makeRequest(url, 'get', {
                params:
                    {
                        type: selectedType,
                        page: (activePage + 1),
                        keyword: searchTerm || ''
                    }
            }, {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setCandidates(
                    res.data.candidates.map((candidate) => ({
                        id: candidate.id,
                        first_name: candidate.first_name,
                        last_name: candidate.last_name,
                        name: candidate.full_name,
                        title: candidate.title,
                        email_address: candidate.email_address,
                        linkedin_profile_url: candidate.linkedin_profile_url?.replace('https://',''),
                        created_at: candidate.created_at,
                        avatar_url: candidate.avatar_url,
                        experiences: candidate.experiences
                    }))
                )
                setPageCount(res.data.meta.total_pages)
                setCurrentCounts(res.data.meta.current_count)
                setTotalCandidate(res.data.meta.candidate_count)
                setStartRecord((activePage * res.data.meta.per_page))
                setLoading(false)
            },
        })
    }

    useEffect(() => {
        feather.replace()
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,selectedType])

    return(
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Candidate Management
                </P>
            </div>
            <Row className='w-100 align-items-center'>
                <Col lg="4" md="4" sm="12" xs="12">
                {totalCandidate > 0 &&    
                    <DisplayPagination>
                        Displaying  {(startRecord == 0)?1:startRecord + 1} - {currentCounts} of  {totalCandidate} candidates
                    </DisplayPagination>
                }
                </Col>
                <Col lg="8" md="8" sm="12" xs="12" className='d-flex justify-content-end pb-2 p-0 filter_ui_fix'>
                    {/* <div className='d-flex'> */}
                        <Select
                            defaultValue={selected}
                            options={filterOptions}
                            onChange={handleSelectType}
                            placeholder={'Select Type'}
                            styles={colourStyles}
                        />
                        <SearchBar
                                placeholder="Search Candidate"
                                value={searchTerm}
                                onChange={(e) => onChangeSearchTerm(e.target.value)}
                                onEnterPressed={() =>
                                    activePage == 0
                                        ? fetchData()
                                        : setActivePage(0)
                                }
                                onButtonClick={() =>
                                    activePage == 0
                                        ? fetchData()
                                        : setActivePage(0)
                                }
                        />
                    {/* </div> */}
                </Col>
            </Row>
            {showRemoveConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={()=>destroyBulk()}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />
            )}
            {showTypeConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={`Yes, ${type === 'true' ? 'passive it!' : 'active it!'}`}
                    confirmBtnBsStyle="warning"
                    title="Are you sure?"
                    onConfirm={()=>changeCandidateTypeBulk()}
                    onCancel={()=>setShowTypeConfirmation(false)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />
            )}
            <Table
                multipleIdsFunc = {multipleIdsFunc}
                columNames={[
                    {
                        name: '#',
                        field: 'id',
                        editable: true,
                        type: 'checkbox'
                    },
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Profile Avatar',
                        field: 'avatar',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Name',
                        field: 'name',
                        editable: true,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Title',
                        field: 'title',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Email Address',
                        field: 'email_address',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Linkedin Profile',
                        field: 'linkedin_profile_url',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Created On',
                        field: 'created_at',
                        editable: true,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Options',
                        field: 'options',
                        editable: false,
                        type: 'text',
                    },
                ]}
                rowValues={candidates.map((o) => ({
                    ...o,
                    created_at: moment(o.created_at).format('MMM DD, YYYY'),
                    avatar: o.avatar_url !== "N/A" ? <Image style={{borderRadius: '10px'}} width={75} src={o.avatar_url} /> : 
                    <div className={styles.candidateAvtarImage}>{ (o.first_name) ? (o?.first_name && Util.getFirstCharater(o?.first_name)) + (o?.last_name && Util.getFirstCharater(o?.last_name) ) : (o?.email && Util.getFirstCharater(o?.email) ) }</div>
                }))}
                showEditOption
                activePage={activePage}
                goToEditPage={(id) =>
                    (window.location.href = '/admin/candidates/' + id)
                }
                deleteAction={deleteCandidate}
                loading={loading}
            />
            { pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
            {bulkIds && bulkIds.length > 0 && (
              <ActionBar
                data={bulkIds}
                setBulkIds={setBulkIds}
              >
                <OverlayTrigger
                  placement='top'
                  overlay={
                      <Tooltip>{selectedType === 'true' ? `Active to Passive (${bulkIds.length})`  : `Passive to Active (${bulkIds.length})` }</Tooltip>
                  }
                >
                  <a className='mt-auto mb-auto cursor-pointer' onClick={()=>setShowTypeConfirmation(true)}><i className='text-light  mr-3' data-feather="repeat"></i></a>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='top'
                  overlay={
                      <Tooltip>Remove ({`${bulkIds.length}`})</Tooltip>
                  }
                >
                  <a className='mt-auto mb-auto cursor-pointer' onClick={()=>setShowRemoveConfirmation(true)}><i className='text-light  mr-3' data-feather="trash-2"></i></a>
                </OverlayTrigger>
              </ActionBar>
            )}
        </Card>
    );
}
export default CandidateManagement