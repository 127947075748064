import React from 'react'

import TextInput from '../../common/TextInput/TextInput'
import styles from './styles/Signup.module.scss'
import { FormWrapper } from './styles/Signup.styled'

function UserForm() {
  return (
    <FormWrapper>
      <TextInput
        className={styles.fullwidthInput}
        label="Email Address"
        name="email"
        type="email"
        width="28rem"
      />
      <TextInput
        className={styles.fullwidthInput}
        label="Password"
        name="password"
        type="password"
        width="28rem"
      />
    </FormWrapper>
  )
}

export default UserForm
