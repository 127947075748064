import React from 'react';
import { makeRequest } from '../../components/common/RequestAssist/RequestAssist';
let initialState = {
    ...initialState,
    ...{
        candidate: null,
        id: '',
        email_address: '',
        phone_number: '',
        first_name: '', 
        last_name: '', 
        industry: '', 
        linkedin_profile_url: '', 
        title: '',
        experiences: [],
        deleteExperienceId: [],
        active: ''
    }
}
const reducer = (state, action) => {
    const {type, value, id } = action
    switch(type) {
        case 'update-candidate':
            const formData = new FormData()
            for (var key in value) {
                if(key === 'experiences')
                    formData.append(`candidate[${key}]`,JSON.stringify(value[key]))
                else
                    formData.append(`candidate[${key}]`, value[key])
            }
        
            const url = `/agencies/candidates/${id}`
            sendRequest(formData,url)
            return {...state, ...value}
        default:
            return state
    }
}

async function sendRequest(formData, url) {
    await makeRequest(url, 'put', formData, {
      contentType: 'multipart/form-data',
      loadingMessage: 'Submitting...',
      createResponseMessage: () => {
        return {
          message: 'Updated Successfully',
          messageType: 'success',
          loading: false,
          autoClose: true,
      }
      },
      onSuccess: () => {
        // window.location.reload()
      }
     })
  }

  // context for candidate admin page
const AgencyCandidateContext = React.createContext(null)

export {initialState, reducer, AgencyCandidateContext}