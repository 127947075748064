import React, { useEffect, useState, useContext, useRef } from 'react'
import feather from 'feather-icons'
import { Row, Col, Alert, Button, Dropdown, Tabs, Tab, Nav, Image, Spinner } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import { nanoid } from 'nanoid'
import _ from 'lodash'
import styles from './styles/CandidateInfoPanel.module.scss'
import ResumeIcon from '../../../../assets/images/icons/resume-icon.svg'
import GithubIcon from '../../../../assets/images/icons/github-icon.svg'
import LinkedinIcon from '../../../../assets/images/icons/linkedin-icon.svg'
import UrlIcon from '../../../../assets/images/icons/url-link-icon.svg'
import PlusIcon from '../../../../assets/images/icons/plus-icon-v2.svg'
import PhoneIcon from '../../../../assets/images/icons/phone-icon.svg'
import MailIcon from '../../../../assets/images/icons/mail-icon.svg'
import AddNote from '../AddNote/AddNote'
import { makeRequest } from '../RequestAssist/RequestAssist'
import { StoreDispatchContext } from '../../../stores/JDPStore'
import './styles/CandidateInfoPanel.scss'
import { useOnClickOutside } from '../ScheduleInterviwes/ScheduledTable/SheduledTableBody'
import ResumeDragDrop from '../ResumeDragDrop/ResumeDragDrop'
import Select from 'react-select'
import { capitalize, onCopyText } from '../../../utils'
import styled from 'styled-components'
// import FileViewer from 'react-file-viewer';

function isPhoneValid(phone_number) {
  if (phone_number?.trim() === '') return true

  const regex = /^[+][0-9]{10,13}$/
  const regex_reg = /^[0-9]{10,14}$/
  if (regex.test(phone_number?.trim())) {
    return true
  } else if (regex_reg.test(phone_number?.trim())) {
    return true
  } else {
    let temp_phone = (phone_number?.trim()).replace(/\D/g, "")
    if (regex.test(temp_phone?.trim()) || regex_reg.test(temp_phone?.trim())) {
      return true
    }
  }
  return false
}

function isEmailValid(email) {
  if (email === '' || email == null || email == undefined) return false
  const email_regex = /^\S+@\S+[\.][0-9a-z]+$/
  return email_regex.test(email)
}

function isFirstNameValid(first_name) {
  console.log(first_name)
  if (first_name === '' || first_name == null || first_name == undefined) return false
  const first_regex = /^[a-zA-Z'`._\- ]+$/
  return first_regex.test(first_name)
}


function isLastNameValid(last_name) {
  if (last_name === '' || last_name == null || last_name == undefined) return false
  const first_regex = /^[a-zA-Z'`._\- ]+$/
  return first_regex.test(last_name)
}

function isYearValid(year) {
  if (year === '' || year == null || year == undefined) return false
  const first_regex = /^[12][0-9]{3}$/
  return first_regex.test(year)
}

const allStages = [
  { id: 'recruitor_screen', label: 'Recruiter Screened' },
  { id: 'submitted', label: 'Submitted' },
  // { id: 'first_interview', label: 'First interview' },
  // { id: 'second_interview', label: 'Second interview' },
  // { id: 'offer', label: 'Offer' },
  // { id: 'reject', label: 'Archive' },
]

const source = [
  { id: "sign_in", label: "Registration" },
  { id: "incoming_mail", label: 'Incoming Mail' },
  { id: "linkedin", label: "Linkedin" },
  { id: "salesql_phone", label: "Phone" },
  { id: "salesql_email", label: "Email" },
  { id: "applicant", label: "Applicant" },
  { id: "indeed", label: "Indeed" },
  { id: "bulk_upload", label: "Bulk Upload" },
  { id: "zoom_info", label: "Zoom Info" },
  { id: 'direct', label: "Direct" },
  { id: 'ziprecruiter', label: "ZipRecruiter" }
]

const fileTypes = [
  'application/msword',
  'application/pdf',
  'application/docx',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

export const DatePicker = styled.input`
appearance: none;
position: relative;
-webkit-appearance: none;
color: #4a5489;
font-size: 14px;
border: ${({ isValid }) => (!isValid ? 'none' : '1px solid red')};
outline: none;
height: 30px;
background: #f5f7ff;
padding: 7px 5px;
display: inline-block !important;
visibility: visible !important;

::-webkit-calendar-picker-indicator {
    color: #4a5489;
    border: none;
}
::-webkit-clear-button {
    display: none;
}
::-webkit-inner-spin-button {
    display: none;
}
&:focus {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
`

function CandidateInfoPanel({
  user,
  candidate,
  closeFunc,
  showUploadCandidatePanel,
  handleScheduleButtonClick,
  page,
  setPage,
  pageCount,
  jobId,
  currentOrganization,
  allowCandidateUpload,
  setApiCallFrom,
  memberOrganization,
  recruitersList,
  jobStatus,
  candidates,
  showCandidateModal = true,
  listEmailSendCandidate = []
}) {
  const selectCurrencyRef = useRef();
  const { dispatch, state } = useContext(StoreDispatchContext)
  const [notes, setNotes] = useState([])
  const [stageTransitions, setStageTransitions] = useState([])
  const [feedbacks, setFeedbacks] = useState([])
  const [candidateSubmited, setCandidateSubmited] = useState(false)
  const [errorFetchingCandidate, setErrorFetchingCandidate] = useState(null)
  const [resume, setResume] = useState(null)
  const [resumeUrl, setResumeUrl] = useState(null)
  const [noteAdded, setNoteAdded] = useState(false)
  const [stageTransitionsAdded, setstageTransitionsAdded] = useState(false)
  const [experience, setExperience] = useState([])
  const [education, setEducation] = useState([])
  const [loading, setLoading] = useState(false)
  const [manually, setManually] = useState(false)
  const [nameEditable, setNameEditable] = useState(false)
  const [showWishList, setShowWishList] = useState(true)
  const [isCardinalMember, setIsCardinalMember] = useState(false)
  const [currencies, setCurrencies] = useState([])
  const pathname = window.location.pathname?.split('/')
  const airec = pathname[1]
  const search = location.search
  const pageName = new URLSearchParams(search).get('page')
  const [candidateUploadData, setCandidateUploadData] = useState({
    name: '',
    title: '',
    company: '',
    location: '',
    linkedin_profile_url: '',
    github_url: '',
    school: '',
    experiences: '',
    job_experiences: [],
    educations: [],
    skills: [],
    emails: [''],
    phone_numbers: [''],
    phone_number_filtered: [''],
    links: [],
    files: [],
    stage: 'submitted',
    source: 'linkedin',
    summary: '',
    recruiterId: user?.id,
    salary: '',
    currency: '',
    parse_resume_json: '',
    colour_id: '',
    highlight: ''
  })
  const [validationErrors, setValidationErrors] = useState({})
  const [isEditable, setIsEditable] = useState(false)
  const [defaultActiveKey, setDefaultActiveKey] = useState('upload-resume-tab')
  // UPLOAD RESUME CODE
  const [bulkResume, setBulkResume] = useState([])
  const [resumeloading, setResumeLoading] = useState(false)
  const [multiClick, setMultiClick] = useState(false)
  const [upcomingInterviews, setUpcomingInterviews] = useState(false)
  const handleResumeFiles = (files) => {
    setBulkResume([...files])
    setValidationErrors({})
  }

  const handleOnRemoveResumes = (removeIndex) => {
    const newResumes = bulkResume.filter(
      (_, index) => removeIndex !== index
    )
    setBulkResume(newResumes)
    setValidationErrors({})
  }

  const generateBlobUrl = (file) => {
    try {
      return (URL.createObjectURL(file))
    } catch (error) {
      console.log('Not able to genrate resume blob path')
      return (file)
    }
  };

  const handleSubmitResume = async () => {
    setValidationErrors({})
    if (bulkResume.length <= 0) {
      setValidationErrors({
        ...validationErrors,
        resume: 'Please Upload Resume files.',
      })
      return
    }
    if (!fileTypes?.includes(bulkResume[0]?.type)) {
      setValidationErrors({
        ...validationErrors,
        resume: 'Please check the format of file.',
      })
      return
    }
    if (Object.values(validationErrors).length === 0) {
      setResumeLoading(true)
      setErrorFetchingCandidate('')
      const url = '../../people/get_resume_data'
      const formData = new FormData()
      formData.append('resume_file[resume]', bulkResume[0])
      const responce = await makeRequest(url, 'post', formData, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
        createResponseMessage: (responce) => {
          return {
            messageType: responce?.type,
            message: responce?.msg,
            loading: false,
            autoClose: true,
          }
        },
      }).then((res) => {
        let expArr = new Array()
        let currentCompany = new Array()
        if ((res?.data?.data?.experiences).length > 0) {
          (res?.data?.data?.experiences).map((value, index) => {
            let year_of_exp = value?.years_of_experience?.split('to')
            if (value?.years_of_experience.includes("october")) {
              if (year_of_exp[0]?.trim() == 'oc') {
                year_of_exp[0] = (year_of_exp[0]?.trim() == 'oc') ? "octo".concat(year_of_exp[1]) : year_of_exp[0];
                year_of_exp[1] = year_of_exp[2];
              } else if (year_of_exp[1]?.trim() == 'oc') {
                year_of_exp[1] = (year_of_exp[1]?.trim() == 'oc') ? "octo".concat(year_of_exp[2]) : year_of_exp[1]
              }
            }
            let jobExperience = {
              id: nanoid(),
              title: value?.designation || 'N/A',
              company_name: value?.company_worked_at || 'N/A',
              location: 'N/A',
              start_date: year_of_exp && year_of_exp[0]?.trim() || 'N/A',
              end_date: year_of_exp && year_of_exp[1]?.trim() || 'N/A',
              description: value?.experience_details || 'N/A',
            }
            expArr.push(jobExperience)
            if (year_of_exp != '' && (year_of_exp[1]?.trim())?.toLowerCase() === 'present') {
              currentCompany.push(jobExperience)
            }
          })
          setExperience(expArr)
        }
        let eduArr = new Array()
        if ((res?.data?.data?.education).length > 0) {
          (res?.data?.data?.education).map((value, index) => {
            let year_of_edu = value?.graduation_year?.split('to')
            let eduExperience = {
              id: nanoid(),
              school_name: value.university?.trim() || '',
              from: value.from_year?.trim() || '',
              to:  value.to_year?.trim() || '',
              degree: value.degree?.trim() || ''
            }
            eduArr.push(eduExperience)
          })
          setEducation(eduArr)
        }
        let linkedin_profile_url = createLinkedinUrl(res?.data?.data?.linkedin_url)
        let candidateDetails = {
          name: res?.data?.data?.name + ' ' + res?.data?.data?.name,
          first_name: res?.data?.data?.first_name,
          linkedin_profile_url: linkedin_profile_url,
          github_url: res?.data?.data?.github_url,
          last_name: res?.data?.data?.last_name,
          title: currentCompany[0]?.title || '',
          company: currentCompany[0]?.company_name || '',
          location: res?.data?.data?.location,
          school: (res?.data?.data?.school) ? (res?.data?.data?.school) : (res?.data?.data?.education[0]?.university || ''),
          experiences: expArr,
          job_experiences: expArr,
          educations: eduArr,
          skills: (res?.data?.data?.skills) ? (res?.data?.data?.skills) : "",
          emails: [res?.data?.data?.email],
          phone_numbers: [res?.data?.data?.phone_number],
          phone_number_filtered: [''],
          email_address_filtered: [''],
          links: res?.data?.data?.links,
          files: [bulkResume[0]],
          stage: 'submitted',
          source: 'linkedin',
          summary: res?.data?.data?.summary,
          recruiterId: user.id,
          salary: (res?.data?.data?.salary) ? (res?.data?.data?.salary) : '',
          currency: (res?.data?.data?.currency) ? (res?.data?.data?.currency) : '',
          parse_resume_json: res?.data?.data ? JSON.stringify(res?.data?.data) : ''
        }
        setCandidateUploadData({ ...candidateDetails })
        setDefaultActiveKey('add-candidate-tab')
        setResumeLoading(false)
        setShowWishList(false)
      }).catch((e) => {
           console.log(e)
        setErrorFetchingCandidate("Kindly upload a different file.")
        setResumeLoading(false)
      })
    }
  }
  // END UPLOAD RESUME CODE

  const ref = useRef();
  const selectcolourStyles = {
    control: styles => ({
      ...styles,
      borderColor: '#4c68ff',
      borderRadius: '0px',
      color: '#4c68ff',
      "&:hover": {
        borderColor: "#4c68ff"
      }
    }),
    singleValue: (provided, state) => {
      const color = '#4c68ff';
      const fontSize = '12px'
      return { ...provided, color, fontSize };
    }
  };

  useEffect(() => {
    dispatch({
      type: 'set_candidates',
      candidates: candidates
    })
  },[candidates])

  useEffect(() => {
    feather.replace()
    setDefaultActiveKey(showUploadCandidatePanel === true ? 'upload-resume-tab' : 'add-candidate-tab')
  }, [showUploadCandidatePanel])

  useEffect(() => {
    if (noteAdded && candidate) {
      axios
        .get(`/people/${candidate.id}.json?job_id=${jobId}`)
        .then((res) => {
          setNotes(res.data.notes)
        })
        .catch((e) => console.log(e.message))
      setNoteAdded(false)
    }
  }, [noteAdded])

  useEffect(() => {
    if (stageTransitionsAdded && candidate) {
      axios
        .get(`/people/${candidate.id}.json?job_id=${jobId}`)
        .then((res) => {
          setStageTransitions(res.data.stage_transitions)
          console.log(res.data);
        })
        .catch((e) => console.log(e.message))
      setstageTransitionsAdded(false)
    }
  }, [stageTransitionsAdded])

  useEffect(() => {
    setManually(false)
    if (candidate != null) {
      setLoading(true)
      let candidateDetails = {
        id: candidate.id,
        name: candidate.first_name + ' ' + candidate.last_name,
        first_name: candidate.first_name,
        linkedin_profile_url: candidate.linkedin_profile_url,
        github_url: candidate.github_url,
        last_name: candidate.last_name,
        title: candidate.title,
        company: candidate.company_names,
        location: candidate.location,
        school: candidate.school,
        experiences: candidate.experiences,
        job_experiences: [],
        skills: candidate?.skills ? candidate?.skills?.split(',') : "",
        emails: [candidate.email_address],
        phone_numbers: [candidate.phone_number],
        phone_number_filtered: (candidate?.phone_number_filtered) ? [candidate?.phone_number_filtered] : '',
        email_address_filtered: (candidate?.email_address_filtered) ? [candidate?.email_address_filtered] : '',
        links: candidate.links,
        files: [],
        summary: candidate.summary,
        educations: [],
        salary: candidate.salary,
        currency: candidate.currency,
        colour_id: candidate?.colour_id
      }

      axios
        .get(`/people/${candidate.id}.json`, { params: { ats: allowCandidateUpload, page: pageName == 'free' ? 'free' :'' } })
        .then((res) => {
          if (res?.data?.code == 'reached_max_limit') {
            window.location.reload()
          }
          let linkedin_profile_url = createLinkedinUrl(res.data.person.linkedin_profile_url)
          setCandidateUploadData({
            ...candidateDetails,
            phone_number_filtered:  [res?.data?.person?.phone_number_filtered] || [''],
            email_address_filtered:  [res?.data?.person?.email_address_filtered] || [''],
            links: res.data.person.links,
            linkedin_profile_url: linkedin_profile_url,
            github_url: (res.data.person.github_url == null || res.data.person.github_url == 'null') ? '' : res.data.person.github_url,
            job_experiences: res.data.job_experiences || [],
            educations: res.data.educations || [],
            highlight: (res.data.person.highlight_inc == null || res.data.person.highlight_inc == '') ? '' : res.data.person.highlight_inc,
          })
          setIsCardinalMember(res?.data?.ct_member || false)
          setExperience(res.data.job_experiences || [])
          setEducation(res.data.educations || [])
          setNotes(res.data.notes)
          setStageTransitions(res.data.stage_transitions)
          setFeedbacks(res.data.interview_feedbacks)
          setResume(res.data.resume)
          setResumeUrl(res.data.resume_url)
          // setIsEditable(res.data.is_editable)
          setIsEditable(true)
          setShowWishList(true)
          setUpcomingInterviews(res.data.upcoming_interviews)
          setLoading(false)
          // selectCurrencyRef.current.select.setValue({ value: res?.data?.person?.currency, label: res?.data?.person?.currency });
        })
        .catch((e) => {
          setErrorFetchingCandidate(e.message)
          setLoading(false)
        })
    }

    if (currencies?.length == 0) {
      axios.get(`/get_currency`)
        .then((res) => {
          setCurrencies(res.data.currencies)
        })
    }
  }, [candidate, candidateSubmited])
  /**
   *
   * @param {string} skills  Candidate skills
   * @returns  Array of strings
   */
  const formatSkills = (skills) => {
    if (!skills) {
      return []
    }
    if (Array.isArray(skills) && skills?.length) {
      return skills.filter((ele) => ele != '')
    } else if (typeof skills === 'string' && skills?.length) {
      return skills.split(',') ?? []
    }
    return []
  }

  const close = () => {
    closeFunc()
    setCandidateUploadData({
      first_name: '',
      last_name: '',
      title: '',
      company: '',
      location: '',
      school: '',
      experiences: '',
      job_experiences: [],
      educations: [],
      skills: [],
      emails: [''],
      phone_numbers: [''],
      phone_number_filtered: [''],
      email_address_filtered: [''],
      links: [],
      files: [],
      linkedin_profile_url: '',
      github_url: '',
      stage: 'submitted',
      source: 'linkedin',
      summary: '',
      salary: '',
      currency: 'USD'
    })
    setResume(null)
    setValidationErrors({})
  }
  (!allowCandidateUpload && showCandidateModal) && useOnClickOutside(ref, close);

  const displaySkills = (skills) => {
    if (!skills) return null
    return formatSkills(skills).map((skill, index) => (
      <div className={styles.skillBadge} key={`${index} ${skill}`}>
        <div>{skill}</div>
        {(isEditable || allowCandidateUpload) &&
          <a
            style={{ marginLeft: '10px', cursor: 'pointer' }}
            onClick={(event) => removeItem(event, skill, 'skills', index)}
          >
            x
          </a>
        }
      </div>
    ))
  }

  const displaySkillstext = (skills) => {
    if (!skills) return null
    return formatSkills(skills).map((skill, index) => (
      <div className={styles.skillBadge} key={`${index} ${skill}`}>
        <div>{skill}</div>
        {(isEditable || allowCandidateUpload) &&
          <a
            style={{ marginLeft: '10px', cursor: 'pointer' }}
          >
          </a>
        }
      </div>
    ))
  }

  useEffect(() => {
    if (state.nextCandidateId != null) {
      dispatch({
        type: 'show_candidate',
        candidate: state.candidates[state.nextCandidateId],
      })
      dispatch({
        type: 'remove_show_next_candidate',
      })
    }
  }, [state.candidates])

  const showNextCandidate = (currentlyDisplayedCandidate) => {
    const currentCandidateIndex = state.candidates.indexOf(
      currentlyDisplayedCandidate
    )
    if (
      page + 1 >= pageCount &&
      currentCandidateIndex + 1 >= state.candidates.length
    )
      return

    dispatch({
      type: 'hide_candidate',
    })
    if (currentCandidateIndex + 1 >= state.candidates.length) {
      dispatch({
        type: 'set_show_next_candidate',
        index: 0,
      })
      setApiCallFrom('pagination')
      setPage(page + 1)
    } else {
      const nextIndex = currentCandidateIndex + 1
      dispatch({
        type: 'show_candidate',
        candidate: state.candidates[nextIndex],
      })
    }
  }

  const showPrevCandidate = (currentlyDisplayedCandidate) => {
    const currentCandidateIndex = state.candidates.indexOf(
      currentlyDisplayedCandidate
    )
    if (page == 0 && currentCandidateIndex - 1 < 0) return
    dispatch({
      type: 'hide_candidate',
    })

    if (currentCandidateIndex - 1 < 0) {
      dispatch({
        type: 'set_show_next_candidate',
        index: 24,
      })
      setApiCallFrom('pagination')
      setPage(page - 1)
    } else {
      const nextIndex = currentCandidateIndex - 1
      dispatch({
        type: 'show_candidate',
        candidate: state.candidates[nextIndex],
      })
    }
  }

  const modifySkills = (skills, item) => {
    var skills = skills.concat(',', item).split(",")
    var updatedSkills = new Map(skills && skills.map(s => [s, s.toLowerCase()]));
    updatedSkills = [...updatedSkills.values()]
    return [...new Set(updatedSkills)].join()
  }

  const validateForm = (candidateData, editmode) => {
    setValidationErrors({})
    // const candidateData = { ...candidateUploadData }

    // Check FirstName Validation //
    if (!candidateData.first_name || candidateData.first_name?.trim().length === 0) {
      setValidationErrors({
        ...validationErrors,
        first_name: 'Please enter candidate first name.',
      })
      return false
    } else {
      if (!isFirstNameValid(candidateData.first_name || candidateData.first_name?.trim().length === 0)) {
        setValidationErrors({
          ...validationErrors,
          first_name: 'Please enter candidate first name in valid format.',
        })
        return false
      }
    }
    // Check First Name Validation //
    if (!isFirstNameValid(!candidateData.first_name?.trim() || candidateData.first_name?.length === 0)) {
      setValidationErrors({
        ...validationErrors,
        first_name: 'Please enter candidate first name.',
      })
      return false
    }


    // Check LastName Validation //
    if (!candidateData.last_name || candidateData.last_name?.trim().length === 0) {
      setValidationErrors({
        ...validationErrors,
        last_name: 'Please enter candidate last name.',
      })
      return false
    } else {
      if (!isLastNameValid(candidateData.last_name || candidateData.last_name?.trim().length === 0)) {
        setValidationErrors({
          ...validationErrors,
          last_name: 'Please enter candidate last name in valid format.',
        })
        return false
      }
    }

    // Check Last Name Validation //
    if (!isLastNameValid(!candidateData.last_name?.trim() || candidateData.last_name?.length === 0)) {
      setValidationErrors({
        ...validationErrors,
        last_name: 'Please enter candidate last name.',
      })
      return false
    }


    // Check Email Validation //
    if (candidateData.emails[candidateData?.emails?.length - 1]?.trim().length == 0) {
      setValidationErrors({
        ...validationErrors,
        emails: 'Please enter candidate email.',
      })
      return false
    } else {
      const emails_valid = candidateData.emails.some(
        (email) => isEmailValid(email) == false
      )
      if (emails_valid) {
        setValidationErrors({
          ...validationErrors,
          emails: 'Please enter email in valid format.',
        })
        return false
      }
    }

    // Check Phone Number Validation //
    if (candidateData?.phone_numbers[candidateData?.phone_numbers?.length - 1]?.trim().length === 0) {
      setValidationErrors({
        ...validationErrors,
        phone_numbers: 'Please enter phone number.',
      })
      return false
    } else {
      if (!isPhoneValid(candidateData?.phone_numbers[candidateData?.phone_numbers?.length - 1]?.trim())) {
        setValidationErrors({
          ...validationErrors,
          phone_numbers: 'Please enter valid phone number,</br> [ Either 10 digit number or + and up to 13 digit numbers ].',
        })
        return false
      }
    }

    // Check Company Validation //
    if (candidateData.company?.trim().length === 0) {
      setValidationErrors({
        ...validationErrors,
        company: 'Please enter candidate recent company.'
      })
      return false
    }

    // Check Position Validation //
    if (candidateData.title?.trim().length === 0) {
      setValidationErrors({
        ...validationErrors,
        title: 'Please enter candidate recent position.'
      })
      return false
    }

    // Check Github URL Validation //
    if (candidateData?.github_url != '') {
      let github_url = candidateData?.github_url
      let checkDomainExist = github_url?.indexOf("github.com/")
      if (checkDomainExist == -1) {
        setValidationErrors({
          ...validationErrors,
          github_url: 'Please enter valid github URL.',
        })
        return false
      } else if (checkDomainExist == 0) {
        let splitValue = github_url?.split('github.com/')
        if (splitValue && splitValue[1] === '') {
          setValidationErrors({
            ...validationErrors,
            github_url: 'Please enter valid github URL.',
          })
          return false
        }
      } else {
        let splitValue = github_url?.split('github.com/')
        if (splitValue && splitValue[0] == '') {
          setValidationErrors({
            ...validationErrors,
            github_url: 'Please enter valid github URL.',
          })
          return false
        }
      }
    }

    // Check linkedin URL Validation //
    if (candidateData.linkedin_profile_url != '' && typeof candidateData.linkedin_profile_url === 'string') {
      let linkedin_url = candidateData.linkedin_profile_url
      let checkDomainExist = linkedin_url.indexOf("linkedin.com/")
      if (checkDomainExist == -1) {
        setValidationErrors({
          ...validationErrors,
          linkedin_url: 'Please enter valid linkedin URL.',
        })
        return false
      } else if (checkDomainExist == 0) {
        let splitValue = linkedin_url.split('linkedin.com/')
        if (splitValue[1] === '') {
          setValidationErrors({
            ...validationErrors,
            linkedin_url: 'Please enter valid linkedin URL.',
          })
          return false
        }
      } else {
        let splitValue = linkedin_url.split('linkedin.com/')
        if (splitValue[0] == '') {
          setValidationErrors({
            ...validationErrors,
            linkedin_url: 'Please enter valid linkedin URL.',
          })
          return false
        }
      }
    } else {
      if (editmode === true) {
        if (!resume) {
          setValidationErrors({
            ...validationErrors,
            linkedin_url: 'Please enter linkedin URL or upload resume.'
          })
          return false
        }
      } else {
        if (candidateData.files.length === 0) {
          setValidationErrors({
            ...validationErrors,
            linkedin_url: 'Please enter linkedin URL or upload resume.'
          })
          return false
        }
      }
    }

    // Check Resume Upload Validation //
    if (candidateData.linkedin_profile_url == '') {
      if (candidateData.files.length == 0 && editmode === false) {
        setValidationErrors({
          ...validationErrors,
          linkedin_profile_url: 'Please enter linkedin URL or upload resume.',
        })
        return false
      } else {
        if (candidateData?.files?.length > 0 && !fileTypes?.includes(candidateData?.files[0]?.type)) {
          setValidationErrors({
            ...validationErrors,
            resume: `Please upload valid resume file.`
          })
          return false
        }
      }
    }

    // Check location Validation //
    if (candidateData.location?.trim().length === 0) {
      setValidationErrors({
        ...validationErrors,
        location: 'Please enter candidate location.'
      })
      return false
    }

    // Check school Validation //
    if (candidateData.school?.trim().length === 0) {
      setValidationErrors({
        ...validationErrors,
        school: 'Please enter candidate university.'
      })
      return false
    }

    if (!candidateData?.salary || (candidateData.salary == "null") ? '' : candidateData.salary?.length > 9) {
      setValidationErrors({
        ...validationErrors,
        salary: 'Please enter valid Desired Compensation Amount, it can be 1 to 9 digits only.',
      })
      return false
    }

    // Duplicate Education Validation
    if (candidateData.educations?.length !== 0) {
      let educations_status = true
      candidateData?.educations?.map((e, i) => {
        if (candidateData?.educations?.filter(s => s?.from === e?.from && s?.to === e?.to && s?.school_name?.trim() === e?.school_name?.trim() && s?.degree?.trim() === e?.degree?.trim()).length > 1) {
          setValidationErrors({
            ...validationErrors,
            error: 'Duplicate education details are not allowed'
          })
          educations_status = false
        }

        if (e?.school_name?.trim().length == 0) {
          setValidationErrors({
            ...validationErrors,
            error: 'Education University detail is invalid'
          })
          educations_status = false
        }

        if (e?.from?.trim().length != 0 && !isYearValid(e?.from?.trim())) {
          setValidationErrors({
            ...validationErrors,
            error: 'Education "FROM" Year detail is invalid, Only Year(YYYY) is required.'
          })
          educations_status = false
        }

        if (e?.to?.trim().length != 0 && !isYearValid(e?.to?.trim())) {
          setValidationErrors({
            ...validationErrors,
            error: 'Education "TO" Year detail is invalid, Only Year(YYYY) is required.'
          })
          educations_status = false
        }

        // if (isYearValid(e?.to?.trim()) && isYearValid(e?.from?.trim())) {
        //   if (e?.to < e?.from) {
        //     setValidationErrors({
        //       ...validationErrors,
        //       error: 'The year provided on the  "TO" of Education must be same or more than on the  "FROM".'
        //     })
        //     educations_status = false
        //   }
        // }

        // if (isYearValid(e?.to?.trim())) {
        //   if (moment(e?.to).year() > moment().year()) {
        //     setValidationErrors({
        //       ...validationErrors,
        //       error: 'Education "TO" year provided must be same or less than current year.'
        //     })
        //     educations_status = false
        //   }
        // }
        // if (isYearValid(e?.from?.trim())) {
        //   if (moment(e?.from).year() > moment().year()) {
        //     setValidationErrors({
        //       ...validationErrors,
        //       error: 'Education  "FROM" year provided must be same or less than current year.'
        //     })
        //     educations_status = false
        //   }
        // }
      })
      if (!educations_status) {
        return false
      }
    }

    // Check skills Validation //
    if (candidateData.skills?.length == 0) {
      setValidationErrors({
        ...validationErrors,
        skills: 'Minimum 1 skill is required.',
      })
      return false
    }
    // Check Job Exp Validation //
    if (candidateData.job_experiences?.length == 0) {
      // setValidationErrors({
      //   ...validationErrors,
      //   job_experiences: 'Minimum 1 job experience is required.',
      // })
      // return false
    } else {
      let job_exp_status = true
      let count_present = 0
      candidateData?.job_experiences?.map((e, i) => {

        if (e.location?.trim().length == 0) {
          setValidationErrors({
            ...validationErrors,
            location: 'Please enter location for the job experience',
          })
          job_exp_status = false
        }

        if (e.start_date?.trim().length == 0) {
          setValidationErrors({
            ...validationErrors,
            start_date: 'Please enter start date for the job experience',
          })
          job_exp_status = false
        }
        if(!e?.present) {
          if (e.end_date?.trim().length == 0) {
            setValidationErrors({
              ...validationErrors,
              end_date: 'Please enter end date for the job experience',
            })
            job_exp_status = false
          }
        }


        if (e.start_date?.trim().length > 0 && e.end_date?.trim().length > 0 && moment(e.end_date).isValid() == true && moment(e.start_date).isValid() == true) {
          if (moment(e.end_date).diff(moment(e.start_date)) <= 0) {
            setValidationErrors({
              ...validationErrors,
              job_experiences: "The end date added for Experience must be greater than the start date.",
            })
            job_exp_status = false
          }
        }

        if (e.end_date?.trim().length == 0 && e.present == false) {
          setValidationErrors({
            ...validationErrors,
            location: 'Invalid experience end date.',
          })
          job_exp_status = false
        }

        if (e.end_date?.trim().length > 0 && moment(e.end_date).isValid()) {
          if (moment(e.end_date).year() <= moment().year()) {
            if (moment(e.end_date).year() == moment().year() && moment(e.end_date).month() > moment().month()) {
              setValidationErrors({
                ...validationErrors,
                job_experiences: 'Invalid experience end date, It must not be after the current date.',
              })
              job_exp_status = false
            }
          } else {
            setValidationErrors({
              ...validationErrors,
              job_experiences: 'Invalid experience end date, It must not be after the current date.',
            })
            job_exp_status = false
          }
        }

        if (e.start_date?.trim().length > 0 && moment(e.start_date).isValid()) {
          if (moment(e.start_date).year() <= moment().year()) {
            if (moment(e.start_date).year() == moment().year() && moment(e.start_date).month() > moment().month()) {
              setValidationErrors({
                ...validationErrors,
                job_experiences: 'Invalid experience start date, It must not be after the current date.',
              })
              job_exp_status = false
            }
          } else {
            setValidationErrors({
              ...validationErrors,
              job_experiences: 'Invalid experience start date, It must not be after the current date.',
            })
            job_exp_status = false
          }
        }

        if (e.company_name?.trim().length == 0) {
          setValidationErrors({
            ...validationErrors,
            company_name: 'Please enter company for the job experience.',
          })
          job_exp_status = false
        }
        if (e.title?.trim().length == 0) {
          setValidationErrors({
            ...validationErrors,
            title: 'Please enter position title for job experience',
          })
          job_exp_status = false
        }
        // if (candidateData?.job_experiences?.filter(s => s?.company_name === e?.company_name &&  (new Date(s?.start_date).getTime() ===  new Date(e?.start_date).getTime()) &&( new Date(s?.end_date).getTime() ===  new Date(e?.end_date).getTime()) && s?.title === e?.title ).length > 1) {
        //   setValidationErrors({
        //     ...validationErrors,
        //     job_experiences: 'Two or more job experiences have the same value for title and company. Please correct it.',
        //   })
        //   job_exp_status = false
        // }

        if (e.present == true) {
          count_present = count_present + 1
        }
      })
      if (!job_exp_status) {
        return false
      }

      if (count_present > 1) {
        setValidationErrors({
          ...validationErrors,
          job_experiences: 'Mutiple Present, Invalid exprience data.',
        })
        return false
      }
    }

    return true
  }
  const createLinkedinUrl = (linkedin_url) => {
    if (linkedin_url != '') {
      let checkDomainExist = linkedin_url.indexOf("linkedin.com/")
      let splitValue = linkedin_url.split('linkedin.com/')
      if (checkDomainExist == 0) {
        if (splitValue[1] !== '') {
          return 'https://www.' + linkedin_url
        }
      } else if (checkDomainExist > 0) {
        if (splitValue[0].includes('https://') || splitValue[0].includes('http://')) {
          return linkedin_url
        } else {
          return 'https://' + linkedin_url
        }
      } else {
        return linkedin_url
      }
    } else {
      return ''
    }
  }

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(
      pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );


  const createGithubUrl = (github_url) => {
    if (github_url?.trim() != '' && (github_url?.trim())?.length > 0) {
      let checkDomainExist = github_url.indexOf("github.com/")
      let splitValue = github_url.split('github.com/')
      if (checkDomainExist == 0) {
        if (splitValue[1] !== '') {
          return 'https://www.' + github_url
        }
      } else if (checkDomainExist > 0) {
        if (splitValue[0].includes('https://') || splitValue[0].includes('http://')) {
          return github_url
        } else {
          return 'https://' + github_url
        }
      }
    } else {
      return ''
    }
  }

  const submitCandidateDataForm = async (event, item, controlName) => {
    setValidationErrors({})
    const modifiedCandidate = { ...candidateUploadData }
    let editmode = true
    if (validateForm(modifiedCandidate, editmode)) {
      event.preventDefault()
      const url = `/people/${candidateUploadData.id}.json`
      let linkedin_profile_url = createLinkedinUrl(modifiedCandidate?.linkedin_profile_url)
      let github_url = createGithubUrl(modifiedCandidate?.github_url)
      const formData = new FormData()
      formData.append('person[school]', (modifiedCandidate?.school) ? modifiedCandidate.school?.trim() : '')
      formData.append('person[location]', (modifiedCandidate?.location) ? modifiedCandidate.location?.trim() : '')
      formData.append('person[company_names]', (modifiedCandidate?.company) ? modifiedCandidate.company?.trim() : '')
      formData.append('person[title]', (modifiedCandidate?.title) ? modifiedCandidate.title?.trim() : '')
      formData.append('person[first_name]', (modifiedCandidate?.first_name) ? modifiedCandidate.first_name?.trim() : '')
      formData.append('person[last_name]', (modifiedCandidate?.last_name) ? modifiedCandidate.last_name?.trim() : '')
      formData.append('person[skills]', (modifiedCandidate?.skills) ? modifiedCandidate.skills.join(',') : '')
      formData.append('person[phone_number]', modifiedCandidate.phone_numbers[modifiedCandidate.phone_numbers.length - 1] || '')
      formData.append('person[email_address]', modifiedCandidate?.emails[modifiedCandidate?.emails.length - 1] || '')
      formData.append('person[experiences]', (modifiedCandidate?.job_experiences) ? JSON.stringify(modifiedCandidate.job_experiences) : '')
      formData.append('person[educations]', (modifiedCandidate?.educations) ? JSON.stringify(modifiedCandidate.educations) : '')
      formData.append('person[links]', (modifiedCandidate?.links) ? JSON.stringify(modifiedCandidate.links) : '')
      formData.append('person[linkedin_profile_url]', (linkedin_profile_url != '') ? linkedin_profile_url : modifiedCandidate?.linkedin_profile_url)
      formData.append('person[github_url]', (github_url != '') ? github_url : modifiedCandidate?.github_url)
      formData.append('person[summary]', modifiedCandidate?.summary?.trim() || '')
      formData.append('person[salary]', modifiedCandidate?.salary || '')
      formData.append('person[currency]', modifiedCandidate?.currency || '')
      formData.append('person[parse_resume_json]', modifiedCandidate.parse_resume_json || '')
      if (modifiedCandidate.files[0])
        formData.append('person[resume]', modifiedCandidate.files[0] || '')
      if (jobId) {
        formData.append('job_id', jobId)
      }
      const response = await makeRequest(url, 'put', formData, {
        contentType: 'multipart/form-data',
        loadingMessage: 'Submitting...',
        onError: (error) => {
          dispatch({
            type: 'update_candidate',
            candidate,
          })
          dispatch({
            type: 'show_candidate',
            candidate,
          })
        },
        createResponseMessage: (response) => {
          return {
            message: response.data
              ? response.data.message
              : 'Update successful',
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        },
      })
      dispatch({
        type: 'update_candidate',
        candidate,
      })
      if (response.data.success) {
        setCandidateUploadData({
          first_name: '',
          last_name: '',
          title: '',
          company: '',
          location: '',
          school: '',
          experiences: '',
          summary: '',
          job_experiences: [],
          skills: [],
          emails: [''],
          phone_numbers: [''],
          phone_number_filtered: [''],
          email_address_filtered: [''],
          educations: [''],
          links: [],
          files: [],
          linkedin_profile_url: '',
          github_url: '',
          stage: 'submitted',
          source: 'linkedin',
          salary: '',
          currency: 'USD',
          parse_resume_json: '',
          colour_id: '',
        })
        close()
        window.location.reload();
      }
    }
  }

  const submitSingleCandidate = async (event) => {
    setValidationErrors({})
    const candidateData = { ...candidateUploadData }
    if (validateForm(candidateData, false) && multiClick == false) {
      setMultiClick(true)
      event.preventDefault()
      const url = `/people/new/single_candidate`
      let linkedin_profile_url = createLinkedinUrl(candidateData?.linkedin_profile_url)
      let github_url = createGithubUrl(candidateData?.github_url)
      const formData = new FormData()
      if (candidate != null) {
        formData.append('id', candidateData.id)
      }
      formData.append('person[first_name]', candidateData?.first_name?.trim() || '')
      formData.append('person[last_name]', candidateData?.last_name?.trim() || '')
      formData.append('person[title]', candidateData?.title?.trim() || '')
      formData.append('person[company_names]', candidateData?.company?.trim() || '')
      formData.append('person[location]', candidateData?.location?.trim() || '')
      formData.append('person[school]', candidateData?.school?.trim() || '')
      formData.append(
        'person[experiences]',
        (candidateData?.job_experiences) ? JSON.stringify(candidateData.job_experiences) : ''
      )
      formData.append('person[educations]', (candidateData?.educations) ? JSON.stringify(candidateData.educations) : '')
      formData.append('person[skills]', (candidateData?.skills) ? candidateData.skills.join(',') : '')
      formData.append('person[email_address]', candidateData?.emails[0] || '')
      formData.append('person[links]', (candidateData?.links) ? JSON.stringify(candidateData.links) : '')
      formData.append('person[phone_number]', candidateData.phone_numbers[0] || '')
      formData.append('person[organization_id]', currentOrganization?.id || '')
      formData.append('person[linkedin_profile_url]', (linkedin_profile_url != '') ? linkedin_profile_url : candidateData?.linkedin_profile_url)
      formData.append('person[github_url]', (github_url != '') ? github_url : candidateData?.github_url)
      if (candidateData.files[0])
        formData.append('person[resume]', candidateData.files[0] || '')
      formData.append('job_id', jobId)
      formData.append('stage', candidateData?.stage || '')
      formData.append('user_id', candidateData?.recruiterId || '')
      formData.append('person[source]', candidateData?.source || '')
      formData.append('person[salary]', candidateData?.salary || '')
      formData.append('person[currency]', candidateData?.currency || '')
      formData.append('person[active]', false)
      formData.append('person[parse_resume_json]', candidateData.parse_resume_json || '')
      if (candidateData.stage == 'submitted') {
        formData.append('person[active]', true)
      }
      formData.append('person[summary]', candidateData?.summary?.trim() || '')
      const response = await makeRequest(url, 'post', formData, {
        contentType: 'multipart/form-data',
        loadingMessage: 'Uploading candidate',
        createResponseMessage: (response) => {
          setMultiClick(false)
          return {
            message: response.msg,
            messageType: response.type,
            loading: false,
            autoClose: true,
          }
        },
        onError: (error) => {
          dispatch({
            type: 'update_candidate',
            candidate,
          })
          dispatch({
            type: 'show_candidate',
            candidate,
          })
          setMultiClick(false)
        },
      })
      if (response.data.success) {
        setCandidateUploadData({
          first_name: '',
          last_name: '',
          title: '',
          company: '',
          location: '',
          school: '',
          experiences: '',
          summary: '',
          job_experiences: [],
          skills: [],
          emails: [''],
          phone_numbers: [''],
          phone_number_filtered: [''],
          email_address_filtered: [''],
          links: [],
          files: [],
          linkedin_profile_url: '',
          github_url: '',
          stage: 'submitted',
          source: 'linkedin',
          salary: '',
          currency: 'USD',
          colour_id: ''
        })
        setMultiClick(false)
        close()
        window.location.reload();
      }
    }
  }

  const findLable = (value, list) => {
    const newLable = list.find((item) => item.id == value)
    return newLable?.label
  }

  const showExperienceForm = () => {
    const newExperience = {
      id: nanoid(),
      title: '',
      company_name: '',
      location: '',
      start_date: '',
      end_date: '',
      description: '',
    }
    setCandidateUploadData({
      ...candidateUploadData,
      job_experiences: [...candidateUploadData.job_experiences, newExperience],
    })
  }

  const addNewExperience = (exp) => {
    setCandidateUploadData({
      ...candidateUploadData,
      job_experiences: candidateUploadData.job_experiences.map((e) => {
        if (e.id === exp.id) return exp
        else return e
      }),
    })
  }

  const removeExperience = (exp) => {
    setCandidateUploadData({
      ...candidateUploadData,
      job_experiences: candidateUploadData.job_experiences.filter(
        (e) => e.id != exp.id
      ),
    })
  }

  const showEducationForm = () => {
    const newEducation = {
      id: nanoid(),
      school_name: '',
      degree: '',
      from: '',
      to: '',
    }
    setCandidateUploadData({
      ...candidateUploadData,
      educations: [...candidateUploadData.educations, newEducation],
    })
  }

  const addNewEducation = (exp) => {
    setCandidateUploadData({
      ...candidateUploadData,
      educations: candidateUploadData.educations.map((e) => {
        if (e.id === exp.id) return exp
        else return e
      }),
    })
  }

  const removeEducation = (edu) => {
    setCandidateUploadData({
      ...candidateUploadData,
      educations: candidateUploadData.educations.filter(
        (e) => e.id != edu.id
      ),
    })
  }

  const addItem = (e, item, control) => {
    const regex = /^$|(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    if (item != '') {
      if (!regex.test(item) && control == 'links') {
        setValidationErrors({
          ...validationErrors,
          links: 'Please enter valid links url.'
        })
        return false
      }
      let links = [...candidateUploadData[control], item]
      if (control == "linkedin_profile_url" || control == "github_url") {
        setCandidateUploadData({
          ...candidateUploadData,
          [control]: item,
        })
      } else {
        setCandidateUploadData({
          ...candidateUploadData,
          [control]: [...new Set(links)],
        })
      }
    }
  }

  const removeItem = (event, item, control, index) => {
    event.preventDefault()
    setCandidateUploadData({
      ...candidateUploadData,
      [control]: candidateUploadData[control].filter((e, idx) =>
        e === item && idx == index ? false : true
      ),
    })
    if (candidate && control === "skills") {
      var skills = candidate.skills
      candidate.skills = skills.split(",").filter((e) => e !== item).join()
      // submitCandidateDataForm(event, '', control)
    }

    if (candidate && control === "links") {
      var links = candidate.links
      candidate.links = links.filter((e) => e !== item)
      // submitCandidateDataForm(event,'', control)
    }
  }

  const handleChange = (e) => {
    setValidationErrors({})
    setCandidateUploadData({
      ...candidateUploadData,
      [e.target.name]: e.target.value,
    })
  }

  const handleEmail = (event) => {
    const clone = candidateUploadData.emails.slice()
    clone[0] = event.target.value
    setValidationErrors({})
    setCandidateUploadData({
      ...candidateUploadData,
      emails: clone,
    })
  }
  const handleFirstName = (event) => {
    setValidationErrors({})
    setCandidateUploadData({
      ...candidateUploadData,
      first_name: event.target.value,
    })
  }

  const handleLastName = (event) => {
    setValidationErrors({})
    setCandidateUploadData({
      ...candidateUploadData,
      last_name: event.target.value,
    })
  }

  const handleLinkedinUrl = (event) => {
    setValidationErrors({})
    setCandidateUploadData({
      ...candidateUploadData,
      linkedin_profile_url: event.target.value,
    })
  }

  const handleGithubUrl = (event) => {
    setValidationErrors({})
    setCandidateUploadData({
      ...candidateUploadData,
      github_url: event.target.value,
    })
  }

  const handelPhoneNumber = (event) => {
    const clone = candidateUploadData.phone_numbers.slice()
    clone[0] = event.target.value
    setValidationErrors({})
    setCandidateUploadData({
      ...candidateUploadData,
      phone_numbers: clone,
    })
  }

  const updateCandidateData = (control, item) => {
    // console.log(control)
    // console.log(item)
    // setCandidateUploadData({
    //     ...candidateUploadData,
    //     company: "item",
    // })
  }

  const showItems = (control) => {
    const getHostname = (url) => {
      try {
        return new URL(url).hostname
      } catch (error) {
        return null
      }
    }

    function getIcon(item) {
      let icon

      switch (control) {
        case 'emails':
          icon = (
            <Image
              src={MailIcon}
              style={{
                width: '15px',
                height: '15px',
                marginRight: '15px',
              }}
            />
          )
          break
        case 'phone_numbers':
          icon = (
            <Image
              src={PhoneIcon}
              style={{
                width: '15px',
                height: '15px',
                marginRight: '15px',
              }}
            />
          )
          break
        case 'links':
          icon = (
            <Image
              src={
                getHostname(item) === 'linkedin.com'
                  ? LinkedinIcon
                  : getHostname(item) === 'github.com'
                    ? GithubIcon
                    : UrlIcon
              }
              style={{
                width: '15px',
                height: '15px',
                marginRight: '15px',
              }}
            />
          )
          break
        case 'files':
          icon = (
            <Image
              src={ResumeIcon}
              style={{
                width: '15px',
                height: '15px',
                marginRight: '15px',
              }}
            />
          )
          break
        default:
          break
      }

      return icon
    }
    return (
      <>
        {candidateUploadData[control].map((e, idx) => {
          if (
            (control === 'emails' || control === 'phone_numbers') &&
            idx == 0
          )
            return null
          return (
            <p key={idx} className={styles.urlText}>
              {getIcon(e)}
              <span
                style={{
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  overflowX: 'hidden',
                }}
              >
                <a href={e} target="_blank">{control === 'files' ? e.name : e}</a>
              </span>
              {(isEditable) &&
                <a
                  onClick={(event) => {
                    removeItem(event, e, control, idx)
                  }}
                >
                  <i
                    data-feather="x"
                    style={{
                      width: '10px',
                      height: '10px',
                      marginLeft: '15px',
                      color: 'red',
                      cursor: 'pointer',
                    }}
                  ></i>
                </a>
              }
            </p>
          )
        })}
      </>
    )
  }
  if (candidate || showUploadCandidatePanel) {
    return (
      <div
        ref={ref}
        selectCurrencyRef={selectCurrencyRef}
        className={`${styles.infoPanel} candidate-info-panel`}
      >
        <Row className="d-flex justify-content-between">
          <Col xs={12} lg={8} className="d-flex position-relative w-139">
            {candidate ? (
              <>
                {/* {(jobStatus != 'closed' && !upcomingInterviews && airec != 'weekly_recommendation') &&
                  <Button
                    onClick={(e) => {
                      dispatch({
                        type: 'update_schedule_modal_data',
                        data: {
                          candidate:
                            state.displayedCandidate,
                        },
                      })
                      dispatch({
                        type: 'hide_candidate',
                        candidate: null,
                      })
                      handleScheduleButtonClick()
                    }}
                    className={styles.topRowButton}
                  >
                    Schedule
                  </Button>
                } */}
                {/* <Button className={styles.topRowButton}>
                  Contact
                </Button> */}
                {(showWishList && jobStatus != 'closed' && allowCandidateUpload != 'weekly_recommendation') &&
                  <Button className={styles.topRowButton} title='Edit Candidate'
                    style={{ backgroundColor: '#339966' }}
                    onClick={() => setShowWishList(false)}>
                    Edit
                  </Button>
                }
                {(!showWishList && jobStatus != 'closed' && allowCandidateUpload) &&
                  <Button className={`${styles.topRowButton} cancelEditBtn`} title='Cancel Edit Candidate'
                    onClick={() => setShowWishList(true)}>
                    Cancel
                  </Button>
                }
                { (airec != 'weekly_recommendation') &&
                  <AddNote
                    candidate={candidate}
                    setNoteAdded={setNoteAdded}
                    jobId={jobId}
                  />
                }
              </>
            ) : (
              <p className={styles.uploadTitle}>
                Single Candidate Upload
              </p>
            )}
          </Col>
          <Col xs={12} lg={4} className="d-flex justify-content-end">
            {(candidate && showCandidateModal) && (
              <>
                <div
                  className={styles.circleButton}
                  onClick={() => showPrevCandidate(candidate)}
                  style={{
                    pointerEvents:
                      page == 0 &&
                        state?.candidates?.length > 0 &&
                        state?.candidates[0].id ===
                        candidate.id
                        ? 'none'
                        : 'all',
                  }}
                >
                  <i data-feather="chevron-left" />
                </div>
                <div
                  className={styles.circleButton}
                  onClick={() => showNextCandidate(candidate)}
                  style={{
                    pointerEvents:
                      page + 1 >= pageCount &&
                        state?.candidates?.slice()?.pop()
                          ?.id === candidate?.id
                        ? 'none'
                        : 'all',
                  }}
                >
                  <i data-feather="chevron-right" />
                </div>
              </>
            )}
            {showCandidateModal && (
              <div
              className={styles.circleButton}
              style={{ background: 'red', color: '#fff' }}
              onClick={close}
            >
              <i
                data-feather="x"
                style={{ width: '15px', height: '15px' }}
              />
            </div>
            )}
          </Col>
        </Row>

        <Tabs
          activeKey={defaultActiveKey}
          className={showUploadCandidatePanel ? "save-candidate-tabs" : "d-none"}
        >
          <Tab eventKey="upload-resume-tab" title="Upload Resume">
            {resumeloading ? (
              <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
                <div id="loading" />
              </div>
            ) : (
              <>
                <ResumeDragDrop
                  title={'resume'}
                  files={bulkResume}
                  handleOnRemoveFiles={handleOnRemoveResumes}
                  handleFiles={handleResumeFiles}
                  handleOnSubmit={handleSubmitResume}
                  validationErrors={validationErrors}
                />
                <hr className='hrBorder' />
                <a className={styles.noResumeskip} onClick={() => {
                  setDefaultActiveKey('add-candidate-tab')
                  setManually(true)
                }}>Click here to fill manually</a>
              </>
            )}
          </Tab>
          <Tab eventKey="add-candidate-tab" title="Candidate Details">
            <Row className={styles.contentRow} >
              <Col xs={12} lg={12} className={styles.contentRowLefColumn}>
                {candidate ? (
                  <>
                    <Row className="d-flex position-relative w-100">
                      <p className={styles.nameText}> Candidate Details </p>
                    </Row>
                    <Row className="d-flex position-relative w-100">
                      <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}>First Name*</p>
                        {!showWishList ?
                          <input
                            type="text"
                            class="form-control"
                            name="first_name"
                            required
                            className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['first_name']) ? 'expError' : ''}`}
                            value={candidateUploadData.first_name}
                            // onChange={handleChange}
                            onChange={(e) => {
                              handleFirstName(e)
                            }}
                            onBlur={(e) => {
                              // if (!isFirstNameValid(e.target.value))
                              // setValidationErrors({
                              //   ...validationErrors,
                              //   emails: 'Please enter first name in valid format.',
                              // })
                            }}
                          />
                          : <div className={`${styles.shadingtext} textClBlack`}>{candidateUploadData.first_name || '-'}</div>
                        }
                      </div>

                      <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}>Last Name*</p>
                        {!showWishList ?
                          <input
                            type="text"
                            class="form-control"
                            name="last_name"
                            required
                            className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['last_name']) ? 'expError' : ''}`}
                            value={candidateUploadData.last_name}
                            // onChange={handleChange}
                            onChange={(e) => {
                              handleLastName(e)
                            }}
                            onBlur={(e) => {
                              // if (!isLastNameValid(e.target.value))
                              //   setValidationErrors({
                              //     ...validationErrors,
                              //     emails: 'Please enter last name in valid format.',
                              //   })
                            }}
                          />
                          : <div className={`${styles.shadingtext} textClBlack`}>{candidateUploadData.last_name || '-'}</div>
                        }
                      </div>
                  { (pageName =='free' ? candidateUploadData?.colour_id == 2 : true) && <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}>Email*</p>
                        {!showWishList ?
                          <input
                            type="text"
                            name="email_address"
                            class="form-control"
                            required
                            value={candidateUploadData.emails[0]}
                            className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['email_address']) ? 'expError' : ''}`}
                            onChange={(e) => {
                              handleEmail(e)
                            }}
                            onBlur={(e) => {
                              if (!isEmailValid(e.target.value))
                                setValidationErrors({
                                  ...validationErrors,
                                  emails: 'Please enter email in valid format.',
                                })
                            }}
                          />
                          : <div className={`${styles.shadingtext} textClBlack`}>{ (candidateUploadData?.emails?.[0] && candidateUploadData?.emails?.[0] != '' && candidateUploadData?.emails?.[0] != null) ? ((isCardinalMember) ?  candidateUploadData.emails?.[0] : candidateUploadData.email_address_filtered?.[0]) : '-'}</div>
                        }
                      </div> }

                     {(pageName == 'free' ? candidateUploadData?.colour_id == 2 : true) && <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}>Phone Number*</p>
                        {!showWishList ?
                          <input
                            type="text"
                            name="phone_numbers"
                            placeholder="(xxx) xxx-xxxx "
                            className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['phone_numbers']) ? 'expError' : ''}`}
                            value={candidateUploadData.phone_numbers[0]}
                            onChange={(e) => handelPhoneNumber(e)}
                            onBlur={(e) => {
                              if (!isPhoneValid(e.target.value))
                                setValidationErrors({
                                  ...validationErrors,
                                  phone_numbers: 'Please enter valid phone number, </br> [ Either 10 digit number or + and up to 13 digit numbers ].',
                                })
                            }}
                          /> : <div className={`${styles.shadingtext} textClBlack`}>{  (candidateUploadData?.phone_numbers?.[0] && candidateUploadData?.phone_numbers?.[0] != '' && candidateUploadData?.phone_numbers?.[0] != null) ? ((isCardinalMember) ?  candidateUploadData.phone_numbers?.[0] : candidateUploadData.phone_number_filtered?.[0]) : '-' }</div>
                        }
                      </div> }
                      <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}> Recent Company*</p>
                        {!showWishList ?
                          <input
                            type="text"
                            name="company"
                            className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['company']) ? 'expError' : ''}`}
                            value={candidateUploadData.company}
                            onChange={handleChange}
                          /> : <div className={`${styles.shadingtext} textClBlack`} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{candidateUploadData.company || '-'}</div>
                        }
                      </div>

                      <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}> Recent Position*</p>
                        {!showWishList ?
                          <input
                            type="text"
                            name="title"
                            className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['title']) ? 'expError' : ''}`}
                            value={candidateUploadData.title}
                            onChange={handleChange}
                          /> : <div className={`${styles.shadingtext} textClBlack`} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} title={candidateUploadData.title}>{candidateUploadData.title || '-'}</div>
                        }
                      </div>

                      <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}>Github Profile</p>
                        {!showWishList ?
                          <input
                            type="text"
                            required
                            className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['github_url']) ? 'expError' : ''}`}
                            value={candidateUploadData.github_url}
                            onChange={(e) => {
                              handleGithubUrl(e)
                            }}
                          /> : <div className={`${styles.shadingtext} textClBlack`} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}><a href={candidateUploadData.github_url || '-'} target="_blank" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{candidateUploadData.github_url || '-'}</a></div>
                        }
                      </div>
                      <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}>Linkedin Profile</p>
                        {!showWishList ?
                          <input
                            type="text"
                            required
                            className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['linkedin_url']) ? 'expError' : ''}`}
                            value={candidateUploadData.linkedin_profile_url}
                            onChange={(e) => {
                              handleLinkedinUrl(e)
                            }}
                          /> : <div className={`${styles.shadingtext} textClBlack`} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}><a href={candidateUploadData.linkedin_profile_url || '-'} target="_blank" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{candidateUploadData.linkedin_profile_url || '-'}</a></div>
                        }
                      </div>
                      <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}>Location*</p>
                        {!showWishList ?
                          <input
                            type="text"
                            name="location"
                            className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['location']) ? 'expError' : ''}`}
                            value={candidateUploadData.location}
                            onChange={handleChange}
                          /> : <div className={`${styles.shadingtext} textClBlack`} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} title={candidateUploadData.location}>{candidateUploadData.location || '-'}</div>
                        }
                      </div>

                      <div className="col-4 mb-3">
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}>University*</p>
                        {!showWishList ?
                          <input
                            type="text"
                            name="school"
                            className={`${styles.editInput} ${(candidateUploadData.school === 'N/A') ? 'expError' : ''} ${(validationErrors['school']) ? 'expError' : ''}`}
                            value={(candidateUploadData.school === 'N/A') ? '' : candidateUploadData.school}
                            onChange={handleChange}
                          /> : <div title={candidateUploadData.school} className={`${styles.shadingtext} textClBlack`} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{candidateUploadData.school || '-'}</div>
                        }
                      </div>

                      <div className="col-8 mb-3" >
                        <p style={{ marginBottom: '-1px', color: '#2e4ae4', fontWeight: '600' }}>Desired Compensation Amount</p>
                        {!showWishList ?
                          <div className="d-flex position-relative w-100">
                            <div className="col-4 mb-3" style={{ paddingLeft: '0px' }}>
                              <Select
                                styles={selectcolourStyles}
                                defaultValue={{ value: candidateUploadData?.currency, label: candidateUploadData?.currency }}
                                isSearchable={true}
                                ref={selectCurrencyRef}
                                name="currency-select-box"
                                options={currencies}
                                onChange={({ value, label }) => {
                                  setCandidateUploadData(
                                    {
                                      ...candidateUploadData,
                                      currency: value,
                                    }
                                  );
                                }}
                                menuPosition='fixed'
                              />
                            </div>
                            <div className="col-8 mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                              <input
                                type="text"
                                name="salary"
                                min="1"
                                max="9"
                                placeholder="Desired Compensation Amount"
                                //  className={`${styles.editInput} form-control`}
                                className={`${styles.editInput} form-control ${styles.editCandidateName} ${(validationErrors['salary']) ? 'expError' : ''}`}
                                value={(candidateUploadData.salary === 'N/A') ? '' : candidateUploadData.salary}
                                onChange={handleChange}
                              />
                            </div>
                          </div> : <div className={`${styles.shadingtext} textClBlack`} >{candidateUploadData.currency || ''}  {candidateUploadData.salary || '-'}</div>
                        }
                      </div>
                    </Row>

                    <Row>
                      <Col lg='6' md='6' sm='12' xs='12' className='p-0 pr-3'>
                        <div
                          className={`${styles.indent} ${styles.tabContentText}`}
                        >
                          <p className={styles.contactText}>Links <span className={styles.fileType}> (Links)</span></p>
                          {showItems('links')}

                          <div
                            style={{ cursor: 'pointer', marginTop: '10px' }}
                          >
                            {!showWishList ?
                              <p>{(isEditable || allowCandidateUpload) && (
                                <AddButton
                                  className={`${styles.editInput} ${(candidateUploadData.links === 'N/A') ? 'expError' : ''} ${(validationErrors['links']) ? 'expError' : ''}`}
                                  title="Add link"
                                  controlName="links"
                                  update={addItem}
                                  candidate={candidate}
                                />
                              )}</p>
                              : ''}
                          </div>
                        </div>
                      </Col>
                      <Col lg='6' md='6' sm='12' xs='12' className='p-0' style={{ marginLeft: '-11px' }}>
                        {!showWishList ? <p className={styles.contactText}>Resume <span className={styles.fileType}> (.pdf/.docx)</span></p> : ""}
                        <div className="row" >
                          {!showWishList ?
                            <div className="col pl-1" >
                              {resume && (!manually) && (
                                <div className="d-flex">
                                  <Image
                                    src={ResumeIcon}
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      marginRight: '15px',
                                    }}
                                  ></Image>
                                  <span
                                    style={{
                                      display: 'inline-block',
                                    }}
                                    className={styles.urlText}
                                  >

                                    {memberOrganization ?
                                      <a href={resumeUrl} download target="_blank">{(resume.filename) ? resume.filename : resume.name}</a>
                                      : <span>{(resume.filename) ? resume.filename : resume.name}</span>
                                    }

                                  </span>

                                </div>
                              )}
                              <p>{(allowCandidateUpload || isEditable) && (
                                <AddButton
                                  title="Upload file"
                                  controlName="files"
                                  update={addItem}
                                  setResume={setResume}
                                />
                              )}</p>
                            </div> : ''}
                        </div>
                      </Col>
                    </Row>
                  </>

                ) : (
                  <>
                    <Row style={{ marginLeft: '-18px' }}>
                      <div class="col">
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>First Name*</p>
                        <input
                          type="text"
                          class="form-control"
                          name="first_name"
                          required
                          className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['first_name']) ? 'expError' : ''}`}
                          value={candidateUploadData.first_name}
                          // onChange={handleChange}
                          onChange={(e) => {
                            handleFirstName(e)
                          }}
                          onBlur={(e) => {
                            // if (!isFirstNameValid(e.target.value))
                            //   setValidationErrors({
                            //     ...validationErrors,
                            //     emails: 'Please enter first name in valid format.',
                            //   })
                          }}
                        />
                      </div>
                      <div class="col">
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>Last Name*</p>
                        <input
                          type="text"
                          class="form-control"
                          name="last_name"
                          required
                          className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['last_name']) ? 'expError' : ''}`}
                          value={candidateUploadData.last_name}
                          // onChange={handleChange}
                          onChange={(e) => {
                            handleLastName(e)
                          }}
                          onBlur={(e) => {
                            // if (!isLastNameValid(e.target.value))
                            //   setValidationErrors({
                            //     ...validationErrors,
                            //     emails: 'Please enter last name in valid format.',
                            //   })
                          }}
                        />
                      </div>
                      <div class="col">
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>Email*</p>
                        <input
                          type="text"
                          name="email_address"
                          class="form-control"
                          required
                          value={candidateUploadData.emails[0]}
                          className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['email_address']) ? 'expError' : ''}`}
                          onChange={(e) => {
                            handleEmail(e)
                          }}
                          onBlur={(e) => {
                            if (!isEmailValid(e.target.value))
                              setValidationErrors({
                                ...validationErrors,
                                emails: 'Please enter email in valid format.',
                              })
                          }}
                        />
                      </div>
                    </Row>
                    <Row style={{ marginLeft: '-18px' }}>
                      <div class="col">
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>Phone Number*</p>
                        <input
                          type="text"
                          name="phone_numbers"
                          placeholder="(xxx) xxx-xxxx "
                          className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['phone_numbers']) ? 'expError' : ''}`}
                          value={candidateUploadData.phone_numbers[0]}
                          onChange={(e) => handelPhoneNumber(e)}
                          onBlur={(e) => {
                            if (!isPhoneValid(e.target.value))
                              setValidationErrors({
                                ...validationErrors,
                                phone_numbers: 'Please enter valid phone number, </br> [ Either 10 digit number or + and up to 13 digit numbers ].',
                              })
                          }}
                        />
                      </div>
                      <div class="col">
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>Recent Company*</p>
                        <input
                          type="text"
                          name="company"
                          className={`${styles.editInput} ${styles.editCandidateName} ${(candidateUploadData.company === 'N/A') ? 'expError' : ''} ${(validationErrors['company']) ? 'expError' : ''}`}
                          value={candidateUploadData.company}
                          onChange={handleChange}
                        />
                      </div>
                      <div class="col">
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>Recent Position*</p>
                        <input
                          type="text"
                          name="title"
                          className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['title']) ? 'expError' : ''}`}
                          value={candidateUploadData.title}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>

                    <Row style={{ marginLeft: '-18px' }}>
                      <div class="col">
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>Github Profile</p>
                        <input
                          type="text"
                          required
                          className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['github_url']) ? 'expError' : ''}`}
                          value={candidateUploadData.github_url}
                          onChange={(e) => {
                            handleGithubUrl(e)
                          }}
                        />
                      </div>
                      <div class="col">
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>LinkedIn Profile</p>
                        <input
                          type="text"
                          required
                          className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['linkedin_url']) ? 'expError' : ''}`}
                          value={candidateUploadData.linkedin_profile_url}
                          onChange={(e) => {
                            handleLinkedinUrl(e)
                          }}
                        />
                      </div>
                      <div class="col">
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>Location*</p>
                        <input
                          type="text"
                          name="location"
                          className={`${styles.editInput} ${styles.editCandidateName} ${(validationErrors['location']) ? 'expError' : ''}`}
                          value={candidateUploadData.location}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div class="col" style={{ paddingRight: '0px', marginLeft: '-16px' }}>
                        <p style={{ marginBottom: '-1px', color: '#4f67ec', fontWeight: '700', fontSize: '14px' }}>University*</p>
                        <input
                          type="text"
                          name="school"
                          className={`${styles.editInput} ${(candidateUploadData.school === 'N/A') ? 'expError' : ''} ${(validationErrors['school']) ? 'expError' : ''}`}
                          value={(candidateUploadData.school === 'N/A') ? '' : candidateUploadData.school}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>
                    <Row>
                      {!candidate && (
                        <Col lg='6' md='6' sm='12' xs='12' className='p-0 pr-3' >
                          <span className={styles.labelText}>Source</span>
                          <Dropdown className={`${styles.stageDopDown}`}>
                            <Dropdown.Toggle
                              className={`${styles.stageToggle}`}
                            >
                              {findLable(
                                candidateUploadData.source, source
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {source.sort((a, b) => a.label > b.label ? 1 : -1).map((source, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onSelect={(e) => {
                                    setCandidateUploadData({
                                      ...candidateUploadData,
                                      source: e,
                                    })
                                  }}
                                  value={source.id}
                                  eventKey={source.id}
                                >
                                  {source.label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      )}
                      <Col lg='6' md='6' sm='12' xs='12' className='p-0' style={{ marginLeft: '-11px' }}>
                        <p className={styles.labelText}>Desired Compensation Amount</p>
                        <Row>
                          <Col lg='5' md='5' sm='12' xs='12' className='p-0' style={{ marginRight: '-1px' }}>
                            <Select
                              styles={selectcolourStyles}
                              defaultValue={{ value: candidateUploadData?.currency, label: candidateUploadData?.currency }}
                              isSearchable={true}
                              ref={selectCurrencyRef}
                              name="currency-select-box"
                              options={currencies}
                              onChange={({ value, label }) => {
                                setCandidateUploadData(
                                  {
                                    ...candidateUploadData,
                                    currency: value,
                                  }
                                );
                              }}
                              menuPosition='fixed'
                            />
                          </Col>
                          <Col lg='7' md='7' sm='12' xs='12' className='p-0'>
                            <input
                              type="text"
                              name="salary"
                              placeholder="Desired Compensation Amount"
                              // className={`${styles.editInput} form-control`}
                              className={`${styles.editInput} form-control ${styles.editCandidateName} ${(validationErrors['salary']) ? 'expError' : ''}`}
                              value={(candidateUploadData.salary === 'N/A') ? '' : candidateUploadData.salary}
                              onChange={handleChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='6' md='6' sm='12' xs='12' className='p-0 pr-3'>
                        <span className={styles.labelText}>Stage</span>
                        <Dropdown
                          className={`${styles.stageDopDown}`} style={{ marginBottom: '5px' }}
                        >
                          <Dropdown.Toggle
                            className={`${styles.stageToggle}`}
                          >
                            {findLable(
                              candidateUploadData.stage, allStages
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {allStages.map(
                              (stage, stageIndex) => (
                                <Dropdown.Item
                                  key={stageIndex}
                                  onSelect={(e) => {
                                    setCandidateUploadData(
                                      {
                                        ...candidateUploadData,
                                        stage: e,
                                      }
                                    )
                                  }}
                                  value={stage.id}
                                  eventKey={stage.id}
                                >
                                  {stage.label}
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col lg='6' md='6' sm='12' xs='12' className='p-0' style={{ marginLeft: '-11px' }}>
                        {memberOrganization &&
                          <div style={{ marginBottom: '5px' }}>
                            <span className={styles.labelText}>Select Recruiter (Default is set to you)</span>
                            <Select
                              styles={selectcolourStyles}
                              defaultValue={{ value: candidateUploadData.recruiterId, label: user.first_name + ' ' + user.last_name }}
                              isSearchable={true}
                              name="recruiter-select-box"
                              options={[...recruitersList]}
                              onChange={({ value, label }) => {
                                setCandidateUploadData(
                                  {
                                    ...candidateUploadData,
                                    recruiterId: value,
                                  }
                                );
                              }}
                            />
                          </div>
                        }
                      </Col>
                    </Row>

                    <Row style={{ marginLeft: '-18px' }}>
                      <div
                        className={`${styles.indent} ${styles.tabContentText}`}
                      >
                        <p className={styles.contactText}>Links <span className={styles.fileType}> (Links)</span></p>
                        {showItems('links')}

                        <div
                          style={{ cursor: 'pointer', marginTop: '10px' }}
                        >
                          {(isEditable || allowCandidateUpload) && (
                            <AddButton
                              title="Add link"
                              controlName="links"
                              update={addItem}
                              candidate={candidate}
                            />
                          )}
                        </div>
                      </div>
                      {showWishList ?
                        <div
                          style={{ marginBottom: '10px', marginLeft: '182px' }}
                        >
                          <p className={styles.contactText}>Resume <span className={styles.fileType}> (.pdf/.docx)</span></p>
                          {!candidate && <div>{showItems('files')}</div>}
                          {(allowCandidateUpload || isEditable) && (
                            <AddButton
                              title="Upload file"
                              controlName="files"
                              update={addItem}
                              setResume={setResume}
                            />
                          )}
                        </div>
                        :
                        <div
                          style={{ marginBottom: '10px', marginLeft: '182px' }}
                        >
                          <p className={styles.contactText}>Resume <span className={styles.fileType}> (.pdf/.docx)</span></p>
                          {!candidate && <div>{showItems('files')}</div>}
                        </div>}
                    </Row>
                  </>
                )}

                <Tab.Container defaultActiveKey="about_candidate"  >
                  {candidate && (
                    <Row className={styles.tabContainer}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="about_resume"
                            className={styles.tabButton}
                          >
                            Resume
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="about_candidate"
                            className={styles.tabButton}
                          >
                            About Candidate
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="recruiter_activity"
                            className={styles.tabButton}
                          >
                            Recruiter Activity
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="about_submission"
                            className={styles.tabButton}
                          >
                            Submission
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="about_highlight"
                            className={styles.tabButton}
                          >
                            Candidate Highlights
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Row>
                  )}

                  <Row className={styles.tabContent}>
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <Tab.Content style={{ width: '100%' }}>
                        <Tab.Pane eventKey="about_resume">
                          {candidate ?
                            <p>
                              <p className={styles.tabContentTitle} >
                                Resume
                              </p>

                              {resumeUrl ? (
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  {resumeUrl.includes("incomingemails") ? (
                                    resumeUrl.toLowerCase().endsWith('.pdf') ? (
                                      <iframe className={styles.heightlevel} src={resumeUrl}></iframe>
                                    ) : (
                                      resumeUrl.toLowerCase().endsWith('.docx') ? (
                                        // <FileViewer
                                        //   fileType="docx"
                                        //   filePath={resumeUrl}
                                        //   errorComponent={() => <div>Error loading DOCX file</div>}
                                        //   onError={(e) => console.log('Error loading:', e)}
                                        // />
                                        <a href={resumeUrl}>Click And Download Resume</a>
                                      ) : (
                                        <p>Unsupported File Format</p>
                                      )
                                    )
                                  ) : (
                                    resumeUrl.toLowerCase().endsWith('.docx') ? (
                                      <a href={resumeUrl}>Click And Download Resume</a>
                                    ) : (
                                      <iframe className={styles.heightlevel} src={resumeUrl}></iframe>
                                    )
                                  )}
                                </div>
                              ) : (
                                <p style={{ color: 'red' }}>Please Upload the Resume!</p>
                              )}
                            </p> :
                            <p>
                              <Tab.Pane eventKey="about_candidate">
                                <p className={styles.tabContentTitle}>
                                  Summary
                                </p>
                                <textarea
                                  placeholder="Summary"
                                  name="summary"
                                  rows="2"
                                  style={{ resize: 'none', }}
                                  className={`${styles.editInput}`}
                                  onChange={handleChange}
                                  value={(candidateUploadData.summary === 'N/A') ? '' : candidateUploadData.summary}
                                />

                                <p className={styles.tabContentTitle}>
                                  Education
                                </p>
                                <div
                                  className={`${styles.indent} ${styles.tabContentText}`}
                                  style={{
                                    cursor: 'pointer',
                                    border: '1px solid #435ad4',
                                    borderRadius: '2px',
                                    padding: '12px',
                                    marginBottom: '12px',
                                    marginLeft: '0px'
                                  }}
                                >
                                  <p>
                                    {candidateUploadData?.educations?.map(
                                      (edu) => {
                                        return (
                                          <AddNewEducation
                                            key={edu.id}
                                            education={edu}
                                            onChange={
                                              addNewEducation
                                            }
                                            removeEducation={
                                              removeEducation
                                            }
                                            isEditable={isEditable}
                                            allowCandidateUpload={
                                              allowCandidateUpload
                                            }
                                          />
                                        )
                                      }
                                    )}
                                  </p>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p
                                      style={{
                                        cursor: 'pointer',
                                        marginBottom: '0px',
                                      }}
                                    >
                                      {(allowCandidateUpload ||
                                        isEditable) && (
                                          <a
                                            onClick={() =>
                                              showEducationForm()
                                            }
                                          >
                                            <Image
                                              src={PlusIcon}
                                              style={{
                                                width: '10px',
                                                height: '10px',
                                                marginRight:
                                                  '15px',
                                              }}
                                            />
                                            Add education
                                          </a>
                                        )}
                                    </p>
                                  </div>
                                </div>

                                <p className={styles.tabContentTitle}>
                                  Experience
                                </p>
                                <div
                                  className={`${styles.indent} ${styles.tabContentText} ${(validationErrors['job_experiences']) ? 'expError' : ''}`}
                                  style={{
                                    cursor: 'pointer',
                                    border: '1px solid #435ad4',
                                    borderRadius: '2px',
                                    padding: '12px',
                                    marginBottom: '12px',
                                    marginLeft: '0px'
                                  }}
                                >
                                  <p>{candidateUploadData.job_experiences.map(
                                    (exp) => (
                                      <AddNewExperience
                                        key={exp.id}
                                        experience={exp}
                                        onChange={
                                          addNewExperience
                                        }
                                        removeExperience={
                                          removeExperience
                                        }
                                        isEditable={isEditable}
                                        allowCandidateUpload={
                                          allowCandidateUpload
                                        }
                                        updateCandidateData={updateCandidateData}
                                      />
                                    )
                                  )}</p>

                                  <div className={`d-flex justify-content-between align-items-center`}>
                                    <p
                                      style={{
                                        cursor: 'pointer',
                                        marginBottom: '0px',
                                      }}
                                    >
                                      {(allowCandidateUpload ||
                                        isEditable) && (
                                          <a
                                            onClick={() =>
                                              showExperienceForm()
                                            }
                                          >
                                            <Image
                                              src={PlusIcon}
                                              style={{
                                                width: '10px',
                                                height: '10px',
                                                marginRight:
                                                  '15px',
                                              }}
                                            />
                                            Add experience
                                          </a>
                                        )}
                                    </p>
                                  </div>
                                </div>
                                <p className={styles.tabContentTitle}>
                                  Skills*
                                </p>

                                <div className={styles.skillContainer}>
                                  <p>{((candidate && candidate.skills) ||
                                    candidateUploadData.skills
                                      .length > 0) && (
                                      <div
                                        className={`${styles.indent} ${styles.skillscontent} mb-3 d-flex flex-wrap`}
                                      >
                                        {displaySkills(candidateUploadData.skills)}
                                      </div>
                                    )}
                                  </p>
                                </div>

                                <div
                                  className={`${styles.tabContentText}`}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <p>{(allowCandidateUpload ||
                                    isEditable) && (
                                      <AddButton
                                        title="Add Skill"
                                        controlName="skills"
                                        update={addItem}
                                        candidate={candidate}
                                        validationErrors={validationErrors}
                                        setValidationErrors={setValidationErrors}
                                      />
                                    )}
                                  </p>
                                </div>
                              </Tab.Pane>

                              {(isEditable || allowCandidateUpload) && (
                                <>
                                  <div>
                                    {showWishList ?
                                      <Button
                                        className={styles.saveButton}
                                        onClick={(e) => {
                                          if (candidate) {
                                            submitCandidateDataForm(e)
                                          }
                                          else {
                                            submitSingleCandidate(e)
                                          }
                                        }}
                                      >
                                        {candidate
                                          ? 'Update Candidate'
                                          : 'Save Candidate'}
                                      </Button> : ""}
                                  </div>
                                </>
                              )}
                            </p>}
                        </Tab.Pane>
                        <Tab.Pane eventKey="about_candidate">

                          {(!showWishList || showUploadCandidatePanel) ?
                            <>
                              <p className={styles.tabContentTitle}>
                                Summary
                              </p>
                              <textarea
                                placeholder="Summary"
                                name="summary"
                                rows="4"
                                style={{ resize: 'none' }}
                                className={`${styles.editInput} textClBlackTextarea`}
                                onChange={handleChange}
                                value={(candidateUploadData.summary === 'N/A') ? '' : candidateUploadData.summary}
                              />
                              <hr className='hrBorder' />
                            </>
                            : <>
                              {((candidateUploadData.summary)?.length > 0) &&
                                <>
                                  <p className={styles.tabContentTitle}>
                                    Summary
                                  </p>
                                  <textarea
                                    placeholder="Summary"
                                    name="summary"
                                    rows="4"
                                    style={{ resize: 'none', border: 'none' }}
                                    className={`${styles.editInput} textClBlackTextarea`}
                                    value={(candidateUploadData.summary === 'N/A') ? '' : candidateUploadData.summary} />
                                  <hr className='hrBorder' />
                                </>
                              }
                            </>
                          }



                          {(!showWishList || showUploadCandidatePanel) &&
                            <>
                              <p className={styles.tabContentTitle}>
                                Education
                              </p>

                              <div
                                className={`${styles.indent} ${styles.tabContentText}`}
                                style={{
                                  cursor: 'pointer',
                                  // border: '1px solid #435ad4',
                                  borderRadius: '2px',
                                  padding: '12px',
                                  marginBottom: '12px',
                                  marginLeft: '0px'
                                }}
                              >
                                <p>
                                  {candidateUploadData?.educations?.map(
                                    (edu) => {
                                      return (
                                        <>
                                          <AddNewEducation
                                            key={edu.id}
                                            education={edu}
                                            onChange={
                                              addNewEducation
                                            }
                                            removeEducation={
                                              removeEducation
                                            }
                                            isEditable={isEditable}
                                            allowCandidateUpload={
                                              allowCandidateUpload
                                            }
                                          />
                                          <hr />
                                        </>
                                      )
                                    }
                                  )}
                                </p>
                                <div className="d-flex justify-content-between align-items-center" style={{ border: 'none' }}>
                                  <p
                                    style={{
                                      cursor: 'pointer',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    {(allowCandidateUpload ||
                                      isEditable) && (
                                        <a
                                          onClick={() =>
                                            showEducationForm()
                                          }
                                        >
                                          <Image
                                            src={PlusIcon}
                                            style={{
                                              width: '10px',
                                              height: '10px',
                                              marginRight:
                                                '15px',
                                            }}
                                          />
                                          Add education
                                        </a>
                                      )}
                                  </p>
                                </div>
                              </div>
                              <hr className='hrBorder' />
                            </>
                          }
                          {showWishList && (candidate?.education_exp && (candidate?.education_exp).length > 0) &&
                            <>

                              <p className={styles.tabContentTitle}>
                                Education
                              </p>
                              <div
                                className={`${styles.indent} ${styles.tabContentText}`}
                                style={{
                                  cursor: 'pointer',
                                  // border: '1px solid #435ad4',
                                  borderRadius: '2px',
                                  padding: '12px',
                                  marginBottom: '12px',
                                  marginLeft: '0px'
                                }}
                              >
                                <p>
                                  {candidate?.education_exp?.map((edu) => {
                                    return (
                                      <div className='mb-3 mt-1'>
                                        <p className={'headExprience'} >{`${(edu?.school_name && edu?.school_name != '') ? (edu?.school_name)?.trim() : 'University Name not given '}`}</p>
                                        {(edu?.degree && edu?.degree != '') && <p className={'detailExprience'}>{(edu?.degree)?.trim()}</p>}
                                        {((edu?.from != '' && edu?.from != null && edu?.from != 'null') || (edu?.to != '' && edu?.to != null && edu?.to != 'null')) && <p className={'detailExprienceDate'}>{`${moment(edu?.from).isValid() ? moment(edu?.from).format('YYYY') : (edu?.from == '' || edu?.from == null || edu?.from == 'null') ? 'N/A' : edu?.from} ` + 'to' + ` ${moment(edu?.to).isValid() ? moment(edu?.to).format('YYYY') : (edu?.to == '' || edu?.to == null || edu?.to == 'null') ? 'N/A' : edu?.to}`}</p>}
                                      </div>
                                    )
                                  })}
                                </p>
                              </div>
                              <hr className='hrBorder' />
                            </>
                          }

                          <p className={styles.tabContentTitle}>
                            Experience
                          </p>

                          <div
                            className={`${styles.indent} ${styles.tabContentText} ${(validationErrors['job_experiences']) ? 'expError' : ''}`}
                            style={{
                              cursor: 'pointer',
                              // border: '1px solid #435ad4',
                              borderRadius: '2px',
                              padding: '12px',
                              marginBottom: '12px',
                              marginLeft: '0px'
                            }}
                          >
                            {(!showWishList || showUploadCandidatePanel) ?
                              <p>{candidateUploadData.job_experiences.map(
                                (exp) => (
                                  <>
                                    <AddNewExperience
                                      key={exp.id}
                                      experience={exp}
                                      onChange={
                                        addNewExperience
                                      }
                                      removeExperience={
                                        removeExperience
                                      }
                                      isEditable={isEditable}
                                      allowCandidateUpload={
                                        allowCandidateUpload
                                      }
                                      updateCandidateData={updateCandidateData}
                                    />
                                    <hr />
                                  </>
                                )
                              )}</p>
                              : <p>
                                {candidateUploadData.job_experiences?.map((exp) => {
                                  return (
                                    <div className='mb-3 mt-1'>
                                      <p className={'headExprience'}>{`${(exp?.title && exp?.title != '') ? (exp?.title)?.trim() : 'No Exprience Title Details Available'}`}</p>
                                      {((exp?.start_date != '' && exp?.start_date != null && exp?.start_date != 'null') || (exp?.end_date != '' && exp?.end_date != null && exp?.end_date != 'null')) && <p className={'detailExprienceDate'} >{`${moment(exp?.start_date).isValid() ? moment(exp?.start_date).format('MMM-YYYY') : (exp?.start_date == '' || exp?.start_date == null || exp?.start_date == 'null') ? 'N/A' : exp?.start_date} ` + 'to' + ` ${moment(exp?.end_date).isValid() ? moment(exp?.end_date).format('MMM-YYYY') : (exp?.present && exp?.present == true) ? 'Present' : (exp?.end_date == '' || exp?.end_date == null || exp?.end_date == 'null') ? 'N/A' : exp?.end_date}`}</p>}
                                      {((exp?.location && exp?.location != '' && exp?.location != 'N/A') || (exp?.company_name && exp?.company_name != '' && exp?.company_name != 'N/A')) && <p className={'detailExprienceDate'} >{`${(exp?.company_name && exp?.company_name != '' && exp?.company_name != 'N/A') ? (exp?.company_name)?.trim() : ''}` + ' ' + `${(exp?.location && exp?.location != '' && exp?.location != 'N/A') ? ', ' + (exp?.location)?.trim() : ''}`} </p>}
                                      {(exp?.description && exp?.description != 'N/A' && exp?.description != '') ? <p className={'detailExprience'}> {(exp?.description)?.trim()}</p> : ''}
                                    </div>
                                  )
                                })}
                              </p>
                            }

                            <div className={`d-flex justify-content-between align-items-center`}>
                              {(!showWishList || showUploadCandidatePanel) ?
                                <p
                                  style={{
                                    cursor: 'pointer',
                                    marginBottom: '0px',
                                  }}
                                >
                                  {(allowCandidateUpload ||
                                    isEditable) && (
                                      <a
                                        onClick={() =>
                                          showExperienceForm()
                                        }
                                      >
                                        <Image
                                          src={PlusIcon}
                                          style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight:
                                              '15px',
                                          }}
                                        />
                                        Add experience
                                      </a>
                                    )}
                                </p> : ''}
                            </div>
                          </div>
                          <hr className='hrBorder' />
                          <p className={styles.tabContentTitle}>
                            Skills*
                          </p>

                          <div className={styles.skillContainer}>
                            {(!showWishList || showUploadCandidatePanel) ?
                              <p>{((candidate && candidate.skills) ||
                                candidateUploadData.skills
                                  .length > 0) && (
                                  <div
                                    className={`${styles.indent} ${styles.skillscontent} mb-3 d-flex flex-wrap`}
                                  >
                                    {displaySkills(candidateUploadData.skills)}
                                  </div>
                                )}
                              </p>
                              : <p>
                                <div
                                  className={`${styles.indent} ${styles.skillscontent} mb-3 d-flex flex-wrap`}
                                >
                                  {displaySkillstext(candidateUploadData.skills)}
                                </div>
                              </p>}
                          </div>

                          <div
                            className={`${styles.tabContentText}`}
                            style={{ cursor: 'pointer' }}
                          >
                            {(!showWishList || showUploadCandidatePanel) ?
                              <p>{(allowCandidateUpload ||
                                isEditable) && (
                                  <AddButton
                                    title="Add Skill"
                                    controlName="skills"
                                    update={addItem}
                                    candidate={candidate}
                                    validationErrors={validationErrors}
                                    setValidationErrors={setValidationErrors}
                                  />
                                )}
                              </p> : ""}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="recruiter_activity">
                          <p className={styles.tabContentTitle}>
                            NOTES
                          </p>
                          <div>
                            {notes.map((note) => {
                              if (note.entity_type == 'memo') {
                                return (
                                  <RecruiterActivityItem
                                    key={note.id}
                                    author={note}
                                    created_at={
                                      note.created_at
                                    }
                                    title={note?.email}
                                    content={note.body}
                                  />
                                )
                              }
                            })}
                          </div>
                          <p className={styles.tabContentTitle}>
                            FEEDBACK
                          </p>
                          <div>
                            {feedbacks.map(({ job, feedback }) => {
                              return (
                                <FeedbackActivityItem
                                  job={job}
                                  feedback={feedback}
                                />
                              )
                            })}
                          </div>

                          <p className={styles.tabContentTitle}>
                            ACTIVITY
                          </p>
                          <div>
                            {notes.map((note) => {
                              if (note.entity_type == 'activity') {
                                return (
                                  <RecruiterActivityItem
                                    key={note.id}
                                    author={note}
                                    created_at={
                                      note.created_at
                                    }
                                    title={note?.email}
                                    content={note.body}
                                  />
                                )
                              }
                            })}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="about_submission">
                          <p className={styles.tabContentTitle}>
                            Submission Activity
                          </p>
                          <div>
                            {stageTransitions.map((sub) => {
                              return ( <p className={styles.shadingtext}>Coming Soon</p>
                                // <SubmissionActivityItem
                                //   key={sub.id}
                                //   author={sub?.user?.first_name}
                                //   author_two={sub?.user?.last_name}
                                //   created_at={
                                //     sub.created_at
                                //   }
                                //   title={sub?.user?.email}
                                //   content={sub.submission_type}
                                // />
                              )

                            })}

                          </div>


                        </Tab.Pane>
                        <Tab.Pane eventKey="about_highlight">

                          <p className={styles.tabContentTitle}>
                            Highlights From LinkedIn
                          </p>
                          {candidateUploadData.highlight != '' && candidateUploadData.highlight ?
                          <textarea
                            placeholder="Highlight"
                            name="highlight"
                            rows="13"
                            disabled={true}
                            style={{ resize: 'none' }}
                            className={`${styles.editInput}`}
                            value={(candidateUploadData.highlight === '') ? '' : '------------------------------------------------------------\n' +candidateUploadData.highlight.replace(/-------------------/g, '------------------------------------------------------------\n').replace(/             /g,'\n').split('\n').map(line => line.trim()).join('\n')}
                          /> :  <p style={{ color: 'red' }}>No Data Found</p> }
                        </Tab.Pane>
                      </Tab.Content>)}
                  </Row>
                </Tab.Container>
                {(isEditable || allowCandidateUpload) && (
                  <>
                    <div>
                      {errorFetchingCandidate && (
                        <Alert
                          variant="danger"
                          onClose={() => setErrorFetchingCandidate(null)}
                          dismissible
                          className="candidate-info-close"
                        >
                          {errorFetchingCandidate}
                        </Alert>
                      )}
                      {Object.values(validationErrors).map((error) => (
                        <Alert
                          key={error}
                          variant="danger"
                          onClose={() => setValidationErrors({})}
                          dismissible
                          className="candidate-info-close"
                        >
                          <div dangerouslySetInnerHTML={{ __html: error }} />
                        </Alert>
                      ))}
                      {(!showWishList || showUploadCandidatePanel) ?
                        <Button
                          className={styles.saveButton}
                          onClick={(e) => {
                            if (candidate) {
                              submitCandidateDataForm(e)
                            }
                            else {
                              submitSingleCandidate(e)
                            }
                          }}
                        >
                          {candidate
                            ? 'Update Candidate'
                            : 'Save Candidate'}
                        </Button>
                        : ""
                      }
                      {(showUploadCandidatePanel) && (
                        <Button
                          className={styles.saveButton}
                          onClick={() => { setDefaultActiveKey('upload-resume-tab') }}
                        >
                          Back
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    )
  } else {
    return null
  }
}

const RecruiterActivityItem = ({ author, created_at, title, content }) => {
  const regExp = /@\[[a-zA-Z\s]+\]/g
  const matches = [...content.matchAll(regExp)].map((arr) => arr[0])
  let val = content
  
  for (let index = 0; index < matches.length; index++) {
    const element = matches[index]
    val = val.replace(
      element,
      '<b>@' + element.slice(2, element.length - 1) + '</b>'
    )
  }
  val = val?.replaceAll('recruitor_screen', '<b>Recruiter Screened</b>')
  val = val?.replaceAll('submitted', '<b>Submitted</b>')
  val = val?.replaceAll('first_interview', '<b>First Interview</b>')
  val = val?.replaceAll('second_interview', '<b>Second Interview</b>')
  val = val?.replaceAll('offer', '<b>Offer</b>')
  val = val?.replaceAll('reject', '<b>Archived</b>')
  val = val?.replaceAll('applicant', '<b>Applicant</b>')
  val = val?.replaceAll('hired', '<b>Hired</b>')
  val = val?.replaceAll('lead', '<b>Lead</b>')
  return (
    <div className={`${styles.indent} ${styles.tabContentText}`}>
      <Row className="d-flex justify-content-between mx-0">
        <span className={styles.noteAuthorText}>
          {author ? author.first_name + ' ' + author.last_name : ''}
        </span>
        <span className={styles.noteCreatedText}>
          {moment(created_at).format('llll')}
        </span>
      </Row>
      <Row className="mx-0">
        <p className={`${styles.noteCreatedText} mb-0`}>
          {title ? '@ ' + title : ''}
        </p>
      </Row>
      <Row className="mx-0">
        <p
          className={styles.noteCreatedText}
          dangerouslySetInnerHTML={{ __html: val }}
        ></p>
      </Row>
    </div>
  )
}


const SubmissionActivityItem = ({ author, author_two, created_at, title, content }) => {
  const regExp = /@\[[a-zA-Z\s]+\]/g
  const matches = [...content.matchAll(regExp)].map((arr) => arr[0])
  let val = content

  for (let index = 0; index < matches.length; index++) {
    const element = matches[index]
    val = val.replace(
      element,
      '<b>@' + element.slice(2, element.length - 1) + '</b>'
    )
  }

  return (
    <div className={`${styles.indent} ${styles.tabContentText}`}>
      <Row className="d-flex justify-content-between mx-0">
        <span className={styles.noteAuthorText}>

          {author && author_two ? <p>{capitalize(author) + capitalize(author_two)}</p> : ""}
        </span>
        <span className={styles.noteCreatedText}>
          {moment(created_at).format('llll')}
        </span>
      </Row>
      <Row className="mx-0">
        <p className={styles.noteCreatedText}>
          {title ? '@ ' + title : ''}
        </p>
      </Row>
      <Row className="mx-0">
        <p
          className={styles.noteCreatedText}
          dangerouslySetInnerHTML={{ __html: val }}
        ></p>
      </Row>

    </div>
  )
}

const FeedbackActivityItem = ({ job, feedback }) => {
  return (
    <>
      {feedback.map((data) => {

        return (<div className={`${styles.indent} ${styles.tabContentText}`}>
          <Row className="d-flex justify-content-between mx-0">
            <span className={styles.noteAuthorText}>
              <b>{job.name}</b>
            </span>
            <span className={styles.noteCreatedText}>
              {moment(data.created_at).format('llll')}
            </span>
          </Row>
          <br></br>
          <Row className="mx-0">
            <p className={styles.noteCreatedText}>
              {data.feedback}
            </p>
          </Row>
        </div>
        )
      })}
    </>
  )
}

const AddButton = ({ title, update, controlName, candidate, setResume, validationErrors, setValidationErrors }) => {
  const [isClicked, setIsClicked] = useState(false)
  const [item, setItem] = useState('')
  const [control, setControl] = useState(controlName)
  const [invalidUrl, setInvalidUrl] = useState(null)

  useEffect(() => {
    feather.replace()
  })

  const setSocialState = () => {
    if (control === "linkedin_profile_url") {
      setControl('linkedin_profile_url')
    }
    else if (control === "github_url") {
      setControl('github_url')
    }
  }

  const getHostname = (url) => {
    try {
      return new URL(url).hostname
    } catch (error) {
      setInvalidUrl('Please enter valid url!')
      return null
    }
  }

  const add = (e) => {
    const trimmedInput = item.trim();
    if (!trimmedInput) {
      setItem('')
    }
    if (e.key === 'Enter' && !e.shiftKey && trimmedInput) {
      update(e, item, control)
      setItem('')
      setIsClicked(false)
    }
  }

  if (control === 'skills') {
    return (
      <>
        <input
          type="text"
          value={item}
          // autoFocus
          placeholder="Add Skills"
          onChange={(e) => setItem(e.target.value)}
          onKeyDown={add}
          onBlur={() => {
            setIsClicked(false)
            setItem('')
            setValidationErrors({})
          }}
          className={`${styles.editInput} ${(validationErrors['skills']) ? 'expError' : ''}`}
        />
        <span className="">(Press enter to add skills)</span>
      </>
    )
  }

  if (isClicked) {
    if (control === 'files') {
      return (
        <>
          <input
            type="file"
            value={item}
            onChange={(e) => {
              update(e, e.target.files[0], control)
              setResume(e.target.files[0])
              setItem('')
              setIsClicked(false)
            }}
            style={{ width: '98px', cursor: 'pointer' }}
            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, text/plain, .doc,.docx,.txt,.pdf"
          />
        </>
      )
    }
    if (control == 'contact_info') {
      return (
        <>
          {isClicked && (
            <div
              className={styles.backdrop}
              onClick={() => setIsClicked(false)}
            ></div>
          )}
          <div className="d-flex align-items-center">
            <a onClick={setSocialState}>
              <Image
                src={PlusIcon}
                style={{
                  width: '15px',
                  height: '15px',
                  marginRight: '15px',
                }}
              />
            </a>
            <div className={styles.emailPhonePicker}>
              <p style={{ cursor: 'pointer' }}>
                <a onClick={() => setControl('emails')}>
                  <Image
                    src={MailIcon}
                    style={{
                      width: '15px',
                      height: '15px',
                      marginRight: '15px',
                    }}
                  />
                  Email
                </a>
              </p>
              <p style={{ cursor: 'pointer' }}>
                <a onClick={() => setControl('phone_numbers')}>
                  <Image
                    src={PhoneIcon}
                    style={{
                      width: '15px',
                      height: '15px',
                      marginRight: '15px',
                    }}
                  />
                  Phone Number
                </a>
              </p>
            </div>
          </div>
        </>
      )
    }
    if (control === 'experience') {
      return (
        <textarea
          type="text"
          value={item}
          autoFocus
          rows={8}
          onChange={(e) => setItem(e.target.value)}
          onKeyDown={add}
          onBlur={() => {
            setIsClicked(false)
            setItem('')
          }}
          style={{ width: '100%' }}
        />
      )
    }
    return (
      <p className={styles.urlText}>
        {control === 'emails' ? (
          <Image
            src={MailIcon}
            style={{
              width: '15px',
              height: '15px',
              marginRight: '15px',
            }}
          />
        ) : control === 'phone_numbers' ? (
          <Image
            src={PhoneIcon}
            style={{
              width: '15px',
              height: '15px',
              marginRight: '15px',
            }}
          />
        ) : (
          control === 'links' && (
            <Image
              src={UrlIcon}
              style={{
                width: '15px',
                height: '15px',
                marginRight: '15px',
              }}
            />
          )
        )}

        <input
          type="text"
          value={item}
          autoFocus
          onChange={(e) => setItem(e.target.value)}
          onKeyDown={add}
          onBlur={() => {
            setIsClicked(false)
            setItem('')
          }}
          className={styles.editInput}
        />
      </p>
    )
  }

  return (
    <>
      {invalidUrl && showWishList && (
        <div className={styles.alertMessage}
        >
          <div className={`${control}` + 'error-class'}>
            <Alert
              variant="danger"
              onClose={() => setInvalidUrl(null)}
              dismissible
              className='candidate-info-close'
            >
              {invalidUrl}
            </Alert>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-between">
        <p style={{ cursor: 'pointer' }}>
          <a
            onClick={() => {
              setIsClicked(true)
              setControl(controlName)
            }}
            className={
              control === 'skills'
                ? styles.skillButton
                : styles.plusButtonText
            }
          >
            {control !== 'skills' && (
              <Image
                src={PlusIcon}
                style={{
                  width: '15px',
                  height: '15px',
                  marginRight: '15px',
                }}
              />
            )}
            {title}
          </a>
        </p>
      </div>
    </>
  )
}

const AddNewExperience = (props) => {
  const { onChange, experience, removeExperience, isEditable, allowCandidateUpload, updateCandidateData, index } = props
  useEffect(() => {
    feather.replace()
  })
  const handleChange = (e) => {
    // let closerdiv = e.target.closest('.job-experience')
    // let end_date = closerdiv.getElementsByClassName('end_date')[0].value
    // if(end_date?.toLowerCase() == 'present'){
    //     console.log(closerdiv?.getElementsByClassName('company_name')[0]?.value)
    //     updateCandidateData('title',closerdiv?.getElementsByClassName('position_title')[0]?.value)
    //     updateCandidateData('company',closerdiv?.getElementsByClassName('company_name')[0]?.value)
    // }
    onChange({ ...experience, [e.target.name]: e.target.value })
  }

  const handleStartDate = (e) => {
    e.target.type = 'text'
    e.target.value = moment(e.target.value).isValid() ? moment(e.target.value).format('MM-YYYY') : ''
  }

  const handleFocus = (e, date) => {
    e.target.type = 'month'
    e.target.value = moment(date).format('YYYY-MM')
  }

  const [disableDate, setDisableDate] = useState(((experience.end_date)?.toLowerCase() == ('present').toLocaleLowerCase() || experience.present == true) ? true : false)
  const handleChangecheckbox = (event) => {
    if (event.target.checked) {
      setDisableDate(true)
      experience.end_date = ''
    } else {
      setDisableDate(false)
    }
    onChange({ ...experience, [event.target.name]: event.target.checked })
  }

  return (
    <div
      className="d-flex align-items-start job-experience"
      style={{ marginBottom: '12px' }}
    >
      <div className="d-flex flex-column w-100">
        <div>
          <div className='pull-left'>
            <input
              type="text"
              name="title"
              placeholder="Position title"
              className={`${styles.editInput} ${(experience.title === 'N/A') ? 'expError' : ''} position_title`}
              style={{ width: '90px' }}
              value={experience.title}
              onChange={handleChange}
              readOnly={!(isEditable || allowCandidateUpload)}
            />
            &nbsp; <span className={styles.symbolText}>@</span>
            &nbsp;
            <input
              type="text"
              name="company_name"
              placeholder="Company"
              className={`${styles.editInput} ${(experience.company_name === 'N/A') ? 'expError' : ''} company_name`}
              style={{ width: '85px' }}
              value={experience.company_name}
              onChange={handleChange}
              readOnly={!(isEditable || allowCandidateUpload)}
            />
          </div>
          <div className='pull-right'>
            <DatePicker
              style={{
                border: '1px solid #4c68ff',
                borderRadius: '2px !important',
                backgroundColor: '#ffffff !important',
                padding: '5px 10px',
                minHeight: '25px !important',
                outline: 'none',
                color: '#435ad5',
                marginBottom: '5px',
                width: '40%',
                backgroundColor: '#ffffff',
                marginLeft: '0px',
              }}
              className={`${styles.editInput} ${(experience.start_date === 'N/A') ? 'expError' : ''}`}
              type="text"
              name="start_date"
              onChange={handleChange}
              placeholder={
                (experience.start_date != '' && ((experience.start_date)?.toLowerCase() != ('present').toLocaleLowerCase()))
                  ? (moment(experience.start_date).isValid()) ? moment(experience.start_date).format('MM-YYYY') : 'From - MM-YYYY'
                  : 'From - MM-YYYY'
              }
              onBlur={handleStartDate}
              onFocus={(e) => handleFocus(e, experience.start_date)}

            />
            -
            <DatePicker
              style={{
                border: '1px solid #4c68ff',
                borderRadius: '2px !important',
                backgroundColor: '#ffffff !important',
                padding: '5px 10px',
                minHeight: '25px !important',
                outline: 'none',
                color: '#435ad5',
                marginBottom: '5px',
                width: '40%',
                backgroundColor: '#ffffff',
              }}
              className={`${styles.editInput} ${(experience.end_date === 'N/A') ? 'expError' : ''}`}
              type="text"
              name="end_date"
              onChange={handleChange}
              placeholder={
                (experience.end_date != '' && ((experience.end_date)?.toLowerCase() != ('present').toLocaleLowerCase()))
                  ? (moment(experience.end_date).isValid()) ? moment(experience.end_date).format('MM-YYYY') : 'To - MM-YYYY'
                  : 'To - MM-YYYY'
              }
              onBlur={handleStartDate}
              onFocus={(e) => handleFocus(e, experience.end_date)}
              disabled={disableDate}
            />
            <span><input type='checkbox' title='Present' onChange={(e) => handleChangecheckbox(e)} className='ml-1 mr-1' name='present' defaultChecked={((experience.present)) ? true : false} />Present</span>
          </div>
        </div>
        <input
          type="text"
          name="location"
          placeholder=" Add Location*"
          className={`${styles.editInput} ${(experience.location === 'N/A') ? 'expError' : ''}`}
          value={experience.location}
          onChange={handleChange}
          readOnly={!(isEditable || allowCandidateUpload)}
        />
        <textarea
          placeholder="Details"
          name="description"
          rows="5"
          style={{ resize: 'none' }}
          className={`${styles.editInput} ${(experience.description === 'N/A') ? 'expError' : ''}`}
          value={experience.description}
          onChange={handleChange}
          readOnly={!(isEditable || allowCandidateUpload)}
        />
      </div>
      {(allowCandidateUpload || isEditable) &&
        <div
          className={styles.circleButton}
          style={{
            background: '#4C68FF',
            color: '#fff',
            width: '16px',
            height: '16px',
          }}
          onClick={() => {
            removeExperience(experience)
          }}
        >
          <i data-feather="x" />
        </div>
      }
    </div>
  )
}

const AddNewEducation = (props) => {

  const { onChange, education, removeEducation, isEditable, allowCandidateUpload, index } = props
  useEffect(() => {
    feather.replace()
  })

  useEffect(() => {
    feather.replace()
  })

  const handleChange = (e) => {
    onChange({ ...education, [e.target.name]: e.target.value })
  }

  return (
    <div
      className="d-flex align-items-start"
      style={{ marginBottom: '12px' }}
    >
      <div className="d-flex flex-column w-100">
        <div className="d-flex justify-content-between">
          <div className='w-100'>
            <input
              type="text"
              name="school_name"
              placeholder="University"
              className={styles.editInput}
              value={education.school_name}
              onChange={handleChange}
              readOnly={!(isEditable || allowCandidateUpload)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className='w-100'>
            <input
              style={{
                border: '1px solid #4c68ff',
                borderRadius: '2px !important',
                backgroundColor: '#ffffff !important',
                padding: '5px 10px',
                minHeight: '25px !important',
                outline: 'none',
                color: '#435ad5',
                marginBottom: '5px',
                width: '48%',
                backgroundColor: '#ffffff',
              }}
              type="text"
              name="from"
              onChange={handleChange}
              placeholder={
                (education.from != '' && education.from != null)
                  ? education.from
                  : 'From Year - YYYY'
              }
            />
            <input
              type="text"
              name="to"
              style={{
                border: '1px solid #4c68ff',
                borderRadius: '2px !important',
                backgroundColor: '#ffffff !important',
                padding: '5px 10px',
                minHeight: '25px !important',
                outline: 'none',
                color: '#435ad5',
                marginBottom: '5px',
                width: '48%',
                backgroundColor: '#ffffff',
                marginLeft: '4%'
              }}
              onChange={handleChange}
              placeholder={
                (education.to != '' && education.to != null)
                  ? education.to
                  : 'To Year - YYYY'
              }
              min={education.from}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className='w-100'>
            <input
              type="text"
              name="degree"
              placeholder="Degree"
              className={styles.editInput}
              value={education.degree}
              onChange={handleChange}
              readOnly={!(isEditable || allowCandidateUpload)}
            />
          </div>
        </div>
      </div>
      {(allowCandidateUpload || isEditable) &&
        <div
          className={styles.circleButton}
          style={{
            background: '#4C68FF',
            color: '#fff',
            width: '16px',
            height: '16px',
          }}
          onClick={() => {
            removeEducation(education)
          }}
        >
          <i data-feather="x" />
        </div>
      }
    </div>
  )
}
export default CandidateInfoPanel