import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styles from './styles/ScheduledTable.module.scss'
import './styles/ScheduledTable.scss'
import feather from 'feather-icons'
import { makeRequest } from "../../../common/RequestAssist/RequestAssist";

const AddFeedback = ({setModal, modal, user,interviewId, setViewFeedbackModal}) => {
    const [addFeedback, setAddFeedback] = useState(false)
    const [openFeedbackMessage, setOpenFeedbackMessage] = useState('')
    const [interviewDetails, setInterviewDetails] = useState({}) 
    const [isErrorMessage, setIsErrorMessage] = useState(false)
    const [addModalFeedback, setAddModalFeedback] = useState(false)
    const [FeedbackError, setFeedbackError ] = useState(false)
    const [updateModalFeedback, setUpdateModalFeedback] = useState(false)
    const [feedbackValue, setFeedbackValue] = useState()
    const [isMessage, setIsMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        feather.replace()
    })
  
    const onFeedbackSubmit= async () => {
    if (feedbackValue){
      setFeedbackError(false)
      const payload = new FormData()
      payload.append('feedback[user_id]', user)
      payload.append('feedback[feedback]', feedbackValue)
      payload.append('feedback[interview_schedule_id]', interviewId)
      let url = '/interview_feedbacks.json'
      const res = await makeRequest(url, 'post', payload, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
        createResponseMessage: (responce) => {
          if(responce?.success){
            setModal(false)
            setViewFeedbackModal(false)
          }
          
            return {
                message: responce?.message
                    ? responce.message
                    : 'Feedback added successfully',
                messageType: 'success',
                loading: false,
                autoClose: true,
            }
        },
        })
    }else{
      setFeedbackError(true)
    }
  }
    return (
        <>
         <Modal
         className="scheduleModal"
         show={modal}
        //  onHide={() => setAddModalFeedback(false) || setUpdateModalFeedback(false)}
         aria-labelledby="contained-modal-title-vcenter"
         backdropClassName={styles.modalBackdrop}
         size="xl"
         style={{zIndex: '9999'}}
         centered
       >
         <Modal.Body style={{padding:'1rem'}}>
           <div className={`${styles.feedbackModal}`} >
             { isErrorMessage === false && addModalFeedback == false && <>
               <h4 class="interviewer-modalTitle">Add FeedBack</h4>
               <div
                 onClick={() => setModal(false)}
                 className={styles.cancelDelete}
               >    
                 <i className={styles.cancelIcon} data-feather="x" />
               </div>
                 <div className={`${styles.modalFeedbackText}`}>
                     Please Enter Your Feedback Here
                 </div>
                 <textarea type="text" className={`${styles.feedbackInputBox}`}
                   onChange={(e)=>{ setFeedbackValue(e.target.value)
                     setFeedbackError(false)}}
                   placeholder="Write feedback here..."
                   defaultValue={feedbackValue}
                 />
               <div>
                   <button onClick={onFeedbackSubmit}   disabled={loading}  className={`${styles.addModalFeedbackButton}`}> 
                    Add Feedback</button>
               </div>
               {FeedbackError && <p style={{color: "red",marginTop:"23px",marginLeft:"105px"}}>please enter feedback</p>}
             </>
             }
             {updateModalFeedback &&
               <>
                 <h4 class="modalTitle">Update FeedBack</h4>
                 <div
                 onClick={() => setUpdateModalFeedback(false)}
                 className={styles.cancelFeedbackButton}
               >    
                 <i className={styles.cancelIcon} data-feather="x" />
               </div>
                 <div className={`${styles.modalFeedbackText}`}>
                   Please Enter Your Feedback Here
                 </div>
                 <textarea type="text" className={`${styles.feedbackInputBox}`}
                   onChange={(e)=>{ setFeedbackValue(e.target.value)
                     setFeedbackError(false)}}
                   placeholder="Write feeback here..."
                //    defaultValue={updateModalFeedback}
                 />
                 <div className={`${styles.addModalFeedbackButton}`} onClick={handleSubmitUpdate}>
                   <div className={`${styles.addFeedbackText}`}> + Update Feedback </div>
                 </div>
                 {isMessage &&  
                   <Alert className={`${styles.feedbackSuccessMessage}`} variant={messageType}>{message}</Alert>
                 }
                 {FeedbackError && <p className={`${styles.feedbackUpdateError}`}>please update feedback</p>}
               </>  
             }
           </div>
         </Modal.Body>
      </Modal>  
        </>
    )
}
 
export default AddFeedback;