import React, { useEffect, useState } from 'react'
import P from '../Admin/shared/P'
import { Row,Col } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from './styles/AnalyticPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Table from '../Admin/shared/Table'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import moment from 'moment'
import Card from '../Admin/shared/Card'

const RecruitersAssignmentsDetailsAnalyticPage = (props) => {
  let recruiterId = window.location.pathname.split('/')
  recruiterId = recruiterId[recruiterId.length -1]
  const [recruitersData,setRecruitersData] = useState(props.recruiters_list.map((data,key)=> ({
    id: key,
    teamLead: data?.full_name && capitalize(data?.full_name),
    jobId: data.job_id,
    jobName: data.job_name,
    orgName: data.name,
    accName: data.acc_name,
    assignedOn: data.assigned_on
  })))
  const [totalValues,setTotalValues] = useState(props.total_values)
  const [membersRecruiter,setMembersRecruiter] = useState([{value: 'all', label: 'All'},...props.members_recruiter])
  const [selectedmembersRecruiter,setSelectedmembersRecruiter] = useState((recruiterId)? props.members_recruiter.filter((option) => option.value == recruiterId) :{value: 'all', label: 'All'})
  const [selectedRecruiter,setSelectedRecruiter] = useState(recruiterId)
  const [isLoading, setLoading] = useState(false)
  const [member_options, setOptions] = useState([])
  const [selectedOrganization, setSelectedOrganization]  = useState('')
  const [inputValue, setInputValue] = useState('')
  const [exportDatas, setExportDatas] = useState([])
  const csvExportRef = React.useRef();
  const [loading, setLoader] = useState(true)
  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  };
  const header = [
    { label: 'Team Lead', key: 'teamLead' },
    { label: 'Client Name', key: 'orgName' },
    { label: 'Client Account Manager', key: 'accName' },
    { label: 'Job Title', key: 'jobName' },
    { label: 'Assigned On', key: 'assignedOn' },
  ]

  const tableColumn = [
    { name: 'No.',field: 'id',editable: false,type: 'text' },
    { name: 'Team Lead',field: 'teamLead',editable: false,type: 'text' },
    { name: 'Client Name',field: 'orgName',editable: false,type: 'text'},
    { name: 'Client Account Manager',field: 'accName',editable: false,type: 'text'},
    { name: 'Job Title',field: 'jobName',editable: false,type: 'text'},
    { name: 'Assigned On',field: 'assignedOn',editable: false,type: 'text'}
  ]
  const fetchData = async () => {
    setLoader(true)
    const url = `/analytic/recruiters_assignments_analytic/${recruiterId}.json`
    const response = await makeRequest(
      `${url}?organization=${selectedOrganization}&member=${selectedRecruiter}`,
      'get',
      {}
    )
    setRecruitersData(response?.data?.recruiters.map((data,key)=> ({
      id: key,
      teamLead: data?.full_name && capitalize(data?.full_name),
      jobId: data.job_id,
      jobName: data.job_name,
      orgName: data.name,
      accName: data.acc_name,
      assignedOn: moment(data.assigned_on).format('YYYY-MM-DD')
    })))
    setTotalValues(response.data.total_values)
    setLoader(false)
  }

  useEffect(()=>{
    fetchData()
  },[selectedOrganization,selectedRecruiter])

  const loadOptions = (inputValue, callback) => {
    setLoading(true)
    fetch(`/admin/organizations/fetch_organization?search=${inputValue}`)
    .then((res) => res.json())
    .then((res) => {
      let {organizations} = res
      setLoading(false)
      setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(member_options);
  }

  const onSelectedMembersRecruiter = (event) => {
    setSelectedRecruiter(event.value)
}

const handleInputChange = (str) => {
  setInputValue(str)
  return str;
}

const handleSelectOption = (selectedOptions) => {
  setSelectedOrganization(selectedOptions?.value || '')
}

const exportDataMethod = async () => {
    await setExportDatas(recruitersData)
    csvExportRef?.current?.link.click();
}

  return (
    <Card>
      <div
        className="p-3 d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Recruiters Assignments Details
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
          <a
            href={'/analytic/recruiters_assignments_analytic'}
            className={`ml-1 ${styles.exportCSVButton}`}
          >
            Back
          </a>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={header}
        filename={"RecruitersAssignmentsDetailsAnalytic.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
              <div style={{display: 'grid'}}>
                  <label>
                    Recruiter Name
                  </label>
                  <Select
                      defaultValue={selectedmembersRecruiter}
                      options={membersRecruiter}
                      onChange={onSelectedMembersRecruiter}
                      name="recruiter_name"
                      className={styles.colourStyles}
                  />
              </div>
              <div style={{display: 'grid'}}>
                <label>
                  Organization
                </label>
                <AsyncSelect
                  isLoading={isLoading}
                  isClearable={true}
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions={selectedOrganization}
                  onInputChange={handleInputChange}
                  onChange={handleSelectOption}
                  placeholder={'Search by organization'}
                  styles={colourStyles}
                  noOptionsMessage={() => 'start typing the name of organization'}
              />
              </div>
          </div>
      </Col>
      </Row>
      <Table 
        columNames={tableColumn}
        rowValues={recruitersData.map((o)=>({
          ...o,
          jobName: (<a href={`/analytic/job_recruiters_analytic?job=${o.jobId}`}>{o.jobName}</a>),
          assignedOn: moment(o.assignedOn).format('MMM DD, YYYY'),
        }))
        }
        activePage='0'
        totalValues={totalValues}
        loading={loading}
      />
    </Card>
  )
}
export default RecruitersAssignmentsDetailsAnalyticPage