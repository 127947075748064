import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import styles from './styles/interviewscheduled.module.scss'
import modalstyle from './styles/interviewmodal.scss'
import CandidateManager from '../../common/CandidateManager/CandidateManager'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Badge, Card, Modal, Tab, Tabs } from 'react-bootstrap'
import Util from '../../../utils/util'
import LinkedinIcon from '../../../../assets/images/icons/linkedin.png'
import SubmittedIcon from '../../../../assets/images/icons/submitted.png'
import lastContactIcon from '../../../../assets/images/icons/lastcontact.png'
import applicationIcon from '../../../../assets/images/icons/application.png'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import lastActivityIcon from '../../../../assets/images/icons/lastactivity.png'
import feedbackIcon from '../../../../assets/images/icons/feedback.png'
import movetoIcon from '../../../../assets/images/icons/moveto.png'
import scheduleIcon from '../../../../assets/images/icons/newschedule.png'
import cancelIcon from '../../../../assets/images/icons/cancel.png'
import isEmpty from 'lodash.isempty'
import AddFeedback from './AddFeedback/Addfeedback'
import profileImage from '../../../../assets/images/icons/profile.png'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import './styles/InterviewSchedule.scss'
import './styles/PaginateInterview.scss'
// import { interviewStageTime } from '../../common/Utils'
import { capitalize } from '../../../utils'
import ViewInterviewFeedback from './ViewInterviewFeedback/ViewInterviewFeedback'
import { colors } from '@material-ui/core'
import InterviewRechedule from './InterviewReschedule/interviewReschedule'
import InterviewScheduleModal from '../../common/InterviewScheduleModal/InterviewScheduleModal'
import MoveToStage from './MoveToStage/movetostage'
import CancelInterviewModal from './CancelInterviewModal/cancelinterviewmodal'


const interviewScheduled = ({user}) => {
    const [errorSubmitting, setErrorSubmitting] = useState(null)
    const [candidate, setCandiate] = useState([])
    const [viewfeedbackModal, setViewFeedbackModal] = useState(false)
    const [data ,setData] = useState([])
    const [application, setApplication] = useState([])
    const [filterSkills, setfilterSkills] = useState([])
    const [modal , setModal] = useState(false)
    const [feedbackId, setFeedbackId] = useState([])
    const [updateInterviewModal, setUpdateInterviewModal] = useState(false)
    const [feedbackCancelModal, setFeedbackCancelModal] = useState(false)
    const [updateModalFeedback, setUpdateModalFeedback] = useState(false)
    const [updateFeedData, setUpdateFeedData] = useState()
    const [interviewRecheduleModal, setInterviewRescheduleModal] = useState(false)
    const [reschedule, setReschedule] = useState(false)
    const [candidateinterviewid, setCandidateInterviewId] = useState()
    const [cancelinterview, setCancelInterview] = useState() 
    const [interviewId, setInterviewId] = useState()
    const [movetostage, setMoveToStage] = useState(false)
    const [cancelinterviewModal, setCancelInterViewModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPagePast, setCurrentPagePast] = useState(0);
    const [dateRange, setDateRange] = useState({startDate: moment().startOf('month').toDate() ,endDate: moment().toDate()})
    const handleDate = (date) => {
        setDateRange(date)
    }
    const ITEMS_PER_PAGE = 10;
    
    const fetchData = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        console.log('fetch data')
        const url = `/interview_scheduled.json?start_date=${start_date}&end_date=${end_date}`
        const response = await makeRequest(
            `${url}`,
            'get',
            {}
        )
        setData(response?.data?.candidates)
    }
    const handlefeedbackCancel = (id) => {
        const url = `/interview_feedbacks/${id}`
        makeRequest(url, 'DELETE', '', {})
        .then((res) => {
          setFeedbackCancelModal(false)
          setViewFeedbackModal(false)
        //   handleFeedbackList(dataId)
        });
    }

    function parseStr(str) {
      return JSON.parse(str)
     }
    function interviewStageTimeSchedule(str) {
        const time_arr = parseStr(str)
        const time =  Array.isArray(time_arr) ? time_arr[0] : time_arr
        const {hour, minute, isAM, timeZone} = time
        const time_minute = minute == '' ? '00' :minute
        if((hour == '00' || hour == '') && (time_minute == '00' || time_minute == '')){
            return "-"
        }
        return hour +':'+time_minute+' '+isAM+' '+timeZone
      }

    const today_data = data?.filter((item) =>item?.interview_date == moment(new Date()).format("YYYY-MM-DD")).filter((item) => (item?.interview_status != 'reschedule') && (item?.interview_status != 'cancelled' ) && (item?.interview_type == 'first_interview'  || item?.interview_type == 'second_interview' ) )
    const upcomming_data = data?.filter((item) => (item?.interview_date > moment(new Date()).format("YYYY-MM-DD") || item?.interview_date == moment(new Date()).format("YYYY-MM-DD"))).filter((item) => (item?.interview_status != 'reschedule' ) && (item?.interview_status != 'cancelled' ) && (item?.interview_type == 'first_interview'  || item?.interview_type == 'second_interview' )  )
    const past_data = data?.filter((item) =>  (item?.interview_status == 'reschedule' ) || (item?.interview_status == 'cancelled' ) || (item?.interview_date < moment(new Date()).format("YYYY-MM-DD")) )
    
    const offset = currentPage * ITEMS_PER_PAGE;
    const paginatedData = upcomming_data?.slice(offset, offset + ITEMS_PER_PAGE);
    const totalPages = Math.ceil(upcomming_data.length / ITEMS_PER_PAGE);

    const offsetPast = currentPagePast * ITEMS_PER_PAGE;
    const paginatedDataPast = past_data?.slice(offsetPast, offsetPast + ITEMS_PER_PAGE);
    const totalPagesPast = Math.ceil(past_data.length / ITEMS_PER_PAGE);

    // Function to handle page change
    const handlePageChange = (newPage) => {
        if (newPage >= 0 && newPage < Math.ceil(upcomming_data.length / ITEMS_PER_PAGE)) {
            setCurrentPage(newPage);
        }
    };

    // Function to handle page change past data
    const handlePageChangePast = (newPage) => {
        if (newPage >= 0 && newPage < Math.ceil(past_data.length / ITEMS_PER_PAGE)) {
            setCurrentPagePast(newPage);
        }
    };

    useEffect(() =>{
        fetchData()
     },[dateRange])

    useEffect(() =>{
       fetchData()
    },[])

   const handleUpdateModalFeedback = () => {
    console.log('+++++++++++=.')
   }

   const onCancelInterview = () => {
    setCancelInterViewModal(true)
   }
     
    function canidateSkills(skill, index){
        return(
                    <>
                        <Badge
                            pill
                            key={index}
                            variant="secondary"
                            className="skills-button d-inline-block text-truncate"                                                                                            
                        >
                            <label className='candidate-skills'> {skill} </label>
                        </Badge>{'   '}
                    </>
        )
    }

    let skills = candidate?.skills?.toLowerCase().split(',');
    if(filterSkills?.length > 0){
        filterSkills?.map(skill =>{
            skills?.map((value,index) => {
                let cindex = (value.toLowerCase().trim()).indexOf(skill.toLowerCase())
                if(cindex != -1){
                    if(index >= 5){
                        let skillname = skills[index]
                        skills.splice(index,1);
                        skills.splice(0,0,skillname);
                    }
                }
            })
        })
    }
    return ( 
        (<Container className="p-0" fluid>
             <label
                className={styles.mainTitle + ' page-title'}
                style={{ marginBottom: '1.5rem' }}
            >
                <span>Interviews Scheduled</span><br/>
                {/* <span className={styles.subTitle}>Browse thousands of active and passive candidates</span> */}
            </label>
            
            <Container className={styles.newcontiner + ' pl-5 pr-5 pb-5 pt-2'} fluid>
                <Row>
                    <Col md="12"  className="" style={{textAlign: 'right'}}>
                        <div style={{display: 'grid',marginRight:'15px',float:'right'}}>
                            <label style={{textAlign: 'start'}}>
                                Select Date Range:
                            </label>
                            <DateRangePicker
                                handleOnSubmit={handleDate}
                                defaultDate={dateRange}
                            />
                        </div>
                    </Col>
                    <Col md="12"  className="">
                    <Tabs
                            defaultActiveKey="upcoming-tab"
                            className="organization-activity-tabs"
                            justify
                          >
                            <Tab  eventKey="upcoming-tab" 
                              title={
                                <React.Fragment>
                                  Upcoming
                         {upcomming_data.length > 0 && <Badge variant="light" style={{marginLeft:'3px',color:'#f8f9fa', backgroundColor:'#4e69fa'}}>{upcomming_data?.length}</Badge>}
                         </React.Fragment>
                              }
                             >
                                <Col>
                                {paginatedData && paginatedData.map((candidates, index) => (
                            <Card style={{marginBottom: '20px', cursor:'default'}}   className={styles.candidateProfileCard} >
                                                     
                        <Card.Body className='candidate-list'>
                            <div className='card-body d-flex justify-content-between'>
                          <div className='first-div'>
                        <div className='row' style={{marginBottom: '11px'}} >
                             <CandidateAvatarIcon candidate={candidate} firstName={candidates?.first_name} lastName={candidates?.last_name} />
                            
                            <div className={styles.cardBody + 'container'} >
                                <h5 className={styles.cardBodyName}>
                                <span onClick={(e) => clickHandler(candidate)}>{ Util.handleUndefinedFullName(candidates?.first_name, candidates?.last_name) }</span>
                                </h5>
                                <p className={styles.OrgNameText} >{capitalize(candidates?.org_name)}</p>
                                <p className={styles.jobNameText} style={{fontSize: '16px'}}>{candidates.job_name} </p>
                                <h5 style={{fontSize: '16px'}}>{candidates.title }</h5>
                                <p style={{fontSize: '13px'}} >{candidates.location }</p>
                            </div>
                        </div>
                        {candidates.skills &&
                        <div className='container row'>
                            <div>
                                <h6 className={styles.skillTitle}>Skills:</h6>
                            </div>
                            <div className={styles.badge}>
                                { canidateSkills(candidates.skills, index)}
                            </div> 
                        </div>}
                        {(candidates?.interview_type == 'first_interview' || candidates?.interview_type == 'second_interview') &&
                        <div
                                className="container row interview-option-icon"
                                style={{ alignItems: 'center' }}
                            >
                                    <div  className="activity-field"
                                    onClick={() =>{
                                        setCandidateInterviewId(candidates)
                                        setReschedule(true)
                                        setInterviewRescheduleModal(true)
                                    }}
                                    >
                                        <h6
                                            style={{
                                                padding: '12px 12px',
                                                display:'flex',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <img  style={{height:'23px', width:'25px'}} src={scheduleIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitleIcon
                                                }
                                                style={{
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                        cursor: 'pointer'
                                                }}
                                            >
                                                Reschedule
                                            </label>
                                     
                                        </h6>
                                    </div>
                                     <div className="activity-field"
                                       onClick={() => {
                                        setCancelInterview(true)
                                        setCandidateInterviewId(candidates)
                                       }}
                                     >
                                        <h6
                                            style={{
                                                padding: '12px 12px',
                                                display:'flex',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <img  style={{height:'25px', width:'25px'}} src={cancelIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitleIcon
                                                }
                                                style={{
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                        cursor: 'pointer'
                                                }}
                                            >
                                                {' '}
                                                Cancel Interview{' '}
                                            </label>
                                        </h6>
                                    </div>
                            </div> }
                    
                    </div>
                    <div className='' >
                        {candidates.interview_date &&
                        <div className='container row'>
                            <div>
                                <h6 className={styles.skillTitle}>Interview Date:</h6>
                            </div>
                            <div className={styles.badge}>
                            {moment(candidates?.interview_date).format('dddd, MMM DD,YYYY')}
                            </div> 
                        </div>}
                        {candidates.interview_time &&
                        <div className='container row'>
                            <div>
                                <h6 className={styles.skillTitle}>Interview Time:</h6>
                            </div>
                            <div className={styles.badge}>
                            {interviewStageTimeSchedule(candidates?.interview_time ? candidates?.interview_time : '')}
                            </div> 
                        </div>}
                            {candidates.interview_type &&
                            <div className='container row'>
                               
                                <div className={styles.interviewType}>
                                {candidates?.interview_type ? capitalize(candidates?.interview_type).replace("_", " ") : ''}
                                </div> 
                            </div>}
                        </div>
                        </div>

                </Card.Body>    
                </Card>
                    ))}
                          {upcomming_data?.length <= 0   &&  <Card style={{marginBottom: '20px'}}   className={styles.candidateProfileCard}>
                                <Card.Body>
                                <Row className="align-items-center d-flex justify-content-center">
                                                        <div className='text-center d-flex justify-content-center mt-1'>
                                                            <p>No Record Found</p>
                                                       </div>
                                    </Row>
                                            </Card.Body>
                            </Card>}

                        {upcomming_data?.length > ITEMS_PER_PAGE && (
                            <div className="pagination">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 0}
                                >
                                    Previous
                                </button>
                                {[...Array(totalPages).keys()].map((page) => (
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        className={currentPage === page ? 'active' : ''}
                                    >
                                        {page + 1}
                                    </button>
                                ))}
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}


                                </Col>
                            </Tab>
                            <Tab  eventKey="past-tab"
                             title={
                                <React.Fragment>
                                  Past
                         {past_data.length > 0 && <Badge  variant="light" style={{marginLeft:'3px', color:'#f8f9fa', backgroundColor:'#4e69fa'}}>{past_data?.length}</Badge>}
                         </React.Fragment>
                              }
                              >
                                <Col>
                    {paginatedDataPast && paginatedDataPast.map((candidates, index) => (
                        <Card style={{marginBottom: '20px', cursor:'default'}}   className={styles.candidateProfileCard} >
                        <Card.Body className='candidate-list'>
                            <div className='card-body d-flex justify-content-between'>
                          <div className='first-div'>
                        <div className='row' style={{marginBottom: '11px'}} >
                            <CandidateAvatarIcon candidate={candidate} firstName={candidates?.first_name} lastName={candidates?.last_name} />
                            <div className={styles.cardBody + 'container'} >
                                <h5 className={styles.cardBodyName}>
                                <span onClick={(e) => clickHandler(candidate)}>{ Util.handleUndefinedFullName(candidates?.first_name, candidates?.last_name) }</span>
                                </h5>
                                <p className={styles.OrgNameText} >{capitalize(candidates?.org_name)}</p>
                                <p className={styles.jobNameText} style={{fontSize: '16px'}}>{candidates.job_name} </p>
                                <h5 style={{fontSize: '16px'}}>{candidates.title }</h5>
                                <p style={{fontSize: '13px'}} >{candidates.location }</p>
                            </div>
                        </div>
                        {candidates.skills &&
                        <div className='container row'>
                            <div>
                                <h6 className={styles.skillTitle}>Skills:</h6>
                            </div>
                            <div className={styles.badge}>
                                { canidateSkills(candidates.skills, index)}
                            </div> 
                        </div>}
                        {((candidates?.interview_type == 'first_interview' || candidates?.interview_type == 'second_interview') && (candidates?.interview_status == '')) &&

                        <div
                                className="container row"
                                style={{ alignItems: 'center' }}
                            >
                                <div
                                    className="activity-class"
                                    style={{ display: 'contents' }}
                                >
                          <div  className="activity-field"
                                    onClick={() =>{
                                        setInterviewId(candidates?.id) 
                                        setViewFeedbackModal(true)
                                    }}
                                    >
                                        <h6
                                            style={{
                                                padding: '12px 12px',
                                                display:'flex',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <img style={{height:'25px', width:'25px'}} src={feedbackIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitleIcon
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                }}
                                            >
                                                Feedback
                                            </label>
                                     
                                        </h6>
                                    </div>

                                    <div  className="activity-field"
                                    onClick={() =>{
                                        setReschedule(true)
                                        setCandidateInterviewId(candidates)
                                        setInterviewRescheduleModal(true)
                                    }}
                                    >
                                        <h6
                                            style={{
                                                padding: '12px 12px',
                                                display:'flex',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <img  style={{height:'23px', width:'25px'}} src={scheduleIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitleIcon
                                                }
                                                style={{
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                        cursor: 'pointer'
                                                }}
                                            >
                                                Reschedule
                                            </label>
                                     
                                        </h6>
                                    </div>
                                     <div className="activity-field"
                                       onClick={() => {
                                        setCancelInterview(true)
                                        setCandidateInterviewId(candidates)
                                       }}
                                     >
                                        <h6
                                            style={{
                                                padding: '12px 12px',
                                                display:'flex',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <img  style={{height:'25px', width:'25px'}} src={cancelIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitleIcon
                                                }
                                                style={{
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                        cursor: 'pointer'
                                                }}
                                            >
                                                {' '}
                                                Cancel Interview{' '}
                                            </label>
                                        </h6>
                                    </div>
                                </div>
                            </div> }
                    </div>
                    <div className='' >
                        {candidates.interview_date &&
                        <div className='container row'>
                            <div>
                                <h6 className={styles.skillTitle}>Interview Date:</h6>
                            </div>
                            <div className={styles.badge}>
                            {moment(candidates?.interview_date).format('dddd, MMM DD,YYYY')}
                            </div> 
                        </div>}
                        {candidates?.interview_time &&
                        <div className='container row'>
                            <div>
                                <h6 className={styles.skillTitle}>Interview Time:</h6>
                            </div>
                            <div className={styles.badge}>
                            {interviewStageTimeSchedule(candidates?.interview_time ? candidates?.interview_time : '')}
                            </div> 
                        </div>}
                        {candidates?.interview_status != '' ? 
                            <div className='container row'>
                                <div className={candidates?.interview_status == 'cancelled' ? styles.cancellInterviewType : styles.interviewType}>
                                    {candidates?.interview_status ? capitalize(candidates?.interview_status).replace("_", " ") : ''}
                                </div>  
                            </div>
                            :
                            <div className='container row'>
                                <div className={styles.interviewType}>
                                    {candidates?.interview_type ? capitalize(candidates?.interview_type).replace("_", " ") : ''}
                                </div>  
                            </div>
                        }

                        </div>
                        </div>

                </Card.Body>    
                </Card>
                    ))}
                     {past_data?.length <= 0   &&  <Card style={{marginBottom: '20px'}}   className={styles.candidateProfileCard}>
                                <Card.Body>
                                <Row className="align-items-center d-flex justify-content-center">
                                                        <div className='text-center d-flex justify-content-center mt-1'>
                                                            <p>No Record Found</p>
                                                       </div>
                                    </Row>
                                            </Card.Body>
                            </Card>}

                            {past_data?.length > ITEMS_PER_PAGE && (
                            <div className="pagination">
                                <button
                                    onClick={() => handlePageChangePast(currentPagePast - 1)}
                                    disabled={currentPagePast === 0}
                                >
                                    Previous
                                </button>
                                {[...Array(totalPagesPast).keys()].map((page) => (
                                    <button
                                        key={page}
                                        onClick={() => handlePageChangePast(page)}
                                        className={currentPagePast === page ? 'active' : ''}
                                    >
                                        {page + 1}
                                    </button>
                                ))}
                                <button
                                    onClick={() => handlePageChangePast(currentPagePast + 1)}
                                    disabled={currentPagePast === totalPagesPast - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}

                                </Col>
                            </Tab>
                          </Tabs>  
                          {viewfeedbackModal ? 
                        <ViewInterviewFeedback
                        viewfeedbackModal={viewfeedbackModal}
                        setViewFeedbackModal={setViewFeedbackModal}
                        setModal={setModal}
                        interviewId={interviewId}
                        setFeedbackCancelModal={setFeedbackCancelModal}
                        setFeedbackId={setFeedbackId}
                        feedbackId={feedbackId}
                        feedbackCancelModal={feedbackCancelModal}
                        handlefeedbackCancel={handlefeedbackCancel}
                        handleUpdateModalFeedback={handleUpdateModalFeedback}
                        setUpdateInterviewModal={setUpdateInterviewModal}
                        updateInterviewModal={updateInterviewModal}
                        setUpdateFeedData={setUpdateFeedData}
                        updateFeedData={updateFeedData}
                        />
                        : ''
                        }            

                         {modal ? 
                        <AddFeedback
                        setModal={setModal}
                        modal={modal}
                        user={user}
                        interviewId={interviewId}
                        setViewFeedbackModal={setViewFeedbackModal}
                        handleUpdateModalFeedback={handleUpdateModalFeedback}
                        setUpdateInterviewModal={setUpdateInterviewModal}
                        updateInterviewModal={updateInterviewModal}
                        />
                        :""}
                       {interviewRecheduleModal ? 
                       <InterviewScheduleModal
                       interviewRecheduleModal={interviewRecheduleModal}
                       reschedule={reschedule}
                       setReschedule={setReschedule}
                       candidateinterviewid={candidateinterviewid}
                       setInterviewRescheduleModal={setInterviewRescheduleModal}
                       styles={styles}
                       />
                      : ''
                       }
                       { movetostage ?
                       <MoveToStage
                       movetostage={movetostage}
                       setMoveToStage={setMoveToStage}
                       candidateinterviewid={candidateinterviewid}

                       />
                        : ''
                       }
                       {cancelinterviewModal ? 
                         <CancelInterviewModal
                         cancelinterviewModal={cancelinterviewModal}
                         setCancelInterViewModal={setCancelInterViewModal}
                         candidateinterviewid={candidateinterviewid}
                         />
                         : ''
                        }
                       {cancelinterview &&
                          <SweetAlert
                            warning
                            id
                            className='sweet-alert-cancel-interview'
                            showCancel
                            confirmBtnText="Yes"
                            confirmBtnBsStyle="danger"
                            title="Are you sure you want to Cancel Interview?"
                            onConfirm={()=> (onCancelInterview(),setCancelInterview(false))}
                            onCancel={()=>setCancelInterview(false)}
                            focusCancelBtn
                            >
                            {/* You will not be able to recover! */}
                        </SweetAlert> }
                    </Col>
                </Row>
            </Container>
        </Container>)
    )
}

export default interviewScheduled
