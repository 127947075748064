import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction};
    flex-wrap: wrap;
    align-items: center;
    flex: ${(props) => (props.flex ? props.flex : 'unset')};
    justify-content: ${(props) => (props.jContent ? props.jContent : 'unset')};
`

export const Box = styled.div`
    display: flex;
`
export const Row = styled(Box)`
    display: flex;
    flex-direction: ${(props) => props.direction};
    flex-wrap: wrap;
    width: 100%;
    justify-content: ${(props) => (props.jContent ? props.jContent : 'unset')};
    align-items: ${(props) => (props.aItems ? props.aItems : 'unset')};
    margin-bottom: 20px;
`
export const W4text = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) => props.size};
    color: ${(props) => props.color};
    display: flex;
`
export const Error = styled.span`
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`

export const Required = styled.p`
 color: red;
  display: inline;
  font-size: 15px;
`
export const InfoSpan = styled.span`
font-size: 12px;
margin-left: 4px;
`
export const Button = styled.button`
background: linear-gradient(
        94.67deg,
        #5f78ff -1.19%,
        #7185f2 53.94%,
        #8d91ff 102.59%
    );
    border-radius: 20px;
    padding: 9px 25px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    &:disabled {
        background: lightgray;
    }

`
