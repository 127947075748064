import React, { useEffect, useReducer, useRef, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import Card from '../../Admin/shared/Card'
import { BlockBody, BlockHeader } from './styles/Dashboard.styled'
import { capitalize } from '../../../../utils'
import P from '../../Admin/shared/P'

const index = ({data, information}) => {
    console.log(data)
    // const information = props.information
    // const data = data
    const [formContent, setFormContent] = useState(
        information.map((resource) => ({
            name: resource.name,
            todayCount: resource.today_count,
            totalCount: resource.total_count,
        }))
    )
    return (
        <Card>
        <P size="30px" height="41px">
            Agency Dashboard
        </P>
        <div style={{ marginTop: '50px', display: 'flex' }}>
            {formContent.map((resource, idx) => (
                <div key={idx} style={{ marginLeft: '50px' }}>
                    <BlockHeader width={200}>
                        <P size="16px" height="27px" color={resource.name === "candidates" ?
                            "red" : resource.name === "jobs" ? "blue" :
                            resource.name === "recruiters" ? "grey" : 
                            resource.name === "organizations" ? "orange" : resource.name === 'Total Hired' ? 'purple' :
                            resource.name === 'Total Offer' ? 'darkblue' :
                            "#1D2447"
                        }
                        >
                            {capitalize(resource.name)}
                        </P>
                    </BlockHeader>
                    <BlockBody>
                        <P size="10px" height="14px" color="#3F446E">
                            Today
                        </P>
                        <P
                            size="20px"
                            height="27px"
                            color="#3F446E"
                            marginTop="5px"
                        >
                            {resource.todayCount}
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="10px" height="14px" color="#3F446E">
                            Total
                        </P>
                        <P
                            size="20px"
                            height="27px"
                            color="#3F446E"
                            marginTop="5px"
                        >
                            {resource.totalCount}
                        </P>
                    </BlockBody>
                </div>
            ))}
        </div>
        <div style={{ marginTop: '80px' }}>
            <P
                color="#1D2447"
                size="18px"
                height="25px"
                marginBottom="20px"
                center
            >
                Activity in last 7 days
            </P>
            <div>
                <LineChart width={1000} height={300} data={data}>
                    <Line
                        type="linear"
                        dataKey="candidates_count"
                        stroke="red"
                        dot={false}
                    />
                    <Line
                        type="linear"
                        dataKey="jobs_count"
                        stroke="blue"
                        dot={false}
                    />
                    <Line
                        type="linear"
                        dataKey="recruiters_count"
                        stroke="green"
                        dot={false}
                    />
                    <Line
                        type="linear"
                        dataKey="organizations_count"
                        stroke="orange"
                        dot={false}
                    />
                      <Line
                        type="linear"
                        dataKey="total_hired_count"
                        stroke="purple"
                        dot={false}
                    />
                      <Line
                        type="linear"
                        dataKey="total_offer_count"
                        stroke="darkblue"
                        dot={false}
                    />
                    <Tooltip />
                    <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                </LineChart>
            </div>
        </div>
    </Card>
    )
}

export default index