import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const LeadSourcerPage = (prop) => {
    const queryParams = new URLSearchParams(window.location.search)
    const urlRecruiterId = queryParams.get("recruiter")
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    const jobId = queryParams.get("job")
    let sourcer_start_date = sessionStorage.getItem("sourcer_start_date");
    let sourcer_end_date = sessionStorage.getItem("sourcer_end_date")
    let sourcer_recruiter = sessionStorage.getItem("sourcer_recruiter")
    let sourcer_job = sessionStorage.getItem("sourcer_job")
    let sourcer_org = sessionStorage.getItem("sourcer_org")
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(prop.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [organization,setOrganization] = useState([])
    const [selectedOrganization, setSelectedOrganization]  = useState(prop.organization ? prop.organization[0].value  : sourcer_org && sourcer_org != "null" ? JSON.parse(sourcer_org).value : '')
    const [selectedOrganizationValue, setSelectedOrganizationValue]  = useState(prop.organization ? prop.organization[0]  : sourcer_org && sourcer_org != "null" ? JSON.parse(sourcer_org) : '')
    const [jobs,setJobs] = useState([{value: 'all', label: 'All'},...prop.jobs_list])
    const [selectedJob,setSelectedJob] = useState(sourcer_job && sourcer_job != "null" ? JSON.parse(sourcer_job).value : jobId ? jobId : '')
    const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'},...prop.recruiter_list])
    const [selectedRecruiter,setSelectedRecruiter] = useState((urlRecruiterId) ? urlRecruiterId : sourcer_recruiter && sourcer_recruiter != "null" ? JSON.parse(sourcer_recruiter).value : '')
    const [selected,setSelected] = useState((jobId)? prop.jobs_list.filter((option) => option.value == jobId) : sourcer_job && sourcer_job != "null" ? JSON.parse(sourcer_job) : {value: 'all', label: 'All'})
    const [defaultselected,setdefaultselected] = useState((urlRecruiterId)? prop.recruiter_list.filter((option) => option.value == urlRecruiterId) : sourcer_recruiter && sourcer_recruiter != "null" ? JSON.parse(sourcer_recruiter) : {value: 'all', label: 'All'})
    const [jobStatus, setJobStatus] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [member_options, setOptions] = useState(prop.organization ? [prop.organization]  : [])
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate : sourcer_start_date ? sourcer_start_date : moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : sourcer_end_date ? sourcer_end_date : moment().toDate()})
    const [loading, setLoder] = useState(true)
    const handleDate = (date) => {
        setDateRange(date)
    }
    const csvExportRef = React.useRef();
    const [exportDatas, setExportDatas] = useState([])
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const headers = [
        { label: 'Sourcer Name', key: 'sourcerName' },
        { label: 'Organization', key: 'organization' },
        { label: 'Job Name', key: 'jobName' },
        { label: 'Submitted', key: 'submitted' },
        { label: 'First Interview', key: 'firstInterview' },
        { label: 'Second Interview', key: 'secondInterview' },
        { label: 'On Hold', key: 'onhold' },
        { label: 'Offers', key: 'offers' },
        { label: 'Hired', key: 'hired' }
    ];
    const fetchData = async () => {
        setLoder(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("sourcer_start_date", start_date)
        sessionStorage.setItem("sourcer_end_date", end_date)
        const url = '/admin/lead_sourcer.json'
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&organization=${selectedOrganization}&recruiter=${selectedRecruiter}&job=${selectedJob}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setJobStatus(response?.data?.sorcers.map((data,key)=> (sr_no = sr_no + 1,{
            id: sr_no,
            sourcerName: `${data?.sourcer_name ? `${capitalize(data?.sourcer_name)}` : '-' }`,
            organization:  capitalize(data?.org_name),
            jobName: `${data?.job_name ? `${capitalize(data?.job_name)}` : '-' }`,
            submitted: data.submitted > 0 ? <a href={`/admin/sourcer_analytic_detail?job_id=${data.jobs_id}&organization=${data.org_id}&recruiter=${data.account_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a> : '',
            firstInterview: data.first_interview > 0 ? <a href={`/admin/sourcer_analytic_detail?job_id=${data.jobs_id}&organization=${data.org_id}&recruiter=${data.account_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> : '',
            secondInterview: data.second_interview > 0 ? <a href={`/admin/sourcer_analytic_detail?job_id=${data.jobs_id}&organization=${data.org_id}&recruiter=${data.account_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> : '',
            onhold: data.onhold > 0 ? <a href={`/admin/sourcer_analytic_detail?job_id=${data.jobs_id}&organization=${data.org_id}&recruiter=${data.account_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=onhold`}>{data.onhold}</a> : '',
            offers: data.offer > 0 ? <a href={`/admin/sourcer_analytic_detail?job_id=${data.jobs_id}&organization=${data.org_id}&recruiter=${data.account_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a>: '',
            hired: data.hired > 0 ? <a href={`/admin/sourcer_analytic_detail?job_id=${data.jobs_id}&organization=${data.org_id}&recruiter=${data.account_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> : ''
        })))
        setFooter({id:'Total',
            submitted:response.data.total_values.submitted,
            firstInterview:response.data.total_values.firstInterview,
            secondInterview:response.data.total_values.secondInterview,
            onhold:response.data.total_values.onhold,
            offers:response.data.total_values.offers,
            hired:response.data.total_values.hired
        })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoder(false)
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '4%',
            minWidth: '4%'
        },
        {
            name: 'Sourcer name',
            selector: row => row.sourcerName,
            cell: (row) => <div title={row.sourcerName}>{row.sourcerName}</div>,
            sortable: true,
            sortField: 'sourcer_name',
            maxWidth: '18%',
            minWidth: '18%',
            wrap:true
        },
        {
            name: 'Organization',
            selector: row => row.organization,
            cell: (row) => <div title={row.organization}>{row.organization}</div>,
            sortable: true,
            sortField: 'org_name',
            maxWidth: '18%',
            minWidth: '18%',
            wrap:true
        },
        {
            name: 'Job Name',
            selector: row => row.jobName,
            cell: (row) => <div title={row.jobName}>{row.jobName}</div>,
            sortable: true,
            sortField: 'job_name',
            maxWidth: '18%',
            minWidth: '18%',
            wrap:true
        },
        
        {
            name: 'Submitted',
            selector: row => row.submitted,
            cell: (row) => <div title={row.submitted}>{row.submitted}</div>,
            sortable: true,
            sortField: 'submitted',
            maxWidth: '8%',
            minWidth: '8%'
        },
        {
            name: 'First Interview',
            selector: row => row.firstInterview,
            cell: (row) => <div title={row.firstInterview}>{row.firstInterview}</div>,
            sortable: true,
            sortField: 'first_interview',
            maxWidth: '9%',
            minWidth: '9%'
        },
        {
            name: 'Second Interview',
            selector: row => row.secondInterview,
            cell: (row) => <div title={row.secondInterview}>{row.secondInterview}</div>,
            sortable: true,
            sortField: 'second_interview',
            maxWidth: '9%',
            minWidth: '9%'
        },
        {
            name: 'On Hold',
            selector: row => row.onhold,
            cell: (row) => <div title={row.onhold}>{row.onhold}</div>,
            sortable: true,
            sortField: 'onhold',
            maxWidth: '9%',
            minWidth: '9%'
        },
        {
            name: 'Offers',
            selector: row => row.offers,
            cell: (row) => <div title={row.offers}>{row.offers}</div>,
            sortable: true,
            sortField: 'offer',
            maxWidth: '8%',
            minWidth: '8%'
        },
        {
            name: 'Hired',
            selector: row => row.hired,
            cell: (row) => <div title={row.hired}>{row.hired}</div>,
            sortable: true,
            sortField: 'hired',
            maxWidth: '8%',
            minWidth: '8%'
        }
    ]

    const exportDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("sourcer_start_date", start_date)
        sessionStorage.setItem("sourcer_end_date", end_date)
        const url = '/admin/lead_sourcer.json'
        const response = await makeRequest(
            `${url}?organization=${selectedOrganization}&recruiter=${selectedRecruiter}&job=${selectedJob}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setExportDatas(response?.data?.sorcers.map((data,key)=> ({
            id: key,
            sourcerName: `${data?.sourcer_name ? `${capitalize(data?.sourcer_name)}` : '-' }`,
            organization:  capitalize(data?.org_name),
            jobName: `${data?.job_name ? `${capitalize(data?.job_name)}` : '-' }`,
            submitted: data.submitted,
            firstInterview: data.first_interview,
            secondInterview: data.second_interview,
            onhold: data.onhold,
            offers: data.offer,
            hired: data.hired
        })))
        csvExportRef?.current?.link.click();
    }

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/admin/organizations/fetch_organization?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {organizations} = res
          setLoading(false)
          setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(member_options);
    }

    const handleInputChange = (str) => {
        setInputValue(str)
        return str;
    }

    const handleSelectOption = (selectedOptions) => {
        setActivePage(1)
        setSelectedOrganization(selectedOptions?.value || '')
        setSelectedOrganizationValue(selectedOptions || {})
        sessionStorage.setItem("sourcer_org", JSON.stringify(selectedOptions))
    }

    useEffect(()=>{
        fetchData()
    },[selectedOrganization,selectedRecruiter,selectedJob,dateRange,activePage,sortDirection,sortField])

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
    };

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Sourcer Analytics
                </P>
                <div>
                    <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                        Export CSV
                    </button>
                    {jobId && (
                        <a
                            href='javascript:void(0)'
                            onClick={()=>history.back()}
                            className={`ml-1 ${styles.exportCSVButton}`}
                        >
                            Back
                        </a>
                    )}
                </div>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"Sourcer_analytic.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
                    <div>
                        <label>
                            Sourcer
                        </label>
                        <Select
                            defaultValue={defaultselected}
                            options={recruiter}
                            onChange={(event)=>{
                                setSelectedRecruiter(event.value)
                                sessionStorage.setItem("sourcer_recruiter", JSON.stringify(event))
                            }}
                            name="recruiter"
                            className={styles.colourStyles}
                        />
                    </div>
                    <div>
                        <label>
                            Organization
                        </label>
                        <AsyncSelect
                            isLoading={isLoading}
                            isClearable={true}
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultValue={selectedOrganizationValue}
                            defaultOption={selectedOrganization}
                            onInputChange={handleInputChange}
                            onChange={handleSelectOption}
                            placeholder={'Search by organization'}
                            styles={colourStyles}
                            noOptionsMessage={() => 'start typing the name of organization'}
                        />
                    </div>
                    <div style={{marginLeft:'10px'}}>
                        <label>
                            Job Name
                        </label>
                        <Select
                            defaultValue={selected}
                            options={jobs}
                            onChange={(event)=>{
                                setSelectedJob(event.value)
                                sessionStorage.setItem("sourcer_job", JSON.stringify(event))
                            }}
                            name="job_name"
                            className={styles.colourStyles}
                        />
                    </div>
                    
                    <div style={{display:'grid'}}>
                        <label>
                            Date
                        </label>

                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobStatus}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                responsive={true}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
        
    )
}

export default LeadSourcerPage