import React from 'react'
import Util from '../../../utils/util'
import './styles/CandidateAvatarIcon.scss'

const CandidateAvatarIcon = ({ firstName,lastName, clickHandler, candidate }) => {

    const getNameChar = () => {
        return `${firstName && Util.getFirstCharater(firstName)}${lastName && Util.getFirstCharater(lastName)}`
    }

    return (
        <>
            <div onClick={()=> clickHandler(candidate)}  className={`avatar${Util.getFirstCharater(firstName)} candidateAvtarImage`}>{ getNameChar() }</div>
        </>
    )
}
export default CandidateAvatarIcon
