import React, { useState, useEffect } from 'react'
import moment from 'moment'
import P from '../../Admin/shared/P'
import { BlockBody, BlockHeader, DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import Select from 'react-select'
import { Col,Spinner } from 'react-bootstrap'
import DataTable from "react-data-table-component-footer"
import   '../../Admin/styles/DataTableCustom.scss'
import feather from 'feather-icons'
import CreateProfilePage from '../../CreateProfilePage/CreateProfilePage'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import SearchBar from '../../../common/SearchBar/SearchBar'
import Card from '../../Admin/shared/Card'


const OrganizationManagement = ({per_page}) => {
    var active_page = window.sessionStorage.getItem('agency_admin_org_activePage')
    var query = window.sessionStorage.getItem('agency_admin_org_query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [startRecord, setStartRecord] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [organizations, setOrganizations] = useState([])
    const [showOrganizationRequests, setShowOrganizationRequests] = useState(false)
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [todayPendingCount,setTodayPendingCount] = useState(0)
    const [pendingCount,setPendingCount] = useState(0)
    const [regionsCount,SetRegionsCount] = useState([])
    const [industriesCount,setIndustriesCount] = useState()
    const [totalOrganization,setTotalOrganization] = useState()
    const [totalApproved,setTotalApproved] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [status,setStatus] = useState('')
    const [orgCount,setOrgCount] = useState(0)
    const [showOrgCreatePage, setShowOrgCreatePage] = useState(false)
    const [orgRole, setOrgRole] = useState({role: 'employer'})
    const [loading, setLoading] = useState(true)
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]
    const [expiredCount,setExpiredCount] = useState(0)
    const [rejectCount,setRejectCount] = useState(0)
    const [selectedStatus,setSelectedStatus] = useState({value: '', label: 'Status'})
    const [sortField, setSortField] = useState('organizations.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(per_page)

    useEffect(() => {
        feather.replace()
    })

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const statusOptions = [
        {value: '', label: 'All'},
        { value: 'approved', label: 'Approved' },
        { value: 'declined', label: 'Reject' },
        { value: 'expired', label: 'Expired' }
    ]

    const fetchData = () => {
        setLoading(true)
        setOrganizations([])
        setPageCount(0)
        window.sessionStorage.setItem('agency_admin_org_activePage',activePage)
        const url = `/agencies/admin/reports/organizations.json?page=${activePage}&search=${searchTerm}&status=${status}&sortField=${sortField}&sortDirection=${sortDirection}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setTotalOrganization(res.data.total_count)
                let sr_no = (activePage - 1) * res.data.per_page
                setOrganizations(
                    res.data.organizations.map((org) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        id: org.id,
                        agency_name: capitalize(org.agency_name),
                        name: capitalize(org.name),
                        industry: org.industry,
                        company_size: org.company_size,
                        owner: org.owner.full_name,
                        created_at: moment(org.created_at).format('MMM DD, YYYY'),
                        status: capitalize((org.status ==='declined') ? 'rejected': org.status),
                        member_organization: !org?.member_organization_id ? '': 'CardinalTalent',
                        active_jobs: (<a>{org.active_jobs}</a>),
                        closed_jobs: (<a>{org.closed_jobs}</a>),
                        expired_job: (<a>{org.expired_job}</a>),
                        organization_type: capitalize((org.organization_type === 'N/A' ) ? '-': org.organization_type),
                    }))
                )
                setPageCount(res.data.total_pages)
                setTodayPendingCount(res.data.today_pending_organization)
                setPendingCount(res.data.pending)
                SetRegionsCount(res.data.region_count)
                setIndustriesCount(res.data.industry_count)
                setTotalApproved(res.data.approved)
                setCurrentCounts(res.data.current_counts)
                setOrgCount(res.data.org_count)
                setStartRecord((activePage - 1) * res.data.per_page)
                setExpiredCount(res.data.expired)
                setRejectCount(res.data.declined)
                setLoading(false)
            },
        })
    }

    const fetchAppropriateData = () => {
        if (showOrganizationRequests) {
            fetchOrganizationRequests()
        } else {
            fetchData()
        }
    }

    useEffect(() => {
        fetchAppropriateData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage, showOrganizationRequests,status,sortDirection,sortField])

    const switchToOrganizationRequests = () => {
        setShowOrganizationRequests(true)
        setActivePage(1)
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('agency_admin_org_query', value)
    }

    const handleSelectStatus = (selectedval) =>{
        setActivePage(1)
        setStatus(selectedval.value)
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#F6F7FC',marginLeft:'10px',border: 'none',height:'40px',width: '230px' })
    }

    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '4%',
            minWidth: '4%',
        },
        {
            name: 'Agency Name',
            selector: row => row.agency_name,
            wrap:true,
            maxWidth: '18%',
            minWidth: '18%',
            sortable: true,
            sortField: 'organizations.agency_id',
        },
        {
            name: 'Organization Name',
            selector: row => row.name,
            wrap:true,
            maxWidth: '20%',
            minWidth: '20%',
            sortable: true,
            sortField: 'organizations.name',
        },
        {
            name: 'Active Jobs',
            selector: row => row.active_jobs,
            maxWidth: '10%',
            minWidth: '10%',
        },
        {
            name: 'Closed Jobs',
            selector: row => row.closed_jobs,
            maxWidth: '10%',
            minWidth: '10%'
        },
        {
            name: 'Expired Jobs',
            selector: row => row.expired_job,
            maxWidth: '10%',
            minWidth: '10%'
        },
        {
            name: 'Created On',
            selector: row => row.created_at,
            maxWidth: '14%',
            minWidth: '14%',
            sortable: true,
            sortField: 'organizations.created_at',
        },
        {
            name: 'Status',
            selector: row => row.status,
            maxWidth: '14%',
            minWidth: '14%'
        },
    ]

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Organization Management
                </P>
            </div>

            { showOrgCreatePage ?
                (   <>
                        <CreateProfilePage 
                            user = {orgRole}
                            isProfileComplete = {false}
                            style = {"height: 100%; align-items: center; justify-content:center"}
                            showInfoPanel = {false}
                        />
                    </>
                ) : (
                <>
            <div style={{ marginTop: '50px', marginBottom:'50px', display: 'flex' }}>
                <div style={{ marginLeft: '50px' }}>
                    <BlockHeader>
                        <P size="16px" height="27px" color="#3F446E" style={{textAlign:'center'}}>
                            Organizations
                        </P>
                    </BlockHeader>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                            Total
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                            { totalApproved + pendingCount + rejectCount + expiredCount }
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                            Approved
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                           {totalApproved}
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                        Today
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                        {todayPendingCount}
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                        Reject
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                            {rejectCount}
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                        Expired
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                                {expiredCount}
                        </P>
                    </BlockBody>
                </div>
                <div style={{ marginLeft: '50px' }}>
                    {regionsCount &&
                        <>
                        <BlockHeader width={300} style={{marginLeft:'10%'}} marginBottom={-15}>
                            <P size="16px" height="27px" color="#3F446E">
                                Region Wise Organization
                            </P>    
                        </BlockHeader>
                        <PieChart width={380} height={380}>
                            <Pie
                                data={regionsCount}
                                labelLine={false}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {regionsCount.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip/>
                        </PieChart>
                        </>
                    }
                </div>
                <div style={{ marginLeft: '50px' }}>
                    {industriesCount &&
                        <>
                            <BlockHeader width={300} style={{marginLeft:'10%'}} marginBottom={-15}>
                                <P size="16px" height="27px" color="#3F446E">
                                Industry Wise Organization
                                </P>    
                            </BlockHeader>
                            <PieChart width={380} height={380}>
                                <Pie
                                data={industriesCount}
                                labelLine={false}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {industriesCount.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip/>
                            </PieChart>
                        </>
                    }
                </div>
            </div>

            <div style={{display: 'flex',width: '100%',justifyContent: 'space-between',
                alignItems: 'baseline',
                marginBottom: '15px'}}>
                <Col xs={12} sm={12} lg={7} style={{float:'left'}}>
                    {  (!showOrganizationRequests) && totalOrganization > 0 ? 
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalOrganization ? totalOrganization : currentCounts } of {totalOrganization}</DisplayPagination>
                    : ''
                    }
                </Col>
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                
                    <Select
                        defaultValue={selectedStatus}
                        options={statusOptions}
                        onChange={handleSelectStatus} 
                        placeholder={'Select Status'} 
                        styles={colourStyles}
                    />
                </Col>
                 <Col xs={12} sm={12} lg={3} style={{float:'left'}}>
                    <SearchBar
                            placeholder="Search organizations..."
                            value={searchTerm}
                            onChange={(e) => onChangeSearchTerm(e.target.value)}
                            onEnterPressed={() =>
                                activePage == 1
                                    ? fetchAppropriateData()
                                    : setActivePage(1)
                            }
                            onButtonClick={() =>
                                activePage == 1
                                    ? fetchAppropriateData()
                                    : setActivePage(1)
                            }
                    />
                </Col>

              
            </div>           
                <DataTable
                    title={false}
                    persistTableHead={true}
                    noDataComponent={<p className="text-center p-3">No record found</p>}
                    columns={columns}
                    data={organizations}
                    progressPending={loading}
                    progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner className='spinner' animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </P>}
                    onSort={handleSort}
                    sortServer
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalOrganization}
                    paginationPerPage={perPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    onChangePage={page => setActivePage(page)}
                /> 
          </>
          )
      }
        </Card>
    )
}

export default OrganizationManagement