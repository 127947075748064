import React, { useEffect, useState } from 'react'
import Card from '../../Admin/shared/Card'
import P from '../../Admin/shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from '../../Admin/styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../../utils'
import { CSVLink } from "react-csv";
import MonthRangePicker from '../../../common/MonthRangePicker/MonthRangePicker.js'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   '../../Admin/styles/DataTableCustom.scss'

const OrganizationsAnalyticPage = (props) => {
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(props.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [account_managers,setAccountManagers] = useState([{value: 'all', label: 'All'},...props.account_managers])
    const [selectedTeamLead,setSelectedTeamLead] = useState('')
    const [jobStatus, setJobStatus] = useState([])
    const [dateRange, setDateRange] = useState({startDate:  moment().startOf('month').toDate() ,endDate: moment().toDate()})
    const csvExportRef = React.useRef();
    const [exportDatas, setExportDatas] = useState([])
    const [loading, setLoading] = useState(true)
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const handleDate = (date) => {
        setDateRange(date)
    }
    const [defaultselected,setdefaultselected] = useState({value: 'all', label: 'All'})
    const [organizationType,setOrganizationType] = useState([{value: 'all', label: 'All'},{value: 'hourly', label: 'Hourly'},{value: 'contingency', label: 'Contingency'}])
    const [selectedOrganizationType,setSelectedOrganizationType] = useState('')

    const headers = [
        { label: 'Organization', key: 'organization' },
        { label: 'Account Manager', key: 'accountManager' },
        { label: 'No of Jobs', key: 'no_of_jobs' },
        { label: 'Leads', key: 'leads' },
        { label: 'Applicants', key: 'applicants' },
        { label: 'Recruiter Screened', key: 'recruiterScreened' },
        { label: 'Submitted', key: 'submitted' },
        { label: 'First Interview', key: 'firstInterview' },
        { label: 'Second Interview', key: 'secondInterview' },
        { label: 'Offers', key: 'offers' },
        { label: 'Hired', key: 'hired' },
        { label: 'Archived', key: 'archived' },
    ];

    const fetchData = async () => {
        setLoading(true)
        const url = '/agencies/analytic/organization.json'
        let start_date = moment(moment(dateRange.startDate).startOf('month').toDate()).format('YYYY-MM-DD')
        let end_date =  moment(moment(dateRange.startDate).endOf('month').toDate()).format('YYYY-MM-DD')
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&account_manager_id=${selectedTeamLead}&organization_type=${selectedOrganizationType}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        let lastDay = new Date(dateRange.startDate.getFullYear(), dateRange.startDate.getMonth() + 1, 0)
        setJobStatus(response?.data?.org_status.map((data,key)=> (sr_no = sr_no + 1,{
            id: sr_no,
            organization: capitalize(data?.organizations_name && capitalize(data?.organizations_name)),
            leads: (data.lead) ? <a href={`/agencies/analytic/organization/detail?organization_id=${data.organizations_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=lead`}>{data.lead}</a> :'',
            applicants: (data.applicant) ? <a href={`/agencies/analytic/organization/detail?organization_id=${data.organizations_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=applicant`}>{data.applicant}</a> :'',
            recruiterScreened: (data.recruitor_screened) ? <a href={`/agencies/analytic/organization/detail?organization_id=${data.organizations_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> :'',
            submitted:  (data.submitted > 0) ? <a href={`/agencies/analytic/organization/detail?organization_id=${data.organizations_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a> :'',
            firstInterview: (data.first_interview > 0) ? <a href={`/agencies/analytic/organization/detail?organization_id=${data.organizations_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> : '',
            secondInterview: (data.second_interview > 0) ? <a href={`/agencies/analytic/organization/detail?organization_id=${data.organizations_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> : '',
            offers: (data.offer > 0) ? <a href={`/agencies/analytic/organization/detail?organization_id=${data.organizations_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a> : '',
            archived: (data.reject > 0) ? <a href={`/agencies/analytic/organization/detail?organization_id=${data.organizations_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> : '',
            accountManager:capitalize(data.account_manager_name),
            no_of_jobs: data.no_of_jobs,
            hired: (data.hired > 0) ? <a href={`/agencies/analytic/organization/detail?organization_id=${data.organizations_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> : '',
        })))
        setFooter({id:'Total',
            applicants:response.data.total_values.applicants,
            leads:response.data.total_values.leads,
            recruiterScreened:response.data.total_values.recruiterScreened,
            submitted:response.data.total_values.submitted,
            firstInterview:response.data.total_values.firstInterview,
            secondInterview:response.data.total_values.secondInterview,
            offers:response.data.total_values.offers,
            archived:response.data.total_values.archived,
            hired:response.data.total_values.hired
        })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoading(false)
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '4%',
            minWidth: '4%'
        },
        {
            name: 'Organization',
            selector: row => row.organization,
            maxWidth: '12%',
            minWidth: '12%',
            wrap:true,
            sortable: true,
            sortField: 'organizations_name'
        },
        {
            name: 'Account Manager',
            selector: row => row.accountManager,
            maxWidth: '14%',
            minWidth: '14%',
            wrap:true,
            sortable: true,
            sortField: 'account_manager_name'
        },
        {
            name: 'No of Jobs',
            selector: row => row.no_of_jobs,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'no_of_jobs'
        },
        {
            name: 'Leads',
            selector: row => row.leads,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'lead'
        },
        {
            name: 'Applicants',
            selector: row => row.applicants,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'applicant'
        },
        {
            name: 'Recruiter Screened',
            selector: row => row.recruiterScreened,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'recruitor_screened'
        },
        {
            name: 'Submitted',
            selector: row => row.submitted,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'submitted'
        },
        {
            name: 'First Interview',
            selector: row => row.firstInterview,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'first_interview'
        },
        {
            name: 'Second Interview',
            selector: row => row.secondInterview,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'second_interview'
        },
        {
            name: 'Offers',
            selector: row => row.offers,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'offer'
        },
        {
            name: 'Hired',
            selector: row => row.hired,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'hired'
        },
        {
            name: 'Archived',
            selector: row => row.archived,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'reject'
        }
    ]

    const exportDataMethod = async () => {
        const url = '/agencies/analytic/organization.json'
        let start_date = moment(moment(dateRange.startDate).startOf('month').toDate()).format('YYYY-MM-DD')
        let end_date =  moment(moment(dateRange.startDate).endOf('month').toDate()).format('YYYY-MM-DD')
        const response = await makeRequest(
            `${url}?account_manager_id=${selectedTeamLead}&organization_type=${selectedOrganizationType}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setExportDatas(response?.data?.org_status.map((data,key)=> ({
            id: key,
            organization: data?.organizations_name && capitalize(data?.organizations_name),
            leads: data.lead,
            applicants: data.applicant,
            recruiterScreened: data.recruitor_screened,
            submitted: data.submitted,
            firstInterview: data.first_interview,
            secondInterview: data.second_interview,
            offers: data.offer,
            archived: data.reject,
            accountManager:data.account_manager_name,
            no_of_jobs: data.no_of_jobs,
            hired: data.hired
        })))
        csvExportRef?.current?.link.click();
    }

    useEffect(()=>{
        fetchData()
    },[dateRange,activePage, selectedTeamLead,sortDirection,sortField,selectedOrganizationType])

    const onSelectedTeamLead = (event) => {
        setSelectedTeamLead(event.value)
    }

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',marginLeft:'10px',minHeight:'40px' })
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Organizations Analytics
                </P>
                <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                    Export CSV
                </button>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"organizations_analytic.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount} Organizations</DisplayPagination>
                    }
                </Col>
                <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'grid',marginLeft:'10px'}}>
                            <label>
                               Account Manager
                            </label>
                            <Select
                                // defaultValue={selected}
                                options={account_managers}
                                onChange={onSelectedTeamLead}
                                name="team_lead"
                                className={styles.colourStyles}
                            />
                        </div>
                        <div style={{display: 'grid',marginLeft:'10px'}}>
                            <label>
                                Month
                            </label>

                            <MonthRangePicker
                                handleOnSubmit={handleDate}
                                defaultDate={dateRange}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobStatus}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
    )
}

export default OrganizationsAnalyticPage
