import React, { useState, useEffect, useContext, useReducer } from 'react';
import axios from 'axios';
import Text from '../../common/Styled components/Text';
import Card from '../Admin/shared/Card';
import Button from '../../common/Styled components/Button';
import CloseButton from '../../common/Styled components/CloseButton';
import EntryCard from './EntryCard';
import { Box, Row, Container } from './styles/TimeSheet.styled';
import { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import TimeEntryForm from './TimeEntryForm';
import DateFilter from './DateFilter';

import {initialState, reducer, TimeEntryContext} from '../../../stores/TimeEntryStore'

function TimeEntryPage({user}) {
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState)
  const {entries, meta, loading, dateRange, error} = state
  const {from, to, missingDays, totalHours, organizationId, organization} = meta
  const {startDate, endDate} = dateRange

  useEffect(() => {
    const URL = `/users/${user.id}/timesheet.json?start_date=${startDate}&end_date=${endDate}`
    axios.get(URL).then(res => {
      console.log('> sucess: ', res.data)
      dispatch({
        type: 'UPDATE_STATE',
        value: {recruiterId: user.id, organizationId: user.organization_id, entries: res.data.entries, meta:  res.data.meta}
      });
    });
  }, startDate, endDate);
  
  return (
    <TimeEntryContext.Provider value={{state, dispatch}}>
      { loading ? (
        <div className='container' style={{textAlign: 'center', marginTop: '100px'}}>
          <h2>Loading....</h2> 
          <div id="loading" />
        </div>
      ) : (
      <Card>
        <Row gutterButtom aItems="center" jContent="space-between">
          <Text varient={'h1'} color="primary" size={'28px'}>
            Time Entry
          </Text>
        </Row>
        <Row gutterButtom aItems="center" jContent="space-between">
          <Box>
            <Text varient={'p'} color="primary" size={'20px'}>
              Missing Days: {missingDays}
            </Text>
          </Box>
          <Box className='d-none'>
            <Button>Export</Button>
          </Box>
        </Row>
        <Row gutterButtom aItems="center" jContent="space-between">
          <Box>
            <Text varient={'p'} color="primary" size={'20px'}>
              Weekly Hours: {totalHours}
            </Text>
          </Box>
          <Box aItems="center" jContent="space-between">
            <DateFilter >{`${startDate} - ${endDate}`}</DateFilter>
          </Box>
        </Row>
  
        <Row>
          {/* {entries.map((entry, index) => ( */}
            {/* <Fragment key={index}> */}
              <EntryCard />
            {/* </Fragment> */}
          {/* ))} */}
        </Row>
        <Row jContent="flex-end" className='d-none'>
          <Button size="small" color="success" onClick={() => setIsOpen(true)} shadow={true}>
            <h2 style={{ margin: '0px 1px' }}>+</h2>
          </Button>
        </Row>
  
        <Modal show={isOpen} size="xl" onHide={() => setIsOpen(false)}  centered>
          <TimeEntryForm>
            <Row jContent="space-between" gutterTop>
              <Text varient="h2" color="primary" size={'30px'} givePadding style={{ minWidth: '200px' }}>
                Create Timesheet
              </Text>
              <CloseButton handleClick={() => setIsOpen(false)} />
            </Row>
          </TimeEntryForm>
        </Modal>
      </Card>
      )
      }
    </TimeEntryContext.Provider>
  );
}

export default TimeEntryPage;
