import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from '../../Admin/shared/Card'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import AsyncSelect from "react-select/async"
import CandidateSkills from '../../../common/CandidateSkills/CandidateSkills'
import Select from 'react-select'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row,Col,Spinner,Modal,Popover,OverlayTrigger } from 'react-bootstrap'
import '../Jobs/styles/index.scss'
import { Formik } from 'formik'
import SearchTextBox from '../../Admin/shared/SearchTextBox'
import { capitalizeFirstLetter } from '../../../../utils'
import isEmpty from 'lodash.isempty'
import DataTable from "react-data-table-component-footer"
import   '../Jobs/styles/DataTableCustom.scss'
import feather from 'feather-icons'
import CandidateUpload from './CandidateUpload'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'

const JobsIndex = () => {
    var active_page = window.localStorage.getItem('agency_activePage')
    var query = window.localStorage.getItem('agency_query')
    var status = window.localStorage.getItem('agency_status')
    var organization = window.localStorage.getItem('agency_organization')
    var owner = window.localStorage.getItem('agency_owner')
    var wishlist = window.localStorage.getItem('agency_wishlist')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [pageCount, setPageCount] = useState(0)
    const [jobs, setJobs] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [isLoading, setLoading] = useState(false)
    let organization_data = organization && JSON.parse('['+organization+']')
    let owner_data = owner && JSON.parse(owner)
    const [selectedOrganization, setSelectedOrganization]  = useState(organization_data ? organization_data.map( ({value, label}) =>value) :[])
    const [filterOrganization, setFilterOrganization] = useState(organization_data && organization_data || [])
    const [filterOwner, setFilterOwner] = useState(owner_data && owner_data)
    const [selectedStatus, setSelectedStatus]  = useState('all')
    const [member_options, setOptions] = useState([])
    const [owner_options, setOwnerOptions] = useState([])
    const [selectedOwner, setSelectedOwner] = useState(owner_data && owner_data.value)
    const [selected, setSelected] = useState({value: status || 'all', label: status && capitalizeFirstLetter(status) || 'All'})
    const [selectedWishlist, setSelectedWishlist] = useState({value: wishlist || 'all', label: (wishlist) &&  (wishlist == 'all' ? 'All' : ((wishlist == 'true' ? 'Yes' : 'No') ) || 'All')})
    const [totalJobs,setTotalJobs] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [loading, setLoader] = useState(true)
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const [selectedWishlistSourcing, setSelectedWishlistSourcing]  = useState(wishlist || 'all')
    const [sortField, setSortField] = useState('jobs.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(25)
    const [show,setShow] = useState(false)
    const [selectJob,setSelectJob] = useState(false)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showFormSequnce, setShowFormSequnce] = useState(false)
    const [listMixmaxAc, setListMaxmaxAc] = useState([])
    const [selectedMixMaxAc, setSelectedMixMaxAc] = useState()
    const [defaultMixMaxAc, setDefaultMixMaxAc] = useState()
    const [sequnceList, setSequnceList] = useState([])
    const [selectedSequnce, setSelectedSequnce] = useState()
    const [defaultSequnce, setDefaultSequnce] = useState()
    const [validationError, setValidationError] = useState({})
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)

    useEffect(() => {
        feather.replace()
    })

    const fetchData = (clearFlag=false) => {
        setLoader(true)
        const url = `/agencies/jobs.json`
        window.localStorage.setItem('agency_activePage',activePage)
        makeRequest(url, 'get',  {params: {page: (activePage), query: query, status: status, organization: selectedOrganization, owner: selectedOwner, wishlist: selectedWishlistSourcing, sortField: sortField, sortDirection: sortDirection }}, {   
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setTotalJobs(res.data.total_count)
                let sr_no = (activePage - 1) * res.data.per_page
                setJobs(
                    res.data.jobs.map((job) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        id: job.id,
                        name:(<a href={`/agencies/jobs/${job.id}`}>{capitalize(job.name)}</a>),
                        skills:  (job.skills != '') ? <CandidateSkills skills = {job.skills} /> :job.skills,
                        organization: (<a href={`/agencies/organizations/${job.organization_id}`}>{job.organization_name}</a>),
                        expiry_date:  moment(job.expiry_date).format('MMM DD, YYYY'),
                        created_at: moment(job.created_at).format('MMM DD, YYYY'),
                        status:  (<label style={{color: (job.status == "closed" || job.status == "expired") ? "red" :""  }}>{capitalize(job.status)}</label>),
                        create_lead: (job.create_lead === true) ? 'True' : 'False',
                        organization_id: job.organization_id,
                        full_name: (job?.first_name) ? `${job?.first_name} ${job?.last_name}` : '',
                        wishlist_sourcing: (job.source_at) ? 'Yes' : 'No',
                        actions: (<div className='w-100 d-inline-flex justify-content-around'>
                            <a  href={`/agencies/jobs/${job.id}/edit`} title="Edit" className='pr-4'><i data-feather="edit-2" /></a>
                            <a  onClick={() =>showConfirmDelete(job)} title="Delete" href={`javascript::void()`} className='pr-4'><i data-feather="trash-2" /></a>
                            {/* <a  onClick={() => uploadCandidate(job)} title="Upload Candidate" href={`javascript::void()`} className='pr-4'><i data-feather="plus-circle" /></a> */}
                            {/* <a  onClick={() =>showSequnceForm(job)} title="Mixmax Sequnce Id" href={`javascript::void()`} ><i data-feather="codesandbox" /></a> */}
                      </div>)
                    }))
                )
                setPageCount(res.data.total_pages)
                setCurrentCounts(res.data.current_counts)
                setStartRecord((activePage - 1) * res.data.per_page)
                setLoader(false)
            },
        })
    }

    const showSequnceForm = (job) => {
        // Reset all state
       
        setListMaxmaxAc([])
        setSelectedMixMaxAc()
        setDefaultMixMaxAc()
      
        setSequnceList([])
        setSelectedSequnce()
        setDefaultSequnce()

        setSelectJob(job)
        getMixmaxAcoount(job)
        if(job.mixmax_config_id != ''){
            getListSequnce(job.mixmax_config_id,job.mixmax_sequnce_id)
            setSelectedMixMaxAc(job.mixmax_config_id)
            setSelectedSequnce(job.mixmax_sequnce_id)
        }
        setShowFormSequnce(true)     
    }

    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '6%',
            minWidth: '6%',
        },
        {
            name: 'Job Name',
            selector: row => row.name,
            wrap:true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'jobs.name',
        },
        {
            name: 'Primary Owner',
            selector: row => row.full_name,
            wrap:true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'users.first_name',
        },
        {
            name: 'Skills',
            selector: row => row.skills,
            wrap:true,
            maxWidth: '20%',
            minWidth: '20%',
        },
        {
            name: 'Organization',
            selector: row => row.organization,
            wrap:true,
            maxWidth: '15%',
            minWidth: '15%',
            sortable: true,
            sortField: 'organizations.name',
        },
        {
            name: 'Expiry On',
            selector: row => row.expiry_date,
            maxWidth: '9%',
            minWidth: '9%',
            sortable: true,
            sortField: 'jobs.expiry_date',
        },
        {
            name: 'Created On',
            selector: row => row.created_at,
            wrap:true,
            maxWidth: '9%',
            minWidth: '9%',
            sortable: true,
            sortField: 'jobs.created_at',
        },
        {
            name: 'Status',
            selector: row => row.status,
            maxWidth: '9%',
            minWidth: '9%'
        },
        {
            name: 'Action',
            selector: row => row.actions,
            maxWidth: '12%',
            minWidth: '12%',
        },
    ]

    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,selectedStatus,selectedOrganization, selectedOwner,selectedWishlistSourcing,sortDirection,sortField])

    const deleteJobs = async () => {
        setShowRemoveConfirmation(false)
        let id = selectJob.id
        const url = `/agencies/jobs/${id}`
        await makeRequest(url, 'delete', '', {
            createSuccessMessage: () => 'Job deleted successfully',
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: () => {      
                setTimeout(()=>{
                    fetchData()
                },2000)
            },      
        })      
    }       

    const uploadCandidate = (job) => {
        setShow(true)
        setSelectJob(job)
    }

    const addJobs = () => {
        window.location.href = '/agencies/jobs/new'
    }

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/agencies/jobs/fetch_agency_organization?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {organizations} = res
          setLoading(false)
          setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(member_options);
    }

    const loadOwnerOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/agencies/jobs/job_owner?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {owners} = res
          setLoading(false)
          setOwnerOptions([...owners.map((owner) => ({ value: owner.id, label: `${owner.first_name} ${owner.last_name}` }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(owner_options);
    }

    const handleOwnerOption = (selectedOptions) => {
        setSelectedOwner(selectedOptions && selectedOptions.value)
        setActivePage(1)
        window.localStorage.setItem('agency_activePage',1)
        window.localStorage.setItem('agency_owner', JSON.stringify(selectedOptions && selectedOptions || ''))
    }
    
    const handleSelectOption = (selectedOptions) => {
        console.log(selectedOptions)
        setSelectedOrganization([...selectedOptions.map( ({value, label}) =>value)])
        // setFilterOrganization([...selectedOptions.map( ({value, label}) => ([...{'value':value, 'label':label}]))])
        setActivePage(1)
        window.localStorage.setItem('agency_activePage',1)
        window.localStorage.setItem('agency_organization',[...selectedOptions.map( ({value, label}) => JSON.stringify({'value':value, 'label':label}))])
    }
    const handleSelectStatus = (selectedval) =>{
        setActivePage(1)
        window.localStorage.setItem('agency_activePage',0)
        setSelectedStatus(selectedval.value)
        window.localStorage.setItem('agency_status',selectedval.value)
    }
    const handleSelectedWishlistSourcing = (selectedval) => {
        setActivePage(1)
        window.localStorage.setItem('agency_activePage',0)
        setSelectedWishlistSourcing(selectedval.value)
        window.localStorage.setItem('agency_wishlist',selectedval.value)
    }
    const filterOptions = [
        { value: 'all', label: 'All'},
        { value: 'active', label: 'Active' },
        { value: 'expired', label: 'Expired' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'closed', label: 'Closed' }
    ]
    const filterOptionsWishlist = [
        { value: 'all', label: 'All'},
        { value: 'true', label: 'Yes'},
        { value: 'false', label: 'No'}
    ]

    const onSearchChange = (val) =>{
        setSearchTerm(val)
        window.localStorage.setItem('agency_query', val)
    }

    const onSearchClick = (val) => {
        if(searchTerm != ""){
            setSearchTerm("")
            window.localStorage.setItem('agency_query', "")
            fetchData(true)
        }
    }

    const colourStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px' })
    };

    const colourStatusStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px' })
    };

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }
    
    const showConfirmDelete = (job) => {
        setSelectJob(job)
        setShowRemoveConfirmation(true)
    }

 
      
    const saveSeqeunce = async () => {
        setBtnDisabled(false)
        let errors = {}
        let isFormValid = true
        if(selectedSequnce == '' || selectedSequnce == null || selectedSequnce == 'null'){
            errors["sequnce"] = "Sequence is required field."
            isFormValid = false
        }
    
        if (selectedMixMaxAc == '' || selectedMixMaxAc == null || selectedMixMaxAc == 'null'){
            isFormValid = false
            errors["mixmax_ac"] = "Mixmax account is required field."
        }
        setValidationError(errors)
        setBtnDisabled(isFormValid)
    
        if(isFormValid){
            const formData = new FormData()
            for (const key in selectJob) {
                if (key == 'mixmax_sequnce_id') {
                    formData.append(`job[${key}]`, selectedSequnce)
                } else if (key == 'mixmax_config_id') {
                    formData.append(`job[${key}]`, selectedMixMaxAc)
                }else{
                    formData.append(`job[${key}]`, selectJob[key])
                }
            }
            const url = `/agencies/jobs/update_sequnce/${selectJob.id}`
            await makeRequest(url, 'put', formData, {
                createSuccessMessage: () => 'Job update successfully ',
                onSuccess: (res) => {
                    if(res.data.code == 200){
                        setShowFormSequnce(false)
                        fetchData()
                    }
                    setBtnDisabled(false)
                },
            })
        }
    }
      
    const getListSequnce = async (id,sequnce_id) => {
        if(id != ''){
            const url = `/agencies/sequences/mixmax/${id}.json`
            setLoadingModal(true)
            try {
                const response = await axios.get(url,'',
                {
                    headers: {
                        'content-type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                });
                if(response?.data?.api_data){
                    let sequmceArray = (response.data.api_data).map(({ name, _id }) => {
                    return { value: _id, label: name };
                    })
                    setSequnceList(sequmceArray)
                    if(sequnce_id != '' && sequmceArray.length > 0){
                        let selectSequnce = sequmceArray.filter((row)=>(row.value == sequnce_id ))
                        setDefaultSequnce((selectSequnce[0]) || {})
                    }
                }
                setLoadingModal(false) 
            } catch (e) {
                console.error(e)
            }
        }
    }

    const getMixmaxAcoount = async (job) => {
        if(job.id != ''){
            const url = `sequences/mixmax_ac/${job.id}`
            try {
                setLoadingModal(true)
                const res = await axios.get(url,'',
                {
                    headers: {
                        'content-type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                });
                console.log(res)
                if(res?.data?.list){
                    if(res?.data?.list){
                        let obj = (res?.data?.list).filter((row) => (row.value == job.mixmax_config_id))
                        setListMaxmaxAc(res.data.list)
                        setDefaultMixMaxAc((obj[0]) ? obj[0] : '')
                    }
                    setBtnDisabled(false)
                }
                setLoadingModal(false) 
            } catch (e) {
                console.error(e)
            }
        }
      
    }
    
    const onSelecteMixmax = (e) => {
        setSelectedMixMaxAc(e.value)
        getListSequnce(e.value,'')
        setDefaultMixMaxAc(e)
        setSequnceList([])
        setSelectedSequnce()
        setDefaultSequnce()
        
    }

    const onSelecteSequnce = (e) => {
        setSelectedSequnce(e.value)
        setDefaultSequnce(e)
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '20px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Jobs List
                </P>
                <div style={{flexGrow:1}}/>
                <Button onClick={addJobs} className="ml-3">
                    Add Job
                </Button>
            </div>
            <Row className="w-100 justify-content-between" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline',flexDirection: 'row-reverse' }}>
            <Col xs={12} sm={12} lg={2} style={{display:'contents'}}>
                <div>
                    <Formik>
                        <SearchTextBox type="search" value={searchTerm} name="jobsearch" placeholder="Search job..."  onChange={(e) => onSearchChange(e.target.value)}  onClick={(e) => onSearchClick(e.target.value)}  onKeyPress={(e) => {
                            window.localStorage.setItem('activePage',0)
                            setActivePage(1)
                            e.key === 'Enter' && fetchData()
                    }}   />
                    </Formik>
                    <Button onClick={() =>
                            activePage == 1
                                ? fetchData()
                                : setActivePage(1)
                        } className="" style={{marginLeft: '10px'}}>
                        Search
                    </Button>
                </div>
                </Col>
            </Row>
            <Row className="w-100 justify-content-between mb-2" style={{ display: 'flex',alignItems: 'baseline', }}>
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    {totalJobs > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalJobs}</DisplayPagination>
                    }
                 </Col>
                 
                 <Col xs={12} sm={12} lg={2}>
                    <AsyncSelect
                        isMulti
                        isLoading={isLoading}
                        isClearable={true}
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={handleSelectOption}
                        placeholder={'Organization'}
                        defaultValue={filterOrganization}
                        styles={colourStyles}
                        noOptionsMessage={() => 'start typing the name of organization'}
                    />
                 </Col>
                 <Col xs={12} sm={12} lg={2}>
                    <AsyncSelect
                        isLoading={isLoading}
                        cacheOptions
                        isClearable={true}
                        loadOptions={loadOwnerOptions}
                        onChange={handleOwnerOption}
                        placeholder='Primary owner'
                        defaultValue={filterOwner}
                        styles={colourStyles}
                        noOptionsMessage={() => 'start typing the name of owner'}
                    />
                 </Col>
                <Col xs={12} sm={12} lg={2}>
                    <Select 
                    defaultValue={selected}
                    options={filterOptions} 
                    onChange={handleSelectStatus} 
                    placeholder={'Select Status'} 
                    styles={colourStatusStyles}
                    />
                </Col>
            </Row>
            <DataTable
                title={false}
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobs}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalJobs}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
            />   

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size={'xl'}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <Modal.Body>
                <CandidateUpload
                    job={selectJob}
                    onClose={() => setShow(false)}
                />
                </Modal.Body>
            </Modal>

            {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to delete?"
                    onConfirm={()=>(deleteJobs(),setShowRemoveConfirmation(false))}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this job!
                </SweetAlert>
            }
            <Modal
                onHide={() => (setShowFormSequnce(false),setBtnDisabled(false))}
                show={showFormSequnce}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                dialogClassName="modal-60w"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="mx-auto text-center">
                        Set Sequence Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                    <form >
                        <Row className="w-100">
                            <Col  lg="12" md="12" sm="12" xs="12" className="text-center">
                                <h5>{selectJob.name}</h5>
                            </Col>
                        </Row>
                        <Row className="w-100" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <Col  lg="12" md="12" sm="12" xs="12">
                                <label>Select Mixmax Account :</label>
                                <Select
                                options={listMixmaxAc}
                                value={defaultMixMaxAc}
                                onChange={onSelecteMixmax}
                                name="api_key"
                                style={colourStyles}
                                menuPosition='fixed'
                                placeholder='Select Mixmax Account'
                                isLoading={loadingModal}
                            />
                            {validationError && validationError.mixmax_ac && 
                                <label className='error-validation'>
                                {validationError && validationError.mixmax_ac} 
                                </label>
                            }
                            </Col>
                        </Row>
                        <Row className="w-100" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <Col  lg="12" md="12" sm="12" xs="12">
                                <label>Select Sequence :</label>
                                <Select
                                options={sequnceList}
                                value={defaultSequnce}
                                onChange={onSelecteSequnce}
                                name="mixmax_sequnce_id"
                                style={colourStyles}
                                menuPosition='fixed'
                                placeholder='Select Sequence'
                                isLoading={loadingModal}
                                />
                                {validationError && validationError.sequnce &&  
                                <label className='error-validation'>
                                    {validationError && validationError.sequnce} 
                                </label>
                                }
                            </Col>
                        </Row>
                        <br/>
                    </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100 text-right" style={{ marginBottom: '10px', marginTop: '20px' }}>
                        <Col  lg="12" md="12" sm="12" xs="12">
                        <button className='btn btn-info' onClick={ () => (setBtnDisabled(false),setShowFormSequnce(false)) }>
                            Cancel
                        </button>
                        <button className='btn btn-primary ml-3' disabled={btnDisabled} onClick={ () => saveSeqeunce() }>
                            { selectJob.mixmax_sequnce_id != '' ? 'Update' : 'Add' }
                        </button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </Card>
    )
}

export default JobsIndex