import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, Form, Row, Spinner ,ProgressBar} from "react-bootstrap";
import Webcam from 'react-webcam';
import maryImage from '../../../../../assets/images/mary.png';
import interviewImage from '../../../../../assets/images/ai_interview/aiinterview.png';
import interviewGif from '../../../../../assets/images/ai_interview/aiinterview.gif';

const PracticeInterviewScreeningPage = ({setScreenInterviewModal, screenInterviewModal,interviewId, organization}) => {
    const [interviewStarted, setInterviewStarted] = useState(false); // For starting interview
    const [recording, setRecording] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [recordedVideos, setRecordedVideos] = useState([]); // Store individual question-answer videos
    const [progress, setProgress] = useState(0); // Progress percentage
    const [time, setTime] = useState(3 * 60); // Initialize with 15 minutes (900 seconds)
    const [doneEnabled, setDoneEnabled] = useState(false); // Enable/Disable "Done Answering"
    const [isSpeaking, setIsSpeaking] = useState(false); // Track when AI is speaking
    const [transcripts, setTranscripts] = useState([]); // Store transcripts for each question
    const [currentTranscript, setCurrentTranscript] = useState('');  // Store real-time transcript
    const [loading, setLoading] = useState(true);
    const [recognition, setRecognition] = useState(null); // Speech recognition instance
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null); // MediaRecorder for individual recordings
    const fullInterviewRecorderRef = useRef(null); // MediaRecorder for full interview
    const videoChunks = useRef([]); // Store chunks for individual question videos
    const fullInterviewChunks = useRef([]); // Store chunks for full interview
    const [fullInterviewURL, setFullInterviewURL] = useState(null); // Store full interview video URL
    const [voicesLoaded, setVoicesLoaded] = useState(false);

    const questions = [
        "Hello! I am Mary AI Interviewer. Welcome, excited to get to know you. Could you briefly introduce yourself?",
        "Why should we hire you?"
    ];

    // Function to speak the current question using the Web Speech API
    const speakQuestion = (text) => {
        if ('speechSynthesis' in window) {
            const speech = new SpeechSynthesisUtterance(text);
            speech.lang = 'en-US';  // You can change this based on language needs
            speech.pitch = 1;       // Adjust pitch if needed (0 to 2, default 1)
            speech.rate = 1;        // Adjust rate of speech if needed (0.1 to 10, default 1)

            // Get available voices and filter for a female voice
            const voices = window.speechSynthesis.getVoices();
            console.log("Available voices:", voices);
            const femaleVoice = voices.find(voice => 
                voice.gender === 'female' || 
                voice.name.includes('Female') || 
                voice.name.includes('Siri') ||
                voice.name.includes('Zira')
            ); // Common female voice identifier

            if (femaleVoice) {
                speech.voice = femaleVoice; // Set the female voice
            }

            // Start speaking
            setIsSpeaking(true);

            window.speechSynthesis.speak(speech);

            // Disable "Done Answering" while the AI is speaking
            setDoneEnabled(false);

            // Once the AI finishes speaking, automatically start recording and enable "Done Answering"
            speech.onend = () => {
                startRecording();
                setIsSpeaking(false); // Stop showing waves
                setDoneEnabled(true);
            };
        } else {
            console.warn("Speech Synthesis is not supported in this browser.");
        }
    };

    // Set up Speech Recognition for real-time transcription
    useEffect(() => {
        if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const recognitionInstance = new SpeechRecognition();
            recognitionInstance.continuous = true;
            recognitionInstance.interimResults = true;
            recognitionInstance.lang = 'en-US'; // Set the language for recognition
            recognitionInstance.onresult = (event) => {
                let interimTranscript = '';
                let finalTranscript = '';

                for (let i = event.resultIndex; i < event.results.length; i++) {
                const transcriptText = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    finalTranscript += transcriptText + ' ';
                } else {
                    interimTranscript += transcriptText;
                }
                }

                setCurrentTranscript(finalTranscript || interimTranscript); // Update the transcript in real-time
            };
            setRecognition(recognitionInstance);
        } else {
            console.warn("Speech Recognition API is not supported in this browser.");
        }

        if ('speechSynthesis' in window) {
            // If voices are already loaded, set the voicesLoaded state to true
            if (window.speechSynthesis.getVoices().length !== 0) {
                setVoicesLoaded(true);
            } else {
                // Listen for voiceschanged event to set the voicesLoaded state
                window.speechSynthesis.onvoiceschanged = () => {
                    setVoicesLoaded(true);
                };
            }
        } else {
            console.warn("Speech Synthesis is not supported in this browser.");
        }
        
        const timer = setTimeout(() => {
            setLoading(false);
            setInterviewStarted(true)
        }, 3000); // 4 seconds delay
      
        return () => clearTimeout(timer);
    }, []);

    
    useEffect(() => {
        if (interviewStarted && voicesLoaded) {
            // Speak the current question whenever the question changes
            speakQuestion(questions[currentQuestion]);
        }
    }, [currentQuestion, interviewStarted, voicesLoaded]);


    // Start countdown timer from 15 minutes (900 seconds)
    useEffect(() => {
        let timerInterval = null;
        if (interviewStarted && time > 0) {
            timerInterval = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
        }

        // Clear the interval when the timer reaches 0
        if (time === 0) {
            clearInterval(timerInterval);
        }

        return () => clearInterval(timerInterval); // Cleanup on component unmount
    }, [interviewStarted, time]);

    // Convert time in seconds to MM:SS format
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // Start recording the candidate's response (for both individual and full interview)
    const startRecording = () => {
        // Start speech recognition
        if (recognition) {
            recognition.start();
        }
        // Start recording individual video
        setRecording(true);
        videoChunks.current = [];
        // Access the stream from the webcam
        const stream = webcamRef.current?.video?.srcObject;
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'video/webm' });
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.addEventListener('stop', handleStopRecording);
        mediaRecorderRef.current.start();

        // Start recording the full interview if it's the first question
        if (currentQuestion === 0 && !fullInterviewRecorderRef.current) {
            fullInterviewChunks.current = [];
            fullInterviewRecorderRef.current = new MediaRecorder(stream, { mimeType: 'video/webm' });
            fullInterviewRecorderRef.current.addEventListener('dataavailable', (e) => {
                if (e.data.size > 0) {
                fullInterviewChunks.current.push(e.data);
                }
            });
            fullInterviewRecorderRef.current.start();
        }
    };

    // Handle when data becomes available during recording (for individual question)
    const handleDataAvailable = (e) => {
        if (e.data.size > 0) {
            videoChunks.current.push(e.data);
        }
    };

    // Handle stop recording, save the video for the current question
    const handleStopRecording = () => {
        const videoBlob = new Blob(videoChunks.current, { type: 'video/webm' });
        const videoURL = URL.createObjectURL(videoBlob);
        setRecordedVideos((prev) => [...prev, videoURL]);
        setCurrentTranscript('')
        // Save the current transcript
        setTranscripts((prev) => [...prev, currentTranscript]);

        // Move to the next question or mark interview complete
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion((prev) => prev + 1);
        } else {
            stopFullInterviewRecording(); // Stop full interview recording after the last question
            setIsComplete(true);
        }
        setProgress(((currentQuestion + 1) / questions.length) * 100);
        // Stop speech recognition
        if (recognition) {
            recognition.stop();
        }

        setRecording(false);
    };

    // Stop recording the full interview
    const stopFullInterviewRecording = () => {
        if (fullInterviewRecorderRef.current) {
        fullInterviewRecorderRef.current.stop();
        fullInterviewRecorderRef.current.addEventListener('stop', () => {
            const fullInterviewBlob = new Blob(fullInterviewChunks.current, { type: 'video/webm' });
            const fullInterviewURL = URL.createObjectURL(fullInterviewBlob);
            setFullInterviewURL(fullInterviewURL);
        });
        }
    };

    // Stop recording when the user clicks "Done Answering" for individual questions
    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setDoneEnabled(false); // Disable "Done Answering" after submitting the answer
    };

    // Preview all recorded videos after completion
    const renderVideoPreviews = () => {
        return recordedVideos.map((video, index) => (
        <div key={index} className="video-preview">
            <h4>Answer {index + 1}:</h4>
            <h5>Transcript:</h5>
            <p>{transcripts[index]}</p> {/* Display the saved transcript for each answer */}
            <video src={video} controls width="320" height="240"></video>
        </div>
        ));
    };

    return(
        <>
            <Modal
                show={screenInterviewModal}
                onHide={() => setScreenInterviewModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                centered
                id="modal-start-interview"
                backdrop="static"
            >
                <Modal.Body>  
                    <div className={loading ? 'blurred-content' : ''} style={{ position: 'relative' }}>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="d-inline-flex align-items-center">
                                { organization && organization?.image_url && organization?.image_url != null &&
                                    <img
                                        alt={ organization.name} src={ organization.image_url}
                                        width="50" height="50"
                                        className="d-inline-block align-top"
                                    />
                                }
                                <div className="ml-2">
                                    <p className="mb-n1 black-dark-font">{ organization.name}</p>
                                    <a href={ organization.website_url} target='_blank' className="text-decoration-underline text-primary">{ organization.website_url }</a>
                                </div>
                            </div>
                            <div className='w-50 d-inline-flex justify-content-between align-items-center'>
                                <div class="status text-small">
                                    <span>{recording ? 'Recording...' : 'Paused'}</span>
                                    <span>{formatTime(time)}</span>
                                    <span>{currentQuestion + 1}/{questions.length}</span>
                                </div>
                                <ProgressBar className='w-100 mr-2' now={progress} label={`${progress}${'%'}`} />
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-5">
                            <div className="profile-section">
                                <h5 className="font-weight-bold">AI Interviewer</h5>
                                <div className="position-relative">
                                    <img
                                        alt="AI Interviewer" src={recording ? interviewImage : (isComplete ? interviewImage : interviewGif)}
                                    />{' '}
                                    {/* <img
                                        alt="AI Interviewer" src={maryImage}
                                    />{' '} */}
                                    <div className="profile-name">Mary</div>
                                </div>
                                {isSpeaking && (
                                    <div className="wave-container">
                                        <div className="wave"></div>
                                        <div className="wave"></div>
                                        <div className="wave"></div>
                                        <div className="wave"></div>
                                        <div className="wave"></div>
                                    </div>
                                )}
                            </div>

                            <div className="chat-section">
                                <h6 className='font-weight-bold mb-2'>AI Interviewer</h6>
                                <p className='mb-3'>{questions[currentQuestion]}</p>
                                <h6 className='font-weight-bold mb-2 mt-1'>You</h6>
                                <p>{currentTranscript}</p>
                            </div>

                            <div className="profile-section">
                                <h5 className="font-weight-bold">Candidate</h5>
                                <div className="position-relative">
                                    {/* <img
                                        alt="Candidate" src="https://via.placeholder.com/150"
                                    />{' '} */}
                                    <Webcam
                                        audio={true}
                                        ref={webcamRef}
                                        muted={true}
                                        screenshotFormat="image/jpeg"
                                        className="webcam"
                                        videoConstraints={{
                                        width: 175,
                                        height: 175,
                                        facingMode: "user",
                                        }}
                                    />
                                    <div className="profile-name">RS</div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-4">
                            {!isComplete ? (
                                <Button variant="primary" className="ai-btn-primary" onClick={stopRecording} disabled={!doneEnabled} >Done answering? Continue</Button>
                            ) : (
                                <></>
                                // <Button variant="primary" className="ai-btn-primary" onClick={setScreenInterviewModal(false)} >Completed</Button>
                            )}
                            {isComplete && (
                                <Button variant="primary" className="ai-btn-primary" onClick={() => setScreenInterviewModal(false)} >Completed</Button>
                            )}
                        </div>
                    </div>
                    {loading && (
                        <div className="loader-overlay">
                            <Spinner animation="border" variant="primary" />
                            <p className="mt-3 ml-2 font-weight-bold">Loading AI Interview...</p>
                        </div>
                    )}        
                </Modal.Body>
            </Modal>
        </>
    )
}
export default PracticeInterviewScreeningPage;