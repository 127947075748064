import React, { Fragment, Error,useRef, useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'
import { useField , Formik, Field ,Form as FormSubmit } from 'formik'
import Select from 'react-select'
import _ from 'lodash'
import CloseButton from '../Styled components/CloseButton'
import { Box, Row, Button, W4text } from './styles/JobModal.styled'
import feather from 'feather-icons'
import { makeRequest } from '../RequestAssist/RequestAssist'

const initialState = {
}

const techTalentList = [
    { value: 'Accounting / Finance', label: 'Accounting / Finance' },
    { value: 'Account Executives', label: 'Account Executives' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Customer Success', label: 'Customer Success' },
    { value: 'Designers', label: 'Designers' },
    { value: 'HR', label: 'HR' },
    { value: 'Legal', label: 'Legal' },
    { value: 'Marketers', label: 'Marketers' },
    { value: 'Recruiters', label: 'Recruiters' },
    { value: 'SDRs', label: 'SDRs' },
    { value: 'Software Engineers', label: 'Software Engineers' },
];

function TalentTechGroupModal({ onClose, user_id }) {
    
    const [validationError, setValidationError] = useState({
        techTalents: null,
    });

    const [candidateUploadData, setCandidateUploadData] = useState({
        techTalents: [],
    })
    
    const checkValidation = () => {
        setValidationError({})
        let isValid = true
        if (_.isEmpty(candidateUploadData.techTalents)) {
            setValidationError((prev) => ({
                ...prev,
                techTalents: 'Job Groups are required',
            }));
            isValid = false
        }
        if(candidateUploadData.techTalents?.length > 5){
            setValidationError((prev) => ({
                ...prev,
                techTalents: 'Max 5 Job Groups are allowed',
            }));
            isValid = false
        }
        return isValid
    };

    const handleTechTalentList = (event) => {
        setCandidateUploadData({ ...candidateUploadData, techTalents: [...event] });
        setValidationError({ ...validationError, techTalents: null });
    };

    const handleTechGroupSubmit = (data,resetForm) => {
        let URL = '/update_tech_group'
        let method = 'post'
        const techTalentString = candidateUploadData.techTalents.map((techTalent)=> techTalent.value).join(',')
        const payload = new FormData()
        payload.append('user[tech_talents]', techTalentString)
        payload.append('user[user_id]', user_id)
        
        makeRequest(URL,'post',payload , {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                if(responce['messageType'] == 'failure'){
                    return {
                        message: responce['msg'],
                        messageType: responce['messageType'],
                        loading: false,
                        autoClose: true,
                    }
                }else{
                    return {
                        message: responce['msg'],
                        messageType: responce['messageType'],
                        loading: false,
                        autoClose: true,
                    }
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: (responce) => {
                if(responce['data']['messageType'] == "success"){
                    window.location.href = '/' 
                }
            },
        })
    }

    return(
        <Fragment>
            <Row direction="row" aItems="center">
                <Box>
                <W4text color="#16246B" size="24px">
                    Please select type of jobs you are interested into:
                </W4text>
                </Box>
                <div style={{ flexGrow: '1' }} />
                <Box>
                <CloseButton handleClick={() => onClose()} />
                </Box>
            </Row>
            <Formik
                initialValues={{}}
                validateOnChange={false}
                validateOnBlur={false}
                validate={(values) => {
                    const errors = {}
                    checkValidation()
                    return errors
                }}
                onSubmit={(values, { setSubmitting ,resetForm }) => {
                    const isValid = checkValidation()
                    if(isValid){
                        setTimeout(() => {
                            setSubmitting(false);
                            handleTechGroupSubmit(values,resetForm);
                        }, 400);
                    }
                }}
                >
                <FormSubmit>
                    <Form.Group as={Row} controlId="formRate" style={{ height: '50px' }}>
                        <Form.Label column sm="2">Job Groups<span className='text-danger'>*</span></Form.Label>
                        <Col sm="10">
                            <Select
                                options={techTalentList}
                                name='techTalents'
                                id='techTalents'
                                placeholder={'Select Job Groups (Max 5)'}
                                onChange={handleTechTalentList}
                                isMulti
                                value= {candidateUploadData.techTalents}
                                menuPosition='fixed'
                            />
                            {validationError.techTalents && (
                                <div className="text-danger">{validationError.techTalents}</div>
                            )}
                        </Col>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Button type="submit">Update</Button>
                        </Col>
                    </Row>
                </FormSubmit>
            </Formik>
            
            
        </Fragment>
    )

}

export default TalentTechGroupModal
