import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'
import { Spinner, Button, Alert } from 'react-bootstrap'

import styles from './styles/Signup.module.scss'
import UserForm from './UserForm'
import TextInput from '../../common/TextInput/TextInput'
import { Label, GoogleButton } from './styles/Signup.styled'
import GoogleIcon from '../../../../assets/images/icons/icon-google.svg'

const initialState = {
  email: '',
  password: '',
  agreeToTermsAndPolicy: false,
}

function SignUp({ userRole, signUpWithRole }) {
  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get("token")?.toString()
  document.cookie = "token=" + token
  console.log("🚀 ~ file: SignUp.js:24 ~ SignUp ~ token", token == 'undefined')
  const cookie = document.cookie
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [role, setRole] = useState(userRole)

  const validationSchema = Yup.object({
    email: Yup.string({ role })
      .email()
      .required('Email is required')
      .test(
        'email-unique',
        'This email is already in use',
        async function (value) {
          const res = await axios.get(
            `/users/exists?email=${encodeURIComponent(value)}`
          )
          return !res.data.user_exists
        }
      )
      .test(
        'email-fake',
        userRole == 'employer' ? 'Please enter your work email. Example: name@companyname.com' : 'Please enter a valid domain',
        async function (value) {
          const domain = await axios.get(
            `/users/blocked_email_domain.json?email=${(value)}&role=${(userRole)}`
          )
          console.log(domain.data.email_blocked_domain)
          return !domain.data.email_blocked_domain
        }
      ),
      // .matches(
      //   /^((([a-z]|\d|[!#\$%&'\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/,
      //   'Restricted character found in the email address'
      // ),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,25}$/,
        'Password should have 8 to 25 characters, must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
      ),
    agreeToTermsAndPolicy: Yup.boolean().oneOf(
      [true],
      'Please accept the Terms and Conditions & Privacy Policy'
    ),
  })

  const handleSubmit = async (user) => {
    const payload = new FormData()
    const url = `/signup/${role}`
    if (!_.isEmpty(role)) {
      payload.append('user[role]', role)
      payload.append('user[email]', user.email)
      payload.append('user[password]', user.password)
      payload.append('user[password_confirmation]', user.password)
      payload.append('user[invited]', token)
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      setLoading(true)
      await axios
        .post(url, payload, {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
          },
          role,
        })
        .then((res) => {
          const responce = res.data
          if (responce.success) {
            setResponse({ messageType: 'success', message: responce.msg })
            window.location.href = '/complete/profile'
          } else {
            setResponse({ messageType: 'danger', message: responce.msg })
          }
          setLoading(false)
        })
        .catch((e) => {
          setResponse({ messageType: 'success', message: e })
          setLoading(false)
        })
    } else {
      setResponse({ messageType: 'danger', message: 'Please Select Role' })
    }
  }

  const handleGoogleSignIn = () => {
    if (_.isEmpty(role)) {
      setResponse({ messageType: 'danger', message: 'Please Select Role' })
    } else {
      window.location.href = `/user/auth/google_oauth2?role=${role}`
    }
  }

  const handleRole = (event) => {
    setResponse({})
    setRole(event.target.value)
  }

  return (
    <div>
      {!_.isEmpty(response) && (
        <Alert
          variant={response.messageType}
          onClose={() => setResponse({})}
          style={{ fontSize: '15px', width: '27rem' }}
          dismissible
          className="candidate-info-close"
        >
          <span className={styles.alertInfo}> {response.message} </span>
        </Alert>
      )}
      {signUpWithRole ? (
        <>
          <ChooseRole role={role} handleRole={handleRole} />
          <GoogleButton
            onClick={() => handleGoogleSignIn()}
          >
            <img src={GoogleIcon} />
            Continue with Google
          </GoogleButton>
        </>
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          <Form className={styles.signForm}>
            <UserForm />
            <TextInput
              type="checkbox"
              name="agreeToTermsAndPolicy"
              id="terms_and_policy"
              label={<span> I agree to the <a href='/terms_of_service' target='_blank'>Terms and Conditions</a> & <a href='/privacy_policy' target='_blank'>Privacy Policy</a></span>}
            />
             
            <Button
              type="submit"
              className={styles.submitButton}
              disabled={loading}
            >
              {loading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                'Sign Up'
              )}
            </Button>
          </Form>
        </Formik>
      )}
    </div>
  )
}

export const ChooseRole = ({ handleRole, role }) => {
  return (
    <div className={styles.responseContainer}>
      <div>
        <Label>Choose Role to Sign Up</Label>
      </div>
      <div className={styles.roleContainer}>
        <div>
          <input
            type="checkbox"
            value="employer"
            onChange={handleRole}
            checked={role === 'employer'}
          />
          <span className={styles.roleText}>Employer</span>
        </div>
        <div>
          <input
            type="checkbox"
            value="recruiter"
            onChange={handleRole}
            checked={role === 'recruiter'}
          />
          <span className={styles.roleText}>Recruiter</span>
        </div>
        <div>
          <input
            type="checkbox"
            value="talent"
            onChange={handleRole}
            checked={role === 'talent'}
          />
          <span className={styles.roleText}>Talent</span>
        </div>
      </div>
    </div>
  )
}

export default SignUp
