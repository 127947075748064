import React, { useState } from 'react'
import CloseButton from '../../../common/Styled components/CloseButton'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import Button from '../shared/Button'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import axios from 'axios'

const allStages = [
  { value: 'applicant', label: 'Applicants' },
  { value: 'recruitor_screen', label: 'Recruiter Screened' },
  { value: 'submitted', label: 'Submitted' },
  { value: 'first_interview', label: 'First interview' },
  { value: 'second_interview', label: 'Second interview' },
  { value: 'offer', label: 'Offer' },
]

const Row = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${(props) => (props.jContent ? props.jContent : 'unset')};
  align-items: ${({ aItems }) => (aItems ? aItems : 'unset')};
  margin-bottom: 12px;
`
function CandidateUpload({ job, onClose }) {
  const [stage, setStage] = useState('')
  const [candidateIds, setCandidateIds] = useState([])
  const [validationErrorsCandidate, setValidationErrorsCandidate] = useState('')
  const [validationErrorsStage,setValidationErrorsStage] = useState('')
  const handleSelectSatge = (stage) => {
    setValidationErrorsStage('')
    setStage(stage.value)
  }

  const handleCancel = () => {
    onClose()
  }

  const handleSelectOption = (options) => {
    setValidationErrorsCandidate('')
    const ids = options.map((item) => item.value)
    setCandidateIds([...ids])
  }

  const loadOptions = async (inputValue, callback) => {
   const URL = `/api/v1/suggestion/candidates?keyword=${inputValue}`
   const response =  await axios.get(URL).then((res)=> res).catch(error=>console.log(error))
   const candidates = response.data.candidates
   const candidateArray = candidates.map((candidate)=> {return { value:candidate.id, label:`${candidate.first_name} ${candidate.last_name}` } })
   callback(candidateArray)
  }

  const handleValidationErrors = () => {
    setValidationErrorsCandidate('')
    setValidationErrorsStage('')
    let valid = true
    if (candidateIds?.length == 0){
      setValidationErrorsCandidate('Please Select Candidate.')
      valid = false
    }
    if (stage == '') {
      setValidationErrorsStage('Please Select Stage.')
      valid = false
    }
    return valid
  }

  const handleSave =  () => {
    if(handleValidationErrors() == true){
      const URL = `/jobs/${job.id}/change_stage`
      const payload = new FormData()
      payload.append('candidate_ids', JSON.stringify(candidateIds))
      payload.append('stage', stage)
      payload.append('submission[user_id]', '')
      payload.append('submission[person_id]', '')
      payload.append('submission[job_id]', job.id)
      payload.append('submission[submission_type]', stage)
      payload.append('submission[organization_id]', job.organization_id)
      makeRequest(URL, 'put', payload, {
        contentType: 'application/json',
        loadingMessage: `Submitting candidates to ${stage}...`,
        createResponseMessage: (response) => {
          return {
            message: response.message,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        },
        createErrorMessage: (error) => error.response.data.error,
      }).then((res) => onClose(false))
    }
  }

  return (
    <div>
      <Row direction="row" aItems="center" jContent="space-between">
        <div>
          <h3>{job.name}</h3>
        </div>
        <div>
          <h5>{job.organization}</h5>
        </div>
        <div>
          <CloseButton handleClick={onClose} />
        </div>
      </Row>
      <div>
        <Row direction="column">
          <label>Select Stage*</label>
          <Select
            onChange={handleSelectSatge}
            options={allStages}
            value={stage.label}
            placeholder={'Select Stage'}
          />
          <small style={{ color: 'red' }}>{validationErrorsStage ? validationErrorsStage : ''}</small>
        </Row>
      </div>
      <div>
        <Row direction="column">
          <label>Select Candidate*</label>
          <AsyncSelect
            isMulti
            isClearable={true}
            cacheOptions
            loadOptions={loadOptions}
            onChange={handleSelectOption}
            placeholder={'Type to select Candidate'}
          />
          <small style={{ color: 'red' }}>{validationErrorsCandidate ? validationErrorsCandidate : ''}</small>
        </Row>
      </div>
      <div>
        <Row>
          <div>
            <Button onClick={handleSave}>Save</Button>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        </Row>
      </div>
      <div></div>
    </div>
  )
}

export default CandidateUpload
