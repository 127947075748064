import React, { useState, useEffect } from 'react'
import './../styles/invitedPage.scss'
import Row from 'react-bootstrap/Row'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Paginator_v2 from '../../../common/Paginator/Paginator_v2'
import AboutLeftPenal from '../Home/AboutLeftPenal'


const InvitedPage = ({communityId,currentUser}) => {
  
  const [invitations, setInvitations] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [loading, setLoading] = useState([])
  const [activePage, setActivePage] = useState(0)
  const [totalCount, setTotalCount] = useState()
  const [currentCount, setCurrentCount] = useState()
  const [perPage, setPerPage] = useState(50)

  useEffect(() => {
    fetchData()
  }, [activePage])

  const fetchData = async () => {
    setLoading(true)
    setTotalPages(0)
    const url = '/communities/'+communityId+`/members/invited.json?page=${activePage == 0 ? 1 : activePage + 1}`
    makeRequest(url, 'get', '', {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      onSuccess: (response) => {
        setInvitations(
          response.data.list.map((list) => ({
            email: list.email,
            user_name: list.user.first_name + ' ' + list.user.last_name,
            status: list.status,
            created_at: moment(list.created_at).format('MMM DD YYYY'),
            actions: ((list.status == 'Pending') ? ( <><button className="new3" onClick={() => (onResendClick(list))}>RESEND</button><button className="new3" onClick={() => (onRevokeClick(list))}>REVOKE</button></>) : <button className="new3" onClick={() => (onResendClick(list))}>RESEND</button>)
          }))
        )
        setPerPage(response.data.per_page)
        setTotalPages(response.data.total_pages)
        setTotalCount(response.data.total_count)
        setCurrentCount(response.data.current_counts)
        setLoading(false)
      },
    })
  }

  const onResendClick = (item) => {
    const URL = '/communities/resend_invite'
    const payload = new FormData()
    payload.append('email_re_invitation[community_id]', communityId)
    payload.append('email_re_invitation[email]', item.email)
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (res) => {
        setTimeout(() => {
          window.open('/communities/'+communityId+'/members/invited', "_self")
        }, 1000);
        return {
          message: res?.msg,
          messageType: res?.messageType
        }
      }
    })
  }

  const onRevokeClick = (item) => {
    const URL = '/communities/revoke_member_invitation'
    const payload = new FormData()
    payload.append('email_re_invitation[community_id]', communityId)
    payload.append('email_re_invitation[email]', item.email)
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (res) => {
        setTimeout(() => {
          window.open('/communities/'+communityId+'/members/invited', "_self")
        }, 1000);
        return {
          message: res?.msg,
          messageType: res?.messageType
        }
      }
    })
  }

  return (
    <div className="container communities-memebrs mt-30">
      <div className="row">
        <div className="col-md-8">
          <h5 className="mb-30 font-weight-bold">{totalCount} Pending Invites</h5>
          <div className="invites-container">
          {(invitations && invitations.length) ? (
            invitations.map((invitation) => {
              return (
                <div className="invite">
                  <div className="invite-info">
                    <strong>{invitation.email}</strong>
                    <div className="invite-status">{invitation.status} · Invited by {invitation.user_name} on {invitation.created_at}</div>
                  </div>
                  <div className="actions">
                    {invitation.actions}
                  </div>
                </div>
              )
            })
          ) : (
              <div style={{ textAlign: 'center' }}>
                  No Invitations
              </div>
          )}
          </div>
          {Array.isArray(invitations) && totalPages > 1 && (
              <Row className="d-flex justify-content-center">
                  <Paginator_v2
                      pageCount={totalPages}
                      pageWindowSize={5}
                      activePage={activePage}
                      setActivePage={setActivePage}
                  />
              </Row>
          )}
        </div>
        {/* <div className="col-lg-4 col-md-4"> */}
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
           <AboutLeftPenal communityId={communityId} ></AboutLeftPenal>
        </div>
      </div>
    </div>
  );
}
export default InvitedPage