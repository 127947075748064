import React, { useEffect,useState } from 'react'
import _ from 'lodash'
import SignUp from './SignUpV2'
import '../../../../assets/v2/css/main.css'
import candidate1 from '../../../../assets/v2/imgs/page/candidates/img-candidate10.png';
import candidate2 from '../../../../assets/v2/imgs/page/candidates/img-candidate11.png';
import candidate3 from '../../../../assets/v2/imgs/page/candidates/img-candidate12.png';
import './styles/default.css';

const StepPage2 = ({
  setFormData
}) => {

  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (isSubmitted) {
      setFormData((prev) => ({
          ...prev,
          step: 'CONTACT_DETAILS',
          step2: true,
      }))
    }
    setIsSubmitted(false)
  }, [isSubmitted])

  const submitAction = async () => {
    setIsSubmitted(true)
  }

  return (
    <>
      <div className="row step-section-row" style={{margin: 0, padding: 0}}>
        <div className="col-lg-6" style={{backgroundColor: '#5192ff'}}>
          <div className="container">
            <div className="box-newsletter" style={{background: 'none', marginTop: '50px'}}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-md-30">
                  <div className="card-none-bd hover-up wow animate__ animate__fadeInUp animated" data-wow-delay=".0s" style={{visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp'}}>
                    <div className>
                      <h4 style={{color: '#fff'}}>Job Queue</h4>
                      <h2 style={{color: '#fff'}}><span className="count">15</span></h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-md-30">
                  <div className="card-none-bd hover-up wow animate__ animate__fadeInUp animated" data-wow-delay=".1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                    <div className>
                      <h4 style={{color: '#fff'}}>Applied</h4>
                      <h2 style={{color: '#fff'}}><span className="count">35</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="employers-list mt-60">
                <div className="card-employers hover-up wow animate__ animate__fadeIn animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 d-flex align-items-center">
                      <div className="employers-logo online mr-15">
                        <img alt="jobhub" src={candidate1} />
                      </div>
                      <div className="employers-name">
                        <span style={{padding: '1px 1px 1px 150px', backgroundColor: '#f0ebebde', borderRadius: '20px'}} /><br />
                        <span style={{padding: '1px 1px 1px 100px', backgroundColor: '#f0ebebde', borderRadius: '20px', marginTop: '10px'}} />
                      </div>
                    </div>
                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                      <div className="card-grid-2-link">
                        <a href="#"><i className="fi-rr-shield-check" /></a>
                        <a href="#"><i className="fi-rr-bookmark" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-employers hover-up wow animate__ animate__fadeIn animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 d-flex align-items-center">
                      <div className="employers-logo mr-15">
                        <img alt="jobhub" src={candidate2} />
                      </div>
                      <div className="employers-name">
                        <span style={{padding: '1px 1px 1px 150px', backgroundColor: '#f0ebebde', borderRadius: '20px'}} /><br />
                        <span style={{padding: '1px 1px 1px 100px', backgroundColor: '#f0ebebde', borderRadius: '20px', marginTop: '10px'}} />
                      </div>
                    </div>
                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                      <div className="card-grid-2-link">
                        <a href="#"><i className="fi-rr-shield-check" /></a>
                        <a href="#"><i className="fi-rr-bookmark" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-employers hover-up wow animate__ animate__fadeIn animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 d-flex align-items-center">
                      <div className="employers-logo online mr-15">
                        <img alt="jobhub" src={candidate3} />
                      </div>
                      <div className="employers-name">
                        <span style={{padding: '1px 1px 1px 150px', backgroundColor: '#f0ebebde', borderRadius: '20px'}} /><br />
                        <span style={{padding: '1px 1px 1px 100px', backgroundColor: '#f0ebebde', borderRadius: '20px', marginTop: '10px'}} />
                      </div>
                    </div>
                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                      <div className="card-grid-2-link">
                        <a href="#"><i className="fi-rr-shield-check" /></a>
                        <a href="#"><i className="fi-rr-bookmark" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-center">
          <div className="box-info-job box-info-about pl-90 text-center">
            <h5 className="heading-36 mb-30 mt-10 wow animate__animated animate__fadeInUp" style={{fontSize: '25px'}}>Heads up! You must live in the U.S. to use CardinalTalent.</h5>
            {/* <span class="text-blue  wow animate__animated animate__fadeInUp" style="font-size: 15px;">Please confirm your location to continue.</span>   */}
            <div className="mt-4 mb-4">
              <a href="#" onClick={submitAction}>
                <button className="btn btn-default font-heading icon-send-letter btn1 w-50">I'm based in the U.S.</button>
              </a>
            </div>
          </div>    
        </div>
      </div>  
    </>
  )
}

export default StepPage2
