import isEmpty from 'lodash.isempty';
import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Select from 'react-select'
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import styles from './styles/CandidateInfo.module.scss'

function IntrestedCandidate({ recruiter_org, saveIntrestedPeople, onHide }) {
  const [isLoading, setLoading] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const [orgOptions, setOrgOptions] = useState(recruiter_org)
  const [jobsOptions, setJobsOptions] = useState([])
  const [jobSelected, setJobSelected] = useState('')
  const [stageSelected, setStageSelected] = useState('')
  const [errorOrganization, setErrorOrganization] = useState('')
  const [errorJob, setErrorJob] = useState('')
  const [errorStage, setErrorStage] = useState('')

  const allStages = [
    { value: 'recruitor_screen', label: 'Recruiter Screened' },
    { value: 'submitted', label: 'Submitted' }
  ]

  const colourStyles = {
    control: styles => ({ ...styles, height: 'auto', backgroundColor: '#F6F7FC', minWidth: '20em', maxWidth: '20em', marginLeft: '10px', border: 'none', minHeight: '40px' })
  };

  const fetchJobs = async () => {
    const url = '/weekly_referral/organization_jobs.json'
    await makeRequest(url, 'get', { params: { id: selectedOrganization } }, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setJobsOptions(res.data.jobs)
      },
    })
  }

  const submitIntrestedPeople = () => {
    if(isEmpty(selectedOrganization)){
      setErrorOrganization('Select organization.')
    }
    else if(isEmpty(jobSelected.toString())){
      setErrorJob('Select Job.')
    }
    else if(isEmpty(stageSelected)){
      setErrorStage('Select Stage.')
    }
    else{
      saveIntrestedPeople(selectedOrganization,jobSelected, stageSelected)
    }
  }

  useEffect(()=>{
    fetchJobs()
  },[selectedOrganization])

  const handleOrganization = (str) => {
    setSelectedOrganization(str.value)
    setJobSelected('')
    setErrorOrganization('')
  }

  const handleJob = (str) => {
    setJobSelected(str.value)
    setErrorJob('')
  }

  const handleStage = (str) => {
    setStageSelected(str.value)
    setErrorStage('')
  }

  return (
    <>
      <Container className='p-0'>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <Row>
            <Col sm={12} md={12} xs={12}>
              <Select
                options={orgOptions}
                onChange={handleOrganization}
                placeholder={'Select Organization'}
                styles={colourStyles}
                menuPosition='fixed'
              />
              {errorOrganization && (
                <label style={{fontSize: '10px',color: 'red',marginTop: '5px', marginLeft: '10px'}}>
                  {errorOrganization}   
                </label>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col sm={12} md={6} xs={6}>
              <Select
                options={jobsOptions}
                onChange={handleJob}
                placeholder={'Select Job'}
                styles={colourStyles}
                menuPosition='fixed'
              />
              {errorJob && (
                <label style={{fontSize: '10px',color: 'red',marginTop: '5px', marginLeft: '10px'}}>
                  {errorJob}   
                </label>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col sm={12} md={6} xs={6}>
              <Select
                options={allStages}
                onChange={handleStage}
                placeholder={'Select Stage'}
                styles={colourStyles}
                menuPosition='fixed'
              />
              {errorStage && (
                <label style={{fontSize: '10px',color: 'red',marginTop: '5px', marginLeft: '10px'}}>
                  {errorStage}   
                </label>
              )}
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <div className='d-flex flex-row align-items-center justify-content-end' style={{ marginTop: '18px' }}>
              <Button
                type="button"
                onClick={onHide}
                className={`${styles.button} mr-1`}
              >
                Cancel
              </Button>
              <Button className={styles.button} type="submit" onClick={()=>submitIntrestedPeople()}>Submit Candidate(s)</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default IntrestedCandidate;