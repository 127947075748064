import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, FormControl } from 'react-bootstrap'

import styles from './styles/CompanyProfilePage.module.scss'
import MultiSelect from '../../common/MultiSelect/MultiSelect'
import Util from '../../../utils/util'
import Search from '../../../../assets/images/talent_page_assets/search-icon-new.png'
import _ from 'lodash'
import JobCard from './JobCard'

const groupByIndustries = (list) => {
    return Util.groupBy(list, 'department', 'jobs', 'No department found')
}

const initialFilterValues = {
    searchTerm: '',
    department: [],
    location: [],
}

function CarrerSection({ jobs, currentUser, isProfileComplete }) {
    const [filter, setFilter] = useState(initialFilterValues)
    const [filteredJobs, setFilteredJobs] = useState([])
    const availableIndustries = groupByIndustries(jobs)
        .filter((item) => item.department !== 'No department found')
        .map((job) => job.department)
    const availableLocations = jobs.map((job) => job.location)

    useEffect(() => {
        updateFilteredJobs(jobs)
    }, [])

    useEffect(() => {
        filterJobs()
    }, [filter])

    const updateFilteredJobs = (newJobs) => {
        const jobsByIndustries = groupByIndustries(newJobs)
        setFilteredJobs([...jobsByIndustries])
    }

    const filterJobs = () => {
        let newFilteredJobs = jobs || []

        if (!_.isEmpty(filter.searchTerm)) {
            const filteredJobsByTerm = jobs.filter((job) => {
                return (
                    job.name
                        .toLowerCase()
                        .includes(filter.searchTerm.toLowerCase()) ||
                    job.department
                        .toLowerCase()
                        .includes(filter.searchTerm.toLowerCase())
                )
            })
            newFilteredJobs = filteredJobsByTerm
        }

        if (!_.isEmpty(filter.department)) {
            const filteredJobsByDepartment = newFilteredJobs.filter((job) =>
                filter.department.includes(job.department)
            )
            newFilteredJobs = filteredJobsByDepartment
        }

        if (!_.isEmpty(filter.location)) {
            const filteredJobsByLocation = newFilteredJobs.filter((job) =>
                filter.location.includes(job.location)
            )
            newFilteredJobs = filteredJobsByLocation
        }

        updateFilteredJobs(newFilteredJobs)
    }

    const handleChange = (event) => {
        setFilter({ ...filter, searchTerm: event.target.value })
    }

    const handleMultiSelect = (list, type) => {
        const valueList = list.map((item) => item.value)
        handleFilter(valueList, type)
    }

    const handleFilter = (valueList, type) => {
        setFilter({ ...filter, [type]: [...valueList] })
    }

    return (
        <Fragment>
            <Row className="mb-3">
                <Col xs={1} className="d-flex align-items-center">
                    <span className="mt-1">Filter By:</span>
                </Col>
                <Col xs={3} className="mt-3">
                    <div className={`form-group ${styles.hasSearch}`}>
                        <span className={styles.formControlFeedback}>
                            <img width={20} height={20} src={Search} />
                        </span>
                        <FormControl
                            type="text"
                            className={`form-control ${styles.formControl}`}
                            value={filter.searchTerm}
                            onChange={handleChange}
                            placeholder="Search jobs or departments"
                            aria-label="Search"
                        />
                    </div>
                </Col>
                <Col xs={3} className="mt-3">
                    <div>
                        <MultiSelect
                            place
                            onSelect={(values) =>
                                handleMultiSelect(values, 'department')
                            }
                            options={availableIndustries}
                            placeholder={'Department'}
                        />
                    </div>
                </Col>
                <Col xs={3} className="mt-3">
                    <div>
                        <MultiSelect
                            onSelect={(values) =>
                                handleMultiSelect(values, 'location')
                            }
                            options={availableLocations}
                        />
                    </div>
                </Col>
            </Row>
            {_.isEmpty(filteredJobs) ? (
                <Row className="mt-4 justify-content-center">
                    <h4>No Jobs Found</h4>
                </Row>
            ) : (
                <Fragment>
                    {filteredJobs.map(({ department, jobs }, index) => (
                        <Fragment key={index}>
                            <Row className={`${styles.departmentContainer} mt-2`}>
                                <label
                                    className={`${styles.careerLabel}`}
                                >
                                    {department}
                                </label>
                                {jobs.map((career, index) => (
                                    <Fragment key={index}>
                                        <JobCard
                                            career={career}
                                            currentUser={currentUser}
                                            isProfileComplete={
                                                isProfileComplete
                                            }
                                        />
                                    </Fragment>
                                ))}
                            </Row>
                        </Fragment>
                    ))}
                </Fragment>
            )}
        </Fragment>
    )
}

export default CarrerSection
