import React, { useState, useEffect } from 'react'
import styles from './styles/AccountSettingPage.module.scss'
import { capitalize } from '../../../utils'
import {
    Wrapper,
} from './styles/AccountSettingPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import SweetAlert from 'react-bootstrap-sweetalert'
import feather from 'feather-icons'

const OrganizationManagementPage = (props) => {
    const [isDelete, setIsDelete] = useState(false)
    const { currentUser } = props
    const { first_name, last_name } = currentUser
    const [item, setItem ] = useState({})
    const [itemList, setItemList] = useState( (props.list) ? props.list : [])
    const [favList, setFavList] = useState( (props.favorite_list) ? props.favorite_list : [])
    const [labelFavorite, setLabelFavorite] = useState('Add')

    useEffect(() => {
        feather.replace()
    })

    const confirmDelete = (item) => {
        setItem(item)
        setIsDelete(true)
    }

    const onDeleteClick = async () => {
        const url = `/account/recruiter_organizations/${item.id}`
        await makeRequest(url, 'delete', '', {
            createSuccessMessage: () => 'Organization deleted successfully',
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: () => {
                window.location.reload()
            },
        })
    }

    const setFaviourate = (val,data) => {
        setLabelFavorite(val)
        if(val == 'add'){
            onConfirmFavorite(data)
        }else{
            onRemoveFavorite(data)
        }
    }

    const onConfirmFavorite = async (obj) => {
        const url = `/favorite_organization/create`
        const payload = new FormData()
        payload.append('favorite[user_id]', currentUser.id)
        payload.append('favorite[organization_id]', obj.id)
        await makeRequest(url, 'post', payload, {
            createSuccessMessage: () => 'Success',
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: () => {
                window.location.reload()
            },
        })
    }

    const onRemoveFavorite = async (obj) => {
        const url = `/favorite_organization/delete/${obj.id}`
        await makeRequest(url, 'delete', '', {
            createSuccessMessage: () => 'Success',
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: () => {
                window.location.reload()
            },
        })
    }

    return (
        <div className={styles.accountSettingPage}>
            <Wrapper className={`${styles.accontSetting}`}>
                <div className={`${styles.sidebar}`}>
                    <div className={`${styles.sidebar_header}`}>
                        <p>{capitalize(`${first_name} ${last_name}`)}</p>
                    </div>
                    <a href="/account/setting">General Settings</a>
                    <a href="/account/security">
                        Security & Login
                    </a>
                    <a href="/account/email_verification">Email Verification</a>
                    { (currentUser.role == 'recruiter') && 
                        <a className={`${styles.active}`}  href="/account/organization_management">Organization Membership Management</a>
                    }
                    {(currentUser.role == 'employer' || currentUser.role == 'recruiter') && 
                        <a href="/account/organization_agreement">Organization Agreement</a>
                    }
                  <a href="/account/invite_your_friend">Invite Your Friend</a>
                  {currentUser.role == 'talent' &&
                  <a href="/account/email_configuration">SMTP Email Configuration</a>
                  }
                </div>
                <div className={`${styles.containt}`} style={{ paddingBottom: '12rem' }}>
                   
                    <h3>Organization Membership Management</h3>
                   
                    <div style={{ paddingTop: '2rem' }}>
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr className='text-center'>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Owner</th>
                                    <th>Status</th>
                                    <th>Favorite</th>
                                    <th>Action</th>
                                </tr> 
                            </thead>
                            <tbody>
                                {itemList && itemList?.map((item,index)=> {
                                    return (
                                        <tr key={index} className='text-left'>
                                            <td>{index + 1}</td>
                                            <td>{capitalize(item.name)}</td>
                                            <td>{capitalize(item.first_name + ' ' + item.last_name)}</td>
                                            <td className='text-center'>{ (item.status =="approved") ? <span style={{color: 'green'}}>Active</span> : <span style={{color: 'red'}}>Inactive</span>}</td>
                                            <td className='text-center'> {favList.includes(item.id) ? <span title='Click to remove from favorite' onClick={ ()=>setFaviourate('remove',item) } style={{cursor:'pointer',color:'green'}}><i data-feather="star" fill="yes"></i></span>  : <span title='Click to add as favorite' onClick={ ()=>setFaviourate('add',item) } style={{cursor:'pointer',color:'#eb8585'}}><i data-feather="star"></i></span> }</td>
                                            <td className='text-center'><a style={{cursor:'pointer',color:'#8185ff'}} onClick={() => (confirmDelete(item))} > Remove </a></td>
                                        </tr>
                                    )
                                })}
                                { itemList.length == 0 && 
                                        <tr className='text-center'>
                                            <td colSpan={7}> No Record Found.</td>
                                        </tr>
                                    
                                }
                            </tbody>
                        </table>
                     </div>              
                </div>
                
            </Wrapper>
            {isDelete && (
                <SweetAlert
                    warning
                    title='Please confirm !'
                    showCloseButton={false}
                    showCancel={true}
                    showConfirm={true}
                    confirmBtnText="Yes"
                    CancelBtnText="No"
                    onCancel={()=> (setItem({}),setIsDelete(false))}
                    onConfirm={()=> (onDeleteClick(),setIsDelete(false))}
                >
                        {'You will no longer be a member of the organization, ' + item.name +'.'}
                </SweetAlert>
            )}
        </div>
    )
}
export default OrganizationManagementPage
