import React, { useState, useEffect } from 'react'
import moment from 'moment'

import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import './styles/Employee.scss'
import Util from "../../../../utils/util";

const EmployeeList = () => {
    sessionStorage.removeItem('timeReportDateRange')
    var active_page = window.sessionStorage.getItem('emp_activePage')
    var query = window.sessionStorage.getItem('emp_query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const [employees, setEmployee] = useState([])
    const [totalJobs,setTotalJobs] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [loading, setLoading] = useState(true)
   
    const fetchData = (clearFlag=false) => {
        setLoading(true)
        const url = `/cardinal/employees.json`
        window.sessionStorage.setItem('activePage',activePage)
        makeRequest(url, 'get',  {params: {page: (activePage+1), query: query}}, {   
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setEmployee(
                    res.data.employees.map((emp) => ({
                        id: emp.id,
                        user_id: emp.user_id,
                        join_date: emp.join_date,
                        location: emp.location,
                        employee_type: Util.capitalize(emp.employee_type),
                        manager: (emp.manager_first_name != null) ? emp.manager_first_name + ' '+ emp.manager_last_name : '-',
                        salary: emp.salary,
                        last_week_hr: 0,
                        last_week_pay: 0,
                        created_at: emp.created_at,
                        emp_name: emp.first_name + ' ' + emp.last_name
                    }))
                )
                setPageCount(res.data.total_pages)
                setTotalJobs(res.data.total_count)
                setCurrentCounts(res.data.current_counts)
                setStartRecord(activePage * res.data.per_page)
                setLoading(false)
            },
        })
    }

    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage])

    const deleteEmployee = async (id) => {
        const URL = `/cardinal/employees/${id}`
        await makeRequest(URL, 'delete', '', {
            createResponseMessage: () => {
                return {
                    message: `Deleted successful`,
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: () => {      
                fetchData()
            },      
        })      
    }    

    const addEmployee = () => {
        window.location.href = '/cardinal/employees/new'
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Employee List
                </P>
                <div style={{flexGrow:1}}/>
                <Button onClick={addEmployee} className="ml-3">
                    Add Employee
                </Button>
            </div>
            
            <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    {totalJobs > 0  &&
                        <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalJobs} employees
                        </DisplayPagination>
                    }
                 </Col>
            </Row>
            <Table
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Name',
                        field: 'emp_name',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Join Date',
                        field: 'join_date',
                        editable: true,
                        type: 'text',
                    },{
                        name: 'Location',
                        field: 'location',
                        editable: false,
                        type: 'text',
                        nowrap:''
                    },
                    {
                        name: 'Type',
                        field: 'employee_type',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Manager',
                        field: 'manager',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Salary ($)',
                        field: 'salary',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Last Week Hr',
                        field: 'last_week_hr',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Last Week Pay',
                        field: 'last_week_pay',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Action',
                        field: 'options',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap',
                        options:{timeEntry: true }
                    },
                
                ]}
                rowValues={employees.map((emp) => ({
                    ...emp,
                    join_date: moment(emp.join_date).format('MMM DD, YYYY'),
                }))}
                showEditOption
                activePage={activePage}
                deleteAction={deleteEmployee}
                goToEditPage={(id) =>
                    (window.location.href = '/cardinal/employees/' + id + '/edit')
                }
                loading={loading}
            />

            {pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
        </Card>
    )
}

export default EmployeeList