import React, { useState, useEffect, useContext, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import styles from './styles/CandidateInfo.module.scss'
import isEmpty from 'lodash.isempty'
import Util from '../../../utils/util'
import { Card, Container, Col, Button, DropdownButton, Image, Row, Modal } from 'react-bootstrap'
import lastContactIcon from '../../../../assets/images/icons/lastcontact.png'
import profileImage from '../../../../assets/images/icons/profile.png'
import Badge from "react-bootstrap/Badge";
import './styles/CandidateInfo.scss'
import lastActivityIcon from '../../../../assets/images/icons/lastactivity.png'
import applicationIcon from '../../../../assets/images/icons/application.png'
import SubmittedIcon from '../../../../assets/images/icons/submitted.png'
import _ from 'lodash'
import LinkedinIcon from '../../../../assets/images/icons/linkedin.png'
import PdfIcon from '../../../../assets/images/icons/pdf.png'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import moment from 'moment'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'

const MAX_SELECTION_LIMIT = 25

function CandidateInfo({
  loading,
  candidates,
  handleIntrested,
  multiSelectCheckbox,
  multiIntrestedCandidates
}) {

  const [submissionData, setSubmissionData] = useState({ active: false, id: null })
  function activeSubmission(candidateId) {
    setSubmissionData({ active: true, id: candidateId })
  }

  if (loading) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>Loading....</h2>
        <div id="loading" />
      </div>
    </>
    )
  }
  if (isEmpty(candidates)) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>No candidate found....</h2>
      </div>
    </>
    )
  }

  const candidateClickHandler = (candidate) => {
    console.log(candidate)
  }

  return (
    <>
      <Table className={styles.candidateTable} responsive hover>
        <tbody >
          {Array.isArray(candidates) &&
            candidates.map((candidate, index) => {
              return (
                <CandidateRow
                  candidate={candidate}
                  idx={candidate.id}
                  key={index}
                  clickHandler={candidateClickHandler}
                  setSubmissionData={setSubmissionData}
                  submissionData={submissionData}
                  activeSubmission={activeSubmission}
                  handleIntrested={handleIntrested}
                  multiSelectCheckbox={multiSelectCheckbox}
                  multiIntrestedCandidates={multiIntrestedCandidates}
                />
              )
            })
          }
        </tbody>
      </Table>
    </>
  )
}

function CandidateRow({ candidate, idx, clickHandler, setSubmissionData, submissionData, activeSubmission, handleIntrested, multiSelectCheckbox,multiIntrestedCandidates }) {
  console.log("🚀 ~ file: CandidateInfo.js:88 ~ CandidateRow ~ candidate:", candidate)
  const checked = multiIntrestedCandidates.length > 0 ? multiIntrestedCandidates.includes(idx) : false
  const [visible, setVisible] = useState(6)
  const [showModal, setShowModal] = useState(false)
  const [employerComments, setEmployerComments] = useState('')
  const [application, setApplication] = useState('')
  const [candidateId, setCandidateId] = useState('')
  const [bulkSelect, setBulkSelect] = useState(checked)
  const [lastContact, setLastContact] = useState({
    active: false,
    id: null,
})
  const loadMore = () => {
    setVisible(visible + 6)
  }
  const loadLess = () => {
    setVisible(6)
  }
  const handleCheckboxChange = (event) => {
    setBulkSelect(!bulkSelect)
    multiSelectCheckbox(candidate.id)
  }
  const HandleSaveModal = () => {
      const payload = new FormData()
      payload.append('free_candidate_search[employer_comment]', employerComments)
      payload.append('free_candidate_search[person_id]',candidateId)
      payload.append('free_candidate_search[approval_status]',false)
      makeRequest('/free_candidate_search', 'post', payload, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createResponseMessage: (res) => {
              if (res?.success){
                  setShowModal(false)
              }
              return {
                  message: res.msg,
                  messageType: res.messageType
              }
          },
      })
  }
  const [lastActive, setLastActive] = useState({
    active: false,
    id: null,
})
   
  function canidateSkills(skill, index){
    return(
                <>
                    <Badge
                        pill
                        key={index}
                        variant="secondary"
                        className="skills-button d-inline-block text-truncate"                                                                                            
                    >
                        <label className='candidate-skills'> {skill} </label>
                    </Badge>{'   '}
                </>
    )
}
  return (
    <>
      <Card key={candidate.id} className={styles.candidateProfileCard} >
        <Card.Body className='candidate-list'>
          <div>
          <Modal
                    className="free-candidate"
                    show={showModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header
                    onHide={() => setShowModal(false)}
                    closeButton
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Modal.Title>{'Interview Request'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form>
                   <Row>
                   <Col>
                   <Form.Label>Description:</Form.Label>
                  <Form.Control type="text" onChange={(e) => setEmployerComments(e.target.value)} as="textarea" rows={3} placeholder="Enter Message Here " />
                    </Col>
                     </Row>
                     <div style={{marginTop:'20px', display:'flex', justifyContent:'space-around'}}>
                   <div>
                   <Button onClick={() => setShowModal(false)} variant="secondary">
                     Close
                     </Button>
                    </div>
                    <div>
                   <Button onClick={HandleSaveModal} variant="secondary">
                     Save
                     </Button>
                    </div>
                     </div>
                      </Form>
                    </Modal.Body>
                    {/* <Modal.Footer>
          <Button variant="secondary">
            Close
          </Button>
          <Button variant="primary">
            Save Changes
          </Button>
        </Modal.Footer> */}

                </Modal>
            <Row style={{ marginBottom: '11px',justifyContent: 'space-between'}} >
              <div className='d-flex'>
                {candidate?.image_url ?
                  <img className={styles.candidateProfileImage} src={candidate.image_url ? candidate.image_url : profileImage} onClick={(e) => clickHandler(candidate)} />
                  : <CandidateAvatarIcon clickHandler={clickHandler} candidate={candidate} firstName={candidate?.first_name} lastName={candidate?.last_name} />
                }
                <div className={styles.cardBody + 'container'} >
                  <div className='d-flex justify-content-space-between w-100'>
                    <h5 className={styles.cardBodyName}>
                      <span>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</span>
                      {candidate.linkedin_profile_url &&
                        <a href={candidate.linkedin_profile_url} target="_blank">
                          <Image
                            src={LinkedinIcon}
                            style={{
                              width: '16px',
                              height: '16px',
                              marginLeft: '15px',
                              marginTop: '-1px'
                            }}
                          />
                        </a>
                      }
                      {candidate.resume && 
                        (
                          <a href={candidate.resume} target="_blank">
                            <Image
                                src={PdfIcon}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '5px',
                                    marginTop: '-1px'
                                }}
                            />
                          </a>)}
                    </h5>
                  </div>
                  <h5 style={{ fontSize: '16px' }} >{candidate?.title}</h5>
                  <h5 style={{ fontSize: '16px' }} >{candidate?.location}</h5>
                  <div className='container row'>
                            <div>
                                <h6 className={styles.skillTitle}>Skills:</h6>
                            </div>
                            <div className={styles.badge}>
                                { canidateSkills(candidate.skills, 2)}
                            </div> 
                  </div>
                  <div
                                className="container row"
                                style={{ alignItems: 'center' }}
                            >
                                <div>
                                    <h6>Activity</h6>
                                </div>{' '}
                                {'  '}
                                <div
                                    className="activity-class"
                                    style={{ display: 'contents' }}
                                >
                                    <div className="activity-field">
                                        <h6
                                            style={{
                                                backgroundColor:
                                                    lastContact.id ==
                                                        candidate.id &&
                                                    lastContact.active
                                                        ? '#E4EAFF'
                                                        : '',
                                                borderRadius:
                                                    lastContact.id ==
                                                        candidate.id &&
                                                    lastContact.active
                                                        ? '20px'
                                                        : '',
                                                padding: '12px 12px',
                                            }}
                                        >
                                            <img src={lastContactIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitle
                                                }
                                                style={{
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                }}
                                            >
                                                {' '}
                                                Last Contact{' '}
                                            </label>
                                        </h6>
                                    </div>
                                    <div className="activity-field">
                                        <h6
                                            style={{
                                                backgroundColor:
                                                    application.id ==
                                                        candidate.id &&
                                                    application.active
                                                        ? '#E4EAFF'
                                                        : '',
                                                borderRadius:
                                                    application.id ==
                                                        candidate.id &&
                                                    application.active
                                                        ? '20px'
                                                        : '',
                                                padding: '12px 12px',
                                            }}
                                        >
                                            <img src={applicationIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitle
                                                }
                                                style={{
                                                    // background: application ? '#E4EAFF' : '',
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                }}
                                            >
                                                Applications
                                            </label>
                                            {/* {candidate.applications.filter(
                                                (s) =>
                                                    s?.organization_id ===
                                                    currentOrganization?.id
                                            ).length != 0 && (
                                                <Badge
                                                    className={
                                                        styles.applicationsCount
                                                    }
                                                    id=""
                                                    variant="light"
                                                >
                                                    {
                                                        candidate.applications.filter(
                                                            (s) =>
                                                                s?.organization_id ===
                                                                currentOrganization?.id
                                                        ).length
                                                    }
                                                </Badge>
                                            )} */}
                                        </h6>
                                    </div>
                                    <div className="activity-field">
                                        <h6
                                            style={{
                                                backgroundColor:
                                                    lastActive.id ==
                                                        candidate.id &&
                                                    lastActive.active
                                                        ? '#E4EAFF'
                                                        : '',
                                                borderRadius:
                                                    lastActive.id ==
                                                        candidate.id &&
                                                    lastActive.active
                                                        ? '20px'
                                                        : '',
                                                padding: '12px 12px',
                                            }}
                                        >
                                            <img src={lastActivityIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitle
                                                }
                                                style={{
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                }}
                                            >
                                                {' '}
                                                Last Active{' '}
                                            </label>
                                        </h6>
                                    </div>
                                    {/* {memberOrganization && (
                                        <div className="activity-field">
                                            <h6
                                                style={{
                                                    backgroundColor:
                                                        submissionData.id ==
                                                            candidate.id &&
                                                        submissionData.active
                                                            ? '#E4EAFF'
                                                            : '',
                                                    borderRadius:
                                                        submissionData.id ==
                                                            candidate.id &&
                                                        submissionData.active
                                                            ? '20px'
                                                            : '',
                                                    padding: '12px 12px',
                                                }}
                                            >
                                                <img src={SubmittedIcon} />
                                                <label
                                                    className={
                                                        styles.activityFieldTitle
                                                    }
                                                    style={{
                                                        borderRadius:
                                                            submissionData
                                                                ? '20px'
                                                                : '',
                                                    }}
                                                >
                                                    Submitted
                                                </label>
                                                {candidate.submissions.length !=
                                                    0 && (
                                                    <Badge
                                                        className={
                                                            styles.applicationsCount
                                                        }
                                                        id=""
                                                        variant="light"
                                                    >
                                                        {
                                                            candidate
                                                                .submissions
                                                                .length
                                                        }
                                                    </Badge>
                                                )}
                                            </h6>
                                        </div>
                                    )} */}
                                    {/* {lastContact.id == candidate.id &&
                                        lastContact.active &&
                                        !isEmpty(
                                            candidate.last_contacted_content
                                        ) && (
                                            <div className="container">
                                                <hr />
                                                <div className="row contact_time">
                                                    <img
                                                        src={lastContactIcon}
                                                        style={{
                                                            height: '19px',
                                                            marginRight: '12px',
                                                        }}
                                                    />{' '}
                                                    1 email received from{' '}
                                                    {state.user.first_name} on{' '}
                                                    {moment(
                                                        application.last_contacted_time
                                                    ).format(
                                                        'MMM Do YYYY'
                                                    )}{' '}
                                                    <br />{' '}
                                                    {
                                                        candidate.last_contacted_content
                                                    }
                                                </div>
                                            </div>
                                        )} */}

                                    {application.id == candidate.id &&
                                        application.active && (
                                            {/* <div className="container">
                                                <hr />
                                                <p> Recent Application</p>
                                                {candidate.applications
                                                    .filter(
                                                        (s) =>
                                                            s?.organization_id ===
                                                            currentOrganization?.id
                                                    )
                                                    .sort((a, b) =>
                                                        a.submission
                                                            ?.created_at >
                                                        b.submission?.created_at
                                                            ? -1
                                                            : 1
                                                    )
                                                    .map((application) => (
                                                        <ul
                                                            style={{
                                                                width: '100%',
                                                                marginBottom:
                                                                    '0',
                                                            }}
                                                        >
                                                            <li>
                                                                <div className="row contact_time">
                                                                    {
                                                                        application.name
                                                                    }
                                                                    {application?.stage && (
                                                                        <b
                                                                            style={{
                                                                                marginLeft:
                                                                                    '5px',
                                                                            }}
                                                                        >
                                                                            Stage
                                                                            :{' '}
                                                                        </b>
                                                                    )}{' '}
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                'capitalize',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        {application?.stage?.replace(
                                                                            '_',
                                                                            ' '
                                                                        )}
                                                                        &nbsp;{' '}
                                                                        <b>
                                                                            Applied
                                                                            on
                                                                        </b>{' '}
                                                                        &nbsp;{' '}
                                                                        {moment(
                                                                            application?.updated_date
                                                                        ).format(
                                                                            'MMM DD, YYYY'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ))}
                                            </div> */}
                                        )}
                                    {lastActive.id == candidate.id &&
                                        lastActive.active &&
                                        !isEmpty(candidate.last_login) && (
                                            <div className="container">
                                                <hr />
                                                <span>Last Active</span>
                                                <div className="row">
                                                    <img
                                                        src={lastActivityIcon}
                                                        style={{
                                                            height: '19px',
                                                            marginRight: '12px',
                                                        }}
                                                    />{' '}
                                                    {moment(
                                                        candidate.last_login
                                                    ).format('MMM DD, YYYY')}
                                                </div>
                                            </div>
                                        )}
                                    {submissionData.id == candidate.id &&
                                        submissionData.active && (
                                            <div className="container">
                                                <hr />
                                                <p> Submitted Jobs</p>
                                                {candidate.submissions.length >
                                                    0 &&
                                                    candidate.submissions.map(
                                                        (application) => (
                                                            <ul
                                                                style={{
                                                                    width: '100%',
                                                                    marginBottom:
                                                                        '0',
                                                                }}
                                                            >
                                                                <li>
                                                                    <div className="row contact_time">
                                                                        <b>
                                                                            {
                                                                                application.organization_name
                                                                            }
                                                                        </b>{' '}
                                                                        &nbsp; -
                                                                        &nbsp;
                                                                        {
                                                                            application.job_name
                                                                        }{' '}
                                                                        <span
                                                                            style={{
                                                                                textTransform:
                                                                                    'capitalize',
                                                                            }}
                                                                        >
                                                                            <b
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '5px',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                &nbsp;
                                                                                Submitted
                                                                                on
                                                                                &nbsp;
                                                                            </b>{' '}
                                                                            {moment(
                                                                                application.date
                                                                            ).format(
                                                                                'MMM DD, YYYY'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        )
                                                    )}
                                            </div>
                                        )}
                                </div>
                            </div>
                </div>
              </div>
              <div>
                <Button
                  onClick={() => {
                  setShowModal(true)
                  setCandidateId(candidate.id)
                  }
                  }
                //   variant='primary'
                  className={candidate.free_candidate_details == true ? styles.Acceptbutton : candidate.free_candidate_details == false ? styles.Pendingbutton : styles.Submitbutton }
                >
                  {candidate.free_candidate_details == true ? 'Request Submit' : candidate.free_candidate_details == false ? 'Request Pending' : 'Request Interview' }
                </Button>
              </div>
            </Row>
              <div className='container row w-100 flex-nowrap align-items-center'>
                <div style={{float: 'left'}}>
                  {/* <p className={styles.submittedJob}>Submitted Jobs</p> */}
                  {candidate?.submissions?.length > 0 && candidate?.submissions?.map((application) => (
                    <ul style={{ marginBottom: '0' }}>
                      <li>
                        <div className='row contact_time'>
                          <b>{application?.job?.organization?.name}</b>  &nbsp; - &nbsp;
                          <a href={`/jobs/${application?.job?.id}/${application?.job?.name}`}>{application?.job.name}</a>  <span style={{ textTransform: "capitalize" }}><b style={{ marginLeft: "5px" }}> &nbsp; {application.submission_type != 'submitted' ? 'submitted to recruiter screened' : 'submitted on'}  &nbsp;</b> - &nbsp;{moment(application.created_at).format('MMM DD, YYYY')} </span>
                          <span style={{ textTransform: "capitalize" }}><b style={{ marginLeft: "5px" }}> &nbsp; Submitted By &nbsp;</b> - &nbsp;{`${application?.user?.first_name} ${application?.user?.last_name}`}  </span>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
          </div>
        </Card.Body>
      </Card>
      <br />
    </>
  )
}

export default CandidateInfo
