import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import linkedinIcon  from '../../../../assets/images/icon-linkedin.png'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const JobsStatusPage = (prop) => {
    console.log('Date check')
    console.log(moment().toDate())
    console.log(moment().format('MMM DD, YYYY'))
    const queryParams = new URLSearchParams(window.location.search)
    const cardinal_members_only = sessionStorage.getItem('cardinal_members_only')
    const urlRecruiterId = queryParams.get("recruiter")
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    const jobId = queryParams.get("job")
    let jobStatus_start_date = sessionStorage.getItem("jobStatus_start_date");
    let jobStatus_end_date = sessionStorage.getItem("jobStatus_end_date")
    let jobStatus_org_type = sessionStorage.getItem("jobStatus_org_type")
    let jobStatus_ac_manager = sessionStorage.getItem("jobStatus_ac_manager")
    let jobStatus_recruiter = sessionStorage.getItem("jobStatus_recruiter")
    let jobStatus_job = sessionStorage.getItem("jobStatus_job")
    let jobStatus_org = sessionStorage.getItem("jobStatus_org")
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(prop.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [organization,setOrganization] = useState([])
    const [selectedOrganization, setSelectedOrganization]  = useState(prop.organization ? prop.organization[0].value  : jobStatus_org && jobStatus_org != "null" ? JSON.parse(jobStatus_org).value : '')
    const [selectedOrganizationValue, setSelectedOrganizationValue]  = useState(prop.organization ? prop.organization[0]  : jobStatus_org && jobStatus_org != "null" ? JSON.parse(jobStatus_org) : '')
    const [jobs,setJobs] = useState([{value: 'all', label: 'All'},...prop.jobs_list])
    const [selectedJob,setSelectedJob] = useState(jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job).value : jobId ? jobId : '')
    const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'},...prop.recruiter_list])
    const [account_managers,setAccountManagers] = useState([{value: 'all', label: 'All'},...prop.team_leads])
    const [selectedRecruiter,setSelectedRecruiter] = useState((urlRecruiterId) ? urlRecruiterId : jobStatus_recruiter && jobStatus_recruiter != "null" ? JSON.parse(jobStatus_recruiter).value : '')
    const [selectedTeamLead,setSelectedTeamLead] = useState(jobStatus_ac_manager && jobStatus_ac_manager != "null" ? JSON.parse(jobStatus_ac_manager).value : '')
    const [selected,setSelected] = useState((jobId)? prop.jobs_list.filter((option) => option.value == jobId) : jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job) : {value: 'all', label: 'All'})
    const [defaultselected,setdefaultselected] = useState((urlRecruiterId)? prop.recruiter_list.filter((option) => option.value == urlRecruiterId) : jobStatus_recruiter && jobStatus_recruiter != "null" ? JSON.parse(jobStatus_recruiter) : {value: 'all', label: 'All'})
    const [jobStatus, setJobStatus] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [member_options, setOptions] = useState(prop.organization ? [prop.organization]  : [])
    const [selectOrganizationType,setSelectOrganizationType] = useState(jobStatus_org_type && jobStatus_org_type != "null" ? JSON.parse(jobStatus_org_type).value : '')
    const [defaultOrganizationType, setDefaultOrganizationType] = useState(jobStatus_org_type && jobStatus_org_type != "null" ? JSON.parse(jobStatus_org_type) : [])
    const [defaultAccountManager, setDefaultAccountManager] = useState(jobStatus_ac_manager && jobStatus_ac_manager != "null" ? JSON.parse(jobStatus_ac_manager) : [])
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate : jobStatus_start_date ? jobStatus_start_date : moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : jobStatus_end_date ? jobStatus_end_date : moment().toDate()})
    const [loading, setLoder] = useState(true)
    const handleDate = (date) => {
        setDateRange(date)
    }
    const csvExportRef = React.useRef();
    const [selectedAction, setSelectedAction] = useState('')
    const [defaultActionselected, setdefaultActionselected] = useState({ value: 'All', label: 'All' })
    const csvExportRef2 = React.useRef();
    const [exportDatas, setExportDatas] = useState([])
    const [sortField, setSortField] = useState('')
    const [actionLists, setActionLists] = useState([{ value: 'All', label: 'All' }, { value: 0, label: 'Pending' }, { value: 1, label: 'Active' }, { value: 2, label: 'Rejected' }, { value: 3, label: 'Expired' }, { value: 4, label: 'Closed' }])
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const [cardinalMembersOnly, setCardinalMembersOnly] = useState(cardinal_members_only == 'true' ? true : false)
    const headers = [
        { label: 'Job Name', key: 'jobName' },
        { label: 'Organization', key: 'organization' },
        { label: 'Primary Owner', key: 'recruiterName' },
        { label: 'Leads', key: 'leads' },
        { label: 'Applicants Received', key: 'applicants' },
        { label: 'Applicants Dispositioned', key: 'archived' },
        { label: 'Email Sent', key: 'send_email' },
        { label: 'SMS Sent', key: 'send_sms' },
        { label: 'Submitted', key: 'submitted' },
        { label: 'First Interview', key: 'firstInterview' },
        { label: 'Second Interview', key: 'secondInterview' },
        { label: 'On Hold', key: 'onhold' },
        { label: 'Offers', key: 'offers' },
        { label: 'Hired', key: 'hired' }
    ];

    const headers2 = [
        { label: 'IDs.', key: 'id' },
        { label: 'Job Name', key: 'jobName' },
        { label: 'Job Link', key: 'jobLink' },
        { label: 'Organization', key: 'organization' },
        { label: 'Skills', key: 'skills' },
        { label: 'Tech Group', key: 'recruiterName' },
    ];

    const organization_type_list = [
        { value: 'all', label: 'All' },
        { value: 'hourly', label: 'Hourly' },
        { value: 'contingency', label: 'Contingency' }
    ];
    const fetchData = async () => {
        setLoder(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("jobStatus_start_date", start_date)
        sessionStorage.setItem("jobStatus_end_date", end_date)
        const url = '/admin/jobs_analytic.json'
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&organization=${selectedOrganization}&cardinal_member=${cardinalMembersOnly}&recruiter=${selectedRecruiter}&account_manager_id=${selectedTeamLead}&organization_type=${selectOrganizationType}&job=${selectedJob}&perform_action=${selectedAction}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setJobStatus(response?.data?.jobs_status.map((data,key)=> (sr_no = sr_no + 1,{
            id: sr_no,
            jobName: (<a href={`/admin/jobs/${data.job_id}/edit`}>{capitalize(data?.job_name && capitalize(data?.job_name))}</a>),
            jName: capitalize(data?.job_name),
            status: data?.job_status,
            linkedin_url: data?.linkedin_url ? <a target='_blank' href={data?.linkedin_url}>
                        <img style={{width:'20px', height:'20px'}}  src={linkedinIcon} /></a>
                         : '',
            organization:  (data?.organization_type)?capitalize(data?.organizations_name)+' ('+(data?.organization_type)?.charAt(0)?.toUpperCase()+')':capitalize(data?.organizations_name),
            recruiterName: `${data?.user_name ? `${capitalize(data?.user_name)}` : '-' }`,
            leads: data.lead > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=lead`}>{data.lead}</a> : '',
            applicants: data.applicant > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=applicant`}>{data.applicant}</a> : '',
            recruiterScreened: data.recruitor_screened > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> : '',
            submitted: data.submitted > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a> : '',
            firstInterview: data.first_interview > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> : '',
            secondInterview: data.second_interview > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> : '',
            onhold: data.onhold > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=onhold`}>{data.onhold}</a> : '',
            offers: data.offer > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a> : '',
            archived: data.reject > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> : '',
            organization_type: data.organization_type,
            send_email: (response?.data?.recruiter_email_send[data.job_id]) ? response?.data?.recruiter_email_send[data.job_id] : '' ,
            send_sms: (response?.data?.recruiter_sms_send[data.job_id]) ? response?.data?.recruiter_sms_send[data.job_id] : '' ,
            hired: data.hired > 0 ? <a href={`/admin/job_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> : ''
        })))
        setFooter({id:'Total',
            applicants:response.data.total_values.applicants,
            leads:response.data.total_values.leads,
            recruiterScreened:response.data.total_values.recruiterScreened,
            submitted:response.data.total_values.submitted,
            firstInterview:response.data.total_values.firstInterview,
            secondInterview:response.data.total_values.secondInterview,
            onhold:response.data.total_values.onhold,
            offers:response.data.total_values.offers,
            archived:response.data.total_values.archived,
            no_of_jobs:response.data.total_values.no_of_jobs,
            no_of_clients:response.data.total_values.no_of_clients,
            hired:response.data.total_values.hired,
            send_sms:response.data.total_sms_send,
            send_email:response.data.total_email_send
        })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoder(false)
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '4%',
            minWidth: '4%'
        },
        {
            name: 'Job name',
            selector: row => row.jobName,
            cell: (row) => <div title={row.jName}>{row.jobName}</div>,
            sortable: true,
            sortField: 'job_name',
            maxWidth: '12%',
            minWidth: '12%',
            wrap:true
        },
        {
            name: 'Job status',
            selector: row => row.status,
            cell: (row) => <div title={row.status}>{row.status}</div>,
            sortable: true,
            sortField: 'job_status',
            maxWidth: '5%',
            minWidth: '5%',
            wrap:true
        },
        {
            name: 'Organization',
            selector: row => row.organization,
            cell: (row) => <div title={row.organization}>{row.organization}</div>,
            sortable: true,
            sortField: 'organizations_name',
            maxWidth: '10%',
            minWidth: '10%',
            wrap:true
        },
        {
            name: 'Primary Owner',
            selector: row => row.recruiterName,
            cell: (row) => <div title={row.recruiterName}>{row.recruiterName}</div>,
            sortable: true,
            sortField: 'user_name',
            maxWidth: '8%',
            minWidth: '8%',
            wrap:true
        },
        {
            name: 'Linkedin',
            selector: row => row.linkedin_url,
            sortable: true,
            sortField: 'linkedin_url',
            maxWidth: 'z%',
            minWidth: '4.5%',
            wrap:true
        },
        {
            name: 'Leads',
            selector: row => row.leads,
            sortable: true,
            sortField: 'lead',
            maxWidth: '4%',
            minWidth: '4%'
        },
        {
            name: 'Applicants Received',
            selector: row => row.applicants,
            sortable: true,
            sortField: 'applicant',
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Applicants Dispositioned',
            selector: row => row.archived,
            sortable: true,
            sortField: 'reject',
            maxWidth: '6.5%',
            minWidth: '6.5%'
        },
        {
            name: 'Email Sent',
            selector: row =>  row.send_email,
            sortable: false,
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'SMS Sent',
            selector: row =>  row.send_sms,
            sortable: false,
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Submitted',
            selector: row => row.submitted,
            sortable: true,
            sortField: 'submitted',
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'First Interview',
            selector: row => row.firstInterview,
            sortable: true,
            sortField: 'first_interview',
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Second Interview',
            selector: row => row.secondInterview,
            sortable: true,
            sortField: 'second_interview',
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'On Hold',
            selector: row => row.onhold,
            sortable: true,
            sortField: 'onhold',
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Offers',
            selector: row => row.offers,
            sortable: true,
            sortField: 'offer',
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Hired',
            selector: row => row.hired,
            sortable: true,
            sortField: 'hired',
            maxWidth: '5%',
            minWidth: '5%'
        }
    ]

    const exportDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("jobStatus_start_date", start_date)
        sessionStorage.setItem("jobStatus_end_date", end_date)
        const url = '/admin/jobs_analytic.json'
        const response = await makeRequest(
            `${url}?organization=${selectedOrganization}&cardinal_member=${cardinalMembersOnly}&recruiter=${selectedRecruiter}&account_manager_id=${selectedTeamLead}&organization_type=${selectOrganizationType}&job=${selectedJob}&perform_action=${selectedAction}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setExportDatas(response?.data?.jobs_status.map((data,key)=> ({
            id: key,
            jobName: data?.job_name && capitalize(data?.job_name),
            organization: (data?.organizations_name)?capitalize(data?.organizations_name)+' ('+(data?.organization_type)?.charAt(0).toUpperCase()+')':capitalize(data?.organizations_name),
            recruiterName: `${data?.user_name ? `${capitalize(data?.user_name)}` : '-' }`,
            leads: data.lead,
            applicants: data.applicant,
            recruiterScreened: data.recruitor_screened,
            submitted: data.submitted,
            firstInterview: data.first_interview,
            secondInterview: data.second_interview,
            onhold: data.onhold,
            offers: data.offer,
            archived: data.reject,
            organization_type: data.organization_type,
            send_email: (response?.data?.recruiter_email_send[data.job_id]) ? response?.data?.recruiter_email_send[data.job_id] : '' ,
            send_sms: (response?.data?.recruiter_sms_send[data.job_id]) ? response?.data?.recruiter_sms_send[data.job_id] : '' ,
            hired: data.hired
        })))
        csvExportRef?.current?.link.click();
    }

    const exportAllDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = '/admin/all_jobs.json'
        const response = await makeRequest(
            `${url}`,
            'get',
            {}
        )
        setExportDatas(response?.data?.jobs?.map((data,key)=> ({
            id: data?.id,
            jobName: data?.name && capitalize(data?.name),
            jobLink: 'https://cardinaltalent.ai/admin/jobs/'+data.id+'/edit',
            organization: data?.organization?.name && capitalize(data?.organization?.name),
            skills: data?.skills && capitalize(data?.skills),
            recruiterName: "",
        })))
        csvExportRef2?.current?.link.click();
    }

    const handleAction = (event) => {
        console.log(event)
        setSelectedAction(event.value)
    }

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/admin/organizations/fetch_organization?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {organizations} = res
          setLoading(false)
          setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(member_options);
    }

    const handleInputChange = (str) => {
        setInputValue(str)
        return str;
    }

    const handleSelectOption = (selectedOptions) => {
        setActivePage(1)
        setSelectedOrganization(selectedOptions?.value || '')
        setSelectedOrganizationValue(selectedOptions || {})
        sessionStorage.setItem("jobStatus_org", JSON.stringify(selectedOptions))
    }

    useEffect(()=>{
        console.log(selectOrganizationType)
        fetchData()
    },[selectedOrganization,selectedRecruiter,cardinalMembersOnly,selectedJob,dateRange,activePage,selectedAction,selectedTeamLead,selectOrganizationType,sortDirection,sortField])

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
    };

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Jobs Analytics
                </P>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" checked={cardinalMembersOnly} onClick={(e)=>{
                                setCardinalMembersOnly(e.target.checked)
                                sessionStorage.setItem('cardinal_members_only',e.target.checked)
                            }} id="cardinal_members_only"/>
                            <label class="custom-control-label" for="cardinal_members_only">CT Member Organizations Only</label>
                    </div>
                    <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                        Export CSV
                    </button>
                    <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportAllDataMethod}>
                        Export All Jobs
                    </button>
                    {jobId && (
                        <a
                            href='javascript:void(0)'
                            onClick={()=>history.back()}
                            className={`ml-1 ${styles.exportCSVButton}`}
                        >
                            Back
                        </a>
                    )}
                </div>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"jobs_analytic.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers2}
                filename={"all_jobs.csv"}
                ref={csvExportRef2}
            >
            </CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
                    <div>
                        <label>
                            Organization
                        </label>
                        <AsyncSelect
                            isLoading={isLoading}
                            isClearable={true}
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultValue={selectedOrganizationValue}
                            defaultOption={selectedOrganization}
                            onInputChange={handleInputChange}
                            onChange={handleSelectOption}
                            placeholder={'Search by organization'}
                            styles={colourStyles}
                            noOptionsMessage={() => 'start typing the name of organization'}
                        />
                    </div>
                    <div style={{marginLeft:'10px'}}>
                        <label>
                            Job Name
                        </label>
                        <Select
                            defaultValue={selected}
                            options={jobs}
                            onChange={(event)=>{
                                setSelectedJob(event.value)
                                sessionStorage.setItem("jobStatus_job", JSON.stringify(event))
                            }}
                            name="job_name"
                            className={styles.colourStyles}
                        />
                    </div>
                    <div style={{  marginLeft: '10px' }}>
                            <label>
                                Job Status
                            </label>
                            <Select
                                defaultValue={defaultActionselected}
                                options={actionLists}
                                onChange={(event) => handleAction(event)}
                                name="perform_action"
                                className={styles.colourStyles}
                            />
                        </div>
                    <div>
                        <label>
                            Recruiter
                        </label>
                        <Select
                            defaultValue={defaultselected}
                            options={recruiter}
                            onChange={(event)=>{
                                setSelectedRecruiter(event.value)
                                sessionStorage.setItem("jobStatus_recruiter", JSON.stringify(event))
                            }}
                            name="recruiter"
                            className={styles.colourStyles}
                        />
                    </div>
                    <div>
                        <label>
                            Account Manager
                        </label>
                        <Select
                            defaultValue={defaultAccountManager}
                            options={account_managers}
                            onChange={(event)=>{
                                setSelectedTeamLead(event.value)
                                sessionStorage.setItem("jobStatus_ac_manager", JSON.stringify(event))
                            }}
                            name="account_manager"
                            className={styles.colourStyles}
                        />
                    </div>
                    <div>
                        <label>
                            Organization Type 
                        </label>
                        <Select
                            defaultValue={defaultOrganizationType}
                            options={organization_type_list}
                            onChange={(event)=>{
                                setSelectOrganizationType(event.value)
                                sessionStorage.setItem("jobStatus_org_type", JSON.stringify(event))
                            }}
                            name="org_type"
                            className={styles.colourStyles}
                        />
                    </div>
                    <div style={{display:'grid'}}>
                        <label>
                            Date
                        </label>

                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                </Col>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobStatus}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                responsive={true}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
        
    )
}

export default JobsStatusPage