import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import styles from './styles/CandidateSearchPage.module.scss'
import CandidateManager from '../../common/CandidateManager/CandidateManager'
import SweetAlert from 'react-bootstrap-sweetalert'

function CandidateSearchPage({ currentUser,free_candiates_page, isEmailConfigured, jobs, currentOrganization, memberOrganization, is_month_organization_limit_exp, profileCompleted, showCandidate, showCandidateModal = true, organization, ctMember, freeCandidates }) {
    const [errorSubmitting, setErrorSubmitting] = useState(null)
    const [filterValue, setFilterValue] = useState([])
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    if (pageName == 'free'){
        localStorage.removeItem("candidate_search_page_no");
       }
    const EMPTY_FILTERS = {
        titles: [],
        locations: [],
        skills: [],
        tags: [],
        company_names: '',
        top_company: false,
        schools: '',
        disciplines: [],
        degrees: [],
        top_school: false,
        active: false,
        passive: (currentUser?.is_commission_recruiter == 'yes') ? true : false,
        phone_number_available: false,
        names: [],
        emails: [],
        email_type: '',
        keyword: '',
        withinPeriodKey: '',
        city: [],
        state: [],
        match_skill: false,
        free_candidates: pageName == 'free' ? "free_candidates" : ''
    }
    const [filterStack, setFilterStack] = useState(EMPTY_FILTERS)
    return ( (profileCompleted) ? 
        (<Container className="p-0" fluid>
             <label
                className={styles.mainTitle + ' page-title'}
                style={{ marginBottom: '2rem' }}
            >
                <span>{pageName === 'free' ? 'Free Candidate Search' : 'Candidate Search' }</span><br/>
                <span className={styles.subTitle}>{pageName === 'free' ? 'Browse thousands of candidates' : 'Browse thousands of active and passive candidates'}</span>
            </label>
            
            <Container className={styles.newcontiner + ' p-5'} fluid>
                {errorSubmitting && (
                    <Alert
                        variant="danger"
                        onClose={() => setErrorSubmitting(null)}
                        dismissible
                    >
                        {errorSubmitting}
                    </Alert> 
                )} 
                {is_month_organization_limit_exp && free_candiates_page != 'free' && (
                    <SweetAlert
                        warning
                        title={'You have reached your free monthly limit.'}
                        showCloseButton={false}
                        showCancel={false}
                        showConfirm={false}
                    >
                        Please email
                        <a href="mailto:sales@cardinaltalent.ai"> sales@cardinaltalent.ai </a> if you'd like a premium account.
                    </SweetAlert>
                )}
                <Row> 
                    <Col md="12" className="">
                        <CandidateManager
                            tableColumns={[ pageName == 'free' ? 
                                'candidate_search' : 'candidate_search'
                            ]}
                            isEmailConfigured={isEmailConfigured}
                            user={currentUser}
                            candidateSource="candidate_search"
                            jobId={null}
                            title=""
                            showSearchField={true}
                            memberOrganization={memberOrganization}
                            enableStages={false}
                            fullJob = {jobs}
                            candidatePage={true}
                            placeholder='Search...'
                            currentOrganization={currentOrganization}
                            filterStack={filterStack}
                            setFilterStack={setFilterStack}
                            emptyFilter={EMPTY_FILTERS}
                            setFilterValue={setFilterValue}
                            filterValue={filterValue}
                            isMonthOrganizationLimitExp={is_month_organization_limit_exp}
                            isFromCandidateSearchPage
                            showCandidate={showCandidate}
                            showCandidateModal= {showCandidateModal}
                            organization={organization}
                            ctMember={ctMember}
                            pageName={pageName}
                            freeCandidates={freeCandidates}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>) : (
            <SweetAlert
                warning
                title={'Incomplete Profile'}
                showCloseButton={false}
                showCancel={false}
                showConfirm={false}
                confirmBtnText="Complete Profile Page"
            >
                <span className='font-weight-normal'>Please complete your profile to view the candidates.</span><br />
                <a href="/complete/profile" className='btn btn-primary mt-2'>Complete Profile Page</a>
            </SweetAlert>
        )
    )
}

export default CandidateSearchPage
