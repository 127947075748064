import React, { useState, useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import NoInterview from '../../../../../assets/images/no_ai_interview.png'
import { makeRequest } from "../../../common/RequestAssist/RequestAssist"
import moment from 'moment'
import Select from 'react-select'
import AiSearchBar from '../../../common/SearchBar/AiSearchBar'

import InterviewCreatePage from './InterviewCreatePage'
import InviteInterviewPage from './InviteInterviewPage'
import { Search } from '@material-ui/icons'

const Index = (prop) => {
    // interview create module
    const [interviewModalShow, setInterviewModalShow] = useState(prop.showDefaultAddModal ? true : false)
    const [showSuccessInterviewCreate, setShowSuccessInterviewCreate] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showRestoreConfirmation, setShowRestoreConfirmation] = useState(false)
    const [openMenuIndex, setOpenMenuIndex] = useState(null)
    const [interviews, setInterviews] = useState([])
    const [countMatrix, setCountMatrix] = useState([])
    const [loading, setLoading] = useState(false)
    const [archivedId, setArchivedId] = useState('')
    const [selectInterview, setSelectInterview] = useState(null)
    const [showCopyConfirmation, setShowCopyConfirmation] = useState(false)
    var status = window.localStorage.getItem('ai_interview_status')
    const [selectedStatus, setSelectedStatus]  = useState(status || 'active')
    const [selected, setSelected] = useState({value: status || 'active', label: status == 'archieved' ? 'Archieved' :  'Active' })
    const colourStatusStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px', width: '160px' })
    };
    const [searchTerm, setSearchTerm] = useState('')

    // invite module
    const [inviteInterviewModal, setInviteInterviewModal] = useState(false)
    const [interviewId, setInterviewId] = useState('')

    const filterOptions = [
        { value: 'active', label: 'Active'},
        { value: 'archieved', label: 'Archieved' }
    ]

    const handleSelectStatus = (selectedval) =>{
        setSelectedStatus(selectedval.value)
        window.localStorage.setItem('ai_interview_status',selectedval.value)
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
    }

    const interviewList = async () => {
        const url = '/ai_interviews/list'
        const responce = await makeRequest(url, 'get',  {params: {status: selectedStatus, search: searchTerm}}, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                setInterviews(responce.interviews)
                setCountMatrix(responce.grouped_data)
                setLoading(true)
            },
        })
    }

    useEffect(() => {
        interviewList()
    },[])
    useEffect(() => {
        interviewList()
    },[interviewId,selectedStatus])

    const handleMenuToggle = (index) => {
        setOpenMenuIndex(openMenuIndex === index ? null : index);
    }

    const onDeleteClick = (obj, event) => {
        setArchivedId(obj.id)
        setSelectInterview(obj)
        setShowDeleteConfirmation(true)
        setOpenMenuIndex(null)
    }

    const onRestoreClick = (obj, e) => {
        setArchivedId(obj.id)
        setSelectInterview(obj)
        setShowRestoreConfirmation(true)
        setOpenMenuIndex(null)
    }

    const onCopyClick = (obj, event) => {
        setArchivedId(obj.id)
        setSelectInterview(obj)
        setShowCopyConfirmation(true)
        setOpenMenuIndex(null)
    }

    // Delete candidate 
    const onDeleteInterview = () => {
        if(archivedId != '' && archivedId != null){
            const url = `/ai_interviews/interview/${archivedId}/delete`
            makeRequest(url, 'delete',  {}, {   
            contentType: 'application/json',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                    if(res.data.success){
                        interviewList()
                        setArchivedId('')
                        setSelectInterview(null)
                    }
            },
            })
        }
    }

    const onRestoreInterview = () => {
        if(archivedId != '' && archivedId != null){
            const url = `/ai_interviews/interview/${archivedId}/restore`
            makeRequest(url, 'get',  {}, {   
            contentType: 'application/json',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                    if(res.data.success){
                        interviewList()
                        setArchivedId('')
                        setSelectInterview(null)
                    }
            },
            })
        }
    }

    const getMenu = (obj, index) => {
        return (
            <div className="action-menu-table">
                <div className="kebab-menu" onClick={() => handleMenuToggle(index)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                {openMenuIndex === index && (
                    <>
                    <ul className="submenu-table">
                       { (obj.discarded_at == null) ?
                        <>
                            <li><button onClick={(event) => onDeleteClick(obj, event)}>Archive</button></li>
                            <li><button onClick={(event) => onCopyClick(obj, event)}>Copy</button></li>
                            <li><a href={`/ai_interviews/interview/${obj.id}/edit`} style={{ padding: '5px 10px', color: '#1A141F'}}>Edit</a></li>
                        </>
                       : <li><button onClick={(event) => onRestoreClick(obj, event)}>Restore</button></li>}
                     </ul>
                    </>
                )}
            </div>
        );
    }

    const copyInterview = async () => {
        if(archivedId != '' && archivedId != null){
            setShowCopyConfirmation(false)
            const url = `/ai_interviews/interview/${archivedId}/copy`
            await makeRequest(url, 'get', '', {
                createSuccessMessage: () => 'Interview copy successfully',
                createErrorMessage: (e) => e.response.data.msg,
                onSuccess: (res) => {      
                    if(res.data.success == true){
                        console.log(res.data)
                        window.location.href="/ai_interviews/interview/"+ res.data.interview.id +"/edit"
                    }
                },      
            })
        }     
    }  
    
    return (
        <>
          <div className="container-fluid ai-containter">
                <div className="d-flex justify-content-between align-items-center mb-2 mt-5 pb-3 border-bottom">
                    <div className="d-inline-flex align-items-center">
                        { prop?.organization && prop?.organization?.image_url && prop?.organization?.image_url != null &&
                            <img
                                alt={ prop.organization.name} src={ prop.organization.image_url}
                                width="50" height="50"
                                className="d-inline-block align-top"
                            />
                        }
                        {' '}
                        <div className="ml-2">
                            <p className="mb-n1">{ prop.organization.name}</p>
                            <a href={ prop.organization.website_url} target='_blank' className="text-decoration-underline">{ prop.organization.website_url }</a>
                        </div>
                    </div>
                    <div style={{display: 'grid', float: 'right'}} className='ml-auto mr-3'>
                            <label>
                                Select Status
                            </label>
                            <Select 
                            defaultValue={selected}
                            options={filterOptions} 
                            onChange={handleSelectStatus} 
                            placeholder={'Select Status'} 
                            styles={colourStatusStyles}
                            className='mt-1'
                            />
                    </div>
                    <div style={{display: 'grid', float: 'right'}} className='mr-5'>
                        <label style={{ paddingLeft: '25px'}}>
                                Search
                        </label>
                        <AiSearchBar
                            placeholder="Search"
                            value={searchTerm}
                            setValue={setSearchTerm}
                            onChange={(e) => onChangeSearchTerm(e.target.value)}
                            onEnterPressed={() => {
                                interviewList()
                            }}
                            onClick={(e) => setSearchTerm('')}
                        />
                    </div>
                    <div style={{display: 'grid', marginTop: '30px'}}>
                        <div style={{display: 'grid'}}>
                            <a href='#' className="d-inline-flex" onClick={(e)=> setInterviewModalShow(true)}>
                                Create AI Interview
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(70,146,221,1)" style={{ width: '24px', height: '24px', marginLeft: '12px' }}><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg>
                            </a>
                        </div>
                       
                    </div>
                </div>
                { (interviews.length == 0 && loading) &&
                    <div className="mb-2 pb-3" style={{marginTop: '6%'}}>
                            <div style={{textAlign: 'center'}}>
                                <img src={NoInterview} alt="No AI Interview Found" height={400} width={400} />
                            </div>
                     </div>
                }
                { interviews.length > 0 &&
                    <div className="mb-2 pb-3" style={{marginTop: '2%'}}>
                        <table className='table ai-table'>
                            <thead>
                                <tr>
                                    <th>Interview Title</th>
                                    <th>Interview Type</th>
                                    <th>Job Title</th>
                                    <th className='td-center'>Invitations Sent</th>
                                    <th className='td-center'>Completed Interview</th>
                                    <th>Created On</th>
                                    <th className='td-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { interviews.map( (val, key) => (
                                    <tr>
                                        <td><a href={`/ai_interviews/${val.id}/detail`} className='theme-font-color'>{val.interview_title}</a></td>
                                        <td>{val.interview_type}</td>
                                        <td>{val.job.name}</td>
                                        <td className='td-center'> {(countMatrix[val.id]) ? <a href={`/ai_interviews/${val.id}/invites`} > { countMatrix[val.id]['total']}</a> : 0 } </td>
                                        <td className='td-center'>{(countMatrix[val.id]) ? ((countMatrix[val.id]['statuses']['Completed']) ? <a href={`/ai_interviews/${val.id}/candidate_report`} > { countMatrix[val.id]['statuses']['Completed']}</a> : 0 ): 0 }</td>
                                        <td>{moment(val.created_at).format('DD MMM-YYYY')}</td>
                                        <td style={{textAlign: '-webkit-center'}}>{ getMenu(val,key) }</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            {interviewModalShow &&
                <InterviewCreatePage
                    interviewModalShow={interviewModalShow}
                    setInterviewModalShow={setInterviewModalShow}
                    jobs={prop.jobs}
                    jobSkills={prop.job_skills}
                    currentUser={prop.currentUser}
                    isInterviewCreated={prop.isInterviewCreated}
                    setInviteInterviewModal={setInviteInterviewModal}
                    setInterviewId={setInterviewId}
                    setShowSuccessInterviewCreate={setShowSuccessInterviewCreate}
                />
            }
            {inviteInterviewModal ? 
                <InviteInterviewPage
                inviteInterviewModal={inviteInterviewModal}
                setInviteInterviewModal={setInviteInterviewModal}
                interview_id={interviewId}
                />
                : ''
            }

            {showSuccessInterviewCreate &&
                <SweetAlert
                    success // This makes it a success alert
                    title="Success!"
                    onConfirm={() => {setShowSuccessInterviewCreate(false); setInviteInterviewModal(true)}} // Custom function for "Invite Candidate"
                    onCancel={() => (setShowSuccessInterviewCreate(false))} // Custom function for "Not Now"
                    confirmBtnText="Invite Candidates"
                    cancelBtnText="Not Now"
                    showCancel
                    confirmBtnStyle={{ backgroundColor: "#005999", color: "white", borderColor: '#005999', fontWeight: 300, textDecoration: 'none' }} // Style for Invite Candidate button
                    cancelBtnStyle={{ backgroundColor: "#bd1d23", color: "white", borderColor: '#bd1d23', fontWeight: 300, textDecoration: 'none' }} // Style for Not Now button
                >
                <div>
                  <h5 style={{fontWeight: 400}}>
                    Your AI interview has been successfully created.
                    Please invite candidates to participate in the interview.
                  </h5>
                </div>
              </SweetAlert>
            }
            {showDeleteConfirmation &&
                <SweetAlert
                    title="Are you sure you want to archive this?"
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    onConfirm={()=>(onDeleteInterview(),setShowDeleteConfirmation(false))}
                    onCancel={()=>(setArchivedId(''),setShowDeleteConfirmation(false))}
                    confirmBtnStyle={{ backgroundColor: "#005999", color: "white", borderColor: '#005999', fontWeight: 300, textDecoration: 'none', boxShadow: 'none' }} // Style for Invite Candidate button
                    cancelBtnStyle={{ backgroundColor: "rgb(255, 255, 255)", color: "rgb(0, 89, 153)", borderColor: 'rgb(0, 89, 153)', fontWeight: 300, textDecoration: 'none' }} // Style for Not Now button
                    >
                         <><span><b>Interview Title:</b> { selectInterview?.interview_title } </span>
                         <br /><br/> <span><b>Job Title:</b> { selectInterview?.job?.name }</span></>
                </SweetAlert>
            }
            {showCopyConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, copy it!"
                    confirmBtnBsStyle="warning"
                    title="Are you sure you want to copy?"
                    onConfirm={()=>(copyInterview(),setShowCopyConfirmation(false))}
                    onCancel={()=>setShowCopyConfirmation(false)}
                    confirmBtnStyle={{ backgroundColor: "#005999", color: "white", borderColor: '#005999', fontWeight: 300, textDecoration: 'none', boxShadow: 'none' }} // Style for Invite Candidate button
                    cancelBtnStyle={{ backgroundColor: "rgb(255, 255, 255)", color: "rgb(0, 89, 153)", borderColor: 'rgb(0, 89, 153)', fontWeight: 300, textDecoration: 'none' }} // Style for Not Now button
                    >
                    <><span><b>Interview Title:</b> { selectInterview?.interview_title } </span>
                    <br /><br/> <span><b>Job Title:</b> { selectInterview?.job?.name }</span></>
                </SweetAlert>
            }
            {showRestoreConfirmation &&
                <SweetAlert
                    title="Are you sure you want to restore this?"
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    onConfirm={()=>(onRestoreInterview(),setShowRestoreConfirmation(false))}
                    onCancel={()=>(setArchivedId(''),setShowRestoreConfirmation(false))}
                    confirmBtnStyle={{ backgroundColor: "#005999", color: "white", borderColor: '#005999', fontWeight: 300, textDecoration: 'none', boxShadow: 'none' }} // Style for Invite Candidate button
                    cancelBtnStyle={{ backgroundColor: "rgb(255, 255, 255)", color: "rgb(0, 89, 153)", borderColor: 'rgb(0, 89, 153)', fontWeight: 300, textDecoration: 'none' }} // Style for Not Now button
                    >
                         <><span><b>Interview Title:</b> { selectInterview?.interview_title } </span>
                         <br /><br/> <span><b>Job Title:</b> { selectInterview?.job?.name }</span></>
                </SweetAlert>
            }
     </>
    )
}

export default Index