import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import TextInput from '../../common/TextInput/TextInput'
import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import axios from 'axios'
import { passwordSchema } from '../../common/ValidationSchema/PasswordSchema'
import {H1, P, A, SPAN, DIVERROR, DIVSUCCESS, Button} from './styles/Signup.styled'

const initialFormData = {
  password: '',
  password_confirmation: '',
}

const PasswordChangePage = () => {
  const [formData, setFormData] = useState(initialFormData)

  return (
    <>
      <MainPanel>
        <H1>Reset Password</H1>
        <SPAN>
          Your new password should be different from current and old passwords.
        </SPAN>

        <Formik
          initialValues={{
            password: formData.password,
            confirmPassword: formData.password_confirmation,
          }}
          validationSchema={Yup.object({
            ...passwordSchema,
          })}
          onSubmit={(values, { setSubmitting }) => {
            document.getElementById('signin-success-msg').innerHTML = ''
            document.getElementById('signin-error-msg').innerHTML = ''
            setFormData((prev) => ({
              ...prev,
              user: {
                password: values.password,
                confirmPassword: values.confirmPassword,
              },
            }))
            const data = { ...formData }
            const payload = new FormData()
            const url = '/users/password'
            payload.append('user[password]', values.password)
            payload.append(
              'user[password_confirmation]',
              values.confirmPassword
            )
            payload.append(
              'user[reset_password_token]',
              document.querySelector("input[id='reset_password_token']").value
            )
            payload.append(
              'user[accepts_date]',
              document.querySelector("input[id='accepts_date']").value
            )
            payload.append(
              'user[accepts]',
              document.querySelector("input[id='accepts']").value
            )
            payload.append('format', 'js')
            axios
              .patch(url, payload)
              .then(function (response) {
                document.getElementById('signin-success-msg').innerHTML =
                  'Sucessfully changed password'
                setTimeout(() => {
                  window.location = '/'
                }, 2000)
              })
              .catch(function (error) {
                document.getElementById('signin-error-msg').innerHTML =
                  'Wrong email or password!'
              })
          }}
        >
          <Form>
            <DIVERROR id="signin-error-msg"></DIVERROR>

            <br />

            <DIVSUCCESS id="signin-success-msg"></DIVSUCCESS>

            <br />

            <TextInput
              label="Password"
              name="password"
              type="password"
              id="password"
              width="20rem"
            />

            <br />

            <TextInput
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              id="confirmPassword"
              width="20rem"
            />

            <br />

            <Button
              type="submit"
              className="float-right"
              style={{ marginTop: '10px' }}
            >
              Send
            </Button>
          </Form>
        </Formik>
      </MainPanel>
      <InfoPanel>
        <div className="d-flex flex-column">
          <P>Already have an account?</P>
          <A href="/users/sign_in">Sign In</A>
        </div>
      </InfoPanel>
    </>
  )
}

export default PasswordChangePage
