import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DateSelector from '../DateSelector.js/DateSelector';
import { makeRequest } from '../RequestAssist/RequestAssist';
import TimePicker from '../TimePicker/TimePicker';
import './styles/CandidateTableModal.scss'

const InterviewScheduleModal = ({
    moveStage,
    moveJobId,
    moveUserId,
    moveSelectedCandidates,
    checkInterviewStage,
    jobs,
    allSelectedCandidates,
    changeStage,
    interviewTypeId,
    setInterviewTypeId,
    interviewRecheduleModal,
    setReschedule,
    candidateinterviewid,
    setCheckInteviewStage,
    setInterviewRescheduleModal,
    reschedule,
    candidateId,
    jobId,
    styles
   }) => {
    const [validationDate, setValidationDate] = useState()
    const [validationTime, setValidationTime] = useState('')
    const [validationTimeZone, setValidationTimeZone] = useState('')
    const [dateError, setDateError] = useState()
    const [timeZoneMsg, setTimeZoneMsg] = useState()
    const [timeErrors, seTimeErrors] = useState()
    const [ZoneError, seZoneError] = useState()
    const [saveData, setSaveData] = useState(false)
    const [selectedTimes, setSelectedTimes] = useState([])
    const [timezone, setTimeZones] = useState()
    const [timeError, setTimeError] = useState(false)
    const [amError, setAmError] = useState(false)
    const [disable, setDisable] = useState(false)
    const [interviews, setInterviews] = useState([
        { id: nanoid(), date: null, time: null, stage: null, timeZone: null },
    ])
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    const [dateRange, setDateRange] = useState({
        startDate: moment(new Date()).toDate(),
        endDate: moment(new Date()).toDate(),
    })
    const [backgroundColor, setBackgroundColor] = useState({
        active: false,
        id: null,
        matchDate: null,
    })
    const [selectedDate, setSelectedDate] = useState()

    const submitScheduleData = () => {
        if (reschedule) {
            setDisable(false)
            setValidationDate(selectedDate == undefined ? 'Please select interview date' : '')
            // setValidationTime((timezone.hour == '') && (timezone.minute == '') && 'Please select valid interview time ')
            // setValidationTime(timeErrors && 'Please select interview time ')
            // setValidationTimeZone(ZoneError && 'Please select timezone')
            if (dateError == false) {
                const payload = new FormData()
                payload.append('interview_schedule[interview_date]', selectedDate)
                payload.append('interview_schedule[interview_time]', JSON.stringify(timezone))
                payload.append('interview_schedule[interview_type]', candidateinterviewid.interview_type)
                payload.append('interview_schedule[id]', candidateinterviewid.id)
                payload.append('interview_schedule[person_id]', candidateinterviewid.person_id)
                payload.append('interview_schedule[method]', 'put')
                payload.append('interview_schedule[interview_status]', reschedule ? 'reschedule' : 'schedule')
                makeRequest('/submit_interview_schedule', 'post', payload, {
                    contentType: 'application/json',
                    loadingMessage: 'Submitting...',
                    createResponseMessage: (res) => {
                        setInterviewRescheduleModal(false)
                        window.location.href = `/interview_scheduled`
                        return {
                            message: res.msg,
                            messageType: res.messageType
                        }
                    },
                })
            }
        } else {
            setDisable(false)
            setValidationDate(selectedDate == undefined ? 'Please select interview date' : '')
            // setValidationTime((timezone.hour == '') && (timezone.minute == '') && 'Please select valid interview time ')
            
            // setValidationTime(timeErrors && 'Please select interview time ')
            // setValidationTimeZone(ZoneError && 'Please select timezone')
            // if (dateError == false && timeErrors == false && ZoneError == false) {
            if (dateError == false) {
                setValidationDate('')
                setValidationTime('')
                setValidationTimeZone('')
                var findId = pageName == 'free' ? [candidateId] : allSelectedCandidates.map((i) => i.id)
                const person_ids = pageName == 'free' ? findId : [...findId]
                const payload = new FormData()
                payload.append('interview_schedule[interview_date]', selectedDate)
                payload.append(
                    'interview_schedule[interview_time]',
                    JSON.stringify(timezone)
                )
                payload.append('interview_schedule[person_id]', person_ids)
                if (!(pageName == 'free')) {
                    payload.append('interview_schedule[job_id]', jobId)
                }
                if (pageName == 'free') {
                    payload.append('interview_schedule[free]', 'free')
                }
                payload.append('interview_schedule[interview_type]', pageName == 'free' ? 'first_interview' : interviewTypeId)
                makeRequest('/submit_interview_schedule', 'post', payload, {
                    contentType: 'application/json',
                    loadingMessage: 'Submitting...',
                    createResponseMessage: (res) => {
                        setDisable(res?.success == true ? true : false)
                        return {
                            message: res.msg,
                            messageType: res.messageType
                        }
                    },
                })
                if (pageName == 'free') {
                    setCheckInteviewStage(false)
    
                }
                if (!(pageName == 'free')) {
                    changeStage(moveStage, moveJobId, moveSelectedCandidates, moveUserId, false)
                }
            }
        }
    }
    const checktimeZone = (time) => {
        setTimeZoneMsg(time.timeZone)
        seZoneError(time.timeZone !='Time-zone'? false :true)
        // seTimeErrors((time.hour == '00') || (time.hour == '') || (time.timeZone == "Time-zone")  ? true : false)
        seTimeErrors((time.hour == '00') || (time.hour == '') ? true : false)
      }

      const closeInterviewScheduleModal = () => {
        if(!reschedule){
            setCheckInteviewStage(false)
            if (!(pageName == 'free')){
                window.location.href = `/jobs/${jobId}`
            }
        }
        setInterviewRescheduleModal(false)
    }
    const getLastYearDate = () => {
        const today = new Date();
        const lastYear = new Date(today);
        lastYear.setFullYear(today.getFullYear() - 1);
        return lastYear;
      };
    return(
        <>
         <Modal
                    onHide={closeInterviewScheduleModal}
                    className={pageName == 'free' ? 'edit-job-modal-free-candidate-interview-modal' : "edit-job-modal-interview-modal"}
                    show={checkInterviewStage || interviewRecheduleModal}
                    // show={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header
                    closeButton
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Modal.Title>{reschedule ? "Candidate Interview Reschedule" : "Candidate Interview Schedule"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ marginTop: '24px' }}>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm">
                                    <label>Select Date</label>
                                    <DateSelector
                                        handleOnSubmit={(date) => {
                                            setValidationDate('')
                                            setDateError(false)
                                            setSelectedDate(date)
                                        }}
                                        submitName="Select"
                                        dateselected={selectedDate}
                                        minDate={getLastYearDate()} 
                                    />
                        {/* {candidateinterviewid.interview_date &&
                        <div className='container row'>
                            <div>
                                <h6 className={styles.skillTitle}>current Date:</h6>
                            </div>
                            <div className={styles.badge}>
                            {moment(candidateinterviewid?.interview_date).format('dddd, MMM DD,YYYY')}
                            </div> 
                        </div>} */}
                                    <p
                                        style={{
                                            color: 'red',
                                            fontSize: '11px',
                                            marginLeft: '2px',
                                        }}
                                    >
                                        {validationDate}
                                    </p>
                                </div>
                                <div class="col-sm">
                                    <label>Select Time</label>
                                    <TimePicker
                                        selectedTimes={selectedTimes}
                                        handleOnTime={(time) => {
                                            // setValidationDate('')
                                            const clone = selectedTimes
                                            clone[interviews] = time
                                            setSelectedTimes(clone)
                                            setValidationTime('')
                                            checktimeZone(time)
                                            // seTimeErrors(time.hour.length >= 1 ? false : true)
                                            setTimeZones(clone[interviews])
                                            setTimeError(timeError - 1)
                                            setAmError(false)
                                        }}
                                    />
                                    <p
                                        style={{
                                            color: 'red',
                                            fontSize: '11px',
                                        }}
                                    >
                                        {validationTime?.length > 1 ? validationTime : validationTimeZone}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex" style={{ marginTop: '-50px' }}>
                            <div className="modal-close-btn">
                                <Button
                                    variant="secondary"
                                    onClick={closeInterviewScheduleModal}
                                >
                                    Close
                                </Button>
                            </div>
                            <div className="modal-close-btn">
                                <Button
                                    variant="secondary"
                                    onClick={submitScheduleData}
                                    disabled={disable}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                            <Button variant="primary">Save changes</Button>
                        </Modal.Footer> */}
                </Modal>
        </>
    )
}
export default InterviewScheduleModal;