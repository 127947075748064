import React, { useEffect, useState,Fragment} from 'react'
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Paginator from '../../common/Paginator/Paginator'
import Util from '../../../utils/util'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import './styles/MyNetworkIndex.scss'
import ManageMyMetworkSideBar from './ManageMyMetworkSideBar'
import lodingImage from '../../../../assets/v2/imgs/theme/loading_new.gif'
import Moment from 'moment'
import '../../../../assets/v2/css/main.css'
import '../../pages/Interview/styles/AiInterview.scss'

function AllMyCandidate({ current_user,my_total_candidates,received,sent,reject }) {
    const [loading, setLoader] = useState(true)
    const [candidates, setCandidates] = useState([])
    const [activePage, setActivePage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [sortBy, setSortBy] = useState('created_at')
    const [searchBy, setSearchBy] = useState('')
    const fetchData = async() => {
        setCandidates([])
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setLoader(true)
        const url = `/my_networks/my.json`
        await makeRequest(url, 'get',  {
                params: {page: (activePage+1),sort_by: sortBy,search_by: searchBy}
            },{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setCandidates(
                    res.data.candidates.map((candidate) => ({
                        id: candidate.id,
                        user_id: (
                            (current_user.id == candidate?.user2?.id) ? (candidate?.user1 && candidate?.user1?.id) :
                            (candidate?.user2 && candidate?.user2?.id)
                          ),
                        first_name: (
                            (current_user.id == candidate?.user2?.id) ? (candidate?.user1?.person && capitalize(candidate?.user1?.person?.first_name)) :
                            (candidate?.user2?.person && capitalize(candidate?.user2?.person?.first_name))
                          ),
                        last_name: (
                            (current_user.id == candidate?.user2?.id) ? (candidate?.user1?.person && capitalize(candidate?.user1?.person?.last_name)) :
                            (candidate?.user2?.person && capitalize(candidate?.user2?.person?.last_name)) 
                        ),
                        title: (
                            (current_user.id == candidate?.user2?.id) ? (candidate?.user1?.person && candidate?.user1?.person?.title) :
                            (candidate?.user2?.person && candidate?.user2?.person?.title)
                        ),
                        location: (
                            (current_user.id == candidate?.user2?.id) ? (candidate?.user1?.person && candidate?.user1?.person?.location) :
                            (candidate?.user2?.person && candidate?.user2?.person?.location) 
                        ),
                        state: candidate.state || '',
                        city: candidate.city || '',
                        updated_at: candidate.updated_at,
                        avatar_url: (
                            (current_user.id == candidate?.user2?.id) ? (candidate?.user1?.person && candidate?.user1?.person?.avatar_url) :
                            (candidate?.user2?.person && candidate?.user2?.person?.avatar_url)
                        ),
                        employer: (
                            (current_user.id == candidate?.user2?.id) ?  (candidate?.user1?.person && candidate?.user1?.person?.employer) :
                            (candidate?.user2?.person && candidate?.user2?.person?.employer) 
                        ),
                        description: (
                            (current_user.id == candidate?.user2?.id) ? (candidate?.user1?.person && candidate?.user1?.person?.description) :
                            (candidate?.user2?.person && candidate?.user2?.person?.description) 
                        ),
                    }))
                )
                setPageCount(res.data.total_pages)
                setTotalCount(res.data.total)
                setLoader(false)
            },
        })
    }
    useEffect(()=>{
        fetchData()
    },[activePage,sortBy,searchBy])

    return (
        <>
            <div className="section-box mt-20">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 float-right">
                            <div className="content-page box-boder2">
                               
                                    <div className="box-filters-job mt-15 mb-10">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h5 className={'fw-bold'}>My Connection</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {loading == false && candidates.length > 0 ? (''):(
                                    <div className="box-filters-job pt-20 mb-10">
                                        <div className="row">
                                            <div className="col-lg-12 text-center p-10">
                                                <h5 className={'fw-bold'}>No Data Found</h5>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="box-filters-job mb-10">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7">
                                            <div className="form-group mb-0">
                                                <input type="text"   onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setSearchBy(e.target.value);
                                                        }
                                                    }} className="form-control input-bg-white form-icons"
                                                 placeholder="Search by name" />
                                                <i className="fi-rr-search"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 text-lg-end mt-sm-15">
                                            <span className="text-sortby mr-2">Sort by:</span>
                                            <div className="dropdown dropdown-sort">
                                                <select className="form-control sortby form-icons" onClick={(e) => {
                                                        setSortBy(e.target.value)
                                                    }
                                                }>
                                                    <option value="created_at">Newest</option>
                                                    <option value="first_name">First name</option>
                                                    <option value="last_name">Last Name</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="employers-list">
                                {(loading) ? 
                                    <div class="text-center loading">
                                        <img src={lodingImage} alt="loading" />
                                    </div>
                                :''}
                                {candidates && candidates.map((candidate, index) => {
                                    return(
                                        <div key={candidate.id}  className="card-employers hover-up wow animate__animated animate__fadeIn">
                                            <div className="row align-items-center">
                                                <div className="col-lg-9 col-md-6 d-flex">
                                                    <div className="employers-logo  mr-15">
                                                        <a href={`/users/profile/${candidate.user_id}`} className={'invite_candidate'}>
                                                            <figure>
                                                                {candidate?.avatar_url ?
                                                                    <img alt="jobhub" className={'img_border'} src={(candidate?.avatar_url != '')?candidate.avatar_url:'assets/imgs/page/candidates/img-candidate10.png'} />
                                                                    : <CandidateAvatarIcon firstName={candidate?.first_name} lastName={candidate?.last_name} />
                                                                }
                                                            </figure>
                                                        </a>
                                                    </div>
                                                    <div className="employers-name">
                                                        <h5><a href={`/users/profile/${candidate.user_id}`}><strong>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</strong></a></h5>
                                                        <span className="text-sm  text-muted overflowtext" title={candidate?.description}>{candidate?.description || 'N/A'}</span>
                                                        <span className="d-flex align-items-center"><i className="fi-rr-marker mr-5 ml-0"></i>{candidate?.location || 'N/A'}</span>
                                                        <span className="text-sm  text-muted overflowtext">Connected {Moment(candidate?.updated_at).fromNow()}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 text-lg-end d-lg-block d-none">
                                                    <a href={`/network_conversations?user=${candidate?.user_id}`}
                                                     class="btn btn-default btn-brand-hover rounded-pill">
                                                        <div className={'d-flex align-items-center'}><i class="fi-rr-envelope"></i> <span className='mt-0 ml-10 fw-bold'>Message</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                             {(pageCount>1) ? 
                                <div className="paginations">
                                    <Paginator
                                        pageCount={pageCount}
                                        activePage={activePage}
                                        setActivePage={setActivePage}
                                        pageWindowSize={10}
                                    />
                                </div>
                            :''}
                        </div>
                        <ManageMyMetworkSideBar my_total_candidates={my_total_candidates} received={received} sent={sent} reject={reject}></ManageMyMetworkSideBar>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllMyCandidate
