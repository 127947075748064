import React, { useState, useEffect,useRef} from 'react'
import './../styles/communities.scss'
import image1 from '../../../../../assets/v2/imgs/placeholder.jpg'
import defaultUSerImage from '../../../../../assets/images/placeholder.png'
import AboutLeftPenal from './AboutLeftPenal'
import { Card, Row, Col, Modal, Form, Button, Spinner } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import '../../../../../assets/v2/css/main.css'

const AboutPage = ({community,person,total_member,current_user,community_subscription,isCommunityAdmin}) => {
  const [objCommunity, setObjCommunity] = useState(JSON.parse(community))
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  //Actual data
  const [allMedias, setAllMedias] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  // Dummy data
  // const [allMedias, setAllMedias] = useState(images);
  // const [selectedImage, setSelectedImage] = useState(images[0]);
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [media, setMedia] = useState('');
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)

  const handleVideoUrlChange = (e) => {
    setVideoUrl(e.target.value);
  };

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setSelectedImage(allMedias[0])
  }, [allMedias]);

  const fetchData = async () => {
    const url = '/communities/' + objCommunity.id + `/medias.json`
    makeRequest(url, 'get', '', {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      onSuccess: (response) => {
        setAllMedias(
          response.data.communityMedia.map((list) => ({
            id: list.id,
            type: list.file_type,
            url: list.file_link,
          }))
        )
      },
    })
  }

  const handleFileChange = async (e) => {
    let selectedfile = e.target.files[0]
    let errors = {};
    setErrors({});
    if (selectedfile && selectedfile.size > 1024 * 1024 * 4) {
      errors.imageFile = 'File size cannot exceed more than 4MB';
      setErrors(errors)
    }else{
      setImageFile(selectedfile);
      const payload = new FormData()
      payload.append('community_media[community_id]', objCommunity.id)
      payload.append('community_media[file_type]', 'image')
      payload.append('community_media[image_file]', selectedfile)
      setImageLoading(true)
      const response = await sendRequest(payload)
    }
  };

  const sendRequest = async (payload) => {
    let URL = `/communities/${objCommunity.id}/medias/create`
    const response = await makeRequest(URL, 'post', payload, {
      contentType: 'multipart/form-data',
      loadingMessage: 'Submitting...',
      createSuccessMessage: (response) => response.data.message,
      createResponseMessage: (response) => {
        return {
          message: response.message,
          messageType: response.success ? 'success' : 'failure',
          loading: false,
          autoClose: true,
        }
      },
      onSuccess: (res) => {
        setImageLoading(false)
        setLoading(false)
        closeModal()
        fetchData()
      },
    })
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (videoUrl) {
      const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=).{11}$/;
      isValid = pattern.test(videoUrl);
      if(!isValid){
        errors.videoUrl = 'Please enter valid youtube Url. Example: https://www.youtube.com/wath?v=[Id]';
      }
    }

    if (!videoUrl) {
      errors.videoUrl = 'Please enter YouTube video link';
      isValid = false;
    }

    if (imageFile && imageFile.size > 1024 * 1024 * 4) {
      errors.imageFile = 'File size cannot exceed more than 4MB';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = new FormData()
      payload.append('community_media[community_id]', objCommunity.id)
      payload.append('community_media[file_link]', videoUrl)
      payload.append('community_media[file_type]', 'video_url')
      setLoading(true)
      const response = await sendRequest(payload)
    }
  };


  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const addFiles = () => {
    setShowModal(true)
  };

  const closeModal = () => {
    setVideoUrl('');
    setImageFile('');
    setShowModal(false)
  };

  const getYoutubeVideoId = (url) => {
    const match = url.match(/(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    return match && match[1];
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    const resizeIframe = () => {
      if (iframe) {
        let scrolHeight = iframe.contentWindow.document.body.scrollHeight + 15
        iframe.style.height = `${scrolHeight}px`;
      }
    };

    // Resize the iframe on load and whenever content changes
    iframe.onload = resizeIframe;
    window.addEventListener('resize', resizeIframe);

    return () => {
      window.removeEventListener('resize', resizeIframe);
    };
  }, [objCommunity.description]);

  const deleteMedia = (media) => {
    setMedia(media.id);
    setShowRemoveConfirmation(true);
  }
  
  const handleDelete = (e) => {
    const url = `/communities/${objCommunity.id}/medias/${media}`
    makeRequest(url, 'delete', '', {
      createSuccessMessage: () => 'Media deleted successfully',
      createErrorMessage: (e) => e.response.data.msg,
      onSuccess: () => {
        setShowRemoveConfirmation(false)
        fetchData()
      },
    })
  }


  return (
    <>
    <div className="container communities-detail">
      <div className="row">
        <div className="col-lg-8 p-0 m-0">
          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card-blog-1 hover-up wow animate__animated animate__fadeIn" data-wow-delay=".0s">
                <h3 className="post-title mb-15"><a >{ objCommunity.name }</a></h3>
                {selectedImage == null && (
                  (!isCommunityAdmin) ? (
                    <figure className="post-thumb mb-15">
                      <a >
                        <img alt="jobhub" style={{ height: '450px', width: '100%'}} src={ (objCommunity?.cover_image && objCommunity?.cover_image != '' && objCommunity?.cover_image != null) ? objCommunity?.cover_image : image1} />
                      </a>
                    </figure>
                  ) : (
                    <div className='uploadMedias' onClick={addFiles}>Upload images / videos</div>
                  )                 
                )}
                <div style={{ marginTop: '20px' }}>
                  {selectedImage && (
                    <>
                      {selectedImage.type == 'image' && (
                      <figure className="post-thumb mb-15">
                        <img alt="jobhub" style={{ height: '450px', width: '100%'}} src={selectedImage.url} />
                      </figure>
                      )}
                      {selectedImage.type == 'video_url' && (
                        
                        <iframe width="100%" height="450" 
                          src={"https://www.youtube.com/embed/"+getYoutubeVideoId(selectedImage.url)}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                          autoPlay
                        />
                        
                      )}
                      <Row xs={1} md={2} lg={6} className="g-2 group_medias">
                      {(allMedias && allMedias.length) ? (
                        allMedias.map((item, index) => (
                          <Col key={index} style={{paddingRight: '5px', paddingLeft: '5px'}}>
                            <Card style={{ cursor: 'pointer' }} onClick={() => handleImageClick(item)}>
                            {item.type === 'image' && (
                              <Card.Img className={(selectedImage.id === item.id) ? "active_media" : ""} variant="top" src={item.url} alt={`Image ${index + 1}`} style={{height: '100px',borderRadius: '10px'}}/>
                            )}
                            {item.type === 'video_url' && (
                              <>
                                <Card.Img className={(selectedImage.id === item.id) ? "active_media" : ""} variant="top" src={"https://i.ytimg.com/vi/"+getYoutubeVideoId(item.url)+"/hqdefault.jpg"} alt={`Image ${index + 1}`} style={{height: '100px',borderRadius: '10px'}} />
                                <div className="youtube-icon" >
                                  <svg viewBox="0 0 33 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><rect x="3.37646" y="3.62793" width="21.9356" height="14.7857" fill="#FFFFFF"></rect><path d="M13.0333 17.9688V5.03125L22.8083 11.5L13.0333 17.9688ZM29.325 0.575C28.3475 0.2875 22.3196 0 16.2917 0C10.2637 0 4.23583 0.273125 3.25833 0.54625C0.716833 1.29375 0 6.325 0 11.5C0 16.6606 0.716833 21.7063 3.25833 22.4394C4.23583 22.7269 10.2637 23 16.2917 23C22.3196 23 28.3475 22.7269 29.325 22.4394C31.8665 21.7063 32.5833 16.6606 32.5833 11.5C32.5833 6.325 31.8665 1.30813 29.325 0.575Z" fill="#202124"></path></svg>
                                </div>
                              </>
                            )}
                            {isCommunityAdmin == 1 && (
                              <div className="remove-icon" onClick={() => { deleteMedia(item) }}>
                                &#x2715;
                              </div>
                            )}
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <></>
                      )}
                      {(isCommunityAdmin && allMedias.length < 6) ? (
                      <div className="col">
                          <div className="card upload_file_ui" style={{height:'100px',width:'100px'}}
                          onClick={() => {
                            setShowModal(true)
                          }} >
                              <div x="50%" y="50%" fill="#dee2e6" dy=".3em"><svg viewBox="0 0 40 40" width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M40 22.8571H22.8571V40H17.1429V22.8571H0V17.1429H17.1429V0H22.8571V17.1429H40V22.8571Z"></path></svg> 
                              {/* <span style={{position: 'relative', top: '2px', marginLeft: '10px',fontWeight: 'bold'}}>New Course</span> */}
                              </div>
                          </div>
                      </div>
                      ) : (
                        <></>
                      )}
                      </Row>
                    </>
                  )}
                </div>
                <div className="card-block-info">
                  <div className="row mt-25 mb-35 justify-content-between">
                    <span className="icon-card text-capitalize col-lg-3 com-md-3 col-sm-6 pr-0 mb-3"><i className="fi-rr-shield-check"></i>{ objCommunity.community_type } Group</span>
                    { (objCommunity.paid == true) && 
                      <span className="icon-card col-lg-3 com-md-3 col-sm-6 pr-0 mb-3"> ${ (community_subscription !=null && community_subscription?.amount ) ? community_subscription?.amount : 0} /month</span>
                    }
                     { (objCommunity.paid == false) && 
                      <span className="icon-card col-lg-3 com-md-3 col-sm-6 pr-0  mb-3"> <i className="fi-rr-rocket"></i> Free</span>
                    }
                    <span className="icon-card col-lg-3 com-md-3 col-sm-6  pr-0 mb-3"><i className="fi-rr-user"></i>{total_member} Members</span>
                    <div className="author d-flex align-items-center col-lg-3 com-md-3 col-sm-6 mb-3 pl-10">
                      <img alt="jobhub" src={(person?.avatar_url != null && person?.avatar_url != '') ? person?.avatar_url : defaultUSerImage}  />
                      <span className='ml-10'>By {person?.first_name} {person?.last_name}</span>
                    </div>
                  </div>
                  {objCommunity && (
                        <div ref={iframeRef} dangerouslySetInnerHTML={{ __html: objCommunity.description }} />
                        // <iframe scrolling="no" ref={iframeRef} width='100%'
                        //     srcdoc={objCommunity.description}>
                        // </iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
           <AboutLeftPenal communityId={objCommunity.id} ></AboutLeftPenal>
        </div>
      </div>
    </div>
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Image or Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Upload an image (1400 x 790 recommended):</Form.Label>
            {imageLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <>
                <Form.File
                  id="custom-file"
                  label="Choose file"
                  custom
                  onChange={handleFileChange}
                  accept="image/png, image/gif, image/jpeg"
                  isInvalid={!!errors.imageFile}
                  feedback={errors.imageFile}
                />
              </>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Or, add a YouTube video link:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Video URL"
              value={videoUrl}
              onChange={handleVideoUrlChange}
              isInvalid={!!errors.videoUrl}
            />
            <Form.Control.Feedback type="invalid">
              {errors.videoUrl}
            </Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              CANCEL
            </Button>
            <Button type="submit" disabled={loading} variant="primary" onClick={handleSubmit}>
              {loading ? (
               <Spinner animation="border" variant="light" />
              ) : (
                <>{'ADD'}</>
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>   

    {showRemoveConfirmation &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to delete this media?"
          onConfirm={() => (handleDelete())}
          onCancel={() => setShowRemoveConfirmation(false)}
          focusCancelBtn
        >
          You will not be able to recover this media!
        </SweetAlert>
    }             
  </>
  );
}
export default AboutPage