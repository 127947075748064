import React from 'react'

function ManageMyMetworkSideBar({my_total_candidates,received,sent,reject }) {
    return (
        <>
          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="sidebar-shadow widget-categories  mt-20">
                  <h5 className="sidebar-title">Manage my network</h5>
                  <ul>
                      <li className="d-flex justify-content-between align-items-center">
                          <a href="/my_networks/my">My Connections</a>
                          <span className="count">{my_total_candidates || 0}</span>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                          <a href="/my_networks/received">Received Invitations</a>
                          <span className="count">{received || 0}</span>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                          <a href="/my_networks/sent">Sent Invitations</a>
                          <span className="count">{sent || 0}</span>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                          <a href="/my_networks/reject">Reject Invitations</a>
                          <span className="count">{reject || 0}</span>
                      </li>
                  </ul>
              </div>
          </div> 
        </>
    )
}
export default ManageMyMetworkSideBar
