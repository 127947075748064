import React, { useState } from 'react'

const FinanceDashboard = (props) => {

    return (
        <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
                <h1>Coming Soon ...!</h1>  
            </div>
        </div>
    );

}

export default FinanceDashboard;