import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Badge from "react-bootstrap/Badge";
import Card from './shared/Card'
import TableRecruiter from './shared/Table'
import Paginator from '../../common/Paginator/Paginator'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from './shared/Button'
import P from './shared/P'
import TableWrapper from './shared/TableWrapper'
import SearchBar from '../../common/SearchBar/SearchBar'
import { capitalize } from '../../../utils'
import { Row, Col, Spinner  } from 'react-bootstrap'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import './styles/RecruiterManagment.scss'
import SweetAlert from 'react-bootstrap-sweetalert'
import feather from 'feather-icons'
import DataTable from "react-data-table-component-footer"
import linkedinIcon  from '../../../../assets/images/icon-linkedin.png'
import   './styles/DataTableCustom.scss'

const RecruiterManagement = ({per_page}) => {
    var active_page = window.sessionStorage.getItem('recruiter_activePage')
    var query = window.sessionStorage.getItem('recruiter_query')
    const cardinal_members_only = sessionStorage.getItem('cardinal_members_only')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [pageCount, setPageCount] = useState(0)
    const [activePageOnDemand, setActivePageOnDemand] = useState(0)
    const [pageCountOnDemand, setPageCountOnDemand] = useState(0)
    const [onDemandRecruiters, setOnDemandRecruiters] = useState([])
    const [showOnDemandRecruiters, setShowOnDemandRecruiters] = useState(false)
    const [recruiters, setRecruiters] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [startRecord, setStartRecord] = useState(0)
    const [startRecordOnDemand, setStartRecordOnDemand] = useState(0)
    const [totalRecruiter, setTotalRecruiter] = useState(0)
    const [totalRecruiterOnDemand, setTotalRecruiterOnDemand] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [currentCountsOnDemand, setCurrentCountsOnDemand] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showSweetAlertMessage,setShowSweetAlertMessage]=useState()
    const [sortField, setSortField] = useState('users.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(per_page || 25)
    const [selectUser,setSelectUser] = useState(false)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [cardinalMembersOnly, setCardinalMembersOnly] = useState(cardinal_members_only == 'true' ? true : false)

    useEffect(() => {
        feather.replace()
    })

    const fetchData = () => {
        setLoading(true)
        window.sessionStorage.setItem('recruiter_activePage',activePage)
        const url = `/admin/recruiters.json?page=${activePage}&search=${searchTerm}&cardinal_member=${cardinalMembersOnly}&sortField=${sortField}&sortDirection=${sortDirection}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (response) => {
                setTotalRecruiter(response.data.total_count)
                let sr_no = (activePage - 1) * response.data.per_page
                setRecruiters(
                    response.data.users.map((user) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        id: user.id,
                        name: user.first_name + ' ' + user.last_name,
                        email: user.email, 
                        role: user.role,
                        is_commission_recruiter: (user.is_commission_recruiter != null) ? capitalize(user.is_commission_recruiter) : '',
                        created_at: moment(user.created_at).format('MMM DD, YYYY'),
                        email_confirmed: user.email_confirmed ? 'Approved' : 'Pending',
                        send_email_request: user.send_email_request ? 'Approved' : 'Pending',
                        on_demand_recruiter: capitalize(user.on_demand_recruiter),
                        ein_number: user.ein_number,
                        linkedin_profile_url: user?.linkedin_profile_url ? <a target='_blank' href={user?.linkedin_profile_url}>
                        <img style={{width:'20px', height:'20px'}}  src={linkedinIcon} /></a>
                         : '',
                        organizations:   user.recruiter_organizations &&
                                (sortRecruiterOrganization(user.recruiter_organizations)).map((skill, index) => canidateSkills(skill, index)),
                        actions: (<div className='w-100 d-inline-flex justify-content-around'>
                                <a  href={`/admin/recruiters/${user.id}`} className='pr-4'><i data-feather="edit-2" /></a>
                                <a  onClick={() =>showConfirmDelete(user)}  href={`javascript::void()`} className='pr-4'><i data-feather="trash-2" /></a>
                            </div>)
                        }))
                )
               
                setPageCount(response.data.total_pages)
                setCurrentCounts(response.data.current_counts)
                setStartRecord((activePage - 1) * response.data.per_page)
                setLoading(false)
            },
        })
    }
    const fetchOnDemandData = () => {
        setLoading(true)
        const url = `/admin/recruiters/pending?page=${activePageOnDemand+1}&search=${searchTerm}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (response) => {
                setOnDemandRecruiters(
                    response.data.users.map((user) => ({
                        id: user.id,
                        name: user.first_name + ' ' + user.last_name,
                        email: user.email,
                        role: user.role,
                        created_at: user.created_at,
                        email_confirmed: user.email_confirmed ? 'Approved' : 'Pending',
                        send_email_request: user.send_email_request ? 'Approved' : 'Pending',
                        on_demand_recruiter: user.on_demand_recruiter,
                        organizations:   user.recruiter_organizations &&
                                sortRecruiterOrganization(user.recruiter_organizations)
                    }))
                )
                setTotalRecruiterOnDemand(response.data.total_count)
                setPageCountOnDemand(response.data.total_pages)
                setCurrentCountsOnDemand(response.data.current_counts)
                setStartRecordOnDemand(((activePageOnDemand + 1) * response.data.per_page) - response.data.per_page)
                setLoading(false)
            },
        })
    }

    const fetchAppropriateData = () => {
        if (showOnDemandRecruiters) {
            fetchOnDemandData()
        } else {
            fetchData()
        }
    }

    const sortRecruiterOrganization = (data) => {
        let sort_data = data.slice(-5).sort((a, b) => a.id > b.id ? -1 : 1)
        return sort_data.map((org, key) => { 
            return (org.organization) ? org.organization.name : ''
        })
    }

    useEffect(() => {
        fetchAppropriateData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,activePageOnDemand, showOnDemandRecruiters,sortDirection,sortField, cardinalMembersOnly])

    const deleteRecruiter = async () => {
        setShowDeleteConfirmation(false)
        let id = selectUser.id
        const url = `/admin/recruiters/${id}`
        const response = await makeRequest(url, 'delete', {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createSuccessMessage: (response) => response.data.message,
            createErrorMessage: (e) => e.response.data.msg,
        })
        if(response?.data?.errorMsg){
            setShowSweetAlertMessage(response?.data?.errorMsg)
            setShowRemoveConfirmation(true)
        }else if(response?.data?.success){
            fetchData()
        }
    }

    const approveRecruiter = async ({ data }) => {
        const url = `/admin/recruiters/${data.id}/approve`
        await makeRequest(url, 'put', '', {
            createSuccessMessage: () => 'Recruiter approved successfully',
            onSuccess: () => {
                // fetchData()
                switchToRecruites()
            },
        })
    }

    const rejectRecruiter = async (id) => {
        const url = `/admin/recruiters/${id}/reject`
        await makeRequest(url, 'put', '', {
            createSuccessMessage: () => 'Recruiter rejected successfully',
            onSuccess: () => {
                // fetchData()
                switchToRecruites()
            },
        })
    }

    const saveRecruiter = (newRec) => {
        // to do call backend to save updated data
    }

    const switchToOnDemandRecruites = () => {
        setShowOnDemandRecruiters(true)
        setActivePage(1)
    }

    const switchToRecruites = () => {
        setShowOnDemandRecruiters(false)
        setActivePage(1)
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('recruiter_query', value)
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '6%',
            minWidth: '6%',
        },
        {
            name: 'Name',
            selector: row => row.name,
            cell: (row) => <div title={row.name}>{row.name}</div>,
            maxWidth: '1%',
            minWidth: '12%',
            wrap:true,
            sortable: true,
            sortField: 'users.first_name',
        },
        {
            name: 'Email',
            selector: row => row.email,
            cell: (row) => <div title={row.email}>{row.email}</div>,
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true,
            sortable: true,
            sortField: 'users.email',
        },
        {
            name: 'Organizations',
            selector: row => row.organizations,
            maxWidth: '15%',
            minWidth: '15%',
        },
        {
            name: 'Linkedin Url',
            selector: row => row.linkedin_profile_url,
            cell: (row) => <div title={row.linkedin_profile_url}>{row.linkedin_profile_url}</div>,
            maxWidth: '7%',
            minWidth: '7%',
        },
        {
            name: 'Gmail Verified',
            selector: row => row.send_email_request,
            cell: (row) => <div title={row.send_email_request}>{row.send_email_request}</div>,
            maxWidth: '8%',
            minWidth: '8%',
            sortable: true,
            sortField: 'users.send_email_request',
        },
        {
            name: 'Email Verified',
            selector: row => row.email_confirmed,
            cell: (row) => <div title={row.email_confirmed}>{row.email_confirmed}</div>,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'users.email_confirmed',
        },
        // {
        //     name: 'On-Demand',
        //     selector: row => row.on_demand_recruiter,
        //     cell: (row) => <div title={row.on_demand_recruiter}>{row.on_demand_recruiter}</div>,
        //     maxWidth: '7%',
        //     minWidth: '7%',
        // },
        {
            name: 'EIN Number',
            selector: row => row.ein_number,
            cell: (row) => <div title={row.ein_number}>{row.ein_number}</div>,
            maxWidth: '7%',
            minWidth: '7%',
        },
        {
            name: '100% Commission Recruiter',
            selector: row => row.is_commission_recruiter,
            cell: (row) => <div title={row.is_commission_recruiter}>{row.is_commission_recruiter}</div>,
            maxWidth: '6%',
            minWidth: '6%',
        },
        {
            name: 'Created On',
            selector: row => row.created_at,
            cell: (row) => <div title={row.created_at}>{row.created_at}</div>,
            maxWidth: '9%',
            minWidth: '9%',
            sortable: true,
            sortField: 'users.created_at',
        },
        {
            name: 'Actions',
            cell: row => row.actions,
            maxWidth: '8%',
            minWidth: '8%',
        },
    ]

    const showConfirmDelete = (user) => {
        setSelectUser(user)
        setShowDeleteConfirmation(true)
    }

    const handleClose = () => {
        setShowRemoveConfirmation(false)
    }

    return (
        <Card>
             {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    title={showSweetAlertMessage}
                    onConfirm={()=>handleClose(true)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />
            }
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '20px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Recruiter Management
                </P>
                <div style={{display: 'flex', alignItems: 'center'}}>

                <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" checked={cardinalMembersOnly} onClick={(e)=>{
                                setCardinalMembersOnly(e.target.checked)
                                sessionStorage.setItem('cardinal_members_only',e.target.checked)
                            }} id="cardinal_members_only"/>
                            <label className="custom-control-label" for="cardinal_members_only">CT Member</label>
                       </div>
            {!showOnDemandRecruiters && (
                <>
                {/* <Button onClick={switchToOnDemandRecruites} className="ml-3">
                    Recruiter Requests
                </Button> */}
                </>
            )}
                </div>
         
            </div>
            {showOnDemandRecruiters ? (
             <>
                <TableWrapper>
                    <div
                        className="d-flex justify-content-between align-items-center w-100"
                        style={{ marginBottom: '18px    ' }}
                    >
                        <P size="22px" height="30px" color="#1D2447">
                            On-Demand Recruiter Requests
                        </P>
                        <Button
                            onClick={switchToRecruites}
                            padding="1px 10px"
                            height="30px"
                            width="30px"
                            radius="15px"
                            background="#8599FF"
                        >
                            x
                        </Button>
                    </div>
                    <div className='text-left w-100'>
                        {totalRecruiterOnDemand > 0 &&
                            <DisplayPagination>Displaying  {(activePageOnDemand == 0)?1:startRecordOnDemand + 1} - {currentCountsOnDemand > totalRecruiterOnDemand ? totalRecruiterOnDemand : currentCountsOnDemand } of {totalRecruiterOnDemand} recruiters</DisplayPagination>
                        }
                    </div>
                    <TableRecruiter
                        columNames={[
                            {
                                name: 'No.',
                                field: 'id',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Name',
                                field: 'name',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Email',
                                field: 'email',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Gmail Verified',
                                field: 'send_email_request',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Email Verified',
                                field: 'email_confirmed',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Requested On',
                                field: 'created_at',
                                editable: false,
                                type: 'text',

                                nowrap:'text-nowrap'
                            },
                            {
                                name: 'Options',
                                field: 'options',
                                editable: false,
                                type: 'text',

                            },
                        ]}
                        rowValues={onDemandRecruiters.map((o) => ({
                            ...o, 
                            created_at: moment(o.created_at).format(
                                'MMM DD, YYYY'
                            ),
                        }))}
                        deleteAction={rejectRecruiter}
                        saveAction={approveRecruiter}
                        activePage={activePageOnDemand}
                        goToEditPage={(id) =>
                            (window.location.href = '/admin/recruiters/' + id)
                        }
                        loading={loading}
                    />
                </TableWrapper>
                {pageCountOnDemand > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePageOnDemand  == 1 ? 0 : activePageOnDemand}
                        setActivePage={setActivePageOnDemand}
                        pageCount={pageCountOnDemand}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
                )}
            </>
            ) : (
            <>
                <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
                    <Col xs={12} sm={12} lg={8} style={{float:'left'}}>
                        {totalRecruiter > 0 &&
                            <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalRecruiter ? totalRecruiter : currentCounts } of {totalRecruiter} recruiters</DisplayPagination>
                        }
                    </Col>
                    <Col xs={12} sm={12} lg={4} style={{float:'right'}}>
                        <SearchBar
                        placeholder="Search users..."
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 1
                                ? fetchAppropriateData()
                                : setActivePage(1)
                            !searchTerm && window.sessionStorage.setItem('recruiter_query','')
                        }}
                        onClick={(e) => setSearchTerm('')}
                        />
                    </Col>
                       
                </Row>
                <DataTable
                    title={false}
                    persistTableHead={true}
                    noDataComponent={<p className="text-center p-3">No record found</p>}
                    columns={columns}
                    data={recruiters}
                    progressPending={loading}
                    progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner className='spinner' animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </P>}
                    onSort={handleSort}
                    sortServer
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRecruiter}
                    paginationPerPage={perPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    responsive={true}
                    onChangePage={page => setActivePage(page)}
                /> 
                </>
            )}

           {showDeleteConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to delete?"
                    onConfirm={()=> (deleteRecruiter(),setShowDeleteConfirmation(false))}
                    onCancel={()=>setShowDeleteConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this user!
                </SweetAlert>
            }
        </Card>
    )

    function canidateSkills(skill, index){
        return(
            <>
                <Badge
                  style={{cursor:"pointer"}}
                   title={skill}
                    pill
                    key={index}
                    variant="secondary"
                    className="skills-button d-inline-block text-truncate"                                                                                            
                >
                    <span style={{cursor:"pointer"}} title={skill}>
                    <label style={{cursor:"pointer"}} title={skill} className='candidate-skills' > {skill} </label>
                    </span>
                </Badge>{'   '}
            </>
        )
    }
}

export default RecruiterManagement