import React, { useState, useEffect } from 'react'
import moment from 'moment'

import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import Util from "../../../../utils/util";

const TimeReport = () => {
    var active_page = window.sessionStorage.getItem('activePage')
    var query = window.sessionStorage.getItem('query')
    const localStorageDateRange = JSON.parse(sessionStorage.getItem('timeReportDateRange'))
    const startDate = (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate()
    const endDate = (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate()
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const [timeReport, setTimeReport] = useState([])
    const [totalEntry,setTotalEntry] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const startDate = moment(dateRange.startDate).format('MMM,DD, YYYY')
        const endDate = moment(dateRange.endDate).format('MMM,DD, YYYY')
        fetchData(startDate,endDate)
    }, [activePage, dateRange])

    const handleDate = (date) => {
        sessionStorage.setItem('timeReportDateRange', JSON.stringify(date))
        setDateRange(date)
    }

    const fetchData = (sDate,eDate) => {
        setLoading(true)
        const url = `/cardinal/time_reports.json`
        window.sessionStorage.setItem('activePage',activePage)
        makeRequest(url, 'get',{params: {page: (activePage+1),startDate: sDate ,endDate: eDate, query: query}}, {   
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setTimeReport(
                    res.data.time_reports.map((record) => ({
                        id: record.user_id,
                        user_id: record.user_id,
                        total_hrs: record.total_time,
                        total_calls: record.total_calls ? record.total_calls : 0,
                        total_emails: record.total_emails ? record.total_emails :0,
                        total_submissions: record.total_submissions ? record.total_submissions :0,
                        inmail_sent: record?.inmail_sent ? record?.inmail_sent : 0,
                        confirmed_interview: record?.confirmed_interview ? record?.confirmed_interview : 0,
                        missing: ( 7 - parseInt(record.total_entry)),
                        employee_type: Util.capitalize(record.emp_type),
                        week_cost: '-',
                        clients : '',
                        emp_name: Util.fullName(record.first_name,record.last_name),
                        client_hr : (record.client_hr)?record.client_hr:0,
                        views : <a href={`/cardinal/time_reports/detail/${record.emp_id}`}>  <i size='20' color="#4C68FF" data-feather="clock"/></a>
                    }))
                )
                setPageCount(res.data.total_pages)
                setTotalEntry(res.data.total_count)
                setCurrentCounts(res.data.current_counts)
                setStartRecord(activePage * res.data.per_page)
                setLoading(false)
            },
        })
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Time Reports
                </P>
                <div style={{flexGrow:1}}/>
              
            </div>
            
            <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
                <Col xs={12} sm={12} lg={9} className='p-0'>
                    {totalEntry > 0  &&
                        <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalEntry} employees
                        </DisplayPagination>
                    }
                 </Col>
                 <Col  xs={12} sm={12} lg={3} className='text-right p-0'>
                 <DateRangePicker
                        handleOnSubmit={handleDate}
                        defaultDate={dateRange}
                    />
                 </Col>
            </Row>
            <Table
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Name',
                        field: 'emp_name',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Type',
                        field: 'employee_type',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'This Week Hr',
                        field: 'total_hrs',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'This Week Cost',
                        field: 'week_cost',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Missing Days',
                        field: 'missing',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Client Hr',
                        field: 'client_hr',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Calls',
                        field: 'total_calls',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Emails',
                        field: 'total_emails',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Submission',
                        field: 'total_submissions',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Inmail Sent',
                        field: 'inmail_sent',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Confirmed Interviews',
                        field: 'confirmed_interview',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Action',
                        field: 'views',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap',
                    },
                
                ]}
                rowValues={timeReport.map((entry) => ({
                    ...entry,
                }))}
                showEditOption={true}
                activePage={activePage}
                goToEditPage={(id) =>
                    getUpdateData(id)
                }
                loading={loading}
            />

            {pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
           
        </Card>
    )
}

export default TimeReport   