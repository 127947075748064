import React from 'react'
import { makeRequest } from '../../components/common/RequestAssist/RequestAssist'

const initialState = {
    ...initialState,
    ...{
        name: '',
        ownerFirstName:'',
        ownerLastName: '',
        email: '',
        phone: '',
        status:''
    },
}

const reducer = (state, action) => {
    const { type, value, id } = action
    let url = ''
    let formData = {}
    switch (type) {
        case 'save-agency':
            const token = document.querySelector(
                'meta[name="csrf-token"]'
            ).content
            formData = new FormData()
            formData.append('authenticity_token', token)

        case 'update-agency':
            formData = new FormData()
            formData.append(`agency[name]`, value?.name)
            formData.append(`agency[status]`,value?.status)
            formData.append(`user[first_name]`,value?.ownerFirstName)
            formData.append(`user[last_name]`,value?.ownerLastName)
            formData.append(`user[email]`,value?.email)
            formData.append(`user[phone_number]`,value?.phone)
            formData.append(`user[role]`, 'agency')
            url = `/agencies/admin/agencies/${id}.json`
            sendRequest(formData, url, 'put')
            return { ...state, ...value }

        case 'add-agency':
            formData = new FormData()
            formData.append(`agency[name]`, value?.name)
            formData.append(`agency[status]`,value?.status)
            formData.append(`user[first_name]`,value?.ownerFirstName)
            formData.append(`user[last_name]`,value?.ownerLastName)
            formData.append(`user[email]`,value?.email)
            formData.append(`user[phone_number]`,value?.phone)
            formData.append(`user[role]`, 'agency')
            url = `/agencies/admin/agencies.json`
            sendRequest(formData, url, 'post')
            return { ...state, ...value }
        
            case 'change-password':
                formData = new FormData()
                formData.append(`user[password]`, value?.password)
                formData.append(`user[password_confirmation]`, value?.confirm_password)
                url = `/change/password.json?`
                sendRequest(url, 'get')
                return { ...state, ...value }

        case 'remove-agency':
            url = `url/${id}`
            sendRequest({}, url, 'delete')
            return { ...state }
        default:
            return state
    }
}

async function sendRequest(formData, url, method) {
    const messsage =
        method == 'put' ? 'Update' : method == 'post' ? 'Save' : 'Remove'
    await makeRequest(url, method, formData, {
        contentType: 'multipart/form-data',
        loadingMessage: 'Submitting...',
        createResponseMessage: (e) => {
                if(e?.messageType == 'success'){
                    setTimeout(() => {
                        window.location.href ='/agencies/admin/agencies'
                    }, 2000);
                }
            return {
                message: `${e.msg || e.error_message || e.message}`,
                messageType: `${e.messageType}`,
                loading: false,
                autoClose: true,
            }
        },
        createSuccessMessage: () => `${messsage} successful`,
        createErrorMessage: (e) => {
            return e.response.data.msg
        },
    })
}

// context for agency page
const AgencyContext = React.createContext(null)

export { initialState, reducer, AgencyContext }
