import React, { useEffect, useState } from 'react'
import Card from '../Admin/shared/Card'
import P from '../Admin/shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from '../Admin/styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   '../Admin/styles/DataTableCustom.scss'

const OrganizationOnboard = (prop) => {
    const queryParams = new URLSearchParams(window.location.search)
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [perPage, setPerPage] = useState(prop.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [organizations,setOrganizations] = useState([{value: 'all', label: 'All'},...prop.organizations])
    const [selectedOrganization, setSelectedOrganization]  = useState('all')
    const [organizationOnboards,setOrganizationOnboards] = useState([])
    const csvExportRef = React.useRef()
    const [startRecord, setStartRecord] = useState(0)
    const [exportDatas, setExportDatas] = useState([])
    const [loading, setLoading] = useState(true)
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})

    const headers = [
        { label: 'Organization Name', key: 'org_name' },
        { label: 'Signed Date', key: 'sign_date' },
        { label: 'Account Manager', key: 'ac_manager' },
        { label: 'Jobs Posted on CT', key: 'job_count' },
        { label: 'Jobs with SR', key: 'job_sr_count' },
        { label: 'Last Lead', key: 'lead_received' },
        { label: 'Jobs on Linkedin', key: 'job_linked_count' },
        { label: 'Recruiters', key: 'recruiters' },
    ];
    
    const fetchData = async () => {
        setLoading(true)
        const url = '/analytic/organization_onboard.json'
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&organization=${selectedOrganization}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setOrganizationOnboards(response?.data?.organization_onboards.map((data,key)=> (sr_no = sr_no + 1,{
            id: sr_no,
            org_name: data?.name && capitalize(data?.name),
            created_at: data?.created_at && moment(data.created_at).format('MMM DD, YYYY'),
            ac_manager: `${data?.first_name ? `${capitalize(data?.first_name)} ${capitalize(data?.last_name)}` : '-' }`,
            job_count: data.job_count,
            job_sr_count: data.job_sr_count,
            lead_received: data?.last_lead_received &&  moment(data.last_lead_received).format('MMM DD, YYYY'),
            job_linked_count: data.job_linked_count,
            recruiters: `${data.recruiter_info?.recruiter_name ?  `${ data.recruiter_info.total_recruiter}  (${data.recruiter_info.recruiter_name })` : '0'}`
        })))
        setFooter({
            id:'Total',
            job_sr_count:response.data.total_values.job_sr_count,
            job_linked_count:response.data.total_values.job_linked_count,
            job_count:response.data.total_values.job_count,
        })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoading(false)
    }
    
    const  columns= [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '4%',
            minWidth: '4%',
        },
        {
            name: 'Organization Name',
            selector: row => row.org_name,
            sortable: true,
            sortField: 'name',
            maxWidth: '16%',
            minWidth: '16%',
            wrap:true
        },
        {
            name: 'Sign Date',
            selector: row => row.created_at,
            sortable: true,
            sortField: 'created_at',
            maxWidth: '8%',
            minWidth: '8%',
        },
        {
            name: 'Account Manager',
            selector: row => row.ac_manager,
            sortable: true,
            sortField: 'first_name',
            maxWidth: '10%',
            minWidth: '10%',
            wrap:true
        },
        {
            name: 'Job Posted on CT',
            selector: row => row.job_count,
            sortable: true,
            sortField: 'job_count',
            maxWidth: '8%',
            minWidth: '8%',
        },
        {
            name: 'Jobs with SR',
            selector: row => row.job_sr_count,
            sortable: true,
            sortField: 'job_sr_count',
            maxWidth: '8%',
            minWidth: '8%',
        },
        {
            name: 'Last leads received',
            selector: row => row.lead_received,
            maxWidth: '8%',
            minWidth: '8%',
        },
        {
            name: 'Jobs on Linkedin',
            selector: row => row.job_linked_count,
            sortable: true,
            sortField: 'job_linked_count',
            maxWidth: '8%',
            minWidth: '8%',
        },
        {
            name: 'Recruiters',
            selector: row => row.recruiters,
            maxWidth: '30%',
            minWidth: '30%',
            wrap:true
        },
    ]

    const exportDataMethod = async () => {
        const url = '/analytic/organization_onboard.json'
        const response = await makeRequest(
            `${url}?organization=${selectedOrganization}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setExportDatas(response?.data?.jobs_status.map((data,key)=> ({
            id: key,
            org_name: data?.name && capitalize(data?.name),
            sign_date:  data?.created_at &&  moment(data.created_at).format('MMM DD, YYYY'),
            ac_manager: `${data?.first_name ? `${capitalize(data?.first_name)} ${capitalize(data?.last_name)}` : '-' }`,
            job_count: data.job_count,
            job_sr_count: data.job_sr_count,
            lead_received: data?.last_lead_received &&  moment(data.last_lead_received).format('MMM DD, YYYY'),
            job_linked_count: data.job_linked_count,
            recruiters: `${data.recruiter_info?.recruiter_name ?  `${ data.recruiter_info.total_recruiter}  (${data.recruiter_info.recruiter_name })` : '0'}`
        })))
        csvExportRef?.current?.link.click();
    }

    useEffect(()=>{
        fetchData()
    },[selectedOrganization,activePage,sortDirection,sortField])

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Organization Onboard
                </P>
                <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                    Export CSV
                </button>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"client_onboarding.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                    <div style={{display: 'flex'}}>
                        <div>
                            <label>
                                Select Organization
                            </label>
                            <Select
                                
                                options={organizations}
                                onChange={(event)=>setSelectedOrganization(event.value)}
                                name="organization"
                                className={styles.colourStyles}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={organizationOnboards}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
    )
}

export default OrganizationOnboard