import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/TimeReportEmployee'
import { getDueDays } from '../../../../utils'
import TimePicker from 'rc-time-picker'
import axios from 'axios'
import Util from "../../../../utils/util";
import 'rc-time-picker/assets/index.css';

const AddTimeEntry = ({employee,timeEntry,onHide,method,isAdminForm=true,entryDate, applicantData}) => {
    const [validationError, setValidationError] = useState({})
    const [itemValidationError, setItemValidationError] = useState({})
    const [entryData, setEntryData] = useState({
        entry_date: timeEntry.entry_date?timeEntry.entry_date: (entryDate) ? entryDate : moment().format('YYYY-MM-DD'),
        start_1: timeEntry.id?timeEntry.start_1:'00:00',
        end_1: timeEntry.id?timeEntry.end_1:'00:00',
        start_2: timeEntry.id?timeEntry.start_2:'00:00',
        end_2: timeEntry.id?timeEntry.end_2:'00:00',
        break_1_start: timeEntry.id?timeEntry.break_1_start:'00:00',
        break_1_end: timeEntry.id?timeEntry.break_1_end:'00:00',
        break_2_start: timeEntry.id?timeEntry.break_2_start:'00:00',
        break_2_end: timeEntry.break_2_end?timeEntry.break_2_end:'00:00',
        calls: timeEntry.calls?timeEntry.calls:0,
        emails: timeEntry.emails?timeEntry.emails:0,
        submissions: applicantData.submissions?applicantData.submissions:0,
        inmail_sent: applicantData.inMails?applicantData.inMails:0,
        confirmed_Interview: applicantData.confirmed_interview?applicantData.confirmed_interview:0,
    })
   
    const [itemActivity, setItemActivity] = useState({label:'',value:''})
    const [itemActivityValue, setItemActivityValue] = useState('')
    const [itemHrs, setItemHrs] = useState('')
    const [itemList, setItemList] = useState([])
    const defaultActivityList = [{label:'Sick Leave',value:'sick leave'},{label:'Paid Leave',value:'paid leave'}]
    const [activityList,setActivityList] = useState([])
    const [btnDisabled, setBtnDisabled] = useState(false)

    const handleChange = (e) => {
        setEntryData({
            ...entryData,
            [e.target.name]: e.target.value,
        })
        setValidationError({})
    }
    const format = 'h:mm a';
    const handleTimeChange = (val,name) => {
        let addTime = '00:00'
        if(val === null){
            addTime = '00:00'
        }else{
            let time_explod =  moment(val).format('LT').split(':')
            if(time_explod[0] < 12 && time_explod[1].split(' ')[0] < 60){
                if(time_explod[1].split(' ')[1] == 'PM'){
                    addTime = (parseInt(time_explod[0])+12) + ':' + time_explod[1].split(' ')[0]
                }
                else{
                    addTime = time_explod[0] + ':' + time_explod[1].split(' ')[0]
                }
            }
        }
        setEntryData({
            ...entryData,
            [name]: addTime,
        })
        setValidationError({})
    }   
 
    useEffect(() => {
        if(method === 'put')
            getEntryItems()
            getUserOrganization()
    }, [])
        
    const handleSubmit = (e) => {
        e.preventDefault()
        setBtnDisabled(true)
        if(validateForm()){
            let errors_list = validationError
            // Calculating hours of working
            let shift_1_minutes = calculateTotalMinutes(entryData.start_1,entryData.end_1,entryData.break_1_start,entryData.break_1_end) 
            let shift_2_minutes = calculateTotalMinutes(entryData.start_2,entryData.end_2,entryData.break_2_start,entryData.break_2_end) 
            let tot_minutes =  shift_1_minutes + shift_2_minutes
            let entry_hours = Math.floor(tot_minutes / 60)
            let entry_minutes = Math.floor(tot_minutes % 60)
            let total_time = (tot_minutes / 60).toFixed(2)
               
            let URL = '/cardinal/time_report_employees'
            if(method === 'put'){
                URL = `/cardinal/time_report_employees/${timeEntry.id}`
            }
          
            const payload = new FormData()
            payload.append('time_report[user_id]', employee.user_id)
            payload.append('time_report[entry_date]', e.target.entry_date.value)
            payload.append('time_report[start_1]',entryData.start_1)
            payload.append('time_report[end_1]',entryData.end_1)
            payload.append('time_report[start_2]', entryData.start_2)
            payload.append('time_report[end_2]',entryData.end_2)
            payload.append('time_report[break_1_start]',entryData.break_1_start)
            payload.append('time_report[break_1_end]',entryData.break_1_end)
            payload.append('time_report[break_2_start]', entryData.break_2_start)
            payload.append('time_report[break_2_end]',entryData.break_2_end)
            payload.append('time_report[calls]', e.target.calls.value)
            payload.append('time_report[emails]', e.target.emails.value)
            payload.append('time_report[submissions]', applicantData.submission)
            payload.append('time_report[inmail_sent]',  e.target.inmail_sent.value)
            payload.append('time_report[confirmed_interview]', applicantData.confirmInterview)
            payload.append('time_report[total_hr]',entry_hours)
            payload.append('time_report[total_min]',entry_minutes)
            payload.append('time_report[total_time]', total_time)
            payload.append('time_report[discrepancy]', 0)
            payload.append('time_report[total_hrs_leave]',0)
            payload.append('time_report[total_hrs_paid_leave]', 0)

            //headers
            const CSRF_Token = document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content')
                
            const headers = {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-Token': CSRF_Token,
                },
            }
            makeRequest(URL,method, payload, headers, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
                createResponseMessage: () => {
                    let messsage = 'Save' 
                    return {
                        message: `${messsage} Successfully`,
                        messageType: 'success',
                        loading: false,
                        autoClose: true,
                    }
                },
                createErrorMessage: (e) => 'Failed',
                onSuccess: () => {},
            }).then((res) => {
                if(res.data.type === 'success'){
                   onHide()
                }
            }) 
        }
        setBtnDisabled(false)
    }

    const validateForm = () => {
        let errors = {}
        let formIsValid = true

        if(!entryData.entry_date){
            formIsValid = false;
            errors["entry_date"] = "Entry date is required field."
        }
        if(entryData.entry_date){
            let dayDiff = getDueDays(entryData.entry_date)
            if(dayDiff > 0){
                errors["entry_date"] = 'Entry date must be less than current date.'
                formIsValid = false;
            }
        }
        if((entryData.calls < 0)){
            errors["calls"] = 'Min value 0.'
            formIsValid = false;
        }
        if((entryData.emails < 0)){
            errors["emails"] = 'Min value 0.'
            formIsValid = false;
        }
        if((entryData.submissions < 0)){
            errors["submissions"] = 'Min value 0.'
            formIsValid = false;
        }
        if((entryData.inmail_sent < 0)){
            errors["inmail_sent"] = 'Min value 0.'
            formIsValid = false;
        }
        if((entryData.confirmed_interview < 0)){
            errors["confirmed_interview"] = 'Min value 0.'
            formIsValid = false;
        }
        
        // checking for shift time added or not
        if(!checkShiftTime(entryData.start_1,entryData.end_1,entryData.start_2,entryData.end_2)){
            errors["start_1"] = 'Shift time is required field.'
            formIsValid = false;
        }else{
            // check shift/break time valid enter
            if(!checkValidTime(entryData.start_1,entryData.end_1)){
                errors["start_1"] = 'Invalid shift time.'
                formIsValid = false;
            }
            if(!checkValidTime(entryData.start_2,entryData.end_2)){
                errors["start_2"] = 'Invalid shift time.'
                formIsValid = false;
            }
            if(!checkValidTime(entryData.break_1_start,entryData.break_1_end)){
                errors["break_1"] = 'Invalid break time.'
                formIsValid = false;
            }
            if(!checkValidTime(entryData.break_2_start,entryData.break_2_end)){
                errors["break_2"] = 'Invalid break time.'
                formIsValid = false;
            }

            // check break time must be between respected shift time
            if(!checkBreakTime(entryData.start_1,entryData.end_1,entryData.break_1_start,entryData.break_1_end)){
                errors["break_1"] = 'Invalid break time, It must be between shift time.'
                formIsValid = false;
            }
            if(!checkBreakTime(entryData.start_2,entryData.end_2,entryData.break_2_start,entryData.break_2_end)){
                errors["break_2"] = 'Invalid break time, It must be between shift time.'
                formIsValid = false;
            }

            if(!checkStartAfterEndTimeValidation(entryData.start_1,entryData.end_1,entryData.start_2,entryData.end_2)){
                errors["start_2"] = 'Invalid shift time.'
                formIsValid = false;
             }

            if(formIsValid){
                let shift_1_minutes = calculateTotalMinutes(entryData.start_1,entryData.end_1,entryData.break_1_start,entryData.break_1_end) 
                
                if(shift_1_minutes < 30 && shift_1_minutes != 0) {
                    errors["start_1"] = 'Minimum 30 minutes per shift.'
                    formIsValid = false;
                }

                let shift_2_minutes = calculateTotalMinutes(entryData.start_2,entryData.end_2,entryData.break_2_start,entryData.break_2_end) 
                
                if(shift_2_minutes < 30  && shift_2_minutes != 0) {
                    errors["start_2"] = 'Minimum 30 minutes per shift.'
                    formIsValid = false;
                }
                
            }

        }
        setValidationError(errors)
        setBtnDisabled(formIsValid)
        return formIsValid;
    }

    const checkValidTime = (start_time,end_time) => {

        if(start_time === '00:00' && end_time === '00:00'){
            return true
        }
        let sTime_explod =  start_time.split(':')
        let eTime_explod =  end_time.split(':')

        if(parseInt(sTime_explod[0]) > parseInt(eTime_explod[0])){
            return false
        }else{
            if((parseInt(sTime_explod[0]) === parseInt(eTime_explod[0])) && (parseInt(sTime_explod[1]) >= parseInt(eTime_explod[1]))){
                return false
            }
        }
        return true
    }
   // For check break time is valid 
    const checkBreakTime = (start_time,end_time,break_start,break_end) => {
        
        if(break_start === '00:00' && break_end === '00:00'){ 
            return true
        }
        let sTime_explod =  start_time.split(':')
        let eTime_explod =  end_time.split(':')
        let sTime_break_explod = break_start.split(':')
        let eTime_break_explod = break_end.split(':')
        
        if((parseInt(sTime_explod[0]) <= parseInt(sTime_break_explod[0]))){
            // Hr Same then check miniutes check
            if((parseInt(sTime_explod[0]) === parseInt(sTime_break_explod[0])) && (parseInt(sTime_explod[1]) > parseInt(sTime_break_explod[1]))){
                return false
            }

            if((parseInt(eTime_explod[0]) === parseInt(eTime_break_explod[0])) && (parseInt(eTime_explod[1]) <= parseInt(eTime_break_explod[1]))){
                return false
            }

            if((parseInt(sTime_explod[0]) > parseInt(sTime_break_explod[0])) && (parseInt(eTime_explod[0]) <= parseInt(eTime_break_explod[0])) ){
                return false
            }

            if((parseInt(eTime_explod[0]) < parseInt(eTime_break_explod[0])) || start_time === break_start){
                return false
            }

            return true
        }
        return false
    }

    const checkShiftTime = (start_1,end_1,start_2,end_2) => {
        if( (start_1 === '00:00' && end_1 === '00:00' && start_2 === '00:00' && end_2 === '00:00')){
            return false
        }
        return true
    }

    const checkStartAfterEndTimeValidation = (start_1,end_1,start_2,end_2) => {
        if((start_1 != end_1) && (start_2 != end_2)){
            let eTime_1_explod =  end_1.split(':')
            let sTime_2_explod = start_2.split(':')
            if(parseInt(eTime_1_explod[0]) <= parseInt(sTime_2_explod[0])){
                if((parseInt(eTime_1_explod[0]) === parseInt(sTime_2_explod[0])) && (parseInt(eTime_1_explod[1]) >= parseInt(sTime_2_explod[1]))){
                    return false
                }
            }else{
                return false
            }
        }
        return true
    }

    const calculateTotalMinutes = (shift_start,shift_end,break_start,break_end) => {
        let shift_start_explod =  shift_start.split(':')
        let shift_end_explod =  shift_end.split(':')
        let break_start_explod = break_start.split(':')
        let break_end_explod = break_end.split(':')
        let shift_start_hr = shift_start_explod[0]
        let shift_start_minutes = shift_start_explod[1]
        let shift_end_hr = shift_end_explod[0]
        let shift_end_minutes = shift_end_explod[1]
        let shif_hr = parseInt(shift_end_hr)  - parseInt(shift_start_hr)
        let shif_minutes = parseInt(shift_end_minutes)  - parseInt(shift_start_minutes)
        let break_start_hr = break_start_explod[0]
        let break_start_minutes = break_start_explod[1]
        let break_end_hr = break_end_explod[0]
        let break_end_minutes = break_end_explod[1]
        let break_hr = parseInt(break_end_hr)  - parseInt(break_start_hr)
        let break_minutes = parseInt(break_end_minutes)  - parseInt(break_start_minutes)
        let shift_total_hr_minutes = (shif_hr - break_hr) * 60
        let shif_total_minutes = (shif_minutes -  break_minutes) + shift_total_hr_minutes
        // var h = Math.floor(shif_total_minutes / 60)
        // var m = Math.floor(shif_total_minutes % 60)
        return  shif_total_minutes
    }

    const onChangeActivity = (e) => {
        setItemActivityValue(e.value)
        setItemActivity({label:e.label,value:e.value})
    }

    const onChangeHrs = (e) => {
        setItemHrs(e.target.value)
    }

    const handleSubmitItem = (e) => {
        e.preventDefault()
        setBtnDisabled(true)
         // check validation 
        let errors = {}
        let formIsValid = true
        if(!timeEntry.id){
            errors["msg"] = 'Please add shift hour timings before saving time entry.'
            formIsValid = false;
        }else{
            if((e.target.code.value === '')){
                errors["code"] = 'Activity is required field.'
                formIsValid = false;
            }
            if(e.target.hrs.value === '' || e.target.hrs.value > 8 || e.target.hrs.value <= 0){
                errors["hrs"] = 'Hours value must be minimum 1 or maximum 8.'
                formIsValid = false;
            }
        }
        setBtnDisabled(formIsValid)   
        setItemValidationError(errors)

        if(formIsValid){

            let URL = '/cardinal/time_report_items'
            const payload = new FormData()
            payload.append('time_report_item[user_id]', employee.user_id)
            payload.append('time_report_item[entry_date]', entryData.entry_date)
            payload.append('time_report_item[code]',e.target.code.value)
            payload.append('time_report_item[hrs]',e.target.hrs.value)
            payload.append('time_report_item[paid]',true)
            
            //headers
            const CSRF_Token = document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content')
                
            const headers = {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-Token': CSRF_Token,
                },
            }
            makeRequest(URL,'post', payload, headers, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
                createResponseMessage: () => {
                    let messsage = 'Save' 
                    return {
                        message: `${messsage} Successfully`,
                        messageType: 'success',
                        loading: false,
                        autoClose: true,
                    }
                },
                createErrorMessage: (e) => 'Failed',
                onSuccess: () => {},
            }).then((res) => {
                if(res.data.type === 'success'){
                    setItemActivity({})
                    setItemHrs('')
                    setItemActivityValue('')
                    getEntryItems()
                }
            }) 

        }
        setBtnDisabled(false)
    }

    const getEntryItems = () => {
        console.log(' call getEntryItems')
        const URL = `/cardinal/time_report_items.json?user_id=${employee.user_id}&entry_date=${timeEntry.entry_date}`
        axios.get(URL).then((res) => {
            setItemList(res.data.time_report_items)
        })
    }

    const getUserOrganization = () => {
        const URL = `/cardinal/get_user_organizations/${employee.user_id}`
        axios.get(URL).then((res) => {
            let dataOption = defaultActivityList            
            res.data.organizations.map((org)=>{
                dataOption.push({label:org.name,value:org.name})
            })
            setActivityList(dataOption)
        })
    }

    const deleteTimeEntryItem = (id) => {
        const URL = `/cardinal/time_report_items/${id}`
         makeRequest(URL, 'delete', '', {
            createResponseMessage: () => {
                return {
                    message: `Deleted Successfully`,
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: () => {      
                getEntryItems()
            },      
        })
    }

    return (
        <>
          <Container> 
           <form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
               <Row className="w-100" style={{ marginBottom: '20px',display: 'flex',alignItems: 'baseline', }}>
                    <Col xs={12} sm={12} lg={6}>
                        <span>Entry Date* : </span>
                        <input type='date' tabIndex="101" className='form-control w-100' name='entry_date' disabled ={ (method === 'put' || isAdminForm == false) ? true : false} id="entry_date" defaultValue={entryData?.entry_date} onChange={handleChange} />
                        <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                            {validationError && validationError.entry_date}        
                        </label>
                    </Col>
                </Row>

                <Row className="w-100" style={{ marginBottom: '20px',display: 'flex',alignItems: 'baseline', }}>
                    <Col xs={12} sm={12} lg={6} >
                        <div className='time-enter-block'>
                            <div className='text-center timeLabel'>
                                <b>Shift Timing</b>
                            </div>
                            <Row className="modal-entry-form-row"  >
                               
                                <Col xs={12} sm={12} lg={6}>
                                    <span >First Start</span>
                                    <div>
                                    <TimePicker
                                        showSecond={false}
                                        defaultValue={moment()?.hour(entryData.start_1.split(':')[0])?.minute(entryData.start_1.split(':')[1])}
                                        className="xxx"
                                        onChange={(e) => handleTimeChange(e,'start_1')}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                    />
                                    </div>
                                </Col>
                               
                                <Col xs={12} sm={12} lg={6}>
                                    <span >First End</span>
                                    <div>
                                    <TimePicker
                                        showSecond={false}
                                        defaultValue={moment()?.hour(entryData.end_1.split(':')[0])?.minute(entryData.end_1.split(':')[1])}
                                        className="xxx"
                                        onChange={(e) => handleTimeChange(e,'end_1')}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                    />
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} lg={12}>
                                    <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                        {validationError && validationError.start_1}        
                                    </label>
                                </Col>
                            </Row>

                            <Row className="modal-entry-form-row" >
                                <Col xs={12} sm={12} lg={6}>
                                    <span >Second Start</span>
                                    <TimePicker
                                        showSecond={false}
                                        defaultValue={moment()?.hour(entryData.start_2.split(':')[0])?.minute(entryData.start_2.split(':')[1])}
                                        className="xxx"
                                        onChange={(e) => handleTimeChange(e,'start_2')}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                    />
                                </Col>
                                <Col xs={12} sm={12} lg={6}>
                                    <span >Second End</span>
                                    <TimePicker
                                        showSecond={false}
                                        defaultValue={moment().hour(entryData.end_2.split(':')[0]).minute(entryData.end_2.split(':')[1])}
                                        className="xxx"
                                        onChange={(e) => handleTimeChange(e,'end_2')}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                    />
                                </Col>
                                <Col xs={12} sm={12} lg={12}>
                                    <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                        {validationError && validationError.start_2}
                                        {validationError && validationError.time_error}         
                                    </label>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} lg={6} >
                        <div className='time-enter-block'>
                            <div className='text-center timeLabel'>
                                <b>Break Timing</b>
                            </div>
                            <Row className="modal-entry-form-row" >
                                <Col xs={12} sm={12} lg={6}>
                                    <span>First Start</span>
                                    <TimePicker
                                        showSecond={false}
                                        defaultValue={moment().hour(entryData.break_1_start.split(':')[0]).minute(entryData.break_1_start.split(':')[1])}
                                        className="xxx"
                                        onChange={(e) => handleTimeChange(e,'break_1_start')}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                    />
                                </Col>
                                <Col xs={12} sm={12} lg={6}>
                                    <span>First End</span>
                                    <TimePicker
                                        showSecond={false}
                                        defaultValue={moment().hour(entryData.break_1_end.split(':')[0]).minute(entryData.break_1_end.split(':')[1])}
                                        className="xxx"
                                        onChange={(e) => handleTimeChange(e,'break_1_end')}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                    />
                                </Col>
                                <Col xs={12} sm={12} lg={12}>
                                    <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                        {validationError && validationError.break_1}        
                                    </label>
                                </Col>
                            </Row>
                            <Row className="modal-entry-form-row" >
                                <Col xs={12} sm={12} lg={6}>
                                    <span>Second Start</span>
                                    <TimePicker
                                        showSecond={false}
                                        defaultValue={moment().hour(entryData.break_2_start.split(':')[0]).minute(entryData.break_2_start.split(':')[1])}
                                        className="xxx"
                                        onChange={(e) => handleTimeChange(e,'break_2_start')}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                    />
                                </Col>
                                <Col xs={12}  sm={12} lg={6}>
                                    <span>Second End</span>
                                    <TimePicker
                                        showSecond={false}
                                        defaultValue={moment().hour(entryData.break_2_end.split(':')[0]).minute(entryData.break_2_end.split(':')[1])}
                                        className="xxx"
                                        onChange={(e) => handleTimeChange(e,'break_2_end')}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                    />
                                </Col>
                                <Col xs={12} sm={12} lg={12}>
                                    <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                        {validationError && validationError.break_2}        
                                    </label>
                                </Col>
                            </Row>
                         </div>
                    </Col>
                </Row>
                <Row className="w-100 modal-entry-form-row" >
                    <Col xs={6} sm={6} lg={2}>
                        <span>Calls : </span>
                        <input min='0' max='9999' type='number' tabIndex="110" className='form-control' name='calls' id="calls" defaultValue={entryData?.calls} onChange={handleChange}/>
                    </Col>
                    <Col xs={6} sm={6} lg={2}>
                        <span>Emails : </span>
                        <input min='0' max='9999' type='number' tabIndex="111" className='form-control' name='emails' id="emails" defaultValue={entryData?.emails} onChange={handleChange}/>
                    </Col>
                    <Col xs={6} sm={6} lg={2}>
                        <span>Submissions : </span>
                        <input disabled type='number' tabIndex="112" className='form-control' name='submissions' id="submissions" defaultValue={applicantData?.submission}/>
                    </Col>
                    <Col xs={6} sm={6} lg={2}>
                        <span>InMail Sent : </span>
                        <input min='0' max='9999' type='number' tabIndex="112" className='form-control' name='inmail_sent' id="inmail_sent" defaultValue={applicantData?.inMails} onChange={handleChange}/>
                    </Col>
                    <Col xs={6} sm={6} lg={2}>
                        <span>Confirmed Interview : </span>
                        <input disabled tabIndex="112" className='form-control' name='confirmed_Interview' id="confirmed_Interview" defaultValue={applicantData?.confirmInterview} onChange={handleChange}/>
                    </Col>
                    <Col xs={6} sm={6} lg={2} className='text-right w-100'>
                        <input disabled={btnDisabled} type='submit' value={method === 'put' ? 'Update' : 'Save'} className='btn btn-primary btn-md mt-2 btn-radius saveBtn' onChange={handleChange}/>
                    </Col>
                </Row>  

                <Row className="w-100 modal-entry-form-row"  >
                    <Col xs={12} sm={12} lg={12} >
                         
                    </Col>
                </Row>
            </form>

            { method && 
                <>
                <form onSubmit={(e) => {handleSubmitItem(e)}} style={{width:"100%",marginTop:"20px"}}>
                    <Row className="w-100 modal-entry-form-row  " >
                        <Col xs={12} sm={12} lg={12} className='text-left'>
                            <h4>Client/Non-Client Time</h4>
                        </Col>
                    </Row>

                    <Row className="w-100 modal-entry-form-row" >
                        <Col xs={6} sm={6} lg={6}>
                            <span>Select Activity : </span>
                            <Select
                                options={activityList}
                                name='code'
                                id='code'
                                onChange={onChangeActivity}
                                defaultValue={itemActivity}
                                value={itemActivity}
                                // menuPortalTarget={document.querySelector('.modal')}
                                menuPosition='fixed'
                            />
                            <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                        {itemValidationError && itemValidationError.code}        
                            </label>
                        </Col>
                        <Col xs={6} sm={6} lg={4}>
                            <br/>
                            <input type='number'min="0" className='form-control' name='hrs' placeholder='Hours' id="hrs" onChange={onChangeHrs} value={itemHrs}/>
                            <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                    {itemValidationError && itemValidationError.hrs}     
                            </label>
                        </Col>
                        <Col xs={6} sm={6} lg={2} className='text-right'>
                            <br/>
                            <input type='submit' value='Save & Add New' disabled={btnDisabled} className='btn btn-primary btn-md btn-radius' />
                        </Col>
                        <Col xs={12} sm={12} lg={12} className='text-left'>
                            <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                    {itemValidationError && itemValidationError.msg}     
                            </label>
                        </Col>
                    </Row>
                    <Row className="w-100 modal-entry-form-row  mb-5" >
                        <Col xs={12} sm={12} lg={12}>
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Activity</th>
                                        <th>Hrs</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itemList && itemList?.map((item,index)=> {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{Util.capitalize(item.code)}</td>
                                                <td>{item.hrs}</td>
                                                <td><a style={{cursor:'pointer',color:'#8185ff'}} onClick={() => deleteTimeEntryItem(item.id)} > Delete </a></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Col>

                    </Row>
                </form>
                </>
            }


            </Container>
        </>
        
    )
}

export default AddTimeEntry