import React, { useEffect,useState } from 'react'
import _ from 'lodash'
import SignUp from './SignUpV2'
import '../../../../assets/v2/css/main.css'
import candidate1 from '../../../../assets/v2/imgs/page/candidates/img-candidate10.png';
import candidate2 from '../../../../assets/v2/imgs/page/candidates/img-candidate11.png';
import candidate3 from '../../../../assets/v2/imgs/page/candidates/img-candidate12.png';
import './styles/default.css';

const StepPage1 = ({
  setFormData
}) => {

  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (isSubmitted) {
      setFormData((prev) => ({
          ...prev,
          step: 'Step2',
          step1: true,
      }))
    }
    setIsSubmitted(false)
  }, [isSubmitted])

  const submitAction = async () => {
    setIsSubmitted(true)
  } 

  return (
    <>
      <div className="row step-section-row" style={{margin: 0, padding: 0}}>
        <div className="col-lg-6" style={{backgroundColor: '#5192ff'}}>
          <div className="container">
            <div className="box-newsletter" style={{background: 'none', marginTop: '50px'}}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-md-30">
                  <div className="card-none-bd hover-up wow animate__ animate__fadeInUp animated" data-wow-delay=".0s" style={{visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp'}}>
                    <div>
                      <h4 style={{color: '#fff'}}>Job Queue</h4>
                      <h2 style={{color: '#fff'}}><span className="count">15</span></h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-md-30">
                  <div className="card-none-bd hover-up wow animate__ animate__fadeInUp animated" data-wow-delay=".1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                    <div>
                      <h4 style={{color: '#fff'}}>Applied</h4>
                      <h2 style={{color: '#fff'}}><span className="count">35</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="employers-list mt-60">
                <div className="card-employers hover-up wow animate__ animate__fadeIn animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 d-flex align-items-center">
                      <div className="employers-logo online mr-15">
                        <img alt="jobhub" src={candidate1} />
                      </div>
                      <div className="employers-name">
                        <span style={{padding: '1px 1px 1px 150px', backgroundColor: '#f0ebebde', borderRadius: '20px'}} /><br />
                        <span style={{padding: '1px 1px 1px 100px', backgroundColor: '#f0ebebde', borderRadius: '20px', marginTop: '10px'}} />
                      </div>
                    </div>
                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                      <div className="card-grid-2-link">
                        <a href="#"><i className="fi-rr-shield-check" /></a>
                        <a href="#"><i className="fi-rr-bookmark" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-employers hover-up wow animate__ animate__fadeIn animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 d-flex align-items-center">
                      <div className="employers-logo mr-15">
                        <img alt="jobhub" src={candidate2} />
                      </div>
                      <div className="employers-name">
                        <span style={{padding: '1px 1px 1px 150px', backgroundColor: '#f0ebebde', borderRadius: '20px'}} /><br />
                        <span style={{padding: '1px 1px 1px 100px', backgroundColor: '#f0ebebde', borderRadius: '20px', marginTop: '10px'}} />
                      </div>
                    </div>
                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                      <div className="card-grid-2-link">
                        <a href="#"><i className="fi-rr-shield-check" /></a>
                        <a href="#"><i className="fi-rr-bookmark" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-employers hover-up wow animate__ animate__fadeIn animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 d-flex align-items-center">
                      <div className="employers-logo online mr-15">
                        <img alt="jobhub" src={candidate3} />
                      </div>
                      <div className="employers-name">
                        <span style={{padding: '1px 1px 1px 150px', backgroundColor: '#f0ebebde', borderRadius: '20px'}} /><br />
                        <span style={{padding: '1px 1px 1px 100px', backgroundColor: '#f0ebebde', borderRadius: '20px', marginTop: '10px'}} />
                      </div>
                    </div>
                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                      <div className="card-grid-2-link">
                        <a href="#"><i className="fi-rr-shield-check" /></a>
                        <a href="#"><i className="fi-rr-bookmark" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-center">
          <div className="box-info-job box-info-about pl-90">
            <h5 className="heading-36 mb-30 mt-10 wow animate__animated animate__fadeInUp">Let's get started</h5>
            <span className="text-blue  wow animate__animated animate__fadeInUp">Confirm the items below.</span>
            <h5 className="heading-md-regular mt-30 mb-10 wow animate__animated animate__fadeInUp">You are:</h5>
            <div className="checkmark-row top-align">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height="20.869565217391305" fill="none" viewBox="0 0 23 20" style={{minWidth: '24px'}}>
                <path fill="none" d="M0 0H22.396V20H0z" />
                <path stroke="#384FFB" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10.5l5 5L17.5 4" />
              </svg>
              <p className="questionnaire-body-text">based in the U.S.</p>
            </div>
            <div className="checkmark-row top-align">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height="20.869565217391305" fill="none" viewBox="0 0 23 20" style={{minWidth: '24px'}}>
                <path fill="none" d="M0 0H22.396V20H0z" />
                <path stroke="#384FFB" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10.5l5 5L17.5 4" />
              </svg>
              <p className="questionnaire-body-text">looking for a full-time role</p>
            </div>  
            <h5 className="heading-md-regular mt-30 mb-10 wow animate__animated animate__fadeInUp">You have:</h5>
            <div className="checkmark-row top-align">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height="20.869565217391305" fill="none" viewBox="0 0 23 20" style={{minWidth: '24px'}}>
                <path fill="none" d="M0 0H22.396V20H0z" />
                <path stroke="#384FFB" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10.5l5 5L17.5 4" />
              </svg>
              <p className="questionnaire-body-text">a digital resume handy</p>
            </div>
            <div className="checkmark-row top-align">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height="20.869565217391305" fill="none" viewBox="0 0 23 20" style={{minWidth: '24px'}}>
                <path fill="none" d="M0 0H22.396V20H0z" />
                <path stroke="#384FFB" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10.5l5 5L17.5 4" />
              </svg>
              <p className="questionnaire-body-text">2-5 minutes to complete this questionnaire</p>
            </div> 
            <div className="mt-4 mb-4">
              <a href="#" onClick={submitAction}>
                <button className="btn btn-default font-heading icon-send-letter btn1">Continue</button>
              </a>
            </div>
          </div>    
        </div>
      </div>  
    </>
  )
}

export default StepPage1
