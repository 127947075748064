import moment from 'moment';
import Container from 'react-bootstrap/Container'
import React, { useState, useEffect, useContext, useReducer } from 'react';
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Card from '../Admin/shared/Card';
import styles from './styles/WeeklyReferralPage.module.scss'
import CandidateInfo from './CandidateInfo';
import Util from '../../../utils/util';
import Paginator from '../../common/Paginator/Paginator';
import Select from "react-select"
import { Button, Modal } from 'react-bootstrap';
import SearchBar from '../../common/SearchBar/SearchBar';
import SweetAlert from 'react-bootstrap-sweetalert';

const RequestInterviewsPage = () => {
    const job_group = []
    const my_submission_only = sessionStorage.getItem('my_submission_only')
    const queryParams = new URLSearchParams(window.location.search)
    const email_date = queryParams.get("date")
    const pathname = window.location.pathname?.split('/')
    const categoryId = parseInt(pathname[2]) ? pathname[2] : ''
    const [activePage, setActivePage] = useState(0)
    const [loading, setLoader] = useState(true)
    const [candidates, setCandidates] = useState([])
    const [searchText, setSearchText] = useState('')
    const [pageCount, setPageCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(0)
    const [show,setShow] = useState(false)
    const [category, setCategory] = useState(categoryId)
    const [selectedCategory, setSelectedCategory] = useState()
    const [multiIntrestedCandidates, setMultiIntrestedCandidates] = useState([])
    const [mySubmissionOnly, setMySubmissionOnly] = useState(my_submission_only == 'true' ? true : false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [rejectUserId, setRejectUserId] = useState('')
    
    const fetchData = async() => {
      setCandidates([])
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setLoader(true)
      const url = `/request_interviews.json`
      await makeRequest(url, 'get',  {params: {page: (activePage+1), search: searchText, submission: mySubmissionOnly, email_date:  email_date}}, {
          contentType: 'application/json',
          loadingMessage: 'Fetching...',
          createSuccessMessage: (response) => response.data.message,
          onSuccess: (res) => {
            setCandidates(
                res.data.candidates.map((candidate) => ({
                    id: candidate.id,
                    first_name: capitalize(candidate?.user?.first_name),
                    last_name: capitalize(candidate?.user?.last_name),
                    title: candidate.title,
                    or_name: candidate?.user?.organization?.name,
                    email: candidate.email_address,
                    phone_number: candidate.phone_number,
                    employer_message: candidate?.employer_comment || " ",
                    talent_comment: candidate?.talent_comment || "",
                    location: candidate?.user?.organization.location,
                    linkedin_profile_url: candidate.linkedin_profile_url,
                    candidate_response: candidate.candidate_response,
                    submissions: candidate.submissions,
                    resume: candidate.cv_url,
                    user_id: candidate?.user_id,
                    job_name: candidate?.job?.name
                }))
            )
            setPageCount(res.data.total_pages)
            setTotalCount(res.data.total)
            setLoader(false)
          },
      })
    }

    const showConfirmDelete = () => {
      setShowDeleteConfirmation(true)
  }
  
    const handleCheckboxChange = (id) => {
      if(multiIntrestedCandidates?.includes(id))
      {
        setMultiIntrestedCandidates([...multiIntrestedCandidates.filter((row)=> row != id)])
      }
      else{
        setMultiIntrestedCandidates([...multiIntrestedCandidates, id])
      }
      
    }
  
    const handleIntrested = (candidate) => {
      var doc = document.getElementsByClassName('form-check-input')
      for (let i = 0; i < doc.length; i++) {
        if(doc[i].checked)
          doc[i].click()
      }
      setMultiIntrestedCandidates([])
      setShow(true)
    }

    const rejectRequest = () => {
      const payload = new FormData()
      payload.append('request_interviews[colour_id]',3)
      payload.append('request_interviews[user_id]',rejectUserId?.user_id)
      payload.append('request_interviews[candidate_response]',false)
      makeRequest('/interview_request_reject', 'post', payload, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createResponseMessage: (res) => {
              if (res?.success){
                window.location.reload()
              }
              return {
                  message: res.msg,
                  messageType: res.messageType
              }
          },
      })
    }
  
    useEffect(()=>{
      fetchData()
    },[page,category,mySubmissionOnly,activePage])
    return(
        <>
        <Container className="p-0" fluid>
          <label
            className={styles.mainTitle + ' page-title'}
            style={{ marginBottom: '2rem' }}
          >
            <span>Requested Interview</span>
        </label>
       </Container>
        <Card>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{ marginBottom: '15px' }}
          >
              <span className={styles.candidatecount}>
                Displaying{' '}
                {Util.displayNumberOfResults(
                    totalCount,
                    pageCount,
                    activePage,
                    25,
                    totalCount >= 10000 ? `10000+` : totalCount,
                    true,
                    true
                )}
              </span>
          </div>
          {showDeleteConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, Reject it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to Reject?"
                    onConfirm={()=>(rejectRequest(), setShowDeleteConfirmation(false))}
                    onCancel={()=>setShowDeleteConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this
                </SweetAlert>
            }
    
          <div
            className="d-flex justify-content-end align-items-center w-100"
            style={{ marginBottom: '15px' }}
          >
          </div>
          <CandidateInfo
          showConfirmDelete={showConfirmDelete}
            loading={false}
            candidates={candidates}
            handleIntrested={handleIntrested}
            multiSelectCheckbox={handleCheckboxChange}
            multiIntrestedCandidates={multiIntrestedCandidates}
            setRejectUserId={setRejectUserId}
          />
          {pageCount > 1 && (
              <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: 'auto' }}
              >
                  <Paginator
                      activePage={activePage}
                      setActivePage={setActivePage}
                      pageCount={pageCount}
                      pageWindowSize={5}
                      showGoToField={false}
                  />
              </div>
          )} 
        </Card>
        </>
    )
}

export default RequestInterviewsPage