import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from '../Admin/shared/Card'
import TableRecruiter from '../Admin/shared/Table'
import Paginator from '../../common/Paginator/Paginator'
import styles from './styles/AnalyticPage.module.scss'
import { CSVLink } from "react-csv"
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import P from '../Admin/shared/P'
import './styles/RecruiterManagment.scss'
import feather from 'feather-icons'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import ResumeIcon from '../../../../assets/images/icons/resume-icon'
import Modal from 'react-bootstrap/Modal'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Row,Col } from 'react-bootstrap'
import SearchBar from '../../common/SearchBar/SearchBar'
import { Input } from '@material-ui/icons'


const IncomingMailDashboard = () => {
    var query = window.sessionStorage.getItem('recruiter_incoming_mail_query')
    const queryParams = new URLSearchParams(window.location.search)
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    const [activePage, setActivePage] = useState(0)
    const [exportDatas, setExportDatas] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [incomingMails, setIncomingMails] = useState([])
    const [open,setOpen] = useState(false)
    const [excelExport,setExcelExport] = useState(false)
    const [rsumeText,setRsumeText] = useState('')
    const [totalMails,setTotalMails] = useState()
    const [currentCounts,setCurrentCounts] = useState()
    const [loading, setLoading] = useState(true)
    const [startRecord, setStartRecord] = useState(0)
    const [submissionId, setSubmissionId] = useState('')
    const [showSendConfirmation, setShowSendConfirmation] = useState(false)
    const [searchTerm, setSearchTerm] = useState(query || '')
    const csvExportRef = React.useRef()
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
    const handleDate = (date) => {
        setDateRange(date)
    }
    const [tableHeaders, setTableHeaders ] = useState([
        { label: 'Name', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Phone Number', key: 'phone_number' },
      ])
    const [tableHeadersFinal, setTableHeadersFinal] = useState([])
    const [checkedList, setCheckedList] = useState(tableHeaders.map(data => data.key));
    const fetchData = () => {
        setLoading(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = ` /analytic/incoming_mail_dashboard.json?page=${activePage + 1}&start_date=${start_date}&end_date=${end_date}&search=${searchTerm}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (response) => {
                setIncomingMails(
                    response.data.mails.map((mailData,key) => ({
                        id: key,
                        name: mailData.candidate_name ? mailData.candidate_name : '-',
                        email: response.data?.person_list[mailData.person_id]?.email_address,
                        to_email: mailData.to_email,
                        date: mailData.date,
                        resume: resumePreview(mailData),
                        subject: mailData.subject,
                        attachment_url: mailData.attachment_url,
                        phone_number: response.data?.person_list[mailData.person_id]?.phone_number
                    }))
                )
                setPageCount(response.data.meta.total_pages)
                setCurrentCounts(response.data.meta.current_count)
                setTotalMails(response.data.meta.total_mails)
                setStartRecord((activePage * 25) + 1) 
                setLoading(false)
            },
        })
    }

    const exportDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = ` /analytic/incoming_mail_dashboard.json?start_date=${start_date}&end_date=${end_date}&search=${searchTerm}`
        const response = await makeRequest(
          `${url}`,
          'get',
          {}
        )
        setExportDatas(response?.data?.mails.map((mailData,key)=> ({
          id: key + 1,
          name: mailData.candidate_name ? mailData.candidate_name : '-',
          email: response.data?.person_list[mailData.person_id].email_address,
          phone_number: response.data?.person_list[mailData.person_id].phone_number
        })))
        csvExportRef?.current?.link.click();
      }

    const handleCheckboxChange = (event) => {
        
        const { value, checked } = event.target;
        console.log(value, checked)
        if (checked) {
            setCheckedList(prev => [...prev, value]);
        } else {
            setCheckedList(prev => prev.filter(item => item !== value));
        }
    }

    const handleButtonClick = () => {
        if(checkedList.length > 0){
            var listData = []
            tableHeaders.map(data => {
                if(checkedList.includes(data.key)){
                    listData.push(data)
                }
            })
            setTableHeadersFinal(listData)
            exportDataMethod()
        }
    }

    useEffect(() => {
        fetchData()
        feather.replace()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,dateRange])

    const resumeModalCall = (resume) =>{
        setOpen(true)
        setRsumeText(resume)
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('recruiter_incoming_mail_query',value)
    }

    const onSendSubmission = async () => {
        // manual_candidate_submission
        let id = submissionId
        if(id != ''){
            const url = `/admin/manual_candidate_submission`
            try {
                const  data  = await makeRequest(url, 'post', {id: id}, {
                    contentType: 'application/json',
                    loadingMessage: 'Applying..',
                    createResponseMessage: (response) => {
                        return {
                            message:response.message,
                            messageType: 'success',
                            loading: false,
                            autoClose: true,
                        }
                    },
                    createErrorMessage:(error)=>error.response.data.error
                }).then((res)=>{
                    if(res.data.type == 'success'){
                            setSubmissionId('')
                            setShowSendConfirmation(false)
                            // fetchData()
                    }
                })
                
            } catch (error) {
                console.log(error)
            }
        }
        
    }

    const showConfirmSend = (id) => {
        setSubmissionId(id)
        setShowSendConfirmation(true)
    }

    const resumePreview = (o) =>{
        return (<div >
                   <img src={ResumeIcon} onClick={(e)=>resumeModalCall(o.resume_text)} className='align-items-center' style={{cursor:'pointer'}}/>
                   {(o.attachment_url != null && o.attachment_url != 'N/A' && o.attachment_url.trim() != '') ?
                   <a href={(o.attachment_url != null && o.attachment_url != 'N/A' && o.attachment_url.trim() != '') ? o.attachment_url : '#'} target="_blank" className='ml-3'>
                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                        <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                        <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg>
                    </a>
                    : <></>}
                </div>)
    }
   
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{ width: '90%'}}>
                    Incoming Mail Dashboard
                </P>
                <div>
                    <button className={`ml-3 ${styles.exportCSVButton}`} onClick={ ()=>setExcelExport(true)}>
                        Export CSV
                    </button>
                </div>
                <CSVLink
                    className='hidden'
                    data={exportDatas} 
                    headers={tableHeadersFinal}
                    filename={"IncomingMailAnalytic.csv"}
                    ref={csvExportRef}
                >
                </CSVLink>
            </div>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '15px' }}
            >
                <Col xs={12} sm={12} lg={6} style={{float:'left'}}>
                    {totalMails > 0 &&
                        <DisplayPagination>Displaying  {(activePage == 0)?1:startRecord} - {currentCounts} of {totalMails} Mails</DisplayPagination>
                    }
                </Col>
                <Col xs={12} sm={12} lg={3}>
                    <div style={{display: 'grid',marginLeft:'10px',float:'right'}}>
                        <label>
                            Select Date Range
                        </label>
                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                </Col>
                <Col xs={12} sm={12} lg={3} style={{marginTop:'1.2%',marginLeft: '3%'}}>
                    <SearchBar
                        placeholder="Search Subject..."
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 0 ? fetchData() : setActivePage(0)
                            !searchTerm && window.sessionStorage.setItem('recruiter_incoming_mail_query','')
                        }}
                        onClick={(e) => setSearchTerm('')}
                    />
                </Col>
            </div>
            <TableRecruiter
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Name',
                        field: 'name',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Subject',
                        field: 'subject',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Email',
                        field: 'email',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Phone Number',
                        field: 'phone_number',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'From email',
                        field: 'to_email',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Date',
                        field: 'date',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Resume',
                        field: 'resume',
                        editable: false,
                        type: 'text',
                    }
                ]}
                rowValues={incomingMails.map((o) => ({
                    ...o,
                    date: moment(o.date).format(
                        'MMM DD, YYYY'
                    ),
                    
                }))}
                activePage={activePage}
                goToEditPage={(id) =>
                    (window.location.href = '/admin/recruiters/' + id)
                }
                loading={loading}
            />
            {pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}

            <Modal
                show={open}
                onHide={()=>setOpen(!open)}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>Resume</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                        {rsumeText}
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setOpen(!open)}>
                        Close
                    </Button>
                </Modal.Footer> 
            </Modal>

            {showSendConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, Send it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure want to make auto submission?"
                    onConfirm={()=>(onSendSubmission(),setShowSendConfirmation(false))}
                    onCancel={()=>setShowSendConfirmation(false)}
                    focusCancelBtn
                    >
                </SweetAlert>
            }

            <Modal
                show={excelExport}
                onHide={()=>setExcelExport(!excelExport)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Export CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <div>
                        <span>Select column you want to download :</span>
                    </div>
                    <div className='mt-2'>
                        {tableHeaders.map(data => (
                            <span className='m-2' key={data.key}>
                            <input
                                type="checkbox"
                                name="headers"
                                value={data.key}
                                checked={checkedList.includes(data.key)}
                                onChange={handleCheckboxChange}
                            />
                            <label style={{ paddingLeft: '10px' }}> {data.label}</label>
                            </span>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>handleButtonClick()}>
                        Download
                    </Button>
                    <Button variant="secondary" onClick={()=>setExcelExport(!excelExport)}>
                        Cancel
                    </Button>
                </Modal.Footer> 
            </Modal>

        </Card>
    )

}

export default IncomingMailDashboard