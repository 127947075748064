import React, { useEffect, useState } from 'react'
import Card from '../Admin/shared/Card'
import P from '../Admin/shared/P'
import { Row,Col } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from '../Admin/styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Table from '../Admin/shared/Table'
import { capitalize } from '../../../utils'
import Paginator from '../../common/Paginator/Paginator'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'

const RecruiterJobAnalyticPage = (prop) => {
  const queryParams = new URLSearchParams(window.location.search)
  const urlRecruiterId = queryParams.get("recruiter")
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  const localStorageDateRange = JSON.parse(sessionStorage.getItem('jobAnalysticDateRange'))
  let jobStatus_org = sessionStorage.getItem("jobStatus_org")
  const [activePage, setActivePage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [organization,setOrganization] = useState([])
  const [selectedOrganizationValue, setSelectedOrganizationValue]  = useState(prop.organization ? prop.organization[0]  : jobStatus_org && jobStatus_org != "null" ? JSON.parse(jobStatus_org) : '')
  const [selectedOrganization, setSelectedOrganization]  = useState(prop.organization ? prop.organization[0].value  : jobStatus_org && jobStatus_org != "null" ? JSON.parse(jobStatus_org).value : '')
  const [jobs,setJobs] = useState([{value: 'all', label: 'All'}])
  const [selectedJob,setSelectedJob] = useState('')
  const [selectedDate,setSelectedDate] = useState('')
  const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'}])
  const [account_managers,setAccountManagers] = useState([{value: 'all', label: 'All'}])
  const [selectedRecruiter,setSelectedRecruiter] = useState((urlRecruiterId)?urlRecruiterId:'')
  const [selectedTeamLead,setSelectedTeamLead] = useState('')
  const [selected,setSelected] = useState({value: 'all', label: 'All'})
  const [defaultselected,setdefaultselected] = useState((urlRecruiterId)? prop.recruiter_list.filter((option) => option.value == urlRecruiterId) :{value: 'all', label: 'All'})
  const [jobStatus, setJobStatus] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [member_options, setOptions] = useState([])
  const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate:  (localStorageDateRange?.startDate) ?  localStorageDateRange?.startDate : moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate()})
  const [loading, setLoder] = useState(true)
  const handleDate = (date) => {
    sessionStorage.setItem('jobAnalysticDateRange', JSON.stringify(date))
      setDateRange(date)
  }
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [totalValues,setTotalValues] = useState({})
  const headers = [
    { label: 'Job Name', key: 'jobName' },
    { label: 'Organization', key: 'organization' },
    { label: 'Recruiter Name', key: 'recruiterName' },
    { label: 'Leads', key: 'leads' },
    { label: 'Applicants Received', key: 'applicants' },
    { label: 'Recruiter Screened', key: 'recruiterScreened' },
    { label: 'Submitted', key: 'submitted' },
    { label: 'First Interview', key: 'firstInterview' },
    { label: 'Second Interview', key: 'secondInterview' },
    { label: 'onhold', key: 'onhold' },
    { label: 'Offers', key: 'offers' },
    { label: 'Hired', key: 'hired' },
    { label: 'Archived', key: 'archived' },
    { label: 'Application Desposition', key: 'desposition' },
  ];

  const fetchData = async () => {
    setLoder(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = 'recruiter_jobs_analytics.json'
    const response = await makeRequest(
      `${url}?page=${
          activePage + 1
      }&organization=${selectedOrganization}&recruiter=${prop.currentUser.id}&account_manager_id=${selectedTeamLead}&job=${selectedJob}&start_date=${start_date}&end_date=${end_date}`,
      'get',
      {}
      )
    setJobStatus(response?.data?.jobs_status?.map((data,key)=> ({
      id: key,
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organizations_name && capitalize(data?.organizations_name),
      recruiterName: `${data?.user_name ? `${capitalize(data?.user_name)}` : '-' }`,
      leads: data.lead > 0 ? <a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=lead`}>{data.lead}</a> : '',
      applicants: data.applicant > 0 ? <a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=applicant`}>{data.applicant}</a> : '',
      recruiterScreened: data.recruitor_screened > 0 ? <a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> : '',
      submitted: data.submitted > 0 ? (<a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a>) : '',
      firstInterview: (data.first_interview > 0) ? <a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&recruiter=${prop.currentUser.id}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> : '',
      secondInterview: (data.second_interview > 0) ? <a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> : '',
      offers: (data.offer > 0) ? <a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a>: '',
      archived: (data.reject > 0) ? <a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> : '',
      hired: (data.hired > 0) ? <a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> : '',
      onhold: (data?.onhold > 0) ? <a href={`/analytic/submitted_candidates_analytic?job_id=${data.job_id}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=onhold`}>{data?.onhold}</a> : '',
      desposition: (data.recruitor_screened + data.submitted + data.first_interview + data.second_interview + data.offer + data.hired + data.reject + data?.onhold)
    })))
    setPageCount(response.data.total_pages)
    setTotalCount(response.data.total_count)
    setStartRecord(((activePage + 1) * response.data.per_page) - response.data.per_page)
    // setStartRecordOnDemand(((activePageOnDemand + 1) * response.data.per_page) - response.data.per_page)

    setPerPage(response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setTotalValues(response.data.total_values)
    setLoder(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = 'recruiter_jobs_analytics.json'
    const response = await makeRequest(
      `${url}?organization=${selectedOrganization}&recruiter=${prop.currentUser.id}&account_manager_id=${selectedTeamLead}&job=${selectedJob}&start_date=${start_date}&end_date=${end_date}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.jobs_status.map((data,key)=> ({
      id: key,
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organizations_name && capitalize(data?.organizations_name),
      recruiterName: `${data?.user_name ? `${capitalize(data?.user_name)}` : '-' }`,
      leads: data.lead,
      applicants: data.applicant,
      recruiterScreened: data.recruitor_screened,
      submitted: data.submitted,
      firstInterview: data.first_interview,
      secondInterview: data.second_interview,
      offers: data.offer,
      archived: data.reject,
      hired: data.hired,
      onhold: data?.onhold,
      desposition: (data.recruitor_screened + data.submitted + data.first_interview + data.second_interview + data.offer + data.hired + data.reject + data?.onhold)
    })))
    csvExportRef?.current?.link.click();
  }

  const loadOptions = (inputValue, callback) => {
    setLoading(true)
    fetch(`/organizations/get_organization?search=${inputValue}`)
    .then((res) => res.json())
    .then((res) => {
      let {organizations} = res
      setLoading(false)
      setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(member_options);
  }

  const handleInputChange = (str) => {
    setInputValue(str)
    return str;
  }

  const handleSelectOption = (selectedOptions) => {
    setActivePage(0)
    setSelectedOrganization(selectedOptions?.value || '')
    setSelectedOrganizationValue(selectedOptions || {})
    sessionStorage.setItem("jobStatus_org", JSON.stringify(selectedOptions))
  }

  useEffect(()=>{
    fetchData()
  },[selectedOrganization,selectedRecruiter,selectedJob,dateRange,activePage, selectedTeamLead])

  const onSelectedOrganization = (event) => {
    setSelectedOrganization(event.value)
    setSelectedJob('')
    setSelectedDate('')
    setSelectedRecruiter('')
    setSelectedTeamLead('')
  }

  const onSelectedjob = (event) => {
    setSelectedJob(event.value)
  }

  const onSelectedDate = (event) => {
    setSelectedDate(event.target.value)
  }

  const onSelectedRecruiter = (event) => {
    setSelectedRecruiter(event.value)
  }

  const onSelectedTeamLead = (event) => {
    setSelectedTeamLead(event.value)
  }

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  };

  return (
    <>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
        style={{ marginBottom: '42px' }}
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Job Analytics
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
          {/* <a href='/analytic/recruiter_jobs_analytics' className={`ml-3 ${styles.exportCSVButton}`}>
              Back
          </a> */}
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={headers}
        filename={"jobs_recruiter_analytic.csv"}
        ref={csvExportRef}
        >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 &&
                <DisplayPagination>Displaying  {(activePage == 0)?1:startRecord+1} - {currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
            <div style={{display: 'grid'}}>
              <label>
                  Organization
              </label>
              <AsyncSelect
                  isLoading={isLoading}
                  isClearable={true}
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions={selectedOrganization}
                  defaultValue={selectedOrganizationValue}
                  onInputChange={handleInputChange}
                  onChange={handleSelectOption}
                  placeholder={'Search by organization'}
                  styles={colourStyles}
                  noOptionsMessage={() => 'start typing the name of organization'}
              />
            </div>
            {/* <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Job Name
              </label>
              <Select
                  defaultValue={selected}
                  options={jobs}
                  onChange={onSelectedjob}
                  name="job_name"
                  className={styles.colourStyles}
                  />
            </div> */}
            {/* <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Recruiter
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={recruiter}
                  onChange={onSelectedRecruiter}
                  name="recruiter"
                  className={styles.colourStyles}
                  />
            </div> */}
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Date
              </label>

              <DateRangePicker
                  handleOnSubmit={handleDate}
                  defaultDate={dateRange}
                  />
            </div>
          </div>
        </Col>
      </Row>
      <Table 
        columNames={[
            {
                name: 'No.',
                field: 'id',
                editable: false,
                type: 'text',
            },
            {
                name: 'Job Name',
                field: 'jobName',
                editable: false,
                type: 'text',
            },
            {
                name: 'Organization',
                field: 'organization',
                editable: false,
                type: 'text',
            },
          //   {
          //       name: 'Recruiter Name',
          //       field: 'recruiterName',
          //       editable: false,
          //       type: 'text',
          // },
          {
              name: 'Leads',
              field: 'leads',
              editable: false,
              type: 'text',
            },
            {
                name: 'App. Received',
                field: 'applicants',
                editable: false,
                type: 'text',
            },
            {
                name: 'Recruiter Screened',
                field: 'recruiterScreened',
                editable: false,
                type: 'text',
            },
            {
                name: 'Submitted',
                field: 'submitted',
                editable: false,
                type: 'text',
            },
            {
                name: 'First Interview',
                field: 'firstInterview',
                editable: false,
                type: 'text',
            },
            {
                name: 'Second Interview',
                field: 'secondInterview',
                editable: false,
                type: 'text',
            },
            {
              name: 'On Hold',
              field: 'onhold',
              editable: false,
              type: 'text',
            },
            {
                name: 'Offers',
                field: 'offers',
                editable: false,
                type: 'text',
            },
            {
                name: 'Hired',
                field: 'hired',
                editable: false,
                type: 'text',
            },
            {
                name: 'Archived',
                field: 'archived',
                editable: false,
                type: 'text',
            },
            {
              name: 'App. Dispositioned',
              field: 'desposition',
              editable: false,
              type: 'text',
            }
        ]}
        rowValues={jobStatus?.map((o)=>({
            ...o,
            created_at: moment(o.created_at).format(
                'MMM DD, YYYY'
                ),
            }))
        }
        activePage={activePage}
        perPage={perPage}
        totalValues={totalValues}
        loading={loading}
        />
      { pageCount > 1 && (
          <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
          >
              <Paginator
                  activePage={activePage}
                  setActivePage={setActivePage}
                  pageCount={pageCount}
                  pageWindowSize={5}
                  showGoToField={false}
                  />
          </div>
      )}
    </Card>
      </>
  )
}

export default RecruiterJobAnalyticPage