import axios from 'axios';
import { capitalize } from '../../../utils';

export const getLocation = async (value) => {
  const url = `/filter_location`;
  const formData = new FormData();
  formData.append('filter_word', value);
  let locationArray = [];

  try {
    const response = await axios.post(url, formData);
    locationArray = response.data.filter.map(({ location }) => ({
      value: capitalize(location),
      label: location
    }));

    // Get unique values using Set
    const uniqueLocations = [...new Set(locationArray.map(location => location.value))];
    
    // Create an array of objects with unique values
    locationArray = uniqueLocations.map(location => ({
      value: location,
      label: location
    }));
  } catch (error) {
    console.log(error);
  }

  return locationArray;
};


export const getCompany = async (value) => {
  const url = `/filter_company_name`;
  const formData = new FormData();
  formData.append('filter_word', value);
  let companyArray = [];

  try {
    const response = await axios.post(url, formData);
    companyArray = response.data.filter.map((company) => ({
      value: capitalize(company.company_name),
      label: company.company_name,
    }));
    const uniqueCompanies = [...new Set(companyArray.map(company => company.value))];
    companyArray = uniqueCompanies.map(company => ({
      value: company,
      label: company
    }));
  } catch (error) {
    console.log(error);
  }

  return companyArray;
};


export const getPosition = async (value) => {
  const url = `/filter_position`;
  const formData = new FormData();
  formData.append('filter_word', value);
  let educationArray = [];

  try {
    const response = await axios.post(url, formData);
    educationArray = response.data.filter.map(({ position, id }) => ({
      value: position,
      label: position
    }));
    const uniquePositions = [...new Set(educationArray.map(position => position.value))];
    educationArray = uniquePositions.map(position => ({
      value: capitalize(position),
      label: position
    }));
  } catch (error) {
    console.log(error);
  }

  return educationArray;
};


export const getIndustry = async (value) => {
  const url = '/filter_industry';
  const formData = new FormData();
  formData.append('filter_word', value);
  let industryArray = [];
  try {
    const response = await axios.post(url, formData);
    industryArray = response.data.filter.map(({ industry, id }) => ({
      value: industry,
      label: industry
    }));

    // Get unique values using Set
    const uniqueIndustries = [...new Set(industryArray.map(industry => industry.value))];
    
    // Create an array of objects with unique values
    industryArray = uniqueIndustries.map(industry => ({
      value: capitalize(industry),
      label: industry
    }));
  } catch (error) {
    console.log(error);
  }

  return industryArray;
};

