import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'


const Span = styled.span`
    margin-left: 12px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${(props) => (props.close ? 'red' : '#9EA6C8')};
    cursor: ${(props) => (props.close ? 'pointer' : 'auto')};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 8rem;
    display: inline-block;
`
const Button = styled.button`
    background: linear-gradient(
        94.67deg,
        #5f78ff -1.19%,
        #7185f2 53.94%,
        #8d91ff 102.59%
    );
    border-radius: 20px;
    padding: 9px 25px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    &:disabled {
        background: lightgray;
    }

`

// list allow mime type
const defaultTypes = [
    'application/msword',
    'application/pdf',
    'application/docx',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const FileButton = (props) => {
    const [file, setFile] = useState(props.file)
    const [wrongFileType, setWrongFileType] = useState(props.resumeError)
    const { label, getFile, resumeError, setResumeError, ...rest } = props
    const inputRef = useRef()
    const [types, setTypes] = useState(props?.fileType?.length > 0 ? props.fileType : defaultTypes)
    useEffect(() => {
        setWrongFileType(resumeError)
    }, [resumeError])

    const handleClick = (e) => {
        inputRef.current.click()
    }

    const handleFileChange = (e) => {
        if (
            inputRef.current &&
            inputRef.current.files.length &&
            checkFileTypes(inputRef.current.files)
        ) {
            const f = inputRef.current.files[0]
            setWrongFileType(null)
            props.setResumeError(null)
            setFile(f)
            getFile(f)
        }
    }

    const checkFileTypes = (files) => {
        //define message container
        let err = ''
    
        // loop access array
        for (var x = 0; x < files.length; x++) {
            // compare file type find doesn't matach
            if (types.every((type) => files[x].type !== type)) {
                // create error message and assign to container
                err +=
                    files[x].type[0].toUpperCase() +
                    files[x].type.slice(1) +
                    ' is not a supported format\n'
            }
        }

        if (err !== '') {
            // if message not same old that mean has error
            setWrongFileType(err)
            setResumeError(err)
            return false
        }
        setWrongFileType(null)
        return true
    }

    return (
        <>
            <div className="buttonIn">
                <input
                    type="file"
                    accept=".doc,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, text/plain, .docx,.txt,.pdf"
                    style={{ display: 'none' }}
                    ref={inputRef}
                    onChange={handleFileChange}
                />
                <button type='button' onClick={handleClick} className="btn btn-default font-heading icon-send-letter btn1" style={{marginBottom: '20px', backgroundColor: 'rgb(149, 149, 149)'}}>{label}</button>
                <span>{(file && file.name) || 'No file chosen'}</span>
                {file && (
                    <Span
                        onClick={() => {
                            setFile(null)
                            getFile(null)
                        }}
                        close
                    >
                        x
                    </Span>
                )}
            </div>
            {wrongFileType && (
                <p
                    style={{
                        fontSize: '10px',
                        color: 'red',
                        marginTop: '5px',
                    }}
                >
                    {wrongFileType}
                </p>
            )}
        </>
    )
}

export default FileButton
