import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from './shared/Card'
import Select from 'react-select'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from './shared/Button'
import P from './shared/P'
import './styles/RecruiterManagment.scss'
import feather from 'feather-icons'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import ResumeIcon from '../../../../assets/images/icons/resume-icon'
import Modal from 'react-bootstrap/Modal'
import { Row,Col,Spinner } from 'react-bootstrap'
import SearchBar from '../../common/SearchBar/SearchBar'
import { Input } from '@material-ui/icons'
import styles from './styles/JobsStatusPage.module.scss'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'


const AutomationEmailForward = (prop) => {
    var query = window.sessionStorage.getItem('fwd_admin_automation_mail_query')
    const queryParams = new URLSearchParams(window.location.search)
    const urlStartDate = sessionStorage.getItem("fwd_admin_automation_mail_start_date")
    const urlEndDate = sessionStorage.getItem("fwd_admin_automation_mail_end_date")
    const jobStatus_job = sessionStorage.getItem("fwd_admin_automation_mail_job")
    let jobStatus_ac_manager = sessionStorage.getItem("fwd_admin_automation_mail_ac_manager")
    var isSubmission = sessionStorage.getItem('fwd_admin_automation_mail_submission')
    const jobId = JSON.parse(jobStatus_job)?.value
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [incomingMails, setIncomingMails] = useState([])
    const [open,setOpen] = useState(false)
    const [jobs,setJobs] = useState([{value: 'all', label: 'All'},...prop.jobs_list])
    const [account_managers,setAccountManagers] = useState([{value: 'all', label: 'All'},...prop.account_managers])
    const [selectedTeamLead,setSelectedTeamLead] = useState(jobStatus_ac_manager && jobStatus_ac_manager != "null" ? JSON.parse(jobStatus_ac_manager).value : '')
    const [selectedJob,setSelectedJob] = useState(jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job).value : jobId ? jobId : '')
    const [selectedSubmission, setSelectedSubmission] = useState({value: isSubmission || 'all', label: (isSubmission) &&  (isSubmission == 'all' ? 'All' : ((isSubmission == 'true' ? 'Yes' : 'No') ) || 'All')})
    const [selectedIsSubmission, setSelectedIsSubmission]  = useState(isSubmission || 'all')
    const [rsumeText,setRsumeText] = useState('')
    const [perPage, setPerPage] = useState(prop.perPage)
    const [totalMails,setTotalMails] = useState()
    const [currentCounts,setCurrentCounts] = useState()
    const [loading, setLoading] = useState(true)
    const [startRecord, setStartRecord] = useState(0)
    const [submissionId, setSubmissionId] = useState('')
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const [defaultAccountManager, setDefaultAccountManager] = useState(jobStatus_ac_manager && jobStatus_ac_manager != "null" ? JSON.parse(jobStatus_ac_manager) : [])
    const [selected,setSelected] = useState((jobId)? prop.jobs_list.filter((option) => option.value == jobId) : jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job) : {value: 'all', label: 'All'})
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
    const stages = {lead: 'Lead', recruitor_screen: 'Recruiter Screened', applicant: 'Applicant',submitted: 'Submitted', submitted: 'Submitted', first_interview: 'First Interview', second_interview: 'Second Interview', onhold: 'onhold', offer: 'Offer', reject: 'Archived', hired: 'Hired'}
    const handleDate = (date) => {
        setDateRange(date)
    }
   
    const filterOptionsWishlist = [
        { value: 'all', label: 'All'},
        { value: 'yes', label: 'Yes'}
    ]
    const fetchData = () => {
        setLoading(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("fwd_admin_automation_mail_start_date", start_date)
        sessionStorage.setItem("fwd_admin_automation_mail_end_date", end_date)
        const url = ` /admin/automation_email_forward.json?page=${activePage}&account_manager_id=${selectedTeamLead}&start_date=${start_date}&end_date=${end_date}&search=${searchTerm}&job=${selectedJob}&sortField=${sortField}&sortDirection=${sortDirection}&selectedSubmission=${selectedIsSubmission}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (response) => {
                setTotalCount(response.data.meta.total_mails)
                setPerPage(response.data.per_page)
                let sr_no = (activePage - 1) * response.data.per_page
                setIncomingMails(
                    response.data.mails.map((mailData,key) => (sr_no = sr_no + 1,{
                        id: sr_no,
                        name: mailData.first_name ? mailData.first_name +' '+ mailData.last_name : '-',
                        email: response.data?.person_list[mailData.person_id]?.email_address,
                        to_email: mailData.to_email,
                        date:  (mailData.created_at != null) ?  moment(mailData.created_at).format('MMM DD, YYYY') : '',
                        subject: mailData.subject,
                        attachment_url: response.data?.person_list[mailData.person_id]?.attachment_url,
                        phone_number: response.data?.person_list[mailData.person_id]?.phone_number,
                        job_name: response.data?.job_list[mailData.job_id]?.name,
                        am_name: mailData.am_name,
                        submission_type: (stages[mailData.submission_type]),
                        action: resumePreview(response.data?.person_list[mailData.person_id],mailData),
                    }))
                )
                setPageCount(response.data.meta.total_pages)
                setCurrentCounts(response.data.meta.current_count)
                setTotalMails(response.data.meta.total_mails)
                setStartRecord((activePage - 1) * response.data.per_page) 
                setLoading(false)
                setPageCount(response.data.total_pages)
            },
        })
    }

    const onSelectedTeamLead = (event) => {
        setSelectedTeamLead(event.value)
        sessionStorage.setItem("fwd_admin_automation_mail_ac_manager", JSON.stringify(event))

    }

    useEffect(() => {
        fetchData()
        feather.replace()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,dateRange,sortDirection,sortField,selectedJob,selectedTeamLead,selectedIsSubmission])

    const resumeModalCall = (resume) =>{
        setOpen(true)
        let formattedText = resume.split('\n').map((line, index) => (
            <div key={index}>
              {line.trim()}
            </div>
          ));
        setRsumeText(formattedText)
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('fwd_admin_automation_mail_query',value)
    }

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver',backgroundColor: '#F6F7FC',minWidth:'14em',maxWidth:'14em',minHeight:'30px',marginRight: '10px',height: '42px' })
    }

    const resumePreview = (o,email_data) =>{
        return (<div >
                   <img src={ResumeIcon} onClick={(e)=>resumeModalCall(email_data.formated_body)} className='align-items-center' style={{cursor:'pointer'}} title='Email Body'/>
                   {(o.attachment_url != null && o.attachment_url != 'N/A' && o.attachment_url.trim() != '') ?
                   <a href={(o.attachment_url != null && o.attachment_url != 'N/A' && o.attachment_url.trim() != '') ? o.attachment_url : '#'} target="_blank" className='ml-3' title='Resume'>
                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                        <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                        <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg>
                    </a>
                    : <></>}
                </div>)
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Candidate Name',
            selector: row => row.name,
            sortable: true,
            sortField: 'first_name',
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true
        },
        {
            name: '	Email',
            selector: row => row.email,
            sortable: true,
            sortField: 'email',
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true
        },
        {
            name: 'Job Name',
            selector: row => row.job_name,
            sortable: false,
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true
        },
        {
            name: 'Job Owner',
            selector: row => row.am_name,
            sortable: true,
            maxWidth: '14%',
            minWidth: '14%',
            wrap:true
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            sortField: 'created_at',
            maxWidth: '12%',
            minWidth: '12%',
            wrap:true
        },
        {
            name: 'Current Stage',
            selector: row => row.submission_type,
            sortable: false,
            maxWidth: '14%',
            minWidth: '14%',
            wrap:true
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            maxWidth: '10%',
            minWidth: '10%',
            wrap:true
        }
    ]

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const  handleSelectedIsSubmission = (selectedval) => { 
        setActivePage(1)
        setSelectedIsSubmission(selectedval.value)
        sessionStorage.setItem("fwd_admin_automation_mail_submission", selectedval.value)
    }
   
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{ width: '90%'}}>
                    Automation Email Forward Report
                </P>
            </div>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '15px' }}
            >
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col xs={12} sm={12} lg={10}  className='d-flex justify-content-end p-0 filter_ui_fix'>
                        <div>
                            <label>
                               Job Owner:
                            </label>
                            <Select
                                defaultValue={defaultAccountManager}
                                options={account_managers}
                                onChange={onSelectedTeamLead}
                                name="am_name"
                                styles={colourStyles}
                            />
                        </div>
                    <div>
                        <label>
                            Job Name:
                        </label>
                        <Select
                            defaultValue={selected}
                            options={jobs}
                            onChange={(event)=>{
                                setSelectedJob(event.value)
                                setActivePage(1)
                                sessionStorage.setItem("fwd_admin_automation_mail_job", JSON.stringify(event))
                            }}
                            name="job_name"
                            styles={colourStyles}
                        />
                    </div>
                    <div>
                        <label>Is Client Submission: </label>
                        <Select
                            defaultValue={selectedSubmission}
                            options={filterOptionsWishlist} 
                            onChange={handleSelectedIsSubmission} 
                            placeholder={'Is Submission'} 
                            styles={colourStyles}
                        />
                    </div>
                    <div>
                        <label>
                            Select Date Range:
                        </label><br/>
                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                    <div style={{marginTop: '40px'}}>
                        <SearchBar
                            placeholder="Search Candidate Name"
                            value={searchTerm}
                            setValue={setSearchTerm}
                            onChange={(e) => onChangeSearchTerm(e.target.value)}
                            onEnterPressed={() => {
                                activePage === 1 ? fetchData() : setActivePage(1)
                                !searchTerm && window.sessionStorage.setItem('fwd_admin_automation_mail_query','')
                            }}
                            onClick={(e) => setSearchTerm('')}
                            style={{height:'40px'}}
                        />
                    </div>
                </Col>
            </div>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={incomingMails}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                responsive={true}
                onChangePage={page => setActivePage(page)}
            />

            <Modal
                show={open}
                onHide={()=>setOpen(!open)}
                size="lg"
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>Email Body</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{borderTop: '1px solid #dee2e6'}}>
                    
                {/* <div dangerouslySetInnerHTML={{ __html: rsumeText }} />  */}
                <div>{rsumeText}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setOpen(!open)}>
                        Close
                    </Button>
                </Modal.Footer> 
            </Modal>


        </Card>
    )

}

export default AutomationEmailForward