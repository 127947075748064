import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Nav from 'react-bootstrap/Nav'
import styles from './styles/TalentDashboard.module.scss'
import '../JobDetailPage/styles/JobDetailPage.scss'
import UploadResumeSection from '../../common/UploadResumeSection/UploadResumeSection'
import UploadResumeModal from '../../common/UploadResumeModal/UploadResumeModal'
import UploadIcon from '../../../../assets/images/talent_page_assets/upload-icon-v4.png'

import TalentTechGroupModal from '../../common/JobModal/TalentTechGroupModal'
import Modal from 'react-bootstrap/Modal'

const TalentDashboard = ({
    currentUser,
    hasResume,
    webSocketsUrl,
    resume,
    resume_service_url,
    applied_jobs_count,
    match_score_status,
    has_tech_group
}) => {
    const [jobFilterText, setJobFilterText] = useState('')
    const [activePage, setActivePage] = useState(0)
    const [errorFetchingJob, setErrorFetchingJob] = useState(null)
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [jobs, setJobs] = useState([])
    const [totalJobsCount, setTotalJobsCount] = useState(0)
    const [addAddTechList, setAddTechList] = useState(has_tech_group ? false : true)
    
    return (
        <div className="bg-white">
            <Modal
                onHide={() => setAddTechList(false)}
                className="wishlist-job-modal"
                show={addAddTechList}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
              >
                <TalentTechGroupModal 
                user_id={currentUser.id}
                onClose={() => setAddTechList(false)}
                />
            </Modal>
            <UploadResumeSection
                webSocketsUrl={webSocketsUrl}
                uploadedResume={resume}
                currentUser={currentUser}
                hasResume={hasResume}
                resume={resume}
                jobs={jobs}
                setJobs={setJobs}
                totalJobsCount={totalJobsCount}
                setTotalJobsCount={setTotalJobsCount}
                jobFilterText={jobFilterText}
                setJobFilterText={setJobFilterText}
                activePage={activePage}
                setActivePage={setActivePage}
                errorFetchingJob={errorFetchingJob}
                setErrorFetchingJob={setErrorFetchingJob}
                loading={loading}
                setLoading={setLoading}
                pageCount={pageCount}
                setPageCount={setPageCount}
                resumeServiceUrl={resume_service_url}
                matchScoreStatus={match_score_status}
                render={({
                    uploadedResume,
                    setUploadedResume,
                    handleFiles,
                    showUploadResumeModal,
                    setShowUploadResumeModal,
                }) => {
                    return (
                        <>
                            <UploadApplyControl
                                uploadedResume={uploadedResume}
                                setUploadedResume={setUploadedResume}
                                handleFiles={handleFiles}
                                showUploadResumeModal={showUploadResumeModal}
                                setShowUploadResumeModal={
                                    setShowUploadResumeModal
                                }
                                applied_jobs_count={applied_jobs_count}
                                resumeServiceUrl={resume_service_url}
                            />
                        </>
                    )
                }}
            />
        </div>
    )
}

const UploadApplyControl = ({
    uploadedResume,
    setUploadedResume,
    handleFiles,
    showUploadResumeModal,
    setShowUploadResumeModal,
    applied_jobs_count,
    resumeServiceUrl
}) => {
    const appliedJobsCount = applied_jobs_count ? applied_jobs_count : 0
    return (
        <div className={styles.container + ' position-relative'}>
            <Row className={styles.newRow}>
                <Col md="6" className={`${styles.column} text-center`}>
                    <p className={styles.title}>Resume</p>
                    {uploadedResume ? (
                        <>
                            <Row>
                                <Col md="12" className=''>
                                    
                                    <Button
                                        className={styles.button}
                                        onClick={() =>
                                            setShowUploadResumeModal(true)
                                        }
                                    >
                                       <Image
                                        src={UploadIcon}
                                        style={{
                                            width: '50px',
                                            height: '33px',
                                        }}
                                    /> Upload New Resume
                                    </Button>
                                    <div
                                        className={`mt-2 ml-2`}
                                    >
                                        <span className={styles.subTitle}>
                                            Uploaded Resume:
                                        </span>{' '}
                                        <a href={resumeServiceUrl}
                                            target="_blank"
                                            className={styles.text}
                                            style={{ marginLeft: '13px' }}
                                        >
                                            {uploadedResume.filename ||
                                                uploadedResume.name ||
                                                ''}
                                        </a>
                                        {/* <span
                                            className={styles.text}
                                            style={{ marginLeft: '14px' }}
                                        >
                                            <a
                                                onClick={() =>
                                                    setUploadedResume(null)
                                                }
                                                style={{
                                                    textDecoration: 'none',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                x
                                            </a>
                                        </span> */}
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col md="12" className=''>
                                    <Button
                                        className={styles.button}
                                        onClick={() =>
                                            setShowUploadResumeModal(true)
                                        }
                                    >
                                        <Image
                                        src={UploadIcon}
                                        style={{
                                            width: '50px',
                                            height: '33px',
                                        }}
                                        />
                                        Upload Your Resume
                                    </Button>
                                    <p className={styles.subTitle + ' mt-2 mb-0'}>
                                        To begin matching to jobs
                                    </p>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>

                <Col md="6" className={styles.columnPadding}>
                    <p className={styles.title}>Applications</p>
                    <span className={styles.subTitle}>You’ve applied to:</span>
                    <div style={{ marginTop: '10px' }}>
                        <Nav.Link href="/submissions" >
                            <span className={styles.text}>
                                    {appliedJobsCount > 1 ? `${appliedJobsCount} Jobs` : `${appliedJobsCount} Job`}
                            </span>
                        </Nav.Link>
                    </div>
                </Col>
            </Row>
            {showUploadResumeModal && (
                <div className={styles.uploadResumeModal}>
                    <UploadResumeModal
                        uploadedResume={uploadedResume}
                        handleFiles={handleFiles}
                        setShowUploadResumeModal={setShowUploadResumeModal}
                    />
                </div>
            )}
        </div>
    )
}

export default TalentDashboard
