import React, { useState, useEffect } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import axios from 'axios'
import feather from 'feather-icons'

import styles from './styles/EmployerDashboard.module.scss'
import Paginator from '../../common/Paginator/Paginator'
import Header from './Header/Header'
import JobsTable from './Table/JobsTable'
import NoJobImage from '../../../../assets/images/no-job.png'
import Image from 'react-bootstrap/Image'

const RESULTS_PER_PAGE = 25

const EmployerDashboard = ({ organization, currentUser, isProfileComplete, memberOrganization, my_jobs_count, all_jobs_count, closed_jobs_count, job_groups, ctRecruitersList }) => {
    let catchJobStatus = 'active'
    const [jobs, setJobs] = useState([])
    const [totalJobsCount, setTotalJobsCount] = useState(0)
    const [activePage, setActivePage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [errorFetchingJob, setErrorFetchingJob] = useState(null)
    const [loading, setLoading] = useState(false)
    const [jobFilterText, setJobFilterText] = useState('')
    const [showMyJobs, setShowMyJobs] = useState((catchJobStatus === 'active') ? true : false)
    const [showMyClosedJobs, setShowMyClosedJobs] = useState((catchJobStatus === 'closed') ? true : false)
    const [jobStatus, setJobStatus] = useState(catchJobStatus) // jobStatus values = ['all', 'active', 'closed']
    const [reloadData, setReloaddata] = useState(false)
    const [openEmailSequenceModal, setOpenEmailSequenceModal] = useState(false)
    const [myJobsCount, setMyJobsCount] = useState(my_jobs_count)
    const [closedJobsCount, setClosedJobsCount] = useState(closed_jobs_count)
    const [allJobsCount, setAllJobsCount] = useState(all_jobs_count)
    
    useEffect(() => {
      setLoading(true)
      if (jobFilterText.length > 0) {
        handleSearch()
      }
      else {
        const user_id = jobStatus == 'all' || jobStatus == 'closed' ? '' : currentUser.id
        window.sessionStorage.setItem("catchJobStatus", jobStatus);
        axios
          .get(
            `/jobs.json?page=${activePage + 1
            }&status=${jobStatus}&organization_id=${organization?.id}&user_id=${user_id}`
          )
          .then((res) => {
            setJobs(res.data.jobs)
            setTotalJobsCount(res.data.meta.jobsCount)
            setLoading(false)
            // setReloaddata(false)
            setTotalPages(res.data.meta.total_pages)
          })
          .catch((err) => {
            setErrorFetchingJob(err.message)
            setLoading(false)
            // setReloaddata(false)
          })
      }
    }, [activePage, showMyJobs, showMyClosedJobs, reloadData, jobStatus])

    useEffect(() => {
        feather.replace()
    })
    
    const handleSearch = async () => {
        const url = '/jobs.json'
        setLoading(true)
        try {
            const user_id = jobStatus == 'all' || jobStatus == 'closed' ? '' : currentUser.id
            const response = await axios.get(
                `${url}?page=${activePage + 1}&status=${jobStatus}&organization_id=${organization?.id}&user_id=${user_id}&query=${jobFilterText}`
            )
            const jobsData = response.data
            const jobs = jobsData.jobs
            const totalCount = jobsData.meta.total_count
            const totalPages = jobsData.meta.total_pages
            setTotalJobsCount(totalCount)
            setErrorFetchingJob(null)
            setJobs(jobs)
            if(jobStatus == 'all'){
                setAllJobsCount(totalCount);
            } else if(jobStatus == 'closed'){
                setClosedJobsCount(totalCount);
            } else if(jobStatus == 'active'){
                setMyJobsCount(totalCount);
            }
            setTotalPages(totalPages)
        } catch (e) {
            console.error(e.message)
            setErrorFetchingJob(e.message)
        }
        setLoading(false)
    }

    const handleMyJobs = () => {
        setActivePage(0)
        setJobs([])
        setShowMyJobs(true)
        setReloaddata(true)
        setJobStatus('active')
    }

    const handleAllJobs = () => {
        setActivePage(0)
        setJobs([])
        setShowMyJobs(false)
        setShowMyClosedJobs(false)
        setReloaddata(true)
        setJobStatus('all')
    }

    const handleClosedJobs = () => {
        setJobs([])
        setShowMyJobs(false)
        setShowMyClosedJobs(false)
        setReloaddata(true)
        setJobStatus('closed')
    }

    return (
        <div className={styles.container}>
            <Header
                handleMyJobs={handleMyJobs}
                handleAllJobs={handleAllJobs}
                handleClosedJobs={handleClosedJobs}
                setShowMyClosedJobs={setShowMyClosedJobs}
                setJobFilterText={setJobFilterText}
                setActivePage={setActivePage}
                handleSearch={handleSearch}
                showMyJobs={showMyJobs}
                showMyClosedJobs={showMyClosedJobs}
                jobFilterText={jobFilterText}
                loading={loading}
                organization={organization}
                currentUser={currentUser}
                setReloaddata={setReloaddata}
                reloadData={reloadData}
                setOpenEmailSequenceModal={setOpenEmailSequenceModal}
                openEmailSequenceModal={openEmailSequenceModal}
                isProfileComplete={isProfileComplete}
                myJobsCount={myJobsCount}
                closedJobsCount={closedJobsCount}
                allJobsCount={allJobsCount}
                jobGroup={job_groups}
                recruiterMember={ctRecruitersList}
            />
            {loading ? (
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : errorFetchingJob ? (
                <Alert
                    variant="danger"
                    onClose={() => setErrorFetchingJob(null)}
                    dismissible
                    className="mt-4 newAlert"
                >
                    {errorFetchingJob}
                </Alert>
            ) : (
                <>
                    {jobs && jobs?.length ? (
                        <>
                            <JobsTable
                                jobs={jobs}
                                currentUser={currentUser}
                                organization={organization}
                                setReloaddata={setReloaddata}
                                memberOrganization={memberOrganization}
                            />
                            <div
                                style={{
                                    marginTop: '32px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {totalPages > 1 && (
                                    <Paginator
                                        pageCount={totalPages}
                                        pageWindowSize={5}
                                        activePage={activePage}
                                        setActivePage={setActivePage}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        // <p className={styles.noJobs}>Sorry! No jobs found</p>
                          <div className={styles.noJobDiv}>
                            <Image
                            src={NoJobImage}
                            style={{
                                height: '205px',
                                width: '300px'
                            }}
                            className='noJobImage'
                            />
                        </div>

                    )}
                </>
            )}
        </div>
    )
}

export default EmployerDashboard
