import React, { useState, useRef, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Resizer from 'react-image-file-resizer'
import axios from 'axios'
import Select from 'react-select'
import stateCities from 'state-cities'
import { Spinner } from 'react-bootstrap'
import { Card, Col } from 'react-bootstrap'
import TextInput from '../../common/TextInput/TextInput'
import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import { capitalizeFirstLetter } from '../../../utils'
import styles from '../SignupPage/styles/Signup.module.scss'
import {Button,H1,Message,P,StyledForm,A,InfoText,Row} from './styles/CreateProfilePage.styled'

const CreateOrganizationTypePage = ({
    formData,
    setFormData,
    submitData,
    loading,
    result,
    setResult,
    selectedRole,
    hidePrevious,
    showInfoPanel
}) => {
    const [roleDescription, setRoleDescription] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [selectColorFirst, setSelectColorFirst] = useState('rgba(0, 0, 0, 0.125)')
    const [selectColorSecond, setSelectColorSecond] = useState('#667eff')
    const [defaultType, setDefaultType] = useState('self-service')
    const [showSlot, setShowSlot] = useState(false)
    
    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            organization: {
                ...prev.organization,
                organization_type: defaultType,
            },
        }))
        fetchLookUp()
    }, [defaultType])

    useEffect(() => {
        setResult({})
        if (isSubmitted) {
            if(showSlot){
                setFormData((prev) => ({
                    ...prev,
                    step: 'BOOK_SLOT',
                }))
            }else{
                submitData()
            }
        }
        setIsSubmitted(false)
    }, [isSubmitted])

    const fetchLookUp = async () => {
        const url = `/signup/contracts?name=org_note&role=${selectedRole}`
        await axios.get(url).then((res) => {
            setRoleDescription(res.data?.content)
        })
    }

    const handleOnSubmit = (values) => {
        setFormData((prev) => ({
            ...prev
        }))
        setIsSubmitted(true)
    }

    const onValueChange = (event) => {
        setType(event.target.value)
    }

    const setType = (value) => {
        if(value == 'contingency'){
            setSelectColorFirst('#667eff')
            setSelectColorSecond('rgba(0, 0, 0, 0.125)')
            setShowSlot(true)
       }else{
            setSelectColorFirst('rgba(0, 0, 0, 0.125)')
            setSelectColorSecond('#667eff')
            setShowSlot(false)
       }
       setDefaultType(value)
    }

    return (
        <>
            <div className={`${styles.signUpForm}`}>
                <MainPanel>
                    <H1>Organization Information</H1>

                    <Formik
                        initialValues={{
                            organization_type: defaultType,
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            handleOnSubmit(values)
                        }}
                    >
                        <StyledForm display="flex" direction="column">
                            <Row jContent="space-between">
                                <Col xs={12} lg={6} >
                                    <Card style={{cursor:'pointer',border: `1.5px solid ${selectColorSecond}`}} onClick={ () => {setType('self-service')}}>
                                        <div className={`${styles.cardOrgTitle}`} >
                                            <h5>Self-Service</h5>  
                                        </div>
                                        <Card.Body style={{minHeight:'320px', maxHeight:'320px', padding:'1.20rem  0px 1.20rem 0px'}}>
                                                <div className='container'>
                                                        <ul></ul>
                                                        <ul>
                                                            <li>Free Job Posting for 2 jobs</li>
                                                            <li>Free ATS usage</li>
                                                            <li>Access to our 1M+ candidates</li>
                                                            <li>Setup your own Outreach </li>
                                                            <li>View candidate contact info</li>
                                                        </ul>
                                                        <div style={{textAlign:'center'}}>
                                                             <input type="radio" value="self-service" name="organization_type" checked={defaultType === "self-service"}  onChange={onValueChange} /> 
                                                        </div>
                                                </div>
                                            </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} lg={6} >
                                    <Card style={{cursor:'pointer',border: `1.5px solid ${selectColorFirst}`}} onClick={ () => {setType('contingency')}}>
                                        <div className={`${styles.cardOrgTitle}`}>
                                            <h5>Contingency</h5>   
                                        </div>
                                        <Card.Body style={{minHeight:'320px', maxHeight:'320px', padding:'1.20rem  0px 1.20rem 0px'}}>
                                                <div className='container'>
                                                        <ul><b>All features of Self-Service</b></ul>
                                                        <ul>
                                                            <li>Get immediate help from our expert recruiters</li>
                                                            <li>Vetted candidates to your mailbox</li>
                                                            <li>Candidate's work authorization check</li>
                                                            <li>Skills Verification</li>
                                                            <li>Our own Outreach</li>
                                                            <li>And more...</li>
                                                        </ul>
                                                        <div style={{textAlign:'center'}}>
                                                             <input type="radio" value="contingency" name="organization_type" checked={defaultType === "contingency"} onChange={onValueChange}/> 
                                                        </div>
                                                </div>
                                            </Card.Body>
                                    </Card>     
                                </Col>
                            </Row>
                         
                            <Row jContent="space-between" className={`${styles.cardOrgBtnDiv}`}>
                                <Col xs={12} lg={6} >
                                    <A
                                        style={{ marginRight: '20px' }}
                                        onClick={() =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                step: 'CREATE_ORGANIZATION',
                                            }))
                                        }
                                    >
                                    {!hidePrevious &&  'Previous'}
                                    </A>
                                </Col>
                                <Col xs={12} lg={6} >
                                    <Button type="submit" disabled={loading} className={`${styles.cardOrgBtn}`}>
                                        {loading ? (
                                            <Spinner
                                                animation="border"
                                                variant="light"
                                            />
                                        ) : (
                                           (showSlot) ? 'Next' : 'Submit'
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                            {result && (
                                <Row jContent="center">
                                    <Message color={result.color}>
                                        {result.message}
                                    </Message>
                                </Row>
                            )}
                        </StyledForm>
                    </Formik>
                </MainPanel>
                { showInfoPanel &&
                    <InfoPanel>
                        <div className={`${styles.infopanelDiv}`}>
                            <div className="d-flex flex-column">
                                <P>Note</P>
                                <div
                                    style={{
                                        border: '1px solid #BFC5E2',
                                        width: '100%',
                                        marginBottom: '30px',
                                    }}
                                ></div>
                                <P
                                    weight={'normal'}
                                    dangerouslySetInnerHTML={{
                                        __html: roleDescription,
                                    }}
                                ></P>
                            </div>
                        </div>
                    </InfoPanel>
                }
            </div>
        </>
    )
}

export default CreateOrganizationTypePage
