import React, { Fragment, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import feather from 'feather-icons';
import AsyncCreatableSelect from 'react-select/async-creatable';

import Expander from '../Expander/Expander';
import FilterGroup_v2 from '../FilterGroup/FilterGroup_v2';
import { colourStyles_v2 } from './styles/FilterJob_v2.styles';
import { getCompany, getIndustry, getLocation, getPosition } from '../../api/filter/filter.activeJob.api';
import FilterBy_v2 from './FilterBy_v2';
import FilterBy_linkedin from './FilterBy_linkedin';

const initialState = [
  { key: 'companyNames', label: 'Company' },
  { key: 'position', label: 'Position' },
  { key: 'locations', label: 'Location' },
  { key: 'industry', label: 'Industry' },
  { key: 'email', label: 'Email Reuired' },
  { key: 'linkedinJob', label: 'Show Only Linkedin Job' }
];
const FilterActiveJob_v2 = ({
  filterStack,
  setStackFilter,
  handleSearch,
  setActivePage = () => {},
  handleReset,
}) => {
  useEffect(() => {
    feather.replace();
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setActivePage(0);
    handleSearch();
  };

  const handleResetFilters = () => {
    if (handleReset && typeof handleReset === 'function') {
      setStackFilter({
          companyNames: [],
          locations:[],
          position:[],
          industry :[],
            });
      handleReset();
    } else {
      resetFilterStack();
    }
  };

  const resetFilterStack = () => {
    window.location.replace(`/search_employer`);
  };

  const handleFilter = (event, key) => {
    setStackFilter({ ...filterStack, [key]: event });
  };

  const handleKeyPress = async (inputValue, callback, key) => {
    let arrayOptions = [];

    if (key === 'companyNames') {
      arrayOptions = await getCompany(inputValue);
    }
    if (key === 'position') {
      arrayOptions = await getPosition(inputValue);
    }
    if (key === 'locations') {
      arrayOptions = await getLocation(inputValue);
    }
    if (key === 'industry') {
        arrayOptions = await getIndustry(inputValue);
    }
    callback(arrayOptions);
  };

  const handleCreate = (event, key) => {
    const newItem = { value: event, label: event };
    const cloneObject = filterStack[key];
    cloneObject.push(newItem);
    setStackFilter({ ...filterStack, [key]: [...cloneObject] });
  };

  function activeFilterCount(filters) {
    return Object.keys(filters)
      .map((key) => {
        if (Array.isArray(filterStack[key]))
          return filterStack[key].length > 0 ? 1 : 0;
        else if (typeof filterStack[key] === 'boolean')
          return filterStack[key] ? 1 : 0;
        else return 0;
      })
      .reduce((acc, current) => acc + current, 0);
  }

  return (
    <FilterGroup_v2
      filterCount={activeFilterCount(filterStack)}
      experienceYearsCount={filterStack.experienceYears}
    >
      {initialState.map(({ key, label }) => (

        <Fragment key={key}>
            {key != 'email' && key != 'linkedinJob' && (
              <>
                <h5 className="medium-heading mb-15 mt-20">{label}</h5>
              
                <AsyncCreatableSelect
                  isMulti
                  createOptionPosition="first"
                  styles={colourStyles_v2}
                  value={filterStack[key]}
                  onChange={(event) => handleFilter(event, key)}
                  loadOptions={(inputValue, callback) =>
                    handleKeyPress(inputValue, callback, key)
                  }
                  menuPlacement="auto"
                  fmaxMenuHeight={30}
                  onCreateOption={(event) => {
                    handleCreate(event, key);
                  }}
                  menuPortalTarget={document.querySelector('body')}
                />
              </>
            )} 

            {key === 'email' && (
              <>
                <FilterBy_v2 setStackFilter={setStackFilter} filterStack={filterStack}/>
              </>
            )} 
            {key === 'linkedinJob' && (
              <>
                <FilterBy_linkedin setStackFilter={setStackFilter} filterStack={filterStack}/>
              </>
            )}
        </Fragment>

          
      ))}

      {/* <Button className={'filterBtn'} onClick={handleSubmit}>
        Filter
      </Button> */}
      <Button className={' btn btn-default ml-30'} onClick={handleSubmit} style={{ background: '#005999',color: 'white' }}>
        Apply
      </Button>
      <Button className={' btn btn-default ml-30'} onClick={handleResetFilters} style={{ background: '#005999',color: 'white' }}>
        Reset
      </Button>
    </FilterGroup_v2>
  );
};

export default FilterActiveJob_v2;
