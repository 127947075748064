import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: #2b373b;
    padding: 10px 20px;
    position: fixed;
    bottom: 0px;
    z-index: 9999;
    @media only screen and (min-device-width : 320px) and (max-device-width : 767px){
        display:block !important;
    }
`
export const W4text = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) => props.size};
    color: ${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-device-width : 320px) and (max-device-width : 767px){
        display:block !important;
    }
`
export const Button = styled.button`
    background: linear-gradient(
        94.67deg,
        #4c68ff -1.19%,
        #6077f4 53.94%,
        #8185ff 102.59%
    );
    border-radius: 20px;
    padding: ${(props) => props.tb} ${(props) => props.lr};
    width: fit-content;
    display: flex;
    align-items: center;
    color: #ffffff;
`

export const Row = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction};
    flex-wrap: wrap;
    width: 100%;
    justify-content: ${(props) => (props.jContent ? props.jContent : 'unset')};
    
`
