import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import styles from '../styles/SearchResultsBlock.module.scss'
import JobPositionCard from '../../../common/JobPositionCard/JobPositionCard'
import Paginator from '../../../common/Paginator/Paginator'
import Util from '../../../../utils/util'
import JobSearchBar from '../../../common/JobSearchBar/JobSearchBar'
import FilterJob from '../../../common/FilterJob/FilterJob'
import Select from 'react-select'
import ResumeDragDrop from '../../../common/ResumeDragDrop/ResumeDragDrop'
import '../styles/ResumeDragDrop.scss'
import HorizontalLoader from '../../../common/HorizontalLoader/HorizontalLoader'
import { Form } from 'react-bootstrap'


const SearchResultsBlock = (props) => {
    const {
        jobs,
        loading,
        activePage,
        totalJobsCount,
        pageCount,
        submitJobSearch,
        handleInputChange,
        inputValue,
        setActivePage,
        setErrorSearchingJobs,
        errorSearchingJobs,
        filterStack,
        setStackFilter,
        handleSearch,
        experienceYears,
        setExperienceYears,
        user,
        perPage,
        filter_status,
        organization_type_list,
        setSelectOrganizationType,
        selectOrganizationType,
        setJobFilter,
        jobFilter,
        validationErrors,
        resumeFile,
        handleOnRemoveResumes,
        handleResumeFiles,
        handleSubmitResume,
        generatingScoreLoading,
        currentUser,
        handleClearResumes,
        buttonDisabled,
        uploadResumeFlage,
        setShow,
        resumeFileName,
        removeResume,
        personId,
        onChangeBulkJobIds,
        job_groups,
        selectJobGroup,
        setSelectJobGroup,
        setSelectOriginalJob,
        selectOriginalJob
    } = props
    const displayNumberOfResults = () => {
        return Util.displayNumberOfResults(
            totalJobsCount,
            pageCount,
            activePage,
            perPage
        )
    }
    const displayText = `Displaying ${displayNumberOfResults()} results`
    console.log('here');
    return (
        <div>
            <div className={`${styles.jobSearchHeading}`}>
                <div className="container text-center">
                    <h2>Search for jobs</h2>
                    <p>
                        Browse organizations jobs managed by cardinal talent
                    </p>
                </div>
                <JobSearchBar
                    placeholder="Search by Job title, keyword, or company"
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onEnterPressed={(e) => {
                        setActivePage(0)
                        submitJobSearch(e)
                    }}
                    uploadResumeFlage={uploadResumeFlage}
                    setShow={setShow}

                />
            </div>
            {resumeFileName && (<div className='text-primary text-center mt-4 font-weight-bolder'>Attached File: {resumeFileName} <span style={{cursor: 'pointer'}} onClick={()=>removeResume()}><i className='text-danger' data-feather='x'/></span></div>)}
            <div className={styles.mainSearchblock} fluid>
                <div className={styles.containers}>
                    {window.location.pathname == "/job/cardinal_jobs" ?
                        "" :
                        <div className={`${styles.filterJob} `}>
                            {!currentUser &&
                                <ResumeDragDrop
                                    title={'resume'}
                                    buttonlabel={'Get Matched Jobs'}
                                    files={resumeFile}
                                    handleOnRemoveFiles={handleOnRemoveResumes}
                                    handleFiles={handleResumeFiles}
                                    handleOnSubmit={handleSubmitResume}
                                    validationErrors={validationErrors}
                                    handleClearResumes={handleClearResumes}
                                    buttonDisabled={buttonDisabled}
                                />
                            }
                            <FilterJob
                                filterStack={filterStack}
                                setStackFilter={setStackFilter}
                                handleSearch={handleSearch}
                                setActivePage={setActivePage}
                                experienceYears={experienceYears}
                                setExperienceYears={setExperienceYears}
                            />
                        </div>
                    }
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center align-content-center"
                            style={{ width: '100%' }}
                        >
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    ) : errorSearchingJobs ? (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorSearchingJobs(null)}
                            dismissible
                        >
                            {errorSearchingJobs}
                        </Alert>
                    ) : generatingScoreLoading ? (
                        <div className="w-100 flex-column justify-content-center align-items-center">
                            <p className={styles.alertText}>
                                Please give us a moment as our AI generates your
                                matches!
                            </p>
                            <HorizontalLoader />
                        </div>
                    ) : (
                        <Col>
                            {totalJobsCount && (
                                <p className={styles.recommendedJobsTitle}>
                                    <span className="ml-3" style={{ fontSize: '0.75rem' }}>{displayText}</span>
                                </p>

                            ) || ''}
                            {window.location.pathname == "/job/cardinal_jobs" &&
                                <div className={`${styles.selectJobOriginType}`}>
                                    <Select
                                        options={[{label: 'All', value: 'all'}, {label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                                        onChange={(event) => {
                                            setSelectOriginalJob(event.value)
                                            setActivePage(0)
                                        }
                                        }
                                        name="origin_job"
                                        placeholder='Select Original Job'
                                        value={
                                            [{label: 'All', value: 'all'}, {label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}].filter(option =>
                                                option.value === selectOriginalJob)
                                        }
                                    />
                                </div>
                            }
                            {window.location.pathname == "/job/cardinal_jobs" &&
                                <div className={`${styles.selectOrganizationType}`}>
                                    <Select
                                        options={organization_type_list}
                                        onChange={(event) => {
                                            setSelectOrganizationType(event.value)
                                            setActivePage(0)
                                        }
                                        }
                                        name="org_type"
                                        placeholder='Select Organization Type'
                                        value={
                                            organization_type_list.filter(option =>
                                                option.value === selectOrganizationType)
                                        }
                                    />
                                </div>
                            }
                              
                            <div className={styles.filterStatus} >
                                {window.location.pathname == "/job/cardinal_jobs" &&
                                    <div className={`${styles.selectOrganizationType}`}>
                                        <Select
                                            options={filter_status}
                                            onChange={(event) => {
                                                setJobFilter(event.value)
                                                setActivePage(0)
                                            }}
                                            name="org_type"
                                            placeholder='Select Status Type'
                                            value={
                                                filter_status.filter(option =>
                                                    option.value === jobFilter)
                                            }
                                        />
                                    </div>
                                }
                            </div>
                            <div className={styles.filterStatus} >
                                {window.location.pathname == "/job/cardinal_jobs" &&
                                    <div className={`${styles.selectOrganizationType}`}>
                                          <Form.Group style={{marginRight:'20px'}}>
                                {/* <Form.Label>Job Group</Form.Label> */}
                                <Select
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    placeholder='Select Job Group'
                                    // styles={colourStyles}
                                    menuPlacement="auto"
                                    minMenuHeight={6}
                                    onChange={(event) => {
                                        console.log(event.label)
                                        setSelectJobGroup(event.value)
                                        setActivePage(0)
                                    }}
                                    options={job_groups}
                                    value={job_groups.filter((option => option.value === selectJobGroup))}
                                />
                            </Form.Group>
                                    </div>
                                }
                            </div>
                            <div className={styles.tableColumn}>
                                <div className="search-results-wrap__bottom-row">
                                    {(jobs && jobs.length) ? (
                                        jobs.map((jobItem) => {
                                            return (
                                                <JobPositionCard
                                                    key={jobItem.id}
                                                    job={jobItem}
                                                    matchScore={(jobItem?.score) ? Math.round(jobItem?.score) : 0}
                                                    showSalary={false}
                                                    showLocation={false}
                                                    showCardinalJob={true}
                                                    showMatchScore={(jobItem.score) ? true : false}
                                                    personId={personId}
                                                    onChangeBulkJobIds={onChangeBulkJobIds}
                                                />
                                            )
                                        })
                                    ) : (
                                        <div style={{ textAlign: 'center' }}>
                                            No jobs found
                                        </div>
                                    )}
                                </div>
                            </div>
                            {Array.isArray(jobs) && pageCount > 1 && (
                                <Row className="d-flex justify-content-center">
                                    <Paginator
                                        pageCount={pageCount}
                                        pageWindowSize={5}
                                        activePage={activePage}
                                        setActivePage={setActivePage}
                                    />
                                </Row>
                            )}
                        </Col>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SearchResultsBlock
