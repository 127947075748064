import React, {  useState,useRef } from 'react'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './../styles/communities.scss'
import Modal from "react-bootstrap/Modal"
// import moment from 'moment'
import image2 from '../../../../../assets/v2/imgs/avatar/ava_16.png'
import csvImp from '../../../../../assets/v2/imgs/csv_imp.png'
import SettingLeftPenal from '../SettingLeftPenal'


const InviteSettingPage = ({communityId,currentUser}) => {
  const [pageLink, setPageLink] = useState(window.location.origin+"/communities/"+communityId+"/about")
  const [userEmail, setUserEmail] = useState("")
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [validationError, setValidationError] = useState({})
  const [show, setShow] = useState(false)

  const divStyle = {
    backgroundImage: `url("${csvImp}")`,
  };

  const onHide = () => {
    setShow(false)
  }

  function copyToClipboard(value, button) {
    navigator.clipboard.writeText(value)
      .then(() => {
        if (button) {
          button.textContent = 'Copied';
          setTimeout(() => {
            button.textContent = 'Copy';
          }, 3000); // Change back to 'Copy' after 3 seconds
        }
      })
      .catch((error) => {
        console.error('Unable to copy to clipboard: ', error);
      });
  }

  const handleVerification = async (event) => {

    if (validateForm(userEmail)) {
      const URL = '/communities/send_invite'
      const payload = new FormData()
      payload.append('email_invitation[community_id]', communityId)
      payload.append('email_invitation[email]', userEmail)
      makeRequest(URL, 'post', payload, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
        createResponseMessage: (res) => {
          setBtnDisabled(false)
          return {
            message: res?.msg,
            messageType: res?.messageType
          }
        }
      })
    }
  }

  const validateForm = (e) => {
    let errors = {}
    let formIsValid = true
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!userEmail) {
      formIsValid = false;
      errors["email"] = "Email is required."
    }
    if(!userEmail.match(regex)){
      formIsValid = false;
      errors["email"] = "Email is invalid."
    }
    setValidationError(errors)
    setBtnDisabled(formIsValid)
    return formIsValid;
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    handleVerification(event)
  }

  const [responseFile, setResponseFile] = useState('')
  	const responseFileRef = useRef()

	const responseFileChange = () => {
		if (responseFileRef.current.files.length > 0) {
			setResponseFile(responseFileRef.current.files)
		}
	}

	const handleFileSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateFile(e)){
			let URL = '/communities/import_invite'
			const payload = new FormData()
			payload.append('email_invitation[file]', responseFile[0])
      payload.append('email_invitation[community_id]', communityId)
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
      
      makeRequest(URL,'post', payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.messageType == 'success'){
						onHide()
            setBtnDisabled(false)
				}
			}) 
		}
	}

	const validateFile = (e) => {
		let errors = {}
		let formIsValid = true
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		if (responseFile?.length > 0) {
			const element = responseFile[0];
			let findName = element?.name?.split('.')
			const splitName = findName[findName.length -1]
			if( (splitName !== 'csv')) {
				errors["file"] = "Please attach files in a valid format."
				formIsValid = false;
			}
		}else{
			errors["file"] = "Please select csv file."
			formIsValid = false;
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}
  
  return (
    <>
      <div className="container mt-40 communities-detail">
        <div className="row">
          <SettingLeftPenal communityId={communityId} ></SettingLeftPenal>
          <div className="col-lg-8 m-0 p-20">
            <h4>Invite Members</h4>
            <h4 className="mt-20 mb-10" style={{fontSize: '22px'}}>Share your group link</h4>
            <p>This will take people to your group’s About page where they can purchase or request membership.</p>
            <div className="group_share mt-25">
              <div className="input-group">
                  <input readOnly={true} className='form-control group_link' style={{height: '50px'}} type='text' name='group_link_link' value={pageLink} />
                  <button className="btn btn-default m-0" type="button" onClick={(e) => copyToClipboard(pageLink, e.target)}>Copy</button>
              </div>
            </div>
            <div className="mt-50">
              <p>These invite methods will grant instant access without purchasing or requesting membership.</p>
              <div style={{ textAlign: 'initial' }}>
                <form onSubmit={(e) => { handleSubmit(e) }}>
                  <input type="text" id="email" name="email" value={userEmail} required className="mt-3" style={{ border: '1px solid #ccc',borderRadius: '5px', width: '70%', height: '50px' }} placeholder="Enter Email address" 
                    onChange={event =>{
                      setUserEmail(event.target.value)
                      setValidationError({})
                      }}
                    />
                  <button type="submit" className="btn btn-default t m-0 ml-5 mb-1" disabled={btnDisabled} style={{ backgroundColor: '#2e6ef5', height: '50px' }}>SEND</button>
                </form>
                <label className='error'>
                  {validationError && validationError.email}        
                </label>
              </div>
            </div>
            <div className="import_invite mt-50">
              <div>
                <img src={csvImp} alt="" className="avatar" />    
                <div className="mr-50">
                  <label>Import .CSV file</label>
                  <p style={{ fontSize: '15px'}}>Invite members in bulk by uploading a .CSV file of email addresses.</p>
                </div>
                <button onClick={() => { setShow(true) }} className="btn btn-default mt-5" style={{ backgroundColor: '#2e6ef5', height: '50px' }}>IMPORT</button>
              </div>
            </div>
            <div className="font-weight-bold mt-50">
              <a href={'/communities/'+communityId+'/members/invited'} target="_blank" >See Pending Invites</a>
            </div>
          </div>
          <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className='modal-entry-form'
          >
            <Modal.Header closeButton className='mt-2 mb-2'>
              <Modal.Title id="contained-modal-title-vcenter" >
                Import .CSV file
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container className="p-20">
                <form onSubmit={(e) => { handleFileSubmit(e) }} style={{ width: "100%" }}>
                  <Row className="w-100">
                    <label className="import_form_label">Step 1. Upload .CSV file</label>
                    <p className="mb-10">Upload a .CSV file of email addresses you want to invite. You can <a href={'https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/example.csv'} >use this template.</a></p>
                    <Col xs={12} sm={12} lg={12}>
                      {/* <span>Upload File (csv) </span> */}
                      <input ref={responseFileRef} type='file' style={{ padding: '5px' }} onChange={responseFileChange} className='form-control w-100 mt-10' name='import_file' id="import_file_url" />
                      <small className='error'>
                        {validationError && validationError.file}
                      </small>
                    </Col>
                  </Row>
                  <Row className="w-100 modal-entry-form-row" >
                    <label className="import_form_label mt-20">Step 2. Send invites</label>
                    <p className="mb-10">By clicking “Send Invites”, we'll send an email with a unique invite link to each member so they can join your group instantly without going through the membership approval process.</p>
                    <Col xs={12} sm={12} lg={12}>
                      <button type='submit' disabled={btnDisabled} className='btn btn-default btn-radius'>Send invites</button>
                    </Col>
                  </Row>
                </form>
              </Container>
            </Modal.Body>
          </Modal>
        </div>
      </div>

    </>
  );
}
export default InviteSettingPage