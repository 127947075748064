import React, { useEffect, useState } from 'react'
import Card from '../Admin/shared/Card'
import P from '../Admin/shared/P'
import { Row,Col } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from './styles/AnalyticPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Table from '../Admin/shared/Table'
import { capitalize } from '../../../utils'
import Paginator from '../../common/Paginator/Paginator'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AnalyticPage.styled'

const CrossSubmissionDetailsAnalyticPage = (prop) => {
  const {currentUser} = prop
  const queryParams = new URLSearchParams(window.location.search)
  const urlOrganizationID = queryParams.get("organization_id")
  const urlJobID = queryParams.get("job_id")
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  const [activePage, setActivePage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobStatus, setJobStatus] = useState([])
  const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
  const handleDate = (date) => {
      setDateRange(date)
  }
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [loading, setLoading] = useState(true)
  let tableHeaders = [
      { label: 'Candidate Name', key: 'personName' },
      { label: 'Organization Name', key: 'organization' },
      { label: 'Job Name', key: 'jobName' },
      { label: 'Submitted On', key: 'recruitor_screen' },
      { label: 'Sent to client', key: 'submitted' },
      { label: 'First Interview', key: 'firstInterview' },
      { label: 'Second Interview', key: 'secondInterview' },
      { label: 'Offers', key: 'offers' },
      { label: 'Hired', key: 'hired' },
      { label: 'Archived', key: 'archived' },
    ]
  let tableColumn = [
      { name: 'No.',field: 'id',editable: false,type: 'text' },
      { name: 'Candidate Name',field: 'personName',editable: false,type: 'text' },
      { name: 'Organization Name',field: 'organization',editable: false,type: 'text' },
      { name: 'Job name',field: 'jobName',editable: false,type: 'text'},
      { name: 'Submitted On',field: 'recruitor_screen',editable: false,type: 'text'},
      { name: 'Sent to client',field: 'submitted',editable: false,type: 'text',},
      { name: 'First Interview',field: 'firstInterview',editable: false,type: 'text'},
      { name: 'Second Interview',field: 'secondInterview',editable: false,type: 'text'},
      { name: 'Offers',field: 'offers',editable: false,type: 'text'},
      { name: 'Hired',field: 'hired',editable: false,type: 'text'},
      { name: 'Archived',field: 'archived',editable: false,type: 'text'},
    ]

  const fetchData = async () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/analytic/cross_submission_details_analytic.json'
    const response = await makeRequest(
      `${url}?page=${activePage + 1}&start_date=${start_date}&end_date=${end_date}&organization=${urlOrganizationID}&job=${urlJobID}`,
      'get',
      {}
    )
    setJobStatus(response?.data?.data.map((data,key)=> ({
      id: key,
      personName: data?.person_name && capitalize(data?.person_name),
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organizations_name && capitalize(data?.organizations_name),
      submitted: data?.submitted && moment(data?.submitted).format('MMM DD, YYYY'),
      recruitor_screen: data?.recruitor_screened && moment(data?.recruitor_screened).format('MMM DD, YYYY'),
      firstInterview: data?.first_interview && moment(data?.first_interview).format('MMM DD, YYYY'),
      secondInterview: data?.second_interview && moment(data?.second_interview).format('MMM DD, YYYY'),
      offers: data?.offer && moment(data?.offer).format('MMM DD, YYYY'),
      archived: data?.reject && moment(data?.reject).format('MMM DD, YYYY'),
      hired: data?.hired && moment(data?.hired).format('MMM DD, YYYY')
    })))
    setPageCount(response.data.total_pages)
    setTotalCount(response.data.total_count)
    setStartRecord((activePage * response.data.per_page)+1)
    setPerPage(response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setLoading(false)
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/analytic/cross_submission_details_analytic.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&organization=${urlOrganizationID}&job=${urlJobID}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.data.map((data,key)=> ({
      id: key,
      personName: data?.person_name && capitalize(data?.person_name),
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organizations_name && capitalize(data?.organizations_name),
      submitted: data?.submitted && moment(data?.submitted).format('MMM DD, YYYY'),
      recruitor_screen: data?.recruitor_screened && moment(data?.recruitor_screened).format('MMM DD, YYYY'),
      firstInterview: data?.first_interview && moment(data?.first_interview).format('MMM DD, YYYY'),
      secondInterview: data?.second_interview && moment(data?.second_interview).format('MMM DD, YYYY'),
      offers: data?.offer && moment(data?.offer).format('MMM DD, YYYY'),
      archived: data?.reject && moment(data?.reject).format('MMM DD, YYYY'),
      hired: data?.hired && moment(data?.hired).format('MMM DD, YYYY')
    })))
    csvExportRef?.current?.link.click();
  }

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  };

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Cross Submission Candidates
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
          <a href={`/analytic/cross_submissions_analytic`} className={`ml-3 ${styles.exportCSVButton}`}>
              Back
          </a>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"CrossSubmissionCandidates.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 &&
                <DisplayPagination>Displaying  {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Select Date Range
              </label>
              <DateRangePicker
                  handleOnSubmit={handleDate}
                  defaultDate={dateRange}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Table 
        columNames={tableColumn}
        rowValues={jobStatus.map((o)=>({
          ...o,
          created_at: moment(o.created_at).format(
              'MMM DD, YYYY'
          ),
        }))
        }
        activePage={activePage}
        perPage={perPage}
        loading={loading}
      />
      { pageCount > 1 && (
          <div
              className="d-flex justify-content-center"
              style={{ marginTop: 'auto' }}
          >
              <Paginator
                  activePage={activePage}
                  setActivePage={setActivePage}
                  pageCount={pageCount}
                  pageWindowSize={5}
                  showGoToField={false}
              />
          </div>
      )}
    </Card>
  )
}

export default CrossSubmissionDetailsAnalyticPage