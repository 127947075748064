import React from 'react'
import { makeRequest } from '../../components/common/RequestAssist/RequestAssist'

const initialState = {
    ...initialState,
    ...{
        FirstName:'',
        LastName: '',
        email: '',
        location: '',
        phoneNumber: '',
        Designation:'',
    },
}

const reducer = (state, action) => {
    const { type, value, id } = action
    let url = ''
    let formData = {}
    switch (type) {
        case 'save-user':
            const token = document.querySelector(
                'meta[name="csrf-token"]'
            ).content
            formData = new FormData()
            formData.append('authenticity_token', token)

        case 'update-user':
            formData = new FormData()
            formData.append(`user[first_name]`, value?.first_name)
            formData.append(`user[last_name]`, value?.last_name)
            formData.append(`user[email]`, value?.email)
            formData.append(`user[location]`, value?.location)
            formData.append(`user[phone]`, value?.phone_number)
            formData.append(`user[designation]`, value?.designation)
            url = `/agencies/recruiters/${id}.json`
            sendRequest(formData, url, 'put')
            return { ...state, ...value }

        case 'add-user':
            formData = new FormData()
            formData.append(`user[first_name]`, value?.first_name)
            formData.append(`user[last_name]`, value?.last_name)
            formData.append(`user[email]`, value?.email)
            formData.append(`user[location]`, value?.location)
            formData.append(`user[phone]`, value?.phone_number)
            formData.append(`user[designation]`, value?.designation)
            url = `/agencies/recruiters.json`
            sendRequest(formData, url, 'post')
            return { ...state, ...value }

        case 'remove-user':
            url = `url/${id}`
            sendRequest({}, url, 'delete')
            return { ...state }
        default:
            return state
    }
}

async function sendRequest(formData, url, method) {
    const messsage =
        method == 'put' ? 'Update' : method == 'post' ? 'Save' : 'Remove'
    await makeRequest(url, method, formData, {
        contentType: 'multipart/form-data',
        loadingMessage: 'Submitting...',
        createResponseMessage: (resp) => {
            if(resp.messageType == 'success'){
                window.location.href ='/agencies/recruiters'
            }
            return {
                message:  `${resp?.message || resp?.error_message || resp?.msg}` ,
                messageType: resp?.messageType,
                loading: false,
                autoClose: true,
            }
        },
        createSuccessMessage: () => `${messsage} successful`,
        createErrorMessage: (e) => {
            return e.response.data.msg
        },
        onSuccess: () => {
        },
    })
}

// context for agency Recruiter page
const AgencyRecruiterContext = React.createContext(null)

export { initialState, reducer, AgencyRecruiterContext }
