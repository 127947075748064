import React, { useReducer, useState,useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { H1, StyledForm, Button } from './styles/UserManagementEditPage.styled'
import {Label} from '../CreateProfilePage/styles/CreateProfilePage.styled'
import TextInput from './shared/TextInput'
import Select from './shared/Select'
import SelectRect from 'react-select'
import { Row, Col } from 'react-bootstrap'
import { reducer, UserProfileContext } from '../../../stores/Admin/UserProfileStore'
import CreatableSelect from 'react-select/creatable'
import PhoneInput from '../../common/PhoneInput/PhoneInput'
import { normalizeInput } from '../../../utils'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'

const CreateSalesUserPage = ({ user, people }) => {
    const [selectRole,setSelectRole] = useState('sales')
    const [phoneNumberValue, setPhoneNumberValue] = useState(normalizeInput(user.phone_number,"") || '')
    const [selectedLocation, setSelectedLocation] = useState(user.location || "")
    const [defaultLocation, setdefaultLocation]  = useState(((user.location === 'null')) ? {}  : [{value: user?.location, label: user?.location}])
    const [locationOptions, setLocationOptions] = useState([])
    const [appendLinkedinValue, setAppendLinkedinValue] = useState()
    const [showSuccessConfirmation, setShowSuccessConfirmation] = useState(false)
    const [showSweetAlertMessage,setShowSweetAlertMessage]=useState()
    const [emailId, setEmail] = useState()
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            width: '100%',
            height: '43px',
            marginTop: '1.5px',
            backgroundColor: '#f5f7ff',
            border: 'none',
            zIndex: '15',
        }),
    }
    const { email, role, firstName, lastName, linkedinProfile, phoneNumber, emailConfirmed, userApproved, location} = user

    let initialState = {
        ...initialState,
        user,
        email,
        role,
        firstName,
        lastName,
        linkedinProfile,
        phoneNumber,
        emailConfirmed,
        userApproved,
        location
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const saveUser = async (newUser) => {
        const formData = new FormData()
        for (var key in newUser) {
            formData.append(`person[${key}]`, newUser[key])
        }
        setEmail(newUser['email'])
        const url = `/admin/users/save_sales`
        await makeRequest(url, 'post', formData, {
            contentType: 'multipart/form-data',
            loadingMessage: 'Submitting...',
            createResponseMessage: () => {
            //   return {
            //     message: 'Update Successful',
            //     messageType: 'success',
            //     loading: false,
            //     autoClose: true,
            // }
            },
            onSuccess: (response) => {
                // window.location.reload()
                // window.location.href = '/admin/users'
                if(response?.data?.success){
                    setShowSuccessConfirmation(true)
                    setShowSweetAlertMessage(response?.data?.password)
                }
            }
        })
    }
    const onRoleChange = (event) => {
        setSelectRole(event.target.value)
    }

    const locationChange = (e) => {
        setSelectedLocation(e.value)
    }

    const handleChange = ({ target: { value } }) => {
        setPhoneNumberValue(normalizeInput(value, ""));
    }

    const fetchRequired = async () => {
        const url1 = `/filter_candidate_on_location`
        const formData = new FormData()
        formData.append('filter_word', '')
        const response = await axios
            .post(url1, formData)
            .then((res) => res.data)
            .catch((error) => console.log(error))
        const locationArray = response.filter.map((res) => ({
            value: `${res.city}, ${res.state}`,
            label: `${res.city}, ${res.state}`,
        }))
        setLocationOptions([...locationArray])
    }

    const handleClose = () => {
        setShowSuccessConfirmation(false)
        window.location.href = '/admin/users'
    }
    
    useEffect(() => {
        fetchRequired()
    }, [])
    
    return (
        // <UserProfileContext.Provider value={ state, dispatch }>
        <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
            {showSuccessConfirmation &&
                <SweetAlert
                    success
                    title={'Sales User is successfully created.'}
                    onConfirm={()=>handleClose(true)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                >
                  <p>Email : {emailId}</p>
                  <p>Password : {showSweetAlertMessage}</p>  
                </SweetAlert>
            }
            <H1>Create Sales User</H1>
            <Formik
                initialValues={{
                    email: user.email || '',
                    role: user.role || '',
                    firstName: user.first_name || '',
                    lastName: user.last_name || '',
                    linkedinProfile: (user.linkedin_profile_url && user.linkedin_profile_url != undefined)? user.linkedin_profile_url : '',
                    phoneNumber: user.phone_number || '',
                    emailConfirmed: user.email_confirmed || '',
                    userApproved: user.user_approved || ',',
                    location: selectedLocation || '',
                    leads_limit: 0,
                }}
                validationSchema={Yup.object({
                    firstName: Yup.string()
                        .required('First Name is required')
                        .test(
                            'first name alphabets only',
                            'First Name can only contain alphabet characters and one space in between if there are two words',
                            function (value) {
                                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                                return regex.test(value)
                            }
                        ),
                    lastName: Yup.string()
                        .required('Last Name is required')
                        .test(
                            'last name alphabets only',
                            'Last Name can only contain alphabet characters and one space in between if there are two words',
                            function (value) {
                                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                                return regex.test(value)
                            }
                        ),
                    email: Yup.string()
                        .email()
                        .required('Email is required')
                        .test(
                            'email-unique',
                            'This email is already in use',
                            async function (value) {
                                // check if user exists with email
                                // call backend with email and see if it returns user
                                const res = await axios.get(
                                    `/users/exists?email=${encodeURIComponent(value)}`
                                )
                                return !res.data.user_exists
                            }
                        ),
                    leads_limit: Yup.number()
                        .required('Leads Limit is required')
                        .test(
                            'leads-limit',
                            'The Leads Limit must be greater than 0.',
                            function (value) {
                                if (value > 0) {
                                    return true
                                }else{
                                    return false
                                }
                            }
                        ),
                    linkedinProfile: Yup.string()
                        .typeError('Please add linkedin url only')
                        .test(
                            'linkedin only',
                            'Please add linkedin url only',
                            function (value) {
                                if(selectRole == "talent"){
                                    let linkedin_url = value
                                    let checkDomainExist = linkedin_url?.indexOf("linkedin.com/")
                                    let splitValue = linkedin_url?.split('linkedin.com/')
                                    if(checkDomainExist == 0){
                                        if(splitValue[1] !== ''){
                                            setAppendLinkedinValue('https://www.'+ linkedin_url) 
                                        }
                                    }else if(checkDomainExist > 0){
                                        if(splitValue[0]?.includes('https://') || splitValue[0]?.includes('http://')){
                                            setAppendLinkedinValue(linkedin_url)     
                                        }else{
                                            setAppendLinkedinValue('https://'+linkedin_url) 
                                        } 
                                    }
                                    if(appendLinkedinValue == undefined && value != undefined && value != ''){
                                        return true    
                                    }
                                    if(appendLinkedinValue == -1 || appendLinkedinValue == undefined){
                                        return false
                                    }else if(appendLinkedinValue == 0){
                                        let splitValue = linkedin_url?.split('linkedin.com/')
                                        if(splitValue[1] === ''){ 
                                            return false
                                        }
                                    }else{
                                        let splitValue = linkedin_url?.split('linkedin.com/')
                                        if(splitValue[0] == ''){
                                            return false
                                        }
                                    }
                                    return true
                                }else{
                                    return true
                                }
                            }
                        )
                })}
               
                onSubmit={(values) => {
                    if((phoneNumberValue).replace('(','').replace(')','').replace('-','').replace(' ','').trim() == '' || phoneNumberValue.length == 14){
                        saveUser({
                            first_name: values.firstName,
                            last_name: values.lastName,
                            linkedin_profile_url: (appendLinkedinValue != undefined) ? appendLinkedinValue : '' ,
                            phone_number: (phoneNumberValue).replace('(','').replace(')','').replace('-','').replace(' ','').trim(),
                            email: values.email,
                            role: selectRole,
                            email_confirmed: false,
                            user_approved: true,
                            leads_limit: values.leads_limit,
                            location: ( (selectedLocation === 'null') ? '' : selectedLocation),
                        })
                    }
                }}
            >
                <StyledForm>
                    <Row>
                        <Col lg={2}></Col>
                        <Col xs={12} sm={12} lg={12}>
                            <Row>
                                <Col xs={6} sm={6} lg={6}>
                                    <TextInput
                                        label="First Name*"
                                        name="firstName"
                                        type="text"
                                        id="firstName"
                                        width="100%"
                                    />
                                </Col>
                                <Col xs={6} sm={6} lg={6}>
                                    <TextInput
                                        label="Last Name*"
                                        name="lastName"
                                        type="text"
                                        id="lastName"
                                        width="100%"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} lg={6}>
                                    <TextInput
                                        label="Email*"
                                        name="email"
                                        type="email"
                                        id="email"
                                        width="100%"
                                    />
                                    </Col>
                                    <Col xs={6} sm={6} lg={6}>
                                    <Label>{'Location'}</Label>
                                    <CreatableSelect
                                    name="streetAddress"
                                    id="streetAddress"
                                    placeholder="Location"
                                    isClearable="true"
                                    onChange={(e) =>
                                        locationChange(e)
                                    }
                                    defaultValue={defaultLocation}
                                    options={locationOptions}
                                    styles={colourStyles}
                                />
                                    </Col>
                            </Row>
                            <Row>
                                
                                
                                    <Col xs={6} sm={6} lg={6}>
                                    <Select
                                            label="Role"
                                            name="role"
                                            width="100%"
                                            disabled={true}
                                            value={selectRole}
                                            onChange={onRoleChange}
                                        >
                                        <option value="sales">Sales</option>
                                    </Select>
                                </Col>
                            
                                <Col xs={6} sm={6} lg={6}>
                                    <PhoneInput
                                        label="Phone"
                                        name="phoneNumber"
                                        type="text"
                                        id="phoneNumber"
                                        width="100%"
                                        placeholder="(xxx) xxx-xxxx"
                                        onChange={handleChange}
                                        value={phoneNumberValue}
                                        style={{ marginRight: '10px' }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} lg={6}>
                                    <TextInput
                                        label="Leads Limit*"
                                        name="leads_limit"
                                        type="text"
                                        id="leads_limit"
                                        width="100%"
                                    />
                                </Col>
                            </Row>
                                
                        </Col>
                    </Row>
                    <Row>
                       <Col xs={6} sm={6} lg={6}>
                            <div style={{ marginTop: '18px' }}>
                                <Button type="submit" className="mr-sm-3 mt-2">
                                    Create
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => window.history.back()}
                                    className="ml-sm-3 mt-2"
                                >
                                    Go Back
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </StyledForm>
            </Formik>
        </div>
        // </UserProfileContext.Provider>
    )
}

export default CreateSalesUserPage
