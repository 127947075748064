import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import styles from './style/FilterAutocomplete.module.scss'
import AsyncSelect from 'react-select/async'
import Util from '../../../../utils/util'
import { colourStyles } from './style/Select.style'

const FilterAutocomplete = ({
    getFilterValue,
    testAttr,
    onBlur,
    initialValues,
}) => {
    const [isLoading, setIsLoading] = useState({
        companyName: false,
        company_name: false,
        city: false,
        school: false,
        state: false,
    })

    const handleKeyPress = async (inputValue, callback, testAttr) => {
        let url
        if (testAttr == 'company_names') {
            setIsLoading({ ...isLoading, company_name: true })
            url = '/filter_candidate_on_company'
        } else if (testAttr == 'schools') {
            setIsLoading({ ...isLoading, school: true })
            url = '/filter_candidate_on_education'
        } else if (testAttr == 'city') {
            setIsLoading({ ...isLoading, city: true })
            url = '/filter_candidate_on_location?flag=true'
        } else if (testAttr === 'companyNames') {
            setIsLoading({ ...isLoading, companyName: true })
            url = `/organizations/fetch_organization?search=${inputValue}`
        } else {
            setIsLoading({ ...isLoading, state: true })
            url = '/filter_candidate_on_location'
        }

        if (testAttr === 'companyNames') {
            const response = await axios.get(url)
            const companyNamePreferrenceArray = response.data.organizations.map(
                ({ name }) => {
                    const capName = Util.capitalize(name)
                    return { value: capName, label: capName }
                }
            )
            const duplicateIdArray = initialValues.map(({ id }) => id)
            const uniqueCompanyNameArray = companyNamePreferrenceArray.filter(
                ({ value }) => !duplicateIdArray.includes(value)
            )
            callback(uniqueCompanyNameArray)
            setIsLoading({ ...isLoading, companyName: false })
        } else {
            const formData = new FormData()
            formData.append('filter_word', inputValue)
            let response = await axios
                .post(url, formData)
                .then((res) => res)
                .catch((error) => console.log(error))

            if (testAttr === 'state') {
                const statePreferrenceArray = response.data.filter.map(
                    ({ state }) => {
                        const capState = Util.capitalize(state)
                        return { value: capState, label: capState }
                    }
                )
                const uniqueStateArray = statePreferrenceArray.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        JSON.stringify(t) === JSON.stringify(thing)
                    ))
                )
                callback(uniqueStateArray)
                setIsLoading({ ...isLoading, state: false })
            } else if (testAttr === 'city') {
                const cityPreferrenceArray = response.data.filter.map(
                    ({ state, city }) => {
                        const capState = Util.capitalize(state)
                        const capCity = Util.capitalize(city)
                        return { value: `${capCity}, ${capState}.`, label: `${capCity}, ${capState}.` }
                    }
                )

                const duplicateIdArray = initialValues.map(({ id }) => id)
                const uniqueCityArray = cityPreferrenceArray.filter(
                    ({ value }) => !duplicateIdArray.includes(value)
                )
                callback(uniqueCityArray)
                setIsLoading({ ...isLoading, city: false })
            } else if (testAttr === 'company_names') {
                const companyPreferenceArray = response.data.filter.map(
                    (company) => {
                        const name = Util.capitalize(company.company_name)
                        return { value: name, label: name }
                    }
                )
                const duplicateIdArray = initialValues.map(({ id }) => id)
                const uniqueCompanyArray = companyPreferenceArray.filter(
                    ({ value }) => !duplicateIdArray.includes(value)
                )
                callback(uniqueCompanyArray)
                setIsLoading({ ...isLoading, company_name: false })
            } else {
                const schoolPreferenceArray = response.data.filter.map(
                    ({ name }) => {
                        const capName = Util.capitalize(name)
                        return { value: capName, label: capName }
                    }
                )
                const duplicateIdArray = initialValues.map(({ id }) => id)
                const uniqueSchoolArray = schoolPreferenceArray.filter(
                    ({ value }) => !duplicateIdArray.includes(value)
                )
                callback(uniqueSchoolArray)
                setIsLoading({ ...isLoading, school: false })
            }
        }
    }

    const handleSelected = (selectedOption) => {
        getFilterValue(selectedOption)
    }

    return (
        <div className={styles.wrapper}>
            <AsyncSelect
                isLoading={isLoading[testAttr]}
                isClearable={true}
                styles={colourStyles}
                loadOptions={(inputValue, callback) =>
                    handleKeyPress(inputValue, callback, testAttr)
                }
                onChange={(selectedOption) =>
                    handleSelected(selectedOption, testAttr)
                }
                placeholder={'Search here'}
                onBlur={onBlur}
            />
        </div>
    )
}

FilterAutocomplete.propTypes = {
    getFilterValue: PropTypes.func.isRequired,
}

export default FilterAutocomplete
