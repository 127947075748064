import React, { useState, useEffect } from 'react'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import './../styles/communities.scss'
import Util from '../../../../utils/util'
import Paginator from '../../../common/Paginator/Paginator'
import image1 from '../../../../../assets/v2/imgs/placeholder.jpg'
import image2 from '../../../../../assets/v2/imgs/avatar/ava_1.png'
import '../../../../../assets/v2/css/main.css'

const HomePage = ({ categories }) => {
  const [showMoreCategory, setShowMoreCategory] = useState(false)
  const [communitiesList, setCommunitiesList] = useState([])
  const [filterCategoryId, setFilterCategoryId] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [totalCategory, setTotalCategory] = useState(0)
  const [currentCounts, setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [loading, setLoading] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [searchValue, setSearchValue] = useState('');

  const categoryData = categories.map(category => ({
    label: category.name,
    value: category.id
  }));
  let visibleCategories, hideCategories;
  if (categories.length > 6) {
    visibleCategories = categories.slice(0, 6);
    hideCategories = categories.slice(6, categories.length);
  } else {
    visibleCategories = categories;
    hideCategories = [];
  }
  const fetchData = () => {
    setLoading(true)
    const url = '/communities.json'
    makeRequest(url, 'get', { params: { page: (activePage + 1), category_id: filterCategoryId,searchValue: searchValue } }, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setCommunitiesList(
          res.data.communities
          // .sort((a, b) => b.member_count - a.member_count)
          .map((community) => ({
            id: community.id,
            name: community?.name,
            avatar_image: community?.avatar_image,
            community_type: community?.community_type,
            cover_image: community?.cover_image,
            description: community?.description,
            paid: community?.paid,
            small_description: community?.small_description,
            member_count: community?.member_count,
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalCategory(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setLoading(false)
      },
    })
  }
  useEffect(() => {
    fetchData()
  }, [activePage,filterCategoryId])
  
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };

  return (
    <div>
      <div class="archive-header pb-20">
        <div class="container">
          <h3 class="mb-10 text-center mx-auto">
            Discover Communities
          </h3>
          <h5 class="mb-20 text-center w-75 mx-auto">
            or <a href='/communities/new' style={{ color: '#005999'}}>create your own</a>
          </h5>
          <div class="widget_search mb-20">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 col-md-6">
                <div class="search-form-1">
                  <form onSubmit={handleSubmit}>
                    <input type="text" value={searchValue} onChange={handleChange} placeholder="Search for anything" />
                    <button type="submit"><i class="fi-rr-search"></i></button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 skill-header">
              <a href="#" onClick={() => { setFilterCategoryId('') }} className={`btn btn-border btn-sm mr-10 ${filterCategoryId === '' ? 'active' : ''}`}>All</a>
              <a href={'/communities/v5'} className={`btn btn-border btn-sm mr-10`} target='_blank'>Job Interview Answers</a>
              {visibleCategories.map((category, index) => (
                <>
                  <a key={index} onClick={() => { setFilterCategoryId(category.id) }} href="#" className={`btn btn-border btn-sm mr-10 ${filterCategoryId === category.id ? 'active' : ''}`}>{category.name}</a>
                </>
              ))}
              {showMoreCategory && (
                <>
                  {hideCategories.map((category, index) => (
                    <a key={index} href="#" onClick={() => { setFilterCategoryId(category.id) }} className={`btn btn-border btn-sm mr-10 ${filterCategoryId === category.id ? 'active' : ''}`}>{category.name}</a>
                  ))}
                  <a onClick={() => setShowMoreCategory(false)} className="btn btn-border btn-sm mr-10">Less...</a>
                </>
              )}
              {!showMoreCategory && (
                <a onClick={() => setShowMoreCategory(true)} className="btn btn-border btn-sm mr-10">More...</a>
              )}
            </div>
            {/* <div class="col-md-2 skill-header">
              <a href="#" class="btn btn-border btn-sm mr-10">Filter <i class="fi-rr-filter"></i></a>
            </div> */}
          </div>
        </div>
      </div>
      <div class="post-loop-grid">
        <div class="container">
          <div class="row">
            {communitiesList && communitiesList.map((community, index) => {

              return (<div class="col-lg-4 col-md-6">
                <div class="card-grid-2 hover-up">
                  <div class="text-center card-grid-2-image community_img">
                    <a href={`${'/communities/' + community.id}`+'/about'}>
                      <img src={community.cover_image || image1} alt={community.name} />
                    </a>
                    <label class="btn-urgent">#{index + 1}</label>
                  </div>
                  <div class="card-block-info p-0 p-10">
                    <div class="row">
                      <div class="col-lg-12 col-6">
                        <a target='_blank' href={`${'/communities/' + community.id}`+'/about'} class="card-2-img-text">
                          <span class="card-grid-2-img-small">
                            <img src={community.avatar_image || image2} alt={community.name} /></span>
                          <h6><b>{community.name}</b></h6>
                        </a>
                      </div>
                    </div>
                    <p class="mt-10 short_desc" title={community.small_description || community.description}><a target='_blank' href={`${'/communities/' + community.id}`+'/about'}>{community.small_description || community.description}</a></p>
                    <div class="mt-15 d-flex justify-content-between">
                      <span class="icon-card"><i class="fi-rr-shield-check"></i>{Util.capitalize(community.community_type) || 'Private'}</span>
                      <span class="icon-card"><i class="fi-rr-user"></i>{((community.member_count >= 1000) ? (community.member_count/1000)+'K' : community.member_count)} {(community.member_count > 1)?'Members':'Member'} </span>
                      <span class="icon-card"><i class="fi-rr-credit-card"></i>{(community.paid) ? 'Paid' : 'Free'}</span>
                    </div>
                  </div>  
                </div>
              </div>)
            })}
          </div>
          {(pageCount > 1) ?
            <div className="paginations">
              <Paginator
                pageCount={pageCount}
                activePage={activePage}
                setActivePage={setActivePage}
                pageWindowSize={30}
              />
            </div>
            : ''}
        </div>
      </div>
    </div>
  );
}
export default HomePage