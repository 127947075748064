import { act } from 'react-dom/test-utils'
import moment from 'moment'

export const userRoels = ['guest', 'member', 'moderator', 'admin', 'talent', 'employer', 'recruiter', 'third_party_recruiter', 'internal_recruiter']

export const findByTestAttr = (wrapper, attr) => {
    return wrapper.find(`[data-test='${attr}']`)
}

export const timeoutResolve = (time = 0, response = {}) =>
    new Promise((resolve) => {
        setTimeout(() => resolve(response), time)
    })

export const wait = (time = 0) => {
    const timer = timeoutResolve(time)

    return act(async () => {
        await timer
    })
}
export function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function generateRandom(min, max) {
    return Math.floor(min + Math.random() * (max + 1 - min))
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function createMarkedList(array) {
    if (!Array.isArray(array)) {
        return []
    }
    return array.map((candidate) => ({
        id: candidate.id,
        name: candidate.id,
        fullName: `${candidate.first_name} ${candidate.last_name}`,
        emailAddress: candidate.email_address,
        isChecked: false,
    }))
}

export function stringReplace(string,toSymbol,fromSymbol){
    return string.replace(toSymbol, fromSymbol)
}

export function capitalize(string) {
    if(string != null){
        return string.replace('_', ' ').replace(/\b(\w)/g, s => s.toUpperCase());
    }else{
        return string
    }
}

export function skillIsNotEmpty(value){
    return value.trim().length > 0;
}

export function firstCharacterCapital(value){
    return value?.charAt(0)?.toUpperCase() + value?.slice(1)
}

export function getDueDays(expiry_date){
    let expiryDate = moment(expiry_date).format('DD-MMM-YYYY')
    let today = moment().format('DD-MMM-YYYY');
    let expDate = moment(expiryDate);
    return expDate.diff(today,'day');
}

export function normalizeInput(value, previousValue) {
    // return nothing if no value
    if (!value) return value;
  
    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;
  
    if (!previousValue || value.length > previousValue.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;
  
      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  
      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
};