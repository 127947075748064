import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import { capitalize } from '../../../utils'
import Button from './shared/Button'
import DataTable from 'react-data-table-component-footer'
import   './styles/DataTableCustom.scss'
import Select from 'react-select'
import styles from './styles/JobsStatusPage.module.scss'    
import MonthRangePicker from '../../common/MonthRangePicker/MonthRangePicker'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'

const RecommendedCandidateDetails = ({metaData}) => {
    const [loading, setLoading] = useState(true)
    const [detailData, setDetailData] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(metaData.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [dateRange, setDateRange] = useState({ startDate:  moment(metaData.start_date).toDate() ,endDate: moment(metaData.end_date).toDate() })
    const handleDate = (date) => {
        setDateRange(date)
    }
    const stages = {lead: 'Lead', applicant: 'Applicant', recruitor_screen: 'Recruiter Screened', submitted: 'Submitted', first_interview: 'First Interview', second_interview: 'Second Interview', onhold: 'onhold', offer: 'Offer', reject: 'Archived', hired: 'Hired'}
    const [stageLists, setStageLists] = useState([{value: 'recruitor_screen', label: 'Recruiter Screened'}, {value: 'first_interview', label: 'First Interview'}, {value: 'second_interview', label: 'Second Interview'},{value: 'onhold', label: 'On Hold'}, {value: 'offer', label: 'Offer'}, {value: 'hired', label: 'Hired'}, {value: 'reject', label: 'Archived'}])
    const [selectedStage, setSelectedStage] = useState(metaData.stage)
    const [defaultStageselected, setdefaultStageselected] = useState({label: stages[metaData.stage], value: selectedStage})
    
    const columns=[
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Candidate Name',
            selector: row => row.candidate_name,
            cell: (row) => <div title={row.candidate_name}>{row.candidate_name}</div>,
            sortable: true,
            sortField: 'candidate_name',
            maxWidth: '20%',
            minWidth: '20%'
        },
        {
            name: 'Recruiter Name',
            selector: row => row.recruiter_name,
            cell: (row) => <div title={row.recruiter_name}>{row.recruiter_name}</div>,
            sortable: true,
            sortField: 'recruiter_name',
            maxWidth: '20%',
            minWidth: '20%'
        },
        {
            name: 'Move On',
            selector: row => row.created_at,
            cell: (row) => <div title={row.created_at}>{row.created_at}</div>,
            sortable: true,
            sortField: 'created_at',
            maxWidth: '15%',
            minWidth: '15%'
        },
        {
            name: 'Moved From',
            selector: row => row.previous_stage,
            cell: (row) => <div title={row.previous_stage}>{row.previous_stage}</div>,
            sortable: true,
            sortField: 'previous_stage',
            maxWidth: '15%',
            minWidth: '15%'
        },
        {
            name: 'Stage',
            selector: row => row.stage,
            cell: (row) => <div title={row.stage}>{row.stage}</div>,
            sortable: true,
            sortField: 'stage',
            maxWidth: '10%',
            minWidth: '10%'
        },
        {
            name: 'Current Stage',
            selector: row => row.current_stage,
            cell: (row) => <div title={row.current_stage}>{row.current_stage}</div>,
            sortable: true,
            sortField: 'submission_type',
            maxWidth: '15%',
            minWidth: '15%'
        }
    ]
    
    const fetchData = async () => {
        setLoading(true)
        const url = '/admin/recommended_candidate/details.json'
        let start_date = moment(moment(dateRange.startDate).startOf('month').toDate()).format('YYYY-MM-DD')
        let end_date =  moment(moment(dateRange.endDate).endOf('month').toDate()).format('YYYY-MM-DD')
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&recruiter_id=${metaData.recruiter_id}&start_date=${start_date}&end_date=${end_date}&stage=${selectedStage}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setDetailData(response?.data?.detail_list.map((data,key)=> (sr_no=sr_no+1,{
            id: sr_no,
            previous_stage: data.previous_stage && stages[data.previous_stage],
            candidate_name: capitalize(data.candidate_name),
            recruiter_name: (data.recruiter_name) && (capitalize(data.recruiter_name)),
            stage: capitalize(stages[data.stage]),
            created_at:moment(data.created_at).format('MMM DD, YYYY'),
            current_stage: stages[data.submission_type]
        })))
        setLoading(false)
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
    }

    useEffect(()=>{
        fetchData()
    },[dateRange,activePage,selectedStage,sortDirection,sortField])

    const redirectBack = () => {
        window.location.href = '/admin/recommended_candidate'
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '20px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Recommended Candidate Details
                </P>
                <div>
                    <Button  className="ml-3" onClick={ ()=> redirectBack()}>
                        Go Back
                    </Button>
                </div>
            </div>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                    <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                    <div style={{display: 'flex'}}>
                    <div style={{display: 'grid',marginLeft:'10px'}}>
                        <label>
                            Stage
                        </label>
                        <Select
                            defaultValue={defaultStageselected}
                            options={stageLists}
                            onChange={(event)=>setSelectedStage(event.value)}
                            name="stage"
                            className={styles.colourStyles}
                        />
                    </div>
                    <div style={{display: 'grid',marginLeft:'10px'}}>
                        {/* <label>
                            Month
                        </label>

                    <MonthRangePicker
                        handleOnSubmit={handleDate}
                        defaultDate={dateRange}
                    /> */}
                                 <label>
                            Select Date Range
                        </label>
                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                    </div>
                </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={detailData}
                progressPending={loading}
                progressComponent={ <P className="text-center  p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                responsive={true}
                onChangePage={page => setActivePage(page)}
            />
        </Card>
    )
}

export default RecommendedCandidateDetails
