import React, { useEffect, useState } from 'react'
import Card from '../Admin/shared/Card'
import P from '../Admin/shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from './styles/AnalyticPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AnalyticPage.styled'
import Util from "../../../utils/util";
import DataTable from "react-data-table-component-footer"
import   '../Admin/styles/DataTableCustom.scss'

const SubmittedCandidatesAnalyticPage = (prop) => {
  const {currentUser} = prop
  const queryParams = new URLSearchParams(window.location.search)
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  const urlStage = queryParams.get("stage") || ''
  const job_id = queryParams.get("job_id") || ''
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(prop.per_page)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobStatus, setJobStatus] = useState([])
  const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
  const handleDate = (date) => {
      setDateRange(date)
  }
  const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'},...prop.recruiterList])
  const [selectedRecruiter,setSelectedRecruiter] = useState('')
  const [defaultselected,setdefaultselected] = useState({value: 'all', label: 'All'})
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const stages = {lead: 'Lead', applicant: 'Applicant', recruitor_screen: 'Recruiter Screened', submitted: 'Submitted', submitted: 'Submitted', first_interview: 'First Interview', second_interview: 'Second Interview', onhold:'On Hold', offer: 'Offer', reject: 'Archived', hired: 'Hired'}
  let tableHeaders,tableColumn = []
  if(currentUser.role == "recruiter"){
    tableHeaders = [
      { label: 'Client', key: 'organization' },
      { label: 'Job Name', key: 'jobName' },
      { label: 'Candidate Name', key: 'candidate_name' },
      { label: 'Submission Date', key: 'submission_date' },
      { label: 'Last updated on', key: 'update_date' },
      { label: 'Current Status', key: 'current_status' },
      { label: 'Candidate Source', key: 'candidate_source' },
      { label: 'Candidate LinkedIn', key: 'candidate_linkedin' },
      { label: 'All Stages', key: 'all_stage' },
    ]
    tableColumn = [
      { name: 'No.',
        selector: row => row.id,
        maxWidth: '5%',
        minWidth: '5%'
      },
      { name: 'Client',
        selector: row => row.organization,
        sortable: true,
        sortField: 'organization_name',
        maxWidth: '15%',
        wrap:true,
        minWidth: '15%'
      },
      { name: 'Job name',
        selector: row => row.jobName,
        sortable: true,
        sortField: 'job_name',
        maxWidth: '10%',
        wrap:true,
        minWidth: '10%'
      },
      { name: 'Submitted On',
        selector: row => row.date,
        sortable: true,
        sortField: 'date',
        maxWidth: '10%',
        minWidth: '10%'
      },
      { name: 'Candidate Name',
        selector: row => row.candidate_name,
        sortable: true,
        sortField: 'person_first_name',
        maxWidth: '15%',
        minWidth: '15%',
        wrap:true,
      },
      { name: 'Updated On',
      selector: row => row.update_at,
      sortable: true,
      sortField: 'date',
      maxWidth: '15%',
      minWidth: '15%'
       },
       { name: 'Moved From',
       selector: row => row.previous_stage,
       sortable: true,
       sortField: 'previous_stage',
       maxWidth: '15%',
       minWidth: '15%'
     },
       { name: 'Current Stage',
       selector: row => row.current_status,
       sortable: true,
       sortField: 'date',
       maxWidth: '15%',
       minWidth: '15%'
     },
    ]
  }else{
    tableHeaders = [
      { label: 'Recruiter Name', key: 'recruiter' },
      { label: 'Job Name', key: 'jobName' },
      { label: 'Date', key: 'date' },
      { label: 'Candidate Name', key: 'candidate_name' },
    ]
    tableColumn = [
      { name: 'No.',
        selector: row => row.id,
        maxWidth: '5%',
        minWidth: '5%'
      },
      { name: 'Recruiter Name',
        selector: row => row.recruiter,
        sortable: true,
        sortField: 'user_first_name',
        maxWidth: '25%',
        minWidth: '25%'
      },
      { name: 'Job name',
        selector: row => row.jobName,
        sortable: true,
        sortField: 'job_name',
        maxWidth: '30%',
        minWidth: '30%'
      },
      { name: 'Date',
        selector: row => row.date,
        sortable: true,
        sortField: 'date',
        maxWidth: '15%',
        minWidth: '15%'
      },
      { name: 'Candidate Name',
        selector: row => row.candidate_name,
        sortable: true,
        sortField: 'person_first_name',
        maxWidth: '25%',
        minWidth: '25%'
      },
    ]
  }

  const columns = tableColumn
  const fetchData = async () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/analytic/submitted_candidates_analytic.json'
    const response = await makeRequest(
      `${url}?page=${activePage}&job=${job_id}&start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter || currentUser?.id}&stage=${urlStage}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setTotalCount(response.data.total_count)
    let sr_no = (activePage - 1) * response.data.per_page
    setJobStatus(response?.data?.submitted_candidate.map((data,key)=> (sr_no= sr_no + 1,{
      id: sr_no,
      // previous_stage: capitalize(stages[data?.previous_stage]),
      previous_stage: data?.previous_stage && stages[data.previous_stage],
      date: moment(data.date).format('MMM DD, YYYY'),
      update_at: moment(data.updated_at).format('MMM DD, YYYY'),
      current_status: data?.current_stage && capitalize(stages[data?.current_stage]),
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organization_name && capitalize(data?.organization_name),
      recruiter: `${data?.user_first_name ? Util.fullName(data.user_first_name,data.user_last_name) : '-' }`,
      candidate_name: `${data?.person_first_name ? Util.fullName(data.person_first_name,data.person_last_name) : '-' }`
    })))
    setPageCount(response.data.total_pages)
    setStartRecord((activePage - 1) * response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setLoading(false)
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/analytic/submitted_candidates_analytic_excel.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}&sortField=${sortField}&stage=${urlStage}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.submitted_candidate.map((data,key)=> ({
      id: key,
      submission_date: (data?.data_type) ? (response?.data?.list_date_data[data.list.id]) ? moment(response?.data?.list_date_data[data.list.id]).format('MMM DD, YYYY') : '' : data?.list?.id ? (response?.data?.second_list[data.list.id]) ? moment((response?.data?.second_list[data.list.id][0])['transition_date']).format('MMM DD, YYYY') : '' : '',
      update_date: data?.list?.id ? (response?.data?.second_list[data.list.id]) ? moment((response?.data?.second_list[data.list.id][(response?.data?.second_list[data.list.id]).length - 1])['transition_date']).format('MMM DD, YYYY') : '' : '',
      jobName: data?.list?.job_name && capitalize(data?.list.job_name),
      organization: data?.list?.cleint_name && capitalize(data?.list?.cleint_name),
      recruiter: `${data?.list?.user_first_name ? Util.fullName(data.list.user_first_name,data.list.user_last_name) : ' ' }`,
      candidate_name: `${data?.list?.candidate_first_name ? Util.fullName(data.list.candidate_first_name,data.list.candidate_last_name) : ' ' }`,
      current_status: data?.list?.current_status && capitalize(stages[data?.list?.current_status]),
      all_stage: data?.list?.id ? response?.data?.second_list ? response?.data?.second_list[data.list.id].map((row) => `${capitalize(stages[row.current_status])}`).join(',') : '' : '',
      candidate_source: data?.list?.current_status && (data?.list?.source),
      candidate_linkedin: data?.list?.linkedin_profile_url && (data?.list?.linkedin_profile_url)
    })))
    csvExportRef?.current?.link.click();
  }

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage,selectedRecruiter,sortDirection,sortField])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }
  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }

  return (  
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Submitted Candidate Analytics
        </P>
        <div>
          { (urlStage == '') ?
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
          :
          <a href='/analytic/recruiter_jobs_analytics' className={`ml-3 ${styles.exportCSVButton}`}>
              Back
          </a>
          }
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"SubmittedCandidatesAnalytic.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
              {totalCount > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
             }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
            {(currentUser.role === "employer" &&
              <div style={{display: 'grid',marginLeft:'10px'}}>
                <label>
                    Recruiter
                </label>
                <Select
                    defaultValue={defaultselected}
                    options={recruiter}
                    onChange={(event)=>setSelectedRecruiter(event.value)}
                    name="recruiter"
                    className={styles.colourStyles}
                />
              </div>
            )}
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Select Date Range
              </label>
              <DateRangePicker
                  handleOnSubmit={handleDate}
                  defaultDate={dateRange}
              />
            </div>
          </div>
        </Col>
      </Row>
      <DataTable
          persistTableHead={true}
          noDataComponent={<p className="text-center p-3">No record found</p>}
          columns={columns}
          data={jobStatus}
          progressPending={loading}
          progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
              <Spinner className='spinner' animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>
          </P>}
          onSort={handleSort}
          sortServer
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          paginationPerPage={perPage}
          paginationComponentOptions={{
              noRowsPerPage: true
          }}
          onChangePage={page => setActivePage(page)}
        />
    </Card>
  )
}

export default SubmittedCandidatesAnalyticPage