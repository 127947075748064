import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from '../../Admin/shared/Card'
import P from '../../Admin/shared/P'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import Select from 'react-select'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import SearchBar from '../../../common/SearchBar/SearchBar'
import { Col, Image, Row, } from 'react-bootstrap'
import Util from '../../../../utils/util'
import styles from '../../Admin/Candidate/styles/candidate.module.scss'
import '../../Admin/Candidate/styles/candidate.scss'
import feather from 'feather-icons'

const CandidateManagement = (props) => {
    var active_page = window.sessionStorage.getItem('agency_admin_can_activePage')
    var type = window.sessionStorage.getItem('agency_admin_can_type_query')
    var query = window.sessionStorage.getItem('agency_admin_can_search_query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const [candidates, setCandidates] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [totalCandidate,setTotalCandidate] = useState()
    const [currentCounts,setCurrentCounts] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [selectedType, setSelectedType]  = useState(type || 'true')
    const [bulkIds, setBulkIds] = useState([])
    const [loading, setLoading] = useState(true)

    const multipleIdsFunc = value => {
        if(bulkIds.includes(value)){
            let arr = bulkIds.filter(item => item !== value)
            setBulkIds([...arr])
        }
        else
            setBulkIds([...bulkIds, value])
    }
    const onChangeSearchTerm = (value) => {
        window.sessionStorage.setItem('agency_admin_can_search_query',value)
        setSearchTerm(value)
    }

    const fetchData = () => {
        setLoading(true)
        window.sessionStorage.setItem('agency_admin_can_activePage',activePage)
        const url = `/agencies/admin/reports/candidates.json`
        makeRequest(url, 'get', {
                params:
                    {
                        type: selectedType,
                        page: (activePage + 1),
                        keyword: searchTerm || ''
                    }
            }, {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setCandidates(
                    res.data.candidates.map((candidate) => ({
                        id: candidate.id,
                        first_name: candidate.first_name,
                        last_name: candidate.last_name,
                        agency_name: candidate.agency_name,
                        name: candidate.full_name,
                        title: candidate.title,
                        email_address: candidate.email_address,
                        linkedin_profile_url: candidate.linkedin_profile_url?.replace('https://',''),
                        created_at: candidate.created_at,
                        avatar_url: candidate.avatar_url,
                        experiences: candidate.experiences
                    }))
                )
                setPageCount(res.data.meta.total_pages)
                setCurrentCounts(res.data.meta.current_count)
                setTotalCandidate(res.data.meta.candidate_count)
                setStartRecord((activePage * res.data.meta.per_page))
                setLoading(false)
                console.log(res.data.meta.per_page,"res.data.meta.per_page")
            },
        })
        console.log(activePage,"activePage")
    }

    useEffect(() => {
        feather.replace()
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,selectedType])

    return(
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Candidate Management
                </P>
            </div>
            <Row className='w-100 align-items-center'>
                <Col lg="4" md="4" sm="12" xs="12">
                {totalCandidate > 0 &&
                    <DisplayPagination>Displaying  {(activePage == 0)?1:startRecord + 1} - {currentCounts > totalCandidate ? totalCandidate : currentCounts } of {totalCandidate} recruiters</DisplayPagination>
                }
                </Col>
                <Col lg="8" md="8" sm="12" xs="12" className='d-flex justify-content-end pb-2 p-0'>
                    <div className='d-flex'>
                        <SearchBar
                                placeholder="Search Candidate"
                                value={searchTerm}
                                onChange={(e) => onChangeSearchTerm(e.target.value)}
                                onEnterPressed={() =>
                                    activePage == 0
                                        ? fetchData()
                                        : setActivePage(0)
                                }
                                onButtonClick={() =>
                                    activePage == 0
                                        ? fetchData()
                                        : setActivePage(0)
                                }
                        />
                    </div>
                </Col>
            </Row>
            <Table
                multipleIdsFunc = {multipleIdsFunc}
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Agency Name',
                        field: 'agency_name',
                        editable: true,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Profile Avatar',
                        field: 'avatar',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Candidate Name',
                        field: 'name',
                        editable: true,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Title',
                        field: 'title',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Email Address',
                        field: 'email_address',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Linkedin Profile',
                        field: 'linkedin_profile_url',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Created On',
                        field: 'created_at',
                        editable: true,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                ]}
                rowValues={candidates.map((o) => ({
                    ...o,
                    created_at: moment(o.created_at).format('MMM DD, YYYY'),
                    avatar: o.avatar_url !== "N/A" ? <Image style={{borderRadius: '10px'}} width={75} src={o.avatar_url} /> : 
                    <div className={styles.candidateAvtarImage}>{ (o.first_name) ? (o?.first_name && Util.getFirstCharater(o?.first_name)) + (o?.last_name && Util.getFirstCharater(o?.last_name) ) : (o?.email && Util.getFirstCharater(o?.email) ) }</div>
                }))}
                showEditOption
                activePage={activePage}
                loading={loading}
            />
            { pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
        </Card>
    );
}
export default CandidateManagement