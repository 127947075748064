import React, { useEffect } from 'react';

function BooleanInput({ label, testAttr, checked, setCheckBox }) {
  const handleCheckboxChange = () => {
    setCheckBox();
  };

  return (
    <div className="d-flex align-items-center">
      <input
        checked={checked}
        id={testAttr}
        type="checkbox"
        data-test="checkbox-item"
        onChange={handleCheckboxChange}
        style={{ marginRight: '0.5rem' }}
      />
      <label
        data-test="label-item"
        style={{ marginBottom: '0' }}
        htmlFor={testAttr}
      >
        {label}
      </label>
    </div>
  );
}

export default BooleanInput;
