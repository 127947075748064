import React, { useRef, useEffect, useState } from 'react'
import './styles/ResumeDragDrop.scss'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import UploadIcon from '../../../../assets/images/talent_page_assets/upload-icon-v4.png'
import { Row } from 'react-bootstrap'
import feather from 'feather-icons'

function ResumeDragDrop(props) {
    const dropRef = useRef()
    const fileInputRef = useRef()
    const [dragging, setDragging] = useState(false)

    const {
        handleFiles,
        title,
        files,
        handleOnRemoveFiles,
        handleOnSubmit,
        buttonlabel='Upload',
        validationErrors,
        handleClearResumes,
        buttonDisabled=false
    } = props
    let dragCounter

    const fileInputClicked = () => {
        fileInputRef.current.click()
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files)
        }
    }
    const handleRemoveResume = (index) => {
        handleOnRemoveFiles(index)
    }

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        }
        dragCounter += 1
    }

    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        dragCounter -= 1
        if (dragCounter > 0) return
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFiles(e.dataTransfer.files)
            dragCounter = 0
        }
    }
    useEffect(() => {
        feather.replace()
    })

    useEffect(() => {
        dragCounter = 0
        let div = dropRef.current
        div.addEventListener('dragenter', handleDragIn)
        div.addEventListener('dragleave', handleDragOut)
        div.addEventListener('dragover', handleDrag)
        div.addEventListener('drop', handleDrop)

        return () => {
            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
        }
    }, [dropRef, handleFiles])

    return (
        <>
            <Row>
                <div ref={dropRef} className='dropContainer'>
                    <input
                        ref={fileInputRef}
                        type="file"
                        onChange={filesSelected}
                        style={{ display: 'none' }}
                        accept=".doc,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, .docx,.pdf"
                    />
                    {dragging && (
                        <div className='dropArea'>
                            <div className='dropText'>
                                <div>Drop here</div>
                            </div>
                        </div>
                    )}
                    <div>
                        <div className='uploadResumeContainer'>
                            <div className='flexItem'>
                                <div className='dragDropItem'>
                                    <div>
                                        <Image
                                            src={UploadIcon}
                                            style={{ color: '#fff' }}
                                        />
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                        <p className='subTitle'>
                                            Drag & drop {title} (.pdf) file format
                                       </p>
                                        <p className='orText'>or</p>
                                        <Button
                                            className='button'
                                            onClick={fileInputClicked}
                                            style={{
                                                pointerEvents: dragging
                                                    ? 'unset'
                                                    : 'all',
                                            }}
                                        >
                                            Choose file
                                        </Button>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div className='resumeContainer'>
                                        {files?.map((resume, index) => (
                                            <div
                                                key={index}
                                                className='resume'
                                            >
                                                <span
                                                    className='
                                                        resumeNameText
                                                    '
                                                >
                                                    {resume.name}
                                                </span>{' '}
                                                <span
                                                    onClick={() =>
                                                        handleRemoveResume(
                                                            index
                                                        )
                                                    }
                                                >
                                                    <i
                                                        data-feather="x"
                                                        size="15px"
                                                        color="#4C68FF"
                                                        className='svgIcon'
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className='buttonUploadDiv'>
                                <Button
                                    disabled={buttonDisabled}
                                    className='buttonUpload'
                                    onClick={handleOnSubmit}
                                >
                                    {buttonlabel}
                                </Button>
                                {validationErrors?.resume && 
                                    <div className='errorMessage'>
                                        {validationErrors?.resume}
                                    </div>
                                }
                                {handleClearResumes &&
                                <Button
                                    className='buttonUpload'
                                    onClick={handleClearResumes}
                                >
                                    Clear
                                </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    )
}

export default ResumeDragDrop
