import React, { useState, useEffect, useContext, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import styles from './styles/CandidateInfo.module.scss'
import isEmpty from 'lodash.isempty'
import Util from '../../../utils/util'
import { Card, Container, Col, Button, DropdownButton, Image, Row, Modal } from 'react-bootstrap'
import profileImage from '../../../../assets/images/icons/profile.png'
import Badge from "react-bootstrap/Badge";
import './styles/CandidateInfo.scss'
import _ from 'lodash'
import LinkedinIcon from '../../../../assets/images/icons/linkedin.png'
import PdfIcon from '../../../../assets/images/icons/pdf.png'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import moment from 'moment'
import MatchScore from '../../common/MatchScore/MatchScore'
import { capitalize } from '../../../utils'
import JobModal from '../../common/JobModal/JobModal'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import notmatch from '../../../../assets/images/icons/thumb_down_2.png'
import { StoreDispatchContext } from '../../../stores/JDPStore'


function CandidateInfo({
  loading,
  candidates,
  handleIntrested,
  multiSelectCheckbox,
  multiIntrestedCandidates,
  organizationname,
  memberOrganization,
  organization,
  showNotMatch
}) {

  const [submissionData, setSubmissionData] = useState({ active: false, id: null })
  const { dispatch, state } = useContext(StoreDispatchContext)
  function activeSubmission(candidateId) {
    setSubmissionData({ active: true, id: candidateId })
  }

  if (loading) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>Loading....</h2>
        <div id="loading" />
      </div>
    </>
    )
  }
  if (isEmpty(candidates)) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>No candidate found</h2>
      </div>
    </>
    )
  }
  const candidateClickHandler = (candidate) => {
    dispatch({
      type: 'show_candidate',
      candidate,
    })
  }

  return (
    <>
      <Table className={styles.candidateTable} responsive hover>
        <tbody >
          {Array.isArray(candidates) &&
            candidates.map((candidate, index) => {
              return (
                <CandidateRow
                  candidate={candidate}
                  idx={candidate.id}
                  key={index}
                  clickHandler={candidateClickHandler}
                  setSubmissionData={setSubmissionData}
                  submissionData={submissionData}
                  activeSubmission={activeSubmission}
                  handleIntrested={handleIntrested}
                  multiSelectCheckbox={multiSelectCheckbox}
                  multiIntrestedCandidates={multiIntrestedCandidates}
                  organizationname={organizationname}
                  memberOrganization={memberOrganization}
                  organization={organization}
                  showNotMatch={showNotMatch}
                />
              )
            })
          }
        </tbody>
      </Table>
    </>
  )
}

function CandidateRow({ candidate, idx, clickHandler, setSubmissionData, submissionData, activeSubmission, handleIntrested, multiSelectCheckbox,multiIntrestedCandidates, organizationname, memberOrganization, organization, showNotMatch }) {
  const checked = multiIntrestedCandidates.length > 0 ? multiIntrestedCandidates.includes(idx) : false
  const pathname = window.location.pathname?.split('/')
  const jobId = parseInt(pathname[2]) ? pathname[2] : ''
  const [visible, setVisible] = useState(6)
  const [visibleJobs, setVisibleJobs] = useState(1)
  const [bulkSelect, setBulkSelect] = useState(checked)
  const userBelongsToCT = organization?.name === window.ch_const.ct_org_name
  const subOrgBelongsToCT =  memberOrganization === true
  const [show,setShow] = useState(showNotMatch)
  const [check, setCheck] = useState([])
  const [message, setMessage] = useState('')
  const checkList = ["Location does not match", "Skills does not match", "Experience does not match", "Not enough experience", "Education requirement does not match", "Employment authorization does not match", "Other"]
  const loadMore = () => {
    setVisible(visible + 6)
  }
  const loadLess = () => {
    setVisible(6)
  }
  const loadMoreSubmittedJobs = () => {
    setVisibleJobs(visibleJobs + 40)
  }
  const loadLessSubmittedJobs = () => {
    setVisibleJobs(1)
  }
  const handleCheck = (event) => {
    var updatedList = [...check]
    if (event.target.checked) {
      updatedList = [...check, event.target.value]
    } else {
      updatedList.splice(check.indexOf(event.target.value), 1)
    }
    setCheck(updatedList)
  }

  const closeFun = () => {
    setShow(false)
    setCheck([])
  }
  // Generate string of checked items
  const checkedItems = check.length
    ? check.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";

  // Return classes based on whether item is checked
  var isCheck = (item) =>
    check.includes(item) ? "checked-item" : "not-checked-item";

  const saveFeedback = () => {
    const url = `/weekly_recommendation/feedback`
    const formData = new FormData()
    if(check?.length > 0){
      let data = []
      if(message){
        data.push({person_recommendations: {
          person_id: candidate.id,
          job_id: jobId,
          reason: [...check, message]
        }})  
      }
      else{
        data.push({person_recommendations: {
          person_id: candidate.id,
          job_id: jobId,
          reason: check
        }}) 
      }
      formData.append('feedback', JSON.stringify(data))
    }
    else{
      formData.append('person_recommendations[person_id]',candidate.id)
      formData.append('person_recommendations[job_id]',jobId)
      formData.append('person_recommendations[reason]',check)
    }
    makeRequest(url, 'post', formData, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        if(res.data.type == "success"){
          setShow(false)
          setTimeout(()=>{
            window.location.reload()
          },3000)
        }
      },
    })
  }

  function getSelectCell(candidate, col) {
    return (
      <td
        key={'checkbox' + candidate.id}
        style={{ width: '1rem' }}
        className={styles.td + ' align-middle'}
      >
        {!candidate.reason &&
        <Form.Check
          className={`${styles.candidateCheckbox} check_${candidate.id}`}
          type="checkbox"
          value={candidate.candidate_name}
          name={candidate.candidate_name}
          checked={checked}
          onChange={handleCheckboxChange}
          onClick={(event) => event.stopPropagation()}
        />
        }
      </td>
    )
  }

  const handleCheckboxChange = (event) => {
    setBulkSelect(!checked)
    multiSelectCheckbox(candidate.id)
  }

  return (
    <>
      <Modal
          onHide={() => setShow(false)}
          centered
          show={show}
          aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize: "26px"}}>Please Provide us a Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkList.map((item, index) => (
            <div key={index}>
            <input value={item} type="checkbox" onChange={handleCheck} />
            <span style={{fontSize: "20px"}}>&nbsp; {item} <br /></span>
          </div>
          ))}
          {check.includes('Other') && 
          <textarea name="Text1" cols="40" rows="3" placeholder='Type your reason here...' onChange={(e)=> setMessage(e.target.value)}></textarea>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeFun}>
            Close
          </Button>
          <Button variant="primary" onClick={saveFeedback}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Card key={candidate.id} className={styles.candidateProfileCard}  style={{backgroundColor:  (!candidate.reason) ?'#fff':'antiquewhite'}}>
        <Card.Body className='candidate-list'>
          {
            getSelectCell(candidate)
          }
          <div style={{width:'100%'}}>
            <Row style={{ marginBottom: '11px',justifyContent: 'space-between'}} >
              <div className='d-flex'>
                {candidate?.image_url ?
                  <img className={styles.candidateProfileImage} src={candidate.image_url ? candidate.image_url : profileImage} onClick={(e) => clickHandler(candidate)} />
                  : <CandidateAvatarIcon clickHandler={clickHandler} candidate={candidate} firstName={candidate?.first_name} lastName={candidate?.last_name} />
                }
                <div className={styles.cardBody + 'container'} >
                  <div className='d-flex justify-content-space-between w-100'>
                    <h5 className={styles.cardBodyName}>
                      <span onClick={(e) => clickHandler(candidate)}>{Util.handleUndefinedFullName(capitalize(candidate?.first_name), capitalize(candidate?.last_name))}</span>
                      {candidate.linkedin_profile_url &&
                        <a href={candidate.linkedin_profile_url} target="_blank">
                          <Image
                            src={LinkedinIcon}
                            style={{
                              width: '16px',
                              height: '16px',
                              marginLeft: '15px',
                              marginTop: '-1px'
                            }}
                          />
                        </a>
                      }
                      {candidate.resume && 
                        (
                          <a href={candidate.resume} target="_blank">
                            <Image
                                src={PdfIcon}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '5px',
                                    marginTop: '-1px'
                                }}
                            />
                          </a>)}
                    </h5>
                  </div>
                  <h5 style={{ fontSize: '16px' }} onClick={(e) => clickHandler(candidate)}>{candidate?.title}</h5>
                </div>
              </div>
              <div style={{display: '-webkit-inline-box'}}>
                {!candidate.reason ?
                  <>
                  <Button
                    variant='primary'
                    className={styles.button}
                    onClick={()=>handleIntrested(candidate)}
                  >
                    Move to ATS
                  </Button>
                  <div className="img-block">
                    <img
                        style={{width: '50px', cursor: 'pointer'}}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Mark not match"
                        src={notmatch}
                        alt=""
                        onClick={()=>setShow(true)}
                    />
                  </div>
                  </>
                :
                <Button
                    variant='danger'
                    className={styles.button_danger}
                  >
                  Unmatch Candidate
                </Button>
                }
              </div>
            </Row>
                  
            <Row>
            {candidate?.skills &&
              <div className='container row flex-nowrap'>
                <div>
                  <h6 className={styles.skillTitle}>Skills</h6>
                </div>
                <div className={styles.badge}>
                  {candidate?.skills?.split(',').slice(0, visible).map((skill, index) => skill?.trim() && canidateSkills(skill, index))}
                  {visible < candidate?.skills?.split(',').length && (
                    <Badge
                      pill
                      variant="secondary"
                      className='skills-more-button  d-inline-block text-truncate'
                      onClick={loadMore}
                    >
                      <label>View More</label>
                    </Badge>
                  )}
                  {visible >= candidate?.skills?.split(',').length && candidate?.skills?.split(',').length > 6 && (
                    <Badge
                      pill
                      variant="secondary"
                      className='skills-more-button  d-inline-block text-truncate'
                      onClick={loadLess}
                    >
                      <label>View Less</label>
                    </Badge>
                  )}
                </div>
              </div>}
              <div className='container row w-100 flex-nowrap align-items-start'>
                <div style={{float: 'left'}}>
                  <p className={styles.submittedJob}>Location: {candidate?.location}</p>
                </div>
              </div>
              <div className='container row w-100 flex-nowrap align-items-start'>
                <div style={{float: 'left'}}>
                  <p className={styles.submittedJob}>Recommended On: {moment(candidate?.created_at).format('MMM DD, YYYY')}</p>
                </div>
              </div>
              {(userBelongsToCT || subOrgBelongsToCT) && (
              <div className='container row w-100 flex-nowrap align-items-start'>
                <div style={{float: 'left'}}>
                  <p className={styles.submittedJob}>Submitted Jobs</p>
                  {candidate?.submissions?.length > 0 && candidate?.submissions?.slice(0, visibleJobs).map((application) => (
                    <ul style={{ marginBottom: '0' }}>
                      <li>
                        <div className='row contact_time'>
                          <b>{application?.job?.organization?.name}</b>  &nbsp; - &nbsp;
                          <a href={`/jobs/${application?.job?.id}/${application?.job?.name}`}>{application?.job?.name}</a>  <span style={{ textTransform: "capitalize" }}><b style={{ marginLeft: "5px" }}> &nbsp; {application?.submission_type != 'submitted' ? 'submitted to recruiter screened' : 'submitted on'}  &nbsp;</b> - &nbsp;{moment(application?.created_at).format('MMM DD, YYYY')} </span>
                          <span style={{ textTransform: "capitalize" }}><b style={{ marginLeft: "5px" }}> &nbsp; Submitted By &nbsp;</b> - &nbsp;{`${application?.user?.first_name} ${application?.user?.last_name}`}  </span>
                        </div>
                      </li>
                    </ul>
                  ))}
                  {visibleJobs < candidate?.submissions?.length && (
                    <Badge
                      style= {{marginTop: '10px', marginLeft: '18px'}}
                      pill
                      variant="secondary"
                      className='skills-more-button  d-inline-block text-truncate'
                      onClick={loadMoreSubmittedJobs}
                    >
                      <label>View All {candidate?.submissions?.length} Submitted Jobs</label>
                    </Badge>
                  )}
                  {visibleJobs >= candidate?.submissions?.length && candidate?.submissions?.length > 1 && (
                    <Badge
                      style= {{marginTop: '10px', marginLeft: '18px'}}
                      pill
                      variant="secondary"
                      className='skills-more-button  d-inline-block text-truncate'
                      onClick={loadLessSubmittedJobs}
                    >
                      <label>View Less</label>
                    </Badge>
                  )}

                  { candidate.reason &&
                   <p className={styles.submittedJob} style={{marginTop:'15px' , color:'red'}}><b>Reason : </b> {(candidate.reason)?.replace('[','')?.replace(']','')?.replaceAll('"','')}</p>
                  }
                </div>
              </div>
              )}
              <div className="ml-auto p-2 bd-highlight">
                  <MatchScore score={candidate.score} big={true} />
              </div>
              </Row>
          </div>
        </Card.Body>
      </Card>
      <br />
    </>
  )
}

function canidateSkills(skill, index) {
  return (
    <>
      <Badge
        pill
        key={index}
        variant="secondary"
        className="skills-button d-inline-block text-truncate"
      >
        <label className='candidate-skills'> {skill} </label>
      </Badge>
    </>
  )
}

export default CandidateInfo
