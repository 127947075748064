import styled from 'styled-components'

export const BlockHeader = styled.div`
    background: #e6ebff;
    padding: 12px 48px;
    display: flex;
    justify-content: center;
    width: ${(props) =>
        props.width ? `${props.width}px` : 'auto'};
    margin-bottom: ${(props) =>
        props.marginBottom ? `${props.marginBottom}px` : '0px'};
`

export const BlockBody = styled.div`
    border: 1px solid #e5ebff;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
`

export const DisplayPagination = styled.div`
    font-family: $default-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #696B74;
    text-align: left;
    margin-bottom: 10px;
`

export const ExportCSVButton = styled.button`
    background: 
    linear-gradient(
    94.67deg,
    #4c68ff -1.19%,
    #6077f4 53.94%,
    #8185ff 102.59%);
    border-radius: 20px;
    padding: 13px 20px;
    font-family: Avenir, "Lato", sans-serif !important;
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 16px;
    text-align: center;
    color: #fff;
    width: unset;
    height: unset;
    &:hover{
    color: #fff;
    text-decoration: none;
    }
    cursor: pointer;
`
