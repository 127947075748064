import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'
import { Spinner, Button, Alert } from 'react-bootstrap'
import styles from './styles/Signup.module.scss'
import { Label, GoogleButton } from './styles/Signup.styled'
import GoogleIcon from '../../../../assets/images/icons/icon-google.svg'
import UserForm from './UserFormV2'
const currentHost = window.location.host;

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  agreeToTermsAndPolicy: false,
  agreeToBusiness: false,
}

function SignUp({ userRole, signUpWithRole }) {
  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get("token")?.toString()
  document.cookie = "token=" + token
  const cookie = document.cookie
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [role, setRole] = useState(userRole)

  const validationSchema = Yup.object({
    email: Yup.string({ role })
      .email()
      .required('Email is required')
      .test(
        'email-unique',
        'This email is already in use',
        async function (value) {
          const res = await axios.get(
            `/users/exists?email=${encodeURIComponent(value)}`
          )
          return !res.data.user_exists
        }
      )
      .test(
        'email-fake',
        userRole == 'employer' ? 'Please enter your work email. Example: name@companyname.com' : 'Please enter a valid domain',
        async function (value) {
          const domain = await axios.get(
            `/users/blocked_email_domain.json?email=${(value)}&role=${(userRole)}`
          )
          console.log(domain.data.email_blocked_domain)
          return !domain.data.email_blocked_domain
        }
      ),
      // .matches(
      //   /^((([a-zA-Z0-9]|\d|[!#\$%&'\-\/=\?\^_`{\|}~])+(\.([a-zA-Z0-9]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])+|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))?$/,
      //   // /^((([a-zA-Z]|\d|[!#\$%&'\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/,
      //   'Restricted character found in the email address'
      // ),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,25}$/,
        'Password should have 8 to 25 characters, must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
      ),
    agreeToTermsAndPolicy: Yup.boolean()
    .oneOf(
      [true],
      'Please accept the Terms and Conditions & Privacy Policy'
    ),
    agreeToBusiness: userRole == 'recruiter' ? Yup.boolean().oneOf(
      [true],
      'Please accept the Business Terms.'
    ) : Yup.boolean(),
    first_name: Yup.string()
      .required('First Name is required')
      .max(50, 'Must be exactly 50 characters')
      .test(
          'first name alphabets only',
          'First Name can only contain alphabet characters and one space in between if there are two words',
          function (value) {
              const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
              return regex.test(value)
          }
      ),
    last_name: Yup.string()
      .required('Last Name is required')
      .max(50, 'Must be exactly 50 characters')
      .test(
          'last name alphabets only',
          'Last Name can only contain alphabet characters and one space in between if there are two words',
          function (value) {
              const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
              return regex.test(value)
          }
      ),
  })

  const handleSubmit = async (user) => {
    const payload = new FormData()
    const url = `/signup/${role}`
    if (!_.isEmpty(role)) {
      payload.append('user[role]', role)
      payload.append('user[email]', user.email)
      payload.append('user[password]', user.password)
      payload.append('user[password_confirmation]', user.password)
      payload.append('user[first_name]', user.first_name)
      payload.append('user[last_name]', user.last_name)
      payload.append('user[invited]', token)
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      setLoading(true)
      await axios
        .post(url, payload, {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
          },
          role,
        })
        .then((res) => {
          const responce = res.data
          if (responce.success) {
            setResponse({ messageType: 'success', message: responce.msg })
            if(role == 'talent'){
              window.location.href = '/communities'
            }else{
              window.location.href = '/complete/profile'
            }
          } else {
            setResponse({ messageType: 'danger', message: responce.msg })
          }
          setLoading(false)
        })
        .catch((e) => {
          setResponse({ messageType: 'success', message: e })
          setLoading(false)
        })
    } else {
      setResponse({ messageType: 'danger', message: 'Please Select Role' })
    }
  }

  const handleGoogleSignIn = () => {
    if (_.isEmpty(role)) {
      setResponse({ messageType: 'danger', message: 'Please Select Role' })
    } else {
      window.location.href = `/user/auth/google_oauth2?role=${role}`
    }
  }

  const handleRole = (event) => {
    setResponse({})
    setRole(event.target.value)
  }

  return (
    <div>
      {!_.isEmpty(response) && (
        <Alert
          variant={response.messageType}
          onClose={() => setResponse({})}
          style={{ fontSize: '15px', width: '27rem' }}
          dismissible
          className="candidate-info-close"
        >
          <span className={styles.alertInfo}> {response.message} </span>
        </Alert>
      )}
      {signUpWithRole ? (
        <>
          <ChooseRole role={role} handleRole={handleRole} />
          <GoogleButton
            onClick={() => handleGoogleSignIn()}
          >
            <img src={GoogleIcon} />
            Continue with Google
          </GoogleButton>
        </>
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          <Form className={styles.signForm}>
            <UserForm signUp={true} />
            {userRole == 'recruiter' && (
            <div className="second-action-container" style={{display: "flex",justifyContent: "space-between",alignItems: "center",marginTop: 20}}>
              <div className="remember-me-container d-flex">
                {/* <input type="checkbox" name="remember" class="sonara-checkbox checkbox1" id="id_remember"></input> */}
                <Field type="checkbox" name="agreeToBusiness" className="sonara-checkbox checkbox1" id="id_remember"/>
                <label htmlFor="id_remember" style={{ fontWeight: 500 }}>
                  <p className="auth-secondary-text" style={{ margin: 0, color: "#666" }}>
                    By creating an account, you are agreeing to our
                    <a href='/marketing_terms' target='_blank' style={{ textDecoration: "underline" }}>
                      {" "}
                      Marketing Terms
                    </a>{" "}
                  </p>
                </label>
              </div> 
            </div>
            )}
            <ErrorMessage name="agreeToBusiness" render={msg => <span width="28rem" className={styles.errorMsg}>{msg}</span>} />   
            <div className="second-action-container" style={{display: "flex",justifyContent: "space-between",alignItems: "center"}}>
              <div className="remember-me-container d-flex">
                {/* <input type="checkbox" name="remember" class="sonara-checkbox checkbox1" id="id_remember"></input> */}
                <Field type="checkbox" name="agreeToTermsAndPolicy" className="sonara-checkbox checkbox1" id="id_remember"/>
                <label htmlFor="id_remember" style={{ fontWeight: 500 }}>
                  <p className="auth-secondary-text" style={{ margin: 0, color: "#666" }}>
                    By creating an account, you are agreeing to our
                    <a href='/terms_of_service' target='_blank' style={{ textDecoration: "underline" }}>
                      {" "}
                      Terms &amp; Conditions
                    </a>{" "}
                    and{" "}
                    <a href='/privacy_policy' target='_blank' style={{ textDecoration: "underline" }}>
                      Privacy Policy
                    </a>
                  </p>
                </label>
              </div> 
            </div>
            <ErrorMessage name="agreeToTermsAndPolicy" render={msg => <span width="28rem" className={styles.errorMsg}>{msg}</span>} />   
            <div className="text-center">
              <button type="submit" disabled={loading} className="btn btn-default font-heading icon-send-letter btn1 signupbtn w-50">{loading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  'Sign Up'
                )}
              </button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  )
}

export const ChooseRole = ({ handleRole, role }) => {
  return (
    <div className={styles.responseContainer}>
      <div>
        <Label>Choose Role to Sign Up</Label>
      </div>
      <div className={styles.roleContainer}>
        <div>
          <input
            type="checkbox"
            value="employer"
            onChange={handleRole}
            checked={role === 'employer'}
          />
          <span className={styles.roleText}>Employer</span>
        </div>
        <div>
          <input
            type="checkbox"
            value="recruiter"
            onChange={handleRole}
            checked={role === 'recruiter'}
          />
          <span className={styles.roleText}>Recruiter</span>
        </div>
        <div>
          <input
            type="checkbox"
            value="talent"
            onChange={handleRole}
            checked={role === 'talent'}
          />
          <span className={styles.roleText}>Talent</span>
        </div>
      </div>
    </div>
  )
}

export default SignUp
