import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import Paginator from '../../common/Paginator/Paginator'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'


const CrossSubmissionRecruitersAnalyticPage = (prop) => {
  const {currentUser} = prop
  const queryParams = new URLSearchParams(window.location.search)
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  let jobStatus_org = sessionStorage.getItem("jobStatus_org")
  let org_type = sessionStorage.getItem("org_type")
  let recruiter_anaytic = sessionStorage.getItem("recruiter_anaytic")
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(prop.per_page)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobStatus, setJobStatus] = useState([])
  const localStorageDateRange = JSON.parse(sessionStorage.getItem('CrossSubmissionAnalyticsDateRange'))
  const startDate = (urlStartDate ? urlStartDate : (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate())
  const endDate = (urlEndDate ? urlEndDate : (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate())
  const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
  const handleDate = (date) => {
      sessionStorage.setItem('AdminCrossSubmissionRecruitersAnalyticsDateRange', JSON.stringify(date))
      setDateRange(date)
  }
  const [organizations,setOrganizations] = useState([{value: 'all', label: 'All'},...prop.organizations])
  const [recruiters,setRecruiters] = useState([{value: 'all', label: 'All'},...prop.recruiters])
  const [selectedOrganization,setSelectedOrganization] = useState(jobStatus_org && jobStatus_org != "null" ? JSON.parse(jobStatus_org).value : '')
  const [defaultselectOrganization, setDefaultSelectOrganization] = useState(jobStatus_org && jobStatus_org != "null" ? JSON.parse(jobStatus_org) : {value: 'all', label: 'All'})
  const [selectedOrganizationType,setSelectedOrganizationType] = useState(org_type && org_type != "null" ? JSON.parse(org_type).value : '')
  const [selectedRecruiters,setSelectedRecruiters] = useState(recruiter_anaytic && recruiter_anaytic != "null" ? JSON.parse(recruiter_anaytic).value : '')
  const [defaultRecruiter, setDefaultReecruiter] = useState(recruiter_anaytic && recruiter_anaytic != "null" ? JSON.parse(recruiter_anaytic) : {value: 'all', label: 'All'})
  const [defaultselected,setdefaultselected] = useState(org_type && org_type != "null" ? JSON.parse(org_type) : {value: 'all', label: 'All'})
  const [organizationType,setOrganizationType] = useState([{value: 'all', label: 'All'},{value: 'hourly', label: 'Hourly'},{value: 'contingency', label: 'Contingency'}])
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [footer, setFooter] = useState({})

  let tableHeaders = [
      { label: 'Recruiter Name', key: 'user_name' },
      { label: 'Recruiter Screened', key: 'recruitor_screen' },
      { label: 'Submitted', key: 'submitted' },
      { label: 'First Interview', key: 'firstInterview' },
      { label: 'Second Interview', key: 'secondInterview' },
      { label: 'On Hold', key: 'onhold' },
      { label: 'Offers', key: 'offers' },
      { label: 'Hired', key: 'hired' },
      { label: 'Archived', key: 'archived' },
    ]
    const columns = [
      { name: 'No.',
        selector: row => row.id
      },
      { name: 'Recruiter name',
        selector: row => row.user_name,
        sortable: true,
        sortField: 'user_name' 
      },
      { name: 'Recruiter Screened',
        selector: row => row.recruitor_screen,
        sortable: true,
        sortField: 'recruitor_screened' 
      },
      { name: 'Submitted',
        selector: row => row.submitted,
        sortable: true,
        sortField: 'submitted' 
      },
      { name: 'First Interview',
        selector: row => row.firstInterview,
        sortable: true,
        sortField: 'first_interview' 
      },
      { name: 'Second Interview',
        selector: row => row.secondInterview,
        sortable: true,
        sortField: 'second_interview' 
      },
      { name: 'On Hold',
        selector: row => row.onhold,
        sortable: true,
        sortField: 'onhold' 
      },
      { name: 'Offers',
        selector: row => row.offers,
        sortable: true,
        sortField: 'offer' 
      },
      { name: 'Hired',
        selector: row => row.hired,
        sortable: true,
        sortField: 'hired' 
      },
      { name: 'Archived',
        selector: row => row.archived,
        sortable: true,
        sortField: 'reject' 
      },
    ]

  const fetchData = async () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/cross_submission_recruiters_analytic.json'
    const response = await makeRequest(
      `${url}?page=${activePage}&start_date=${start_date}&end_date=${end_date}&organization=${selectedOrganization}&organization_type=${selectedOrganizationType}&recruiters=${selectedRecruiters}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setTotalCount(response.data.total_count)
    let sr_no = (activePage - 1) * response.data.per_page
    setJobStatus(response?.data?.data.map((data,key)=> (sr_no = sr_no + 1,{
      id: sr_no,
      user_name: data?.user_name && capitalize(data?.user_name),
      recruitor_screen: (data.recruitor_screened  > 0) ? <a href={`/admin/cross_submission_recruiter_analytic_detail?recruiter_id=${data.user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> :'',
      submitted: (data.submitted > 0) ? <a href={`/admin/cross_submission_recruiter_analytic_detail?recruiter_id=${data.user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a> :'',
      firstInterview: (data.first_interview > 0) ? <a href={`/admin/cross_submission_recruiter_analytic_detail?recruiter_id=${data.user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> :'',
      secondInterview: (data.second_interview  > 0) ? <a href={`/admin/cross_submission_recruiter_analytic_detail?recruiter_id=${data.user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> :'',
      onhold: (data.second_interview  > 0) ? <a href={`/admin/cross_submission_recruiter_analytic_detail?recruiter_id=${data.user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=onhold`}>{data.onhold}</a> :'',
      offers: (data.offer  > 0) ? <a href={`/admin/cross_submission_recruiter_analytic_detail?recruiter_id=${data.user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a> :'',
      archived: (data.reject  > 0) ? <a href={`/admin/cross_submission_recruiter_analytic_detail?recruiter_id=${data.user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> :'',
      hired: (data.hired > 0) ? <a href={`/admin/cross_submission_recruiter_analytic_detail?recruiter_id=${data.user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> :'',
      created_at: data.created_at,
      user_id:data.user_id
    })))
    setFooter({
      id:'Total',
      recruitor_screen:response.data.total_values.recruitor_screen,
      submitted:response.data.total_values.submitted,
      firstInterview:response.data.total_values.firstInterview,
      secondInterview:response.data.total_values.secondInterview,
      offers:response.data.total_values.offers,
      archived:response.data.total_values.archived,
      hired:response.data.total_values.hired
    })
    setPageCount(response.data.total_pages)
    setStartRecord((activePage - 1) * response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setLoading(false)
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/cross_submission_recruiters_analytic.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&organization=${selectedOrganization}&organization_type=${selectedOrganizationType}&recruiters=${selectedRecruiters}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.data.map((data,key)=> ({
      id: key,
      jobName: data?.job_name && capitalize(data?.job_name),
      user_name: data?.user_name && capitalize(data?.user_name),
      submitted: data.submitted,
      recruitor_screen: data.recruitor_screened,
      firstInterview: data.first_interview,
      secondInterview: data.second_interview,
      onhold: data.onhold,
      offers: data.offer,
      archived: data.reject,
      hired: data.hired,
      created_at: data.created_at,
      user_id:data.user_id
    })))
    csvExportRef?.current?.link.click();
  }

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage,selectedOrganization,selectedOrganizationType,selectedRecruiters,sortDirection,sortField])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }
  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }    
  }

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447">
            Cross Submission Analytics
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"CrossSubmissionAnalytics.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
      
        <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Organization
            </label>
            <Select
                defaultValue={defaultselectOrganization}
                options={organizations}
                onChange={(event)=>{
                  setSelectedOrganization(event.value)
                  sessionStorage.setItem("jobStatus_org", JSON.stringify(event))
                }
                }
                name="organization"
                className={styles.colourStyles}
            />
          </div>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Recruiter
            </label>
            <Select
                defaultValue={defaultRecruiter}
                options={recruiters}
                onChange={(event)=>{
                  setSelectedRecruiters(event.value)
                  sessionStorage.setItem("recruiter_anaytic", JSON.stringify(event))

                }
              }
                name="recruiters"
                className={styles.colourStyles}
            />
          </div>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Organization Type
            </label>
            <Select
                defaultValue={defaultselected}
                options={organizationType}
                onChange={(event)=>{
                  setSelectedOrganizationType(event.value)
                  sessionStorage.setItem("org_type", JSON.stringify(event))
                }
                }
                name="organization_type"
                className={styles.colourStyles}
            />
          </div>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Select Date Range
            </label>
            <DateRangePicker
                handleOnSubmit={handleDate}
                defaultDate={dateRange}
            />
          </div>
        </Col>
        <Col lg="12" md="12" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 &&
                <DisplayPagination>Displaying  {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
      </Row>
      <DataTable
            persistTableHead={true}
            noDataComponent={<p className="text-center p-3">No record found</p>}
            columns={columns}
            data={jobStatus}
            progressPending={loading}
            progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                <Spinner className='spinner' animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </P>}
            onSort={handleSort}
            sortServer
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={perPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setActivePage(page)}
            footer={footer}
        />
    </Card>
  )
}

export default CrossSubmissionRecruitersAnalyticPage