import React, { Fragment, Error,useRef, useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { useField , Formik, Field ,Form as FormSubmit } from 'formik'
import _ from 'lodash'
import { Box, Row, Button, W4text } from './styles/JobModal.styled'
import { makeRequest } from '../RequestAssist/RequestAssist'
import { CKEditor } from 'ckeditor4-react';


function InMailModal({ onClose, job ,allowUpdate }) {

    const [validationError, setValidationError] = useState({
        techTalents: null,
        inMailAdd: null,
        jdFile: null
    });

    const history = useHistory();

    const [inMailAdd, setInMailAdd] = useState(job?.in_mail_message)
    const [jdFile, setJdFile] = useState(null)
    const [allowToUpdate, setallowUpdate] = useState(!allowUpdate)
    // const [allowToUpdate, setallowUpdate] = useState(false)
    const [candidateUploadData, setCandidateUploadData] = useState({
        techTalents: [],
    })
    
    const checkValidation = () => {
        setValidationError({})
        let isValid = true
        if(_.isEmpty(inMailAdd?.trim())){
            setValidationError({
                ...validationError, inMailAdd: 'InMail message is required',
            });
            return false;
        }
        if (jdFile != null && jdFile != undefined && jdFile.type !== 'application/pdf') {
            setValidationError({
                ...validationError, jdFile: 'Only PDF files are allowed',
            });
            return false;
        }
        return isValid
    };

    const handleEditorChange = (content) => {
        setInMailAdd(content.editor.getData())
        setValidationError({})
    }

    const handleFileChange = (event) => {
        setJdFile(event.currentTarget.files[0])
        console.log(event.currentTarget.files[0],event.currentTarget.files[0].type)
        setValidationError({})
    }

    const handleTechGroupSubmit = (data,resetForm) => {
        let URL = '/jobs/update_job_in_mail'
        let method = 'post'
        const payload = new FormData()
        payload.append('inMail[in_mail_message]', inMailAdd)
        payload.append('inMail[job_id]', job?.id)
        payload.append('inMail[job_detail_file]', jdFile)
        makeRequest(URL,'post',payload , {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                if(responce['type'] == 'failure'){
                    return {
                        message: responce['message'],
                        messageType: responce['type'],
                        loading: false,
                        autoClose: true,
                    }
                }else{
                    return {
                        message: responce['message'],
                        messageType: responce['type'],
                        loading: false,
                        autoClose: true,
                    }
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: (responce) => {
                if(responce['data']['type'] == "success"){
                    window.location.reload()
                }
            },
        })
    }

    return(
        <Fragment>
            <Formik
                initialValues={{}}
                validateOnChange={false}
                validateOnBlur={false}
                validate={(values) => {
                    const errors = {}
                    checkValidation()
                    return errors
                }}
                onSubmit={(values, { setSubmitting ,resetForm }) => {
                    const isValid = checkValidation()
                    if(isValid){
                        setTimeout(() => {
                            setSubmitting(false);
                            handleTechGroupSubmit(values,resetForm);
                        }, 400);
                    }
                }}
                >
                <FormSubmit>
                <Col xs={12}>
                    <CKEditor
                            initData={inMailAdd}
                            config={{
                                versionCheck: false,
                                toolbar: [
                                    ['Cut', 'Copy', 'Paste'],
                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                    ['Bold', 'Italic', 'Strike'],
                                    ['Undo', 'Redo'],
                                    ['NumberedList', 'BulletedList', 'Blockquote', 'Outdent', 'Indent'],
                                    ['Link', 'Unlink'],
                                    ['Table', 'HorizontalRule'],
                                    ['Maximize']
                                ]
                            }}
                            readOnly={allowToUpdate}
                            onChange={handleEditorChange}
                        />
                    <Form.Control.Feedback type="invalid" style={{ display: (validationError?.inMailAdd) ? 'block' : 'none' }}>
                        {validationError?.inMailAdd}
                    </Form.Control.Feedback>
                </Col>
                <Col xs={12} className='mt-3 mb-3'>
                    <div>
                        <span style={{ fontWeight: '800',marginRight: '5%'}}> Job Description File (PDF): </span>   
                        <input type="file" name="job_detail_file" id="job_detail_file" accept="application/pdf"
                            onChange={(event) => {
                                handleFileChange(event);
                            }}
                        ></input>
                        { job.job_detail_file != null &&  job.job_detail_file != 'null' && job.job_detail_file != undefined && job.job_detail_file != 'undefined' &&
                            <>
                                <a href={job.job_detail_file} target='_blank'>View Uploaded File</a>
                            </>
                        }
                        { validationError?.jdFile && validationError?.jdFile != null &&
                            <div>
                                <span style={{color: 'red'}}>{ validationError?.jdFile }</span>
                            </div>
                        }
                        
                    </div>
                </Col>
                <Col xs={12}>
                    {!allowToUpdate &&
                        <>
                            <Button className="mt-2" type="submit">Save</Button>  <span title='Variable' style={{marginLeft: '3%',fontWeight: '500'}}>{" {PERSON_NAME}  {ROLE_NAME}  {COMPANY_NAME}"}</span>
                        </>
                    }
                </Col>
                </FormSubmit>
            </Formik>
            
            
        </Fragment>
    )

}

export default InMailModal
