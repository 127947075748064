import React, { useState } from 'react'
import styles from './styles/AccountSettingPage.module.scss'
import { capitalize } from '../../../utils'
import { Row } from 'react-bootstrap'
import {
    Wrapper,
} from './styles/AccountSettingPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import ResumeDragDrop from '../../common/ResumeDragDrop/ResumeDragDrop'


const OrganizationManagementPage = (props) => {
    const { currentUser,attachement } = props
    const { first_name, last_name } = currentUser
    const [bulkResume, setBulkResume] = useState([])
    const [validationErrors, setValidationErrors] = useState({})
    const [isEditBasicInfo, setIsEditBasicInfo] = useState(false)
    console.log(attachement)

    const fileTypes = [
        'application/pdf',
    ]

    const handleResumeFiles = (files) => {
        setBulkResume([...files])
        setValidationErrors({})
      }

    const handleOnRemoveResumes = (removeIndex) => {
        const newResumes = bulkResume.filter(
          (_, index) => removeIndex !== index
        )
        setBulkResume(newResumes)
        setValidationErrors({})
      }

      const handleSubmitResume = async () => {
        setValidationErrors({})
        if (bulkResume.length <= 0) {
          setValidationErrors({
            ...validationErrors,
            agreement: 'Please Upload Resume files.',
          })
          return
        }
        if (!fileTypes?.includes(bulkResume[0]?.type)) {
          setValidationErrors({
            ...validationErrors,
            agreement: 'Please check the format of file.',
          })
          return
        }
        if (Object.values(validationErrors).length === 0) {
            const url = `/account/save_organization_agreement`
            const payload = new FormData()
            payload.append('agreement', bulkResume[0])
            makeRequest(url, 'post', payload, {
                contentType: 'application/json',
                loadingMessage: 'Fetching...',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                    window.location.reload()
                },
            })
        }
    }

    return (
        <div className={styles.accountSettingPage}>
            <Wrapper className={`${styles.accontSetting}`}>
                <div className={`${styles.sidebar}`}>
                    <div className={`${styles.sidebar_header}`}>
                        <p>{capitalize(`${first_name} ${last_name}`)}</p>
                    </div>
                    <a href="/account/setting">General Settings</a>
                    <a href="/account/security">
                        Security & Login
                    </a>
                    <a href="/account/email_verification">Email Verification</a>
                    { (currentUser.role == 'recruiter') && 
                        <a className={`${styles.active}`}  href="/account/organization_management">Organization Membership Management</a>
                    }
                    {(currentUser.role == 'employer' || currentUser.role == 'recruiter') && 
                        <a href="/account/organization_agreement">Organization Agreement</a>
                    }
                    <a href="/account/invite_your_friend">Invite Your Friend</a>
                    {currentUser.role == 'talent' &&
                    <a href="/account/email_configuration">SMTP Email Configuration</a>
                    }
                </div>
                <div className={`${styles.containt}`} style={{ paddingBottom: '12rem' }}>
                   
                    <h3>Organization Agreement</h3>
                   
                    <div className={`${styles.basicinfo}`}>
                        <Row>
                            <h4>{!isEditBasicInfo ? 'Uploaded Agreement' : 'Upload Agreement'}</h4>
                            {/* {!isEditBasicInfo && (
                                <button
                                    className={`${styles.editButton}`}
                                    onClick={() =>
                                        setIsEditBasicInfo(!isEditBasicInfo)
                                    }
                                >
                                    Edit Agreement
                                </button>
                            )} */}
                            {isEditBasicInfo && (
                                <button
                                    className={`${styles.editButton}`}
                                    onClick={() =>
                                        setIsEditBasicInfo(!isEditBasicInfo)
                                    }
                                >
                                    Cancel
                                </button>
                            )}
                        </Row>
                        {!isEditBasicInfo ?
                            <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                            <iframe style={{width: '100%', height: '732px'}} src={attachement}>
                            </iframe>
                          </div> : 
                            <ResumeDragDrop
                            title={'Agreement'}
                            files={bulkResume}
                            handleOnRemoveFiles={handleOnRemoveResumes}
                            handleFiles={handleResumeFiles}
                            handleOnSubmit={handleSubmitResume}
                        />
                        }
                    </div>              
                </div>
            </Wrapper>
        </div>
    )
}
export default OrganizationManagementPage
