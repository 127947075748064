import { Formik } from 'formik'
import React, { useReducer, useState,useEffect, Fragment } from 'react'
import * as Yup from 'yup'
import { Col, Row } from 'react-bootstrap'
import {H1, StyledForm,Button } from '../../Admin/styles/UserManagementEditPage.styled'
import TextInput from '../../Admin/shared/TextInput'
import styles from './styles/candidate.module.scss'
import EditExperience from '../../CandidateViewProfilePage/EditExperience'
import { Button2 } from '../../CandidateViewProfilePage/styles/CandidateViewProfile.styled'
import { AgencyCandidateContext, reducer } from '../../../../stores/Agency/AgencyCandidateStore'
import Select from '../../Admin/shared/Select'
import { CSVLink } from 'react-csv'
import { merge } from 'lodash'
import isNil from 'lodash.isnil'

const redirectToCandidateManagement = () => {
    window.location.href = '/agencies/candidates/candidate_management'
 }

const CandidateManagementEditPage = ({ candidate }) => {
    const { email_address, first_name, last_name, phone_number, industry, linkedin_profile_url, title, id, active, company_names,location,school,summary,github_url,source,currency,salary } = candidate.data
    const { experiences } = candidate
    const { currencies } = candidate
    const [deleteExperienceId, setDeleteExperienceId] = useState([])
    const [state, dispatch] = useReducer(reducer, {
        candidate,
        email_address, 
        first_name, 
        last_name,
        phone_number,
        industry, 
        linkedin_profile_url, 
        title,
        experiences,
        deleteExperienceId,
        active,
        company_names,
        location,
        school,
        summary,
        github_url,
        source,
        currency,
        salary
    })
   
    const [type,setType] = useState(active)
    const [onSource,setOnSource] = useState(source || '')
    const [onCurrency,setOnCurrency] = useState(currency || 'USD')
    const [initialState, setState] = useState({ ...state })
    const [exprienceDateInvalid, setExprienceDateInvalid] = useState(true)
    const [appendLinkedinValue, setAppendLinkedinValue] = useState(candidate.data.linkedin_profile_url)
    const [appendGithubValue, setAppendGithubValue] = useState(candidate.data.github_url)
    const [validationError, setValidationError] = useState({})

    const updateCandidate = async (newUser) => {
        dispatch({ type: 'update-candidate', value: newUser,id:id })
        redirectToCandidateManagement()
    }

    const handleExperience = (event, index) => {
        const { name, value } = event.target
        const oldExperiences = initialState.experiences
        const experience = oldExperiences[index]
        const newExperience = { ...experience, [name]: value }
        oldExperiences[index] = newExperience
        setState({ ...initialState, experiences: [...oldExperiences] })
        setValidationError({})
    }
    const handleExperienceDate = (date, index, type) => {
        let oldExperiences = initialState.experiences
        let experience = oldExperiences[index]
        let updateExperience = {}
        if (type === 'startDate') {
            updateExperience = { ...experience, start_date: date }
        } else {
            updateExperience = { ...experience, end_date: date }
        }

        oldExperiences[index] = updateExperience
        setState({ ...initialState, experiences: oldExperiences })
    }
    const handleRemoveExperience = (experienceId, removeIndex) => {
        let prevExperiences = [...initialState.experiences]

        let newExperences = prevExperiences.filter(
            (_, index) => index != removeIndex
        )
        setDeleteExperienceId([...deleteExperienceId ,experienceId])
        setState({ ...initialState, experiences: newExperences })
        setValidationError({})
    }
    const handleNewExperience = () => {
        const newExperience = {
            title: '',
            location: '',
            description: '',
            company_name: '',
            start_date: '',
            end_date: '',
            present: false,
        }
        setState({
            ...initialState,
            experiences: [...initialState.experiences, newExperience],
        })
    }
    const onTypeChange = (event) => {
        setType(event.target.value)
    }
    const onSourceChange = (event) => {
        setValidationError({})
        setOnSource(event.target.value)
    }
    const onCurrencyChange = (event) => {
        setOnCurrency(event.target.value)
    }
    // Start export csv file
    let expHeader = ['first_name','last_name','phone_number', 'email_address','linkedin_profile_url','industry','title','company_names','location','school','summary','github_url','source','currancy','salary']
    let expExperienceData = []
    experiences?.map((exp,index)=>{
        Object.entries(exp).map(([key,value]) => { 
            if(key !== 'id' && key !== 'person_id' && key !== 'created_at' && key !== 'updated_at' && key !== 'discarded_at') 
            {   
                expHeader.push(`experience ${key} ${index+1}`)
                expExperienceData.push(value)
            }
        })
    })

    let expBody = []
    expBody.push(first_name)
    expBody.push(last_name)
    expBody.push(phone_number)
    expBody.push(email_address)
    expBody.push(linkedin_profile_url)
    expBody.push(industry)
    expBody.push(title)
    expBody.push(company_names)
    expBody.push(location)
    expBody.push(school)
    expBody.push(summary)
    expBody.push(github_url)
    expBody.push(onSource)
    expBody.push(onCurrency)
    expBody.push(salary)
    expBody.push(...expExperienceData)
    let expData = [expHeader,expBody]

    const validateForm = (e) => {
        let errors = {}
		let formIsValid = true
        if(initialState?.experiences?.length == 0 && deleteExperienceId.length != 0){
            formIsValid = false;
            errors["experience"] = "Minimum 1 job experience is required."
        }
        if(onSource == ''){
            formIsValid = false;
            errors["source"] = "Source is required."
        }
        initialState?.experiences?.map((exp,index)=>{
            if(exp.location == ''){
                formIsValid = false;
                errors["experience"] = "Location is required."
            }
            if(exp.start_date == ''){
                formIsValid = false;
                errors["experience"] = "Start date is required."
            }
            if(exp.company_name == ''){
                formIsValid = false;
                errors["experience"] = "Company name is required."
            }
            if(exp.title == ''){
                formIsValid = false;
                errors["experience"] = "Position Title is required."
            }
        })
        setValidationError(errors)
        return formIsValid;
    }
    return (
        <AgencyCandidateContext.Provider  value={(state, dispatch)}>
            <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
                <H1>Update Candidate Information</H1>
                <Formik
                    initialValues={{
                        email_address: email_address || '',
                        first_name: first_name || '',
                        last_name: last_name || '',
                        phone_number: phone_number || '',
                        industry: industry === 'null' ? '' : industry,
                        linkedin_profile_url: linkedin_profile_url || '',
                        title: title || '',
                        active: type || '',
                        company_names: company_names || '',
                        location: location || '',
                        school:school || '',
                        summary:summary || '',
                        github_url:github_url || '',
                        source: onSource || '',
                        currency: onCurrency || '',
                        salary:salary || ''
                    }}
                    validationSchema={Yup.object({
                        email_address: Yup.string()
                            .required('Email address is required')
                            .email('Please enter valid email address'),
                        phone_number: Yup.string().trim('')
                            .required('Phone Number is required'),
                        location: Yup.string()
                            .required('Location is required'),
                        first_name: Yup.string()
                            .required('First Name is required')
                            .test(
                                'first name alphabets only',
                                'First Name can only contain alphabet characters and one space in between if there are two words',
                                function (value) {
                                    const regex = /^[a-zA-Z0-9.]+(\s[a-zA-Z0-9.]+)?$/g
                                    return regex.test(value)
                                }
                            ),
                        last_name: Yup.string()
                            .required('Last Name is required')
                            .test(
                                'last name alphabets only',
                                'Last Name can only contain alphabet characters and one space in between if there are two words',
                                function (value) {
                                    const regex = /^[a-zA-Z0-9.]+(\s[a-zA-Z0-9.]+)?$/g
                                    return regex.test(value)
                                }
                            ),
                        github_url: Yup.string().trim('')
                            .typeError('Invalid url, please add github url only')
                            .test(
                                'github only',
                                'Invalid url, please add github url only',
                                function (value) {
                                    if(value){
                                        try {
                                            if(!value.includes("github.com/")){
                                                return false
                                            }
                                            let hostname = new URL(value).hostname
                                            setAppendGithubValue(value)
                                            return hostname.includes("github.com")
                                        } catch (error) {
                                            setAppendGithubValue(value)
                                            if(value === 'www.github.com/' || value === 'https://www.github.com' || value === 'https://www.github.com/' || value === 'github.com/' || value === 'github.com' || value === 'https://github.com' || value === 'https://github.com/'){
                                                return false
                                            }
                                            let checkDomainExist = value.indexOf("github.com/")
                                            if(checkDomainExist == -1){
                                                return false
                                            }else if(checkDomainExist == 0){
                                                let splitValue = value.split('github.com/')
                                                if(splitValue[1] === ''){
                                                    return false
                                                }
                                                let tempURL = 'https://www.'+ value
                                                setAppendGithubValue(tempURL)
                                                return true
                                            }else{
                                                let splitValue = value.split('github.com/')
                                                if(splitValue[0] === 'www.'){
                                                    let tempURL = 'https://'+ value
                                                    setAppendGithubValue(tempURL)
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                    }
                                    else
                                    {
                                        setAppendGithubValue('')
                                        return true
                                    }
                                }
                            ),
                        linkedin_profile_url: Yup.string()
                            .typeError('Invalid url, please add Linkedin url only')
                            .test(
                                'linkedin only',
                                'Invalid url, please add Linkedin url only',
                                function (value) {
                                    if(value){
                                        try {
                                            if(!value.includes("linkedin.com/")){
                                                return false
                                            }
                                            let hostname = new URL(value).hostname
                                            setAppendLinkedinValue(value)
                                            return hostname.includes("linkedin.com")
                                        } catch (error) {
                                            setAppendLinkedinValue(value)
                                            if(value === 'www.linkedin.com/' || value === 'https://www.linkedin.com' || value === 'https://www.linkedin.com/' || value === 'linkedin.com/' || value === 'linkedin.com' || value === 'https://linkedin.com' || value === 'https://linkedin.com/'){
                                                return false
                                            }
                                            let checkDomainExist = value.indexOf("linkedin.com/")
                                            if(checkDomainExist == -1){
                                                return false
                                            }else if(checkDomainExist == 0){
                                                let splitValue = value.split('linkedin.com/')
                                                if(splitValue[1] === ''){
                                                    return false
                                                }
                                                let tempURL = 'https://www.'+ value
                                                setAppendLinkedinValue(tempURL)
                                                return true
                                            }else{
                                                let splitValue = value.split('linkedin.com/')
                                                if(splitValue[0] === 'www.'){
                                                    let tempURL = 'https://'+ value
                                                    setAppendLinkedinValue(tempURL)
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                    }
                                    else
                                    {
                                        setAppendLinkedinValue('')
                                        return true
                                    }
                                }
                            ),
                        title: Yup.string().trim('')
                            .required('Position title is required'),
                        company_names: Yup.string().trim('')
                            .required('Recent Company is required'),
                        school: Yup.string().trim('')
                            .required('University is required'),
                        // source: Yup.string()
                        //     .required('Source is required'),
                        active: Yup.string()
                                .test('Candidate type is required','Candidate type is required',
                                    function(value){
                                        if(type !== '')
                                            return true
                                        return false
                                })
                    })}
                    onSubmit={(values) => {
                        console.log(values, 'valuess')
                        if (validateForm(values) && exprienceDateInvalid){
                            updateCandidate({
                                id: id,
                                email_address: values.email_address,
                                first_name: values.first_name,
                                last_name: values.last_name,
                                phone_number: values.phone_number,
                                industry: values.industry,
                                linkedin_profile_url: appendLinkedinValue,
                                title: values.title,
                                experiences: initialState.experiences,
                                remove_exp_id: JSON.stringify(deleteExperienceId),
                                active: type,
                                company_names: values.company_names,
                                location: values.location,
                                school:values.school,
                                summary:values.summary,
                                github_url:appendGithubValue,
                                source:onSource,
                                currency: onCurrency,
                                salary: values.salary,
                            })
                        }
                    }}
                > 
                    <StyledForm>
                        <Row>
                            <Col lg={2}></Col>
                            <Col xs={12} sm={12} lg={12}>
                                <Row className='d-flex w-100 justify-content-between align-items-baseline'>
                                    <span className={styles.subTitle}><b>Basic Information</b></span>
                                    <CSVLink
                                        className={`ml-3 ${styles.exportCSVButton}`}
                                        data={expData}
                                        filename={`${first_name}_${last_name}`}
                                    >
                                    Export CSV
                                    </CSVLink>
                                </Row>
                                <Row>
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="First Name*"
                                            name="first_name"
                                            type="text"
                                            id="first_name"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="Last Name*"
                                            name="last_name"
                                            type="text"
                                            id="last_name"
                                            width="100%"
                                        />
                                    </Col>
                                
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="Email Address*"
                                            name="email_address"
                                            type="text"
                                            id="email_address"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="Phone Number*"
                                            name="phone_number"
                                            type="text"
                                            id="phone_number"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="Linkdin Profile"
                                            name="linkedin_profile_url"
                                            type="text"
                                            id="linkedin_profile_url"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="GitHub Profile"
                                            name="github_url"
                                            type="text"
                                            id="github_url"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="Recent Position*"
                                            name="title"
                                            type="text"
                                            id="title"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="Recent Company*"
                                            name="company_names"
                                            type="text"
                                            id="company_names"
                                            width="100%"
                                        />
                                    </Col>

                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="Industry"
                                            name="industry"
                                            type="text"
                                            id="industry"
                                            width="100%"
                                        />
                                    </Col>
                                   
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="Localtion*"
                                            name="location"
                                            type="text"
                                            id="location"
                                            width="100%"
                                        />
                                    </Col>

                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="University*"
                                            name="school"
                                            type="text"
                                            id="school"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <Select
                                            label="Source*"
                                            name="source"
                                            type="text"
                                            id="source"
                                            width="100%"
                                            value={onSource}
                                            onChange={onSourceChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="sign_in">Registration</option>
                                            <option value="incoming_mail">Incoming Mail</option>
                                            <option value="linkedin">Linkedin</option>
                                            <option value="salesql_phone">Phone</option>
                                            <option value="salesql_email">Email</option>
                                            <option value="applicant">Applicant</option>
                                            <option value="indeed">Indeed</option>
                                            <option value="bulk_upload">Bulk Upload</option>
                                            <option value="zoom_info">Zoom Info</option>
                                            <option value='direct'>Direct</option>
                                            <option value='ziprecruiter'>ZipRecruiter</option>
                                        </Select>
                                        <label className={styles.error}>
                                            {validationError && validationError.source} 
                                        </label>
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <Select
                                            label="Currency"
                                            name="currency"
                                            type="text"
                                            id="currency"
                                            width="100%"
                                            value={onCurrency}
                                            onChange={onCurrencyChange}
                                        >
                                           {currencies?.map(data=>
                                                    <option value={data.value}>{data.label}</option> 
                                            )};
                                        </Select>
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <TextInput
                                            label="Desired Compensation"
                                            name="salary"
                                            type="text"
                                            id="salary"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <Select
                                            label="Candidate Type*"
                                            name="active"
                                            type="text"
                                            id="active"
                                            width="100%"
                                            value={type}
                                            onChange={onTypeChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="true">Active</option>
                                            <option value="false">Passive</option>
                                        </Select>
                                    </Col>
                                    
                                    <Col xs={12} sm={12} lg={12}>
                                        <TextInput
                                            label="Summary"
                                            name="summary"
                                            type="textarea"
                                            id="summary"
                                            width="100%"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <span className={styles.subTitle}><b>Experience</b></span>
                                </Row>
                                {initialState.experiences?.map((exp,index)=> {
                                    return (
                                        <Fragment key={index}>
                                            <EditExperience
                                                experience={exp}
                                                setExprienceDateInvalid={setExprienceDateInvalid}
                                                dispatch={dispatch}
                                                index={index}
                                                isLastIndex={
                                                    initialState.experiences?.length - 1 === index
                                                }
                                                handleExperience={handleExperience}
                                                handleExperienceDate={handleExperienceDate}
                                                validationError={validationError}
                                                setValidationError={setValidationError}
                                                handleRemoveExperience={
                                                    handleRemoveExperience
                                                }
                                            />
                                        </Fragment>
                                        
                                    )
                                })}
                                <Row>
                                    <Button2
                                        type='button'
                                        className={styles.subTitle}
                                        lr="16px"
                                        tb="5px"
                                        onClick={() => handleNewExperience()}
                                    >
                                        Add Experience Section
                                    </Button2>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                        <Col xs={6} sm={6} lg={6}>
                            <label className={styles.error}>
								{validationError && validationError.experience} 
							</label>
                            <div style={{ marginTop: '18px' }}>
                                <Button 
                                    type="submit"
                                    className="mr-sm-3 mt-2"
                                >
                                    Update
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => window.history.back()}
                                    className="ml-sm-3 mt-2"
                                >
                                    Go Back
                                </Button>
                            </div>
                        </Col>
                        </Row>
                    </StyledForm>
                </Formik>
            </div>
        </AgencyCandidateContext.Provider>
    )
}

export default CandidateManagementEditPage