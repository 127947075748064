import React, { useEffect } from 'react'
import feather from 'feather-icons'
import styled from 'styled-components'

const Button = styled.button`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(
        94.67deg,
        #5f78ff -1.19%,
        #7185f2 53.94%,
        #8d91ff 102.59%
    );
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        transform: scale(1);
        color: #ffff;
    }
`

function CloseButton(props) {
    const { handleClick } = props
    useEffect(() => {
        feather.replace()
    })
    return (
        <Button onClick={handleClick}>
            <i data-feather="x"></i>
        </Button>
    )
}

export default CloseButton
