import React, { useState, useEffect } from 'react'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import './../styles/communities.scss'
import SettingLeftPenal from '../SettingLeftPenal'
import Moment from 'moment'

const PayoutPage = ({ communityId, totalBalance, currentUser }) => {
  const [loading, setLoading] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalCategory, setTotalCategory] = useState(0)
  const [currentCounts, setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [payoutDatas, setPayoutDatas] = useState([])

  const fetchData = () => {
    setLoading(true)
    const url = '/communities/' + communityId + '/payout_history.json'
    makeRequest(url, 'get', { params: { page: (activePage + 1) } }, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setPayoutDatas(
          res.data.payout_histories.map((payout_history) => ({
            amount: payout_history.amount,
            created_at: payout_history.created_at,
            customer_name: payout_history.customer_name,
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalCategory(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setLoading(false)
      },
    })
  }
  useEffect(() => {
    fetchData()
  }, [activePage])

  return (
    <>
      <div className="container mt-60 communities-detail">
        <div className="row">
          <SettingLeftPenal communityId={communityId} ></SettingLeftPenal>
          <div className="col-lg-8 p-0 m-0">
            <div className="single-recent-jobs pl-20">
              <div className=''>
                <div>
                  <h4>Payouts</h4>
                  {/* <p className='pt-10'>Next payout will be $0 in 7 days.</p> */}
                </div>
                <div className="block-price mt-20 md-20 badge bg-primary">
                  <span className="fs-1">${(totalBalance > 0) ? (totalBalance / 100) : 0}</span>
                  <p className="fs-6 mt-10">Balance</p>
                </div>
              </div>
            </div>

            <section className="section-box">
              <div className="container">
                <h5 className="mb-20 mt-25">Payout history</h5>
                <div className="divider"></div>
              </div>
              <div className="list-recent-jobs mt-20 pl-20">
                {payoutDatas && payoutDatas.map((payout, index) => {
                  return (<div key={index} className="card-job hover-up wow animate__animated animate__fadeInUp mb-10">
                    <div className="card-job-top">
                      <div className="d-flex justify-content-between">
                        <h6 className="card-job-top--info-heading">{payout?.customer_name || 'N/A'}</h6>
                        <h6 className="card-job-top--info-heading">
                          ${(payout?.amount > 0) ? (payout?.amount / 100) : 0}
                        </h6>
                      </div>
                    </div>
                    <div className="card-job-description row">
                      <div className='col-md-9'>
                        {Moment(payout?.created_at).format('lll')}
                      </div>
                    </div>
                  </div>)
                })}
              </div>
              {(pageCount > 1) ?
                <div className="paginations">
                  <Paginator
                    pageCount={pageCount}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    pageWindowSize={10}
                  />
                </div>
                : ''}
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
export default PayoutPage