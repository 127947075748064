import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select'
import './style/RecruiterOrganization.scss'
import {colourStyles} from './style/RecruiterOrganization.styles'
import Util from '../../../utils/util'

function RecruiterOrganization({
    recruiterOrganizations,
    organization,
    handleChangeOrganization,   
}) {
    const [jobData, setJobData] = useState()
    const job_id = location.href.split('/')[4]
    const url = `/organization_name/${job_id}` // Depricated ondward v0.1.179
    const sortedRecruiterOrganizationsArray = (recruiterOrganizations?.recruiterOrganizationsArray)?.sort(Util.sortArray)
    const sortedRecruiterOrganizationFavArray = (recruiterOrganizations?.recruiterOrganizationFavArray)?.sort(Util.sortArray)
    const [organizationsList, setOrganizationsList] = useState([])
    useEffect(() => {
        if (job_id !== 'new' && job_id !== undefined && isNaN(job_id) === false) {
            const fetchData = async () => {
                const result = await axios(url)
                setJobData(result.data)
            }
            fetchData()
        }
    }, [])

    useEffect(() => {
        setOrganizationsList([].concat(sortedRecruiterOrganizationFavArray, sortedRecruiterOrganizationsArray))
    },[sortedRecruiterOrganizationsArray])


    const colourStyles = {
        control: styles => ({ ...styles, height: 'auto',minWidth:'15em',maxWidth:'15em',minHeight:'45px', borderRadius: '20px', color: 'rgb(76, 104, 255) !important;',backgroundColor: 'rgb(235, 237, 250)' })
      }
    return (
        <>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="color"
                styles={colourStyles}
                onChange={(event) => handleChangeOrganization(event.value)}
                options={organizationsList}
                value={
                    organization
                        ? organizationsList?.filter(
                              (recruiterOrganization) =>
                                  recruiterOrganization?.value ===
                                  organization?.id
                          )
                        : (jobData ? { value: jobData.id, label: jobData.name } : { value: '', label: 'No Organization' })
                }
            />
        </>
    )
}

export default RecruiterOrganization
