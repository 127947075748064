import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from './shared/Card'
import TableRecruiter from './shared/Table'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import Paginator from '../../common/Paginator/Paginator'
import { CSVLink } from "react-csv"
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from './shared/Button'
import P from './shared/P'
import './styles/RecruiterManagment.scss'
import feather from 'feather-icons'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import ResumeIcon from '../../../../assets/images/icons/resume-icon'
import Modal from 'react-bootstrap/Modal'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Row,Col,Spinner } from 'react-bootstrap'
import SearchBar from '../../common/SearchBar/SearchBar'
import { Input } from '@material-ui/icons'
import styles from './styles/JobsStatusPage.module.scss'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'


const AutomationAnalyticReport = (prop) => {
    var query = window.sessionStorage.getItem('analytic_automation_mail_query')
    const queryParams = new URLSearchParams(window.location.search)
    const urlStartDate = sessionStorage.getItem("analytic_automation_mail_start_date")
    const urlEndDate = sessionStorage.getItem("analytic_automation_mail_end_date")
    const jobStatus_job = sessionStorage.getItem("analytic_automation_mail_job")
    const organization = sessionStorage.getItem('analytic_automation_organization')
    let organization_data = organization && JSON.parse('['+organization+']')
    const jobId = JSON.parse(jobStatus_job)?.value
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [incomingMails, setIncomingMails] = useState([])
    const [selectedOrganization, setSelectedOrganization]  = useState(organization_data ? organization_data.map( ({value, label}) =>value) :[])
    const [filterOrganization, setFilterOrganization] = useState(organization_data && organization_data || [])
    const [orgOptions, setOptions] = useState([])
    const [open,setOpen] = useState(false)
    const [excelExport,setExcelExport] = useState(false)
    const [jobs,setJobs] = useState([{value: 'all', label: 'All'},...prop.jobs_list])
    const [selectedJob,setSelectedJob] = useState(jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job).value : jobId ? jobId : '')
    const [perPage, setPerPage] = useState(prop.perPage)
    const [totalMails,setTotalMails] = useState()
    const [currentCounts,setCurrentCounts] = useState()
    const [loading, setLoading] = useState(true)
    const [startRecord, setStartRecord] = useState(0)
    const [submissionId, setSubmissionId] = useState('')
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const [selected,setSelected] = useState((jobId)? prop.jobs_list.filter((option) => option.value == jobId) : jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job) : {value: 'all', label: 'All'})
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
    const stages = {lead: 'Lead', recruitor_screen: 'Recruiter Screened', applicant: 'Applicant',submitted: 'Submitted', submitted: 'Submitted', first_interview: 'First Interview', second_interview: 'Second Interview', onhold: 'onhold', offer: 'Offer', reject: 'Archived', hired: 'Hired'}
    const handleDate = (date) => {
        setDateRange(date)
    }
    
    const fetchData = () => {
        setLoading(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("analytic_automation_mail_start_date", start_date)
        sessionStorage.setItem("analytic_automation_mail_end_date", end_date)
        const url = ` /admin/automation_analytic.json?page=${activePage}&start_date=${start_date}&end_date=${end_date}&search=${searchTerm}&job=${selectedJob}&organization=${selectedOrganization}&sortField=${sortField}&sortDirection=${sortDirection}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (response) => {
                setTotalCount(response.data.total_count)
                setPerPage(response.data.per_page)
                let sr_no = (activePage - 1) * response.data.per_page
                setIncomingMails(
                    response.data.email_report.map((dt,key) => (sr_no = sr_no + 1,{
                        id: sr_no,
                        job_name: dt.job_name,
                        total_email: dt.total_email,
                        total_email_responed: dt.total_forwarded_email,
                        total_sms: dt.total_sms,
                        total_sms_response: dt.total_sms_received,
                        org_name: dt.org_name
                    }))
                )
                setPageCount(response.data.total_pages)
                setCurrentCounts(response.data.current_count)
                setTotalMails(response.data.total_count)
                setStartRecord((activePage - 1) * response.data.per_page) 
                setLoading(false)
                setPageCount(response.data.total_pages)
            },
        })
    }

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/admin/organizations/fetch_organization?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {organizations} = res
          setLoading(false)
          setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(orgOptions);
    }

    const handleSelectOption = (selectedOptions) => {
        setSelectedOrganization([...selectedOptions.map( ({value, label}) =>value)])
        setActivePage(1)
        sessionStorage.setItem('analytic_automation_organization',[...selectedOptions.map( ({value, label}) => JSON.stringify({'value':value, 'label':label}))])
    }

    useEffect(() => {
        fetchData()
        feather.replace()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,dateRange,sortDirection,sortField,selectedJob,selectedOrganization])

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('analytic_automation_mail_query',value)
    }

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver',backgroundColor: '#F6F7FC',minWidth:'14em',maxWidth:'14em',minHeight:'30px',marginRight: '10px',height: '42px' })
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Job Name',
            selector: row => row.job_name,
            sortable: false,
            sortField: 'first_name',
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true
        },
        {
            name: 'Organization',
            selector: row => row.org_name,
            sortable: false,
            sortField: 'org_name',
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true
        },
        {
            name: '	Email Sent',
            selector: row => row.total_email,
            sortable: false,
            sortField: 'email',
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true
        },
        {
            name: 'Email Response',
            selector: row => row.total_email_responed,
            sortable: false,
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true
        },
        {
            name: '	SMS Sent',
            selector: row => row.total_sms,
            sortable: false,
            sortField: 'email',
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true
        },
        {
            name: 'SMS Response',
            selector: row => row.total_sms_response,
            sortable: false,
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true
        }
    ]

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const colourStyleCustoms = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginRight:'14px',border: 'none',minHeight:'40px',width: '250px' })
    };
   
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{ width: '90%'}}>
                    Automation Analytics
                </P>
            </div>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '15px' }}
            >
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col xs={12} sm={12} lg={10}  className='d-flex justify-content-end p-0 filter_ui_fix'>
                    <div>
                    <label>Select Organization: </label>
                    <AsyncSelect
                        isMulti
                        isLoading={loading}
                        isClearable={true}
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={handleSelectOption}
                        placeholder={'Organization'}
                        defaultValue={filterOrganization}
                        styles={colourStyleCustoms}
                        noOptionsMessage={() => 'start typing the name of organization'}
                    />
                    </div>
                    <div>
                        <label>
                            Job Name:
                        </label>
                        <Select
                            defaultValue={selected}
                            options={jobs}
                            onChange={(event)=>{
                                setSelectedJob(event.value)
                                setActivePage(1)
                                sessionStorage.setItem("analytic_automation_mail_job", JSON.stringify(event))
                            }}
                            name="job_name"
                            styles={colourStyles}
                        />
                    </div>
                    <div>
                        <label>
                            Select Date Range:
                        </label><br/>
                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                    <div style={{marginTop: '40px'}}>
                        <SearchBar
                            placeholder="Search Job Name"
                            value={searchTerm}
                            setValue={setSearchTerm}
                            onChange={(e) => onChangeSearchTerm(e.target.value)}
                            onEnterPressed={() => {
                                activePage === 1 ? fetchData() : setActivePage(1)
                                !searchTerm && window.sessionStorage.setItem('analytic_automation_mail_query','')
                            }}
                            onClick={(e) => setSearchTerm('')}
                            style={{height:'40px'}}
                        />
                    </div>
                </Col>
            </div>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={incomingMails}
                progressPending={loading}
                progressComponent={ <div className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                responsive={true}
                onChangePage={page => setActivePage(page)}
            />
        </Card>
    )

}

export default AutomationAnalyticReport