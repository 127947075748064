import React, { useState, useEffect } from 'react'
import Table from '../Admin/shared/Table'
import Paginator from '../../common/Paginator/Paginator'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import P from '../Admin/shared/P'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import './styles/JobLead.scss'
import Util from "../../../utils/util"
import Modal from "react-bootstrap/Modal"
import moment from 'moment'

const ReviewLead = ({marketers}) => {

  const [pendingReview, setPendingReview] = useState([])
  const [show,setShow] = useState(false)
  const [alreadyReview,setAlreadyReview] = useState([])
  const [activePage, setActivePage] = useState(0)
  const [reviewedActivePage, setReviewedActivePage] = useState(0)
  const [reviewedPageCount, setReviewedPageCount] = useState(0)
  const [reviewedTotalDatas,setReviewedTotalDatas] = useState(0)
  const [reviewedStartRecord, setReviewedStartRecord] = useState(0)
  const [reviewedCurrentCounts,setReviewedCurrentCounts] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [totalDatas,setTotalDatas] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [reviewStatus, setReviewStatus] = useState()
  const [organization, setOrganization] = useState('')
  const [reviewNote,setReviewNote] = useState('')
  const [job,setJob] = useState('')
  const [checkboxValue,setCheckboxValue] = useState(false)
  const [dataId,setDataId] = useState(0)
  const [noteError,setNoteError] = useState('')
  const [loading, setLoder] = useState(false)

  const fetchData = () => {
    const url = `/job_leads.json`
    setLoder(true)
    onHide()
    makeRequest(url, 'get',  {params: {page: (activePage+1),reviewdPage : (reviewedActivePage + 1)}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setPendingReview(
          res.data.pending.map((data) => ({
            id: data.id,
            organization_id: data?.organization?.id,
            organization_name: Util.capitalize(data?.organization?.name),
            job_name: <a href={'/job_leads/' + data.id}>{Util.capitalize(data?.job?.name)}</a>,
            upload_date: data.created_at ? moment(data.created_at).format('MMM DD, YYYY') : '',
            decision: <><Button className='approveButton mr-2' onClick={ () => addData('approve',Util.capitalize(data?.job?.name),Util.capitalize(data?.organization?.name),data.id)} >Approve</Button> <Button className='rejectButton' onClick={ () => addData('reject',Util.capitalize(data?.job?.name),Util.capitalize(data?.organization?.name),data.id)}>Reject</Button></>
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalDatas(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setPerPage(res.data.per_page)
        setLoder(false)
      },
    })

    
  }

  const getReviredData = () => {
    makeRequest('/lead_reviewed', 'get',  {params: {reviewdPage : (reviewedActivePage + 1)}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setAlreadyReview(
            res.data.reviewed.map((data) => ({
              id: data.id,
              organization_id: data?.organization?.id,
              organization_name: Util.capitalize(data?.organization?.name),
              job_name: Util.capitalize(data?.job?.name),
              upload_date: data.created_at ? moment(data.created_at).format('MMM DD, YYYY') : '',
              decision: Util.capitalize( data?.review_status),
              decision_date:data.review_at ? moment(data.review_at).format('MMM DD, YYYY') : '',
              decision_note:data?.review_note,
              outreach_status:'Sent',
              marketing_contact: (data?.organization?.marketer_id) ?  (marketers[data.organization.marketer_id]) ? marketers[data.organization.marketer_id][0].first_name + ' ' + marketers[data.organization.marketer_id][0].last_name : '-' : '-'
            }))
        )
        setReviewedPageCount(res.data.review_total_pages)
        setReviewedTotalDatas(res.data.review_total_count)
        setReviewedCurrentCounts(res.data.review_current_counts)
        setReviewedStartRecord(reviewedActivePage * res.data.per_page)
      },
    })
    
  }

  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activePage])

  useEffect(() => {
    getReviredData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [reviewedActivePage])


  const updateStatus = async (id) => {
    const payload = new FormData()
    payload.append('job_leads_params[review_status]',reviewStatus)
    let reviewNoteValue = reviewNote
    if(checkboxValue === true && reviewNoteValue != ''){ 
        if(reviewNoteValue.length  < 10){
          setNoteError('Minimum 10 characters are required.')
          return false
        }
    }else if(checkboxValue === false && reviewNoteValue != ''){ 
      if(reviewNoteValue.length  < 10){
          setNoteError('Minimum 10 characters are required.')
          return false
      }
    }else if (checkboxValue === false && reviewNoteValue == ''){
      setNoteError('Note is required field.')
      return false
    }else{
       reviewNoteValue = 'Leads are not good.'
       if(reviewStatus == 'approve'){
        reviewNoteValue = 'Leads are good.'
       }
      
    }
    payload.append('job_leads_params[review_note]', reviewNoteValue)
    const URL = `/job_leads/${id}`
    await makeRequest(URL, 'post',payload, {
      createResponseMessage: () => {
        return {
          message: `Updated successfully`,
          messageType: 'success',
          loading: false,
          autoClose: true,
        }
      },
      createErrorMessage: (e) => 'Failed',
      onSuccess: () => { 
          onHide()   
          fetchData()
      },      
    })      
  }   

  const addData = (status,job,organization,id) => {
    setShow(true)
    setReviewStatus(status)
    setOrganization(organization)
    setJob(job)
    setDataId(id)
    setCheckboxValue(false)
    setNoteError('')
    setReviewNote('')
  }

  const onHide = () => {
    setShow(false)
    setCheckboxValue(false)
    setNoteError('')
    setReviewStatus('')
    setDataId(0)
    setReviewNote('')
  }

  const onInputChange = (e) => {
    setReviewNote((e.target.value).trim())
  }

  const onCheckboxChange = (e) => {
    setCheckboxValue(e.target.checked)
  }
  return (
    <>
    <div className="container">
      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px',marginBottom:'20px'}}>
          Review Leads
        </P>
      </div>

      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="21px" height="38px" color="#1D2447" style={{width:'540px',marginBottom:'20px'}}>
          Pending to Review
        </P>
        <div style={{flexGrow:1}}/>
      </div>
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
        <Col xs={12} sm={12} lg={2} className="" style={{float:'left'}}>
          {totalDatas > 0  &&
            <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord + 1} - { (totalDatas < currentCounts) ? totalDatas : currentCounts}  of  {totalDatas} entries
            </DisplayPagination>
          }
        </Col>
        <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'right'}}>

        </Col>
      </Row>  
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Organization',
              field: 'organization_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Job',
              field: 'job_name',
              editable: true,
              type: 'text',
            },
            {
              name: 'Uploaded Date',
              field: 'upload_date',
              editable: false,
              type: 'text',
              nowrap:''
            },
            {
              name: 'Decision',
              field: 'decision',
              editable: false,
              type: 'text',
            },
          ]}
          rowValues={pendingReview.map((data) => ({
              ...data,
          }))}
          activePage={activePage}
          perPage={perPage}
          loading={loading}
      />
      {pageCount > 1 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
        >
          <Paginator
              activePage={activePage}
              setActivePage={setActivePage}
              pageCount={pageCount}
              pageWindowSize={5}
              showGoToField={false}
          />
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="21px" height="38px" color="#1D2447" style={{width:'540px',marginBottom:'20px'}}>
          Already Reviewed
        </P>
        <div style={{flexGrow:1}}/>
      </div>
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
        <Col xs={12} sm={12} lg={2} className="" style={{float:'left'}}>
          {totalDatas > 0  &&
            <DisplayPagination>Displaying {(reviewedStartRecord == 0)?1:reviewedStartRecord+1} - {(reviewedTotalDatas < reviewedCurrentCounts) ? reviewedTotalDatas : reviewedCurrentCounts}  of  {reviewedTotalDatas} entries
            </DisplayPagination>
          }
        </Col>
        <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'right'}}>

        </Col>
      </Row> 
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Organization',
              field: 'organization_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Job',
              field: 'job_name',
              editable: true,
              type: 'text',
            },
            {
              name: 'Uploaded Date',
              field: 'upload_date',
              editable: false,
              type: 'text',
              nowrap:''
            },
            {
              name: 'Decision',
              field: 'decision',
              editable: false,
              type: 'text'
            },
            {
              name: 'Decision Date',
              field: 'decision_date',
              editable: false,
              type: 'text'
            },
            {
              name: 'Decision Notes',
              field: 'decision_note',
              editable: false,
              type: 'text'
            },
            {
              name: 'Marketing Contact',
              field: 'marketing_contact',
              editable: false,
              type: 'text'
            },
            {
              name: 'Outreach Status',
              field: 'decision',
              editable: false,
              type: 'text'
            }
          ]}
          rowValues={alreadyReview.map((data) => ({
              ...data,
          }))}
          activePage={reviewedActivePage}
          perPage={perPage}
          loading={loading}
      />
      {reviewedPageCount > 1 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
        >
          <Paginator
              activePage={reviewedActivePage}
              setActivePage={setReviewedActivePage}
              pageCount={reviewedPageCount}
              pageWindowSize={5}
              showGoToField={false}
          />
        </div>
      )}


    </div>

    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
                  {Util.capitalize(organization)} - {Util.capitalize(job)} - {Util.capitalize(reviewStatus)}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
              <Row className="w-100 m-0">
                <Col xs={12} sm={12} lg={12} style={{textAlign:'center'}}>
                  <p> Please add note for marketing if you have any specific instruction for them, else select the no comment checkbox</p>
                </Col>
              </Row>
              <Row className="w-100 m-0">
					       <Col xs={12} sm={12} lg={2} style={{textAlign:'right'}}>
                    <span>Note :</span>
                 </Col>
                 <Col xs={12} sm={12} lg={8}>
                    <textarea rows="5" cols="60" placeholder='Enter note here' onChange={ (e)=> onInputChange(e)}></textarea>
                 </Col>
               </Row>
               <Row className="w-100 m-0">
                  <Col xs={12} sm={12} lg={2}>
                  </Col>
                  <Col xs={12} sm={12} lg={6}>
                     <input type='checkbox' className="mr-2" onClick={ (e)=> onCheckboxChange(e)}></input> { (reviewStatus == 'approve') ? 'No comments, Leads are good' : 'No comments, Leads are not good'}.
                     <br></br>
                     <span className='spanError'>{noteError}</span>
                  </Col>
                  
               </Row>
               <Row className="w-100 m-0"  >
                  <Col xs={12} sm={12} lg={11} style={{textAlign:'right'}}>
                    <Button className="mr-4 approveButton" onClick={ ()=> updateStatus(dataId)}>Submit</Button>
                  </Col>
               </Row>
            </div>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default ReviewLead