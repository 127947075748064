import React, { useEffect, useContext, useReducer, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from './styles/CandidateViewProfilePage.module.scss'
import CandidateTwoImage from '../../../../assets/images/img_avatar.png'
import moment from 'moment'
import { capitalize, stringReplace } from '../../../utils'
import feather from 'feather-icons'
import Modal from '../../common/Styled components/Modal'
import EditCandidateProfile from './EditCandidateProfile'
import styled from 'styled-components'
import { reducer, UserProfileContext } from '../../../stores/UserProfileStore'
import LinkedinIcon from '../../../../assets/images/icons/linkedin-icon.svg'
import { Badge } from 'react-bootstrap'

const Link = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    > svg {
        margin-right: 10px;
        margin-bottom: 2px;
    }
`

const CandidateViewProfilePage = (props) => {
    const [open, setOpen] = useState(false)
    const [more, setMore] = useState(false)
    const [profileFlag, setProfileFlag] = useState(0)
    const { currentUser, person, avatar_url, experiences, educations, skills,resume_url, full_user, audio_video_url } = props
    const { description, title, school, location, employer, portfolio, authorization, transfer, states, compensation, sponsorship, linkedin_profile_url } = person
    const { first_name, last_name } = currentUser
    let initialState = {
        ...initialState,
        ...{
            currentUser,
            person,
            first_name,
            last_name,
            description,
            title,
            school,
            employer,
            location,
            skills,
            avatar_url,
            experiences,
            educations,
            deleteExperienceId: [],
            deleteEducationId: [],
            skillset: '',
            portfolio,
            linkedin_profile_url,
            authorization,
            transfer,
            states,
            compensation,
            sponsorship
        },
    }
    console.log("🚀 ~ file: CandidateViewProfilePage.js:33 ~ CandidateViewProfilePage ~ initialState", initialState)
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        feather.replace()
    })

    const format_logo_url = (avatar_url) => {
        if (typeof avatar_url == 'object') {
            return URL.createObjectURL(avatar_url)
        }
        return avatar_url
    }

    const loadMore = () => {
        setMore(true)
    }
    const loadLess = () => {
        setMore(false)
    }

    return (
        <UserProfileContext.Provider value={{ state, dispatch }}>
            <div className="candidate-view-profile-page">
                <div
                    className={`${styles.profileHeading} jumbotron jumbotron-fluid`}
                >
                    <div className="container"></div>
                </div>
                <div className="container">
                    <div className={`${styles.profileImageSection}`}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Image
                                    src={
                                        state.avatar_url == null ||
                                            state.avatar_url == ''
                                            ? CandidateTwoImage
                                            : format_logo_url(state.avatar_url)
                                    }
                                    className={`${styles.profileImage}`}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <button
                                    className={`${styles.editProfileButton}`}
                                    onClick={() => {
                                        setProfileFlag(0)
                                        setOpen(!open)
                                    }}
                                >
                                    Edit Profile
                                </button>
                                <Modal
                                    isOpen={open}
                                    s
                                    onBlur={() => setOpen(!open)}
                                >
                                    <EditCandidateProfile
                                        profileFlag={profileFlag}
                                        resume_url={resume_url}
                                        full_user={full_user}
                                        open={open}
                                        persons={person}
                                        setOpen={setOpen}
                                        format_logo_url={format_logo_url}
                                        audio_video_url={audio_video_url}
                                    />
                                </Modal>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`${styles.profileName} mt-2`}>
                                {capitalize(
                                    `${(state.first_name != null && state.first_name != 'null') ? state.first_name : ''} ${(state.last_name != null && state.last_name != 'null') ? state.last_name : ''}`
                                )}
                            </h2>
                        </Col>
                        <Col
                            xs={12}
                            className={`${styles.profileheading} d-flex`}
                        >
                            <Headline profileData={state} />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={12} sm={12} md={4} lg={4} className="mb-5">
                            <div className={`${styles.profileAboutme}`}>
                                <h4 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                    About Me
                                    <SvgImage setProfileFlag={setProfileFlag} flag={1} setOpen={setOpen} open={open} />
                                </h4>
                                <AboutMe
                                    setOpen={setOpen}
                                    open={open}
                                    description={((state.description != null && state.description != 'null') ? state.description : '') || ''}
                                />
                            </div>
                            <div className={`${styles.profileSkills} mt-5`}>
                                <h4 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                    Skills
                                    <SvgImage setProfileFlag={setProfileFlag} flag={1} setOpen={setOpen} open={open} />
                                </h4>
                                <Skills
                                    setOpen={setOpen}
                                    open={open}
                                    skills={skills}
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <h4 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                Experience
                                <SvgImage setProfileFlag={setProfileFlag} flag={2} setOpen={setOpen} open={open} />
                            </h4>
                            <Experiences
                                setOpen={setOpen}
                                open={open}
                                experiences={props.experiences}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={8} lg={8} className='mb-5'>
                            <h4 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                Education
                                <SvgImage setProfileFlag={setProfileFlag} flag={3} setOpen={setOpen} />
                            </h4>
                            <Educations
                                setOpen={setOpen}
                                open={open}
                                educations={props.educations}
                            />
                            
                            {(more && currentUser.role == 'talent') &&
                                <>
                                <div className={`${styles.profileAboutme}`}>
                                    <h6 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                        Portfolio and Password
                                        <SvgImage setProfileFlag={setProfileFlag} flag={1} setOpen={setOpen} open={open} />
                                    </h6>
                                    <Portfolio
                                        setOpen={setOpen}
                                        open={open}
                                        portfolio={((person.portfolio != null && person.portfolio != 'null') ? person.portfolio : '') || ''} />
                                </div>
                                <div className={`${styles.profileAboutme}`}>
                                        <h6 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                            Are you authorized to work in the United States?
                                            <SvgImage setProfileFlag={setProfileFlag} flag={1} setOpen={setOpen} open={open} />
                                        </h6>
                                        <Authorization
                                            setOpen={setOpen}
                                            open={open}
                                            authorization={((person.authorization != null && person.authorization != 'null') ? person.authorization : '') || ''} />
                                </div>
                                <div className={`${styles.profileAboutme}`}>
                                        <h6 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                            All roles posted in United States locations require that you be located within one of the 50 U.S. States. Will you perform the job responsibilities within one of the 50 U.S. States?
                                            <SvgImage setProfileFlag={setProfileFlag} flag={1} setOpen={setOpen} open={open} />
                                        </h6>
                                        <Transfer
                                            setOpen={setOpen}
                                            open={open}
                                            transfer={((person.transfer != null && person.transfer != 'null') ? person.transfer : '') || ''} />
                                </div>
                                <div className={`${styles.profileAboutme}`}>
                                        <h6 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                            Which state do you reside in?
                                            <SvgImage setProfileFlag={setProfileFlag} flag={1} setOpen={setOpen} open={open} />
                                        </h6>
                                        <State
                                            setOpen={setOpen}
                                            open={open}
                                            state={((person.state != null && person.state != 'null') ? person.state : '') || ''} />
                                </div>
                                <div className={`${styles.profileAboutme}`}>
                                        <h6 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                            What's your expected compensation if you are willing to share?
                                            <SvgImage setProfileFlag={setProfileFlag} flag={1} setOpen={setOpen} open={open} />
                                        </h6>
                                        <Compensation
                                            setOpen={setOpen}
                                            open={open}
                                            compensation={((person.compensation != null && person.compensation != 'null') ? person.compensation : '') || ''} />
                                </div>
                                <div className={`${styles.profileAboutme}`}>
                                        <h6 className={`d-flex align-items-center ${styles.profileHeadingTitle}`}>
                                            Will you, now or in the future, require sponsorship for employment in the U.S.?
                                            <SvgImage setProfileFlag={setProfileFlag} flag={1} setOpen={setOpen} open={open} />
                                        </h6>
                                        <Sponsorship
                                            setOpen={setOpen}
                                            open={open}
                                            sponsorship={((person.sponsorship != null && person.sponsorship != 'null') ? person.sponsorship : '') || ''} />
                                </div>
                                </>
                            }

                            {(more == false && currentUser.role == 'talent') && <Badge
                                style= {{marginTop: '10px'}}
                                // pill
                                // variant="secondary"
                                // className='skills-more-button  d-inline-block text-truncate'
                                onClick={loadMore}
                            >
                                <label>View More</label>
                            </Badge>}
                            
                        
                            {(more && currentUser.role == 'talent') && <Badge
                                style= {{marginTop: '10px'}}
                                // pill
                                // variant="secondary"
                                // className='skills-more-button  d-inline-block text-truncate'
                                onClick={loadLess}
                            >
                                <label>View Less</label>
                            </Badge>}
                           
                        </Col>
                    </Row>
                </div>
            </div>
        </UserProfileContext.Provider>
    )
}

function SvgImage({setOpen, open, flag, setProfileFlag}) {
    return (<div className={`${styles.edit}`} onClick={() =>{
        setProfileFlag(flag)
        setOpen(!open)
        }}>
        <li-icon type="pencil-icon" size="medium" role="img" aria-label="Edit about"><svg xmlns="http://www.w3.org/2000/svg" color='#00000099' viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" class="mercado-match" width="24" height="24" focusable="false">
            <path d="M21.13 2.86a3 3 0 00-4.17 0l-13 13L2 22l6.19-2L21.13 7a3 3 0 000-4.16zM6.77 18.57l-1.35-1.34L16.64 6 18 7.35z"></path>
        </svg></li-icon>
    </div>)
}

function Headline({ profileData }) {

    const { title, employer, location, person, currentUser } = profileData
    return (
        <>
            <span>{title}</span>
            {!!employer && (
                <>
                    <div className={`${styles.profileheadingseprate}`}></div>
                    <span>{employer}</span>
                </>
            )}
            {!!location && (
                <>
                    <div className={`${styles.profileheadingseprate}`}></div>
                    <span>{location}</span>
                </>
            )}
            {person?.linkedin_profile_url && (
                <> 
                    <div className={`${styles.profileheadingseprate}`}>

                    </div>
                            <a href={currentUser?.linkedin_profile_url}><img className="mr-3" src={LinkedinIcon} /></a>
                </>
            )}
        </>
    )
}

function Skills({ skills }) {
    if (skills.length == 0)
        return (
            <span>No Skills</span>
        )
    return (
        <>
            {skills.map((skill, index) => (
                <span key={index}>
                    {capitalize(stringReplace(skill, '_', ' '))}
                </span>
            ))}
        </>
    )
}

function Experiences({ experiences }) {
    if (experiences.length == 0)
        return (
            <span>No Experience</span>
        )

    return (
        <>
            {experiences.map((experience, index) => (
                <div className={`${styles.experienc}`} key={index}>
                    <h5 className={`${styles.experienceHeader}`}>{experience.title}</h5>
                    <h6>{experience.company_name}</h6>
                    <h6 className={`${styles.experienceDate}`}>
                        {ExperienceDate(experience?.start_date)} {(ExperienceDate(experience?.start_date) === ' ') ? '' : '-'}
                        {experience.present == true
                            ? 'Present'
                            : ExperienceDate(experience?.end_date)}
                    </h6>
                    <div className="clearfix"></div>
                    <h6 className={`${styles.experienceDetail}`}>
                        {experience.location}
                    </h6>
                    {experience.description ?? (
                        <ul>
                            {experience?.description?.split('\n')
                                .map((description, index) => {
                                    return (<li key={index}>{description}</li>)
                                })}
                        </ul>
                    )}
                </div>
            ))}
        </>
    )
}

function Educations({ educations }) {
    if (educations.length == 0)
        return (
            <span>No Education</span>
        )

    return (
        <>
            {educations.map((education, index) => (
                <div className={`${styles.experienc}`} key={index}>
                    <h5 className={`${styles.educationHeader}`}>
                        {education.school_name}
                    </h5>
                    <h6 style={{ lineHeight: '15px', fontSize: '14px' }}>
                        {education.degree}
                    </h6>
                    <h6 className={`${styles.educationDate}`}>
                        {EducationDate(education?.from)} {(EducationDate(education?.to) === ' ') ? '' : '-'}
                        {education.present == true
                            ? 'Present'
                            : EducationDate(education?.to)}
                    </h6>
                    <div className="clearfix"></div>
                </div>
            ))}
        </>
    )
}

function AboutMe({ description }) {
    if (description == '')
        return (
            <span>No Description</span>
        )
    return (
        <>
            <div
                className={`${styles.profileDetail}`}
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </>
    )
}

function Portfolio({ portfolio }) {
    if (portfolio == '')
        return (
            <span>No Portfolio</span>
        )
    return (
        <>
            <div
                className={`${styles.profileDetail}`}
                dangerouslySetInnerHTML={{ __html: portfolio }}
            />
        </>
    )
}

function Authorization({ authorization }) {
    if (authorization == '')
        return (
            <span>No Authorization</span>
        )
    return (
        <>
            <div
                className={`${styles.profileDetail}`}
                dangerouslySetInnerHTML={{ __html: authorization }}
            />
        </>
    )
}

function Transfer({ transfer }) {
    if (transfer == '')
        return (
            <span>No Transfer</span>
        )
    return (
        <>
            <div
                className={`${styles.profileDetail}`}
                dangerouslySetInnerHTML={{ __html: transfer }}
            />
        </>
    )
}

function State({ state }) {
    if (state == '')
        return (
            <span>No State</span>
        )
    return (
        <>
            <div
                className={`${styles.profileDetail}`}
                dangerouslySetInnerHTML={{ __html: state }}
            />
        </>
    )
}

function Compensation({ compensation }) {
    if (compensation == '')
        return (
            <span>No Compensation</span>
        )
    return (
        <>
            <div
                className={`${styles.profileDetail}`}
                dangerouslySetInnerHTML={{ __html: compensation }}
            />
        </>
    )
}

function Sponsorship({ sponsorship }) {
    if (sponsorship == '')
        return (
            <span>No</span>
        )
    return (
        <>
            <div
                className={`${styles.profileDetail}`}
                dangerouslySetInnerHTML={{ __html: sponsorship }}
            />
        </>
    )
}

function ExperienceDate(date) {
    if (!date || date == '') return ' '
    return moment(date).format('MMM YYYY')
}

function EducationDate(date) {
    if (!date || date == '') return ' '
    return moment(date).format('MMM YYYY')
}

export default CandidateViewProfilePage
