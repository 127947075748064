import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const RecruitersAssignmentsAnalyticPage = (team_leads) => {
  const [recruiters,setRecruiters] = useState([])
  let team_lead = sessionStorage.getItem('team_lead');
  let recruiter_org = sessionStorage.getItem('recruiter_analytics_org');
  const [account_managers,setAccountManagers] = useState([{value: '', label: 'All'},...team_leads.team_leads])
  const [defaultTeamLead, setDefaultTeamLead] = useState(team_lead && team_lead != "null" ? JSON.parse(team_lead) : [])
  const [selectedTeamLead,setSelectedTeamLead] = useState(team_lead && team_lead != "null" ? JSON.parse(team_lead).value : [])
  const [isLoading, setLoading] = useState(false)
  const [member_options, setOptions] = useState([])
  const [selectedOrganizationValue, setSelectedOrganizationValue]  = useState(team_leads.organization ? team_leads.organization[0]  : recruiter_org && recruiter_org != "null" ? JSON.parse(recruiter_org) : '')
  const [selectedOrganization, setSelectedOrganization]  = useState(team_leads.organization ? team_leads.organization[0].value  : recruiter_org && recruiter_org != "null" ? JSON.parse(recruiter_org).value : '')
  const [inputValue, setInputValue] = useState('')
  const [exportDatas, setExportDatas] = useState([])
  const [totalValues,setTotalValues] = useState({})
  const csvExportRef = React.useRef();
  const [loading, setLoader] = useState(true)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [footer, setFooter] = useState({})

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  };

  const header = [
    { label: 'Recruiter Name', key: 'fullName' },
    { label: 'Assigned Hourly Organization', key: 'orgHourly' },
    { label: 'Assigned Contingency Organization', key: 'orgContingency' },
    { label: 'Assigned Job Count', key: 'jobCount' },
  ]

  const tableColumn = [
    { name: 'No.',field: 'id',editable: false,type: 'text' },
    { name: 'Recruiter Name',field: 'fullName',editable: false,type: 'text' },
    { name: 'Assigned Hourly Organization',field: 'hourly',editable: false,type: 'text'},
    { name: 'Assigned Contingency Organization',field: 'contingency',editable: false,type: 'text'},
    { name: 'Assigned Job Count',field: 'jobCount',editable: false,type: 'text',}
  ]
  const fetchData = async () => {
    setLoader(true)
    const url = '/admin/recruiters_assignments_analytic.json'
    const response = await makeRequest(
      `${url}?organization=${selectedOrganization}&team_lead=${selectedTeamLead}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    let sr_no = 0
    setRecruiters(response?.data?.recruiters.map((data,key)=> (sr_no = sr_no + 1,{
      id: sr_no,
      fullName: `${data?.full_name && capitalize(data?.full_name)} ${data?.team_name && `(${data?.team_name})`}`,
      userId: data?.user_id,
      teamName: data?.team_name,
      orgContingency: data?.org_contingency,
      orgContingencyName: (data.user_id) ? (data?.contingency) ? `${data?.org_contingency} (${data?.contingency})` : '' : (data.org_contingency) ? data.org_contingency : 0,
      orgHourlyName: (data.user_id) ? (data?.hourly) ? `${data?.org_hourly} (${data?.hourly})` : '' : (data.org_hourly) ? data.org_hourly : 0,
      orgHourly: data?.org_hourly,
      contingency: data.contingency,
      hourly: data.hourly,
      jobCount: data.job_count,
    })))
    setFooter({
      id:'Total',
      orgHourly:response.data.total_values.hourly,
      orgContingency:response.data.total_values.contingency ,
      jobCount:response.data.total_values.jobCount

    })
    setLoader(false)
  }

  const columns = [
    {
        name: 'No.',
        selector: row => row.id,
        maxWidth: '4%',
        minWidth: '4%'
    },
    {
        name: 'Recruiter Name',
        selector: row =><a href={`/admin/recruiters_assignments_analytic/${row.userId}`}>{row.fullName}</a>,
        cell: (row) => <div title={row.fullName}><a href={`/admin/recruiters_assignments_analytic/${row.userId}`}>{row.fullName}</a></div>,
        wrap:true,
        sortable: true,
        sortField: 'full_name'
    },
    {
        name: 'Assigned Hourly Organization',
        selector: row => row.userId ? (<>{`${row.orgHourly}`} {row.hourly && `(${row.hourly})`}</>) : row.orgHourly,
        cell: (row) => <div title={row.orgHourlyName}>{row.orgHourlyName ? row.orgHourlyName : 0}</div>,
        wrap:true,
        sortable: true,
        sortField: 'org_hourly'
    },
    {
        name: 'Assigned Contingency Organization',
        selector: row => row.userId ? (<>{`${row.orgContingency}` } {row.contingency && `(${row.contingency})`}</>) : row.orgContingency,
        cell: (row) => <div title={row.orgContingencyName}>{row.orgContingencyName ? row.orgContingencyName : 0}</div>,
        wrap:true,
        sortable: true,
        sortField: 'org_contingency'
    },
    {
        name: 'Assigned Job Count',
        selector: row => row.jobCount,
        sortable: true,
        sortField: 'job_count'
    }
]

  useEffect(()=>{
    fetchData()
  },[selectedOrganization,selectedTeamLead,sortDirection,sortField])

  const loadOptions = (inputValue, callback) => {
    setLoading(true)
    fetch(`/admin/organizations/fetch_organization?search=${inputValue}`)
    .then((res) => res.json())
    .then((res) => {
      let {organizations} = res
      setLoading(false)
      setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(member_options);
  }

  const onSelectedTeamLead = (event) => {
    setSelectedTeamLead(event.value)
    sessionStorage.setItem("team_lead", JSON.stringify(event))

}

const handleInputChange = (str) => {
  setInputValue(str)
  return str;
}

const handleSelectOption = (selectedOptions) => {
  setSelectedOrganization(selectedOptions?.value || '')
  setSelectedOrganizationValue(selectedOptions || {})
  sessionStorage.setItem("recruiter_analytics_org", JSON.stringify(selectedOptions))
}

const exportDataMethod = async () => {
  const url = '/admin/recruiters_assignments_analytic.json'
    const response = await makeRequest(
      `${url}?organization=${selectedOrganization}&team_lead=${selectedTeamLead}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.recruiters.map((data,key)=> ({
      id: key,
      fullName: `${data?.full_name && capitalize(data?.full_name)} ${data?.team_name && `(${data?.team_name})`}`,
      teamName: data?.team_name,
      orgContingency: `${data?.org_contingency} ${data.contingency && `(${data.contingency})`}`,
      orgHourly: `${data?.org_hourly} ${data.hourly && `(${data.hourly})`}`,
      jobCount: data.job_count,
    })))
    csvExportRef?.current?.link.click();
}

const handleSort = async (column, sortDirection) => {
  console.log(column.sortField,sortDirection)
  if(column.sortField != undefined && sortDirection != undefined){
      setSortField(column.sortField)
      setSortDirection(sortDirection)
  }
}

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Recruiters Assignments Analytic
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={header}
        filename={"RecruitersAssignmentsAnalytic.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
          <div style={{display: 'grid'}}>
              <label>
                Team Lead
              </label>
              <Select
                  defaultValue={defaultTeamLead}
                  options={account_managers}
                  onChange={onSelectedTeamLead}
                  name="team_lead"
                  className={styles.colourStyles}
              />
          </div>
          <div style={{display: 'grid'}}>
            <label>
              Organization
            </label>
            <AsyncSelect
              isLoading={isLoading}
              isClearable={true}
              cacheOptions
              defaultValue={selectedOrganizationValue}
              loadOptions={loadOptions}
              defaultOptions={selectedOrganization}
              onInputChange={handleInputChange}
              onChange={handleSelectOption}
              placeholder={'Search by organization'}
              styles={colourStyles}
              noOptionsMessage={() => 'start typing the name of organization'}
          />
          </div>
      </Col>
      </Row>
      <DataTable
          persistTableHead={true}
          noDataComponent={<p className="text-center p-3">No record found</p>}
          columns={columns}
          data={recruiters}
          progressPending={loading}
          progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
              <Spinner className='spinner' animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>
          </P>}
          onSort={handleSort}
          sortServer
          highlightOnHover
          responsive={true}
          footer={footer}
      />
    </Card>
  )
}

export default RecruitersAssignmentsAnalyticPage