import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: wrap;
  justify-content: ${(props) => (props.jContent ? props.jContent : 'unset')};
  align-items: ${(props) => (props.aItems ? props.aItems : 'unset')};
  margin-bottom: ${({ gutterButtom }) => gutterButtom && '10px'};
  width: ${({ width }) => width && width};
`;

export const Row = styled(Box)`
  width: 100%;
  padding: ${({ padding }) => padding};
  margin-top: ${({ gutterTop }) => gutterTop && '10px'};
`;

export const Container = styled(Box)`
  padding: ${({ padding }) => padding};
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > svg {
    color: red;
    background: #f5f7ff;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    align-self: flex-end;
  }
`;
