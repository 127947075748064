import React, { useState, useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'

const ActionBar = (props) => {
  
  const { data, setBulkIds, children, username, setToggleClearRows  } = props
  const dismiss = () => {
    data.map((id,index)=> {
      {username ? setToggleClearRows(true) : document.getElementById(`chk_${id}`).checked = false}
      // document.getElementById(`chk_${id}`).checked = false
    })
    setBulkIds([])
  }
  
  return (
    <div className='action-bar'>
        <Row className='h-100'>
          <Col xs={8} sm={8} lg={8} className='align-self-center'>
            <span className='text-light font-weight-bold ml-3'>{`${data.length} ${username ? username : 'Candidates'} Selected`}</span>
          </Col>
          <Col xs={4} sm={4} lg={4} className='align-self-center'>
            <div className='d-flex justify-content-end mr-3'>
              <ul className='mt-auto mb-auto'>
                {children}
                <Button className='text-light font-weight-bold text-decoration-none' onClick={()=> dismiss()}>Cancel</Button>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    )
}
export default ActionBar