import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../styles/AiInterview.scss'
import { capitalize } from '../../../../utils'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Row,Col,Spinner } from 'react-bootstrap'
import moment from 'moment'
import DataTable from "react-data-table-component-footer"
import { DisplayPaginationAI } from '../../Analytic/styles/AnalyticPage.styled'
import   '../styles/DatatableAICustom.scss'
import InviteInterviewPage from './InviteInterviewPage'
import AiDateRangePicker from '../../../common/DateRangePicker/AiDateRangePicker'
import AiSearchBar from '../../../common/SearchBar/AiSearchBar'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Select from 'react-select'
import { event } from 'react-ga'

const InvitesListPage = ({organization, interview, job, per_page, interviewCompletedCount}) => {
    const [loading, setLoading] = useState(true)
    const [candidates, setCandidates] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalDatas,setTotalDatas] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [sortField, setSortField] = useState('')
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(per_page)
    const [sortDirection, setSortDirection] = useState('')
    const [archivedId, setArchivedId] = useState('')
    const [resendLinkCount, setResendLinkCount] = useState(0)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [selectInterviewCandidate, setSelectInterviewCandidate] = useState(null)
    const [showScoreModal, setShowScoreModal] = useState(false)
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState('')
    const [inviteInterviewModal, setInviteInterviewModal] = useState(false)
    const [showInviteConfirmation,setShowInviteConfirmation] = useState(false)
    const [dateRange, setDateRange] = useState({startDate: moment().startOf('month').toDate() ,endDate: moment().toDate()})
    const handleDate = (date) => {
        setDateRange(date)
    }
    const [showRestoreConfirmation, setShowRestoreConfirmation] = useState(false)
    var status = window.localStorage.getItem('ai_interview_invite_status')
    const [selectedStatus, setSelectedStatus]  = useState(status || 'active')
    const [selected, setSelected] = useState({value: status || 'active', label: status == 'archieved' ? 'Archieved' :  'Active' })
    const colourStatusStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px', width: '160px' })
    };

    const filterOptions = [
        { value: 'active', label: 'Active'},
        { value: 'archieved', label: 'Archieved' }
    ]

    const handleSelectStatus = (selectedval) =>{
        setSelectedStatus(selectedval.value)
        window.localStorage.setItem('ai_interview_invite_status',selectedval.value)
    }
   
    const columns = [
        { name: 'No.',
          selector: row => row.id,
          maxWidth: '6%',
          minWidth: '6%'
        },
        { name: 'Candidate Name',
          selector: row => row.name,
          sortable: true,
          sortField: 'candidate_name',
          maxWidth: '22%',
          minWidth: '22%'
        },
        { name: 'Candidate Email',
          selector: row => row.email,
          sortable: true,
          sortField: 'candidate_email',
          maxWidth: '25%',
          minWidth: '25%'
        },
        { name: 'Invited On',
          selector: row => row.created_at,
          sortable: true,
          sortField: 'created_at',
          maxWidth: '12%',
          minWidth: '12%'
        },
        { name: 'Status',
          selector: row => row.status,
          sortable: true,
          sortField: 'status',
          maxWidth: '12%',
          minWidth: '12%'
        },
        {
            name: 'Action',
            cell: (row, index) => getMenu(row.row_obj, index),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            maxWidth: '22%',
            minWidth: '22%'
        }
    ]
    // Fetch existing interview question 
    const getInterviewInvites = async (id) =>{
        setOpenMenuIndex(null)
        setCandidates([])
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const url =`/ai_interviews/${id}/invites.json?page=${activePage}&sortField=${sortField}&sortDirection=${sortDirection}&start_date=${start_date}&end_date=${end_date}&search=${searchTerm}&status=${selectedStatus}`
        const response = await axios.get(url).then(res=>res).catch(error=>console.log(error))
        if(response?.status){
            setCandidates(response.list)
            setTotalCount(response.data.total_count)
            let sr_no = (activePage - 1) * response.data.per_page
            setCandidates(response?.data?.list.map((data,key)=> (sr_no= sr_no + 1,{
              id: sr_no,
              row_obj: data,
              name: (data?.status == 'Completed') ?  <a href={`/ai_interviews/${data.id}/report`}>{capitalize(data?.candidate_name)}</a> : capitalize(data?.candidate_name),
              email: data?.candidate_email,
              created_at: moment(data.updated_at).format('MMM DD, YYYY'),
              status: data?.status
            })))
            setPageCount(response.data.total_pages)
            setStartRecord((activePage - 1) * response.data.per_page)
            setCurrentCounts(response.data.current_counts)
            setLoading(false)
        }
    }

    useEffect (() => {
        getInterviewInvites(interview.id)
    },[dateRange,activePage,sortField,sortDirection,selectedStatus])
  
    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
    }

    const handleMenuToggle = (index) => {
        setOpenMenuIndex(openMenuIndex === index ? null : index);
    }

    const onDeleteClick = (obj, event) => {
        setArchivedId(obj.id)
        setShowDeleteConfirmation(true)
        setSelectInterviewCandidate(obj)
        setOpenMenuIndex(null)
    }

    const onRestoreInterviewInvite = () => {
        if(archivedId != '' && archivedId != null){
            const url = `/ai_interviews/invite/${archivedId}/restore`
            makeRequest(url, 'get',  {}, {   
            contentType: 'application/json',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                    if(res.data.success){
                        setArchivedId('')
                        setSelectInterviewCandidate(null)
                        getInterviewInvites(interview.id)
                    }
            },
            })
        }
    }

    const onRestoreClick = (obj, e) => {
        setArchivedId(obj.id)
        setSelectInterviewCandidate(obj)
        setShowRestoreConfirmation(true)
        setOpenMenuIndex(null)
    }

    const onResendClick = (obj, event) => {
        setResendLinkCount(obj.resend_invite)
        setArchivedId(obj.id)
        setShowInviteConfirmation(true)
    }

    const sendInviteLink = () => {
        if(archivedId != '' && archivedId != null){
            const url = `/ai_interviews/resend_invitation/${archivedId}`
            makeRequest(url, 'get',  {}, {   
                contentType: 'application/json',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                        if(res.data.success){
                            setOpenMenuIndex(null)
                            setArchivedId('')
                            getInterviewInvites(interview.id)
                        }
                },
            })
        }
            setOpenMenuIndex(null)
            setResendLinkCount(0)
    }

    // Delete candidate 
    const onDeleteInviteCandidate = () => {
        if(archivedId != '' && archivedId != null){
            const url = `/ai_interviews/invite/${archivedId}/delete`
            makeRequest(url, 'delete',  {}, {   
            contentType: 'application/json',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                    if(res.data.success){
                        getInterviewInvites(interview.id)
                        setSelectInterviewCandidate(null)
                        setArchivedId('')
                    }
            },
            })
        }
    }

    const getMenu = (obj, index) => {
        return (
            <div className="action-menu-table">
                <div className="kebab-menu" onClick={() => handleMenuToggle(index)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                {openMenuIndex === index && (
                    <>
                    <ul className="submenu-table">
                        { (obj.discarded_at == null) ?
                            <>
                                <li><button onClick={(event) => onDeleteClick(obj, event)}>Archive</button></li>
                                {(obj.status == 'Inprogress' || obj.status == 'Pending') && (
                                    <li><button onClick={(event) => onResendClick(obj, event)}>Resend Invitation</button></li>
                                )}
                            </> : 
                            <li><button onClick={(event) => onRestoreClick(obj, event)}>Restore</button></li>
                        }
                     </ul>
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="container-fluid ai-containter">
                <div className="d-flex justify-content-between align-items-center pb-3 mt-5 ">
                    <div className="d-inline-flex align-items-center">
                        { organization && organization?.image_url && organization?.image_url != null &&
                            <img
                                alt={ organization.name} src={ organization.image_url}
                                width="50" height="50"
                                className="d-inline-block align-top"
                            />
                        }
                        {' '}
                        <div className="ml-2">
                            <p className="mb-n1">{ organization.name}</p>
                            <a href={ organization.website_url} target='_blank' className="text-decoration-underline">{ organization.website_url }</a>
                        </div>
                    </div>
                </div>
                <div className="border rounded-lg p-4 shadow">
                    <div className="d-flex justify-content-between align-items-center">
                        <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb breadcrumb-ai">
                                <li className="breadcrumb-item"><a href="/ai_interviews/create">AI Interviews</a></li>
                                <li className="breadcrumb-item"> <a href={`/ai_interviews/${interview.id}/detail`}>{ interview.interview_title }</a></li>
                                <li className="breadcrumb-item" aria-current="page">Invited Candidates</li>
                            </ol>
                        </nav>
                        <div>
                            <a className='mr-3' href={`/ai_interviews/${interview.id}/candidate_report`}>Completed Interview List ({interviewCompletedCount})</a>
                            { interview.discarded_at == null ? <a className='ml-2' href="#" onClick={(e)=> setInviteInterviewModal(true)}>Invite Candidate</a> : '' }
                        </div>
                    </div>
                </div>
                <div className="justify-content-between align-items-right">
                <Row className="w-100 mt-4 mb-3" >
                    <Col xs={12} sm={12} lg={5}>
                        {totalCount > 0 && loading == false &&
                            <DisplayPaginationAI>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPaginationAI>
                        }
                    </Col>
                    <Col xs={12} sm={12} lg={7}>
                        <div style={{display: 'flex',float:'right'}}>
                            <div style={{display: 'grid', float: 'right'}} className='mr-3'>
                                <label>
                                    Select Status
                                </label>
                                <Select 
                                defaultValue={selected}
                                options={filterOptions} 
                                onChange={handleSelectStatus} 
                                placeholder={'Select Status'} 
                                styles={colourStatusStyles}
                                className='mt-1'
                                />
                            </div>
                            <div style={{display: 'grid',float:'right'}}>
                                <label>
                                    Select Date Range
                                </label>
                                <AiDateRangePicker
                                    handleOnSubmit={handleDate}
                                    defaultDate={dateRange}
                                />
                            </div>
                            <div style={{display: 'grid',float:'right'}}>
                                <label style={{ paddingLeft: '25px'}}>
                                     Search
                                </label>
                                <AiSearchBar
                                    placeholder="Search"
                                    value={searchTerm}
                                    setValue={setSearchTerm}
                                    onChange={(e) => onChangeSearchTerm(e.target.value)}
                                    onEnterPressed={() => {
                                        activePage === 1 ? getInterviewInvites(interview.id) : setActivePage(1)
                                    }}
                                    onClick={(e) => setSearchTerm('')}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <DataTable
                    persistTableHead={true}
                    noDataComponent={<p className="text-center p-3">No record found</p>}
                    columns={columns}
                    data={candidates}
                    progressPending={loading}
                    progressComponent={ <div className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner className='spinner' animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>}
                    onSort={handleSort}
                    sortServer
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalCount}
                    paginationPerPage={perPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    onChangePage={page => setActivePage(page)}
                />
                </div>
            </div>

            {inviteInterviewModal ? 
                <InviteInterviewPage
                inviteInterviewModal={inviteInterviewModal}
                setInviteInterviewModal={setInviteInterviewModal}
                interview_id={interview.id}
                />
                : ''
            }

            {showDeleteConfirmation &&
                <SweetAlert
                    title="Are you sure you want to archive this?"
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    onConfirm={()=>(onDeleteInviteCandidate(),setShowDeleteConfirmation(false))}
                    onCancel={()=>(setArchivedId(''),setShowDeleteConfirmation(false))}
                    confirmBtnStyle={{  backgroundColor: "#005999", color: "white", borderColor: '#005999', fontWeight: 300, textDecoration: 'none', boxShadow: 'none' }} // Style for Invite Candidate button
                    cancelBtnStyle={{ backgroundColor: "rgb(255, 255, 255)", color: "rgb(0, 89, 153)", borderColor: 'rgb(0, 89, 153)', fontWeight: 300, textDecoration: 'none' }} // Style for Not Now button
                    >
                    <><span><b>Candidate Name:</b> { selectInterviewCandidate?.candidate_name } </span>
                   </>
                </SweetAlert>
            }
            {showInviteConfirmation &&
                <SweetAlert
                    title={ (resendLinkCount > 0 ) ?  <>Are you sure you want to resend the invitation link?<br /><br />You have already invited this candidate {resendLinkCount} times.</>  : "Are you sure you want to resend invitation link?"}
                    warning
                    showCancel
                    confirmBtnText="Yes, Resend"
                    confirmBtnBsStyle="danger"
                    onConfirm={()=>(sendInviteLink(),setShowInviteConfirmation(false))}
                    onCancel={()=>(setArchivedId(''),setShowInviteConfirmation(false))}
                    confirmBtnStyle={{ backgroundColor: "#005999", color: "white", borderColor: '#005999', fontWeight: 300, textDecoration: 'none' }} // Style for Invite Candidate button
                    cancelBtnStyle={{ backgroundColor: "rgb(255, 255, 255)", color: "rgb(0, 89, 153)", borderColor: 'rgb(0, 89, 153)', fontWeight: 300, textDecoration: 'none' }} // Style for Not Now button
                    >
                </SweetAlert>
            }

            {showRestoreConfirmation &&
                <SweetAlert
                    title="Are you sure you want to restore this?"
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    onConfirm={()=>(onRestoreInterviewInvite(),setShowRestoreConfirmation(false))}
                    onCancel={()=>(setArchivedId(''),setShowRestoreConfirmation(false))}
                    confirmBtnStyle={{ backgroundColor: "#005999", color: "white", borderColor: '#005999', fontWeight: 300, textDecoration: 'none', boxShadow: 'none' }} // Style for Invite Candidate button
                    cancelBtnStyle={{ backgroundColor: "rgb(255, 255, 255)", color: "rgb(0, 89, 153)", borderColor: 'rgb(0, 89, 153)', fontWeight: 300, textDecoration: 'none' }} // Style for Not Now button
                    >
                         <><span><b>Candidate Name:</b> { selectInterviewCandidate?.candidate_name } </span></>
                </SweetAlert>
            }
        </>
      );

}
export default InvitesListPage