import React, { useEffect, useState, useRef } from 'react'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import Util from "../../../utils/util";
import Card from './shared/Card'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const EmailDatasAnalyticsReport = ({currentUser,recruiters,per_page}) => { 
  var active_page = window.sessionStorage.getItem('activePage_emailData')
  var query = window.sessionStorage.getItem('query')
  let campaign_manager = sessionStorage.getItem('campaign_manager');
  const queryParams = new URLSearchParams(window.location.search)
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(per_page)
  const [tableDatas, setTableDatas] = useState([])
  const [totalDatas,setTotalDatas] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [exportDatas, setExportDatas] = useState([])
  const csvExportRef = React.useRef();
  const [selectedRecruiter,setSelectedRecruiter] = useState(campaign_manager && campaign_manager != "null" ? JSON.parse(campaign_manager).value : '')
  const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'},...recruiters])
  const [defaultselected,setdefaultselected] = useState(campaign_manager && campaign_manager != "null" ? JSON.parse(campaign_manager) : [])
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('users.first_name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [footer, setFooter] = useState({})
  const localStorageDateRange = JSON.parse(sessionStorage.getItem('EmailDatasAnalyticsReport'))
  const startDate = (urlStartDate ? urlStartDate : (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate())
  const endDate = (urlEndDate ? urlEndDate : (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate())
  const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
  const handleDate = (date) => {
      sessionStorage.setItem('EmailDatasOrganizationReport', JSON.stringify(date))
      setDateRange(date)
  }

  const tableHeaders = [
    { label: 'Campaign Manager Name', key: 'recruiter_name' },
    { label: 'Email Sent', key: 'email_sent' },
    { label: 'Text Sent', key: 'text_sent' },
    { label: 'Response Rate', key: 'response_rate' },
    { label: '# Of Replies', key: 'of_replies'},
    { label: 'InMail Sent', key: 'in_email_sent'},
    { label: 'InMail Remaining', key: 'inmail_remaining'},
    { label: 'Submission', key: 'candidate_submitted'},
    { label: 'Interview', key: 'confirmed_interview'},
    { label: 'Hire', key: 'hire'}
  ]
  const fetchData = () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = `/admin/email_datas.json`
    window.sessionStorage.setItem('activePage_emailData',activePage)
    makeRequest(`${url}?start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}&sortField=${sortField}&sortDirection=${sortDirection}`, 'get',  {params: {page: (activePage), query: query}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {

        setTotalDatas(res.data.total_count)
        let sr_no = (activePage - 1) * res.data.per_page
        setTableDatas(
          res.data.email_datas_list.map((inMails) => (sr_no = sr_no + 1,{
            id: sr_no,
            organization_id: inMails?.organization_id,
            user_id: inMails?.user_id,
            recruiter_name: (<a href={`/admin/marketer_organizations?user_id=${inMails?.user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}`}>{Util.fullName(inMails?.user?.first_name,inMails?.user?.last_name) }</a>),
            email_sent: inMails?.email_sent,
            text_sent: inMails?.text_sent,
            of_replies: inMails?.of_replies,
            response_rate: inMails?.response_rate,
            in_email_sent: inMails?.in_email_sent,
            inmail_remaining: inMails?.inmail_remaining,
            candidate_submitted: inMails?.candidate_submitted,
            confirmed_interview: inMails?.confirmed_interview,
            hire: inMails?.hire,
          }))
        )
        setFooter({
          id:'Total',
          email_sent:res?.data?.total_values?.email_sent,
          hire:res?.data?.total_values?.hire,
          text_sent:res?.data?.total_values?.text_sent,
          of_replies:res?.data?.total_values?.of_replies,
          response_rate:res?.data?.total_values?.response_rate,
          in_email_sent:res?.data?.total_values?.in_email_sent,
          inmail_remaining:res?.data?.total_values?.inmail_remaining,
          candidate_submitted:res?.data?.total_values?.candidate_submitted,
          confirmed_interview:res?.data?.total_values?.confirmed_interview,
        })
        setPageCount(res.data.total_pages)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setLoading(false)
      },
    })
  }
  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activePage,dateRange,selectedRecruiter,sortDirection,sortField])

  // const handleDate = (date) => {
  //   setDateRange(date)
  // }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = `/admin/email_datas.json`
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.email_datas_list.map((data,key)=> ({
      recruiter_name: Util.fullName(data?.user?.first_name,data?.user?.last_name),
      organization_id: data?.organization_id,
      email_sent: data?.email_sent,
      text_sent: data?.text_sent,
      of_replies: data?.of_replies,
      response_rate: data?.response_rate,
      in_email_sent: data?.in_email_sent,
      inmail_remaining: data?.inmail_remaining,
      candidate_submitted: data?.candidate_submitted,
      confirmed_interview: data?.confirmed_interview,
      hire: data?.hire,
    })))
    csvExportRef?.current?.link.click();
  }
  const columns = [
    {
      name: 'No.',
      selector: row => row.id,
      maxWidth: '3%',
      minWidth: '4%'
    },
    {
      name: 'Campaign Manager Name',
      selector: row => row.recruiter_name,
      maxWidth: '15%',
      minWidth: '9%',
      wrap:true,
      sortable: true,
      sortField: 'users.first_name'
    },
    {
      name: 'Email Sent',
      selector: row => row.email_sent,
      minWidth: '7%',
      maxWidth: '7%',
      wrap:true,
      sortable: true,
      sortField: 'email_sent'
    },
    {
      name: 'Text Sent',
      selector: row => row.text_sent,
      maxWidth: '7%',
      minWidth: '7%',
      wrap:true,
      sortable: true,
      sortField: 'text_sent'
    },
    {
      name: 'Response Rate',
      selector: row => row.response_rate,
      maxWidth: '10%',
      minWidth: '8%',
      sortable: true,
      wrap:true,
      sortField: 'response_rate'
    },
    {
      name: '# Of Replies',
      selector: row => row.of_replies,
      maxWidth: '8%',
      minWidth: '8%',
      sortable: true,
      sortField: 'of_replies'
    },
    {
      name: 'InMail Sent',
      selector: row => row.in_email_sent,
      maxWidth: '10%',
      minWidth: '7%',
      sortable: true,
      sortField: 'in_email_sent'
    },
    {
      name: 'InMail Remaining',
      selector: row => row.inmail_remaining,
      maxWidth: '10%',
      minWidth: '7%',
      sortable: true,
      sortField: 'inmail_remaining'
    },
    {
      name: 'Submission',
      selector: row => row.candidate_submitted,
      maxWidth: '10%',
      minWidth: '7%',
      sortable: true,
      sortField: 'candidate_submitted'
    },
    {
      name: 'Interview',
      selector: row => row.confirmed_interview,
      maxWidth: '10%',
      minWidth: '7%',
      sortable: true,
      sortField: 'confirmed_interview'
    },
    {
      name: 'Hire',
      selector: row => row.hire,
      maxWidth: '10%',
      minWidth: '7%',
      sortable: true,
      sortField: 'hire'
    }
  ]

  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }

  return (
    <>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
         Campaign Manager Analytics
        </P>
        <div>
        <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
        </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"MarketerReportAnalytics.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalDatas > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalDatas}</DisplayPagination>
             }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
          <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Select Campaign Manager 
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={recruiter}
                  onChange={(event)=>{
                    setSelectedRecruiter(event.value);
                    sessionStorage.setItem("campaign_manager", JSON.stringify(event))

                  }
                  }
                  name="recruiter"
                  className={styles.colourStyles}
              />
          </div>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Select Date Range
            </label>
            <DateRangePicker
                handleOnSubmit={handleDate}
                defaultDate={dateRange}
            />
          </div>
        </Col>
      </Row>
      <DataTable
          persistTableHead={true}
          noDataComponent={<p className="text-center p-3">No record found</p>}
          columns={columns}
          data={tableDatas}
          progressPending={loading}
          progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
              <Spinner className='spinner' animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>
          </P>}
          onSort={handleSort}
          sortServer
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalDatas}
          paginationPerPage={perPage}
          paginationComponentOptions={{
              noRowsPerPage: true
          }}
          onChangePage={page => setActivePage(page)}
          footer={footer}
      />
    </Card>
    </>
  )
}

export default EmailDatasAnalyticsReport
