import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Alert from 'react-bootstrap/Alert'
import TextInput from '../../common/TextInput/TextInput'
import ReCAPTCHA from 'react-google-recaptcha'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import '../../../../assets/v2/css/main.css'
import styles from './styles/Signup.module.scss';

const PasswordRecoveryPage = () => {
    const [successFormSubmitting, setSuccessFormSubmitting] = useState('')

    return (
        <>
            <div className="d-flex justify-content-center align-items-center mt-60" style={{padding: '0px 0px 60px 0px'}}>

                <div className="col-md-5">
                    <div className={'border border-3 '+ styles.borderColor}  />
                    <div className="card  bg-white shadow p-5">
                        <div className="mb-4 text-center">
                            <svg className width={75} height={75} fill="currentColor" viewBox="0 0 16 16" style={{color: '#2e6ef5'}}>
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                            </svg>
                        </div>
                        <div className="text-center">
                            <h4>Forgot Password !</h4>
                            <p>Enter the email associated with your account <br /> and
                                we'll send you instructions to reset your password</p>
                            {successFormSubmitting && (
                                <Alert
                                    style={{ width:'auto',paddingLeft:'50px',paddingRight:'50px' }}
                                    variant="success"
                                    onClose={() =>
                                        setSuccessFormSubmitting(null)
                                    }
                                    dismissible
                                >
                                    {successFormSubmitting}
                                </Alert>
                            )}
                            <Formik
                                initialValues={{
                                    email: '',
                                    captcha: '',
                                }}
                                validationSchema={Yup.object({
                                    email: Yup.string()
                                        .email()
                                        .required('Email is required'),
                                    captcha: Yup.string().required(
                                        'Please complete captcha to continue'
                                    ),
                                })}
                                onSubmit={(values, { setSubmitting,resetForm }) => {
                                    const payload = new FormData()
                                    const url = '/users/password'
                                    payload.append('user[email]', values.email)

                                    const response = makeRequest(url, 'post', payload, {
                                        contentType: 'application/json',
                                        loadingMessage: 'Submitting...',
                                        'X-CSRF-Token': document
                                            .querySelector("meta[name='csrf-token']")
                                            .getAttribute('content'),
                                        createSuccessMessage: (response) =>
                                            response.data.message,
                                        onSuccess: (res) => {
                                            setSuccessFormSubmitting(
                                                'Check your mail for further instructions.'
                                            )
                                            resetForm({values:''})
                                            window.grecaptcha.reset();
                                            setSubmitting(false)
                                        },
                                    })
                                    setTimeout(() => {
                                        setSuccessFormSubmitting('')
                                    }, 5000)
                                }}
                                render={({
                                    values,
                                    errors,
                                    handleSubmit,
                                    handleChange,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <Form className={styles.signForm}>
                                        <div className="text-center">
                                            <Field type="email" name="email" className={"mt-3 "+styles.forgotField} /> 
                                        </div>   
                                        <div className="text-center">
                                            <ErrorMessage name="email" render={msg => <span width="28rem" className={styles.errorMsg}>{msg}</span>} />   
                                        </div>    
                                        <div className="text-center">
                                            <ReCAPTCHA  
                                                sitekey='6LdoP0AbAAAAAE_NNrzWQtIh7I2gdHcBGvbwkGnm'
                                                size="normal"
                                                className={styles.captchCenter}
                                                onChange={(value) => {
                                                    const newValue = value || ''
                                                    setFieldValue('captcha', newValue)
                                                }}
                                                
                                            />

                                            <TextInput className={styles.fullwidthInput}
                                                type="text"
                                                name="captcha"
                                                id="captcha"
                                                hidden
                                                value={values.captcha}
                                            />
                                            <div>
                                            <button
                                                type="submit"
                                                className="btn btn-outline-success"
                                                style={{ marginTop: '10px', backgroundColor: '#2e6ef5' }}
                                            >
                                                Submit
                                            </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordRecoveryPage
