import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import axios from 'axios'
import moment from 'moment'
import styles from './styles/JobResultsTable.module.scss'
import JobPositionCard from '../JobPositionCard/JobPositionCard'
import JobPositionCardV2 from '../JobPositionCard/JobPositionCard_v2'
import SearchBar from '../SearchBar/SearchBar'
import Paginator from '../Paginator/Paginator_v2'
import HorizontalLoader from '../../common/HorizontalLoader/HorizontalLoader'
import Util from '../../../utils/util'
import feather from 'feather-icons'

const JobResultsTableV2 = (props) => {
    const {
        jobs,
        setJobs,
        totalJobsCount,
        setTotalJobsCount,
        alertUploadedResume,
        setAlertUploadedResume,
        matchesGenerated,
        setMatchesGenerated,
        resumeUploadLoading,
        errorUploadedResume,
        setErrorUploadedResume,
        jobFilterText,
        setJobFilterText,
        activePage,
        setActivePage,
        errorFetchingJob,
        setErrorFetchingJob,
        loading,
        setLoading,
        pageCount,
        setPageCount,
        lastuploadedResume,
        uploadedResume,
        regenerateScore,
        setResumeUploadLoading
    } = props
    useEffect(() => {
        handleSearch()
    }, [activePage,lastuploadedResume])
    useEffect(() => {
        feather.replace()
    })
    const [showRefreshButton, setShowRefreshButton] = useState(false)
    const handleSearch = async () => {
        setAlertUploadedResume(null)
        setErrorUploadedResume(null)
        const url = (uploadedResume) ? 'job_searches/search_match_score' : 'job_searches/search'
        (uploadedResume) ? setResumeUploadLoading(true) : setLoading(true)
        const job_search = {
            keyword: jobFilterText,
        }
        const payload = JSON.stringify({ job_search })
        const CSRF_Token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')

        try {
            const response = await axios.post(
                `${url}?page=${activePage + 1}`,
                payload,
                {
                    headers: {
                        'content-type': 'application/json',
                        'X-CSRF-Token': CSRF_Token,
                    },
                }
            )

            const jobsData = response.data
            const jobs = jobsData.jobs
            const total_count = jobsData.total_count
            const totalPages = jobsData.total_pages
            const match_scores_created_lastdate = jobs[0]?.match_scores_created || ''
            if(match_scores_created_lastdate != ''){
                var lastdate = moment(match_scores_created_lastdate);
                var todaysdate = moment();
                var diffday = lastdate.diff(todaysdate, 'days');
                if(diffday < 0){
                    setShowRefreshButton(true)
                }else{
                    setShowRefreshButton(false)
                }
            }
            setMatchesGenerated(jobsData.matches_generated)
            setJobs(jobs)
            setTotalJobsCount(total_count)
            setPageCount(totalPages)
            setLoading(false)
            setResumeUploadLoading(false)
        } catch (error) {
            setErrorFetchingJob(error.message)
            setLoading(false)
            setResumeUploadLoading(false)
        }
    }

    const displayNumberOfResults = () => {
        if (totalJobsCount < 1) return
        if (matchesGenerated) return `1 - ${totalJobsCount} of ${totalJobsCount}`
        return Util.displayNumberOfResults(
            totalJobsCount,
            pageCount,
            activePage,
            10
        )
    }

    const displayText = `Displaying ${displayNumberOfResults()} results`

    return (
        <section className="section-box">
            <div className="container">
                <div className="row flex-row-reverse">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12 float-right">
                        <div className="content-page">
                            <div className="box-filters-job mt-15 mb-10">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h4 style={{marginLeft: '14px'}}>{matchesGenerated
                                                ? 'AI Matched Jobs'
                                                : 'Recommended Jobs'}</h4>
                                    </div>
                                </div>
                            </div>
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : resumeUploadLoading ? (
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <p className={styles.alertTextV2}>
                                        Please give us a moment as our AI generates your
                                        matches!
                                    </p>
                                    <HorizontalLoader />
                                </div>
                            ) : alertUploadedResume ? (
                                <Alert
                                    variant="danger"
                                    onClose={() => setAlertUploadedResume(null)}
                                    dismissible
                                    className="dashboard-info-close"
                                >
                                    {alertUploadedResume}
                                </Alert>
                            ) : errorUploadedResume ? (
                                <Alert
                                    variant="danger"
                                    onClose={() => setErrorUploadedResume(null)}
                                    dismissible
                                    className="dashboard-info-close"
                                >
                                    {errorUploadedResume}
                                </Alert>
                            ) : errorFetchingJob ? (
                                <Alert
                                    variant="danger"
                                    onClose={() => setErrorFetchingJob(null)}
                                    dismissible
                                    className="dashboard-info-close"
                                >
                                    {errorFetchingJob}
                                </Alert>
                            ) : jobs.length == 0 && uploadedResume ? (
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <p className={styles.alertTextV2}>
                                    AI generates matched jobs not found. Please upload resume again.
                                    </p>
                                </div>
                            ) : (
                                <div class="job-list-list mb-15 mt-15">
                                    <div class="list-recent-jobs">
                                    {jobs.length == 0 && <div>No jobs to display</div>}
                                    {jobs.map((job) => (
                                        <JobPositionCardV2
                                            key={job.id}
                                            job={job}
                                            matchScore={(job?.score) ? Math.round(job?.score) : 0}
                                            showSalary={false}
                                            showMatchScore={(job?.score) ? true : false}
                                        />
                                    ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JobResultsTableV2
