import React, { useEffect, useState } from 'react'
import Util from '../../../utils/util'
import './styles/RecruiterProfilePageModule.scss'

import PeterImage from '../../../../assets/images/on_demand_recruiter/peter-anthony.svg'
import PaulImage from '../../../../assets/images/on_demand_recruiter/paul-campbell.svg'
import LawrenceImage from '../../../../assets/images/on_demand_recruiter/lawrence.jpeg'
import AlexanderImage from '../../../../assets/images/on_demand_recruiter/alexander.png'

import PortfolioIcon from '../../../../assets/images/recruiter_home_icons/icon-portfolio.svg'
import ExperienceIcon from '../../../../assets/images/recruiter_home_icons/icon-experience.svg'
import LocationIcon from '../../../../assets/images/recruiter_home_icons/icon-location.svg'
import AvailabilityIcon from '../../../../assets/images/recruiter_home_icons/icon-availability.svg'
import PreferredIcon from '../../../../assets/images/recruiter_home_icons/icon-preferred-environment.svg'
import MostAmazingIcon from '../../../../assets/images/recruiter_home_icons/icon-most-amazing.svg'
import EmploymentIcon from '../../../../assets/images/recruiter_home_icons/icon-employment.svg'
import SkillsIcon from '../../../../assets/images/recruiter_home_icons/icon-skills.svg'
import EducationIcon from '../../../../assets/images/recruiter_home_icons/icon-education.svg'

const top_recruiter_list = [
    {
        id:1, 
        first_name: "Paul", 
        rate: "$75 Per Hour", 
        location:"Palo Alto, California, United States", 
        name:"Paul Campbell", 
        about:"I have been recruiting in the Bay Area for about 10 years mainly for tech startups and have been very successful at finding great engineers for the startups that I have worked with! In college, I attended UC Berkeley and studied Civil Engineering and continued to play sports mainly through intramurals.  For graduate school, I attended Stanford and studied Engineering and also took several business related courses. These days I am very interested in cycling and every few years I do a triathlon.",
        link_name: "Paul_Campbell", 
        image:PaulImage, 
        skills:['Recruiting','Cloud Computing','Software Development','SaaS','Start-ups','Product Management'], 
        educations:[{id:1,school_name:"Stanford University",degree:"Master of Science - MS - Engineering",from:"2008",to:"2010"},{id:2,school_name:"University of California, Berkeley",degree:"Bachelor of Science - BS - Business",from:"2003",to:"2007"}],
        experiences:[
            {
                title:"Program Director",
                company_name:"Team4Tech",
                description:"• Team4Tech works with top tech companies to build CSR /social impact programs that engage their employees in crafting and implementing technology solutions that support education nonprofits around the world."
            },{
                title:"Director Of Partnerships",
                company_name:"Corkscrew",
                description:"• Built partnerships with US universities to provide entrepreneurship education programs at incubators in Amsterdam, Berlin, Barcelona, Dublin, and Cape Town."
            },{
                title:"Community Director; Director of Programs",
                company_name:"Net Impact",
                description:"• Net Impact is dedicated to harnessing the power of the business sector to drive social and environmental change. Through Net Impact programming, students and professionals hone their leadership skills while driving real impact in campus-based and corporate social responsibility (CSR) initiatives."
            }
        ]
    },
    {
        id:2, 
        first_name: "Peter", 
        rate: "$75 Per Hour", 
        location:"New York", 
        name:"Peter Anthony", 
        about:"I have a fervor for connecting the right people to the right opportunities. Too often people miss out on their dream role, and employers miss out on their ideal candidate; simply due to an overwhelming amount of generic emails, pointless notifications and a lack of intentional connections. I look to bridge that gap every day.", 
        link_name: "Peter_Anthony", 
        image:PeterImage, 
        skills:['Recruiting','Technical Recruiting','Troubleshooting','Business Development','Account Management','Full-life Cycle Recruiting','E-commerce'], 
        educations:[],
        experiences:[
            {
                title:"Sr Talent",
                company_name:"Robinhood",
                description:""
            }
            ,{
                title:"Technical Recruiter & Account Manager",
                company_name:"VC-Backed Startups · Full-time",
                description:"Matching the right people to the right opportunity. I'm always looking for people who meet the desired technical criteria, AND fit well with the culture of the company!"
            }
        ]
    },
    {
        id:3, 
        first_name: "Lawrence", 
        rate: "$75 Per Hour", 
        location:"New York City Metropolitan Area", 
        name:"Lawrence Wang", 
        about:"I lead an all star recruiting team supporting multiple industries including fintech, financial services, biotech, SaaS, and more!Have helped numerous people find their next home, let’s find yours!", 
        link_name: "Lawrence_Wang", 
        image:LawrenceImage, 
        skills:['Recruiting','Communication','Leadership','Teamwork','Human Resources (HR)','Technical Recruiting','Management','Social Media Marketing'], 
        educations:[{id:1,school_name:"University of California, Los Angeles",degree:"B.A. in Psychology and Minor in Labor and Workplace Studies, Psychology",from:"2015",to:"2019"}],
        experiences:[
            {
                title:"Technical Recruiter | RPO",
                company_name:"Sama",
                description:"• We're Hiring! <br />•  Looking for experienced, SDRs, Senior Enterprise AE's, Marketing Operations Managers and Customer Success Managers in USA, Canada & Germany!  If you're looking to make social impact, let's chat!"
            },{
                title:"Lead Technical Recruiter",
                company_name:"VC-Backed Startups",
                description:"• Billing $500k+ yearly with my amazing team <br />• Full cycle recruiting for tech and non-tech roles"
            },{
                title:"Sourcing Specialist",
                company_name:"Hiring Strategy Group",
                description:"• Assist in sourcing and filling pipelines for multiple start up companies.  Upload candidate profiles into our applicant tracking system, Lever.  Qualify leads for clients and craft individualized emails for candidates."
            }
        ]
    },
    {
        id:4, 
        first_name: "Alexander", 
        rate: "$75 Per Hour", 
        location:"San Francisco Bay Area", 
        name:"Alexander Lorimer", 
        about:"I work with world-class startups and tech companies to get them the talent that will help secure their growth. Full-Cycle experience in sourcing, screening, scheduling, reference checks, assisting with the offer process, and improving / streamlining the hiring process.", 
        link_name: "Alexander_Lorimer", 
        image:AlexanderImage , 
        skills:['Recruiting','Leadership','Data Analysis','Laboratory Skills','Python','LabVIEW'], 
        educations:[{id:1,school_name:"University of California, Berkeley",degree:"Bachelor of Arts - BA - Physics",from:"2016",to:"2018"},{id:2,school_name:"Grossmont College",degree:"Physics",from:"2012",to:"2016"}],
        experiences:[
            {
                title:"Lead Recruiter",
                company_name:"Stealth Startup",
                description:"• Placements in the following verticals: <br /> - Fintech / Cryptocurrency <br /> - Medical Technology <br /> - AI / Machine Learning<br /> - Cloud solutions "
            },{
                title:"Research Assistant",
                company_name:"University of California, Berkeley",
                description:""
            },{
                title:"Physics Tutor",
                company_name:"Grossmont College",
                description:"• I was recruited by the college's Physics faculty to tutor students during term time and summer sessions."
            }
        ]
    },
];

const RecruiterProfilePage = ({currentUser, recruiterId, type, user_data}) => {
    const [recruiterDetails, setProfile] = useState('')
    const [profile_type, setProfileType] = useState('/recruiter_contact')
    const [contactUrl, setContactUrl] = useState(type)
    useEffect(() => {
        if(type == "Home"){
            setContactUrl('/home_recruiter_contact/'+top_recruiter_list[recruiterId-1].id+'/'+top_recruiter_list[recruiterId-1].link_name)
            setProfile(top_recruiter_list[recruiterId-1]);
        }else{
            const profile_data =  {
                id:user_data.id, 
                first_name: user_data.first_name,
                last_name: user_data.last_name,  
                location: user_data?.location ? user_data.location?.replace('(usa)','(US)') : "No location information added yet.", 
                name:user_data.first_name+" "+user_data.last_name, 
                about : user_data?.summary ? user_data?.summary : user_data?.description,
                link_name: user_data.first_name+"_"+user_data.last_name, 
                image : user_data.avatar_url ? user_data.avatar_url : "",
                skills:user_data?.skills ? user_data?.skills?.split(',') : [], 
                educations:user_data?.education_experiences,
                experiences:user_data?.job_experiences,
                tech_talents:user_data?.tech_group ?  user_data?.tech_group?.split(',') : [],
                job_title: user_data?.title ? user_data?.title + (user_data?.employer ? " ("+user_data?.employer+")" : "") : user_data?.job_experiences.length > 0 ? user_data?.job_experiences[0]?.title +" ("+user_data?.job_experiences[0]?.company_name+")" : ""
            }
            setContactUrl('/talent_contact/'+profile_data.id+'/'+profile_data.link_name)
            setProfile(profile_data);
        }
    }, []);
    
    return (
        <>
        <div className="layout-header"></div>
        <div className="layout has-top_margin" data-role="main_content">
            <div className="layout-shadow">
                <div className="layout-content is-white">
                    <div className="grid-row">
                        <div className="grid-row-inner">
                            <div className="resume_section resume_top-section" data-view="resumes#top-aligner">
                                <div className="resume_top-left" data-role="left_panel">
                                    {recruiterDetails.image ? (
                                        <div className="resume_top-photo_wrapper"><img alt={recruiterDetails.about} className="resume_top-photo" width={380} height={380} src={recruiterDetails.image} /></div>
                                    ) : (
                                        <div className="candidateAvtarImage">{ (recruiterDetails.first_name) ? (recruiterDetails?.first_name && Util.getFirstCharater(recruiterDetails?.first_name)) + (recruiterDetails?.last_name && Util.getFirstCharater(recruiterDetails?.last_name) ) : (recruiterDetails?.email && Util.getFirstCharater(recruiterDetails?.email) ) }</div>
                                    )}
                                </div>
                                <div className="resume_top-right" data-role="right_panel">
                                    <div className="resume_top-info">
                                        <div className="resume_top-info_name" data-slug="armin-mehinovic" data-target-role="Developer">{recruiterDetails.name}</div>
                                        <div className="resume_top-info_short_description" data-talent_title="Software Developer">
                                            <h1 className="resume_top-info_location">{recruiterDetails?.job_title }</h1>
                                        </div>
                                        <div className="resume_top-info_bio" data-role="description">{recruiterDetails.about}</div>
                                    </div>
                                    <div className="resume_top-tags" data-max-rows={2} data-role="tags_list" style={{maxHeight: 'none'}}>
                                        <b>Skills : </b>
                                        {recruiterDetails?.skills?.length > 0 ? (
                                        <>
                                        {recruiterDetails.skills?.map((skill) => {
                                            return (
                                                <a className="tag is-expert" key={skill} data-role="tag" href="#">{skill}</a>
                                            )}
                                        )}
                                        </>
                                        ) : (
                                            <>No skills information added yet.</>
                                        )} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-row">
                        <div className="grid-row-inner">
                            <div className="resume_section for-hire_block mobile_app-hide">
                                <div className="resume_hire_block is-available">
                                    <div className="resume_hire_block-text">{recruiterDetails.first_name} is now <span className="resume_hire_block-availability is-green">available</span> for contact</div>
                                    <div className="resume_hire_block-actions"><a className="button is-green_candy is-default resume_top-hire_button" data-role="action_button" data-short-text="Hire" href={contactUrl}>Contact {recruiterDetails.first_name}</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-row" id="Availability">
                        <div className="grid-row-inner">
                            <div className="resume_section has-no_bottom_border_on_mobile for-details" data-role="resume-details" data-view="resumes#details-height">
                                <div className="resume_details-col is-1_of_3 is-1_of_2_for_printing" data-role="details-cell">
                                    <div className="resume_details-item">
                                        <div className="resume_details-item_inner for-availability">
                                            <h2 className="resume_details-title"><img src={AvailabilityIcon} style={{height: '24px',width:'24px',marginRight: '10px'}} />Job Types</h2>
                                            <div className="resume_top-tags" data-max-rows={2} data-role="tags_list" style={{maxHeight: 'none'}}>
                                            {recruiterDetails?.tech_talents?.length > 0 ? (
                                            <>
                                                {recruiterDetails.tech_talents?.map((skill) => {
                                                    return (
                                                        <a className="tag is-expert" key={skill} data-role="tag" href="#">{skill}</a>
                                                    )}
                                                )}
                                            </>
                                            ) : (
                                                <>No job types information added yet.</>
                                            )} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="resume_details-col is-1_of_3 is-1_of_2_for_printing for-preferred-environment" data-role="details-cell">
                                    <div className="resume_details-item">
                                        <div className="resume_details-item_inner for-preferred-environment">
                                            <h2 className="resume_details-title"><img src={PreferredIcon} style={{height: '24px',width:'24px',marginRight: '10px'}} />Recruiting Experiences</h2>
                                            <div className="resume_top-tags" data-max-rows={2} data-role="tags_list" style={{maxHeight: 'none'}}>
                                                {recruiterDetails.recruiter_category?.map((skill) => {
                                                    return (
                                                        <a className="tag is-expert" key={skill} data-role="tag" href="#">{skill}</a>
                                                    )}
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="resume_details-col is-beige resume_details-map_wrapper hide_in_webview is-1_of_3 is-hidden_for_printing" data-role="details-cell">
                                    <h2 className="resume_details-title"><img src={LocationIcon} style={{height: '24px',width:'24px',marginRight: '10px'}}/>Location</h2>
                                    <div className="muted resume_details-amazing_thing_content">
                                        <div className="opening_quote" />
                                            {recruiterDetails.location}
                                        <div className="closing_quote" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-row" id="Experience">
                        <div className="grid-row-inner">
                            <div className="resume_section js-experience for-experience">
                            <div className="resume_section-inner">
                                <h2 className="resume_section-title has-default_offset"><img src={ExperienceIcon} style={{height: '24px',width:'24px',marginRight: '10px'}} />Experience</h2>
                                <div className="resume_section-content">
                                    <ul>
                                    {recruiterDetails?.experiences?.length > 0 ? (
                                    <>
                                    {recruiterDetails.experiences?.map((experience) => {
                                        return (
                                            <li className="resume_section-content_item" key={experience.title}>
                                                <div className="resume_section-content_title">{experience.title}</div>
                                                <div className="muted">
                                                    <p>{experience.company_name}</p>
                                                </div>
                                                <br />
                                                <div className="muted">
                                                    <p
                                                        weight="normal"
                                                        dangerouslySetInnerHTML={{ __html: experience.description }}
                                                    />
                                                </div>
                                            </li>
                                        )}
                                    )}
                                    </>
                                    ) : (
                                        <li className="resume_section-content_item">No experiences information added yet.</li>
                                    )}   
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-row" id="EducationandCertifications">
                        <div className="grid-row-inner">
                            <div className="resume_section js-educations">
                                <div className="resume_section-inner">
                                    <h2 className="resume_section-title for-education"><img src={EducationIcon} style={{height: '24px',width:'24px',marginRight: '10px'}} />Education</h2>
                                    <div className="resume_section-content">
                                        <ul>
                                        {recruiterDetails?.educations?.length > 0 ? (
                                        <>
                                        {recruiterDetails.educations?.map((education) => {
                                            return (
                                                <li className="resume_section-content_item" key={education.id} >
                                                    <div className="resume_section-content_row">
                                                        <div className="resume_section-content_title is-blue has-range">{education.school_name}</div>
                                                        <div className="resume_section-content_range">{education.from} - {education.to}</div>
                                                        <div className="resume_section-content_subtitle">{education.degree}</div>
                                                    </div>
                                                </li>
                                            )}
                                        )}
                                        </>
                                        ) : (
                                            <li className="resume_section-content_item">No educations information added yet.</li>
                                        )}    
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default RecruiterProfilePage
