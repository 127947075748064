import React, { useEffect, useState } from 'react'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from 'react-data-table-component-footer'
import moment from 'moment'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import P from './shared/P'
import Card from './shared/Card'
import { Col, Row, Spinner } from 'react-bootstrap'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import styles from './styles/JobsStatusPage.module.scss'
import { CSVLink } from 'react-csv'
import   './styles/DataTableCustom.scss'
import Select from 'react-select'
import { capitalize } from '../../../utils'

const HireAnalyticPage = ({recruiter_list, job_list}) => {
  const [hiredCandidate, setHiredCandidate] = useState([])
  let hire_org_type = sessionStorage.getItem("hire_org_type")
  let recruiterStatus_recruiter = sessionStorage.getItem("recruiterStatus_recruiter")
  const urlRecruiterId = sessionStorage.getItem("recruiter")
  let jobStatus_job = sessionStorage.getItem("jobStatus_job")
  const jobId = sessionStorage.getItem("job")
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(25)
  const [totalCount,setTotalCount] = useState()
  const [dateRange, setDateRange] = useState({startDate: moment().startOf('week').toDate() ,endDate: moment().toDate()})
  const [loading, setLoder] = useState(true)
  const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'}, ...recruiter_list])
  const [exportDatas, setExportDatas] = useState([])
  const csvExportRef = React.useRef();
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [startRecord, setStartRecord] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobs,setJobs] = useState([{value: 'all', label: 'All'},...job_list])
  const [selectedJob,setSelectedJob] = useState(jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job).value : jobId ? jobId : '')
  const [selected,setSelected] = useState((jobId)? job_list.filter((option) => option.value == jobId) : jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job) : {value: 'all', label: 'All'})
  const [selectedRecruiter,setSelectedRecruiter] = useState((urlRecruiterId) ? urlRecruiterId : recruiterStatus_recruiter && recruiterStatus_recruiter != "null" ? JSON.parse(recruiterStatus_recruiter).value : '')
  const [selectedOrganizationType,setSelectedOrganizationType] = useState(hire_org_type && hire_org_type != "null" ? JSON.parse(hire_org_type).value : "")
  const [organizationType,setOrganizationType] = useState([{value: 'all', label: 'All'},{value: 'hourly', label: 'Hourly'},{value: 'contingency', label: 'Contingency'}])
  const [defaultselected,setdefaultselected] = useState(hire_org_type && hire_org_type != "null" ? JSON.parse(hire_org_type) : {value: 'all', label: 'All'})
  const handleDate = (date) => {
    setDateRange(date)
  }

  const fetchData = async () => {
    setLoder(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/hire_analytics.json'
    const response = await makeRequest(
      `${url}?page=${activePage}&start_date=${start_date}&end_date=${end_date}&organization_type=${selectedOrganizationType}&recruiter=${selectedRecruiter}&sortField=${sortField}&job=${selectedJob}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    let sr_no = (activePage -1) * perPage
    setPageCount(response.data.total_pages)
    setTotalCount(response.data.total_count)
    setStartRecord((activePage - 1) * response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setHiredCandidate(response.data.candidates.map((candidate) => (sr_no = sr_no + 1,{
        id: sr_no,
        candidateName: candidate.candidate_name,
        orgName: candidate.org_name,
        orgType: candidate?.org_type ? capitalize(candidate.org_type) : '',
        jobName: candidate.job_name,
        recruiterName: candidate.recruiter_name,
        accountManagerName: candidate.account_manager_name,
        movedByName: candidate.moved_by_name,
        hiredDate: moment(candidate.hired_date).format('MMM DD, YYYY'),
        offerDate: candidate.offer_date ? moment(candidate.offer_date).format('MMM DD, YYYY') : '',
        joiningDate: candidate.joining_date ? moment(candidate.joining_date).format('MMM DD, YYYY') : ''
    })))
    setLoder(false)
  }

  const headers = [
    { label: 'Candidate Name', key: 'candidateName' },
    { label: 'Organization', key: 'orgName' },
    { label: 'Organization Type', key: 'orgType' },
    { label: 'Job', key: 'jobName' },
    { label: 'Recruiter', key: 'recruiterName' },
    { label: 'Account Manager', key: 'accountManagerName' },
    { label: 'Offer Signed Date', key: 'offerDate' },
    { label: 'Moved By', key: 'movedByName' },
    { label: 'Hired Date', key: 'hiredDate' },
    { label: 'Joining Date', key: 'joiningDate' },
];

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/hire_analytics.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&organization_type=${selectedOrganizationType}&job=${selectedJob}&&recruiter=${selectedRecruiter}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.candidates.map((data,key)=> ({
      id: key,
      candidateName: data.candidate_name,
        orgName: data.org_name,
        orgType: data.org_type,
        jobName: data.job_name,
        recruiterName: data.recruiter_name,
        accountManagerName: data.account_manager_name,
        movedByName: data.moved_by_name,
        offerDate: data.offer_date ? moment(data.offer_date).format('MMM DD, YYYY') : '',
        hiredDate: moment(data.hired_date).format('MMM DD, YYYY'),
        joiningDate: data.joining_date ? moment(data.joining_date).format('MMM DD, YYYY') : ''
    })))
    csvExportRef?.current?.link.click();
  }

  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage,sortDirection,sortField, selectedOrganizationType, selectedRecruiter, selectedJob])

  
  const  columns= [
    {
        name: 'No.',
        selector: row => row.id,
        maxWidth: '4%',
        minWidth: '4%',
    },
    {
        name: 'Candidate Name',
        selector: row => row.candidateName,
        cell: (row) => <div title={row.candidateName}>{row.candidateName}</div>,
        maxWidth: '13%',
        minWidth: '13%',
        wrap:true,
        sortable: true,
        sortField: 'candidate_name',
    },
    {
        name: 'Organization Name',
        selector: row => row.orgName,
        cell: (row) => <div title={row.orgName}>{row.orgName}</div>,
        maxWidth: '10%',
        minWidth: '10%',
        wrap:true,
        sortable: true,
        sortField: 'org_name',
    },
    {
      name: 'Organization Type',
      selector: row => row.orgType,
      cell: (row) => <div title={row.orgType}>{row.orgType}</div>,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
      sortable: true,
      sortField: 'org_type',
    },
    {
      name: 'Job Name',
      selector: row => row.jobName,
      cell: (row) => <div title={row.jobName}>{row.jobName}</div>,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true,
      sortable: true,
      sortField: 'job_name',
    },
    {
      name: 'Recruiter Name',
      cell: row => row.recruiterName,
      cell: (row) => <div title={row.recruiterName}>{row.recruiterName}</div>,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true,
      sortable: true,
      sortField: 'recruiter_name',
    },
    {
      name: 'Account Manager',
      selector: row => row.accountManagerName,
      cell: (row) => <div title={row.accountManagerName}>{row.accountManagerName}</div>,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true,
      sortable: true,
      sortField: 'account_manager_name',
    },
    {
      name: 'Offer Signed Date',
      selector: row => row.offerDate,
      cell: (row) => <div title={row.offerDate}>{row.offerDate}</div>,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
      sortable: true,
      sortField: 'offer_date',
    },
    {
        name: 'Moved By',
        selector: row => row.movedByName,
        cell: (row) => <div title={row.movedByName}>{row.movedByName}</div>,
        maxWidth: '11%',
        minWidth: '11%',
        wrap:true,
        sortable: true,
        sortField: 'moved_by_name',
    },
    {
      name: 'Hired Date',
      selector: row => row.hiredDate,
      cell: (row) => <div title={row.hiredDate}>{row.hiredDate}</div>,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
      sortable: true,
      sortField: 'hired_date',
    },
    {
      name: 'Joining Date',
      selector: row => row.joiningDate,
      cell: (row) => <div title={row.joiningDate}>{row.joiningDate}</div>,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
      sortable: true,
      sortField: 'joining_date',
    }
  ]

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  };

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
        style={{ marginBottom: '42px' }}
      >
        <P size="28px" height="38px" color="#1D2447">
          Hired Dashboard
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={()=>exportDataMethod()}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={headers}
        filename={"hired_analytic.csv"}
        ref={csvExportRef}
      />
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
          <div style={{display: 'grid', marginLeft:'10px'}}>
              <label>
                  Organization Type
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={organizationType}
                  onChange={(event)=>{
                      setSelectedOrganizationType(event.value)
                  sessionStorage.setItem("job_status_org", JSON.stringify(event))
                  }
                  }
                  name="organization_type"
                  className={styles.colourStyles}
              />
          </div>
          <div style={{display: 'grid', marginLeft:'10px'}}>
              <label>
                  Job Name
              </label>
              <Select
                  defaultValue={selected}
                  options={jobs}
                  onChange={(event)=>{
                      setSelectedJob(event.value)
                      sessionStorage.setItem("jobStatus_job", JSON.stringify(event))
                  }}
                  name="job_name"
                  className={styles.colourStyles}
              />
          </div>
          <div style={{display: 'grid', marginLeft:'10px'}}>
              <label>
                  Recruiter
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={recruiter}
                  onChange={(event)=>{
                      setSelectedRecruiter(event.value)
                      sessionStorage.setItem("recruiterStatus_recruiter", JSON.stringify(event))
                  }}
                  name="recruiter"
                  className={styles.colourStyles}
              />
          </div>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Date Range
            </label>
            <DateRangePicker
                handleOnSubmit={handleDate}
                defaultDate={dateRange}
            />
          </div>
        </Col>
        <Col lg="12" md="12" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 &&
                <DisplayPagination>Displaying  {(activePage == 0)?1:startRecord+1} - {currentCounts > totalCount ? totalCount : currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
      </Row>
      <DataTable
        title={false}
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={hiredCandidate}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalCount}
        paginationPerPage={25}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        onSort={handleSort}
        sortServer
        responsive={true}
        onChangePage={page => {
          console.log("page", page)
          setActivePage(page)
        }}
    />
    </Card>
  )
}

export default HireAnalyticPage