import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const RecommendedCandidate = (prop) => {
    const queryParams = new URLSearchParams(window.location.search)
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    let recommended_start_date = sessionStorage.getItem("recommended_start_date")
    let recommended_end_date = sessionStorage.getItem("recommended_end_date")
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(prop.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [jobStatus, setJobStatus] = useState([])
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate : recommended_start_date ? recommended_start_date : moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : recommended_end_date ? recommended_end_date : moment().toDate()})
    const [loading, setLoder] = useState(true)
    const handleDate = (date) => {
        setDateRange(date)
    }
    const csvExportRef = React.useRef();
    const csvExportRef2 = React.useRef();
    const [exportDatas, setExportDatas] = useState([])
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const headers = [
        { label: 'Recruiter Name', key: 'recruiterName' },
        { label: 'Move To ATS', key: 'moveToATS' },
        { label: 'First Interview', key: 'firstInterview' },
        { label: 'Second Interview', key: 'secondInterview' },
        { label: 'On Hold', key: 'onhold' },
        { label: 'Offers', key: 'offers' },
        { label: 'Hired', key: 'hired' },
        { label: 'Archived', key: 'archived' },
    ];

    const fetchData = async () => {
        setLoder(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("recommended_start_date", start_date)
        sessionStorage.setItem("recommended_end_date", end_date)
        const url = '/admin/recommended_candidate.json'
        const response = await makeRequest(
            `${url}?page=${activePage}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setJobStatus(response?.data?.recommended_candidates.map((data,key)=> (sr_no = sr_no + 1,{
            id: sr_no,
            recruiterName: capitalize(data?.recruiter_name && capitalize(data?.recruiter_name)),
            moveToATS:  data?.recruiter_screen > 0 ? <a href={`/admin/recommended_candidate/details?recruiter_id=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruiter_screen}</a> : '',
            firstInterview: data.first_interview > 0 ? <a href={`/admin/recommended_candidate/details?recruiter_id=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> : '',
            secondInterview: data.second_interview > 0 ? <a href={`/admin/recommended_candidate/details?recruiter_id=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> : '',
            onhold: data.onhold > 0 ? <a href={`/admin/recommended_candidate/details?recruiter_id=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=onhold`}>{data.onhold}</a> : '',
            offers: data.offer > 0 ? <a href={`/admin/recommended_candidate/details?recruiter_id=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a> : '',
            archived: data.reject > 0 ? <a href={`/admin/recommended_candidate/details?recruiter_id=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> : '',
            hired: data.hired > 0 ? <a href={`/admin/recommended_candidate/details?recruiter_id=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> : ''
        })))
        setFooter({id:'Total',
            moveToATS: response.data.total_values.moveToATS,
            firstInterview:response.data.total_values.firstInterview,
            secondInterview:response.data.total_values.secondInterview,
            onhold:response.data.total_values.onhold,
            offers:response.data.total_values.offers,
            archived:response.data.total_values.archived,
            hired:response.data.total_values.hired
        })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoder(false)
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Recruiter Name',
            selector: row => row.recruiterName,
            cell: (row) => <div title={row.recruiterName}>{row.recruiterName}</div>,
            sortable: true,
            sortField: 'recruiter_name',
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true
        },
        {
            name: 'Move To ATS',
            selector: row => row.moveToATS,
            sortable: true,
            sortField: 'recruiter_screen',
            maxWidth: '10%',
            minWidth: '10%',
            wrap:true
        },
        {
            name: 'First Interview',
            selector: row => row.firstInterview,
            sortable: true,
            sortField: 'first_interview',
            maxWidth: '11%',
            minWidth: '11%'
        },
        {
            name: 'Second Interview',
            selector: row => row.secondInterview,
            sortable: true,
            sortField: 'second_interview',
            maxWidth: '11%',
            minWidth: '11%'
        },
        {
            name: 'On Hold',
            selector: row => row.onhold,
            sortable: true,
            sortField: 'onhold',
            maxWidth: '11%',
            minWidth: '11%'
        },
        {
            name: 'Offers',
            selector: row => row.offers,
            sortable: true,
            sortField: 'offer',
            maxWidth: '11%',
            minWidth: '11%'
        },
        {
            name: 'Hired',
            selector: row => row.hired,
            sortable: true,
            sortField: 'hired',
            maxWidth: '10%',
            minWidth: '10%'
        },
        {
            name: 'Archived',
            selector: row => row.archived,
            sortable: true,
            sortField: 'reject',
            maxWidth: '10%',
            minWidth: '10%'
        },
    ]

    const exportDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("jobStatus_start_date", start_date)
        sessionStorage.setItem("jobStatus_end_date", end_date)
        const url = '/admin/recommended_candidate.json'
        const response = await makeRequest(
            `${url}?start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        console.log(response)
        setExportDatas(response?.data?.recommended_candidates.map((data,key)=> ({
            id: key,
            recruiterName: data?.recruiter_name && capitalize(data?.recruiter_name),
            moveToATS: data?.recruiter_screen,
            firstInterview: data.first_interview,
            secondInterview: data.second_interview,
            onhold: data.onhold,
            offers: data.offer,
            archived: data.reject,
            hired: data.hired
        })))
        csvExportRef?.current?.link.click();
    }

    useEffect(()=>{
        fetchData()
    },[dateRange,activePage,sortDirection,sortField])

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Recommended Candidate Report
                </P>
                <div>
                    <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                        Export CSV
                    </button>
                </div>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"recommended_candidates.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
                    <div style={{display:'grid'}}>
                        <label>Date Range</label>
                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobStatus}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                responsive={true}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
        
    )
}

export default RecommendedCandidate