import React from 'react'
import { useField } from 'formik'
import styled from 'styled-components'

const Input = styled.input.attrs((props) => ({
    width: props.width || '250px',
}))`
    background: #F6F7FC;
    border: 1px solid #F6F7FC !important;
    box-sizing: border-box;
    width: ${(props) => props.width};
    text-indent: solid;
    box-shadow: 5px;
    border:none;
    padding: 5px;
    display: inline;
    &:focus {
        background: #F6F7FC;
        border: 1px solid #2684FF !important;
        box-sizing: border-box;
        line-height: 28px;
    }
`
const SearchTextBox = ({ label, ...props }) => {
    const [field, meta] = useField(props)

    return (
        <Input {...field} {...props}  className="form-control" />
    )
}

export default SearchTextBox
