import React,{useEffect } from 'react'
import feather from 'feather-icons'
import styled from 'styled-components'


export const Box = styled.button`
    display: flex;
    flex-direction: ${(props) => props.direction};
    flex-wrap: wrap;
    justify-content: ${(props) => (props.jContent ? props.jContent : 'unset')};
    align-items: ${(props) => (props.aItems ? props.aItems : 'unset')};
    box-shadow:${({shadow})=> shadow && '0px 4px 15px rgba(0, 0, 0, 0.15)'} ;
    background: #FFFFFF;
    border-radius: 50%;
    margin-bottom: ${({gutterButtom})=> gutterButtom && '10px' };
    padding: 8px;

    svg{
        color: ${({color})=> color && color };
        width: ${({size})=> size && size};
        height: ${({size})=> size && size};
    }
`

const arrowTpes = {
    left:'chevron-left',
    right:'chevron-right',
    down:'chevron-down',
    up:'chevron-up',
}

const colorTypes = {
    primary: '#1D2447',
    secondary: '#768BFF',
  }

function ArrowButton({varient, color, size, onClick, shadow}) {

    useEffect(() => {
        feather.replace()
    })


    const direction = varient ? arrowTpes[varient] : 'chevron-down'
    const iconColor = color ? colorTypes[color] ? colorTypes[color] : color : colorTypes['primary']
  

    return (
        <Box jContent="center" aItems="center" color={iconColor} size={size} onClick={onClick} shadow={shadow}>
            <i data-feather={direction}></i>
        </Box>
    )
}

export default ArrowButton
