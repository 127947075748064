import React, { useEffect, useState } from 'react'
import Card from '../Admin/shared/Card'
import P from '../Admin/shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import AsyncSelect from "react-select/async"
import styles from './styles/AnalyticPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv"
import DataTable from "react-data-table-component-footer"
import   '../Admin/styles/DataTableCustom.scss'

const RecruitersAssignmentsAnalyticPage = (team_leads) => {
  const [recruiters,setRecruiters] = useState([])
  const [selectedTeamLead,setSelectedTeamLead] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [member_options, setOptions] = useState([])
  const [selectedOrganization, setSelectedOrganization]  = useState('')
  const [inputValue, setInputValue] = useState('')
  const [exportDatas, setExportDatas] = useState([])
  const csvExportRef = React.useRef();
  const [loading, setLoader] = useState(true)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [footer, setFooter] = useState({})

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }

  const header = [
    { label: 'Recruiter Name', key: 'fullName' },
    { label: 'Assigned Hourly Organization', key: 'orgHourly' },
    { label: 'Assigned Contingency Organization', key: 'orgContingency' },
    { label: 'Assigned Job Count', key: 'jobCount' },
  ]

  const columns = [
    { name: 'No.',
      selector: row => row.id,
      maxWidth: '5%',
      minWidth: '5%'
    },
    { name: 'Recruiter Name',
      selector: row => row.fullName,
      maxWidth: '20%',
      minWidth: '20%',
      wrap:true,
      sortable: true,
      sortField: 'full_name'
    },
    { name: 'Assigned Hourly Organization',
      selector: row => row.orgHourly,
      maxWidth: '30%',
      minWidth: '30%',
    },
    { name: 'Assigned Contingency Organization',
      selector: row => row.orgContingency,
      maxWidth: '30%',
      minWidth: '30%',
     
    },
    { name: 'Assigned Job Count',
      selector: row => row.jobCount,
      maxWidth: '15%',
      minWidth: '15%',
      sortable: true,
      sortField: 'job_count',
     
    }
  ]
  const fetchData = async () => {
    setLoader(true)
    const url = '/analytic/recruiters_assignments_analytic.json'
    const response = await makeRequest(
      `${url}?organization=${selectedOrganization}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    let sr_no = 0
    setRecruiters(response?.data?.recruiters.map((data,key)=> (sr_no = sr_no + 1,{
      id: sr_no,
      userId: data?.user_id,
      fullName: `${data?.full_name && capitalize(data?.full_name)} ${data?.team_name && `(${data?.team_name})`}`,
      teamName: data?.team_name,
      orgContingency: `${data?.org_contingency} ${data.contingency && `(${data.contingency})`}`,
      orgHourly: `${data?.org_hourly} ${data.hourly && `(${data.hourly})`}`,
      contingency: data.contingency,
      hourly: data.hourly,
      jobCount: data.job_count,
    })))
    setFooter({
      id:'Total',
      contingency:response.data.total_values.contingency,
      hourly:response.data.total_values.hourly,
      jobCount:response.data.total_values.jobCount
    })
    setLoader(false)
  }

  useEffect(()=>{
    fetchData()
  },[selectedOrganization,sortDirection,sortField])

  const loadOptions = (inputValue, callback) => {
    setLoading(true)
    fetch(`/admin/organizations/fetch_organization?search=${inputValue}`)
    .then((res) => res.json())
    .then((res) => {
      let {organizations} = res
      setLoading(false)
      setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(member_options);
  }

const handleInputChange = (str) => {
  setInputValue(str)
  return str;
}

const handleSelectOption = (selectedOptions) => {
  setSelectedOrganization(selectedOptions?.value || '')
}

const exportDataMethod = async () => {
  const url = '/analytic/recruiters_assignments_analytic.json'
    const response = await makeRequest(
      `${url}?organization=${selectedOrganization}&team_lead=${selectedTeamLead}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.recruiters.map((data,key)=> ({
      id: key,
      fullName: `${data?.full_name && capitalize(data?.full_name)} ${data?.team_name && `(${data?.team_name})`}`,
      teamName: data?.team_name,
      orgContingency: `${data?.org_contingency} ${data.contingency && `(${data.contingency})`}`,
      orgHourly: `${data?.org_hourly} ${data.hourly && `(${data.hourly})`}`,
      jobCount: data.job_count,
    })))
    csvExportRef?.current?.link.click();
}

const handleSort = async (column, sortDirection) => {
  if(column.sortField != undefined && sortDirection != undefined){
      setSortField(column.sortField)
      setSortDirection(sortDirection)
  }
}

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Recruiters Assignments Analytic
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={header}
        filename={"RecruitersAssignmentsAnalytic.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
              <div style={{display: 'grid'}}>
                <label>
                  Organization
                </label>
                <AsyncSelect
                  isLoading={isLoading}
                  isClearable={true}
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions={selectedOrganization}
                  onInputChange={handleInputChange}
                  onChange={handleSelectOption}
                  placeholder={'Search by organization'}
                  styles={colourStyles}
                  noOptionsMessage={() => 'start typing the name of organization'}
              />
              </div>
          </div>
      </Col>
      </Row>
      <DataTable
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={recruiters}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        onSort={handleSort}
        sortServer
        highlightOnHover
        footer={footer}
      />
    </Card>
  )
}

export default RecruitersAssignmentsAnalyticPage