import React, { useState, useEffect } from 'react'
import { Card, Col, Image, Row, ProgressBar } from 'react-bootstrap'
import Table from '../Admin/shared/Table'
import './styles/Subscription.scss'

import moment from 'moment'
const Invoice_v2 = (props) => {
  const { invoice_list } = props
  const [loading, setLoading] = useState(false)
  console.log(invoice_list)
  return (
    <>
      <section class="section-box mt-40" style={{marginBottom: '50px',minHeight: '45vh'}}>
          <div className='container'  style={{marginTop: '25px'}} >
            <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12"><h5>Invoices</h5> </div>
                <hr style={{marginTop: '20px'}}></hr>
                <div class="col-md-12 col-lg-12 col-sm-12" style={{marginTop: '50px'}}>
                    <Table
                        columNames={[
                        {
                            name: 'Invoice No.',
                            field: 'number',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Date',
                            field: 'date',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Plan',
                            field: 'plan',
                            editable: true,
                            type: 'text',
                        },
                        {
                            name: 'Amount',
                            field: 'amount',
                            editable: false,
                            type: 'text',
                            nowrap: ''
                        },
                        {
                            name: 'Action',
                            field: 'invoice_pdf',
                            editable: false,
                            type: 'text'
                        }
                        ]}
                        rowValues={invoice_list.map((o) => ({
                        ...o,
                        amount: '$'+o.amount,
                        date: moment.unix(o.date).format('MMM DD, YYYY'),
                        invoice_pdf: o.invoice_pdf !== "" ? <a href={o.invoice_pdf}> Download </a> : 'o.invoice_pdf'
                        // <div className={styles.candidateAvtarImage}>{ (o.first_name) ? (o?.first_name && Util.getFirstCharater(o?.first_name)) + (o?.last_name && Util.getFirstCharater(o?.last_name) ) : (o?.email && Util.getFirstCharater(o?.email) ) }</div>
                        }))}
                        activePage={'1'}
                        perPage={'1'}
                        loading={loading}
                    />
                </div>
            </div>
         </div>
      </section>
    </>
  )
}
export default Invoice_v2
