import React, { useState, useRef, useEffect } from 'react'
import styles from '../SignupPage/styles/Signup.module.scss'
import {H1,P} from './styles/CreateProfilePage.styled'
import './styles/CalanderBookSlot.scss'

const PendingApprovalPage = ({
    user
}) => {
    return (
        <>
            <div className={`${styles.signUpForm}`}>
                <div class={`${styles.approvalTextCenter}`}>
                    <H1 style={{fontWeight: '600',marginBottom: '20px'}}>Hi {user.first_name} {user.last_name},</H1>
                    <H1 style={{fontSize: '27px',color: '#667eff'}}>Welcome to CardinalTalent</H1>
                    <P>Due to high demand you are currently #39 on the waiting list. We will be in touch with you soon.</P>
                </div>
            </div>
        </>
    )
}

export default PendingApprovalPage
