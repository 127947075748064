import React, { useRef, useState, useEffect } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/InMails.scss'
import Button from '../Admin/shared/Button'

const InMailsAdd = ({organizations,recruiters,inMailEntry,onHide,method,fetchData}) => {
	
	const [validationError, setValidationError] = useState({})
	const [entryData, setEntryData] = useState({
		organization_id: inMailEntry?.organization_id ? inMailEntry?.organization_id : '',
		entry_date: inMailEntry?.entry_date ? inMailEntry?.entry_date : '',
		sent: inMailEntry?.sent ? inMailEntry?.sent : '',
		response_text: inMailEntry?.response_text ? inMailEntry?.response_text : '',
		response_attachment: (inMailEntry?.response_attachment?.blob) ? (inMailEntry?.response_attachment?.blob) : '',
		reason: inMailEntry?.reason ? inMailEntry?.reason : '',
		note: inMailEntry?.note ? inMailEntry?.note : '',
		attachment_attachment: (inMailEntry?.attachment_attachment?.blob) ? (inMailEntry?.attachment_attachment?.blob) : '',
	})
	const [reason, setReason] = useState([
		{value: "Some emails are missing on my outlook inbox.",label: "Some emails are missing on my outlook inbox."},
		{value: "Had some personal emergencies, will complete the pending work on coming days.",label: "Had some personal emergencies, will complete the pending work on coming days."},
		{value: "I already sent in the required number of inmails for the whole week.",label: "I already sent in the required number of inmails for the whole week."},
		{value: "I am still going through past responses.",label: "I am still going through past responses."},
		{value: "Technical Problems.",label: "Technical Problems."},
		{value: "Others.",label: "Others."},
	])
	const [btnDisabled, setBtnDisabled] = useState(false)
	const [showResponseFile, setShowResponseFile] = useState((inMailEntry?.response_attachment)?true:false)
	const [showAttachmentFile, setShowAttachmentFile] = useState((inMailEntry?.attachment_attachment)?true:false)
	const [isEdit , setIsEdit] = useState((inMailEntry?.id)?true:false)
	const [reasonRequired,setReasonRequired]= useState(false)
	const handleChange = (e) => {
		setValidationError({})
	}
	const handleChangeOrganization = (e) =>{
		setExpectedSent(e?.daily_target)
		setResponseRequest(e?.response_request)
		handleChange()
	}
	const responseFileChange = ()=>{
		if(responseFileRef.current.files.length > 0){
			setResponseFile(responseFileRef.current.files)
			setShowResponseFile(false)
		}
	}

	const attachmentFileChange = ()=>{
		if(attachmentFileRef.current.files.length > 0){
			setAttachmentFile(attachmentFileRef.current.files)
			setShowAttachmentFile(false)
		}
	}

	const inmailSentChange = (e)=>{
		let Expectedsentval = 0
		if(expectedSent > 0){
			Expectedsentval = Math.floor((expectedSent * 60)/100)
		}
		if(e.target.value <= Expectedsentval){
			setReasonRequired(true)
		}else{
			setReasonRequired(false)
		}
		handleChange()
	}

	const [modal,setModal] = useState('')
	const [expectedSent,setExpectedSent] = useState(0)
	const [responseRequest,setResponseRequest]= useState(false)
	const [responseFile,setResponseFile]= useState('')
	const [attachmentFile,setAttachmentFile]= useState('')
	const responseFileRef = useRef()
	const attachmentFileRef = useRef()
	const attachmentfileTypes = [
		'image/x-png','image/gif','image/jpeg','image/png'
	]
	const responsefileTypes = [
		'text/csv',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.ms-excel',
	]
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/in_mails'
			if(method === 'put'){
				URL = `/in_mails/${inMailEntry.id}`
			}
			const payload = new FormData()
			payload.append('in_mails[organization_id]', e?.target?.organization_id?.value || '')
			payload.append('in_mails[user_id]', recruiters?.id || '')
			payload.append('in_mails[entry_date]', e?.target?.entry_date?.value || '')
			payload.append('in_mails[sent]', e?.target?.sent?.value || '')
			payload.append('in_mails[response_text]', e?.target?.response_text?.value || '')
			if (responseFile[0])
				payload.append('in_mails[response]', (responseFile[0])?responseFile[0]:'')
			payload.append('in_mails[reason]', e?.target?.reason?.value || '')
			payload.append('in_mails[note]', e?.target?.note?.value || '')
			if (attachmentFile[0])
				payload.append('in_mails[attachment]', (attachmentFile[0])?attachmentFile[0]:'')
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
						onHide()
						fetchData()
				}
			}) 
		}
		setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		if(!e.target.entry_date.value){
				formIsValid = false;
				errors["entry_date"] = "Date is required field."
		}
		if(!e.target.organization_id.value){
			formIsValid = false;
			errors["organization_id"] = "Organization is required field."
		}
		if(!e.target.sent.value){
			formIsValid = false;
			errors["sent"] = "InMail Sent is required field."
		}else{
			if (!(new RegExp("^[0-9\b]+$").test(e.target.sent.value))){
				formIsValid = false;
				errors["sent"] = "Invalid InMail Sent value"
			}
			let Expectedsentval = 0
			if(expectedSent > 0){
				Expectedsentval = Math.floor((expectedSent * 60)/100)
			}
			if(e.target.sent.value <= Expectedsentval){
				if(!e.target.reason.value){
					formIsValid = false;
					errors["reason"] = "Reason is required field."
				}
			}
		}
		if(responseRequest && !e.target.response_text.value){
			formIsValid = false;
			errors["response_text"] = "Response is required field."
		}
		if((responseRequest || e.target.response_text.value != '') && responseFileRef?.current?.files?.length == 0 && !isEdit){
			formIsValid = false;
			errors["response_file_url"] = "Response File is required field."
		}
		if(responseFileRef?.current?.files?.length > 0){
			if(responseFileRef?.current.files[0]?.size > 5000000){
				formIsValid = false;
				errors["response_file_url"] = "Response File is not allowed more then 5MB."
			}
			if (!responsefileTypes.includes(responseFileRef?.current?.files[0]?.type)) {
				formIsValid = false;
				errors["response_file_url"] = "Invalid file format,Please upload CSV and Excel file only."
			}
		}
		if(attachmentFileRef?.current?.files?.length > 0){
			if(attachmentFileRef?.current?.files[0]?.size > 5000000){
				formIsValid = false;
				errors["attachment_file_url"] = "Attachment File is not allowed more then 5MB."
			}
			if (!attachmentfileTypes.includes(attachmentFileRef?.current?.files[0]?.type)) {
				formIsValid = false;
				errors["attachment_file_url"] = "Invalid file format,Please upload image file only."
			}
		}

		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
				<Row className="w-100 m-0">
					<Col xs={12} sm={12} lg={6}>
						<span>Select Date* : </span>
						<input type='date' defaultValue={entryData.entry_date} onChange={handleChange} className='form-control w-100' name='entry_date' id="entry_date" />

						<label className='error'>
							{validationError && validationError.entry_date} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Select Organization* : </span>
						<Select
							options={organizations}
							name='organization_id'
							id='organization_id'
							placeholder={'Select Organization'}
							onChange={handleChangeOrganization}
							defaultValue={organizations.filter((row) => row.value == entryData.organization_id)}
							menuPortalTarget={modal}
						/>
						<label className='error'>
							{validationError && validationError.organization_id} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Expected Sent :</span>
						{(expectedSent > 0) ?<h4 className=''><b>{expectedSent}</b></h4> : <p className=''>( Please select organization )</p>}
						
						<label className='error'>
							{validationError && validationError.daily_target} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>InMail Sent* : </span>
						<input type='number'  defaultValue={entryData.sent} onChange={inmailSentChange} className='form-control w-100' name='sent' id="sent" />
						<label className='error'>
							{validationError && validationError.sent} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Response{(responseRequest)&&'*'} : </span>
						<input type='text'  defaultValue={entryData.response_text} onChange={handleChange} className='form-control w-100' name='response_text' id="response_text" />
						<label className='error'>
							{validationError && validationError.response_text} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>InMail Response File{(responseRequest)&&'*'} : </span>
						<input ref={responseFileRef} type='file' style={{padding:'3px'}}  onChange={responseFileChange} className='form-control w-100' name='response_file_url' id="response_file_url" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
						{(entryData?.response_attachment && showResponseFile) &&
							<label><a target='_blank' href={entryData?.response_attachment?.service_url}>{(entryData?.response_attachment) ? (entryData?.response_attachment?.filename) : ''}</a></label>
						}
						<label className='error'>
							{validationError && validationError.response_file_url} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Reason {(reasonRequired)&&'*'} : </span>
						<Select
							options={reason}
							name='reason'
							id='reason'
							placeholder={'Select Reason'}
							onChange={handleChange}
							defaultValue={reason.filter((row) => row.value == entryData.reason)}
							menuPortalTarget={modal}
						/>
						<label className='error'>
							{validationError && validationError.reason} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<p className='mt-4'>( if sent count is lesser than expected )</p>
					</Col>
					<Col xs={12} sm={12} lg={12}>
						<span>Note : </span>
						<textarea onChange={handleChange} className='form-control w-100' name='note' id="note" defaultValue={entryData.note}></textarea>
						<label className='error'>
							{validationError && validationError.note} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={12}>
						<span>Attachment : </span>
						<input ref={attachmentFileRef} type='file' style={{padding:'3px'}}  onChange={attachmentFileChange} className='form-control w-100' name='attachment_file_url' id="attachment_file_url" accept="image/x-png,image/gif,image/jpeg" />
						{(entryData?.attachment_attachment && showAttachmentFile) &&
							<label><a target='_blank' href={entryData?.attachment_attachment?.service_url}>{(entryData?.attachment_attachment) ? (entryData?.attachment_attachment?.filename) : ''}</a></label>
						}
						<label className='error'>
							{validationError && validationError.attachment_file_url} 
						</label>
					</Col>
				</Row>
				<Row className="w-100 modal-entry-form-row mt-4">
					<Col xs={12} sm={12} lg={12}>
							<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius mr-2'>Save</Button>
					</Col>
				</Row>
			</form>
		</>
  )
}

export default InMailsAdd