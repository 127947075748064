import React, { useEffect, useState } from 'react'
import Card from '../Admin/shared/Card'
import P from '../Admin/shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import { capitalize } from '../../../utils'
import Button from '../Admin/shared/Button'
import DataTable from 'react-data-table-component-footer'
import   '../Admin/styles/DataTableCustom.scss'

const CrossSubmissionsAnalyticDetailPage = ({metaData}) => {
    const [loading, setLoading] = useState(true)
    const [detailData, setDetailData] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(metaData.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    
    const stages = {lead: 'Lead', applicant: 'Applicant', recruitor_screen: 'Recruiter Screened', submitted: 'Submitted', submitted: 'Submitted', first_interview: 'First Interview', second_interview: 'Second Interview', offer: 'Offer', reject: 'Archived', hired: 'hired'}
    const columns=[
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Candidate Name',
            selector: row => row.candidate_name,
            sortable: true,
            sortField: 'first_name',
            maxWidth: '30%',
            minWidth: '30%'
        },
        {
            name: 'Recruiter Name',
            selector: row => row.recruiter_name,
            sortable: true,
            sortField: 'recruiter_first_name',
            maxWidth: '25%',
            minWidth: '25%'
        },
        {
            name: 'Moved On',
            selector: row => row.created_at,
            sortable: true,
            sortField: 'created_at',
            maxWidth: '15%',
            minWidth: '15%'
        },
        {
            name: 'Moved from',
            selector: row => row.previous_stage,
            sortable: true,
            sortField: 'created_at',
            maxWidth: '10%',
            minWidth: '10%'
        },
        {
            name: 'Stage',
            selector: row => row.stage,
            sortable: true,
            sortField: 'stage',
            maxWidth: '15%',
            minWidth: '15%'
        },
    ]
    
    const fetchData = async () => {
        setLoading(true)
        const url = '/analytic/cross_submissions_analytic_detail.json'
        let start_date = moment(metaData.start_date).format('YYYY-MM-DD')
        let end_date =  moment(metaData.end_date).format('YYYY-MM-DD')
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&start_date=${start_date}&end_date=${end_date}&job_id=${metaData.job_id}&stage=${metaData.stage}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        console.log(response?.data?.detail_list, '====>')
        let sr_no = (activePage - 1) * response.data.per_page
        setDetailData(response?.data?.detail_list.map((data,key)=> (sr_no=sr_no+1,{
            id: sr_no,
            previous_stage: data?.previous_stage && stages[data.previous_stage],
            candidate_name: capitalize(data.first_name) + ' '+ capitalize(data.last_name),
            recruiter_name: data?.recruiter_first_name ? capitalize(data.recruiter_first_name) + ' '+ capitalize(data.recruiter_last_name) : '',
            job_name: capitalize(data.name),
            stage: capitalize(stages[data.stage]),
            created_at:moment(data.created_at).format('MMM DD, YYYY')
        })))
        setLoading(false)
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
       
    }

    useEffect(()=>{
        fetchData()
    },[activePage,sortDirection,sortField])

    const redirectBack = () => {
        window.location.href = `/analytic/cross_submissions_analytic?start_date=${metaData.start_date}&end_date=${metaData.end_date}`
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '20px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Cross Analytics Details
                </P>
                <div>
                    <Button  className="ml-3" onClick={ ()=> redirectBack()}>
                        Go Back
                    </Button>
                </div>
            </div>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="4" md="4" sm="12" xs="4" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col  lg="4" md="4" sm="12" xs="4" style={{textAlign:'right'}}>
                    <P size="17px">
                       <label style={{fontSize:'20px'}}>Job : </label> &nbsp;&nbsp; {capitalize(metaData.job_detail.name)} 
                    </P>
                </Col>
                <Col  lg="4" md="4" sm="12" xs="4" style={{textAlign:'right'}}>
                    <P size="17px">
                    <label style={{fontSize:'20px'}}>Date From : </label> &nbsp;&nbsp; {moment(metaData.start_date).format('MMM DD, YYYY')}&nbsp;&nbsp; To &nbsp;&nbsp; {moment(metaData.end_date).format('MMM DD, YYYY')}
                    </P>
                </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={detailData}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
            />
        </Card>
    )
}

export default CrossSubmissionsAnalyticDetailPage