import React, { useState,useEffect, useReducer } from 'react'
import { Formik } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import moment from 'moment'
import { H1, StyledForm, Label, Button } from '../../Admin/styles/UserManagementEditPage.styled'
import TextInput from '../../Admin/shared/TextInput'
import Select from '../../Admin/shared/Select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import AsyncSelect from "react-select/async";



const EmployeeAdd = ({employee,user,method,message}) => {
    const [isLoading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [users_options, setOptions] = useState([])
    const [optionManager, setOptionManager] = useState([])
    const [selectedUser, setSelectedUser]  = useState([{value: user?.id, label: user?user.first_name +' '+ user.last_name : ''}])
    const [states, setStates] = useState([])
    const [userId,setUSerId] = useState(user?.id)
    const [errorUserId,setErrorUserId] = useState('')
    const [errorJoinDate,setErrorJoinDate] = useState('')

    const type = [
        {display:'Exempt', value:'exempt'},
        {display:'Non-Exempt', value:'non-exempt'}]

    
    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/cardinal/employees/get_cardinal_member?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {members} = res
          setLoading(false)             
          const options = [...members.map((member) => ({ value: member.user_id, label: member.first_name +' ' + member.last_name}))]
          const uniquemOptions = options.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        JSON.stringify(t) === JSON.stringify(thing)
                    ))
                )
          setOptions(uniquemOptions);
        })
        .catch((err) => console.log("Request failed", err));
        callback(users_options);
    }

    useEffect(() => {
        getState()
        getManager()
      }, []);
    
    const getManager = () => {
        fetch(`/cardinal/employees/get_manager`)
        .then((res) => res.json())
        .then((res) => {
          let {members} = res
          setOptionManager([...members.map((member) => ({ value: member.user_id, label: member.first_name +' ' + member.last_name}))]);
        })
        .catch((err) => console.log("Request failed", err));
    }

    const handleInputChange = (str) => {
        setInputValue(str)
        return str;
    }
   
    const handleSelectUser = (selectedOptions) => {
      setSelectedUser([selectedOptions])
      setUSerId(selectedOptions.value)
    }
 

    const getState = () => {
        const lookupsUrl = '/signup/lookups'
        axios.get(lookupsUrl).then((res) => {
            setStates([...res.data.states])
        })
    } 

    const saveEmployee = (employeeData) => {
        let URL = `/cardinal/employees`
        if(method === 'put'){
            URL = `/cardinal/employees/${employee.id}`
        }
        const payload = new FormData()
        payload.append('employee[user_id]', employeeData.user_id)
        payload.append('employee[join_date]', employeeData.join_date)
        payload.append('employee[location]',employeeData.location)
        payload.append('employee[employee_type]',employeeData.employee_type)
        payload.append('employee[manager_id]',employeeData.manager_id)
        payload.append('employee[salary]',employeeData.salary)
        payload.append('employee[salary_schedule]',employeeData.salary_schedule)
        makeRequest(URL,method,payload , {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: () => {
                let messsage = method == 'put' ? 'Update' :'Save' 
                return {
                    message: `${messsage} Successfully`,
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: () => {
                window.location.href = '/cardinal/employees'
            },
        })
    }
    return (
       
    <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
    <H1>{method == 'post' ? 'Add Employee' : 'Update Employee'}</H1>
    <Formik
        initialValues={{
            user_id: employee?.user_id,
            join_date: employee? moment(employee.join_date).format('YYYY-MM-DD') : '',
            location: employee?.location,
            employee_type: employee?.employee_type || 'exempt',
            manager_id: employee?.manager_id || 0,
            salary: employee?.salary || 0,
            salary_schedule: employee?.salary_schedule || 'week'
        }}
        validationSchema={Yup.object({
            join_date: Yup.date()
                .required('Join Date is required'),
            location: Yup.string()
                .required('Location is required'),
            employee_type: Yup.string()
                .required('Employee Type is required'),
            salary: Yup.number()
                .required('Salary is required')
                .min(1, 'Minimum value is 1')
                .max(999999, 'Maximum value is 999999'),
            salary_schedule: Yup.string()
                .required('Salary schedule is required'),
        })}
        onSubmit={(values) => {
            setErrorJoinDate('')
            setErrorUserId('')
            // Date validation for join date
            let join_date = moment(values.join_date)
            let current_date = moment()
            let dayDiff =  current_date.diff(join_date, 'days')
            if(dayDiff <= 0){
                setErrorJoinDate('Joined date must be before the current date')
                return false;
            }
          
            // User id validation
            if(!(userId > 0)){
                setErrorUserId('User is required')
                return false;
            }

            saveEmployee({
                user_id: userId,
                join_date: values.join_date,
                location: values.location,
                employee_type: values.employee_type,
                manager_id: values.manager_id,
                salary: Math.round(values.salary),
                salary_schedule: values.salary_schedule
            })
        }}
    >
        <StyledForm>
            <Row className='align-items-center justify-content-center'>
                <Col xs={12} sm={12} lg={12}>
                  <Row>
                    <Col xs={12} sm={12} lg={4}>
                        <Label>
                            {'Select User*'}
                        </Label>
                        <AsyncSelect
                            isLoading={isLoading}
                            isClearable={false}
                            isDisabled={(method == 'put') ? true : false}
                            cacheOptions
                            placeholder= 'search for user'
                            loadOptions={loadOptions}
                            defaultValue={selectedUser[0]}
                            onInputChange={handleInputChange}
                            onChange={handleSelectUser}
                            noOptionsMessage={() => 'start typing the name of user'}
                        />
                         <Label style={{fontSize: '10px',color: 'red',marginTop: '5px'}}>
                            {errorUserId && errorUserId}        
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} lg={4}>
                      <TextInput
                          label="Joined Date*"   
                          name="join_date"
                          type="date"
                          id="join_date"
                          width="100%"
                      />
                      <Label style={{fontSize: '10px',color: 'red',marginTop: '5px'}}>
                            {errorJoinDate && errorJoinDate}        
                        </Label>
                    </Col>
                 
                    <Col xs={12} sm={12} lg={4}>
                        <Select
                            label="Location*"
                            name="location"
                            id="location"
                            width="100%"
                            type="text"
                        >
                            <option value="">Select State</option>
                            {states.map(
                                ({ key, value }) => {
                                    return (
                                        <option
                                            key={key}
                                            value={key}
                                        >
                                            {value}
                                        </option>
                                    )
                                }
                            )}
                        </Select>
                    </Col>
                 </Row>
                  <Row>
                    <Col xs={12} sm={12} lg={4}>
                        <Select
                                label="Type*"
                                name="employee_type"
                                id="employee_type"
                                width="100%"
                            >
                                {type && type.map((a, index) => {
                                    return (
                                        <option key={a.value} value={a.value}>{a.display}</option>
                                    )
                                })}
                        </Select>
                    </Col>
                  
                    <Col xs={12} sm={12} lg={4}>
                        <Select
                                label="Manager"
                                name="manager_id"
                                id="manager_id"
                                width="100%"
                                placeholder="Select Manager"
                            >   
                                <option> Select Manager </option>
                                 {optionManager.map(
                                    ({ value, label }) => {
                                        return (
                                            <option
                                                key={value}
                                                value={value}
                                            >
                                                {label}
                                            </option>
                                        )
                                    }
                                )}
                        </Select>
                    </Col>
                    <Col xs={12} sm={12} lg={2}>
                        <TextInput
                            label="Salary ($) *"
                            name="salary"
                            type="number"
                            id="salary"
                            width="100%"
                            placeholder= '$'
                        />
                    </Col>
                    <Col xs={12} sm={12} lg={2}>
                        <Select
                                
                                name="salary_schedule"
                                id="salary_schedule"
                                width="100%"
                                placeholder= 'Salary Schedule'
                            >
                                <option value="week">Per Week</option>
                                <option value="month">Per Month</option>
                        </Select>
                    </Col>
                  </Row>
                </Col>
                
            </Row>
            <Row>
                <Col>
                    <div style={{ marginTop: '18px' }}>
                        <Button type="submit">Save</Button>
                        <Button
                            type="button"
                            onClick={() => window.history.back()}
                            className="ml-sm-3"
                        >   
                            Go Back
                        </Button>
                       
                        
                    </div>
                </Col>
            </Row>
        </StyledForm>
    </Formik>
  </div>
  
  )
}
export default EmployeeAdd