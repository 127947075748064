import React, { Fragment } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Input from '../../common/Styled components/Input'
import { Row, W3text, Typography } from './styles/AccountSettingPage.styled'
import styles from './styles/AccountSettingPage.module.scss'

const Schema = {
    firstName: Yup.string()
        .required('First Name is required')
        .test(
            'first name alphabets only',
            'First Name can only contain alphabet characters and one space in between if there are two words',
            function (value) {
                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                return regex.test(value)
            }
        ),

    lastName: Yup.string()
        .required('Last Name is required')
        .test(
            'last name alphabets only',
            'Last Name can only contain alphabet characters and one space in between if there are two words',
            function (value) {
                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                return regex.test(value)
            }
        ),
    phoneNumber: Yup.string()
        .typeError('Invalid Phone number, please add numbers only')
        .nullable(true)
        .test(
            'phone number digits only',
            'Phone number must contain 10 to 15 digits only',
            function (value) {
                if( value != '' && value != undefined){
                    const regex = /^\d{10,15}$/g
                    return regex.test(value.replace('(','').replace(')','').replace('-','').replace(' ','').trim())
                }
                return true
                
            }
        ),
}

let finalLinkedInURL = ''

function EditAccountSetting(props) {
    const {
        firstName,
        lastName,
        email,
        handleSaveBasic,
        phoneNumber,
        linkedinProfileUrl,
        role,
        setIsEditBasicInfo,
        setLinkedinId
    } = props
    const linkedinProfileUrlScema = Yup.string().trim()
        .nullable(true)
        .test(
            'linkedin only',
            'Invalid url, please add Linkedin url only',
            function (value) {
                if(value){
                    try {
                        let hostname = new URL(value).hostname
                        finalLinkedInURL = value
                        setLinkedinId('')
                        if(value === 'https://www.linkedin.com' || value === 'https://www.linkedin.com/'){
                            return false
                        }
                        return (
                            hostname === 'linkedin.com' || hostname === 'www.linkedin.com'
                        )
                    } catch (error) {
                        finalLinkedInURL = value
                        setLinkedinId('')
                        if(value === 'www.linkedin.com/' || value === 'https://www.linkedin.com/' || value === 'linkedin.com/' || value === 'linkedin.com' || value === 'https://linkedin.com' || value === 'https://linkedin.com/'){
                            return false
                        }
                        let checkDomainExist = value.indexOf("linkedin.com/")
                        if(checkDomainExist == -1){
                            return false
                        }else if(checkDomainExist == 0){
                            let splitValue = value.split('linkedin.com/')
                            if(splitValue[1] === ''){ 
                                return false
                            }
                            finalLinkedInURL = 'https://www.'+ value
                            setLinkedinId('')
                            return true
                        }else{
                            let splitValue = value.split('linkedin.com/')
                            if(splitValue[0] === 'www.'){
                                finalLinkedInURL = 'https://'+ value
                               setLinkedinId('')
                                return true
                            }
                        }
                        return false
                    }
                }
                return true
    
            }
        )
    
    const talentSchema = {
        ...Schema,
        linkedinProfileUrl: linkedinProfileUrlScema,
    }

  const validationSchema = role === 'talent' ? Yup.object({...talentSchema}) :Yup.object({...Schema})

    return (
        <>
            <Formik
                initialValues={{
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    linkedinProfileUrl: linkedinProfileUrl,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        setSubmitting(false)
                    }, 500)
                    // values.linkedinProfileUrl = finalLinkedInURL
                    handleSaveBasic(values)
                }}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                    } = props
                    return (
                        <Fragment>
                            <Row aItems="center">
                                <label>First Name</label>
                                <Input
                                    type="text"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    name="firstName"
                                    width="40%"
                                    error={
                                        Boolean(errors.firstName) &&
                                        touched.firstName
                                    }
                                />
                                {errors.firstName && touched.firstName && (
                                    <Typography>{errors.firstName}</Typography>
                                )}
                            </Row>
                            <Row aItems="center">
                                <label>Last Name</label>
                                <Input
                                    type="text"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    name="lastName"
                                    width="40%"
                                    error={
                                        Boolean(errors.lastName) &&
                                        touched.lastName
                                    }
                                />
                                {errors.lastName && touched.lastName && (
                                    <Typography>{errors.lastName}</Typography>
                                )}
                            </Row>

                            <Row aItems="center">
                                <label>Email</label>
                                <p style={{ marginRight: '20px' }}>
                                    {email + '    '}{' '}
                                </p>
                                <W3text color="#5F73D9" size="">
                                    {' '}
                                    (We do not currently support changing email
                                    address){' '}
                                </W3text>
                            </Row>
                            {role === 'talent' && (
                                <>
                                    <Row aItems="center">
                                        <label>Linkedin profile</label>
                                        <Input
                                            type="text"
                                            value={values.linkedinProfileUrl}
                                            onChange={handleChange}
                                            name="linkedinProfileUrl"
                                            width="40%"
                                            error={
                                                Boolean(
                                                    errors.linkedinProfileUrl
                                                ) && touched.linkedinProfileUrl
                                            }
                                        />
                                        {errors.linkedinProfileUrl &&
                                            touched.linkedinProfileUrl && (
                                                <Typography>
                                                    {errors.linkedinProfileUrl}
                                                </Typography>
                                            )}
                                    </Row>
                                </>
                            )}
                            <Row aItems="center">
                                <label>Phone Number</label>
                                <Input
                                    type="text"
                                    value={(values.phoneNumber)?.replace('(','').replace(')','').replace('-','').replace(' ','').trim()}
                                    onChange={handleChange}
                                    name="phoneNumber"
                                    width="40%"
                                    error={
                                        Boolean(errors.phoneNumber) &&
                                        touched.phoneNumber
                                    }
                                />
                                {errors.phoneNumber && touched.phoneNumber && (
                                    <Typography>
                                        {errors.phoneNumber}
                                    </Typography>
                                )}
                            </Row>
                            <Row aItems="center">
                                <button
                                    type='submit'
                                    className={`${styles.editButton}`}
                                    onClick={handleSubmit}
                                >
                                    Save
                                </button>
                                <button
                                    className={`${styles.editButton}`}
                                    onClick={()=>setIsEditBasicInfo(false)}
                                >
                                    Cancel
                                </button>
                            </Row>
                        </Fragment>
                    )
                }}
            </Formik>
        </>
    )
}

export default EditAccountSetting
