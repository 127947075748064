import React, { Fragment, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
    W3text,
    Row,
    Typography
} from './styles/AccountSettingPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { passwordSchema } from '../../common/ValidationSchema/PasswordSchema'
import '../../../../assets/v2/css/main.css'
import './styles/account_setting.css'

const passwordValidationSchema = Yup.object({
    currentPassword: Yup.string().required('Current Password is required'),
    ...passwordSchema
})
const AccountSecurityPage = (props) => {
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState(false)
    const { currentUser } = props
    
    const handleReset = async (data) => {
        const { password, confirmPassword, currentPassword } = data
        const payload = new FormData()
        const url = '/user/security_update'
        payload.append('user[password]', password)
        payload.append('user[password_confirmation]', confirmPassword)
        payload.append('[current_password]', currentPassword)
        await makeRequest(url, 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            onSuccess: (res) => {
                if (res.data.message === 'Invalid Current Password') {
                    setError(true)
                    setIsSuccess(false)
                } else {
                    setIsSuccess(true)
                    setError(false)
                }
            },
        })
    }

    // TODO
    // Need a Nav component for account setting page nav

    return (
        <section className="section-box" style={{ overflow: 'inherit !important' }}>
            <div className="container bootstrap snippets bootdeys">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="panel panel-default">
                            <div className="panel-heading" style={{ backgroundColor: '#622fff85' }}>
                                <h4 className="panel-title" style={{ color: 'white' }}>Change Password</h4>
                            </div>
                            <div className="panel-body">
                                {isSuccess ? (
                                    <Row style={{ paddingBottom: '12rem' }}>
                                        <W3text color="#0f5132" size="22px">
                                            You’ve successfully changed your password.
                                        </W3text>
                                    </Row>
                                ) : (
                                    <Formik
                                        initialValues={{
                                            currentPassword: '',
                                            password: '',
                                            confirmPassword: '',
                                        }}
                                        validationSchema={passwordValidationSchema}
                                        onSubmit={(values, { setSubmitting }) => {
                                            setTimeout(() => {
                                                setSubmitting(false)
                                            }, 500)

                                            handleReset(values)
                                        }}
                                    >
                                        {(props) => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                isSubmitting,
                                                handleChange,
                                                handleSubmit,
                                            } = props
                                            return (
                                                <Fragment>
                                                    <div className="form-group">
                                                        <label className="col-sm-2 control-label">Current Password</label>
                                                        <div className="col-sm-6">
                                                            <input type="password" name="currentPassword" className="form-control" placeholder="Enter Current Password"
                                                                value={values.currentPassword} 
                                                                onChange={handleChange} 
                                                                style={{ height: 'auto' }}
                                                                error={
                                                                    Boolean(
                                                                        errors.currentPassword
                                                                    ) &&
                                                                    touched.currentPassword
                                                                }
                                                            />
                                                            {errors.currentPassword &&
                                                                touched.currentPassword && (
                                                                    <Typography>
                                                                        {
                                                                            errors.currentPassword
                                                                        }
                                                                    </Typography>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-sm-2 control-label">New Password</label>
                                                        <div className="col-sm-6">
                                                            <input type="password" name="password" className="form-control" placeholder="Enter New Password"
                                                                value={values.password} 
                                                                onChange={handleChange} 
                                                                style={{ height: 'auto' }}
                                                                error={
                                                                    Boolean(
                                                                        errors.password
                                                                    ) &&
                                                                    touched.password
                                                                }
                                                            />
                                                            {errors.password &&
                                                                touched.password && (
                                                                    <Typography>
                                                                        {
                                                                            errors.password
                                                                        }
                                                                    </Typography>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-sm-2 control-label">Re-enter New Password</label>
                                                        <div className="col-sm-6">
                                                            <input type="password" name="confirmPassword" className="form-control" placeholder="Re-enter New Password"
                                                                value={values.confirmPassword} 
                                                                onChange={handleChange} 
                                                                style={{ height: 'auto' }}
                                                                error={
                                                                    Boolean(
                                                                        errors.confirmPassword
                                                                    ) &&
                                                                    touched.confirmPassword
                                                                }
                                                            />
                                                            {errors.confirmPassword &&
                                                                touched.confirmPassword && (
                                                                    <Typography>
                                                                        {
                                                                            errors.confirmPassword
                                                                        }
                                                                    </Typography>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="col-sm-10 col-sm-offset-2">
                                                            <button onClick={handleSubmit} className="btn btn-default" style={{margin: '0px !important'}}>Reset Password</button>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                        }}
                                    </Formik>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
    )
}
export default AccountSecurityPage
