import React, { useState, useEffect } from 'react'
import { Alert, Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap'
import '../styles/AiInterview.scss'
import { makeRequest } from "../../../common/RequestAssist/RequestAssist";
import StartInterviewPage from '../InterviewProcessList/StartInterviewPage'
import PracticeInterviewScreeningPage from './PracticeInterviewScreeningPage'


const Welcome = ({ isTokenValid, organization, candidateInfo, token, interviewInfo, job }) => {
    const [isValidToken, setIsValidToken] = useState(isTokenValid);
    const [showStartInterviewModal, setShowStartInterviewModal] = useState(false);
    const [screenInterviewModal, setScreenInterviewModal] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const handleInputChange = (e) => {
      setFeedback(e.target.value);
    };
  
    const handleFeedbackSubmit = async () => {
      if (!feedback) return;
      setIsSubmitting(true);
      const payload = new FormData()
        payload.append('feedback_param[ai_interview_id]', candidateInfo?.interview_id)
        payload.append('feedback_param[candidate_email]', candidateInfo?.candidate_email)
        payload.append('feedback_param[feedback]', feedback)

        let URL = `/ai_interviews/save_feedback`
        
        await makeRequest(URL,'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
        }).then((res) => {
            window.location.href = 'https://cardinaltalent.ai/'
        }) 
    };
    return (
        <>
            {
            (candidateInfo == null || candidateInfo == undefined || interviewInfo == null || interviewInfo == undefined) ?
                <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
                    <div className='invalid-interview-div'>
                        <h3 style={{ fontSize: '18px', color: '#005999' }}>Welcome to CardinalTalent AI Interview</h3>
                        <p style={{ fontSize: '18px', marginTop: '20px' }}>Unfortunately, it looks like the invitation link you used is either invalid or has expired.</p>
                        <div className='d-flex'>
                            <a href="/users/sign_in" className='btn btn-primary ai-btn-primary'>Go to Home Page</a>
                        </div>
                    </div>
                </div>
            : (!isValidToken && candidateInfo.status != 'Completed') ? (
                <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
                    <div className='invalid-interview-div'>
                        <h3 style={{ fontSize: '18px', color: '#005999' }}>Welcome to CardinalTalent AI Interview</h3>
                        <p style={{ fontSize: '18px', marginTop: '20px' }}>Unfortunately, it looks like the invitation link you used is either invalid or has expired.</p>
                        <div className='d-flex'>
                            <a href="/users/sign_in" className='btn btn-primary ai-btn-primary'>Go to Home Page</a>
                        </div>
                    </div>
                </div>
            ) : candidateInfo.status === 'Completed' ? (
                <div className="completed-screen">
                    <div className="completed-content">
                        <div className="icon-checkmark">
                        </div>
                        <h2 className="mt-5 font-weight-bold" >Interview is complete!</h2>
                        <p>Do you have any feedback for us?</p>
                        <textarea
                            className="feedback-textarea"
                            placeholder="Write your feedback or experience here (optional)"
                            value={feedback}
                            onChange={handleInputChange}
                            disabled={isSubmitting}
                        />
                        <div>
                        <Button
                            className="btn ai-btn-primary" onClick={handleFeedbackSubmit} disabled={!feedback || isSubmitting}
                        > 
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                        </div>
                        <p className="help-text">
                            Need help? email <a href="mailto:support@cardinaltalent.ai">support@cardinaltalent.ai</a>
                        </p>
                    </div>
                </div>
            ) : (
                <div className="min-vh-100 pt-5 pb-5 ai-containter">

                    <Row className='min-h-100'>
                        <Col className='pb-5 pr-10 pl-10'>
                            <p className='mb-n1  pb-3'>Interview with</p>
                            <div className="d-inline-flex align-items-center">
                                {organization && organization?.image_url && organization?.image_url != null &&
                                    <img
                                        alt={organization.name} src={organization.image_url}
                                        width="50" height="50"
                                        className="d-inline-block align-top"
                                    />
                                }
                                <div className="ml-2">
                                    <p className="mb-n1">{organization.name}</p>
                                    <a href={organization.website_url} target='_blank' className="text-decoration-underline">{organization.website_url}</a>
                                </div>
                            </div>

                            <div className='pt-3'>
                                <p>Hi {candidateInfo.candidate_name},</p>
                                <p>{organization.name} is exicted to invite you to participate in digital interview for the {job.name}.</p>
                                <p>Pretty soon, you will be conducting an on-demand interview for {job.name}.</p>
                                <p>Before you participate in our digital interviewing process, we want to make sure we give you all of the  "need -to-know" information. Prior to begning the on-demand interview, please click "Practice or Start Interview", located below. By practicing the interview process, not only you are getting comfortable with the idea of digital interviewing, you are also ensuring that your network connection and internet browser work with the software</p>

                                <Alert variant="info" className='my-5'>
                                    <p><strong>Important Information!</strong></p>
                                    <p>Please ensure you are in a quiet place with a stable internet connection before starting the official interview. For the best experience, we recommend using a desktop or laptop.</p>
                                    <p>If you have any technical issues or questions, please reach out to our <a href="#" className="text-primary">support team</a>.</p>
                                </Alert>

                                <Card className='rounded-lg shadow'>
                                    <Card.Body>
                                        <Row>
                                            <Col md={6} className='divider-custom pr-5'>
                                                <h5 className="text-dark font-weight-bold">Practice</h5>
                                                <Card.Text>Take a practice session to get familiar with the interview format. This is just for your preparation, and your answers will not be recorded or reviewed.</Card.Text>
                                                <Card.Text><strong>Action:</strong><br />Click to launch a mock interview with sample questions to help you prepare for the real one.</Card.Text>

                                                <div className='d-flex justify-content-end'>
                                                    <Button variant='primary' className='ai-btn-primary' onClick={() => setScreenInterviewModal(true)}>Practice</Button>
                                                </div>
                                            </Col>


                                            <Col md={6} className=' pr-5' style={{ paddingLeft: '35px' }}>
                                                <h5 className="text-dark font-weight-bold">Start Interview</h5>
                                                <Card.Text>Begin the official AI interview for the {job.name} at Slope. Your responses will be recorded and reviewed by the hiring team. Be sure you're prepared and in a quiet environment before starting.</Card.Text>
                                                <Card.Text><strong>Action:</strong><br />Click to enter the official interview, where your answers will be captured in real-time for evaluation.</Card.Text>

                                                <div className='d-flex justify-content-end'>
                                                    <Button variant="primary" className='ai-btn-primary' onClick={() => setShowStartInterviewModal(true)}>Start interview</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}

            {showStartInterviewModal &&
                <StartInterviewPage
                    setShowStartInterviewModal={setShowStartInterviewModal}
                    showStartInterviewModal={showStartInterviewModal}
                    interviewId={candidateInfo.interview_id}
                    candidateInfo={candidateInfo}
                    organization={organization}
                />
            }

            {screenInterviewModal &&
                <PracticeInterviewScreeningPage
                    setScreenInterviewModal={setScreenInterviewModal}
                    screenInterviewModal={screenInterviewModal}
                    interviewId={candidateInfo.interview_id}
                    organization={organization}
                />
            }


        </>
    );

}
export default Welcome