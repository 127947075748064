import React from 'react'
import styles from './styles/Policy.module.scss'

function PrivacyPolicy() {
    return (
        <div className={styles.policyContainer}>
            <div>
                <p
                    className="c18 c11"
                    style={{
                        margin: 0,
                        color: '#000000',
                        fontSize: '9pt',
                        fontFamily: '"Times New Roman"',
                        paddingTop: '0pt',
                        paddingBottom: '0pt',
                        lineHeight: 1.0,
                        orphans: 2,
                        widows: 2,
                        textAlign: 'left',
                        height: '9pt',
                    }}
                >
                    <span
                        className="c9 c19 c28"
                        style={{
                            fontWeight: 400,
                            fontSize: '9pt',
                            fontFamily: '"Calibri"',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            fontStyle: 'normal',
                            color: '#000000',
                        }}
                    />
                </p>
            </div>
            <p
                className="c16"
                style={{
                    margin: 0,
                    color: '#000000',
                    fontSize: '9pt',
                    fontFamily: '"Times New Roman"',
                    paddingTop: '12pt',
                    paddingBottom: '0pt',
                    lineHeight: 1.0,
                    orphans: 2,
                    widows: 2,
                    textAlign: 'center',
                }}
            >
                <span
                    className="c3 c9"
                    style={{
                        backgroundColor: '#ffffff',
                        fontSize: '12pt',
                        fontFamily: '"Arial"',
                        color: '#000000',
                        fontWeight: 700,
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        fontStyle: 'normal',
                    }}
                >
                    CCPA
                </span>
            </p>
            <p
                className="c16"
                style={{
                    margin: 0,
                    color: '#000000',
                    fontSize: '9pt',
                    fontFamily: '"Times New Roman"',
                    paddingTop: '12pt',
                    paddingBottom: '0pt',
                    lineHeight: 1.0,
                    orphans: 2,
                    widows: 2,
                    textAlign: 'center',
                }}
            >
                <span
                    className="c3 c9"
                    style={{
                        backgroundColor: '#ffffff',
                        fontSize: '12pt',
                        fontFamily: '"Arial"',
                        color: '#000000',
                        fontWeight: 700,
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        fontStyle: 'normal',
                    }}
                >
                    DO NOT SELL MY PERSONAL INFORMATION
                </span>
            </p>
            <p
                className="c1"
                style={{
                    margin: 0,
                    color: '#000000',
                    fontSize: '9pt',
                    fontFamily: '"Times New Roman"',
                    paddingTop: '10pt',
                    paddingBottom: '0pt',
                    lineHeight: 1.0,
                    orphans: 2,
                    widows: 2,
                    textAlign: 'left',
                }}
            >
                <span
                    className="c0"
                    style={{
                        backgroundColor: '#ffffff',
                        fontSize: '12pt',
                        fontFamily: '"Arial"',
                        color: '#000000',
                        fontWeight: 400,
                    }}
                >
                    CardinalTalent.ai is a division of CardinalHire, Inc.
                    Our mission is to connect people who might be passively looking for a job with the right opportunities and help employers find the best candidates for their open positions.
                    CardinalTalent's AI recruiting software is used by companies of all sizes including many Fortune 500 companies to find qualified people for their open job positions, probably someone just like you.
                    We gather and share your professional personal information with companies who are looking for someone with your skill sets and professional background. This sharing is considered a sale under the CCPA.{' '}
                </span>
            </p>
            <p
                className="c1"
                style={{
                    margin: 0,
                    color: '#000000',
                    fontSize: '9pt',
                    fontFamily: '"Times New Roman"',
                    paddingTop: '10pt',
                    paddingBottom: '0pt',
                    lineHeight: 1.0,
                    orphans: 2,
                    widows: 2,
                    textAlign: 'left',
                }}
            >
                <span
                    className="c0"
                    style={{
                        backgroundColor: '#ffffff',
                        fontSize: '12pt',
                        fontFamily: '"Arial"',
                        color: '#000000',
                        fontWeight: 400,
                    }}
                >
                    CardinalTalent.ai, respects your choices.
                    As a California consumer, you have the right to opt-out of the sale of your personal information. {' '}
                </span>
                <span
                    className="c0"
                    style={{
                        backgroundColor: '#ffffff',
                        fontSize: '12pt',
                        fontFamily: '"Arial"',
                        color: '#000000',
                        fontWeight: 600,
                    }}
                >
                    Please email us at{' '}
                </span>
                <span
                    className="c20 c5 c4"
                    style={{
                        WebkitTextDecorationSkip: 'none',
                        color: '#0000ff',
                        textDecoration: 'underline',
                        textDecorationSkipInk: 'none',
                        fontSize: '12pt',
                        fontFamily: '"Arial"',
                        fontWeight: 600,
                        backgroundColor: '#ffffff',
                    }}
                >
                    <a
                        className="c22"
                        href="mailto:optout@cardinaltalent.ai"
                        style={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                        optout@cardinaltalent.ai
                    </a>
                </span>
                <span
                    className="c0"
                    style={{
                        backgroundColor: '#ffffff',
                        fontSize: '12pt',
                        fontFamily: '"Arial"',
                        color: '#000000',
                        fontWeight: 600,
                    }}
                >
                    &nbsp;to verify your request and complete the opt-out process.{' '}
                </span>
                <span
                    className="c2 c4"
                    style={{
                        color: '#000000',
                        fontWeight: 400,
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        fontSize: '12pt',
                        fontFamily: '"Arial"',
                        fontStyle: 'normal',
                        backgroundColor: '#ffffff',
                    }}
                >
                    For more information of California consumers’ rights under CCPA and how CardinalTalent collects and uses California consumer personal information, please visit our{' '}
                </span>
                <span
                    className="c20 c5 c4"
                    style={{
                        WebkitTextDecorationSkip: 'none',
                        color: '#0000ff',
                        textDecoration: 'underline',
                        textDecorationSkipInk: 'none',
                        fontSize: '12pt',
                        fontFamily: '"Arial"',
                        fontWeight: 600,
                        backgroundColor: '#ffffff',
                    }}
                >
                    <a
                        className="c22"
                        href="/privacy_policy"
                        style={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                        Privacy Policy.
                    </a>
                </span>
            </p>
        </div>
    )
}

export default PrivacyPolicy
