import React from 'react';
import moment from 'moment';

// initial state
const initialState = {
  recruiterId: '',
  recruiterName: '',
  organizationId: '',
  organizationId: '',
  dateRange: {
              startDate: moment().startOf('week').format('MMM,DD, YYYY'), 
              endDate: moment().endOf('week').format('MMM,DD, YYYY')
            },
  entries: [],
  meta: {from: '', to: '', missingDays: 7, totalHours: 0, jobs: [], organization: {}, organizations: []},
  activePage: 1,
  loading: true,
  error: [],
}

// actions 
function reducer(state, action) {
  const {type, value} = action

  switch(type) {
    case 'TOGGLE_DATE_RANGE':
      console.log('set date range');
      console.log(value)
      return {...state, dateRange: value}
    case 'SET_LOADING':
      return {...state, ...{loading: value}}
    case 'UPDATE_STATE':
      const {recruiterId, organizationId, entries, meta} = value
      const {from, to, missing_days, total_hours, organization, organizations, recruiter_name, jobs} = meta
      return {...state, loading: false, recruiterId, recruiterName:recruiter_name, organizationId, ...{entries, meta: {from, to, missingDays: missing_days, totalHours: total_hours, jobs: jobs, organization, organizations} }}
    default:
      console.log('its default')
  }
}

// context for timesheet entries
const TimeEntryContext = React.createContext(initialState)
export {initialState, reducer, TimeEntryContext}