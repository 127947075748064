import React, { useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

import P from './shared/P'
import Card from './shared/Card'
import { BlockBody, BlockHeader } from './styles/AdminDashboard.styled'
import { capitalize } from '../../../utils'

const TextMessengerDashboard = (props) => {
    const information = props.information
    const data = props.data
    const [formContent, setFormContent] = useState(
        information.map((resource) => ({
            name: resource.name,
            todayCount: resource.today_count,
            totalCount: resource.total_count,
        }))
    )

    return (
        <Card>
            <P size="30px" height="41px">
            Text Messenger Dashboard
            </P>
            <div style={{ marginTop: '50px', display: 'flex' }}>
                {formContent.map((resource, idx) => (
                    <div key={idx} style={{ marginLeft: '50px' }}>
                        <BlockHeader style={{textAlign:'center'}} width={200}>
                            <P size="16px" height="27px" color={resource.name === "candidates" ?
                                "red" : resource.name === "jobs" ? "blue" :
                                resource.name === "recruiters" ? "grey" : 
                                resource.name === "organizations" ? "orange" :  "#1D2447"}
                            >
                                {capitalize(resource.name)}
                            </P>
                        </BlockHeader>
                        <BlockBody>
                            <P size="10px" height="14px" color="#3F446E">
                                Today
                            </P>
                            <P
                                size="20px"
                                height="27px"
                                color="#3F446E"
                                marginTop="5px"
                            >
                                {resource.todayCount > 0 ? <a href='/admin/auto_candidate_submission'>{resource.todayCount}</a> : resource.todayCount }
                            </P>
                        </BlockBody>
                        <BlockBody>
                            <P size="10px" height="14px" color="#3F446E">
                                Total
                            </P>
                            <P
                                size="20px"
                                height="27px"
                                color="#3F446E"
                                marginTop="5px"
                            >
                                {resource.totalCount > 0 ? <a href='/admin/auto_candidate_submission'>{resource.totalCount}</a> :resource.totalCount }
                            </P>
                        </BlockBody>
                    </div>
                ))}
            </div>
        </Card>
    )
}

export default TextMessengerDashboard
