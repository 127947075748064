import React, { useEffect, useState } from 'react'
import _ from 'lodash'
// import '../../../../assets/v2/css/main.css'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import candidate1 from '../../../../assets/v2/imgs/page/candidates/img-candidate10.png';
import candidate2 from '../../../../assets/v2/imgs/page/candidates/img-candidate11.png';
import candidate3 from '../../../../assets/v2/imgs/page/candidates/img-candidate12.png';
import s1 from '../../../../assets/v2/imgs/s1.png';
import s2 from '../../../../assets/v2/imgs/s2.png';
import './styles/default.css';

const StepPage3 = ({
  setFormData
}) => {

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [validationError, setValidationError] = useState({})
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const currentHost = window.location.host;
  const handleVerification = async (event) => {
    if (validateForm(userEmail)) {
      const URL = '/account/invite_your_friend'
      const payload = new FormData()
      payload.append('email_invitation[email]', userEmail)
      payload.append('email_invitation[token]', '')
      makeRequest(URL, 'post', payload, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
        createResponseMessage: (res) => {
          setTimeout(() => {
            window.location.href = '/talent_home'
          }, 1000);
          return {
            message: res?.msg,
            messageType: res?.messageType
          }
        }
      })
    }
  }
  const validateForm = (e) => {
    let errors = {}
    let formIsValid = true
    const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
    if (!userEmail) {
      formIsValid = false;
      errors["email"] = "Email is required."
    }
    setValidationError(errors)
    setBtnDisabled(formIsValid)
    return formIsValid;
  }


  const handleSubmit = (event) => {
    event.preventDefault()
    handleVerification(event)
  }

  useEffect(() => {
    if (isSubmitted) {
      setFormData((prev) => ({
        ...prev,
        step: 'CONTACT_DETAILS',
        step2: true,
      }))
    }
    setIsSubmitted(false)
  }, [isSubmitted])

  const submitAction = async () => {
    setIsSubmitted(true)
  }

  return (
    <>
      <div className="row step-section-row" style={{ margin: 0, padding: 0}}>
        <div className="col-lg-6" style={{ backgroundColor: '#5192ff' }}>
          <div className="container">
            <div className="box-newsletter" style={{ background: 'none', marginTop: '50px' }}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-md-30">
                  <div className="card-none-bd hover-up wow animate__ animate__fadeInUp  animated" data-wow-delay=".0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>
                    <div className>
                      <h4 style={{ color: '#fff' }}>Total Invitations</h4>
                      <h2 style={{ color: '#fff' }}><span className="count">25</span></h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-md-30">
                  <div className="card-none-bd hover-up wow animate__ animate__fadeInUp  animated" data-wow-delay=".1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
                    <div className>
                      <h4 style={{ color: '#fff' }}>Total Earning</h4>
                      <h2 style={{ color: '#fff' }}><span className="count">$350</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="employers-list mt-60">
                <div className="card-employers hover-up wow animate__ animate__fadeIn  animated" style={{ visibility: 'visible', animationName: 'fadeIn' }}>
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 d-flex align-items-center">
                      <div className="employers-logo online mr-15">
                        <img alt="jobhub" src={candidate1} />
                      </div>
                      <div className="employers-name">
                        <span style={{ padding: '1px 1px 1px 150px', backgroundColor: '#f0ebebde', borderRadius: '20px' }} /><br />
                        <span style={{ padding: '1px 1px 1px 100px', backgroundColor: '#f0ebebde', borderRadius: '20px', marginTop: '10px' }} />
                      </div>
                    </div>
                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                      <div className="card-grid-2-link">
                        <a href="#"><i className="fi-rr-shield-check" /></a>
                        <a href="#"><i className="fi-rr-bookmark" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-employers hover-up wow animate__ animate__fadeIn  animated" style={{ visibility: 'visible', animationName: 'fadeIn' }}>
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 d-flex align-items-center">
                      <div className="employers-logo mr-15">
                        <img alt="jobhub" src={candidate2} />
                      </div>
                      <div className="employers-name">
                        <span style={{ padding: '1px 1px 1px 150px', backgroundColor: '#f0ebebde', borderRadius: '20px' }} /><br />
                        <span style={{ padding: '1px 1px 1px 100px', backgroundColor: '#f0ebebde', borderRadius: '20px', marginTop: '10px' }} />
                      </div>
                    </div>
                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                      <div className="card-grid-2-link">
                        <a href="#"><i className="fi-rr-shield-check" /></a>
                        <a href="#"><i className="fi-rr-bookmark" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-employers hover-up wow animate__ animate__fadeIn  animated" style={{ visibility: 'visible', animationName: 'fadeIn' }}>
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 d-flex align-items-center">
                      <div className="employers-logo online mr-15">
                        <img alt="jobhub" src={candidate3} />
                      </div>
                      <div className="employers-name">
                        <span style={{ padding: '1px 1px 1px 150px', backgroundColor: '#f0ebebde', borderRadius: '20px' }} /><br />
                        <span style={{ padding: '1px 1px 1px 100px', backgroundColor: '#f0ebebde', borderRadius: '20px', marginTop: '10px' }} />
                      </div>
                    </div>
                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                      <div className="card-grid-2-link">
                        <a href="#"><i className="fi-rr-shield-check" /></a>
                        <a href="#"><i className="fi-rr-bookmark" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-center">
          <div className="box-info-job box-info-about pl-90">
            <h5 className="heading-36 mb-30 mt-30 wow animate__ animate__fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>Invite your Network</h5>
            <div className="mt-4 mb-4">
              <div className="container">
                <div className="row mt-20 mb-20">
                  <div className="col-lg-2 test2">
                    <img src={s1} className="w-50" />
                  </div>
                  <div className="col-lg-10">
                    <span className>Earn 25% of revenue generated from each person you invite who joins CardinalTalent.ai</span>
                    <p className>Earn 25% Of Revenue</p>
                  </div>
                </div>
                <div className="row mt-20 mb-20">
                  <div className="col-lg-2 test2">
                    <img src={s2} className="w-50" />
                  </div>
                  <div className="col-lg-10">
                    <span className>Example of revenue generated</span>
                    <p className>If the person you invited purchases a $500 per month subscription for 4 months that would be $2,000 in revenue.<br></br>  So, you would earn $500 for inviting that person.</p>
                  </div>
                </div>
              </div>
              <div className style={{ textAlign: 'initial' }}>
                <form onSubmit={(e) => { handleSubmit(e) }}>
                  <input type="email" id="email" name="email" value={userEmail} required className="mt-3" style={{ border: '1px solid #2e6ef5', width: '71%', height: '50px' }} placeholder="enter an email address" 
                    onChange={event =>{
                      setUserEmail(event.target.value)
                     setValidationError({})
                     }}
                   />
                  <button type="submit" className="btn btn-outline-success mt-5" style={{ backgroundColor: '#2e6ef5', height: '50px' ,color: '#fff' }}>Send
                    Invitation</button>
                </form>
                <label className='error'>
									{validationError && validationError.email}        
							  </label>
              </div>

            </div>
            <div className="text-center">
              {/* <a href="#"><button className="btn btn-outline-success mt-5 w-25" style={{ backgroundColor: '#2e6ef5', height: '50px' }}>
                Skip</button></a> */}
              {(currentHost == 'cardinalgroups.com' || currentHost == 'www.cardinalgroups.com' ?
              <a href="/communities/new"><button className="btn btn-outline-success mt-5 w-30" style={{ backgroundColor: '#2e6ef5', height: '50px',color: '#fff' }}>
                Skip</button></a>
              :<a href="/talent_home"><button className="btn btn-outline-success mt-5 w-30" style={{ backgroundColor: '#2e6ef5', height: '50px',color: '#fff' }}>
              Skip</button></a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StepPage3
