import React, { useState, useEffect } from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'
const CheckoutForm = injectStripe(({setStripeStatus,stripe}) => {
  setStripeStatus(stripe)
  return (
    <>
      <section>
          <CardElement />
      </section>
    </>
  )
});
export default CheckoutForm
