import React, { useState, useEffect } from 'react'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ImageOne from '../../../../assets/images/recruiter_page_assets/buy.jpg'
import RightArrow from '../../../../assets/images/recruiter_page_assets/arrow-right-long.svg'
import './styles/Subscription.scss'
import {
  H1,
  P,
  Button,
  CONTAINER,
  GRID,
  COL,
  A,
} from './styles/Subscription.styled'
import TextInput from '../../common/TextInput/TextInput'
import { Formik, Form } from 'formik'
import CheckoutForm from './CheckoutForm'
import { Elements, StripeProvider } from 'react-stripe-elements'
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios'
import  '../../../../assets/v2/css/main.css'
import './styles/Subscription.scss'

const Order_v2 = (props) => {
  const { publishable_key, plans, current_user, billing_info, active_plan,states } = props
  console.log("🚀 ~ file: order.js:29 ~ OrderPage ~ plans:", plans)
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activePlanDetail, setActivePlanDetail] = useState([])
  const [stripeStatus, setStripeStatus] = useState([])
  const [stripeErrorMessage, setStripeErrorMessage] = useState('')
  const [sweetalertMessage, setSweetalertMessage] = useState('')
  const [sweetalertMessageError, setSweetalertMessageError] = useState('')
  const [isSwitchOn, setSwitchOn] = useState(false);

  const toggleSwitch = () => {
    setSwitchOn(!isSwitchOn);
  };

  const setActivePlan = (plan_id) => {
    setActivePlanDetail((plans?.filter((p) => p.id == plan_id))[0])
  }

  const calculateDiscountAmount = (amount, per) => {
    if (amount > 0) {
      return parseFloat((activePlanDetail?.amount * activePlanDetail?.discount) / 100).toFixed(2)
    } else {
      return 0
    }
  }

  const paymentSuccess = () => {
    if(current_user?.role == 'talent'){
      window.location.href = '/search_employer'
    }else{
      window.location.href = '/people_searches/new'
    }
  }

  const createSubscription = (values, stripe_payload) => {
    let URL = `/subscription/payment`
    const payload = new FormData()
    payload.append('subscription[first_name]', values.firstName)
    payload.append('subscription[last_name]', values.lastName)
    payload.append('subscription[email]', values.email)
    payload.append('subscription[phone_number]', values.phoneNumber)
    payload.append('subscription[country]', values.country)
    payload.append('subscription[state]', values.state)
    payload.append('subscription[city]', values.city)
    payload.append('subscription[zipcode]', values.zipcode)
    payload.append('subscription[user_id]', current_user.id)
    payload.append('subscription[plan_id]', activePlanDetail.id)
    payload.append('subscription[address]', values.address1)
    payload.append('subscription[address_line_1]', values?.address2 || '')
    payload.append('subscription[name_card]', values?.nameCard || '')
    payload.append('subscription[token]', (stripe_payload?.token) ? JSON.stringify(stripe_payload.token) : '')
    payload.append('subscription[billing_info_id]', billing_info?.id || '')
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (responce) => {
        if (responce.success) {
          setLoading(false)
          setSweetalertMessage('Your Subscription is Activated Now.')
        } else {
          setLoading(false)
          setSweetalertMessageError(responce.message)
        }
      },
      createErrorMessage: (e) => {
        setLoading(false)
        return e.message
      },
    })
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    if (activePlanDetail?.amount > 0) {
      stripeStatus.createToken({ name: values.nameCard }).then(stripe_payload => {
        if (stripe_payload.token) {
          createSubscription(values, stripe_payload)
        }
        if (stripe_payload.error) {
          setLoading(false)
          setStripeErrorMessage(stripe_payload.error.message)
        }
      });
    } else {
      createSubscription(values, '')
    }
  };

  const validationSchemaWithCard = Yup.object({
    firstName: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Please enter alphabets only")
      .max(15, 'Must be 15 characters or less')
      .required('First Name is Required'),
    lastName: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Please enter alphabets only")
      .max(15, 'Must be 20 characters or less')
      .required('Last Name is Required'),
    phoneNumber: Yup.string().trim()
      .matches(/^[0-9]+$/, "Please enter valid phone number")
      .max(15, 'Phone Number can have 10 to 15 digits only.')
      .min(10, "Phone Number can have 10 to 15 digits only.")
      .required('Phone Number is Required'),
    country: Yup.string().trim()
      .matches(/^[A-Z]+$/, "Please enter alphabets only")
      .max(2, 'Must be 2 characters')
      .min(2, 'Must be 2 characters')
      .required('Country Code is Required'),
    state: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Please enter alphabets only")
      .required('State Name is Required'),
    city: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Please enter alphabets only")
      .required('City Name is Required'),
    zipcode: Yup.string().trim()
      .matches(/^[0-9]+$/, "Please enter valid zip code")
      .max(6, 'Zip Code can have 5 to 6 digits only.')
      .min(5, "Zip Code can have 5 to 6 digits only.")
      .required('Zip Code is Required'),
    address1: Yup.string().trim()
      .required('Address1 is Required'),
    // address2: Yup.string().trim()
    //   .required('Address2 is Required'),
    nameCard: Yup.string().trim()
      .matches(/^[a-zA-Z ]+$/, "Please enter alphabets only")
      .required('Card Name is Required'),
    email: Yup.string().trim()
      .email('Invalid email address')
      .required('Email is Required')
  })

  const validationSchemaWithoutCard = Yup.object({
    firstName: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Please enter alphabets only")
      .max(15, 'Must be 15 characters or less')
      .required('First Name is Required'),
    lastName: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Please enter alphabets only")
      .max(15, 'Must be 20 characters or less')
      .required('Last Name is Required'),
    phoneNumber: Yup.string().trim()
      .matches(/^[0-9]+$/, "Please enter valid phone number")
      .max(15, 'Phone Number can have 10 to 15 digits only.')
      .min(10, "Phone Number can have 10 to 15 digits only.")
      .required('Phone Number is Required'),
    country: Yup.string().trim()
      .matches(/^[A-Z]+$/, "Please enter alphabets only")
      .max(2, 'Must be 2 characters')
      .min(2, 'Must be 2 characters')
      .required('Country Code is Required'),
    state: Yup.string().trim()
    .matches(/^[a-zA-Z ]+$/, "Please enter alphabets only")
    .required('State Name is Required'),
    city: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Please enter alphabets only")
      .required('City Name is Required'),
    zipcode: Yup.string().trim()
      .matches(/^[0-9]+$/, "Please enter valid zip code")
      .max(6, 'Zip Code can have 5 to 6 digits only.')
      .min(5, "Zip Code can have 5 to 6 digits only.")
      .required('Zip Code is Required'),
    email: Yup.string().trim()
      .email('Invalid email address')
      .required('Email is Required'),
    address1: Yup.string().trim()
      .required('Address1 is Required'),
    // address2: Yup.string().trim()
    //   .required('Address2 is Required'),
  })


  return (
    <>
      {step1 && (
        <>
          <section class="section-box mt-40" style={{marginBottom: '50px',minHeight: '45vh'}}>
            <div class="container">
                <div class="w-50 w-md-100 mx-auto text-center">
                    <h5 class="mb-30 wow animate__animated animate__fadeInUp">Start saving time today and choose your best plan</h5>
                </div>
                {/* <div class="text-center mt-20">
                    <span class="text-lg text-billed">Billed Monthly</span>
                    <label class="switch ml-20 mr-20">
                        <input type="checkbox" id="cb_billed_type" name="billed_type"  checked={isSwitchOn} onChange={toggleSwitch} />
                        <span class="slider round"></span>
                    </label>
                    <span class="text-lg text-billed">Billed Yearly</span>
                </div> */}
                <div class="block-pricing mt-90 mt-md-50">
                    <div class="row">
                    { ( isSwitchOn ==false && plans && plans?.length)  &&   plans?.map((plan) => {
                        return (
                              <>
                                
                                { (plan?.is_popular == true ) ?
                                <div class="col-lg-3 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                    <div class="box-pricing-item most-popular">
                                        <div class="text-end mb-10">
                                            <a href="#" class="btn btn-white-sm">Most popular</a>
                                        </div>
                                        <div class="box-info-price">
                                            <span class="text-price for-month display-month">$ {plan?.amount}</span>
                                            <span class="text-month">/month</span>
                                        </div>
                                        <div>
                                            <h4 class="mb-15">{plan?.name}</h4>
                                        </div>
                                          <div dangerouslySetInnerHTML={{ __html: plan?.description }}  style={{marginTop: '20px',marginBottom: '20px',color:'#fff'}}/>
                                        <div>
                                          { ((plan?.amount == 0 && !active_plan) || active_plan?.subscription_plan_id == plan?.id) ?
                                            <a href="#" class="btn active-btn-subscription">Active Plan</a>
                                            :
                                              <a href="#" class="btn btn-border" onClick={(e) => {
                                                    setStep2(true);
                                                    setStep1(false);
                                                    setActivePlan(plan.id);
                                                }}>Choose Plan</a>
                                          }
                                        </div>
                                    </div>
                                  </div>
                                 : 
                                 <div class="col-lg-3 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                  <div class="box-pricing-item">
                                      <div class="box-info-price">
                                          <span class="text-price for-month display-month">$ {plan?.amount}</span>
                                          <span class="text-month">/month</span>
                                      </div>
                                      <div>
                                          <h4 class="mb-15">{plan?.name}</h4>
                                      </div>
                                        <div dangerouslySetInnerHTML={{ __html: plan?.description }}  style={{marginTop: '20px',marginBottom: '20px'}}/>
                                      <div>
                                         { ((plan?.amount == 0 && !active_plan) || active_plan?.subscription_plan_id == plan?.id) ?
                                          <a href="#" class="btn active-btn-subscription">Active Plan</a>
                                          :
                                          <a href="#" class="btn btn-border" onClick={(e) => {
                                                        setStep2(true);
                                                        setStep1(false);
                                                        setActivePlan(plan.id);
                                                    }}>Choose Plan</a>
                                        }
                                      </div>
                                   </div>
                                 </div>
                                 
                                 }
                              </>
                            )
                        })
                      }
                      { isSwitchOn == true && 
                          <>  
                            <div class="col-lg-12 col-md-12 col-sm-12 wow text-center">
                                  <h5 style={{color: '#255b9eb0'}}> No Yearly Subscription Plan Available !</h5>
                            </div>
                              
                          </>
                      }
                      
                    </div>
                </div>
            </div>
        </section>
        </>
      )}

      {step2 && (
        <>
           <section class="section-box mt-40" style={{marginBottom: '50px',minHeight: '45vh'}}>
              <div class="container">
                <div class="row" style={{marginTop: '25px'}}>
                  <div class='col-md-6 col-lg-6 col-sm-12'>
                    <div className=" ">
                      <h5 className="find-tittle  find-title-v2  mb-25" style={{fontSize: '22px'}}>
                        BILLING INFORMATION
                      </h5>
                      <hr></hr>
                      <div className={'signForm mt-50'}>
                        <Formik
                          validateOnChange={true}
                          validateOnBlur={true}
                          initialValues={{
                            firstName: billing_info?.first_name || '',
                            lastName: billing_info?.last_name || '',
                            email: billing_info?.email || '',
                            phoneNumber: billing_info?.phone_number || '',
                            country: billing_info?.country || 'US',
                            state: billing_info?.state || '',
                            city: billing_info?.city || '',
                            zipcode: billing_info?.zipcode || '',
                            address1: billing_info?.address || '',
                            address2: billing_info?.address_line_1 || '',
                            nameCard: ''
                          }}
                          validationSchema={(activePlanDetail?.amount > 0) ? validationSchemaWithCard : validationSchemaWithoutCard}
                          onSubmit={(values) => handleSubmit(values)}
                        >
                          <Form>
                            <Row className='subScriptionForm'>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  className={'fullwidthInput'}
                                  label="First Name*"
                                  name="firstName"
                                  type="text"
                                  width="100%"
                                  display="block"
                                />
                              </Col>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  className={'fullwidthInput'}
                                  label="Last Name*"
                                  name="lastName"
                                  type="text"
                                  width="100%"
                                  display="block"
                                />
                              </Col>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  className={'fullwidthInput'}
                                  label="Email Address*"
                                  name="email"
                                  type="email"
                                  width="100%"
                                  display="block"
                                />
                              </Col>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  className={'fullwidthInput'}
                                  label="Phone Number*"
                                  name="phoneNumber"
                                  type="text"
                                  width="100%"
                                  display="block"
                                />
                              </Col>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  className={'fullwidthInput'}
                                  label="Address1*"
                                  name="address1"
                                  type="text"
                                  width="100%"
                                  display="block"
                                />
                              </Col>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  className={'fullwidthInput'}
                                  label="Address2"
                                  name="address2"
                                  type="text"
                                  width="100%"
                                  display="block"
                                />
                              </Col>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  className={'fullwidthInput'}
                                  label="City*"
                                  name="city"
                                  type="text"
                                  width="100%"
                                  display="block"
                                />
                              </Col>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  as="select"
                                  className={'fullwidthInput'}
                                  label="State*"
                                  name="state"
                                  type="text"
                                  width="100%"
                                  display="block"
                                >
                                <option value="">Select State</option>
                                {states.map(
                                    ({ key, value }) => {
                                        return (
                                            <option
                                                key={key}
                                                value={key}
                                            >
                                                {value}
                                            </option>
                                        )
                                    }
                                )}
                              </TextInput>
                              </Col>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  className={'fullwidthInput'}
                                  label="Zip Code*"
                                  name="zipcode"
                                  type="text"
                                  width="100%"
                                  display="block"
                                />
                              </Col>
                              <Col lg={6} md={6} sm={12} xs={12}>
                                <TextInput
                                  as="select"
                                  className={'fullwidthInput'}
                                  label="Country Code*"
                                  name="country"
                                  type="text"
                                  width="100%"
                                  display="block"
                                >
                                  <option value="US">US</option>
                                </TextInput>
                              </Col>
                              
                              {(activePlanDetail?.amount > 0) &&
                                <>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <TextInput
                                      className={'fullwidthInput'}
                                      label="Name on Card*"
                                      name="nameCard"
                                      type="text"
                                      width="100%"
                                      display="block"
                                    />
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <label>Enter Card Detail*</label>
                                    <StripeProvider apiKey={publishable_key}>
                                      <Elements>
                                        <CheckoutForm setStripeStatus={setStripeStatus} />
                                      </Elements>
                                    </StripeProvider>
                                    <small style={{ color: '#eb1c26' }}>{(stripeErrorMessage) && stripeErrorMessage}</small>
                                  </Col>
                                </>
                              }
                              <div style={{ marginTop: '10px' }}>
                                <Button
                                  className={'sign_in_button'}
                                  type="submit"
                                  style={{
                                    alignSelf: 'flex-end',
                                    float: 'left',
                                  }}
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <Spinner animation="border" variant="light" />
                                  ) : (
                                    (activePlanDetail?.amount > 0) ? 'Payment' : 'Submit'
                                  )}
                                </Button>
                                <Button disabled={loading} className='ml-2' onClick={(e) => {
                                  setStep2(false)
                                  setStep1(true)
                                }}>
                                  Cancel
                                </Button>
                              </div>
                            </Row>
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </div>
                  <div class='col-md-6 col-lg-6 col-sm-12'>
                    <div className=" mt-5">
                      <h5 className="find-tittle find-title-v2  mb-25 mt-5"  style={{fontSize: '22px'}}>
                        ORDER SUMMARY
                      </h5>
                      <hr></hr>
                      <div className="d-flex flex-wrap justify-content-between mt-50">
                        <P color="#1D2447" >{activePlanDetail?.name}</P>
                        <P color="#1D2447">${activePlanDetail?.amount}</P>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <small className='plan_description' color="#1D2447">
                          <div dangerouslySetInnerHTML={{ __html: activePlanDetail?.description }} />
                        </small>
                      </div>

                      <hr></hr>
                      <div className="d-flex flex-wrap justify-content-between">
                        <P color="#1D2447" className='justify-content-between-P'>SUBTOTAL</P>
                        <P color="#1D2447">${activePlanDetail?.amount}</P>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <P color="#1D2447" className='justify-content-between-P'>DISCOUNT ({activePlanDetail?.discount}%)</P>
                        <P color="#1D2447">${calculateDiscountAmount(activePlanDetail?.amount, activePlanDetail?.discount)}</P>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <P color="#1D2447" className='justify-content-between-P'>TAX</P>
                        <P color="#1D2447">$0.00</P>
                      </div>
                      <hr></hr>
                      <div className="d-flex flex-wrap justify-content-between">
                        <P color="#1D2447" className='justify-content-between-P'>TOTAL</P>
                        <P color="#1D2447">${activePlanDetail?.amount - calculateDiscountAmount(activePlanDetail?.amount, activePlanDetail?.discount)}</P>
                      </div>
                      <br></br>
                      <div className='teams_condition'>
                        <small color="#1D2447">SSL & SCA Ready Secure Payment.</small><br></br>
                        <small color="#1D2447">All Amounts are in US Dollars.</small><br></br> 
                        <small color="#1D2447">By Placing this order, You agree to our Terms & Conditions.</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </section>
        </>
      )}

      {sweetalertMessage != '' && (
        <SweetAlert success confirmBtnText={current_user?.role == 'talent' ? "Goto Search Decision Makers Page" : "Goto Candidate Search Page"} title="Success" onConfirm={paymentSuccess}>
          {sweetalertMessage}
        </SweetAlert>
      )}
      {sweetalertMessageError != '' && (
        <SweetAlert error title="Payment Failed" onConfirm={(e) => {
          setSweetalertMessageError('')
        }}>
          {sweetalertMessageError}
        </SweetAlert>
      )}
    </>
  )
}
export default Order_v2
