import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SearchResultsBlock_v2 from './SearchResultsBlock/SearchResultsBlock_v2'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import '../../../../assets/v2/css/main.css'
import '../../pages/Interview/styles/AiInterview.scss'
import moment from 'moment'
const RESULTS_PER_PAGE = 10
const JobSearchPageV2 = (props) => {
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    const recent_active_page = window.sessionStorage.getItem('search_active_page')
    const {currentUser} = props
    const [jobs, setJobs] = useState([])
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [activePage, setActivePage] = useState(Number(recent_active_page) || 0)
    const [totalJobsCount, setTotalJobsCount] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [errorSearchingJobs, setErrorSearchingJobs] = useState(null)
    const [personId,setPersonId]=useState((sessionStorage.getItem('guestUser'))?sessionStorage.getItem('guestUser'):'')
    const [filterStack, setFilterStack] = useState({
        skills: [],
        companyNames: [],
        locations:[],
        experienceYears:[],
        isAnd:true,
    })
    
    useEffect(() => {
      if(pageName != 'prew'){
        sessionStorage.removeItem("search_active_page");
        window.sessionStorage.setItem('search_active_page', 0)
      }
    },[activePage])
    // Upload File Code
    const fileTypes = [
        'application/msword',
        'application/pdf',
        'application/docx',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ]
    const [validationErrors, setValidationErrors] = useState({})
    const [resumeFile, setResumeFile] = useState([])
    const [generatingScoreLoading, setGeneratingScoreLoading] = useState(false)
    const [currentDateTime, setCurrentDateTime] = useState('')
    const [buttonDisabled, setButtonDisabled]= useState(false)
    
    const handleClearResumes = () =>{
        setResumeFile([])
        setValidationErrors({})
        setPersonId('')
        setButtonDisabled(false)
        sessionStorage.removeItem('guestUser')
        setCurrentDateTime(moment())
    }
    const handleOnRemoveResumes = (removeIndex) => {
        const newResumes = resumeFile.filter(
            (_, index) => removeIndex !== index
        )
        setResumeFile(newResumes)
        setValidationErrors({})
        setPersonId('')
        setButtonDisabled(false)
        sessionStorage.removeItem('guestUser')
        setCurrentDateTime(moment())
    }
    const handleResumeFiles = (files) => {  
        setResumeFile([...files])
        setValidationErrors({})
        setButtonDisabled(false)
    }
    
    const handleSubmitResume = async () => {
        setValidationErrors({})
        if (resumeFile.length <= 0){
            setValidationErrors({
            ...validationErrors,
            resume: 'Please Upload Resume files.',
            })
            return
        }
        if (!fileTypes?.includes(resumeFile[0]?.type)) {
            setValidationErrors({
                ...validationErrors,
                resume: 'Please upload pdf or docx file only.',
            })
            return
        }
        if (Object.values(validationErrors).length === 0) {
            setButtonDisabled(true)
            setGeneratingScoreLoading(true)
            const url = '/match_scores/from_resume_anon'
            const formData = new FormData()
            formData.append('resume',resumeFile[0])
            const responce = await makeRequest(url, 'post', formData, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
            }).then((res) => {
                if(res?.data?.msg){
                    setValidationErrors({
                    ...validationErrors,
                    resume: res?.data?.msg,
                    })
                }
                sessionStorage.setItem('guestUser', res?.data?.person)
                setPersonId(res?.data?.person)
                setCurrentDateTime(moment())
            }).catch((e) => {
                setValidationErrors({
                    ...validationErrors,
                    resume: 'Kindly upload a different file.',
                    })
                setGeneratingScoreLoading(false)
                setButtonDisabled(false)
            })
        }
    }    
    // Upload File Code End
    const submitJobSearch = (event) => {
        event.preventDefault()
        setActivePage(0)
        fetchJobsBySearch()
    }
    useEffect(() => {
        fetchJobsBySearch()
    }, [activePage,currentDateTime])
    const handleInputChange = (value) => {
        setInputValue(value)
    }
    const fetchJobsBySearch = async () => {
        sessionStorage.removeItem("search_active_page");
        let url = 'job_searches/search'
        if(personId > 0 && !currentUser){
            url = 'job_searches/search_match_score'
            setGeneratingScoreLoading(true)
        }else{
            setLoading(true)
        }
        const locationNameArray = filterStack.locations.map(({value})=> value)
        const companyNameArray = filterStack.companyNames.map(({value})=> value)
        const skillsArray = filterStack.skills.map(({label})=> label)
        const experienceYears = filterStack.experienceYears.map(({value})=> value)
        const formData = new FormData()
        formData.append('person_id', personId || '')
        formData.append('job_search[keyword]',inputValue)
        formData.append('job_search[experience_years]',experienceYears.toString())
        formData.append('job_search[skills]', skillsArray.toString())
        formData.append('job_search[portalcompanyname]',companyNameArray.toString())
        formData.append('job_search[locations]',locationNameArray.toString())
        formData.append('job_search[isAnd]',filterStack.isAnd)
        const CSRF_Token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')
        try {
            if(pageName == 'prew'){
                const response = await axios.post(
                    `${url}?page=${Number(activePage) + 1}`,
                    formData,
                    {
                        headers: {
                            'content-type': 'application/json',
                            'X-CSRF-Token': CSRF_Token,
                        },
                    }
                )
                window.sessionStorage.setItem('search_active_page', activePage)
                const jobsData = response.data
                const jobs = jobsData.jobs
                const totalCount = jobsData.total_count
                const totalPages = jobsData.total_pages
                setTotalJobsCount(totalCount)
                setTotalPages(totalPages)
                setJobs(jobs)
            }
            else{
                const response = await axios.post(
                    `${url}?page=${Number(activePage) + 1}`,
                    formData,
                    {
                        headers: {
                            'content-type': 'application/json',
                            'X-CSRF-Token': CSRF_Token,
                        },
                    }
                    )
            window.sessionStorage.setItem('search_active_page', activePage)
            const jobsData = response.data
            const jobs = jobsData.jobs
            const totalCount = jobsData.total_count
            const totalPages = jobsData.total_pages
            setTotalJobsCount(totalCount)
            setTotalPages(totalPages)
            setJobs(jobs)
        }
        } catch (e) {
            setErrorSearchingJobs(e.message)
        }
        setGeneratingScoreLoading(false)
        setLoading(false)
    }
    return (
        <>
           <section className="section-box">
            <div className="ai-containter">
                <div className="row flex-row-reverse">
                    <SearchResultsBlock_v2
                        totalJobsCount={totalJobsCount}
                        pageCount={totalPages}
                        activePage={activePage}
                        jobs={jobs}
                        loading={loading}
                        submitJobSearch={submitJobSearch}
                        handleInputChange={handleInputChange}
                        inputValue={inputValue}
                        setActivePage={setActivePage}
                        errorSearchingJobs={errorSearchingJobs}
                        setErrorSearchingJobs={setErrorSearchingJobs}
                        filterStack={filterStack}
                        setStackFilter={setFilterStack}
                        handleSearch={fetchJobsBySearch}
                        perPage={RESULTS_PER_PAGE}
                        validationErrors={validationErrors}
                        resumeFile={resumeFile}
                        handleOnRemoveResumes={handleOnRemoveResumes}
                        handleResumeFiles={handleResumeFiles}
                        handleSubmitResume={handleSubmitResume}
                        generatingScoreLoading={generatingScoreLoading}
                        currentUser={currentUser}
                        handleClearResumes={handleClearResumes}
                        buttonDisabled={buttonDisabled}
                    />
                </div>
            </div>
        </section>
           
        </>
    )
}
export default JobSearchPageV2