import React, { useState, useEffect } from 'react'
import Table from '../Admin/shared/Table'
import Paginator from '../../common/Paginator/Paginator'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import P from '../Admin/shared/P'
import './styles/JobLead.scss'
import Util from "../../../utils/util"
import SweetAlert from 'react-bootstrap-sweetalert'
import { CSVLink } from "react-csv"

const JobMarketerLeadList = ({job,organization,lead_file}) => {
  const [finalReview, setFinalReview] = useState([])
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [actionShow, setActionShow] = useState( (lead_file.review_status == 'pending') ? true : false)
  const [loading, setLoder] = useState(false)
  const [dataId,setDataId] = useState(0)
  const [exportDatas, setExportDatas] = useState([])
  const csvExportRef = React.useRef()

  const headers = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' }
  ];

  const fetchData = () => {
    setLoder(true)
    const url = `/job_leads/${lead_file.id}.json`
    makeRequest(url, 'get',  {params: {}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setFinalReview(
          res.data.job_leads.map((data) => ({
            id: data.id,
            candidate_name: data?.first_name + ' ' + data?.last_name,
            current_title: Util.capitalize(data?.current_position),
            current_company: data?.company_name,
            linkedIn: <a href={data?.person_linkedin_url} target="_blank"> Link </a>,
            skill: data?.person_skills,
            education: data?.education_experience,
            email: data?.email1,
            action: <a  onClick={()=> onDeleteClick(data.id)} className="trash-leads"> <i data-feather="trash-2" /> </a>
          }))
        )
        setLoder(false)
      },
    })
    
  }

  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const deleteCandidate = async (id) => {
    const URL = `/job_leads/${id}`
    await makeRequest(URL, 'delete', '', {
      createResponseMessage: () => {
        return {
          message: `Deleted successful`,
          messageType: 'success',
          loading: false,
          autoClose: true,
        }
      },
      createErrorMessage: (e) => 'Failed',
      onSuccess: () => {      
          setDataId(0)
          setShowRemoveConfirmation(false)
          fetchData()
      },      
    })      
  }
    
  const onDeleteClick = (id) =>{
    setShowRemoveConfirmation(true)
    setDataId(id)
  }

  const redirectBack = () => {
    window.location.href = '/job_process_leads'
  }

  const exportDataMethod = async (id) => {
    const url = `/job_leads/${id}.json`
    const response = await makeRequest(
        `${url}`,
        'get',
        {}
    )
    setExportDatas(response?.data?.job_leads.map((data,key)=> ({
        id: key,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email1
    })))
    csvExportRef?.current?.link.click();
 }

  return (
    <>
    <div className="container">
      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="28px" height="38px" color="#1D2447" style={{width:'940px',marginBottom:'20px'}}>
          Review Leads for {Util.capitalize(organization.name)} - {Util.capitalize(job.name)}
        </P>
        <div>
            <Button  className="ml-3" onClick={ ()=> redirectBack()}>
                Go Back
            </Button>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="21px" height="38px" color="#1D2447" style={{width:'540px',marginBottom:'20px'}}>
          Final Review
        </P>
        <div>
            <Button  className="ml-3 downloadButton" onClick={()=>exportDataMethod(lead_file.id)}>
                Download File
            </Button>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center w-100">
        <p><strong>Note: </strong> Removing lead is irreversible process.</p>
      </div>
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Candidate Name',
              field: 'candidate_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Email',
              field: 'email',
              editable: false,
              type: 'text',
            },
            {
              name: 'Current Title',
              field: 'current_title',
              editable: true,
              type: 'text',
            },
            {
              name: 'Current Company',
              field: 'current_company',
              editable: true,
              type: 'text',
            },
            {
              name: 'LinkedIn',
              field: 'linkedIn',
              editable: false,
              type: 'text',
            },
            {
              name: 'Skills',
              field: 'skill',
              editable: false,
              type: 'text',
            },
            {
              name: 'Education',
              field: 'education',
              editable: false,
              type: 'text',
            },
            {
              name: 'Action',
              field: 'action',
              editable: false,
              type: 'text',
            }
          ]}
          rowValues={finalReview.map((data) => ({
              ...data,
          }))}
          activePage="0"
          loading={loading}
        />
    </div>
    { showRemoveConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={()=>deleteCandidate(dataId)}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />
     )}
     <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={headers}
        filename={"Process_Final_Leads.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
    </>
  )
}

export default JobMarketerLeadList