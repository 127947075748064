import React, { useState } from 'react'
import Card from '../shared/Card'
import Button from '../shared/Button'
import P from '../shared/P'
import { Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component-footer'
import '../styles/DataTableCustom.scss'
import CandidateSkills from '../../../common/CandidateSkills/CandidateSkills'

const show = ({ lead_file, jobs_lead }) => {
  const [jobsLead, setJobsLead] = useState([])

  useState(()=>{
    var sr_no = 0
    setJobsLead(jobs_lead.map((lead)=>(sr_no = sr_no + 1,{
      id: sr_no,
      full_name: `${lead.first_name} ${lead.last_name}`,
      person_linkedin_url: lead.person_linkedin_url,
      company_name: lead.company_name,
      current_position: lead.current_position,
      person_skills: lead.person_skills,
      education_experience: lead.education_experience
    })))
  },[jobs_lead])

  const columns = [
    {
      name: 'No.',
      selector: row => row.id,
      maxWidth: '4%',
      minWidth: '4%'
    },
    {
      name: 'Candidate Name',
      cell: row => <a target='_blank' href={`${row.person_linkedin_url}`}>{row.full_name}</a>,
      wrap: true,
      sortable: true,
      sortField: 'first_name'
    },
    {
      name: 'Current Company',
      selector: row => row.company_name,
      wrap: true,
      sortable: true,
      sortField: 'company_name'
    },
    {
      name: 'Current Title',
      selector: row => row.current_position,
      wrap: true,
      sortable: true,
      sortField: 'current_position'
    },
    {
      name: 'Skills',
      cell: row => (row.person_skills != '') ? <CandidateSkills skills = {row.person_skills} display={5} /> : row.person_skills,
      wrap: true,
      sortable: true,
      sortField: 'recruiter'
    },
    {
      name: 'Education',
      selector: row => row.education_experience,
      wrap: true,
      sortable: true,
      sortField: 'education_experience'
    },
  ]

  const handleSort = async (column, sortDirection) => {
    if (column.sortField != undefined && sortDirection != undefined) {
      setSortField(column.sortField)
      setSortDirection(sortDirection)
    }
  }
  return (
    <Card>
      <div className="d-flex justify-content-between align-items-center w-100 mb-2">
        <P size="28px">Job Leads</P>
        <Button onClick={()=>history.back()}>Back</Button>
      </div>
      <Row className="w-100" style={{ marginBottom: '15px' }}>
        <table>
          <tr>
            <td className='pl-1'><span><b>Organization Name:</b>&nbsp; {lead_file[0].organization_name}</span></td>
            <td className='pl-2'><span><b>Job Name:</b>&nbsp; {lead_file[0].job_name}</span></td>
        </tr>
        <tr>
          <td className='pl-1'><span><b>Account Manager:</b>&nbsp; {lead_file[0].account_manager}</span></td>
          <td className='pl-2'><span><b>Campaign Manager:</b>&nbsp; {lead_file[0].campaign_manager}</span></td>
        </tr>
        </table>
      </Row>
      <DataTable
        columns={columns}
        data={jobsLead}
        highlightOnHover
      />
    </Card>
  )
}
export default show