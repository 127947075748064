import React, { useEffect, useState } from 'react'
import Card from '../Admin/shared/Card'
import P from '../Admin/shared/P'
import Button from '../Admin/shared/Button'
import { Row,Col } from 'react-bootstrap'
import styles from '../Admin/styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Table from '../Admin/shared/Table'
import { capitalize } from '../../../utils'
import linkedinIcon  from '../../../../assets/images/icon-linkedin.png'
import Modal from 'react-bootstrap/Modal'
import Util from '../../../utils/util'
import EmployerMail from '../../common/EmailClient/EmployerMail';
import axios from 'axios';

const BulkUpload = ({current_user,email_data}) => {
    console.log(email_data);
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModelEmail, setShowModalEmail] = useState(false)
  const [showUpload, setShowUpload] = useState(false)
  const [showMember, setShowMember] = useState(false)
  const [method, setMethod] = useState('post')
  const [entry, setEntry] = useState({})
  const [teams, setTeams] = useState([])
  const [validationError, setValidationError] = useState({})
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [jsonData, setJsonData] = useState(null)
  const [duplicateData, setDuplicateData] = useState(false)
  const [wrongData, setWrongData] = useState(false)
  
  const addUpload = () => {
    setValidationError('')
    setShowUpload(true)
  } 
  const headers = [
    { label: 'Job Name', key: 'jobName' },
    { label: 'Organization', key: 'organization' },
    { label: 'Recruiter Name', key: 'recruiterName' },
    { label: 'Leads', key: 'leads' },
    { label: 'Applicants', key: 'applicants' },
    { label: 'Recruiter Screened', key: 'recruiterScreened' },
    { label: 'Submitted', key: 'submitted' },
    { label: 'First Interview', key: 'firstInterview' },
    { label: 'Second Interview', key: 'secondInterview' },
    { label: 'onhold', key: 'onhold' },
    { label: 'Offers', key: 'offers' },
    { label: 'Hired', key: 'hired' },
    { label: 'Archived', key: 'archived' },
  ];

  function generateCSVContent() {
    // Generate the CSV content
    const csvContent = 'first_name,last_name,email,phone_number,location,position,company_name,linkedin_url,source,industry,company_size'
    // Create a Blob object with the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv' });
    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'sample_decision_makers.csv'; // Set the file name
    // Programmatically click the anchor element to trigger the download
    anchor.click();
  }

  const onHideUpload = () => {
    setShowUpload(false)
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setValidationError('')
  }

  const convertCSVToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const result = [];
    let a = 0;
    for (let i = 1; i < lines.length - 1; i++) {
      const obj = {};
      const currentLine = lines[i].split(",");
        if(currentLine.length != headers.length){
            a++;
        }
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }
  
      result.push(obj);
    }
    if(a > 0){
        setWrongData(true);
    }
    return result;
  };

  const handleUploade = async () => {  
    if(validateForm(selectedFile)){
        const file = selectedFile;
        const reader = new FileReader();
      
        reader.onload = (e) => {
          const csvData = e.target.result;
          const jsonData = convertCSVToJson(csvData);
          console.log(jsonData)
          getComputData(jsonData)
        };
      
        reader.readAsText(file);
        
    }
  }

  const getComputData = async (data) => {
    console.log(data)
    const url = `/bulk_upload/checked`
      await makeRequest(url, 'post',  { data
         }, {
          contentType: 'application/json',
          loadingMessage: 'Fetching...',
          createSuccessMessage: (response) => response.data.message,
          onSuccess: (res) => {
            setLoading(true)
            console.log(res.data)
            if(res.data.success == true){
                setDuplicateData(true)
                let sr_no = 0
                setTeams(
                    data.map((item) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        first_name: item.first_name ? capitalize(item?.first_name) : '',
                        last_name: item.last_name ? capitalize(item?.last_name) : '',
                        name: item.first_name + ' ' + item?.last_name,
                        email: item.email ? item?.email : '',
                        phone_number: item?.phn_number,
                        location: item.location ? capitalize(item?.location) : '',
                        position: item.position ? capitalize(item?.position) : '',
                        company_name: item.company_name ? capitalize(item?.company_name) : '',
                        linkedin_url: item?.linkedin_url ? <a target='_blank' href={item?.linkedin_url}>
                        <img style={{width:'20px', height:'20px'}}  src={linkedinIcon} /></a> : '',
                        source: item?.source,
                        industry: item?.industry,
                        company_size: item?.company_size,
                        exist: (((res.data.list).indexOf(item?.email)) == -1) ? 'false' : 'true'
                        
                        })
                    )
                )
            }else{
                let sr_no = 0
                setTeams(
                    data.map((item) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        first_name: item.first_name ? capitalize(item?.first_name) : '',
                        last_name: item.last_name ? capitalize(item?.last_name) : '',
                        name: item.first_name + ' ' + item?.last_name,
                        email: item.email ? item?.email : '',
                        phone_number: item?.phone_number,
                        location: item?.location ? capitalize(item?.location) : '',
                        position: item?.position ? capitalize(item?.position) : '',
                        company_name: item?.company_name ? capitalize(item?.company_name) : '',
                        linkedin_url: item?.linkedin_url ? <a target='_blank' href={item?.linkedin_url}>
                        <img style={{width:'20px', height:'20px'}}  src={linkedinIcon} /></a> : '',
                        source: item?.source,
                        industry: item?.industry,
                        company_size: item?.company_size,
                        exist:'false'
                        })
                    )
                )
                setDuplicateData(false)
            }
            setLoading(false)
            setShowUpload(false)
            setJsonData(data)
          },
      })
  }
 
  const validateForm = (e) => {
    let errors = {}
    let formIsValid = true
    if(!(e)){
        formIsValid = false;
        errors["file"] = "file is required."
    }
    setValidationError(errors)
    setBtnDisabled(formIsValid)
    return formIsValid;
  }

const onEmailSendClick = () => {
    setShowModalEmail(true)
}

const handleCreateServer = async () => {
    const url = '/start/campaign'
    await makeRequest(url, 'get', {}, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
        onSuccess: (res) => {
            window.location.reload();      
        },
    })
}
    

return (
    <>
   <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
        style={{ marginBottom: '42px' }}
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Bulk Email Campaign
        </P>
            <div>
              <div style={{ flexGrow: 1 }} />
                { ( (current_user.account_email_verified == true && email_data != null) && (email_data?.refresh_token != null ||  email_data.refresh_token != '' )  && ((current_user?.email_campaign_api_token != null) || (current_user?.email_campaign_server_id != null))) &&
                   <><Button onClick={generateCSVContent} className="ml-3">
                        Download CSV Template
                    </Button>
                    <Button onClick={addUpload} className="ml-3">
                        Upload CSV
                    </Button></>
                }
                { (jsonData && jsonData.length > 0 && duplicateData == false && wrongData == false)  &&
                    <Button onClick={onEmailSendClick} className="ml-3">
                        Send Email
                    </Button>
                }
                { ( (current_user.account_email_verified == true) && (email_data != null && email_data.refresh_token != null &&  email_data.refresh_token != '' )  && ((current_user?.email_campaign_api_token == null) || (current_user?.email_campaign_server_id == null))) &&
                    <Button className="ml-3" onClick={handleCreateServer} >
                            Start Email Campaign
                    </Button>
                }
            </div>
       </div>
            { (wrongData == true) && <p style={{color:'red'}}> Comma is not accepted, Please remove it and upload the file again.</p> }
            <Table
                columNames={[
                    {
                        name: 'No',
                        field: 'sr_no',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Name',
                        field: 'name',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Emails',
                        field: 'email',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Phone Number',
                        field: 'phone_number',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Location',
                        field: 'location',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Position',
                        field: 'position',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Company Name',
                        field: 'company_name',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Linkedin Url',
                        field: 'linkedin_url',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Source',
                        field: 'source',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Industry',
                        field: 'industry',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Company Size',
                        field: 'company_size',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Error',
                        field: 'exist',
                        editable: false,
                        type: 'text',
                    }
                ]}
                rowValues={teams.map((team) => ({
                    ...team,
                    exist: (team.exist == 'true') ? <div><p title='Email already send' style={{ size:'20px',color:'red'}}>Duplicate</p></div> : '-'
                }))}
                loading={loading}
            />
    
    </Card>
      <Modal
            onHide={onHideUpload}
            show={showUpload}
           
            aria-labelledby="contained-modal-title-vcenter"
            scrollable
            className="upload-modal"
            overlayClassName="modal-overlay"
        >
            <Modal.Header closeButton className="mt-2 mb-2">
                <Modal.Title id="contained-modal-title-vcenter">
                    {'Upload CSV File'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="modal-content">
                <Row className='m-2 mt-3 '>
                    <Col lg="9">
                        <input type="file" onChange={handleFileChange}  accept='.csv'/>
                        <label className='error mt-2' style={{ color:'red'}}>
                                    {validationError && validationError.file}        
                        </label>
                    </Col>
                    <Col lg="2">
                        <button className="upload-button btn btn-default" onClick={handleUploade} style={{width:'80px'}}>Upload</button>
                    </Col>
                </Row>
            </div>
            </Modal.Body>
        </Modal>
        

                <Modal
                  style={{padding:'20px'}}
                  onHide={() => setShowModalEmail(false)}
                  // className="free-candidate"
                  show={showModelEmail}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  scrollable
                  closeButton
                    
                >
                    <Modal.Header
                    onHide={() => setShowModal(false)}
                    closeButton
                    >
                        <Modal.Title>{'Send mail'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <EmployerMail
                          emailClientId={'emailclientfor_' + 'scheduler'}
                          userId={current_user?.id}
                          isEmailConfigured={true}
                          userEmail={current_user?.email || ''}
                          showByDefault={true}
                          mailSentCallback={() => {}}
                          sendList={[]}
                          embedded={true}
                          setShowModal={setShowModal}
                          candidateCount={25}
                          jsonData={jsonData}
                      />
                    </Modal.Body>
                </Modal>
    </>
  )
}

export default BulkUpload