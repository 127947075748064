import React, { useState, useEffect } from 'react'
import actionCable from 'actioncable'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import axios from 'axios'
import JobResultsTableV2 from '../JobResultsTable/JobResultsTableV2'

const UploadResumeSectionV2 = (props) => {
    const [uploadedResume, setUploadedResume] = useState(props.uploadedResume)
    const [resumeServiceUrl, setResumeServiceUrl] = useState(props.resumeServiceUrl)
    const [lastuploadedResume, setLastUploadedResume] = useState('')
    const [resumeUploadLoading, setResumeUploadLoading] = useState(false)
    const [alertUploadedResume, setAlertUploadedResume] = useState(null)
    const [errorUploadedResume, setErrorUploadedResume] = useState(null)
    const [matchesGenerated, setMatchesGenerated] = useState(false)
    const [showUploadResumeModal, setShowUploadResumeModal] = useState(false)

    const {
        webSocketsUrl,
        render,
        currentUser,
        hasResume,
        resume,
        jobs,
        setJobs,
        totalJobsCount,
        setTotalJobsCount,
        jobFilterText,
        setJobFilterText,
        activePage,
        setActivePage,
        errorFetchingJob,
        setErrorFetchingJob,
        loading,
        setLoading,
        pageCount,
        setPageCount,
        matchScoreStatus
    } = props

    useEffect(() => {
        if (!matchScoreStatus) {
            regenerateScore()
        }
    }, [matchScoreStatus])

    const regenerateScore = () => {
        if (resumeServiceUrl) {
            const formData = new FormData()
            formData.append('resume_service_url', resumeServiceUrl)
            sendResume(formData)
            setErrorUploadedResume(null)
        } else {
            setErrorUploadedResume('Upload your resume first!')
        }
    }

    const handleFiles = (files) => {
        if (files) {
            const formData = new FormData()
            if (currentUser) {
                formData.append('resume', files[0])
            } else {
                formData.append('file', files[0])
            }
            // formData.append('description', 'DA')
            setUploadedResume(files[0])
            sendResume(formData)
            setErrorUploadedResume(null)
        } else {
            setErrorUploadedResume('Upload your resume first!')
        }
    }

    const sendResume = (resume) => {
        const url = currentUser
            ? '/match_scores/from_resume'
            : '/match_scores/from_resume_anon'
        setResumeUploadLoading(true)
        var CSRF_Token = document.querySelector('meta[name="csrf-token"]')
            .content
        const headers = {
            'Content-Type': 'Application/json',
            'X-CSRF-Token': CSRF_Token,
        }
        axios
            .post(url, resume, { headers })
            .then((response) => {
                if (response.data.success) {
                    setMatchesGenerated(true)
                    if (response.data == '') {
                        setAlertUploadedResume(
                            'There are currently no close matches for your resume. Check back with us before long!'
                        )
                    } else {
                        setLastUploadedResume(resume)
                    }
                } else {
                    setErrorUploadedResume(response.data.msg)
                    setMatchesGenerated(false)
                    // setUploadedResume(null)
                }
                setResumeUploadLoading(false)
                setShowUploadResumeModal(false)
            })
            .catch((error) => {
                setResumeUploadLoading(false)
                setErrorUploadedResume(error.message)
                // setUploadedResume(null)
                setShowUploadResumeModal(false)
            })
    }

    return (
        <>
            <section className="section-box mt-40">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12 float-right">
                            <div className="content-page">
                                <div className="job-list-list mb-15 mt-15">
                                    <div className="list-recent-jobs">
                                        <div className="card-job hover-up wow animate__animated animate__fadeIn plan text-center">
                                            <div className="card-job-top">
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="card-job-top--info" style={{ paddingLeft: '30px' }}>
                                                            <div className="card-job-heading-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <h6 className="card-job-top--info-heading"><a href="#">Choose a plan to have CardinalTalent start auto applying to matching jobs below.</a></h6>
                                                            </div>
                                                            <p>Select a plan to have CardinalTalent start auto applying to matching jobs below.</p>
                                                        </div>
                                                    </div>
                                                    <div className="block-signin">
                                                        <a href="/subscription/order" className="btn btn-default btn-shadow mt-15" style={{ color: 'white', fontSize: '14px' }}>Choose a plan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-box">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12 float-right" style={{paddingTop: '0px'}}>
                            <div className="content-page">
                                <Row className="mt-4">
                                    <Col>
                                        {/* {alertApplyForAllMatchingJob && (
                                            <Alert
                                                variant="success"
                                                onClose={() => setAlertApplyForAllMatchingJob(null)}
                                                dismissible
                                            >
                                                {alertApplyForAllMatchingJob}
                                            </Alert>
                                        )}
                                        {errorApplyForAllMatchingJob && (
                                            <Alert
                                                variant="danger"
                                                onClose={() => setErrorApplyForAllMatchingJob(null)}
                                                dismissible
                                            >
                                                {errorApplyForAllMatchingJob}
                                            </Alert>
                                        )} */}
                                        {render({
                                            showUploadResumeModal,
                                            uploadedResume,
                                            setUploadedResume,
                                            setShowUploadResumeModal,
                                            // applyToAllMatches,
                                            handleFiles,
                                            matchesGenerated,
                                            totalJobsCount,
                                        })}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <JobResultsTableV2
                jobs={jobs}
                setJobs={setJobs}
                totalJobsCount={totalJobsCount}
                setTotalJobsCount={setTotalJobsCount}
                matchesGenerated={matchesGenerated}
                setMatchesGenerated={setMatchesGenerated}
                resumeUploadLoading={resumeUploadLoading}
                alertUploadedResume={alertUploadedResume}
                setAlertUploadedResume={setAlertUploadedResume}
                errorUploadedResume={errorUploadedResume}
                setErrorUploadedResume={setErrorUploadedResume}
                jobFilterText={jobFilterText}
                setJobFilterText={setJobFilterText}
                activePage={activePage}
                setActivePage={setActivePage}
                errorFetchingJob={errorFetchingJob}
                setErrorFetchingJob={setErrorFetchingJob}
                loading={loading}
                setLoading={setLoading}
                pageCount={pageCount}
                setPageCount={setPageCount}
                lastuploadedResume={lastuploadedResume}
                uploadedResume={uploadedResume}
                regenerateScore={regenerateScore}
                setResumeUploadLoading={setResumeUploadLoading}
            />
            <section className="section-box mt-40">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12 float-right">
                            <div className="content-page">
                                <div className="job-list-list mb-15 mt-15">
                                    <div className="list-recent-jobs">
                                        <div className="card-job hover-up wow animate__animated animate__fadeIn plan text-center">
                                            <div className="card-job-top">
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="card-job-top--info" style={{ paddingLeft: '30px' }}>
                                                            <div className="card-job-heading-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <h6 className="card-job-top--info-heading"><a href="#">Choose a plan to have CardinalTalent start auto applying to matching jobs below.</a></h6>
                                                            </div>
                                                            <p>Select a plan to have CardinalTalent start auto applying to matching jobs below.</p>
                                                        </div>
                                                    </div>
                                                    <div className="block-signin">
                                                        <a href="/subscription/order" className="btn btn-default btn-shadow mt-15" style={{ color: 'white', fontSize: '14px' }}>Choose a plan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UploadResumeSectionV2
