import React, { useRef, useState, useContext, useEffect } from 'react'
import FileUploader from './FileUploader'
import AttachmentIcon from '../../../../assets/images/icons/attachment-icon.svg'
import InsertVarIcon from '../../../../assets/images/icons/insert-var-icon.svg'
import AddLinkIcon from '../../../../assets/images/icons/add-link-icon.svg'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'
import Toast from 'react-bootstrap/Toast'
import './styles/EmailClient.scss'
import CreatePasswordPopup from '../CreatePasswordPopup/CreatePasswordPopup'
import isEmpty from 'lodash.isempty'
import { StoreDispatchContext } from '../../../stores/JDPStore'
import Dropdown from 'react-bootstrap/Dropdown'
import EmailContent from './EmailContent'
import AddLinkModal from './AddLinkModal'
import Delta from 'quill-delta'
import ReactQuill, { Quill } from 'react-quill'
import TextareaAutosize from 'react-autosize-textarea'
import SweetAlert from 'react-bootstrap-sweetalert';
import CloseIcon from '../../../../assets/images/cancel.svg'
import Util from '../../../utils/util'
import 'react-quill/dist/quill.snow.css';
import { makeRequest } from '../RequestAssist/RequestAssist'
// Tell Quill to wrap sections with <div> instead of <p>
// (otherwise you get double lines due to: <p><br></p>)
const Block = Quill.import('blots/block')
Block.tagName = 'DIV'
Quill.register(Block, true)

const EmployerMail = (props) => {
    const {
        emailClientId,
        userId,
        sendList,
        jobId,
        userEmail,
        isEmailConfigured,
        showByDefault = false,
        mailSentCallback,
        embedded = false,
        candidateCount,
        title = 'New email',
        successFormSubmitting,
        setShowModal,
        multiIntrestedCandidates,
        seeEmail,
        emailData,
        jsonData
    } = props
    console.log(jsonData)
    const initialFormState = {
        fromInput: userEmail,
        toInput: '',
        subjectInput: '',
        contentTextArea: '',
    }
    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const [passwordSubmitted, setPasswordSubmitted] = useState(false)
    const [show, setShow] = useState(embedded)
    const [sendingMails, setSendingMails] = useState(false)
    const [uploadedFile, setUploadedFile] = useState(null)
    const [formState, setFormState] = useState(initialFormState)
    const [externalSendEmailFunc, setExternalSendEmailFunc] = useState(null)
    const [filteredRecipients, setFilteredRecipients] = useState([])
    const [recipientDisplayString, setRecipientDisplayString] = useState('')
    const[emailAlert, setEmailAlert] = useState(false);
    const [requestAssistProps, setRequestAssistProps] = useState({})

    // success/error state
    const [formErrorSubmitting, setFormErrorSubmitting] = useState('')

    // used for inserting variables into subject/content inputs
    const [
        lastUsedVariableInputField,
        setLastUsedVariableInputField,
    ] = useState('contentTextArea')
    const emailContentRef = useRef(null)
    const subjectRef = useRef(null)

    // names of 'variables' that can be inserted into document by user
    const documentVariables = ['first_name', 'last_name']
    const fieldNameToRef = {
        contentTextArea: emailContentRef,
        subjectInput: subjectRef,
    }
    const handleCloseModal = () => {
        setShowSentEmailModal(false)
        setShow(false)
    } 
        
    const handleShowModal = () => {
        if(isEmailConfigured){
            setShow(true)
            setEmailAlert(false)
        }
        else{
            setShow(false)
            setEmailAlert(true)
        }
    }

    // feature flags
    const enableAddLinkButton = false
    const [showAddLinkModal, setShowAddLinkModal] = useState(false)
    const [employerEmailData, setEmployerEmailData] = useState()
    const [selectedText, setSelectedText] = useState({
        selection: {},
        text: '',
    })
   
    const fetchData = async() => {
        const url = `/employer_email_data`
        await makeRequest(url, 'get',  {
             params: {
                email: emailData?.email,
            }}, {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setEmployerEmailData(
                  res?.data?.employer_email
              )
            },
        })
      }

      useEffect(() =>{
        fetchData()
      },[])
    const handleSendButton = async (event) => {
        event.preventDefault()
        const candidates = sendList
        const candidateIds = candidates.map((candidate) => candidate.id)
        const errors = []
        if (isEmpty(candidateIds) && isEmpty(jsonData))
            errors.push("You haven't selected any candidates to mail.")
        if (isEmpty(formState.subjectInput))
            errors.push('You must enter a subject before sending')
        if (isEmpty(formState.contentTextArea))
            errors.push('You must enter a message to send')
        if (!isEmailConfigured)
            errors.push('Your email must be verified from the account settings before it can be sent.')
        if (!isEmpty(errors)) {
            setFormErrorSubmitting(errors[0])
            return
        }

        if (!isEmailConfigured && !passwordSubmitted) {
            setExternalSendEmailFunc(() => (appPassword) =>
                sendEmail(candidateIds, appPassword)
            )
            setShowPasswordModal(true)
        } else {
            sendEmail(candidateIds)
        }
    }

    const handleInsertVariable = (variable) => {
        const fieldName = lastUsedVariableInputField
        const element = fieldNameToRef[fieldName].current
        if (fieldName === 'contentTextArea') {
            const newValue = insertText(variable)
            setFormState({
                ...formState,
                [fieldName]: newValue,
            })
        } else {
            const cursorPos = element.selectionStart
            const oldText = formState[fieldName]
            setFormState({
                ...formState,
                [fieldName]:
                    oldText.slice(0, cursorPos) +
                    `{{${variable}}}` +
                    oldText.slice(cursorPos),
            })
        }
    }

    const insertText = (variable) => {
        var range = emailContentRef.current.getSelection()
        let position = range ? range.index : 0
        emailContentRef.current.insertText(
            position,
            variable ? `{{${variable}}}` : ''
        )
        return emailContentRef.current.selection.root.innerHTML
    }

    const inputChangeHandler = (event, inputType) => {
        event.preventDefault()
        const newValue = event.target.value

        setFormState({
            ...formState,
            [inputType]: newValue,
        })
    }

    const sendEmail = async (candidateIds, appPassword, refreshToken, accessToken) => {
        setShowPasswordModal(false)
        const campaign = {
            source_address: formState.fromInput,
            subject: formState.subjectInput,
            content: formState.contentTextArea,
            user_id: userId,
        }
        const URL = '/employer_email'
        const payload = new FormData()
        const emailArray = sendList.map((person) => {
            return person.email;
          });
        const employer_id = sendList.map((item) => item?.id)  
        payload.append('employer_email[email]',emailArray)
        payload.append('employer_email[subject]',formState.subjectInput)
        payload.append('employer_email[body]',formState.contentTextArea)
        payload.append('employer_email[user_id]',userId)
        payload.append('employer_email[employer_id]',employer_id)
        payload.append('employer_email[status]','pending')
        payload.append('employer_email[data]', JSON.stringify(jsonData))
        makeRequest(URL, 'post',payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (res) => {
                console.log(res)
                setSendingMails(true)
                setShowModal(false)  
                window.location.reload() 
                setTimeout(() => {
                }, 2000);
                return {
                    message: res?.message,
                    messageType: res?.messageType
                }
            }
        })
    }

    // Builds and updates the recipientDisplayString, used to show
    // names of recipients in the "To" field
    useEffect(() => {
        const trimCount = sendList.count < 25 ? sendList.length : 25
        const candidates = sendList.slice(0, trimCount)
        let nameList = candidates.reduce(
            (str, candidate, i) =>
            str + (i > 0 ? ',' : '') + candidate.first_name + ' ' + candidate.last_name,
            ''
        )
        if (candidates.length == 1) {
            nameList = `${candidates[0].first_name} ${candidates[0].last_name}`
        }
        setRecipientDisplayString(isEmpty(candidates) ? '' : nameList)
    }, [sendList])

    useEffect(() => {
        if (selectedText.text !== '') {
            setShowAddLinkModal(true)
        }
    }, [selectedText])

    const remCandidate = sendList?.length < 25 ? ' ' :  ( sendList?.length - candidateCount?.length +' '+  "More")
    return (
        <>
            {show ? (
                <div
                    className={`email-client ${
                        embedded ? 'embedded-display' : ''
                    }`}
                >
                    <div className="background-wrap">
                       {!seeEmail && <div className="modal-header">
                            <h3 className="modal-title">{title}</h3>
                            {!embedded && (
                                <button
                                    aria-label="close-modal"
                                    onClick={handleCloseModal}
                                    className="close-modal-btn"
                                >
                                    <span />
                                </button>
                            )}
                        </div> }
                        <div className="modal-body">
                            <div className="body-first-row">
                               {!seeEmail && <div className="input-block">
                                    <span className="input-title">From</span>
                                    <input
                                        className="regular-input"
                                        disabled
                                        value={formState.fromInput}
                                        onChange={(event) =>
                                            inputChangeHandler(
                                                event,
                                                'fromInput'
                                            )
                                        }
                                        >
                                    </input>
                                           
                                </div> }
                               {!seeEmail && <div className="input-block">
                                    <span className="input-title">To</span>
                                    
                                    <TextareaAutosize
                                        className="regular-input"
                                        placeholder={
                                            isEmpty(recipientDisplayString)
                                                ? ((!isEmpty(jsonData)) ? 'All' :'<no candidates selected>')
                                                : recipientDisplayString
                                        }
                                        disabled={true}
                                        readOnly
                                    />
                                </div> }
                                <div className="input-block">
                                    <span className="input-title">Subject</span>
                                    {seeEmail ? 
                                        <input
                                        type="text"
                                        ref={subjectRef}
                                        disabled={seeEmail}
                                        value={employerEmailData?.subject}
                                        className="regular-input"
                                        onSelect={() =>
                                            setLastUsedVariableInputField(
                                                'subjectInput'
                                            )
                                        }
                                        onChange={(event) =>
                                            inputChangeHandler(
                                                event,
                                                'subjectInput'
                                            )
                                        }
                                    />
                                    :
                                    <input
                                        type="text"
                                        value={formState.subjectInput}
                                        ref={subjectRef}
                                        className="regular-input"
                                        onSelect={() =>
                                            setLastUsedVariableInputField(
                                                'subjectInput'
                                            )
                                        }
                                        onChange={(event) =>
                                            inputChangeHandler(
                                                event,
                                                'subjectInput'
                                            )
                                        }
                                    />
                                    }
                                </div>
                            </div>
                            <div className="body-second-row">
                                {enableAddLinkButton && (
                                    <button
                                        className="add-link sub-button"
                                        onClick={() => {
                                            var selection = emailContentRef.current.getSelection()
                                            if (
                                                selection == null ||
                                                selection == undefined ||
                                                (selection &&
                                                    selection.length == 0)
                                            ) {
                                                return
                                            }
                                            setShowAddLinkModal(true)
                                            var selectedContent = emailContentRef.current.getContents(
                                                selection.index,
                                                selection.length
                                            )
                                            setSelectedText({
                                                selection,
                                                text:
                                                    selectedContent.ops[0]
                                                        .insert,
                                            })
                                        }}
                                    >
                                        <img
                                            src={AddLinkIcon}
                                            className="sub-button__img"
                                        />
                                        <span>Add Link</span>
                                    </button>
                                )}
                            </div>
                            <div className="body-third-row">
                                {seeEmail ?
                                <div>
                                {!employerEmailData?.body ? <>loading</> :  
                                <ReactQuill
                                    readOnly={seeEmail}
                                    defaultValue={employerEmailData?.body}
                                    />}
                                  
                                </div>
                                :
                                 <EmailContent
                                    onSelect={() => {
                                        setLastUsedVariableInputField(
                                            'contentTextArea'
                                        )
                                    }}
                                    getQuillRef={(quill) => {
                                        emailContentRef.current = quill
                                    }}
                                    value={formState.contentTextArea}
                                    inputChangeHandler={inputChangeHandler}
                                />
                            }
                            </div>
                            <div className="body-fourth-row">
                                {sendingMails && !formErrorSubmitting && (
                                    <span style={{ color: 'black' }}>
                                        Sending emails...
                                    </span>
                                )}
                                <span title='Variable'>{"{SUBSCRIBER_NAME}"}</span>
                                <Toast
                                    onClose={() => setFilteredRecipients([])}
                                    show={!isEmpty(filteredRecipients)}
                                >
                                    <Toast.Header>
                                        <strong className="mr-auto">
                                            Note
                                        </strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        We did not send to the following (
                                        {filteredRecipients.length}) recipients
                                        since they were recently contacted by
                                        your organization:
                                        <textarea
                                            readOnly
                                            style={{
                                                width: '100%',
                                                height: '5rem',
                                                marginTop: '1rem',
                                                color: '#888',
                                            }}
                                            value={filteredRecipients.reduce(
                                                (str, recipient) =>
                                                    str + recipient + '\n',
                                                ''
                                            )}
                                        ></textarea>
                                    </Toast.Body>
                                </Toast>
                                {successFormSubmitting && (
                                    <Alert
                                        style={{ flexGrow: '1' }}
                                        variant="success"
                                        dismissible
                                    >
                                        {successFormSubmitting}
                                    </Alert>
                                )}
                                {formErrorSubmitting && (
                                    <Alert
                                        style={{ flexGrow: '1' }}
                                        variant="danger"
                                        onClose={() =>
                                            setFormErrorSubmitting(null)
                                        }
                                        dismissible
                                    >
                                        {formErrorSubmitting}
                                    </Alert>
                                )}
                                {uploadedFile && (
                                    <span className="attached-info">
                                        <img
                                            alt="attached-icon"
                                            src={AttachmentIcon}
                                            className="attached-icon"
                                        />
                                        File 1 Attached
                                        <button
                                            onClick={() =>
                                                setUploadedFile(null)
                                            }
                                            className="delete-attached-btn"
                                        >
                                            &#10005;
                                        </button>
                                    </span>
                                )}
                                    {!seeEmail &&
                                    <button
                                        onClick={(event) =>
                                            handleSendButton(event)
                                        }
                                        className="submit-modal-btn"
                                    >
                                        <span>Send</span>
                                    </button>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                {(emailAlert) && 
                <SweetAlert
                    confirmBtnText="Account Setting"
                    cancelBtnText="No"  
                    style={{ backgroundColor:'#F8D7DA', color: 'red', border: "#F5C6CB", paddingTop:7, paddingRight: 36 }}
                    customButtons={
                        <div className='w-100 d-flex justify-content-end'>
                            <button className="alertFont" onClick={() => window.open('/account/email_verification', "_self")}>Go to Account Settings</button>
                        </div>
                      }
                >
                <div>
                    <img className='alert-close-button' src={CloseIcon} onClick={()=> setEmailAlert(false)} />
                    <h5 className='alert-heading'>
                        Your email must be verified from the account settings before it can be sent.
                    </h5>                  
                </div>
              </SweetAlert>
            }
                <button
                    onClick={handleShowModal}
                    className="toggle-email-client-btn"
                >
                    <span>New Email</span>
                </button>
                </>
            )}
            <CreatePasswordPopup
                show={showPasswordModal}
                sendEmail={externalSendEmailFunc}
                closeHandler={() => setShowPasswordModal(false)}
            />
            <AddLinkModal
                initialText={selectedText.text}
                showModal={showAddLinkModal}
                closeModal={() => setShowAddLinkModal(false)}
                getText={(text, link) => {
                    emailContentRef.current.updateContents(
                        new Delta()
                            .retain(selectedText.selection.index)
                            .delete(selectedText.selection.length)
                            .insert(text, {
                                link,
                            })
                    )
                }}
            />
        </>
    )
}

export default EmployerMail
