import React, { useState, useEffect } from 'react'
import Card from '../Admin/shared/Card'
import Table from '../Admin/shared/Table'
import Paginator from '../../common/Paginator/Paginator'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import P from '../Admin/shared/P'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import styles from '../Admin/styles/JobsStatusPage.module.scss'
import { Row, Col } from 'react-bootstrap'
import './styles/JobLead.scss'
import Util from "../../../utils/util"
import moment from 'moment'
import Select from 'react-select'
import { CSVLink } from "react-csv"
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'

const JobProcessLead = (props) => {
  const [pendingReview, setPendingReview] = useState([])
  const [alreadyReview,setAlreadyReview] = useState([])
  const [exportDatas, setExportDatas] = useState([])
  const csvExportRef = React.useRef()
  const queryParams = new URLSearchParams(window.location.search)
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  const [activePage, setActivePage] = useState(0)
  const [reviewedActivePage, setReviewedActivePage] = useState(0)
  const [reviewedPageCount, setReviewedPageCount] = useState(0)
  const [reviewedTotalDatas,setReviewedTotalDatas] = useState(0)
  const [reviewedStartRecord, setReviewedStartRecord] = useState(0)
  const [reviewedCurrentCounts,setReviewedCurrentCounts] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [totalDatas,setTotalDatas] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [loading, setLoder] = useState(false)
  const [loadingReviewd, setLoderReviewd] = useState(false)
  const [filename, setFilename] = useState('')
  const [organizations,setOrganizations] = useState([{value: 'all', label: 'All'},...props?.organizations])
  const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('month').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
  const [selectedOrganization, setSelectedOrganization]  = useState('all')
  const handleDate = (date) => {
    setDateRange(date)
  }

  const headers = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' }
  ];

  const fetchData = () => {
    setLoder(true)
    setLoderReviewd(true)
    const url = `/job_process_leads.json`
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    makeRequest(url, 'get',  {params: {page: (activePage+1),organization: selectedOrganization,start_date: start_date,end_date:end_date ,reviewdPage : (reviewedActivePage + 1)}}, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setPendingReview(
          res.data.pending.map((data) => ({
            id: data.id,
            organization_id: data?.organization?.id,
            organization_name: Util.capitalize(data?.organization?.name),
            job_name: <a href={'/job_process_leads/' + data.id}>{Util.capitalize(data?.job?.name)}</a>,
            lead_file:  <a onClick={()=>{
              setFilename(`${data?.organization?.name.replaceAll(' ','_')}_${data?.job?.name.replaceAll(' ','_')}.csv`)
              exportDataMethod(data.id)}
            } style={{cursor:"pointer",color:"#667eff"}}>Download</a>,
            upload_date: data.created_at ? moment(data.created_at).format('MMM DD, YYYY') : '',
            decision: Util.capitalize( data?.review_status),
            decision_date:data.review_at ? moment(data.review_at).format('MMM DD, YYYY') : '',
            decision_note:data?.review_note,
            outreach_status:<Button className='approveButton mr-2' onClick={ () => addData(data.id)} >Sent</Button>
          }))
        )
        
        setPageCount(res.data.total_pages)
        setTotalDatas(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setPerPage(res.data.per_page)
        setAlreadyReview(
            res.data.reviewed.map((data) => ({
              id: data.id,
              organization_id: data?.organization?.id,
              organization_name: Util.capitalize(data?.organization?.name),
              job_name: <a href={'/job_process_leads/' + data.id}>{Util.capitalize(data?.job?.name)}</a>,
              lead_file:  <a onClick={()=>{
                setFilename(`${data?.organization?.name.replaceAll(' ','_')}_${data?.job?.name.replaceAll(' ','_')}.csv`)
                exportDataMethod(data.id)
              }} style={{cursor:"pointer",color:"#667eff"}}>Download</a>,
              upload_date: data.created_at ? moment(data.created_at).format('MMM DD, YYYY') : '',
              decision: Util.capitalize( data?.review_status),
              decision_date:data.review_at ? moment(data.review_at).format('MMM DD, YYYY') : '',
              decision_note:data?.review_note,
              marketing_contact: (data?.organization?.marketer_id) ?  (props.marketers[data.organization.marketer_id]) ? props.marketers[data.organization.marketer_id][0].first_name + ' ' + props.marketers[data.organization.marketer_id][0].last_name : '-' : '-',
              outreach_status:'Sent'
            }))
        )
        setLoderReviewd(false)
        setLoder(false)
        setReviewedPageCount(res.data.review_total_pages)
        setReviewedTotalDatas(res.data.review_total_count)
        setReviewedCurrentCounts(res.data.review_current_counts)
        setReviewedStartRecord(reviewedActivePage * res.data.per_page)
      },
    })
  }
    useEffect(() => {
      fetchData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [selectedOrganization,dateRange,activePage,reviewedActivePage])

    const addData = async (id) => {
      const payload = new FormData()
      payload.append('job_leads_params[id]',id)
      const URL = `/job_process_leads/${id}`
      await makeRequest(URL, 'post',payload, {
        createResponseMessage: () => {
          return {
            message: `Sent successful`,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        },
        createErrorMessage: (e) => 'Failed',
        onSuccess: () => { 
            fetchData()
        },      
      })   
    }

    const exportDataMethod = async (id) => {
      const url = `/job_leads/${id}.json`
      const response = await makeRequest(
          `${url}`,'get',{}
      )
      setExportDatas(response?.data?.job_leads.map((data,key)=> ({
          id: key,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email1
      })))
      csvExportRef?.current?.link.click();
   }

   const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
   }

  return (
    <>
    <div className="container">
      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px',marginBottom:'20px'}}>
          Process Leads
        </P>
      </div>
      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="21px" height="38px" color="#1D2447" style={{width:'540px',marginBottom:'20px'}}>
          Pending to Process
        </P>
        <div style={{flexGrow:1}}/>
      </div>
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
        <Col xs={12} sm={12} lg={8} className="" style={{float:'left'}}>
          {totalDatas > 0  &&
            <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts}  of  {totalDatas} entries
            </DisplayPagination>
          }
        </Col>
        <Col xs={12} sm={12} lg={2} className='d-flex justify-content-end p-0'>
                    <div style={{display: 'flex'}}>
                        <div>
                            <label>
                                Select Organization
                            </label>
                            <Select
                                options={organizations}
                                onChange={(event)=>setSelectedOrganization(event.value)}
                                name="organization"
                                className={styles.colourStyles}
                                defaultValue={{value: 'all', label: 'All'}}
                            />
                        </div>
                    </div>
                </Col>
        <Col xs={12} sm={12} lg={2} className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Select Date Range
              </label>
              <DateRangePicker
                  handleOnSubmit={handleDate}
                  defaultDate={dateRange}
              />
            </div>
          </div>
        </Col>
      </Row> 
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Organization',
              field: 'organization_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Job',
              field: 'job_name',
              editable: true,
              type: 'text',
            },
            {
              name: 'Lead File',
              field: 'lead_file',
              editable: true,
              type: 'text',
            },
            {
              name: 'Uploaded Date',
              field: 'upload_date',
              editable: true,
              type: 'text',
            },
            {
              name: 'Decision',
              field: 'decision',
              editable: false,
              type: 'text',
              nowrap:''
            },
            {
              name: 'Decision Date',
              field: 'decision_date',
              editable: false,
              type: 'text',
            },
            {
              name: 'Decision Notes',
              field: 'decision_note',
              editable: false,
              type: 'text',
            },
            {
              name: 'Outreach Status',
              field: 'outreach_status',
              editable: false,
              type: 'text',
            }
          ]}
          rowValues={pendingReview.map((data) => ({
              ...data,
          }))}
          activePage={activePage}
          loading={loadingReviewd}
          perPage={perPage}
      />
      {pageCount > 1 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
        >
          <Paginator
              activePage={activePage}
              setActivePage={setActivePage}
              pageCount={pageCount}
              pageWindowSize={5}
              showGoToField={false}
          />
        </div>
      )}

      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="21px" height="38px" color="#1D2447" style={{width:'540px',marginBottom:'20px'}}>
          Already Processed
        </P>
        <div style={{flexGrow:1}}/>
      </div>
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
        <Col xs={12} sm={12} lg={2} className="" style={{float:'left'}}>
          {totalDatas > 0  &&
            <DisplayPagination>Displaying {(reviewedStartRecord == 0)?1:reviewedStartRecord} - {reviewedCurrentCounts}  of  {reviewedTotalDatas} entries
            </DisplayPagination>
          }
        </Col>
        <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'right'}}>

        </Col>
      </Row> 
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Organization',
              field: 'organization_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Job',
              field: 'job_name',
              editable: true,
              type: 'text',
            },
            {
              name: 'Lead File',
              field: 'lead_file',
              editable: true,
              type: 'text',
            },
            {
              name: 'Uploaded Date',
              field: 'upload_date',
              editable: true,
              type: 'text',
            },
            {
              name: 'Decision',
              field: 'decision',
              editable: false,
              type: 'text',
              nowrap:''
            },
            {
              name: 'Decision Date',
              field: 'decision_date',
              editable: false,
              type: 'text',
            },
            {
              name: 'Decision Notes',
              field: 'decision_note',
              editable: false,
              type: 'text',
            },
            {
              name: 'Marketing Contact',
              field: 'marketing_contact',
              editable: false,
              type: 'text',
            },
            {
              name: 'Outreach Status',
              field: 'outreach_status',
              editable: false,
              type: 'text',
            }
          ]}
          rowValues={alreadyReview.map((data) => ({
              ...data,
          }))}
          activePage={reviewedActivePage}
          loading={loading}
          perPage={perPage}
        />
        {reviewedPageCount > 1 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
        >
          <Paginator
              activePage={reviewedActivePage}
              setActivePage={setReviewedActivePage}
              pageCount={reviewedPageCount}
              pageWindowSize={5}
              showGoToField={false}
          />
        </div>
      )}
    </div>
    <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={headers}
        filename={filename}
        ref={csvExportRef}
      >
      </CSVLink>
    </>
  )
}

export default JobProcessLead