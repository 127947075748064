import React, { useState, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
// import styles from './styles/Navbar.module.scss'
import LogoWithText from '../../../../assets/images/logos/navbar-logo.svg'
import  '../../../../assets/v2/css/main.css'
import  avatarImg from '../../../../assets/v2/imgs/avatar/ava_1.png'
import LogoGroups from '../../../../assets/images/logos/cardinalgroups.png'
import Util from '../../../utils/util'

const NavbarTalent = ({
    userId,
    isTalent,
    isEmployer,
    avatar_url,
    organization,
    currentUser,
    memberOrganization,
    base_url,
    host_url,
    is_groups_url
}) => {
    const currentPathname = window.location.pathname
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    var SideName = (is_groups_url)?'Cardinal Groups':'CardinalTalent';
    const authLinks = (
        <>
            <li>
                <a
                    href="/talent_home"
                    className={`${currentPathname === '/talent_home'
                            ? 'active'
                            : ''
                        }`}
                >
                    Dashboard
                </a>
            </li>
            <li>
                <a
                    href="/job_search"
                    className={`${currentPathname === '/job_search'
                            ? 'active'
                            : ''
                        }`}
                >
                    Find Jobs
                </a>
            </li>
            <li>
                <a
                    href="/search_employer"
                    className={`${currentPathname === '/search_employer'
                            ? 'active'
                            : ''
                        }`}
                        >
                    Search Decision Makers
                </a>
            </li>
            <li>
                <a
                    href="/candidate_interview_scheduled"
                    className={`${currentPathname === '/candidate_interview_scheduled'
                            ? 'active'
                            : ''
                        }`}
                        >
                    Interviews
                </a>
            </li>
            <li>
                <a
                    href="/my_networks"
                    className={`${currentPathname === '/my_networks'
                            ? 'active'
                            : ''
                        }`}
                >
                    My Network
                </a>
            </li>
            <li>
                <a
                    href="/network_conversations"
                    className={`${currentPathname === '/network_conversations'
                            ? 'active'
                            : ''
                        }`}
                >
                    Messaging
                </a>
            </li>
            <li>
                <a href="/account/invite_your_friend" className={`${currentPathname === '/account/invite_your_friend'
                            ? 'active'
                            : ''
                        }`} style={{ color: 'red', display: 'flex', alignItems: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={"bi bi-gift"} viewBox="0 0 16 16" style={{ marginTop: '-5px' }}>
                        <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5V7zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5H7z"/>
                    </svg>
                    <div className={"res1"} style={{ marginLeft: '5px' }}>
                        <span style={{ color: 'black', fontWeight: 'bold' }}> Invite Your Network, </span> 
                        <span style={{ fontWeight: 'bold' }}>Earn Money</span>
                    </div>
                </a>
            </li>
            <li className={'block-signin d-none'}>
                <a href="#" className={"btn btn-default btn-shadow ml-40 "} style={{color: 'white'}}>Submitted Jobs</a>
            </li> 
        </>
    )
    return (
        <>
        <header className={"header sticky-bar"}>
            <div className={"main-header"}>
                <div className={"header-left"}>
                    <div className={"header-logo"}>
                        <a href={host_url} className={"d-flex img1 ml-40"}>
                            <Image src={(is_groups_url)?LogoGroups:LogoWithText} alt={SideName} />
                        </a>
                    </div>
                    <div className={"header-nav"}>
                        <nav className={"nav-main-menu d-none d-xl-block"}>
                            <Nav className="align-items-center flex-wrap">
                                <ul className={'main-menu'} style={{display:'flex',alignItems:'center'}}>
                                    {authLinks}
                                </ul>
                            </Nav>
                        </nav>
                        <div className={"burger-icon burger-icon-white"}>
                            <span className={"burger-icon-top"}></span>
                            <span className={"burger-icon-mid"}></span>
                            <span className={"burger-icon-bottom"}></span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className={"mobile-header-active mobile-header-wrapper-style perfect-scrollbar"}>
            <div className={"mobile-header-wrapper-inner"}>
                <div className={"mobile-header-top"}>
                    <div className={"user-account"}>
                        
                        <img src={ (avatar_url) ? avatar_url : avatarImg} alt={ (currentUser.first_name) ? (currentUser?.first_name && Util.getFirstCharater(currentUser?.first_name)) + (currentUser?.last_name && Util.getFirstCharater(currentUser?.last_name) ) : (currentUser?.email && Util.getFirstCharater(currentUser?.email) ) } />
                        <div className={"content"}>
                            <h6 className={"user-name"}>Hello, <span className={"text-brand"}>{ (currentUser.first_name) ? (currentUser?.first_name && Util.getFirstCharater(currentUser?.first_name)) + (currentUser?.last_name && Util.getFirstCharater(currentUser?.last_name) ) : (currentUser?.email && Util.getFirstCharater(currentUser?.email) ) }</span></h6>
                        </div>
                    </div>
                    <div className={"burger-icon burger-icon-white"}>
                        <span className={"burger-icon-top"}></span>
                        <span className={"burger-icon-mid"}></span>
                        <span className={"burger-icon-bottom"}></span>
                    </div>
                </div>
                <div className={"mobile-header-content-area"}>
                    <div className={"perfect-scroll"}>
                        <div className={"mobile-menu-wrap mobile-header-border d-xl-none"}>
                            <nav>
                                <Nav>
                                    <ul className={'mobile-menu font-heading'}>
                                        {authLinks}
                                    </ul>
                                </Nav>
                            </nav>
                        </div>
                        <div className={"mobile-account"}>
                            <h6 className={"mb-10"}>Your Account</h6>
                            <ul className={"mobile-menu font-heading"}>
                                <li></li>
                                <li><a href={`/users/profile/${userId}`}>My Profile</a></li>
                                <li><a href={`/communities`}>My Community</a></li>
                                <li><a href={"/account/setting"}>Account Settings</a></li>
                                <li><a href={"/submissions"}>My Applications</a></li>
                                <li><a href={"/account/security"}>Change Password</a></li>
                                <li><a href={"/account/email_verification"} style={{ color: `${currentPathname === '/account/email_verification' ? '#2e6ef5': '#080a28'}`}}>Email Verification</a></li>
                                <li><a href={"/account/email_configuration"}>SMTP Email Configuration</a></li>
                                <li><a href={`/account/invite_your_friend`}>Invite Friends</a></li>

                                <li className="has-children">
                                    <a href={`/subscription`}>Subscription</a>
                                    <ul className="sub-menu">
                                        <li><a href={`/subscription`} style={{ color: `${currentPathname === '/subscription' ? '#2e6ef5': '#080a28'}`}}>Subscription</a></li>
                                        <li><a href={`/subscription/invoice`}  style={{ color: `${currentPathname === '/subscription/invoice' ? '#2e6ef5': '#080a28'}`}}>Subscription Invoice</a></li>
                                        <li><a href={`/subscription/billing`}  style={{ color: `${currentPathname === '/subscription/billing' ? '#2e6ef5': '#080a28'}`}}>Subscription Billing</a></li>
                                    </ul>
                                </li>

                                {/* <li><a href="#">Cover Letter Generator</a></li>
                                <li><a href="#">Email Generator</a></li> */}
                                <li><a href="#" onClick={signOut}>Sign Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

    function signOut(){
        localStorage.removeItem("user")
        if(localStorage.getItem("meet-sourcer-showed")){
          localStorage.removeItem("meet-sourcer-showed")
        }
        fetch('/users/sign_out', {method: 'DELETE'})
        .then((res) => {
          window.location.href = '/users/sign_in'
        })
      }
}

export default NavbarTalent
