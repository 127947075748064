import React, { useState, useEffect } from 'react'
import './../styles/invitedPage.scss'
import Row from 'react-bootstrap/Row'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Paginator_v2 from '../../../common/Paginator/Paginator_v2'
import AboutLeftPenal from '../Home/AboutLeftPenal'
import SweetAlert from 'react-bootstrap-sweetalert'

const PendingPage = ({communityId,currentUser}) => {
  
  const [invitations, setInvitations] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [loading, setLoading] = useState([])
  const [activePage, setActivePage] = useState(0)
  const [totalCount, setTotalCount] = useState()
  const [currentCount, setCurrentCount] = useState()
  const [perPage, setPerPage] = useState(50)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [memberID, setMemberID] = useState(0)
  const [requestType, setRequestType] = useState('')

  useEffect(() => {
    fetchData()
  }, [activePage])

  const fetchData = async () => {
    setLoading(true)
    setTotalPages(0)
    const url = '/communities/'+communityId+`/members/pending.json?page=${activePage == 0 ? 1 : activePage + 1}`
    makeRequest(url, 'get', '', {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      onSuccess: (response) => {
        setInvitations(
          response.data.list.map((list) => ({
            email: list.email,
            user_name: list.user.first_name + ' ' + list.user.last_name,
            status: list.status,
            created_at: moment(list.created_at).format('MMM DD YYYY'),
            actions: ((list.status == 'pending' || list.status == 'Pending' ) ? ( <><button className="new3 btn btn-success" onClick={() => (onApproveClick(list.id,'active'))}>Approve</button><button className="new3 btn btn-danger" onClick={() => (onRejectClick(list.id,'cancel'))}>Reject</button></>) : '')
          }))
        )
        setPerPage(response.data.per_page)
        setTotalPages(response.data.total_pages)
        setTotalCount(response.data.total_count)
        setCurrentCount(response.data.current_counts)
        setLoading(false)
      },
    })
  }

  const onApproveClick = (item_id,type) => {
    const URL = '/communities/member/request'
    const payload = new FormData()
    payload.append('member_id', item_id)
    payload.append('type', type)
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (res) => {
        setTimeout(() => {
          window.open('/communities/'+communityId+'/members/pending', "_self")
        }, 1000);
        return {
          message: res?.msg,
          messageType: res?.messageType
        }
      }
    })
  }

  const onRejectClick = (item_id,type) => {
    setShowRemoveConfirmation(true)
    setRequestType(type)
    setMemberID(item_id)
  }

  return (
     <>
    <div className="container communities-memebrs-pending mt-30">
      <div className="row">
        <div className="col-md-8">
          <h5 className="mb-30 font-weight-bold">{totalCount} Pending Request</h5>
          <div className="invites-container">
          {(invitations && invitations.length) ? (
            invitations.map((invitation) => {
              return (
                <div className="invite">
                  <div className="invite-info">
                    <strong style={{fontSize: '16px'}}>{invitation.user_name}</strong>
                    <div className="invite-status text-capitalize">{invitation.status} · Requested by {invitation.user_name} on {invitation.created_at}</div>
                  </div>
                  <div className="actions">
                    {invitation.actions}
                  </div>
                </div>
              )
            })
          ) : (
              <div style={{ textAlign: 'center' }}>
                  No Pending Request
              </div>
          )}
          </div>
          {Array.isArray(invitations) && totalPages > 1 && (
              <Row className="d-flex justify-content-center">
                  <Paginator_v2
                      pageCount={totalPages}
                      pageWindowSize={5}
                      activePage={activePage}
                      setActivePage={setActivePage}
                  />
              </Row>
          )}
        </div>
        <div className="col-lg-4 col-md-4">
                <AboutLeftPenal communityId={communityId} ></AboutLeftPenal>
        </div>
      </div>
    </div>
      {showRemoveConfirmation &&
        <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            title="Are you sure you want to reject?"
            onConfirm={()=>( onApproveClick(memberID,requestType),setShowRemoveConfirmation(false))}
            onCancel={()=>setShowRemoveConfirmation(false)}
            focusCancelBtn
            >
              You will not be able to recover this member!
        </SweetAlert>
      }
      </>
  );
}
export default PendingPage