import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import TextInput from '../Admin/shared/TextInput'
import MainPanel from '../../common/MainPanel/MainPanel'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'

const H1 = styled.h1`
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: #393f60;
    margin-bottom: 30px;
`

const P = styled.p`
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #1d2447;
    margin-bottom: 15px;
`

const A = styled.a`
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: #8091e7;
`

const SPAN = styled.span`
    font-size: 16px;
    margin-top: 10px;
    color: #1d2447;
`

const DIVERROR = styled.div`
    font-size: 15px;
    color: red;
    text-align: center;
    width: 100%;
`

const DIVSUCCESS = styled.div`
    font-size: 15px;
    color: green;
    text-align: center;
`
const Button = styled.button`
    background: linear-gradient(
        94.67deg,
        #5f78ff -1.19%,
        #7185f2 53.94%,
        #8d91ff 102.59%
    );
    border-radius: 20px;
    padding: 9px 31px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    &:disabled {
        background: lightgray;
    }

`

const initialFormData = {
    reason: '',
}



const RecommendedOptOutPage = (props) => {
    const { id, job_id , reasons } = props

    return (
        <>
            <MainPanel>
                <H1>Candidate Recommendation Opt Out</H1>

                <Formik
                    initialValues={{
                        reason: ''
                    }}
                    validationSchema={Yup.object({
                        reason: Yup.string()
                            .required('Reason is required')
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        document.getElementById(
                            'signin-success-msg'
                        ).innerHTML = ''
                        document.getElementById('signin-error-msg').innerHTML = ''
                        const payload = new FormData()
                        const url = '/users/update_recommended_optout'
                        payload.append('unsubscribe[job_id]', job_id)
                        payload.append('unsubscribe[reason]', values.reason)
                        payload.append('format', 'js')
                        axios
                            .post(url, payload)
                            .then(function (response) {
                                document.getElementById(
                                    'signin-success-msg'
                                ).innerHTML = response.data.message
                                setTimeout(() => {
                                    window.location.reload()
                                }, 2000)
                            })
                            .catch(function (error) {
                                const message = error?.response?.data?.alert || 'Sorry, something went wrong.'
                                document.getElementById(
                                    'signin-error-msg'
                                ).innerHTML = message
                            })
                    }}
                >
                    <Form className="">
                        <DIVERROR id="signin-error-msg"></DIVERROR>
                        <br />
                        <DIVSUCCESS id="signin-success-msg"></DIVSUCCESS>
                        <br />
                        <Col xs={12} sm={12} lg={12}>
                        <TextInput
                            label="Reason To Opt Out"
                            name="reason"
                            placeholder='Enter Reason'
                            id="reason"
                            type="textarea"
                            width="400px"
                            rows="3"
                        >
                            
                        </TextInput>
                        </Col>
                        <Col xs={12} sm={12} lg={12}>
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            <Button
                                type="submit"
                                style={{ alignSelf: 'flex-end' }}
                            >
                                Opt Out
                            </Button>
                        </div>
                        </Col>
                    </Form>
                </Formik>
            </MainPanel>
        </>
    )
}

export default RecommendedOptOutPage;