import React, { useEffect, useState, useRef } from 'react'
import Card from './shared/Card'
import Button from './shared/Button'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import Input from '../../common/Styled components/Input'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import MonthRangePicker from '../../common/MonthRangePicker/MonthRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import { Dashboard } from '@material-ui/icons'

const CampaignManagerAnalyticPage = (props) => {
    const [activePage, setActivePage] = useState(1)
    let campaign_manager = sessionStorage.getItem('campaign_manager_2')
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(props.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [account_managers,setAccountManagers] = useState([{value: 'all', label: 'All'},...props.account_managers])
    const [jobStatus, setJobStatus] = useState([])
    const queryParams = new URLSearchParams(window.location.search)
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    const [marketer, setMarketer] =  useState((queryParams.get("selected_marketer")) ? queryParams.get("selected_marketer") : '')
    const [selectedTeamLead,setSelectedTeamLead] = useState(campaign_manager && campaign_manager != "null" ? JSON.parse(campaign_manager).value : '')
    const localStorageDateRange = JSON.parse(sessionStorage.getItem('recruiterAnalysticDateRange'))
    const startDate = (urlStartDate ? urlStartDate : (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('month').toDate())
    const endDate = (urlEndDate ? urlEndDate : (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate())
    const [dateRange, setDateRange] = useState({startDate:  startDate ,endDate: endDate})
    const csvExportRef = React.useRef();
    const [exportDatas, setExportDatas] = useState([])
    const [loading, setLoading] = useState(true)
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const handleDate = (date) => {
        setDateRange(date)
    }
    const [defaultselected,setdefaultselected] = useState(campaign_manager && campaign_manager != "null" ? JSON.parse(campaign_manager) : {value: 'all', label: 'All'})
    const [organizationType,setOrganizationType] = useState([{value: 'all', label: 'All'},{value: 'hourly', label: 'Hourly'},{value: 'contingency', label: 'Contingency'}])
    const [selectedOrganizationType,setSelectedOrganizationType] = useState('')
    const selectCurrencyRef = useRef()
    const headers = [
        { label: 'Campaign Manager', key: 'campaign_manager_name' },
        { label: 'Leads', key: 'leads' },
        { label: 'Applicants', key: 'applicants' },
        { label: 'Recruiter Screened', key: 'recruiterScreened' },
        { label: 'Submitted', key: 'submitted' },
        { label: 'First Interview', key: 'firstInterview' },
        { label: 'On Hold', key: 'onhold' },
        { label: 'Second Interview', key: 'secondInterview' },
        { label: 'Offers', key: 'offers' },
        { label: 'Hired', key: 'hired' },
        { label: 'Archived', key: 'archived' },
    ];
    
    const fetchData = async () => {
        setLoading(true)
        const url = '/admin/campaign_manager_lead.json'
        let start_date = moment(moment(dateRange.startDate).startOf('month').toDate()).format('YYYY-MM-DD')
        let end_date =  moment(moment(dateRange.startDate).endOf('month').toDate()).format('YYYY-MM-DD')
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&account_manager_id=${selectedTeamLead}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}&marketer=${marketer}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setJobStatus(response?.data?.org_status.map((data,key)=> (sr_no = sr_no + 1,{
            id: sr_no,
            campaign_manager_name: (capitalize(data?.cm_name)),
            leads: (data.lead > 0) ? <a href={`/admin/campaign_manager_lead_detail?marketer_id=${data.user_id}&selected_marketer=${selectedTeamLead}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=lead`}>{data.lead}</a> :'',
            applicants: (data.applicant) ? data.applicant :'',
            recruiterScreened: (data.recruitor_screened) ? <a href={`/admin/campaign_manager_lead_detail?marketer_id=${data.user_id}&selected_marketer=${selectedTeamLead}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> :'',
            submitted:  (data.submitted > 0) ? <a href={`/admin/campaign_manager_lead_detail?marketer_id=${data.user_id}&selected_marketer=${selectedTeamLead}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a>:'',
            firstInterview: (data.first_interview > 0) ? <a href={`/admin/campaign_manager_lead_detail?marketer_id=${data.user_id}&selected_marketer=${selectedTeamLead}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> : '',
            secondInterview: (data.second_interview > 0) ? <a href={`/admin/campaign_manager_lead_detail?marketer_id=${data.user_id}&selected_marketer=${selectedTeamLead}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> : '',
            onhold: (data.onhold > 0) ? <a href={`/admin/campaign_manager_lead_detail?marketer_id=${data.user_id}&selected_marketer=${selectedTeamLead}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=onhold`}>{data.onhold}</a> : '',
            offers: (data.offer > 0) ? <a href={`/admin/campaign_manager_lead_detail?marketer_id=${data.user_id}&selected_marketer=${selectedTeamLead}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a>: '',
            archived: (data.reject > 0) ? <a href={`/admin/campaign_manager_lead_detail?marketer_id=${data.user_id}&selected_marketer=${selectedTeamLead}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> : '',
            hired: (data.hired > 0) ? <a href={`/admin/campaign_manager_lead_detail?marketer_id=${data.user_id}&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> : '',
        })))
        setFooter({id:'Total',
            applicants:response.data.total_values.applicants,
            leads:response.data.total_values.leads,
            recruiterScreened:response.data.total_values.recruiterScreened,
            submitted:response.data.total_values.submitted,
            firstInterview:response.data.total_values.firstInterview,
            secondInterview:response.data.total_values.secondInterview,
            onhold:response.data.total_values.onhold,
            offers:response.data.total_values.offers,
            archived:response.data.total_values.archived,
            hired:response.data.total_values.hired
        })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoading(false)
        // selectCurrencyRef.current.select.setValue(response.data.selected_marketer);
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Campaign Manager',
            selector: row => row.campaign_manager_name,
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true,
            sortable: true,
            sortField: 'cm_name'
        },
        {
            name: 'Leads',
            selector: row => row.leads,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'lead'
        },
        {
            name: 'Recruiter Screened',
            selector: row => row.recruiterScreened,
            maxWidth: '12%',
            minWidth: '12%',
            sortable: true,
            sortField: 'recruitor_screened'
        },
        {
            name: 'Submitted',
            selector: row => row.submitted,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'submitted'
        },
        {
            name: 'First Interview',
            selector: row => row.firstInterview,
            maxWidth: '9%',
            minWidth: '9%',
            sortable: true,
            sortField: 'first_interview'
        },
        {
            name: 'Second Interview',
            selector: row => row.secondInterview,
            maxWidth: '9%',
            minWidth: '9%',
            sortable: true,
            sortField: 'second_interview'
        },
        {
            name: 'On Hold',
            selector: row => row.onhold,
            maxWidth: '8%',
            minWidth: '8%',
            sortable: true,
            sortField: 'onhold'
        },
        {
            name: 'Offers',
            selector: row => row.offers,
            maxWidth: '8%',
            minWidth: '8%',
            sortable: true,
            sortField: 'offer'
        },
        {
            name: 'Hired',
            selector: row => row.hired,
            maxWidth: '6%',
            minWidth: '6%',
            sortable: true,
            sortField: 'hired'
        },
        {
            name: 'Archived',
            selector: row => row.archived,
            maxWidth: '8%',
            minWidth: '8%',
            sortable: true,
            sortField: 'reject'
        }
    ]

    const exportDataMethod = async () => {
        const url = '/admin/campaign_manager_lead.json'
        let start_date = moment(moment(dateRange.startDate).startOf('month').toDate()).format('YYYY-MM-DD')
        let end_date =  moment(moment(dateRange.startDate).endOf('month').toDate()).format('YYYY-MM-DD')
        const response = await makeRequest(
            `${url}?account_manager_id=${selectedTeamLead}&organization_type=${selectedOrganizationType}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setExportDatas(response?.data?.org_status.map((data,key)=> ({
            id: key,
            campaign_manager_name: data?.cm_name && capitalize(data?.cm_name),
            leads: data.lead,
            applicants: data.applicant,
            recruiterScreened: data.recruitor_screened,
            submitted: data.submitted,
            firstInterview: data.first_interview,
            secondInterview: data.second_interview,
            offers: data.offer,
            archived: data.reject,
            hired: data.hired
        })))
        csvExportRef?.current?.link.click();
    }

    useEffect(()=>{
        fetchData()
    },[dateRange,activePage, selectedTeamLead,sortDirection,sortField,selectedOrganizationType])

    const onSelectedTeamLead = (event) => {
        setSelectedTeamLead(event?.value || '')
        setMarketer(event?.value || '')
        sessionStorage.setItem("campaign_manager_2", JSON.stringify(event))
    }

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',marginLeft:'10px',minHeight:'40px' })
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const dashboardRedirect = () =>{
        let start_date = moment(moment(dateRange.startDate).startOf('month').toDate()).format('YYYY-MM-DD')
        let end_date =  moment(moment(dateRange.startDate).endOf('month').toDate()).format('YYYY-MM-DD')
        window.location.href = `/admin/campaign_manager_lead_dashboard?start_date=${start_date}&end_date=${end_date}`
    }
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'800px'}}>
                        Campaign Manager Lead Analytics  (Dashboard-1)
                </P>
                <div className='pull-right'>
                    <button className={`ml-3 ${styles.exportCSVButton}`} onClick={dashboardRedirect}>
                        Dashboard 2
                    </button>
                    <button className={`ml-1 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                        Export CSV
                    </button>
                </div>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"Campaign_manager_lead_analytic.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
                    <div style={{display: 'grid',marginLeft:'10px'}}>
                        <label>
                            Campaign Manager
                        </label>
                        <Select
                            defaultValue={defaultselected}
                            options={account_managers}
                            onChange={onSelectedTeamLead}
                            name="team_lead"
                            className={styles.colourStyles}
                            // ref={selectCurrencyRef}
                        />
                    </div>
                    <div style={{display: 'grid',marginLeft:'10px'}}>
                        <label>
                            Month
                        </label>

                        <MonthRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobStatus}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
    )
}

export default CampaignManagerAnalyticPage
