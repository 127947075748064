import React, { useState, useEffect, useRef } from 'react'
import './../styles/invitedPage.scss'
import Row from 'react-bootstrap/Row'
import image3 from '../../../../../assets/v2/imgs/blog/blog-thumb-1.png'
import CandidateTwoImage from '../../../../../assets/images/img_avatar.png'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import CandidateAvatarIcon from '../../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import moment from 'moment'
import Paginator_v2 from '../../../common/Paginator/Paginator_v2'
import Container from "react-bootstrap/Container"
import Modal from "react-bootstrap/Modal"
import SweetAlert from 'react-bootstrap-sweetalert'
import AboutLeftPenal from '../Home/AboutLeftPenal'

const ListPage = ({ communityId, isCommunityAdmin, isCommunityMember, currentUser, membersRoleCount, membersCount }) => {
  const [pageLink, setPageLink] = useState(window.location.origin+"/communities/"+communityId+"/about")
  const [members, setMembers] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [loading, setLoading] = useState([])
  const [show, setShow] = useState(false)
  const [showInvite, setInviteShow] = useState(false)
  const [activePage, setActivePage] = useState(0)
  const [filterStatus, setFilterStatus] = useState(isCommunityAdmin ? 'active' : 'active')
  const [filterRole, setFilterRole] = useState(isCommunityAdmin ? '' : 'member')
  const [totalCount, setTotalCount] = useState()
  const [currentCount, setCurrentCount] = useState()
  const [perPage, setPerPage] = useState(50)
  const [selectedMember, setSelectedMember] = useState([])
  const [moveToArchived, setMoveToArchived] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [validationError, setValidationError] = useState({})

  useEffect(() => {
    fetchData()
  }, [activePage, filterStatus, filterRole])

  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  // Handles clicking outside the popover to close it
  const handleClickOutside = (event) => {
    if (isPopoverOpen && !popoverRef.current.contains(event.target)) {
      setPopoverOpen(false);
    }
  };

  // Reference to the popover element
  const popoverRef = useRef(null);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverOpen]);

  const fetchData = async () => {
    setLoading(true)
    setTotalPages(0)

    const url = '/communities/' + communityId + `/members.json?page=${activePage == 0 ? 1 : activePage + 1}&t=${filterStatus}&r=${filterRole}`
    makeRequest(url, 'get', '', {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      onSuccess: (response) => {
        setMembers(
          response.data.list.map((list) => ({
            member_id: list.id,
            user_id: list.user_id,
            user_name: list.user.first_name + ' ' + list.user.last_name,
            first_name: list.user.first_name,
            last_name: list.user.last_name,
            title: list.user?.person?.title,
            location: list.user.location,
            is_creator: (list.community.created_by == list.user_id) ? 1 : 0,
            show_role: list.role == 'admin' ? 'Admin' : 'Member',
            role: list.role == 'admin' ? (list.community.created_by == list.user_id) ? '(Creator)' : '(Admin)' : '',
            pic: (list.user?.person?.avatar_url != null) ? list.user?.person?.avatar_url : CandidateTwoImage,
            avatar_url: list.user?.person?.avatar_url,
            status: list.status,
            created_at: moment(list.created_at).format('MMM DD YYYY'),
          }))
        )
        setPerPage(response.data.per_page)
        setTotalPages(response.data.total_pages)
        setTotalCount(response.data.total_count)
        setCurrentCount(response.data.current_counts)
        setLoading(false)
      },
    })
  }

  const getFilterBasedOnCount = (t) => {
    setActivePage(0)
    setFilterStatus(t)
  }

  const getFilterBasedOnRole = (t) => {
    setActivePage(0)
    setFilterRole(t)
  }

  const onHide = () => {
    setShow(false)
    setInviteShow(false)
  }

  const showMemberSetting = (member) => {
    setSelectedMember(member)
    setShow(true)
  }

  const showMemberInvite = () => {
    setInviteShow(true)
  }

  const handleRoleSelect = (role) => {
    const URL = '/communities/update_role'
    const payload = new FormData()
    payload.append('community_id', communityId)
    payload.append('member_id', selectedMember.member_id)
    payload.append('role', role.toLowerCase())
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (res) => {
        setShow(false)
        fetchData()
        return {
          message: res?.msg,
          messageType: res?.messageType
        }
      }
    })
    setPopoverOpen(false); // Optionally close the popover
  };

  const handleRemoveMember = () => {
    setMoveToArchived(false)
    const URL = '/communities/remove_member'
    const payload = new FormData()
    payload.append('community_id', communityId)
    payload.append('member_id', selectedMember.member_id)
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (res) => {
        setTimeout(() => {
          window.open('/communities/' + communityId + '/members', "_self")
        }, 1000);
        return {
          message: res?.msg,
          messageType: res?.messageType
        }
      }
    })
    setPopoverOpen(false); // Optionally close the popover
  };

  const removeMember = () => {
    setMoveToArchived(true)
  };

  function copyToClipboard(value, button) {
    navigator.clipboard.writeText(value)
      .then(() => {
        if (button) {
          button.textContent = 'Copied';
          setTimeout(() => {
            button.textContent = 'Copy';
          }, 3000); // Change back to 'Copy' after 3 seconds
        }
      })
      .catch((error) => {
        console.error('Unable to copy to clipboard: ', error);
      });
  }

  const handleVerification = async (event) => {
    const URL = '/communities/send_invite'
    const payload = new FormData()
    payload.append('email_invitation[community_id]', communityId)
    payload.append('email_invitation[email]', userEmail)
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (res) => {
        setBtnDisabled(false)
        return {
          message: res?.msg,
          messageType: res?.messageType
        }
      }
    })
  }


  const handleInviteSend = (event) => {
    event.preventDefault()
    handleVerification(event)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-sm-8">
          <div className="single-recent-jobs">
            <div className="list-recent-jobs">
              <div className="card-job hover-up wow animate__ animate__fadeInUp  animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                <div className="card-job-bottom">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-md-12">
                      <div className="">
                        {(isCommunityAdmin) ? (
                          <>
                            <a href="#" onClick={() => getFilterBasedOnCount('active')} className={filterStatus == 'active' ? "btn btn-chip members_top_btn mr-10" : "btn btn-chip mr-10"}>Active {membersCount.active ? membersCount.active : 0}</a>
                            <a href="#" onClick={() => getFilterBasedOnCount('cancel')} className={filterStatus == 'cancel' ? "btn btn-chip members_top_btn mr-10" : "btn btn-chip mr-10"}>Cancelling {membersCount.cancel ? membersCount.cancel : 0}</a>
                            <a href="#" onClick={() => getFilterBasedOnCount('remove')} className={filterStatus == 'remove' ? "btn btn-chip members_top_btn mr-10" : "btn btn-chip mr-10"} >Churned {membersCount.remove ? membersCount.remove : 0}</a>
                          </>
                        ) : (
                          <>
                            <a href="#" onClick={() => getFilterBasedOnRole('member')} className={filterRole == 'member' ? "btn btn-chip members_top_btn mr-10" : "btn btn-chip mr-10"}>Members {membersRoleCount.member ? membersRoleCount.member : 0}</a>
                            <a href="#" onClick={() => getFilterBasedOnRole('admin')} className={filterRole == 'admin' ? "btn btn-chip members_top_btn mr-10" : "btn btn-chip mr-10"}>Admin {membersRoleCount.admin ? membersRoleCount.admin : 0}</a>
                          </>
                        )}
                        {(isCommunityAdmin) ? (
                            <a href={'/communities/'+communityId+'/invites'} target="_blank" className="btn btn-default btn-sm mr-10 invite-btn float-right">INVITE</a>
                        ) : (
                            <a href="#" onClick={() => showMemberInvite()} className="btn btn-default btn-sm mr-10 invite-btn float-right">INVITE</a>
                        )}
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              {(members && members.length) ? (
                members.map((member) => {
                  return (
                    <div key={member.member_id} className="card-job hover-up wow animate__ animate__fadeInUp  animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                      <div className="card-job-top">
                        <div className="card-job-top--image">
                          <figure>
                          {member?.avatar_url ?
                          <img alt="jobhub" src={member.pic} /> : <CandidateAvatarIcon firstName={member?.first_name} lastName={member?.last_name} /> }
                          </figure>
                        </div>
                        <div className="card-job-top--info">
                          <h6 className="card-job-top--info-heading"><a href="#">{member.user_name} <span style={{ color: 'rgb(67, 87, 173)', fontSize: '13px' }}>{member.role}</span></a></h6>
                          <div className="row">
                            <div className="col-lg-6">
                              <a ><span className="card-job-top--company" style={{color: '#005999'}}>@{member.user_name}-{member.member_id}</span></a>
                              <br />
                              <h6 className="card-job-top--info-heading">
                                <a href="#">{member.title}</a>
                              </h6>
                              {/* <span className=" text-icon-first">Online</span><br /> */}
                              <span className="card-job-top--location text-sm"><i className="fi-rr-marker" />{member.location}</span><br />
                              <span className="card-job-top--post-time text-sm"><i className="fi-rr-clock" /> Joined {member.created_at}</span>
                            </div>
                            <div className="col-lg-6 text-lg-end">
                              { (isCommunityMember && (filterStatus == 'active' || filterStatus == '') && currentUser.id != member.user_id ) ? (
                                <a href={('/communities/' + communityId + '/members/chat?user='+member.user_id)} className="btn btn-default mr-10 btn-ai-view"><span className="icon-card"><i className="fi-rr-comments"></i> Chat</span></a>
                              ) : (
                                <></>
                              )}    
                              {(isCommunityAdmin && member.is_creator == 0 && filterStatus == 'active') ? (
                                <>
                                  <a href="#" className="btn btn-default mr-10 btn-ai-view" onClick={() => showMemberSetting(member)}><span className="icon-card"><i className="fi-rr-settings"></i> Settings</span></a>
                                </>
                              ) : (
                                <></>
                              )}
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div style={{ textAlign: 'center',minHeight: '100px' }}>
                  No members
                </div>
              )}
              {Array.isArray(members) && totalPages > 1 && (
                <Row className="d-flex justify-content-center">
                  <Paginator_v2
                    pageCount={totalPages}
                    pageWindowSize={5}
                    activePage={activePage}
                    setActivePage={setActivePage}
                  />
                </Row>
              )}
            </div>
          </div>
        </div>
        <div class="col-md-4">
           <AboutLeftPenal communityId={communityId} ></AboutLeftPenal>
        </div>
      </div>
      {moveToArchived &&
        <SweetAlert
          warning
          showCancel
          title='Remove from group?'
          showCloseButton={false}
          showConfirm={true}
          confirmBtnText="REMOVE"
          CancelBtnText="CANCEL"
          confirmBtnBsStyle="danger"
          onCancel={() => setMoveToArchived(false)}
          onConfirm={() => { handleRemoveMember() }}
          cancelBtnStyle={{ textDecoration: 'none' }}
        >
          {"Are you sure you want to remove " + selectedMember.user_name + " from group? You can't undo this."}
        </SweetAlert>
      }
      <Modal
        onHide={onHide}
        show={show}
        size="lg"
        centered={true}
        aria-labelledby="contained-modal-title-vcenter"
        className='modal-entry-form'
      >
        <Modal.Header closeButton className='mt-2 mb-2'>
          <Modal.Title id="contained-modal-title-vcenter" className="font-weight-bold">
            {selectedMember.user_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="p-20 mb-20">
            <h5 className="mb-10">Membership settings</h5>
            <div style={{ fontSize: '16px' }}><span className="font-weight-bold">Role: </span>{selectedMember.show_role}</div>
            <button className="btn btn-primary change-role btn-ai-view" onClick={togglePopover}>Change Role</button>
            <button className="btn btn-primary mt-20 remove_group" onClick={removeMember}>Remove From Group</button>
            {isPopoverOpen && (
              <div className="popover" ref={popoverRef}>
                <ul className="role-list">
                  {['Member', 'Admin'].map((role) => (
                    <li
                      key={role}
                      className={selectedMember.show_role === role ? 'active' : ''}
                      onClick={() => handleRoleSelect(role)}
                    >
                      {role}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Container>
        </Modal.Body>
      </Modal>

      <Modal
        onHide={onHide}
        show={showInvite}
        size="lg"
        // centered={true}
        aria-labelledby="contained-modal-title-vcenter"
        className='modal-entry-form'
      >
        <Modal.Header closeButton className='mt-2 mb-2'>
          <Modal.Title id="contained-modal-title-vcenter" className="font-weight-bold">
            {'INVITE'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="p-20 mb-20">
            <h4 className="mt-20 mb-10" style={{fontSize: '22px'}}>Share your group link</h4>
            <p>This will take people to your group’s About page where they can purchase or request membership.</p>
            <div className="group_share mt-25">
              <div className="input-group">
                  <input readOnly={true} className='form-control group_link' style={{height: '50px'}} type='text' name='group_link_link' value={pageLink} />
                  <button className="btn btn-default m-0" type="button" onClick={(e) => copyToClipboard(pageLink, e.target)}>Copy</button>
              </div>
            </div>
            <div className="mt-50">
              <p>These invite methods will grant instant access without purchasing or requesting membership.</p>
              <div style={{ textAlign: 'initial' }}>
                <form onSubmit={(e) => { handleInviteSend(e) }}>
                  <input type="text" id="email" name="email" value={userEmail} required className="mt-3" style={{ border: '1px solid #ccc',borderRadius: '5px', width: '70%', height: '50px' }} placeholder="Enter Email address" 
                    onChange={event =>{
                      setUserEmail(event.target.value)
                      setValidationError({})
                      }}
                    />
                  <button type="submit" className="btn btn-default t m-0 ml-5 mb-1" disabled={btnDisabled} style={{ backgroundColor: '#2e6ef5', height: '50px' }}>SEND</button>
                </form>
                <label className='error'>
                  {validationError && validationError.email}        
                </label>
              </div>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ListPage

