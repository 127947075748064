import React, { useEffect, useState,Fragment} from 'react'
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Util from '../../../utils/util'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import './styles/MyNetworkIndex.scss'
import lodingImage from '../../../../assets/v2/imgs/theme/loading_new.gif'
import ManageMyMetworkSideBar from './ManageMyMetworkSideBar'
import '../../../../assets/v2/css/main.css'
import '../../pages/Interview/styles/AiInterview.scss'

function MyNetworkIndex({ current_user,my_total_candidates,received,sent,reject }) {
    const { id, linkedin_profile_url, location, job_title, github_url, name } = current_user
    const [loading, setLoader] = useState(true)
    const [candidates, setCandidates] = useState([])
    const [requestCandidates, setRequestCandidates] = useState([])
    const [acceptedtCandidates, setAcceptedCandidates] = useState([])
    const [totalInvitetionCount, setTotalInvitetionCount] = useState(0)
    const fetchData = async() => {
        setCandidates([])
        const url = `/new_network.json`
        await makeRequest(url, 'get',  {},{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setCandidates(
                    res.data.candidates.map((candidate) => ({
                        id: candidate.id,
                        first_name: (candidate.person.first_name != null) ? capitalize(candidate.person.first_name): capitalize(candidate.first_name),
                        last_name: (candidate.person.last_name != null) ? capitalize(candidate.person.last_name) : capitalize(candidate.last_name),
                        title: candidate.person.title || candidate.title,
                        location: candidate.person.location || candidate.location,
                        state: candidate.state || '',
                        city: candidate.city || '',
                        avatar_url: candidate.person.avatar_url || '',
                        employer: candidate.person.employer || '',
                        description: candidate.person.description || '',
                    }))
                )
                setLoader(false)
            },
        })
    }

    const fetchMyNetwork = async() => {
        setRequestCandidates([])
        const url = `/my_networks.json`
        await makeRequest(url, 'get',  {},{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setAcceptedCandidates(
                    res.data.candidates.map((candidate) => ({
                        id: candidate.id,
                        first_name: (candidate.person.first_name != null) ? capitalize(candidate.person.first_name): capitalize(candidate.first_name),
                        last_name: (candidate.person.last_name != null) ? capitalize(candidate.person.last_name) : capitalize(candidate.last_name),
                        title: candidate.person.title || candidate.title,
                        location: candidate.person.location || candidate.location,
                        state: candidate.state || '',
                        city: candidate.city || '',
                        avatar_url: candidate.person.avatar_url || '',
                        employer: candidate.person.employer || '',
                        description: candidate.person.description || '',
                    }))
                )
            },
        })
    }

    const fetchNewRequestData = async() => {
        setRequestCandidates([])
        // setLoader(true)
        const url = `/get_invite_network.json`
        await makeRequest(url, 'get',  {},{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setRequestCandidates(
                    res.data.candidates.map((candidate) => ({
                        id: candidate.id,
                        first_name: (candidate.user1.person.first_name != null) ? capitalize(candidate.user1.person.first_name): capitalize(candidate.user1.first_name),
                        last_name: (candidate.user1.person.last_name != null) ? capitalize(candidate.user1.person.last_name) : capitalize(candidate.user1.last_name),
                        title: candidate.user1.person.title || candidate.user1.title,
                        location: candidate.user1.person.location || candidate.user1.location,
                        state: candidate.user1.state || candidate.user1.state,
                        city: candidate.user1.city || candidate.user1.city,
                        avatar_url: candidate.user1.person.avatar_url || '',
                        employer: candidate.user1.person.employer || '',
                        description: candidate.user1.person.description || '',
                    }))
                )
                setTotalInvitetionCount(res.data.total)
                // setLoader(false)
            },
        })
    }
    useEffect(()=>{
        fetchData()
        fetchNewRequestData()
        fetchMyNetwork()
    },[])

    const inviteNetwork = (id) => {
        const url = `/invite_network.json`
        makeRequest(url, 'post',  {
                user_id_2: id
            },{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            createResponseMessage: (response) => {
                return {
                    message: response.message,
                    messageType: response.messageType,
                    loading: false,
                    autoClose: true,
                }
            },
            onSuccess: (res) => {   
                const updatedCandidates = candidates.map(candidate =>
                    candidate.id === id ? { ...candidate, status: 'pending' } : candidate
                );
                setCandidates(updatedCandidates);
            },
        })
    }

    const removeNetwork = (index) => {
        const updatedCandidates = [...candidates];
        updatedCandidates.splice(index, 1); // Remove the candidate at the specified index
        setCandidates(updatedCandidates);
    }

    const updateNetworkStatus = (id,index,status) => {
        const url = `/my_networks/${id}`
        makeRequest(url, 'put',  {
                status: status
            },{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            createResponseMessage: (response) => {
                return {
                    message: response.message,
                    messageType: response.messageType,
                    loading: false,
                    autoClose: true,
                }
            },
            onSuccess: (res) => {
                const updatedCandidates = [...requestCandidates];
                updatedCandidates.splice(index, 1); // Remove the candidate at the specified index
                setRequestCandidates(updatedCandidates);
            },
        })
    }

    return (
        <>
            <div className="section-box mt-20">
                <div className="ai-containter">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 float-right mt-20">
                            <div className="content-page box-boder">
                                <div className="box-filters-job mt-15 mb-10">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            {requestCandidates.length != 0 ? (
                                                <h5 className={'fw-bold'}>Invitations</h5>
                                            ):(
                                                <h5>No pending invitations</h5>
                                            )}
                                        </div>
                                        <div className="col-lg-5 text-lg-end mt-sm-15">
                                            <div className="display-flex2">
                                                <a href='my_networks/received'>
                                                {totalInvitetionCount > 3 ? (
                                                    <span className="text-sortby">See all {totalInvitetionCount}</span>
                                                ):(
                                                    <span className="text-sortby">Manage</span>
                                                )} 
                                                </a> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="employers-list">
                                {requestCandidates && requestCandidates.map((candidate, index) => {
                                    return(
                                        <div key={candidate.id}  className="card-employers_new hover-up wow animate__animated animate__fadeIn">
                                            <div className="row align-items-center">
                                                <div className="col-lg-9 col-md-6 d-flex">
                                                    <div className="employers-logo  mr-15">
                                                        <a href={`/users/profile/${candidate.id}`} className={'invite_candidate'}>
                                                            <figure>
                                                                {candidate?.avatar_url ?
                                                                    <img alt="jobhub" className={'img_border image-size'} src={(candidate?.avatar_url != '')?candidate.avatar_url:'assets/imgs/page/candidates/img-candidate10.png'} />
                                                                    : <CandidateAvatarIcon firstName={candidate?.first_name} lastName={candidate?.last_name} />
                                                                }
                                                            </figure>
                                                        </a>
                                                    </div>
                                                    <div className="employers-name">
                                                        <h5><a href={`/users/profile/${candidate.id}`}><strong>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</strong></a></h5>
                                                        <span className="text-sm  text-muted overflowtext" title={candidate?.description}>{candidate?.description || 'N/A'}</span>
                                                        <span className="d-flex align-items-center"><i className="fi-rr-marker mr-5 ml-0"></i>{candidate?.location || 'N/A'}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 text-lg-end d-lg-block d-none">
                                                        <a href="#" onClick={(e) => { updateNetworkStatus(candidate.id,index,'declined') }} className="btn btn-danger rounded-pill mr-10">Ignore</a>
                                                        <a href="#" onClick={(e) => { updateNetworkStatus(candidate.id,index,'accepted') }} className="btn btn-default rounded-pill">Accept</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                </div>
                            </div>

                            <div className="content-page">
                                <div className="box-filters-job mt-15 mb-10">
                                    <div className="row">
                                        <div className='col-lg-7'>
                                            <h5 className={'fw-bold'}>People you may know {(location!= '' && location!= null)  ? 'in Greater '+location : ''} </h5>
                                        </div>
                                        <div className="col-lg-5 text-lg-end mt-sm-15">
                                            <div className="display-flex2">
                                                <a href='/my_networks/new'><span className="text-sortby">See All</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {(loading) ? 
                                        <div className="text-center loading">
                                            <img src={lodingImage} alt="loading" />
                                        </div>
                                    :''}
                                    {candidates.map((candidate, index) => {
                                        return(
                                        <div key={candidate.id} className="col-lg-3 col-md-6">
                                            <div className="card-grid-2 hover-up">
                                                <div className="card-grid-2-link">
                                                    <a href='#' onClick={(e) => {
                                                                removeNetwork(index)
                                                                }
                                                    }><i className="fi-rr-cross-circle"></i></a>
                                                </div>
                                                <div className="text-center card-grid-2-image-rd">
                                                    <a href={`/users/profile/${candidate.id}`} className={'new_candidate'}>
                                                        <figure>
                                                            {candidate?.avatar_url ?
                                                                <img alt="jobhub" className={'img_border'} src={(candidate?.avatar_url != '')?candidate.avatar_url:'assets/imgs/page/candidates/img-candidate.png'} />
                                                                : <CandidateAvatarIcon firstName={candidate?.first_name} lastName={candidate?.last_name} />
                                                            }
                                                        </figure>
                                                    </a>
                                                </div>
                                                <div className="card-block-info">
                                                    <div className="card-profile">
                                                        <a href={`/users/profile/${candidate.id}`}><strong>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</strong></a>
                                                        <span className="text-sm overflowtext" title={candidate?.description}>{(candidate?.description || 'N/A')}</span>
                                                    </div>
                                                    <div className="employers-info d-flex align-items-center justify-content-center mt-15">
                                                        <span className="d-flex align-items-center"><i className="fi-rr-marker mr-5 ml-0"></i>{(candidate?.location || 'N/A')}</span>
                                                    </div>
                                                    <div className="card-2-bottom card-2-bottom-candidate mt-30">

                                                        <div className="text-center mt-25 mb-5">
                                                        {candidate.status === 'pending' ? (
                                                            <button className="btn btn-default btn-brand-hover rounded-pill m-0" disabled={true}>
                                                                <div className={'d-flex align-items-center'}><i className="fi-rr-clock"></i> <span className='mt-0 ml-10 fw-bold'>Pending</span></div>
                                                            </button>
                                                        ):(
                                                            <a
                                                            onClick={(e) => {
                                                                inviteNetwork(candidate?.id)
                                                                }
                                                            } className="btn btn-border btn-brand-hover rounded-pill" disabled={loading}>
                                                                <div className={'d-flex align-items-center'}><i className="fi-rr-user-add"></i> <span className='mt-0 ml-10 fw-bold'>{loading ? 'Sending...' : 'Connect'}</span></div>
                                                            </a>
                                                        )} 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        );
                                    })
                                    }
                                </div>
                            </div>

                            {acceptedtCandidates.length > 0 ? (
                                <div className="content-page">
                                    <div className="box-filters-job mt-15 mb-10">
                                        <div className="row">
                                            <div className='col-lg-7'>
                                                <h5 className={'fw-bold'}>My Connects</h5>
                                            </div>
                                            <div className="col-lg-5 text-lg-end mt-sm-15">
                                                <div className="display-flex2">
                                                    <span className="text-sortby">See All</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        {acceptedtCandidates.map((candidate, index) => {
                                            return(
                                            <div key={candidate.id} className="col-lg-3 col-md-6">
                                                <div className="card-grid-2 hover-up">
                                                    {/* <div className="card-grid-2-link">
                                                        <a href='#' onClick={(e) => {
                                                                    removeNetwork(index)
                                                                    }
                                                        }><i className="fi-rr-cross-circle"></i></a>
                                                    </div> */}
                                                    <div className="text-center card-grid-2-image-rd">
                                                        <a href={`/users/profile/${candidate.id}`} className={'new_candidate'}>
                                                            <figure>
                                                                {candidate?.avatar_url ?
                                                                    <img alt="jobhub" className={'img_border'} src={(candidate?.avatar_url != '')?candidate.avatar_url:'assets/imgs/page/candidates/img-candidate.png'} />
                                                                    : <CandidateAvatarIcon firstName={candidate?.first_name} lastName={candidate?.last_name} />
                                                                }
                                                            </figure>
                                                        </a>
                                                    </div>
                                                    <div className="card-block-info">
                                                        <div className="card-profile">
                                                            <a href={`/users/profile/${candidate.id}`}><strong>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</strong></a>
                                                            <span className="text-sm overflowtext" title={candidate?.description}>{(candidate?.description || 'N/A')}</span>
                                                        </div>
                                                        <div className="employers-info d-flex align-items-center justify-content-center mt-15">
                                                            <span className="d-flex align-items-center"><i className="fi-rr-marker mr-5 ml-0"></i>{(candidate?.location || 'N/A')}</span>
                                                        </div>
                                                        <div className="card-2-bottom card-2-bottom-candidate mt-30">

                                                            <div className="text-center mt-25 mb-5">
                                                            
                                                                <a href={`/network_conversations?user=${candidate?.id}`} className="btn btn-border btn-brand-hover rounded-pill">
                                                                    <div className={'d-flex align-items-center'}><i className="fi-rr-envelope"></i> <span className='mt-0 ml-10 fw-bold'>Message</span></div>
                                                                </a>    
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        })
                                        }
                                    </div>
                                
                                </div>
                            ):''}
                        </div>
                        <ManageMyMetworkSideBar my_total_candidates={my_total_candidates} received={received} sent={sent} reject={reject}></ManageMyMetworkSideBar>
                    </div>
                </div>
               
               
            </div>
        </>
    )
}

export default MyNetworkIndex
