import React from 'react'
import Image from 'react-bootstrap/Image'

import ImageOne from '../../../../assets/images/about_us_page_assets/about-us.png'
import {
    Wrapper,
    AboutUsSection,
    AboutUsText,
    P,
    TeamSection,
    Line,
    Description,
    Button,
    LookingForWorkSection,
} from './styles/AboutUsPage.styled'

const AboutUsPage = ({ description }) => {
    return (
        <Wrapper>
            <AboutUsSection className="row">
                <div className="col-md-6">
                    <AboutUsText>
                        <P>
                            From problem solving to innovational thinking, our team
                            strives for the best.
                        </P>
                    </AboutUsText>
                </div>
                <div className="col-md-6">
                    <Image className="img-fluid w-100" src={ImageOne} />
                </div>
            </AboutUsSection>
            <TeamSection>
                <div className="d-flex flex-column align-items-center">
                    <Line />
                    <P size="40px" height="55px" marginTop="39px">
                       About Us
                    </P>
                    <Line marginTop="39px" />
                </div>
            </TeamSection>
            <Description
                weight="normal"
                dangerouslySetInnerHTML={{ __html: description }}
            />
            <LookingForWorkSection>
                <P size="30px" height="41px" style={{ maxWidth: 'unset' }}>
                    Looking for work? Join our team!
                </P>
                {/* <Button
                    onClick={() => (window.location.href = '/welcome/careers')}
                >
                    View Open Positions
                </Button> */}
            </LookingForWorkSection>
        </Wrapper>
    )
}

export default AboutUsPage