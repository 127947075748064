import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal"
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './../styles/communities.scss'
import image2 from '../../../../../assets/v2/imgs/avatar/ava_16.png'
import Paginator from '../../../common/Paginator/Paginator'
import SweetAlert from 'react-bootstrap-sweetalert'
import SettingLeftPenal from '../SettingLeftPenal'

const IndexPage = ({ communityId, currentUser }) => {
  const [show, setShow] = useState(false)
  const [method, setMethod] = useState('post')
  const [entry, setEntry] = useState({})
  const [validationError, setValidationError] = useState({})
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [loading, setLoading] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalCategory, setTotalCategory] = useState(0)
  const [currentCounts, setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [categoryDatas, setCategoryDatas] = useState([])

  const handleChange = (e) => {
    setValidationError({})
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    if (validateForm(e)) {
      let URL = `/communities/${communityId}/category/create`
      if (method === 'put') {
        URL = `/communities/${communityId}/category/${entry.id}`
      }
      const payload = new FormData()
      payload.append('post_category[name]', e.target.name.value)
      payload.append('post_category[description]', e.target.description.value)
      payload.append('post_category[community_id]', communityId)
      //headers
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      const headers = {
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
        },
      }
      makeRequest(URL, method, payload, headers, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
      }).then((res) => {
        if (res.data.success) {
          if (method == 'put') {
            const categoryIndex = categoryDatas.findIndex(category => category.id === entry.id);
            if (categoryIndex !== -1) {
              const updatedCategoryDatas = [...categoryDatas];
              updatedCategoryDatas[categoryIndex] = {
                ...updatedCategoryDatas[categoryIndex],
                name: res.data.category.name,
                description: res.data.category.description,
              };
              setCategoryDatas(updatedCategoryDatas);
            } else {
              console.error('Category not found');
            }
          } else {
            setCategoryDatas(prevCategoryDatas => [
              {
                id: res.data.category.id,
                name: res.data.category.name,
                description: res.data.category.description,
                community_id: res.data.category.community_id,
                total_post: 0,
              },
              ...prevCategoryDatas
            ]);
          }
          onHide()
        }
      })
    }
    setBtnDisabled(false)
  }

  const validateForm = (e) => {
    let errors = {}
    let formIsValid = true
    const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
    if (!e?.target?.name?.value?.trim()) {
      formIsValid = false;
      errors["name"] = "Category Name is required field."
    } else if (!regex.test(e?.target?.name?.value?.trim())) {
      formIsValid = false;
      errors["name"] = "Please Enter Valid Category Name."
    }
    if (!e?.target?.description?.value?.trim()) {
      formIsValid = false;
      errors["description"] = "Description is required field."
    }
    setValidationError(errors)
    setBtnDisabled(formIsValid)
    return formIsValid;
  }

  const onHide = () => {
    setEntry({})
    setMethod('post')
    setShow(false)
  }

  const fetchData = () => {
    setLoading(true)
    const url = '/communities/' + communityId + '/category.json'
    makeRequest(url, 'get', { params: { page: (activePage + 1) } }, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setCategoryDatas(
          res.data.category.map((category) => ({
            id: category.id,
            name: category?.name,
            description: category?.description,
            community_id: category?.community_id,
            total_post: 0,
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalCategory(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setLoading(false)
      },
    })
  }

  const editCategory = (category_id) => {
    const categoryToEdit = categoryDatas.find(category => category.id === category_id);
    setEntry({
      id: categoryToEdit.id || '',
      name: categoryToEdit.name || '',
      description: categoryToEdit.description || '',
    });
    setShow(true)
    setMethod('put')
  }
  const deleteCategory = (category_id) => {
    const categoryToEdit = categoryDatas.find(category => category.id === category_id);
    setEntry({
      id: categoryToEdit.id || '',
      name: categoryToEdit.name || '',
      description: categoryToEdit.description || '',
    });
    setShowRemoveConfirmation(true);
  }

  const handleDelete = (e) => {
    const url = `/communities/${communityId}/category/${entry.id}`
    makeRequest(url, 'delete', '', {
      createSuccessMessage: () => 'Categoty deleted successfully',
      createErrorMessage: (e) => e.response.data.msg,
      onSuccess: () => {
        setShowRemoveConfirmation(false)
        fetchData()
      },
    })
  }

  useEffect(() => {
    fetchData()
  }, [activePage])

  return (
    <>
      <div className="container mt-60 communities-detail">
        <div className="row">
          <SettingLeftPenal communityId={communityId} ></SettingLeftPenal>
          <div className="col-lg-8 p-0 m-0">
            <div className="single-recent-jobs">
              <div className='d-flex justify-content-between'>
                <h4 className="">Categories</h4>
                <button onClick={() => {
                  setEntry({})
                  setMethod('post')
                  setShow(true)
                }} className="btn btn-default mr-15">Add Category</button>
              </div>
              <div className="list-recent-jobs mt-20">
                {categoryDatas && categoryDatas.map((category, index) => {
                  return (<div key={index} className="card-job hover-up wow animate__animated animate__fadeInUp mb-10">
                    <div className="card-job-top">
                      <div className="d-flex justify-content-between">
                        <h6 className="card-job-top--info-heading">{category?.name || 'N/A'}</h6>
                        <div className="">
                          <button href="job-grid-2.html" onClick={() => { editCategory(category?.id) }} className="mb-0 mt-0 btn-small background-blue-light mr-5">EDIT</button>
                          <button href="job-grid.html" onClick={() => { deleteCategory(category?.id) }} className="mb-0 mt-0  btn-small background-urgent btn-pink mr-5">DELETE</button>
                        </div>
                      </div>
                    </div>
                    <div className="card-job-description row">
                      <div className='col-md-9'>
                        {category?.description || 'N/A'}
                      </div>
                    </div>
                  </div>)
                })}
              </div>
              {(pageCount > 1) ?
                <div className="paginations">
                  <Paginator
                    pageCount={pageCount}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    pageWindowSize={10}
                  />
                </div>
                : ''}
            </div>
          </div>
        </div>
      </div>

      <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='modal-entry-form'
      >
        <Modal.Header closeButton className='mt-2 mb-2'>
          <Modal.Title id="contained-modal-title-vcenter" >
            {method === 'put' ? 'Update' : 'Add'} Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form onSubmit={(e) => { handleSubmit(e) }} style={{ width: "100%" }}>
              <Row className="w-100">
                <Col xs={12} sm={12} lg={12}>
                  <span>Category Name* : </span>
                  <input type='text' defaultValue={entry.name} onChange={handleChange} className='form-control w-100' name='name' id="name" />
                  <small className='error'>
                    {validationError && validationError.name}
                  </small>
                </Col>
                <Col xs={12} sm={12} lg={12} className='mt-10'>
                  <span>Description* : </span>
                  <textarea defaultValue={entry.description} onChange={handleChange} className='form-control w-100' name='description' id="description" />
                  <small className='error'>
                    {validationError && validationError.description}
                  </small>
                </Col>
              </Row>
              <Row className="w-100 modal-entry-form-row" >
                <Col xs={12} sm={12} lg={12}>
                  <button type='submit' disabled={btnDisabled} className='btn btn-default btn-radius'>{method === 'put' ? 'Update' : 'Save'}</button>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>

      {
        showRemoveConfirmation &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to delete category?"
          onConfirm={() => (handleDelete())}
          onCancel={() => setShowRemoveConfirmation(false)}
          focusCancelBtn
        >
          You will not be able to recover this category!
        </SweetAlert>
      }
    </>
  );
}
export default IndexPage