import { set } from 'lodash';
import React, { useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { allStages } from '../../../../misc/gconst';
import { makeRequest } from '../../../common/RequestAssist/RequestAssist';
import StageSelector from '../../../common/StageSelector/StageSelector';
import './Styles/movetostage.scss'

const MoveToStage = ({movetostage, setMoveToStage,candidateinterviewid}) => {
const [selectStage, setSelectStage] = useState()
console.log("🚀 ~ file: movetostage.js:11 ~ MoveToStage ~ selectStage", selectStage)
const [dropdownitem, setDropdownitem] = useState()
const [errormessage, setErrorMessage] = useState()
const [checkvalidation, setCheckValidation] = useState(false)
    function menuItemsForStage(activeStage) {
        if(activeStage == 'second_interview'){ 
            return allStages.filter((stageData) => stageData.id !== activeStage).filter((item)=>item.id != 'recruitor_screen').filter((item)=>item.id != 'submitted').filter((item)=>item.id != 'first_interview')
        }
        if(activeStage == 'first_interview'){
            return allStages.filter((stageData) => stageData.id !== activeStage).filter((item)=>item.id != 'recruitor_screen').filter((item)=>item.id != 'submitted')
        }
        else{
            return allStages.filter((stageData) => stageData.id !== activeStage)
        }
    }
    const onMoveToStage = async () => {
        setErrorMessage('please select stage')
        if(checkvalidation){
        const payload = new FormData()
        payload.append('interview_schedule[interview_type]', selectStage)
        payload.append('interview_schedule[id]', candidateinterviewid?.id)
        let url = '/interviewer_move_to_stage.json'
        const res = await makeRequest(url, 'put', payload, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createResponseMessage: (responce) => {
            if(responce?.success){
                setMoveToStage(false)
                setTimeout(() => {
                window.location.href = `/interview_scheduled`                    
                }, 1000);
            }
            
              return {
                  message: responce?.msg
                      ? responce.msg
                      : 'successfully moved',
                  messageType: 'success',
                  loading: false,
                  autoClose: true,
              }
          },
          })
        }
    }

    return(
        <>
          <Modal
                    onHide={() => setMoveToStage(false)}
                    className="move-to-stage-modal"
                    show={movetostage}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header
                    closeButton
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Modal.Title>Candidate Move To</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div class="container">
                            <div class="row">
                                <div class="col-sm">
                                <Dropdown>
                <Dropdown.Toggle className="changeStageDropdown stageRight movetostage-dropdown">
                    {dropdownitem ? dropdownitem : 'Move selected to'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {menuItemsForStage(candidateinterviewid.interview_type).map((itemData,index) => (
                        <Dropdown.Item
                            key={index}
                            onClick={async (e) => {
                                e.preventDefault()
                                setSelectStage(itemData?.id)
                                setDropdownitem(itemData?.label)
                                setCheckValidation(true)
                                setErrorMessage('')
                            }}
                        >
                            {itemData.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
                            
                                </div>
                            </div>
                        </div>
                       <p style={{marginLeft:'83px', color:'red', fontSize:'12px'}}>{errormessage}</p>
                        <div className="d-flex sub-move-to-btn mr-3">
                            <div className="move-tostage-btn-interview">
                                <Button
                                    variant="secondary"
                                    onClick={() => setMoveToStage(false)}
                                >
                                    Close
                                </Button>
                            </div>
                            <div className="move-tostage-btn-interview">
                                <Button
                                    variant="secondary"
                                    onClick={() => onMoveToStage()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
        
                </Modal>
        </>
    )
}
export default MoveToStage;