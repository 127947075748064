import axios from 'axios';

export const getLocation = async (value) => {
  const url = `/filter_candidate_on_location?flag=true`;
  const formData = new FormData();
  formData.append('filter_word', value);
  let locationArray = [];
  try {
    const response = await axios.post(url, formData);
    locationArray = response.data.filter.map(({ state, city, id }) => ({
      value: `${city}, ${state}`,
      label: `${city}, ${state} (US)`,
    }));
  } catch (error) {
    console.log(error);
  }

  return locationArray;
};

export const getCompany = async (value) => {
  const url = `/filter_candidate_on_company`;
  const formData = new FormData();
  formData.append('filter_word', value);
  let companyArray = [];
  try {
    const response = await axios.post(url, formData);
    companyArray = response.data.filter.map((company) => ({
      value: company.company_name,
      label: company.company_name,
    }));
  } catch (error) {
    console.log(error);
  }

  return companyArray;
};

export const getEducation = async (value) => {
  const url = `/filter_candidate_on_education`;
  const formData = new FormData();
  formData.append('filter_word', value);
  let educationArray = [];
  try {
    const response = await axios.post(url, formData);
    educationArray = response.data.filter.map(({ name, id }) => {
      return { value: id, label: name };
    });
  } catch (error) {
    console.log(error);
  }

  return educationArray;
};

export const getSkills = async (value) => {
  const url = 'filter_skills';
  const formData = new FormData();
  formData.append('filter_word', value);
  let skillArray = [];
  try {
    const response = await axios.post(url, formData);
    skillArray = response.data.filter.map(({ skill, id }) => {
      return { value: skill, label: skill };
    });
  } catch (error) {
    console.log(error);
  }

  return skillArray;
};
