import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import { getDueDays } from '../../../../utils'
import TimePicker from 'react-time-picker'
import axios from 'axios'
import Util from "../../../../utils/util";
import AsyncSelect from "react-select/async"

const Add = ({rateData,onHide,method}) => {
    const [validationError, setValidationError] = useState({})
    const [selectedOrganization, setSelectedOrganization]  = useState(rateData?.organization_id ? rateData.organization_id : '')
    const [isLoading, setIsLoading] = useState(false)
    const [member_options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [orgType, setOrgType] = useState((rateData?.commission_type) ? (rateData.commission_type == 'percentage') ? 'hourly' : 'contingency' : '')
    const [responseFile,setResponseFile]= useState('')
    const [attachmentFile,setAttachmentFile]= useState('')
	const [showAttachmentFile, setShowAttachmentFile] = useState((rateData?.fee_agreement)?true:false)
    const attachmentFileRef = useRef()
    const attachmentfileTypes = [ 'application/pdf'
	]

    const handleChange = (e) => {
        setValidationError({})
    }

    const loadOptions = (inputValue, callback) => {
        setIsLoading(true)
        fetch(`/cardinal/commission_rates/fetch_organization?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {organizations} = res
          setIsLoading(false)
          setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(member_options);
    }

    const handleSelectOption = (selectedOptions) => {
        setSelectedOrganization(selectedOptions?.value || '')
        
        fetch(`/cardinal/commission_rates/fetch_type?id=${selectedOptions?.value}`)
        .then((res) => res.json())
        .then((res) => {
            setOrgType((res.organizations.organization_type == null) ? 'hourly' : 'contingency')
        })
        .catch((err) => console.log("Request failed", err));
    }

    const handleInputChange = (str) => {
        setInputValue(str)
        return str;
    }

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'24em',maxWidth:'24em',minHeight:'40px' })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setBtnDisabled(true)
        let errors = {}
        let formIsValid = true
        if(e.target.commission_rate.value < 0 || e.target.commission_rate.value == ''){
            formIsValid = false;
            errors["commission_rate"] = "Commission rate must be greater than zero."
        }
        if(e.target.commission_to_submitter.value < 0 || e.target.commission_to_submitter.value == ''){
            formIsValid = false;
            errors["commission_to_submitter"] = "Commission to submitter value must be greater than zero."
        }
        if(e.target.commission_to_hires.value < 0 || e.target.commission_to_hires.value == ''){
            formIsValid = false;
            errors["commission_to_hires"] = "Commission to hire value must be greater than zero."
        }
        if(e.target.commission_campaign_manager.value < 0 || e.target.commission_campaign_manager.value == ''){
            formIsValid = false;
            errors["commission_campaign_manager"] = "Commission to campaign manager value must be greater than zero."
        }
        if(selectedOrganization == ''){
            formIsValid = false;
            errors["organization"] = "Organization is required field."
        }
        if(orgType === 'hourly' && e.target.commission_rate.value >= 100){
            formIsValid = false;
            errors["common_error_commission"] = "Commission rate must be less than 100"
        }
        if(e.target.commission_to_submitter.value != '' && e.target.commission_to_hires.value != '' && e.target.commission_campaign_manager.value != ''){
            if( (parseFloat(e.target.commission_to_submitter.value) + parseFloat(e.target.commission_to_hires.value) + parseFloat(e.target.commission_campaign_manager.value)) != 100 ){
                formIsValid = false;
                errors["common_error_percentage"] = "Sum of commission to CM, commission to Submitter and commission to hires must be 100."
            }
        }
      
		if(attachmentFileRef?.current?.files?.length > 0){
			if(attachmentFileRef?.current?.files[0]?.size > 5000000){
				formIsValid = false
				errors["attachment_file_url"] = "Agreement File is not allowed more then 5MB."
			}
			if (!attachmentfileTypes.includes(attachmentFileRef?.current?.files[0]?.type)) {
				formIsValid = false
				errors["attachment_file_url"] = "Invalid file format, Please upload pdf file only."
			}
		}
        if(attachmentFileRef.current.files.length == 0 &&  rateData?.fee_agreement == null){
            formIsValid = false
            errors["attachment_file_url"] = "Agreement File is required field."
        }
       
        console.log(errors)
        setValidationError(errors)
        
        if(formIsValid){
            let URL = '/cardinal/commission_rates'
            if(method === 'put'){
                URL = `/cardinal/commission_rates/${rateData.id}`
            }
            
            const payload = new FormData()
            payload.append('commission_rate[organization_id]', selectedOrganization)
            payload.append('commission_rate[commission_rate]', e.target.commission_rate.value)
            payload.append('commission_rate[commission_type]', orgType === 'hourly' ? 'percentage' : 'amount')
            payload.append('commission_rate[commission_to_submitter]',e.target.commission_to_submitter.value)
            payload.append('commission_rate[commission_to_hires]',e.target.commission_to_hires.value)
            payload.append('commission_rate[commission_to_campaign_manager]',e.target.commission_campaign_manager.value)
            if (attachmentFile[0])
				payload.append('commission_rate[agreement]', (attachmentFile[0])?attachmentFile[0]:'')

            //headers
            const CSRF_Token = document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content')
                
            const headers = {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-Token': CSRF_Token,
                },
            }
            makeRequest(URL,method, payload, headers, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
                createResponseMessage: () => {
                    let messsage = 'Save' 
                    return {
                        message: `${messsage} Successfully`,
                        messageType: 'success',
                        loading: false,
                        autoClose: true,
                    }
                },
                createErrorMessage: (e) => 'Failed',
                onSuccess: () => {},
            }).then((res) => {
                if(res.data.type === 'success'){
                    onHide()
                }
            }) 
        }
        setBtnDisabled(false)
    }

	const attachmentFileChange = ()=>{
		if(attachmentFileRef.current.files.length > 0){
			setAttachmentFile(attachmentFileRef.current.files)
			setShowAttachmentFile(false)
		}
	}

    return (
        <>
          <Container> 
           <form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
               <Row className="w-100" style={{ marginBottom: '20px',display: 'flex',alignItems: 'baseline', }}>
                    <Col xs={12} sm={12} lg={6}>
                        { (rateData?.name) ? 
                            <><label> Organization :</label>
                            <br/>
                             <input type='text' className='form-control' defaultValue={rateData.name} disabled='disabled'></input>
                            </>
                       :  <><label>Select Organization*</label>
                       <AsyncSelect
                           isLoading={isLoading}
                           isClearable={true}
                           cacheOptions
                           loadOptions={loadOptions}
                           defaultOptions={selectedOrganization}
                           onInputChange={handleInputChange}
                           onChange={handleSelectOption}
                           placeholder={'Search by organization'}
                           styles={colourStyles}
                           noOptionsMessage={() => 'start typing the name of organization'}
                       />
                       <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                            {validationError && validationError.organization}
                        </label> </> }
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                        <label>Commission Rate { (orgType == 'hourly') ? '(%)' : '($)'} * : </label>
                        <input type='number'  className='form-control w-100' name='commission_rate' id="commission_rate"   defaultValue={rateData?.commission_rate} onChange={handleChange} />
                        <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                            {validationError && validationError.commission_rate}
                        </label>
                    </Col>
                </Row>

                <Row className="w-100 modal-entry-form-row mb-3" >
                    <Col xs={6} sm={6} lg={4}>
                        <label>Commission to CM (%)* : </label>
                        <input type='number' className='form-control' name='commission_campaign_manager' id="commission_campaign_manager" defaultValue={rateData?.commission_to_campaign_manager}  onChange={handleChange}/>
                        <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                            {validationError && validationError.commission_campaign_manager}
                        </label>
                    </Col>
                    <Col xs={6} sm={6} lg={4}>
                        <label>Commission to Submitter (%)* : </label>
                        <input type='number'className='form-control' name='commission_to_submitter' id="commission_to_submitter" defaultValue={rateData?.commission_to_submitter}  onChange={handleChange}/>
                        <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                            {validationError && validationError.commission_to_submitter}
                        </label>
                    </Col>
                    <Col xs={6} sm={6} lg={4}>
                        <label>Commission to Hires (%)* : </label>
                        <input type='number' className='form-control' name='commission_to_hires' id="commission_to_hires" defaultValue={rateData?.commission_to_hires} onChange={handleChange}/>
                        <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                            {validationError && validationError.commission_to_hires}
                        </label>
                    </Col>
                </Row>
                <Row className="w-100 modal-entry-form-row mb-3" >
                    <Col xs={6} sm={6} lg={6}>
                        <label>Fee Agreement (pdf){ (!rateData?.fee_agreement) && '*' }: </label>
                        <input ref={attachmentFileRef} type='file' style={{padding:'3px'}}  onChange={attachmentFileChange} className='form-control w-100' name='agreement' id="agreement"  accept='.pdf'/>
                        <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                            {validationError && validationError.attachment_file_url}
                        </label>
                        
                    </Col>
                    <Col xs={6} sm={6} lg={6} className="pt-4">
                      { rateData?.fee_agreement && <span><a href={rateData?.fee_agreement} target="_blank" >File</a></span> }
                    </Col>
                </Row>  

                { (validationError && (validationError.common_error_commission || validationError.common_error_percentage)) && 
                    <Row className="w-100 modal-entry-form-row mb-3" >
                        <Col xs={6} sm={6} lg={8}>
                            <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                {validationError && validationError.common_error_commission}
                            </label>
                            <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                {validationError && validationError.common_error_percentage}
                            </label>
                        </Col>
                    </Row>
                }

                <Row className="w-100 modal-entry-form-row mb-3"  >
                    <Col xs={12} sm={12} lg={12}  style={{textAlign:'left'}}>
                        <input  type='submit' value={method === 'put' ? 'Update' : 'Save'} className='btn btn-primary btn-md mt-2 btn-radius saveBtn' />
                    </Col>
                </Row>
            </form>
           </Container>
        </>
        
    )
}

export default Add