import styled from 'styled-components'

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: ${(props) => (props.jContent ? props.jContent : 'unset')};
    align-items: ${(props) => (props.aItems ? props.aItems : 'unset')};
    margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
`
export const W5text = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: ${(props) => props.size};
    color: ${(props) => props.color};
    display: flex;
`

export const Button = styled.button`
    background: linear-gradient(
        94.67deg,
        #4c68ff -1.19%,
        #6077f4 53.94%,
        #8185ff 102.59%
    );
    border-radius: 20px;
    padding: ${(props) => props.tb} ${(props) => props.lr};
    width: fit-content;
    display: flex;
    align-items: center;
    color: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
`
export const Message = styled.span`
    font-family: Avenir, 'Lato', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color: ${(props) => props.color};
`
