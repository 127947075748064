import React, { useEffect, useState } from 'react'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from 'react-data-table-component-footer'
import moment from 'moment'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import P from './shared/P'
import Card from './shared/Card'
import { Col, Row, Spinner } from 'react-bootstrap'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import styles from './styles/JobsStatusPage.module.scss'
import { CSVLink } from 'react-csv'
import   './styles/DataTableCustom.scss'
import Select from 'react-select'
import { capitalize } from '../../../utils'

const LeadCandidateReport = () => {
    let lead_start_date = sessionStorage.getItem("lead_can_start_date");
    let lead_end_date = sessionStorage.getItem("lead_can__end_date")
    const [hiredCandidate, setHiredCandidate] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [perPage, setPerPage] = useState(25)
    const [totalCount,setTotalCount] = useState()
    const [dateRange, setDateRange] = useState({startDate: lead_start_date ? lead_start_date : moment().startOf('month').toDate() ,endDate: lead_end_date ? lead_end_date : moment().toDate()})
    const [loading, setLoder] = useState(true)
    const [exportDatas, setExportDatas] = useState([])
    const csvExportRef = React.useRef();
    const [footer, setFooter] = useState({})
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [startRecord, setStartRecord] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [selectedOrganizationType,setSelectedOrganizationType] = useState("")
    const [organizationType,setOrganizationType] = useState([{value: 'all', label: 'All'},{value: 'hourly', label: 'Hourly'},{value: 'contingency', label: 'Contingency'}])
    const [defaultselected,setdefaultselected] = useState({value: 'all', label: 'All'})
    const handleDate = (date) => {
      setDateRange(date)
    }
  

    const fetchData = async () => {
        setLoder(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("lead_can_start_date", start_date)
        sessionStorage.setItem("lead_can__end_date", end_date)
        const url = '/admin/candidate_lead_report.json'
        const response = await makeRequest(
          `${url}?page=${activePage}&start_date=${start_date}&end_date=${end_date}&organization_type=${selectedOrganizationType}&sortField=${sortField}&sortDirection=${sortDirection}`,
          'get',
          {}
        )
        let sr_no = (activePage -1) * perPage
        setPageCount(response.data.total_pages)
        setTotalCount(response.data.total_count)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setHiredCandidate(response.data.candidates.map((candidate) => (sr_no = sr_no + 1,{
            id: sr_no,
            org_name: capitalize(candidate.org_name),
            organization_type: candidate?.organization_type ? capitalize(candidate.organization_type) : '',
            submitted: (candidate.submitted > 0) ? candidate.submitted : '',
            firstInterview: (candidate.first_interview > 0) ? candidate.first_interview : '',
            secondInterview: (candidate.second_interview > 0) ? candidate.second_interview : '',
            offers: (candidate.offer > 0) ? candidate.offer : '',
            hired: (candidate.hired > 0) ? candidate.hired: '',
            confirmed_interview: ( parseInt(candidate.second_interview > 0 ? candidate.second_interview : 0)  + parseInt(candidate.first_interview > 0 ? candidate.first_interview : 0)),
            total_lead: response.data.lead_total[candidate.org_id],
        })))
        setFooter({id:'Total',
            submitted:response.data.total_values.submitted,
            firstInterview:response.data.total_values.firstInterview,
            secondInterview:response.data.total_values.secondInterview,
            confirmed_interview:parseInt(response.data.total_values.firstInterview) + parseInt(response.data.total_values.secondInterview),
            offers:response.data.total_values.offers,
            hired:response.data.total_values.hired
        })
        setLoder(false)
      }
    useEffect(()=>{
        fetchData()
    },[dateRange,activePage,sortDirection,sortField, selectedOrganizationType])
    
    const headers = [
        { label: 'Organization Name', key: 'org_name' },
        { label: 'Organization Type', key: 'organization_type' },
        { label: 'Total Lead', key: 'total_lead' },
        { label: 'Submitted', key: 'submitted' },
        { label: 'Confirmed Interview', key: 'confirmed_interview' },
        { label: 'Offers', key: 'offers' },
        { label: 'Hired', key: 'hired' },
    ]

    const  columns= [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '4%',
            minWidth: '4%',
        },
        {
            name: 'Organization Name',
            selector: row => row.org_name,
            cell: (row) => <div title={row.org_name}>{row.org_name}</div>,
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true,
            sortable: true,
            sortField: 'org_name',
        },
        {
          name: 'Organization Type',
          selector: row => row.organization_type,
          cell: (row) => <div title={row.organization_type}>{row.organization_type}</div>,
          maxWidth: '10%',
          minWidth: '10%',
          wrap:true,
          sortable: false,
          sortField: 'organization_type',
       },
       {
           name: 'Total Lead',
           selector: row => row.total_lead,
           cell: (row) => <div title={row.total_lead}>{row.total_lead}</div>,
           sortable: false
       },
        {
            name: 'Submission',
            selector: row => row.submitted,
            sortable: true,
            sortField: 'submitted'
        },
        {
            name: 'Confirmed Interviews',
            selector: row => (row.confirmed_interview > 0) ? row.confirmed_interview : '',
            sortable: false,
            sortField: 'confirmed_interview'
        },
        {
            name: 'Offers',
            selector: row => row.offers,
            sortable: true,
            sortField: 'offer'
        },
        {
            name: 'Hired',
            selector: row =>   row.hired,
            sortable: true,
            sortField: 'hired'
        }
  ]

  
  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/candidate_lead_report.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&organization_type=${selectedOrganizationType}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.candidates.map((candidate,key)=> ({
        id: key,
        org_name: capitalize(candidate.org_name),
        organization_type: candidate?.organization_type ? capitalize(candidate.organization_type) : '',
        submitted: (candidate.submitted > 0) ? candidate.submitted : '',
        firstInterview: (candidate.first_interview > 0) ? candidate.first_interview : '',
        secondInterview: (candidate.second_interview > 0) ? candidate.second_interview : '',
        confirmed_interview:  parseInt(candidate.second_interview) + parseInt(candidate.first_interview),
        offers: (candidate.offer > 0) ? candidate.offer : '',
        hired: (candidate.hired > 0) ? candidate.hired: '',
        total_lead: response.data.lead_total[candidate.org_id],
    })))
    csvExportRef?.current?.link.click();
  }

  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }
    const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
    };

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
        style={{ marginBottom: '42px' }}
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
          Candidate Lead Report (Client Wise)
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={()=>exportDataMethod()}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={headers}
        filename={"lead_candidate_report(organization).csv"}
        ref={csvExportRef}
      />
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 &&
                <DisplayPagination>Displaying  {(activePage == 0)?1:startRecord+1} - {currentCounts > totalCount ? totalCount : currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
          <div style={{display: 'grid', marginLeft:'10px'}}>
              <label>
                  Organization Type
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={organizationType}
                  onChange={(event)=>{
                      setSelectedOrganizationType(event.value)
                  sessionStorage.setItem("job_status_org", JSON.stringify(event))
                  }
                  }
                  name="organization_type"
                  className={styles.colourStyles}
              />
          </div>
          
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Date Range
            </label>
            <DateRangePicker
                handleOnSubmit={handleDate}
                defaultDate={dateRange}
            />
          </div>
        </Col>
      </Row>
      <DataTable
        title={false}
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={hiredCandidate}
        progressPending={loading}
        progressComponent={ <div className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalCount}
        paginationPerPage={25}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        onSort={handleSort}
        responsive={true}
        sortServer
        onChangePage={page => {
          console.log("page", page)
          setActivePage(page)
        }}
        footer={footer}
    />
    </Card>
  )
}

export default LeadCandidateReport