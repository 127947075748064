import React, { useEffect, useState } from 'react'
import { Modal, Alert, Button } from 'react-bootstrap'
import axios from 'axios'
import styles from './styles/AccountSettingPage.module.scss'
import Input from '../../common/Styled components/Input'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import '../AccountSettingPage/styles/account_setting.css'
import { capitalize } from '../../../utils'
import {
  Row,
  Wrapper,
} from './styles/AccountSettingPage.styled'

const EmailVerificationPage_v2 = (props) => {
  const { currentUser } = props
  const { email_data } = props
  const { is_cm } = props
  const { id, email, first_name, last_name, account_email_verified } = currentUser
  const [userEmail, setUserEmail] = useState(email)
  const [isError, setIsError] = useState(false)
  const [isNote, setIsNote] = useState(false)
  const [agreement,setAgreement] = useState()
  const handleVerification = async (event) => {
    const URL = '/account/email_verification'
    const payload = new FormData()
    payload.append('email_verification[email]', email)
    const result = await axios
      .post(URL, payload)
      .then((response) => {
        window.open(response.data.authorization_uri, '_blank')
      })
      .catch((error) => console.log(error))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if(!userEmail.includes('gmail')){
      return setIsError(true)
    }else{
      handleVerification(event)
    }
  }

  const handleCheck = (e) => {
    if(e.target.checked)
      setAgreement(true)
    else
      setAgreement(false)
  }
  const handleYes = (e) => {
    setIsError(false)
    handleVerification(e)
  };

  const handleClose = () => {
    setIsError(false);
    setIsNote(true);
  }

  const handleCreateServer = async () => {
    const url = '/start/campaign'
    await makeRequest(url, 'get', {}, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
        onSuccess: (res) => {
            if((currentUser.role == 'talent')){
              window.location.href = '/search_employer'
            }else{
              window.location.href = '/bulk_upload'
            }            
        },
    })
}


  return (
    <>
    <section className="section-box" style={{ overflow: 'inherit !important' }}>
            <div className="container bootstrap snippets bootdeys">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="panel panel-default" style={{ marginTop: '60px'}}>
                            <div className="panel-heading" style={{ backgroundColor: '#622fff85' }}>
                                <h4 className="panel-title" style={{ color: 'white' }}>Email Verification</h4>
                            </div>
                            <div className="panel-body" style={{ paddingBottom: '0.5px'}}>
                                 
                                <form onSubmit={(e) => {handleSubmit(e)}}>
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">From Name</label>
                                        <div className="col-sm-6">
                                            <input type="email" name="email"
                                                className="form-control" onChange={event => setUserEmail(event.target.value)}
                                                value={userEmail} style={{ height:'46px'}} />
                                        </div>
                                    </div>
                                
                                   
                                    {isNote &&
                                    <>
                                        <div className="text-danger">Sorry, right now we only support email account that uses Google workspace (G-Suite).</div>
                                    </>
                                    }
                                

                                        { !account_email_verified &&
                                            <>
                                                    <div className="form-group " style={{marginTop: '30px',marginBottom: '0px'}}>
                                                        <div className="col-sm-8 d-flex">
                                                        <input
                                                            type="checkbox"
                                                            name="terms_and_policy"
                                                            id="terms_and_policy"
                                                            onChange={(e)=>handleCheck(e)}
                                                            style={{width: '20px',height:'20px'}}
                                                            checked={agreement}
                                                        />
                                                            <span className="ml-2">I agree to the <a href="/terms_of_service">Terms and Conditions</a> & <a href="/privacy_policy">Privacy Policy</a>.</span>
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <Row style={{ marginBottom:'0px'}}>
                                                        <button className="btn btn-default"  disabled={!agreement} style={{marginTop: '0px'}}>Verify Me</button>
                                                    </Row>
                                            </>
                                        }
                                    <Row>
                                </Row>
                                </form>
                           
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                {/* <div className='row'>
                    { ( (account_email_verified == true) && (email_data != null && email_data.refresh_token != null &&  email_data.refresh_token != '' )  && ((currentUser?.email_campaign_api_token == null) || (currentUser?.email_campaign_server_id == null))) &&
                        <div className='row' style={{ marginTop: '30px'}}>
                            <button
                                type="button"
                                className="btn btn-default"
                                    onClick={handleCreateServer}
                                >
                                    Start Email Campaign
                            </button>
                        </div>
                    }
                </div> */}
                <div className='row'>
                <Alert variant="danger">
                    <p>
                    Note:<br />
                    Currently, we ONLY support those email accounts that uses Google workspace (G-Suite).<br />
                    If you are not using your Google workspace (G-Suite), you will not be able to use automated E-Mail Sequencing functionality correctly.<br />
                    If you would like to send email from your Gmail account, then please update your email address. We will link your Gmail account with your CardinalTalent account so that, you can contact candidate directly from CardinalTalent platform.
                    </p>
                </Alert>
                </div>
                </div>
            </div>
    </section>
     <Modal
     show={isError}
     centered
     >
     <Modal.Body style={{ paddingLeft: '10px' }}>Are you using Google workspace (G-Suite) for your email account?</Modal.Body>
     <Modal.Footer xs={12} style={{ justifyContent: 'center' }}>
         <Button variant="primary" onClick={(e) => {handleYes(e)}}>
         Yes
         </Button>
         <Button variant="secondary" onClick={handleClose}>
         No
         </Button>
     </Modal.Footer>
     </Modal>
     </>
  )
}

export default EmailVerificationPage_v2