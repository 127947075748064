import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import ProfileAvatarEmployer from '../../common/ProfileAvatar/ProfileAvatarEmployer'
import RecruiterOrganization from '../../pages/RecruiterOrganization/RecruiterOrganization'
import styles from './styles/Navbar.module.scss'
import styleNew from './styles/NavbarEmployer.scss'
import LogoWithoutText from '../../../../assets/images/Cardinal.png'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Signup from './Signup/Signup'
import { getOrganizationFavorite } from '../../api/recruiter.api'
import feather from 'feather-icons'
import Util from '../../../utils/util'
import { Button, Modal, Row } from 'react-bootstrap'

const NavbarEmployer = ({
    isAdmin,
    isAgency,
    isAgencyAdmin,
    isSales,
    canPeopleSearch,
    canPeopleIntake,
    canJobView,
    userId,
    isTalent,
    isEmployer,
    isRecruiter,
    avatar_url,
    organization,
    currentUser,
    memberOrganization,
    url,
    isTeamOwner,
    isFinancer,
    base_url,
    isTextMessenger,
    host_url
}) => {
    const currentPathname = window.location.pathname
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    const [recruiterOrganizations, setRecruiterOrganizations] = useState([])
    const [recruiterOrganizationFavs, setRecruiterOrganizationFavs] = useState([])
    const [teamOwner, setTeamOwner] = useState([])
    const userBelongsToCT = organization?.name === window.ch_const.ct_org_name
    const userFromAgency = (organization?.agency_id != 0) ? true : false
    const has_commission_recruiter = (currentUser?.is_commission_recruiter == 'yes') ? true : false
    const subOrgBelongsToCT =
        memberOrganization?.name === window.ch_const.ct_org_name
    const fetchOrganization = async () => {
        try {
            const response = await getOrganizationFavorite();
            setRecruiterOrganizations(response);    
        } catch (error) {
            console.log(error);
        }
    };
    const [isInterviewCreatedBefore, setIsInterviewCreatedBefore] = useState(false)

    useEffect(() => {
        if (isRecruiter) {
            fetchOrganization();
        }
    }, []);


    const handleChangeOrganization = async (organization_id) => {
        const url = '/change_recruiter_organization'

        const payload = {
            organization_id: organization_id,
            user_id: userId,
        }

        const response = await makeRequest(url, 'put', payload, {
            contentType: 'application/json',
            loadingMessage: 'changing organization...',
            createResponseMessage: (response) => {
                return response.message
            },
            onSuccess: () => {
                setTimeout(() => {
                    window.location.href = '/jobs'
                }, 1200)
            },
        })
        // window.location.reload(true)
    }

    const onAiInterviewClick = async() => { 
        const url = '/ai_interviews/check_ai_interview_exist'
        await makeRequest(url, 'get', {}, {
            contentType: 'application/json',
            loadingMessage: '...',
            createResponseMessage: (response) => {
                return response.message
            },
            onSuccess: (responce) => {
                // setTimeout(() => {
                //     window.location.href = '/jobs'
                // }, 1200)
                console.log(responce.data)
                if(responce.data.isAiInterview){
                    window.location.href = '/ai_interviews/create'
                }else{
                    setIsInterviewCreatedBefore(true)
                }
            },
        })
    }

    const onCreatInterviewClick = () => {
        window.location.href = '/ai_interviews/create?show=1'
    }
    
    const authLinks = (
        <>
            
            {(isEmployer) && (
                <Nav.Link
                    
                    className={`${styles.navbarLink} ${currentPathname === 'ai_interviews/create' ||
                            currentPathname === '/ai_interviews/create' || currentPathname.includes('ai_interviews') == true
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                    onClick={()=>onAiInterviewClick()}
                >
                    AI Interviewer
                </Nav.Link>
            )}

            {(isEmployer) && (
                <Nav.Link
                    href="/jobs"
                    className={`${styles.navbarLink} ${currentPathname === '/jobs' ||
                            currentPathname === '/jobs/new'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Jobs Posted
                </Nav.Link>
            )}

            {(isEmployer) && canPeopleSearch && !has_commission_recruiter && (
                <Nav.Link
                    href="/people_searches/new"
                    className={`${styles.navbarLink} ${currentPathname === '/people_searches/new'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Candidates
                </Nav.Link>
            )}
              {/* {(isEmployer) && canPeopleSearch && (
                <Nav.Link
                    href="/people_searches/new/?page=free"
                    className={`${styles.navbarLink} ${pageName === 'free'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Free Candidate Search
                </Nav.Link>
            )}
         */}
             {(isEmployer) && (
                <Nav.Link
                    href="/interview_scheduled"
                    className={`${styles.navbarLink} ${currentPathname === '/interview_scheduled'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Interviews Scheduled
                </Nav.Link>
            )}

           
            {(isEmployer) && (
                <>
                    <Nav.Link
                        href="/organizations"
                        className={`${styles.navbarLink} ${currentPathname === '/organizations'
                                ? 'activeLinkColor'
                                : 'linkMenu'
                            }`}
                    >
                        Organization
                    </Nav.Link>
                </>
            )}
        </>
    )
    
    
    return (
        <>
            <Navbar expand="lg" sticky="top" style={{zIndex: 1040 }}>
                <Navbar.Brand href="/jobs">
                    <img
                        alt=""
                        src={LogoWithoutText}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <span>CardinalTalent</span>{' '}
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link href="https://cardinaltalent.ai" className='activeLinkColorTop' style={{color: '#000'}}>Employers</Nav.Link>
                        <Nav.Link href="/talent_home">Talent</Nav.Link>
                        <Nav.Link href="/welcome/recruiter">Recruiters</Nav.Link>
                        <Nav.Link href="https://app.cardinaltalent.ai/communities">Communities</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                     <ProfileAvatarEmployer
                        profileAvatar={avatar_url}
                        userId={userId}
                        organization_id={organization ? organization.id : ''}
                        isEmployer={isEmployer}
                        currentUser={currentUser}
                        userBelongsToCT={userBelongsToCT}
                        subOrgBelongsToCT={subOrgBelongsToCT}
                        isTalent={isTalent}
                    />
            </Navbar>

            <Navbar expand="lg" variant="dark" className="text-white dark-blue-nav" sticky="top">
                

                <Navbar.Collapse id="basic-navbar-nav-second" className="justify-content-end">
                    <Nav>
                        {userId && isEmployer && authLinks}
                        <NavDropdown title="Analytics" id="basic-nav-dropdown" style={{marginLeft: '20px'}}>
                            <NavDropdown.Item  href="/analytic/recruiter_jobs_analytics"
                                                className={`navbarLinkCust ${currentPathname === '/analytic/recruiter_jobs_analytics'
                                                ? 'activeLinkColor'
                                                : 'linkMenu'
                                                } linkMenuProfile`}
                            >Jobs</NavDropdown.Item>
                            <NavDropdown.Item href="/analytic/submitted_candidates_analytic"
                                className={`navbarLinkCust ${currentPathname === '/analytic/submitted_candidates_analytic'
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    } linkMenuProfile`}
                            >
                                Submitted Candidate</NavDropdown.Item>
                            <NavDropdown.Item  href="/analytic/incoming_mail_dashboard"
                                className={`navbarLinkCust ${currentPathname === '/analytic/incoming_mail_dashboard'
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    } linkMenuProfile`}
                            >
                                Incoming Mail Dashboard</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Tasks" id="basic-nav-dropdown-task" style={{marginLeft: '20px'}}>
                            <NavDropdown.Item  href="/job_leads"
                                                className={`navbarLinkCust ${(currentPathname == '/job_leads' || currentPathname.includes('job_leads') == true)
                                                    ? 'activeLinkColor'
                                                    : 'linkMenu'
                                                } linkMenuProfile`}
                            >Review Leads</NavDropdown.Item>
                            <NavDropdown.Item href="/job_process_leads"
                                className={`navbarLinkCust ${(currentPathname === '/job_process_leads'  || currentPathname.includes('job_process_leads') == true)
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    } linkMenuProfile`}
                            >
                                Process Leads</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Modal
                show={isInterviewCreatedBefore}
                onHide={() => setIsInterviewCreatedBefore(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                id="custom-dialog-popup-to-header"
            >
            <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-between">
                            <h5 className="font-weight-bold mb-3">AI Interview</h5>
                            <span onClick={() => setIsInterviewCreatedBefore(false)} role="button">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '30px', height: '30px' }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </span>
                        </div>
                        <Row className='d-flex' style={{padding: '4%'}}>
                            <p>It looks like there are no AI interviews currently created.</p>
                            <p>Would you like to create a new AI interview now?</p>
                            <p>Click the button below to set up a new AI interview for your candidates</p>
                        </Row>
                                
                        <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                            <Button variant="outline-primary" className="mr-3" onClick={() => setIsInterviewCreatedBefore(false) }>Cancel</Button>
                            <Button variant="primary" onClick={() => onCreatInterviewClick()} className='btn-pk-color'>Create AI Interview</Button>
                        </div>
                    </div>           
            </Modal.Body>
            </Modal>
        </>
    )
}

export default NavbarEmployer
