import React, { useState, useEffect } from 'react'
import Card from './shared/Card'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import P from './shared/P'
import SearchBar from '../../common/SearchBar/SearchBar'
import moment from 'moment'
import {firstCharacterCapital} from '../../../utils/index'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import { Row,Col,Spinner,Modal } from 'react-bootstrap'
import feather from 'feather-icons'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import   './styles/AutoCandidateSubmission.scss'
import Select from 'react-select'
// import {Button} from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import linkedinIcon  from '../../../../assets/images/icon-linkedin.png'
import Button from './shared/Button'
import { Formik } from 'formik'
import SearchTextBox from './shared/SearchTextBox'
import Collapsible from 'react-collapsible';


const filterOptions = [
    { value: 'received', label: 'Waiting to Reply' },
    { value: 'send', label: 'Waiting for a Response' },
]

const AutoCandidateScreeningSubmission = ({per_page,chatResponse}) => {
    var active_page = window.sessionStorage.getItem('user_activePage')
    var query = window.sessionStorage.getItem('user_query')
    var phone_query = window.sessionStorage.getItem('user_query_phone')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [totalUsers, setTotalusers] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [users, setUsers] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [searchPhone, setSearchPhone] = useState(phone_query || '')
    const [selected,setSelected] = useState({value: 'all', label: 'All'})
    const [loading, setLoading] = useState(true)
    const [loadingMessage, setLoadingMessage] = useState(false)
    const [sortField, setSortField] = useState('msg_id')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(per_page || 25)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [submissionId, setSubmissionId] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [errorMessageValidation, setErrorMessageValidation] = useState('')
    const [messageValue, setMessageValue] = useState('')
    const [historyShow, setHistoryShow] = useState(false)
    const [messages, setMessages] = useState([])
    const [loadingModal, setLoadingModal] = useState(false)
    const [showSendConfirmation, setShowSendConfirmation] = useState(false)
    const [showProcessConfirmation, setShowProcessConfirmation] = useState(false)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)

    const [processMessage, setProcessMessage] = useState('Are you sure want to make process manual?')
    const [validationError, setValidationError] = useState({})
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [editUserData, setEditUserData] = useState([])
    
    const [showEditConfirmation, setShowEditConfirmation] = useState(false)
    const [selectedFilter,setSelectedFilter] = useState({value: 'received', label: 'Waiting to Reply'})
    const [type, setType] =  useState('received')
    const [msgType, setMsgTypeValue] =  useState('')
    const [defaultMsgSeq, setDefaultMsgSeq] = useState()
    const [selectedMsgSequnce, setSelectedMsgSequnce] = useState('1')
    const [msgSeq, sermsgSeq] = useState({ value: 1, label: 'Default - Text'})

    useEffect(() => {
        feather.replace()
    })
   
    const fetchData = async () => {
        setLoading(true)
        window.sessionStorage.setItem('user_activePage',activePage)
        const url = '/admin/candidate_text_campaign.json'
        const response = await makeRequest(
            `${url}?page=${activePage}&search=${searchTerm}&searchPhone=${searchPhone}&type=${type}&sortField=${sortField}&sortDirection=${sortDirection}&msg_sequence=${selectedMsgSequnce}`,
            'get',
            {}
        )
        setTotalusers(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        
        setUsers(
            response.data.data.map((user,index) => (sr_no = sr_no + 1,{
                sr_no: sr_no,
                id: user.id,
                name: firstCharacterCapital(user.name),
                email: user.email+ ' | ' +user.phone_number,
                phone_number: user.phone_number,
                // jobTitle: user.title,
                jobTitle: (user.job_id == null) ?  user.job_name                : 
                    (<div className='w-100 d-inline-flex justify-content-around'>
                        <a href={`/admin/jobs/${user.job_id}/edit`} className='pl-1' target='_blank'>{user.job_name}</a>
                    </div>),
                initial_msg: (user.received_date != null) ?  moment(user.received_date).format('MMM DD, YYYY  hh:mm A') : '',
                created_at: (user.created_at != null) ?  moment(user.created_at).format('MMM DD, YYYY  hh:mm A') : '',
                received_date: user.received_date,
                actions: (<div className='w-100 d-inline-flex justify-content-around'>
                            {/* <a onClick={() =>showEdit(user)} href={`javascript::void()`} className='pl-1 pr-2'><i data-feather="edit-2" /></a> */}
                            <a style={{color: type == 'received'? 'red': '#007bff'}} onClick={() => handleShowChat(user.id)}  href={`javascript::void()`} title='View Messages' className='pl-1'><i data-feather="message-circle" /></a>
                           
                        </div>)
            }))
        )
       
        setCurrentCounts(response.data.current_counts)
        setStartRecord((activePage - 1) * response.data.per_page)
        setLoading(false)
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#F6F7FC',marginLeft:'10px',border: 'none',height:'40px' })
    };

    useEffect(() => {
        fetchData(),
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,type,selected,sortDirection,sortField, selectedMsgSequnce])

    const createLinkedinUrl = (linkedin_url) => {
        if (linkedin_url != '') {
          let checkDomainExist = linkedin_url.indexOf("linkedin.com/")
          let splitValue = linkedin_url.split('linkedin.com/')
          if (checkDomainExist == 0) {
            if (splitValue[1] !== '') {
              return 'https://www.' + linkedin_url
            }
          } else if (checkDomainExist > 0) {
            if (splitValue[0].includes('https://') || splitValue[0].includes('http://')) {
              return linkedin_url
            } else {
              return 'https://' + linkedin_url
            }
          }
        } else {
          return ''
        }
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('user_query',value)
    }

    const onChangeSearchPhone = (value) => {
        setSearchPhone(value)
        window.sessionStorage.setItem('user_query_phone',value)
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const onProcessUpdate = async() => {
        setErrorMessage('')
        let id = submissionId
        if(id != ''){
            const url = `/admin/update_auto_process`
                try {
                    const  data  = await makeRequest(url, 'post', {auto_submission_id: id}, {
                        contentType: 'application/json',
                        loadingMessage: 'Applying...',
                        createResponseMessage: (response) => {
                            return {
                                message:response.message,
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        createErrorMessage:(error)=>error.response.data.error
                    }).then((res)=>{
                    if(res.data.type == 'success'){
                            setSubmissionId('')
                            setShowProcessConfirmation(false)
                            fetchData()
                    }
                    })
                    
                } catch (error) {
                    console.log(error)
                }
        }else{
            setErrorMessage('Message is reuired field')
        }
    }

    const onStopProcess = async() => {
        setErrorMessage('')
        let id = submissionId
        if(id != ''){
            const url = `/admin/stop_candidate_process`
                try {
                    const  data  = await makeRequest(url, 'post', {auto_submission_id: id}, {
                        contentType: 'application/json',
                        loadingMessage: 'Applying...',
                        createResponseMessage: (response) => {
                            return {
                                message:response.message,
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        createErrorMessage:(error)=>error.response.data.error
                    }).then((res)=>{
                    if(res.data.type == 'success'){
                            setSubmissionId('')
                            setShowRemoveConfirmation(false)
                            fetchData()
                    }
                    })
                    
                } catch (error) {
                    console.log(error)
                }
        }else{
            setErrorMessage('Message is reuired field')
        }
    }
    
    const onUpdateCandidate = async(e) => {
		e.preventDefault()
		setBtnDisabled(true)
        if(validateForm(e)){
			const payload = new FormData()
            let id = submissionId
			payload.append('candidate[auto_submission_id]', id)
            payload.append('candidate[first_name]', e.target.first_name.value)
			payload.append('candidate[last_name]', e.target.last_name.value)
			payload.append('candidate[email]', e.target.email.value)
			payload.append('candidate[phone_number]', e.target.phone_number.value)
			
            const url = `/admin/update_auto_candidate`
            try {
                const  data  = await makeRequest(url, 'post', payload , {
                    contentType: 'application/json',
                    loadingMessage: 'Applying...',
                    createResponseMessage: (response) => {
                        return {
                            message:response.message,
                            messageType: 'success',
                            loading: false,
                            autoClose: true,
                        }
                    },
                    createErrorMessage:(error)=>error.response.data.error
                }).then((res)=>{
                if(res.data.type == 'success'){
                        setSubmissionId('')
                        setShowEditConfirmation(false)
                        setBtnDisabled(false)
                        fetchData()
                }
                })
                
            } catch (error) {
                console.log(error)
            } 
		}else{
            console.log('some errors')
        }
		setBtnDisabled(false)
    }

    const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		if(!e?.target?.first_name?.value?.trim()){
				formIsValid = false;
				errors["first_name"] = "First Name is required field."
		}else if(!regex.test(e?.target?.first_name?.value?.trim())){
			formIsValid = false;
			errors["first_name"] = "Please Enter Valid First Name."
		}
		if(!e?.target?.last_name?.value?.trim()){
            formIsValid = false;
            errors["last_name"] = "Last Name is required field."
        }else if(!regex.test(e?.target?.last_name?.value?.trim())){
            formIsValid = false;
            errors["last_name"] = "Please Enter Valid Last Name."
        }
        if(!e?.target?.email?.value?.trim()){
            formIsValid = false;
            errors["email"] = "Email is required field."
        }
        if(!e?.target?.phone_number?.value?.trim()){
            formIsValid = false;
            errors["phone_number"] = "Phone Number is required field."
        }
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

    const getMessages = async(id) => {
        setErrorMessageValidation('')
        if(id != ''){
            setSubmissionId(id)
            setLoadingMessage(true)
            const url = `/admin/get_campaign_messages?submission_id=${id}`
                try {
                    const  data  = await makeRequest(url, 'get', {}).then((res)=>{
                        if(res.data.type == 'success'){ 
                            setMessages(res.data.data) 
                            console.log(res.data.data)
                        }
                        setLoadingMessage(false)
                    })
                    
                } catch (error) {
                    console.log(error)
                }
        }
    }


    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '4%',
            minWidth: '4%',
        },
        {
            name: 'Candidate Name',
            selector: row => row.name,
            cell: (row) => <div title={row.name}>{row.name}</div>,
            // maxWidth: '10%',
            // minWidth: '10%',
            wrap:true,
            sortable: true,
            sortField: 'candidate_message_campaigns.name',
        },
        {
            name: 'Candidate Email | Phone',
            selector: row => row.phone_number,
            cell: (row) => <div title={row.phone_number}>{row.phone_number}</div>,
            // maxWidth: '25%',
            // minWidth: '25%',
            wrap:true,
            sortable: true,
            sortField: 'candidate_message_campaigns.phone_number',
        },
        {
            name: 'Job Title',
            selector: row => row.jobTitle,
            // maxWidth: '13%',
            // minWidth: '13%',
            wrap:true,
            sortable: true,
            sortField: 'jobs.name',
        },
        {
            name: 'Last Message',
            selector: row => row.initial_msg,
            cell: (row) => <div title={row.initial_msg}>{row.initial_msg}</div>,
            // maxWidth: '12%',
            // minWidth: '12%',
            wrap:true,
            sortable: true,
            sortField: 'msg_id',
        },
        {
            name: 'Action',
            selector: row => row.actions,
            maxWidth: '16%',
            minWidth: '16%',
        }
    ]

    const handleMessagesModal = () => {
        setSubmissionId('')
        setMessageValue('')
        setMessages([])
        setHistoryShow(false)
    }

    const handleShowChat = (id) => {
        getMessages(id)
        setHistoryShow(true)
    }

    const onMessageChange = (val) => {
        setMessageValue(val)
    }

    const onMessageType = (val) => {
        setMsgTypeValue(val)
    }

    const onSendMessage = async() => {
        setErrorMessageValidation('')
        if(messageValue != '' && submissionId != ''){
            const url = `/admin/send_msg_campaign`
                try {
                    const  data  = await makeRequest(url, 'post', {msg: messageValue, msg_type: msgType,submission_id: submissionId}, {
                        contentType: 'application/json',
                        loadingMessage: 'Applying...',
                        createResponseMessage: (response) => {
                            return {
                                message:response.message,
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        createErrorMessage:(error)=>error.response.data.error
                    }).then((res)=>{
                        if(res.data.type == 'success'){
                                setMessageValue('')
                                getMessages(submissionId)
                        }
                    })
                    
                } catch (error) {
                    console.log(error)
                }
        }else{
            setErrorMessageValidation('Message is reuired field')
        }
    }

    const onWaitingFor = async(type) => {
        setErrorMessageValidation('')
        if(submissionId != ''){
            const url = `/admin/update_waiting_for`
                try {
                    const  data  = await makeRequest(url, 'post', {waiting_for: type, auto_submission_id: submissionId}, {
                        contentType: 'application/json',
                        loadingMessage: 'Applying...',
                        createResponseMessage: (response) => {
                            return {
                                message:response.message,
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        createSuccessMessage: (response) => response.data.message,
                        onSuccess: (res) => {
                            console.log(res)
                            fetchData()
                            handleMessagesModal();
                        },
                    })    
                } catch (error) {
                    console.log(error)
                }
        }else{
            setErrorMessageValidation('Message is reuired field')
        }
    }

    const showConfirmSend = (id) => {
        setSubmissionId(id)
        setShowSendConfirmation(true)
    }

    const showEdit = (data) => {
        setSubmissionId(data.id)
        setShowEditConfirmation(true)
        setEditUserData(
            {
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                phone_number: data.phone_number,
                
            }
        )
    }

    const handleChange = (e) => {
		setValidationError({})
	}


    const showConfirmRemove = (id) => {
        setSubmissionId(id)
        setShowRemoveConfirmation(true)
    }

    const setTextMessage = (text) => {
        setMessageValue(text)
    }

    const conditionalRowStyles = [
        {
          when: row => row.received_date != null,
          style: {
            backgroundColor: 'rgb(189 191 201)',
          }
        }
    ]

    const handleSelectFilter = (selectedval) =>{
        setActivePage(1)
        setType(selectedval.value)
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Text Message Campaigns
                </P>
            </div>
            <div
                className="d-flex  align-items-center w-100 filter_ui_fix"
                style={{ marginBottom: '10px' }}
            >
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    {totalUsers > 0 &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalUsers ? totalUsers : currentCounts } of {totalUsers}</DisplayPagination>
                    }
                </Col>
                
                <Col xs={12} sm={12} lg={3} style={{float:'left'}}>
                    <Select
                        defaultValue={selectedFilter}
                        options={filterOptions}
                        onChange={handleSelectFilter} 
                        placeholder={'Select Status'} 
                        styles={colourStyles}
                    />
                </Col>
                <Col xs={12} sm={12} lg={3} style={{float:'left'}}>
                    <Formik>
                        <SearchTextBox width='100%' type="search" value={searchPhone} name="jobsearch" placeholder="Search Phone..."  onChange={(e) => onChangeSearchPhone(e.target.value)}  />
                    </Formik>
                </Col>
                <Col xs={12} sm={12} lg={3} style={{float:'left'}}>
                    <SearchBar
                        placeholder="Search candidate..."
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 1 ? fetchData() : setActivePage(1)
                            !searchTerm && window.sessionStorage.setItem('user_query','')
                        }}
                        onClick={(e) => setSearchTerm('')}
                    />
                </Col>
               
            </div>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
               <Col  lg="12" md="12" sm="12" xs="12">
                    <DataTable
                        title={false}
                        persistTableHead={true}
                        noDataComponent={<p className="text-center p-3">No record found</p>}
                        columns={columns}
                        data={users}
                        progressPending={loading}
                        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                            <Spinner className='spinner' animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </P>}
                        onSort={handleSort}
                        sortServer
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationServerOptions={{persistSelectedOnSort: true, persistSelectedOnPageChange: true}}
                        paginationTotalRows={totalUsers}
                        paginationPerPage={perPage}
                        paginationComponentOptions={{
                            noRowsPerPage: true
                        }}
                        responsive={true}
                        // conditionalRowStyles= {conditionalRowStyles}
                        onChangePage={page => setActivePage(page)}
                        clearSelectedRows={toggledClearRows}
                    />
                </Col>
            </Row>

            <Modal
                show={historyShow}
                size="lg"
                dismissible
                onHide={handleMessagesModal}
                className="ats-border messageModel"
                backdrop="static"
                centered
                keyboard={true}
                onEscapeKeyDown={handleMessagesModal}
                >
                    <Modal.Header style={{minHeight: 'min-height: 92px'}} closeButton><Modal.Title>Candidate Messages History</Modal.Title></Modal.Header>
                    <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 modal-messages-scrollable">
                                
                                { messages && messages?.length > 0 && 
                                    <>
                                        <div >
                                        {   messages.map((msg) => {
                                                return (
                                                    <>
                                                        {msg.msg_type == 'send' &&
                                                            <div className="message-body mr-33">
                                                                <p>{msg.msg}</p>
                                                                <span className="time-right">{moment(msg.created_at).format('llll')}</span>
                                                            </div>
                                                        }

                                                        {msg.msg_type != 'send' &&
                                                            <div className="message-body darker ml-33">
                                                                <p>{msg.msg}</p>
                                                                <span className="time-right">{moment(msg.created_at).format('llll')}</span>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                        </div>
                                    
                                    </>
                                }
                                { loadingMessage == true && messages?.length == 0 &&
                                    <div className='loader-msg'>
                                        <Spinner className='spinner' animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                }
                            </div>
                            {/* <div className="col-md-5 modal-messages-scrollable">
                                <h4 className="text-center response-title">Responses</h4>
                                { messages && messages?.length > 0 &&
                                    <div className="all_response">
                                    {   chatResponse.map((msg) => {
                                            return (
                                                <div className="card" key={msg.id}>
                                                    <div className="card-body">
                                                        <Collapsible trigger={'Question: '+msg.que} transitionTime={300}>
                                                            <p className="card-text responses_list mt-2" onClick={ () => setTextMessage(msg.ans) }><b>Response:</b> {msg.ans}</p>
                                                        </Collapsible>
                                                    </div>
                                                </div>
                                            )    
                                        })
                                    }
                                    </div>
                                }
                            </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <div className='footer-custom container pr-3 pl-3'>
                        <Row className="mb-2">
                            <span>Message :</span>
                        </Row>
                        <Row className="mb-2">
                            <textarea
                                className="flex-grow-1"
                                placeholder='Enter message here'
                                rows="3"
                                name="msg"
                                onChange={(e)=> onMessageChange(e.target.value)}
                                value={messageValue}
                            ></textarea>
                        </Row>
                        {errorMessageValidation && errorMessageValidation != '' &&
                            <Row className="">
                                <span style={{color:'red'}}>{errorMessageValidation}</span>
                            </Row>
                        }
                    </div>
                    <Button variant="primary" onClick={ () => onSendMessage() }>
                        <i data-feather="send" /> <b>Send</b> 
                    </Button>          
                </Modal.Footer>
            </Modal>

            
            {showProcessConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, Make it!"
                    confirmBtnBsStyle="danger"
                    title={processMessage}
                    onConfirm={()=>(onProcessUpdate(),setShowProcessConfirmation(false))}
                    onCancel={()=>setShowProcessConfirmation(false)}
                    focusCancelBtn
                    >
                </SweetAlert>
            }

            {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, Stop it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure want to stop process for selected candidate?"
                    onConfirm={()=>(onStopProcess(),setShowRemoveConfirmation(false))}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    >
                </SweetAlert>
            }

    
            <Modal
                onHide={() => (setShowEditConfirmation(false),setBtnDisabled(false))}
                show={showEditConfirmation}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                dialogClassName="modal-60w"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="mx-auto text-center">
                        Update Candidate Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <form onSubmit={(e) => {onUpdateCandidate(e)}} style={{width:"100%"}}>
                            <Row className="w-100">
                                <Col xs={12} sm={12} lg={12}>
                                    <span>First Name* : </span>
                                    <input type='text' defaultValue={editUserData.first_name} onChange={handleChange} className='form-control w-100' name='first_name' id="fname" />
                                    <label className='error'>
                                            {validationError && validationError.first_name}        
                                    </label>
                                </Col>
                                <Col xs={12} sm={12} lg={12}>
                                    <span>Last Name* : </span>
                                    <input type='text' defaultValue={editUserData.last_name} onChange={handleChange} className='form-control w-100' name='last_name' id="lname" />
                                    <label className='error'>
                                            {validationError && validationError.last_name}        
                                    </label>
                                </Col>
                                <Col xs={12} sm={12} lg={12}>
                                    <span>Email* : </span>
                                    <input type='email' defaultValue={editUserData.email} onChange={handleChange} className='form-control w-100' name='email' id="email" />
                                    <label classsName='error'>
                                            {validationError && validationError.email}        
                                    </label>
                                </Col>
                                <Col xs={12} sm={12} lg={12}>
                                    <span>Phone Number* : </span>
                                    <input type='text' defaultValue={editUserData.phone_number} onChange={handleChange} className='form-control w-100' name='phone_number' id="phone" />
                                    <label classsName='error'>
                                            {validationError && validationError.phone_number}        
                                    </label>
                                </Col>
                            </Row>
                            <br/>
                            <Row className="w-100 modal-entry-form-row" >
                                <Col xs={12} sm={12} lg={12}>
                                    <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Save</Button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    
                </Modal.Footer>
            </Modal>

        </Card>
    )
}

export default AutoCandidateScreeningSubmission