import React, { useState, useEffect } from 'react'
import { Card, Col, Image, Row, ProgressBar } from 'react-bootstrap'
import Table from '../Admin/shared/Table'
import ImageOne from '../../../../assets/images/recruiter_page_assets/buy.jpg'
import RightArrow from '../../../../assets/images/recruiter_page_assets/arrow-right-long.svg'
import './styles/Subscription.scss'
import {
  Wrapper
} from './styles/Subscription.styled'
import {
  H1,
  P,
  Button,
  CONTAINER,
  GRID,
  COL,
  A,
} from './styles/Subscription.styled'
import moment from 'moment'
const InvoicePage = (props) => {
  const { invoice_list } = props
  const [loading, setLoading] = useState(false)
  console.log(invoice_list)
  return (
    <>
      <div className='subscriptionPage'>
        <Wrapper>
          <div className='sidebar'>
            <div className='sidebar_header'>
              <p>Subscriptions & Billing</p>
            </div>
            <a href="/subscription">
              Subscriptions
            </a>
            <a className='active' href="/subscription/invoice">Invoices</a>
            <a href="/subscription/billing">Billing Information</a>
          </div>

          <div className='containt'>
            <Row>
              <Col><h3>Invoices</h3>
              <Table
                columNames={[
                  {
                    name: 'Invoice No.',
                    field: 'number',
                    editable: false,
                    type: 'text',
                  },
                  {
                    name: 'Date',
                    field: 'date',
                    editable: false,
                    type: 'text',
                  },
                  {
                    name: 'Plan',
                    field: 'plan',
                    editable: true,
                    type: 'text',
                  },
                  {
                    name: 'Amount',
                    field: 'amount',
                    editable: false,
                    type: 'text',
                    nowrap: ''
                  },
                  {
                    name: 'Action',
                    field: 'invoice_pdf',
                    editable: false,
                    type: 'text'
                  }
                ]}
                rowValues={invoice_list.map((o) => ({
                  ...o,
                  amount: '$'+o.amount,
                  date: moment.unix(o.date).format('MMM DD, YYYY'),
                  invoice_pdf: o.invoice_pdf !== "" ? <a href={o.invoice_pdf}> Download </a> : 'o.invoice_pdf'
                  // <div className={styles.candidateAvtarImage}>{ (o.first_name) ? (o?.first_name && Util.getFirstCharater(o?.first_name)) + (o?.last_name && Util.getFirstCharater(o?.last_name) ) : (o?.email && Util.getFirstCharater(o?.email) ) }</div>
                }))}
                activePage={'1'}
                perPage={'1'}
                loading={loading}
              />
              </Col>
            </Row>
          </div>
        </Wrapper>
      </div>
    </>
  )
}
export default InvoicePage
