import React, { useState, useEffect, useContext, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import styles from './styles/CandidateInfo.module.scss'
import isEmpty from 'lodash.isempty'
import Util from '../../../utils/util'
import { Card, Container, Col, Button, DropdownButton, Image, Row, Modal } from 'react-bootstrap'
import profileImage from '../../../../assets/images/icons/profile.png'
import Badge from "react-bootstrap/Badge";
import './styles/CandidateInfo.scss'
import SubmittedIcon from '../../../../assets/images/icons/submitted.png'
import _ from 'lodash'
import LinkedinIcon from '../../../../assets/images/icons/linkedin.png'
import lastContactIcon from '../../../../assets/images/icons/lastcontact.png'
import PdfIcon from '../../../../assets/images/icons/pdf.png'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import moment from 'moment'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { capitalize } from '../../../utils'

const MAX_SELECTION_LIMIT = 25

function CandidateInfo({
  loading,
  candidates,
  handleIntrested,
  multiSelectCheckbox,
  multiIntrestedCandidates,
  showConfirmDelete,
  setRejectUserId
}) {

  const [submissionData, setSubmissionData] = useState({ active: false, id: null })
  function activeSubmission(candidateId) {
    setSubmissionData({ active: true, id: candidateId })
  }

  if (loading) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>Loading....</h2>
        <div id="loading" />
      </div>
    </>
    )
  }
  if (isEmpty(candidates)) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>No Interviews Have Been Scheduled</h2>
      </div>
    </>
    )
  }

  const candidateClickHandler = (candidate) => {
    console.log(candidate)
  }

  return (
    <>
      <Table className={styles.candidateTable} responsive hover>
        <tbody >
          {Array.isArray(candidates) &&
            candidates.map((candidate, index) => {
              return (
                <CandidateRow
                  candidate={candidate}
                  idx={candidate.id}
                  key={index}
                  clickHandler={candidateClickHandler}
                  setSubmissionData={setSubmissionData}
                  submissionData={submissionData}
                  activeSubmission={activeSubmission}
                  handleIntrested={handleIntrested}
                  multiSelectCheckbox={multiSelectCheckbox}
                  multiIntrestedCandidates={multiIntrestedCandidates}
                  showConfirmDelete={showConfirmDelete}
                  setRejectUserId={setRejectUserId}
                />
              )
            })
          }
        </tbody>
      </Table>
    </>
  )
}

function CandidateRow({ candidate, idx, clickHandler, setSubmissionData, submissionData, setRejectUserId, showConfirmDelete, multiSelectCheckbox,multiIntrestedCandidates }) {
  const checked = multiIntrestedCandidates.length > 0 ? multiIntrestedCandidates.includes(idx) : false
  const [visible, setVisible] = useState(6)
  const [showModal, setShowModal] = useState(false)
  const [seeMessage ,setSeeMessage] = useState(false)
  const [talentComments, setTalentComments] = useState('')
	const [validationError, setValidationError] = useState({})
	const [btnDisabled, setBtnDisabled] = useState(false)
  const [bulkSelect, setBulkSelect] = useState(checked)
  const loadMore = () => {
    setVisible(visible + 6)
  }
  const loadLess = () => {
    setVisible(6)
  }
  const handleCheckboxChange = (event) => {
    setBulkSelect(!bulkSelect)
    multiSelectCheckbox(candidate.id)
  }

  const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		if(!(talentComments?.trim())){
			formIsValid = false;
			errors["description"] = "description is required field."
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

  const HandleSaveModal = (candidate) => {
		if(validateForm(talentComments.length > 0)){

      const payload = new FormData()
      payload.append('request_interviews[candidate_response]',true)
      payload.append('request_interviews[talent_comment]',talentComments)
      payload.append('request_interviews[user_id]',candidate.user_id)
      makeRequest('/request_interviews', 'post', payload, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createResponseMessage: (res) => {
              if (res?.success){
                window.location.reload()
                  setShowModal(false)
              }
              return {
                  message: res.msg,
                  messageType: res.messageType
              }
          },
      })
    }
  }
  return (
    <>
      <Card key={candidate.id} className={styles.candidateProfileCard} >
        <Card.Body className='candidate-list'>
          <div>
          <Modal
                    className="free-candidate"
                    show={showModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header
                    onHide={() => setShowModal(false)}
                    closeButton
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Modal.Title>{'Interview Acceptance'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form>
                   <Row>
                   <Col>
                   <Form.Label>Description:</Form.Label>
                  <Form.Control type="text" name='description' onChange={(e) => setTalentComments(e.target.value)} as="textarea" rows={3} placeholder="Enter Message Here " />
                  <p style={{color:'red'}}>
                  {validationError && validationError.description} 
                  </p>
                    </Col>
                     </Row>
                     <div style={{marginTop:'20px', display:'flex', justifyContent:'space-around'}}>
                   <div>
                   <Button onClick={() => setShowModal(false)} variant="secondary">
                     Close
                     </Button>
                    </div>
                    <div>
                   <Button onClick={() => HandleSaveModal(candidate)} variant="secondary">
                     Save
                     </Button>
                    </div>
                     </div>
                      </Form>
                    </Modal.Body>
                    {/* <Modal.Footer>
          <Button variant="secondary">
            Close
          </Button>
          <Button variant="primary">
            Save Changes
          </Button>
        </Modal.Footer> */}

                </Modal>
            <Row style={{ marginBottom: '11px',justifyContent: 'space-between'}} >
              <div className='d-flex'>
                {candidate?.image_url ?
                  <img className={styles.candidateProfileImage} src={candidate.image_url ? candidate.image_url : profileImage} onClick={(e) => clickHandler(candidate)} />
                  : <CandidateAvatarIcon clickHandler={clickHandler} candidate={candidate} firstName={candidate?.or_name} lastName={candidate?.or_name} />
                }
                <div className={styles.cardBody + 'container'} >
                  <div className='d-flex justify-content-space-between w-100'>
                    <h5 className={styles.cardBodyName}>
                      <span>{candidate?.or_name}</span><br/>
                      <span style={{fontSize:'15px'}}>{candidate?.job_name}</span>
                      {candidate.linkedin_profile_url &&
                        <a href={candidate.linkedin_profile_url} target="_blank">
                          <Image
                            src={LinkedinIcon}
                            style={{
                              width: '16px',
                              height: '16px',
                              marginLeft: '15px',
                              marginTop: '-1px'
                            }}
                          />
                        </a>
                      }
                      {candidate.resume && 
                        (
                          <a href={candidate.resume} target="_blank">
                            <Image
                                src={PdfIcon}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '5px',
                                    marginTop: '-1px'
                                }}
                            />
                          </a>)}
                    </h5>
                  </div>
                  <h5 style={{ fontSize: '16px' }}>{candidate?.first_name}  {candidate?.last_name} </h5>
                  {/* <h5 style={{ fontSize: '16px' }}>{candidate?.title}</h5> */}
                  <div
                                className="container row"
                                style={{ alignItems: 'center' }}
                            >
                                {/* <div>
                                    <h6>Activity</h6>
                                </div>{' '}
                                {'  '} */}
                                <div
                                    className="activity-class"
                                    style={{ display: 'contents' }}
                                >
                                    <div className="activity-field" style={{marginLeft:'-21px'}}
                                      onClick={() => setSeeMessage(!seeMessage)}
                                    >
                                        <h6
                                        >
                                            <img src={lastContactIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitle
                                                }
                                            >
                                                {' '}
                                                Message{' '}
                                            </label>
                                        </h6>
                                    </div>
                                   {seeMessage && <div className="container">
                                                <hr />
                                                {/* <p> Recent Application</p> */}
                                                        <ul
                                                            style={{
                                                                width: '100%',
                                                                marginBottom:
                                                                    '0',
                                                            }}
                                                        >
                                                            <li>
                                                                <div className="row contact_time">
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                'capitalize',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        &nbsp;{' '}
                                                                        <b>
                                                                            {candidate?.employer_message}
                                                                        </b>{' '}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                     </div>}
                                </div>
                  </div>
                </div>
              </div>
              {candidate?.candidate_response == true  && 
                <p>
                <span className={styles.jobCardText}>{capitalize('Request Accepted')}</span>
                </p>
                }
                {candidate?.candidate_response == false  && 
                <p>
                <span className={styles.jobCardTextReject}>{capitalize('Request Rejected')}</span>
                </p>
                }  
              {candidate?.candidate_response == null && <div>
                <Button
                  onClick={() => setShowModal(true)}
                  variant='primary'
                  className={styles.Acceptbutton}
                >
                  Accept
                </Button>
                <Button
                  variant='primary'
                  className={styles.button}
                  onClick={() =>{
                    setRejectUserId(candidate)
                  showConfirmDelete()}
                  }
                >
                  Reject
                </Button>
              </div>
                  }              
            </Row>
              <div className='container row w-100 flex-nowrap align-items-center'>
                <div style={{float: 'left'}}>
                  {/* <p className={styles.submittedJob}>Submitted Jobs</p> */}
                  {candidate?.submissions?.length > 0 && candidate?.submissions?.map((application) => (
                    <ul style={{ marginBottom: '0' }}>
                      <li>
                        <div className='row contact_time'>
                          <b>{application?.job?.organization?.name}</b>  &nbsp; - &nbsp;
                          <a href={`/jobs/${application?.job?.id}/${application?.job?.name}`}>{application?.job.name}</a>  <span style={{ textTransform: "capitalize" }}><b style={{ marginLeft: "5px" }}> &nbsp; {application.submission_type != 'submitted' ? 'submitted to recruiter screened' : 'submitted on'}  &nbsp;</b> - &nbsp;{moment(application.created_at).format('MMM DD, YYYY')} </span>
                          <span style={{ textTransform: "capitalize" }}><b style={{ marginLeft: "5px" }}> &nbsp; Submitted By &nbsp;</b> - &nbsp;{`${application?.user?.first_name} ${application?.user?.last_name}`}  </span>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
          </div>
        </Card.Body>
      </Card>
      <br />
    </>
  )
}

export default CandidateInfo
