import React, { useState, useEffect } from 'react'
import { Card, Col, Image, Row, ProgressBar } from 'react-bootstrap'
import RightArrow from '../../../../assets/images/recruiter_page_assets/arrow-right-long.svg'
import './styles/Subscription.scss'
import moment from 'moment'
import {
  Wrapper
} from './styles/Subscription.styled'
import {
  H1,
  P,
  Button,
  CONTAINER,
  GRID,
  COL,
  A,
} from './styles/Subscription.styled'
import { capitalize } from '../../../utils'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'

const IndexPage = (props) => {
  const {transactionLog_detail, current_user } = props
  const [cancelPlan,setCancelPlan] = useState(false)
  const [cancelPlanId,setCancelPlanId] = useState('')
  const [transactionId,setTransactionId] = useState('')
  const calculatePercentage = (value1, value2 ,decimal) => {
    return parseFloat((value1 * 100) / value2).toFixed(decimal)
  }

  const calculateDueDate = (endDate) => {
    var given = moment(endDate, "YYYY-MM-DD");
    var current = moment().startOf('day');
    //Difference in number of days
    return moment.duration(given.diff(current)).asDays();
  }

  const cancleSubscription = () =>{
    let URL = `/subscription/cancel_subscription`
    const payload = new FormData()
    payload.append('subscription[subscription_id]', cancelPlanId)
    payload.append('subscription[transaction_id]', transactionId)
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (responce) => {
        setCancelPlan(false)
        if (responce.success) {
          window.location.reload();
          return {
            message: responce.message,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        } else {
          return {
            message: responce.message,
            messageType: 'failure',
            loading: false,
            autoClose: true,
          }
        }
      },
      createErrorMessage: (e) => {
        return e.message
      },
    })
  }

  return (
    <>
      <div className='subscriptionPage'>
        <Wrapper>
          <div className='sidebar'>
            <div className='sidebar_header'>
              <p>Subscriptions & Billing</p>
            </div>
            <a className='active' href="/subscription">
              Subscriptions
            </a>
            <a href="/subscription/invoice">Invoices</a>
            <a href="/subscription/billing">Billing Information</a>
          </div>
          <div className='containt'>
            <div>
              <Row>
                <Col><h3>Subscriptions</h3></Col>
                <Col className='text-right'>
                  <A href="/subscription/order">
                    Buy New Plan
                    <Image
                      src={RightArrow}
                      style={{ marginLeft: '15px' }}
                    />
                  </A>
                </Col>
              </Row>
            </div>
            {transactionLog_detail && transactionLog_detail?.length ? (
              transactionLog_detail?.map((transactionLog,key) => {
                if (transactionLog)
                  return (
                    <Card.Body key={key}>
                      <Row className="align-items-center">
                        <Col
                          lg={2} md={2} sm={12} xs={12}
                        >
                          <div className="align-items-center justify-content-end">
                            <div className='matchText_big' style={{ margin: 'auto' }}>
                              <span
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                {calculatePercentage(transactionLog?.used_limit,transactionLog?.max_limit,0)}%
                                <span className='matchSubText_big'>
                                  Reached
                                </span>
                              </span>
                            </div>
                          </div>

                          <div className='text-center mt-1'>
                           { transactionLog?.status != 'in_active' && <p className='jobCardText'>{calculateDueDate(transactionLog?.end_date)} day Due</p> }
                          </div>
                        </Col>
                        <Col lg={10} md={10} sm={12} xs={12} className="px-0">
                          <Row>
                            <Col className='jobCardColjobCardCol'>
                              <p className='jobCardText'>
                                { (transactionLog?.status == 'active' && transactionLog?.user_subscription?.amount > 0) &&
                                  <Button className='cancelbuttom' onClick={()=>{
                                    setCancelPlan(true)
                                    setCancelPlanId(transactionLog?.user_subscription_id)
                                    setTransactionId(transactionLog?.id)
                                  }}>
                                    Cancel
                                  </Button>
                                }
                                <span className={`plan${(transactionLog?.user_subscription) ? (transactionLog?.user_subscription.status) : (transactionLog?.status)} mr-2`}>
                                  { (transactionLog?.user_subscription) ?
                                    ((transactionLog?.user_subscription?.status != 'in_active') ? capitalize(transactionLog?.user_subscription?.status) : 'Inactive')
                                    : ((transactionLog?.status != 'in_active') ? capitalize(transactionLog?.status) : 'Inactive')
                                  }
                                </span>
                              </p>
                              <span className='jobCardTitle'>
                                {transactionLog?.user_subscription?.subscription_plan?.name || 'Free Plan'}
                                {/* {transactionLog.user_subscription.} */}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <p className='jobCardText'>
                                   {current_user.role == 'talent' ? "Email Limit" : "Candidate Limit"} : ({transactionLog?.used_limit} / {transactionLog?.max_limit})
                                  </p>
                                  <ProgressBar now={calculatePercentage(transactionLog?.used_limit,transactionLog?.max_limit,0)} />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="auto" className='mt-2'>
                              <p className='jobCardText'>
                                Date : {moment(transactionLog?.start_date).format('LL')} <b>to</b> {moment(transactionLog?.end_date).format('LL')}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  )
              })
            ) : (
              <div style={{ textAlign: 'center' }}>
                No Active Plan Available
              </div>
            )}

          </div>
        </Wrapper>
      </div>
      {cancelPlan &&
          <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, Cancel it!"
              cancelBtnText="No"
              confirmBtnBsStyle="danger"
              title="Are you sure you want to cancel the plan?"
              onConfirm={()=>cancleSubscription()}
              onCancel={()=>setCancelPlan(false)}
              focusCancelBtn
              >
          </SweetAlert>
      }
    </>
  )
}
export default IndexPage
