import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Badge from "react-bootstrap/Badge";
import Card from '../../Admin/shared/Card'
import TableRecruiter from '../../Admin/shared/Table'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import TableWrapper from '../../Admin/shared/TableWrapper'
import SearchBar from '../../../common/SearchBar/SearchBar'
import { Row, Col, Spinner  } from 'react-bootstrap'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import '../../Admin/styles/RecruiterManagment.scss'
import SweetAlert from 'react-bootstrap-sweetalert'
import feather from 'feather-icons'
import DataTable from "react-data-table-component-footer"
import '../../Admin/styles/DataTableCustom.scss'
import { capitalize } from '../../../../utils';
import Paginator from '../../../common/Paginator/Paginator';
import Button from '../../Admin/shared/Button';
import P from '../../Admin/shared/P';

const RecruiterManagement = ({per_page}) => {
    var active_page = window.sessionStorage.getItem('agency_admin_recruiter_activePage')
    var query = window.sessionStorage.getItem('agency_admin_recruiter_query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [pageCount, setPageCount] = useState(0)
    const [activePageOnDemand, setActivePageOnDemand] = useState(0)
    const [showOnDemandRecruiters, setShowOnDemandRecruiters] = useState(false)
    const [recruiters, setRecruiters] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [startRecord, setStartRecord] = useState(0)
    const [totalRecruiter, setTotalRecruiter] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [loading, setLoading] = useState(false)
    const [sortField, setSortField] = useState('users.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(per_page || 25)

    useEffect(() => {
        feather.replace()
    })

    const fetchData = () => {
        setLoading(true)
        window.sessionStorage.setItem('agency_admin_recruiter_activePage',activePage)
        const url = `/agencies/admin/reports/recruiters.json?page=${activePage}&search=${searchTerm}&sortField=${sortField}&sortDirection=${sortDirection}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (response) => {
                let sr_no = (activePage - 1) * response.data.per_page
                setPageCount(response.data.total_pages)
                setTotalRecruiter(response.data.total_count)
                
                setRecruiters(
                    response.data.users.map((user) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        id: user.id,
                        agency_name: user.name,
                        name: user.first_name + ' ' + user.last_name,
                        email: user.email,
                        role: user.role,
                        created_at: moment(user.created_at).format('MMM DD, YYYY'),
                        email_confirmed: user.email_confirmed ? 'Approved' : 'Pending',
                        send_email_request: user.send_email_request ? 'Approved' : 'Pending',
                        on_demand_recruiter: capitalize(user.on_demand_recruiter),
                        organizations:   user.recruiter_organizations &&
                                (sortRecruiterOrganization(user.recruiter_organizations)).map((skill, index) => canidateSkills(skill, index))                        
                        }))
                )
              
                setCurrentCounts(response.data.current_count)
                setStartRecord((activePage - 1) * response.data.per_page)
                setLoading(false)
            },
        })
    }
    const fetchAppropriateData = () => {
        if (showOnDemandRecruiters) {
            fetchOnDemandData()
        } else {
            fetchData()
        }
    }

    const sortRecruiterOrganization = (data) => {
        let sort_data = data.slice(-5).sort((a, b) => a.id > b.id ? -1 : 1)
        return sort_data.map((org, key) => { 
            return (org.organization) ? org.organization.name : ''
        })
    }

    useEffect(() => {
        fetchAppropriateData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,activePageOnDemand, showOnDemandRecruiters,sortDirection,sortField])

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('agency_admin_recruiter_query', value)
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '4%',
            minWidth: '4%',
        },{
            name: 'Agency Name',
            selector: row => row.agency_name,
            maxWidth: '13%',
            minWidth: '13%',
            wrap:true,
            sortable: true,
            sortField: 'agencies.name',
        },
        {
            name: 'Recruiter Name',
            selector: row => row.name,
            maxWidth: '13%',
            minWidth: '13%',
            wrap:true,
            sortable: true,
            sortField: 'users.first_name',
        },
        {
            name: 'Email',
            selector: row => row.email,
            maxWidth: '18%',
            minWidth: '18%',
            wrap:true,
            sortable: true,
            sortField: 'users.email',
        },
        {
            name: 'Gmail Verified',
            selector: row => row.send_email_request,
            maxWidth: '15%',
            minWidth: '15%',
            sortable: true,
            sortField: 'users.send_email_request',
        },
        {
            name: 'Email Verified',
            selector: row => row.email_confirmed,
            maxWidth: '13%',
            minWidth: '13%',
            sortable: true,
            sortField: 'users.email_confirmed',
        },
        {
            name: 'On-Demand',
            selector: row => row.on_demand_recruiter,
            maxWidth: '12%',
            minWidth: '12%',
        },
        {
            name: 'Created On',
            selector: row => row.created_at,
            maxWidth: '12%',
            minWidth: '12%',
            sortable: true,
            sortField: 'users.created_at',
        },
    ]

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '12px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Recruiter Management
                </P>
            </div>
                <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
                    <Col xs={12} sm={12} lg={8} style={{float:'left'}}>
                        
                            <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalRecruiter ? totalRecruiter : currentCounts } of {totalRecruiter} recruiters</DisplayPagination>
                        
                    </Col>
                    <Col xs={12} sm={12} lg={4} style={{float:'right'}}>
                        <SearchBar
                        placeholder="Search users..."
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 1
                                ? fetchAppropriateData()
                                : setActivePage(1)
                            !searchTerm && window.sessionStorage.setItem('agency_admin_recruiter_query','')
                        }}
                        onClick={(e) => setSearchTerm('')}
                        />
                    </Col>
                </Row>
                <DataTable
                    title={false}
                    persistTableHead={true}
                    noDataComponent={<p className="text-center p-3">No record found</p>}
                    columns={columns}
                    data={recruiters}
                    progressPending={loading}
                    progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner className='spinner' animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </P>}
                    onSort={handleSort}
                    sortServer
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRecruiter}
                    paginationPerPage={perPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    onChangePage={page => setActivePage(page)}
                /> 
        </Card>
    )

    function canidateSkills(skill, index){
        return(
            <>
                <Badge
                    pill
                    key={index}
                    variant="secondary"
                    className="skills-button d-inline-block text-truncate"                                                                                            
                >
                    <label className='candidate-skills'> {skill} </label>
                </Badge>{'   '}
            </>
        )
    }
}

export default RecruiterManagement