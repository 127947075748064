import React, { useState, useEffect } from 'react'
import Card from '../shared/Card'
import Table from '../shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../shared/Button'
import P from '../shared/P'
import { DisplayPagination } from '../styles/AdminDashboard.styled'
import { Row, Col, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import './styles/ChatFaqs.scss'
import '../styles/DataTableCustom.scss'
import Select from 'react-select'
import styles from '../../Admin/styles/JobsStatusPage.module.scss'
import feather from 'feather-icons'
import Util from '../../../../utils/util'
import Modal from 'react-bootstrap/Modal'
import SearchBar from '../../../common/SearchBar/SearchBar'
import moment from 'moment'
import { capitalize } from '@material-ui/core'
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import axios from 'axios'
import AddFaqs from './AddFaqs'
import DataTable from 'react-data-table-component-footer'

const ChatFaqs = (ctrs_email_list) => {
    const team_lists = []
    const team_lead_list = []
    const recruiters = []
    const queryParams = new URLSearchParams(window.location.search)
    var active_page = window.sessionStorage.getItem('teamListActivePage')
    var query = window.sessionStorage.getItem('query')
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    let jobStatus_start_date = sessionStorage.getItem("jobStatus_start_date");
    let jobStatus_end_date = sessionStorage.getItem("jobStatus_end_date")
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const jobId = queryParams.get("job")
    const [teams, setTeams] = useState([])
    const [totalTeams, setTotalTeams] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [teamUserId, setTeanUserId] = useState()
    const [show, setShow] = useState(false)
    const [showMember, setShowMember] = useState(false)
    const [method, setMethod] = useState('post')
    const [entry, setEntry] = useState({})
    const [loading, setLoading] = useState(true)
    const [data, setDate] = useState([])
    let email_seacrh = sessionStorage.getItem("email_seacrh")
    const [emailList,setTeamList] = useState([{value: 'All', label: 'All'},...ctrs_email_list.ctrs_email_list])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate : jobStatus_start_date ? jobStatus_start_date : moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : jobStatus_end_date ? jobStatus_end_date : moment().endOf('week').toDate()})
    const [selected,setSelected] = useState((jobId)? recruiters.filter((option) => option.value == jobId) : email_seacrh && email_seacrh != "null" ? JSON.parse(email_seacrh) : {value: 'all', label: 'All'})
    const [selectedEmail,setSelectedEmail] = useState(email_seacrh && email_seacrh != "null" ? JSON.parse(email_seacrh).label : jobId ? jobId : '')
    const [fetchApi, setFetchApi] = useState()
    const [fetchOrgAPi, setFetchOrgApi] = useState()
    const fetchData = () => {
        setLoading(true)
        const url = `/admin/chat_faqs.json`
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("jobStatus_start_date", start_date)
        sessionStorage.setItem("jobStatus_end_date", end_date)
        window.sessionStorage.setItem('teamListActivePage', activePage)
        makeRequest(
            url,
            'get',
            {
                params: {
                    page: activePage + 1
                },
            },
            {
                contentType: 'application/json',
                loadingMessage: 'Fetching...',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                    setDate(res?.data?.data)
                    let sr_no = (activePage == 0 ? 0 : (activePage - 1)) * 25
                    setTeams(
                        res.data.data.map((item) => (sr_no = sr_no + 1,{
                            id: item.id,
                            que: capitalize(item?.que),
                            ans: item?.ans,
                            // actions: (<div className='w-100 d-inline-flex justify-content-around'>
                            //         <a onClick={() => 
                            //             {setTeamUserId(item.id), getUpdateData(item.id)}} title="Edit" href={`javascript::void()`} className='pr-4'><i data-feather="edit-2" /></a>
                            //         <a onClick={() => deleteTeam(item.id)} title="Delete" href={`javascript::void()`} className='pr-4'><i data-feather="trash-2" /></a>
                            //         </div>)
                            }))
                            )
                            setPageCount(res?.data?.total_pages)
                            setTotalTeams(res?.data?.total_count)
                            setCurrentCounts(res?.data?.current_counts)
                            setStartRecord(activePage * res?.data?.per_page)
                            setLoading(false)
                        },
                    }
                    )
                }
                useEffect(() => {
                    feather.replace()
                })
                useEffect(() => {
                    fetchData()
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }, [activePage, selectedEmail, dateRange])
                
                const deleteTeam = async (id) => {
                    const URL = `/admin/chat_faqs/${id}`
                    await makeRequest(URL, 'delete', '', {
                        createResponseMessage: () => {
                            return {
                                message: `Deleted successful`,
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        createErrorMessage: (e) => 'Failed',
                        onSuccess: () => {
                            fetchData()
                        },
                    })
                }
                
    const sendMail = (id) => {
        let getTeam = data.filter((row) => row.id == id)
        if (getTeam?.length > 0) {
            setEntry(getTeam[0])
            setMethod('mail')
            setShow(true)
        }
             }
    const addCtrs = () => {
        setEntry({})
        setMethod('post')
        setShow(true)
    }

    const onHide = () => {
        setEntry({})
        setMethod('post')
        setShow(false)
        setShowMember(false)
    }

    const getUpdateData = (id) => {
        let getTeam = data.filter((row) => row.id == id)
        if (getTeam?.length > 0) {
            setMethod('put')
            setEntry(getTeam[0])
            setShow(true)
        }
    }

    const handleSort = async (column, sortDirection) => {
        if (column.sortField != undefined && sortDirection != undefined) {
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }
 
    const handleDate = (date) => {
        setDateRange(date)
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '10%',
            minWidth: '10%',
        },
        {
            name: 'Questions',
            selector: row => row.que,
            wrap: true,
            maxWidth: '40%',
            minWidth: '40%',
            sortable: true,
            sortField: 'full_name',
        },
        {
            name: 'Answers',
            selector: row => row.ans,
            wrap: true,
            maxWidth: '40%',
            minWidth: '40%',
            sortable: true,
            sortField: 'role',
        },
        {
            name: 'Action',
            selector: row => row.actions,
            maxWidth: '10%',
            minWidth: '10%',
        },
    ]

    return(
        <>
        <Card>
            <div className="d-flex justify-content-between align-items-center w-100">
                <P
                    size="28px"
                    height="38px"
                    color="#1D2447"
                    style={{ width: '540px' }}
                >
                    Chat FAQs
                </P>
                <div style={{ flexGrow: 1 }} />
                <Button onClick={addCtrs} className="ml-3">
                    Add FAQ
                </Button>
            </div>
                 <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                {totalTeams > 0 && loading == false &&
                    <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalTeams}</DisplayPagination>
                }
            </Col>
                </Row>
            {/* </div> */}
            <Table
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                        size: 10,
                    },
                    {
                        name: 'Question',
                        field: 'que',
                        editable: true,
                        type: 'text',
                        size: 40,
                    },
                    {
                        name: 'Answer',
                        field: 'ans',
                        editable: false,
                        type: 'text',
                        size: 40,
                    },
                    {
                        name: 'Action',
                        field: 'options',
                        editable: false,
                        type: 'text',
                        size: 10,
                    },
                ]}
                rowValues={teams.map((team) => ({
                    ...team,
                    member: team?.member?.map((t) =>
                        t?.user?.id != team?.owner_id ? (
                            <span className="members">
                                {' '}
                                {Util.fullName(
                                    t?.user?.first_name,
                                    t?.user?.last_name
                                )}{' '}
                            </span>
                        ) : (
                            ''
                        )
                    ),
                    status: Util.capitalize(team?.status),
                }))}
                showEditOption
                activePage={activePage}
                deleteAction={deleteTeam}
                goToEditPage={(id) => {
                    setTeanUserId(id)
                    getUpdateData(id)
                }}
                loading={loading}
            />
            {pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
            {/* <DataTable
                title={false}
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={teams}
                progressPending={loading}
                progressComponent={<P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalTeams}
                paginationPerPage={25}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => {
                    setActivePage(page)
                }}
            /> */}
        </Card>

        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            scrollable
            className="modal-entry-form"
        >
            <Modal.Header closeButton className="mt-2 mb-2">
                <Modal.Title id="contained-modal-title-vcenter">
                    {method === 'put'
                        ? 'Update' : method === 'mail' ? 'Follow Up'
                        : 'Add Question'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddFaqs
                    recruiter_list={recruiters}
                    // org_list={org_list}
                    teamEntry={entry}
                    onHide={onHide}
                    method={method}
                    team_lists={team_lists}
                    fetchData={fetchData}
                    team_lead_list={team_lead_list}
                    teamUserId={teamUserId}
                    fetchApi={fetchApi}
                    fetchOrgAPi={fetchOrgAPi}
                    setFetchApi={setFetchApi}
                    setFetchOrgApi={setFetchOrgApi}
                ></AddFaqs>
            </Modal.Body>
        </Modal>
    </>
    )
}

export default ChatFaqs