import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal"
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Box } from '../../../common/Styled components/styled'
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch'
import { Row, Col } from 'react-bootstrap'
import Text from '../../../common/Styled components/Text'
import Container from "react-bootstrap/Container"
import './../styles/communities.scss'
// import moment from 'moment'
import SettingLeftPenal from '../SettingLeftPenal'

const ExpiredPage = ({ communityId, currentUser}) => {
  return (
    <>
      <div className="container mt-60 communities-detail">
        <div className="row">
          <div className="col-lg-12 p-0 m-0">
            <div className="single-recent-jobs" style={{ paddingLeft: '20px' }}>
              <div className='alert-warning mt-50'>
                <div className='p-15'>
                  <h5>Expired Link</h5>
                  <p>This link has expired or the session has expired please click the below button to regenerate a new link.</p>
                  <a href={'/communities/'+communityId+'/subscription'} className="btn btn-primary mt-20">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ExpiredPage