import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from '../../../common/Styled components/CloseButton'
import styles from '../AddFeedback/styles/ScheduledTable.module.scss'
function ScheduledDeleteModal({
  index,
  feedbackCancelModal,
  setFeedbackCancelModal,
  handlefeedbackCancel,
  setDeleteModal,
  feedbackId,
}) {
  
  return (
    <Modal
      className="scheduleModal delete-feed-modal-interview"
      show={index === feedbackCancelModal}
      onHide={() => setFeedbackCancelModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName={styles.modalBackdrop}
      size="md"
      style={{ zIndex: '9999' }}
      centered
    >
      <Modal.Body className={styles.modalBody}>
        <div className={styles.areYouSureText}>
          <div> Are you sure you want to delete the feedback?
          </div>
          {/* <CloseButton handleClick={() => setFeedbackCancelModal(false)} /> */}
        </div>
        <div className={styles.deleteFeedbackButtonContainer}>
          <div>
            <button
              className={styles.feedDeleteButton}
              onClick={() => handlefeedbackCancel(feedbackId)}
            >
              Yes, I want to delete
            </button>
          </div>
          <div>
            <button
              className={styles.feedDeleteButton}
              onClick={() => {
                setFeedbackCancelModal(null)
                // setDeleteModal(false)
              }}
            >
              No, I do not want to delete
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ScheduledDeleteModal
