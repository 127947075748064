import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import {
    Row,
    W5text,
    Wrapper,
    Button,
    Message,
} from './styles/EmailConfirm.styled'
import CloseButton from '../Styled components/CloseButton'
import TextInput from '../TextInput/TextInput'

const validationSchema = Yup.object({
    email: Yup.string().email().required('Email is required'),

    agreeToTermsAndPolicy: Yup.boolean().oneOf(
        [true],
        'Please accept the Terms and Conditions & Privacy Policy'
    ),
})

function EmailConfirm({ currentUser, setEmailVerify }) {
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(null)

    const hadleSendMail = async (values) => {
        const resendformData = new FormData()
        resendformData.append(`user[email]`, values.email)
        const url = `/resend_confirmation`
        setLoading(true)
        const CSRF_Token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')
        const responce = await axios
            .post(url, resendformData, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-Token': CSRF_Token,
                },
            })
            .then((res) => {
                const responce = res.data
                if (responce.success) {
                    setResult({ color: '#0f5132', message: responce.msg })
                } else {
                    setResult({ color: '#ff1d31', message: responce.msg })
                }
            })
            .catch((error) => console.log(error))
        setLoading(false)
    }

    return (
        <Wrapper>
            <Formik
                initialValues={{
                    email: currentUser.email,
                    agreeToTermsAndPolicy: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    hadleSendMail(values)
                }}
            >
                <Form>
                    <Row jContent="space-between" aItems="center" mb="20px">
                        <W5text size="22px" color="#1D2447">
                            Please verify your email
                        </W5text>
                        <CloseButton
                            handleClick={() => setEmailVerify(false)}
                        />
                    </Row>
                    <Row mb="20px">
                        <TextInput
                            label="Email"
                            name="email"
                            type="email"
                            width="16rem"
                            readOnly={true}
                        />
                    </Row>
                    <Row aItems="center" mb="20px">
                        <TextInput
                            type="checkbox"
                            name="agreeToTermsAndPolicy"
                            id="terms_and_policy"
                            label=" I agree to the Terms and Conditions & Privacy
                                Policy"
                        />
                    </Row>
                    {result && (
                        <Row jContent="center" mb="10px">
                            <Message color={result.color}>
                                {result.message}
                            </Message>
                        </Row>
                    )}
                    <Row jContent="flex-end">
                        <Button type="submit" lr="20px" tb="10px">
                            {loading ? (
                                <Spinner animation="border" variant="light" />
                            ) : result ? (
                                'Resend'
                            ) : (
                                'Send Mail'
                            )}
                        </Button>
                    </Row>
                </Form>
            </Formik>
        </Wrapper>
    )
}

export default EmailConfirm
