import styled from 'styled-components'
import { Form } from 'formik'

export const Container = styled.div`
    display:flex;
    align-content:center;
    overflow:auto;
    margin-bottom:5px;
    justify-content:center;
`
export const Row = styled.div`flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    display: flex;
    margin-top: 35px;
`
export const ButtonRow = styled.div`flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    display: flex;
    margin-top: 35px;
`
export const StyledForm = styled(Form)`
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 50px;
    @media only screen and (min-device-width : 320px) and (max-device-width : 767px){
        padding:15px !important;
        overflow: auto;
    }
`
export const Button = styled.button`
    background: linear-gradient(
        94.67deg,
        #5f78ff -1.19%,
        #7185f2 53.94%,
        #8d91ff 102.59%
    );
    border-radius: 25px;
    padding: 10px 40px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    &:disabled {
        background: lightgray;
    }

`
export const ErrorMessage = styled.div`
    font-size: 10px;
    color: red;
    margin-top: 5px;
    max-width: ${(props) => props.width || 400};
`