import React, { useState, useEffect } from 'react'
import './../styles/communities.scss'
import SettingLeftPenal from '../SettingLeftPenal'
import Moment from 'moment'
const DashboardPage = ({ communityId, totalBalance,totalPaidMember, currentUser }) => {
  return (
    <>
      <div className="container mt-60 communities-detail">
        <div className="row">
          <SettingLeftPenal communityId={communityId} ></SettingLeftPenal>
          <div className="col-lg-8 p-0 m-0">
            <div className="single-recent-jobs pl-20">
              <div className=''>
                <div>
                  <h4>🎉 Happy {Moment().format('dddd')}, {currentUser?.first_name}</h4>
                  {/* <p className='pt-10'>Next payout will be $0 in 7 days.</p> */}
                </div>
                <h6 className="mb-20 mt-25">Subscriptions</h6>
                <div className="divider"></div>
                <div className="block-price mt-20 md-20">
                  <span className="text-price for-month display-month">{ totalPaidMember || 0}</span>
                  <p>Paid members</p>
                </div>
                <div className="block-price mt-20 md-20 ml-50">
                  <span className="text-price for-month display-month">${(totalBalance > 0) ? (totalBalance / 100) : 0}</span>
                  <p>MRR</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DashboardPage