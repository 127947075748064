import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/ChatFaqs.scss'
import validator from 'validator'
import Button from '../shared/Button'
import DateSelector from '../../../common/DateSelector.js/DateSelector'
import AsyncCreatableSelect from 'react-select/async-creatable'
import ReactSelect from 'react-select'
import moment from 'moment'
import axios from 'axios'

const AddFaqs = ({teamEntry,onHide,method,fetchData}) => {
	const [validationError, setValidationError] = useState({})
	const [entryData, setEntryData] = useState({
		que: teamEntry?.que ? teamEntry?.que : '',
		ans: teamEntry?.ans ? teamEntry?.ans : '',
	})
	const [btnDisabled, setBtnDisabled] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date())
	const handleChange = (e) => {
		setValidationError({})
		// setSelectedRecruiter(e.value)
		// sessionStorage.setItem("jobStatus_recruiter", JSON.stringify(e))
	}
	const [modal,setModal] = useState('')
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/admin/chat_faqs'
			const payload = new FormData()
			if(method === 'put'){
				URL = `/admin/chat_faqs/${teamEntry.id}`
			}
			payload.append('chat_faqs[ans]',e?.target.ans?.value)
			payload.append('chat_faqs[que]', e?.target?.que?.value)
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method == 'mail' ? 'post' : method , payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
              		setBtnDisabled(true)
						onHide()
						fetchData()
				}
			}) 
		}
		// setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		if(!(e?.target?.que?.value?.trim())){
			formIsValid = false;
			errors["que"] = "Question is required field."
		}
		if(!(e?.target?.ans?.value?.trim())){
			formIsValid = false;
			errors["ans"] = "Answer is required."
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<Container> 
				<form id="testformid" onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
						<Col xs={12} sm={12} lg={12}>
							<span>Question: </span>
							{/* <input  type='textarea' tabIndex="112" disabled={method == 'mail'} defaultValue={entryData?.que}  onChange={handleChange} className='form-control w-100' name='que' id="que" /> */}
							<textarea form ="testformid" defaultValue={teamEntry?.que} onChange={handleChange} className='form-control w-100' name='que' id="que" cols="35" wrap="soft"></textarea>
							<label className='error'>
									{validationError && validationError.que}        
							</label>
						</Col>
						<Col xs={12} sm={12} lg={12}>
							<span>Answer: </span>
							{/* <input  type='textarea' tabIndex="112" disabled={method == 'mail'} defaultValue={teamEntry?.ans} onChange={handleChange} className='form-control w-100' name='ans' id="ans" /> */}
							<textarea form ="testformid" defaultValue={teamEntry?.ans} onChange={handleChange} className='form-control w-100' name='ans' id="ans" cols="35" wrap="soft"></textarea>
							<label className='error'>
									{validationError && validationError.ans}        
							</label>
						</Col>
					</Row>
					<Row className="w-100 modal-entry-form-row" >
						<Col xs={3} sm={3} lg={3}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Save</Button>
						</Col>
					</Row>
				</form>
				</Container>
		</>
  )
}

export default AddFaqs;