import React from 'react'
import styles from './styles/Signup.module.scss'
import { Dropdown } from 'react-bootstrap'

function Signup() {
    return (
        <Dropdown className={styles.signupDropdown}>
            <Dropdown.Toggle
                id="dropdown-basic"
                className={styles.signUpDropdownButton}
            >Sign Up</Dropdown.Toggle>

            <Dropdown.Menu align="right">
                <Dropdown.Item href="/signup/talent">
                    Talent
                </Dropdown.Item>
                <Dropdown.Item href="/signup/employer">
                    Employer
                </Dropdown.Item>
                <Dropdown.Item href="/signup/recruiter">
                    Recruiter
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default Signup
