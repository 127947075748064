import React, { useEffect, useState,Fragment} from 'react'
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Paginator from '../../common/Paginator/PaginatorV2'
import Util from '../../../utils/util'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import './styles/MyNetworkIndex.scss'
import ManageMyMetworkSideBar from './ManageMyMetworkSideBar'
import lodingImage from '../../../../assets/v2/imgs/theme/loading_new.gif'
import '../../../../assets/v2/css/main.css'
import '../../pages/Interview/styles/AiInterview.scss'

function AllNewCandidate({ current_user,my_total_candidates,received,sent,reject }) {
    const [loading, setLoader] = useState(true)
    const [candidates, setCandidates] = useState([])
    const [activePage, setActivePage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [sortBy, setSortBy] = useState('created_at')
    const [searchBy, setSearchBy] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const fetchData = async() => {
        setCandidates([])
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setLoader(true)
        const url = `/my_networks/new.json`
        await makeRequest(url, 'get',  {
                params: {page: (activePage+1),sort_by: sortBy,search_by: searchBy}
            },{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setCandidates(
                    res.data.candidates.map((candidate) => ({
                        id: candidate.id,
                        first_name: (candidate?.person?.first_name != null) ? capitalize(candidate?.person?.first_name): capitalize(candidate?.first_name),
                        last_name: (candidate?.person?.last_name != null) ? capitalize(candidate?.person?.last_name) : capitalize(candidate?.last_name),
                        title: candidate?.person?.title || candidate.title,
                        location: candidate?.person?.location || candidate.location,
                        state: candidate?.state || '',
                        city: candidate?.city || '',
                        avatar_url: candidate?.person?.avatar_url || '',
                        employer: candidate?.person?.employer || '',
                        description: candidate?.person?.description || '',
                    }))
                )
                setPageCount(res.data.total_pages)
                setTotalCount(res.data.total)
                setLoader(false)
            },
        })
    }
    useEffect(()=>{
        fetchData()
    },[activePage,sortBy,searchBy])

    const inviteNetwork = (id) => {
        setLoader(true)
        const url = `/invite_network.json`
        makeRequest(url, 'post',  {
                user_id_2: id
            },{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            createResponseMessage: (response) => {
                return {
                    message: response.message,
                    messageType: response.messageType,
                    loading: false,
                    autoClose: true,
                }
            },
            onSuccess: (res) => {
                setLoader(false)
                const updatedCandidates = candidates.map(candidate =>
                    candidate.id === id ? { ...candidate, status: 'pending' } : candidate
                );
                setCandidates(updatedCandidates);
            },
        })
    }
    return (
        <>
            <div className="section-box mt-20">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 float-right">
                            <div className="content-page box-boder2">
                                
                                    <div className="box-filters-job mt-15 mb-10">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h5 className={'fw-bold'}>People you may know</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {loading == false && candidates.length > 0 ? (''):(
                                    <div className="box-filters-job pt-20 mb-10">
                                        <div className="row">
                                            <div className="col-lg-12 text-center p-10">
                                                <h5 className={'fw-bold'}>No Data Found</h5>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="box-filters-job mb-10">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7">
                                            <div className="form-group mb-0">
                                                <input type="text" onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setSearchBy(e.target.value);
                                                        }
                                                    }} className="form-control input-bg-white form-icons"
                                                 placeholder="Search by name" />
                                                <i className="fi-rr-search"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 text-lg-end mt-sm-15">
                                            <span className="text-sortby mr-2">Sort by:</span>
                                            <div className="dropdown dropdown-sort">
                                                <select className="form-control sortby form-icons" onClick={(e) => {
                                                        setSortBy(e.target.value)
                                                    }
                                                }>
                                                    <option value="created_at">Newest</option>
                                                    <option value="first_name">First name</option>
                                                    <option value="last_name">Last Name</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="employers-list">
                                {(loading) ? 
                                    <div className="text-center loading">
                                        <img src={lodingImage} alt="loading" />
                                    </div>
                                :''}
                                {candidates && candidates.map((candidate, index) => {
                                    return(
                                        <div key={candidate.id}  className="card-employers hover-up wow animate__animated animate__fadeIn">
                                            <div className="row align-items-center">
                                                <div className="col-lg-9 col-md-6 d-flex">
                                                    <div className="employers-logo  mr-15">
                                                        <a href={`/users/profile/${candidate.id}`} className={'invite_candidate'}>
                                                            <figure>
                                                                {candidate?.avatar_url ?
                                                                    <img alt="jobhub" className={'img_border'} src={(candidate?.avatar_url != '')?candidate.avatar_url:'assets/imgs/page/candidates/img-candidate10.png'} />
                                                                    : <CandidateAvatarIcon firstName={candidate?.first_name} lastName={candidate?.last_name} />
                                                                }
                                                            </figure>
                                                        </a>
                                                    </div>
                                                    <div className="employers-name">
                                                        <h5><a href={`/users/profile/${candidate.id}`}><strong>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</strong></a></h5>
                                                        <span className="text-sm  text-muted overflowtext" title={candidate?.description}>{candidate?.description || 'N/A'}</span>
                                                        <span className="d-flex align-items-center"><i className="fi-rr-marker mr-5 ml-0"></i>{candidate?.location || 'N/A'}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 text-lg-end d-lg-block d-none">
                                                    {candidate.status === 'pending' ? (
                                                        <button className="btn btn-default btn-brand-hover rounded-pill m-0" disabled={true}>
                                                            <div className={'d-flex align-items-center'}><i className="fi-rr-clock"></i> <span className='mt-0 ml-10 fw-bold'>Pending</span></div>
                                                        </button>
                                                    ):(
                                                        <a
                                                        onClick={(e) => {
                                                            inviteNetwork(candidate?.id)
                                                            }
                                                        } className="btn-default btn rounded-pill" disabled={loading}>
                                                            <div className={'d-flex align-items-center'}><i className="fi-rr-user-add"></i> <span className='mt-0 ml-10 fw-bold'>{loading ? 'Sending...' : 'Connect'}</span></div>
                                                        </a>
                                                    )} 
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                            {(pageCount>1) ? 
                                <div className="paginations">
                                    <Paginator
                                        pageCount={pageCount}
                                        activePage={activePage}
                                        setActivePage={setActivePage}
                                        pageWindowSize={10}
                                    />
                                </div>
                            :''}
                        </div>
                        <ManageMyMetworkSideBar my_total_candidates={my_total_candidates} received={received} sent={sent} reject={reject}></ManageMyMetworkSideBar>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllNewCandidate
