import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner, Form } from 'react-bootstrap'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import Select from 'react-select'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import { CSVLink } from 'react-csv'

const OkrAnalyticPage = (prop) => {
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const queryParams = new URLSearchParams(window.location.search)
    const teamId = queryParams.get("team")
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(prop.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [jobStatus, setJobStatus] = useState([])
    const [timeLists, setTimeLists] = useState([{value: '2 Weeks', label: '2 Weeks'}, {value: '4 Weeks', label: '4 Weeks'}, {value: '12 Weeks', label: '12 Weeks'}, {value: '26 Weeks', label: '26 Weeks'}, {value: '52 Weeks', label: '52 Weeks'}])
    const [selectedTime, setSelectedTime] = useState('2 Weeks')
    const [defaultTimeselected, setdefaultTimeselected] = useState({value: '2 Weeks', label: '2 Weeks'})
    const [loading, setLoading] = useState(true)
    const [sortField, setSortField] = useState('')
    let team_name_search = sessionStorage.getItem("team_name_search")
    const [teamlist,setTeamList] = useState([{value: 'all', label: 'All'},...prop?.team_lists])
    const [selectedteam,setSelectedTeam] = useState(team_name_search && team_name_search != "null" ? JSON.parse(team_name_search).value : teamId ? teamId : '')
    const [selected,setSelected] = useState((teamId)? prop?.team_lists.filter((option) => option.value == teamId) : team_name_search && team_name_search != "null" ? JSON.parse(team_name_search) : {value: 'all', label: 'All'})
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const [exportDatas, setExportDatas] = useState([])
    const csvExportRef = React.useRef();
    const [headerCount, setHeaderCount] = useState([...prop.header_counts.map((data) => data)])
    const columns = [
        {
            name: 'No',
            selector: row => row.id,
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Recruiter Name',
            selector: row => row.accountManager,
            sortable: true,
            sortField: 'recruiter_name',
            wrap:true,
            maxWidth: '20%',
            minWidth: '20%'
        },
        {
            name: `Submission(${selectedTime == '2 Weeks' ? headerCount[0] * 1 : selectedTime == '4 Weeks' ? headerCount[0] * 2 : selectedTime == '12 Weeks' ? headerCount[0] * 6 : selectedTime == '26 Weeks' ? headerCount[0] * 13 : selectedTime == '52 Weeks' ? headerCount[0] * 26 : ''})`,
            selector: row => row.submissions,
            sortable: true,
            sortField: 'submissions',
            maxWidth: '8%',
            minWidth: '8%'
        },
        {
            name: 'Submission Rate',
            selector: row => row.submissions_rate_count,
            sortable: true,
            sortField: 'submissions',
            maxWidth: '8%',
            minWidth: '8%'
        },
        {
            name: `Confirmed Interviews(${selectedTime == '2 Weeks' ? headerCount[1] * 1 : selectedTime == '4 Weeks' ? headerCount[1] * 2 : selectedTime == '12 Weeks' ? headerCount[1] * 6 : selectedTime == '26 Weeks' ? headerCount[1] * 13 : selectedTime == '52 Weeks' ? headerCount[1] * 26 : ''})`,
            selector: row =>  row.firstInterview,
            sortable: true,
            sortField: 'first_interview',
            maxWidth: '8%',
            minWidth: '8%'
           
        },
        {
          name: 'Confirmed Interviews Rate',
          selector: row =>  row.firstInterview_rate_count,
          sortable: true,
          sortField: 'first_interview',
          maxWidth: '8%',
          minWidth: '8%'
         
        },
        {
            name: `Offers`,
            selector: row => row.offers,
            sortable: true,
            sortField: 'offer',
            maxWidth: '8%',
            minWidth: '8%'
        },
        {
            name: 'Offers Rate',
            selector: row => row.offers_rate_count,
            sortable: true,
            sortField: 'offer',
            maxWidth: '8%',
            minWidth: '8%'
        },
        {
            name: `Hires(${selectedTime == '2 Weeks' ? headerCount[3] * 1 : selectedTime == '4 Weeks' ? headerCount[3] * 2 : selectedTime == '12 Weeks' ? headerCount[3] * 6 : selectedTime == '26 Weeks' ? headerCount[3] * 13 : selectedTime == '52 Weeks' ? headerCount[3] * 26 : ''})`,
            selector: row =>   row.hired,
            sortable: true,
            sortField: 'hired',
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Hires Rate',
            selector: row =>   row.hired_rate_count,
            sortable: true,
            sortField: 'hired',
            maxWidth: '8%',
            minWidth: '8%'
        },
        {
            name: `InMails(${selectedTime == '2 Weeks' ? headerCount[4] * 1 : selectedTime == '4 Weeks' ? headerCount[4] * 2 : selectedTime == '12 Weeks' ? headerCount[4] * 6 : selectedTime == '26 Weeks' ? headerCount[4] * 13 : selectedTime == '52 Weeks' ? headerCount[4] * 26 : ''})`,
            selector: row =>   row.inmails,
            sortable: true,
            sortField: 'in_mails',
            maxWidth: '8%',
            minWidth: '8%'
        },
        {
            name: 'InMails Rate',
            selector: row =>   row.inmails_rate_count,
            sortable: true,
            sortField: 'in_mails',
            maxWidth: '8%',
            minWidth: '8%'
        },
    ]

    const headers = [
        { label: 'Recruiter Name', key: 'accountManager' },
        { label: 'Submission', key: 'submissions' },
        { label: 'Submission Rate', key: 'submissions_rate_count' },
        { label: 'Confirmed Interviews', key: 'firstInterview' },
        { label: 'Confirmed Interviews Rate', key: 'firstInterview_rate_count' },
        { label: 'Offers', key: 'offers' },
        { label: 'Offers Rate', key: 'offers_rate_count' },
        { label: 'Hires', key: 'hired' },
        { label: 'Hires Rate', key: 'hired_rate_count' },
        { label: 'InMails', key: 'inmails' },
        { label: 'InMails Rate', key: 'inmails_rate_count' },
    ];

    const fetchData = async () => {
        setLoading(true)
        const url = '/admin/okr_analytic.json'
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&selectedTime=${selectedTime}&sortField=${sortField}&sortDirection=${sortDirection}&selectedTeam=${selectedteam}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setJobStatus(response?.data?.org_status.map((data,key)=> (sr_no = sr_no + 1,{
            recruiter_rate: data?.recruiter_rate,
            id: sr_no ,
            submissions: ((selectedTime == '2 Weeks') && (data.submissions < parseInt(data.submission_limit))) ? <p style={{color: 'red'}}> {data.submissions} </p>
                        : ((selectedTime == '4 Weeks') && (data.submissions < (2 * parseInt(data.submission_limit)))) ? <p style={{color: 'red'}}> {data.submissions} </p>
                        : ((selectedTime == '12 Weeks') && (data.submissions < (6 * parseInt(data.submission_limit)))) ? <p style={{color: 'red'}}> {data.submissions} </p>
                        : ((selectedTime == '26 Weeks') && (data.submissions < (13 * parseInt(data.submission_limit)))) ? <p style={{color: 'red'}}> {data.submissions} </p>
                        : ((selectedTime == '52 Weeks') && (data.submissions < (26 * parseInt(data.submission_limit)))) ? <p style={{color: 'red'}}> {data.submissions} </p>
                        : <p>{data.submissions}</p>,
            submissions_rate_count : (data?.submissions > 0 ? `$${((data?.recruiter_rate / data?.submissions ) + ( data?.in_mails / data?.submissions )).toFixed(4)}` : '')  ,          
            firstInterview: ((selectedTime == '2 Weeks') && (data.first_interview < parseInt(data.confirmed_interview_limit))) ? <p style={{color: 'red'}}> {data.first_interview} </p>
                        : ((selectedTime == '4 Weeks') && (data.first_interview < (2 * parseInt(data.confirmed_interview_limit)))) ? <p style={{color: 'red'}}> {data.first_interview } </p>
                        : ((selectedTime == '12 Weeks') && (data.first_interview < (6 * parseInt(data.confirmed_interview_limit)))) ? <p style={{color: 'red'}}> {data.first_interview} </p>
                        : ((selectedTime == '26 Weeks') && (data.first_interview < (13 * parseInt(data.confirmed_interview_limit)))) ? <p style={{color: 'red'}}> {data.first_interview} </p>
                        : ((selectedTime == '52 Weeks') && (data.first_interview < (26 * parseInt(data.confirmed_interview_limit)))) ? <p style={{color: 'red'}}> {data.first_interview } </p>
                        : <p>{data.first_interview}</p>,
            firstInterview_rate_count: (data?.first_interview > 0 ? `$${((data?.recruiter_rate / data?.first_interview) + ( data?.in_mails / data?.first_interview )).toFixed(4)}` : ''),            
            offers: (data.offer > 0) ? <p>{data.offer}</p> : <p>0</p>,
            offers_rate_count: (data?.offer > 0 ? `$${(data?.recruiter_rate / data?.offer) + (data?.in_mails / data?.offer)}` : ''),
            accountManager: ((selectedTime == '2 Weeks') && ((data.submissions == '' || data.submissions < parseInt(data.submission_limit)) || (data.first_interview == '' || data.first_interview < parseInt(data.confirmed_interview_limit)) || (data.hired == '' || data.hired < parseInt(data.hired_limit)) || (data.in_mails == '' || data.in_mails < parseInt(data.inmails_limit)))) ? <p style={{color: 'red'}}> {data.recruiter_name + ' ' + (data?.recruiter_rate != null ? ` ($${data?.recruiter_rate})` : '')} </p>
                            : ((selectedTime == '4 Weeks') && ((data.submissions == '' || data.submissions < (2 * parseInt(data.submission_limit))) || (data.first_interview == '' || data.first_interview < (2 * parseInt(data.confirmed_interview_limit))) || (data.hired == '' || data.hired < (2 * parseInt(data.hired_limit))) || (data.in_mails == '' || data.in_mails < (2 * parseInt(data.inmails_limit))))) ? <p style={{color: 'red'}}> {data.recruiter_name + ' ' + ( data?.recruiter_rate != null ? ` ($${data?.recruiter_rate})` : '')} </p>
                            : ((selectedTime == '12 Weeks') && ((data.submissions == '' || data.submissions < (6 * parseInt(data.submission_limit))) || (data.first_interview == '' || data.first_interview < (6 * parseInt(data.confirmed_interview_limit))) || (data.hired == '' || data.hired < (6 * parseInt(data.hired_limit))) || (data.in_mails == '' || data.in_mails < (6 * parseInt(data.inmails_limit))))) ? <p style={{color: 'red'}}> {data.recruiter_name + ' ' +( data?.recruiter_rate != null ? ` ($${data?.recruiter_rate})` : '')} </p>
                            : ((selectedTime == '26 Weeks') && ((data.submissions == '' || data.submissions < (13 * parseInt(data.submission_limit))) || (data.first_interview == '' || data.first_interview < (13 * parseInt(data.confirmed_interview_limit))) || (data.hired == '' || data.hired < (13 * parseInt(data.hired_limit))) || (data.in_mails == '' || data.in_mails < (13 * parseInt(data.inmails_limit))))) ? <p style={{color: 'red'}}> {data.recruiter_name + ' ' +( data?.recruiter_rate != null ? ` ($${data?.recruiter_rate})` : '')}</p>
                            : ((selectedTime == '52 Weeks') && ((data.submissions == '' || data.submissions < (26 * parseInt(data.submission_limit))) || (data.first_interview == '' || data.first_interview < (26 * parseInt(data.confirmed_interview_limit))) || (data.hired == '' || data.hired < (26 * parseInt(data.hired_limit))) || (data.in_mails == '' || data.in_mails < (26 * parseInt(data.inmails_limit))))) ? <p style={{color: 'red'}}> {data.recruiter_name + ' ' +( data?.recruiter_rate != null ? ` ($${data?.recruiter_rate})` : '')}</p>
                            : <p>{data.recruiter_name + ' ' + (data?.recruiter_rate != null ? ` ($${data?.recruiter_rate})` : '')}</p>,
            recruiter_id:data.recruiter_id,
            hired: ((selectedTime == '2 Weeks') && (data.hired < parseInt(data.hired_limit))) ? <p style={{color: 'red'}}> {data.hired} </p>
                    : ((selectedTime == '4 Weeks') && (data.hired < (2 * parseInt(data.hired_limit)))) ? <p style={{color: 'red'}}> {data.hired} </p>
                    : ((selectedTime == '12 Weeks') && (data.hired < (6 * parseInt(data.hired_limit)))) ? <p style={{color: 'red'}}> {data?.hired } </p>
                    : ((selectedTime == '26 Weeks') && (data.hired < (13 * parseInt(data.hired_limit)))) ? <p style={{color: 'red'}}> {data?.hired } </p>
                    : ((selectedTime == '52 Weeks') && (data.hired < (26 * parseInt(data.hired_limit)))) ? <p style={{color: 'red'}}> {data?.hired } </p>
                    : <p>{data.hired}</p>,
            hired_rate_count: (data.hired > 0 ? `$${((data?.recruiter_rate / data?.hired) + (data?.in_mails / data?.hired)).toFixed(4)}` : '' )  ,        
            inmails: ((selectedTime == '2 Weeks') && (data.in_mails < parseInt(data.inmails_limit))) ? <p style={{color: 'red'}}> {data?.in_mails} </p>
                    : ((selectedTime == '4 Weeks') && (data.in_mails < (2 * parseInt(data.inmails_limit)))) ? <p style={{color: 'red'}}> {data.in_mails} </p>
                    : ((selectedTime == '12 Weeks') && (data.in_mails < (6 * parseInt(data.inmails_limit)))) ? <p style={{color: 'red'}}> {data.in_mails} </p>
                    : ((selectedTime == '26 Weeks') && (data.in_mails < (13 * parseInt(data.inmails_limit)))) ? <p style={{color: 'red'}}> {data.in_mails} </p>
                    : ((selectedTime == '52 Weeks') && (data.in_mails < (26* parseInt(data.inmails_limit)))) ? <p style={{color: 'red'}}> {data.in_mails} </p>
                    : data.in_mails == null ? 0
                    : <p>{data.in_mails} </p>,
            inmails_rate_count: (data.in_mails > 0 ? `$${((data?.recruiter_rate / data?.in_mails) + 1 ).toFixed(4)}` : '')
        })))
        setFooter({id:'Total',
                  submissions:response.data.total_values.submissions,
                  firstInterview:response.data.total_values.firstInterview,
                  secondInterview:response.data.total_values.secondInterview,
                  offers:response.data.total_values.offers,
                  hired:response.data.total_values.hired,
                  inmails:response.data.total_values.in_mail
                })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoading(false)
    }
    const exportDataMethod = async () => {
        const url = '/admin/okr_analytic.json'
        const response = await makeRequest(
            `${url}?&selectedTime=${selectedTime}&sortField=${sortField}&sortDirection=${sortDirection}&selectedTeam=${selectedteam}`,
            'get',
            {}
        )
        setExportDatas(response?.data?.org_status.map((data,key)=> ({
            id: key ,
            submissions: ((selectedTime == '2 Weeks') && (data.submissions < parseInt(data.submission_limit))) ?  data.submissions
                        : ((selectedTime == '4 Weeks') && (data.submissions < (2 * parseInt(data.submission_limit)))) ?  data.submissions
                        : ((selectedTime == '12 Weeks') && (data.submissions < (6 * parseInt(data.submission_limit)))) ?  data.submissions
                        : ((selectedTime == '26 Weeks') && (data.submissions < (13 * parseInt(data.submission_limit)))) ?  data.submissions
                        : ((selectedTime == '52 Weeks') && (data.submissions < (26 * parseInt(data.submission_limit)))) ?  data.submissions
                        : data.submissions,
            submissions_rate_count : (data?.submissions > 0 ? `$${((data?.recruiter_rate / data?.submissions ) + ( data?.in_mails / data?.submissions )).toFixed(4)}` : '') ,
            firstInterview: ((selectedTime == '2 Weeks') && (data.first_interview < parseInt(data.confirmed_interview_limit))) ?  data.first_interview
                        : ((selectedTime == '4 Weeks') && (data.first_interview < (2 * parseInt(data.confirmed_interview_limit)))) ?  data.first_interview
                        : ((selectedTime == '12 Weeks') && (data.first_interview < (6 * parseInt(data.confirmed_interview_limit)))) ?  data.first_interview
                        : ((selectedTime == '26 Weeks') && (data.first_interview < (13 * parseInt(data.confirmed_interview_limit)))) ?  data.first_interview
                        : ((selectedTime == '52 Weeks') && (data.first_interview < (26 * parseInt(data.confirmed_interview_limit)))) ?  data.first_interview
                        : data.first_interview,
            firstInterview_rate_count: (data?.first_interview > 0 ? `$${((data?.recruiter_rate / data?.first_interview) + ( data?.in_mails / data?.first_interview )).toFixed(4)}` : ''),            
            offers: (data.offer > 0) ? data.offer : 0,
            offers_rate_count: (data?.offer > 0 ? `$${(data?.recruiter_rate / data?.offer) + (data?.in_mails / data?.offer)}` : ''),
            accountManager: ((selectedTime == '2 Weeks') && ((data.submissions == '' || data.submissions < parseInt(data.submission_limit)) || (data.first_interview == '' || data.first_interview < parseInt(data.confirmed_interview_limit)) || (data.hired == '' || data.hired < parseInt(data.hired_limit)) || (data.in_mails == '' || data.in_mails < parseInt(data.inmails_limit)))) ?  data.recruiter_name
                            : ((selectedTime == '4 Weeks') && ((data.submissions == '' || data.submissions < (2 * parseInt(data.submission_limit))) || (data.first_interview == '' || data.first_interview < (2 * parseInt(data.confirmed_interview_limit))) || (data.hired == '' || data.hired < (2 * parseInt(data.hired_limit))) || (data.in_mails == '' || data.in_mails < (2 * parseInt(data.inmails_limit))))) ?  data.recruiter_name
                            : ((selectedTime == '12 Weeks') && ((data.submissions == '' || data.submissions < (6 * parseInt(data.submission_limit))) || (data.first_interview == '' || data.first_interview < (6 * parseInt(data.confirmed_interview_limit))) || (data.hired == '' || data.hired < (6 * parseInt(data.hired_limit))) || (data.in_mails == '' || data.in_mails < (6 * parseInt(data.inmails_limit))))) ?  data.recruiter_name
                            : ((selectedTime == '26 Weeks') && ((data.submissions == '' || data.submissions < (13 * parseInt(data.submission_limit))) || (data.first_interview == '' || data.first_interview < (13 * parseInt(data.confirmed_interview_limit))) || (data.hired == '' || data.hired < (13 * parseInt(data.hired_limit))) || (data.in_mails == '' || data.in_mails < (13 * parseInt(data.inmails_limit))))) ?  data.recruiter_name
                            : ((selectedTime == '52 Weeks') && ((data.submissions == '' || data.submissions < (26 * parseInt(data.submission_limit))) || (data.first_interview == '' || data.first_interview < (26 * parseInt(data.confirmed_interview_limit))) || (data.hired == '' || data.hired < (26 * parseInt(data.hired_limit))) || (data.in_mails == '' || data.in_mails < (26 * parseInt(data.inmails_limit))))) ?  data.recruiter_name
                            : data.recruiter_name,
            recruiter_id:data.recruiter_id,
            hired: ((selectedTime == '2 Weeks') && (data.hired < parseInt(data.hired_limit))) ?  data.hired
                    : ((selectedTime == '4 Weeks') && (data.hired < (2 * parseInt(data.hired_limit)))) ?  data.hired
                    : ((selectedTime == '12 Weeks') && (data.hired < (6 * parseInt(data.hired_limit)))) ?  data.hired
                    : ((selectedTime == '26 Weeks') && (data.hired < (13 * parseInt(data.hired_limit)))) ?  data.hired
                    : ((selectedTime == '52 Weeks') && (data.hired < (26 * parseInt(data.hired_limit)))) ?  data.hired
                    : data.hired,
            hired_rate_count: (data.hired > 0 ? `$${((data?.recruiter_rate / data?.hired) + (data?.in_mails / data?.hired)).toFixed(4)}` : '' ),
            inmails: ((selectedTime == '2 Weeks') && (data.in_mails < parseInt(data.inmails_limit))) ?  data.in_mails
                    : ((selectedTime == '4 Weeks') && (data.in_mails < (2 * parseInt(data.inmails_limit)))) ?  data.in_mails
                    : ((selectedTime == '12 Weeks') && (data.in_mails < (6 * parseInt(data.inmails_limit)))) ?  data.in_mails
                    : ((selectedTime == '26 Weeks') && (data.in_mails < (13 * parseInt(data.inmails_limit)))) ?  data.in_mails
                    : ((selectedTime == '52 Weeks') && (data.in_mails < (26* parseInt(data.inmails_limit)))) ?  data.in_mails
                    : data.in_mails == null ? 0
                    : data.in_mails,
            inmails_rate_count: (data.in_mails > 0 ? `$${((data?.recruiter_rate / data?.in_mails) + 1 ).toFixed(4)}` : '')

        })))
        csvExportRef?.current?.link.click();
    }

    useEffect(()=>{
        fetchData()
    },[activePage,selectedTime,sortDirection,sortField, selectedteam])

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }    
    }
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    OKR Analytics
                </P>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {/* <div class="custom-control custom-switch">

                    <input type="checkbox" class="custom-control-input" checked={teamIndia} onClick={(e)=>{
                                setTeamIndia(e.target.checked)
                                sessionStorage.setItem('india_team_only',e.target.checked)
                            }} id="cardinal_members_only"/>
                        <label class="custom-control-label" for="cardinal_members_only">Team India</label>

                            </div> */}

                    <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                        Export CSV
                    </button>
                </div>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"okr_analytic.csv"}
                ref={csvExportRef}
            ></CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
               <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
                    <div style={{display: 'grid',marginLeft:'10px'}}>
                    <label>Team Name</label>
                        <Select
                            defaultValue={selected}
                            options={teamlist}
                            onChange={(event)=>{
                              setSelectedTeam(event.value)
                              sessionStorage.setItem("team_name_search", JSON.stringify(event))
                              }}
                              name="job_name"
                              className={styles.colourStyles}
                              />
                    </div>
                    <div style={{display: 'grid',marginLeft:'10px'}}>
                        <label>
                            Select Date Range
                        </label>
                        <Select
                            defaultValue={defaultTimeselected}
                            options={timeLists}
                            onChange={(event)=>{
                                setSelectedTime(event.value)
                                sessionStorage.setItem("select", JSON.stringify(event))
                            
                            }
                            }
                            name="select"
                            className={styles.colourStyles}
                        />
                    </div>
                </Col>
            </Row>
            
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobStatus}
                progressPending={loading}
                progressComponent={ <P className="text-center  p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
    )
}

export default OkrAnalyticPage