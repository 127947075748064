import React, { useEffect } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import feather from 'feather-icons'
import styles from './styles/JobPositionCard.module.scss'
import CompanyPlaceholderImage from '../../../../assets/images/talent_page_assets/company-placeholder.png'
import CandidateSkills from '../CandidateSkills/CandidateSkills'
import {handelBrokenUrl} from '../../common/Utils'
import { capitalize } from '../../../utils'
import Moment from 'moment'
import MatchScore from '../MatchScore/MatchScore'

const JobPositionCard = (props) => {
    const {
        job,
        matchScore,
        showSkills = true,
        showSalary = true,
        showMatchScore,
        showCardinalJob = false,
        personId = false,
        onChangeBulkJobIds
    } = props
    const {
        id,
        name: jobTitle,
        portalcompanyname: companyName,
        compensation: salary,
        portalcity: location,
        skills,
        organization,
        created_at
    } = job

    // todo check if user already applied - waiting for backend support
    const alreadyApplied = false

    useEffect(() => {
        feather.replace()
    })

    const JobAvatarUrl = (logo = organization.image_url) => {
      return logo ? logo : CompanyPlaceholderImage
    }

    return (
    <a href={ (showCardinalJob) ? `../jobs/${id}` : `jobs/${id}`} className={styles.descriptionLink}>
        <Card
            className={styles.jobCard}
            style={{
                backgroundColor: `${alreadyApplied ? '#F7F7F7' : '#fff'}`,
                cursor: 'pointer',
            }}
        >
            <Card.Body>
                <Row className="align-items-center">
                    <Col
                        lg={2} md={2} sm={12} xs={12}
                        className="d-sm-flex align-items-sm-center flex-sm-column flex-lg-row text-center"
                        style={{ margin: 'auto', width: '100px',height:'auto' }}
                    >
                        {alreadyApplied && (
                            <i
                                data-feather="check"
                                className={styles.checkIcon}
                            ></i>
                        )}
                        {personId && (
                            <input type='checkbox' id={`chk_${id}`} onChange={(e)=>onChangeBulkJobIds(id,e.target.checked)} />
                        )}
                        <Image
                            src={
                                JobAvatarUrl(job.logo)
                            }
                            fluid
                            className={styles.jobCardImage, 'companyJobLogo'}
                            style={{ margin: 'auto', width: '100px',height:'auto' }}
                            onError={(e) => {handelBrokenUrl(e)}}
                        />
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={12} className="px-0">
                        <Row>
                            <Col className={styles.jobCardCol}>
                                <p className={styles.jobCardText}>
                                    <a href={ (showCardinalJob) ? `../organizations/${organization?.id}/careers` : `organizations/${organization?.id}/careers`}>{organization?.name}</a>
                                    <span className={styles.jobCardText}>&nbsp;&nbsp;{job?.location !== "null" ? job?.location && capitalize(job?.location?.replace('(US)','')?.replace('(usa)','')?.replace('(USA)','')) : 'No location found'}</span>
                                    {job.status === 'closed' && (
                                <span className={styles.jobClosed}>
                                    Closed
                                </span> 
                                )}
                                {job.status === 'expired' && (
                                <span className={styles.jobExpired}>
                                    Expired
                                </span> 
                                )}
                                {job.status === 'pending' && (
                                <span className={styles.jobPending}>
                                    Pending
                                </span> 
                                )}
                                {job.status === 'rejected' && (
                                <span className={styles.jobRejected}>
                                    Rejected
                                </span> 
                                )}
                                </p>
                                <span className={styles.jobCardTitle}>
                                    {jobTitle}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {showSkills && (
                                    <Row>
                                        <Col>
                                            <CandidateSkills skills = {skills} />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                        {showSalary && (
                            <Row>
                                <Col xs="auto">
                                    <p className={styles.jobCardText}>
                                        Salary:
                                    </p>
                                </Col>
                                <Col className="pl-0">
                                    <p className={styles.jobCardText}>
                                        {salary}
                                    </p>
                                </Col>
                            </Row>
                        )}
                    </Col>
                  
                    <Col
                        lg={2} md={2} sm={12} xs={12}
                    >
                        {/* <MatchScore score={matchScore} big={true} /> */}
                        {showMatchScore && matchScore && (
                            <div className="align-items-center justify-content-end">
                                <MatchScore score={matchScore} big={true} />
                            </div>
                        )}
                        <div className='text-center mt-1'>
                            <p className={styles.jobCardText}>Posted: {Moment(created_at).fromNow()}</p>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </a>
    )
}

export default JobPositionCard