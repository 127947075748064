import React, { Fragment, useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { Form, Col } from 'react-bootstrap'
import axios from 'axios'
import _ from 'lodash'
import CloseButton from '../Styled components/CloseButton'
import { colourStyles } from './styles/JobSelect.styles'
import { Box, Row, Button, W4text,Error } from './styles/JobModal.styled'

const initialState = {
  niceToHaveSkills: '',
  booleanKeywordSearch: '',
  mustHaveKeyword: '',
  educationPreferences: '',
  companyPreferrences: '',
  locationPreferrences: '',
  experienceYears: '',
  preferredTitles: '',
  sourcerNotes: '',
  employmentTypes: '',
  workAuthorization:'',
  compensation:'',
  industry:'',
  titleAvoid:'',
  companiesAvoid:'',
  targetLocationAvoid:'',
  jobDescriptionLink:'',
  jobIdeallinkedInUrl: '',
  manualSourcing: '',
  preferredTopSchool: ''
}

const workAuthorizationTypes = [
  {value: '', label: 'Select...'},
  {value:'US Citizen', label: 'US Citizen'},
  {value:'Permanent Resident', label: 'Permanent Resident'},
  {value:'H-1B', label: 'H-1B'},
  {value:'OPT', label: 'OPT'},
  {value: 'Not Specified', label: 'Not Specified'}
]

const manualSourcingOption = [
  {value: '', label: 'Select...'},
  {value: 'true', label: 'Yes'},
  {value: 'false', label: 'No'},
]

function JobWishlist({ onClose, handleSubmit, job, editMode }) {
  const [jobWishList, setJobWishList] = useState(initialState)
  const [isLoading, setIsLoading] = useState({
    company: false,
    location: false,
    education: false,
  })
  const [modal,setModal] = useState()
  const [defaultWorkAuthorization, setDefaultWorkAuthorization] = useState((job?.work_authorization && job?.work_authorization != "null") ? {value: job?.work_authorization, label: job?.work_authorization} : {value: '', label: 'Select...'} )
  const [defaultManualSourcing, setDefaultManualSourcing] = useState(
    (job?.manual_sourcing && job?.manual_sourcing != "null") &&
    job?.manual_sourcing == true ?
      {value: job?.manual_sourcing, label: 'Yes', } :
        job?.manual_sourcing == false ?
          {value: job?.manual_sourcing, label: 'No'} :
    {value: '', label: 'Select...'} )
  const [showWishList,setShowWishList] = useState(editMode)
  const [validationError, setValidationError] = useState({
    ideal_linked_in_url: null,
    job_description_link: null,
    preferred_titles: null,
    location_preferrences: null,
    boolean_keyword_search: null,
    must_have_keyword: null,
    experience_years: null
  });

  useEffect(() => {
    let whishlist = {
      ...jobWishList,
      ...{
        niceToHaveSkills: job?.nice_to_have_keywords == "null" ? '' : job?.nice_to_have_keywords,
        booleanKeywordSearch: job?.boolean_keyword_search == "null" ? '' : job?.boolean_keyword_search,
        educationPreferences: job?.education_preference == "null" ? '' : job?.education_preference,
        companyPreferrences: job?.company_preference == "null" ? '' : job?.company_preference,
        preferredTitles: job?.prefered_titles == "null" ? '' : job?.prefered_titles,
        experienceYears: job?.experience_years == "null" ? '' : job?.experience_years,
        mustHaveKeyword: job.keywords == "null" ? '' : job.keywords,
        sourcerNotes: job.sourcer_notes == "null" ? '' : job.sourcer_notes,
        employmentTypes: job.employment_type == "null" ? '' : job.employment_type,
        workAuthorization: job.work_authorization == 'null' ? '' : job.work_authorization,
        compensation: job.compensation == "null" ? '' : job.compensation,
        locationPreferrences: job.location_preference == "null" ? '' : job.location_preference,
        industry: job.industry == "null" ? '' : job.industry,
        titleAvoid: job.title_avoid == "null" ? '' : job.title_avoid,
        companiesAvoid: job.companies_avoid == "null" ? '' : job.companies_avoid,
        targetLocationAvoid: job.target_location_avoid == "null" ? '' : job.target_location_avoid,
        jobDescriptionLink: job.job_description_link == "null" ? '' : job.job_description_link,
        jobIdeallinkedInUrl: job.ideal_linked_in_url == "null" ? '' : job.ideal_linked_in_url,
        manualSourcing: job.manual_sourcing == null ? '' : job.manual_sourcing,
        preferredTopSchool: (job?.prefered_top_schools == "null" && job?.prefered_top_schools == null) ? '' : job?.prefered_top_schools,
      },
    }
    setJobWishList((prev) => ({ ...prev, ...whishlist }))
  }, [job])

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setJobWishList((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSinglePreferences = (values, type) => {
    let newJobWishList = { ...jobWishList, [type]: values?.value}
    setJobWishList(newJobWishList)
  }

  const checkValidation = () => {
    setValidationError({})
    let isValid = true
    if(!_.isEmpty(jobWishList.jobIdeallinkedInUrl)){
      const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'); 
      if(!(regex.test(jobWishList.jobIdeallinkedInUrl) && jobWishList.jobIdeallinkedInUrl.includes("linkedin")))
      {
        setValidationError((prev) => ({
          ...prev,
          ideal_linked_in_url: 'Invalid Linkedin URL',
        }));
        isValid = false
      }
    }
    if(!_.isEmpty(jobWishList.jobDescriptionLink)){
      const regex1 = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'); 
      const regex2 = new RegExp('(http?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'); 
      if(!(regex1.test(jobWishList.jobDescriptionLink)) || !(regex2.test(jobWishList.jobDescriptionLink)))
      {
        setValidationError((prev) => ({
          ...prev,
          job_description_link: 'Invalid Job Description Link',
        }));
        isValid = false
      }
    }
    if(_.isEmpty(jobWishList.preferredTitles?.toString()?.trim())){
      setValidationError((prev) => ({
        ...prev,
        preferred_titles: 'Current Titles is required',
      }));
      isValid = false
    }
    if(_.isEmpty(jobWishList.locationPreferrences?.toString()?.trim())){
      setValidationError((prev) => ({
        ...prev,
        location_preferrences: 'Target Location is required',
      }));
      isValid = false
    }
    if(_.isEmpty(jobWishList.booleanKeywordSearch?.toString()?.trim())){
      setValidationError((prev) => ({
        ...prev,
        boolean_keyword_search: 'Boolean / Keywords Search is required',
      }));
      isValid = false
    }
    if(_.isEmpty(jobWishList.mustHaveKeyword?.toString()?.trim())){
      setValidationError((prev) => ({
        ...prev,
        must_have_keyword: 'Must have keyword is required',
      }));
      isValid = false
    }
    if(_.isEmpty(jobWishList.experienceYears?.toString()?.trim())){
      setValidationError((prev) => ({
        ...prev,
        experience_years: 'Experience is required',
      }));
      isValid = false
    }
    // else{
    //   setValidationError((prev) => ({
    //     ...prev,
    //     job_description_link: 'Job Description Link is required',
    //   }));
    //   isValid = false
    // }
    return isValid
  };

  const handleSubmitResponse = () => {
    if(checkValidation()){
      const jobData = new FormData()
      jobData.append('job[nice_to_have_keywords]',(jobWishList?.niceToHaveSkills)?.trim() || '')
      jobData.append('job[keywords]',(jobWishList?.mustHaveKeyword)?.trim() || '')
      jobData.append('job[prefered_titles]',(jobWishList?.preferredTitles)?.trim() || '')
      jobData.append('job[experience_years]',(jobWishList?.experienceYears)?.trim() || '')
      jobData.append('job[education_preference]',(jobWishList?.educationPreferences)?.trim() || '')
      jobData.append('job[company_preference]',(jobWishList?.companyPreferrences)?.trim() || '')
      jobData.append('job[location_preference]',( jobWishList?.locationPreferrences)?.trim() || '')
      jobData.append('job[sourcer_notes]',(jobWishList?.sourcerNotes)?.trim() || '')
      jobData.append('job[employment_type]',(jobWishList?.employmentTypes)?.trim() || '')
      jobData.append('job[work_authorization]',(jobWishList?.workAuthorization)?.trim() || '') 
      jobData.append('job[compensation]',(jobWishList?.compensation)?.trim() || '')
      jobData.append('job[boolean_keyword_search]',(jobWishList?.booleanKeywordSearch)?.trim() || '')
      jobData.append('job[prefered_top_schools]',(jobWishList?.preferredTopSchool)?.trim() || '')
      jobData.append('job[industry]',(jobWishList?.industry)?.trim() || '')
      jobData.append('job[title_avoid]',(jobWishList?.titleAvoid)?.trim() || '')
      jobData.append('job[companies_avoid]',(jobWishList?.companiesAvoid)?.trim() || '')
      jobData.append('job[target_location_avoid]',(jobWishList?.targetLocationAvoid)?.trim() || '')
      jobData.append('job[job_description_link]',(jobWishList?.jobDescriptionLink)?.trim() || '')
      jobData.append('job[ideal_linked_in_url]',(jobWishList?.jobIdeallinkedInUrl)?.trim() || '')
      jobData.append('job[manual_sourcing]',jobWishList?.manualSourcing)
      jobData.append('job[flag]', 'Updated sourcing requirement to a Job')
      
      if( !_.isEmpty((jobWishList?.niceToHaveSkills)?.trim())
          || !_.isEmpty((jobWishList?.booleanKeywordSearch)?.trim())
          || !_.isEmpty((jobWishList?.preferredTopSchool)?.trim())
          || !_.isEmpty((jobWishList?.mustHaveKeyword)?.trim())
          || !_.isEmpty((jobWishList?.preferredTitles)?.trim())
          || !_.isEmpty((jobWishList?.experienceYears)?.trim())
          || !_.isEmpty((jobWishList?.educationPreferences)?.trim())
          || !_.isEmpty((jobWishList?.companyPreferrences)?.trim())
          || !_.isEmpty((jobWishList?.locationPreferrences)?.trim())
          || !_.isEmpty((jobWishList?.sourcerNotes)?.trim())
          || !_.isEmpty((jobWishList?.employmentTypes)?.trim())
          || !_.isEmpty((jobWishList?.workAuthorization)?.trim())
          || !_.isEmpty((jobWishList?.compensation)?.trim())
          || !_.isEmpty((jobWishList?.industry)?.trim())
          || !_.isEmpty((jobWishList?.titleAvoid)?.trim())
          || !_.isEmpty((jobWishList?.companiesAvoid)?.trim())
          || !_.isEmpty((jobWishList?.targetLocationAvoid)?.trim())
          || !_.isEmpty((jobWishList?.jobIdeallinkedInUrl)?.trim())
          || !_.isEmpty((jobWishList?.jobDescriptionLink)?.trim())
          || jobWishList?.manualSourcing == "true"
        ){
          jobData.append('job[source_at]', true)
      }else{
          jobData.append('job[source_at]', false)
      }
      handleSubmit(jobData)
    }
  }
  
  return (
    <Fragment>
      <Row direction="row" aItems="center">
        <Box>
          <W4text color="#16246B" size="24px">
            Sourcing Requirements
          </W4text>
        </Box>
        <div style={{ flexGrow: '1' }} />
        {showWishList &&
          <Box title='Edit' onClick={()=>setShowWishList(false)}><i data-feather="edit-2" style={{ marginRight: '16px', color: 'blue'}}></i>
          </Box>
        }
        {/* <Box  data-feather="edit-2" style={{ marginRight: '16px', color: 'blue'}}>
          <CloseButton onClick={() => setShow(true)} />
        </Box> */}
        <Box>
          <CloseButton handleClick={() => onClose()} />
        </Box>
      </Row>
      <Form>
        <Form.Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>
                Job Description Link
              </Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='url'
                className="form-control"
                name="jobDescriptionLink"
                onChange={handleChange}
                value={jobWishList.jobDescriptionLink}
              />
              :<p>{jobWishList.jobDescriptionLink || '-'}</p>
              }
              {validationError.job_description_link && (
                  <Error>{validationError.job_description_link}</Error>
              )}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>
                Specific Notes for Sourcer
              </Form.Label>
              {
              !showWishList ?
              <Form.Control
                as="textarea"
                className="form-control"
                name="sourcerNotes"
                onChange={handleChange}
                rows={3}
                value={jobWishList.sourcerNotes}
              />
              :<p>{jobWishList.sourcerNotes || '-'}</p>
              } 
            </Form.Group>
          </Col>
          
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>
                Current Titles<span className='text-danger'>*</span>
              </Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="preferredTitles"
                onChange={handleChange}
                value={jobWishList.preferredTitles}
              />
              :<p>{jobWishList.preferredTitles || '-'}</p>
              }
              {!showWishList && 
                <small className='text-danger'>{validationError.preferred_titles}</small>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>
                Titles to Avoid
              </Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="titleAvoid"
                onChange={handleChange}
                value={jobWishList.titleAvoid}
              />
              :<p>{jobWishList.titleAvoid || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>
                Target Companies
              </Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="companyPreferrences"
                onChange={handleChange}
                value={jobWishList.companyPreferrences}
              />
              :<p>{jobWishList.companyPreferrences || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>
                Target Companies to Avoid
              </Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="companiesAvoid"
                onChange={handleChange}
                value={jobWishList.companiesAvoid}
              />
              :<p>{jobWishList.companiesAvoid || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}} >Target Locations (Separated by comma)<span className='text-danger'>*</span></Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="locationPreferrences"
                onChange={handleChange}
                value={jobWishList.locationPreferrences}
              />
              :<p>{jobWishList.locationPreferrences || '-'}</p>
              }
              {!showWishList && 
                <small className='text-danger'>{validationError.location_preferrences}</small>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>
                Target Locations to Avoid
              </Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="targetLocationAvoid"
                onChange={handleChange}
                value={jobWishList.targetLocationAvoid}
              />
              :<p>{jobWishList.targetLocationAvoid || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>Must have keyword (Separated by comma)<span className='text-danger'>*</span></Form.Label>
              {
              !showWishList ?
                <Form.Control
                  type='text'
                  className="form-control"
                  name="mustHaveKeyword"
                  onChange={handleChange}
                  value={jobWishList.mustHaveKeyword}
                />
                :<p>{jobWishList.mustHaveKeyword || '-'}</p>
              }
              {!showWishList && 
                <small className='text-danger'>{validationError.must_have_keyword}</small>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}} >Nice to have keyword (Separated by comma)</Form.Label>
              {
              !showWishList ?
                <Form.Control
                  type='text'
                  className="form-control"
                  name="niceToHaveSkills"
                  onChange={handleChange}
                  value={jobWishList.niceToHaveSkills}
                />
                :<p>{jobWishList.niceToHaveSkills || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>
                Target Schools (Separated by comma)
              </Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="preferredTopSchool"
                onChange={handleChange}
                value={jobWishList.preferredTopSchool}
              />
              :<p>{jobWishList.preferredTopSchool || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>
                Boolean / Keywords Search <span className='text-danger'>*</span>
              </Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="booleanKeywordSearch"
                onChange={handleChange}
                value={jobWishList.booleanKeywordSearch}
              />
              :<p>{jobWishList.booleanKeywordSearch || '-'}</p>
              }
              {!showWishList && 
                <small className='text-danger'>{validationError.boolean_keyword_search}</small>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>Industry</Form.Label>
              {
              !showWishList ?
                <Form.Control
                  type='text'
                  className="form-control"
                  name="industry"
                  onChange={handleChange}
                  value={jobWishList.industry}
                />
                :<p>{jobWishList.industry || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>Employment Type</Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="employmentTypes"
                onChange={handleChange}
                value={jobWishList.employmentTypes}
              />
              :<p>{jobWishList.employmentTypes || '-'}</p>
            }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>Work Authorization</Form.Label>
              {
              !showWishList ?
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder='select...' 
                styles={colourStyles}
                onChange={(values) =>
                  handleSinglePreferences(
                        values,
                        'workAuthorization'
                    )
                }
                options={workAuthorizationTypes}
                defaultValue={defaultWorkAuthorization}
                menuPosition='fixed'
              />  :
              <p>{defaultWorkAuthorization?.value || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>Experience<span className='text-danger'>*</span></Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="experienceYears"
                onChange={handleChange}
                value={jobWishList.experienceYears}
              />
              :<p>{jobWishList.experienceYears || '-'}</p>
             }
             {!showWishList && 
                <small className='text-danger'>{validationError.experience_years}</small>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>Education Preferences</Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="educationPreferences"
                onChange={handleChange}
                value={jobWishList.educationPreferences}
              />
              :<p>{jobWishList.educationPreferences || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>Compensation</Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="compensation"
                onChange={handleChange}
                value={jobWishList.compensation}
              />
              :<p>{jobWishList.compensation || '-'}</p>
              }
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>Ideal Candidate LinkedIn Profile</Form.Label>
              {
              !showWishList ?
              <Form.Control
                type='text'
                className="form-control"
                name="jobIdeallinkedInUrl"
                onChange={handleChange}
                value={jobWishList.jobIdeallinkedInUrl}
              />
              :<p>{jobWishList.jobIdeallinkedInUrl || '-'}</p>
              }
              {validationError.ideal_linked_in_url && (
                  <Error>{validationError.ideal_linked_in_url}</Error>
              )}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label style= {{fontWeight: 'bold'}}>Manual Sourcing (Choose yes for complex and difficult roles)</Form.Label>
              {
              !showWishList ?
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder='select...' 
                styles={colourStyles}
                onChange={(values) =>
                  handleSinglePreferences(
                        values,
                        'manualSourcing'
                    )
                }
                options={manualSourcingOption}
                defaultValue={defaultManualSourcing}
                menuPosition='fixed'
              />
              : jobWishList?.manualSourcing == true ?
                 <p className='text-danger font-weight-bold'>{jobWishList?.manualSourcing == true && 'Yes'}</p> :
                 <p>{jobWishList?.manualSourcing == false && 'No' || '-'}</p>

              }
              {validationError.manualSourcing && (
                  <Error>{validationError.manualSourcing}</Error>
              )}
            </Form.Group>
          </Col>
        </Form.Row>
        {!showWishList &&
          <>
            <Row jContent="flex-end" style={{marginTop: '7px'}}>
              <Button
                variant="primary"
                style={{
                  margin: 0,
                  marginRight:'10px'
                }}
                type="button"
                onClick={() => handleSubmitResponse()}
              >
                Update
              </Button>
              <Button
                variant="primary"
                style={{
                  margin: 0,background: 'red'
                }}
                type="button"
                onClick={()=>setShowWishList(true)}
              >
                Cancel
              </Button>
            </Row>
          </>
        }
      </Form>
    </Fragment>



  )
}

export default JobWishlist
