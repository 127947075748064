import React, { useEffect, useState } from 'react'
import { Modal, Alert, Button } from 'react-bootstrap'
import axios from 'axios'
import styles from './styles/AccountSettingPage.module.scss'
import Input from '../../common/Styled components/Input'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { capitalize } from '../../../utils'
import {
  Row,
  Wrapper,
} from './styles/AccountSettingPage.styled'

const EmailVerificationPage = (props) => {
  const { currentUser } = props
  const { email_data } = props
  const { is_cm } = props
  const { id, email, first_name, last_name, account_email_verified } = currentUser
  const [userEmail, setUserEmail] = useState(email)
  const [isError, setIsError] = useState(false)
  const [isNote, setIsNote] = useState(false)
  const [agreement,setAgreement] = useState()
  const handleVerification = async (event) => {
    const URL = '/account/email_verification'
    const payload = new FormData()
    payload.append('email_verification[email]', email)
    const result = await axios
      .post(URL, payload)
      .then((response) => {
        window.open(response.data.authorization_uri, '_blank')
      })
      .catch((error) => console.log(error))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if(!userEmail.includes('gmail')){
      return setIsError(true)
    }else{
      handleVerification(event)
    }
  }

  const handleCheck = (e) => {
    if(e.target.checked)
      setAgreement(true)
    else
      setAgreement(false)
  }
  const handleYes = (e) => {
    setIsError(false)
    handleVerification(e)
  };

  const handleClose = () => {
    setIsError(false);
    setIsNote(true);
  }

  const handleCreateServer = async () => {
    const url = '/start/campaign'
    await makeRequest(url, 'get', {}, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
        onSuccess: (res) => {
            if((currentUser.role == 'talent')){
              window.location.href = '/search_employer'
            }else{
              window.location.href = '/bulk_upload'
            }            
        },
    })
}


  return (
    <div className={styles.accountSettingPage}>
    <Wrapper className={`${styles.accontSetting}`}>
        <div className={`${styles.sidebar}`}>
          <div className={`${styles.sidebar_header}`}>
            <p>{capitalize(`${first_name} ${last_name}`)}</p>
          </div>
          <a href="/account/setting">
            General Settings
          </a>
          <a href="/account/security">Security & Login</a>
          <a className={`${styles.active}`} href="/account/email_verification">Email Verification</a>
          { (currentUser.role == 'recruiter') &&
                        <>
                            <a  href="/account/email_configuration">SMTP Email Configuration</a>
                        </>
                    }
          { (is_cm > 0) && 
              <a href="/account/mixmax_configuration">Mixmax Configuration</a>
          }
           { (currentUser.role == 'recruiter') && 
              <a href="/account/organization_management">Organization Membership Management</a>
           }
            {(currentUser.role == 'employer' || currentUser.role == 'recruiter') && 
              <a href="/account/organization_agreement">Organization Agreement</a>
            }
          <a href="/account/invite_your_friend">Invite Your Friend</a>
          {currentUser.role == 'talent' &&
                  <a href="/account/email_configuration">SMTP Email Configuration</a>
                  }
           
        </div>
        <div className={`${styles.containt}`}>
          <div className={`${styles.containt_email_verify}`}>
            <h3>Email Verification</h3>
            {agreement === false && 
              <Alert variant="danger">Please agree to privacy policy and terms and conditions.</Alert>
            }
            <div className={`${styles.basicinfodetail}`}>
              <>
                <form onSubmit={(e) => {handleSubmit(e)}}>
                  <Row>
                    <Input
                      label="Email"
                      value={userEmail}
                      name="email"
                      type="email"
                      width="20rem"
                      onChange={event => setUserEmail(event.target.value)}

                    />
                  </Row>
                  <>
                    <Modal
                      show={isError}
                      centered
                    >
                      <Modal.Body style={{ paddingLeft: '10px' }}>Are you using Google workspace (G-Suite) for your email account?</Modal.Body>
                      <Modal.Footer xs={12} style={{ justifyContent: 'center' }}>
                        <Button variant="primary" onClick={(e) => {handleYes(e)}}>
                          Yes
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                          No
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {isNote &&
                      <>
                        <div className="text-danger">Sorry, right now we only support email account that uses Google workspace (G-Suite).</div>
                      </>
                    }
                  </>

                  { !account_email_verified &&
                    <>
                      <Row aItems="center">
                        <input
                          label="no-label"
                          type="checkbox"
                          name="terms_and_policy"
                          id="terms_and_policy"
                          value="I agree to Privacy Policy and Terms and Conditions."
                          onChange={(e)=>handleCheck(e)}
                        />
                        <span className="ml-2">I agree to the <a href="/terms_of_service">Terms and Conditions</a> & <a href="/privacy_policy">Privacy Policy</a>.</span>
                        <br />
                      </Row>
                      <Row>
                        <button
                          className={`${styles.editButton}`}
                          disabled={!agreement}
                        >
                          Verify Me
                        </button>
                      </Row>
                    </>
                  }
                  <Row>
                  </Row>
                </form>
              </>
            </div>
          </div>
          <Alert variant="danger">
            <p>
              Note:<br />
              Currently, we ONLY support those email accounts that uses Google workspace (G-Suite).<br />
              If you are not using your Google workspace (G-Suite), you will not be able to use automated E-Mail Sequencing functionality correctly.<br />
              If you would like to send email from your Gmail account, then please update your email address. We will link your Gmail account with your CardinalTalent account so that, you can contact candidate directly from CardinalTalent platform.
            </p>
          </Alert>
          <div>
            { ( (account_email_verified == true) && (email_data != null && email_data.refresh_token != null &&  email_data.refresh_token != '' )  && ((currentUser?.email_campaign_api_token == null) || (currentUser?.email_campaign_server_id == null))) &&
                <div className='row' style={{ marginTop: '30px'}}>
                    <button
                        type="button"
                            className={`${styles.editButton}`}
                            onClick={handleCreateServer}
                        >
                            Start Email Campaign
                    </button>
                </div>
            }
         </div>
        </div>
       
      </Wrapper>
    </div>
  )
}

export default EmailVerificationPage