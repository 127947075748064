import React from 'react';
import { makeRequest } from '../components/common/RequestAssist/RequestAssist'
import validator from 'validator'

const initialState = {
  currentUser: null,
  person: null,
  firstName: '',
  lastName: '',
  employer: '',
  title: '',
  school: '',
  location: '',
  skills: '',
  description: '',
  avatar_url: null,
  experiences: [],
  educations: [],
  deleteEducationId: [],
  deleteExperienceId: [],
  skillset: '',
  portfolio: '',
  transfer: '',
  authorization: '',
  state: '',
  linkedin_profile_url:'',
  compensation: '',
}

const reducer = (state, action) => {
  const {type, value, resume, source, linkdin_url, calendly_link} = action
  switch(type) {
    case 'update_user_profile':
      const {first_name, last_name, person, employer, school, title, location, skills, description, currentUser, avatar_url, experiences, educations, deleteEducationId, deleteExperienceId, tech_group, portfolio, transfer, authorization, states, compensation, sponsorship} = value
        const formData = new FormData()
        formData.append('person[first_name]', (first_name)?.trim() || '')
        formData.append('person[last_name]', (last_name)?.trim() || '')
        formData.append('person[portfolio]', (person?.portfolio)?.trim() || '')
        formData.append('person[transfer]', (person?.transfer)?.trim() || '')
        formData.append('person[authorization]', (person?.authorization)?.trim() || '')
        formData.append('person[state]', (person?.state)?.trim() || '')
        formData.append('person[compensation]', (person?.compensation)?.trim() || '')
        formData.append('person[sponsorship]', (person?.sponsorship)?.trim() || '')
        if(employer != null){
          formData.append('person[employer]', (employer)?.trim() || '')
        }
        if(school != null){
          formData.append('person[school]',  school)
        }
        if(title != null){
          formData.append('person[title]',  (title)?.trim() || '')
        }
        if(location != null){
          formData.append('person[location]', (location)?.trim() || '')
        }
        if(description != null){
          formData.append('person[description]', (description)?.trim() || '')
        }
        formData.append('person[skills]', skills.join(','))
        formData.append('id', currentUser.id)

        if(typeof avatar_url == 'object' && avatar_url != null) {
          formData.append('person[avatar]', avatar_url)
        }
        if(resume != null){
          formData.append('person[resume]',  (resume) || '')
        }
        if(linkdin_url != null){
          formData.append('person[linkedin_profile_url]',  (linkdin_url) || '')
        }
        if(calendly_link != null){
          formData.append('person[calendly_link]',  (calendly_link) || '')
        }
        if(source){
          formData.append('person[audio_video]',(source) || '')
        }
        let exp = []
        experiences.map(({id,title, company_name,description, location, start_date, end_date, present},i)=>{
          if(title != '' && company_name != ''){ 
            exp.push({
              id: id,
              title: title,
              location: location,
              description: description,
              company_name: company_name,
              start_date: start_date,
              end_date: end_date,
              present: present,
          })
          }
        })
        formData.append(
            'person[experiences]',
            JSON.stringify(exp)
        )
        let edu = []
        educations.map(({id, school_name, degree, from, to},i)=>{
          if(school_name != '' && degree != ''){
            edu.push({
              id: id,
              school_name: school_name,
              degree: degree,
              from: from,
              to: to,
            })
          }
        })
        formData.append(
          'person[educations]',
          JSON.stringify(edu)
        )
        formData.append(
          'person[remove_edu_id]',
          JSON.stringify(deleteEducationId)
      )
        formData.append(
            'person[remove_exp_id]',
            JSON.stringify(deleteExperienceId)
        )

        if(tech_group != null){
          formData.append('person[tech_group]', tech_group)
        }

        const url = `${currentUser.id}`
        sendRequest(formData, url)
     
      return {...state, ...value}
    default:
      return state
  }
}

async function sendRequest(formData, url) {

  await makeRequest(url, 'put', formData, {
    contentType: 'multipart/form-data',
    loadingMessage: 'Submitting...',
    createResponseMessage: () => {
      return {
        message: 'Update successful',
        messageType: 'success',
        loading: false,
        autoClose: true,
    }
    },
    onSuccess: () => {
    window.location.reload()
    }
   })
}

// context for user profile page
const UserProfileContext = React.createContext(null)

export {initialState, reducer, UserProfileContext}