import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col} from "react-bootstrap";
import { makeRequest } from "../../../common/RequestAssist/RequestAssist";
import feather from 'feather-icons'

const InviteInterviewPage = ({setInviteInterviewModal, inviteInterviewModal,interview_id}) => {
    const [errormessage, setErrorMessage] = useState()
    const [selectedFile, setSelectedFile] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(false)
    // State to hold the list of candidates
    const [candidates, setCandidates] = useState([{ name: "", email: "" }]);

    useEffect(() => {
        feather.replace();
    }, []);

    // Handler to add a new candidate row
    const addCandidate = () => {
        setCandidates([...candidates, { name: "", email: "" }]);
    };

    const handleCSVUpload = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    // Handler to remove a candidate row
    const removeCandidate = (index) => {
        const updatedCandidates = candidates.filter((_, i) => i !== index);
        setCandidates(updatedCandidates);
    };

    // Handler to remove the selected file
    const removeSelectedFile = () => {
        setSelectedFile(null);
    };


    // Handler to update the name and email of a candidate
    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const updatedCandidates = candidates.map((candidate, i) =>
        i === index ? { ...candidate, [name]: value } : candidate
        );
        setCandidates(updatedCandidates);
    };

    const validateData = async () => {
        setErrorMessage(""); // Clear any existing error
        // Validate that all name and email fields are filled
        let error = 0
        if (selectedFile == null){
            for (let i = 0; i < candidates.length; i++) {
                if (!candidates[i].name || !candidates[i].email) {
                    error = 1
                }
            }
        }
        if(error == 1){
            setErrorMessage("Please fill in all the name and email fields or Upload CSV file.")
            return false
        }
        return true
    }

       
    const onInviteInterview = async () => {
        if(await validateData()){
            setBtnDisabled(true)
            const payload = new FormData()
            payload.append('invite_candidate[interview_id]', interview_id)
            payload.append('invite_candidate[candidates]', JSON.stringify(candidates))
            if (selectedFile) {
                payload.append('invite_candidate[file]', selectedFile);
            }
            let URL = '/ai_interviews/candidate_invite'
            
            makeRequest(URL,'post', payload, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
            }).then((res) => {
                if(res.data.messageType == 'success'){
                    setInviteInterviewModal(false)
                    setBtnDisabled(false)
                }
            }) 
        }
    }

    return(
        <>
            <Modal
                show={inviteInterviewModal}
                onHide={() => setInviteInterviewModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                centered
                backdrop="static"
            >
                <Modal.Body >
                    <div className="d-flex justify-content-between">
                        <h4 className="font-weight-bold mb-2">Invite Candidates</h4>
                        <span onClick={() => setInviteInterviewModal(false)} role="button">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '30px', height: '30px' }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </span>
                    </div>
                    <h6>Invite up to 1,000 people at once.</h6>
                    <Form className="border rounded-lg p-3 shadow mt-1 mb-2">
                        {candidates.map((candidate, index) => (
                            <div key={index} >
                                <Row key={index} >
                                    <Col className="col-md-5">
                                        <Form.Group>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={candidate.name}
                                                onChange={(event) => handleChange(index, event)} 
                                                placeholder="John Doe" />
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-md-5">
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control 
                                                name="email"
                                                type="email" 
                                                value={candidate.email}
                                                onChange={(event) => handleChange(index, event)}
                                                placeholder="name@example.com" 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-md-2">
                                    {candidates.length > 1 && index !== 0 && (
                                            <button className="d-inline-flex text-primary pr-2 mt-4" type="button" onClick={() => removeCandidate(index)} style={{ marginRight: "10px",marginTop: '40px' }}>
                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#C2040F"><path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18.8504 9.14001L18.2004 19.21C18.0904 20.78 18.0004 22 15.2104 22H8.79039C6.00039 22 5.91039 20.78 5.80039 19.21L5.15039 9.14001" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.3301 16.5H13.6601" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.5 12.5H14.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                            </button>
                                    )}
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        
                    </Form>
                    <div className="d-inline-flex text-primary pl-2 mt-2">
                        <a role="button" className="d-inline-flex text-primary pr-2" onClick={addCandidate} style={{ marginBottom: "10px",alignItems: "center" }}>
                            <i data-feather="plus" width="20" height="20"></i> Add Another Candidate
                        </a>
                        <span className="vl pr-2"></span>
                    
                        {!selectedFile && (
                            <>
                            <input
                                type="file"
                                id="file"
                                accept=".csv"
                                onChange={handleCSVUpload}
                                style={{ display: "none" }} // Hiding the default file input
                            />
                            <label htmlFor="file">
                            <a role="button" className="d-inline-flex" style={{ alignItems: "center" }}><i data-feather="plus" width="20" height="20"></i> Upload CSV File</a>
                            </label>
                            </>
                        )}
                        {selectedFile && (
                            <p className="pr-2 ml-2">
                                {selectedFile.name}
                                <button type="button" onClick={removeSelectedFile} style={{ marginLeft: "10px" }}>
                                X
                                </button>
                            </p>
                        )}
                        <span className="vl ml-2 pr-2"></span>
                        <a href={'https://cardinaltalent-ai-interview.s3.us-east-2.amazonaws.com/invite_candidate_sample.csv'}><i data-feather="download"  width="18" height="18"></i> Download Format</a>
                    </div>
                    <p style={{marginLeft:'10px', color:'red', fontSize:'14px'}}>{errormessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                        {/* <Button variant="outline-primary" className="mr-3" onClick={() => setInviteInterviewModal(false)}>Close</Button> */}
                        <Button variant="primary" className="btn-pk-color" onClick={onInviteInterview} disabled={btnDisabled} >Send Invitation</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default InviteInterviewPage;