import React, { useState, useEffect } from 'react'
import './../styles/invitedPage.scss'
import { Formik, Form , Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row,Col, Spinner} from 'react-bootstrap'
import image2 from '../../../../../assets/v2/imgs/avatar/ava_16.png'
import Select from 'react-select'
import styled from 'styled-components'
import ProfileUploaderV2 from '../../CandidateProfilePage/ProfileUploaderV2'
import BlankOrganizationImage from '../../../../../assets/images/logo.png';
import SettingLeftPenal from '../SettingLeftPenal'
import { CKEditor } from 'ckeditor4-react';


const GeneralSettingPage = ({communityId,currentUser,community,categories}) => {
  
  const [loading, setLoading] = useState(false)
  const [iconImage, setIconImage] = useState(community.avatar_image);
  const [coverImage, setCoverImage] = useState(community.cover_image);
  const [communityType, setCommunityType] = useState(community.community_type ? community.community_type : 'private');
  const [validationErrors, setValidationErrors] = useState([])
  const [editorKey, setEditorKey] = useState(1); // Key to force CKEditor re-render,

  const initialFormData = {
    name: community.name,
    category_id: community.category_id,
    small_description: community.small_description ? community.small_description : '',
    description: community.description ? community.description : ''
  }

  const CommunityValidation = {
    name: Yup.string()
      .required('Please enter group name.').trim()
      .max(50, 'Group name has a max length of 50 characters')
      .test(
        'group-unique',
        'This group name is already in use',
        async function (value) {
          const res = await axios.get(
            `/communities/exists?name=${encodeURIComponent(value)}&id=${communityId}`
          )
          return !res.data.community_exists
        }
      ),
    // category_id: Yup.string().required('Category is required'),
    small_description: Yup.string().trim().max(100, 'Description has a max length of 100 characters')
      .required('Description is required'),
    description: Yup.string().trim().required('About is required')
  }

  const validationSchema = Yup.object({ ...CommunityValidation })

  const categoryData = categories.map(category => ({
    label: category.name,
    value: category.id
  }));

  const [defaultCategory, setDefaultCategory] = useState(categoryData.find(cd => cd.value === community?.category_id));
  const [selectedCategory, setCategory] = useState(community.category_id);

  const handleIconChange = (file) => {
    setIconImage(file)
  };

  const handleCoverChange = (file) => {
    setCoverImage(file)
  };

  const format_logo_url = (avatar_url) => {
    if(typeof avatar_url == "object"){
      return(URL.createObjectURL(avatar_url))
    }
    return avatar_url
  }

  const ImageContainer = styled.div`
    display: flex;
    border-radius: 10px;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;

    > img {
        border-radius: 10px;
        width: 150px;
    }
  `

  const onChangeDescription = (e, field, values, setFieldValue) => {
    let data = e.editor.getData()
    setFieldValue('description', data)
    // call formik onChange method
    // field.onChange(e);
  }

  const handleSubmit = async (values) => {
    const payload = new FormData()
    const url = '/communities/'+communityId+'/update'
    payload.append('community[name]', values.name.trim())
    payload.append('community[category_id]', selectedCategory)
    payload.append('community[small_description]', values.small_description.trim())
    payload.append('community[description]', values.description.trim())
    payload.append('community[community_type]', communityType)
    if(typeof iconImage == 'object' && iconImage != null) {
      payload.append('community[avatar]', iconImage)
    }
    if(typeof coverImage == 'object' && coverImage != null) {
      payload.append('community[cover]', coverImage)
    }
    setLoading(true)
    const response = await makeRequest(url, 'put', payload, {
      contentType: 'multipart/form-data',
      loadingMessage: 'Submitting...',
      createSuccessMessage: (response) => response.data.message,
      createResponseMessage: (response) => {
        return {
          message: response.message,
          messageType: response.success ? 'success' : 'failure',
          loading: false,
          autoClose: true,
        }
      },
      onSuccess: (res) => {
        setLoading(false)
        window.open('/communities/' + communityId + '/general_setting', "_self")
      },
  })
  }


  return (
    <>
      <div className="container mt-40 communities-detail">
        <div className="row">
          <SettingLeftPenal communityId={communityId} ></SettingLeftPenal>
          <div className="col-lg-8 m-0 p-20">
            <h4>General Setting</h4>
            <div className="group-form">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <ImageContainer>
                          <img src={iconImage == null ? BlankOrganizationImage : format_logo_url(iconImage)} />
                      </ImageContainer>
                    </div>
                    <div className="col-lg-6">
                      <div  style={{ marginLeft: '12px' }}>
                        <ProfileUploaderV2
                          onFileSelectSuccess={(file) =>
                            handleIconChange(file)
                          }
                          recomm={'128x128'}
                          btnText={'Upload Icon'}
                          onFileSelectError={({ error }) => alert(error)}
                          isProfilePicture={'profilePicture'}
                          />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <ImageContainer>
                          <img src={coverImage == null ? BlankOrganizationImage : format_logo_url(coverImage)} />
                      </ImageContainer>
                    </div>
                    <div className="col-lg-6">
                      <div  style={{ marginLeft: '12px' }}>
                        <ProfileUploaderV2
                          onFileSelectSuccess={(file) =>
                            handleCoverChange(file)
                          }
                          recomm={'1084x576'}
                          btnText={'Upload Cover'}
                          onFileSelectError={({ error }) => alert(error)}
                          isProfilePicture={'profilePicture'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Formik
                validateOnChange={true}
                validateOnBlur={false}
                initialValues={initialFormData}
                validationSchema={validationSchema}
                onSubmit={(values)  => { handleSubmit(values)}}
              >
                {(props) => {
                  const {
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      setValues,
                      handleChange,
                      handleSubmit,
                  } = props
                  return (
                    <Form>
                      <div className="form-group">
                        <label className="control-label">Group Name*</label>
                        <div className="">
                          <input type="text" name="name" className="form-control" placeholder="Group Name"
                            value={values.name} 
                            onChange={handleChange} 
                            style={{ height: 'auto' }}
                            error={
                                Boolean(
                                    errors.name
                                ) &&
                                touched.name
                            }
                          />
                          {errors.name && touched.name && (
                              <span width="28rem" className='error'>{errors.name}</span>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label">Group Description*</label>
                        <div className="">
                          <input type="text" name="small_description" className="form-control" placeholder="Group Description"
                            value={values.small_description} 
                            onChange={handleChange} 
                            style={{ height: 'auto' }}
                            error={
                                Boolean(
                                    errors.small_description
                                ) &&
                                touched.small_description
                            }
                          />
                          {errors.small_description && touched.small_description && (
                              <span width="28rem" className='error'>{errors.small_description}</span>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label">About*</label>
                        <div className="">
                          {/* <textarea rows="4" name="description" className="form-control" placeholder="About"
                            value={values.description} 
                            onChange={handleChange} 
                            style={{ height: 'auto' }}
                            error={
                                Boolean(
                                    errors.description
                                ) &&
                                touched.description
                            }
                          /> */}
                          <Field name="numberOfTickets">
                            {({ field }) => (
                              <CKEditor
                                  key={editorKey} // Force re-render when the key changes
                                  // data={values.description}
                                  initData={values.description}
                                  config={{
                                    versionCheck: false,
                                    toolbar: [
                                        ['Cut', 'Copy', 'Paste'],
                                        ['Styles', 'Format', 'Font', 'FontSize'],
                                        ['Bold', 'Italic', 'Strike'],
                                        ['Undo', 'Redo'],
                                        ['NumberedList', 'BulletedList', 'Blockquote', 'Outdent', 'Indent'],
                                        ['Link', 'Unlink'],
                                        ['Table', 'HorizontalRule'],
                                        ['Maximize']
                                    ]
                                  }}
                                  onChange={e => onChangeDescription(e, field, values, setFieldValue)}
                              />
                          )}
                          </Field>

                          {errors.description && touched.description && (
                              <span width="28rem" className='error'>{errors.description}</span>
                          )}
                        </div>
                      </div>
                      <Row className="w-100">
                        <Col xs={12} sm={12} lg={12} md={12}>
                          <label className="control-label">Group Category</label>
                          <Select
                            options={categoryData}
                            name='category_id'
                            id='category_id'
                            className="category_select_ui"
                            placeholder={'Select Category'}
                            defaultValue={defaultCategory}
                            onChange={(event) => {setCategory(event?.value || ''); }}
                            menuPosition='fixed'
                            error={
                              Boolean(
                                  errors.category_id
                              ) &&
                              touched.category_id
                            }
                          />
                          {errors.category_id && touched.category_id && (
                              <span width="28rem" className='error'>{errors.category_id}</span>
                          )}
                        </Col>
                      </Row>
                      <div className="radio-group mt-20">
                        <input type="radio" id="private" name="community_type" defaultValue="private" defaultChecked={communityType == 'private' ? true : false} 
                          onClick={()=>{
                            setCommunityType('private')
                          }} 
                        />
                        <label htmlFor="private" className="radio-option">
                          <span className="radio-dot" />
                          <span className="radio-text">
                            <strong>Private</strong>
                            <span>Only members can see who's in the group and what they post. Content is hidden from search engines.</span>
                          </span>
                        </label>
                        <input type="radio" id="public" name="community_type" defaultValue="public" defaultChecked={communityType == 'public' ? true : false}
                          onClick={()=>{
                            setCommunityType('public')
                          }} 
                        />
                        <label htmlFor="public" className="radio-option">
                          <span className="radio-dot" />
                          <span className="radio-text">
                            <strong>Public</strong>
                            <span>Anyone can see who's in the group and what they post. Content is discoverable by search engines.</span>
                          </span>
                        </label>
                      </div>
                      <div className="text-center mt-4">
                        <button type="submit" disabled={loading} className="btn btn-default font-heading icon-send-letter w-30">{loading ? (
                            <Spinner animation="border" variant="light" />
                          ) : (
                            'Update Settings'
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
          
        </div>
      </div>

    </>
  );
}
export default GeneralSettingPage