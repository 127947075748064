import React, { useState, useEffect } from 'react'
import Card from '../Admin/shared/Card'
import Table from '../Admin/shared/Table'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import P from '../Admin/shared/P'
import { Row, Col } from 'react-bootstrap'
import AsyncSelect from "react-select/async"
import './styles/SourcingSchedules.scss'
import Badge from "react-bootstrap/Badge";

const SourcingSchedules = ({}) => {
  const [candidates, setCandidates] = useState([])
  const [isLoadingSourcer, setLoadingSourcer] = useState(false)
  const [selectedSourcerFilter, setSelectedSourcerFilter]  = useState('')
  const [sourcerOptions, setSourcerOptions] = useState([])
  const [loading, setLoader] = useState(true)
  const [WeekTotals, setWeekTotals] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [validationError, setValidationError] = useState({})

  const fetchData = () => {
    setLoader(true)
    const url = `/sourcing_schedules.json`
    makeRequest(url, 'get',  {params: {sourcer:selectedSourcerFilter}},{   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        let data = res.data.candidate_list
        data.sort((a, b) => (a.campaign_manager > b.campaign_manager) ? 1 : -1)
        setCandidates(data)
        setWeekTotals(res.data.totals)
        setLoader(false)
      },
    })
  }
  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [selectedSourcerFilter])

  const handleInputChange = (str) => {
    setInputValue(str)
    handleChange()
    return str;
  }

  const handleChange = (e) => {
		setValidationError({})
	}

  const loadSourcerOption = (inputValue, callback) => {
    setLoadingSourcer(true)
    fetch(`/users/fetch_sourcer?search=${inputValue}`)
    .then((res) => res.json())
    .then((res) => {
      let {sourcer} = res
      setLoadingSourcer(false)
      setSourcerOptions([...sourcer.map((sourcer) => ({ value: sourcer.id, label: sourcer.first_name +' '+ sourcer.last_name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(sourcerOptions);
  }

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#fff',width: '100%'})
  }

  return (
    <>
    <Card>
      <div className="d-flex justify-content-between align-items-center w-100 mb-1">
        <P size="28px"> Sourcing Assignment Schedule</P>
      </div>
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'end', }}>
        <Col lg="3" md="3" sm="3" xs="3" className='p-0'>
          <div>
            <label>
                Select Sourcer :
            </label>
            <AsyncSelect
                name='sourcer'
                id='sourcer'
                isLoading={isLoadingSourcer}
                isClearable={true}
                loadOptions={loadSourcerOption}
                defaultOptions={selectedSourcerFilter}
                onInputChange={handleInputChange}
                onChange={(event)=>setSelectedSourcerFilter(event?.value || '')}
                placeholder={'Select Sourcer Name'}
                styles={colourStyles}
                noOptionsMessage={() => 'Start typing the name of sourcer'}
            />
          </div>
        </Col>
        <Col lg="9" md="9" sm="9" xs="9" className='p-0'>
          <div className='text-right'>
            <Badge pill variant="secondary" className={`info-button paused`} >Paused</Badge>
            <Badge pill variant="secondary" className={`info-button ongoing`} >Ongoing</Badge>
            <Badge pill variant="secondary" className={`info-button manual`} >Manual</Badge>
          </div>
        </Col>

      </Row>
      <Table
        columNames={[
          {
            name: 'Campaign Manager',
            field: 'campaign_manager',
            editable: false,
            type: 'text',
          },
          {
            name: (<span>Monday {(WeekTotals?.monday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.monday?.ongoing}</Badge>)} {(WeekTotals?.monday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.monday?.manual}</Badge>))} {(WeekTotals?.monday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.monday?.paused}</Badge>))} </span> ),
            field: 'monday',
            editable: false,
            type: 'text',
          },
          {
            name: (<span>Tuesday {(WeekTotals?.tuesday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.tuesday?.ongoing}</Badge>)} {(WeekTotals?.tuesday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.tuesday?.manual}</Badge>))} {(WeekTotals?.tuesday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.tuesday?.paused}</Badge>))} </span> ),
            field: 'tuesday',
            editable: true,
            type: 'text',
          },
          {
            name: (<span>Wednesday {(WeekTotals?.wednesday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.wednesday?.ongoing}</Badge>)} {(WeekTotals?.wednesday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.wednesday?.manual}</Badge>))} {(WeekTotals?.wednesday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.wednesday?.paused}</Badge>))}</span> ),
            field: 'wednesday',
            editable: false,
            type: 'text',
          },
          {
            name: (<span>Thursday {(WeekTotals?.thursday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.thursday?.ongoing}</Badge>)} {(WeekTotals?.thursday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.thursday?.manual}</Badge>))} {(WeekTotals?.thursday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.thursday?.paused}</Badge>))}</span> ),
            field: 'thursday',
            editable: false,
            type: 'text',
          },
          {
            name: (<span>Friday {(WeekTotals?.friday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.friday?.ongoing}</Badge>)} {(WeekTotals?.friday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.friday?.manual}</Badge>))} {(WeekTotals?.friday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.friday?.paused}</Badge>))}</span> ),
            field: 'friday',
            editable: false,
            type: 'text',
          },
        ]}
        rowValues={candidates?.map((candidate) => ({
          ...candidate,
          monday: (candidate?.monday) ? (candidate?.monday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
          thursday:  (candidate?.thursday) ? (candidate?.thursday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
          friday: (candidate?.friday) ? (candidate?.friday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
          tuesday: (candidate?.tuesday) ? (candidate?.tuesday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
          wednesday: (candidate?.wednesday) ? (candidate?.wednesday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
        }))}
        loading={loading}
      />
    </Card>

    </>
  )

  function OrganizationList(info, index){
    return(
      <>
        <Badge
          pill
          key={index}
          variant="secondary"
          className={`skills-button ${info['status']}`}
        >
          <label className={`candidate-skills`}> {info['name']} </label>
        </Badge>
      </>
    )
  }
}

export default SourcingSchedules