import React, { useEffect, useState, useRef } from 'react'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Card from './shared/Card'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import './styles/MixmaxAnalyticCustom.scss'
import feather from 'feather-icons'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'

const MixmaxDashboard = ({configs,per_page}) => { 

   const [selectedConfigId, setSelectedConfigId] = useState('')
   const [configList,setConfigList] = useState(configs)
   const [loading, setLoading] = useState(true)
   const [sequenceDetail, setSequenceDetail] = useState({})
   const [candidate, setCandidate] = useState([])
   const [activePage, setActivePage] = useState(1)
   const [totalCandiadate, setTotalCandiadate]  = useState(0)
   const [perPage, setPerPage] = useState(10)
   const localStorageDateRange = JSON.parse(sessionStorage.getItem('MixmaxDashboardAnalyticsReport'))
   const queryParams = new URLSearchParams(window.location.search)
   const urlStartDate = queryParams.get("start_date")
   const urlEndDate = queryParams.get("end_date")
   const startDate = (urlStartDate ? urlStartDate : (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate())
   const endDate = (urlEndDate ? urlEndDate : (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate())
   const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
   const [footer, setFooter] = useState({})
   const handleDate = (date) => {
       sessionStorage.setItem('MixmaxDashboardAnalyticsReport', JSON.stringify(date))
       setDateRange(date)
   }
 
  
  useEffect(() => {
    feather.replace()
  })

  const fetchData = (id) => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = `/admin/mixmax_dashboard.json`
    if(selectedConfigId != ''){
      makeRequest(`${url}`, 'get',  {params: {page: activePage,config_id:id, start_date: start_date, end_date: end_date}}, {   
        contentType: 'application/json',
        loadingMessage: 'Fetching...',
        createSuccessMessage: (response) => response.data.message,
        onSuccess: (res) => {
        
              let sr_no = (activePage - 1) * perPage
              setCandidate(res?.data?.data.map((data,key)=> (sr_no = sr_no + 1,{
                  sr_no: sr_no,
                  name: data?.mixmax_configuration?.name,
                  outreach_date: moment(data?.outreach_date).format('MMM DD,YYYY'),
                  recipient: data?.recipients,
                  sent: data?.sent,
                  replied: data?.replied,
                  deliverd: data?.delivered,
                  bounced: data?.bounced,
                  opened: data?.opened,
                  clicked: data?.clicked,
                  tentative: data?.tentative,
                  downloaded: data?.downloaded,
                  accepted: data?.accepted,
                  declined: data?.declined,
              })))
              setFooter({
                sr_no:'Total',
                recipient:res.data?.total_values?.recipients,
                sent: res.data?.total_values?.sent,
                replied: res.data?.total_values?.replied,
                deliverd: res.data?.total_values?.delivered,
                bounced: res.data?.total_values?.bounced,
                opened: res.data?.total_values?.opened,
                clicked: res.data?.total_values?.clicked,
                tentative: res.data?.total_values?.tentative,
                downloaded: res.data?.total_values?.downloaded,
                accepted: res.data?.total_values?.accepted,
                declined: res.data?.total_values?.declined,
              })
                setTotalCandiadate((res?.data).length)
                setLoading(false)
        },
      })
    }
  }

  const onSelecteConfig = (event) => {
    setSelectedConfigId(event.value)
  }

  

  useEffect(() => {
    fetchData(selectedConfigId)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [dateRange,selectedConfigId])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'22em',maxWidth:'22em',marginLeft:'10px',minHeight:'40px' })
  }

  const  columns= [
    {
        name: 'No.',
        selector: row => row.sr_no,
        maxWidth: '5%',
        minWidth: '5%',
    },
    {
        name: 'Name',
        selector: row => row.name,
        maxWidth: '13%',
        minWidth: '13%',
        wrap:true,
    },
    {
        name: 'Outreach Sent At',
        selector: row => row.outreach_date,
        maxWidth: '10%',
        minWidth: '10%',
        wrap:true,
        sortable: true,
        sortField: 'users.email',
    },
    {
        name: 'Recipient',
        selector: row => row.recipient,
        maxWidth: '8%',
        minWidth: '8%',
        wrap:true,
    },
    {
      name: 'Sent',
      cell: row => row.sent,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
    },
    {
        name: 'Replied',
        selector: row => row.replied,
        maxWidth: '8%',
        minWidth: '8%',
        wrap:true,
    },
    {
      name: 'Bounce',
      selector: row => row.bounced,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
    },
    {
        name: 'Open',
        selector: row => row.opened,
        maxWidth: '8%',
        minWidth: '8%',
        wrap:true,
    },
    {
        name: 'Click',
        selector: row => row.clicked,
        maxWidth: '8%',
        minWidth: '8%',
        wrap:true,
    },
    {
      name: 'Tentative',
      selector: row => row.tentative,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
    },
    {
        name: 'Downloaded',
        selector: row => row.downloaded,
        maxWidth: '8%',
        minWidth: '8%',
        wrap:true,
    },
    {
        name: 'Bounce Email',
        selector: row => row.bounced,
        maxWidth: '8%',
        minWidth: '8%',
    }
  ]

  return (
    <>
    <Card style={{minHeight:'580px !important'}}>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
         Mixmax Dashboard
        </P>
      </div>
      
      <Row className="w-100" style={{ marginBottom: '10px' }}>
      <Col lg="8" md="8" sm="8" xs="8" className='d-flex align-items-end'></Col>
        <Col lg="2" md="2" sm="2" xs="2">
            <label><b>Select Mixmax A/C </b> &nbsp; :</label>
            <Select
                options={configList}
                onChange={onSelecteConfig}
                name="job"
                style={colourStyles}
            />
         </Col>
         <Col lg="2" md="2" sm="2" xs="2" className='d-flex align-items-end'>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                <b>Select Date Range </b> &nbsp; :
              </label>
              <DateRangePicker
                  handleOnSubmit={handleDate}
                  defaultDate={dateRange}
              />
            </div>
        </Col>
      </Row>
      { loading == false  && 
        <>
          <Row className="w-100" style={{ marginBottom: '10px' }}>
              <Col  lg="12" md="12" sm="12" xs="12">
                <div>
                      <h5>{sequenceDetail?.name }</h5>
                  </div>
              </Col>
          </Row>
          <Row className="w-100" style={{ marginBottom: '10px', marginTop: '20px' }}>
              <Col  lg="9" md="9" sm="9" xs="9">
                  <div>
                      <h5>Outreach Details </h5>
                  </div>
              </Col>
          </Row>
          <Row className="w-100" style={{ marginBottom: '10px' }}>
              <Col  lg="12" md="12" sm="12" xs="12">
                <DataTable
                  title={false}
                  persistTableHead={true}
                  noDataComponent={<p className="text-center p-3">No record found</p>}
                  columns={columns}
                  data={candidate}
                  progressPending={loading}
                  progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                      <Spinner className='spinner' animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                      </Spinner>
                  </P>}
                  highlightOnHover
                  pagination
                  paginationTotalRows={totalCandiadate}
                  paginationPerPage={perPage}
                  paginationComponentOptions={{
                      noRowsPerPage: true
                  }}
                  onChangePage={page => setActivePage(page)}
                  footer={footer}
                />
              </Col>
          </Row> 
        </>
      }
     
    </Card>
    </>
  )
}

export default MixmaxDashboard
