import React, { Fragment, useState } from 'react'
import _ from 'lodash'

import styles from './styles/CompanyProfilePage.module.scss'
import ApplyJob from '../../common/ApplyJob/ApplyJob'

function JobCard({ career, currentUser, isProfileComplete }) {
    const [applied, setApplied] = useState(false)

    return (
        <Fragment>
            <div className={styles.jobCard}>
                <div className={styles.jobCardContainer}>
                    <div className={`${styles.jodDetailsWrapper}`}>
                        <a
                            href={`/jobs/${career.id}`}
                            className={`${styles.careerHeader}`}
                        >
                            {career.name}
                        </a>
                        <div className="clearfix"></div>
                        <span className={`${styles.careerDetail}`}>
                            {career.location !== "null" ? career.location : 'No location found'}
                        </span>
                    </div>
                    <div>
                        {_.isEmpty(currentUser) && (
                            <ApplyJob
                                job={career}
                                isProfileComplete={isProfileComplete}
                                currentUser={currentUser}
                                applied={applied}
                                setApplied={setApplied}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default JobCard
