import React, { useState, useEffect } from 'react'
import Card from '../Admin/shared/Card'
import Table from '../Admin/shared/Table'
import Paginator from '../../common/Paginator/Paginator'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import P from '../Admin/shared/P'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import './styles/EmailDatas.scss'
import Util from "../../../utils/util";
import Modal from "react-bootstrap/Modal"
import EmailDatasCreate from './EmailDatasCreate'
import moment from 'moment'

const EmailDatasList = ({organizations,currentUser}) => {
  var active_page = window.sessionStorage.getItem('emailDateActivePage')
  var query = window.sessionStorage.getItem('query')
  const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
  const [pageCount, setPageCount] = useState(0)
  const [tableDatas, setTableDatas] = useState([])
  const [totalDatas,setTotalDatas] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [show,setShow] = useState(false)
  const [method,setMethod] = useState('post')
  const [entry,setEntry] = useState({})
  const [totalValues,setTotalValues] = useState({})
  const fetchData = () => {
    const url = `/email_datas.json`
    window.sessionStorage.setItem('emailDateActivePage',activePage)
    makeRequest(url, 'get',  {params: {page: (activePage+1), query: query}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setTableDatas(
          res.data.email_datas_list.map((inMails) => ({
            id: inMails.id,
            organization_id: inMails.organization_id,
            organization_name: Util.capitalize(inMails?.organization?.name),
            email_sent: inMails?.email_sent,
            text_sent: inMails?.text_sent,
            of_replies: inMails?.of_replies,
            response_rate: inMails?.response_rate,
            created_at: inMails?.created_at,
            in_email_sent: inMails?.in_email_sent,
            inmail_remaining: inMails?.inmail_remaining,
            candidate_submitted: inMails?.candidate_submitted,
            confirmed_interview: inMails?.confirmed_interview,
            hire: inMails?.hire,
            email_metric_sent_to_client: inMails?.email_metric_sent_to_client,
            email_metric_sent_to_am: inMails?.email_metric_sent_to_am,
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalDatas(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setTotalValues(res.data.totalValues)
      },
    })
  }
    useEffect(() => {
      fetchData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage])

    const deleteData = async (id) => {
      const URL = `/email_datas/${id}`
      await makeRequest(URL, 'delete', '', {
        createResponseMessage: () => {
          return {
            message: `Deleted successful`,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        },
        createErrorMessage: (e) => 'Failed',
        onSuccess: () => {      
            fetchData()
        },      
      })      
    }    

    const addData = () => {
      setEntry({})
      setMethod('post')
      setShow(true)
    }

    const onHide = () => {
      setEntry({})
      setMethod('post')
      setShow(false)
    }
    
    const getUpdateData = (id) => {
      let getData = tableDatas.filter((row) => row.id == id)
      if(getData?.length > 0){
        setMethod('put')
        setEntry(getData[0])
        setShow(true)
      } 
    }

  return (
    <>
    <div className="container">
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
          List Email Data Entry
        </P>
        <div style={{flexGrow:1}}/>
        <Button onClick={addData} className="ml-3">
          Add New Entry
        </Button>
      </div>

      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
        <Col xs={12} sm={12} lg={2} className="" style={{float:'left'}}>
          {totalDatas > 0 &&
            <DisplayPagination>Displaying  {(activePage == 0)?1:startRecord} - {currentCounts} of {totalDatas}</DisplayPagination>
          }
        </Col>
        <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'right'}}>

        </Col>
      </Row>
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Name',
              field: 'organization_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Date',
              field: 'created_at',
              editable: false,
              type: 'text',
            },
            {
              name: 'Email Sent',
              field: 'email_sent',
              editable: true,
              type: 'number',
            },
            {
              name: 'Text Sent',
              field: 'text_sent',
              editable: true,
              type: 'number',
            },
            {
              name: '# Of Replies',
              field: 'of_replies',
              editable: true,
              type: 'number',
            },
            {
              name: 'Response Rate',
              field: 'response_rate',
              editable: false,
              type: 'number',
              nowrap:''
            },
            {
              name: 'InMail Sent',
              field: 'in_email_sent',
              editable: false,
              type: 'number',
              nowrap:''
            },
            {
              name: 'InMail Remaining',
              field: 'inmail_remaining',
              editable: false,
              type: 'number',
              nowrap:''
            },
            {
              name: 'Submission',
              field: 'candidate_submitted',
              editable: false,
              type: 'number',
              nowrap:''
            },
            {
              name: 'Interview',
              field: 'confirmed_interview',
              editable: false,
              type: 'number',
              nowrap:''
            },
            {
              name: 'Hire',
              field: 'hire',
              editable: false,
              type: 'number',
              nowrap:''
            },
            {
              name: 'Sent To Client',
              field: 'email_metric_sent_to_client',
              editable: false,
              type: 'text',
              nowrap:''
            },
            {
              name: 'Sent To AM',
              field: 'email_metric_sent_to_am',
              editable: false,
              type: 'number',
              nowrap:''
            },
            {
              name: 'Action',
              field: 'options',
              editable: false,
              type: 'text',
              nowrap:'text-nowrap',
              options:{}
            },
          ]}
          rowValues={tableDatas.map((data) => ({
              ...data,
              created_at: moment(data?.created_at).format('MMM DD, YYYY'),
              email_metric_sent_to_client: (data?.email_metric_sent_to_client) ? 'Yes' : 'No',
              email_metric_sent_to_am: (data?.email_metric_sent_to_am) ? 'Yes' : 'No',
          }))}
          showEditOption
          activePage={activePage}
          totalValues={totalValues}
          deleteAction={deleteData}
          goToEditPage={(id) =>
            getUpdateData(id)
          }
      />
      {pageCount > 1 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
        >
          <Paginator
              activePage={activePage}
              setActivePage={setActivePage}
              pageCount={pageCount}
              pageWindowSize={5}
              showGoToField={false}
          />
        </div>
      )}
    </div>

    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" className='ml-3'>
              { method ==='put' ?'Update' : 'Add' } Email Data Entry
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <EmailDatasCreate organizations={organizations} recruiters={currentUser} inMailEntry={entry} onHide={onHide} method={method} fetchData={fetchData}></EmailDatasCreate>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default EmailDatasList