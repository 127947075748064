import React, { useState, useEffect } from 'react'
import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './styles/CtrsDashboard.scss'
import Select from 'react-select'
import styles from '../../Admin/styles/JobsStatusPage.module.scss'
import feather from 'feather-icons'
import Util from '../../../../utils/util'
import Modal from 'react-bootstrap/Modal'
import AddCtrs from './AddCtrs'
import SearchBar from '../../../common/SearchBar/SearchBar'
import moment from 'moment'
import { capitalize } from '@material-ui/core'
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import axios from 'axios'

const CtrsDashboard = (ctrs_email_list) => {
    const team_lists = []
    const team_lead_list = []
    const recruiters = []
    const queryParams = new URLSearchParams(window.location.search)
    var active_page = window.sessionStorage.getItem('teamListActivePage')
    var query = window.sessionStorage.getItem('query')
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    let jobStatus_start_date = sessionStorage.getItem("jobStatus_start_date");
    let jobStatus_end_date = sessionStorage.getItem("jobStatus_end_date")
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const jobId = queryParams.get("job")
    const [teams, setTeams] = useState([])
    const [totalTeams, setTotalTeams] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [teamUserId, setTeanUserId] = useState()
    const [show, setShow] = useState(false)
    const [showMember, setShowMember] = useState(false)
    const [method, setMethod] = useState('post')
    const [entry, setEntry] = useState({})
    const [loading, setLoading] = useState(true)
    const [data, setDate] = useState([])
    let email_seacrh = sessionStorage.getItem("email_seacrh")
    const [emailList,setTeamList] = useState([{value: 'All', label: 'All'},...ctrs_email_list.ctrs_email_list])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate : jobStatus_start_date ? jobStatus_start_date : moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : jobStatus_end_date ? jobStatus_end_date : moment().toDate()})
    const [selected,setSelected] = useState((jobId)? recruiters.filter((option) => option.value == jobId) : email_seacrh && email_seacrh != "null" ? JSON.parse(email_seacrh) : {value: 'all', label: 'All'})
    const [selectedEmail,setSelectedEmail] = useState(email_seacrh && email_seacrh != "null" ? JSON.parse(email_seacrh).label : jobId ? jobId : '')
    const [fetchApi, setFetchApi] = useState()
    const [fetchOrgAPi, setFetchOrgApi] = useState()
    const fetchData = () => {
        setLoading(true)
        const url = `/admin/ctrs_reports.json`
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("jobStatus_start_date", start_date)
        sessionStorage.setItem("jobStatus_end_date", end_date)
        window.sessionStorage.setItem('teamListActivePage', activePage)
        makeRequest(
            url,
            'get',
            {
                params: {
                    page: activePage + 1,
                    query: query,
                    search: searchTerm,
                    name: selectedEmail,
                    start_date: start_date,
                    end_date: end_date
                },
            },
            {
                contentType: 'application/json',
                loadingMessage: 'Fetching...',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                    setDate(res?.data?.data)
                    let arr = res?.data?.data?.map((item) => item.ctrs_detail[item?.ctrs_detail.length -1])
                    // console.log(arr[arr.length])
                    setTeams(
                        res.data.data.map((item) => ({
                            id: item.id,
                            name: capitalize(item?.name ),
                            email: item?.email,
                            message: item.ctrs_detail[item?.ctrs_detail.length -1].message,
                            date: item.ctrs_detail[item?.ctrs_detail.length -1].date
                            ? moment(item.ctrs_detail[item?.ctrs_detail.length -1].date).format(
                                'MMM DD, YYYY'
                                )
                                : '',    
                            }))
                            )
                            setPageCount(res?.data?.total_pages)
                            setTotalTeams(res?.data?.total_count)
                            setCurrentCounts(res?.data?.current_counts)
                            setStartRecord(activePage * res?.data?.per_page)
                            setLoading(false)
                        },
                    }
                    )
                }
                useEffect(() => {
                    fetchData()
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }, [activePage, selectedEmail, dateRange])
                
                const deleteTeam = async (id) => {
                    const URL = `/admin/ctrs_reports/${id}`
                    await makeRequest(URL, 'delete', '', {
                        createResponseMessage: () => {
                            return {
                                message: `Deleted successful`,
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        createErrorMessage: (e) => 'Failed',
                        onSuccess: () => {
                            fetchData()
                        },
                    })
                }
                
    const sendMail = (id) => {
        let getTeam = data.filter((row) => row.id == id)
        if (getTeam?.length > 0) {
            setEntry(getTeam[0])
            setMethod('mail')
            setShow(true)
        }
             }
    const addCtrs = () => {
        setEntry({})
        setMethod('post')
        setShow(true)
    }

    const onHide = () => {
        setEntry({})
        setMethod('post')
        setShow(false)
        setShowMember(false)
    }

    const getUpdateData = (id) => {
        let getTeam = data.filter((row) => row.id == id)
        if (getTeam?.length > 0) {
            setMethod('put')
            setEntry(getTeam[0])
            setShow(true)
        }
    }
 
    const handleDate = (date) => {
        setDateRange(date)
    }
    return(
        <>
        <Card>
            <div className="d-flex justify-content-between align-items-center w-100">
                <P
                    size="28px"
                    height="38px"
                    color="#1D2447"
                    style={{ width: '540px' }}
                >
                    CTRS Dashboard
                </P>
                <div style={{ flexGrow: 1 }} />
                <Button onClick={addCtrs} className="ml-3">
                    Add New
                </Button>
            </div>

           
                 <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
                    <div>
                    <label>Emails</label>
                    <Select
                        defaultValue={selected}
                        options={emailList}
                        onChange={(event)=>{
                            setSelectedEmail(event.label)
                            sessionStorage.setItem("email_seacrh", JSON.stringify(event))
                        }}
                          name="job_name"
                          className={styles.colourStyles + " " + 'email-select'}
                          />
                        </div>
                            <div style={{display:'grid'}}>
                        <label>
                            Date
                        </label>

                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                </Col>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                {totalTeams > 0 && loading == false &&
                    <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalTeams}</DisplayPagination>
                }
            </Col>
                </Row>
            {/* </div> */}
            <Table
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Name',
                        field: 'name',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Emails',
                        field: 'email',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Last Communication Date',
                        field: 'date',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Last Message',
                        field: 'message',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Action',
                        field: 'options',
                        editable: false,
                        type: 'text',
                        options:{CustomEventIcon:'mail',CustomEventMethod: (id) => sendMail(id)}
                    },
                ]}
                rowValues={teams.map((team) => ({
                    ...team,
                    member: team?.member?.map((t) =>
                        t?.user?.id != team?.owner_id ? (
                            <span className="members">
                                {' '}
                                {Util.fullName(
                                    t?.user?.first_name,
                                    t?.user?.last_name
                                )}{' '}
                            </span>
                        ) : (
                            ''
                        )
                    ),
                    status: Util.capitalize(team?.status),
                }))}
                showEditOption
                activePage={activePage}
                deleteAction={deleteTeam}
                goToEditPage={(id) => {
                    setTeanUserId(id)
                    getUpdateData(id)
                }}
                loading={loading}
            />
            {pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
        </Card>

        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            scrollable
            className="modal-entry-form"
        >
            <Modal.Header closeButton className="mt-2 mb-2">
                <Modal.Title id="contained-modal-title-vcenter">
                    {method === 'put'
                        ? 'Update' : method === 'mail' ? 'Follow Up'
                        : 'CTRS - Add Entry'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddCtrs
                    recruiter_list={recruiters}
                    // org_list={org_list}
                    teamEntry={entry}
                    onHide={onHide}
                    method={method}
                    team_lists={team_lists}
                    fetchData={fetchData}
                    team_lead_list={team_lead_list}
                    teamUserId={teamUserId}
                    fetchApi={fetchApi}
                    fetchOrgAPi={fetchOrgAPi}
                    setFetchApi={setFetchApi}
                    setFetchOrgApi={setFetchOrgApi}
                ></AddCtrs>
            </Modal.Body>
        </Modal>
    </>
    )
}

export default CtrsDashboard