import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import styles from './styles/SubmissionResultsBlock.module.scss'
import JobAppledCard from '../../../common/JobAppledCard/JobAppledCard'
import Paginator from '../../../common/Paginator/Paginator'
import Paginator_v2 from '../../../common/Paginator/Paginator_v2'
import Util from '../../../../utils/util'
import JobSearchBar from '../../../common/JobSearchBar/JobSearchBar'
import '../../../../../assets/v2/css/process.css'

const SubmissionResultsBlock = (props) => {
    const {
        submission,
        totalJobsCount,
        pageCount,
        activePage,
        setErrorSearchingJobs,
        errorSearchingJobs,
        loading,
        inputValue,
        setActivePage,
        showJobDetails,
        submitJobSearch,
        handleInputChange,
        perPage
    } = props

    const displayNumberOfResults = () => {
        return Util.displayNumberOfResults(
            totalJobsCount,
            pageCount,
            activePage,
            perPage
        )
    }
    const displayText = `Displaying ${displayNumberOfResults()} results`

    return (
        <div className="container">
            {/* <div className={`${styles.jobSearchHeading}`}>
                <div className="container text-center">
                    <h2>My Applied Jobs</h2>
                </div>
                <div class="box-filters-job mt-15 mb-10">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 style="margin-left: 14px;">My Applied Jobs</h4>
                        </div>
                    </div>
                </div>
                <JobSearchBar
                    placeholder="Search by Job title, keyword, or company"
                    value={inputValue}
                    onChange={(e) =>
                        handleInputChange(e.target.value)
                    }
                    onEnterPressed={(e) => {
                        setActivePage(0)
                        submitJobSearch(e)
                    }}
                />
            </div> */}
            <div className="row flex-row-reverse" style={{minHeight: '60vh'}}>
                <div className="col-lg-8 col-md-12 col-sm-12 col-12 float-right">
                <Row>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center align-content-center" style={{ margin: '0px auto' }}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    ) : errorSearchingJobs ? (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorSearchingJobs(null)}
                            dismissible
                        >
                            {errorSearchingJobs}
                        </Alert>
                    ) : (
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <div>
                                <div className="submission-results-wrap__bottom-row">
                                    <div className="box-filters-job mt-15 mb-10">
                                        <div className="row">
                                            <div className="col-lg-9">
                                                <h4 className="margin-left: 14px;">My Applied Jobs</h4>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className={`${styles.paginationText}`}>{displayText}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="job-list-list mb-15 mt-15">
                                        <div class="list-recent-jobs">
                                        {submission && submission?.length ? (
                                            submission?.map((jobItem) => {
                                                if (jobItem)
                                                    return (
                                                        <JobAppledCard
                                                            key={jobItem.id}
                                                            submission={jobItem}
                                                            showJobDetails={showJobDetails}
                                                        />
                                                        
                                                    )
                                            })
                                        ) : (
                                            <div style={{ textAlign: 'center' }}>
                                                No jobs found
                                            </div>
                                        )}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {Array.isArray(submission) && pageCount > 1 && (
                                <Row className="d-flex justify-content-center">
                                    <Paginator_v2
                                        pageCount={pageCount}
                                        pageWindowSize={perPage}
                                        activePage={activePage}
                                        setActivePage={setActivePage}
                                        showJobDetails={showJobDetails}
                                    />
                                </Row>
                            )}
                        </Col>)}
                </Row>
                </div>
            </div>
        </div>
    )
}

export default SubmissionResultsBlock
