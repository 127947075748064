import React, { useState, useEffect } from 'react'
import Simal from '../../../../assets/images/Simal.jpg'
const FaqPage = () => {
  return (
    <div>
      <div className="archive-header pb-20">
        <div className="container">
          <section class="section-box">
            <div class="container pt-50">
              <div class="w-50 w-md-100 mx-auto text-center">
                <h1 class="section-title-large mb-10 wow animate__animated animate__fadeInUp">FAQs</h1>
                <p class="mb-10 text-muted wow animate__animated animate__fadeInUp font-md">This is part of our help center where frequently asked questions are collected.</p>
              </div>
            </div>
          </section>
          <section>
            <div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        How do I invite my network?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                          <p class="mb-15">
                          You can forward any resumes in your email account that you'd like us to parse to resumes@datasort.ai and we will upload the data to your dashboard.  Then, you can download the data to a CSV file.  Then, follow the attached simple instructions to upload the csv file and invite your network.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        How do I track my earnings from people I have invited to job referral groups?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">You can track the people you have invited under "see pending invites" on the invite tab of Groups.  A dashboard is coming soon which will show the revenue generated per person who joined and revenue type.</div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        How do I track my earnings from contacts I have invited?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">You can track your earnings from contacts you have invited using the attached dashboard.</div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                        How much money can I make with Cardinal Groups?
                        </button>
                      </h2>
                      <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                          <p className='mb-10'>Let's say you invite 500 people to the job referral group that you created on Cardinal Groups or to connect with you.  The following are 5 ways you could potentially earn between $1k to $25k:</p>
                          <p>1) Let's say that 51 of them sign up for Cardinal Premium Messaging services.  That's $99/mo each.  The revenue generated is $5,049.  25% of that is $1,262.25.</p>
                          <p>2) Let's say that 10 of them sign up for Cardinal AI Job Application services.  That's $299/mo + 10% of success fee each.  Of these, let's say that 3 of them land jobs at $100k.  The total revenue is $2,990 + $30,000 = $32,990.  25% of that is $8,247.50.</p>
                          <p>3) Let's say that 1 of them signs up for recruiting services and you are the procuring cause of them booking a meeting.  A typical recruiting client is worth an average of around $100K.  So, 25% of that is $25,000.</p>
                          <p>4) Let's say that 1 of them signs up for Cardinal and you are the procuring cause of them being hired.  A typical placement fee is worth an average of around $40,000.  So, 25% of that is $10,000.</p>
                          <p>5) Let's say that 1 of them signs up for Cardinal and you submit them to the account manager and you are the procuring cause of them being hired.  A typical placement fee is worth an average of around $40,000.  So, 40% of that is $16,000.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree1" aria-expanded="false" aria-controls="flush-collapseThree">
                        Do you have any examples of recruiters who have made a lot of money with Cardinal? 
                        </button>
                      </h2>
                      <div id="flush-collapseThree1" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                        <figure><img src={Simal} alt="Simal" /></figure>
                          <p>Simal Oberoi has already earned over $500,000</p>
                          <p>referring talent to the Cardinal Groups</p>
                          <p>network from 2021 – 2024.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
export default FaqPage