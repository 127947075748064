export const allStages = [
  { id: 'recruitor_screen', label: 'Recruiter Screened' },
  { id: 'submitted', label: 'Submitted' },
  { id: 'first_interview', label: 'First Interviews' },
  { id: 'second_interview', label: 'Second Interviews' },
  { id: 'onhold', label: 'On Hold' },
  { id: 'offer', label: 'Offers' },
  { id: 'hired', label: 'Hired' },
  { id: 'reject', label: 'Archived' }
]

export const requiredStages = [
  { id: 'recruitor_screen', label: 'Recruiter Screened' },
  { id: 'submitted', label: 'Submitted' },
]
