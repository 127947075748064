import React, { useReducer, useState,useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { H1, StyledForm, Button } from './styles/UserManagementEditPage.styled'
import {Label} from '../CreateProfilePage/styles/CreateProfilePage.styled'
import TextInput from './shared/TextInput'
import Select from './shared/Select'
import SelectRect from 'react-select'
import { Row, Col } from 'react-bootstrap'
import { reducer, UserProfileContext } from '../../../stores/Admin/UserProfileStore'
import CreatableSelect from 'react-select/creatable'
import PhoneInput from '../../common/PhoneInput/PhoneInput'
import { normalizeInput } from '../../../utils'
import styles from './styles/UsermanagmentStyle.module.scss'

const UserManagementEditPage = ({ user, people , salesUserDetail,audio_video_url}) => {
    const [selectRole,setSelectRole] = useState(user.role)
    const [selectEmailConfirmed,setSelectEmailConfirmed] = useState(user.email_confirmed)
    const [selectUserApproved,setSelectUserApproved] = useState(user.user_approved)
    const [phoneNumberValue, setPhoneNumberValue] = useState(normalizeInput(user.phone_number,"") || '')
    const [slackId, setSlack] = useState(user?.slack_member_id ||'')
    const [selectedLocation, setSelectedLocation] = useState(user.location || "")
    const [defaultLocation, setdefaultLocation]  = useState(((user.location === 'null')) ? {}  : [{value: user?.location, label: user?.location}])
    const [locationOptions, setLocationOptions] = useState([])
    const [appendLinkedinValue, setAppendLinkedinValue] = useState()
    const [source, setSource] = React.useState('');
    const [fileError, setFileError] = useState('')
    const [selectedGroup, setSelectedGroup] = useState(people?.tech_group || "")
    const [defaultGroup, setDefaultGroup] = useState( (people?.tech_group && people?.tech_group != '' && people?.tech_group != null) ? (people?.tech_group.split(',')).map((res) => ({
        value: res,
        label: res,
    }))  : '')
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            width: '100%',
            height: '43px',
            marginTop: '1.5px',
            backgroundColor: '#f5f7ff',
            border: 'none',
            zIndex: '15',
        }),
    }
    const { email, role, firstName, lastName, linkedinProfile, phoneNumber, emailConfirmed, userApproved, location,slack_member_id} = user
    let initialState = {
        ...initialState,
        user,
        email,
        role,
        firstName,
        lastName,
        linkedinProfile,
        phoneNumber,
        emailConfirmed,
        userApproved,
        location,
        slack_member_id
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const saveUser = async (newUser) => {
        dispatch({ type: 'save-user', value: newUser,id:user.id, file: source })
    }
    const onRoleChange = (event) => {
        setSelectRole(event.target.value)
    }
    const onEmailConfirmedChange = (event) => {
        setSelectEmailConfirmed(event.target.value)
    }
    const onUserApprovedChange = (event) => {
        setSelectUserApproved(event.target.value)
    }

    const locationChange = (e) => {
        setSelectedLocation(e.value)
    }

    const handleChange = ({ target: { value } }) => {
        setPhoneNumberValue(normalizeInput(value, ""));
    }
    const handleChangeSlackId = (e) => {
        setSlack(e.target.value)
    }

    const fetchRequired = async () => {
        const url1 = `/filter_candidate_on_location`
        const formData = new FormData()
        formData.append('filter_word', '')
        const response = await axios
            .post(url1, formData)
            .then((res) => res.data)
            .catch((error) => console.log(error))
        const locationArray = response.filter.map((res) => ({
            value: `${res.city}, ${res.state}`,
            label: `${res.city}, ${res.state}`,
        }))
        setLocationOptions([...locationArray])
    }
    
    useEffect(() => {
        fetchRequired()
    }, [])

    const techTalentList = [
        { value: 'Accounting / Finance', label: 'Accounting / Finance' },
        { value: 'Account Executives', label: 'Account Executives' },
        { value: 'Admin', label: 'Admin' },
        { value: 'Customer Success', label: 'Customer Success' },
        { value: 'Designers', label: 'Designers' },
        { value: 'HR', label: 'HR' },
        { value: 'Legal', label: 'Legal' },
        { value: 'Marketers', label: 'Marketers' },
        { value: 'Recruiters', label: 'Recruiters' },
        { value: 'SDRs', label: 'SDRs' },
        { value: 'Software Engineers', label: 'Software Engineers' },
    ]
    
    const handleTechTalentList = (event) => {
        setSelectedGroup(event.map((techTalent)=> techTalent.value).join(','))
        setValidationError({});
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const url = URL.createObjectURL(file);
        if(file?.size > 50000000){
            setFileError( 'The uploaded file should be less than 50MB')
            setSource(file);
        }
        else{
                if((!file.type.includes('mp4')) && !(file.type.includes('mp3')) && !(file.type.includes('audio')) && !(file.type.includes('video'))){
                    setFileError("invalid format")
                    setSource(file);
                }else{              
                    setFileError('')    
                    setSource(file);
                }
            }
      };

    const [validationError, setValidationError] = useState({
        techTalents: null,
    });

    return (
        <UserProfileContext.Provider value={ [state, dispatch] }>
        <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
            <H1>Update User Information</H1>
            <Formik
                initialValues={{
                    email: user.email || '',
                    role: user.role || '',
                    firstName: user.first_name || '',
                    lastName: user.last_name || '',
                    linkedinProfile: (user.linkedin_profile_url && user.linkedin_profile_url != undefined)? user.linkedin_profile_url : '',
                    phoneNumber: user.phone_number || '',
                    emailConfirmed: user.email_confirmed || '',
                    userApproved: user.user_approved || ',',
                    location: selectedLocation || '',
                    tech_group: selectedGroup,
                    leads_limit: salesUserDetail?.leads_limit,
                    slack_member_id: ''
                }}
                validationSchema={Yup.object({
                    firstName: Yup.string()
                        .required('First Name is required')
                        .test(
                            'first name alphabets only',
                            'First Name can only contain alphabet characters and one space in between if there are two words',
                            function (value) {
                                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                                return regex.test(value)
                            }
                        ),
                    lastName: Yup.string()
                        .required('Last Name is required')
                        .test(
                            'last name alphabets only',
                            'Last Name can only contain alphabet characters and one space in between if there are two words',
                            function (value) {
                                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                                return regex.test(value)
                            }
                        ),
                    leads_limit: Yup.number()
                        .typeError('Leads Limit is required')
                        .test(
                            'leads-limit',
                            'The Leads Limit must be greater than 0.',
                            function (value) {
                                if(selectRole == "sales"){
                                    if (value > 0) {
                                        return true
                                    }else{
                                        return false
                                    }
                                }else{
                                    return true
                                }
                            }
                        ),
                    linkedinProfile: Yup.string()
                         .notRequired()
                         .nullable(true)
                        .typeError('Please add linkedin url only')
                        .test(
                            'linkedin only',
                            'Please add linkedin url only',
                            function (value) {
                                if (selectRole === "talent") {
                                    if (!value) {
                                      return true; // Allow empty input
                                    }
                            
                                    const linkedin_url = value.trim().toLowerCase();
                            
                                    if (
                                        linkedin_url.includes("linkedin.com")
                                        )
                                        {
                                        setAppendLinkedinValue(linkedin_url)  
                                      return true
                                    }
                            
                                    return false;
                                  } else {
                                    return true; // Accept any value if selectRole is not "talent"
                                  }
                            }
                        ),
                    tech_group: Yup.string()
                        .typeError('Talent Tech Group is required field')
                        .test( 'Talent Tech Group',
                            'Please add Talent Tech Group',
                            function (value) {
                                if(selectRole == "talent"){
                                    if(selectedGroup == '' || selectedGroup == null){
                                        setValidationError({techTalents: 'Talent Tech Group is required field'})
                                        return false
                                    }else if(selectedGroup.split(',').length > 5){
                                        setValidationError({techTalents: 'Max 5 Tech Group are allowed'})
                                        return false
                                    }
                                    return true
                                }else{
                                    return true
                                }
                            })
                   
                })}
               
                onSubmit={(values) => {
                    if((phoneNumberValue).replace('(','').replace(')','').replace('-','').replace(' ','').trim() == '' || phoneNumberValue.length == 14){
                        saveUser({
                            first_name: values.firstName,
                            last_name: values.lastName,
                            linkedin_profile_url: (appendLinkedinValue != undefined) ? appendLinkedinValue : '' ,
                            phone_number: (phoneNumberValue).replace('(','').replace(')','').replace('-','').replace(' ','').trim(),
                            email: values.email,
                            role: selectRole,
                            email_confirmed: selectEmailConfirmed,
                            user_approved: selectUserApproved,
                            location: ( (selectedLocation === 'null') ? '' : selectedLocation),
                            tech_group: selectedGroup,
                            leads_limit: values.leads_limit,
                            slack_member_id: slackId
                        })
                    }
                }}
            >
                <StyledForm>
                    <Row>
                        <Col lg={2}></Col>
                        <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={6} sm={6} lg={6}>
                                        <TextInput
                                            label="First Name*"
                                            name="firstName"
                                            type="text"
                                            id="firstName"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={6} sm={6} lg={6}>
                                        <TextInput
                                            label="Last Name*"
                                            name="lastName"
                                            type="text"
                                            id="lastName"
                                            width="100%"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} lg={6}>
                                        <TextInput
                                            label="Email*"
                                            name="email"
                                            type="email"
                                            id="email"
                                            width="100%"
                                            readOnly={true}
                                        />
                                     </Col>
                                     <Col xs={6} sm={6} lg={6}>
                                        <Label>{'Location'}</Label>
                                        <CreatableSelect
                                        name="streetAddress"
                                        id="streetAddress"
                                        placeholder="Location"
                                        isClearable="true"
                                        onChange={(e) =>
                                            locationChange(e)
                                        }
                                        defaultValue={defaultLocation}
                                        options={locationOptions}
                                        styles={colourStyles}
                                    />
                                     </Col>
                                </Row>
                                <Row>
                                    
                                  
                                     <Col xs={6} sm={6} lg={6}>
                                        <Select
                                                label="Role"
                                                name="role"
                                                width="100%"
                                                disabled={user.role === 'admin' || user.role === 'finance' || user.role === 'sales'}
                                                value={selectRole}
                                                onChange={onRoleChange}
                                            >
                                            {/* <option value="">Select a role</option> */}
                                            <option value="talent">Talent</option>
                                            <option value="employer">Employer</option>
                                            <option value="recruiter">Recruiter</option>
                                            <option value="text_messenger">Text Messenger</option>
                                            {(user.role === 'sales') ? <option value="sales">Sales</option> :''}
                                            {(user.role === 'admin') ? <option value="admin">Admin</option> :''}
                                            {(user.role === 'finance') ? <option value="finance">Finance</option> :''}
                                        </Select>
                                    </Col>
                                
                                    <Col xs={6} sm={6} lg={6}>
                                        <PhoneInput
                                            label="Phone"
                                            name="phoneNumber"
                                            type="text"
                                            id="phoneNumber"
                                            width="100%"
                                            placeholder="(xxx) xxx-xxxx"
                                            onChange={handleChange}
                                            value={phoneNumberValue}
                                            style={{ marginRight: '10px' }}
                                        />
                                    </Col>
                                </Row>
                                
                                {selectRole && selectRole === 'talent' && (
                                    <Row>
                                        <Col xs={6} sm={6} lg={6}>
                                            <TextInput
                                                label="Linkedin Profile"
                                                name="linkedinProfile"
                                                type="text"
                                                id="linkedinProfile"
                                                width="100%"
                                            />
                                        </Col>
                                        <Col xs={6} sm={6} lg={6}>
                                            <Label>{'Select Talent Tech Group*'}</Label>
                                            <SelectRect
                                                options={techTalentList}
                                                name='tech_group'
                                                id='tech_group'
                                                placeholder={'Select Talent Tech Group (Max 5)'}
                                                onChange={handleTechTalentList}
                                                defaultValue={defaultGroup}
                                                isMulti
                                                menuPosition='fixed'
                                            />
                                            {validationError.techTalents && (
                                                <div  style={{color:'red',fontSize: '10px',marginTop: '5px'}}>{validationError.techTalents}</div>
                                            )}
                                        </Col>
                                    </Row>

                                )}
                               
                                <Row>
                                    <Col xs={6} sm={6} lg={6}>
                                        <Select
                                            label="Email Confirmed"
                                            name="emailConfirmed"
                                            width="100%"
                                            value={selectEmailConfirmed}
                                            onChange={onEmailConfirmedChange}
                                        >
                                            <option value="">Select a value</option>
                                            <option value="true">Approved</option>
                                            <option value="false">Pending</option>
                                        </Select>
                                    </Col>
                                    <Col xs={6} sm={6} lg={6}>
                                        <Select
                                            label="User Approval"
                                            name="userApproved"
                                            width="100%"
                                            value={selectUserApproved}
                                            onChange={onUserApprovedChange}
                                        >
                                            <option value="">Pending</option>
                                            <option value="true">Approved</option>
                                            <option value="false">Reject</option>
                                        </Select>
                                    </Col>
                                    <Col xs={6} sm={6} lg={6}>
                                            <TextInput
                                                label="Slack member Id"
                                                name="slack_member_id"
                                                type="text"
                                                onChange={handleChangeSlackId}
                                                // id="slack_member_id"
                                                value={slackId}
                                                width="100%"
                                            />
                                        </Col>
                                </Row>
                                {selectRole && selectRole === 'sales' && (
                                <Row>
                                    <Col xs={6} sm={6} lg={6}>
                                        <TextInput
                                            label="Leads Limit*"
                                            name="leads_limit"
                                            type="text"
                                            id="leads_limit"
                                            width="100%"
                                        />
                                    </Col>
                                </Row>
                                )}
                        </Col>
                    </Row>
                    {/* <Row> */}
                        <Col xs={6} sm={6} lg={6} style={{marginLeft:'-13px'}}>
                        { selectRole && selectRole === 'talent' &&
                                        <>
                                        <div style={{display:'flex' ,flexDirection:'column'}}>
                                        <label style={{fontSize:'14px'}}>Video or Audio Intro</label>
                                        <div style={{display:'flex'}}>
                                      <label className={`${styles.EdituploadFileLabel}`}  for="actual-btn" >Upload file</label>
                                        <input
                                            id="actual-btn"
                                            hidden
                                            //  ref={inputRef}
                                            className="VideoInput_input"
                                            type="file"
                                            onChange={handleFileChange}
                                            accept=".mov,.mp4, .mp3"
                                            />
                                            <div style={{display:'flex'}}>
                                            <span className={`${styles.Editnofilechosen}`}>{(source?.name) || 'No file chosen'}</span>
                                               {source?.name && <span
                                                className={`${styles.closeFileIcon}`} onClick={() => {
                                                     setSource(null)
                                                     setFileError('')
                                                    }}
                                                > x</span>}
                                                </div>
                                            </div>
                                                </div>
                                            <p style={{color:'red', fontSize:'13px'}}>{fileError}</p>
                                            {audio_video_url &&   <a href={audio_video_url}>Download Audio/video file</a> }
                                            </> }
                        </Col>
                     {/* </Row> */}
                    <Row>
                       <Col xs={6} sm={6} lg={6}>
                            <div style={{ marginTop: '18px' }}>
                                <Button type="submit" disabled={fileError} className="mr-sm-3 mt-2">
                                    Update
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => window.history.back()}
                                    className="ml-sm-3 mt-2"
                                >
                                    Go Back
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </StyledForm>
            </Formik>
        </div>
        </UserProfileContext.Provider>
    )
}

export default UserManagementEditPage
