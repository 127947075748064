import React, { useState, useEffect, useRef } from 'react'
import { Row } from 'react-bootstrap'
import feather from 'feather-icons'
import styled from 'styled-components'
import ResumeDragDrop from '../../common/ResumeDragDrop/ResumeDragDrop'

const ButtonClose = styled.button`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(
        44deg,
        #e30c0cc7 -1.19%,
        #cf000091 53.94%,
        #eb0c0cf0 102.59%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    
    > svg {
        transform: scale(1);
        color: #ffff;
    }
`

const UploadResumeModal = (props) => {

  const {
    onHide,
    resumeFile,
    handleOnRemoveResumes,
    handleResumeFiles,
    handleSubmitResume,
    validationErrors,
    handleClearResumes,
    buttonDisabled
  } = props

  useEffect(() => {
    feather.replace()
  })

  return (
    <>
      <Row className='w-100 justify-content-end'>
        <ButtonClose onClick={() => onHide()}>
          <i data-feather="x"></i>
        </ButtonClose>
      </Row>
      <Row className='justify-content-center p-0 m-0'>
        <ResumeDragDrop
          title={'resume'}
          buttonlabel={'Get Matched Jobs'}
          files={resumeFile}
          handleOnRemoveFiles={handleOnRemoveResumes}
          handleFiles={handleResumeFiles}
          handleOnSubmit={handleSubmitResume}
          validationErrors={validationErrors}
          handleClearResumes={handleClearResumes}
          buttonDisabled={buttonDisabled}
        />
      </Row>
    </>
  )
}

export default UploadResumeModal