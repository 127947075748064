import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Card from '../shared/Card'
import P from '../shared/P'
import styles from '../styles/JobsStatusPage.module.scss'
import DataTable from 'react-data-table-component-footer'
import { Col, Row, Spinner } from 'react-bootstrap'
import '../styles/DataTableCustom.scss'
import moment from 'moment'
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import Select from 'react-select'
import Util from '../../../../utils/util'
import { CSVLink } from 'react-csv'
import { DisplayPagination } from '../../Analytic/styles/AnalyticPage.styled'
import SearchBar from '../../../common/SearchBar/SearchBar'
import isEmpty from 'lodash.isempty'


const MidWeekMetrics = ({ user_names }) => {
    const queryParams = new URLSearchParams(window.location.search)
    const [activityLog, setActivityLog] = useState([])
    const [totalActivityLog, setTotalActivityLog] = useState([])
    const [loading, setLoading] = useState([])
    const [perPage, setPerPage] = useState(25)
    const [totalPages, setTotalPages] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [totalCount, setTotalCount] = useState()
    const [currentCount, setCurrentCount] = useState()
    const [activePage, setActivePage] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')
    const [sortField, setSortField] = useState('activity_logs.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [dateRange, setDateRange] = useState({ startDate: moment().startOf('week').toDate(), endDate: moment().toDate() })
    const handleDate = (date) => {
        setDateRange(date)
    }
    const [defaultselected, setdefaultselected] = useState({ value: '', label: 'All' })
    const [userNames, setUserNames] = useState([{ value: '', label: 'All' }, ...user_names])
    const [selectedUserName, setselectedUserName] = useState('')
    const [defaultActionselected, setdefaultActionselected] = useState({ value: '', label: 'All' })
    const [selectedAction, setSelectedAction] = useState('')

    const [actionLists, setActionLists] = useState([{ value: '', label: 'All' }, { value: 'approve', label: 'Approve' }, { value: ['create', 'add_single_candidate', 'organization_name'], label: 'Create' }, { value: ['destroy', 'cancel', 'delete'], label: 'Delete' }, { value: ['index', 'reference_data_management', 'analytics_dashboard', 'change_recruiter_organization', 'job_process_leads','new', 'company_profile', 'search_recruiter', 'import_candidate_linkedin_url', 'move_candidate_to_stage', 'detail', 'all_jobs', 'job_organization_dashboard', 'show_job_search_page'], label: 'List' }, { value: ['pending', 'job_pending_leads'], label: 'Pending' }, { value: 'reject', label: 'Reject' }, { value: ['show', 'edit', 'change_stage', 'from_resume'], label: 'Show' }, { value: ['update', 'change_candidates_type_bulk', 'security_update', 'remove_member_from_organization', 'update_status', 'update_outreach_status'], label: 'Update' }])

    const csvExportRef = React.useRef();
    const [exportDatas, setExportDatas] = useState([])



    let tableHeaders = [
        { label: 'Action by', key: 'full_name' },
        { label: 'Role', key: 'role' },
        { label: 'Activity', key: 'note' },
        { label: 'Affected Object', key: 'object_name' },
        { label: 'Section', key: 'controller' },
        { label: 'Action', key: 'action' },
        { label: 'Created Date', key: 'created_at' },
    ]



    const fetchData = async () => {
        setLoading(true)
        setTotalPages(0)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date = moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = `/admin/mid_week_metrics.json?page=${activePage == 0 ? 1 : activePage}&start_date=${start_date}&end_date=${end_date}&search=${searchTerm}&user_names=${selectedUserName}&perform_action=${selectedAction}&sortField=${sortField}&sortDirection=${sortDirection}`

        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            onSuccess: (response) => {
                setTotalActivityLog(response.data.total_count)
                let sr_no = (activePage == 0 ? 0 : (activePage - 1)) * 25
                setActivityLog(
                    response.data.activity_logs.map((activity_log) => (sr_no = sr_no + 1, {
                        id: sr_no,
                        user_id: activity_log.user_id,
                        full_name: activity_log.full_name,
                        organization_count: activity_log.organization_count,
                        role: isEmpty(activity_log.stage) ? "No" : "Yes",
                        object_name: parseInt(activity_log.total_emails) + parseInt(activity_log.total_inmails),
                        controller: parseInt(activity_log.total_submissions) + parseInt(activity_log.total_first_interview),
                        action: activity_log.all_jobs_have_linked_urls == 'false' ? "No" : "Yes",
                        params: activity_log.param,
                        note: isEmpty(activity_log.source_at) ? "No" : "Yes",
                        created_at: moment(activity_log.created_at).format('MM/DD/YYYY hh:mm:ss'),
                    }))
                )

                setPerPage(response.data.per_page)
                setTotalPages(response.data.total_pages)
                setTotalCount(response.data.total_count)
                setCurrentCount(response.data.current_counts)
                setStartRecord((activePage - 1) * response.data.per_page)
                setLoading(false)

            },
        })
    }

    const exportDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date = moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = `/admin/mid_week_metrics.json?&start_date=${start_date}&end_date=${end_date}&search=${searchTerm}&user_names=${selectedUserName}&perform_action=${selectedAction}&sortField=${sortField}&sortDirection=${sortDirection}`

        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Downloading...',
            onSuccess: (response) => {
                setTotalActivityLog(response.data.total_count)
                let sr_no = (activePage == 0 ? 0 : (activePage - 1)) * 25
                setExportDatas(
                    response.data.activity_logs.map((activity_log) => (sr_no = sr_no + 1, {
                        id: sr_no,
                        user_id: activity_log.user_id,
                        full_name: activity_log.full_name,
                        role: isEmpty(activity_log.stage) ? "No" : "Yes",
                        object_name: activity_log.total_emails + activity_log.total_inmails,
                        controller: parseInt(activity_log.total_submissions) + parseInt(activity_log.total_first_interview),
                        action: activity_log.all_jobs_have_linked_urls,
                        params: activity_log.param,
                        note: isEmpty(activity_log.source_at) ? "No" : "Yes",
                        created_at: moment(activity_log.created_at).format('MM/DD/YYYY hh:mm:ss'),
                    }))
                )
                csvExportRef?.current?.link.click();
            },
        })
    }

    useEffect(() => {
        fetchData()
    }, [dateRange, activePage, selectedUserName, selectedAction, sortDirection, sortField])

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('user_query',value)
    }

    const handleSort = async (column, sortDirection) => {
        if (column.sortField != undefined && sortDirection != undefined) {
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const handleAction = (event) => {
        console.log(event)
        setSelectedAction(event.value)
    }

    const columns = [
        {
            name: 'No',
            selector: row => row.id,
            maxWidth: '6%',
            minWidth: '6%',
        },
        {
            name: 'Recruiter Name',
            selector: row => row.full_name,
            cell: (row) => <div title={row.full_name}>{row.full_name}</div>,
            wrap: true,
            maxWidth: '30%',
            minWidth: '15%',
            sortable: true,
            sortField: 'full_name',
        },
        {
            name: 'Organization Count',
            selector: row => row.organization_count,
            wrap: true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'organization_count',
        },
        {
            name: 'ATS Updated?',
            selector: row => row.role,
            cell: (row) => <div title={row.role}>{row.role}</div>,
            wrap: true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'role',
        },
        {
            name: 'Sourcing Requirements',
            selector: row => row.note,
            cell: (row) => <div title={row.note}>{row.note}</div>,
            maxWidth: '10%',
            minWidth: '10%'
        },
        {
            name: 'Emails + Inmails',
            selector: row => row.object_name,
            wrap: true,
            maxWidth: '15%',
            minWidth: '15%',
            sortable: true,
            sortField: 'activity_logs.object_name',
        },
        {
            name: 'Submitted + Confirmed Interview',
            selector: row => row.controller,
            wrap: true,
            maxWidth: '15%',
            minWidth: '15%',
            sortable: true,
            sortField: 'activity_logs.controller',
        },
        {
            name: 'LinkdeIn Job URL',
            selector: row => row.action,
            cell: (row) => <div title={row.action}>{row.action}</div>,
            maxWidth: '15%',
            minWidth: '15%',
        },
    ]

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '10px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{ width: '540px' }}>
                    Mid Week Metrics
                </P>
                <div>
                    <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                        Export CSV
                    </button>
                </div>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas}
                headers={tableHeaders}
                filename={"MidWeekMetrics.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                {/* <Col lg="12" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                    <div className='d-flex justify-content-between p-0 w-100'>
                        <div className='d-flex align-items-end p-0'>
                            {totalActivityLog > 0 &&
                                <DisplayPagination>Displaying  {(activePage == 0) ? 1 : startRecord + 1} - {currentCount > totalActivityLog ? totalActivityLog : currentCount} of {totalActivityLog}</DisplayPagination>
                            }
                            
                        </div>
                    
                        
                        <div className="d-flex justify-content-end align-items-center">
                            <div style={{ display: 'grid', marginLeft: '10px' }}>
                                <label>
                                    Action by
                                </label>
                                <Select
                                    defaultValue={defaultselected}
                                    options={userNames}
                                    onChange={(event) => setselectedUserName(event.value)}
                                    name="user_names"
                                    className={styles.colourStyles}
                                />
                            </div>
                            <div style={{ display: 'grid', marginLeft: '10px' }}>
                                <label>
                                    Action
                                </label>
                                <Select
                                    defaultValue={defaultActionselected}
                                    options={actionLists}
                                    onChange={(event) => handleAction(event)}
                                    name="perform_action"
                                    className={styles.colourStyles}
                                />
                            </div>
                            <div style={{ display: 'grid', marginLeft: '10px' }}>
                                <label>
                                    Select Date Range
                                </label>
                                <DateRangePicker
                                    handleOnSubmit={handleDate}
                                    defaultDate={dateRange}
                                />
                            </div>
                            <div style= {{ marginTop: '28px', marginLeft: '10px', minWidth: '350px' }}>
                                <SearchBar
                                    placeholder="Search Affected Object..."
                                    value={searchTerm}
                                    setValue={setSearchTerm}
                                    onChange={(e) => onChangeSearchTerm(e.target.value)}
                                    onEnterPressed={() => {
                                        activePage === 1 ? fetchData() : setActivePage(1)
                                    }}
                                    hideButton = {false}
                            />
                        </div>
                        </div>
                    </div>
                </Col> */}
            </Row>
            <DataTable
                title={false}
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={activityLog}
                progressPending={loading}
                progressComponent={<P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                responsive={true}
                onChangePage={page => {
                    setActivePage(page)
                }}
            />
        </Card>
    )
}

export default MidWeekMetrics