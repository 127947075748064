import React, { Fragment, useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import { Container,Box, Icon } from './styles/TimeSheet.styled';
import Select from 'react-select';
import Text from '../../common/Styled components/Text';
import axios from 'axios';
import { TimeEntryContext } from '../../../stores/TimeEntryStore';
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'

const _formState = {
  _date: '2021-12-22',
  _org: '',
  _hour: 0.0,
  _submissionCount: 0,
  _interviewedCount:0,
  _hiredCount: 0,
  _job: {label: 'choose job', value: '0'},
  _submitted: [],
  _confirmed: [],
  _interviewed: [],
  _hired: [],
  _candidates: []
};



function TimeEntryForm({children}) {
  const {dispatch, state} = useContext(TimeEntryContext)
  const {recruiterName, recruiterId, organizationId} = state
  const {jobs, organization, organizations} = state.meta

  // form state
  const [formState, setFormState] = useState(_formState);
  const {_date, _org, _hour, _job, _submitted, _confirmed, _interviewed, _hired, _candidates} = formState

  const handleChange = (value, field) => {
    const temp = new Object()
    if(field == '_date'){
      value = value.target.value
    }
    if(field == '_hour'){
      value = value.target.value
    }
    temp[field] = value
    setFormState({...formState, ...temp})
  };

  const handleJobChange = (obj) => {

    console.log('> job changed, fetching transitions for this job only now ..')
    const URL = `http://localhost:3000/users/timesheet/4/new.json?job_id=${obj.value}`
    const response = axios.get(URL).then(res => {
      const entry = res.data.entry
      const {candidates} = entry
      setFormState({
        ...formState, 
        ...{_job: obj},
        ...{_candidates: candidates}
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('submitting form ...');

    const URL = '/users/timesheet'
    const payload = new FormData()
    payload.append('timesheet[entry_date]', _date)
    payload.append('timesheet[name]',recruiterName)
    payload.append('timesheet[organization_name]',organization.label)
    payload.append('timesheet[job_name]', _job.label)
    payload.append('timesheet[recruiter_id]',recruiterId)
    payload.append('timesheet[job_id]',_job.value)
    payload.append('timesheet[organization_id]', organizationId)

    payload.append('timesheet[hours]', _hour)
    payload.append('timesheet[total_submissions]', _submitted.length)
    payload.append('timesheet[confirmed_interviews]', _confirmed.length)
    payload.append('timesheet[interviewed]', _interviewed.length)

    payload.append('timesheet[submitted_candidate_ids]',             JSON.stringify( _submitted.map(obj => JSON.stringify(obj))   ) )
    payload.append('timesheet[confirmed_interviewed_candidate_ids]', JSON.stringify( _confirmed.map(obj => JSON.stringify(obj))   ) )
    payload.append('timesheet[interviewed_candidates_ids]',          JSON.stringify( _interviewed.map(obj => JSON.stringify(obj)) ) )
    payload.append('timesheet[hired_candidates_ids]',                JSON.stringify( _hired.map(obj => JSON.stringify(obj))       ) )


    //headers
    const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
        
    const headers = {
      headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
      },
    }
    makeRequest(URL, 'post', payload, headers).then(function(res) {
      console.log("> form create success")
      console.log(res.data)
    });


  };

  return (
    <Container padding="50px">
      {children}
      <form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
        <Row className='mb-3'>
          <Col>
            <Text>Date:</Text>
            <input type='date' id='entry_date' className='form-control' name='entry_date' value={_date} onChange={ (event) => handleChange(event, '_date')}  />
          </Col>
  
          <Col>
            <Text>Organization:</Text>
            <Select
              defaultValue={organizations[0]}
              options={organizations}
              onChange={(event) => onSelect(event, 'organization')}
              placeholder="Select Organization"
              // isDisabled={true}
              name = 'organization'
            />
          </Col>
          <Col>
            <Text>Total Hours:</Text>
            <input type='input' className='form-control' id='total_hours' name='total_hours' value={_hour} onChange={(event) => handleChange(event, '_hour')}/>
          </Col>
          <Col>
            <Text>Jobs:</Text>
            <Select
              defaultValue={_job}
              name='_jobs'
              options={jobs}
              className='job_selec_box'
              minMenuHeight={6}
              onChange={(event) => handleJobChange(event, '_job')}
              name='job'
              placeholder='Select a job'
            />
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Text>Submitted Candidates:</Text>
            <Select
              defaultValue={_submitted}
              isMulti
              options={_candidates}
              onChange={(event) => handleChange(event, '_submitted')}
              minMenuHeight={6}
            />
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Text>Confirmed Interview:</Text>
            <Select
              defaultValue={_confirmed}
              isMulti
              options={_candidates}
              onChange={(event) => handleChange(event, '_confirmed')}
              name='_confirmed'
              minMenuHeight={6}
            />
          </Col>
        </Row>


        <Row className='mb-3'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Text>Interviewed:</Text>
            <Select
              defaultValue={_interviewed}
              isMulti
              onChange={(event) => handleChange(event, '_interviewed')}
              options={_candidates}
              name='_interviewed'
              minMenuHeight={6}
            />
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Text>Hired:</Text>
            <Select
              defaultValue={_hired}
              isMulti
              onChange={(event) => handleChange(event, '_hired')}
              options={_candidates}
              name='_hired'
              minMenuHeight={6}
            />
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col>
            <input type='submit' value='Submit' className='btn btn-primary btn-lg mt-2' />
          </Col>
        </Row>

      </form>
    </Container>
  );
}

export default TimeEntryForm;
