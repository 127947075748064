import React from 'react'

import styles from './styles/NoOrganization.module.scss'
import ScheduledInterviwes from '../../common/ScheduleInterviwes/ScheduledInterviwes'

const NoOrganization = () => {

    return (
        <div className={styles.errorPage}>
            <section className={styles.getStartedSection}>
                    <div className={styles.container}>
                        <ScheduledInterviwes />
                    </div>
            </section>
        </div>
    )
}
export default NoOrganization
