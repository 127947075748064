import React, { useState, useEffect } from 'react'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import './../styles/communities.scss'
import Util from '../../../../utils/util'
import Paginator from '../../../common/Paginator/Paginator'
import image1 from '../../../../../assets/v2/imgs/placeholder.jpg'
import image2 from '../../../../../assets/v2/imgs/avatar/ava_1.png'
import '../../../../../assets/v2/css/main.css'

const HomePageV4 = ({ categories, current_user, communityID }) => {
  const [showMoreCategory, setShowMoreCategory] = useState(false)
  const [communitiesList, setCommunitiesList] = useState([])
  const [filterCategoryId, setFilterCategoryId] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [totalCategory, setTotalCategory] = useState(0)
  const [currentCounts, setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [loading, setLoading] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [searchValue, setSearchValue] = useState('');
  const techImage = [
    'https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/y241izv5vooksc0xnvhyqme247w4',
    'https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/baybrqfz9ntw0ctu590y1jydl0o7',
    'https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/cedaron_medical_inc_logo.jpg',
    'https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/1631341652936.jpg',
    'https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/1630559372932+(1).jpg',
    'https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/New-NovaSolix-Logo.png'
  ]
  const categoryData = categories.map(category => ({
    label: category.name,
    value: category.id
  }));
  let visibleCategories, hideCategories;
  if (categories.length > 6) {
    visibleCategories = categories.slice(0, 6);
    hideCategories = categories.slice(6, categories.length);
  } else {
    visibleCategories = categories;
    hideCategories = [];
  }
  const fetchData = () => {
    setLoading(true)
    const url = '/communities.json'
    makeRequest(url, 'get', { params: { page: (activePage + 1), category_id: filterCategoryId, searchValue: searchValue } }, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setCommunitiesList(
          res.data.communities
            // .sort((a, b) => b.member_count - a.member_count)
            .map((community) => ({
              id: community.id,
              name: community?.name,
              avatar_image: community?.avatar_image,
              community_type: community?.community_type,
              cover_image: community?.cover_image,
              description: community?.description,
              paid: community?.paid,
              small_description: community?.small_description,
              member_count: community?.member_count,
            }))
        )
        setPageCount(res.data.total_pages)
        setTotalCategory(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setLoading(false)
      },
    })
  }
  useEffect(() => {
    fetchData()
  }, [activePage, filterCategoryId])

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };

  return (
    <div>
      <div className="archive-header pb-20">
        <div className="container">
          {/* <div class="block-job-bg block-job-bg-homepage-2">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block">
                <div class="box-image-findjob findjob-homepage-2 ml-0 wow animate__animated animate__fadeIn">
                  <figure><img alt="jobhub" src="https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/job_referrals.png" /></figure>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="box-info-job pl-90 pt-30 pr-90">
                  <h5 class="heading-36 mb-30 mt-30 wow animate__animated animate__fadeInUp">                    Join our free community of 10,000+ active talent  and chat now with top employers, recruiters, and talent.</h5>
                  <div class="box-button-shadow mt-30 wow animate__animated animate__fadeInUp">
                    {current_user ?
                      (communityID > 0 ?
                        <a href={`${'/communities/' + communityID}`} class="btn btn-default">Get Started</a>
                        :
                        <a href='/communities/new' class="btn btn-default">Get Started</a>
                      )
                      :
                      <a href='/signup/talent2' class="btn btn-default">Get Started</a>
                    }
                  </div>
                  <p class="text-lg wow animate__animated animate__fadeInUp">
                    13,236 Members
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <div className='text-center mt-50 mb-50'>
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-12 col-sm-12 col-12 d-none d-md-block">
                <h4 className="mb-30 mt-10">
                  Join our free community of 10,000+ active talent  and chat now with top employers, recruiters, and talent
                </h4>
                <h3>
                  {current_user ?
                    (communityID > 0 ?
                      <a href={`${'/communities/' + communityID}`}>Get Started</a>
                      :
                      <a href='/communities/new'>Get Started</a>
                    )
                    :
                    <a href='/signup/talent2'>Get Started</a>
                  }
                </h3>
                <p>13,236 Members</p>
              </div>
              <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                <figure><img alt="jobhub" src="https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/job_referrals.png" /></figure>
              </div>
            </div>
            <div className="row mt-20">
              {techImage && techImage.map((community, index) => {
                return (<div className="col-lg-2 col-md-3">
                  <div className="card-grid-2 hover-up">
                    <div className="text-center card-grid-2-image community_imgv4">
                      <img src={community || image2} alt={community} />
                    </div>
                  </div>
                </div>)
              })}
            </div>
            <h6 className="text-center mb-20">
              and 100+ other companies
            </h6>
          </div>


          <h3 className="mb-10 text-center mx-auto">
            Discover job referral communities
          </h3>
          <h5 className="mb-20 text-center w-75 mx-auto">
            or <a href='/communities/new'>create your own</a>
          </h5>
          <div className="widget_search mb-20">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 col-md-6">
                <div className="search-form-1">
                  <form onSubmit={handleSubmit}>
                    <input type="text" value={searchValue} onChange={handleChange} placeholder="Search for anything" />
                    <button type="submit"><i className="fi-rr-search"></i></button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 skill-header">
              <a href="#" onClick={() => { setFilterCategoryId('') }} className={`btn btn-border btn-sm mr-10 ${filterCategoryId === '' ? 'active' : ''}`}>All</a>

              {visibleCategories.map((category, index) => (
                <>
                  <a key={index} onClick={() => { setFilterCategoryId(category.id) }} href="#" className={`btn btn-border btn-sm mr-10 ${filterCategoryId === category.id ? 'active' : ''}`}>{category.name}</a>
                </>
              ))}
              {showMoreCategory && (
                <>
                  {hideCategories.map((category, index) => (
                    <a key={index} href="#" onClick={() => { setFilterCategoryId(category.id) }} className={`btn btn-border btn-sm mr-10 ${filterCategoryId === category.id ? 'active' : ''}`}>{category.name}</a>
                  ))}
                  <a onClick={() => setShowMoreCategory(false)} className="btn btn-border btn-sm mr-10">Less...</a>
                </>
              )}
              {!showMoreCategory && (
                <a onClick={() => setShowMoreCategory(true)} className="btn btn-border btn-sm mr-10">More...</a>
              )}
            </div>
            {/* <div className="col-md-2 skill-header">
              <a href="#" className="btn btn-border btn-sm mr-10">Filter <i className="fi-rr-filter"></i></a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="post-loop-grid">
        <div className="container">
          <div className="row">
            {communitiesList && communitiesList.map((community, index) => {

              return (<div className="col-lg-4 col-md-6">
                <div className="card-grid-2 hover-up">
                  <div className="text-center card-grid-2-image community_img">
                    <a href={`${'/communities/' + community.id}` + '/about'}>
                      <img src={community.cover_image || image1} alt={community.name} />
                    </a>
                    <label className="btn-urgent">#{index + 1}</label>
                  </div>
                  <div className="card-block-info p-0 p-10">
                    <div className="row">
                      <div className="col-lg-12 col-6">
                        <a target='_blank' href={`${'/communities/' + community.id}` + '/about'} className="card-2-img-text">
                          <span className="card-grid-2-img-small">
                            <img src={community.avatar_image || image2} alt={community.name} /></span>
                          <h6><b>{community.name}</b></h6>
                        </a>
                      </div>
                    </div>
                    <p className="mt-10 short_desc" title={community.small_description || community.description}><a target='_blank' href={`${'/communities/' + community.id}` + '/about'}>{community.small_description || community.description}</a></p>
                    <div className="mt-15 d-flex justify-content-between">
                      <span className="icon-card"><i className="fi-rr-shield-check"></i>{Util.capitalize(community.community_type) || 'Private'}</span>
                      <span className="icon-card"><i className="fi-rr-user"></i>{((community.member_count >= 1000) ? (community.member_count / 1000) + 'K' : community.member_count)} {(community.member_count > 1) ? 'Members' : 'Member'} </span>
                      <span className="icon-card"><i className="fi-rr-credit-card"></i>{(community.paid) ? 'Paid' : 'Free'}</span>
                    </div>
                  </div>
                </div>
              </div>)
            })}
          </div>
          {(pageCount > 1) ?
            <div className="paginations">
              <Paginator
                pageCount={pageCount}
                activePage={activePage}
                setActivePage={setActivePage}
                pageWindowSize={30}
              />
            </div>
            : ''}
        </div>
      </div>
    </div>
  );
}
export default HomePageV4