import React, { useState } from 'react'
import { Button, Container, W4text, Row } from './styles/Cookies.styled'

const cookieStorage = {
    getItem: (item) => {
        const cookies = document.cookie
            .split(';')
            .map((cookie) => cookie.split('='))
            .reduce(
                (acc, [key, value]) => ({ ...acc, [key.trim()]: value }),
                {}
            )
        return cookies[item]
    },

    setItem: (name, value, days) => {
        var expires = ''
        if (days) {
            var date = new Date()
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
            expires = '; expires=' + date.toUTCString()
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/'
    },
}

function Cookies({version}) {
    const cookieName = version
    const [showPopup, setShowPopup] = useState(
        !cookieStorage.getItem(cookieName)
    )

    const saveToStorage = () => {
        cookieStorage.setItem(cookieName, true, 30)
        setShowPopup(!cookieStorage.getItem(cookieName))
    }

    if (!showPopup) {
        return <></>
    }
    return (
        // <Container>
        //     <Row>
        //         <W4text size="16px" color="#ffffff">
        //             This website uses cookies to enhance the user experience.  See
        //             our
        //             <a href="/privacy_policy" style={{ margin: '0px 4px' }}>
        //                 privacy policy
        //             </a>
        //             for more.
        //         </W4text>
        //     </Row>
        //     <Row jContent="flex-end">
        //         <Button tb="9px" lr="13px" onClick={() => saveToStorage()}>
        //             I Accept
        //         </Button>
        //     </Row>
        // </Container>
        ''
    )
}

export default Cookies
