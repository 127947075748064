import React, { useEffect, useState } from 'react'
import Image from 'react-bootstrap/Image'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import { Row, Col , Spinner} from 'react-bootstrap'
import Paginator from '../../common/Paginator/Paginator'
import {firstCharacterCapital} from '../../../utils/index'
import Util from '../../../utils/util'

import CalendarIcon from '../../../../assets/images/recruiter_home_icons/icon-calendar.svg'
import AiIcon from '../../../../assets/images/category_assets/innovation.png'
import AccountingIcon from '../../../../assets/images/category_assets/accounting_finance.png'
import AllIcon from '../../../../assets/images/category_assets/all.png'
import BigDataIcon from '../../../../assets/images/category_assets/big-data.png'
import CryptoIcon from '../../../../assets/images/category_assets/crypto.png'
import FintechIcon from '../../../../assets/images/category_assets/fintech.png'
import HealthCIcon from '../../../../assets/images/category_assets/healthcare.png'
import HealthTIcon from '../../../../assets/images/category_assets/heart-rate.png'
import LegalIcon from '../../../../assets/images/category_assets/legal-document.png'
import MedicalIcon from '../../../../assets/images/category_assets/medical.png'
import TechnicalIcon from '../../../../assets/images/category_assets/technical-support.png'
import SalesIcon from '../../../../assets/images/category_assets/sales.png'
import RecruitmentIcon from '../../../../assets/images/category_assets/recruitment.png'
import SoftwareEngineerIcon from '../../../../assets/images/category_assets/software-eng.png'
import MarketersIcon from '../../../../assets/images/category_assets/marketers.png'
import DesignersIcon from '../../../../assets/images/category_assets/web-design.png'
import AccountEIcon from '../../../../assets/images/category_assets/account_executives.png'
import SDRsIcon from '../../../../assets/images/category_assets/recruitment.png'
import AdminIcon from '../../../../assets/images/category_assets/admin.png'
import CustomerSIcon from '../../../../assets/images/category_assets/goal.png'
import HrIcon from '../../../../assets/images/category_assets/hr.png'


import './styles/OnDemandRecruiterPagemodule.scss'
import {
    HR,
    P,
    HighText,
    DisplayPagination,
    TopSection,
    TopListSection,
    Card,
} from './styles/OnDemandRecruiter.styled'
import '../RecruiterHomePage/styles/RecruiterHomePageModule.scss'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const type_of_recruiters = [
    {category:"All",image : AllIcon},
    {category:"Recruiters",image : RecruitmentIcon},
    {category:"Software Engineers",image : SoftwareEngineerIcon},
    {category:"Marketers",image : MarketersIcon},
    {category:"Designers",image : DesignersIcon},
    {category:"Account Executives",image : AccountEIcon},
    {category:"SDRs",image : SDRsIcon},
    {category:"Accounting / Finance",image : AccountingIcon},
    {category:"Admin",image : AdminIcon},
    {category:"Customer Success",image : CustomerSIcon},
    {category:"HR",image : HrIcon},
    {category:"Legal",image : LegalIcon},
];

const tech_talents = [
    {category:"All",image : AllIcon},
    {category:"Accounting/Finance",image : AccountingIcon},
    {category:"AI / ML",image : AiIcon},
    {category:"Big Data",image : BigDataIcon},
    {category:"Crypto",image : CryptoIcon},
    {category:"Fintech",image : FintechIcon},
    {category:"Health Care",image : HealthCIcon},
    {category:"Health Tech",image : HealthTIcon},
    {category:"Legal",image : LegalIcon},
    {category:"Medical Device",image : MedicalIcon},
    {category:"Technical",image : TechnicalIcon},
    {category:"Sales",image : SalesIcon},
];

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 11,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
      slidesToSlide: 2
    }
  };

const rateList = [
    { value: 'all', label: 'All' },
    { value: '30', label: '$30-$50' },
    { value: '50', label: '$50-$75' },
    { value: '75', label: '$75-$100' },
    { value: '100', label: '> $100' }
];

const locations = [
    { value: 'all', label: 'All' },
];


const businessSizesList = [
    { value: 'all', label: 'All' },
    { value: 'Business with 1-9 Employees', label: 'Business with 1-9 Employees' },
    { value: 'Business with 10-99 Employees', label: 'Business with 10-99 Employees' },
    { value: 'Fortune 500 Company', label: 'Fortune 500 Company' }
];

const industriesList = [
    { value: 'all', label: 'All' },
    { value: 'Administartion', label: 'Administartion' },
    { value: 'Information Technology and Services', label: 'Information Technology and Services' },
    { value: 'Hospital & Health Care', label: 'Hospital & Health Care' },
    { value: 'Financial Services', label: 'Financial Services' },
    { value: 'Accounting', label: 'Accounting' },
    { value: 'Computer Software', label: 'Computer Software' },
    { value: 'Government Administration', label: 'Government Administration' },
    { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },
    { value: 'Human Resources', label: 'Human Resources' },
    { value: 'Management Consulting', label: 'Management Consulting' },
    { value: 'Logistics & Supply Chain', label: 'Logistics & Supply Chain' },
    { value: 'Architecture & Planning', label: 'Architecture & Planning' },
    { value: 'Facilities Services', label: 'Facilities Services' },
    { value: 'Professional Training & Coaching', label: 'Professional Training & Coaching' },
    { value: 'Graphic Design', label: 'Graphic Design' },
    { value: 'Broadcast Media', label: 'Broadcast Media' },
    { value: 'Business Supplies & Equipment', label: 'Business Supplies & Equipment' },
    { value: 'Computer Hardware', label: 'Computer Hardware' },
    { value: 'Computer & Network Security', label: 'Computer & Network Security' },
    { value: 'Investment Management', label: 'Investment Management' },
    { value: 'Information Services', label: 'Information Services' },
    { value: 'Product Management', label: 'Product Management' },
    { value: 'Project Management', label: 'Project Management' },
    { value: 'Data Analytics', label: 'Data Analytics' },
    { value: 'Dev-Ops', label: 'Dev-Ops' },
    { value: 'Events Services', label: 'Events Services' },
    { value: 'Executive Office', label: 'Executive Office' },
    { value: 'Packaging and Containers', label: 'Packaging and Containers' },
    { value: 'Market Research', label: 'Market Research' }
];

const yearsOfExperiencesList = [
    { value: 'all', label: 'All' },
    { value: '0-1', label: '<1 Year' },
    { value: '1-2', label: '1-2 Years' },
    { value: '2-5', label: '2-5 Years' },
    { value: '5-10', label: '5-10 Years' },
    { value: '10-20', label: '10+ Years' },
];

const DetailCard = ({ title, value }) => {
    return (
        <div className="d-flex flex-column recruiterDetails">

            <span className="articles">{title}</span>
            <span className="number1">{value}</span>
            
        </div>
    )
}

const RecruiterBadges = ({title, badgesList}) => {
    return (
        <>
        <span className="font-weight-bold">{title}:  </span>
        {badgesList.map((data) => {
            return ( <span className="badge badge-pill badge-primary">{data}</span>
            )
        })}
        </>
    )
}

const IndustryCard = ({ image, industry }) => {
    return (
        <Card>
            <Image src={image} />
            <P
                size={13}
                height={18}
                marginTop={12}
                color="#606BE4"
                weight={800}
            >
                {industry}
            </P>
        </Card>
    )
}

const OnDemandRecruiterPage = ({ver,currentUser,states,all_company,all_univercity}) => {

    var active_page = window.sessionStorage.getItem('user_activePage')
    var query = window.sessionStorage.getItem('user_query')
    const queryParams = new URLSearchParams(window.location.search)
    const defaultSkillQury = queryParams.get("skills")
    const defaultIndustryQury = queryParams.get("industries")
    const defaultBusinessQury = queryParams.get("businessSizes")
    const defaultRateQury = queryParams.get("rates")
    const defaultLocationQury = queryParams.get("location")
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [loading, setLoading] = useState(true)
    const [current_ver, setVersion] = useState(ver)
    const [currentUserCount, setCurrentUserCount] = useState(0)
    const [pageCountUsers, setPageCountUsers] = useState(0)
    const [totalUsers, setTotalusers] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [users, setUsers] = useState([])
    const [locations, setLocations] = useState(states)
    const [allCompany, setCompanys] = useState(all_company)
    const [allUnivercity, setUnivercities] = useState(all_univercity)
    const [isLoading, setIsLoading] = useState(false)
    const [locationOptions, setLocationOptions] = useState([])
    const [companiesOptions, setCompaniesOptions] = useState([])
    const [universitiesOptions, setUniversitiesOptions] = useState([])
    
    // Filter set up
    const [selectedRates, setRatesFilter] = useState((defaultRateQury)? defaultRateQury : '')
    const [selectedLocation, setLocationsFilter] = useState((defaultLocationQury)? defaultLocationQury : '')
    // const [selectedSkills, setSkillsFilter] = useState((defaultSkillQury)? defaultSkillQury : '')
    const [selectedIndustries, setIndustriesFilter] = useState((defaultIndustryQury)? defaultIndustryQury : '')
    const [selectedBusinessSizes,setBusinessSizesFilter] = useState((defaultBusinessQury)? defaultBusinessQury : '')
    const [selectedTechCategory, setTechCategoryFilter] = useState('')
    const [selectedTypeCategory, setTypeCategoryFilter] = useState('')
    
    //New Filter
    const [selectedCompany, setCompanyFilter] = useState('')
    const [selectedUnivercity, setUnivercityFilter] = useState('')
    const [selectedExperience, setExperiencedFilter] = useState('')

    const colourStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',minHeight:'40px' })
    };
    
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        fetchData();
    }, [activePage,selectedTechCategory,selectedTypeCategory,selectedLocation,selectedRates,selectedIndustries,selectedBusinessSizes,selectedCompany,selectedUnivercity,selectedExperience]);

    const fetchData = async () => {
        setLoading(true)
        window.sessionStorage.setItem('user_activePage',activePage)
        const url = '/top_talent_list.json'
        const response = await makeRequest(
            `${url}?page=${activePage + 1}&limit=30&rates=${selectedRates}&location=${selectedLocation}&industries=${selectedIndustries}&businessSizes=${selectedBusinessSizes}&tech_category=${selectedTechCategory}&type_category=${selectedTypeCategory}&company=${selectedCompany}&university=${selectedUnivercity}&experience=${selectedExperience}`,
            'get',
            {}
        )

        const users = response.data.users.map((user) => ({
            id: user.id,
            first_name : user.first_name,
            last_name : user.last_name,
            name: user.first_name ? firstCharacterCapital(user.first_name) + ' ' + firstCharacterCapital(user.last_name) : " ",
            location: user.location ? user.location?.replace('(usa)','(US)') : "NA",
            email : user.email,
            image : user?.avatar_url,
            university:  user?.school,
            about : user?.summary ? user?.summary : user?.description,
            rate : "$25 Per hour",
            profile_url: '/talent_profile/'+user.id+'/'+user.first_name+'_'+user.last_name 
        }))

        setUsers(users);
        //Set Filter
        // setDefaultLocation((selectedLocation)? locations?.filter((option) => option.value == selectedLocation) :{value: 'all', label: 'All'})
        // setDefaultRate((selectedRates)? rates.filter((option) => option.value == selectedRates) :{value: 'all', label: 'All'})
        
        setPageCountUsers(response.data.total_pages_users)
        setTotalusers(response.data.total_users)
        setCurrentUserCount(response.data.current_users_counts)
        setStartRecord(activePage * response.data.per_page)
        setLoading(false)
    }

    const loadLocationOptions = (inputValue, callback) => {
        setIsLoading(true)
        fetch(`/top_talent_filters?search=${inputValue}&type=location`)
        .then((res) => res.json())
        .then((res) => {
          let {locations} = res
          setIsLoading(false)
          setLocationOptions([{ value: 'all', label: 'All' }, ...locations]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(locationOptions);
    }

    const loadUniversityOptions = (inputValue, callback) => {
        setIsLoading(true)
        fetch(`/top_talent_filters?search=${inputValue}&type=universities`)
        .then((res) => res.json())
        .then((res) => {
          let {universities} = res
          setIsLoading(false)
          setUniversitiesOptions([{ value: 'all', label: 'All' }, ...universities]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(universitiesOptions);
    }

    const loadCompaniesOptions = (inputValue, callback) => {
        setIsLoading(true)
        fetch(`/top_talent_filters?search=${inputValue}&type=companies`)
        .then((res) => res.json())
        .then((res) => {
          let {companies} = res
          setIsLoading(false)
          setCompaniesOptions([{ value: 'all', label: 'All' }, ...companies]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(companiesOptions);
    }

    return (
        <>
            <TopListSection>
                {ver == 5 ? ( <HighText>  Hire Experienced Talent From The World's Top Companies And Universities - it's Free! </HighText> ) : (
                    <HighText> Hire Experienced Recruiters From The World's Top Companies And Universities -- it's Free! </HighText>)}
                
                </TopListSection>
            <Carousel
                responsive={responsive}
                ssr
                // showDots
                infinite
                containerClass="container-with-dots"
                itemClass="image-item"
            >
            {current_ver == 4 ? (
                tech_talents.map((card,index) => {
                    return (
                        <a href="javascript:void(0)" key={index}>
                            <span className="card-category" onClick={(e) => 
                                                {  
                                                    setTechCategoryFilter(card.category);
                                                    setActivePage(0);
                                                }
                                            }>
                                <img className="" src={card.image} alt="" width="24" height="24"/>
                                <span className="category-text">{card.category}</span>
                                { selectedTechCategory == card.category ?
                                (<HR></HR>) : (<></>) }
                            </span>
                        </a>
                    )
                })
            ) : (
                type_of_recruiters.map((card,index) => {
                    return (
                        <a href="javascript:void(0)" key={index}>
                            <span className="card-category" onClick={(e) =>
                                                {
                                                    setTypeCategoryFilter(card.category)
                                                    setActivePage(0);
                                                }
                                            }>
                                <img className="" src={card.image} alt="" width="24" height="24"/>
                                <span className="category-text">{card.category}</span>
                                { selectedTypeCategory == card.category ?
                                (<HR></HR>) : (<></>) }
                            </span>
                        </a>
                    )
                })
            )}
            </Carousel>
            <TopListSection>
            <Row className="w-100 filter_card">
                    <Col lg="10" md="10" sm="12" xs="12" className='p-0'>
                        <div style={{display: 'flex'}}>
                            {/* <div style={{marginLeft:'10px'}}>
                                <label>
                                    Rates
                                </label>
                                <Select
                                    options={rateList}
                                    onChange={(event)=>{setRatesFilter(event.value); setActivePage(0);}}
                                    name="rates"
                                    className="colourStyles"
                                />
                            </div> */}
                            <Col xs={12} sm={12} lg={2}>
                                <div>
                                    <label>
                                        Locations
                                    </label>
                                    <AsyncSelect
                                        isLoading={isLoading}
                                        cacheOptions
                                        loadOptions={loadLocationOptions}
                                        onChange={(event)=>{setLocationsFilter(event.value); setActivePage(0);}}
                                        placeholder={'Location'}
                                        defaultValue={[]}
                                        styles={colourStyles}
                                        noOptionsMessage={() => 'start typing the name of location'}
                                    />
                                </div>
                            </Col>
                            {/* <div>
                                <label>
                                    Industries
                                </label>
                                <Select
                                    options={industriesList}
                                    onChange={(event)=>{setIndustriesFilter(event.value); setActivePage(0);}}
                                    name="industries"
                                    className="colourStyles"
                                />
                            </div>
                            <div>
                                <label>
                                    Business Sizes
                                </label>
                                <Select
                                    options={businessSizesList}
                                    onChange={(event)=>{setBusinessSizesFilter(event.value); setActivePage(0);}}
                                    name="businessSizes"
                                    className="colourStyles"
                                />
                            </div> */}
                            <Col xs={12} sm={12} lg={2}>
                                <div>
                                <label>
                                    University
                                </label>
                                    <AsyncSelect
                                        isLoading={isLoading}
                                        cacheOptions
                                        loadOptions={loadUniversityOptions}
                                        onChange={(event)=>{setUnivercityFilter(event.value); setActivePage(0);}}
                                        placeholder={'University'}
                                        defaultValue={[]}
                                        styles={colourStyles}
                                        noOptionsMessage={() => 'start typing the name of University'}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} lg={2}>
                                <div>
                                <label>
                                    Company
                                </label>
                                    <AsyncSelect
                                        isLoading={isLoading}
                                        cacheOptions
                                        loadOptions={loadCompaniesOptions}
                                        onChange={(event)=>{setUnivercityFilter(event.value); setActivePage(0);}}
                                        placeholder={'Company'}
                                        defaultValue={[]}
                                        styles={colourStyles}
                                        noOptionsMessage={() => 'start typing the name of Company'}
                                    />
                                </div>
                            </Col>
                            <div>
                                <label>
                                    Years Of Experience
                                </label>
                                <Select
                                    options={yearsOfExperiencesList}
                                    onChange={(event)=>{setExperiencedFilter(event.value); setActivePage(0);}}
                                    name="allCompany"
                                    className="colourStyles"
                                />
                            </div>
                            {/* <div>
                                <FilterButton
                                    onClick={() =>
                                        (window.location.href = '/welcome/top_recruiter?rates='+selectedRates+"&&location="+selectedLocation+"&&industries="+selectedIndustries+"&&businessSizes="+selectedBusinessSizes)
                                    }
                                >
                                    Search
                                </FilterButton>
                            </div> */}
                        </div>
                    </Col>
                    {/* <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-center'>
                        {users.length > 0 &&
                            <DisplayPagination>Displaying { ((startRecord == 0)?1:startRecord) + '-' +currentUserCount} of { `${totalUsers} Recruiters`}</DisplayPagination>
                        }
                    </Col> */}
                </Row>
            </TopListSection>
            {loading == true &&
            <P className="text-center p-3" size="18px" color="#6077f4">
                <Spinner className='spinner' animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </P>
            }
            {users.length != 0 ||  loading ? (
            <TopListSection>
            <div className="row">
                {users.map((data,index) => {
                    return ( 
                        <div key={index} className="col-md-25">
                            {/* <div className="images"> */}
                                <div className="card h-100">
                                    <a href={data.profile_url}>
                                        {data.image ? (
                                            <img src={data.image} className="home_profile_image" alt="" />
                                        ) : (
                                            <div className="candidateAvtarImage home_profile_image" style={{width:'100%'}}>{ (data.first_name) ? (data?.first_name && Util.getFirstCharater(data?.first_name)) + (data?.last_name && Util.getFirstCharater(data?.last_name) ) : (data?.email && Util.getFirstCharater(data?.email) ) }</div>
                                        )} 
                                    </a>
                                    <div className="card-body">
                                        <h4><a href={data.profile_url} className="talent-name ">{data.name} <p>{data.university ? "("+data.university+")" : ''}</p></a></h4>
                                        <p className="card-text talent-desc">{data.about}
                                        </p>
                                        {/* <div className="talent-details">
                                            <span className=""><img src={CalendarIcon} style={{height: '18px',width:'18px',marginRight: '10px'}}/>{data.rate}</span>
                                        </div> */}
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>
                    )}
                )}
            </div>
            </TopListSection>
            ) : (
                <TopListSection>
                    <P size="20px" height="20px" align="center">
                        No Records Found! Please try another filter.
                    </P>
                </TopListSection>
            )}
            <div
                className="d-flex justify-content-center"
                style={{ marginTop: 'auto' }}
            >
                <Paginator
                    activePage={activePage}
                    setActivePage={setActivePage}
                    pageCount={
                        pageCountUsers
                    }
                    pageWindowSize={5}
                    showGoToField={false}
                />
            </div>
            <TopSection
                style={{ paddingTop: '42px' }}
            ></TopSection>
        </>
    )
}

export default OnDemandRecruiterPage
