import React, { useReducer, useState,useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import SelectRect from 'react-select'
import { Row, Col } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import { Button, H1, StyledForm, Label } from '../../Admin/styles/UserManagementEditPage.styled'
import PhoneInput from '../../../common/PhoneInput/PhoneInput'
import { normalizeInput } from '../../../../utils'
import Select from 'react-select'
import TextInput  from '../../Admin/shared/TextInput'
import { AgencyRecruiterContext, reducer } from '../../../../stores/Agency/AgencyRecruitersFormStore'
import isEmpty from 'lodash.isempty'

const Create = ({user, edit}) => {
    const [selectRole,setSelectRole] = useState(user.role)
    const [selectEmailConfirmed,setSelectEmailConfirmed] = useState(user.email_confirmed)
    const [selectUserApproved,setSelectUserApproved] = useState(user.user_approved)
    const [phoneNumberValue, setPhoneNumberValue] = useState(normalizeInput(user.phone_number,"") || '')
    const [selectedLocation, setSelectedLocation] = useState(user.location || "")
    const [defaultLocation, setdefaultLocation]  = useState(((user.location === 'null')) ? {}  : [{value: user?.location, label: user?.location}])
    const [locationOptions, setLocationOptions] = useState([])
    const [errorDesignations, setErrorDesignations] = useState()
    const [designations, setDesignations] = useState([ { value: 'recruiter', label: 'RECRUITER' }, { value: 'account_manager', label: 'ACCOUNT MANAGER' }])
    const [defaultDesignation, setDefaultDesignation] = useState(`recruiter.designation` && designations.filter((row) => user.designation == row.value).map((row) => ({ value: row.value, label: row?.label?.toUpperCase() })))
    const [designation, setDesignation] = useState(user?.designation || "")
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            width: '100%',
            height: '43px',
            marginTop: '1.5px',
            backgroundColor: '#f5f7ff',
            border: 'none',
            zIndex: '15',
        }),
    }
    const { email, role, firstName, lastName, linkedinProfile, phoneNumber, emailConfirmed, userApproved, location} = user

    let initialState = {
        ...initialState,
        user,
        email,
        role,
        firstName,
        lastName,
        phoneNumber,
        emailConfirmed,
        location,
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const saveUser = (newUser) => {
        dispatch({ type: 'update-user', value: newUser,id:user.id })
    }

    const addUser = async (newUser) => {
        dispatch({ type: 'add-user', value: newUser })
    }
    const onRoleChange = (event) => {
        setSelectRole(event.target.value)
    }
    const onEmailConfirmedChange = (event) => {
        setSelectEmailConfirmed(event.target.value)
    }
    const onUserApprovedChange = (event) => {
        setSelectUserApproved(event.target.value)
    }

    const locationChange = (e) => {
        setSelectedLocation(e.value)
    }

    const handleChange = ({ target: { value } }) => {
        setPhoneNumberValue(normalizeInput(value, ""));
    }

    const fetchRequired = async () => {
        const url1 = `/filter_candidate_on_location`
        const formData = new FormData()
        formData.append('filter_word', '')
        const response = await axios
            .post(url1, formData)
            .then((res) => res.data)
            .catch((error) => console.log(error))
        const locationArray = response.filter.map((res) => ({
            value: `${res.city}, ${res.state}`,
            label: `${res.city}, ${res.state}`,
        }))
        setLocationOptions([...locationArray])
    }
    
    useEffect(() => {
        fetchRequired()
    }, [])
    

    const [validationError, setValidationError] = useState({
        techTalents: null,
    });

    return (
        <AgencyRecruiterContext.Provider value={(state, dispatch)}>
        <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
            <H1> Recruiter Information</H1>
            <Formik
                initialValues={{
                    email:  user?.email || '',
                    firstName: user?.first_name || '',
                    lastName:  user?.last_name || '',
                    phoneNumber:  '',
                    emailConfirmed:  '',
                    userApproved:  '',
                    designation: designation || ''
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email()
                        .required('Email is required'),        
                })}
               
                onSubmit={(values) => {
                    if (isEmpty(designation)) {
                        setErrorDesignations('designation is required')
                        return false;
                    }
                    if(edit === true){
                        saveUser({
                            first_name: values.firstName,
                            last_name: values.lastName,
                            email: values.email,
                            role: selectRole,
                             designation: designation,
                             location: ''
                        })
                    }else {
                        addUser({
                            first_name: values.firstName,
                            last_name: values.lastName,
                            phone_number: values.phoneNumber,
                            designation: designation,
                            email: values.email,
                            location: ( (selectedLocation === 'null') ? '' : selectedLocation),
                        })
                    }
                }}
            >
                <StyledForm>
                    <Row className='d-block'>
                        <Col style={{width: '50%', margin: 'auto'}}>
                            <TextInput
                                label="Email*"
                                name="email"
                                type="email"
                                id="email"
                                width="100%"
                                disabled={edit}
                            />
                        </Col>
                        <Col>
                            <div style={{ 'marginBottom': '10px', width: '50%', margin: 'auto' }}>
                                <label>
                                    Designation
                                </label>
                                <Select
                                defaultValue={defaultDesignation}
                                options={designations}
                                onChange={(event) =>{
                                    setDesignation(event.value)
                                    setErrorDesignations('')
                                }
                                    }
                                name="designation"
                                />
                            <span style={{color: 'red'}}>{errorDesignations && errorDesignations}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                       <Col  className='d-flex justify-content-center'>
                            <div style={{ marginTop: '18px' }}>
                               {edit == true ?<Button 
                                        type="submit">
                                            Update
                                            </Button>
                                            :
                                            <Button 
                                        type="submit">
                                            Create
                                            </Button> }
                                <Button
                                    type="button"
                                    onClick={() => window.history.back()}
                                    className="ml-sm-3 mt-2"
                                >
                                    Go Back
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </StyledForm>
            </Formik>
        </div>
        </AgencyRecruiterContext.Provider>
    )
}

export default Create
