import React from 'react'
import Card from '../shared/Card'
import { Row, Box } from '../../../common/Styled components/styled'
import Text from '../../../common/Styled components/Text'
import EntryTable from './EntryTable'

function DetailReportPage(props) {
  const { timesheets, startDate, endDate, name }= props

  return (
    <Card>
      <Row aItems="center" jContent="space-between" gutterButtom>
        <Box>
          <Text varient="h1" color="primary" size="28px" gutterButtom>
            Detail Time Entry Report
          </Text>
        </Box>
      </Row>
      <Row
        direction="row"
        jContent="space-between"
        aItems="center"
        gutterButtom
      >
        <Box>
          <Text varient="h6" color="primary" size="18px" givePadding>
            For: {name}
          </Text>
        </Box>
      </Row>
      <Row
        direction="row"
        jContent="space-between"
        aItems="center"
        gutterButtom
      >
        <Box aItems="center" jContent="space-between">
          <Text weight ='medium' varient="h6" color="primary" size="18px" givePadding>
            From: <Text weight ='light' varient="span" color="primary" size="16px" givePadding>{startDate}</Text>  
            to: <Text weight ='light' varient="span" color="primary" size="16px" givePadding>{endDate}</Text>
          </Text>
        </Box>
      </Row>
      <Row>       
        <EntryTable tableType="details" data={timesheets} date={{startDate: startDate, endDate: endDate}}/>
      </Row>
    </Card>
  )
}

export default DetailReportPage
