import React, { useState, useEffect } from 'react'
import { Formik, Form, Field, useField } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'react-bootstrap'
import axios from 'axios'
import _ from 'lodash'

import FileButton from '../../common/FileButton/FileButtonV2'
import styles from '../SignupPage/styles/Signup.module.scss'
import { colourStyles_v2 } from '../../common/FilterJob/styles/FilterJob_v2.styles';
import PhoneInputV2 from '../../common/PhoneInput/PhoneInputV2'
import { normalizeInput } from '../../../utils'
import '../../../../assets/v2/css/main.css'
import '../../../../assets/v2/css/process.css'
import {
    Message,
    Row,
    ErrorMessage,
} from './styles/CreateProfilePage.styled'
import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from "react-select/async"
import { getLocation} from '../../api/filter/filter.api';

const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
const CHOOSE_ROLE = 'CHOOSE_ROLE'


const experience_list = [
    {id:1, name:"Entry", title:'0 - 1 Years', active:1 },
    {id:2, name:"Intermediate", title:'2 - 3 Years', active:0},
    {id:3, name:"Advanced", title:'4 - 5 Years', active:0},
    {id:4, name:"Experienced", title:'6 - 9 Years', active:0},
    {id:5, name:"Expert", title:'10+ Years', active:0}
];

const ContactDetailPageV2 = ({
    formData,
    setFormData,
    submitData,
    loading,
    setResult,
    result,
    selectedRole,
    user,
    currentUser,
    showInfoPanel
}) => {
    const [roleDescription, setRoleDescription] = useState('')
    const [resume, setResume] = useState(formData.contactDetails.resume)
    const [resumeError, setResumeError] = useState(null)
    const [states, setStates] = useState([])
    const [phoneNumber, setPhoneNumber] = useState(
        formData.contactDetails.phoneNumber
    )
    const [selectedLocation, setSelectedLocation] = useState('')
    const [locationOptions, setLocationOptions] = useState([])
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [appendLinkedinValue, setAppendLinkedinValue] = useState(formData.contactDetails.linkedinProfile)
    const [phoneError, setPhoneError] = useState()
    const [locationError, setLocationError] = useState()
    const [currentStep, setCurrentStep] = useState(formData.stepCount)
    const [activeStepClass, setActiveStepClass] = useState('active-step1')
    const [source, setSource] = React.useState();
    const [fileError, setFileError] = useState('')
    const [jtError, setJTError] = useState(null)
    const [titleInput, setTitleInput] = useState('');
    const [titles, setTitles] = useState([]);
    const [locationInput, setLocationInput] = useState('');
    const [locations, setLocations] = useState([]);
    const [experienceList, setExperienceList] = useState(experience_list);
    const [experiences, setExperiences] = useState(formData.contactDetails.experiences)
    const [experienceError, setExperienceError] = useState('')
    const [isLoading, setLoading] = useState(false)
    
    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            contactDetails: {
                ...prev.contactDetails,
                resume: resume,
            },
        }))
    }, [resume])

    useEffect(() => {
        fetchRequired()
    }, [])

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            stepCount: currentStep,
            // contactDetails: {
            //     ...prev.contactDetails,
            //     experiences: experiences,
            // },
        }))
    }, [currentStep,activeStepClass,experiences])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // const url = URL.createObjectURL(file);
        if (file?.size > 50000000) {
            setFileError('The uploaded file should be less than 50MB')
            setSource(file);
        }
        else {
            if ((!file.type.includes('mp4')) && !(file.type.includes('mp3')) && !(file.type.includes('audio')) && !(file.type.includes('video'))) {
                setFileError("invalid format")
                setSource(file);
            } else {
                setFileError('')
                setSource(file);
            }
        }
    };

    useEffect(() => {
        setResult({})
        if (isSubmitted) {
            if (selectedRole === 'talent') {
                if (!resume) {
                    setResumeError('Resume is required')
                    return
                }
                submitData()
            }
        }
        setIsSubmitted(false)
    }, [isSubmitted])

    const fetchRequired = async () => {
        const url = `/signup/contracts?name=contact_note&role=${selectedRole}`
        axios.get(url).then((res) => {
            setRoleDescription(res.data?.content)
        })

        const lookupsUrl = '/signup/lookups'
        axios.get(lookupsUrl).then((res) => {
            setStates([...res.data.states])
        })

        const url1 = `/filter_candidate_on_location`
        const formData = new FormData()
        formData.append('filter_word', '')
        const response = await axios
            .post(url1, formData)
            .then((res) => res.data)
            .catch((error) => console.log(error))
        const locationArray = response.filter.map((res) => ({
            value: `${res.city}, ${res.state}`,
            label: `${res.city}, ${res.state}`,
        }))
        setLocationOptions([...locationArray])
    }

    const candidateFields = {
        firstName: (formData.contactDetails.firstName != '') ? formData.contactDetails.firstName : (user?.first_name) ? user?.first_name : '',
        lastName: (formData.contactDetails.lastName != '') ? formData.contactDetails.lastName : (user?.last_name) ? user?.last_name : '',
        linkedinProfile: formData.contactDetails.linkedinProfile,
        phoneNumber: phoneNumber,
        resume: formData.contactDetails.resume,
        activeJobSeeker: '',
        title: '',
        remote_location: '',
        locations: '',
        experiences: ''
    }



    const candidateValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        linkedinProfile: Yup.string()
            .notRequired()
            .nullable(true)
            .test(
                'linkedin only',
                'Invalid url, please add Linkedin url only',
                function (value) {
                    if (value) {
                        try {
                            let hostname = new URL(value).hostname
                            setAppendLinkedinValue(value)
                            if (value === 'https://www.linkedin.com' || value === 'https://www.linkedin.com/') {
                                return false
                            }
                            return (
                                hostname === 'linkedin.com' || hostname === 'www.linkedin.com'
                            )
                        } catch (error) {
                            setAppendLinkedinValue(value)
                            if (value === 'www.linkedin.com/' || value === 'https://www.linkedin.com/' || value === 'linkedin.com/' || value === 'linkedin.com' || value === 'https://linkedin.com' || value === 'https://linkedin.com/') {
                                return false
                            }
                            let checkDomainExist = value.indexOf("linkedin.com/")
                            if (checkDomainExist == -1) {
                                return false
                            } else if (checkDomainExist == 0) {
                                let splitValue = value.split('linkedin.com/')
                                if (splitValue[1] === '') {
                                    return false
                                }
                                let tempURL = 'https://www.' + value
                                setAppendLinkedinValue(tempURL)
                                return true
                            } else {
                                let splitValue = value.split('linkedin.com/')
                                if (splitValue[0] === 'www.') {
                                    let tempURL = 'https://' + value
                                    setAppendLinkedinValue(tempURL)
                                    return true
                                }
                            }
                            return false
                        }
                    } else {
                        return true
                    }
                }
            ),
    })

    const handleChange = ({ target: { value } }) => {
        setPhoneError('')
        setPhoneNumber(normalizeInput(value, ''))
    }
    const locationChange = (e) => {
        setSelectedLocation(e.value)
        setLocationError('')
    }

    const nextStep = () => {
        let newStep = currentStep + 1
        if(newStep < 5){
            setCurrentStep(newStep)
        }
        if(newStep == 1){
            setActiveStepClass('active-step1')
        }else if(newStep == 2){
            setActiveStepClass('active-step2')
        }else if(newStep == 3){
            setActiveStepClass('active-step3')
        }else if(newStep == 4){
            setActiveStepClass('active-step4')
        }
    }

    const previewStep = () => {
        let newStep = (currentStep - 1)
        setCurrentStep(newStep)
        if(newStep == 1){
            setActiveStepClass('active-step1')
        }else if(newStep == 2){
            setActiveStepClass('active-step2')
        }else if(newStep == 3){
            setActiveStepClass('active-step3')
        }else if(newStep == 4){
            setActiveStepClass('active-step4')
        }
    }

    const handleSubmit = async (values) => {
        if (formData.selectedRole === 'talent' && !resume) {
            setResumeError('Resume is required')
            return
        }
        if (selectedRole === 'recruiter' && !phoneNumber) {
            return
        }
        if (
            phoneNumber.length != 0 &&
            phoneNumber.length != 14
        ) {
            return
        }
        if (formData.selectedRole === 'talent' && phoneNumber.length != 0 && phoneNumber.length != 14) {
            return
        }
        if (selectedRole === 'recruiter' &&
            _.isEmpty(selectedLocation)) {
            return
        }

        if (values.linkedinProfile) {
            values.linkedinProfile = appendLinkedinValue
        }
        const new_experience = experienceList.filter(item => item.active === 1).map(item => item.name).join(',');
        
        setFormData((prev) => ({
            ...prev,
            contactDetails: {
                ...prev.contactDetails,
                ...values,
                resume: resume,
                phoneNumber: phoneNumber,
                location: locations?.join(','),
                title: titles?.join(','),
                experiences: new_experience
            },
        }))
        setIsSubmitted(true)
    }

    const TextField = ({ label, ...props }) => {
        const [field, meta] = useField(props);
        return (
          <>
            <input {...field} {...props} />
            <ErrorMessage width={props.width}>
                {meta.touched && meta.error ? meta.error : ''}
            </ErrorMessage>
          </>
        );
    };

    const handleTitleChange = (e) => {
        setTitleInput(e.target.value);
    };

    const addTitles = () => {
        // Split the input into individual skills and filter out any empty strings
        const newTitles = titleInput.split(',').map(skill => skill.trim()).filter(skill => skill !== '');

        if((titles.length + newTitles.length) > 20){
            setJTError('Max 20 job titles are allowed')
        }else if (newTitles.length > 0) {
            setTitles([...titles, ...newTitles]);
            setTitleInput('');
        }
    };

    const removeTitles = (indexToRemove) => {
        setTitles(titles.filter((_, index) => index !== indexToRemove));
    };

    const handleLocationChange = (e) => {
        setLocationInput(e.target.value);
    };

    const handleSelectOption = (selectedOptions) => {
        console.log(selectedOptions)
        console.log(selectedOptions.map( ({value, label}) =>value))
        setLocations([...locations, ...selectedOptions.map( ({value, label}) =>value)]);
    }

    const addLocations = () => {
        // Split the input into individual skills and filter out any empty strings
        const newlocations = locationInput.split(',').map(skill => skill.trim()).filter(skill => skill !== '');

        if((newlocations.length + locations.length) > 20){
            setLocationError('Max 20 locations are allowed')
        }else if (newlocations.length > 0) {
            setLocations([...locations, ...newlocations]);
            setLocationInput('');
        }
    };

    const removeLocations = (indexToRemove) => {
        setLocations(locations.filter((_, index) => index !== indexToRemove));
    };

    const experienceSelect = (selectIndex) => {
        let newList = [...experienceList];
        const updatedItems = experienceList.map(item => {
            if (item.id === selectIndex) {
                if(item.active === 1){
                    return { ...item, active: 0 };
                }else{
                    return { ...item, active: 1 };
                }
            }
            return item;
        });
        setExperienceList(updatedItems)
    }

    const loadOptions = async (inputValue, callback) => {
        setLoading(true)
        const url1 = `/filter_candidate_on_location`
        const formData = new FormData()
        formData.append('filter_word', inputValue)
        const response = await axios
            .post(url1, formData)
            .then((res) => res.data)
            .catch((error) => console.log(error))
        const locationArray = response.filter.map((res) => ({
            value: `${res.city}`,
            label: `${res.city}`,
        }))
        setLoading(false)
        setLocationOptions([...locationArray])
        callback(locationOptions);
    }

    const handleKeyPress = async (inputValue, callback) => {
        let arrayOptions = [];
        setLocationOptions([])
        arrayOptions = await getLocation(inputValue);
        setLocationOptions(arrayOptions)
        callback(arrayOptions);
    };

    const handleCreate = (event) => {
        console.log(event)
        const newItem = { value: event, label: event };
        const cloneObject = locations;
        setLocationOptions([])
        cloneObject.push(newItem);
        // setLocations([...locations, ...cloneObject]);
    };

    const handleFilter = (event) => {
        console.log(event)
    };

    return (
        <>
            <section className="section-box" style={{ overflow: 'inherit !important' }}>
                <div className={"process-wrap "+ activeStepClass}>
                    <div className="process-main row">
                        <div className="col-3">
                            <div className="process-step-cont">
                                <div className="process-step step-1" />
                                <span className="process-label">Contact</span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="process-step-cont">
                                <div className="process-step step-2" />
                                <span className="process-label">Job Title</span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="process-step-cont">
                                <div className="process-step step-3" />
                                <span className="process-label">Experience
                                </span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="process-step-cont">
                                <div className="process-step step-4" />
                                <span className="process-label">Locations</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-hero">
                    <div className="banner-inner">
                        <div className="row">
                            <div className={currentStep == 3 ? "col-lg-9 login" : "col-lg-5 login"} style={{ margin: '0px auto 20px auto' }}>
                                <div className="block-banner pt-6">
                                    <Formik
                                        initialValues={
                                            candidateFields
                                        }
                                        validationSchema={
                                            candidateValidation
                                        }
                                        validate={(values) => {
                                            const errors = {}
                                            setLocationError('')
                                            if (selectedRole === 'talent' && !resume) {
                                                errors.resume = 'Resume is required'
                                                setResumeError(errors.resume)
                                            }
                                            if (selectedRole === 'talent' && !phoneNumber) {
                                                errors.phoneNumber = 'Phone Number is required'
                                                setPhoneError('Phone Number is required')
                                            }
                                            if (
                                                phoneNumber.length != 0 &&
                                                phoneNumber.length != 14
                                            ) {
                                                errors.phoneNumber = 'Accepts only 10 digits'
                                            }
                                            // Handle Title Error 
                                            if (
                                                titles.length < 1 && currentStep == 2
                                            ) {
                                                errors.titles = 'Job Titles are required'
                                                setJTError('Job Titles are required')
                                            }
                                            if (
                                                titles.length > 20 && currentStep == 2
                                            ) {
                                                errors.titles = 'Job Titles are required'
                                                setJTError('Max 20 job titles are allowed')
                                            }
                                            // Handle Experience Error 
                                            const new_experience = experienceList.filter(item => item.active === 1)?.map(item => item.name)?.join(',');
                                            if (
                                                new_experience == '' && currentStep == 3
                                            ) {
                                                errors.experiences = 'Max 20 locations are allowed'
                                                setExperienceError('Please select atleast 1 experience')
                                            }
                                            // Handle Location Error
                                            if (
                                                locations.length < 1 && currentStep == 4
                                            ) {
                                                errors.titles = 'Locations are required'
                                                setLocationError('Locations are required')
                                            }
                                            if (
                                                locations.length > 20 && currentStep == 4
                                            ) {
                                                errors.titles = 'Max 20 locations are allowed'
                                                setLocationError('Max 20 locations are allowed')
                                            }

                                            console.log('Validate Call')
                                            console.log(errors)
                                            return errors
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            console.log('call submit here')
                                            setFileError('')
                                            setFormData({
                                                ...formData,
                                                contactDetails: {
                                                    ...values,
                                                    phoneNumber: phoneNumber,
                                                    resume: resume,
                                                },
                                            })
                                            nextStep()
                                            if(currentStep == 4){
                                                handleSubmit(values)
                                            }
                                        }}
                                    >
                                        {(formik) => {
                                            const { errors } = formik
                                            return (
                                                <Form className="wow animate__ animate__fadeInUp animated">
                                                    {(currentStep == 1) && (
                                                    <>    
                                                        <h5 className="heading-banner text-center wow animate__animated animate__fadeInUp" style={{ fontWeight: 500, fontSize: '30px', marginBottom: '20px' }}>Contact Details</h5>
                                                            <div className="row m1">
                                                                <div className="col-md-6">
                                                                    <TextField type="text" name="firstName" className="form-input input-user mr-10 w-100" placeholder="First Name"/>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <TextField type="text" name="lastName" className="form-input input-user w-100" placeholder="Last Name"/>
                                                                </div>
                                                            </div>
                                                            <TextField type="text" name="linkedinProfile" className="form-input input-user m1 w-100" placeholder="LinkedIn Profile"/>
                                                            <PhoneInputV2
                                                                name="phoneNumber"
                                                                type="text"
                                                                id="phoneNumber"
                                                                className="form-input input-phone m1 w-100"
                                                                placeholder="(xxx) xxx-xxxx"
                                                                onChange={handleChange}
                                                                value={phoneNumber}
                                                            />
                                                            <p className="m1" style={{ color: 'red', fontSize: '10px' }}>{phoneError}</p>    
                                                            <FileButton
                                                                label="Add Resume"
                                                                type="button"
                                                                width={321}
                                                                file={resume}
                                                                resumeError={
                                                                    resumeError
                                                                }
                                                                setResumeError={
                                                                    setResumeError
                                                                }
                                                                getFile={(file) =>
                                                                    setResume(file)
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                    {(currentStep == 2) && (
                                                        <>
                                                            <h5 className="heading-banner text-center wow animate__animated animate__fadeInUp" style={{fontWeight: 500, fontSize: '30px'}}>Job Titles</h5>
                                                            <h5 className="text-center wow animate__animated animate__fadeInUp" style={{fontWeight: 500, fontSize: '15px'}}>What job titles should we search for?</h5>
                                                            <div className="d-flex" style={{padding: "22px 30px"}}>
                                                                <input name="title" type="text" onChange={handleTitleChange} className="form-input form-cus input-keysearch mr-10" placeholder="Job Title" value={titleInput}></input>
                                                                <button type="button" onClick={addTitles} className="btn btn-default btn-find wow animate__animated animate__fadeInUp questions" style={{margin: '0px'}}>Add +</button>
                                                            </div>
                                                            <div className="list-tags-banner mt-20 wow animate__ animate__fadeInUp animated" style={{visibility: 'visible', animationName: 'fadeInUp'}}>
                                                                <div className="block-tags text-center">
                                                                {titles.map((title, index) => (
                                                                    <a href="#" className="btn btn-tags-sm mb-10 mr-5">{title} <span style={{marginLeft: '10px', fontSize: '12px'}} onClick={() => removeTitles(index)} >X</span></a>
                                                                ))}    
                                                                </div>
                                                            </div>
                                                            <p className="m1 text-center" style={{ color: 'red', fontSize: '10px' }}>{jtError}</p> 
                                                        </>
                                                    )}
                                                    {(currentStep == 3) && (
                                                        <>
                                                            <h5 className="heading-banner text-center wow animate__ animate__fadeInUp animated" style={{fontWeight: 500, fontSize: '30px', visibility: 'visible', animationName: 'fadeInUp'}}>Experience</h5>
                                                            <h5 className="text-center wow animate__ animate__fadeInUp mt-10 animated" style={{fontWeight: 500, fontSize: '15px', visibility: 'visible', animationName: 'fadeInUp'}}>Which experience level(s) should we target for you?</h5>
                                                            <div className="row">
                                                                <div className="col-lg-12 mx-auto">
                                                                    <div className="row pt-5 text-center experience">
                                                                        <div className="col-lg-1 col-md-6 col-sm-12 col-12 mb-md-30" />
                                                                        {experienceList.map((exper) => {
                                                                            return (
                                                                                <div key={exper.id} className="col-lg-2 col-md-6 col-sm-12 col-12 mb-md-30 text-break">
                                                                                    <div className={(exper.active == 1 ? 'card-grid-4 hover-up active' : 'card-grid-4 hover-up')} onClick={()=> experienceSelect(exper.id)} >
                                                                                        <div className="card-grid-4-info">
                                                                                            <h6 className="mt-20" style={{fontWeight: 'bold'}}>{exper.name}</h6>
                                                                                            <p className="text-normal mt-15 mb-20">{exper.title}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        )}
                                                                        <div className="col-lg-1 col-md-6 col-sm-12 col-12 mb-md-30" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className="m1 text-center" style={{ color: 'red', fontSize: '10px' }}>{experienceError}</p> 
                                                        </>
                                                    )}
                                                    {(currentStep == 4) && (
                                                        <>
                                                            <h5 className="heading-banner text-center wow animate__animated animate__fadeInUp" style={{fontWeight: 500, fontSize: '30px'}}>Locations</h5>
                                                            <h5 className="text-center wow animate__animated animate__fadeInUp" style={{fontWeight: 500, fontSize: '15px'}}>What cities would you like to work in?</h5>
                                                            {/* <div className="mt-20" style={{padding: "22px 30px"}}>
                                                                <AsyncSelect
                                                                    isMulti
                                                                    isLoading={isLoading}
                                                                    isClearable={true}
                                                                    cacheOptions
                                                                    loadOptions={loadOptions}
                                                                    onChange={handleSelectOption}
                                                                    placeholder={'Locations'}
                                                                    // styles={colourStyles}
                                                                    noOptionsMessage={() => 'start typing the name of locations'}
                                                                    className='mt-1'
                                                                />
                                                            </div> */}
                                                            <div className="d-flex mt-20" style={{padding: "22px 30px"}}>
                                                                {/* <AsyncCreatableSelect
                                                                    isMulti
                                                                    createOptionPosition="first"
                                                                    styles={colourStyles_v2}
                                                                    value={locationOptions}
                                                                    onChange={(event) => handleFilter(event, key)}
                                                                    loadOptions={(inputValue, callback) =>
                                                                        handleKeyPress(inputValue, callback)
                                                                    }
                                                                    menuPlacement="auto"
                                                                    fmaxMenuHeight={30}
                                                                    onCreateOption={(event) => {
                                                                        handleCreate(event);
                                                                    }}
                                                                    menuPortalTarget={document.querySelector('body')}
                                                                /> */}
                                                                <input name="locations" type="text" onChange={handleLocationChange} className="form-input form-cus input-keysearch mr-10" placeholder="Locations" value={locationInput}></input>
                                                                <button type="button" onClick={addLocations} className="btn btn-default btn-find wow animate__animated animate__fadeInUp questions" style={{margin: '0px'}}>Add +</button>
                                                            </div>
                                                            <p className="text-center wow animate__ animate__fadeInUp animated" style={{fontWeight: 500, paddingTop: '35px', visibility: 'visible', animationName: 'fadeInUp'}}>If you're only looking for remote jobs, check the box below and leave the city entry blank.</p>
                                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                <Field type="checkbox" name="remote_location" value="1" id="remoteOnly" style={{width: 'auto'}}/>
                                                                <p htmlFor="remoteOnly" className="ml-5">Remote jobs only</p>
                                                            </div>
                                                            <div className="list-tags-banner mt-20 wow animate__ animate__fadeInUp animated" style={{visibility: 'visible', animationName: 'fadeInUp'}}>
                                                                <div className="block-tags text-center">
                                                                {locations.map((title, index) => (
                                                                    <a href="#" className="btn btn-tags-sm mb-10 mr-5">{title} <span style={{marginLeft: '10px', fontSize: '12px'}} onClick={() => removeLocations(index)} >X</span></a>
                                                                ))}    
                                                                </div>
                                                            </div>
                                                            <p className="m1 text-center" style={{ color: 'red', fontSize: '10px' }}>{locationError}</p> 
                                                        </>
                                                    )}
                                                    <div className="block-signin mt-40 text-center">
                                                    {(currentStep > 1 && !loading) && (<button type="button" className="btn btn-default hover-up btn1" onClick={previewStep} style={{border: '1px solid', background: 'none', borderColor: '#2e6ef5', color: '#2e6ef5'}}>Back</button>)}
                                                        <button
                                                            type="submit"
                                                            className="btn btn-default font-heading icon-send-letter btn1"
                                                            disabled={loading || fileError}
                                                        >
                                                            {loading ? (
                                                                <Spinner
                                                                    animation="border"
                                                                    variant="light"
                                                                />
                                                            ) : currentStep == 4 ? (
                                                                'Submit'
                                                            ) : (
                                                                'Next'
                                                            )}
                                                        </button>
                                                    </div>
                                                </Form>)
                                        }}
                                    </Formik>
                                    {result && (
                                        <Row jContent="center" mt="18px">
                                            <Message color={result.color}>
                                                {result.message}
                                            </Message>
                                        </Row>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactDetailPageV2
