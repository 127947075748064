import React, { useState, useEffect } from 'react'
import feather from 'feather-icons'
import { nanoid } from 'nanoid'
import _ from 'lodash'

import Expander from '../../common/Expander/Expander'
import TagInput from '../../common/inputs/TagInput/TagInput'
import MultiBooleanInput from '../../common/inputs/MultiBooleanInput/MultiBooleanInput'
import InputSection from '../../common/inputs/InputSection/InputSection'
import BooleanInput from '../inputs/BooleanInput/BooleanInput'
import RadioInput from '../inputs/RadioInput/RadioInput'
import FilterGroup from '../../common/FilterGroup/FilterGroup'
import './style/FilterStack.scss'
import Select from 'react-select';
import {Button,Alert,Modal} from 'react-bootstrap'
import { makeRequest } from '../RequestAssist/RequestAssist'

const FilterStack = ({
    filterStack,
    currentOrganization,
    setFilterData,
    memberOrganization,
    candidatePage,
    setFilterStack,
    emptyFilter,
    closeFunc,
    jobId,
    filterOptions,
    filterLoading,
    filterLoadData,
    setFilterValue,
    filterValue,
    getSaveFilter,
    stage,
    currentUser
}) => {
    const userBelongsToCT =
        currentOrganization?.name === window.ch_const.ct_org_name

    const subOrgBelongsToCT =
        memberOrganization?.name === window.ch_const.ct_org_name
    
    useEffect(() => {
        feather.replace()
    },[])
    const [locationType, setLocationType] = useState('')
    const [filterName, setFilterName] = useState('')
    const [showmodel, setShowModel] = useState(false)
    const [errorModel, setErrorModel] = useState('')
    const [isCityChecked, setIsCityChecked] = useState(false)
    const [isStateChecked, setIsStateChecked] = useState(false)
    const has_commission_recruiter = (currentUser?.is_commission_recruiter == 'yes') ? true : false
    useEffect(()=>{
        if(filterStack?.city && filterStack?.city.length > 0){
            setLocationType('city')
            setIsCityChecked(true)
        }
        if(filterStack?.state && filterStack?.state.length > 0){
            setLocationType('state')
            setIsStateChecked(true)
        }

    },[])
    const resetFilterStack = (e) => {
        e.preventDefault()
        const filter = { ...emptyFilter }
        filter.keyword = filterStack.keyword
        setFilterStack(filter)
        setLocationType('')
        setFilterName('')
        setFilterValue('')
        closeFunc()
    }

    const setCheckBox = (attr, val) => {
        setFilterData(attr, val)
    }

    const handleCityChange = (e) =>{
        setIsCityChecked(!isCityChecked)
        setLocationType(e.target.value)
        setFilterStack({...filterStack, city:[], state:[]})
    }

    const handleStateChange = (e) =>{
        setIsStateChecked(!isStateChecked)
        setLocationType(e.target.value)
        setFilterStack({...filterStack, city:[], state:[]})
    }

    const handleFilterChange = (e) => {
        if(e){
            // set filter on select history
            setFilterValue(e)
            setFilterName(e.label)
            const currentFilterData = filterLoadData.filter(x => x.id === e.value);
            const currentFilter = currentFilterData[0]
            // const companyNames = (currentFilter.company_names) ? (currentFilter.company_names).split(',').map((name)=>{return {value:name, id:name}}) : []
            // const schools = (currentFilter.schools) ? (currentFilter.schools).split(',').map((name)=>{return {value:name, id:name}}) : []
            const city =  (currentFilter.city) ? _.compact((currentFilter.city).split('.')).map((name )=>{return {value:`${name.replace(/^,|,$/g, "")}.`, id:`${name.replace(/^,|,$/g, "")}.`}}) : [] 
            const state = (currentFilter.state) ? (currentFilter.state).split(',').map((name)=>{return {value:name, id:name}}) : []
            const tempFilterData = {
                titles: (currentFilter.titles) ? (currentFilter.titles).split(',') : [],
                locations: (currentFilter.locations) ? (currentFilter.locations).split(',') : [],
                skills: (currentFilter.skills) ? (currentFilter.skills).split(',') : [],
                tags: (currentFilter.tags) ? (currentFilter.tags).split(',') : [],
                company_names: (currentFilter.company_names) ? (currentFilter.company_names).split(',') : [],
                top_company: (currentFilter.top_company) ? currentFilter.top_company : false,
                schools: (currentFilter.schools) ? (currentFilter.schools).split(',') : [],
                disciplines: (currentFilter.disciplines) ? (currentFilter.disciplines).split(',') : [],
                degrees: (currentFilter.degrees) ? (currentFilter.degrees).split(',') : [],
                top_school: (currentFilter.top_school) ? currentFilter.top_school : false,
                active: (currentFilter.active) ? currentFilter.active : false,
                passive: (currentFilter.passive) ? currentFilter.passive : false,
                phone_number_available: (currentFilter.phone_number_available) ? currentFilter.phone_number_available : false,
                names: (currentFilter.names) ? (currentFilter.names).split(',') : [],
                emails: (currentFilter.emails) ? (currentFilter.emails).split(',') : [],
                email_type: (currentFilter.email_type) ? currentFilter.email_type : '',
                keyword: (currentFilter.keyword) ? currentFilter.keyword : '',
                withinPeriodKey: (currentFilter.withinPeriodKey) ? currentFilter.withinPeriodKey : '',
                city: city,
                state: state,
                match_skill: (currentFilter.match_skill) ? currentFilter.match_skill : false,
            }
            if(!_.isEmpty(city)){
                setLocationType('city')
            }
            if(!_.isEmpty(state)){
                setLocationType('state')
            }
            setFilterStack(tempFilterData)
        }else{
            const filter = { ...emptyFilter }
            filter.keyword = filterStack.keyword
            setFilterStack(filter)
            setLocationType('')
            closeFunc()
            setFilterValue('')
            setFilterName('')
            setErrorModel('')
        }
    }

    const saveFilter = () => {
        const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
        if(_.isEmpty(filterName?.trim())){
            setErrorModel("Please Enter Filter Name")
        }else if(!regex.test(filterName?.trim())){
            setErrorModel("Please Enter Valid Filter Name")
        }else{
            const payload = filterStack
            payload['search_name'] = filterName
            payload['job_id'] = jobId
            const url = _.isEmpty(filterValue) ?  `/people_searches` : `/people_searches/` + filterValue.value 
            const method = _.isEmpty(filterValue) ? 'post' : 'put'
            makeRequest(url, method, formatFiltersForSearch(payload), {
                contentType: 'application/json',
                createResponseMessage: (response) => {
                    if(response.success){
                        const defaultvalue = {value:response.filters.id,label:response.filters.search_name}
                        console.log(defaultvalue)
                        setFilterValue(defaultvalue)
                        setShowModel(false)
                        getSaveFilter()
                        return {
                            message:response.msg,
                            messageType: 'success',
                        }
                    } else {
                        return {
                            message: response.message,
                            messageType: 'failure',
                        }
                    }  
                }
            })
        }
    }

    function formatFiltersForSearch(filterStack) {
        const clone = {}
        Object.keys(filterStack).forEach((key) => {
            if(key == 'companyNames' || key == 'city' || key == 'locations' || key == 'state'){
                const filter = filterStack[key]
                 const values = filter.map(({value})=> value)
                 clone[key] = values.toString()
            } else {
            const filter = filterStack[key]
            clone[key] = Array.isArray(filter) ? filter.join(',') : filter
            }
        })
        return clone
    }

    function activeFilterCount(filters) {
        return Object.keys(filters)
            .map((key) => {
                if (Array.isArray(filterStack[key]))
                    return filterStack[key].length > 0 ? 1 : 0
                else if (typeof filterStack[key] === 'boolean')
                    return filterStack[key] ? 1 : 0
                else return 0
            })
            .reduce((acc, current) => acc + current, 0)
    }

    return (
        <>    
        <FilterGroup
            candidatePage={candidatePage}
            filterCount={activeFilterCount(filterStack)}
        >          
                <Select
                    isLoading={filterLoading}
                    isClearable={true}
                    onChange={handleFilterChange}
                    options={filterOptions}
                    value={filterValue}
                    placeholder={'Search Filter Name'}
                />
            <Expander
                label="Candidate Profile"
                expanded={
                    filterStack.top_school  ||
                    filterStack.top_company ||
                    filterStack.match_skill ||
                    (!filterStack.active && !candidatePage)
                }
                candidatePage={candidatePage}
            >
                <InputSection>
                    <BooleanInput
                        setFilter={setFilterData}
                        label="Top school"
                        testAttr="top_school"
                        checked={filterStack.top_school}
                        setCheckBox={()=>setCheckBox('top_school', !filterStack.top_school )}
                    />
                    <BooleanInput
                        setFilter={setFilterData}
                        label="Top company"
                        testAttr="top_company"
                        checked={filterStack.top_company}
                        setCheckBox={()=>setCheckBox('top_company', !filterStack.top_company)}
                    />
                    { memberOrganization && !has_commission_recruiter ?
                        <>
                        <BooleanInput
                            setFilter={setFilterData}
                            label={ (candidatePage) ? "Show Only Active Candidates" : "Show Applicants"}
                            testAttr="active"
                            checked={filterStack.active}
                            setCheckBox={()=>setCheckBox('active', !filterStack.active)}
                        />
                        { candidatePage && 
                            <BooleanInput
                                setFilter={setFilterData}
                                label={ (candidatePage) ? "Show Only Passive Candidates" : "Show Applicants"}
                                testAttr="passive"
                                checked={filterStack.passive}
                                setCheckBox={()=>setCheckBox('passive', !filterStack.passive)}
                            />
                        }
                        </>
                        : ''
                    }     
                    {stage === 'active_candidates' &&
                        <BooleanInput
                            setFilter={setFilterData}
                            label="Match Skill"
                            testAttr="match_skill"
                            checked={filterStack.match_skill}
                            setCheckBox={()=>setCheckBox('match_skill', !filterStack.match_skill)}
                        />
                    }     
                </InputSection>
            </Expander>
            <Expander
                label="Company"
                expanded={
                    filterStack.company_names &&
                    filterStack.company_names.length > 0
                }
                candidatePage={candidatePage}
            >
                {/* <InputSection>
                    <TagInput
                        setFilter={setFilterData}
                        initialValues={
                            filterStack.company_names
                                ? Array.isArray(filterStack.company_names)
                                    ? filterStack.company_names
                                          .filter((company) => company != '')
                                          .map((company) => {
                                              return {
                                                  id: company.value,
                                                  value: company.value,
                                              }
                                          })
                                    : []
                                : []
                        }
                        testAttr="company_names"
                        candidatePage={candidatePage}
                    />
                </InputSection> */}

                <InputSection>
                    <TagInput
                        setFilter={setFilterData}
                        initialValues={
                            filterStack.company_names
                                ? Array.isArray(filterStack.company_names)
                                    ? filterStack.company_names
                                          .filter((company_names) => company_names != '')
                                          .map((company_names) => {
                                              return {
                                                  id: nanoid(),
                                                  value: company_names,
                                              }
                                          })
                                    : []
                                : []
                        }
                        testAttr="company_names"
                        candidatePage={candidatePage}
                    />
                </InputSection>
            </Expander>
            <Expander
                label="Education"
                expanded={
                    (filterStack.schools && filterStack.schools.length > 0)
                }
                candidatePage={candidatePage}
            >
                <InputSection label="School">
                    {/* <TagInput
                        setFilter={setFilterData}
                        initialValues={
                            filterStack.schools
                                ? Array.isArray(filterStack.schools)
                                    ? filterStack.schools
                                          .filter((school) => school != '')
                                          .map((school) => {
                                              return {
                                                  id: school.value,
                                                  value: school.value,
                                              }
                                          })
                                    : []
                                : []
                        }
                        testAttr="schools"
                        candidatePage={candidatePage}
                    /> */}
                    <TagInput
                        setFilter={setFilterData}
                        initialValues={
                            filterStack.schools
                                ? Array.isArray(filterStack.schools)
                                    ? filterStack.schools
                                          .filter((schools) => schools != '')
                                          .map((schools) => {
                                              return {
                                                  id: nanoid(),
                                                  value: schools,
                                              }
                                          })
                                    : []
                                : []
                        }
                        testAttr="schools"
                        candidatePage={candidatePage}
                    />
                </InputSection>
                {!jobId && location.pathname != '/people_searches/new' ? (
                    <InputSection label="Discipline (major)">
                        <TagInput
                            setFilter={setFilterData}
                            initialValues={
                                filterStack.disciplines
                                    ? Array.isArray(filterStack.disciplines)
                                        ? filterStack.disciplines
                                              .filter(
                                                  (discipline) =>
                                                      discipline != ''
                                              )
                                              .map((discipline) => {
                                                  return {
                                                      id: nanoid(),
                                                      value: discipline,
                                                  }
                                              })
                                        : []
                                    : []
                            }
                            testAttr="disciplines"
                            candidatePage={candidatePage}
                        />
                    </InputSection>
                ) : (
                    ''
                )}
                {!jobId ? (
                    <InputSection label="Degrees">
                        <MultiBooleanInput
                            setFilter={setFilterData}
                            label="Degrees"
                            checkboxInfo={[
                                {
                                    label: window.ch_const.degree_bachelors,
                                    option: window.ch_const.degree_bachelors,
                                },
                                {
                                    label: window.ch_const.degree_masters,
                                    option: window.ch_const.degree_masters,
                                },
                                {
                                    label: window.ch_const.degree_doctorate,
                                    option: window.ch_const.degree_doctorate,
                                },
                            ]}
                            initialValues={filterStack.degrees}
                            testAttr="degrees"
                            candidatePage={candidatePage}
                        />
                    </InputSection>
                ) : (
                    ''
                )}
            </Expander>
            <Expander
                label="Location"
                expanded={
                    filterStack.state && filterStack.state.length > 0 
                    || filterStack.city && filterStack.city.length > 0
                }
                candidatePage={candidatePage}
            >
                    <div className='radioWrapper'>
                        <div className="radioContainer">
                            <input
                                type="checkbox"
                                name="locationType"
                                value="city"
                                checked={isCityChecked}
                                onChange={handleCityChange}
                            />
                         <span className='radioLable'>City</span>
                        </div>
                        <div className="radioContainer">
                            <input
                                type="checkbox"
                                name="locationType"
                                value="state"
                                checked={isStateChecked}
                                onChange={handleStateChange}
                            />
                           <span  className='radioLable'>State</span>
                        </div>
                    </div>

                <InputSection>
                    {isStateChecked && (
                        <>
                            <div>{'State'}</div>
                            <TagInput
                                setFilter={setFilterData}
                                initialValues={
                                    filterStack.state
                                        ? Array.isArray(filterStack.state)
                                            ? filterStack.state
                                                  .filter(
                                                      (stateName) =>
                                                          stateName != ''
                                                  )
                                                  .map((stateName) => {
                                                      return {
                                                          id: stateName.value,
                                                          value: stateName.value,
                                                      }
                                                  })
                                            : []
                                        : []
                                }
                                testAttr="state"
                                candidatePage={candidatePage}
                                showTag={
                                    filterStack?.city &&
                                    filterStack?.city.length == 0
                                        ? true
                                        : false
                                }
                            />
                        </>
                    )}
                    {isCityChecked && (
                        <>
                            <div>{'City'}</div>
                            <TagInput
                                setFilter={setFilterData}
                                initialValues={
                                    filterStack.city
                                        ? Array.isArray(filterStack.city)
                                            ? filterStack.city
                                                  .filter(
                                                      (cityName) =>
                                                          cityName != ''
                                                  )
                                                  .map((cityName) => {
                                                      return {
                                                          id: cityName.value,
                                                          value: cityName.value,
                                                      }
                                                  })
                                            : []
                                        : []
                                }
                                testAttr="city"
                                candidatePage={candidatePage}
                                showTag={
                                    filterStack?.state &&
                                    filterStack?.state.length == 0
                                        ? true
                                        : false
                                }
                            />
                        </>
                    )}
                </InputSection>
            </Expander>
            <Expander
                label="Skills"
                expanded={filterStack.skills && filterStack.skills.length > 0}
                candidatePage={candidatePage}
            >
                <InputSection>
                    <TagInput
                        setFilter={setFilterData}
                        initialValues={
                            filterStack.skills
                                ? Array.isArray(filterStack.skills)
                                    ? filterStack.skills
                                          .filter((skill) => skill != '')
                                          .map((skill) => {
                                              return {
                                                  id: nanoid(),
                                                  value: skill,
                                              }
                                          })
                                    : []
                                : []
                        }
                        testAttr="skills"
                        candidatePage={candidatePage}
                    />
                </InputSection>
            </Expander>
            <Expander
                label="Job Title"
                expanded={filterStack.titles && filterStack.titles.length > 0}
                candidatePage={candidatePage}
            >
                <InputSection>
                    <TagInput
                        setFilter={setFilterData}
                        initialValues={
                            filterStack.titles
                                ? Array.isArray(filterStack.titles)
                                    ? filterStack.titles
                                          .filter((titles) => titles != '')
                                          .map((titles) => {
                                              return {
                                                  id: nanoid(),
                                                  value: titles,
                                              }
                                          })
                                    : []
                                : []
                        }
                        testAttr="titles"
                        candidatePage={candidatePage}
                    />
                </InputSection>
            </Expander>
            <Expander
                label="Phone Number"
                expanded={filterStack.phone_number_available}
                candidatePage={candidatePage}
            >
                <InputSection>
                    <BooleanInput
                        setFilter={setFilterData}
                        label="Phone number available"
                        testAttr="phone_number_available"
                        checked={filterStack.phone_number_available}
                        setCheckBox={()=>setCheckBox('phone_number_available', !filterStack.phone_number_available)}
                    />
                </InputSection>
            </Expander>
            <Expander
                label="Emails"
                expanded={filterStack.emails && filterStack.emails.length > 0 || filterStack.email_type}
                candidatePage={candidatePage}
            >
                <InputSection>
                    <TagInput
                        setFilter={setFilterData}
                        initialValues={
                            filterStack.emails
                                ? Array.isArray(filterStack.emails)
                                    ? filterStack.emails
                                          .filter((email) => email != '')
                                          .map((email) => {
                                              return {
                                                  id: nanoid(),
                                                  value: email,
                                              }
                                          })
                                    : []
                                : []
                        }
                        testAttr="emails"
                        candidatePage={candidatePage}
                    />
                </InputSection>
                <RadioInput
                    setFilter={setFilterData}
                    radioInfo={['Personal', 'Company']}
                    initialValue={filterStack.email_type}
                    testAttr="email_type"
                />
            </Expander>
            <Expander
                label="Names"
                expanded={filterStack.names && filterStack.names.length > 0}
                candidatePage={candidatePage}
            >
                <InputSection>
                    <TagInput
                        setFilter={setFilterData}
                        initialValues={
                            filterStack.names
                                ? Array.isArray(filterStack.names)
                                    ? filterStack.names
                                          .filter((name) => name != '')
                                          .map((name) => {
                                              return {
                                                  id: nanoid(),
                                                  value: name,
                                              }
                                          })
                                    : []
                                : []
                        }
                        testAttr="names"
                        candidatePage={candidatePage}
                    />
                </InputSection>
            </Expander>
            <button
                className="clear-filter"
                onClick={(e) => resetFilterStack(e)}
            >
                Clear Filters
            </button>
            { filterName != '' &&
                <button
                    className="save-filter"
                    onClick={() => {
                        saveFilter()
                        }
                    }
                >
                    Update Filters
                </button>
            }
            { filterName == '' &&
                <button
                    className="save-filter"
                    onClick={() => {
                        setShowModel(true)
                        setErrorModel('')
                        }
                    }
                >
                    Save Filters
                </button>
            }
        </FilterGroup>

        <Modal
            dialogClassName='filterModal'
            show={showmodel}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {'Save Filter'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label>Filter Name*</label>
                <input
                    type="text"
                    placeholder="Enter Filter Name"
                    onChange={(e) => {
                        setFilterName(e.target.value)
                        setErrorModel('')
                    }}
                    className="form-control"
                />
                {errorModel && (
                    <small style={{size:'10px',color:'red'}}>{errorModel}</small>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    href={'#'}
                    variant="primary"
                    onClick={saveFilter}
                >
                    Save
                </Button>
                <Button type="button" variant="secondary" onClick={() => {
                    setShowModel(false)
                    setErrorModel('')
                }}>
                    Close
                </Button>
            </Modal.Footer> 
        </Modal>
    </>
    )
}

export default FilterStack
