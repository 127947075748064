import React, { useState, useEffect } from 'react'
import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import './styles/SubscriptionPlan.scss'
import Util from "../../../../utils/util";
import Modal from "react-bootstrap/Modal"
import SubscriptionPlanAdd from './form'
import SendEmailModal from './sendEmail'

const SubscriptionPlanPage = ({recruiters}) => {
  var active_page = window.sessionStorage.getItem('SubscriptionPlanPage')
  var query = window.sessionStorage.getItem('query')
  const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
  const [pageCount, setPageCount] = useState(0)
  const [plans, setPlans] = useState([])
  const [totalPlans,setTotalPlans] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [show,setShow] = useState(false)
  const [showEmail,setShowEmail] = useState(false)
  const [showMember,setShowMember] = useState(false)
  const [method,setMethod] = useState('post')
  const [entry,setEntry] = useState({})
  const [planDetail,setPlanDetail] = useState({})
  const [loading, setLoading] = useState(true)
  const fetchData = () => {
    setLoading(true)
    const url = `/admin/subscription_plans.json`
    window.sessionStorage.setItem('SubscriptionPlanPage',activePage)
    makeRequest(url, 'get',  {params: {page: (activePage+1), query: query}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setPlans(
          res.data.plans.map((plan) => ({
            id: plan.id,
            name: Util.capitalize(plan?.name),
            amount: plan?.amount,
            discount: plan?.discount,
            payment_link: plan?.payment_link,
            payment_type: plan?.payment_type || 'Recuring (Monthly)',
            description: plan?.description,
            candidate_limit: plan?.role == 'talent' ? "_" : plan?.candidate_limit,
            status: plan?.status,
            jobs_limit: plan?.role == 'talent' ? "_" : plan?.jobs_limit,
            role: plan?.role,
            email_limit: plan?.role == 'talent' ? plan?.candidate_limit : "_",
            is_popular: (plan?.is_popular == true) ? 'true' : 'false'
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalPlans(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setLoading(false)
      },
    })
  }
    useEffect(() => {
      fetchData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage])

    const deletePlan = async (id) => {
      const URL = `/admin/subscription_plans/${id}`
      await makeRequest(URL, 'delete', '', {
        createResponseMessage: (response) => {
          console.log(response)
          return {
            message: response.message,
            messageType: response.type,
            loading: false,
            autoClose: true,
          }
        },
        createErrorMessage: (e) => 'Failed',
        onSuccess: (res) => {
          if(res.data.success){
            fetchData()
          }
        },      
      })      
    }    

    const addPlan = () => {
      setEntry({})
      setMethod('post')
      setShow(true)
    }

    const onHide = () => {
      setEntry({})
      setMethod('post')
      setShow(false)
      setShowMember(false)
    }

    const onHideEmail = () => {
      setShowEmail(false)
    }
    
    const getUpdateData = (id) => {
      let getPlan = plans.filter((row) => row.id == id)
      if(getPlan?.length > 0){
        setMethod('put')
        setEntry(getPlan[0])
        setShow(true)
      } 
    }

    // const getMembersData = (id) => {
    //   let getPlan = plans.filter((row) => row.id == id)
    //   if(getPlan?.length > 0){
    //     setEntry(getPlan[0])
    //     setShowMember(true)
    //   } 
    // }

    const handleCheckboxChange = async (id,event) => {
      console.log(id,event.target.checked)
      const URL = `/admin/subscription_plans_popular/${id}`
      await makeRequest(URL, 'post', {'is_checked':event.target.checked}, {
        createResponseMessage: (response) => {
          console.log(response)
        },
        createErrorMessage: (e) => 'Failed',
        onSuccess: (res) => {
            fetchData()
        },      
      }) 
      event.persist();
  }
  function copyToClipboard(value, button) {
    navigator.clipboard.writeText(value)
      .then(() => {
        if (button) {
          button.textContent = 'Copied';
          setTimeout(() => {
            button.textContent = 'Copy';
          }, 3000); // Change back to 'Copy' after 3 seconds
        }
      })
      .catch((error) => {
        console.error('Unable to copy to clipboard: ', error);
      });
  }
  const sendMail = (id) => {
    let getPlan = plans.filter((row) => row.id == id)
    if (getPlan?.length > 0) {
        setPlanDetail(getPlan[0])
        // setMethod('mail')
        setShowEmail(true)
    }
  }
  return (
    <>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
          Subscription Plan
        </P>
        <div style={{flexGrow:1}}/>
        <Button onClick={addPlan} className="ml-3">
          Add New Subscription Plan
        </Button>
      </div>
      
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
        <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'left'}}>
          {totalPlans > 0  &&
            <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord +1} - {currentCounts > totalPlans ? totalPlans : currentCounts}  of  {totalPlans} subscriptions
            </DisplayPagination>
        }
          </Col>
      </Row>
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Plan Name',
              field: 'name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Amount',
              field: 'amount',
              editable: false,
              type: 'text',
            },
            {
              name: 'Payment Type',
              field: 'payment_type',
              editable: false,
              type: 'text',
            },
            {
              name: 'Payment Url',
              field: 'payment_link',
              editable: false,
              type: 'text',
            },
            {
              name: 'Candidate Limit',
              field: 'candidate_limit',
              editable: false,
              type: 'text',
            },
            {
              name: 'Active Jobs Limit',
              field: 'jobs_limit',
              editable: false,
              type: 'text',
            },
            {
              name: 'Role',
              field: 'role',
              editable: false,
              type: 'text',
            },
            {
              name: 'Email Limit',
              field: 'email_limit',
              editable: false,
              type: 'text',
            },
            {
              name: 'Status',
              field: 'status',
              editable: false,
              type: 'text',
            },
            {
              name: 'Popular',
              field: 'is_popular_txt',
              editable: false,
              nowrap:'text-nowrap',
              type: 'text',
            },
            {
              name: 'Action',
              field: 'options',
              editable: false,
              type: 'text',
              nowrap:'text-nowrap',
              options:{CustomEventIcon:'mail',CustomEventMethod: (id) => sendMail(id)}
            },
          ]}
          rowValues={plans.map((plan) => ({
              ...plan,
              amount: '$'+plan?.amount,
              discount: plan?.discount+'%',
              role: Util.capitalize(plan?.role),
              payment_type: Util.capitalize(plan?.payment_type),
              status: Util.capitalize(plan?.status),
              payment_link: plan?.payment_link ? (
                <>
                  <div className="input-group">
                    <input readOnly={true} className='form-control' type='text' name='payment_link' value={plan?.payment_link} />
                    <button className="btn btn-outline-secondary" type="button" onClick={(e) => copyToClipboard(plan?.payment_link, e.target)}>Copy</button>
                  </div>
                </>
              ) : '',
              is_popular_txt: (<><input type='checkbox' name='is_popular_chk' checked={( plan.is_popular == 'true') ? true : false}  onChange={ (e) => handleCheckboxChange(plan.id,e)} /></>) 
            }))}
          showEditOption
          activePage={activePage}
          deleteAction={deletePlan}
          goToEditPage={(id) =>
            getUpdateData(id)
          }
          loading={loading}
      />
      {pageCount > 1 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
        >
          <Paginator
              activePage={activePage}
              setActivePage={setActivePage}
              pageCount={pageCount}
              pageWindowSize={5}
              showGoToField={false}
          />
        </div>
      )}
    </Card>

    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
              { method ==='put' ?'Update' : 'Add' } Subscription Plan
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SubscriptionPlanAdd subscriptionEntry={entry} onHide={onHide} method={method} fetchData={fetchData}></SubscriptionPlanAdd>
        </Modal.Body>
    </Modal>

    <Modal
        onHide={onHideEmail}
        show={showEmail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
              Send Email - {planDetail.name} (${planDetail.amount})
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SendEmailModal planDetail={planDetail} onHide={onHideEmail}></SendEmailModal>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default SubscriptionPlanPage