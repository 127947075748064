import React from 'react';
import styles from './styles/FilterJob.module.scss';

function FilterBy_linkedin({ filterStack, setStackFilter }) {
  return (
    <div className={styles.filterByContainer}>
      <div className={styles.filterByInputWrapper} style={{marginTop: '20px'}}>
        <div className={styles.checkBox} style={{marginLeft: '0px'}}>
          <input
            type="checkbox"
            checked={filterStack.is_linkedin_job}
            onChange={() =>
              setStackFilter({
                ...filterStack,
                is_linkedin_job: !filterStack.is_linkedin_job,
              })
            }
            style={{width: '20px'}}
          />
          <label style={{fontSize: '13px', fontFamily: "Montserrat, sans-serif"}}>Show only those with Linkedin jobs</label>
        </div>
      </div>
    </div>
  );
}

export default FilterBy_linkedin;
