import React, { useState, useEffect } from 'react'
import Card from '../shared/Card'
import Table from '../shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../shared/Button'
import P from '../shared/P'
import { DisplayPagination } from '../styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import './styles/InMailsAssignments.scss'
import Util from "../../../../utils/util";
import Modal from "react-bootstrap/Modal"
import InMailsAssignmentsAdd from './InMailsAssignmentsAdd'
import moment from 'moment'

const InMailsAssignmentsList = ({recruiters,organizations}) => {
  var active_page = window.sessionStorage.getItem('inmail_activePage')
  var query = window.sessionStorage.getItem('inmail_query')
  const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
  const [pageCount, setPageCount] = useState(0)
  const [tableDatas, setTableDatas] = useState([])
  const [totalDatas,setTotalDatas] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [show,setShow] = useState(false)
  const [method,setMethod] = useState('post')
  const [entry,setEntry] = useState({})
  const [loading, setLoading] = useState(true)
  const fetchData = () => {
    setLoading(true)
    const url = `/admin/in_mails_assignments.json`
    window.sessionStorage.setItem('inmail_activePage',activePage)
    makeRequest(url, 'get',  {params: {page: (activePage), query: query}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setTableDatas(
          res.data.inMails.map((inMails) => ({
            id: inMails.id,
            user_id: inMails.user_id,
            organization_id: inMails.organization_id,
            recruiter_name: Util.fullName(inMails?.user?.first_name,inMails?.user?.last_name),
            organization_name: Util.capitalize(inMails?.organization?.name),
            daily_target: inMails?.daily_target,
            monthly_availability: inMails?.monthly_availability,
            response_request: inMails?.response_request,
            created_at: inMails?.created_at,
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalDatas(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setLoading(false)
      },
    })
  }
    useEffect(() => {
      fetchData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage])

    const deleteData = async (id) => {
      const URL = `/admin/in_mails_assignments/${id}`
      await makeRequest(URL, 'delete', '', {
        createResponseMessage: () => {
          return {
            message: `Deleted successful`,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        },
        createErrorMessage: (e) => 'Failed',
        onSuccess: () => {      
            fetchData()
        },      
      })      
    }    

    const addData = () => {
      setEntry({})
      setMethod('post')
      setShow(true)
    }

    const onHide = () => {
      setEntry({})
      setMethod('post')
      setShow(false)
    }
    
    const getUpdateData = (id) => {
      let getData = tableDatas.filter((row) => row.id == id)
      if(getData?.length > 0){
        setMethod('put')
        setEntry(getData[0])
        setShow(true)
      } 
    }

  return (
    <>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
          InMails Assignments List
        </P>
        <div style={{flexGrow:1}}/>
        <Button onClick={addData} className="ml-3">
          Add New Entry
        </Button>
      </div>
      
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
        <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'left'}}>
          {totalDatas > 0  &&
            <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts}  of  {totalDatas} employees
            </DisplayPagination>
        }
          </Col>
      </Row>
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Organization',
              field: 'organization_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Recruiter Name',
              field: 'recruiter_name',
              editable: true,
              type: 'text',
            },
            {
              name: 'Daily Target',
              field: 'daily_target',
              editable: false,
              type: 'text',
              nowrap:''
            },
            {
              name: 'Monthly Availability',
              field: 'monthly_availability',
              editable: false,
              type: 'text',
              nowrap:''
            },
            {
              name: 'Created Date',
              field: 'created_at',
              editable: false,
              type: 'text',
              nowrap:''
            },
            {
              name: 'Action',
              field: 'options',
              editable: false,
              type: 'text',
              nowrap:'text-nowrap',
              options:{}
            },
          ]}
          rowValues={tableDatas.map((data) => ({
              ...data,
              created_at: moment(data?.created_at).format('MMM DD, YYYY')
          }))}
          showEditOption
          activePage={activePage}
          deleteAction={deleteData}
          goToEditPage={(id) =>
            getUpdateData(id)
          }
          loading={loading}
      />
      {pageCount > 1 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
        >
          <Paginator
              activePage={activePage}
              setActivePage={setActivePage}
              pageCount={pageCount}
              pageWindowSize={5}
              showGoToField={false}
          />
        </div>
      )}
    </Card>

    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
              { method ==='put' ?'Update' : 'Create' } InMail Assignments
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <InMailsAssignmentsAdd  organizations={organizations} recruiters={recruiters} inMailEntry={entry} onHide={onHide} method={method} fetchData={fetchData}></InMailsAssignmentsAdd>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default InMailsAssignmentsList