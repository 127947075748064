import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import axios from 'axios'
import moment from 'moment'
import styles from './styles/JobResultsTable.module.scss'
import JobPositionCard from '../JobPositionCard/JobPositionCard'
import SearchBar from '../SearchBar/SearchBar'
import Paginator from '../Paginator/Paginator'
import HorizontalLoader from '../../common/HorizontalLoader/HorizontalLoader'
import Util from '../../../utils/util'
import feather from 'feather-icons'

const JobResultsTable = (props) => {
    const {
        jobs,
        setJobs,
        totalJobsCount,
        setTotalJobsCount,
        alertUploadedResume,
        setAlertUploadedResume,
        matchesGenerated,
        setMatchesGenerated,
        resumeUploadLoading,
        errorUploadedResume,
        setErrorUploadedResume,
        jobFilterText,
        setJobFilterText,
        activePage,
        setActivePage,
        errorFetchingJob,
        setErrorFetchingJob,
        loading,
        setLoading,
        pageCount,
        setPageCount,
        lastuploadedResume,
        uploadedResume,
        regenerateScore,
        setResumeUploadLoading
    } = props
    useEffect(() => {
        handleSearch()
    }, [activePage,lastuploadedResume])
    useEffect(() => {
        feather.replace()
    })
    const [showRefreshButton, setShowRefreshButton] = useState(false)
    const handleSearch = async () => {
        setAlertUploadedResume(null)
        setErrorUploadedResume(null)
        const url = (uploadedResume) ? 'job_searches/search_match_score' : 'job_searches/search'
        (uploadedResume) ? setResumeUploadLoading(true) : setLoading(true)
        const job_search = {
            keyword: jobFilterText,
        }
        const payload = JSON.stringify({ job_search })
        const CSRF_Token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')

        try {
            const response = await axios.post(
                `${url}?page=${activePage + 1}`,
                payload,
                {
                    headers: {
                        'content-type': 'application/json',
                        'X-CSRF-Token': CSRF_Token,
                    },
                }
            )

            const jobsData = response.data
            const jobs = jobsData.jobs
            const total_count = jobsData.total_count
            const totalPages = jobsData.total_pages
            const match_scores_created_lastdate = jobs[0]?.match_scores_created || ''
            if(match_scores_created_lastdate != ''){
                var lastdate = moment(match_scores_created_lastdate);
                var todaysdate = moment();
                var diffday = lastdate.diff(todaysdate, 'days');
                if(diffday < 0){
                    setShowRefreshButton(true)
                }else{
                    setShowRefreshButton(false)
                }
            }
            setMatchesGenerated(jobsData.matches_generated)
            setJobs(jobs)
            setTotalJobsCount(total_count)
            setPageCount(totalPages)
            setLoading(false)
            setResumeUploadLoading(false)
        } catch (error) {
            setErrorFetchingJob(error.message)
            setLoading(false)
            setResumeUploadLoading(false)
        }
    }

    const displayNumberOfResults = () => {
        if (totalJobsCount < 1) return
        if (matchesGenerated) return `1 - ${totalJobsCount} of ${totalJobsCount}`
        return Util.displayNumberOfResults(
            totalJobsCount,
            pageCount,
            activePage,
            10
        )
    }

    const displayText = `Displaying ${displayNumberOfResults()} results`

    return (
        <div
            className="d-flex flex-column align-items-center"
            style={{ marginBottom: '50px', width: '100%' }}
        >
            <div className={styles.container}>
                <Row className={styles.titleRow}>
                    <Col xl={6}>
                        <p className={styles.recommendedJobsTitle}>
                            <span>
                                {matchesGenerated
                                    ? 'AI Matched Jobs'
                                    : 'Recommended Jobs'}
                            </span>
                            <span
                                className="ml-3"
                                style={{ fontSize: '0.75rem' }}
                            >
                                {displayNumberOfResults()}
                            </span>
                        </p>
                        {(showRefreshButton) &&
                            <Button className={styles.refraceButton} onClick={() =>{ 
                            regenerateScore()
                            setShowRefreshButton(false)
                            }}>
                                <i data-feather="refresh-cw" color="#fff" className='svgIcon' />
                                Reference Jobs
                            </Button>
                        }
                    </Col>
                    <Col xl={6}>
                        <SearchBar
                            placeholder={
                                'Search by Job title, keyword, or company'
                            }
                            value={jobFilterText}
                            setValue={setJobFilterText}
                            onChange={(e) => setJobFilterText(e.target.value)}
                            onEnterPressed={(event) => {
                                setActivePage(0)
                                handleSearch()
                            }}
                            onClick={(e) => setJobFilterText('')}
                        ></SearchBar>
                    </Col>
                </Row>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                ) : resumeUploadLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className={styles.alertText}>
                            Please give us a moment as our AI generates your
                            matches!
                        </p>
                        <HorizontalLoader />
                    </div>
                ) : alertUploadedResume ? (
                    <Alert
                        variant="danger"
                        onClose={() => setAlertUploadedResume(null)}
                        dismissible
                    >
                        {alertUploadedResume}
                    </Alert>
                ) : errorUploadedResume ? (
                     <Alert
                        variant="danger"
                        onClose={() => setErrorUploadedResume(null)}
                        dismissible
                    >
                        {errorUploadedResume}
                    </Alert>
                ) : errorFetchingJob ? (
                    <Alert
                        variant="danger"
                        onClose={() => setErrorFetchingJob(null)}
                        dismissible
                    >
                        {errorFetchingJob}
                    </Alert>
                ) : jobs.length == 0 && uploadedResume ? (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className={styles.alertText}>
                           AI generates matched jobs not found. Please upload resume again.
                        </p>
                        {/* <Button className={styles.searchButton} onClick={() => regenerateScore()}>
                            Please click to regenerate score.
                        </Button> */}
                    </div>
                ) : (
                    <>
                        {jobs.length == 0 && <div>No jobs to display</div>}
                        {jobs.map((job) => (
                            <JobPositionCard
                                key={job.id}
                                job={job}
                                matchScore={(job?.score) ? Math.round(job?.score) : 0}
                                showSalary={false}
                                showMatchScore={(job?.score) ? true : false}
                            />
                        ))}
                    </>
                )}
            </div>
            { !matchesGenerated && pageCount >= 2 &&
                <Paginator
                    pageCount={pageCount}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    pageWindowSize={5}
                />
            }
        </div>
    )
}

export default JobResultsTable
