import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'

import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import { Alert, Modal,Spinner } from 'react-bootstrap'
import axios from 'axios'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import styles from './styles/Signup.module.scss'
import Signup from '../../layout/Navbar/Signup/Signup'
import GoogleIcon from '../../../../assets/images/icons/icon-google.svg'
import { H1, P, A, SPAN, Button, GoogleButton } from './styles/Signup.styled'
import UserForm from './UserForm'
import SignUp from './SignUp'
import CloseButton from '../../common/Styled components/CloseButton'

const initialFormData = {
  email: '',
  password: '',
}

const singInValidation = {
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required'),
}

const SigninPage = (props) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(initialFormData)
  const [response, setResponse] = useState({})
  const [isSignUp, setIsSignUp] = useState(false)

  useEffect(() => {
    if (props.isError) {
      setIsSignUp(true)
    }
  }, [props])

  const validationSchema = Yup.object({ ...singInValidation })

  const resendConfirmation = () => {
    setResponse({})
    const resendformData = new FormData()
    resendformData.append(`user[email]`, formData.email)
    const url = `/resend_confirmation`
    makeRequest(url, 'post', resendformData, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createSuccessMessage: () => 'Recruiter member added successfully',
      createErrorMessage: (e) => e.response.data.msg,
      onSuccess: () => {
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      },
    })
  }

  const handleGoogleSignIn = async () => {
    window.location.href = `/user/auth/google_oauth2`
  }

  const handleSignIn = async (values,resetForm) => {
    setResponse({})
    setFormData({ ...formData, ...values })
    const payload = new FormData()
    const url = '/users/sign_in'
    payload.append('user[email]', values.email)
    payload.append('user[password]', values.password)
    payload.append('format', 'js')
    setLoading(true)
    await axios
      .post(url, payload)
      .then((response) => {
        setTimeout(() => {
            window.location = '/'
        }, 2000)
        localStorage.setItem('user', JSON.stringify(response.data))
      })
      .catch((error) => {
        console.log(error)
        setResponse(error?.response?.data)
        resetForm();
        setLoading(false)
      })
  }

  return (
    <>
      <div className={`${styles.mainForm}`}>
        <MainPanel>
          <H1>Sign In</H1>
          <div className={styles.signForm}>
            {!_.isEmpty(response) && (
              <Alert
                variant={response.messageType}
                onClose={() => setResponse({})}
                style={{ fontSize: '15px', width: '28rem' }}
                dismissible
                className="candidate-info-close"
              >
                <span className={styles.alertInfo}> {response.message} </span>
              </Alert>
            )}
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={initialFormData}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm })  => { handleSignIn(values,resetForm)}}
            >
              <Form>
                <UserForm />
                {_.isEmpty(response) && (
                  <div className={styles.onResponse}>
                    {response.messageType === 'warning' && (
                      <a href="#" onClick={() => resendConfirmation()}>
                        Click here to resend confirmation
                      </a>
                    )}
                  </div>
                )}
                <div style={{ marginTop: '10px' }}>
                  <Button
                    className={styles.sign_in_button}
                    type="submit"
                    style={{
                      alignSelf: 'flex-end',
                      float: 'right',
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      'Sign In'
                    )}
                  </Button>

                  <div className="d-flex flex-column">
                    <A tAlign="left" href="/users/password/new">
                      <SPAN>Forgot password?</SPAN>
                    </A>
                  </div>
                </div>
              </Form>
            </Formik>
            <GoogleButton
              className={styles.googleBtn}
              onClick={() => handleGoogleSignIn()}
            >
              <img src={GoogleIcon} />
              Continue with Google
            </GoogleButton>
          </div>
        </MainPanel>
        <InfoPanel>
          <div className={`${styles.infopanelDiv}`}>
            <div className="d-flex flex-column text-center">
              <P>Don't have an account yet?</P>
              <div className="d-flex justify-content-center">
                <Signup />
              </div>
            </div>
          </div>
        </InfoPanel>
        <Modal
          className="modelContainer"
          show={isSignUp}
          onHide={() => setIsSignUp(!isSignUp)}
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
          centered
        >
          <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
              <h3>Create Account</h3>
              <CloseButton handleClick={() => setIsSignUp(!isSignUp)} />
            </div>
            <SignUp signUpWithRole/>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default SigninPage
