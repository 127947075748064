import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import styles from './styles/Navbar.module.scss'
import styleNew from './styles/NavbarEmployer.scss'
import LogoWithoutText from '../../../../assets/images/Cardinal.png'
import feather from 'feather-icons'
import Util from '../../../utils/util'

const NavbarTalentLogin = ({
    login
}) => {
   
    const authLinks = (
        <>  
            <Nav.Link
                href="/users/sign_in"
                className={`${styles.navbarLink} linkMenu` }
            >
                Interviews
            </Nav.Link>
           
            <Nav.Link
                href="/users/sign_in"
                className={`${styles.navbarLink} linkMenu` }
            >
                Jobs
            </Nav.Link>
           
            <Nav.Link
                href="/users/sign_in"
                className={`${styles.navbarLink} linkMenu` }
            >
                Search Decision Makers
            </Nav.Link>
           
            <Nav.Link
                href="/users/sign_in"
                className={`${styles.navbarLink} linkMenu` }
            >
                My Network
            </Nav.Link>
            
            <Nav.Link
                href="/users/sign_in"
                className={`${styles.navbarLink} linkMenu` }
            >
                Messaging
            </Nav.Link>

            <Nav.Link
                href="/users/sign_in"
                className={`${styles.navbarLink} linkMenu` }
            >
                Invite Your Network
            </Nav.Link>

            <Nav.Link
                href="/users/sign_in"
                className={`${styles.navbarLink} linkMenu` }
            >
                Communities
            </Nav.Link>
        </>
    )
    
    
    return (
        <>
            <Navbar expand="lg" sticky="top" style={{zIndex: 1040 }}>
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={LogoWithoutText}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <span>CardinalTalent</span>{' '}
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className='mr-3'>
                        <Nav.Link href="https://cardinaltalent.ai/">Employers</Nav.Link>
                        <Nav.Link className='activeLinkColorTop' style={{color: '#000'}}>Talent</Nav.Link>
                        <Nav.Link href="/welcome/recruiter">Recruiters</Nav.Link>
                    </Nav>
                    <Nav className='mr-3'>
                        <Nav.Link  href="/signup/recruiter" style={{color: '#005999'}}>Sign up</Nav.Link><span className='mt-2'> /</span>
                        <Nav.Link  href="/users/sign_in" style={{color: '#005999'}}> Login in</Nav.Link>
                       
                    </Nav>
                    
                </Navbar.Collapse>
                   
            </Navbar>

            <Navbar expand="lg" variant="dark" className="text-white dark-blue-nav" sticky="top">
                

                <Navbar.Collapse id="basic-navbar-nav-second" className="justify-content-end">
                    <Nav>
                        {authLinks}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default NavbarTalentLogin
