import React, { useState, useEffect } from 'react'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import './../styles/communities.scss'
import SettingLeftPenal from '../SettingLeftPenal'
import Moment from 'moment'
import Paginator from '../../../common/Paginator/Paginator'

const InviteEarnPage = ({ communityId, totalBalance, currentUser }) => {
  const [loading, setLoading] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [currentCounts, setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [memberData, setMembersData] = useState([])

  const fetchData = () => {
    setLoading(true)
    const url = '/communities/' + communityId + '/get_revenue.json'
    makeRequest(url, 'get', { params: { page: (activePage + 1) } }, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setMembersData(
          res.data.list.map((member) => ({
            status: member.status,
            created_at: member.created_at,
            email: member.email,
            revenue: member.revenue_count,
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalCount(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setLoading(false)
      },
    })
  }
  useEffect(() => {
    fetchData()
  }, [activePage])

  return (
    <>
      <div className="container mt-50 communities-detail">
        <div className="row">
          <SettingLeftPenal communityId={communityId} ></SettingLeftPenal>
          <div className="col-lg-8 p-0 m-0">
            <div className="single-recent-jobs pl-20">
              <div className=''>
                <div>
                  <h4>Invite Referral Earnings</h4>
                </div>
                <div className="block-price mt-20 md-20 badge bg-primary">
                  <span className="fs-1">${(totalBalance > 0) ? totalBalance : 0}</span>
                  <p className="fs-6 mt-10">Balance</p>
                </div>
              </div>
            </div>

            <section className="section-box">
              <div className="container">
                <h5 className="mb-5 mt-15 font-weight-bold">Active Invites</h5>
                <p> Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount} </p>
                <div className="divider"></div>
              </div>
              <div className="list-recent-jobs mt-20 pl-20">
                {memberData && memberData.map((member, index) => {
                  return (<div key={index} className="card-job hover-up wow animate__animated animate__fadeInUp mb-10">
                    <div className="card-job-top">
                      <div className="d-flex justify-content-between p-1">
                        <h6 className="card-job-top--info-heading">Email: <span className="card-values">{member?.email || 'N/A'}</span></h6>
                        {/* <h6 className="card-job-top--info-heading" title="No revenue means user already register by other's invites">
                          Status : <div class="badge bg-primary">{member?.status}</div>
                        </h6> */}
                      </div>
                    </div>
                    <div className="card-job-description row">
                      <div className='col-md-5'>
                        <h6 className="card-job-top--info-heading">
                          Revenue :  <span className="card-values">${member?.revenue}</span>
                        </h6>
                      </div>
                      <div className='col-md-7'>
                        {Moment(member?.created_at).format('lll')}
                      </div>
                    </div>
                  </div>)
                })}
              </div>
              {(pageCount > 1) ?
                <div className="paginations">
                  <Paginator
                    pageCount={pageCount}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    pageWindowSize={10}
                  />
                </div>
                : ''}
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
export default InviteEarnPage