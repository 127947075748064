import React, { useState, useEffect } from 'react'
import Card from '../shared/Card'
import Table from '../shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../shared/Button'
import P from '../shared/P'
import { DisplayPagination } from '../styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import Util from "../../../../utils/util"
import Modal from "react-bootstrap/Modal"
import moment from 'moment'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from './styles/SubmittedCandidate.module.scss'
import { CSVLink } from "react-csv";

const SubmittedCandidate = ({recruiters}) => {
  sessionStorage.removeItem('timeReportDateRange')
  var active_page = window.sessionStorage.getItem('submit_activePage')
  var query = window.sessionStorage.getItem('submit_query')
  const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
  const [pageCount, setPageCount] = useState(0)
  const [candidates, setCandidates] = useState([])
  const [totalCandidate,setTotalCandidate] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [show,setShow] = useState(false)
  const [entry,setEntry] = useState({})
  const [modal,setModal] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isLoadingCandidate, setLoadingCandidate] = useState(false)
  const [isLoadingRecruiter, setLoadingRecruiter] = useState(false)
  const [selectedOrganization, setSelectedOrganization]  = useState('')
  const [selectedCandiate, setSelectedCandiate]  = useState('')
  const [selectedRecruiter, setSelectedRecruiter]  = useState('')
  const [inputValue, setInputValue] = useState('')
  const [member_options, setOptions] = useState([])
  const [candidateOptions, setCandidateOptions] = useState([])
  const [recruiterOptions, setRecruiterOptions] = useState([])
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [perPageValue,setPerPageValue] = useState(25)
  const [loading, setLoader] = useState(true)

  const headers = [
    { label: 'Candidate Name', key: 'candidate_name' },
    { label: 'LinkedIn URL', key: 'linkedin_profile_url' },
    { label: 'Education level', key: 'education_exp' },
    { label: 'Recent job title', key: 'title' },
    { label: 'Recent company', key: 'company_names' },
    { label: 'Location', key: 'location' },
    { label: 'Desired Compensation', key: 'salary' },
    { label: 'Submitted Date', key: 'submited_date' },
    { label: 'Organization Name', key: 'org_name' },
    { label: 'Job Title', key: 'job_title' },
    { label: 'Submitted By', key: 'submited_by' }
  ];
  
  const fetchData = () => {
    setLoader(true)
    const url = `/admin/submitted_candidates.json`
    window.sessionStorage.setItem('submit_activePage',activePage)
    makeRequest(url, 'get',  {params: {page: (activePage+1), recruiter:selectedRecruiter,candidates:selectedCandiate,organization:selectedOrganization}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setCandidates(
          res.data.candidate_list.map((candidate) => ({
            candidate_name:  Util.fullName(candidate?.submission?.person?.first_name,candidate?.submission?.person.last_name),
            submited_by: Util.fullName(candidate?.user?.first_name,candidate?.user?.last_name),
            submited_date: candidate?.created_at,
            job_title: candidate?.submission?.job?.name,
            org_name: candidate?.submission?.job?.organization?.name,
            user_id: candidate.user_id,
            linkedin_profile_url: candidate?.submission?.person?.linkedin_profile_url,
            title: candidate?.submission?.person?.title,
            company_names: candidate?.submission?.person?.company_names,
            location: candidate?.submission?.person?.location,
            salary: candidate?.submission?.person?.salary,
            currency: candidate?.submission?.person?.currency,
            education_exp: candidate?.submission?.person?.education_experiences[0]?.degree,
            views : <a onClick={()=> editRecruiter(candidate)} href="#">  <i size='20' color="#4C68FF" data-feather="edit-2"/></a>
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalCandidate(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setPerPageValue(res.data.per_page)
        setLoader(false)
      },
    })
  }

  const exportDataMethod = async () => {
    const url = '/admin/submitted_candidates.json'
    const response = await makeRequest(
        `${url}?recruiter=${selectedRecruiter}&candidates=${selectedCandiate}&organization=${selectedOrganization}`,
        'get',
        {}
    )
    setExportDatas(
      response.data.candidate_list.map((candidate) => ({
        id: candidate.id,
        candidate_name:  Util.fullName(candidate?.submission?.person?.first_name,candidate?.submission?.person.last_name),
        submited_by: Util.fullName(candidate?.user?.first_name,candidate?.user?.last_name),
        submited_date: moment(candidate.created_at).format('MMM DD, YYYY'),
        job_title: candidate?.submission?.job?.name,
        org_name: candidate?.submission?.job?.organization?.name,
        linkedin_profile_url: candidate?.submission?.person?.linkedin_profile_url,
        title: candidate?.submission?.person?.title,
        company_names: candidate?.submission?.person?.company_names,
        location: candidate?.submission?.person?.location,
        salary: (candidate?.submission?.person?.currency || '') +' '+(candidate?.submission?.person?.salary || ''),
        education_exp: candidate?.submission?.person?.education_experiences[0]?.degree
      }))
    )
    csvExportRef?.current?.link.click();
}
   
  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activePage,selectedOrganization,selectedCandiate,selectedRecruiter])

  useEffect(()=>{
    setModal(document.querySelector('.modal'))
  })

  const onHide = () => {
    setShow(false)
    setEntry({})
  }
  const getUpdateData = (candidate) => {
      setShow(true)
      setEntry(candidate)
  }
  const editRecruiter = (candidate) => {
    getUpdateData(candidate)
  }
  const handleInputChange = (str) => {
    setInputValue(str)
    return str;
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
      
    let URL = `/admin/submitted_candidates/${entry.id}`
    const payload = new FormData()
    payload.append('user_id', e.target.user_id.value)
    
    //headers
    const CSRF_Token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const headers = {
      headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
      },
    }
    makeRequest(URL,'put', payload, headers, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
    }).then((res) => {
      if(res.data.success){
          onHide()
          fetchData()
      }
    }) 
    
    setBtnDisabled(false)
  }

  const handleSelectOption = (selectedOptions) => {
    setActivePage(0)
    setSelectedOrganization(selectedOptions?.value || '')
  }
  const handleSelectCandidateOption = (selectedOptions) => {
    setActivePage(0)
    setSelectedCandiate(selectedOptions?.value || '')
  }
  const handleSelectRecruiterOption = (selectedOptions) => {
    setActivePage(0)
    setSelectedRecruiter(selectedOptions?.value || '')
  }

  const loadOptions = (inputValue, callback) => {
    setLoading(true)
    fetch(`/admin/organizations/fetch_organization?search=${inputValue}`)
    .then((res) => res.json())
    .then((res) => {
      let {organizations} = res
      setLoading(false)
      setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(member_options);
  }

  const loadCandidateOption = (inputValue, callback) => {
    setLoadingCandidate(true)
    fetch(`/admin/users/fetch_pepoles?search=${inputValue}`)
    .then((res) => res.json())
    .then((res) => {
      let {pepoles} = res
      setLoadingCandidate(false)
      setCandidateOptions([...pepoles.map((candidate) => ({ value: candidate.id, label: candidate.first_name +' '+ candidate.last_name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(candidateOptions);
  }

  const loadRecruiterOption = (inputValue, callback) => {
    setLoadingRecruiter(true)
    fetch(`/admin/users/fetch_users?search=${inputValue}&role=recruiter`)
    .then((res) => res.json())
    .then((res) => {
      let {users} = res
      setLoadingRecruiter(false)
      setRecruiterOptions([...users.map((user) => ({ value: user.id, label: user.first_name +' '+ user.last_name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(recruiterOptions);
  }

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }

  return (
    <>
    <Card>
      <div className="d-flex justify-content-between align-items-center w-100" style={{ marginBottom: '42px' }} >
          <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
              Submitted Candidates
          </P>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
       </div>
        <CSVLink
            className='hidden'
            data={exportDatas} 
            headers={headers}
            filename={"Submitted_candidate.csv"}
            ref={csvExportRef}
        >
        </CSVLink>
      
        <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
          <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'left'}}>
            {totalCandidate > 0  &&
              <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord+1} - {currentCounts}  of  {totalCandidate} candidates
              </DisplayPagination>
          }
          </Col>

          <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
              
            <div>
                <label>
                    Organization
                </label>
                <AsyncSelect
                    isLoading={isLoading}
                    isClearable={true}
                    loadOptions={loadOptions}
                    defaultOptions={selectedOrganization}
                    onInputChange={handleInputChange}
                    onChange={handleSelectOption}
                    placeholder={'Search by organization'}
                    styles={colourStyles}
                    noOptionsMessage={() => 'start typing the name of organization'}
                />
            </div>
            <div style={{marginLeft:'10px'}}>
                <label>
                    Select Candidate
                </label>
                <AsyncSelect
                    isLoading={isLoadingCandidate}
                    isClearable={true}
                    loadOptions={loadCandidateOption}
                    defaultOptions={selectedCandiate}
                    onInputChange={handleInputChange}
                    onChange={handleSelectCandidateOption}
                    placeholder={'Search by name'}
                    styles={colourStyles}
                    noOptionsMessage={() => 'start typing the name of candiate'}
                />
            </div>
            <div style={{marginLeft:'10px'}}> 
                <label>
                  Select Recruiter
                </label>
                <AsyncSelect
                    isLoading={isLoadingRecruiter}
                    isClearable={true}
                    loadOptions={loadRecruiterOption}
                    defaultOptions={selectedRecruiter}
                    onInputChange={handleInputChange}
                    onChange={handleSelectRecruiterOption}
                    placeholder={'Search by name'}
                    styles={colourStyles}
                    noOptionsMessage={() => 'start typing the name of reruiter'}
                />
                
            </div>
          </Col>

        </Row>
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Candidate Name',
              field: 'candidate_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'LinkedIn URL',
              field: 'linkedin_profile_url',
              editable: false,
              type: 'text',
            },
            {
              name: 'Education level',
              field: 'education_exp',
              editable: false,
              type: 'text',
            },
            {
              name: 'Recent job title',
              field: 'title',
              editable: false,
              type: 'text',
            },
            {
              name: 'Recent company',
              field: 'company_names',
              editable: false,
              type: 'text',
            },
            {
              name: 'Location',
              field: 'location',
              editable: false,
              type: 'text',
            },
            {
              name: 'Desired Compensation',
              field: 'salary',
              editable: false,
              type: 'text',
            },
            {
              name: 'Submitted Date',
              field: 'submited_date',
              editable: true,
              type: 'text',
            },
            {
              name: 'Org Name',
              field: 'org_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Job Title',
              field: 'job_title',
              editable: false,
              type: 'text',
            },
            {
              name: 'Submitted By',
              field: 'submited_by',
              editable: false,
              type: 'text',
            },
            {
              name: 'Action',
              field: 'views',
              editable: false,
              type: 'text',
              nowrap:'text-nowrap'
            },
          ]}
          rowValues={candidates.map((candidate) => ({
              ...candidate,
              submited_date: moment(candidate.submited_date).format('MMM DD, YYYY'),
              salary: (candidate?.currency || '')+' '+ (candidate?.salary || '')
            }))}
            activePage={activePage}
            perPage={perPageValue}
            loading={loading}
      />
      {pageCount > 1 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
        >
          <Paginator
              activePage={activePage}
              setActivePage={setActivePage}
              pageCount={pageCount}
              pageWindowSize={5}
              showGoToField={false}
          />
        </div>
      )}
    </Card>

    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
              {'Update Recruiter'} 
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
              <Row className="w-100" style={{minHeight:'120px'}}>
                  <Col xs={12} sm={12} lg={8}>
                    <span>Select Recruiter* : </span>
                    <Select
                      options={recruiters}
                      name='user_id'
                      id='user_id'
                      placeholder={'Select Recruiter'}
                      defaultValue={recruiters.filter((row) => row.value == entry.user_id)}
                      menuPortalTarget={modal}
                    />
                  </Col>
                  <Col xs={12} sm={12} lg={4} style={{marginTop:'20px'}}>
                    <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Update</Button>
                  </Col>
              </Row>
            </form>
        </Modal.Body>
    </Modal>

    </>
  )
}

export default SubmittedCandidate