import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { allStages } from "../../../../misc/gconst";
import { makeRequest } from "../../../common/RequestAssist/RequestAssist";
import '../MoveToStage/Styles/movetostage.scss'
const CancelInterviewModal = ({setCancelInterViewModal, cancelinterviewModal,candidateinterviewid}) => {
    const [selectStage, setSelectStage] = useState()
    const [dropdownitem, setDropdownitem] = useState()
    const [errormessage, setErrorMessage] = useState()
    const [checkvalidation, setCheckValidation] = useState(false)
    function menuItemsForStage(activeStage) {
        return allStages.filter((stageData) => stageData.id !== activeStage).filter((item)=>item.id != 'first_interview').filter((item)=>item.id != 'second_interview').filter((item)=>item.id != 'hired').filter((item)=>item.id != 'offer')
    }
    const onCancelInterview = async () => {
        setErrorMessage(!selectStage ? 'please select stage' : '')
        if(checkvalidation){
        const payload = new FormData()
        payload.append('interview_schedule[interview_type]', selectStage)
        payload.append('interview_schedule[id]', candidateinterviewid?.id)
        payload.append('interview_schedule[person_id]', candidateinterviewid?.person_id)
        payload.append('interview_schedule[org_id]', candidateinterviewid?.org_id)
        payload.append('interview_schedule[current_stage]', candidateinterviewid?.interview_type)
        payload.append('interview_schedule[job_id]', candidateinterviewid?.job_id)
        payload.append('interview_schedule[interview_time]', candidateinterviewid?.interview_time)
        payload.append('interview_schedule[interview_date]', candidateinterviewid?.interview_date)
        payload.append('interview_schedule[interview_status]','cancelled')
        let url = '/interviewer_move_to_stage.json'
        const res = await makeRequest(url, 'put', payload, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createResponseMessage: (responce) => {
            if(responce?.success){
                setCancelInterViewModal(false)
                setTimeout(() => {
                window.location.href = `/interview_scheduled`                    
                }, 1000);
            }
            
              return {
                  message: responce?.msg
                      ? responce.msg
                      : 'successfully moved',
                  messageType: 'success',
                  loading: false,
                  autoClose: true,
              }
          },
          })
        }
    }

    return(
        <>
            <Modal
                    onHide={() => setCancelInterViewModal(false)}
                    className="move-to-stage-modal"
                    show={cancelinterviewModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header
                    closeButton
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Modal.Title>Cancel and move to </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div class="container">
                            <div class="row">
                                <div class="col-sm">
                                <Dropdown>
                <Dropdown.Toggle className="changeStageDropdown stageRight movetostage-dropdown">
                {dropdownitem ? dropdownitem : 'Move selected to'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {menuItemsForStage(candidateinterviewid.interview_type).map((itemData,index) => (
                        <Dropdown.Item
                            key={index}
                            onClick={async (e) => {
                                e.preventDefault()
                                setSelectStage(itemData?.id)
                                setDropdownitem(itemData?.label)
                                setCheckValidation(true)
                                setErrorMessage('')
                            }}
                        >
                            {itemData.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
                            
                                </div>
                            </div>
                        </div>
                       <p style={{marginLeft:'83px', color:'red', fontSize:'12px'}}>{errormessage}</p>
                        <div className="d-flex sub-move-to-btn">
                            <div className="move-tostage-btn-interview">
                                <Button
                                    variant="secondary"
                                    onClick={() => setCancelInterViewModal(false)}
                                >
                                    Close
                                </Button>
                            </div>
                            <div className="move-tostage-btn-interview">
                                <Button
                                    variant="secondary"
                                    onClick={onCancelInterview}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
        
                </Modal>
        </>
    )
}
export default CancelInterviewModal;