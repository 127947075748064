import React, { useState, useEffect } from 'react'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import P from '../Admin/shared/P'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import '../JobLeads/styles/JobLead.scss'
import Util from "../../../utils/util";
import { Row, Col,Spinner } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal"
import SweetAlert from 'react-bootstrap-sweetalert'
import DataTable from "react-data-table-component-footer"
import   '../Admin/styles/DataTableCustom.scss'
import feather from 'feather-icons'
import Badge from 'react-bootstrap/Badge'
import Moment from 'moment'

const ListCampaign = ({current_user}) => {
 console.log(current_user)
  const [activePage, setActivePage] = useState(1)
  const [show,setShow] = useState(false)
  const [loading, setLoder] = useState(false)
  const [pendingReview, setPendingReview] = useState([]);
  const [showModelChart, setShowModelChart] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [showModel, setShowModel] = useState(false)
  const [showModelList, setShowModelList] = useState(false)
  const [emailBody, setEmailBody] = useState('')
  const [listSubscriber, setListSubscriber] = useState([]);
  const [graphData, setDraphData] = useState({});

  const fetchData = () => {
    const url = `/campaigns_list.json`
    setLoder(true)
    makeRequest(url, 'get',  {params: {page:activePage}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        if(res.data.status == true){
            let sr_no = (activePage - 1) * res.data.per_page
            setPendingReview(
            res.data.list.map((data) => (sr_no = sr_no + 1,{
                sr_no: sr_no,
                id: data.campaign_id,
                campaign_name: Util.capitalize(data.campaign_title),
                lunch_date: Moment(data?.campaign_launch).format('MMM DD, YYYY') ,
                status: getStatus(data?.campaign_pos),
                receivers: data?.status?.total,
                sent: data?.status?.sent,
                open: data?.status?.opens,
                click: data?.status?.clicks,
                progress:  <Badge style={{background: '#28b62c',fontSize: '11.5px', color: 'white', width: '82px', paddingTop: '5px', paddingBottom: '5px'}} >{ ((100 * data?.status?.sent) / data?.status?.total) } %</Badge>,
                action: <><span style={{fontSize: '12px',cursor: 'pointer', marginLeft: '20px'}} onClick={ () => onClickDetail(data?.campaign_body)} ><i  style={{width: '20px'}} data-feather="eye" /></span> <span onClick={ () => onClickDetailList(data?.campaign_id)} style={{fontSize: '12px',cursor: 'pointer', marginLeft: '10px'}} ><i style={{width: '20px'}} data-feather="users" /></span><span onClick={ () => onClickDetailGraph(data?.campaign_id)} style={{fontSize: '12px',cursor: 'pointer', marginLeft: '10px'}} ><i style={{width: '20px'}} data-feather="pie-chart" /></span></>
            }))
            )
            setPerPage(res.data.per_page)
            setPageCount(res.data.total_pages)
            setStartRecord((activePage - 1) * res.data.per_page)
            setCurrentCounts(res.data.current_counts)
            setTotalCount(res.data.total_count)
        }
        setLoder(false)
      },
    })
    
  }

  const getStatus = (pos) => {

    if(pos == 0){
       return <Badge style={{background: '#158cba', marginLeft: '18px',fontSize: '11.5px', color: 'white', width: '82px', paddingTop: '5px', paddingBottom: '5px'}} >Pending</Badge>
    }else if(pos == 1){
        return  <Badge style={{background: '#ff851b', marginLeft: '18px',fontSize: '11.5px', color: 'white', width: '82px', paddingTop: '5px', paddingBottom: '5px'}} >Sending</Badge>
    }else if(pos == 2){
        return <Badge style={{background: '#ff4136', marginLeft: '18px',fontSize: '11.5px', color: 'white', width: '82px', paddingTop: '5px', paddingBottom: '5px'}} >Stopped</Badge>
    }else if(pos == 3){
        return <Badge style={{background: '#28b62c', marginLeft: '18px',fontSize: '11.5px', color: 'white', width: '82px', paddingTop: '5px', paddingBottom: '5px'}} >Completed</Badge>
    }
    return "Pending"

  }

  useEffect(() => {
    feather.replace()
  })

  const onClickDetailGraph = (id)=> {
    setShowModelChart(true)
    fetchGraphData(id)
  }

  useEffect(() => {
      fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(()=>{
    console.log('Change Page')
    fetchData()
},[activePage])

  const  columns= [
    {
      name: 'No.',
      selector: row => row.sr_no,
      maxWidth: '5%',
      minWidth: '5%',
    },
    {
      name: 'Campaign Name',
      selector: row => row.campaign_name,
      maxWidth: '22%',
      minWidth: '22%',
      wrap:true
    },
    {
      name: 'Launch Date',
      selector: row => row.lunch_date,
      maxWidth: '12%',
      minWidth: '12%',
      wrap:true,
    },
    {
      name: 'Campaign Status',
      selector: row => row.status,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true
    },
    {
      name: 'Receivers',
      selector: row => row.receivers,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true
    },
    {
      name: 'Sent',
      selector: row => row.sent,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true
    },
    {
      name: 'Open',
      selector: row => row.open,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true
    },
    {
      name: 'Progress',
      selector: row => row.progress,
      maxWidth: '9%',
      minWidth: '9%',
    },
    {
      name: 'Action',
      selector: row => row.action,
      maxWidth: '12%',
      minWidth: '12%',
    }
]

const onClickDetail = (html)=> {
  setEmailBody(html)
  setShowModel(true)
}

const onClickDetailList = (id)=> {
  setShowModelList(true)
  fetchDataCandidate(id)
}

const onHide = ()=> {
  setShowModel(false)
  setEmailBody('')
}

const onHideList = ()=> {
  setShowModelList(false)
}

const onHideChart = ()=> {
  setShowModelChart(false)
}


const fetchGraphData = (id) => {
  console.log('fetchGraphData');
  const url = `/campaign_graph`
  makeRequest(url, 'get',  {params: {campaign_id:id}}, {   
    contentType: 'application/json',
    loadingMessage: 'Fetching...',
    createSuccessMessage: (response) => response.data.message,
    onSuccess: (res) => {
      console.log(res.data)
      if(res.data.status == true){
        setDraphData(res.data.data)
        console.log(res.data.data)
      }
    },
  })

}

const fetchDataCandidate = (id) => {
  console.log('fetchDataCandidate');
  const url = `/subscription_list`
  makeRequest(url, 'get',  {params: {campaign_id:id}}, {   
    contentType: 'application/json',
    loadingMessage: 'Fetching...',
    createSuccessMessage: (response) => response.data.message,
    onSuccess: (res) => {
      console.log(res.data)
      if(res.data.status == true){
         let sr_no = 0
          setListSubscriber(
              res.data.list.map((data) => (sr_no = sr_no + 1,{
                sr_no: sr_no,
                name: data.subscriber_name,
                email: data.subscriber_email,
                id: data.subscriber_id
            }))
          );
          console.log(res.data.data)
      }
    },
  })
  
}
  
  return (
    <>
    <div className="container">
      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="28px" height="38px" color="#1D2447" style={{width:'940px',marginBottom:'20px'}}>
           Campaigns
        </P>
      </div>
      <Row>
          <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {(currentCounts > totalCount) ? totalCount : currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
      </Row>
      <div className="w-100">
        <DataTable
            persistTableHead={true}
            noDataComponent={<p className="text-center p-3">No record found</p>}
            columns={columns}
            data={pendingReview}
            progressPending={loading}
            progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                <Spinner className='spinner' animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </P>}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={perPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setActivePage(page)}
        />
      </div>
    </div>
    <Modal
      onHide={onHide}
      show={showModel}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ml-3">
          Email Body
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{__html: emailBody}}>
        </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="light" onClick={onHide }>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal
      onHide={onHideList}
      show={showModelList}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ml-3 text-left" style={{textAlign:'left'}}>
            { (current_user.role == 'talent') ? 'Decision Makers' : 'Candidates'} 
        </Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <div className="table-responsive" style={{maxHeight: '300px', overflowY: 'auto'}}>
            <table className='table table-striped table-bordered'>
                <thead>
                    <tr><th>No.</th><th>Name</th><th>Email</th></tr>
                </thead>
                <tbody>
                  {
                    listSubscriber.map( (data,index) => {
                      return <tr ><td>{data.sr_no}</td><td>{data.name}</td><td>{data.email}</td></tr>
                    })
                  }
                </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="light" onClick={onHideList }>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal
      onHide={onHideChart}
      show={showModelChart}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
    >
        <Modal.Body>
        <div className="row">
            <div className="col-md-12">
                <h4>Summary
                </h4><hr/>
                <div className="row report-boxes">
                    <div className="col-md-6">
                        <div className="alert alert-success">
                            <strong style={{fontSize:'22px'}}>Sent</strong><br/>
                            <span className="counter2" data-to="{ graphData?.sent }"  style={{fontSize:'30px'}}>{ graphData?.sent }</span>%
                            <i className="fa fa-paper-plane report-box-icon"></i>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="alert alert-dark">
                            <strong style={{fontSize:'22px'}}>Unsent</strong><br/>
                            <span className="counter2" data-to="{ graphData?.unsent }" style={{fontSize:'30px'}}>{ graphData?.unsent }</span>%
                            <i className="fa fa-clock report-box-icon"></i>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="alert alert-warning">
                            <strong style={{fontSize:'22px'}}>Opens</strong><br/>
                            <span className="counter2" data-to="{ graphData?.opens }" style={{fontSize:'30px'}}>{ graphData?.opens }</span>%
                            <i className="fa fa-envelope-open report-box-icon"></i>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="alert  alert-danger">
                            <strong style={{fontSize:'22px'}}>Bounces</strong><br/>
                            <span className="counter2" data-to="{ graphData?.bounces }" style={{fontSize:'30px'}}>{ graphData?.bounces }</span>%
                            <i className="fa fa-user-slash report-box-icon"></i>
                        </div>
                    </div>
                    <div className="col-md-6 pt-3">
                        <h6 style={{fontSize:'22px'}}>List Quality</h6><hr/>
                        <div className="score-count h3 text-center"  style={{color: 'rgb(146, 208, 80)'}}>
                            <span className="counter2" data-invert="false" data-to="{ graphData?.lquality }" style={{fontSize:'33px'}}>{ graphData?.lquality }</span> %
                            <div className="pt-2 h1">
                                <i className="fas fa-thumbs-up"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 pt-3">
                        <h6 style={{fontSize:'22px'}}>Campaign Quality</h6><hr/>
                        <div className="score-count h3 text-center" style={{color: 'rgb(255, 102, 0)'}} >
                            <span className="counter2" data-invert="false" data-to="{ graphData?.quality }" style={{fontSize:'33px'}}>{ graphData?.quality }</span> %
                            <div className="pt-2 h1">
                                <i className="fas fa-thumbs-up score-anim"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
        <Button variant="light" onClick={onHideChart }>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}




export default ListCampaign