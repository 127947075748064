import React, { useState, useEffect } from 'react'
import feather from 'feather-icons'
import moment from 'moment'
import { Row, ButtonGroup } from 'react-bootstrap'
import { Alert, Spinner } from 'react-bootstrap'

import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import { BarChart, LineChart } from '../../../common/Chart'
// import useCustomFetch from '../../../hooks/useCustomFetch'
import styles from './styles/ChartContainer.module.scss'

import { Legends, getBarChartData } from './constants'

function ChartContainer({
  metricsData,
  handleCloseViweAnalitic,
  jobId,
}) {
  const [isEngagementGraph, setIsEngagementGraph] = useState(true)
  const [filter, setFilter] = useState({ startDate: Date(), endDate: Date() })
  const startDate = moment(filter.startDate).format('MM/DD/YYYY')
  const endDate = moment(filter.endDate).format('MM/DD/YYYY')

  // const URL = `/jobs.json?page=${1}&user_id=${userId}&job_id=${jobId}&start_date=${startDate}&end_date=${endDate}`

  // const [
  //   data,
  //   loading,
  //   errorFetchingJob,
  //   setErrorFetchingJob,
  // ] = useCustomFetch(URL)

  useEffect(() => {
    feather.replace()
  })

  const handleEngagementGraph = () => {
    setIsEngagementGraph(true)
  }
  const handleMatricGraph = () => {
    setIsEngagementGraph(false)
  }
  const handleOnSubmit = (newDate) => {
    setFilter(newDate)
  }
  function buildBarData() {
    const chartData = getBarChartData()
    if (!metricsData || metricsData.length < 0) {
        return chartData
    }

    const metrics = metricsData[0] || {}
      const {leads, archive, applicant, recruiter,
      submitted, first_interview, second_interview, 
      offer,archived
    } = metricsData[0]

    const results = chartData.map((item) => {
      if (item.name === 'Leads') {
          item.count = leads || 0
      }

      if (item.name === 'Active Candidates') {
        item.count = archive || 0
      }

      if (item.name === 'Applicants') {
          item.count = applicant || 0
      }

      if (item.name === 'Recruiter screen') {
          item.count = recruiter || 0
      }

      if (item.name === 'Submitted') {
          item.count = submitted || 0
      }

      if (item.name === '1st Interview') {
          item.count = first_interview || 0
      }

      if (item.name === '2nd Interview') {
          item.count = second_interview || 0
      }

      if (item.name === 'Offers') {
          item.count = offer || 0
      }

      if (item.name === 'Archived') {
          item.count = archived || 0
      }

      return item
  })

    return results
  }

  function buildLineChartData() {
    if (!metricsData || metricsData.length < 0) {
      return []
    }

    const metrics = metricsData|| []
    const lineChartData = metrics.map((item) => {
      return {
          ...item,
          date: moment(item.metrics_date).format('MMM DD'),
      }
    })
    return [...lineChartData]
  }

    return (
      <>
        <Row className={styles.rowChartContainer + ' d-flex mt-3'}>
          <div className={styles.chartContainer}>
                <Row className={styles.chartHeader}>
                    <ButtonGroup style={{ display: 'initial' }}>
                        <button
                            style={{
                                background: `${
                                    isEngagementGraph
                                        ? '#4C68FF'
                                        : ' #EBEDFA'
                                }`,
                                color: `${
                                    isEngagementGraph
                                        ? ' #EBEDFA'
                                        : '#4C68FF'
                                }`,
                            }}
                            className={styles.graphButton}
                            onClick={() => handleEngagementGraph()}
                        >
                            {' '}
                            Engagement Graph
                        </button>
                        <button
                            style={{
                                background: `${
                                    !isEngagementGraph
                                        ? '#4C68FF'
                                        : ' #EBEDFA'
                                }`,
                                color: `${
                                    !isEngagementGraph
                                        ? ' #EBEDFA'
                                        : '#4C68FF'
                                }`,
                            }}
                            className={styles.graphButton}
                            onClick={() => handleMatricGraph()}
                        >
                            {' '}
                            Metric Bar{' '}
                        </button>
                    </ButtonGroup>
                    <div className={styles.clanederContainer}>
                        {' '}
                        {isEngagementGraph && (
                            <DateRangePicker
                                defaultDate={filter}
                                handleOnSubmit={handleOnSubmit}
                            />
                        )}
                    </div>
                    <div style={{ flexGrow: 1 }}></div>
                    <div
                        onClick={() => handleCloseViweAnalitic(jobId)}
                        className={styles.close}
                    >
                        Close{' '}
                        <span>
                            <i
                                className={styles.exitIcon}
                                data-feather="x"
                            ></i>
                        </span>
                    </div>
                </Row>
                <Row
                    style={{ margin: '0px !important' }}
                    className={styles.graphNameRow}
                >
                  <span className={styles.graphName}>
                    {!isEngagementGraph
                        ? 'Metric Bar'
                        : 'Engagement Graph'}
                  </span>
                </Row>
                      <Row className={styles.graphsContainer}>
                        {isEngagementGraph ? (
                          <LineChart
                            data={buildLineChartData()}
                            legends={Legends}
                          />
                        ) : (
                          <BarChart data={buildBarData()} />
                        )}
                      </Row>
          </div>
        </Row>
      </>
    )
}

export default ChartContainer
