import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Table from './shared/Table'
import { capitalize } from '../../../utils'
import Paginator from '../../common/Paginator/Paginator'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'

const CrossSubmissionsAnalyticPage = (prop) => {
  const {currentUser} = prop
  const queryParams = new URLSearchParams(window.location.search)
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  const urlUserID = queryParams.get("user_id")
  const [activePage, setActivePage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobStatus, setJobStatus] = useState([])
  const localStorageDateRange = JSON.parse(sessionStorage.getItem('AdminCrossSubmissionsAnalyticPageDateRange'))
  const startDate = (urlStartDate ? urlStartDate : (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate())
  const endDate = (urlEndDate ? urlEndDate : (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate())
  const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
  const handleDate = (date) => {
    sessionStorage.setItem('AdminCrossSubmissionsAnalyticPageDateRange', JSON.stringify(date))  
    setDateRange(date)
  }
  if(urlStartDate!='' & urlEndDate!=''){
    sessionStorage.setItem('AdminCrossSubmissionsAnalyticPageDateRange', JSON.stringify(dateRange))
  }
  const [organizations,setOrganizations] = useState([{value: 'all', label: 'All'},...prop.organizations])
  const [recruiters,setRecruiters] = useState([{value: 'all', label: 'All'},...prop.recruiters])
  const [selectedOrganization,setSelectedOrganization] = useState('')
  const [selectedOrganizationType,setSelectedOrganizationType] = useState('')
  const [selectedRecruiters,setSelectedRecruiters] = useState((urlUserID)?urlUserID:'')
  const [defaultselected,setdefaultselected] = useState({value: 'all', label: 'All'})
  const [defaultselectedRecruiters,setdefaultselectedRecruiters] = useState((urlUserID)? prop.recruiters.filter((option) => option.value == urlUserID):{value: 'all', label: 'All'})
  const [organizationType,setOrganizationType] = useState([{value: 'all', label: 'All'},{value: 'hourly', label: 'Hourly'},{value: 'contingency', label: 'Contingency'}])
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [totalValues,setTotalValues] = useState({})
  const [recruiterUsername,setRecruiterUsername] = useState(prop?.recruiter_username || '')
  const [loading, setLoading] = useState(true)
  let tableHeaders = [
      { label: 'Organization Name', key: 'organization' },
      { label: 'Job Name', key: 'jobName' },
      { label: 'New Submitted', key: 'recruitor_screen' },
      { label: 'Sent to client', key: 'submitted' },
      { label: 'First Interview', key: 'firstInterview' },
      { label: 'Second Interview', key: 'secondInterview' },
      { label: 'Offers', key: 'offers' },
      { label: 'Hired', key: 'hired' },
      { label: 'Archived', key: 'archived' },
    ]
  let tableColumn = [
      { name: 'No.',field: 'id',editable: false,type: 'text' },
      { name: 'Organization Name',field: 'organization',editable: false,type: 'text' },
      { name: 'Job name',field: 'jobName',editable: false,type: 'text'},
      { name: 'New Submitted',field: 'recruitor_screen',editable: false,type: 'text'},
      { name: 'Sent to client',field: 'submitted',editable: false,type: 'text',},
      { name: 'First Interview',field: 'firstInterview',editable: false,type: 'text'},
      { name: 'Second Interview',field: 'secondInterview',editable: false,type: 'text'},
      { name: 'Offers',field: 'offers',editable: false,type: 'text'},
      { name: 'Hired',field: 'hired',editable: false,type: 'text'},
      { name: 'Archived',field: 'archived',editable: false,type: 'text'},
    ]

  const fetchData = async () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/cross_submissions_analytic.json'
    const response = await makeRequest(
      `${url}?page=${activePage + 1}&start_date=${start_date}&end_date=${end_date}&organization=${selectedOrganization}&organization_type=${selectedOrganizationType}&recruiters=${selectedRecruiters}`,
      'get',
      {}
    )
    setJobStatus(response?.data?.data.map((data,key)=> ({
      id: key,
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organizations_name && capitalize(data?.organizations_name),
      organizations_id: data?.organizations_id && data?.organizations_id,
      job_id: data?.job_id && data?.job_id, 
      submitted: data.submitted,
      recruitor_screen: data.recruitor_screened,
      firstInterview: data.first_interview,
      secondInterview: data.second_interview,
      offers: data.offer,
      archived: data.reject,
      hired: data.hired,
      created_at: data.created_at,
    })))
    setPageCount(response.data.total_pages)
    setTotalCount(response.data.total_count)
    setStartRecord((activePage * response.data.per_page)+1)
    setPerPage(response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setTotalValues(response.data.total_values)
    setLoading(false)
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/cross_submissions_analytic.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&organization=${selectedOrganization}&organization_type=${selectedOrganizationType}&recruiters=${selectedRecruiters}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.data.map((data,key)=> ({
      id: key,
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organizations_name && capitalize(data?.organizations_name),
      submitted: data.submitted,
      recruitor_screen: data.recruitor_screened,
      firstInterview: data.first_interview,
      secondInterview: data.second_interview,
      offers: data.offer,
      archived: data.reject,
      hired: data.hired,
      created_at: data.created_at,
    })))
    csvExportRef?.current?.link.click();
  }

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage,selectedOrganization,selectedOrganizationType,selectedRecruiters])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  };

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            {recruiterUsername} - Cross Submission Analytics
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
          <a href='/admin/cross_submission_recruiters_analytic' className={`ml-3 ${styles.exportCSVButton}`}>
              Back
          </a>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"CrossSubmissionAnalytics.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 &&
                <DisplayPagination>Displaying  {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Organization
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={organizations}
                  onChange={(event)=>setSelectedOrganization(event.value)}
                  name="organization"
                  className={styles.colourStyles}
              />
            </div>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Recruiter
              </label>
              <Select
                  defaultValue={defaultselectedRecruiters}
                  options={recruiters}
                  onChange={(event)=>{setSelectedRecruiters(event.value); setRecruiterUsername(event.label); }}
                  name="recruiters"
                  className={styles.colourStyles}
              />
            </div>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Organization Type
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={organizationType}
                  onChange={(event)=>setSelectedOrganizationType(event.value)}
                  name="organization_type"
                  className={styles.colourStyles}
              />
            </div>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Select Date Range
              </label>
              <DateRangePicker
                  handleOnSubmit={handleDate}
                  defaultDate={dateRange}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Table 
        columNames={tableColumn}
        rowValues={jobStatus.map((o)=>({
          ...o,
          created_at: moment(o.created_at).format(
              'MMM DD, YYYY'
          ),
          organization: (<a href={`/admin/cross_submission_details_analytic?organization_id=${o.organizations_id}&job_id=${o.job_id}&recruiter_id=${selectedRecruiters}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}`}>{o.organization}</a>),
        }))
        }
        activePage={activePage}
        perPage={perPage}
        totalValues={totalValues}
        loading={loading}
      />
      { pageCount > 1 && (
          <div
              className="d-flex justify-content-center"
              style={{ marginTop: 'auto' }}
          >
              <Paginator
                  activePage={activePage}
                  setActivePage={setActivePage}
                  pageCount={pageCount}
                  pageWindowSize={5}
                  showGoToField={false}
              />
          </div>
      )}
    </Card>
  )
}

export default CrossSubmissionsAnalyticPage