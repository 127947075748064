import React, { useEffect, useState } from 'react'
import Card from '../Admin/shared/Card'
import P from '../Admin/shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from './styles/AnalyticPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Table from '../Admin/shared/Table'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AnalyticPage.styled'
import DataTable from "react-data-table-component-footer"
import   '../Admin/styles/DataTableCustom.scss'

const CrossSubmissionsAnalyticPage = (prop) => {
  const {currentUser} = prop
  const queryParams = new URLSearchParams(window.location.search)
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  let jobStatus_org_type = sessionStorage.getItem("jobStatus_org_type")
  let jobStatus_org = sessionStorage.getItem("jobStatus_org")
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(prop.per_page)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobStatus, setJobStatus] = useState([])
  const localStorageDateRange = JSON.parse(sessionStorage.getItem('CrossSubmissionAnalyticsDateRange'))
  const startDate = (urlStartDate ? urlStartDate : (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate())
  const endDate = (urlEndDate ? urlEndDate : (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate())
  const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
  const handleDate = (date) => {
      sessionStorage.setItem('CrossSubmissionAnalyticsDateRange', JSON.stringify(date))
      setDateRange(date)
  }
  const [organizations,setOrganizations] = useState([{value: 'all', label: 'All'},...prop.organizations])
  const [selectedOrganization,setSelectedOrganization] = useState(jobStatus_org && jobStatus_org != "null" ? JSON.parse(jobStatus_org).value : '')
  const [selectedOrganizationType,setSelectedOrganizationType] = useState(jobStatus_org_type && jobStatus_org_type != "null" ? JSON.parse(jobStatus_org_type).value : '')
  const [defaultselected,setdefaultselected] = useState( jobStatus_org && jobStatus_org != "null" ? JSON.parse(jobStatus_org) : {value: 'all', label: 'All'})
  const [organizationType,setOrganizationType] = useState([{value: 'all', label: 'All'},{value: 'hourly', label: 'Hourly'},{value: 'contingency', label: 'Contingency'}])
  const csvExportRef = React.useRef();
  const [defaultOrganizationType, setDefaultOrganizationType] = useState(jobStatus_org_type && jobStatus_org_type != "null" ? JSON.parse(jobStatus_org_type) : {value: 'all', label: 'All'})
  const [exportDatas, setExportDatas] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [footer, setFooter] = useState({})

  let tableHeaders = [
      { label: 'Organization Name', key: 'organization' },
      { label: 'Job Name', key: 'jobName' },
      { label: 'New Submitted', key: 'recruitor_screen' },
      { label: 'Sent to client', key: 'submitted' },
      { label: 'First Interview', key: 'firstInterview' },
      { label: 'Second Interview', key: 'secondInterview' },
      { label: 'On Hold', key: 'onhold' },
      { label: 'Offers', key: 'offers' },
      { label: 'Hired', key: 'hired' },
      { label: 'Archived', key: 'archived' },
    ]
  let columns = [
    {
      name: 'No.',
      selector: row => row.id,
      maxWidth: '5%',
      minWidth: '5%'
    },
    {
      name: 'Organization Name',
      selector: row => row.organization,
      wrap:true,
      sortable: true,
      sortField: 'organizations_name',
      maxWidth: '17%',
      minWidth: '17%'
    },
    {
      name: 'Job name',
      selector: row => row.jobName,
      wrap:true,
      sortable: true,
      sortField: 'sub.job_name',
      maxWidth: '22%',
      minWidth: '22%'
    },
    {
      name: 'New Submitted',
      selector: row => row.recruitor_screen,
      sortable: true,
      sortField: 'recruitor_screened',
      maxWidth: '7%',
      minWidth: '7%'
    },
    {
      name: 'Sent to client',
      selector: row => row.submitted,
      sortable: true,
      sortField: 'submitted',
      maxWidth: '7%',
      minWidth: '7%'
    },
    {
      name: 'First Interview',
      selector: row => row.firstInterview,
      sortable: true,
      sortField: 'first_interview',
      maxWidth: '7%',
      minWidth: '7%'
    },
    {
      name: 'Second Interview',
      selector: row => row.secondInterview,
      sortable: true,
      sortField: 'second_interview',
      maxWidth: '7%',
      minWidth: '7%'
    },
    {
      name: 'On Hold',
      selector: row => row.onhold,
      sortable: true,
      sortField: 'onhold',
      maxWidth: '7%',
      minWidth: '7%'
    },
    {
      name: 'Offers',
      selector: row => row.offers,
      sortable: true,
      sortField: 'offer',
      maxWidth: '7%',
      minWidth: '7%'
    },
    {
      name: 'Hired',
      selector: row => row.hired,
      sortable: true,
      sortField: 'hired',
      maxWidth: '7%',
      minWidth: '7%'
    },
    {
      name: 'Archived',
      selector: row => row.archived,
      sortable: true,
      sortField: 'reject',
      maxWidth: '7%',
      minWidth: '7%'
    }
  ]

  const fetchData = async () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/analytic/cross_submissions_analytic.json'
    const response = await makeRequest(
      `${url}?page=${activePage}&start_date=${start_date}&end_date=${end_date}&organization=${selectedOrganization}&organization_type=${selectedOrganizationType}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setTotalCount(response.data.total_count)
    let sr_no = (activePage - 1) * response.data.per_page
    setJobStatus(response?.data?.data.map((data,key)=> (sr_no = sr_no + 1,{
      id: sr_no,
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: <a href={`/analytic/cross_submission_details_analytic?organization_id=${data.organizations_id}&job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}`}>{ capitalize(data.organizations_name)}</a>,
      organizations_id: data?.organizations_id,
      job_id: data?.job_id,
      submitted: (data.submitted  > 0) ? <a href={`/analytic/cross_submissions_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a> :'',
      firstInterview: (data.first_interview  > 0) ? <a href={`/analytic/cross_submissions_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> :'',
      secondInterview: (data.second_interview  > 0) ? <a href={`/analytic/cross_submissions_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> :'',
      onhold: (data.onhold > 0) ? <a href={`/analytic/cross_submissions_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=onhold`}>{data?.onhold}</a> :'',
      offers: (data.offer > 0) ? <a href={`/analytic/cross_submissions_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a> :'',
      archived: (data.reject > 0) ? <a href={`/analytic/cross_submissions_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> :'',
      hired: (data.hired > 0) ? <a href={`/analytic/cross_submissions_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> :'',
      recruitor_screen:  (data.recruitor_screened > 0) ? <a href={`/analytic/cross_submissions_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> :''
    })))
    setFooter({
      id:'Total',
      firstInterview:response.data.total_values.firstInterview,
      hired:response.data.total_values.hired,
      archived:response.data.total_values.archived,
      recruitor_screen:response.data.total_values.recruitor_screen,
      secondInterview:response.data.total_values.secondInterview,
      submitted:response.data.total_values.submitted,
      offers:response.data.total_values.offers,
  })
    setPageCount(response.data.total_pages)
    setStartRecord((activePage - 1) * response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setLoading(false)
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/analytic/cross_submissions_analytic.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&organization=${selectedOrganization}&organization_type=${selectedOrganizationType}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.data.map((data,key)=> ({
      id: key,
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organizations_name && capitalize(data?.organizations_name),
      submitted: data.submitted,
      recruitor_screen: data.recruitor_screened,
      firstInterview: data.first_interview,
      secondInterview: data.second_interview,
      onhold: data?.onhold, 
      offers: data.offer,
      archived: data.reject,
      hired: data.hired
    })))
    csvExportRef?.current?.link.click();
  }

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage,selectedOrganization,selectedOrganizationType,sortDirection,sortField])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }
  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Cross Submissions
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"CrossSubmissionAnalytics.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
              {totalCount > 0 && loading == false &&
                  <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
              }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Organization
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={organizations}
                  onChange={(event)=>{
                    setSelectedOrganization(event.value)
                    sessionStorage.setItem("jobStatus_org", JSON.stringify(event))
                  }
                  }
                  name="organization"
                  className={styles.colourStyles}
              />
            </div>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Organization Type
              </label>
              <Select
                  defaultValue={defaultOrganizationType}
                  options={organizationType}
                  onChange={(event)=>{
                    setSelectedOrganizationType(event.value)
                    sessionStorage.setItem("jobStatus_org_type", JSON.stringify(event))
                  }}
                  name="organization_type"
                  className={styles.colourStyles}
              />
            </div>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Select Date Range
              </label>
              <DateRangePicker
                  handleOnSubmit={handleDate}
                  defaultDate={dateRange}
              />
            </div>
          </div>
        </Col>
      </Row>
      <DataTable
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={jobStatus}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        onSort={handleSort}
        sortServer
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalCount}
        paginationPerPage={perPage}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        onChangePage={page => setActivePage(page)}
        footer={footer}
    />
    </Card>
  )
}

export default CrossSubmissionsAnalyticPage