import React from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { Form } from 'react-bootstrap'

const Wrapper = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: rgb(0, 0, 0);
    margin-top: 15px;

    @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
        display: block !important;
    }
`

const Label = styled.label`
    color: #3a5182;
    cursor: pointer;
`

const Input = styled.input.attrs((props) => ({
    width: props.width || '400',
}))`
    background: #f5f7ff;
    border-color: #f5f7ff;
    border-radius: 2px;
    width: ${(props) => props.width};
    border-style: solid;
    padding: 10px 17px;

    &:focus {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        outline: 0;
    }
`
const Box = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ErrorMessage = styled.span`
    font-size: 12px;
    color: red;
    margin-top: 5px;
    min-height:19px;
    max-width: ${(props) => props.width || 400};
`
const Info = styled.span`
margin-left: 5px;
`

const TextInputV2 = ({ label, ...props }) => {
    const [field, meta] = useField(props)

    if (props.type === 'checkbox') {
        return (
            <Wrapper {...props}>
                <Box>
                    <input {...field} {...props} />
                    <Info htmlFor={props.id || props.name} style={props.style}>
                        {label}
                    </Info>
                </Box>
                <ErrorMessage style={props.style} width={props.width}>
                    {meta.touched && meta.error ? meta.error : ''}
                </ErrorMessage>
            </Wrapper>
        )
    } else if (props.id == 'phoneNumber') {
        return ( 
            <Wrapper>
                <Form.Control {...field} {...props} />
                {meta.touched ? (
                    <ErrorMessage style={props.style} width={props.width}>
                        {   
                            (props.isRequired) ? 
                                (props.value != '') ? (props.value.replace('(','').replace(')','').replace('-','').trim().length - 1) !== 10 && 
                                'Accepts only 10 digits' : 'Phone Number required'
                            : ( props.value != '') ? (props.value.replace('(','').replace(')','').replace('-','').trim().length - 1) !== 10 && 
                            'Accepts only 10 digits' : ''
                        }
                    </ErrorMessage>
                ) : null}
            </Wrapper>
        )
    }
    
    else {
        return (
            <Wrapper>
                <Form.Control {...field} {...props} />
                <ErrorMessage style={props.style} width={props.width}>
                    {meta.touched && meta.error ? meta.error : ''}
                </ErrorMessage>
            </Wrapper>
        )
    }
}

export default TextInputV2
