import React, { useState,useRef, useEffect } from 'react'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col,Form } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/Team'
import Select from 'react-select'
import Button from '../../Admin/shared/Button'

const UploadLeadAdd = ({jobs,onHide,method,fetchData}) => {
	const [validationError, setValidationError] = useState({})
	const [loadingModal, setLoadingModal] = useState(false)
    const [selectedJob, setSelectedJob] = useState(null)
    const [defaultSelectedJob, setDefaultSelectedJob] = useState({label: "",value: ""})
	const [btnDisabled, setBtnDisabled] = useState(false)
	const handleChange = (e) => {
		setValidationError({})
	}
	const [modal,setModal] = useState('')
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const [responseFile, setResponseFile] = useState('')
  	const responseFileRef = useRef()

	const responseFileChange = () => {
		if (responseFileRef.current.files.length > 0) {
			setResponseFile(responseFileRef.current.files)
		}
	}
	
	const colourStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px' })
    }

	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/admin/upload_candidate_leads'
			console.log(URL)
			const payload = new FormData()
			payload.append('lead_file', responseFile[0])
			payload.append('job_id', selectedJob)
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
						onHide()
						fetchData()
				}
			}) 
		}
		setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		if (responseFile?.length > 0) {
			const element = responseFile[0];
			// if (element?.size > 5000000) {
			// 	errors["file"] = "Attachment File should not be more than 5MB."
				
			// }
	
			let findName = element?.name?.split('.')
			console.log(findName)
			const splitName = findName[findName.length -1]
			if( (splitName !== 'csv')) {
				errors["file"] = "Please attach files in a valid format."
				formIsValid = false;
			}
		}else{
			errors["file"] = "Please select csv file."
			formIsValid = false;
		}

		let job_id =  selectedJob
        if(job_id == null){
			errors["job_id"] = "Please select job."
		}
		console.log(errors)
		console.log(job_id)
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	const changeJob =  (job) =>{
        setSelectedJob(job.value)
        setDefaultSelectedJob(job)
    }

	return (
		<>
			<Container> 
				<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
						<Col xs={12}>
							<Form.Group>
								<label className='mb-2'>Search Job :</label>
								<Select
									options={jobs}
									name="job_id"
									style={colourStyles}
									menuPosition='fixed'
									placeholder='Select Job Name'
									isLoading={loadingModal}
									onChange={(values) => changeJob(values) }
									value={defaultSelectedJob}
								/>
							</Form.Group>
							{/* {validationError && validationError.job_id && 
								<label className='error-validation'>
								{validationError && validationError.job_id} 
								</label>
							} */}
							<label className='error'>
									{validationError && validationError.job_id}        
							</label>
						</Col>
						<Col xs={12} className="mt-1">
							<Form.Group>
							<Col xs={13} >
								<span>Upload File (csv) </span>
								<input ref={responseFileRef} type='file' style={{ padding: '3px' }} onChange={responseFileChange} className='form-control w-100' name='sales_file' id="sales_file_url" />
							</Col>
							</Form.Group>
							<label className='error'>
									{validationError && validationError.file}        
							</label>
						</Col>
					</Row>
					<Row className="w-100 modal-entry-form-row" >
						<Col xs={12} sm={12} lg={12}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius mt-1'>Save</Button>
						</Col>
					</Row>
				</form>
				</Container>
		</>
  )
}

export default UploadLeadAdd