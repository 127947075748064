import React, { useState, useEffect } from 'react'
import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import './styles/FullCycleOutreach.scss'
import Select from 'react-select'
import styles from '../../Admin/styles/JobsStatusPage.module.scss'

import Util from '../../../../utils/util'
import Modal from 'react-bootstrap/Modal'
import AddOutreach from './AddOutreach'
import SearchBar from '../../../common/SearchBar/SearchBar'
import moment from 'moment'
import { capitalize } from '@material-ui/core'
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import axios from 'axios'

const FullCycleOutreachList = ({
    recruiters,
    org_list
    // recruiter_list,
    // team_lists,
    // team_lead_list,
}) => {
    const team_lists = []
    const team_lead_list = []
    const recruiter_list = []
    const queryParams = new URLSearchParams(window.location.search)
    var active_page = window.sessionStorage.getItem('teamListActivePage')
    var query = window.sessionStorage.getItem('query')
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    let jobStatus_start_date = sessionStorage.getItem("jobStatus_start_date");
    let jobStatus_end_date = sessionStorage.getItem("jobStatus_end_date")
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const jobId = queryParams.get("job")
    const [teams, setTeams] = useState([])
    const [totalTeams, setTotalTeams] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [teamUserId, setTeanUserId] = useState()
    const [show, setShow] = useState(false)
    const [showMember, setShowMember] = useState(false)
    const [method, setMethod] = useState('post')
    const [entry, setEntry] = useState({})
    const [loading, setLoading] = useState(true)
    const [data, setDate] = useState([])
    let recruiter_name_search = sessionStorage.getItem("recruiter_name_search")
    const [teamlist,setTeamList] = useState([{value: 'all', label: 'All'},...recruiters])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate : jobStatus_start_date ? jobStatus_start_date : moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : jobStatus_end_date ? jobStatus_end_date : moment().toDate()})
    const [selected,setSelected] = useState((jobId)? recruiters.filter((option) => option.value == jobId) : recruiter_name_search && recruiter_name_search != "null" ? JSON.parse(recruiter_name_search) : {value: 'all', label: 'All'})
    const [selectedRecruiter,setSelectedRecruiter] = useState(recruiter_name_search && recruiter_name_search != "null" ? JSON.parse(recruiter_name_search).value : jobId ? jobId : '')
    const [fetchApi, setFetchApi] = useState()
    const [fetchOrgAPi, setFetchOrgApi] = useState()
    const fetchData = () => {
        setLoading(true)
        const url = `/admin/full_cycle_outreach.json`
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("jobStatus_start_date", start_date)
        sessionStorage.setItem("jobStatus_end_date", end_date)
        window.sessionStorage.setItem('teamListActivePage', activePage)
        makeRequest(
            url,
            'get',
            {
                params: {
                    page: activePage + 1,
                    query: query,
                    search: searchTerm,
                    name: selectedRecruiter,
                    start_date: start_date,
                    end_date: end_date
                },
            },
            {
                contentType: 'application/json',
                loadingMessage: 'Fetching...',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                    setDate(res?.data?.teams, '====>')
                    setTeams(
                        res.data.teams.map((item) => ({
                            id: item.id,
                            name: capitalize(item?.user?.first_name + ' ' + item?.user?.last_name ),
                            organization_name: item?.organization?.name,
                            email_count: item?.email_count,
                            job: item?.job?.name,
                            inmail_count: item?.inmail_count,
                            submission_count: item?.submission_count,
                            date: item?.date
                                ? moment(item?.date).format(
                                      'MMM DD, YYYY'
                                  )
                                : '',
                            // job: item?.job,
                            // organization: item?.organization,
                            // user: item?.user
                        }))
                    )
                    setPageCount(res?.data?.total_pages)
                    setTotalTeams(res?.data?.total_count)
                    setCurrentCounts(res?.data?.current_counts)
                    setStartRecord(activePage * res?.data?.per_page)
                    setLoading(false)
                },
            }
        )
    }
    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage, selectedRecruiter, dateRange])

    const deleteTeam = async (id) => {
        const URL = `/admin/full_cycle_outreach/${id}`
        await makeRequest(URL, 'delete', '', {
            createResponseMessage: () => {
                return {
                    message: `Deleted successful`,
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: () => {
                fetchData()
            },
        })
    }

    const addTeam = () => {
        setEntry({})
        setMethod('post')
        setShow(true)
    }

    const onHide = () => {
        setEntry({})
        setMethod('post')
        setShow(false)
        setShowMember(false)
    }

    const onJobselect = async (event) =>  {
		const url = `/admin/full_cycle_outreach/filter_job_name`;
		const formData = new FormData();
		formData.append('filter_job', event);
		// setLoading(true);
		const response = await axios
			.post(url, formData)
			.then((res) =>setFetchApi(res?.data?.data))
			.catch((error) => console.log(error));
	}

    const onSelctOrganization = async(event) => {
        const url = `/admin/full_cycle_outreach/filter_org_name`;
        const formData = new FormData();
        formData.append('filter_org', event);
        // setLoading(true);
        const response = await axios
            .post(url, formData)
            .then((res) =>{
                setFetchOrgApi(res?.data?.data)
                sessionStorage.setItem("client", JSON.stringify(res?.data?.data))
            })
            .catch((error) => console.log(error));
        }

    const getUpdateData = (id) => {
        let getTeam = data.filter((row) => row.id == id)
        onJobselect(getTeam[0].organization.id)
        onSelctOrganization(getTeam[0].user.id)
        if (getTeam?.length > 0) {
            setMethod('put')
            setEntry(getTeam[0])
            setShow(true)
        }
    }
 
    const handleDate = (date) => {
        setDateRange(date)
    }
    return (
        <>
            <Card>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <P
                        size="28px"
                        height="38px"
                        color="#1D2447"
                        style={{ width: '540px' }}
                    >
                        Full Cycle Outreach
                    </P>
                    <div style={{ flexGrow: 1 }} />
                    <Button onClick={addTeam} className="ml-3">
                        Add Entry
                    </Button>
                </div>

                {/* <div
                    className="d-flex justify-content-between align-items-center w-100"
                    style={{ marginBottom: '10px', marginTop: '20px' }}
                > */}
                    {' '}
                    {/* <Col
                        xs={12}
                        sm={12}
                        lg={2}
                        className="p-0"
                        style={{ float: 'left' }}
                    >
                        {totalTeams > 0 && (
                            <DisplayPagination>
                                Displaying{' '}
                                {startRecord == 0 ? 1 : startRecord + 1} -{' '}
                                {currentCounts > totalTeams
                                    ? totalTeams
                                    : currentCounts}{' '}
                                of {totalTeams} Full Cycle Outreach
                            </DisplayPagination>
                        )}
                    </Col> */}
                    {/* <Col xs={12} sm={12} lg={4} style={{ float: 'left' }}>
                        <Select
                            options={team_lists}
                            name="job_name"
                            className={styles.colourStyles}
                        />
                    </Col> */}
                     <Row className="w-100" style={{ marginBottom: '10px' }}>
                    <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
                        <div>
                        <label>Recruiter Name</label>
                        <Select
                            defaultValue={selected}
                            options={teamlist}
                            onChange={(event)=>{
                                setSelectedRecruiter(event.value)
                                sessionStorage.setItem("recruiter_name_search", JSON.stringify(event))
                            }}
                              name="job_name"
                              className={styles.colourStyles}
                              />
                            </div>
                           
                                <div style={{display:'grid'}}>
                            <label>
                                Date
                            </label>

                            <DateRangePicker
                                handleOnSubmit={handleDate}
                                defaultDate={dateRange}
                            />
                        </div>
                
                    </Col>
                    <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalTeams > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalTeams}</DisplayPagination>
                    }
                </Col>
                    </Row>
                {/* </div> */}
                <Table
                    columNames={[
                        {
                            name: 'No.',
                            field: 'id',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Client',
                            field: 'organization_name',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Job',
                            field: 'job',
                            editable: true,
                            type: 'text',
                        },
                        {
                            name: 'Date',
                            field: 'date',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Offshore Recuiter',
                            field: 'name',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'InMails',
                            field: 'inmail_count',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Emails',
                            field: 'email_count',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Submissions',
                            field: 'submission_count',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Action',
                            field: 'options',
                            editable: false,
                            type: 'text',
                            nowrap: 'text-nowrap',
                            //   options:{CustomEventIcon:'users',CustomEventMethod:getMembersData}
                        },
                    ]}
                    rowValues={teams.map((team) => ({
                        ...team,
                        member: team?.member?.map((t) =>
                            t?.user?.id != team?.owner_id ? (
                                <span className="members">
                                    {' '}
                                    {Util.fullName(
                                        t?.user?.first_name,
                                        t?.user?.last_name
                                    )}{' '}
                                </span>
                            ) : (
                                ''
                            )
                        ),
                        status: Util.capitalize(team?.status),
                    }))}
                    showEditOption
                    activePage={activePage}
                    deleteAction={deleteTeam}
                    goToEditPage={(id) => {
                        setTeanUserId(id)
                        getUpdateData(id)
                    }}
                    loading={loading}
                />
                {pageCount > 1 && (
                    <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: 'auto' }}
                    >
                        <Paginator
                            activePage={activePage}
                            setActivePage={setActivePage}
                            pageCount={pageCount}
                            pageWindowSize={5}
                            showGoToField={false}
                        />
                    </div>
                )}
            </Card>

            <Modal
                onHide={onHide}
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                className="modal-entry-form"
            >
                <Modal.Header closeButton className="mt-2 mb-2">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {method === 'put'
                            ? 'Update'
                            : 'Full Cycle Outreach - Add Entry'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddOutreach
                        recruiter_list={recruiters}
                        org_list={org_list}
                        teamEntry={entry}
                        onHide={onHide}
                        method={method}
                        team_lists={team_lists}
                        fetchData={fetchData}
                        team_lead_list={team_lead_list}
                        teamUserId={teamUserId}
                        fetchApi={fetchApi}
                        fetchOrgAPi={fetchOrgAPi}
                        setFetchApi={setFetchApi}
                        setFetchOrgApi={setFetchOrgApi}
                    ></AddOutreach>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FullCycleOutreachList; 