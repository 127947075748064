
import styled from 'styled-components'

export const ApplyButton = styled.button`
background: linear-gradient(
    43deg,
    #a1e2ac -1.19%,
    #7fd3b5 53.94%,
    #57c2af 102.59%
);
border-radius: 20px;
padding: ${(props) => props.tb} ${(props) => props.lr};
width: fit-content;
display: flex;
align-items: center;
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
margin: 0px auto !important;
`
export const W5text = styled.span`
font-style: normal;
font-weight: 500;
font-size: ${(props) => props.size};
color: ${(props) => props.color};
display: flex;
`