import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner, Form } from 'react-bootstrap'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import Select from 'react-select'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const RecruiterDashboardPage = (prop) => {
    const queryParams = new URLSearchParams(window.location.search)
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    const localStorageDateRange = JSON.parse(sessionStorage.getItem('recruiteDashboardDateRange'))
    const startDate = (urlStartDate ? urlStartDate : (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate())
    const endDate = (urlEndDate ? urlEndDate : (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate())
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(prop.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [jobStatus, setJobStatus] = useState([])
    const [exportDatas, setExportDatas] = useState([])
    const csvExportRef = React.useRef();
    const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
    const handleDate = (date) => {
        sessionStorage.setItem('recruiteDashboardDateRange', JSON.stringify(date))
        setDateRange(date)
    }
     const [loading, setLoading] = useState(true)
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [type, setType] = useState('Regular')
    const [footer, setFooter] = useState({})

    const headers = [
        { label: 'Account Manager', key: 'accountManager' },
        { label: 'Hourly Submitted', key: 'hourly_submitted' },
        { label: 'Contingency Submitted', key: 'contingency_submitted' },
        { label: 'Hourly Interviews', key: 'interview_hourly' },
        { label: 'Contingency Interviews', key: 'interview_contingency' },
        { label: 'Hourly Offers', key: 'offers_hourly' },
        { label: 'Contingency Offers', key: 'offers_contingency' },
        { label: 'Hourly Hired', key: 'hired_hourly' },
        { label: 'Contingency Hired', key: 'hired_contingency' },
    ]
    const columns = [
        {
            name: 'No',
            selector: row => row.id
        },
        {
            name: 'Account Manager',
            selector: row => row.accountManager,
            sortable: false,
            sortField: 'recruiter_name',
            wrap:true
        },
        {
            name: 'Hourly Submitted',
            selector: row => row.hourly_submitted,
            sortable: true,
            sortField: 'submitted_hourly'
        },
        {
            name: 'Contingency Submitted',
            selector: row => row.contingency_submitted,
            sortable: true,
            sortField: 'submitted_contingency'
        },
        {
            name: 'Hourly Interviews',
            selector: row =>  row.interview_hourly,
            sortable: false,
            sortField: 'second_interview'
        },{
            name: 'Contingency Interviews',
            selector: row =>  row.interview_contingency,
            sortable: false,
            sortField: 'second_interview'
        },
        {
            name: 'Hourly Offers',
            selector: row => row.offers_hourly,
            sortable: true,
            sortField: 'offers_hourly'
        },
        {
            name: 'Contingency Offers',
            selector: row => row.offers_contingency,
            sortable: true,
            sortField: 'offer_contingency'
        },
        {
            name: 'Hourly Hired',
            selector: row =>   row.hired_hourly,
            sortable: true,
            sortField: 'hired_hourly'
        },
        {
            name: 'Contingency Hired',
            selector: row =>   row.hired_contingency,
            sortable: true,
            sortField: 'hired_contingency'
        }
    ]

    const fetchData = async () => {
        setLoading(true)
        const url = '/admin/recruiter_submission_dashboard.json'
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}&type=${type}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setJobStatus(response?.data?.org_status.map((data,key)=> (sr_no = sr_no + 1,{
            id: sr_no ,
            hourly_submitted: (data.submitted_hourly > 0) ? data.submitted_hourly: '',
            contingency_submitted: (data.submitted_contingency > 0) ? data.submitted_contingency: '',
            interview_hourly: ((data.first_interview_hourly + data.second_interview_hourly) > 0) ? data.first_interview_hourly + data.second_interview_hourly : '' ,
            interview_contingency: ((data.first_interview_contingency + data.second_interview_contingency) > 0) ? data.first_interview_contingency + data.second_interview_contingency : '' ,
            offers_hourly: (data.offer_hourly > 0) ? data.offer_hourly : '',
            offers_contingency: (data.offer_contingency > 0) ? data.offer_contingency : '',
            accountManager: data.recruiter_name,
            hired_hourly: (data.hired_hourly > 0) ? data.hired_hourly : '',
            hired_contingency: (data.hired_contingency > 0) ? data.hired_contingency : ''
        })))
        setFooter({id:'Total',
            hourly_submitted:response.data.total_values.submitted_hourly,
            contingency_submitted:response.data.total_values.submitted_contingency,
            interview_hourly:response.data.total_values.first_interview_hourly + response.data.total_values.second_interview_hourly,
            interview_contingency:response.data.total_values.first_interview_contingency + response.data.total_values.second_interview_contingency,
            offers_hourly:response.data.total_values.offers_hourly,
            offers_contingency:response.data.total_values.offers_contingency,
            hired_hourly:response.data.total_values.hired_hourly,
            hired_contingency:response.data.total_values.hired_contingency
        })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoading(false)
    }

    const exportDataMethod = async () => {
        const url = '/admin/recruiter_submission_dashboard.json'
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        await makeRequest(`${url}?start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}&type=${type}`,'get',{}, {
                onSuccess: (response) => {
                    setExportDatas(response?.data?.org_status.map((data,key)=> ({
                        id: key,
                        hourly_submitted: (data.submitted_hourly > 0) ? data.submitted_hourly: 0,
                        contingency_submitted: (data.submitted_contingency > 0) ? data.submitted_contingency: 0,
                        interview_hourly: ((data.first_interview_hourly + data.second_interview_hourly) > 0) ? data.first_interview_hourly + data.second_interview_hourly : 0 ,
                        interview_contingency: ((data.first_interview_contingency + data.second_interview_contingency) > 0) ? data.first_interview_contingency + data.second_interview_contingency : 0 ,
                        offers_hourly: (data.offer_hourly > 0) ? data.offer_hourly : 0,
                        offers_contingency: (data.offer_contingency > 0) ? data.offer_contingency : 0,
                        accountManager: data.recruiter_name,
                        hired_hourly: (data.hired_hourly > 0) ? data.hired_hourly : 0,
                        hired_contingency: (data.hired_contingency > 0) ? data.hired_contingency : 0
                    })))
                    csvExportRef?.current?.link.click();
                }
            }
        )
    }
    useEffect(()=>{
        fetchData()
    },[dateRange,activePage,sortDirection,sortField,type])

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }    
    }
    const handleRadioChange = (e) => {
        setType(e.target.value);
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Account Manager Dashboard
                </P>
                <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                    Export CSV
                </button>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas}
                headers={headers}
                filename={"am_dashboard.csv"}
                ref={csvExportRef}
            />
            <Row className="w-100" style={{ marginBottom: '10px' }}>
               <Col lg="8" md="8" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                            <label style={{cursor: 'pointer'}}>
                                <input
                                    type="radio"
                                    name="type"
                                    value="Regular"
                                    checked={type == 'Regular' ? true : false}
                                    onChange={(e) => handleRadioChange(e)}
                                    style={{marginTop: '40px', marginRight: '10px', cursor: 'pointer'}}
                                />   Regular 
                            </label>
                            <label style={{cursor: 'pointer'}}>
                                <input
                                    type="radio"
                                    name="type"
                                    value="Cumulative"
                                    checked={type == 'Cumulative' ? true : false}
                                    onChange={(e) => handleRadioChange(e)}
                                    style={{marginLeft: '20px', marginTop: '40px', marginRight: '10px', cursor: 'pointer'}}
                                />
                                  Cumulative
                            </label>
                </Col>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'grid'}}>
                            <label>
                            Select Date Range :
                            </label>

                            <DateRangePicker
                                handleOnSubmit={handleDate}
                                defaultDate={dateRange}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobStatus}
                progressPending={loading}
                progressComponent={ <P className="text-center  p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
    )
}

export default RecruiterDashboardPage