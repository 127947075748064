import React, { useState, useEffect } from 'react'
import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import './styles/Team.scss'
import Util from "../../../../utils/util";
import Modal from "react-bootstrap/Modal"
import TeamAdd from './TeamAdd'
import TeamMember from './TeamMember'
import SearchBar from '../../../common/SearchBar/SearchBar'

const TeamList = ({recruiters}) => {
  var active_page = window.sessionStorage.getItem('teamListActivePage')
  var query = window.sessionStorage.getItem('query')
  const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
  const [pageCount, setPageCount] = useState(0)
  const [teams, setTeams] = useState([])
  const [totalTeams,setTotalTeams] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [show,setShow] = useState(false)
  const [showMember,setShowMember] = useState(false)
  const [method,setMethod] = useState('post')
  const [entry,setEntry] = useState({})
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState(query || '')
  
  const fetchData = () => {
    setLoading(true)
    const url = `/agencies/teams.json`
    window.sessionStorage.setItem('teamListActivePage',activePage)
    makeRequest(url, 'get',  {params: {page: (activePage+1), query: query,search:searchTerm}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setTeams(
          res.data.teams.map((team) => ({
            id: team.id,
            owner_name: Util.fullName(team?.owner?.first_name,team?.owner?.last_name),
            team_name: team?.name,
            member: team?.members,
            status: team?.status,
            owner_id: team?.owner_id,
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalTeams(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setLoading(false)
      },
    })
  }
    useEffect(() => {
      fetchData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage])

    const deleteTeam = async (id) => {
      const URL = `/agencies/teams/${id}`
      await makeRequest(URL, 'delete', '', {
        createResponseMessage: () => {
          return {
            message: `Deleted successful`,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        },
        createErrorMessage: (e) => 'Failed',
        onSuccess: () => {      
            fetchData()
        },      
      })      
    }    

    const addTeam = () => {
      setEntry({})
      setMethod('post')
      setShow(true)
    }

    const onHide = () => {
      setEntry({})
      setMethod('post')
      setShow(false)
      setShowMember(false)
    }
    
    const getUpdateData = (id) => {
      let getTeam = teams.filter((row) => row.id == id)
      if(getTeam?.length > 0){
        setMethod('put')
        setEntry(getTeam[0])
        setShow(true)
      } 
    }
    
    const onChangeSearchTerm = (value) => {
      setSearchTerm(value)
      window.sessionStorage.setItem('user_query',value)
  }

    const getMembersData = (id) => {
      let getTeam = teams.filter((row) => row.id == id)
      if(getTeam?.length > 0){
        setEntry(getTeam[0])
        setShowMember(true)
      } 
    }

  return (
    <>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
        style={{ marginBottom: '42px' }}
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
          Team List
        </P>
        <div style={{flexGrow:1}}/>
        <Button onClick={addTeam} className="ml-3">
          Add New Team
        </Button>
      </div>

      <div className="d-flex justify-content-between align-items-center w-100"
        style={{ marginBottom: '10px' }}
        >
        <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'left'}}>
          {totalTeams > 0  &&
            <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts}  of  {totalTeams} employees
            </DisplayPagination>
        }
          </Col>
          <Col xs={12} sm={12} lg={4} style={{ float: 'left' }}>
                    <SearchBar
                        placeholder="Search Teams..."
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 0 ? fetchData() : setActivePage(0)
                            !searchTerm &&
                                window.sessionStorage.setItem('user_query', '')
                        }}
                        onClick={(e) => setSearchTerm('')}
                    />
                </Col>
      </div>
        <Table
          columNames={[
            {
              name: 'No.',
              field: 'id',
              editable: false,
              type: 'text',
            },
            {
              name: 'Team Name',
              field: 'team_name',
              editable: false,
              type: 'text',
            },
            {
              name: 'Team Lead',
              field: 'owner_name',
              editable: true,
              type: 'text',
            },
            {
              name: 'Members',
              field: 'member',
              editable: false,
              type: 'text',
            },
            {
              name: 'Action',
              field: 'options',
              editable: false,
              type: 'text',
              nowrap:'text-nowrap',
              options:{CustomEventIcon:'users',CustomEventMethod:getMembersData}
            },
          ]}
          rowValues={teams.map((team) => ({
              ...team,
              member: (team?.member?.map((t) => (t?.user?.id != team?.owner_id) ? <span className='members'> {Util.fullName(t?.user?.first_name,t?.user?.last_name)} </span> : '')),
              status: Util.capitalize(team?.status)
            }))}
          showEditOption
          activePage={activePage}
          deleteAction={deleteTeam}
          goToEditPage={(id) =>
            getUpdateData(id)
          }
          loading={loading}
      />
      {pageCount > 1 && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 'auto' }}
        >
          <Paginator
              activePage={activePage}
              setActivePage={setActivePage}
              pageCount={pageCount}
              pageWindowSize={5}
              showGoToField={false}
          />
        </div>
      )}
    </Card>

    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
              { method ==='put' ?'Update' : 'Add' } Team
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TeamAdd recruiters={recruiters} teamEntry={entry} onHide={onHide} method={method} fetchData={fetchData}></TeamAdd>
        </Modal.Body>
    </Modal>

    <Modal
        onHide={onHide}
        show={showMember}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
              Team Members
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TeamMember recruiters={recruiters} teamEntry={entry} onHide={onHide} fetchData={fetchData}></TeamMember>
        </Modal.Body>
    </Modal>

    </>
  )
}

export default TeamList