import styled from 'styled-components'

export const SwitchInput = styled.input`
  height: 0;
  width: 0;
  display: none;
`

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: ${({size})=>size.boxWidth};
  height: ${({size})=>size.boxHeight};
  border-radius: 100px;
  border: 2px solid rgb(76 104 255);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  position: relative;
  transition: background-color 0.2s;
  margin-bottom: 0px;
`

export const SwitchButton = styled.span`
  content: '';
  position: absolute;
  left: 2px;
  width: ${({size})=>size.buttonSize};
  height: ${({size})=>size.buttonSize};
  border-radius: 45px;
  transition: 0.2s;
  background: linear-gradient(
    94.67deg,
    #4c68ff -1.19%,
    #6077f4 53.94%,
    #8185ff 102.59%
  );
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  ${SwitchLabel}:active & {
    width: 40px;
  }
`
