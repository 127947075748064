import styled from 'styled-components'

export const BaseDivComponent = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction};
    flex-wrap: wrap;
    justify-content: ${({ jContent }) => (jContent ? jContent : 'unset')};
    align-items: ${({ aItems }) => (aItems ? aItems : 'unset')};
`

export const Box = styled(BaseDivComponent)`
  padding:${({padTB, padLR})=>`${padTB && padTB} ${padLR && padLR}`};

`
export const Row = styled(BaseDivComponent)`
    width: 100%;
    margin-bottom: ${({ gutterButtom }) => gutterButtom && '10px'};
    margin-top: ${({ gutterTop }) => gutterTop && '10px'};
    margin: ${({ gutter }) => gutter && '10px 0px'};
`
