import React, { useState, useEffect } from 'react'
import Card from './shared/Card'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import P from './shared/P'
import SearchBar from '../../common/SearchBar/SearchBar'
import moment from 'moment'
import {firstCharacterCapital} from '../../../utils/index'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import { Row,Col,Spinner,Modal } from 'react-bootstrap'
import feather from 'feather-icons'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import   './styles/AutoCandidateSubmission.scss'
import Select from 'react-select'
import Button from '../Admin/shared/Button'


const SuccessAgreementCandidate = () => {
    
  
    const [activePage, setActivePage] = useState(parseInt(1))
    const [totalUsers, setTotalusers] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [users, setUsers] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(true)
    const [sortField, setSortField] = useState('users.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(25)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [type, setType] =  useState('')
    const [selectedStatus,setSelectedStatus] = useState({value: '', label: 'All'})
    
    const [loadingMessage, setLoadingMessage] = useState(false)
    const [submissionId, setSubmissionId] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [errorMessageValidation, setErrorMessageValidation] = useState('')
    const [messageValue, setMessageValue] = useState('')
    const [historyShow, setHistoryShow] = useState(false)
    const [messages, setMessages] = useState([])
    

    const statusOptions = [
        {value: '', label: 'All'},
        { value: 'success', label: 'Interested' },
        { value: 'fail', label: 'Not Interested' },
    ]

~    useEffect(() => {
        feather.replace()
    })

    const fetchData = async () => {
        setLoading(true)
        const url = `/admin/auto_candidate_report.json`
        const response = await makeRequest(
            `${url}?page=${activePage}&search=${searchTerm}&type=${type}`,
            'get',
            {}
        )
        setTotalusers(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setUsers(
            response.data.data.map((user,index) => (sr_no = sr_no + 1,{
                sr_no: sr_no,
                id: user.id,
                name: firstCharacterCapital(user.person.first_name) + ' ' + firstCharacterCapital(user.person.last_name),
                email: user.email+ ' | ' +user.phone_number,
                phone_number: user.phone_number,
                initial_msg: (user.initial_msg != null) ?  moment(user.initial_msg).format('MMM DD, YYYY  hh:mm A') : '',
                created_at: (user.created_at != null) ?  moment(user.created_at).format('MMM DD, YYYY  hh:mm A') : '',
                send_msg1: (user.send_msg1 != null) ?  moment(user.send_msg1).format('MMM DD, YYYY  hh:mm A') : '',
                send_msg2: (user.send_msg2 != null && user.send_msg2 != 'null') ?  moment(user.send_msg2).format('MMM DD, YYYY  hh:mm A') : '',
                sign_agreement: (user.sign_agreement != null) ?  moment(user.sign_agreement).format('MMM DD, YYYY  hh:mm A') : '',
                send_agreement: (user.send_agreement != null) ?  moment(user.send_agreement).format('MMM DD, YYYY  hh:mm A') : '',
                actions: (<div className='w-100 d-inline-flex justify-content-around'>
                            <a href={user.person.cv_url} target="_blank" className='pl-1 pr-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                    <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                    <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                </svg>
                            </a>
                            <a style={{color: '#007bff'}} onClick={() => handleShowChat(user.id)}  href={`javascript::void()`} title='View Messages' className='pl-1'><i data-feather="message-circle" /></a>
                            <a href={`javascript::void()`} title='Completed' className='pl-1'> { (user.sign_agreement != null && user.processed != null) ? <i data-feather="thumbs-up" style={{color:'green'}} /> : <i data-feather="thumbs-down" style={{color:'red'}}/> } </a>
                        </div>)
            }))
        )
       
       
        setCurrentCounts(response.data.current_counts)
        setStartRecord((activePage - 1) * response.data.per_page)
        setLoading(false)
    }

    const getMessages = async(id) => {
        setErrorMessageValidation('')
        if(id != ''){
            setSubmissionId(id)
            setLoadingMessage(true)
            const url = `/admin/get_messages?auto_submission_id=${id}`
                try {
                    const  data  = await makeRequest(url, 'get', {}).then((res)=>{
                        if(res.data.type == 'success'){ 
                            setMessages(res.data.data) 
                        }
                        setLoadingMessage(false)
                    })
                    
                } catch (error) {
                    console.log(error)
                }
        }
    }

    const handleMessagesModal = () => {
        setSubmissionId('')
        setMessageValue('')
        setMessages([])
        setHistoryShow(false)
    }

    const handleShowChat = (id) => {
        getMessages(id)
        setHistoryShow(true)
    }

    const onMessageChange = (val) => {
        setMessageValue(val)
    }

    const onSendMessage = async() => {
        setErrorMessageValidation('')
        if(messageValue != '' && submissionId != ''){
            const url = `/admin/send_manual_msg`
                try {
                    const  data  = await makeRequest(url, 'post', {msg: messageValue, candidate_auto_submission_id: submissionId}, {
                        contentType: 'application/json',
                        loadingMessage: 'Applying...',
                        createResponseMessage: (response) => {
                            return {
                                message:response.message,
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        createErrorMessage:(error)=>error.response.data.error
                    }).then((res)=>{
                        if(res.data.type == 'success'){
                                setMessageValue('')
                                getMessages(submissionId)
                        }
                    })
                    
                } catch (error) {
                    console.log(error)
                }
        }else{
            setErrorMessageValidation('Message is reuired field')
        }
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#F6F7FC',marginLeft:'10px',border: 'none',height:'40px',width:'250px' })
    };

    useEffect(() => {
        fetchData(),
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,type,sortDirection,sortField])


    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const handleSelectStatus = (selectedval) =>{
        setActivePage(1)
        setType(selectedval.value)
    }

    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '4%',
            minWidth: '4%',
        },
        {
            name: 'Candidate Name',
            selector: row => row.name,
            cell: (row) => <div title={row.name}>{row.name}</div>,
            maxWidth: '10%',
            minWidth: '10%',
            wrap:true,
        },
        {
            name: 'Candidate Email | Phone',
            selector: row => row.email,
            cell: (row) => <div title={row.email}>{row.email}</div>,
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true,
        },
        {
            name: 'Initial Message',
            selector: row => row.initial_msg,
            cell: (row) => <div title={row.initial_msg}>{row.initial_msg}</div>,
            maxWidth: '10%',
            minWidth: '10%',
            wrap:true,
        },
        {
            name: 'Send 10% Fee',
            selector: row => row.send_msg1,
            cell: (row) => <div title={row.send_msg1}>{row.send_msg1}</div>,
            maxWidth: '10%',
            minWidth: '10%',
            wrap:true,
        },
        {
            name: 'Send 5% Fee',
            selector: row => row.send_msg2,
            cell: (row) => <div title={row.send_msg2}>{row.send_msg2}</div>,
            maxWidth: '10%',
            minWidth: '10%',
        },
        {
            name: 'Send Agreement',
            selector: row => row.send_agreement,
            cell: (row) => <div title={row.send_agreement}>{row.send_agreement}</div>,
            maxWidth: '12%',
            minWidth: '12%',
            wrap:true,
        },
        {
            name: 'Sign Agreement',
            selector: row => row.sign_agreement,
            cell: (row) => <div title={row.sign_agreement}>{row.sign_agreement}</div>,
            maxWidth: '10%',
            minWidth: '10%',
        },
        {
            name: 'Action',
            selector: row => row.actions,
            maxWidth: '14%',
            minWidth: '14%',
        }
    ]
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Candidates Interested/Not Interested For Fee Agreement
                </P>
            </div>
            {/* <div
                className="justify-content-between align-items-center w-100"
                style={{ marginBottom: '10px' }}
            > */}
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    {totalUsers > 0 &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalUsers ? totalUsers : currentCounts } of {totalUsers}</DisplayPagination>
                    }
                </Col>
                <Col xs={12} sm={12} lg={2} className="filter_ui_fix" style={{float:'right', justifyContent:'flex-end'}}>
                    <Select
                        defaultValue={selectedStatus}
                        options={statusOptions}
                        onChange={handleSelectStatus} 
                        placeholder={'Select Status'} 
                        styles={colourStyles}
                    />
                </Col>
                <Col xs={12} sm={12} lg={5} style={{float:'left'}}>
                    <SearchBar
                        placeholder="Search candidate..."
                        value={searchTerm}
                        setValue={setSearchTerm} 
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 1 ? fetchData() : setActivePage(1)
                            !searchTerm
                        }}
                        onClick={(e) => setSearchTerm('')}
                    />
                </Col>
            </Row>
            {/* </div> */}
            <Row className="w-100" style={{ marginBottom: '10px' }}>
               <Col  lg="12" md="12" sm="12" xs="12">
                    <DataTable
                        title={false}
                        persistTableHead={true}
                        noDataComponent={<p className="text-center p-3">No record found</p>}
                        columns={columns}
                        data={users}
                        progressPending={loading}
                        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                            <Spinner className='spinner' animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </P>}
                        onSort={handleSort}
                        sortServer
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationServerOptions={{persistSelectedOnSort: true, persistSelectedOnPageChange: true}}
                        paginationTotalRows={totalUsers}
                        paginationPerPage={perPage}
                        paginationComponentOptions={{
                            noRowsPerPage: true
                        }}
                        responsive={true}
                        onChangePage={page => setActivePage(page)}
                        clearSelectedRows={toggledClearRows}
                    />
                </Col>
            </Row>

            <Modal
                show={historyShow}
                size="lg"
                dismissible
                onHide={handleMessagesModal}
                className="ats-border"
                backdrop="static"
                centered
                scrollable
                keyboard={true}
                onEscapeKeyDown={handleMessagesModal}
                >
                    <Modal.Header style={{minHeight: 'min-height: 92px'}} closeButton><Modal.Title>Candidate Messages History</Modal.Title></Modal.Header>
                    <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                
                                { messages && messages?.length > 0 && 
                                    <>
                                        <div >
                                        {   messages.map((msg) => {
                                                return (
                                                    <>
                                                        {msg.msg_type == 'send' &&
                                                            <div className="message-body mr-33">
                                                                <p>{msg.msg}</p>
                                                                <span className="time-right">{moment(msg.created_at).format('llll')}</span>
                                                            </div>
                                                        }

                                                        {msg.msg_type != 'send' &&
                                                            <div className="message-body darker ml-33">
                                                                <p>{msg.msg}</p>
                                                                <span className="time-right">{moment(msg.created_at).format('llll')}</span>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                        </div>
                                    
                                    </>
                                }
                                { loadingMessage == true && messages?.length == 0 &&
                                    <div className='loader-msg'>
                                        <Spinner className='spinner' animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='footer-custom container pr-3 pl-3'>
                        <Row className="mb-2">
                            <span>Message :</span>
                        </Row>
                        <Row className="mb-2">
                            <textarea
                                className="flex-grow-1"
                                placeholder='Enter message here'
                                rows="3"
                                name="msg"
                                onChange={(e)=> onMessageChange(e.target.value)}
                                value={messageValue}
                            ></textarea>
                        </Row>
                        {errorMessageValidation && errorMessageValidation != '' &&
                            <Row className="">
                                <span style={{color:'red'}}>{errorMessageValidation}</span>
                            </Row>
                        }
                    </div>
                    <Button variant="primary" onClick={ () => onSendMessage() }>
                        <i data-feather="send" /> <b>Send</b> 
                    </Button>          
                </Modal.Footer>
            </Modal>

        </Card>
    )
}

export default SuccessAgreementCandidate