import axios from 'axios';
import React, { useEffect, useState } from 'react';
import "./requestView.scss";
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import { Button } from '../SignupPage/styles/Signup.styled';
import { Col, Row } from 'react-bootstrap';

const OptOut = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [varifycode, setVarifyCode] = useState('');
    const [selectResidance, setSelectResidance] = useState('')
    const [id, setId] = useState()
    const [phone, setPhone] = useState('')
    const [linkednUrl, setLinkednUrl] = useState('')
    const [showData, setShowData] = useState(false)
    const [data, setData] = useState(false)
    const [errorMessage, setErrorMessage] = useState({})
    const [successMesssage, setSuccessMessage] = useState('')
    const [alertMessage, setAlertMessage] = useState(false)
    const [resSendCode, setResendCode] = useState(false)
    const [updateMessage, setUpdateMessage] = useState(false)
    const [codeCheck, setCodeCheck] = useState(true)
    const [updatText, setUpdateText] = useState('')
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    const [states, setStates] = useState([])

    useEffect(() => {
        fetchState()
    }, [])

    const fetchState = async () => {
        const lookupsUrl = '/signup/lookups'
        axios.get(lookupsUrl).then((res) => {
            setStates([...res.data.states])
        })
    }

    const validateData = (type) => {
        setErrorMessage({})
        let errors = {}
        let formIsValid = true
        if ((firstName.trim()).length == 0) {
            errors['firstName'] = 'First Name is Required';
            formIsValid = false
        }
        if ((lastName.trim()).length == 0) {
            errors['lastName'] = 'Last Name is Required';
            formIsValid = false
        }
        if ((selectResidance).length == 0 && type == 'add') {
            errors['current_place_of_residence'] = 'Residance is Required';
            formIsValid = false
        }
        if ((email.trim()).length == 0) {
            errors['email_address'] = 'Email is Required';
            formIsValid = false
        }
        var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!(mailFormat.test(email))) {
            errors['email_address'] = 'Required valid email';
            formIsValid = false
        }
        if ((phone.trim()).length == 0 && type == 'edit') {
            errors['phone'] = 'Phone Number is Required';
            formIsValid = false
        }
        const phoneregex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        if (!(phoneregex.test(phone)) && type == 'edit') {
            errors['phone'] = 'Required valid Phone Number';
            formIsValid = false
        }
        if ((linkednUrl.trim()).length == 0 && type == 'edit') {
            errors['linkednUrl'] = 'linkedn Url is Required';
            formIsValid = false
        }
        const linkednRegex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
        if (!(linkednRegex.test(linkednUrl)) && type == 'edit') {
            errors['linkednUrl'] = 'Required valid Linkedn Url';
            formIsValid = false
        }
        setErrorMessage(errors)
        return formIsValid
    }

    const getCodeData = () => {
        if (validateData('add')) {
            setResendCode(true)
            const payload = new FormData()
            payload.append('opt_out_info[first_name]', firstName)
            payload.append('opt_out_info[last_name]', lastName)
            payload.append('opt_out_info[email_address]', email)
            payload.append('opt_out_info[current_place_of_residence]', selectResidance)
            makeRequest('/opt_out_info', 'post', payload, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
                createResponseMessage: (res) => {
                    setCodeCheck((res?.success == 'true') ? true : false)
                    return {
                        message: res.message,
                        messageType: res.messageType
                    }
                }
            })
        }
    }

    const submitData = () => {
        const payload = new FormData()
        payload.append('email_address', email?.trim())
        payload.append('verification_code', varifycode)
        axios
            .post('opt_out_info/show_detail', payload)
            .then((res) => {
                if (res?.data) {
                    setShowData(true)
                }
                setId(res?.data?.data?.id)
                setData(res?.data?.data)
                setFirstName(res?.data?.data?.first_name || '')
                setLastName(res?.data?.data?.last_name || '')
                setEmail(res?.data?.data?.email_address || '')
                setPhone(res?.data?.data?.phone_number || '')
                setLinkednUrl(res?.data?.data?.linkedin_profile_url || '')
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const updateData = () => {
        if (validateData('edit')) {
            const payload = new FormData()
            payload.append('opt_out_info[first_name]', firstName || data?.first_name)
            payload.append('opt_out_info[last_name]', lastName || data?.last_name)
            payload.append('opt_out_info[email_address]', email || data?.email_address)
            payload.append('opt_out_info[phone_number]', phone || data?.phone_number)
            payload.append('opt_out_info[linkedin_profile_url]', linkednUrl || data?.linkedin_profile_url)
            makeRequest(`opt_out_info/${id}`, 'put', payload, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
                createResponseMessage: (res) => {
                    setCodeCheck((res?.success == 'true') ? true : false)
                    return {
                        message: res.message,
                        messageType: res.messageType
                    }
                },
                onSuccess: (responce) => {
                    window.location.href = '/optout'
                }
            })
        }
    }

    const VerifyEmailData = () => {
        const payload = new FormData()
        payload.append('opt_out_info[verification_code]', varifycode)
        makeRequest(`opt_out_info/varify_code`, 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (res) => {
                if(res['messageType'] == 'success'){
                    setAlertMessage(res['success'])
                }
                return {
                    message: res.message,
                    messageType: res.messageType
                }
            }
        })
    }

    const RemoveData = () => {
        setAlertMessage(false)
        const payload = new FormData()
        payload.append('opt_out_info[email_address]', email)
        makeRequest(`opt_out_info/add_email`, 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (res) => {
                if(res['messageType'] == 'success'){
                    setSuccessMessage(res['success'])
                }
                return {
                    message: res.message,
                    messageType: res.messageType
                }
            }
        })
    }

    return (
        <div className='opt-container'>
            {!showData &&
                <div className='mt-l d-flex align-items-center justify-content-center opt-middle-container'>
                    <div className='custom-container form-container'>
                        <div className='mt-3'>
                            <div>
                                {pageName === 'view' &&
                                    <div className='mb-2'>
                                        <h3 className='optout-title' >Request to view</h3>
                                        <div>
                                            This form allows you to request to
                                            <b> view your personal information </b>
                                            from our service under the applicable data privacy laws. Please enter your information below.
                                        </div>
                                    </div>}

                                {pageName === 'edit' &&
                                    <div className='mb-2'>
                                        <h3 className='optout-title' >Request to correct</h3>
                                        <div>
                                            This form allows you to request to
                                            <b>  correct your personal information </b>
                                            from our service under the applicable data privacy laws. Please enter your information below.
                                        </div>
                                    </div>}

                                {pageName === 'remove' &&
                                    <div className='mb-2'>
                                        <h3 className='optout-title' >Request to remove</h3>
                                        <div>
                                            This form allows you to
                                            <b>  remove your personal information </b>
                                            from our service, and opt-out of the processing or sale of your personal information.
                                        </div>
                                    </div>}
                                <hr></hr>
                                <div>
                                    <Row>
                                        <Col lg='6' md='6' sm='12' xs='12'>
                                            <div>
                                                <label>First name*</label>
                                                <input
                                                    className="form-control form-control-xs"
                                                    id="first_name"
                                                    type="text"
                                                    placeholder="Enter Your First Name"
                                                    data-validation-required-message="Please Enter First Name."
                                                    onChange={(e) => {
                                                        setFirstName(e?.target?.value)
                                                        setErrorMessage({})
                                                    }}
                                                />
                                                <small className='error-msg'>  {errorMessage && errorMessage.firstName} </small>
                                            </div>
                                        </Col>
                                        <Col lg='6' md='6' sm='12' xs='12'>
                                            <div>
                                                <label>Last name*</label>
                                                <input
                                                    className="form-control form-control-xs"
                                                    id="last_name"
                                                    type="text"
                                                    placeholder="Enter Your Last Name"
                                                    data-validation-required-message="Please Enter Last Name."
                                                    onChange={(e) => {
                                                        setLastName(e?.target?.value)
                                                        setErrorMessage({})
                                                    }}
                                                />
                                                <small className='error-msg'>  {errorMessage && errorMessage.lastName} </small>
                                            </div>
                                        </Col>

                                        <Col lg='12' md='12' sm='12' xs='12' className='mb-l option-select-text'>
                                            <label>Current place of residence* </label>
                                            <select className="form-control"
                                                onChange={(e) => {
                                                    console.log(e?.target?.value)
                                                    setSelectResidance(e?.target?.value)
                                                    setErrorMessage({})
                                                }}
                                            >
                                                <option value='' >Select Current Residence</option>
                                                {states.map(
                                                    ({ key, value }) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={value}
                                                            >
                                                                {value}
                                                            </option>
                                                        )
                                                    }
                                                )}
                                            </select>
                                            <small className='error-msg'>  {errorMessage && errorMessage.current_place_of_residence} </small>
                                        </Col>

                                        <Col lg='6' md='6' sm='12' xs='12'>
                                            <label>Email*</label>
                                            <input
                                                className="form-control form-control-xs"
                                                name="email"
                                                type="email"
                                                placeholder="username@domian.com"
                                                data-validation-required-message="Please Enter Email."
                                                onChange={
                                                    (e) => {
                                                        if (e?.target?.value)
                                                            setEmail(e?.target?.value)
                                                        setErrorMessage({})
                                                    }
                                                }
                                            />
                                            <small className='error-msg'>  {errorMessage && errorMessage.email_address} </small>
                                        </Col>
                                        <Col lg='6' md='6' sm='12' xs='12'>
                                            <div style={{ paddingTop: '30px' }}>
                                                <label></label>
                                                <Button
                                                    onClick={getCodeData}
                                                    type="submit"
                                                    className="button-get-code"
                                                >
                                                    Get Code
                                                </Button>
                                            </div>
                                        </Col>

                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            <div className='mt-1'>
                                                {resSendCode &&
                                                    <div className='small-desc'>
                                                        Please check your email to get the verfitication code. Didn’t receive a code?<span
                                                            onClick={getCodeData}
                                                            className='reset-btn'> Resend</span>
                                                    </div>
                                                }
                                            </div>
                                            <div />
                                        </Col>

                                        <hr></hr>

                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            <div className='varifycode-inpubox'>
                                                <label>Verification code*</label>
                                                <input
                                                    className="form-control form-control-xs varify-input"
                                                    id="name"
                                                    type="number"
                                                    disabled={codeCheck}
                                                    placeholder="Enter Verification Code"
                                                    data-validation-required-message="Please enter your name."
                                                    onChange={(e) => setVarifyCode(e?.target?.value)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                            <div>
                                <div className='mt-s row'>
                                    <div className='col-8 mt-2'>
                                        <a href='/optout' className='form-cancel-btn'>
                                            Cancel
                                        </a>
                                    </div>
                                    {pageName === 'view' &&
                                        <div className='col-4'>
                                            <div className='text-end'>
                                                <Button className='form-submit-btn'
                                                    onClick={submitData}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>}
                                    {pageName === 'edit' &&
                                        <div className='col-4'>
                                            <div className='text-end'>
                                                <Button className='form-submit-btn'
                                                    onClick={submitData}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>}
                                    {pageName === 'remove' &&
                                        <div className='col-4'>
                                            <div className='text-end'>
                                                <Button className='form-submit-btn'
                                                    onClick={VerifyEmailData}
                                                >
                                                    Submit
                                                </Button>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {showData &&
                <div className='mt-l d-flex align-items-center justify-content-center'>
                    <div className='custom-container form-container request-view-from'>
                        <div className='mt-3'>
                            <div>
                                <div className='mb-2'>
                                    {pageName === 'view' ? <h3 className='optout-title' >Request to view</h3> :
                                        <h3 className='optout-title' >Request to Edit</h3>}
                                </div>
                                <div>
                                    <Row>
                                        <Col lg='6' md='6' sm='12' xs='12'>
                                            <div>
                                                <label>First name*</label>
                                                {pageName == 'view' ?
                                                    <p>{data?.first_name || '-'}</p>
                                                    : <input
                                                        className="form-control form-control-xs"
                                                        id="first_name"
                                                        type="text"
                                                        disabled={pageName == 'view'}
                                                        defaultValue={data?.first_name}
                                                        onChange={(e) => {
                                                            setFirstName(e?.target?.value)
                                                            setErrorMessage({})
                                                        }
                                                        }
                                                    />}
                                                <small className='error-msg'>  {errorMessage && errorMessage.firstName} </small>
                                            </div>
                                        </Col>
                                        <Col lg='6' md='6' sm='12' xs='12'>
                                            <div>
                                                <label>Last name*</label>
                                                {pageName == 'view' ?
                                                    <p>{data?.last_name || '-'}</p>
                                                    : <input
                                                        className="form-control form-control-xs"
                                                        id="last_name"
                                                        type="text"
                                                        disabled={pageName == 'view'}
                                                        defaultValue={data?.last_name}
                                                        onChange={(e) => {
                                                            setLastName(e?.target?.value)
                                                            setErrorMessage({})
                                                        }
                                                        }
                                                    />}
                                                <small className='error-msg'>{errorMessage && errorMessage.lastName} </small>
                                            </div>
                                        </Col>

                                        <Col lg='6' md='6' sm='12' xs='12'>
                                            <div>
                                                <label>Email</label>
                                                {pageName == 'view' ?
                                                    <p>{data?.email_address || '-'}</p>
                                                    : <input
                                                        className="form-control form-control-xs"
                                                        id="email_address"
                                                        type="email"
                                                        disabled={pageName == 'view'}
                                                        defaultValue={data?.email_address}
                                                        onChange={(e) => {
                                                            setEmail(e?.target?.value)
                                                            setErrorMessage({})
                                                        }
                                                        }
                                                    />
                                                }
                                                <small className='error-msg'>{errorMessage && errorMessage.email_address} </small>
                                            </div>
                                        </Col>
                                        <Col lg='6' md='6' sm='12' xs='12'>
                                            <div>
                                                <label>Phone Number</label>
                                                {pageName == 'view' ?
                                                    <p>{data?.phone_number || '-'}</p>
                                                    : <input
                                                        className="form-control form-control-xs"
                                                        id="phone_number"
                                                        type="text"
                                                        disabled={pageName == 'view'}
                                                        defaultValue={data?.phone_number}
                                                        onChange={(e) => {
                                                            setPhone(e?.target?.value)
                                                            setErrorMessage({})
                                                        }}
                                                    />
                                                }
                                                <small className='error-msg'>{errorMessage && errorMessage.phone} </small>
                                            </div>
                                        </Col>
                                        <Col lg='12' md='12' sm='12' xs='12'>
                                            <div>
                                                <label>Linkedn Url</label>
                                                {pageName == 'view' ?
                                                    <p>{data?.linkedin_profile_url || '-'}</p>
                                                    :
                                                    <input
                                                        className="form-control form-control-xs"
                                                        id="linkedin_profile_url"
                                                        type="text"
                                                        disabled={pageName == 'view'}
                                                        defaultValue={data?.linkedin_profile_url}
                                                        onChange={(e) => {
                                                            setLinkednUrl(e?.target?.value)
                                                            setErrorMessage({})
                                                        }}
                                                    />
                                                }
                                                <small className='error-msg'>{errorMessage && errorMessage.linkednUrl} </small>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className=''>
                                        {pageName === 'view' ?
                                            <div className='d-inline-block mb-l optout-input mt-2'>
                                                <div className='text-end'>
                                                    <a className='request-view-cancel-btn'
                                                        href='/optout'
                                                    >
                                                        Cancel
                                                    </a>
                                                </div>
                                            </div>
                                            :
                                            <div className='d-inline-block mt-2 optout-input'>
                                                <div className='text-end'>
                                                    <Button className='form-submit-btn'
                                                        onClick={updateData}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                                {updateMessage &&
                                                    <SweetAlert
                                                        success
                                                        title={updatText}
                                                        onConfirm={() => setUpdateMessage(false)}
                                                        focusCancelBtn
                                                        cancelBtnStyle={{ textDecoration: 'none' }}
                                                    />}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {alertMessage &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Submit"
                    confirmBtnBsStyle="primary"
                    title=''
                    // customIcon={WarnIcon}
                    onConfirm={RemoveData}
                    onCancel={() => setAlertMessage(false)}
                >
                    <b>Request to remove and opt-out of the sale of my personal information</b>
                    <p>By click “Submit”, your personal information will be deleted from our service, and this action cannot be undone.</p>
                </SweetAlert>
            }
            {successMesssage &&
                <SweetAlert
                    success
                    title={'Successfully Done'}
                    onConfirm={() => window.location.href = '/optout'}
                    focusCancelBtn
                    cancelBtnStyle={{ textDecoration: 'none' }}
                />
            }
        </div>
    )
}
export default OptOut;