import React, { Fragment, Error, useRef, useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'
import _ from 'lodash'
import CloseButton from '../Styled components/CloseButton'
import { Box, Row, Button, W4text } from './styles/JobModal.styled'
import feather from 'feather-icons'
import { makeRequest } from '../RequestAssist/RequestAssist'
import { CKEditor } from 'ckeditor4-react';
const initialState = {
  noteFiles: '',
  addNotes: ''
}

function JobNotes({ onClose, handleSubmit, job, allowUpdate }) {
  const responsefileTypes = [
    'text/docx',
    'text/pdf',
    'text/audio',
  ]
  const [newJob, setNewJob] = useState(initialState)
  const [validated, setValidated] = useState(false);
  const [allowToUpdate, setallowUpdate] = useState(!allowUpdate)
  const [validationError, setValidationError] = useState({
    noteFiles: null,
    addNotes: null
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [responseFile, setResponseFile] = useState('')
  const responseFileRef = useRef()
  const [notesAdd, setNotesAdd] = useState(job?.add_notes)

  useEffect(() => {
    feather.replace()
  })

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setNewJob({
      ...newJob,
      [name]: value
    });
    setValidationError({ ...validationError, [name]: '' });
  }

  const handleEditorChange = (content) => {
    setNotesAdd(content.editor.getData())
    setValidationError({})
}

  const checkValidation = () => {
    setValidationError({})
    let isValid = true
    if(_.isEmpty(notesAdd?.trim())){
      setValidationError({
        ...validationError, addNotes: 'Add Note is required',
      });
      return false;
    }
    
    if (responseFile?.length > 0) {
      for (let index = 0; index < responseFile.length; index++) {
        const element = responseFile[index];
        if (element?.size > 5000000) {
          setValidationError({
            ...validationError, noteFiles: "Attachment File should not be more than 5MB.",
          });
          isValid = false
          return
        }
        let findName = element?.name?.split('.')
        const splitName = findName[findName.length -1]
        if( (splitName !== 'docx') && (splitName !== 'pdf') && (splitName !== 'mp3') && (splitName !== 'mp4') ) {
          setValidationError({
          ...validationError, noteFiles: "Please attach files in a valid format.",
          });
          isValid = false
          return
        }
        // if (element?.type.includes("application/vnd.ms-excel")|| element?.type.includes("application/x-xz") || element?.type.includes("sheet") || element?.type.includes("application/x-7z-compressed") || element?.type.includes("application/zip") || element?.type.includes("csv") || element?.type.includes("image") || responsefileTypes.includes(element?.type)) {
        //   setValidationError({
        //     ...validationError, noteFiles: "Please attach files in a valid format.",
        //   });
        //   isValid = false
        //   return
        // }
      }
    }
    return isValid
  };

  useEffect(() => {
    setNewJob({
      ...newJob,
      addNotes: job?.add_notes,
      noteFiles: [...job?.upload_files]
    });
  }, [job]);

  const responseFileChange = () => {
    if (responseFileRef.current.files.length > 0) {
      setResponseFile(responseFileRef.current.files)
    }
  }

  const handleSubmitResponse = (e) => {
    if (checkValidation()) {
      const jobData = new FormData()
      jobData.append('job[add_notes]', notesAdd || '')
      for (let i = 0; i < responseFile.length; i++) {
        jobData.append(`job[notes_file][]`, responseFile[i])
      }
      jobData.append('job[flag]', 'Updated notes to a Job')
      handleSubmit(jobData)
    }
  }
  const removeAttachment = (id) => {
    const url = `/jobs/${id}/note_file`
    makeRequest(url, 'delete', {}, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        if (res.data.type == "success") {
          setNewJob({
            ...newJob,
            noteFiles: [...newJob.noteFiles].filter((file) => file.id != id)
          });
        }
      },
    })
  }

  return (
    <Fragment>
      <Row direction="row" aItems="center">
        <Box>
          <W4text color="#16246B" size="24px">
            Notes
          </W4text>
        </Box>
        <div style={{ flexGrow: '1' }} />
        <Box>
          <CloseButton handleClick={() => onClose()} />
        </Box>
      </Row>
      <Form noValidate validated={validated}>
        <Form.Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>
                Add Note<span className='text-danger'>*</span>
              </Form.Label>
              {/* <Form.Control
                as="textarea"
                className="form-control"
                value={newJob.addNotes}
                name="addNotes"
                onChange={handleChange}
              /> */}
              <CKEditor
                initData={notesAdd}
                config={{
                    versionCheck: false,
                    toolbar: [
                        ['Cut', 'Copy', 'Paste'],
                        ['Styles', 'Format', 'Font', 'FontSize'],
                        ['Bold', 'Italic', 'Strike'],
                        ['Undo', 'Redo'],
                        ['NumberedList', 'BulletedList', 'Blockquote', 'Outdent', 'Indent'],
                        ['Link', 'Unlink'],
                        ['Table', 'HorizontalRule'],
                        ['Maximize']
                    ]
                }
                }
                onChange={handleEditorChange}
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid" style={{ display: (validationError?.addNotes) ? 'block' : 'none' }}>
              {validationError?.addNotes}
            </Form.Control.Feedback>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Col xs={13} >
                <span>Upload File (pdf, docx or audio file) </span>
                <input ref={responseFileRef} type='file' style={{ padding: '3px' }} onChange={responseFileChange} className='form-control w-100' name='noteFiles' id="notes_file_file_url" multiple />
                <table>
                  {[...newJob?.noteFiles]?.map((file) => {
                    return (<tr><td><a target='_blank' href={file?.url}>{file?.file_name}</a></td><td><span role='button' onClick={() => removeAttachment(file.id)}><i className='text-danger' data-feather="x" /></span></td></tr>)
                  })}
                </table>
              </Col>
            </Form.Group>
            <Form.Control.Feedback type="invalid" style={{ display: (validationError?.noteFiles) ? 'block' : 'none' }}>
              {validationError?.noteFiles}
            </Form.Control.Feedback>
          </Col>
        </Form.Row>
        <Row jContent="flex-end">
        {!allowToUpdate &&
          <Button
            variant="primary"
            style={{
              margin: 0,
            }}
            type="button"
            onClick={() => handleSubmitResponse()}
            disabled={isSubmit}
          >
            {isSubmit ? <Spinner animation="border" variant="light" /> : _.isEmpty(job) ? '' : 'Update'}
          </Button>
        }
        </Row>
      </Form>
    </Fragment>
  )
}

export default JobNotes
