import React, {useState, useContext} from 'react';
import ArrowButton from '../../common/Styled components/ArrowButton';
import Text from '../../common/Styled components/Text';
import moment from 'moment';
import { TimeEntryContext } from '../../../stores/TimeEntryStore';
import SweetAlert from 'react-bootstrap-sweetalert';

function DateFilter(props) {
  const {dispatch, state} = useContext(TimeEntryContext);
  const {dateRange} = state
  const {startDate, endDate} = dateRange
  const [alertClose, setAlertClose] = useState(false);

  const toggleDate = (action) => {
    if (action === 'previous') {
      const prevStartDate = moment(new Date(startDate))
        .subtract(1, 'weeks')
        .startOf('week')
        .format('MMM,DD, YYYY');
      const prevEndDate = moment(new Date(endDate))
        .subtract(1, 'weeks')
        .endOf('week')
        .format('MMM,DD, YYYY');
      console.log(`${prevStartDate} - ${prevEndDate}`);
      dispatch({type: 'TOGGLE_DATE_RANGE', value: {startDate: prevStartDate, endDate: prevEndDate} });
    }
    if (action === 'next') {
      const nextStartDate = moment(new Date(startDate))
        .add(1, 'weeks')
        .startOf('week')
        .format('MMM,DD, YYYY');
      const nextEndDate = moment(new Date(endDate))
        .add(1, 'weeks')
        .endOf('week')
        .format('MMM,DD, YYYY');
      if(moment(nextStartDate) > moment(new Date())){
        setAlertClose(true)
      }else{
        dispatch({type: 'TOGGLE_DATE_RANGE', value: {startDate: nextStartDate, endDate: nextEndDate} });
      }
    }
  };

  return(
    <>
      <ArrowButton
        varient="left"
        color="primary"
        size="16px"
        onClick={() => toggleDate('previous')}
        shadow
      />
        <Text varient="p" color="primary" size={'14px'} givePadding style={{ minWidth: '200px' }}>
          {props.children}
        </Text>
      <ArrowButton
        varient="right"
        color="primary"
        size="16px"
        onClick={() => toggleDate('next')}
        shadow
      />
      {alertClose &&
        <SweetAlert
          style={{ fontWeight:'bold' }} 
          onConfirm={() => setAlertClose(false)} 
          btnSize="sm" 
          onCancel={() => setAlertClose(false)}
          >
          you can't view time entries ahead of current week
        </SweetAlert>
      }
    </>
  );
}

export default DateFilter ; 
