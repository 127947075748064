import React, { useState, useEffect } from 'react'

const RadioInput = ({
    radioInfo,
    setFilter,
    testAttr,
    initialValue,
}) => {
    const [checkedOption, setCheckedOption] = useState(initialValue)

    useEffect(() => {
        setFilter(testAttr, checkedOption)
    }, [checkedOption])

    const handleRadioChange = (e) => {
        setCheckedOption(e.target.value)
    }

    return (
        <div className="p-2">
            {radioInfo &&
                radioInfo.map((cb, i) => (
                    <div key={i}>
                        <input
                            type="radio"
                            value={cb}
                            onChange={handleRadioChange}
                            checked={initialValue === cb}
                            style={{ marginRight: '0.5rem' }}
                        />
                        {cb}
                    </div>
                ))}
        </div>
    )
}

export default RadioInput
