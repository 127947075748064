import React, { useRef, useState, useEffect } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/EmailDatas.scss'
import Button from '../Admin/shared/Button'

const EmailDatasCreate = ({organizations,recruiters,inMailEntry,onHide,method,fetchData}) => {
	const [validationError, setValidationError] = useState({})
	const [entryData, setEntryData] = useState({
		organization_id: inMailEntry?.organization_id ? inMailEntry?.organization_id : '',
		user_id: inMailEntry?.organization_id ? inMailEntry?.organization_id : '',
		email_sent: inMailEntry?.email_sent ? inMailEntry?.email_sent : '',
		text_sent: inMailEntry?.text_sent ? inMailEntry?.text_sent : '',
		of_replies: inMailEntry?.of_replies ? inMailEntry?.of_replies : '',
		response_rate: inMailEntry?.response_rate ? inMailEntry?.response_rate : '',
		in_email_sent: inMailEntry?.in_email_sent ? inMailEntry?.in_email_sent : '',
		inmail_remaining: inMailEntry?.inmail_remaining ? inMailEntry?.inmail_remaining : '',
		candidate_submitted: inMailEntry?.candidate_submitted ? inMailEntry?.candidate_submitted : '',
		confirmed_interview: inMailEntry?.confirmed_interview ? inMailEntry?.confirmed_interview : '',
		hire: inMailEntry?.hire ? inMailEntry?.hire : '',
		email_metric_sent_to_client: inMailEntry?.email_metric_sent_to_client ? inMailEntry?.email_metric_sent_to_client : '',
		email_metric_sent_to_am: inMailEntry?.email_metric_sent_to_am ? inMailEntry?.email_metric_sent_to_am : '',
	})
	
	const [btnDisabled, setBtnDisabled] = useState(false)
	const handleChange = (e) => {
		setValidationError({})
	}
	const handleChangeOrganization = (e) =>{
		setExpectedSent(e?.daily_target)
		setResponseRequest(e?.response_request)
		handleChange()
	}

	const inmailSentChange = (e)=>{
		handleChange()
	}

	const [modal,setModal] = useState('')
	const [expectedSent,setExpectedSent] = useState(0)
	const [responseRequest,setResponseRequest]= useState(false)
	
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/email_datas'
			if(method === 'put'){
				URL = `/email_datas/${inMailEntry.id}`
			}
			const payload = new FormData()
			payload.append('email_datas[organization_id]', e?.target?.organization_id?.value || '')
			payload.append('email_datas[user_id]', recruiters?.id || '')
			payload.append('email_datas[email_sent]', e?.target?.email_sent?.value || '')
			payload.append('email_datas[text_sent]', e?.target?.text_sent?.value || '')
			payload.append('email_datas[of_replies]', e?.target?.of_replies?.value || '')
			payload.append('email_datas[response_rate]', e?.target?.response_rate?.value || '')
			payload.append('email_datas[in_email_sent]', e?.target?.in_email_sent?.value || '')
			payload.append('email_datas[inmail_remaining]', e?.target?.inmail_remaining?.value || '')
			payload.append('email_datas[candidate_submitted]', e?.target?.candidate_submitted?.value || '')
			payload.append('email_datas[confirmed_interview]', e?.target?.confirmed_interview?.value || '')
			payload.append('email_datas[hire]', e?.target?.hire?.value || '')
			payload.append('email_datas[email_metric_sent_to_client]', e?.target?.email_metric_sent_to_client?.checked || '')
			payload.append('email_datas[email_metric_sent_to_am]', e?.target?.email_metric_sent_to_am?.checked || '')
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
						onHide()
						fetchData()
				}
			}) 
		}
		setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		if(!e.target.organization_id.value) {
			formIsValid = false;
			errors["organization_id"] = "Organization is required field."
		}

		if(e.target.email_sent.value == '' && e.target.text_sent.value == '' && e.target.of_replies.value == '' && e.target.response_rate.value == '' && e.target.email_metric_sent_to_am.checked == '' && e.target.email_metric_sent_to_client.checked == '' && e.target.hire.value == '' && e.target.confirmed_interview.value == '' && e.target.candidate_submitted.value == '' && e.target.inmail_remaining.value == '' ) {
			formIsValid = false;
			errors["email_metric_sent_to_client"] = " Atleast one Input field is required."
		}

		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
				<Row className="w-100 m-0">
					<Col xs={12} sm={12} lg={6}>
						<span>Organization Name* : </span>
						<Select
							options={organizations.sort((a, b) => (a.label > b.label) ? 1 : -1)}
							name='organization_id'
							id='organization_id'
							placeholder={'Select Organization'}
							onChange={handleChangeOrganization}
							defaultValue={organizations.filter((row) => row.value == entryData.organization_id)}
							menuPortalTarget={modal}
						/>
						<label className='error'>
							{validationError && validationError.organization_id} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Email Sent : </span>
						<input type="number" min="0" defaultValue={entryData.email_sent} onChange={inmailSentChange} className='form-control w-100' name='email_sent' id="email_sent" />
						<label className='error'>
							{validationError && validationError.email_sent} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Text Sent : </span>
						<input type="number"  min="0" defaultValue={entryData.text_sent} onChange={inmailSentChange} className='form-control w-100' name='text_sent' id="text_sent" />
						<label className='error'>
							{validationError && validationError.text_sent} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span># of Replies : </span>
						<input type="number"  min="0" defaultValue={entryData.of_replies} onChange={inmailSentChange} className='form-control w-100' name='of_replies' id="of_replies" />
						<label className='error'>
							{validationError && validationError.of_replies} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Response Rate : </span>
						<input type="number"  min="0" defaultValue={entryData.response_rate} onChange={inmailSentChange} className='form-control w-100' name='response_rate' id="response_rate" />
						<label className='error'>
							{validationError && validationError.response_rate} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>InMail Sent : </span>
						<input type="number"  min="0" defaultValue={entryData.in_email_sent} onChange={inmailSentChange} className='form-control w-100' name='in_email_sent' id="in_email_sent" />
						<label className='error'>
							{validationError && validationError.in_email_sent} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>InMail Remaining : </span>
						<input type="number"  min="0" defaultValue={entryData.inmail_remaining} onChange={inmailSentChange} className='form-control w-100' name='inmail_remaining' id="inmail_remaining" />
						<label className='error'>
							{validationError && validationError.inmail_remaining} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Candidate Submitted : </span>
						<input type="number"  min="0" defaultValue={entryData.candidate_submitted} onChange={inmailSentChange} className='form-control w-100' name='candidate_submitted' id="candidate_submitted" />
						<label className='error'>
							{validationError && validationError.candidate_submitted} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Confirmed Interview : </span>
						<input type="number"  min="0" defaultValue={entryData.confirmed_interview} onChange={inmailSentChange} className='form-control w-100' name='confirmed_interview' id="confirmed_interview" />
						<label className='error'>
							{validationError && validationError.confirmed_interview} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
						<span>Hire : </span>
						<input type="number"  min="0" defaultValue={entryData.hire} onChange={inmailSentChange} className='form-control w-100' name='hire' id="hire" />
						<label className='error'>
							{validationError && validationError.hire} 
						</label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
					<input type='checkbox'  defaultChecked={entryData.email_metric_sent_to_client} onChange={inmailSentChange}  name='email_metric_sent_to_client' id="email_metric_sent_to_client" />
						<label className='ml-1' for="email_metric_sent_to_client"> Email Metric Sent To Client  </label>
						<label className='error'>
						  {validationError && validationError.email_metric_sent_to_client} 
					    </label>
					</Col>
					<Col xs={12} sm={12} lg={6}>
					<input type='checkbox'  defaultChecked={entryData.email_metric_sent_to_am} onChange={inmailSentChange} name='email_metric_sent_to_am' id="email_metric_sent_to_am" />
						<label className='ml-1' for="email_metric_sent_to_am"> Email Metric Sent To AM  </label>
						<label className='error'>
							{validationError && validationError.email_metric_sent_to_am} 
						</label>
					</Col>
				</Row>
				<Row className="w-100 modal-entry-form-row mt-4 ml-1">
					<Col xs={12} sm={12} lg={12}>
					  <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius mr-2'>Save</Button>
					</Col>
				</Row>
			</form>
		</>
  )
}

export default EmailDatasCreate