import React, { useEffect, useState } from 'react'
import styles from './styles/SearchBarCandidate.module.scss'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormControl from 'react-bootstrap/FormControl'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import SearchIcon from '../../../../assets/images/talent_page_assets/search-icon.png'

function SearchBar({
    placeholder,
    value,
    setValue,
    onChange,
    onClick,
    onEnterPressed,
    onCancelPressed,
    candidateSource,
    candidatePage,
    hideButton=false,
    onResetFilterPressed,
    isAIGenerateFilterSet,
    onUpdateAIFilterPressed
}) {
    return (
      <Row className={`${candidatePage ? styles.candidateSearchBar: styles.atsSearchBar} ${styles.searchBarRow}`}>
        <Col lg='12' md='12' style={{display: 'flex',justifyContent: 'space-between', alignItems: 'center'}}>
          <label>Set filters with AI:</label>  { value != '' && isAIGenerateFilterSet && <label onClick={onUpdateAIFilterPressed} style={{marginRight: '18%', textDecoration: 'underline', color: 'blue', cursor: 'pointer'}}>Update AI filters</label> }
        </Col>
        <Col lg='12' md='12' style={{display: 'flex'}}>
          <FormControl
            id={candidatePage ? styles.candidateSearchSpace : ""}
            type="search"
            placeholder="Software Engineers with skills in Python and AWS"
            style={{ textIndent: '05px'}}
            className={candidatePage ? styles.candidateSearch : styles.placeholderText}
            value={value}
            onChange={onChange}
            onClick={onClick}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onEnterPressed(e)
              }
            }}
          />
          <Button className={styles.searchButton} onClick={onEnterPressed}>
            Generate
          </Button>
          {value != '' && isAIGenerateFilterSet &&
            <Button className={styles.searchButton} onClick={onResetFilterPressed} style={{ background: '#a3a5af', border: '1px solid #a3a5af'}}>
              Reset
            </Button>
          }
        </Col>
      </Row>
    )
  }

export default SearchBar
