import React, { useState, useEffect } from 'react'
import { Card, Col, Image, Row, ProgressBar } from 'react-bootstrap'
import * as Yup from 'yup';
import './styles/Subscription.scss'
import {
  Wrapper
} from './styles/Subscription.styled'
import {
  H1,
  P,
  Button,
  CONTAINER,
  GRID,
  COL,
  A,
} from './styles/Subscription.styled'
import TextInput from '../../common/TextInput/TextInput'
import { Formik, Form } from 'formik'
import { Spinner } from 'react-bootstrap'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'


const BillingPage = (props) => {
  const { billing_info, states } = props
  const [loading, setLoading] = useState(false)
  const [initialData, setInitialData] = useState({
    firstName: billing_info?.first_name || '',
    lastName: billing_info?.last_name || '',
    email: billing_info?.email || '',
    phoneNumber: billing_info?.phone_number || '',
    country: billing_info?.country || 'US',
    state: billing_info?.state || '',
    city: billing_info?.city || '',
    zipcode: billing_info?.zipcode || '',
    address1: billing_info?.address || '',
    address2: billing_info?.address_line_1 || '',
  })
  const handleSubmit = async (values) => {
    setLoading(true)
    let URL = (billing_info) ? `/user_billing_infos/` + billing_info?.id : `/user_billing_infos`
    let method = (billing_info) ? 'put' : 'post'
    const payload = new FormData()
    payload.append('user_billing[first_name]', values.firstName)
    payload.append('user_billing[last_name]', values.lastName)
    payload.append('user_billing[email]', values.email)
    payload.append('user_billing[phone_number]', values.phoneNumber)
    payload.append('user_billing[country]', values.country)
    payload.append('user_billing[state]', values.state)
    payload.append('user_billing[city]', values.city)
    payload.append('user_billing[zipcode]', values.zipcode)
    payload.append('user_billing[address]', values.address1)
    payload.append('user_billing[address_line_1]', values?.address2 || '')
    makeRequest(URL, method, payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (responce) => {
        if (responce.success) {
          setLoading(false)
          return {
            message: responce.message,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        } else {
          setLoading(false)
          return {
            message: responce.message,
            messageType: 'failure',
            loading: false,
            autoClose: true,
          }
        }
      },
      createErrorMessage: (e) => {
        setLoading(false)
        return e.message
      },
    })
  };

  return (
    <>
      <div className='subscriptionPage'>
        <Wrapper>
          <div className='sidebar'>
            <div className='sidebar_header'>
              <p>Subscriptions & Billing</p>
            </div>
            <a href="/subscription">
              Subscriptions
            </a>
            <a href="/subscription/invoice">Invoices</a>
            <a className='active' href="/subscription/billing">Billing Information</a>
          </div>

          <div className='containt'>
            <Row className="main-find">
              <Col>
                <div className="m-2 form-details">
                  <h3 className="find-tittle text-center">
                    BILLING INFORMATION
                  </h3>
                  <hr></hr>
                  <div className={'signForm'}>
                    <Formik
                      validateOnChange={true}
                      validateOnBlur={true}
                      initialValues={initialData}
                      validationSchema={
                        Yup.object({
                          firstName: Yup.string().trim()
                            .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
                            .max(15, 'Must be 15 characters or less')
                            .required('First Name is Required'),
                          lastName: Yup.string().trim()
                            .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
                            .max(15, 'Must be 20 characters or less')
                            .required('Last Name is Required'),
                          phoneNumber: Yup.string().trim()
                            .matches(/^[0-9]+$/, "Please enter valid phone number")
                            .max(15, 'Phone Numbers can have 10 to 15 digits only.')
                            .min(10, "Phone Numbers can have 10 to 15 digits only.")
                            .required('Phone Number is Required'),
                          country: Yup.string().trim()
                            .matches(/^[A-Z]+$/, "Only alphabet characters are enter here")
                            .max(2, 'Must be 2 characters')
                            .min(2, 'Must be 2 characters')
                            .required('Country Name is Required'),
                          state: Yup.string().trim()
                            .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
                            .required('State Name is Required'),
                          city: Yup.string().trim()
                            .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
                            .required('City Name is Required'),
                          zipcode: Yup.string().trim()
                            .matches(/^[0-9]+$/, "Please enter valid zip code")
                            .max(6, 'Zip Code can have 5 to 6 digits only.')
                            .min(5, "Zip Code can have 5 to 6 digits only.")
                            .required('Zip Code is Required'),
                          address1: Yup.string().trim()
                            .required('Address1 is Required'),
                          // address2: Yup.string().trim()
                          //   .required('Address2 is Required'),
                          email: Yup.string().trim()
                            .email('Invalid email address')
                            .required('Email is Required')
                        })
                      }
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      <Form>
                        <Row className='subScriptionForm'>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="First Name*"
                              name="firstName"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Last Name*"
                              name="lastName"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Email Address*"
                              name="email"
                              type="email"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Phone Number*"
                              name="phoneNumber"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Address1*"
                              name="address1"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Address2"
                              name="address2"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="City*"
                              name="city"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                          <TextInput
                              as="select"
                              className={'fullwidthInput'}
                              label="State*"
                              name="state"
                              type="text"
                              width="100%"
                              display="block"
                            >
                            <option value="">Select State</option>
                            {states.map(
                                ({ key, value }) => {
                                    return (
                                        <option
                                            key={key}
                                            value={key}
                                        >
                                            {value}
                                        </option>
                                    )
                                }
                            )}
                          </TextInput>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Zip Code*"
                              name="zipcode"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              as="select"
                              className={'fullwidthInput'}
                              label="Country Code*"
                              name="country"
                              type="text"
                              width="100%"
                              display="block"
                            >
                              <option value="US">US</option>
                            </TextInput>
                          </Col>
                          
                          <div style={{ marginTop: '10px' }}>
                            <Button
                              className={'sign_in_button'}
                              type="submit"
                              style={{
                                alignSelf: 'flex-end',
                                float: 'left',
                              }}
                              disabled={loading}
                            >
                              {loading ? (
                                <Spinner animation="border" variant="light" />
                              ) : (
                                'Save'
                              )}
                            </Button>
                            {/* <Button type='button' disabled={loading} className='ml-2' onClick={clearFome}>
                              Clear
                            </Button> */}
                          </div>
                        </Row>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Wrapper>
      </div>
    </>
  )
}
export default BillingPage
