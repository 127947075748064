import React, { Fragment, useState } from 'react'
import * as Yup from 'yup'
import styles from './styles/AccountSettingPage.module.scss'
import { capitalize } from '../../../utils'
import { Row, Col } from 'react-bootstrap'
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Image from 'react-bootstrap/Image';
import {
    Wrapper,
} from './styles/AccountSettingPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import SweetAlert from 'react-bootstrap-sweetalert'
import FileButton from '../../common/FileButton/FileButton'

const AgreementManagementPage = (props) => {
    console.log(props.organization)
    const { currentUser } = props
    const { first_name, last_name } = currentUser
    const [resume, setResume] = useState()
    const [resumeError, setResumeError] = useState(null)
    const [isSubmit, setIsSubmit] = useState(false)
    const [validationErrors, setValidationErrors] = useState([])

    const handleOnSave = async () => {
        if (setValidationMessages()) {
            setIsSubmit(true)
            console.log(resume)
            const formData = new FormData()
            if(resume != null){
                formData.append('organization[agreement_url]',  (resume) || '')
            }
            let url = `/account/agreement_management/${props.organization.id}`
            await makeRequest(url, 'put', formData, {
                contentType: 'multipart/form-data',
                loadingMessage: 'Submitting...',
                createResponseMessage: () => {
                  return {
                    message: 'Update successful',
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
                },
                onSuccess: () => {
                    // window.location.reload()
                }
               })
        }
    }

    const setValidationMessages = () => {
        let errors = []
        if(resume?.size > 10000000){
            errors.push( 'File is not allowed more then 10MB')
        }
        if (resumeError?.length || !resume?.type.includes('doc') && !resume?.type.includes('pdf') && resume?.type.includes('text') ) {
            errors.push( 'Please upload valid file format')
        }
        setValidationErrors(errors)
        if (errors.length > 0) {
            document.getElementById('div').scrollTop = 0
            return false
        } else {
            return true
        }
    }

    return (
        <div className={styles.accountSettingPage}>
            <Wrapper className={`${styles.accontSetting}`}>
                <div className={`${styles.sidebar}`}>
                    <div className={`${styles.sidebar_header}`}>
                        <p>{capitalize(`${first_name} ${last_name}`)}</p>
                    </div>
                    <a href="/account/setting">General Settings</a>
                    <a href="/account/security">
                        Security & Login
                    </a>
                    <a href="/account/email_verification">Email Verification</a>
                    { (currentUser.role == 'recruiter') && 
                        <a  href="/account/organization_management">Organization Membership Management</a>
                    }
                  <a href="/account/invite_your_friend">Invite Your Friend</a>
                  {currentUser.role == 'talent' &&
                  <a href="/account/email_configuration">SMTP Email Configuration</a>
                  }
                </div>
                <div className={`${styles.containt}`} style={{ paddingBottom: '12rem' }}>
                   
                    <h3>Organization Agreement Management</h3>
                   
                    <div style={{ paddingTop: '2rem' }}>
            
                        <Fragment>
                            <Row aItems="center">
                                <FileButton
                                    type="button"
                                    label="Upload Resume"
                                    file={resume}
                                    resumeError={resumeError}
                                    setResumeError={setResumeError}
                                    getFile={(file) => setResume(file)}
                                />
                            </Row>
                            <Row aItems="center" style={{marginTop:'10px'}}>
                                <button
                                    type='submit'
                                    className={`${styles.editButton}`}
                                    onClick={handleOnSave}
                                >
                                    Save
                                </button>
                                
                            </Row>
                        </Fragment>
                    </div>              
                </div>
                
            </Wrapper>
        </div>
    )
}
export default AgreementManagementPage
