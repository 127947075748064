
export const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#fffff',
      color: '#005cbf',
      border: '1px silod #ced4da',
      texTransform: 'capitalize',
    }),
    valueContainer: (styles) => ({
      ...styles,
      texTransform: 'capitalize',
      padding: '2px 4px',
    }),
    option: (styles) => {
      return {
        ...styles,
        textTransform: 'capitalize',
      };
    },
    menuOptions: (styles) => ({
      ...styles,
      fontFamily: 'Avenir, "Lato", sans-serif',
      texTransform: 'capitalize',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: `hsl(0, 0%, 20%)`,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 999999999,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 999999999,
      texTransform: 'capitalize',
    }),
    menuList: (styles) => ({
      ...styles,
      texTransform: 'capitalize',
    }),
    multiValue: (styles) => ({
      ...styles,
      background: `linear-gradient(95.72deg, #E6EBFF 0%, #DBE2FF 53.83%, #D5D7FF 109.96%)`,
      color: '#6470AF',
      borderRadius: '20px',
      padding: '1px',
      textTransform: 'capitalize',
      maxWidth: '65px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#6470AF',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      borderRadius: '50%',
      paddingLeft: '1px',
      paddingRight: '2px',
    }),
    clearIndicator: (styles) => ({
      ...styles,
      padding: '1px',
      width: '18px',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: '2px',
    }),
  };
  
 

  