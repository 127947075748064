import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

// import styles from '../styles/SearchResultsBlock.module.scss'
import styles from './styles/JobPositionCard.module.scss'
import JobPositionCard from '../../common/JobPositionCard/JobPositionCard'
import Paginator from '../../common/Paginator/Paginator'
import JobSearchBar from '../../common/JobSearchBar/JobSearchBar'
import FilterJob from '../../common/FilterJob/FilterJob'
import Select from 'react-select'
import ResumeDragDrop from '../../common/ResumeDragDrop/ResumeDragDrop'
import './styles/ResumeDragDrop.scss'
import HorizontalLoader from '../../common/HorizontalLoader/HorizontalLoader'
import { Badge, Card, Image, Tab, Tabs } from 'react-bootstrap'
import CandidateSkills from '../../common/CandidateSkills/CandidateSkills'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import CompanyPlaceholderImage from '../../../../assets/images/talent_page_assets/company-placeholder.png'
import moment from 'moment'
import { capitalize } from '../../../utils'
import { handelBrokenUrl, interviewStageTime } from '../../common/Utils'
import './styles/CandidateInterviewSchedule.scss'
const CandidateInterviewScheduled_v2 = () => {
    const [job, setJob] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [imageurl, setImageUrl] = useState()
    
    const fetchData = async () => {
        const url = '/candidate_interview_scheduled.json'
        const response = await makeRequest(`${url}`, 'get', {})
        setData(response?.data?.candidates)
        setLoading(response?.data?.candidates?.lenght && false)
    }
    useEffect(() => {
        fetchData()
    }, [])
    
    function parseStr(str) {
        return JSON.parse(str)
       }

      function interviewStageTimeSchedule(str) {
          const time_arr = parseStr(str)
          const time =  Array.isArray(time_arr) ? time_arr[0] : time_arr
          const {hour, minute, isAM, timeZone} = time
          const time_minute = minute == '' ? '00' :minute
          return hour+':'+time_minute+' '+isAM+' '+timeZone
        }

    const upcomming_data = data?.filter((item) => (item?.interview_status != 'reschedule' ) && (item?.interview_status != 'cancelled' ) && (item?.interview_date > moment(new Date()).format("YYYY-MM-DD")))
    const past_data = data?.filter((item) =>  (item?.interview_status == 'reschedule' ) || (item?.interview_status == 'cancelled' ) || (item?.interview_date < moment(new Date()).format("YYYY-MM-DD")) )
    const today_data = data?.filter((item) => (item?.interview_status != 'reschedule') && (item?.interview_status != 'cancelled' ) && (item?.interview_date == moment(new Date()).format("YYYY-MM-DD")))
    const JobAvatarUrl = (logo) => {
        return logo ? logo : CompanyPlaceholderImage
      }
    return (
        <>
         <section className="section-box" style={{ overflow: 'inherit !important' }}>
            <div className="container bootstrap snippets bootdeys">
                <div className="row">
                    <div className="col-xs-12">
                        <div>
                            <div className="mt-50">
                                <div className="container text-center">
                                    <h4 style={{fontWeight: '500'}}>Candidate Interview Schedule</h4>
                                </div>
                            </div>
                            {loading ? <>
                                <div className='container' style={{ textAlign: 'center', marginTop:'50px' }}>
                                        <h5>Loading....</h5>
                                    </div>
                            </> :
                            <div className={styles.mainSearchblock} fluid>
                                <div className={styles.containers}>
                                <Col lg={12} md={12} sm={12} xs={12} style={{marginTop: '5%'}}>
                                    <Tabs
                                        defaultActiveKey="skill-tab"
                                        className="organization-activity-tabs mt-25"
                                    >
                                        <Tab eventKey="skill-tab"
                                        title={
                                            <React.Fragment>
                                            Today
                                    {today_data.length > 0 && <Badge variant="light" style={{marginLeft:'3px',color:'#f8f9fa', backgroundColor:'#4e69fa'}}>{today_data?.length}</Badge>}
                                    </React.Fragment>
                                        }
                                        >
                                        <Col>
                                            <div className={styles.tableColumn}>
                                                <div className="search-results-wrap__bottom-row">
                                                    {today_data && today_data?.map((item) => {
                                                        return(
                                                            
                                                            <Card
                                                            className={styles.jobCard}
                                                            style={{
                                                                backgroundColor: `${
                                                                    false ? '#F7F7F7' : '#fff'
                                                                }`,
                                                                cursor:'default',
                                                        
                                                            }}
                                                            >
                                                        <Card.Body style={{minHeight: '230px'}}>
                                                            <Row className="align-items-center">
                                                                <Col
                                                                    lg={2}
                                                                    md={2}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="d-sm-flex align-items-sm-center flex-sm-column flex-lg-row text-center"
                                                                    style={{
                                                                        margin: 'auto',
                                                                        width: '100px',
                                                                        minHeight: '300px'
                                                                    }}
                                                                >
                                                                    <Image
                                                            src={
                                                                JobAvatarUrl(item?.image_url)
                                                            }
                                                            fluid
                                                            className={styles.jobCardImage, 'companyJobLogo'}
                                                            style={{ margin: 'auto', width: '100px',height:'auto' }}
                                                            onError={(e) => {handelBrokenUrl(e)}}
                                                        />
                                                                </Col>
                                                                <Col
                                                                    lg={8}
                                                                    md={8}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="px-0"
                                                                >
                                                                    <Row>
                                                                    <Col className={styles.jobCardCol}>
                                                                <p className={styles.jobCardText}>
                                                                    <a className={styles.OrgNameText} >{capitalize(item?.org_name)}</a>
                                                                    <span className={styles.jobCardText}>&nbsp;&nbsp;{item?.location !== "null" ? item?.location && capitalize(item?.location?.replace('(US)','')?.replace('(usa)','')?.replace('(USA)','')) : 'No location found'}</span>
                                                                    {/* {item.status === 'closed' && (
                                                                <span className={styles.jobClosed}>
                                                                    Closed
                                                                </span> 
                                                                )}
                                                                {item.status === 'expired' && (
                                                                <span className={styles.jobExpired}>
                                                                    Expired
                                                                </span> 
                                                                )}
                                                                {item.status === 'pending' && (
                                                                <span className={styles.jobPending}>
                                                                    Pending
                                                                </span> 
                                                                )}
                                                                {item.status === 'rejected' && (
                                                                <span className={styles.jobRejected}>
                                                                    Rejected
                                                                </span> 
                                                                )} */}
                                                                </p>
                                                                    <span className={styles.jobCardTitle}>
                                                                        {item.job_name}
                                                                    </span>
                                                                </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                        {item?.skills && (
                                                                    <Row>
                                                                    <Col>
                                                                    <CandidateSkills skills = {item?.skills} />
                                                                            </Col>
                                                                            </Row>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                                <Col
                                                                    lg={2} md={2} sm={12} xs={12}
                                                                >
                                                                    <div className={`${ item?.interview_type == 'hired' ? 'interview-stage-hired' : item?.interview_type == 'reject' ? 'interview-stage-reject' :  'interview-stage mt-1'}`}>

                                                                    <p className={styles.jobCardText}>Interview Date:&nbsp; {moment(item?.interview_date).format('dddd, MMM DD,YYYY')}</p>
                                                                    <p className={styles.jobCardText}>Interview Time:&nbsp; {interviewStageTimeSchedule(item?.interview_time)}</p>
                                                                    <span className={styles.jobCardText}>{capitalize(item?.interview_type == 'reject' ? 'not fit for job' : item?.interview_type).replace("_", " ") || ''}</span>

                                                                    </div>
                                                        </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                        )
                                                    })
                                                    }

                                                </div>
                                            </div>
                                            {today_data?.length <= 0   &&  <Card className={styles.jobCard} style={{backgroundColor: `${ false ? '#F7F7F7' : '#fff'}`,cursor: 'pointer', }}>
                                            <Card.Body  style={{minHeight: '230px'}}>
                                                <Row className="align-items-center d-flex justify-content-center">
                                                                    <div className='text-center d-flex justify-content-center mt-1'>
                                                                        <p style={{marginTop: '5%',fontWeight: '600'}}>No Interview Schedule</p>
                                                                </div>
                                                </Row>
                                            </Card.Body>
                                        </Card>}
                                        </Col>
                                        
                                        </Tab>
                                        <Tab eventKey="about-tab"
                                        title={
                                            <React.Fragment>
                                            Upcoming
                                    {upcomming_data.length > 0 && <Badge variant="light" style={{marginLeft:'3px',color:'#f8f9fa', backgroundColor:'#4e69fa'}}>{upcomming_data?.length}</Badge>}
                                    </React.Fragment>
                                        }
                                        >
                                        <Col>
                                            <div className={styles.tableColumn}>
                                                <div className="search-results-wrap__bottom-row">
                                                    {upcomming_data && upcomming_data?.map((item) => {
                                                        return(
                                                            
                                                            <Card
                                                            className={styles.jobCard}
                                                            style={{
                                                                backgroundColor: `${
                                                                    false ? '#F7F7F7' : '#fff'
                                                                }`,
                                                                cursor: 'default',
                                                            }}
                                                            >
                                                        <Card.Body  style={{minHeight: '230px'}}>
                                                            <Row className="align-items-center">
                                                                <Col
                                                                    lg={2}
                                                                    md={2}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="d-sm-flex align-items-sm-center flex-sm-column flex-lg-row text-center"
                                                                    style={{
                                                                        margin: 'auto',
                                                                        width: '100px',
                                                                        height: 'auto',
                                                                    }}
                                                                >
                                                                    {false && (
                                                                        <i
                                                                            data-feather="check"
                                                                            className={
                                                                                styles.checkIcon
                                                                            }
                                                                        ></i>
                                                                    )}
                                                                    <Image
                                                            src={
                                                                JobAvatarUrl(item?.image_url)
                                                            }
                                                            fluid
                                                            className={styles.jobCardImage, 'companyJobLogo'}
                                                            style={{ margin: 'auto', width: '100px',height:'auto' }}
                                                            onError={(e) => {handelBrokenUrl(e)}}
                                                        />
                                                                </Col>
                                                                <Col
                                                                    lg={8}
                                                                    md={8}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="px-0"
                                                                >
                                                                    <Row>
                                                                    <Col className={styles.jobCardCol}>
                                                                <p className={styles.jobCardText}>
                                                                    <a className={styles.OrgNameText} >{capitalize(item?.org_name)}</a>
                                                                    <span className={styles.jobCardText}>&nbsp;&nbsp;{item?.location !== "null" ? item?.location && capitalize(item?.location?.replace('(US)','')?.replace('(usa)','')?.replace('(USA)','')) : 'No location found'}</span>
                                                                    
                                                                </p>
                                                                    <span className={styles.jobCardTitle}>
                                                                        {item.job_name}
                                                                    </span>
                                                                </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                        {item?.skills && (
                                                                    <Row>
                                                                    <Col>
                                                                    <CandidateSkills skills = {item?.skills} />
                                                                            </Col>
                                                                            </Row>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>

                                                                <Col
                                                                    lg={2} md={2} sm={12} xs={12}
                                                                >
                                                                
                                                                    <div className={`${ item?.interview_type == 'hired' ? 'interview-stage-hired' : item?.interview_type == 'reject' ? 'interview-stage-reject' :  'interview-stage mt-1'}`}>

                                                                    <p className={styles.jobCardText}>Interview Date:&nbsp; {moment(item?.interview_date).format('dddd, MMM DD,YYYY')}</p>
                                                                    <p className={styles.jobCardText}>Interview Time:&nbsp; {interviewStageTimeSchedule(item?.interview_time)}</p>
                                                                    <span className={styles.jobCardText}>{capitalize(item?.interview_type == 'reject' ? 'not fit for job' : item?.interview_type).replace("_", " ") || ''}</span>

                                                                    </div>
                                                        </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                        )
                                                    })}

                                                </div>
                                            </div>
                                            {upcomming_data?.length <= 0   &&  <Card className={styles.jobCard} style={{backgroundColor: `${ false ? '#F7F7F7' : '#fff'}`,cursor: 'pointer', }}>
                                            <Card.Body  style={{minHeight: '230px'}}>
                                            <Row className="align-items-center d-flex justify-content-center">
                                                                    <div className='text-center d-flex justify-content-center mt-1'>
                                                                        <p style={{marginTop: '5%',fontWeight: '600'}}>No Upcoming Interview Schedule</p>
                                                                </div>
                                                </Row>
                                                        </Card.Body>
                                        </Card>}
                                        </Col>
                                        </Tab>
                                        <Tab eventKey="notes-tab"
                                        title={
                                            <React.Fragment>
                                            Past
                                    {past_data.length > 0 && <Badge  variant="light" style={{marginLeft:'3px', color:'#f8f9fa', backgroundColor:'#4e69fa'}}>{past_data?.length}</Badge>}
                                    </React.Fragment>
                                        }
                                        >
                                        <Col>
                                            <div className={styles.tableColumn}>
                                                <div className="search-results-wrap__bottom-row">
                                                    {past_data && past_data?.map((item) => {
                                                        return(
                                                            
                                                            <Card
                                                            className={styles.jobCard}
                                                            style={{
                                                                backgroundColor: `${
                                                                    false ? '#F7F7F7' : '#fff'
                                                                }`,
                                                                cursor: 'default',
                                                            }}
                                                            >
                                                        <Card.Body  >
                                                            <Row className="align-items-center">
                                                                <Col
                                                                    lg={2}
                                                                    md={2}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="d-sm-flex align-items-sm-center flex-sm-column flex-lg-row text-center"
                                                                    style={{
                                                                        margin: 'auto',
                                                                        width: '100px',
                                                                        height: 'auto',
                                                                    }}
                                                                >
                                                                    {false && (
                                                                        <i
                                                                            data-feather="check"
                                                                            className={
                                                                                styles.checkIcon
                                                                            }
                                                                        ></i>
                                                                    )}
                                                                    <Image
                                                            src={
                                                                JobAvatarUrl(item?.image_url)
                                                            }
                                                            fluid
                                                            className={styles.jobCardImage, 'companyJobLogo'}
                                                            style={{ margin: 'auto', width: '100px',height:'auto' }}
                                                            onError={(e) => {handelBrokenUrl(e)}}
                                                        />
                                                                </Col>
                                                                <Col
                                                                    lg={6}
                                                                    md={6}
                                                                    sm={12}
                                                                    xs={12}
                                                                    className="px-0"
                                                                >
                                                                    <Row>
                                                                    <Col className={styles.jobCardCol}>
                                                                <p className={styles.jobCardText}>
                                                                    <a className={styles.OrgNameText} >{capitalize(item?.org_name)}</a>
                                                                    <span className={styles.jobCardText}>&nbsp;&nbsp;{item?.location !== "null" ? item?.location && capitalize(item?.location?.replace('(US)','')?.replace('(usa)','')?.replace('(USA)','')) : 'No location found'}</span>
                                                                    
                                                                </p>
                                                                    <span className={styles.jobCardTitle}>
                                                                        {item.job_name}
                                                                    </span>
                                                                </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                        {item?.skills && (
                                                                    <Row>
                                                                    <Col>
                                                                    <CandidateSkills skills = {item?.skills} />
                                                                            </Col>
                                                                            </Row>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>

                                                                <Col
                                                                    lg={4} md={4} sm={12} xs={12}
                                                                >
                                                        
                                                                    <div className={`${ item?.interview_type == 'hired' ? 'interview-stage-hired' : item?.interview_type == 'reject' ? 'interview-stage-reject' :  'interview-stage mt-1'}`}>

                                                                    <p className={styles.jobCardText}>Interview Date:&nbsp; {moment(item?.interview_date).format('dddd, MMM DD,YYYY')}</p>
                                                                    <p className={styles.jobCardText}>Interview Time:&nbsp; {interviewStageTimeSchedule(item?.interview_time)}</p>
                                                                { item?.interview_status != '' ?<span className={item?.interview_status == 'cancelled' ? styles.cancelJobCardText : styles.jobCardText}>
                                                                        {capitalize(item?.interview_status  || '')}
                                                                    </span>
                                                                :    
                                                                <span className={styles.jobCardText}>
                                                                {capitalize(item?.interview_type == 'reject' ? 'not fit for job' : item?.interview_type).replace("_", " ") || ''}
                                                                    </span>
                                                                }

                                                                    </div>
                                                        </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                        )
                                                    })}

                                                </div>
                                            </div>
                                            {past_data?.length <= 0   &&  <Card className={styles.jobCard} style={{backgroundColor: `${ false ? '#F7F7F7' : '#fff'}`,cursor: 'pointer', }}>
                                            <Card.Body style={{minHeight: '230px'}}>
                                            <Row className="align-items-center d-flex justify-content-center">
                                                                    <div className='text-center d-flex justify-content-center mt-1'>
                                                                        <p style={{marginTop: '5%',fontWeight: '600'}}>No Record Found</p>
                                                                </div>
                                                </Row>
                                                        </Card.Body>
                                        </Card>}
                                        </Col>
                                        </Tab>
                                    </Tabs>              
                                    </Col>
                                    <Col>
                            
                                    </Col>
                                </div>
                            </div> }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default CandidateInterviewScheduled_v2
