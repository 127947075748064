import React, { useState, useEffect } from 'react'
import { Alert, Col, OverlayTrigger, Popover } from 'react-bootstrap'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from 'react-share'
import CompanyPlaceholderImage from '../../../../assets/images/talent_page_assets/company-placeholder.png'
import {
    Row,
    Container,
    Button2,
    Body,
    ImageContainer,
    Box,
    HeaderRow,
    List,
    Dot,
    Button,
    Wrapper,
    Section,
    Skill,
    W4text,
    W9text,
    W5text,
    Header,
} from './styles/JobDescriptionPage.styled'
import './styles/JobDescriptionPage.scss'
import ReferralModal from '../../common/modals/JobDescriptionModal/ReferralModal'
import { handelBrokenUrl } from '../../common/Utils'
import SweetAlert from 'react-bootstrap-sweetalert'
import { firstCharacterCapital, skillIsNotEmpty } from '../../../utils'
import styles from './styles/JobDescriptionPage.module.scss'
import {ApplyButton} from '../../common/ApplyJob/styles/Apply.styled'
import { Modal} from 'react-bootstrap'
import axios from 'axios'
import Moment from 'moment'
import CandidateDetails from '../../common/CandidateForm/CandidateDetails'
import EmailConfirm from '../../common/EmailConfirm/EmailConfirm'
import '../../../../assets/v2/css/main.css'
import '../../pages/Interview/styles/AiInterview.scss'


const popover = (shareUrl) => {
    const content = (
        <Popover id="popover-basic">
            <Popover.Content>
                <FacebookShareButton
                    openShareDialogOnClick={false}
                    onClick={(_, url) => {
                        window.open(url, '_blank')
                    }}
                    style={{ marginLeft: 10 }}
                    url={shareUrl}
                    target="_blank"
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>

                <TwitterShareButton
                    openShareDialogOnClick={false}
                    onClick={(_, url) => {
                        window.open(url, '_blank')
                    }}
                    style={{ marginLeft: 10 }}
                    url={shareUrl}
                    target="_blank"
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>

                <LinkedinShareButton
                    openShareDialogOnClick={false}
                    onClick={(_, url) => {
                        window.open(url, '_blank')
                    }}
                    style={{ marginLeft: 10 }}
                    url={shareUrl}
                    target="_blank"
                >
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </Popover.Content>
        </Popover>
    )

    return content
}

const JobDescriptionPage_v2 = ({
    jobModel: job,
    currentUser,
    isApplied,
    showInviteFriends,
    appliedDate,
    organizationId,
    publicUrl,
    organization,
    isProfileComplete,
}) => {
    const {
        name,
        location,
        compensation: salary,
        portalcompanyname: company,
        description,
        niceToHaveSkills,
        skills,
        gen_reqs: requirements,
        pref_skills: preferredSkills,
        logo,
        department,
        experience_years,
        created_at,
        organization_id
    } = job
    const [errorCreatingSubmission, setErrorCreatingSubmission] = useState(null)
    const [alertApplyForJob, setAlertApplyForJob] = useState(null)
    const [showReferralModal, setShowReferralModal] = useState(showInviteFriends)
    const [applied, setApplied] = useState(isApplied || false)
    let prefSkills = skills.length && skills.split(',')
    let niceToHave = niceToHaveSkills?.length && niceToHaveSkills.split(',')
    const [candidateModel, setCandidateModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [emailVerify, setEmailVerify] = useState(false)
    const [applyedDate, setApplyedDate] = useState(appliedDate)
    const [checkCurrentUserPersonId, setCheckCurrentUserPersonId] = useState(false)

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href)
        return function cleanup() {
            window.onpopstate = null
        }
    }, [])
    
    const redirectToCompleteProfile = () => {
        window.location.href = `/users/profile/${currentUser.id}`
        setCheckCurrentUserPersonId(false)
    }
    const handleApply = async () => {
        if (currentUser) {
            if (!isProfileComplete) {
                window.location.href = '/complete/profile'
            } else if (!currentUser.email_confirmed) {
                setEmailVerify(true)
            } else {
                if(currentUser.person_id){

                const token = document.querySelector(
                    'meta[name="csrf-token"]'
                ).content
                let payload = {
                    authenticity_token: token,
                }

                payload['submission'] = {
                    job_id: job.id,
                    organization_id: job.organization_id,
                    submission_type: 'applicant',
                    source: 'talent_user'
                }

                try {
                    setLoading(true)
                    setApplied(true)
                    const { data } = await axios.post(
                        '/submissions.json',
                        payload
                    )

                   
                    if (data.hasOwnProperty('error')) {
                        setErrorCreatingSubmission(data.error)
                        setLoading(false)
                        setApplied(false)
                    } else {
                        setApplyedDate(new Date())
                        setLoading(false)
                        setAlertApplyForJob(
                            'You successfully applied for this job!'
                        )
                    }
                } catch (error) {
                    setErrorCreatingSubmission(error.response.data.error)
                    setLoading(false)
                }
            }else{
                setCheckCurrentUserPersonId(true)
                console.log('empty')
            }
               
            }
        } else {
            setCandidateModal(true)
        }
    }
    return (
        <>
        <section className="section-box text-center" style={(currentUser?.person_id) ? {}: {marginTop: '85px'}}>
            <div className="box-head-single bg-2">
                <div className="ai-containter">
                    <div className="avatar-sidebar mx-auto">
                        <div className="d-flex align-items-center">
                            <figure><img style={{maxWidth: '100px',maxHeight: '100px',marginRight: '30px'}} alt={organization?.name} src={organization.image_url ? organization.image_url : CompanyPlaceholderImage}
                                        onError={(e) => {
                                            handelBrokenUrl(e)
                                        }}
                                    /></figure>
                            <div className="sidebar-info text-start">
                                <a
                                    href={
                                        organizationId
                                            ? `/organizations/${organizationId}/careers`
                                            : '#'
                                    }
                                ><h5 className="sidebar-company">{organization?.name}</h5>
                                </a>
                                <span className="sidebar-website-text">{organization?.website_url}</span>
                            </div>
                        </div>
                    </div>
                    <h3>{name}</h3>
                    <ul className="breadcrumbs">
                        <li><a href="/">Home</a></li>
                        <li><a href="/job_search">Jobs listing</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="section-box mt-50">
            <div className="ai-containter">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <div className="job-overview">
                            <div className="row">
                                <div className="col-md-6 d-flex mt-sm-15">
                                    <div className="sidebar-icon-item"><i className="fi-rr-marker"></i></div>
                                    <div className="sidebar-text-info ml-10">
                                        <span className="text-description mb-10">Location</span>
                                        <strong className="small-heading">
                                            {!!location?.length && (
                                                    <>
                                                        {location !== "null" ? location : 'No location found'}
                                                    </>
                                            )}
                                        </strong>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex mt-sm-15">
                                    <div className="sidebar-icon-item">
                                        <i className="fi-rr-briefcase"></i>
                                    </div>
                                    <div className="sidebar-text-info ml-10">
                                        <span className="text-description mb-10">Department</span>
                                        <strong className="small-heading">{ (!!department?.length) ? department : '-'}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-30">
                                <div className="col-md-6 d-flex">
                                    <div className="sidebar-icon-item"><i className="fi-rr-clock"></i></div>
                                    <div className="sidebar-text-info ml-10">
                                        <span className="text-description mb-10">Date posted</span>
                                        <strong className="small-heading">{Moment(created_at).fromNow()}</strong>
                                    </div>
                                </div>
                                {/* <div className="col-md-4 d-flex mt-sm-15">
                                    <div className="sidebar-icon-item"><i className="fi-rr-time-fast"></i></div>
                                    <div className="sidebar-text-info ml-10">
                                        <span className="text-description mb-10">Expiration date</span>
                                        <strong className="small-heading">{Moment(job.expiry_date).format('MMM DD, YYYY')}</strong>
                                    </div>
                                </div> */}
                                <div className="col-md-6 d-flex mt-sm-15">
                                    <div className="sidebar-icon-item"><i className="fi-rr-briefcase"></i></div>
                                    <div className="sidebar-text-info ml-10">
                                        <span className="text-description mb-10">Job Title</span>
                                        <strong className="small-heading">{name}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-30">
                                <div className="col-lg-12 col-md-12 d-flex">
                                   <div className="sidebar-icon-item"></div>
                                   <div className="sidebar-text-info ml-10">
                                        <span className="text-description mb-10" style={{ fontSize: '18px',paddingLeft: '0px',paddingBottom: '20px'}}>Skill</span>
                                        {!!prefSkills &&
                                            prefSkills?.map(
                                                (item, index) =>
                                                    skillIsNotEmpty(item) && (
                                                        <Skill key={index}>
                                                            {firstCharacterCapital(item)}
                                                        </Skill>
                                                    )
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-50">
                                <div className="col-lg-12 col-md-12">
                                    { (applied) &&
                                      <>
                                        <a  className="btn btn-default mr-10" 
                                            disabled={applied ? true : false}
                                           >
                                                {applied && applyedDate
                                                    ? `Applied on ${Moment(applyedDate).format(
                                                        'MMM DD, YYYY  hh:mm'
                                                    )}`
                                                    : ''}
                                        </a>
                                      </>

                                    }
                                    { (!applied) && 
                                        <>
                                            <a href="#" className="btn btn-default mr-10" 
                                                disabled={applied ? true : false}
                                                onClick={() => handleApply()}>
                                                    Apply
                                            </a>
                                        </>
                                    }
                                    
                                </div>
                            </div>
                            <Modal
                                onHide={() => setCandidateModal(false)}
                                show={candidateModel}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                scrollable
                            >
                                <CandidateDetails
                                    job={job}
                                    setCandidateModal={setCandidateModal}
                                    setLoading={setLoading}
                                    setApplied={setApplied}
                                />
                            </Modal>
                            <Modal
                                onHide={() => setEmailVerify(false)}
                                show={emailVerify}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                scrollable
                            >
                                <EmailConfirm
                                    currentUser={currentUser}
                                    setEmailVerify={setEmailVerify}
                                />
                            </Modal>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
                        <div className="content-single content-except">
                            <h5>About Job description</h5>
                        </div>
                        <div className="content-single">
                            <div className='description'
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default JobDescriptionPage_v2
