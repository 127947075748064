import React from 'react';
import { makeRequest } from '../../components/common/RequestAssist/RequestAssist';

let initialState = {
    ...initialState,
    ...{
        user:null,
        email:'',
        role:'',
        firstName:'',
        lastName:'',
        linkedinProfile:'',
        phoneNumber:'',
        emailConfirmed:'',
        userApproved:'',
        location:'',
        slack_member_id:''
    }
}

const reducer = (state, action) => {
  const {type, value, id, file } = action
  switch(type) {
    case 'save-user':
        const formData = new FormData()
        for (var key in value) {
            formData.append(`person[${key}]`, value[key])
        }
        formData.append('person[email_address]', value['email'])
        formData.append('person[audio_video]',(file))
        const url = `/admin/user/${id}`
        sendRequest(formData,url)
        return {...state, ...value}
    default:
        return state

  }
}

async function sendRequest(formData, url) {
  await makeRequest(url, 'put', formData, {
    contentType: 'multipart/form-data',
    loadingMessage: 'Submitting...',
    createResponseMessage: (response) => {
      if(response.message_type == 'success'){
        window.location.href = '/admin/users'
      }
      return {
        message: 'Updated Successfully',
        messageType: 'success',
        loading: false,
        autoClose: true,
    }
    },
    onSuccess: () => {
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
   })
}

// context for user profile admin page
const UserProfileContext = React.createContext(null)

export {initialState, reducer, UserProfileContext}