import moment from 'moment'
import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import DateSelector from '../../../common/DateSelector.js/DateSelector'
import TimePicker from '../../../common/TimePicker/TimePicker'
import './styles/CandidateRecheduleModal.scss'
const InterviewRechedule = ({
    interviewRecheduleModal,
    setInterviewRescheduleModal
}) => {
    const [validationDate, setValidationDate] = useState()
    const [validationTime, setValidationTime] = useState()
    const [dateError, setDateError] = useState()
    const [timeErrors, seTimeErrors] = useState()
    const [interviewTypeId, setInterviewTypeId] = useState('')
    const [saveData, setSaveData] = useState(false)
    const [selectedTimes, setSelectedTimes] = useState([])
    const [timezone, setTimeZones] = useState()
    const [timeError, setTimeError] = useState(false)
    const [amError, setAmError] = useState(false)
    const [disable, setDisable] = useState(false)
    const [moveStage, setMoveStage] = useState('')
    const [moveJobId, setMoveJobId] = useState('')
    const [moveSelectedCandidates, setMoveSelectedCandidates] = useState('')
    const [moveUserId, setMoveUserId] = useState('')

    const [interviews, setInterviews] = useState([
        { id: nanoid(), date: null, time: null, stage: null, timeZone: null },
    ])
    const [dateRange, setDateRange] = useState({
        startDate: moment(new Date()).toDate(),
        endDate: moment(new Date()).toDate(),
    })
    const [backgroundColor, setBackgroundColor] = useState({
        active: false,
        id: null,
        matchDate: null,
    })
    const [selectedDate, setSelectedDate] = useState(
        // backgroundColor.matchDate ? backgroundColor.matchDate : new Date()
    )

    const checktimeZone = (time) => {
        seTimeErrors((time.hour == '00') || (time.hour == '') || (time.timeZone == "Time-zone")  ? true : false)
      }
    return (
        <>
             <Modal
                    onHide={() => setInterviewRescheduleModal(false)}
                    className="edit-job-modal-interview-modal"
                    show={interviewRecheduleModal}
                    // show={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header
                    closeButton
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Modal.Title>Candidate Interview Reschedule</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ marginTop: '24px' }}>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm">
                                    <DateSelector
                                        handleOnSubmit={(date) => {
                                            setValidationDate('')
                                            setDateError(false)
                                            setSelectedDate(date)
                                        }}
                                        submitName="Select"
                                        dateselected={selectedDate}
                                        minDate={new Date()}
                                    />
                                    <p
                                        style={{
                                            color: 'red',
                                            fontSize: '11px',
                                            marginLeft: '2px',
                                        }}
                                    >
                                        {validationDate}
                                    </p>
                                </div>
                                <div class="col-sm">
                                    <TimePicker
                                        selectedTimes={selectedTimes}
                                        handleOnTime={(time) => {
                                            // setValidationDate('')
                                            const clone = selectedTimes
                                            clone[interviews] = time
                                            setSelectedTimes(clone)
                                            setValidationTime('')
                                            checktimeZone(time)
                                            // seTimeErrors(time.hour.length >= 1 ? false : true)
                                            setTimeZones(clone[interviews])
                                            setTimeError(timeError - 1)
                                            setAmError(false)
                                        }}
                                    />
                                    <p
                                        style={{
                                            color: 'red',
                                            fontSize: '11px',
                                        }}
                                    >
                                        {validationTime}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex" style={{ marginTop: '-50px' }}>
                            <div className="modal-close-btn">
                                <Button
                                    variant="secondary"
                                    onClick={() => setInterviewRescheduleModal(false)}
                                >
                                    Close
                                </Button>
                            </div>
                            <div className="modal-close-btn">
                                <Button
                                    variant="secondary"
                                    // onClick={submitScheduleData}
                                    disabled={disable}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                            <Button variant="primary">Save changes</Button>
                        </Modal.Footer> */}
                </Modal>

        </>
    )
}

export default InterviewRechedule