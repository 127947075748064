import React from 'react'

import { SwitchInput, SwitchLabel, SwitchButton } from './styles/ToggleSwitch.styled'

const sizeTypes = {
  small: {
    boxWidth: '40px',
    boxHeight: '20px',
    buttonSize: '10px',
  },
  medium: {
    boxWidth: '60px',
    boxHeight: '30px',
    buttonSize: '20px',
  },
  large: {
    boxWidth: '80px',
    boxHeight: '40px',
    buttonSize: '30px',
  },
}

const Switch = ({ id, state, setState, size, disabled=false }) => {
  const handleToggle = () => {
    setState(!state)
  }
  const sizeType = size ? sizeTypes[size] : sizeTypes['small']
  return (
    <>
      <SwitchInput
        className="switch-checkbox"
        id={id}
        type="checkbox"
        checked={!state}
        disabled={disabled}
        onChange={handleToggle}
      />
      <SwitchLabel className="switch-label" htmlFor={id} size={sizeType}>
        <SwitchButton className="switch-button" size={sizeType} />
      </SwitchLabel>
    </>
  )
}

export default Switch
