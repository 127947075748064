import React from 'react'
import { Button } from 'react-bootstrap';
import './styles/RecruiterHome.styled.scss'
import topImage from '../../../../../app/assets/images/recruiter_page_assets/rercruiter_banner.png'
import centerImage from '../../../../../app/assets/images/recruiter_page_assets/top-ai-header-new.jpg'
import lastLongImage from '../../../../../app/assets/images/recruiter_page_assets/full-background.jpg'
import searchFirst from '../../../../../app/assets/images/icons/search-1.png'
import searchSecond from '../../../../../app/assets/images/icons/search-2.png'
import personThree from '../../../../../app/assets/images/icons/heand.png'

const RecruiterHome = () => {
    return (
        <>
            <div>
            {/*1st Image Section */}
            <div
                className="d-flex align-items-center row"
                style={{
                    backgroundImage: `url(${topImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '90%',
                    height: '660px',
                    marginLeft:'5%', marginRight: '5%'
                }}
            >
                <div className="container text-white py-5" style={{ marginLeft: '2%', marginRight: '16%'}}>
                    <div className="row">
                       <div className="col-12 col-md-9 text-start px-3">
                            <h1 className="text-danger fw-normal mb-2">What is CardinalTalent.AI?</h1>
                            <h2 className="fs-4" style={{color: '#000'}}>
                                Effortlessly Discover Top-Tier Talent with CardinalTalent.ai – AI-powered matching, zero fees, vast talent network.
                            </h2>
                            <p className="mt-3" style={{color: '#212224c2'}}>
                                CardinalTalent.ai is a leading AI-powered talent acquisition platform designed for recruiters to discover, match,
                                and place top-tier talent with ease. Our platform simplifies the recruiting process, allowing you to focus on making
                                placements and growing your business—without hidden fees or unnecessary overhead.
                            </p>
                            <div className='d-flex justify-content-end'>
                                <a href='/signup/recruiter'><Button variant='primary' style={{background: '#1A75FF'}}>Sign up</Button></a>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                          
                        </div>
                    </div>
                </div>
            </div>

            <div className="row align-items-center" style={{marginLeft:'5%', marginRight: '5%', marginTop: '1.5%'}}>
                {/*2nd Image Section */}
                <div className="col-12 col-md-6">
                    <img
                        src={centerImage}
                        alt="CardinalTalent AI"
                        className="img-fluid"
                        style={{ width: '100%', height: 'auto' }}
                    />
                    <div className='d-flex justify-content-end mt-2'>
                         <a href='/marketing_terms'><Button variant='outline-primary'>View Full Commission Details</Button></a>
                    </div>
                </div>
                <div className="col-12 col-md-6 text-start px-3">
                    <h1 className="text-black fw-normal mb-2">Maximize Your Earnings with Our Generous Commission Structure</h1>
                    <h4 className="fs-4">
                        Earn More with Every Placement—Up to $50,000
                    </h4>
                    <p className="mt-3 new-darkl-light">
                        As an <strong>approved CardinalTalent Recruiter Partner</strong>, you have the unique opportunity to
                        <em>"own"</em> any candidate or client you invite to the platform for 1 year. This means you benefit from
                        ongoing rewards as your network grows. Here's how you can maximize your earnings:
                    </p>

                    <ul className="list-bullet mt-3">
                        <li className="new-darkl-light">
                            <strong>Earn 10% to 40%</strong> of the placement fee for candidates you introduce.
                        </li>
                        <li className="new-darkl-light">
                            <strong>Earn up to 40%</strong> of revenue generated from new clients you bring in.
                        </li>
                        <li className="new-darkl-light">
                            Your commission rates are based on your <strong>level of involvement</strong>, giving you flexibility and
                            control over your earnings. .
                        </li>
                    </ul>

                    <p className="mt-3 new-darkl-light">
                        With CardinalTalent, your efforts are rewarded—join now and take advantage
                        of this lucrative opportunity.
                    </p>
                </div>
            </div>

            <div
                className="d-flex align-items-center row"
                style={{
                    backgroundImage: `url(${lastLongImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '90%',
                    height: '400px',
                    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
                    marginLeft:'5%', marginRight: '5%', marginTop: '1.5%'
                }}
            >
                <div className="container text-white py-5" style={{ marginLeft: '2%', marginRight: '16%'}}>
                    <div className="row">
                        <div className="col-12 col-md-6 text-start text-md-start px-3">
                            <h2 className="fw-normal">Supercharge Your Recruiting Process - AI-Powered, Zero Fees</h2>
                            <p className="mt-3">
                                At CardinalTalent, we put recruiters first. Our AI-powered platform provides access to a vast talent pool, helping you effortlessly match candidates with top companies. By automating administrative tasks, we give you more time to focus on what really matters—making successful placements. Plus, with zero hidden fees, you keep 100% of your commission, ensuring that your hard work is fully rewarded.
                            </p>
                        </div>
                        <div className="col-12 col-md-6">
                          
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-center mt-5 mb-5 mt-5">
                <h2>How Our AI Interviewer Accelerates Your Recruiting Process</h2>
            </div>

            {/* Advantages Section */}
            <div className="" style={{marginLeft:'5%', marginRight: '5%', marginTop: '2%'}}>
                <div className="row">
                    {[
                        {
                            img: searchFirst,
                            title: 'AI-Powered Screening',
                            text: 'Uses cutting-edge AI to quickly screen and match candidates, streamlining the hiring process and ensuring accurate placements.',
                        },
                        {
                            img: searchSecond,
                            title: 'Customizable Interview Process',
                            text: "Enables recruiters to personalize the interview process for each role, ensuring a smooth and efficient hiring experience.",
                        },
                        {
                            img: personThree,
                            title: 'Faster Placements',
                            text: 'Accelerates the recruiting process by leveraging AI to quickly match candidates with the right roles, reducing time-to-hire and boosting efficiency.',
                        },
                    ].map((item, index) => (
                        <div
                            key={index}
                            className="col d-flex align-items-center justify-content-space-between mx-2"
                            style={{
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '15px',
                                transition: 'transform 0.3s ease',
                                textAlign: 'center',
                            }}
                        >
                            <div style={{ flexDirection: 'column' }}>
                                <img src={item.img} alt="Icon" style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
                                <h5 className='ml-2 text-medium'><strong>{item.title}</strong></h5>
                                <p style={{padding: '6%', fontSize: '19px', color: '#6a6462', paddingTop: '5px'}}>{item.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="text-center mt-5 mb-5">
                <h2>How it works</h2>
            </div>

            {/* Steps Section */}
            <div className="mt-5 mb-5" style={{marginRight: '5%', marginLeft: '5%'}}>
                <div className="row">
                    {[
                        {
                            img: searchFirst,
                            title: 'Create Your Recruiter Profile',
                            text: [
                                'Set up your professional profile in minutes',
                                'Highlight your industry expertise and successful placements',
                                'Customize your preferences for job types and candidates',
                                'Connect your existing professional networks for a broader reach'
                            ],
                        },
                        {
                            img: searchSecond,
                            title: 'Access the Talent Pool',
                            text: [
                                'Dive into our vast, AI-curated pool of top-tier candidates',
                                'Use advanced filters to narrow down your search based on skills, experience, and cultural fit',
                                'Leverage our AI-powered matching system to find the perfect candidates for your open roles',
                                'Review detailed candidate profiles, including AI-generated insights and compatibility scores'
                            ],
                        },
                        {
                            img: personThree,
                            title: 'Make Placements, Earn Commissions',
                            text: [
                                'Present your curated candidates to clients with confidence',
                                'Track the hiring process in real-time through our intuitive dashboard',
                                'Close deals faster with our streamlined communication and negotiation tools',
                                'Receive your full commission instantly upon successful placement—no fees, no deductions'
                            ],
                        },
                    ].map((item, index) => (
                        <div
                            key={index}
                            className="col-md-4 justify-content-between mb-3"
                            style={{
                                padding: '15px',
                                transition: 'transform 0.3s ease'
                            }}
                        >
                            <div style={{ flexDirection: 'row' }}>
                                <div className='d-flex '>
                                    <span className={`custom-span-box-${index + 1}`}>{index + 1}</span>
                                    <h5 className='ml-2 text-medium custom-span-head' ><strong>{item.title}</strong></h5>
                                </div>
                                <ul className='custom-ul-box'>
                                    {item.text.map((text, index) => (
                                        <li key={index}>{text}</li>
                                    ))}
                                </ul>
                                {/* <p>{item.text}</p> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </>
    )
}

export default RecruiterHome
