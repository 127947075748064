import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/CtrsDashboard.scss'
import validator from 'validator'
import Button from '../../Admin/shared/Button'
import DateSelector from '../../../common/DateSelector.js/DateSelector'
import AsyncCreatableSelect from 'react-select/async-creatable'
import ReactSelect from 'react-select'
import moment from 'moment'
import axios from 'axios'

const AddCtrs = ({teamEntry,onHide,method,fetchData}) => {
	const [validationError, setValidationError] = useState({})
	const [entryData, setEntryData] = useState({
		name: teamEntry?.name ? teamEntry?.name : '',
		email: teamEntry?.email ? teamEntry?.email : '',
		date: teamEntry?.date ? teamEntry?.date : '',
		message: teamEntry?.message ? teamEntry?.message : '',
	})
	const [btnDisabled, setBtnDisabled] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date())
	const handleChange = (e) => {
		setValidationError({})
		// setSelectedRecruiter(e.value)
		// sessionStorage.setItem("jobStatus_recruiter", JSON.stringify(e))
	}
	const [modal,setModal] = useState('')
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/admin/ctrs_reports'
			const payload = new FormData()
			if(method === 'put'){
				URL = `/admin/ctrs_reports/${teamEntry.id}`
			}
			if(method == 'mail') {
				URL = `/admin/ctrs_reports/edit`
			payload.append('ctrs_details[ctrs_id]',method == 'mail' ? teamEntry.id : '')
			}
			payload.append('ctrs_details[date]',selectedDate)
			payload.append('ctrs_reports[email]',e?.target.email?.value)
			payload.append('ctrs_reports[name]', e?.target?.name?.value)
			payload.append('ctrs_details[message]',e?.target?.message?.value)
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method == 'mail' ? 'post' : method , payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
              		setBtnDisabled(true)
						onHide()
						fetchData()
				}
			}) 
		}
		// setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		if(!(e?.target?.name?.value?.trim())){
			formIsValid = false;
			errors["name"] = "Name is required field."
		}
		if(!(e?.target?.email?.value?.trim())){
			formIsValid = false;
			errors["email"] = "Email is required."
		}
		  if((method == 'post' || method == 'mail')){

			  if(!(e?.target?.message?.value?.trim())){
				  formIsValid = false;
				  errors["message"] = "message is required field."
				}
			}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<Container> 
				<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
						<Col xs={6} sm={6} lg={6}>
							<span>Name: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={entryData?.name}  onChange={handleChange} className='form-control w-100' name='name' id="name" />
							<label className='error'>
									{validationError && validationError.name}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Email: </span>
							<input  type='email' tabIndex="112" disabled={method == 'mail'} defaultValue={teamEntry?.email} onChange={handleChange} className='form-control w-100' name='email' id="email" />
							<label className='error'>
									{validationError && validationError.email}        
							</label>
						</Col>
						{ (method == 'post' || method == 'mail') && <><Col xs={6} sm={6} lg={6}>
							<span>Select Start Date : </span>
							<DateSelector
								handleOnSubmit={(date) => {
									setSelectedDate(date)
								} }
								submitName="Select"
								dateselected={selectedDate}
								minDate={new Date()} />
						</Col><Col xs={6} sm={6} lg={6}>
								<span>Message: </span>
								<textarea type="text" tabIndex="112" defaultValue={teamEntry?.message} onChange={handleChange} className='form-control w-100' name='message' id="message" />
								<label className='error'>
									{validationError && validationError.message}
								</label>
							</Col></>}
							</Row>
					<Row className="w-100 modal-entry-form-row" >
						<Col xs={3} sm={3} lg={3}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Save</Button>
						</Col>
					</Row>
				</form>
				</Container>
		</>
  )
}

export default AddCtrs;