import React, { useEffect, useReducer, useRef, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import Card from '../../Admin/shared/Card'
import { BlockBody, BlockHeader, ExportCSVButton } from './styles/Dashboard.styled'
import { capitalize } from '../../../../utils'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import P from '../../Admin/shared/P'
import { CSVLink } from "react-csv";

const index = ({sales_details, showDownload}) => {

    const [formContent, setFormContent] = useState(
        {
            leads_limit: sales_details?.leads_limit,
            totalDownloaded: sales_details?.last_record_id,
            showButton: showDownload ? false : true
        }
    )
    const csvExportRef = React.useRef();
    const [showButton, setShowButton] = useState(showDownload ? false : true)
    const [exportDatas, setExportDatas] = useState([])
    const headers = [
        { label: 'First Name', key: 'firstName' },
        { label: 'Last Name', key: 'lastName' },
        { label: 'Email', key: 'email' },
        { label: 'Designation', key: 'designation' },
        { label: 'Current Company', key: 'company' },
        { label: 'LinkedIn URL', key: 'linkedinUrl' },
        { label: 'Location', key: 'location' },
        { label: 'Skills', key: 'skills' },
    ];
    
    const exportDataMethod = async () => {
        const url = '/sales/get_leads.json'
        const response = await makeRequest(
            `${url}`,
            'get',
            {}
        )
        if(response?.data?.leads){
            setExportDatas(response?.data?.leads?.map((data,key)=> ({
                firstName: data?.first_name && capitalize(data?.first_name),
                lastName: data?.last_name && capitalize(data?.last_name),
                email: data?.email,
                designation: data?.designation && capitalize(data?.designation),
                company: data?.current_company,
                linkedinUrl: data?.linkedin_profile_url,
                location: data?.location,
                skills: data?.skills,
            })))
            window.location.reload();
            csvExportRef?.current?.link.click();
        }
        setShowButton(true)
    }
    
    return (
        <Card>
            <P size="30px" height="41px">
                Sales Dashboard
            </P>
            <div style={{ marginTop: '50px', display: 'flex' }}>
                <div style={{ marginLeft: '50px' }}>
                    <BlockHeader width={250}>
                        <P size="16px" height="27px" color={"red"}
                        >
                            Leads Limit
                        </P>
                    </BlockHeader>
                    <BlockBody>
                        <P
                            size="18px"
                            height="38px"
                            color="#3F446E"
                            marginTop="5px"
                        >
                            {formContent.leads_limit+' Per Day'}
                        </P>
                    </BlockBody>
                </div>
                <div style={{ marginLeft: '50px' }}>
                    <BlockHeader width={250}>
                        <P size="16px" height="27px" color={"orange"}
                        >
                            Total Downloaded
                        </P>
                    </BlockHeader>
                    <BlockBody>
                        <P
                            size="18px"
                            height="38px"
                            color="#3F446E"
                            marginTop="5px"
                        >
                            {formContent.totalDownloaded}
                        </P>
                    </BlockBody>
                </div>
                <div style={{ marginLeft: '50px' }}>
                    <BlockHeader width={250}>
                        <P size="16px" height="27px" color={"blue"}
                        >
                            Download File
                        </P>
                    </BlockHeader>
                    <BlockBody>
                        <ExportCSVButton className={`ml-3 btn`} onClick={exportDataMethod} disabled={showButton} > Download Leads </ExportCSVButton>
                    </BlockBody>
                </div>
                            
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"leads.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
        </Card>
    )
}

export default index