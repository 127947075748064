import React, { useState, useEffect } from 'react'
import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './styles/CtrsDashboard.scss'
import Select from 'react-select'
import styles from '../../Admin/styles/JobsStatusPage.module.scss'
import feather from 'feather-icons'
import LinkedinIcon from '../../../../../assets/images/icons/linkedin.png'
import Util from '../../../../utils/util'
import Modal from 'react-bootstrap/Modal'
import AddDecisionMaker from './AddDecisionMaker'
import SearchBar from '../../../common/SearchBar/SearchBar'
import moment from 'moment'
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import axios from 'axios'
import { CSVLink } from 'react-csv'
import { capitalize } from '../../../../utils'

const DecisionMakers = (ctrs_email_list) => {
    const team_lists = []
    const team_lead_list = []
    const recruiters = []
    const queryParams = new URLSearchParams(window.location.search)
    var active_page = window.sessionStorage.getItem('teamListActivePage')
    var query = window.sessionStorage.getItem('query')
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    let jobStatus_start_date = sessionStorage.getItem("jobStatus_start_date");
    let jobStatus_end_date = sessionStorage.getItem("jobStatus_end_date")
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const jobId = queryParams.get("job")
    const [teams, setTeams] = useState([])
    const [totalTeams, setTotalTeams] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [teamUserId, setTeanUserId] = useState()
    const [show, setShow] = useState(false)
    const [showUpload, setShowUpload] = useState(false)
    const [showMember, setShowMember] = useState(false)
    const [method, setMethod] = useState('post')
    const [entry, setEntry] = useState({})
	const [validationError, setValidationError] = useState({})
	const [btnDisabled, setBtnDisabled] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setDate] = useState([])
    const [selectedFile, setSelectedFile] = useState(null)
    let email_seacrh = sessionStorage.getItem("email_seacrh")
    const [emailList,setTeamList] = useState([{value: 'All', label: 'All'},...ctrs_email_list.ctrs_email_list])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate : jobStatus_start_date ? jobStatus_start_date : moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : jobStatus_end_date ? jobStatus_end_date : moment().toDate()})
    const [selected,setSelected] = useState((jobId)? recruiters.filter((option) => option.value == jobId) : email_seacrh && email_seacrh != "null" ? JSON.parse(email_seacrh) : {value: 'all', label: 'All'})
    const [selectedEmail,setSelectedEmail] = useState(email_seacrh && email_seacrh != "null" ? JSON.parse(email_seacrh).label : jobId ? jobId : '')
    const [selectedSource,setSelectedSource] = useState('all')
    const [fetchApi, setFetchApi] = useState()
    const [fetchOrgAPi, setFetchOrgApi] = useState()
    const [exportDatas, setExportDatas] = useState([])
    const csvExportRef = React.useRef()
    const headers = [
        { label: 'first_name', key: 'first_name' },
        { label: 'last_name', key: 'last_name' },
        { label: 'email', key: 'email' },
        { label: 'phn_number', key: 'phn_number' },
        { label: 'location', key: 'location' },
        { label: 'position', key: 'position' },
        { label: 'company_name', key: 'company_name' },
        { label: 'linkedin_url', key: 'linkedin_url' },
    ]
    const filterOptions = [
        { value: 'all', label: 'All'},
        { value: 'linkedin', label: 'LinkedIn'},
        { value: 'facebbok', label: 'Facebbok'},
        { value: 'naukri', label: 'Naukri'},
        { value: 'glassdoor', label: 'Glassdoor'},
        { value: 'indeed', label: 'Indeed'},
        { value: 'zoominfo', label: 'Zoominfo'}
    ]
    const fetchData = () => {
        setLoading(true)
        const url = `/admin/decision_makers.json`
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("jobStatus_start_date", start_date)
        sessionStorage.setItem("jobStatus_end_date", end_date)
        window.sessionStorage.setItem('teamListActivePage', activePage)
        makeRequest(
            url,
            'get',
            {
                params: {
                    page: activePage + 1,
                    query: query,
                    search: searchTerm,
                    name: selectedEmail,
                    start_date: start_date,
                    end_date: end_date,
                    source: selectedSource

                },
            },
            {
                contentType: 'application/json',
                loadingMessage: 'Fetching...',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                    setDate(res?.data?.data)
                    let arr = res?.data?.data?.map((item) => item.ctrs_detail[item?.ctrs_detail.length -1])
                    setTeams(
                        res.data.data.map((item) => ({
                            id: item.id,
                            first_name: item.first_name ? capitalize(item?.first_name) : '',
                            last_name: item.last_name ? capitalize(item?.last_name) : '',
                            name: item.first_name + ' ' + ((item?.last_name &&  item?.last_name != '') ? item?.last_name  : ''),
                            email: item.email ? item?.email : '',
                            phone_number: item?.phn_number,
                            location: item.location ? capitalize(item?.location) : '',
                            position: item.position ? capitalize(item?.position) : '',
                            company_name: item.company_name ? capitalize(item?.company_name) : '',
                            linkedin_url: (item?.linkedin_url != null && item?.linkedin_url != '') ? <a href={item?.linkedin_url} target='_blank'><img src={LinkedinIcon} width={22}/></a> : '',
                            source: item?.source,
                            industry: item?.industry,
                            company_size: item?.company_size,
                            created_at: moment(item?.created_at).format('DD MMM-YYYY')
                            })
                        )
                    )
                setPageCount(res?.data?.total_pages)
                setTotalTeams(res?.data?.total_count)
                setCurrentCounts(res?.data?.current_counts)
                setStartRecord(activePage * res?.data?.per_page)
                setLoading(false)
                },
            }
        )
    }
    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage, selectedEmail, dateRange, selectedSource])
    
    const deleteTeam = async (id) => {
        const URL = `/admin/decision_makers/${id}`
        await makeRequest(URL, 'delete', '', {
            createResponseMessage: (res) => {
                return {
                    message: res?.message,
                    messageType: res?.messageType
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: () => {
                fetchData()
            },
        })
    }
                
    const addCtrs = () => {
        setEntry({})
        setMethod('post')
        setShow(true)
    }

    const addUpload = () => {
        setValidationError('')
        setShowUpload(true)
    }

    const onHide = () => {
        setEntry({})
        setMethod('post')
        setShow(false)
        setShowMember(false)
    }

    const onHideUpload = () => {
        setShowUpload(false)
    }

    const getUpdateData = (id) => {
        let getTeam = data.filter((row) => row.id == id)
        if (getTeam?.length > 0) {
            setMethod('put')
            setEntry(getTeam[0])
            setShow(true)
        }
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0])
        setValidationError('')
    }

    const handleUploade = async () => {
        if(validateForm(selectedFile)){
        const URL = '/admin/decision_makers/upload'
        const payload = new FormData()
        payload.append('file', selectedFile);
        makeRequest(URL, 'post',payload, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createResponseMessage: (res) => {
              setSelectedFile(null)
            setShowUpload(false)
            setTimeout(() => {
                fetchData()
            }, 2500);
          return {
              message: res?.message,
              messageType: res?.messageType
          }
        }
       })
      }
    }

      const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		if(!(e)){
			formIsValid = false;
			errors["file"] = "file is required."
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

    function generateCSVContent() {
        // Generate the CSV content
        const csvContent = 'first_name,last_name,email,phone_number,location,position,company_name,linkedin_url,source,industry,company_size'
        // Create a Blob object with the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });
        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = 'sample_decision_makers.csv'; // Set the file name
        // Programmatically click the anchor element to trigger the download
        anchor.click();
      }
      
    return(
        <>
        <Card>
            <div className="d-flex justify-content-between align-items-center w-100" style={{ marginBottom: '10px' }}>
                <P
                    size="28px"
                    height="38px"
                    color="#1D2447"
                    style={{ width: '540px' }}
                >
                    Decision Makers
                </P>
                <div style={{ flexGrow: 1 }} />
                <Button onClick={addCtrs} className="ml-3">
                    Add New
                </Button>
                <Button onClick={addUpload} className="ml-3">
                    Upload CSV
                </Button>
                
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"Sample_decision_makers.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                  <div style={{display:'flex'}}>
                    <div>
                        <label>Source</label>
                        <Select
                            defaultValue={selectedSource}
                            options={filterOptions}
                            onChange={(event)=>{
                                setSelectedSource(event.value)
                            }}
                            name="job_name"
                            className={styles.colourStyles + " " + 'email-select'}
                        />
                    </div>
                    <div>
                    <label>Emails</label>
                    <Select
                        defaultValue={selected}
                        options={emailList}
                        onChange={(event)=>{
                            setSelectedEmail(event.label)
                            sessionStorage.setItem("email_seacrh", JSON.stringify(event))
                        }}
                          name="job_name"
                          className={styles.colourStyles + " " + 'email-select'}
                          />
                        </div>
                    </div>
                </Col>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalTeams > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalTeams}</DisplayPagination>
                    }
                </Col>
            </Row>
            <Table
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Name',
                        field: 'name',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Emails',
                        field: 'email',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Phone Number',
                        field: 'phone_number',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Location',
                        field: 'location',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Position',
                        field: 'position',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Company Name',
                        field: 'company_name',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'LinkedIn URL',
                        field: 'linkedin_url',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Source',
                        field: 'source',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Industry',
                        field: 'industry',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Company Size',
                        field: 'company_size',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Created On',
                        field: 'created_at',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Action',
                        field: 'options',
                        editable: false,
                        type: 'text'
                    },
                ]}
                rowValues={teams.map((team) => ({
                    ...team,
                    member: team?.member?.map((t) =>
                        t?.user?.id != team?.owner_id ? (
                            <span className="members">
                                {' '}
                                {Util.fullName(
                                    t?.user?.first_name,
                                    t?.user?.last_name
                                )}{' '}
                            </span>
                        ) : (
                            ''
                        )
                    ),
                    status: Util.capitalize(team?.status),
                }))}
                showEditOption
                activePage={activePage}
                deleteAction={deleteTeam}
                goToEditPage={(id) => {
                    setTeanUserId(id)
                    getUpdateData(id)
                }}
                loading={loading}
            />
            {pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
        </Card>
        <Modal
            onHide={onHideUpload}
            show={showUpload}
            size="md"
        >
            <Modal.Header closeButton className="mt-2 mb-2">
                <Modal.Title id="contained-modal-title-vcenter">
                    {'Upload CSV File'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="w-100">
                    <Col xs={6} sm={6} lg={6}>
                        <input type="file" onChange={handleFileChange} />
                        <label className='error'>
                            {validationError && validationError.file}        
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} lg={12}>
                         <a href='javascript::void(0)' onClick={generateCSVContent}>Download Sample CSV Format</a>
                    </Col>
                    <Col xs={12} sm={12} lg={12}>
                        <p style={{color:'red',marginTop: '10px'}}>Note: first_name,last_name,location,position,company_name and source is reuired field.</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-default" onClick={handleUploade}>Upload</button>
            </Modal.Footer>
        </Modal>

        <Modal
            onHide={onHide}
            show={show}
            size="lg"
        >
            <Modal.Header closeButton className="mt-2 mb-2">
                <Modal.Title id="contained-modal-title-vcenter">
                    {method === 'put'
                        ? 'Update' : method === 'mail' ? 'Follow Up'
                        : 'Add Decision Maker'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddDecisionMaker
                    recruiter_list={recruiters}
                    // org_list={org_list}
                    teamEntry={entry}
                    onHide={onHide}
                    method={method}
                    team_lists={team_lists}
                    fetchData={fetchData}
                    team_lead_list={team_lead_list}
                    teamUserId={teamUserId}
                    fetchApi={fetchApi}
                    fetchOrgAPi={fetchOrgAPi}
                    setFetchApi={setFetchApi}
                    setFetchOrgApi={setFetchOrgApi}
                ></AddDecisionMaker>
            </Modal.Body>
        </Modal>
    </>
    )
}

export default DecisionMakers