import React, { useState, useEffect } from 'react'
// import './../styles/communities.scss'
// import moment from 'moment'
import feather from 'feather-icons'
import image1 from '../../../../../assets/v2/imgs/page/homepage3/banner-main.png'
import image2 from '../../../../../assets/v2/imgs/page/homepage3/banner-sm1.png'
import image3 from '../../../../../assets/v2/imgs/page/homepage3/banner-sm2.png'
import image4 from '../../../../../assets/v2/imgs/page/homepage3/banner-sm3.png'
import image5 from '../../../../../assets/v2/imgs/theme/icons/marketing.svg'
import image6 from '../../../../../assets/v2/imgs/theme/icons/content-writer.svg'
import image7 from '../../../../../assets/v2/imgs/theme/icons/marketing-director.svg'
import image8 from '../../../../../assets/v2/imgs/theme/icons/system-analyst.svg'
import image9 from '../../../../../assets/v2/imgs/theme/icons/business-development.svg'
import image10 from '../../../../../assets/v2/imgs/theme/icons/proof-reading.svg'
import image11 from '../../../../../assets/v2/imgs/theme/icons/testing.svg'
import image12 from '../../../../../assets/v2/imgs/page/about/aimatch.png'

const HomePage = (props) => {
  const [showMoreCategory, setShowMoreCategory] = useState(false)
  return (
    <>
      <section class="section-box">
        <div class="banner-hero banner-homepage-3">
          <div class="banner-inner">
            <div class="row">
              <div class="col-lg-8">
                <div class="block-banner">
                  <span class="text-small-primary text-small-primary--disk text-uppercase  wow animate__animated animate__fadeInUp">FUTURE OF HIRING</span>
                  <h1 class="heading-banner wow animate__animated animate__fadeInUp">Discover top talent with <span className='text-primary'>CardinalTalent</span></h1>
                  <div class="banner-description mt-30 wow animate__animated animate__fadeInUp">Upgrade your hiring process with CardinalTalent, a cutting-edge AI-powered platform. Designed to simplify talent acquisition.</div>
                  <div class="mt-30">
                    <div class="box-button-shadow"><a href="/signup/employer" class="btn btn-default">Sign Up For free</a></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="banner-imgs">
                  <img alt="jobhub" src={image1} class="img-responsive shape-1" />
                  <span class="banner-sm1"><img alt="jobhub" src={image2} class="img-responsive shape-3" /></span>
                  <span class="banner-sm2"><img alt="jobhub" src={image3} class="img-responsive shape-2" /></span>
                  <span class="banner-sm3"><img alt="jobhub" src={image4} class="img-responsive shape-2" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-box">
        <div class="container">
          <div class="row align-items-end">
            <div class="col-lg-7">
              <h2 class="section-title mb-20 wow animate__animated animate__fadeInUp">1M* Top Tech Talent</h2>
              <p class="text-md-lh28 color-black-5 wow animate__animated animate__fadeInUp">Accelerate your hiring with our advanced system that highlights the most suitable candidates immediately.</p>
            </div>
            <div class="col-lg-5 text-lg-end text-start wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
              <a href="job-grid-2.html" class="mt-sm-15 mt-lg-30 btn btn-border icon-chevron-right">Browse all</a>
            </div>
          </div>
          <div class="row mt-70">
            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
              <div class="card-grid hover-up wow animate__animated animate__fadeInUp">
                <div class="text-center">
                  <a href="job-grid.html">
                    <figure><img alt="jobhub" src={image5} /></figure>
                  </a>
                </div>
                <h5 class="text-center mt-20 card-heading"><a href="job-grid.html">Marketing & Communication</a>
                </h5>
                <p class="text-center text-stroke-40 mt-20">156 Available Candidate</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
              <div class="card-grid hover-up wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                <div class="text-center">
                  <a href="job-grid.html">
                    <figure><img alt="jobhub" src={image6} /></figure>
                  </a>
                </div>
                <h5 class="text-center mt-20 card-heading"><a href="job-grid.html">Content <br />Writer</a></h5>
                <p class="text-center text-stroke-40 mt-20">268 Available Candidate</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-12 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
              <div class="card-grid hover-up">
                <div class="text-center">
                  <a href="job-grid.html">
                    <figure><img src={image7} alt="jobhub" /></figure>
                  </a>
                </div>
                <h5 class="text-center mt-20 card-heading"><a href="job-grid.html">Marketing <br />Director</a></h5>
                <p class="text-center text-stroke-40 mt-20">145 Available Candidate</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
              <div class="card-grid hover-up wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="text-center">
                  <a href="job-grid.html">
                    <figure><img src={image8} alt="jobhub" /></figure>
                  </a>
                </div>
                <h5 class="text-center mt-20 card-heading"><a href="job-grid.html">System <br />Analyst</a></h5>
                <p class="text-center text-stroke-40 mt-20">236 Available Candidate</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
              <div class="card-grid hover-up wow animate__animated animate__fadeInUp">
                <div class="text-center">
                  <a href="job-grid.html">
                    <figure><img src={image9} alt="jobhub" /></figure>
                  </a>
                </div>
                <h5 class="text-center mt-20 card-heading"><a href="job-grid.html">Digital<br /> Designer</a></h5>
                <p class="text-center text-stroke-40 mt-20">56 Available Candidate</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
              <div class="card-grid hover-up wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                <div class="text-center">
                  <a href="job-grid.html">
                    <figure><img src={image10} alt="jobhub" /></figure>
                  </a>
                </div>
                <h5 class="text-center mt-20 card-heading"><a href="job-grid.html">Market <br />Research</a></h5>
                <p class="text-center text-stroke-40 mt-20">168 Available Candidate</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
              <div class="card-grid hover-up wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="text-center card-img">
                  <a href="job-grid.html">
                    <figure>
                      <img src={image11} alt="jobhub" />
                    </figure>
                  </a>
                </div>
                <h5 class="text-center mt-20 card-heading"><a href="job-grid.html">Human<br /> Resource</a></h5>
                <p class="text-center text-stroke-40 mt-20">628 Available Candidate</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
              <div class="card-grid hover-up wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="text-center mt-15">
                  <h3>18,265+</h3>
                </div>
                <p class="text-center mt-30 text-stroke-40">Candidate are waiting for you</p>
                <div class="text-center mt-30">
                  <div class="box-button-shadow"><a href="job-grid.html" class="btn btn-default">Explore more</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-box mt-90">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="box-image-findjob box-image-about ml-0">
                <figure><img alt="jobhub" src={image12} /></figure>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="pl-90">
                {/* <span class="text-blue text-uppercase wow animate__animated animate__fadeInUp">About us</span> */}
                <h5 class="heading-36 mb-30 mt-10 wow animate__animated animate__fadeInUp">AI-Powered Recommendations</h5>
                <p class="text-md mb-10 wow animate__animated animate__fadeInUp">
                Manatal AI Engine simplifies the whole hiring process by suggesting the best candidates for a given job while automating redundant tasks.
                </p>
                <h5 class="heading-md-regular mt-30 mb-10 wow animate__animated animate__fadeInUp">Matching recommendations</h5>
                <p class="text-md wow animate__animated animate__fadeInUp">
                Score candidates' profiles based on job requirements to facilitate your screening process.
                </p>
                <h5 class="heading-md-regular mt-30 mb-10 wow animate__animated animate__fadeInUp">Candidates' profiles enrichment</h5>
                <p class="text-md wow animate__animated animate__fadeInUp">
                Enrich candidates' profiles with LinkedIn and other social media data for better matching recommendations.
                </p>
                <div class="mt-50 wow animate__animated animate__fadeInUp">
                  <a href="job-grid-2.html" class="mt-sm-15 mt-lg-30 btn btn-primary">Try For Free</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default HomePage