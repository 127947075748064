import React, { useEffect, useState } from 'react'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from 'react-data-table-component-footer'
import moment from 'moment'
import Select from 'react-select'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import P from './shared/P'
import Card from './shared/Card'
import { Col, Row, Spinner } from 'react-bootstrap'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import styles from './styles/JobsStatusPage.module.scss'
import { CSVLink } from 'react-csv'
import './styles/DataTableCustom.scss'
import { capitalize } from '../../../utils'

const RedClientsAnalyticPage = () => {
    const [organization, setOrganization] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [perPage, setPerPage] = useState(25)
    const [totalCount, setTotalCount] = useState()
    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('week').toDate(),
        endDate: moment().toDate(),
    })
    const [loading, setLoder] = useState(true)
    const [exportDatas, setExportDatas] = useState([])
    const csvExportRef = React.useRef()
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [startRecord, setStartRecord] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const handleDate = (date) => {
        setDateRange(date)
    }
    const [defaultselected,setdefaultselected] = useState({value: '', label: 'All'})
    const [organizationList,setOrganizationList] = useState([{value: '', label: 'All'},{value: 'hourly', label: 'Hourly'},{value: 'contingency', label: 'Contingency'}])
    const [organizationType,setOrganizationType] = useState('')
    console.log("🚀 ~ file: RedClientsAnalyticPage.js:39 ~ RedClientsAnalyticPage ~ organizationType", organizationType)

    const fetchData = async () => {
        setLoder(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date = moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = '/admin/red_clients_analytics.json'
        const response = await makeRequest(
            `${url}?page=${activePage}&organizationType=${organizationType}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        let sr_no = (activePage - 1) * perPage
        setPageCount(response.data.total_pages)
        setTotalCount(response.data.total_count)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setOrganization(
            response.data.organizations.map(
                (organization) => (
                    (sr_no = sr_no + 1),
                    {
                        id: sr_no,
                        candidateName: organization.candidate_name,
                        orgName: organization.org_name,
                        orgType: organization?.org_type
                            ? capitalize(organization.org_type)
                            : '',
                        jobName: organization.job_name,
                        recruiterName: organization.recruiter_name,
                        accountManagerName: organization.account_manager_name ? capitalize(organization.account_manager_name) : '',
                        movedByName: organization.moved_by_name,
                        lastDate: organization.last_date ? moment(organization.last_date).format(
                            'MMM DD, YYYY'
                        ) : '',
                    }
                )
            )
        )
        setLoder(false)
    }

    const headers = [
        { label: 'Candidate Name', key: 'candidateName' },
        { label: 'Organization', key: 'orgName' },
        { label: 'Organization Type', key: 'orgType' },
        { label: 'Job', key: 'jobName' },
        { label: 'Recruiter', key: 'recruiterName' },
        { label: 'Account Manager', key: 'accountManagerName' },
        { label: 'Moved By', key: 'movedByName' },
        { label: 'Hired Date', key: 'hiredDate' },
        { label: 'Joining Date', key: 'joiningDate' },
    ]

    const exportDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date = moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = '/admin/hire_analytics.json'
        const response = await makeRequest(
            `${url}?start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setExportDatas(
            response?.data?.candidates.map((data, key) => ({
                id: key,
                candidateName: data.candidate_name,
                orgName: data.org_name,
                orgType: data.org_type,
                jobName: data.job_name,
                recruiterName: data.recruiter_name,
                accountManagerName: data.account_manager_name,
                movedByName: data.moved_by_name,
                hiredDate: moment(data.hired_date).format('MMM DD, YYYY'),
                joiningDate: data.joining_date
                    ? moment(data.joining_date).format('MMM DD, YYYY')
                    : '',
            }))
        )
        csvExportRef?.current?.link.click()
    }

    const handleSort = async (column, sortDirection) => {
        if (column.sortField != undefined && sortDirection != undefined) {
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    useEffect(() => {
        fetchData()
    }, [dateRange, activePage, sortDirection, sortField, organizationType])

    const columns = [
        {
            name: 'No.',
            selector: (row) => row.id,
            maxWidth: '4%',
            minWidth: '4%',
        },
        {
            name: 'Organization Name',
            selector: (row) => row.orgName,
            cell: (row) => <div title={row.orgName}>{row.orgName}</div>,
            maxWidth: '20%',
            minWidth: '20%',
            wrap: true,
            sortable: true,
            sortField: 'org_name',
        },
        {
            name: 'Organization Type',
            selector: (row) => row.orgType,
            cell: (row) => <div title={row.orgType}>{row.orgType}</div>,
            maxWidth: '15%',
            minWidth: '15%',
            wrap: true,
            sortable: true,
            sortField: 'org_type',
        },
        {
            name: 'Account Manager',
            selector: (row) => row.accountManagerName,
            cell: (row) => <div title={row.accountManagerName}>{row.accountManagerName}</div>,
            maxWidth: '20%',
            minWidth: '20%',
            wrap: true,
            sortable: true,
            sortField: 'account_manager_name',
        },
        {
            name: 'Last Hired Candidate',
            selector: (row) => row.candidateName,
            cell: (row) => <div title={row.candidateName}>{row.candidateName}</div>,
            maxWidth: '20%',
            minWidth: '20%',
            wrap: true,
            sortable: true,
            sortField: 'candidate_name',
        },
        {
            name: 'Hired By',
            cell: (row) => row.recruiterName,
            cell: (row) => <div title={row.recruiterName}>{row.recruiterName}</div>,
            maxWidth: '11%',
            minWidth: '11%',
            wrap: true,
            sortable: true,
            sortField: 'recruiter_name',
        },
        {
            name: 'Hired Date',
            selector: (row) => row.lastDate,
            cell: (row) => <div title={row.lastDate}>{row.lastDate}</div>,
            maxWidth: '10%',
            minWidth: '10%',
            wrap: true,
            sortable: true,
            sortField: 'hired_date',
        },
    ]

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            borderColor: 'silver',
            height: 'auto',
            backgroundColor: '#F6F7FC',
            minWidth: '16em',
            maxWidth: '16em',
            minHeight: '40px',
        }),
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P
                    size="28px"
                    height="38px"
                    color="#1D2447"
                    style={{ width: '540px' }}
                >
                    Red Clients
                </P>
            </div>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col
                    lg="2"
                    md="2"
                    sm="12"
                    xs="12"
                    className="d-flex align-items-end"
                >
                    {totalCount > 0 && (
                        <DisplayPagination>
                            Displaying {activePage == 0 ? 1 : startRecord + 1} -{' '}
                            {currentCounts > totalCount
                                ? totalCount
                                : currentCounts}{' '}
                            of {totalCount}
                        </DisplayPagination>
                    )}
                </Col>
                <Col
                    lg="10"
                    md="10"
                    sm="12"
                    xs="12"
                    className="d-flex justify-content-end p-0"
                >
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'grid', marginLeft: '10px' }}>
                            <label>Organization Type</label>
                            <Select
                                defaultValue={defaultselected}
                                options={organizationList}
                                onChange={(event) => {
                                    setOrganizationType(event.value)
                                }}
                                name="organization_type"
                                className={styles.colourStyles}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <DataTable
                title={false}
                persistTableHead={true}
                noDataComponent={
                    <p className="text-center p-3">No record found</p>
                }
                columns={columns}
                data={organization}
                progressPending={loading}
                progressComponent={
                    <P className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner
                            className="spinner"
                            animation="border"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </P>
                }
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={25}
                paginationComponentOptions={{
                    noRowsPerPage: true,
                }}
                onSort={handleSort}
                sortServer
                responsive={true}
                onChangePage={(page) => {
                    console.log('page', page)
                    setActivePage(page)
                }}
            />
        </Card>
    )
}

export default RedClientsAnalyticPage
