import React, { useState, useEffect } from 'react'
import {Card, Col, Image, Row, Spinner, Alert, Button } from 'react-bootstrap'
import styles from './styles/ContactLookup.module.scss'
import JobSearchBar from '../../common/JobSearchBar/JobSearchBar'
import axios from 'axios'
import isURL from 'validator/lib/isURL';
import SweetAlert from 'react-bootstrap-sweetalert';

const ContactLookup = (props) => {
    const [inputValue, setInputValue] = useState('')
    const [inputValueValidation, setInputValueValidation] = useState('')
    const [sendMailMessage, setSendMailMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [personData, setPersonData] = useState({})
    const handleInputChange = (value) => {
        setInputValue(value)
    }
    const [show, setShow] = useState(false);
    const submitJobSearch = (event) => {
        event.preventDefault()
        setPersonData({})
        setShow(false)
        if (inputValue != '' && inputValue != undefined && inputValue != 'undefined') {
            if(isURL(inputValue) && new URL(inputValue)){
                let hostname = new URL(inputValue).hostname
                if(hostname === 'linkedin.com' || hostname === 'www.linkedin.com'){
                    setInputValueValidation('')
                    handleSearch()
                }else{
                    setInputValueValidation('Please enter valid linkedin url')
                }
            }else{
                setInputValueValidation('Please enter valid linkedin url')
            }
        }
        else 
        {
            setInputValueValidation('Please enter linkedin url')
        }
    }
    const handleSearch = async () => {
        const url = `contact_lookups/search`
        const formData = new FormData();
        formData.append('linkedin_url', inputValue);
        setLoading(true)
        const CSRF_Token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        try {
            const response = await axios.post(url,formData,
            {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-Token': CSRF_Token,
                },
            });
            if(response.data.success){
                if(response.data.people){
                    setPersonData(response.data.people)
                }else{
                    setShow(true)
                }
            }else{
                setInputValueValidation(response.data.msg)
            }
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }
    return (
        <div>
            <div className={`${styles.searchHeading}`}>
                <div className="container text-center">
                    <h2>Find Linkedin User</h2>
                    <p>
                       Enter linkedin URL for getting user infomation
                    </p>
                </div>
                <JobSearchBar
                    placeholder="Enter Linkedin URL"
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onEnterPressed={(e) => {
                        submitJobSearch(e)
                    }}
                />
                
            </div>
            <Alert className={styles.alert} >{inputValueValidation}</Alert>
            {(show)&&
            <SweetAlert warning title="Requested information is not available." onConfirm={() => setShow(false)} onCancel={() => setShow(false)}>
                We have sent your inquiry to sourcing team
            </SweetAlert>
            }
            <div className={styles.mainSearchblock}>
                <div className={styles.containers}>
                    {(loading) &&
                        <div className='container' style={{textAlign: 'center'}}>
                            <h2>Loading....</h2> 
                            <div id="loading" className={styles.loading}/>
                        </div>    
                    }
                    {Object.entries(personData).length > 0 &&
                        <Card
                            className={styles.jobCard}
                            style={{
                                backgroundColor: '#F7F7F7',
                            }}
                        >
                            <Card.Body>
                                <Row className="align-items-center">
                                    <Col
                                        lg={3} md={3} sm={3} xs={3}
                                        className="d-sm-flex align-items-sm-center flex-sm-column flex-lg-row text-center"
                                        style={{ margin: 'auto', width: '100%',height:'auto' }}
                                    >
                                        <Image
                                            src='https://d3k1amuy6of1f8.cloudfront.net/assets/tab_icon-1834ac8d8c3ab6cd4c87935e5d8326700c68fba7b9a1f9c94c9d970d33c68474.png'
                                            fluid
                                            className={styles.jobCardImage, 'companyJobLogo'}
                                            style={{ margin: 'auto', width: '60%',height:'auto' }}
                                        />
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} className="px-0">
                                        <Row>
                                            <Col className={''}>
                                                <h5 className={styles.h1CardText}>
                                                    Name
                                                </h5>
                                                {(personData.phone_number) &&
                                                    <h5 className={styles.h3CardText}>
                                                    Phone Number
                                                    </h5>
                                                }
                                                 {(personData.email_address) &&
                                                    <h5 className={styles.h3CardText}>
                                                        Email Id
                                                    </h5>
                                                }
                                                <h5 className={styles.h3CardText}>
                                                    Linkedin Url
                                                </h5>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={7} md={7} sm={7} xs={7} className="px-0">
                                        <Row>
                                            <Col className={''}>
                                                <h5 className={styles.h1CardText}>
                                                 : <span className='ml-1'>{personData.first_name} {personData.last_name}</span>
                                                </h5>
                                                {(personData.phone_number) &&
                                                    <h5 className={styles.h3CardText}>
                                                    : <span className='ml-1'>{personData.phone_number}</span>
                                                    </h5>
                                                }
                                                 {(personData.email_address) &&
                                                    <h5 className={styles.h3CardText}>
                                                        : <span className='ml-1'>{personData.email_address}</span>
                                                    </h5>
                                                }
                                                <a target='_blank' href={personData.linkedin_profile_url} className={styles.h3CardText} style={{cursor: 'pointer'}}>
                                                    : <span className='ml-1'>{personData.linkedin_profile_url}</span>
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    }
                </div>
            </div>
        </div>
    )
}
export default ContactLookup
