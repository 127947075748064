import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

const ButtonComponent = styled.button`
  display: flex;
  flex-direction:row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: ${({ size }) => size.padding};
  font-size: ${({ size }) => size.fontSize};
  font-weight: 400;
  color: ${(props) => props.theme.color};
  border: ${(props) => props.theme.borderColor ? `2px solid ${props.theme.borderColor}` : 'none'};
  background: ${(props) => props.theme.background};
  box-shadow: ${({shadow})=> shadow ? `0px 4px 15px rgb(0 0 0 / 70%)` : ''};
  border-radius: ${({ box }) => (box ? box : '50px')};
`;

const types = {
  contained: {
    primary: {
      color: '#FFFFFF',
      background:
        'linear-gradient(94.67deg, #4C68FF -1.19%, #6077F4 53.94%, #8185FF 102.59%)',
    },
    success: {
      background:
        'linear-gradient(100.84deg, #A6E9B0 0%, #7FD3B5 55.73%, #57C2AF 100%);',
      color: '#FFFFFF',
    },
  },
  outlined: {
    primary: {
      borderColor: '#7286F0',
      color: '#7286F0',
      background: '#ffffff',
    },
    success: {
      borderColor: '#68CAB2',
      color: '#68CAB2',
      background: '#ffffff',
    },
  },
};

const sizeTypes = {
  small: {
    padding: '10px 18px',
    fontSize: '14px',
  },
  medium: {
    padding: '10px 22px',
    fontSize: '16px',
  },
  large: {
    padding: '10px 26px',
    fontSize: '18px',
  },
};

function Button({ varient, color, size, children, box, className, onClick, shadow}) {
  const varientType = varient ? varient : 'contained';
  const colorType = color ? color : 'primary';
  const sizeType = size ? sizeTypes[size] : sizeTypes['medium'];
  const theme = types[varientType][colorType];
  const boxType = box;
  const shadowType = shadow;

  return (
    <ThemeProvider theme={theme}>
      <ButtonComponent onClick={onClick} size={sizeType} className={className} box={boxType} shadow={shadowType}>
        {children}
      </ButtonComponent>
    </ThemeProvider>
  );
}

export default Button;
