import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Select from 'react-select'
import Badge from "react-bootstrap/Badge";
import Card from './shared/Card'
import TableRecruiter from './shared/Table'
import Paginator from '../../common/Paginator/Paginator'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from './shared/Button'
import P from './shared/P'
import TableWrapper from './shared/TableWrapper'
import SearchBar from '../../common/SearchBar/SearchBar'
import { capitalize } from '../../../utils'
import { Row, Col, Spinner  } from 'react-bootstrap'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import './styles/RecruiterManagment.scss'
import SweetAlert from 'react-bootstrap-sweetalert'
import feather from 'feather-icons'
import DataTable from "react-data-table-component-footer"
import linkedinIcon  from '../../../../assets/images/icon-linkedin.png'
import   './styles/DataTableCustom.scss'
import Modal from "react-bootstrap/Modal"
import AgreementUploadCommission from './AgreementUploadCommission'

const CommissionRecruiterManagement = ({jobs}) => {
    var active_page = window.sessionStorage.getItem('recruiter_activePage')
    var per_page = 25
    var query = window.sessionStorage.getItem('recruiter_query')
    const cardinal_members_only = sessionStorage.getItem('cardinal_members_only')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [recruiters, setRecruiters] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [startRecord, setStartRecord] = useState(0)
    const [totalRecruiter, setTotalRecruiter] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showSweetAlertMessage,setShowSweetAlertMessage]=useState()
    const [sortField, setSortField] = useState('users.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(per_page || 25)
    const [selectUser,setSelectUser] = useState(false)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [cardinalMembersOnly, setCardinalMembersOnly] = useState(cardinal_members_only == 'true' ? true : false)
    const [recruiterObject,setRecruiterObject] = useState(false)
    const [showAgreementUpload, setShowAgreementUpload] = useState(false)
    const [showJobAssignModal, setShowJobAssignModal] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [validationError, setValidationError] = useState({})
    const [selectedRecruiter, setSelectedRecruiter] = useState({})
    const [selectedJob, setSelectedJob] = useState(null)
    const [defaultSelectedJob, setDefaultSelectedJob] = useState({label: "",value: ""})
    const [assignList,setAssignList] = useState([])
    const [showUnAssignConfirmation,setShowUnAssignConfirmation] = useState(false)
    const [unassignJobId,setUnassignJobId] = useState(null)
    useEffect(() => {
        feather.replace()
    })

    const fetchData = () => {
        setLoading(true)
        window.sessionStorage.setItem('recruiter_activePage',activePage)
        const url = `/admin/get_recruiter_commission.json?page=${activePage}&search=${searchTerm}&cardinal_member=${cardinalMembersOnly}&sortField=${sortField}&sortDirection=${sortDirection}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (response) => {
                setTotalRecruiter(response.data.total_count)
                let sr_no = (activePage - 1) * response.data.per_page
                setRecruiters(
                    response.data.users.map((user) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        id: user.id,
                        name: user.first_name + ' ' + user.last_name,
                        email: user.email, 
                        role: user.role,
                        is_commission_recruiter: (user.is_commission_recruiter != null) ? capitalize(user.is_commission_recruiter) : '',
                        created_at: moment(user.created_at).format('MMM DD, YYYY'),
                        email_confirmed: user.email_confirmed ? 'Approved' : 'Pending',
                        send_email_request: user.send_email_request ? 'Approved' : 'Pending',
                        on_demand_recruiter: capitalize(user.on_demand_recruiter),
                        agreement_obj : (response.data.agreement_data[user.id]) ? (response.data.agreement_data[user.id]) : '',
                        linkedin_profile_url: user?.linkedin_profile_url ? <a target='_blank' href={user?.linkedin_profile_url}>
                        <img style={{width:'20px', height:'20px'}}  src={linkedinIcon} /></a>
                         : '',
                        organizations:   user.recruiter_organizations &&
                                (sortRecruiterOrganization(user.recruiter_organizations)).map((skill, index) => canidateSkills(skill, index)),
                        actions: (<div className='w-100 d-inline-flex justify-content-around'>
                                  <a  onClick={() =>showJobAssign(user)} title="Assign Job" href={`javascript::void()`} ><i data-feather="plus-circle" /></a>
                                  <a  onClick={() =>showConfirmDelete(user)}  href={`javascript::void()`} title="Remove as 100% Commission Recruiter " className='pr-1' style={{ color: '#dc3545'}}><i data-feather="x" /></a>
                                  <a onClick={() =>showUploadForm(user)} title={(response.data.agreement_data[user.id]) ?  response.data.agreement_data[user.id] : 'sdfs'} style={{ cursor: 'pointer'}}  className='pr-2' ><i data-feather="upload" /></a>
                                  <a download={true} href={(response.data.agreement_data[user.id]) ?  response.data.agreement_data[user.id] : ''} target='_blank'  style={{ cursor: 'pointer', display: (response.data.agreement_data[user.id] != null) ? '' : 'none' }}  className='pr-2' ><i data-feather="download" /></a>
                            </div>)
                        }))
                )
               
                setCurrentCounts(response.data.current_counts)
                setStartRecord((activePage - 1) * response.data.per_page)
                setLoading(false)
            },
        })
    }

    const showJobAssign = (user) => {
        setShowJobAssignModal(true)
        setSelectedRecruiter(user)
        setDefaultSelectedJob({label: "",value: ""}) 
        fetchAssignJob(user)     
    }

    const showUploadForm = (org) => {
        setRecruiterObject(org)
        setShowAgreementUpload(true)
    }

    const onHide = () => {
        setShowAgreementUpload(false)
    }

    const sortRecruiterOrganization = (data) => {
        let sort_data = data.slice(-5).sort((a, b) => a.id > b.id ? -1 : 1)
        return sort_data.map((org, key) => { 
            return (org.organization) ? org.organization.name : ''
        })
    }

    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,sortDirection,sortField])

    const unSetCommissionRecruiter = async () => {
        setShowDeleteConfirmation(false)
        let id = selectUser.id
        const url = `/admin/remove_commission_recruiters/${id}`
        const response = await makeRequest(url, 'get', {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createSuccessMessage: (response) => response.data.message,
            createErrorMessage: (e) => e.response.data.msg,
        })
        if(response?.data?.errorMsg){
            setShowSweetAlertMessage(response?.data?.errorMsg)
            setShowRemoveConfirmation(true)
        }
        fetchData()
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('recruiter_query', value)
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '6%',
            minWidth: '6%',
        },
        {
            name: 'Name',
            selector: row => row.name,
            cell: (row) => <div title={row.name}>{row.name}</div>,
            maxWidth: '16%',
            minWidth: '16%',
            wrap:true,
            sortable: true,
            sortField: 'users.first_name',
        },
        {
            name: 'Email',
            selector: row => row.email,
            cell: (row) => <div title={row.email}>{row.email}</div>,
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true,
            sortable: true,
            sortField: 'users.email',
        },
        {
            name: 'Organizations',
            selector: row => row.organizations,
            maxWidth: '17%',
            minWidth: '17%',
        },
        {
            name: 'Linkedin Url',
            selector: row => row.linkedin_profile_url,
            cell: (row) => <div title={row.linkedin_profile_url}>{row.linkedin_profile_url}</div>,
            maxWidth: '8%',
            minWidth: '8%',
        },
        {
            name: 'Email Verified',
            selector: row => row.email_confirmed,
            cell: (row) => <div title={row.email_confirmed}>{row.email_confirmed}</div>,
            maxWidth: '8%',
            minWidth: '8%',
            sortable: true,
            sortField: 'users.email_confirmed',
        },
        {
            name: '100% Commission Recruiter',
            selector: row => row.is_commission_recruiter,
            cell: (row) => <div title={row.is_commission_recruiter}>{row.is_commission_recruiter}</div>,
            maxWidth: '10%',
            minWidth: '10%',
        },
        {
            name: 'Created On',
            selector: row => row.created_at,
            cell: (row) => <div title={row.created_at}>{row.created_at}</div>,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'users.created_at',
        },
        {
            name: 'Actions',
            cell: row => row.actions,
            maxWidth: '10%',
            minWidth: '10%',
        },
    ]

    const showConfirmDelete = (user) => {
        setSelectUser(user)
        setShowDeleteConfirmation(true)
    }

    const handleClose = () => {
        setShowRemoveConfirmation(false)
    }

    const colourStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px' })
    }

    const saveAssignJob = async () =>{
        let recruiter_id = selectedRecruiter.id
        let job_id =  selectedJob
        if(job_id != "" && job_id != null){
            const url = `/admin/recruiter/job_assign/${recruiter_id}/${job_id}`
            const response = await makeRequest(url, 'post',{}, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
                createSuccessMessage: (response) => response.data.message,
                createErrorMessage: (e) => e.response.data.msg,
            })
            fetchAssignJob(selectedRecruiter);
        }else{
            setValidationError({job_id: 'Please selecte job'});
        }
    }

    const changeJob =  (job) =>{
        setSelectedJob(job.value)
        setDefaultSelectedJob(job)
    }

    const fetchAssignJob = async (user) => {
        setAssignList([]);
        let recruiter_id = user.id
        const url = `/admin/recruiter/job_assigns/${recruiter_id}`
        const response = await makeRequest(url, 'get', {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createSuccessMessage: (response) => response.data.message,
            createErrorMessage: (e) => e.response.data.msg,
        })
        if(response?.data?.success){
           setAssignList(response?.data?.list)
        }
    }

    const unAssignJob = async () => {
        const url = `/admin/recruiter/job_unassign/${unassignJobId}`
            const response = await makeRequest(url, 'get', {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
                createSuccessMessage: (response) => response.data.message,
                createErrorMessage: (e) => e.response.data.msg,
            })
            fetchAssignJob(selectedRecruiter);
    }

    const setUnAssignFunction = (id) => {
        setUnassignJobId(id)
        setShowUnAssignConfirmation(true);
    }

    return (
        <Card>
             {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    title={showSweetAlertMessage}
                    onConfirm={()=>handleClose(true)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />
            }
            
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '20px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    100% Commission Recruiters
                </P>
         
            </div>
            <Row className="w-100" style={{display: 'flex',alignItems: 'baseline', }}>
                <Col xs={12} sm={12} lg={8} style={{float:'left'}}>
                    {totalRecruiter > 0 &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalRecruiter ? totalRecruiter : currentCounts } of {totalRecruiter} recruiters</DisplayPagination>
                    }
                </Col>
                <Col xs={12} sm={12} lg={4} style={{float:'right'}}>
                    <SearchBar
                    placeholder="Search recruiter..."
                    value={searchTerm}
                    setValue={setSearchTerm}
                    onChange={(e) => onChangeSearchTerm(e.target.value)}
                    onEnterPressed={() => {
                        activePage === 1
                            ? fetchData()
                            : setActivePage(1)
                        !searchTerm && window.sessionStorage.setItem('recruiter_query','')
                    }}
                    onClick={(e) => setSearchTerm('')}
                    />
                </Col>
                    
            </Row>
                <DataTable
                    title={false}
                    persistTableHead={true}
                    noDataComponent={<p className="text-center p-3">No record found</p>}
                    columns={columns}
                    data={recruiters}
                    progressPending={loading}
                    progressComponent={ <div className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner className='spinner' animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>}
                    onSort={handleSort}
                    sortServer
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRecruiter}
                    paginationPerPage={perPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    responsive={true}
                    onChangePage={page => setActivePage(page)}
                /> 

           {showDeleteConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to remove as 100% commisson recruiter?"
                    onConfirm={()=> (unSetCommissionRecruiter(),setShowDeleteConfirmation(false))}
                    onCancel={()=>setShowDeleteConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this user!
                </SweetAlert>
            }
            {showUnAssignConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to unassign this job?"
                    onConfirm={()=> (unAssignJob(),setShowUnAssignConfirmation(false))}
                    onCancel={()=>setShowUnAssignConfirmation(false)}
                    focusCancelBtn
                    >
                </SweetAlert>
            }
            <Modal
                onHide={onHide}
                show={showAgreementUpload}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                className='modal-entry-form'
                >
                <Modal.Header closeButton className='mt-2 mb-2'>
                    <Modal.Title id="contained-modal-title-vcenter" >
                    Upload Sign Agreement
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AgreementUploadCommission orgObj={recruiterObject} onHide={onHide} method={'post'} fetchData={fetchData}></AgreementUploadCommission>
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => (setShowJobAssignModal(false),setBtnDisabled(false))}
                show={showJobAssignModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                dialogClassName="modal-60w"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="mx-auto text-center">
                        Assign job to {selectedRecruiter.first_name} {selectedRecruiter.last_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                   
                        <Row className="w-100" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <Col  lg="9" md="9" sm="9" xs="9">
                                <label className='mb-2'>Search Job :</label>
                                <Select
                                    options={jobs}
                                    name="msg_sequence"
                                    style={colourStyles}
                                    menuPosition='fixed'
                                    placeholder='Search Job Name'
                                    isLoading={loadingModal}
                                    onChange={(values) => changeJob(values) }
                                    value={defaultSelectedJob}
                                />
                                {validationError && validationError.job_id && 
                                    <label className='error-validation'>
                                    {validationError && validationError.job_id} 
                                    </label>
                                }
                            </Col>
                            <Col  lg="3" md="3" sm="3" xs="3">
                                <button className='btn btn-primary ml-3' style={{marginTop: '30px'}} onClick={ (e) => saveAssignJob() }>
                                    Assign Job
                                </button>
                               
                            </Col>
                        </Row>
                        <Row className="w-100 mt-5">
                            <Col  lg="12" md="12" sm="12" xs="12" className="text-center">
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Job Name</th>
                                            <th>Org. Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {assignList && assignList?.length ? (
                                            assignList?.map((obj,index) => {
                                                if (obj)
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {index + 1}
                                                            </td>
                                                            <td>
                                                                {obj.job.name}
                                                            </td>
                                                            <td>
                                                                {obj.job.organization.name}
                                                            </td>
                                                            <td>
                                                                <a  title="Remove" href={`javascript::void()`} onClick={(e)=> setUnAssignFunction(obj.id) }><i data-feather="x" /></a>
                                                            </td>
                                                         </tr>
                                                    )
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={4}>
                                                    No Job Assign
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                      
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Row className="w-100 text-right" style={{ marginBottom: '10px', marginTop: '20px' }}>
                        <Col  lg="12" md="12" sm="12" xs="12">
                        <button className='btn btn-info' onClick={ () => (setBtnDisabled(false),setShowJobAssignModal(false)) }>
                            Cancel
                        </button>
                        </Col>
                    </Row>
                </Modal.Footer> */}
            </Modal>
        </Card>
        
    )

    function canidateSkills(skill, index){
        return(
            <>
                <Badge
                  style={{cursor:"pointer"}}
                   title={skill}
                    pill
                    key={index}
                    variant="secondary"
                    className="skills-button d-inline-block text-truncate"                                                                                            
                >
                    <span style={{cursor:"pointer"}} title={skill}>
                    <label style={{cursor:"pointer"}} title={skill} className='candidate-skills' > {skill} </label>
                    </span>
                </Badge>{'   '}
            </>
        )
    }
}

export default CommissionRecruiterManagement