import React, { useEffect, useState,Fragment} from 'react'
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Paginator from '../../common/Paginator/Paginator'
import Util from '../../../utils/util'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import './styles/MyNetworkIndex.scss'
import ManageMyMetworkSideBar from './ManageMyMetworkSideBar'
import lodingImage from '../../../../assets/v2/imgs/theme/loading_new.gif'
import '../../../../assets/v2/css/main.css'
import '../../pages/Interview/styles/AiInterview.scss'

function AllReceivedCandidate({ current_user,my_total_candidates,received,sent,reject }) {
    const [loading, setLoader] = useState(true)
    const [candidates, setCandidates] = useState([])
    const [activePage, setActivePage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const fetchData = async() => {
        setCandidates([])
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setLoader(true)
        const url = `/my_networks/received.json`
        await makeRequest(url, 'get',  {
            params: {page: (activePage+1),}
            },{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setCandidates(
                    res.data.candidates.map((candidate) => ({
                        id: candidate.id,
                        first_name: (candidate.user1.person.first_name != null) ? capitalize(candidate.user1.person.first_name): capitalize(candidate.user1.first_name),
                        last_name: (candidate.user1.person.last_name != null) ? capitalize(candidate.user1.person.last_name) : capitalize(candidate.user1.last_name),
                        title: candidate.user1.person.title || candidate.user1.title,
                        location: candidate.user1.person.location || candidate.user1.location,
                        state: candidate.user1.state || '',
                        city: candidate.user1.city || '',
                        avatar_url: candidate.user1.person.avatar_url || '',
                        employer: candidate.user1.person.employer || '',
                        description: candidate.user1.person.description || '',
                    }))
                )
                setPageCount(res.data.total_pages)
                setTotalCount(res.data.total)
                setLoader(false)
            },
        })
    }
    useEffect(()=>{
        fetchData()
    },[activePage])

    const updateNetworkStatus = (id,index,status) => {
        const url = `/my_networks/${id}`
        makeRequest(url, 'put',  {
                status: status
            },{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            createResponseMessage: (response) => {
                return {
                    message: response.message,
                    messageType: response.messageType,
                    loading: false,
                    autoClose: true,
                }
            },
            onSuccess: (res) => {
                const updatedCandidates = [...candidates];
                updatedCandidates.splice(index, 1); // Remove the candidate at the specified index
                setCandidates(updatedCandidates);
            },
        })
    }

    return (
        <>
            <div className="section-box mt-20">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 float-right">
                            <div className="content-page box-boder2">
                                
                                <div className="box-filters-job mt-15 mb-10">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h5 className={'fw-bold'}>Received Invitations</h5>
                                        </div>
                                    </div>
                                </div>
                                {loading == false && candidates.length > 0 ? '':(
                                    <div className="box-filters-job pt-20 mb-10">
                                        <div className="row">
                                            <div className="col-lg-12 text-center p-10">
                                                <h5 className={'fw-bold'}>No Data Found</h5>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="employers-list">
                                {(loading) ? 
                                    <div class="text-center loading">
                                        <img src={lodingImage} alt="loading" />
                                    </div>
                                :''}
                                {candidates && candidates.map((candidate, index) => {
                                    return(
                                        <div key={candidate.id}  className="card-employers hover-up wow animate__animated animate__fadeIn">
                                            <div className="row align-items-center">
                                                <div className="col-lg-9 col-md-6 d-flex">
                                                    <div className="employers-logo  mr-15">
                                                        <a href={`/users/profile/${candidate.id}`} className={'invite_candidate'}>
                                                            <figure>
                                                                {candidate?.avatar_url ?
                                                                    <img alt="jobhub" className={'img_border'} src={(candidate?.avatar_url != '')?candidate.avatar_url:'assets/imgs/page/candidates/img-candidate10.png'} />
                                                                    : <CandidateAvatarIcon firstName={candidate?.first_name} lastName={candidate?.last_name} />
                                                                }
                                                            </figure>
                                                        </a>
                                                    </div>
                                                    <div className="employers-name">
                                                        <h5><a href={`/users/profile/${candidate.id}`}><strong>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</strong></a></h5>
                                                        <span className="text-sm  text-muted overflowtext" title={candidate?.description}>{candidate?.description || 'N/A'}</span>
                                                        <span className="d-flex align-items-center"><i className="fi-rr-marker mr-5 ml-0"></i>{candidate?.location || 'N/A'}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 text-lg-end d-lg-block d-none">
                                                    <a href="#" onClick={(e) => { updateNetworkStatus(candidate.id,index,'declined') }} class="btn btn-danger rounded-pill mr-10">Ignore</a>
                                                    <a href="#" onClick={(e) => { updateNetworkStatus(candidate.id,index,'accepted') }} class="btn btn-default rounded-pill">Accept</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                            {(pageCount>1) ? 
                                <div className="paginations">
                                    <Paginator
                                        pageCount={pageCount}
                                        activePage={activePage}
                                        setActivePage={setActivePage}
                                        pageWindowSize={10}
                                    />
                                </div>
                            :''}
                        </div>
                        <ManageMyMetworkSideBar my_total_candidates={my_total_candidates} received={received} sent={sent} reject={reject}></ManageMyMetworkSideBar>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllReceivedCandidate
