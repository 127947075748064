import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal"
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Box } from '../../../common/Styled components/styled'
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch'
import { Row, Col } from 'react-bootstrap'
import Text from '../../../common/Styled components/Text'
import Container from "react-bootstrap/Container"
import './../styles/communities.scss'
// import moment from 'moment'
import SettingLeftPenal from '../SettingLeftPenal'

const IndexPage = ({ communityId, currentUser, community, community_subscription }) => {
  const [show, setShow] = useState(false)
  const [method, setMethod] = useState('post')
  const [isPaid, setIsPaid] = useState(community.paid)
  const [entry, setEntry] = useState({})
  const [communitySubscription, setCommunitySubscription] = useState(community_subscription)
  const [validationError, setValidationError] = useState({})
  const [entryData, setEntryData] = useState({
    name: communitySubscription?.name ? communitySubscription?.name : '',
    price: communitySubscription?.amount ? communitySubscription?.amount : '',
  })
  const [btnDisabled, setBtnDisabled] = useState(false)
  const handleChange = (e) => {
    setValidationError({})
  }

  const onHide = () => {
    setEntry({})
    setMethod('post')
    setShow(false)
  }

  const onStateToggle = (e) => {
    if(e){
      if(communitySubscription == null){
          setShow(true)
      }else{
        setIsPaid(e)
        updateInfo(e)
      }
    }else{
      setIsPaid(e)
      updateInfo(e)
    }
  }

  const updateInfo = (paid) => {
    let URL = `/communities/${communityId}/subscription/status/?paid=${paid}`
    makeRequest(URL, 'get', '', {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      onSuccess: (response) => {
        window.location.reload()
      },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    if (validateForm(e)) {
      let URL = '/communities/' + communityId + '/subscription/create'
      if (communitySubscription != null) {
        URL = '/communities/' + communityId + '/subscription/update/' + communitySubscription.id
      }
      const payload = new FormData()
      payload.append('subscription_plan[name]', e.target.name.value)
      payload.append('subscription_plan[amount]', e.target.price.value)
      payload.append('subscription_plan[community_id]', communityId)
      //headers
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      const headers = {
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
        },
      }
      makeRequest(URL, method, payload, headers, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
      }).then((res) => {
        if (res.data.success) {
          onHide()
          setCommunitySubscription(res.data.subscription_plan)
          if (communitySubscription == null) {
            updateInfo(true)
          } else {
            window.location.reload()
          }
        }
      })
    }
    setBtnDisabled(false)
  }

  const handleSubmitCreateAccount = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    let URL = '/communities/' + communityId + '/subscription/account_create'
    const payload = new FormData()
    const CSRF_Token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const headers = {
      headers: {
        'content-type': 'application/json',
        'X-CSRF-Token': CSRF_Token,
      },
    }
    makeRequest(URL, method, payload, headers, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
    }).then((res) => {
      if (res.data.success) {
        window.location.href = res.data.stripe_account_account_link;
      }
    })
    setBtnDisabled(false)
  }

  const validateForm = (e) => {
    console.log(parseInt(e?.target?.price?.value))
    let errors = {}
    let formIsValid = true
    const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
    if (!e?.target?.name?.value?.trim()) {
      formIsValid = false;
      errors["name"] = "Plan Name is required field."
    } else if (!regex.test(e?.target?.name?.value?.trim())) {
      formIsValid = false;
      errors["name"] = "Please Enter Valid Category Name."
    }
    if (e?.target?.price?.value?.trim() == null || e?.target?.price?.value?.trim() == '') {
      formIsValid = false;
      errors["price"] = "Amount value must be greater than zero"
    }
    if (parseInt(e?.target?.price?.value) <= 0) {
      formIsValid = false;
      errors["price"] = "Amount value must be greater than zero"
    }
    setValidationError(errors)
    setBtnDisabled(formIsValid)
    return formIsValid;
  }
  return (
    <>
      <div className="container mt-60 communities-detail">
        <div className="row">
          <SettingLeftPenal communityId={communityId} ></SettingLeftPenal>
          <div className="col-lg-8 p-0 m-0">
            <div className="single-recent-jobs" style={{ paddingLeft: '20px' }}>
              <div className='d-flex justify-content-between'>
                <div>
                  <h4>Subscriptions</h4>
                  <p style={{ marginTop: '10px' }}>Make money by charging a monthly subscription for access to your community.</p>
                  {(community.stripe_status == 0) &&
                    <p className='text-danger pt-10'><b>*Please connect your Stripe account first before using the paid community service.</b></p>
                  }
                  </div>
                <div>
                  {(community.paid == true && community.stripe_status == 1) &&
                    <button className='btn btn-dfault' onClick={() => setShow(true)} >{communitySubscription == null ? 'Add Price' : 'Update Price'}</button>
                  }

                </div>
              </div>
              <div className='d-flex justify-content-between' style={{ marginTop: '30px' }}>
                <>
                  <Box aItems="center" jContent="space-between">
                    <Text varient="h5" color="primary" size="22px" givePadding>
                      Paid
                    </Text>
                    <ToggleSwitch
                      id="client"
                      state={isPaid}
                      setState={(e) => onStateToggle(e)}
                      size="medium"
                      disabled = {(community.stripe_status == 1)?false:true}
                    />
                    <Text varient="h5" color="primary" size="22px" givePadding>
                      Free
                    </Text>
                  </Box>
                </>
              </div>
              <div className='d-flex justify-content-between' style={{ marginTop: '40px' }}>
                <table>
                  {community.paid == true &&
                    <>
                      {communitySubscription == null &&
                        <tr>
                          <td colSpan={2}> No active subscription </td>
                        </tr>
                      }

                      {communitySubscription != null && communitySubscription?.amount &&
                        <>
                          <tr>
                            <td >
                              <h5 style={{ marginTop: '20px' }}> Your active subscription is </h5>
                            </td>
                            <td>
                              <button className='btn btn-success' style={{ color: 'white', margin: '10px' }}> $ {communitySubscription?.amount} / Month</button>
                            </td>
                          </tr>

                        </>
                      }
                    </>
                  }
                </table>
              </div>
             
                  <div className='alert-warning mt-50'>
                    <div className='p-15'>
                      <h5>Connect your bank account</h5>
                      <p>Cardinal Groups uses Stripe to send payouts to your bank account. Complete setup to monetize your group.</p>

                      {community.stripe_status == 1 ?
                        <a target='_blank' href={community.stripe_login_link} className="btn btn-primary mt-20">Stripe Dashboard</a>
                        :
                        <button disabled={btnDisabled} className='btn btn-primary' onClick={(e) => handleSubmitCreateAccount(e)} >Continue</button>
                      }
                    </div>
                  </div>
               

            </div>
          </div>
        </div>
      </div>

      <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='modal-entry-form'
      >
        <Modal.Header closeButton className='mt-2 mb-2'>
          <Modal.Title id="contained-modal-title-vcenter" >
            {communitySubscription != null ? 'Update' : 'Add'} Subscriptions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form onSubmit={(e) => { handleSubmit(e) }} style={{ width: "100%" }}>
              <Row className="w-100">

                <Col xs={12} sm={12} lg={12} style={{ display: (communitySubscription != null ? 'none' : '') }}>
                  <span>Plan Name* : </span>
                  <input type='text' defaultValue={entryData.name} onChange={handleChange} className='form-control w-100' name='name' id="name" />
                  <small className='error'>
                    {validationError && validationError.name}
                  </small>
                </Col>

                <Col xs={12} sm={12} lg={12} className='mt-10'>
                  <span>Amount($)* : </span>
                  <input type='number' defaultValue={entryData.price} onChange={handleChange} className='form-control w-100' name='price' id="price" />
                  <small className='error'>
                    {validationError && validationError.price}
                  </small>
                </Col>
              </Row>
              <Row className="w-100 modal-entry-form-row" >
                <Col xs={12} sm={12} lg={12}>
                  <button type='submit' disabled={btnDisabled} className='btn btn-default btn-radius'>{communitySubscription == null ? 'Save' : 'Update'}</button>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>

    </>
  );
}
export default IndexPage