import React, { useEffect } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import feather from 'feather-icons'
import styles from './styles/JobAppledCard.module.scss'
import CompanyPlaceholderImage from '../../../../assets/images/talent_page_assets/company-placeholder.png'
import {handelBrokenUrl} from '../../common/Utils'
import { capitalize } from '../../../utils'

import Moment from 'moment'

const JobAppledCard = (props) => {
    const {key, submission, showJobDetails} = props
    const {id, created_at, person_id, location, city, country, 
        submission_type, job_name, organization_name, image_url 
    } = submission

    // todo check if user already applied - waiting for backend support
    const alreadyApplied = false

    useEffect(() => {
        feather.replace()
    })

    const activeStatus = () => {
        if(submission_type == 'applicant'){
            return 'process-wrap active-step1'
        }else if(submission_type == 'recruitor_screen'){
            return 'process-wrap active-step2'
        }else if(submission_type == 'first_interview' || submission_type == 'second_interview'){
            return 'process-wrap active-step3'
        }else if(submission_type == 'reject' || submission_type == 'hired'){
            return 'process-wrap active-step4'
        }
            
    }

    const lastStage = () => {
        if(submission_type == 'reject'){
            return 'Rejected'
        }else if(submission_type == 'hired'){
            return 'Hired'
        }else{
            return 'Rejected / Hired'
        }
            
    }

    const JobAvatarUrl = (logo) => {
        return logo ? logo : CompanyPlaceholderImage
    }

    return (
        <div className="card-job hover-up wow animate__ animate__fadeIn animated" key={id} style={{ visibility: 'visible', animationName: 'fadeIn' }}>
            <div class="card-job-top">
                <div class="card-job-top--image">
                    <figure><img alt={organization_name}
                            src={
                                JobAvatarUrl(image_url)
                            } /></figure>
                </div>
                <div class="card-job-top--info">
                    <h6 class="card-job-top--info-heading"> 
                        <a href={`/jobs/${id}`} className={styles.descriptionLink} >{job_name}</a>
                    </h6>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <a >
                                <span class="card-job-top--company ml-12 mr-15 ">{organization_name}</span>
                            </a>
                            { (location !== "null" && location) && 
                                <span class="card-job-top--location text-sm pr-5 mr-15"><i
                                    class="fi-rr-marker"></i> {location !== "null" ? location && capitalize(location?.replace('(US)','')?.replace('(usa)','')?.replace('(USA)','')) : 'No location found'} </span>
                            }
                            <span class="card-job-top--type-job text-sm mr-15"><i
                                    class="fi-rr-briefcase"></i> Full
                                time</span>
                            <span class="card-job-top--post-time text-sm mr-15"><i
                                    class="fi-rr-clock"></i> {Moment(created_at).fromNow()}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-job-bottom mt-25">
                <div className="row">
                    <div className="col-lg-12 col-sm-8 col-12">
                        <div className={activeStatus()} style={{ width: '100%' }}>
                            <div className="process-main row">
                                <div className="col-3">
                                    <div className="process-step-cont">
                                        <div className="process-step step-1" />
                                        <span className="process-label">Applied</span>
                                    </div>
                                </div>
                                <div className="col-3 ">
                                    <div className="process-step-cont">
                                        <div className="process-step step-2" />
                                        <span className="process-label">Recruiter View</span>
                                    </div>
                                </div>
                                <div className="col-3 ">
                                    <div className="process-step-cont">
                                        <div className="process-step step-3" />
                                        <span className="process-label">Interview
                                        </span>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="process-step-cont">
                                        <div className="process-step step-4" />
                                        <span className="process-label">{lastStage()}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobAppledCard
