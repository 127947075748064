import React, { useState } from 'react';
import styles from '../SignupPage/styles/Signup.module.scss';
import stylesAc from '../AccountSettingPage/styles/AccountSettingPage.module.scss';
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import './styles/CalanderBookSlot.scss';
import axios from 'axios'

const CommissionInfoPage = ({ user }) => {
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [validationMessage, setValidationMessage] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState([]);
  

  const handleCheckboxChange = (event, setState, state) => {
    const { name, checked } = event.target;
    if (checked) {
      setState([...state, name]);
    } else {
      setState(state.filter((item) => item !== name));
    }
  };

  const handleSubmit = async () => {
    console.log('Selected Contracts:', selectedContracts);
    console.log('Selected Candidates:', selectedCandidates);
    setValidationMessage(false)
    if(selectedContracts != '' && selectedCandidates != '' && selectedJobType != ''){
      // Add your form submission logic 
      const payload = new FormData()
          const url = '/account/commission_recruiter'
          payload.append('info[agency]', selectedContracts)
          payload.append('info[candidate_info]', selectedCandidates)
          payload.append('info[job_info]', selectedJobType)

          await makeRequest(url, 'post', payload, {
              contentType: 'application/json',
              loadingMessage: 'Submitting...',
              onSuccess: (res) => {
                console.log(res);
                    if(res.data.success){
                          window.location.href="/waiting_approval/profile"
                    }
              },
          })
    }else{
      setValidationMessage(true)
    }
  };

  const handleSkip = async () => {
    console.log('Selected Contracts:', selectedContracts);
    console.log('Selected Candidates:', selectedCandidates);
    // Add your form submission logic 
    const payload = new FormData()
        const url = '/account/skip_commission_recruiter'

        await makeRequest(url, 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            onSuccess: (res) => {
                   if(res.data.success){
                        window.location.href="/waiting_approval/profile"
                   }
            },
        })
  };

  return (
    <div className={`${styles.approvalTextCenter}`} style={{ marginTop: '4%',padding: '1.5%' }}>
      <div className={`${stylesAc.containt}`} style={{ padding: '10px' }}>
        <h3>Basic Information</h3>
        <div>
          <div className='mt-5'>
            <h5 className='mb-3'> What kinds of agencies / contractors would you like to refer?</h5>
            <div>
              <span className='mr-5'>
                <input
                  type='checkbox'
                  name='Design'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedContracts, selectedContracts)}
                />
                Design
              </span>
              <span className='mr-5'>
                <input
                  type='checkbox'
                  name='Recruiting'
                  className='ml-1 mr-3'
                  onChange={(e) => handleCheckboxChange(e, setSelectedContracts, selectedContracts)}
                />
                Recruiting
              </span>
              <span className='mr-5'>
                <input
                  type='checkbox'
                  name='Software development'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedContracts, selectedContracts)}
                />
                Software development
              </span>
            </div>
            <div>
              <span style={{ marginRight: '25px' }}>
                <input
                  type='checkbox'
                  name='Marketing'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedContracts, selectedContracts)}
                />
                Marketing
              </span>
              <span className='mr-5'>
                <input
                  type='checkbox'
                  name='E-commerce'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedContracts, selectedContracts)}
                />
                E-commerce
              </span>
            </div>
          </div>
          <div className='mt-3'>
            <h5 className='mb-3'> What kind of jobs would you like to submit candidates for?</h5>
            <div>
              <span className='mr-5'>
                <input
                  type='checkbox'
                  name='Healthcare'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedJobType, selectedJobType)}
                />
                Healthcare
              </span>
              <span className='mr-5'>
                <input
                  type='checkbox'
                  name='Construction'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedJobType, selectedJobType)}
                />
                Construction
              </span>
              <span className='mr-5'>
                <input
                  type='checkbox'
                  name='Financial Services'
                  className='ml-1 mr-3'
                  onChange={(e) => handleCheckboxChange(e, setSelectedJobType, selectedJobType)}
                />
                Financial Services
              </span>
            </div>
            <div>
              <span style={{ marginRight: '7px' }}>
                <input
                  type='checkbox'
                  name='Executive Search'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedJobType, selectedJobType)}
                />
                Executive Search
              </span>
              <span className='mr-5'>
                <input
                  type='checkbox'
                  name='Technical roles in technology companies'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedJobType, selectedJobType)}
                />
                Technical roles in technology companies
              </span>
            </div>
            <div>
              <span className='mr-5'>
                <input
                  type='checkbox'
                  name='Technical roles in technology companies'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedJobType, selectedJobType)}
                />
                Non-technical roles in technology companies
              </span>
            </div>
          </div>
          <div className='mt-3'>
            <h5 className='mb-3'> What kinds of free top candidates would you like to receive?</h5>
            <div>
              <span className='mr-4'>
                <input
                  type='checkbox'
                  name='Software Engineers'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedCandidates, selectedCandidates)}
                />
                Software Engineers
              </span>
              <span className='mr-4'>
                <input
                  type='checkbox'
                  name='Sales'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedCandidates, selectedCandidates)}
                />
                Sales
              </span>
              <span className='mr-4'>
                <input
                  type='checkbox'
                  name='Marketing'
                  className='ml-1 mr-3'
                  onChange={(e) => handleCheckboxChange(e, setSelectedCandidates, selectedCandidates)}
                />
                Marketing
              </span>
            </div>
            <div>
              <span style={{ marginRight: '112px' }}>
                <input
                  type='checkbox'
                  name='Design'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedCandidates, selectedCandidates)}
                />
                Design
              </span>
              <span className='mr-4'>
                <input
                  type='checkbox'
                  name='I do not want to receive free top candidates'
                  className='ml-1 mr-2'
                  onChange={(e) => handleCheckboxChange(e, setSelectedCandidates, selectedCandidates)}
                />
                I do not want to receive free top candidates
              </span>
            </div>
          </div>
          { validationMessage == true &&
              <div className='mt-3'><span style={{color:'red'}}>At least one option is required for all questions.</span> </div>
          }
          <div className='mt-5'>
            <button
              type='button'
              className={`${stylesAc.editButton}`}
              onClick={handleSubmit}
            >
              Submit
            </button>
            {/* <button
              type='button'
              className={`${stylesAc.editButton}`}
              onClick={handleSkip}
            >
              Skip
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionInfoPage;
