import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/FullCycleOutreach'
import validator from 'validator'
import Button from '../../Admin/shared/Button'
import DateSelector from '../../../common/DateSelector.js/DateSelector'
import AsyncCreatableSelect from 'react-select/async-creatable'
import ReactSelect from 'react-select'
import moment from 'moment'
import axios from 'axios'

const AddOutreach = ({fetchApi,setFetchOrgApi,setFetchApi, fetchOrgAPi,teamEntry,onHide,method,fetchData, recruiter_list, org_list}) => {
	const [validationError, setValidationError] = useState({})
	const team_lists = []
	const queryParams = new URLSearchParams(window.location.search)
    const urlRecruiterId = queryParams.get("recruiter")
    const [selectedLocation, setSelectedLocation] = useState()
	const urlteamId = queryParams.get("team_id")
    let jobStatus_recruiter = sessionStorage.getItem("jobStatus_recruiter")
    let client_strorage = sessionStorage.getItem("client_name")
	let client_org = sessionStorage.getItem("client")
	const [clientst, setClientSt] = useState(client_org)
    let team_name_list = sessionStorage.getItem("team_name_list")
	let team_lead = sessionStorage.getItem("team_lead_list")
    const [recruiter,setRecruiter] = useState([...recruiter_list])
    const [defaultselected,setdefaultselected] = useState((jobStatus_recruiter)? recruiter_list.filter((option) => option.value == JSON.parse(jobStatus_recruiter).value) : entryData && entryData != "null" ? entryData.name : '')
	const [entryData, setEntryData] = useState({
		name: teamEntry?.user ? teamEntry?.user : '',
		email_count: teamEntry?.email_count ? teamEntry?.email_count : '',
		inmail_count: teamEntry?.inmail_count ? teamEntry?.inmail_count : '',
		job: teamEntry?.job ? teamEntry?.job : '',
		submission_count: teamEntry?.submission_count ? teamEntry?.submission_count : '',
		date: teamEntry?.date ? teamEntry?.date : '',
		organization: teamEntry?.organization ? teamEntry.organization : '',
	})
	const [defaultorg, setDefaultOrg] = useState([{value: teamEntry?.organization?.id, label: teamEntry?.organization?.name}])
	const [selectedRecruiter,setSelectedRecruiter] = useState(entryData?.name.id ? entryData?.name.id : '')
	const [orgname ,setOrgName] = useState([])
	const [jobname ,setJobName] = useState([])
    const [defaultselectedteam,setdefaultselectedTeam] = useState((team_name_list)? team_lists.filter((option) => option.value == JSON.parse(team_name_list).value) : team_name_list && team_name_list != "null" ? JSON.parse(team_name_list) : '')
    const [selectedClient,setSelectedClient] = useState(entryData?.organization?.id ? entryData?.organization?.id : '')
	const [btnDisabled, setBtnDisabled] = useState(false)
    const [selectedJob,setSelectedJob] = useState(entryData?.job?.id ? entryData?.job?.id : "")
    const [selectedDate, setSelectedDate] = useState(new Date())
	const [defaultClient, setdefaultClient] = useState([{ value: entryData?.organization?.id, label: entryData.organization.name !== "null" ? entryData.organization.name : '' }])
	const [defaultjob, setdefaultJob] = useState([{ value: entryData?.job?.id, label: entryData.job.name !== "null" ? entryData.job.name : '' }])
	// const [defaultUser, setdefaultUser] = useState([{ value: entryData?.user?.id, label: entryData.user.first_name !== "null" ? entryData.user.first_name : '' }])
	const handleChange = (e) => {
		setValidationError({})
		// setSelectedRecruiter(e.value)
		sessionStorage.setItem("jobStatus_recruiter", JSON.stringify(e))
	}
	const [modal,setModal] = useState('')
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})
	const onSelctOrganization = async(event) => {
		setRecruiter([])
		setDefaultOrg([])
		// setEntryData({...entryData,"organization":{}} )
		setFetchOrgApi('')
		setFetchApi('')
		setdefaultJob('')
		setSelectedClient('')
		// setOrgName([])
	const url = `/admin/full_cycle_outreach/filter_org_name`;
	const formData = new FormData();
	formData.append('filter_org', event?.value);
	// setLoading(true);
	const response = await axios
		.post(url, formData)
		.then((res) =>{
			setOrgName(res?.data?.data)
			sessionStorage.setItem("client", JSON.stringify(res?.data?.data))
	    })
		.catch((error) => console.log(error));
	}

    const onJobselect = async (event) =>  {
		
		const url = `/admin/full_cycle_outreach/filter_job_name`;
		const formData = new FormData();
		formData.append('filter_job', event?.value);
		// setLoading(true);
		const response = await axios
			.post(url, formData)
			.then((res) => setJobName(res?.data?.data))
			.catch((error) => console.log(error));
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/admin/full_cycle_outreach'
			if(method === 'put'){
				URL = `/admin/full_cycle_outreach/${teamEntry.id}`
			}
			const payload = new FormData()
			payload.append('outreach[user_id]', selectedRecruiter)
			payload.append('outreach[date]', selectedDate)
			payload.append('outreach[job_id]', selectedJob)
			payload.append('outreach[email_count]', e?.target.email_count?.value)
			payload.append('outreach[inmail_count]', e?.target?.inmail_count?.value)
			payload.append('outreach[submission_count]', e?.target?.submission_count?.value)
			payload.append('outreach[organization_id]', selectedClient)
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
						onHide()
						fetchData()
				}
			}) 
		}
		setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		if(!(e?.target?.client?.value?.trim())){
			formIsValid = false;
			errors["client"] = "Client is required field."
		}
		if(!(e?.target?.name?.value?.trim())){
			formIsValid = false;
			errors["name"] = "Recruiter Name is required."
		}
		if(!(e?.target?.job?.value?.trim())){
			formIsValid = false;
			errors["job"] = "Job is required field."
		}
		if(!(e?.target?.email_count?.value?.trim())){
			formIsValid = false;
			errors["email_count"] = "Email count is required field."
		}
		if(!(e?.target?.inmail_count?.value?.trim())){
			formIsValid = false;
			errors["inmail_count"] = "Inmail count is required field."
		}
		if(!(e?.target?.submission_count?.value?.trim())){
			formIsValid = false;
			errors["submission_count"] = "Submission count is required field."
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<Container> 
				<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
						<Col xs={6} sm={6} lg={6}>
							<span>Select Recruiter : </span>
							<Select
                                defaultValue={recruiter.filter((row) => row.value == entryData.name.id)}
                                options={recruiter_list}
								// value={defaultselected}
                                onChange={(event)=>{
									setFetchOrgApi('')
									onSelctOrganization(event)
                                    setSelectedRecruiter(event.value)
                                    sessionStorage.setItem("jobStatus_recruiter", JSON.stringify(event))
                                }
                                }
                                name="name"
                                // className={styles.colourStyles}
                            />
							<label className='error'>
								{validationError && validationError.name} 
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Select Start Date : </span>
							<DateSelector
                                    handleOnSubmit={(date) => {
                                    setSelectedDate(date)
                                        }}
                                     submitName="Select"
                                    dateselected={selectedDate}
                                    minDate={new Date()}
                                    />
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Select Client : </span>
							<Select
                                // defaultValue={defaultorg}
								value={defaultorg}
                                options={fetchOrgAPi ? fetchOrgAPi : orgname}
                                onChange={(event)=>{
									onJobselect(event)
									setFetchApi('')
									setDefaultOrg(event)
                                    setSelectedClient(event.value)
                                    sessionStorage.setItem("client_name", JSON.stringify(event))
                                }
                                }
                                    
                                name="client"
                            />
							<label className='error'>
								{validationError && validationError.client} 
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Select Job : </span>
							<Select
                                value={defaultjob}
                                options={fetchApi ? fetchApi : jobname}
                                onChange={(event)=>{
									setdefaultJob(event)
									setFetchOrgApi('')
                                    setSelectedJob(event.value)
                                    sessionStorage.setItem("team_lead_list", JSON.stringify(event))
                                }
                                }
                                name="job"
                            />
							<label className='error'>
									{validationError && validationError.job}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>InMails Count: </span>
							<input  min='0' max='9999' type='number' tabIndex="112" defaultValue={entryData.inmail_count} onChange={handleChange} className='form-control w-100' name='inmail_count' id="inmail_count" />
							<label className='error'>
									{validationError && validationError.inmail_count}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Email Count: </span>
							<input  min='0' max='9999' type='number' tabIndex="112" defaultValue={entryData.email_count} onChange={handleChange} className='form-control w-100' name='email_count' id="email_count" />
							<label className='error'>
									{validationError && validationError.email_count}        
							</label>
						</Col>
						<Col xs={12} sm={12} lg={12}>
						<span>Submissions Count : </span>
						<input min='0' max='9999' type='number' tabIndex="112" onChange={handleChange} className='form-control w-100' name='submission_count' id="submission_count" defaultValue={entryData?.submission_count}/>
							<label className='error'>
									{validationError && validationError.submission_count}        
							</label>
						</Col>
					</Row>
					<Row className="w-100 modal-entry-form-row" >
						<Col xs={3} sm={3} lg={3}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Save</Button>
						</Col>
					</Row>
				</form>
				</Container>
		</>
  )
}

export default AddOutreach;