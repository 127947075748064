import React, { useState, useEffect, useContext, useReducer } from 'react';
import moment from 'moment';
import Container from 'react-bootstrap/Container'
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Card from '../Admin/shared/Card';
import styles from './styles/WeeklyReferralPage.module.scss'
import CandidateInfo from './CandidateInfo';
import Util from '../../../utils/util';
import Paginator from '../../common/Paginator/Paginator';
import Select from "react-select"
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
// import IntrestedCandidate from './IntrestedCandidate';
import CloseIcon from '../../../../assets/images/cancel.svg'
import SearchBar from '../../common/SearchBar/SearchBar';
import EmployerMail from '../../common/EmailClient/EmployerMail';
import SweetAlert from 'react-bootstrap-sweetalert';
import FilterActiveJob from '../../common/FilterJob/FilterActiveJob';
import axios from 'axios';

const MAX_SELECTION_LIMIT = 25
const CANDIDATES_PER_PAGE = 25
const SearchEmployerPage = ({current_user, is_email_config, check_plan, free_plan, free_api_call}) => {
    const pathname = window.location.pathname?.split('/')
    const categoryId = parseInt(pathname[2]) ? pathname[2] : ''
    const [activePage, setActivePage] = useState(0)
    const [loading, setLoader] = useState(true)
    const [candidates, setCandidates] = useState([])
    const [searchText, setSearchText] = useState('')
    const [pageCount, setPageCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(0)
    const [show,setShow] = useState(false)
    const [category, setCategory] = useState(categoryId)
    const [selectedCategory, setSelectedCategory] = useState()
    const [singleIntrestedCandidates, setSingleIntrestedCandidates] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [multiIntrestedCandidates, setMultiIntrestedCandidates] = useState([])
    const checked = multiIntrestedCandidates.length > 0 ? true : false
    const [isCheckAll, setIsCheckAll] = useState(checked)
    const [showSubcriptionAlert, setShowSubcriptionAlert] = useState(false)
    const [check, setCheck] = useState(false)
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkIsEmailConfigured, setCheckIsEmailConfigured] = useState(false)
    const [freePlan, setFreePlan] = useState(false)
    const [filterStack, setFilterStack] = useState({
    companyNames: [],
    locations:[],
    position:[],
    industry :[],
    })

    useEffect(() => {
      const params = {
        param: free_api_call?.free_api_call,
        // Add more parameters as needed
      };
      if (free_plan){
        // if (freePlan == true)  {
          axios.post('/free_plan_active', params)
          .then((res) => console.log(''))
          .catch((error) => console.log(error))
        // }
      }
    },[])

    useEffect(() => {
      // Reset activePage to 0 if the current activePage is greater than 0
      if (activePage > 0) {
        setActivePage(0);
      } else {
        // Fetch data only if the activePage is already 0
        fetchData();
      }
    }, [filterStack]);
  useEffect(() =>{
    setCheck(check)
  },[check])
    const [selectCandidates, setSelectCandidate] = useState([])
    const handleSelectCategory = (str) => {
      setSelectedCategory(str)
      setCategory(str.value)
    }
    const locationNameArray = filterStack?.locations?.map(({value})=> value)
    const companyNamesArray = filterStack?.companyNames?.map(({value})=> value)
    const positionNameArray = filterStack?.position?.map(({value})=> value)
    const industryNameArray = filterStack?.industry?.map(({value})=> value)
  
    const fetchData = async() => {
      setCandidates([])
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setLoader(true)
      const url = `/search_employer.json`
      await makeRequest(url, 'get',  {
          params: {page: (activePage+1),
          search: searchText,
          location:locationNameArray.toString(),
          company_name: companyNamesArray.toString(),
          position: positionNameArray.toString(),
          industry: industryNameArray.toString()
          }}, {
          contentType: 'application/json',
          loadingMessage: 'Fetching...',
          createSuccessMessage: (response) => response.data.message,
          onSuccess: (res) => {
            setCandidates(
                res.data.candidates.map((candidate) => ({
                    id: candidate.id,
                    first_name: (candidate.first_name != null) ? capitalize(candidate.first_name): '',
                    last_name: (candidate.last_name != null) ? capitalize(candidate.last_name) : '',
                    position: candidate.position || '',
                    company_name: candidate.company_name || '',
                    email: candidate.email || '',
                    phone_number: candidate.phn_number || '',
                    skills: candidate.skills || '',
                    linkedin_profile_url: candidate.linkedin_url || '',
                    location: candidate.location || '',
                    industry: candidate?.industry || '',
                    email_sent: candidate?.email_sent || '',
                    user_id: candidate?.user_id
                }))
            )
            setPageCount(res.data.total_pages)
            setTotalCount(res.data.total)
            setLoader(false)
          },
      })
    }
    const handleCheckboxChange = (id) => {
      if(multiIntrestedCandidates?.includes(id))
      {
        setMultiIntrestedCandidates([...multiIntrestedCandidates.filter((row)=> row.id != id.id)])
      }
      else{
        setMultiIntrestedCandidates([...multiIntrestedCandidates, id])
      }
      
    }
  
    const handleIntrested = (candidate) => {
      var doc = document.getElementsByClassName('form-check-input')
      for (let i = 0; i < doc.length; i++) {
        if(doc[i].checked)
          doc[i].click()
      }
      setSingleIntrestedCandidates(candidate)
      setMultiIntrestedCandidates([])
      setShow(true)
    }
  
    const onHide = () => {
      setShow(false)
    }
    const handleSelectAll = e => {
      setIsCheckAll(!isCheckAll)
      const filteredCandidates = candidates?.filter(function(candidate) {
        var candidateArr = candidate.user_id?.split(',');
        return (candidateArr?.indexOf(current_user.id.toString()) == undefined || candidateArr?.indexOf(current_user.id.toString()) == -1)
      });
      setMultiIntrestedCandidates(filteredCandidates)
      if (isCheckAll) {
        setMultiIntrestedCandidates([])
      }
    }
    useEffect(()=>{
      fetchData()
    },[activePage])

    console.log(candidates);
    return(
        <>
        <Container className="p-0" fluid>
          <label
            className={styles.mainTitle + ' page-title'}
            style={{ marginBottom: '2rem' }}
          >
            <span>Search Decision Makers</span>
        </label>
        <div className='row' style={{marginTop: '82px'}}>
          <div className='col'>
        <SearchBar
            candidatePage={true}
            hideButton={false}
            placeholder={'Search...'}
            value={searchText}
            setValue={setSearchText}
            onClick={(e) => {
                setSearchText(searchText)
                }
            }
            onEnterPressed={() => {
              activePage === 0 ? fetchData() : setActivePage(0)
            }}
            onChange={(event) => {
                const text = event.target.value
                setSearchText(text)
              }}
              candidateSource={'Referral'}
              />
          </div>
        </div>
       </Container>
        {/* <Card> */}
          <div
            className="d-flex justify-content-between align-items-center w-100"
            // style={{ marginBottom: '15px' }}
          >
              <span className={styles.activecandidatecount}>
                Displaying{' '}
                {Util.displayNumberOfResults(
                    totalCount,
                    pageCount,
                    activePage,
                    25,
                    totalCount >= 10000 ? `10000+` : totalCount,
                    true,
                    true
                )}
              </span>
              <div
              className="d-flex" style={{marginRight:'100px'}}
               >
          <Form.Check
            className={`${styles.candidateCheckbox} check_${multiIntrestedCandidates}`}
            type="checkbox"
            value="selectAll"
            name="selectAll"
            checked={isCheckAll}
            onChange={handleSelectAll}
            onClick={(event) => event.stopPropagation()}
          />
          <label className={styles.labelHeader} >
            Select All
          </label>
        </div>
          </div>

    
          <div
            className="d-flex justify-content-end align-items-center w-100"
            style={{ marginBottom: '15px' }}
          >
            {checked && (
                <Button
                style={{marginRight:'93px'}}
                  variant='primary'
                  className={styles.button}
                  onClick={() =>{
                      if (is_email_config == null){
                        setCheckIsEmailConfigured(true)
                      }
                      if((multiIntrestedCandidates.length <= (check_plan?.max_limit - check_plan?.used_limit)) && (check_plan?.max_limit > check_plan?.used_limit) && (is_email_config != null)){
                        setShowModal(true)
                      }
                      else
                      {
                        setShowSubcriptionAlert(true)
                      }
                    }
                  } 
                >
                  Send Email
                </Button>
            )}
          </div>
         { showSubcriptionAlert && <SweetAlert
                    confirmBtnText="Account Setting"
                    cancelBtnText="No"  
                    style={{ backgroundColor:'#F8D7DA', color: 'red', border: "#F5C6CB", paddingTop:7, paddingRight: 36 }}
                    customButtons={
                        <div className='w-100 d-flex justify-content-end'>
                            <button className="alertFont" onClick={() => window.open('/subscription/order', "_self")}>Go to Subscription</button>
                        </div>
                      }
                >
                <div>
                    <img className='alert-close-button' src={CloseIcon}  onClick={() => setShowSubcriptionAlert(false)} />
                    <h5 className='alert-heading'>
                      {multiIntrestedCandidates?.length >= (check_plan?.max_limit - check_plan?.used_limit) ? `As per your current plan, the maximum number of employers you can select is ${check_plan?.max_limit - check_plan?.used_limit}. Click below to enhance your subscription plan.` : (check_plan?.max_limit == null ? false :check_plan?.max_limit ) === (check_plan?.used_limit== null ? true : check_plan?.used_limit) ? 'You have reached your limit' : 'Please purchase a plan for further process' }
                    </h5>                  
                </div>
              </SweetAlert> }

              { checkIsEmailConfigured && <SweetAlert
                    confirmBtnText="Account Setting"
                    cancelBtnText="No"  
                    style={{ backgroundColor:'#F8D7DA', color: 'red', border: "#F5C6CB", paddingTop:7, paddingRight: 36 }}
                    customButtons={
                        <div className='w-100 d-flex justify-content-end'>
                            <button className="alertFont" 
                            onClick={
                              () => 
                              {window.open('/account/email_verification', "_self")
                              }           
                 }>Go to Account Settings</button>
                        </div>
                      }
                >
                <div>
                    <img className='alert-close-button' src={CloseIcon}  onClick={() =>{
                     setCheckIsEmailConfigured(false)
                     setShowSubcriptionAlert(false)
                    }
                     } />
                    <h5 className='alert-heading'>
                        Your email must be verified from the account settings before it can be sent.
                    </h5>                  
                </div>
              </SweetAlert> }
                <Modal
                  style={{padding:'20px'}}
                    // className="free-candidate"
                    show={showModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                    
                >
                    <Modal.Header
                    onHide={() => setShowModal(false)}
                    closeButton
                    >
                        <Modal.Title>{'Send mail'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <EmployerMail
                          emailClientId={'emailclientfor_' + 'scheduler'}
                          userId={current_user?.id}
                          isEmailConfigured={true}
                          userEmail={current_user?.email || ''}
                          showByDefault={true}
                          mailSentCallback={() => {}}
                          sendList={multiIntrestedCandidates}
                          embedded={true}
                          setShowModal={setShowModal}
                          candidateCount={25}
                      />
                    </Modal.Body>
                </Modal>
                <div className='d-flex'>

                <div style={{marginLeft:'55px'}} >
                <FilterActiveJob
                  filterStack={filterStack}
                  setStackFilter={setFilterStack}
                  handleSearch={fetchData}
                  setActivePage={setActivePage}
                />
                </div>
                <div style={{width: '81%'}}>

          <CandidateInfo
            loading={loading}
            current_user={current_user}
            candidates={candidates}
            handleIntrested={handleIntrested}
            multiSelectCheckbox={handleCheckboxChange}
            multiIntrestedCandidates={multiIntrestedCandidates}
            setSelectCandidate={setSelectCandidate}
            page={page}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            check={check}
            isCheckAll={isCheckAll}
            setIsCheckAll={setIsCheckAll}
            is_email_config={is_email_config}
            showSubcriptionAlert={showSubcriptionAlert}
            setShowSubcriptionAlert={setShowSubcriptionAlert}
            filterStack={filterStack}
            setStackFilter={setFilterStack}
            handleSearch={fetchData}
            check_plan={check_plan}
            setCheckIsEmailConfigured={setCheckIsEmailConfigured}
            checkIsEmailConfigured={checkIsEmailConfigured}
          />
                </div>
                </div>
          {pageCount > 1 && (
              <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: 'auto' }}
              >
                  <Paginator
                      activePage={activePage}
                      setActivePage={setActivePage}
                      pageCount={pageCount}
                      pageWindowSize={5}
                      showGoToField={false}
                  />
              </div>
          )} 
        {/* </Card> */}
        </>
    )
}

export default SearchEmployerPage;