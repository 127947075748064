import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, Form, Row, Col} from "react-bootstrap";
import { makeRequest } from "../../../common/RequestAssist/RequestAssist";
import maissaImage from '../../../../../assets/images/mary.png'
import InterviewScreeningPage from '../ProcessInterview/InterviewScreeningPage'

const StartInterviewPage = ({setShowStartInterviewModal, showStartInterviewModal,interviewId,candidateInfo,organization}) => {
    const [showCheckContentType, setShowCheckContentType] = useState('info');
    const [isAcceptTermCondition, setIsAcceptTermCondition] = useState(false)
    const [errorObj, setErrorObj] = useState({})
    const [errors, setErrors] = useState({})
    const [updateCandidateInfo, setCandidateInfo] = useState(candidateInfo)
    const [candidateDetail, setCandidateDetail] = useState({name:candidateInfo.candidate_name, email: candidateInfo.candidate_email, phone_num: candidateInfo.phone_num == 'null' ? '' : candidateInfo.phone_num})
    const [screenInterviewModal, setScreenInterviewModal] = useState(false);
    // for auto & camera
    const [hasPermission, setHasPermission] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [cameraStatus, setCameraStatus] = useState(null);
    const [microphoneStatus, setMicrophoneStatus] = useState(null);
    const [microphoneFlag, setMicrophoneFlag] = useState(false);
    const [cameraFlag, setCameraFlag] = useState(false);
    const audioChunksRef = useRef([]); // For holding the audio data
    const mediaRecorderRef = useRef(null); // For controlling the media recording
    const audioRef = useRef(null); // Ref for audio element to play recorded audio
    const audioStreamRef = useRef(null); // Store the media stream reference
    const [audioLevel, setAudioLevel] = useState(0); // Track audio input level
    const analyserRef = useRef(null); // Store the analyser reference
    const rafIdRef = useRef(null); // Store requestAnimationFrame ID for stopping

    // Step 1: Check Camera and Microphone access
    const checkPermissions = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

            // Check for audio permission
            if (stream.getAudioTracks().length > 0) {
                setMicrophoneStatus('Microphone access granted');
                setMicrophoneFlag(true);
            } else {
                setMicrophoneStatus('Microphone not available');
                setMicrophoneFlag(false);
            }

            // Check for video (camera) permission
            if (stream.getVideoTracks().length > 0) {
                setCameraStatus('Camera access granted');
                setCameraFlag(true);
            } else {
                setCameraStatus('Camera not available');
                setCameraFlag(false);
            }

            setHasPermission(true);
        } catch (error) {
            console.error('Error accessing media devices:', error);
            setMicrophoneStatus('Microphone access denied');
            setCameraStatus('Camera access denied');
            setCameraFlag(false);
            setMicrophoneFlag(false);
        }
    };

    // Step 2: Start testing microphone (hearing your voice)
    const startSpeaking = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioStreamRef.current = stream; // Store the stream to continue audio analysis
      
            mediaRecorderRef.current = new MediaRecorder(stream);

            // Collect audio data into chunks
            mediaRecorderRef.current.ondataavailable = (event) => {
              audioChunksRef.current.push(event.data);
            };
      
            // Once the recording stops, create a playable audio blob and auto-play it
            mediaRecorderRef.current.onstop = () => {
              const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' }); // Use 'audio/webm' for better browser support
              const audioUrl = URL.createObjectURL(audioBlob);
              audioRef.current.src = audioUrl;  // Set the audio source

              // Check if audio can be played and then play
              audioRef.current.oncanplaythrough = () => {
                audioRef.current.play(); // Auto-play the recorded audio
              };
              
              audioChunksRef.current = []; // Reset for the next recording
            };

            // Start recording
            mediaRecorderRef.current.start();
            setIsRecording(true);

            // Start analyzing audio levels
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const analyser = audioContext.createAnalyser();
            analyserRef.current = analyser;
            const microphone = audioContext.createMediaStreamSource(stream);
            microphone.connect(analyser);

            analyser.fftSize = 256;
            const dataArray = new Uint8Array(analyser.frequencyBinCount);

            const updateAudioLevel = () => {
                analyser.getByteFrequencyData(dataArray);
                const volume = Math.max(...dataArray); // Find the highest audio frequency level
                const scaledVolume = Math.min(10, Math.floor((volume / 255) * 10)); // Scale to 0-10
                setAudioLevel(scaledVolume);

                rafIdRef.current = requestAnimationFrame(updateAudioLevel); // Keep updating as long as recording
            };

            updateAudioLevel(); // Initial call to update the levels

        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    // Stop the microphone test and stop the audio level display
    const stopSpeaking = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop(); // Stop recording
            setIsRecording(false);
        }

        // Stop microphone level analysis
        if (rafIdRef.current) {
            cancelAnimationFrame(rafIdRef.current); // Stop requestAnimationFrame
        }

        if (audioStreamRef.current) {
            audioStreamRef.current.getTracks().forEach(track => track.stop()); // Stop the media stream
        }

        // Reset audio level to zero immediately
        setAudioLevel(0);
    };

    const handleTermCondition = (e) => { 
        setIsAcceptTermCondition( e.target.checked)
    }

    const onchangeInfo = (e) => {
        const { value, name } = e.target;
        setCandidateDetail(prev => ({ ...prev, [name]: value}));
    }

    const onHandleInfo = async () => {
        setErrors({});
        let validData = true
        // Validate jobId
        const errorList = {}
        if (candidateDetail?.name?.trim() == '') {
            errorList.candidate_name = 'Name is required'
            validData = false
        }
        if (candidateDetail?.email?.trim() == '') {
            errorList.candidate_email = 'Email is required'
            validData = false
        }
        if (candidateDetail?.phone_num?.trim() == '') {
            errorList.phone_num = 'Phone Number is required'
            validData = false
        }
        var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (candidateDetail?.email != '' && !(mailFormat.test(candidateDetail?.email))) {
            errorList.candidate_email = 'Required valid email address';
            validData = false
        }
        setErrors(errorList)
        if (validData){
            await onSaveInfo();
            setShowCheckContentType('microphone')
            await checkPermissions();
        }
    }

    const onSaveInfo = async () => {
        const payload = new FormData()
        payload.append('info_params[candidate_name]', candidateDetail?.name)
        payload.append('info_params[candidate_email]', candidateDetail?.email)
        payload.append('info_params[phone_num]', candidateDetail?.phone_num)

        let URL = `/ai_interviews/update_info/${candidateInfo.id}`
        
        await makeRequest(URL,'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
        }).then((res) => {
            if(res.data.messageType == 'success'){
                setCandidateInfo(res.data.invites)
            }
        }) 
    }

    const onUpdateStatus = async () => {
        const payload = new FormData()
        payload.append('id', candidateInfo.id)
        payload.append('status', 'Inprogress')

        let URL = `/ai_interviews/update_interview_status`
        
        await makeRequest(URL,'post', payload, {
            contentType: 'application/json',
            loadingMessage: '',
        }).then((res) => {
            if(res.data.messageType == 'success'){
            }
        }) 
    }

    const onHandleStartInterview =  async () => {
         // Reset error object at the beginning
         setErrorObj({});
        await checkPermissions();
        let error = false
        // Check for terms acceptance
        if (!isAcceptTermCondition) {
            let errorInterviewType = { termCindition: true }; // Add your error conditions here
            setErrorObj(errorInterviewType); // Set error state for terms acceptance
            error = true
        }

        // Check for microphone and camera permissions
        if (!hasPermission) {
            let errorPermissionType = { permissions: true }; // Add your error conditions here
            setErrorObj(prev => ({ ...prev, ...errorPermissionType })); // Update error state for permissions
            error = true
        }

        if(!error){
            setShowCheckContentType('guidline') 
        }
    }
    return(
        <>
            {!screenInterviewModal ? (
            <Modal
                show={showStartInterviewModal}
                onHide={() => setShowStartInterviewModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                centered
                backdrop="static"
                id="modal-start-interview"
            >
                <Modal.Body >
                    <div className="d-flex justify-content-between">
                        <div className="d-inline-flex align-items-center">
                            { organization && organization?.image_url && organization?.image_url != null &&
                                <img
                                    alt={ organization.name} src={ organization.image_url}
                                    width="50" height="50"
                                    className="d-inline-block align-top"
                                />
                            }
                            <div className="ml-2">
                                <p className="mb-n1 black-dark-font">{ organization.name}</p>
                                <a href={ organization.website_url} target='_blank' className="text-decoration-underline text-primary">{ organization.website_url }</a>
                            </div>
                        </div>
                        <span onClick={() => setShowStartInterviewModal(false)} role="button">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '30px', height: '30px' }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </span>
                    </div>
                    <Form className="rounded-lg p-3 mt-1 mb-2 black-dark-font">
                        {showCheckContentType == 'info' &&
                          <>
                            <div style={{paddingLeft: '15%', paddingRight: '15%', paddingTop: '2%', paddingBottom: '2%'}}>
                                <Row>
                                    <Col className="w-50">
                                        <Form.Group>
                                            <Form.Label>Your Name*</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={candidateDetail?.name}
                                                onChange={onchangeInfo}
                                            />
                                            {errors && errors.candidate_name && (
                                                <p className='text-error-dannger mt-1'>{errors.candidate_name}</p>
                                            )}
                                        </Form.Group>
                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="w-50">
                                        <Form.Group>
                                            <Form.Label>Email Address*</Form.Label>
                                            <Form.Control 
                                                name="email"
                                                type="email" 
                                                value={candidateDetail?.email}
                                                onChange={onchangeInfo}
                                            />
                                            {errors && errors.candidate_email && (
                                                <p className='text-error-dannger mt-1'>{errors.candidate_email}</p>
                                            )}
                                        </Form.Group>
                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="w-50">
                                        <Form.Group>
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control 
                                                name="phone_num"
                                                type="text" 
                                                placeholder="(12)34567894" 
                                                value={candidateDetail?.phone_num}
                                                onChange={onchangeInfo}
                                            />
                                            {errors && errors.phone_num && (
                                                <p className='text-error-dannger mt-1'>{errors.phone_num}</p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                             <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                                <Button variant="primary" className="ai-btn-primary" onClick={() => onHandleInfo()}>Next</Button>
                            </div>
                        </>
                        }
                        {showCheckContentType == 'microphone' && 
                            <>  <div className="text-center">
                                        <div className="justify-content">
                                                <h5 className="microphone-heading">Check Microphone and Camera Permissions</h5>
                                        </div>
                                        <div className="text-left">
                                            <p style={{textAlign: 'justify'}}>Please note that we use audio, video, and screen sharing during the interview process to ensure an accurate assessment and proctoring score. The recording of your screen will be included in the AI interview report for review.</p>
                                        </div>
                                </div>
                                <div className="text-center">
                                     <div className='border rounded-lg p-3 shadow  mt-3 mb-2 microphone-box'>
                                        <div className="text-center">
                                            {cameraStatus && <p className={cameraFlag ? 'access-p' : 'denied-p'}>{cameraStatus}</p>}
                                            {microphoneStatus && <p className={microphoneFlag ? 'access-p' : 'denied-p'}>{microphoneStatus}</p>}

                                            {/* Step 1: Check Permissions */}
                                            {!hasPermission && (
                                                <div className="mt-2">
                                                    <p>Click below to allow access to your microphone and camera</p>
                                                    <button type="button" onClick={checkPermissions} className="btn btn-outline-primary">Check Permissions</button>
                                                </div>
                                            )}

                                            {/* Step 2: Microphone Test if permissions granted */}
                                            {hasPermission && (
                                            <>
                                                <div>
                                                    <p style={{textAlign: 'center'}}>Speak and pause to check your microphone, you will hear your voice. (<b>Mandatory</b>)</p>
                                                    {!isRecording ? (
                                                        <button type="button" className="btn btn-outline-primary" onClick={startSpeaking}>Start Speaking</button>
                                                    ) : (
                                                        <button type="button" className="btn btn-outline-primary" onClick={stopSpeaking}>Pause</button>
                                                    )}
                                                </div>
                                                
                                                <div className='level-container mt-3'>
                                                    <p>Level Input</p>
                                                    <div className="level-input">
                                                        {[...Array(10)].map((_, i) => (
                                                            <div key={i} className={`level-circle ${i < audioLevel ? 'active' : ''}`}></div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                            )}
                                            {/* Hidden audio element for auto-playing the recorded voice */}
                                            <audio ref={audioRef} hidden></audio>
                                        </div>
                                        {errorObj && errorObj.permissions && (
                                            <p className='text-error-dannger mt-1'>Please allow access to your camera and microphone to proceed</p>
                                        )}
                                     </div>
                                     <div className="mt-3">
                                            <Form.Check type="checkbox" name="term" label={
                                                <span>
                                                    I agree to the <a href="/terms_of_service" target="_blank" rel="noopener noreferrer">terms & conditions</a> of this AI interview process
                                                </span>
                                            }  value="true"
                                            id={`default-term`}
                                            onChange={handleTermCondition} 
                                           />
                                           {errorObj && errorObj.termCindition && (
                                                <p className='text-error-dannger'>Please accept term & condition</p>
                                            )}
                                     </div>
                                </div>
                                <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                                     <Button variant="primary" className="ai-btn-primary" onClick={onHandleStartInterview}>Start Interview</Button>
                                </div>
                            </>
                        }
                        {showCheckContentType == 'guidline' &&  
                            <>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="start">
                                        <div className="profile-img position-relative">
                                            <img
                                                alt="Interviewee" src={maissaImage}
                                            />{' '}
                                            <div className="profile-name">Maissa</div>
                                        </div>

                                        <div className="instructions">
                                            <h6>Before Starting the Interview, Make sure to:</h6>
                                            <ol>
                                                <li>Disconnect any extra screen and use one device for the interview.</li>
                                                <li>Position yourself directly in front of the camera and strive to maintain eye contact with the screen throughout the interview.</li>
                                                <li>Please do not open any other tabs during the interview.</li>
                                                <li>Do not refresh the page once the interview has started, as you will not be able to rejoin or give the interview again.</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                                     <Button variant="primary" className="ai-btn-primary" onClick={()=> { onUpdateStatus(); setScreenInterviewModal(true); } } >Done, Start the Interview</Button>
                                </div>
                            </>
                        }
                    </Form>
                </Modal.Body>
            </Modal>
            ) : (
                <InterviewScreeningPage
                    setScreenInterviewModal={setScreenInterviewModal}
                    screenInterviewModal={screenInterviewModal}
                    interviewDetail={updateCandidateInfo}
                    organization={organization}
                />
            )}
        </>
    )
}
export default StartInterviewPage;