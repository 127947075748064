import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import Util from "../../../../utils/util";

const UserSubscriptionReport = () => {
    var active_page = window.sessionStorage.getItem('activePage')
    var query = window.sessionStorage.getItem('query')
    const localStorageDateRange = JSON.parse(sessionStorage.getItem('subscriptionReportDateRange'))
    const startDate = (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate()
    const endDate = (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate()
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const [report, setReport] = useState([])
    const [totalEntry,setTotalEntry] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const startDate = moment(dateRange.startDate).format('MMM,DD, YYYY')
        const endDate = moment(dateRange.endDate).format('MMM,DD, YYYY')
        fetchData(startDate,endDate)
    }, [activePage, dateRange])

    const handleDate = (date) => {
        sessionStorage.setItem('subscriptionReportDateRange', JSON.stringify(date))
        setDateRange(date)
    }

    const fetchData = (sDate,eDate) => {
        setLoading(true)
        const url = `/cardinal/user_subscription.json`
        window.sessionStorage.setItem('activePage',activePage)
        makeRequest(url, 'get',{params: {page: (activePage+1),startDate: sDate ,endDate: eDate, query: query}}, {   
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setReport(
                    res.data.subscriptions.map((record) => ({
                        id: record.user_id,
                        user_id: record.user_id,
                        user: Util.capitalize(record?.user?.first_name)+' '+Util.capitalize(record?.user?.last_name),
                        organization_id: record.organization_id,
                        organization: Util.capitalize(record.organization.name),
                        plan: Util.capitalize(record.subscription_plan.name),
                        amount: '$'+record.amount,
                        start_date: record.start_date,
                        end_date: record.end_date,
                        status: Util.capitalize(record.status),
                    }))
                )
                setPageCount(res.data.total_pages)
                setTotalEntry(res.data.total_count)
                setCurrentCounts(res.data.current_counts)
                setStartRecord(activePage * res.data.per_page)
                setLoading(false)
            },
        })
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    User Subscription Reports
                </P>
                <div style={{flexGrow:1}}/>
              
            </div>
            
            <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
                <Col xs={12} sm={12} lg={9} className='p-0'>
                    {totalEntry > 0  &&
                        <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalEntry} Users
                        </DisplayPagination>
                    }
                 </Col>
                 <Col  xs={12} sm={12} lg={3} className='text-right p-0'>
                 <DateRangePicker
                        handleOnSubmit={handleDate}
                        defaultDate={dateRange}
                    />
                 </Col>
            </Row>
            <Table
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Name',
                        field: 'organization',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Epmloyeer Name',
                        field: 'user',
                        editable: true,
                        type: 'text',
                    },
                    {
                        name: 'Plan Name',
                        field: 'plan',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Amount',
                        field: 'amount',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Start Date',
                        field: 'start_date',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'End Date',
                        field: 'end_date',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    },
                    {
                        name: 'Status',
                        field: 'status',
                        editable: false,
                        type: 'text',
                        nowrap:'text-nowrap'
                    }
                
                ]}
                rowValues={report.map((entry) => ({
                    ...entry,
                    start_date: moment(entry.start_date).format('MMM DD, YYYY'),
                    end_date: moment(entry.end_date).format('MMM DD, YYYY'),
                }))}
                showEditOption={true}
                activePage={activePage}
                goToEditPage={(id) =>
                    getUpdateData(id)
                }
                loading={loading}
            />

            {pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
           
        </Card>
    )
}

export default UserSubscriptionReport   