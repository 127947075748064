import React, { useState, useEffect } from 'react'
import Table from '../Admin/shared/Table'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import { Row, Col } from 'react-bootstrap'
import './styles/Dashboard.scss'
import moment from 'moment'
import { capitalize } from '../../../utils'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts'
import Modal from "react-bootstrap/Modal"
import Container from "react-bootstrap/Container"

const Dashboard = (obj) => {
const [loading, setLoading] = useState(false)
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#d988b8", "#28a745","#dc3545"]; 
const stages = {lead: 'Lead', applicant: 'Applicant', recruitor_screen: 'Recruiter Screened', submitted: 'Submitted', first_interview: 'First Interview', second_interview: 'Second Interview', onhold: 'On Hold', offer: 'Offers', reject: 'Archived', hired: 'Hired'}
const [props, setPropsData] = useState({})
const [btnDisabled, setBtnDisabled] = useState(false)
const [meetingModel, setMeetingModal] = useState(false)
const [meetId, setMeetId] = useState(false)
const stageData = [
    {
      "name": "Applicant",
      "value": props?.stage_data?.applicant || 0
    },
    {
      "name": "Recruiter Screened",
      "value": props?.stage_data?.recruitor_screen || 0
    },
    {
      "name": "Submitted",
      "value": props?.stage_data?.submitted || 0
    },
    {
      "name": "Interviews",
      "value": ( (props?.stage_data?.second_interview || 0) + (props?.stage_data?.first_interview || 0))
    },
    {
      "name": "On Hold",
      "value": props?.stage_data?.onhold || 0
    },
    {
      "name": "Offers",
      "value": props?.stage_data?.offer || 0
    },
    {
      "name": "Hired",
      "value": props?.stage_data?.hired || 0
    },
    {
      "name": "Archived",
      "value": props?.stage_data?.reject || 0
    }
  ];

  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  const getPath = (x, y, width, height) => `M${x},${y + height}
      C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
      C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
      Z`;
  
  const replaceStage = (str) => {
    if(str != ''){
      let temp_string = str.replace('first_interview', 'First Interview')
          temp_string = temp_string.replace('lead', 'Lead')
          temp_string = temp_string.replace('applicant', 'Applicant')
          temp_string = temp_string.replace('recruitor_screen', 'Recruiter Screened')
          temp_string = temp_string.replace('submitted', 'Submitted')
          temp_string = temp_string.replace('second_interview', 'Second Interview')
          temp_string = temp_string.replace('offer', 'Offers')
          temp_string = temp_string.replace('reject', 'Archived')
          temp_string = temp_string.replace('hired', 'Hired')
          temp_string = temp_string.replace('onhold', 'On Hold')
          return temp_string
    }
    return str
  } 

  const colourStyles = {
    control: styles => ({ ...styles, height: 'auto',minWidth:'15em',maxWidth:'15em',minHeight:'45px', borderRadius: '2px', color: 'rgb(76, 104, 255) !important;',backgroundColor: 'rgb(235, 237, 250)' })
  }
  
  useEffect(() => {
    let isShown = (localStorage.getItem("meet-sourcer-showed")) ? true : false
    if(obj?.currentUser?.role == 'recruiter' && isShown == false){
      checkMeetingAvaliablity()
    }
    getData(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const onChangeSwitch = async (val) => {
    getData(val)
  }

  const getData = async (val) => {
    const url = '/dashboard.json' 
    try {
        await makeRequest(url, 'get',  {params: {org: (val) ? 'all' : obj?.organization?.id}}, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (response) => {
            },
        }).then((res) => {
           console.log(res.data)
          setPropsData(res.data)
        })    
    } catch (e) {
        console.log('error')
        console.log(e)
    }
  }

  const checkMeetingAvaliablity = async () =>{
      console.log('call checkMeetingAvaliablity')
       try {
        await makeRequest('/sourcer_meeting', 'get',  {}, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (response) => {
            },
        }).then((res) => {
           console.log(res.data)
           if(res?.data?.data?.id){
              setMeetId(res?.data?.data?.id)
              setMeetingModal(true)
           }
        })    
    } catch (e) {
        console.log('error')
        console.log(e)
    }
  }

  const onHide = () => {
    setMeetId('')
    setMeetingModal(false)
    localStorage.setItem("meet-sourcer-showed",true)
  }

  const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
      let URL = `/sourcer_meeting/`+meetId
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,'put', {feedback: e.target.feedback.value, meet:  e.target.meet.value}, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.code == 'success'){
						onHide()
				}
			}) 
		}
		setBtnDisabled(false)
	}

  const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

  return (
    <>
    <div className="container-full-box">
        <Row className="w-100">
            <Col xs={2} sm={2} lg={2} className="mr5" >
                <div className='container-box'>
                    <span>Active Jobs</span>
                    <h5 className='mt-3'><b>{ props?.active_jobs_count || 0  }</b></h5>
                </div>
            </Col>
            <Col xs={2} sm={2} lg={2} className="mr5">
                <div className='container-box'>
                    <span>Closed Jobs</span>
                    <h5 className='mt-3'><b>{ props?.closed_jobs_count || 0  }</b></h5>
                </div>
            </Col>
            { obj?.currentUser?.is_commission_recruiter != 'yes' &&
              <Col xs={2} sm={2} lg={2} className="mr5" >
                  <div className='container-box'>
                      <span>{ (props?.current_user?.role == 'employer') ? 'Total Recruiters' :'Total Members'}</span>
                      <h5 className='mt-3'><b>{ props?.recruiter_count || 0 }</b></h5>
                  </div>
              </Col>
            }
            {/* <Col xs={2} sm={2} lg={2} className="" >
                <div className='container-box'>
                    <span>Last Week Submissions</span>
                    <h5 className='mt-3'><b>{ props?.stage_week_data?.submitted || 0  }</b></h5>
                </div>
            </Col> */}
            <Col xs={2} sm={2} lg={2} className="mr5" >
                <div className='container-box'>
                    <span>Last Week Hired</span>
                    <h5 className='mt-3'><b>{ props?.stage_week_data?.hired || 0  }</b></h5>
                </div>
            </Col>
            <Col xs={2} sm={2} lg={2} className="mr5" >
                <div className='container-box'>
                    <span>Current Week Hired</span>
                    <h5 className='mt-3'><b>{ (props?.current_week_hired?.[0]) ?  props?.current_week_hired?.[0]['count'] : 0  }</b></h5>
                </div>
            </Col>
        </Row>
        <Row className="w-100">
            <Col xs={8} sm={8} lg={8}>
                <h3  className='heading'>Candidate Stage Summary</h3>
            </Col>
            <Col xs={3} sm={3} lg={3} style={{textAlign: 'right'}}>
                 { obj?.currentUser?.role == 'recruiter' && obj?.currentUser?.is_commission_recruiter != 'yes'  &&
                    <div className="custom-control custom-switch custom-z-index">
                        <input type="checkbox" className="custom-control-input" onClick={(e)=>{
                            onChangeSwitch(e.target.checked)
                         }}   id="team_lead_only"/>
                        <label className="custom-control-label" htmlFor="team_lead_only" style={{fontWeight: 500}}>Show All Organizations</label>
                    </div>
                    }
            </Col>
        </Row>

        <Row className="w-100 mt-3" >
          <Col xs={12} sm={12} lg={12}>
                  <BarChart
                    width={1200}
                    height={300}
                    data={stageData}
                    margin={{
                        top: 30                                                                                                                                                                                                                                                                                                                                                                                                 ,
                        right: 5,
                        left: 10,
                        bottom: 10
                    }}
                    >
                      

                  <CartesianGrid strokeDasharray="3 3" /> 
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Bar dataKey="value" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                      {stageData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % 20]} />
                      ))}
                  </Bar>
                  </BarChart>
        </Col>
      </Row>

      <Row className="w-100 mt-5">
          <Col xs={12} sm={12} lg={12}>
              <h3 className='heading'>Candidate Activities</h3>
          </Col>
      </Row>
      <Row className="w-100 m-1 mt-4 mb-4 " >
        <Col xs={12} sm={12} lg={12}>
         <Row className="w-100">
            <Table 
              columNames={[
                {
                  name: 'No',
                  field: 'sr_no',
                  editable: false,
                  type: 'text',
                },
                {
                    name: 'Candidate Name',
                    field: 'candidate_name',
                    editable: false,
                    type: 'text',
                },
                {
                    name: 'Job Name',
                    field: 'job_name',
                    editable: false,
                    type: 'text',
                },
                {
                    name: 'Previous Stage',
                    field: 'previous_stage',
                    editable: false,
                    type: 'text',
                },
                {
                    name: 'Current Stage',
                    field: 'stage',
                    editable: false,
                    type: 'text',
                },
                {
                    name: 'Moved On',
                    field: 'created_at',
                    editable: false,
                    type: 'text',
                },
                {
                    name: 'Feedback',
                    field: 'feedback',
                    editable: false,
                    type: 'text',
                }
              ]}
              rowValues={props?.transaction_details?.map((o,index)=>({
                ...o,
                sr_no: parseInt(index + 1),
                created_at: moment(o.created_at).format(
                    'MMM DD, YYYY'
                ),
                candidate_name: capitalize(o.pfn) + ' ' + capitalize(o.pln),
                job_name:<a href={'/jobs/' + o.job_id+'?stage='+o.stage}> {capitalize(o.job_name) }</a>,
                stage: stages[o.stage],
                previous_stage: stages[o.previous_stage],
                feedback: replaceStage(o.feedback)
              }))
              }
              loading={loading}
            />
          </Row>
        </Col>
      </Row>
      <Modal
        onHide={onHide}
        show={meetingModel}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="mx-auto">
              Did you meet with your sourcer?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
              <Row className="w-100" >
                  <Col xs={6} sm={6} lg={6} style={{textAlign:'right'}}>
                      <label><input type='radio' name="meet"  value={'Yes'} checked  className='form-controller mr-2'/>  Yes</label>
                  </Col>
                  <Col xs={6} sm={6} lg={6} style={{textAlign:'left'}}>
                      <label><input type='radio' name="meet"  value={'No'}  className='form-controller mr-2'/>  No</label>
                  </Col>
              </Row>
              <div className="w-100 mt-3">
                  <Col xs={12} sm={12} lg={12}>
                    <label>Feedback : </label>
                    <textarea className='form-control w-100 mt-1'  name='feedback' placeholder='Please copy the email content here.' id="feedback" row="10"></textarea>
                  </Col>
                </div>
                <div className="w-100 mt-4" style={{borderBottom: '1px solid #dee2e6'}}>
                </div>
                <div className="w-100">
                  <Col xs={12} sm={12} lg={12}>
                      <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius' style={{marginTop:'20px',float:'right'}}> Save</Button>
                  </Col>
                </div>
            </form>
        </Modal.Body>
      </Modal>
    </div>

    
    </>
  )
}

export default Dashboard