import axios from 'axios';

export const getOrganizations = async () => {
  const url = '/recruiter_organizations';

  const CSRF_Token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  try {
    const response = await axios.get(`${url}`, {
      headers: {
        'content-type': 'application/json',
        'X-CSRF-Token': CSRF_Token,
      },
    });
    const properOrgnizations = response.data.recruiter_organizations?.filter(
      (organization) => organization !== null
    );
    const recruiterOrganizationsArray = properOrgnizations?.map(
      (recruiterOrganization) => {
        return {
          value: recruiterOrganization?.id,
          label: recruiterOrganization?.name,
        };
      }
    );
    return recruiterOrganizationsArray;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
};

export const getOrganizationFavorite = async () => {
  const url = '/user_recruiter_organizations';

  const CSRF_Token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  try {
    const response = await axios.get(`${url}`, {
      headers: {
        'content-type': 'application/json',
        'X-CSRF-Token': CSRF_Token,
      },
    });
    const properOrgnizations = response.data.recruiter_organizations?.filter(
      (organization) => organization !== null
    );

    const properOrgnizationFavs = response.data.organization_fav?.filter(
      (organization) => organization !== null
    );

    const recruiterOrganizationsArray = properOrgnizations?.map(
      (recruiterOrganization) => {
        return {
          value: recruiterOrganization?.id,
          label: recruiterOrganization?.name,
        };
      }
    );

    const recruiterOrganizationFavArray = properOrgnizationFavs?.map(
      (recruiterOrganization) => {
        return {
          value: recruiterOrganization?.id,
          label: recruiterOrganization?.name,
        };
      }
    );
    return { recruiterOrganizationsArray: recruiterOrganizationsArray, recruiterOrganizationFavArray: recruiterOrganizationFavArray };
  } catch (e) {
    console.error(e.message);
    throw e;
  }
};


export const getCommunitiesList = async (community_id) => {
  const url = '/communities/fetch_communities_list.json?community_id='+community_id;
  const CSRF_Token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');
  try {
    const response = await axios.get(`${url}`, {
      headers: {
        'content-type': 'application/json',
        'X-CSRF-Token': CSRF_Token,
      },
    });
    return response.data;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
};