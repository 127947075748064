import React, { useState, useEffect } from 'react'
import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col } from 'react-bootstrap'
import './styles/Team.scss'
import Select from 'react-select'
import styles from '../../Admin/styles/JobsStatusPage.module.scss'

import Util from '../../../../utils/util'
import Modal from 'react-bootstrap/Modal'
import TeamAdd from './TeamAdd'
import SearchBar from '../../../common/SearchBar/SearchBar'
import moment from 'moment'
import { capitalize } from '@material-ui/core'
import linkedinIcon  from '../../../../../assets/images/icon-linkedin.png'
import axios from 'axios'

const TeamContactsList = ({
    recruiters,
    recruiter_list,
    team_lists,
}) => {
    const team_lead_list = []
    var active_page = window.sessionStorage.getItem('teamListActivePage')
    var query = window.sessionStorage.getItem('query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [pageCount, setPageCount] = useState(0)
    const queryParams = new URLSearchParams(window.location.search)
    const jobId = queryParams.get("job")
    const [teams, setTeams] = useState([])
    const [totalTeams, setTotalTeams] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [teamUserId, setTeanUserId] = useState()
    const [show, setShow] = useState(false)
    const [showMember, setShowMember] = useState(false)
    const [method, setMethod] = useState('post')
    const [entry, setEntry] = useState({})
    const [loading, setLoading] = useState(true)
    let team_name_search = sessionStorage.getItem("team_name_search")
    const [teamlist,setTeamList] = useState([{value: 'all', label: 'All'},...team_lists])
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [fetchRecApi, setFetchRecApi] = useState()
    const [selected,setSelected] = useState((jobId)? team_lists.filter((option) => option.value == jobId) : team_name_search && team_name_search != "null" ? JSON.parse(team_name_search) : {value: 'all', label: 'All'})
    const [selectedteam,setSelectedTeam] = useState(team_name_search && team_name_search != "null" ? JSON.parse(team_name_search).value : jobId ? jobId : '')
    const fetchData = () => {
        setLoading(true)
        const url = `/admin/team_contacts.json`
        window.sessionStorage.setItem('teamListActivePage', activePage)
        makeRequest(
            url,
            'get',
            {
                params: {
                    page: activePage + 1,
                    query: query,
                    search: searchTerm,
                    team_name: selectedteam
                },
            },
            {
                contentType: 'application/json',
                loadingMessage: 'Fetching...',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                    setData(res?.data?.teams)
                    setTeams(
                        res.data.teams.map((team) => ({
                            id: team.id,
                            name: team?.user?.first_name + " " + team?.user?.last_name,
                            team: team?.team?.name,
                            department: team?.department,
                            email: team?.email,
                            linkedln: team?.linkedln ? <a target='_blank' href={team?.linkedln}>
                            <img style={{width:'20px', height:'20px'}}  src={linkedinIcon} /></a>
                             : '',
                            start_date: team?.start_date
                                ? moment(team?.start_date).format(
                                      'MMM DD, YYYY'
                                  )
                                : '',
                            location: team?.location,
                            manager: team?.team?.owner?.first_name + " " + team?.team?.owner?.last_name,
                            phone: team?.phone,
                            title: team?.title,
                            birthday: team?.birthday?.length > 1 ? team?.birthday.split(" ").join(', ') : ''
                        }))
                    )
                    setPageCount(res?.data?.total_pages)
                    setTotalTeams(res?.data?.total_count)
                    setCurrentCounts(res?.data?.current_counts)
                    setStartRecord(activePage * res?.data?.per_page)
                    setLoading(false)
                },
            }
        )
    }
    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage, selectedteam])

    const deleteTeam = async (id) => {
        const URL = `/admin/team_contacts/${id}`
        await makeRequest(URL, 'delete', '', {
            createResponseMessage: () => {
                return {
                    message: `Deleted successful`,
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: () => {
                fetchData()
            },
        })
    }

    
    const addTeam = () => {
        setEntry({})
        setMethod('post')
        setShow(true)
    }

    const onHide = () => {
        setEntry({})
        setMethod('post')
        setShow(false)
        setShowMember(false)
    }

    const onSelectRecruiter = async (event) => {
        const url = `/admin/team_contacts/filter_team_recruiter`;
        const formData = new FormData();
        formData.append('filter_team', event);
        setLoading(true);
        const response = await axios
            .post(url, formData)
            .then((res) => setFetchRecApi(res?.data?.data))
            .catch((error) => console.log(error));
        setLoading(false)
      }

    const getUpdateData = (id) => {
        let getTeam = data.filter((row) => row.id == id)
        onSelectRecruiter(getTeam[0].team.id)
        if (getTeam?.length > 0) {
            setMethod('put')
            setEntry(getTeam[0])
            setShow(true)
        }
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('user_query', value)
    }

    const getMembersData = (id) => {
        let getTeam = teams.filter((row) => row.id == id)
        if (getTeam?.length > 0) {
            setEntry(getTeam[0])
            setShowMember(true)
        }
    }
    return (
        <>
            <Card>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <P
                        size="28px"
                        height="38px"
                        color="#1D2447"
                        style={{ width: '540px' }}
                    >
                        Team Member's Contacts
                    </P>
                    <div style={{ flexGrow: 1 }} />
                    <Button onClick={addTeam} className="ml-3">
                        Add Entry
                    </Button>
                </div>

                <div
                    className="d-flex justify-content-between align-items-center w-100"
                    style={{ marginBottom: '10px', marginTop: '20px' }}
                >
                    {' '}
                    <Col
                        xs={12}
                        sm={12}
                        lg={2}
                        className="p-0"
                        style={{ float: 'left' }}
                    >
                        {totalTeams > 0 && (
                            <DisplayPagination>
                                Displaying{' '}
                                {startRecord == 0 ? 1 : startRecord + 1} -{' '}
                                {currentCounts > totalTeams
                                    ? totalTeams
                                    : currentCounts}{' '}
                                of {totalTeams} team contacts
                            </DisplayPagination>
                        )}
                    </Col>
                    {/* <Col xs={12} sm={12} lg={4} style={{ float: 'left' }}>
                        <Select
                            options={team_lists}
                            name="job_name"
                            className={styles.colourStyles}
                        />
                    </Col> */}
                    <Col xs={12} sm={12} lg={2} style={{ float: 'left'}}>
                      <div style={{width: '104%'}}>
                        <label>Team Name</label>
                        <Select
                            defaultValue={selected}
                            options={teamlist}
                            onChange={(event)=>{
                              setSelectedTeam(event.value)
                              sessionStorage.setItem("team_name_search", JSON.stringify(event))
                              }}
                              name="job_name"
                              // className={styles.colourStyles}
                              />
                        </div>
                    </Col>
                </div>
                <Table
                    columNames={[
                        {
                            name: 'No.',
                            field: 'id',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Name',
                            field: 'name',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Department',
                            field: 'department',
                            editable: true,
                            type: 'text',
                        },
                        {
                            name: 'Title',
                            field: 'title',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Team',
                            field: 'team',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Manager',
                            field: 'manager',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Phone',
                            field: 'phone',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Email',
                            field: 'email',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Location',
                            field: 'location',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Linkedin',
                            field: 'linkedln',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Birthday',
                            field: 'birthday',
                            editable: false,
                            type: 'text',
                        },
                        {
                            name: 'Action',
                            field: 'options',
                            editable: false,
                            type: 'text',
                            nowrap: 'text-nowrap',
                            //   options:{CustomEventIcon:'users',CustomEventMethod:getMembersData}
                        },
                    ]}
                    rowValues={teams.map((team) => ({
                        ...team,
                        member: team?.member?.map((t) =>
                            t?.user?.id != team?.owner_id ? (
                                <span className="members">
                                    {' '}
                                    {Util.fullName(
                                        t?.user?.first_name,
                                        t?.user?.last_name
                                    )}{' '}
                                </span>
                            ) : (
                                ''
                            )
                        ),
                        status: Util.capitalize(team?.status),
                    }))}
                    showEditOption
                    activePage={activePage}
                    deleteAction={deleteTeam}
                    goToEditPage={(id) => {
                        setTeanUserId(id)
                        getUpdateData(id)
                    }}
                    loading={loading}
                />
                {pageCount > 1 && (
                    <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: 'auto' }}
                    >
                        <Paginator
                            activePage={activePage}
                            setActivePage={setActivePage}
                            pageCount={pageCount}
                            pageWindowSize={5}
                            showGoToField={false}
                        />
                    </div>
                )}
            </Card>

            <Modal
                onHide={onHide}
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                className="modal-entry-form"
            >
                <Modal.Header closeButton className="mt-2 mb-2">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {method === 'put'
                            ? 'Update'
                            : "Team Member's Contact Details"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TeamAdd
                        recruiters={recruiters}
                        teamEntry={entry}
                        onHide={onHide}
                        method={method}
                        recruiter_list={recruiter_list}
                        team_lists={team_lists}
                        fetchData={fetchData}
                        team_lead_list={team_lead_list}
                        teamUserId={teamUserId}
                        fetchRecApi={fetchRecApi}
                        setFetchRecApi={setFetchRecApi}
                    ></TeamAdd>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TeamContactsList
