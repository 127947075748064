import React, { useEffect, useState } from 'react'
import Card from '../../Admin/shared/Card'
import P from '../../Admin/shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from '../../Admin/styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../../utils'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   '../../Admin/styles/DataTableCustom.scss'

const TeamLeadsAnalyticPage = (prop) => {
  
  const queryParams = new URLSearchParams(window.location.search)
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(prop.per_page)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobStatus, setJobStatus] = useState([])
  const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
  const handleDate = (date) => {
      setDateRange(date)
  }
  const [teamLists,setTeamLists] = useState([{value: 'all', label: 'All'},...prop.team_lists])
  const [selectedTeam,setSelectedTeam] = useState('')
  const [totalValues,setTotalValues] = useState({})
  const [defaultselected,setdefaultselected] = useState({value: 'all', label: 'All'})
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [footer, setFooter] = useState({})

  const tableHeaders = [
    { label: 'Team Name', key: 'teamName' },
    { label: 'Recruiter Screened', key: 'recruiterScreened' },
    { label: 'Submitted', key: 'submitted' },
    { label: 'First Interview', key: 'firstInterview' },
    { label: 'Second Interview', key: 'secondInterview' },
    { label: 'Offers', key: 'offers' },
    { label: 'Hired', key: 'hired' },
    { label: 'Archived', key: 'archived' },
  ]
  
  const fetchData = async () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/agencies/analytic/team.json'
    const response = await makeRequest(
      `${url}?page=${activePage}&start_date=${start_date}&end_date=${end_date}&team=${selectedTeam}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setTotalCount(response.data.total_count)
    let sr_no = (activePage - 1) * response.data.per_page
    setJobStatus(response?.data?.team_status.map((data,key)=> (sr_no = sr_no + 1 ,{
      id: sr_no,
      teamName: data?.team_name && capitalize(data?.team_name),
      recruiterScreened: (data.recruitor_screened  > 0) ? <a href={`/agencies/analytic/team/detail?team_id=${data.team_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> :'',
      submitted: (data.submitted > 0) ? <a href={`/agencies/analytic/team/detail?team_id=${data.team_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a> :'',
      firstInterview: (data.first_interview > 0) ? <a href={`/agencies/analytic/team/detail?team_id=${data.team_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> :'',
      secondInterview: (data.second_interview  > 0) ? <a href={`/agencies/analytic/team/detail?team_id=${data.team_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> :'',
      offers: (data.offer  > 0) ? <a href={`/agencies/analytic/team/detail?team_id=${data.team_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a> :'',
      archived: (data.reject  > 0) ? <a href={`/agencies/analytic/team/detail?team_id=${data.team_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> :'',
      hired: (data.hired > 0) ? <a href={`/agencies/analytic/team/detail?team_id=${data.team_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> :'',
      teamId: data.team_id
    })))
    setFooter({id:'Total',
      recruiterScreened:response.data.total_values.recruiterScreened,
      submitted:response.data.total_values.submitted,
      firstInterview:response.data.total_values.firstInterview,
      secondInterview:response.data.total_values.secondInterview,
      onhold:response.data.total_values.onhold,
      offers:response.data.total_values.offers,
      archived:response.data.total_values.archived,
      hired:response.data.total_values.hired
    })
    setPageCount(response.data.total_pages)
    setStartRecord((activePage * response.data.per_page)+1)
    setCurrentCounts(response.data.current_counts)
    setTotalValues(response.data.total_values)
    setLoading(false)
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/agencies/analytic/team.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&team=${selectedTeam}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.team_status.map((data,key)=> ({
      id: key,
      teamName: data?.team_name && capitalize(data?.team_name),
      recruiterScreened: data.recruitor_screened,
      submitted: data.submitted,
      firstInterview: data.first_interview,
      secondInterview: data.second_interview,
      offers: data.offer,
      archived: data.reject,
      hired: data.hired
    })))
    csvExportRef?.current?.link.click();
  }
  const columns = [
    {
        name: 'No.',
        selector: row => row.id,
        maxWidth: '5%',
        minWidth: '5%'
    },
    {
        name: 'Team Name',
        selector: row => row.teamName,
        maxWidth: '20%',
        minWidth: '20%',
        sortable: true,
        sortField: 'team_name'
    },
    {
        name: 'Recruiter Screened',
        selector: row => row.recruiterScreened,
        maxWidth: '11%',
        minWidth: '11%',
        sortable: true,
        sortField: 'recruitor_screened'
    },
    {
        name: 'Submitted',
        selector: row => row.submitted,
        maxWidth: '11%',
        minWidth: '11%',
        sortable: true,
        sortField: 'submitted'
    },
    {
        name: 'First Interview',
        selector: row => row.firstInterview,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'first_interview'
    },
    {
        name: 'Second Interview',
        selector: row => row.secondInterview,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'second_interview'
    },
    {
        name: 'Offers',
        selector: row => row.offers,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'offer'
    },
    {
        name: 'Hired',
        selector: row => row.hired,
        maxWidth: '11.5%',
        minWidth: '11.5%',
        sortable: true,
        sortField: 'hired'
    },
    {
        name: 'Archived',
        selector: row => row.archived,
        maxWidth: '11.5%',
        minWidth: '11.5%',
        sortable: true,
        sortField: 'reject'
    }
 ]

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage,selectedTeam,sortDirection,sortField])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }

  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }


  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Team Analytics
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"TeamReport.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalCount} Teams</DisplayPagination>
            }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Team Name
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={teamLists}
                  onChange={(event)=>setSelectedTeam(event.value)}
                  name="team_lists"
                  className={styles.colourStyles}
              />
            </div>
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Select Date Range
              </label>
              <DateRangePicker
                  handleOnSubmit={handleDate}
                  defaultDate={dateRange}
              />
            </div>
          </div>
        </Col>
      </Row>
      <DataTable
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={jobStatus}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        onSort={handleSort}
        sortServer
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalCount}
        paginationPerPage={perPage}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        onChangePage={page => setActivePage(page)}
        footer={footer}
      />
    </Card>
  )
}

export default TeamLeadsAnalyticPage