import React, { useState } from 'react';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import styles from '../SignupPage/styles/Signup.module.scss';
import stylesAc from '../AccountSettingPage/styles/AccountSettingPage.module.scss';
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import './styles/CalanderBookSlot.scss';
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import TextInput from '../../common/TextInput/TextInput'
import {Button,StyledForm,Row} from './styles/CreateProfilePage.styled'
import { P } from '../Admin/styles/UserManagementEditPage.styled';

const MatchScorePage = ({ }) => {
    const [matchResult, setMatchResult] = useState('');
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (values) => {
        // Add your form submission logic 
        const payload = new FormData()
        const url = '/get_llm_score'
        console.log(values)
        payload.append('job_description', values.job_description)
        payload.append('c_profile',  values.c_profile)
        payload.append('note',  values.note)
        setLoading(true)
        await makeRequest(url, 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            onSuccess: (res) => {
                console.log(res)
                if (res.data.success) {
                    setMatchResult(res.data.result)
                }
                setLoading(false)   
            },
        })
    };

    const handleSkip = async (resetForm) => {
        resetForm();
        setMatchResult('');
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="">
                    <Formik
                        initialValues={{
                            job_description: '',
                            c_profile: '',
                            note: ''
                        }}
                        validationSchema={
                            Yup.object({
                                job_description: Yup.string()
                                    .required('Job Description is required'),
                                    c_profile: Yup.string()
                                    .required('Candidate Profile is required')
                            })
                        }
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleSubmit(values)
                        }}
                    >
                    {({ isSubmitting, resetForm }) => (
                        <StyledForm display="flex" direction="column"  style={{height:'auto',marginTop: '20px'}}>
                            <h3 style={{color: '#3a5182',fontWeight: '700'}}>Candidate Match Score Info</h3>
                            <hr
                                style={{
                                    border: 0,
                                    borderTop: "1px dashed #3a5182",
                                    height: 1,
                                    margin: "10px 0",
                                    color: '#3a5182'
                                }}
                                />
                            <TextInput
                                className={styles.fullwidthInput}
                                as="textarea"
                                label="Job Description*"
                                name="job_description"
                                type="text"
                                style={{ marginTop: '10px',marginBottom: '5px' }}
                                id="job_description"
                                width="100%"
                                rows="3"
                            />
                            <TextInput
                                className={styles.fullwidthInput}
                                as="textarea"
                                label="Candidate Profile*"
                                name="c_profile"
                                type="text"
                                style={{ marginTop: '10px',marginBottom: '5px' }}
                                id="c_profile"
                                width="100%"
                                rows="3"
                            />
                            <TextInput
                                className={styles.fullwidthInput}
                                label="Additional Notes"
                                as="textarea"
                                name="note"
                                type="text"
                                style={{ marginTop: '10px',marginBottom: '5px' }}
                                id="note"
                                width="100%"
                                rows="2"
                            />
                            {matchResult != '' && (
                                <>
                                    <h3 style={{color: '#3a5182',fontWeight: '700'}}>Result : </h3>
                                    <p
                                        style={{color: '#3a5182',fontSize: '16px'}}
                                        weight={'normal'}
                                        dangerouslySetInnerHTML={{
                                            __html: matchResult,
                                        }}
                                    ></p>
                                </>
                            )}
                            <div className='row'>
                                <Button type="submit" disabled={loading} className={`${styles.cardOrgBtn} mr-3`}>
                                    {loading ? (
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                        />
                                    ) : (
                                        'Generate Score'
                                    )}
                                </Button>
                                <Button type='button' onClick={() => handleSkip(resetForm)} className={`${stylesAc.editButton}`}>
                                    Reset
                                </Button>
                            </div>
                        </StyledForm>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default MatchScorePage
