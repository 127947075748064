import React from 'react'
import styles from './styles/ProfileAvatar.module.scss'
import { Dropdown, Image } from 'react-bootstrap';
import Util from '../../../utils/util'
const ProfileAvatar = ({profileAvatar, userId, organization_id, isEmployer, currentUser, userBelongsToCT, subOrgBelongsToCT, isTalent}) => {
  return(
    <Dropdown drop="down" className={styles.navDropdown}>
      <Dropdown.Toggle id="dropdown-basic" className={styles.profileAvatarDropdownButton}>
          {profileAvatar ?
            <Image src={profileAvatar} alt="Avatar" className={styles.avatar} />:
            <div className={styles.candidateAvtarImage}>{ (currentUser.first_name) ? (currentUser?.first_name && Util.getFirstCharater(currentUser?.first_name)) + (currentUser?.last_name && Util.getFirstCharater(currentUser?.last_name) ) : (currentUser?.email && Util.getFirstCharater(currentUser?.email) ) }</div>
          }
          <Dropdown.Menu align="right">
            <span className={styles.userName}>Welcome  { (currentUser.first_name) ? currentUser.first_name : currentUser.email }</span>&nbsp;
            <hr></hr>
            {(currentUser.role == 'talent' || currentUser.role == 'employer' || currentUser.role == 'recruiter') && 
              <Dropdown.Item href={`/users/profile/${userId}`}>View Profile</Dropdown.Item>
            }
            {(currentUser.role == 'admin' || currentUser.role == 'employer' || currentUser.role == 'recruiter') && 
              <Dropdown.Item href={`/communities`}>My Community</Dropdown.Item>
            }
            <Dropdown.Item href={"/account/setting"}>Account Settings</Dropdown.Item>
            {(currentUser.role == 'talent' || currentUser.role == 'employer' || currentUser.role == 'recruiter') && 
              <Dropdown.Item href={`/account/invite_your_friend`}>Invite Your Friend</Dropdown.Item>
            }
            {isEmployer && <Dropdown.Item href={organization_id ? `/organizations/${organization_id}/careers` : '/complete/profile'}>Organization's Profile</Dropdown.Item>}
            {(isEmployer || isTalent) && !(userBelongsToCT || subOrgBelongsToCT) && <Dropdown.Item href='/subscription'>Subscriptions & Billing</Dropdown.Item>}
            {(currentUser.role == 'recruiter' ) && <Dropdown.Item href='/subscription'>Subscriptions & Billing </Dropdown.Item>}
            <Dropdown.Item onClick={signOut}> Log Out</Dropdown.Item>
          </Dropdown.Menu>
      </Dropdown.Toggle>
    </Dropdown>
  )

  function signOut(){
    localStorage.removeItem("user")
    if(localStorage.getItem("meet-sourcer-showed")){
      localStorage.removeItem("meet-sourcer-showed")
    }
    fetch('/users/sign_out', {method: 'DELETE'})
    .then((res) => {
      window.location.href = '/users/sign_in'
    })
  }
}

export default ProfileAvatar;
