import React, { useState, useEffect, useRef } from 'react'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/CtrsDashboard.scss'
import validator from 'validator'
import Button from '../../Admin/shared/Button'

const AddDecisionMaker = ({teamEntry,onHide,method,fetchData}) => {
	const [validationError, setValidationError] = useState({})
	const [entryData, setEntryData] = useState({
		first_name: teamEntry?.first_name ? teamEntry?.first_name : '',
		last_name: teamEntry?.last_name ? teamEntry?.last_name : '',
		email: teamEntry?.email ? teamEntry?.email : '',
		phn_number: teamEntry?.phn_number ? teamEntry?.phn_number : '',
		linkedin_url: teamEntry?.linkedin_url ? teamEntry?.linkedin_url : '',
		position: teamEntry?.position ? teamEntry?.position : '',
		location: teamEntry?.location ? teamEntry?.location : '',
		company_name: teamEntry?.company_name ? teamEntry?.company_name : '',
		source: teamEntry?.source ? teamEntry?.source : '',
		industry: teamEntry?.industry ? teamEntry?.industry : '',
		company_size: teamEntry?.company_size ? teamEntry?.company_size : '',
	})
	const [btnDisabled, setBtnDisabled] = useState(false)
	const handleChange = (e) => {
		setValidationError({})
		// setSelectedRecruiter(e.value)
		// sessionStorage.setItem("jobStatus_recruiter", JSON.stringify(e))
	}
	const [modal,setModal] = useState('')
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/admin/decision_makers'
			const payload = new FormData()
			if(method === 'put'){
				URL = `/admin/decision_makers/${teamEntry.id}`
			}
			payload.append('decision_makers[first_name]',e?.target.first_name?.value)
			payload.append('decision_makers[last_name]',e?.target.last_name?.value)
			payload.append('decision_makers[email]', e?.target?.email?.value)
			payload.append('decision_makers[phn_number]',e?.target?.phn_number?.value)
			payload.append('decision_makers[position]', e?.target?.position?.value)
			payload.append('decision_makers[location]', e?.target?.location?.value)
			payload.append('decision_makers[company_name]', e?.target?.company_name?.value)
			payload.append('decision_makers[linkedin_url]', e?.target?.linkedin_url?.value)
			payload.append('decision_makers[source]', e?.target?.source?.value)
			payload.append('decision_makers[industry]', e?.target?.industry?.value)
			payload.append('decision_makers[company_size]', e?.target?.company_size?.value)
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method == 'mail' ? 'post' : method , payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
              		setBtnDisabled(true)
						onHide()
						fetchData()
				}else{
					setBtnDisabled(false)
				}
			}) 
		}
		// setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		const phoneRegex = /^\d{10}$/
		const regexLinkdin = new RegExp('www.linkedin.com')
		const regex_to = new RegExp('linkedin.com')
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		if(!(e?.target?.first_name?.value?.trim())){
			formIsValid = false;
			errors["first_name"] = "First name is required field."
		}
		if(!(e?.target?.last_name?.value?.trim())){
			formIsValid = false;
			errors["last_name"] = "Last name is required field."
		}
		// if(!(e?.target?.email?.value?.trim())){
		// 	formIsValid = false;
		// 	errors["email"] = "Email is required field."
		// }
		// if(!phoneRegex.test(e?.target?.phn_number?.value?.trim())){
		// 	formIsValid = false;
		// 	errors["phn_number"] = "Phone Number should consist only 10 digits"
		// }
		if(!(e?.target?.position?.value?.trim())){
			formIsValid = false;
			errors["position"] = "position is required field."
		}
		if(!(e?.target?.location?.value?.trim())){
			formIsValid = false;
			errors["location"] = "location is required field."
		}
		if(!(e?.target?.company_name?.value?.trim())){
			formIsValid = false;
			errors["company_name"] = "Company name is required field."
		}
		if (
			( !(regexLinkdin.test(e?.target?.linkedin_url?.value)) && !(regex_to.test(e?.target?.linkedin_url?.value)) && e?.target?.linkedin_url?.value?.length && validator.trim(e?.target?.linkedin_url?.value))){
		    	formIsValid = false;
				errors["linkedin_url"] ='Invalid url, please add Linkedin url only.'
			}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<Container> 
				<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
						<Col xs={6} sm={6} lg={6}>
							<span>First Name*: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={entryData?.first_name}  onChange={handleChange} className='form-control w-100' name='first_name' id="first_name" />
							<label className='error'>
									{validationError && validationError.first_name}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Last Name*: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={entryData?.last_name}  onChange={handleChange} className='form-control w-100' name='last_name' id="last_name" />
							<label className='error'>
									{validationError && validationError.last_name}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Email: </span>
							<input  type='email' tabIndex="112" disabled={method == 'mail'} defaultValue={teamEntry?.email} onChange={handleChange} className='form-control w-100' name='email' id="email" />
							<label className='error'>
									{validationError && validationError.email}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Phone Number: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={entryData?.phn_number}  onChange={handleChange} className='form-control w-100' name='phn_number' id="phn_number" />
							<label className='error'>
									{validationError && validationError.phn_number}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Position*: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={entryData?.position}  onChange={handleChange} className='form-control w-100' name='position' id="position" />
							<label className='error'>
									{validationError && validationError.position}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Location*: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={teamEntry?.location} onChange={handleChange} className='form-control w-100' name='location' id="location" />
							<label className='error'>
									{validationError && validationError.location}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Company Name*: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={entryData?.company_name}  onChange={handleChange} className='form-control w-100' name='company_name' id="company_name" />
							<label className='error'>
									{validationError && validationError.company_name}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>LinkedIn URL: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={teamEntry?.linkedin_url} onChange={handleChange} className='form-control w-100' name='linkedin_url' id="linkedin_url" />
							<label className='error'>
									{validationError && validationError.linkedin_url}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Source: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={teamEntry?.source} onChange={handleChange} className='form-control w-100' name='source' id="source" />
							<label className='error'>
									{validationError && validationError.linkedin_url}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Industry: </span>
							<input  type='text' tabIndex="112" disabled={method == 'mail'} defaultValue={teamEntry?.industry} onChange={handleChange} className='form-control w-100' name='industry' id="industry" />
							<label className='error'>
									{validationError && validationError.linkedin_url}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Company Size: </span>
							<input  type='number' tabIndex="112" disabled={method == 'mail'} defaultValue={teamEntry?.company_size} onChange={handleChange} className='form-control w-100' name='company_size' id="company_size" />
							<label className='error'>
									{validationError && validationError.linkedin_url}        
							</label>
						</Col>
					</Row>
					<Row className="w-100 modal-entry-form-row" >
						<Col xs={3} sm={3} lg={3}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Save</Button>
						</Col>
					</Row>
				</form>
				</Container>
		</>
  )
}

export default AddDecisionMaker;