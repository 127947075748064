import React, { Fragment,useState } from 'react'
import { Modal} from 'react-bootstrap'
import Moment from 'moment'

import CandidateDetails from '../CandidateForm/CandidateDetails'
import EmailConfirm from '../EmailConfirm/EmailConfirm'
import { makeRequest } from '../RequestAssist/RequestAssist'
import {ApplyButton, W5text} from './styles/Apply.styled'


function ApplyJob({
    job,
    isProfileComplete,
    currentUser,
    applied,
    setApplied,
    appliedDate = '',
}) {
    const [loading, setLoading] = useState(false)
    const [applyedDate, setApplyedDate] = useState(appliedDate)
    const [candidateModel, setCandidateModal] = useState(false)
    const [emailVerify, setEmailVerify] = useState(false)

    const handleApply = async () => {
        if (currentUser) {
            if (!isProfileComplete) {
                window.location.href = '/complete/profile'
            } else if (!currentUser.email_confirmed) {
                setEmailVerify(true)
            } else {
                
                const token = document.querySelector(
                    'meta[name="csrf-token"]'
                ).content
                let payload = {authenticity_token: token}
                payload['submission'] = {job_id: job.id,source: 'talent_user'}
                
                try {
                    const url = '/submissions.json'
                    setLoading(true)
                    const { data } = await makeRequest(url,'post',payload,{
                        contentType: 'application/json',
                        loadingMessage: 'Applying...',
                        createResponseMessage: (responce) => {
                            return {
                                message: responce?.message
                                    ? responce.message
                                    : 'Applied Successfully ',
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        })
                    
                    if (data.hasOwnProperty('error')) {
                        setLoading(false)
                    } else {
                        setApplied(true)
                        setApplyedDate(new Date())
                        setLoading(false)
                    }
                } catch (error) {
                    setLoading(false)
                }
            }
        } else {
            setCandidateModal(true)
        }
    }

    return (
        <Fragment>
            <div>
                {!currentUser ? (
                    <ApplyButton
                        lr="35px"
                        tb="10px"
                        disabled={applied ? true : false}
                        onClick={() => handleApply()}
                    >
                        <W5text size="12px" color="#ffff">
                            {!applied ? `Apply for this job` : `Applied`}
                        </W5text>
                    </ApplyButton>
                ) : (
                    <ApplyButton
                        lr="40px"
                        tb="10px"
                        disabled={applied ? true : false}
                        onClick={() => handleApply()}
                    >
                        <W5text size="12px" color="#ffff">
                            {applied && applyedDate
                                ? `Applied on ${Moment(applyedDate).format(
                                      'DD-MM-YYYY hh:mm:ss'
                                  )}`
                                : 'Apply for this job'}
                        </W5text>
                    </ApplyButton>
                )}
            </div>
            <Modal
                onHide={() => setCandidateModal(false)}
                show={candidateModel}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <CandidateDetails
                    job={job}
                    setCandidateModal={setCandidateModal}
                    setLoading={setLoading}
                    setApplied={setApplied}
                />
            </Modal>
            <Modal
                onHide={() => setEmailVerify(false)}
                show={emailVerify}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <EmailConfirm
                    currentUser={currentUser}
                    setEmailVerify={setEmailVerify}
                />
            </Modal>
        </Fragment>
    )
}

export default ApplyJob
