var api = require("!../../../../../../../../shared/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../../../../shared/node_modules/css-loader/dist/cjs.js??ref--8-1!../../../../../../../../shared/node_modules/postcss-loader/src/index.js??ref--8-2!../../../../../../../../shared/node_modules/sass-loader/dist/cjs.js??ref--8-3!./JobPositionCard.module.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};