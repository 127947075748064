import styled from 'styled-components'
import { Form } from 'formik'

export const Panel = styled.div`
    flex-grow: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;
    margin-bottom: 50px;
`
export const Message = styled.span`
    font-family: Avenir, 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 68px;
    color: ${(props) => props.color};
`

export const H1 = styled.h1`
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #393f60;
    margin-bottom: 30px;
`

export const P = styled.p`
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #1d2447;
    margin-bottom: 15px;
`

export const A = styled.a`
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: #8091e7;
    cursor: pointer;
`

export const StyledForm = styled(Form)`
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    max-width: 800px;
    width: 800px;
    padding: 40px 50px;
    display: ${(props) => (props.display ? props.display : 'unset')};
    flex-direction: ${(props) => (props.direction? props.direction : 'unset')};
`
export const Label = styled.label`
    color: #3a5182;
    cursor: pointer;
    margin-bottom: 4px;
`

export const Button = styled.button`
    background: linear-gradient(
        94.67deg,
        #5f78ff -1.19%,
        #7185f2 53.94%,
        #8d91ff 102.59%
    );
    border-radius: 20px;
    padding: 9px 31px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    &:disabled {
        background: lightgray;
    }
`
export const Logo = styled.div`
    background: ${(props) =>
        props.image
            ? `url(${props.image}) no-repeat center center`
            : `linear-gradient(
        133.96deg,
        #ced9ff -13.41%,
        #eeeefd 51.28%,
        #ccceff 114.63%
    )`};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #9fabe8;
    padding: 35px 30px;
    height: 150px;
    width: 150px;
`

export const Logo2 = styled.div`
    background: ${(props) =>
        props.image
            ? `url(${props.image}) no-repeat center center`
            : `linear-gradient(
        133.96deg,
        #ced9ff -13.41%,
        #eeeefd 51.28%,
        #ccceff 114.63%
    )`};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #9fabe8;
    padding: 35px 30px;
    height: 120px;
    width: 140px;
`

export const InfoText = styled.p`
    font-size: 12px;
    line-height: 16px;
    color: #828bb9;
    margin-top: 5px;
`

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) => (props.jContent ? props.jContent : 'unset')};
    align-items: ${(props) => (props.aItems ? props.aItems : 'unset')};
    margin-top: ${(props) => (props.mt? props.mt : '0px')};
`
export const ErrorMessage = styled.div`
    font-size: 10px;
    color: red;
    margin-top: 5px;
    max-width: ${(props) => props.width || 400};
`