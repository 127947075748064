import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from '../Admin/shared/Card'
import TableRecruiter from '../Admin/shared/Table'
import Select from 'react-select'
import Paginator from '../../common/Paginator/Paginator'
import { CSVLink } from "react-csv"
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import P from '../Admin/shared/P'
import './styles/RecruiterManagment.scss'
import feather from 'feather-icons'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import ResumeIcon from '../../../../assets/images/icons/resume-icon'
import Modal from 'react-bootstrap/Modal'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Row,Col,Spinner } from 'react-bootstrap'
import SearchBar from '../../common/SearchBar/SearchBar'
import { Input } from '@material-ui/icons'
import styles from './styles/JobsStatusPage.module.scss'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'


const AutomationEmailDashboard = (prop) => {
    var query = window.sessionStorage.getItem('admin_automation_mail_query')
    const queryParams = new URLSearchParams(window.location.search)
    const urlStartDate = sessionStorage.getItem("admin_automation_mail_start_date")
    const urlEndDate = sessionStorage.getItem("admin_automation_mail_end_date")
    const jobStatus_job = sessionStorage.getItem("admin_automation_mail_job")
    const jobId = JSON.parse(jobStatus_job)?.value
    const [activePage, setActivePage] = useState(1)
    const [exportDatas, setExportDatas] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [incomingMails, setIncomingMails] = useState([])
    const [open,setOpen] = useState(false)
    const [excelExport,setExcelExport] = useState(false)
    const [jobs,setJobs] = useState([{value: 'all', label: 'All'},...prop.jobs_list])
    const [selectedJob,setSelectedJob] = useState(jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job).value : jobId ? jobId : '')
    const [rsumeText,setRsumeText] = useState('')
    const [perPage, setPerPage] = useState(prop.perPage)
    const [totalMails,setTotalMails] = useState()
    const [currentCounts,setCurrentCounts] = useState()
    const [loading, setLoading] = useState(true)
    const [startRecord, setStartRecord] = useState(0)
    const [submissionId, setSubmissionId] = useState('')
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const [selected,setSelected] = useState((jobId)? prop.jobs_list.filter((option) => option.value == jobId) : jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job) : {value: 'all', label: 'All'})
    const csvExportRef = React.useRef()
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
    const handleDate = (date) => {
        setDateRange(date)
    }
    const [tableHeaders, setTableHeaders ] = useState([
        { label: 'Name', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Phone Number', key: 'phone_number' },
      ])
    const [tableHeadersFinal, setTableHeadersFinal] = useState([])
    const [checkedList, setCheckedList] = useState(tableHeaders.map(data => data.key));
    const fetchData = () => {
        setLoading(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("admin_automation_mail_start_date", start_date)
        sessionStorage.setItem("admin_automation_mail_end_date", end_date)
        const url = ` /admin/automation_email_dashboard.json?page=${activePage}&start_date=${start_date}&end_date=${end_date}&search=${searchTerm}&job=${selectedJob}&sortField=${sortField}&sortDirection=${sortDirection}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (response) => {
                setTotalCount(response.data.meta.total_mails)
                setPerPage(response.data.per_page)
                let sr_no = (activePage - 1) * response.data.per_page
                setIncomingMails(
                    response.data.mails.map((mailData,key) => (sr_no = sr_no + 1,{
                        id: sr_no,
                        name: mailData.first_name ? mailData.first_name +' '+ mailData.last_name : '-',
                        email: response.data?.person_list[mailData.person_id]?.email_address,
                        to_email: mailData.to_email,
                        date:  (mailData.created_at != null) ?  moment(mailData.created_at).format('MMM DD, YYYY') : '',
                        subject: mailData.subject,
                        attachment_url: response.data?.person_list[mailData.person_id]?.attachment_url,
                        phone_number: response.data?.person_list[mailData.person_id]?.phone_number,
                        job_name: response.data?.job_list[mailData.job_id]?.name,
                        action: resumePreview(response.data?.person_list[mailData.person_id],mailData),
                    }))
                )
                setPageCount(response.data.meta.total_pages)
                setCurrentCounts(response.data.meta.current_count)
                setTotalMails(response.data.meta.total_mails)
                setStartRecord((activePage - 1) * response.data.per_page) 
                setLoading(false)
                setPageCount(response.data.total_pages)
            },
        })
    }

    const exportDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = ` /admin/automation_email_dashboard.json?start_date=${start_date}&end_date=${end_date}&search=${searchTerm}`
        const response = await makeRequest(
          `${url}`,
          'get',
          {}
        )
        setExportDatas(response?.data?.mails.map((mailData,key)=> ({
          id: key + 1,
          name: mailData.candidate_name ? mailData.candidate_name : '-',
          email: response.data?.person_list[mailData.person_id].email_address,
          phone_number: response.data?.person_list[mailData.person_id].phone_number
        })))
        csvExportRef?.current?.link.click();
      }


    useEffect(() => {
        fetchData()
        feather.replace()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,dateRange,sortDirection,sortField,selectedJob])

    const resumeModalCall = (resume) =>{
        setOpen(true)
        setRsumeText(resume)
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('admin_automation_mail_query',value)
    }

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'30px',marginRight: '10px',height: '42px' })
    };

    const resumePreview = (o,email_data) =>{
        return (<div >
                   <img src={ResumeIcon} onClick={(e)=>resumeModalCall(email_data.body)} className='align-items-center' style={{cursor:'pointer'}} title='Email Body'/>
                   {(o.attachment_url != null && o.attachment_url != 'N/A' && o.attachment_url.trim() != '') ?
                   <a href={(o.attachment_url != null && o.attachment_url != 'N/A' && o.attachment_url.trim() != '') ? o.attachment_url : '#'} target="_blank" className='ml-3' title='Resume'>
                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                        <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                        <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg>
                    </a>
                    : <></>}
                </div>)
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '5%',
            minWidth: '5%'
        },
        {
            name: 'Candidate Name',
            selector: row => row.name,
            sortable: true,
            sortField: 'first_name',
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true
        },
        {
            name: '	Email',
            selector: row => row.email,
            sortable: true,
            sortField: 'email',
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true
        },
        {
            name: 'Job Name',
            selector: row => row.job_name,
            sortable: false,
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            sortField: 'created_at',
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true
        }
    ]

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }
   
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{ width: '90%'}}>
                    Automation Email Report
                </P>
                <div>
                    {/* <button className={`ml-3 ${styles.exportCSVButton}`} onClick={ ()=>setExcelExport(true)}>
                        Export CSV
                    </button> */}
                </div>
                <CSVLink
                    className='hidden'
                    data={exportDatas} 
                    headers={tableHeadersFinal}
                    filename={"IncomingMailAnalytic.csv"}
                    ref={csvExportRef}
                >
                </CSVLink>
            </div>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '15px' }}
            >
                <Col xs={12} sm={12} lg={4} style={{float:'left'}}>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col xs={12} sm={12} lg={8}  className='d-flex justify-content-end p-0 filter_ui_fix'>
                    <div>
                        <label>
                            Job Name:
                        </label>
                        <Select
                            defaultValue={selected}
                            options={jobs}
                            onChange={(event)=>{
                                setSelectedJob(event.value)
                                sessionStorage.setItem("admin_automation_mail_job", JSON.stringify(event))
                            }}
                            name="job_name"
                            styles={colourStyles}
                        />
                    </div>
                    <div>
                        <label>
                            Select Date Range:
                        </label><br/>
                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                    <div style={{marginTop: '3.3%'}}>
                        <SearchBar
                            placeholder="Search Candidate Name"
                            value={searchTerm}
                            setValue={setSearchTerm}
                            onChange={(e) => onChangeSearchTerm(e.target.value)}
                            onEnterPressed={() => {
                                activePage === 1 ? fetchData() : setActivePage(1)
                                !searchTerm && window.sessionStorage.setItem('recruiter_incoming_mail_query','')
                            }}
                            onClick={(e) => setSearchTerm('')}
                            style={{height:'40px'}}
                        />
                    </div>
                </Col>
            </div>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={incomingMails}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                responsive={true}
                onChangePage={page => setActivePage(page)}
            />

            <Modal
                show={open}
                onHide={()=>setOpen(!open)}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>Email Body</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{borderTop: '1px solid #dee2e6'}}>
                    
                <div dangerouslySetInnerHTML={{ __html: rsumeText }} /> 
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setOpen(!open)}>
                        Close
                    </Button>
                </Modal.Footer> 
            </Modal>


        </Card>
    )

}

export default AutomationEmailDashboard