import React, { useEffect, useState,PureComponent } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import { Box } from '../../common/Styled components/styled'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import Paginator from '../../common/Paginator/Paginator'
import { CSVLink } from "react-csv"
import { DisplayPagination } from './styles/AdminDashboard.styled'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import ToggleSwitch from '../../common/ToggleSwitch/ToggleSwitch'
import Text from '../../common/Styled components/Text'
import './styles/OrganizationAnalyticsClientDashboardPage.scss'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const OrganizationAnalyticsClientDashboardPage = (prop) => {
  console.log(prop)
  const queryParams = new URLSearchParams(window.location.search)
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(prop.per_page)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [organizationList, setOrganizationList] = useState([])
  const [totalValues,setTotalValues] = useState({})
  const [defaultselected,setdefaultselected] = useState({value: 'all', label: 'All'})
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [chartData,setChartData] = useState([])
  const [isWeekly, setIsWeekly] = useState(true)
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [footer, setFooter] = useState({})

  const tableHeaders = [
    { label: 'Organization Name',key: 'organization_name'},
    { label: 'Start Date (DaySince)',key: 'start_date'},
    { label: 'Account Manager',key: 'am'},
    { label: 'Recruiter',key: 'recruiter'},
    { label:  (prop.type == 'hourly') ? 'Hours':'Text',key: (prop.type == 'hourly') ? 'hours':'sms_text_count'},
    { label: 'Email',key: 'email'},
    { label: 'InMails',key: 'inMail',},
    { label: 'Last Hire (#Week since hire)',key: 'last_hire',},
    { label: 'Last Week Submission',key: 'week_submission'},
    { label: 'Last Week Awaiting Feedback',key: 'feedback',},
    { label: 'Actively Interview',key: 'actively_interview',},
    { label: 'Upcoming 1st Interview',key: 'firstInterview'},
    { label: 'Upcoming 2nd Interview',key: 'secondInterview'},
    { label: 'Active Offers',key: 'offers'},
    { label: 'Hired',key: 'hired'},
    { label: 'Declined Offer',key: 'archived'},
  ]
  const columns = [
    { name: 'No.',
      selector: row => row.id,
      minWidth: '4%',
      maxWidth: '4%',
    },
    { name: 'Organization Name',
      selector: row => row.organization_name,
      cell: (row) => <div title={row.oName}>{row.organization_name}</div>,
      wrap:true,
      minWidth: '9%',
      maxWidth: '9%',
      sortable: true,
      sortField: 'organizations.name',
    },
    { name: 'Start Date (DaySince)',
      selector: row => row.start_date,
      cell: (row) => <div title={row.start_date}>{row.start_date}</div>,
      wrap:true,
      minWidth: '6%',
      maxWidth: '6%',
      sortable: true,
      sortField: 'organizations.created_at',
    },
    { name: 'Account Manager',
      selector: row => row.am,
      cell: (row) => <div title={row.am}>{row.am}</div>,
      wrap:true,
      minWidth: '8%',
      maxWidth: '8%',
      sortable: true,
      sortField: 'account_manager',
    },
    { name: 'Recruiter',
      selector: row => row.recruiter,
      wrap:true,
      minWidth: '8%',
      maxWidth: '8%',
    },
    { name: (prop.type == 'hourly') ? 'Hours':'Text',
      selector: (prop.type == 'hourly') ? 'hours':'sms_text_count',
      minWidth: '5%',
      maxWidth: '5%',
    },
    { name: 'Email',
      selector: row => row.email,
      minWidth: '4%',
      maxWidth: '4%',
      sortable: true,
      sortField: 'email_count',
    },
    { name: 'InMails',
      selector: row => row.inMail,
      minWidth: '5%',
      maxWidth: '5%',
      sortable: true,
      sortField: 'inmails_count',
    },
    { name: 'Last Hire (#Week since hire)',
      selector: row => row.last_hire,
      cell: (row) => <div title={row.last_hire}>{row.last_hire}</div>,
      minWidth: '6%',
      maxWidth: '6%',
      wrap:true,
      sortable: true,
      sortField: 'last_hire_date',
    },
    { name: 'Last Week Submission',
      selector: row => row.week_submission,
      minWidth: '5%',
      maxWidth: '5%',
      sortable: true,
      sortField: 'submissions_count',
    },
    { name: 'Last Week Awaiting Feedback',
      selector: row => row.feedback,
      minWidth: '6%',
      maxWidth: '6%',
      sortable: true,
      sortField: 'awaiting_feedback_count',
    },
    { name: 'Actively Interview',
      selector: row => row.actively_interview,
      minWidth: '5%',
      maxWidth: '5%',
      sortable: true,
      sortField: 'actively_interview_count',
    },
    { name: 'Upcoming 1st Interview',
      selector: row => row.firstInterview,
      minWidth: '6%',
      maxWidth: '6%',
      sortable: true,
      sortField: 'upcoming_first_interview',
    },
    { name: 'Upcoming 2nd Interview',
      selector: row => row.secondInterview,
      minWidth: '6%',
      maxWidth: '6%',
      sortable: true,
      sortField: 'upcoming_second_interview',
    },
    { name: 'Active Offers',
      selector: row => row.offers,
      minWidth: '5%',
      maxWidth: '5%',
      sortable: true,
      sortField: 'active_offer',
    },
    { name: 'Hired',
      selector: row => row.hired,
      minWidth: '5%',
      maxWidth: '5%',
      sortable: true,
      sortField: 'hired_count',
    },
    { name: 'Declined Offer',
      selector: row => row.archived,
      minWidth: '6%',
      maxWidth: '6%',
      sortable: true,
      sortField: 'declined_count',
    },
    { name: 'Last Updated On',
      selector: row => row.updated_at,
      cell: (row) => <div title={row.updated_at}>{row.updated_at}</div>,
      minWidth: '10%',
      maxWidth: '10%',
      sortField: 'updated_at',
  }
  ]

  const fetchData = async () => {
    setLoading(true)
    const url = `/admin/client_dashboard/${prop.type}.json`
    const response = await makeRequest(
      `${url}?page=${activePage}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setTotalCount(response.data.total_count)
    let sr_no = (activePage - 1) * response.data.per_page
    setOrganizationList(response?.data?.org_data.map((data,key)=> (sr_no = sr_no + 1,{
      id: sr_no,
      organization_name: <span className={data.week_since_hire >= 5 ? 'danger-row': (data.week_since_hire >=  3 ) ? 'warning-row' :''}>{ capitalize(data.organization.name) }</span> ,
      oName: capitalize(data.organization.name),
      start_date: data?.organization && moment(data.organization.created_at).format('MMM DD, YYYY'),
      am:  data?.account_manager && capitalize(data.account_manager),
      // recruiter: data?.recruiters_list && data.recruiters_list,
      recruiter: <span title={data?.recruiters_list && data.recruiters_list}>{data?.recruiters_list && (data?.recruiters_list.split(',').length > 3 ) ? ((data.recruiters_list.split(',').slice(0,2).join())+' ...') : data.recruiters_list }</span>,
      hours: (prop.type == 'hourly') ? '-':data.sms_text_count,
      email: (data.email_count > 0) ? data.email_count : '',
      inMail: (data.inmails_count > 0) ? data.inmails_count : '',
      last_hire:  data.week_since_hire > 0 ? moment(data?.last_hire_date).format('MMM DD, YYYY') + ' (' + data.week_since_hire + ')' :  (data.last_hire_date != "") ? moment(data?.last_hire_date).format('MMM DD, YYYY') : '',
      week_submission: (data.submissions_count > 0) ? data.submissions_count : '',
      feedback: (data.awaiting_feedback_count > 0) ? data.awaiting_feedback_count : '',
      actively_interview: (data.actively_interview_count > 0) ? data.actively_interview_count : '',
      firstInterview: (data.upcoming_first_interview > 0) ? data.upcoming_first_interview : '',
      secondInterview: (data.upcoming_second_interview > 0) ? data.upcoming_second_interview : '',
      offers: (data.active_offer > 0) ? data.active_offer : '',
      hired: (data.hired_count > 0) ? data.hired_count : '',
      archived: (data.declined_count > 0) ? data.declined_count : '',
      updated_at: moment(data?.updated_at).format('MMM DD, YYYY hh:mm:ss'),
      rowClassName:  data.week_since_hire >= 5 ? 'danger-row': (data.week_since_hire >=  3 ) ? 'warning-row' :''
    })))
    setFooter({
      id:'Total',
      actively_interview:response.data.totalValues?.actively_interview,
      archived:response.data.totalValues?.archived,
      email:response.data.totalValues?.email,
      feedback:response.data.totalValues?.feedback,
      firstInterview:response.data.totalValues?.firstInterview,
      hired:response.data.totalValues?.hired,
      inMail:response.data.totalValues?.inMail,
      last_hire:response.data.totalValues?.last_hire,
      offers:response.data.totalValues?.offers,
      secondInterview:response.data.totalValues?.secondInterview,
      week_submission:response.data.totalValues?.week_submission
    })
    setPageCount(response.data.total_pages)
    setStartRecord((activePage - 1) * response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setTotalValues(response.data.totalValues)
    setLoading(false)
  }

  const exportDataMethod = async () => {
    const url = `/admin/client_dashboard/${prop.type}.json?sortField=${sortField}&sortDirection=${sortDirection}`
    const response = await makeRequest(
      `${url}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.org_data.map((data,key)=> ({
      id: key,
      organization_name: data?.organization && capitalize(data.organization.name),
      start_date: data?.organization && moment(data.organization.created_at).format('MMM DD, YYYY'),
      am:  data?.account_manager && capitalize(data.account_manager),
      recruiter: data?.recruiters_list && data.recruiters_list,
      hours:(prop.type == 'hourly') ? '-':data.sms_text_count,
      email: data.email_count,
      inMail: data.inmails_count,
      last_hire: data.week_since_hire > 0 ? moment(data?.last_hire_date).format('MMM DD, YYYY') + ' (' + data.week_since_hire + ')' :  (data.last_hire_date != "") ? moment(data?.last_hire_date).format('MMM DD, YYYY') : '',
      week_submission: data.submissions_count,
      feedback: data.awaiting_feedback_count,
      actively_interview: data.actively_interview_count,
      firstInterview: data.upcoming_first_interview,
      secondInterview: data.upcoming_second_interview,
      offers: data.active_offer,
      hired: data.hired_count,
      archived: data.declined_count,
    })))
    csvExportRef?.current?.link.click();
  }

  useEffect(()=>{
    fetchData()
  },[activePage,sortDirection,sortField])

  // useEffect(() => {
  //   getChartData()
  // },[isWeekly])

  const setMembers = (members) => {
    let list = members.map(function(org,key){
      return <span key={key}>{(key ==0) ?'':', '} {capitalize(org.first_name)} {capitalize(org.last_name)}</span> 
    })
    return list
  }

  const getChartData = async () => {
    let url = `/admin/analytics_dashboard_chart_data?type=monthly&organization_type=${prop.type}`
    if(isWeekly){
      url = `/admin/analytics_dashboard_chart_data?type=weekly&organization_type=${prop.type}`
    }
    const response = await makeRequest(
      `${url}`,
      'get',
      {}
    )
    setChartData(response.data.chart_data)
  }
  
  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }    
  }

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            { prop.type == 'hourly' ? 'Hourly Organization Dashboard' :'Contingency Organization Dashboard'}
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"client_dashboard.csv"}
        ref={csvExportRef}
      >
      </CSVLink>

      {/* <Row className='mt-4 mb-4'>
        <Col xs={7} sm={7} lg={7} >
          <Box style={{float:'right'}} className="mb-2 mr-5">
              <Text varient="h6" color="primary" size="18px" givePadding>
                  Weekly
                </Text>
                <ToggleSwitch
                  id="client"
                  state={isWeekly}
                  setState={setIsWeekly}
                  size="medium"
                />
                <Text varient="h6" color="primary" size="18px" givePadding>
                  Monthly
                </Text>
            </Box>
        </Col>
        <Col xs={12} sm={12} lg={12}>
              <div style={{height:"300px",width:"1420px"}}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Total Organizations" stroke="#4c68ff" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="Total Submissions" stroke="#FF7E17" />
                    <Line type="monotone" dataKey="Total In Interview" stroke="#785E13" />
                    <Line type="monotone" dataKey="Total Awaiting Feedback" stroke="#6CA579" />
                    <Line type="monotone" dataKey="Total Hired" stroke="#FF00FF" />
                    <Line type="monotone" dataKey="Total Offer" stroke="#008000" />
                    <Line type="monotone" dataKey="Total Declined Offer" stroke="#6610f2" />
                  </LineChart>
                </ResponsiveContainer>
                </div>
        </Col>
      </Row> */}
      
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
              {totalCount > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}</DisplayPagination>
              }
        </Col>
      </Row>
      <DataTable
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={organizationList}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        onSort={handleSort}
        sortServer
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalCount}
        paginationPerPage={perPage}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        responsive={true}
        onChangePage={page => setActivePage(page)}
        footer={footer}
       />
    </Card>
  )
}

export default OrganizationAnalyticsClientDashboardPage