import React, { useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import './styles/TalentPage.scss'
import paulImage from '../../../../assets/v2/imgs/paulnew.png'
import team1 from '../../../../assets/v2/imgs/team/Lawrence_Wang.jpeg'
import dori from '../../../../assets/v2/imgs/team/Dori_Rodriguez.png'
import Roopa from '../../../../assets/v2/imgs/team/roopa.jpeg'
import Ghada from '../../../../assets/v2/imgs/team/GhadaMorad.jpeg'
import Ryan from '../../../../assets/v2/imgs/team/RyanAskew.jpeg'
import Richard from '../../../../assets/v2/imgs/team/richard.jpg'
import Jonathan from '../../../../assets/v2/imgs/team/Jonathan.jpg'
import StevenDiodonet from '../../../../assets/v2/imgs/team/StevenDiodonet.jpeg'
import ucla from '../../../../assets/v2/imgs/company/ucla.png'
import stanford from '../../../../assets/v2/imgs/company/stanford.png'
import Berkeley from '../../../../assets/v2/imgs/company/Berkeley.png'
import Columbia from '../../../../assets/v2/imgs/company/Columbia.jpg'
import '../../../../assets/v2/css/main.css'
import '../../pages/Interview/styles/AiInterview.scss'

const TalentHomeNew = () => {
    return (
        <>
            <section class="section-box mt-90 mt-md-0">
                <div class="ai-containter">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                            <span class="text-lg text-brand wow animate__animated animate__fadeInUp">Talent</span>
                            <h3 class="mt-20 mb-30 wow animate__animated animate__fadeInUp">Land your Dream Job or Grow your Agency with AI.</h3>
                            <p class="mb-20 wow animate__animated animate__fadeInUp">Our AI finds and applies to jobs for you and reaches out to hiring
                                managers for you.</p>
                            <p class="mb-30 wow animate__animated animate__fadeInUp">Our coaches are successful entrepreneurs, industry experts, and
                                world-class recruiters.</p>
                            <div class="mt-10 wow animate__animated animate__fadeInUp">
                                <div class="mt-60">
                                    <div class="box-button-shadow mr-10">
                                        <a href="https://app.cardinaltalent.ai/" class="btn btn-default"> Get Started For Free</a>
                                    </div>
                                    <a href="https://app.cardinaltalent.ai/join" class="btn btn-success ml-20">Book A Call</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12 col-12 pl-200 pr-200 d-none d-lg-block">
                            <div class="banner-imgs banner-imgs-about">
                                <img alt="" src={paulImage} class="img-responsive paul-image" height={400} width={400}/>
                                <div class="mt-10">
                                    <h5 className='about'><b>ABOUT OUR FOUNDER</b></h5>
                                    <div class="text bord-thin-top">
                                        <p>Paul Christopher Campbell is the CEO of CardinalTalent.ai and Cardinal Executive Search, LLC.
                                            He has built a successful consulting agency to over a $10,000,000 per year revenue run rate.
                                            He has 15 years of experience in consulting in sales, marketing, and recruiting in the
                                            technology industry and has a MS in Engineering from Stanford and a BS in Civil Engineering
                                            from UC Berkeley.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section-box mt-md-50">
                <div class="ai-containter">
                    <h2 class="section-title text-center mb-15 wow animate__animated animate__fadeInUp">We have extraordinary coaches.</h2>
                    <h5 class="text-center">
                        Here are several:
                    </h5>
                    <div class="row mt-60">
                        <div class="col-lg-3 col-md-6">
                            <div class="card-grid-2  wow animate__animated animate__fadeIn">
                                <div class="text-center card-grid-2-image">
                                    <a href="#">
                                        <figure><img src={team1} alt="Lawrence Wang" /></figure>
                                    </a>
                                </div>
                                <div class="card-block-info pt-10 text-center">
                                    <figure><img src={ucla} alt="Lawrence Wang" /></figure>
                                    <h5 class="font-bold text-lg mb-5">Lawrence Wang</h5>
                                    <p class="text-small text-muted">Senior Recruiter</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="card-grid-2  wow animate__animated animate__fadeIn">
                                <div class="text-center card-grid-2-image">
                                    <a href="#">
                                        <figure><img src={dori} alt="Dori Rodriguez" /></figure>
                                    </a>
                                </div>
                                <div class="card-block-info pt-10 text-center">
                                    <figure><img src={stanford} alt="Dori Rodriguez" /></figure>
                                    <h5 class="font-bold text-lg mb-5">Dori Rodriguez</h5>
                                    <p class="text-small text-muted">Head of Talent</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="card-grid-2  wow animate__animated animate__fadeIn">
                                <div class="text-center card-grid-2-image">
                                    <a href="#">
                                        <figure><img src={Roopa} alt="Roopa Som" /></figure>
                                    </a>
                                </div>
                                <div class="card-block-info pt-10 text-center">
                                    <figure><img src={stanford} alt="Roopa Som" /></figure>
                                    <h5 class="font-bold text-lg mb-5">Roopa Som</h5>
                                    <p class="text-small text-muted">Director of Recruiting</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="card-grid-2  wow animate__animated animate__fadeIn">
                                <div class="text-center card-grid-2-image">
                                    <a href="#">
                                        <figure><img src={Ghada} alt="Ghada Morad" /></figure>
                                    </a>
                                </div>
                                <div class="card-block-info pt-10 text-center">
                                    <figure><img src={ucla} alt="Ghada Morad" /></figure>
                                    <h5 class="font-bold text-lg mb-5">Ghada Morad</h5>
                                    <p class="text-small text-muted">Lead Recruiter</p>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-3 col-md-6">
                            <div class="card-grid-2  wow animate__animated animate__fadeIn">
                                <div class="text-center card-grid-2-image">
                                    <a href="#">
                                        <figure><img src={Ryan} alt="Ryan Askew" /></figure>
                                    </a>
                                </div>
                                <div class="card-block-info pt-10 text-center">
                                    <figure><img src={Berkeley} alt="Ryan Askew" /></figure>
                                    <h5 class="font-bold text-lg mb-5">Ryan Askew</h5>
                                    <p class="text-small text-muted">Senior Recruiter</p>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-3 col-md-6">
                            <div class="card-grid-2  wow animate__animated animate__fadeIn">
                                <div class="text-center card-grid-2-image">
                                    <a href="#">
                                        <figure><img src={Richard} alt="Richard Idriss" /></figure>
                                    </a>
                                </div>
                                <div class="card-block-info pt-10 text-center">
                                    <figure><img src={Berkeley} alt="Richard Idriss" /></figure>
                                    <h5 class="font-bold text-lg mb-5">Richard Idriss</h5>
                                    <p class="text-small text-muted">Senior Recruiter</p>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-3 col-md-6">
                            <div class="card-grid-2  wow animate__animated animate__fadeIn">
                                <div class="text-center card-grid-2-image">
                                    <a href="#">
                                        <figure><img src={Jonathan} alt="Jonathan" /></figure>
                                    </a>
                                </div>
                                <div class="card-block-info pt-10 text-center">
                                    <figure><img src={Columbia} alt="Jonathan" /></figure>
                                    <h5 class="font-bold text-lg mb-5">Jonathan</h5>
                                    <p class="text-small text-muted">Head of Talent</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="card-grid-2  wow animate__animated animate__fadeIn">
                                <div class="text-center card-grid-2-image">
                                    <a href="#">
                                        <figure><img src={StevenDiodonet} alt="Steven Diodonet" /></figure>
                                    </a>
                                </div>
                                <div class="card-block-info pt-10 text-center">
                                    <figure><img src={Columbia} alt="Steven Diodonet" /></figure>
                                    <h5 class="font-bold text-lg mb-5">Steven Diodonet</h5>
                                    <p class="text-small text-muted">Senior Recruiter</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TalentHomeNew
