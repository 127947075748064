import React, { useState, useEffect } from 'react'
import { Formik, Form, Field, useField } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Col, Dropdown, Spinner } from 'react-bootstrap'
import { StyledForm, Container, Button, Row } from './styles/CandidateForm.styled'
import { normalizeInput } from '../../../utils'
import { makeRequest } from '../RequestAssist/RequestAssist'
import { ErrorMessage } from './styles/CandidateForm.styled'
import styled from 'styled-components'
import FileButton from '../../common/FileButton/FileButtonV2'
import PhoneInputV2 from '../../common/PhoneInput/PhoneInputV2'

const Label = styled.label`
    color: #3a5182;
    cursor: pointer;
`
function JoinCoachingCandidateDetails({
    setCandidateModal = () => { },calendly_link
}) {
    const [resume, setResume] = useState()
    const [resumeError, setResumeError] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [loading, setLoading] = useState(false)
    const TextField = ({ label, ...props }) => {
        const [field, meta] = useField(props);
        return (
            <>
                <Label htmlFor={props.id || props.name}>
                    {label}
                </Label>
                <div className='d-flex align-items-center'>
                    <input {...field} {...props} />
                    <span htmlFor={props.id || props.name} className='ml-10'>{props.span}</span>
                </div>
                <ErrorMessage width={props.width}>
                    {meta.touched && meta.error ? meta.error : ''}
                </ErrorMessage>
            </>
        );
    };

    const candidateValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        email: Yup.string()
            .email()
            .required('Email is required')
            .test(
                'email-unique',
                'This email is already in use',
                async function (value, context) {
                   // Check email uniqueness only if the email field is changed
                    if (value != undefined) {
                        const res = await axios.get(`/users/exists?email=${encodeURIComponent(value)}`);
                        return !res.data.user_exists;
                    }
                    return true;
                }
            ),
        linkedinProfile: Yup.string().test(
            'Invalid url, please add Linkedin url only',
            function (value) {
                if (value != '' && value != undefined && value != 'undefined') {
                    try {
                        let hostname = new URL(value).hostname
                        return (
                            hostname === 'linkedin.com' ||
                            hostname === 'www.linkedin.com'
                        )
                    } catch (error) {
                        return false
                    }
                } else {
                    return true
                }
            }
        ),
        i_accept: Yup.boolean().oneOf([true], 'Please tick this box if you want to proceed'),
        i_acknowledge: Yup.boolean().oneOf([true], 'Please tick this box if you want to proceed'),
        i_realize: Yup.boolean().oneOf([true], 'Please tick this box if you want to proceed'),

    })

    const handleSave = async (candidate) => {
        const payload = new FormData()
        // const url = `/job/apply/${job.id}`
        const url = `/join`
        payload.append('candidate[first_name]', candidate.firstName)
        payload.append('candidate[last_name]', candidate.lastName)
        payload.append('candidate[email_address]', candidate.email)
        payload.append('candidate[linkedin_profile_url]', candidate?.linkedinProfile || '')
        payload.append('candidate[phone_number]', phoneNumber)
        payload.append('candidate[work_authorization_status]', candidate?.work_authorization_status || '')
        payload.append('candidate[salary]', candidate?.salary || '')
        payload.append('candidate[source]', 'join_coaching')
        if (resume) {
            payload.append('candidate[resume]', resume)
        }
        try {
            setLoading(true)
            const responce = await makeRequest(url, 'post', payload, {
                contentType: 'application/json',
                loadingMessage: 'Applying...',
                createResponseMessage: (response) => {
                    return {
                        message: response.message,
                        messageType: response.type,
                        loading: false,
                        autoClose: true,
                    }
                },
            }).then((res) => {
                if(res.data.type == 'success'){
                    window.location.href = calendly_link;
                }else{
                    setLoading(false)
                }
            });
        } catch (error) {
            setLoading(false)
        }
        // setCandidateModal(false)
    }
    const handleChange = ({ target: { value } }) => {
        setPhoneNumber(normalizeInput(value, ""));
    };
    return (
        <Container>
            <Formik
                initialValues={{
                    email: '',
                    firstName: '',
                    lastName: '',
                    linkedinProfile: '',
                    resume: resume,
                    phoneNumber: '',
                    authorization: '',
                    salary: '',
                    i_accept:false,
                    i_acknowledge:false,
                    i_realize:false
                }}
                validationSchema={candidateValidation}
                validate={(values) => {
                    const errors = {}
                    setResumeError('')
                    if (
                        !resume &&
                        (values.linkedinProfile == '' ||
                            values.linkedinProfile == 'undefined' ||
                            values.linkedinProfile == undefined)
                    ) {
                        errors.resume = 'Resume is required'
                        setResumeError(errors.resume)
                    }
                    return errors
                }}
                onSubmit={(values, { setSubmitting }) => {
                    if (
                        !resume &&
                        values.linkedinProfile == '' &&
                        values.linkedinProfile == 'undefined' &&
                        values.linkedinProfile == undefined
                    ) {
                        setResumeError('Resume is required')
                        return
                    }
                    setTimeout(() => {
                        setSubmitting(false)
                    }, 500)

                    handleSave(values)
                }}
            >
                <StyledForm>
                    <div className='mb-20'>
                        <h5><b>Want to know more about how we can help you?</b></h5>
                        <h6>Schedule a no-obligation call with one of our recruitment partners</h6>
                    </div>
                    <div className='row'>

                        <Col lg='6' md='6' sm='12' xs='12'>
                            <TextField
                                label="First Name*"
                                name="firstName"
                                type="text"
                                id="firstName"
                                width="100%"
                                placeholder="First Name*"
                            />
                        </Col>
                        <Col lg='6' md='6' sm='12' xs='12'>
                            <TextField
                                label="Last Name*"
                                name="lastName"
                                type="text"
                                id="lastName"
                                placeholder="Last Name*"
                            />
                        </Col>

                        <Col lg='6' md='6' sm='12' xs='12'>
                            <TextField
                                label="Email Address*"
                                name="email"
                                type="text"
                                id="email"
                                placeholder="Email Address*"
                            />
                        </Col>
                        <Col lg='6' md='6' sm='12' xs='12'>
                            <Label>
                                Phone*
                            </Label>
                            <PhoneInputV2
                                label="Phone*"
                                name="phoneNumber"
                                type="text"
                                id="phoneNumber"
                                placeholder="(xxx) xxx-xxxx"
                                onChange={handleChange}
                                value={phoneNumber}
                                isRequired
                            />
                        </Col>

                        <Col lg='12' md='12' sm='12' xs='12'>
                            <TextField
                                label="Linkedin Profile (https://www.linkedin.com/)"
                                name="linkedinProfile"
                                type="text"
                                id="linkedinProfile"
                                placeholder="https://www.linkedin.com"
                            />
                        </Col>


                        <Col lg='6' md='6' sm='12' xs='12'>
                            <TextField
                                label="What is your work authorization status in the US?"
                                name="work_authorization_status"
                                type="text"
                                id="work_authorization_status"
                                placeholder="US Citizen, Green Card, H1B, Not Authorized, etc."
                            />
                        </Col>
                        <Col lg='6' md='6' sm='12' xs='12'>
                            <TextField
                                label="Yearly minumum expected base salary (USD)"
                                name="salary"
                                type="text"
                                id="salary"
                                placeholder="$0"
                            />
                        </Col>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <Label>
                                Select your resume from your device*
                            </Label>
                            <FileButton
                                label="Select Resume"
                                type="button"
                                file={resume}
                                resumeError={
                                    resumeError
                                }
                                setResumeError={
                                    setResumeError
                                }
                                getFile={(file) =>
                                    setResume(file)
                                }
                            />
                        </Col>
                        <Col lg='12' md='12' sm='12' xs='12' className='mt-20'>
                            <TextField
                                name="i_accept"
                                type="checkbox"
                                id="i_accept"
                                style={{ width: 'auto', height: 'auto' }}
                                span='I hereby accept the CardinalTalent.ai Terms of Service.'
                            />
                        </Col>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <TextField
                                name="i_acknowledge"
                                type="checkbox"
                                id="i_acknowledge"
                                style={{ width: 'auto', height: 'auto' }}
                                span='I acknowledge that the CardinalTalent.ai program is a paid program'
                            />
                        </Col>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <TextField
                                name="i_realize"
                                type="checkbox"
                                id="i_realize"
                                style={{ width: 'auto', height: 'auto' }}
                                span='I realize that this is NOT an interview for a job, but a call to learn more about the program.'
                            />
                        </Col>

                    </div>
                    <button className='btn btn-default p-20' type="submit" disabled={loading}>
                        {loading ? (
                            <Spinner animation="border" variant="light" />
                        ) : (
                            'Book A Time'
                        )}
                    </button>
                </StyledForm>
            </Formik>
        </Container>
    )
}

export default JoinCoachingCandidateDetails
