import React, { useEffect } from 'react'
import styles from './styles/JobSearchBar.module.scss'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormControl from 'react-bootstrap/FormControl'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import SearchIcon from '../../../../assets/images/talent_page_assets/search-icon-new.png'
import uploadIcon from '../../../../assets/images/icons/upload-icon-silver.svg'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function JobSearchBar({
    placeholder,
    value,
    onChange,
    onEnterPressed,
    onButtonClick,
    uploadResumeFlage = false,
    setShow
}) {
    return (
        <Row className={`${styles.candidateSearchBar} ${styles.searchBarRow}`}>
            <Col lg='12' md='12' style={{display: 'flex'}}>
                <FormControl
                    id="inlineFormInputGroup"
                    placeholder={placeholder}
                    style={{ textIndent: '40px' }}
                    className={styles.placeholderText}
                    value={value}
                    onChange={onChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            onEnterPressed(e)
                        }
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        left: '30px',
                        top: '10px',
                        zIndex: '2',
                        height:'20px',
                        width:'20px',
                    }}
                >
                <Image src={SearchIcon} fluid />
                </div>
                
                <Button className={styles.searchButton} onClick={(e) => {
                      onEnterPressed(e)  
                    }}>
                    Search
                </Button>
                
                {uploadResumeFlage && (
                     <Button className={styles.searchButton1} onClick={(e) => {
                        setShow(true)
                        }}>
                        Upload Resume
                    </Button>
                )}
            </Col>
        </Row>
    )
}

export default JobSearchBar
