import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import moment from 'moment'
import { DisplayPagination } from './styles/AdminDashboard.styled'

const CandidateViewAnalyticPage = (props) => {
  const [candidateView, setCandidateView] = useState([])
  let status_rec = sessionStorage.getItem("status_rec")
  const [isLoading, setLoading] = useState(false)
  const [isLoadingOwner, setLoadingOwner] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [activePage, setActivePage] = useState(0)
  const [member_options, setOptions] = useState([])
  const [totalAnalyticLog, setTotalAnalyticLog] = useState([])
  const [sortField, setSortField] = useState("")
  const [sortDirection, setSortDirection] = useState("")
  const [startRecord, setStartRecord] = useState(0)
  const [totalCount, setTotalCount] = useState()
  const [totalPages, setTotalPages] = useState()
  const [currentCount, setCurrentCount] = useState()
  const [perPage, setPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedAction, setSelectedAction] = useState('weekly_recommendation')
  const [selectedRecruiter, setSelectedRecruiter]  = useState(props.recruiter ? props.recruiter[0].value  : status_rec && status_rec != "null" ? JSON.parse(status_rec).value : '')
  const [selectedRecruiterValue, setSelectedRecruiterValue]  = useState(props.recruiter ? props.recruiter[0]  : status_rec && status_rec != "null" ? JSON.parse(status_rec) : '')
  const [defaultActionselected, setdefaultActionselected] = useState({ value: 'weekly_recommendation', label: 'Weekly Recommendation' })
  const [actionLists, setActionLists] = useState([{ value: 'weekly_recommendation', label: 'Weekly Recommendation' }, { value: 'candidate_search', label: 'Candidate Search' }])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }

  const fetchData = async () => {
    setLoading(true)
    setTotalPages(0)
    const url = `/admin/candidate_view_analytic.json?page=${activePage == 0 ? 1 : activePage}&recruiter=${selectedRecruiter}&perform_action=${selectedAction}&sortField=${sortField}&sortDirection=${sortDirection}`

    makeRequest(url, 'get', '', {
        contentType: 'application/json',
        loadingMessage: 'Fetching...',
        onSuccess: (response) => {
          setTotalAnalyticLog(response.data.total_count)
            let sr_no = (activePage == 0 ? 0 : (activePage - 1)) * 25
            setCandidateView(
                response.data.candidate_view.map((candidate_view_analytic) => (sr_no = sr_no + 1,{
                    id: sr_no,
                    recruiter_name: candidate_view_analytic.recruiter_name,
                    candidate_name: candidate_view_analytic.candidate_name,
                    organization_name: candidate_view_analytic.organization_name,
                    view_count: candidate_view_analytic.view_count,
                    edit_count: candidate_view_analytic.edit_count,
                    created_at: moment(candidate_view_analytic.created_at).format('MMM DD, YYYY'),
                }))
            )

            setPerPage(response.data.per_page)
            setTotalPages(response.data.total_pages)
            setTotalCount(response.data.total_count)
            setCurrentCount(response.data.current_counts)
            setStartRecord((activePage - 1) * response.data.per_page)
            setLoading(false)

        },
    })
  }

  const columns = [
    {
        name: 'No.',
        selector: row => row.id,
        maxWidth: '7%',
        minWidth: '7%'
    },    
    {
        name: 'Recruiter Name',
        selector: row => row.recruiter_name,
        wrap:true,
        sortable: true,
        sortField: 'recruiter_name',
    },
    {
        name: 'Candidate Name',
        selector: row => row.candidate_name,
        wrap:true,
        sortable: true,
        sortField: 'candidate_name',
    },
    {
        name: 'Organization Name',
        selector: row => row.organization_name,
        wrap:true,
        sortable: true,
        sortField: 'organization_name',
    },
    {
        name: 'View Count',
        selector: row => row.view_count,
        maxWidth: '9%',
        minWidth: '9%',
        sortable: true,
        sortField: 's.view_count',
    },
    {
        name: 'Edit Count',
        selector: row => row.edit_count,
        maxWidth: '9%',
        minWidth: '9%',
        sortable: true,
        sortField: 's.edit_count',
    },
    {
        name: 'Viewed Date',
        selector: row => row.created_at,
        sortable: true,
        sortField: 's.created_at',
    }
]

  useEffect(()=>{
    fetchData()
  },[selectedRecruiter,activePage,selectedAction,sortDirection,sortField])

  const loadOptions = (inputValue, callback) => {
    setLoadingOwner(true)
    fetch(`/admin/users/fetch_users.json?search=${inputValue}&role=recruiter`)
    .then((res) => res.json())
    .then((res) => {
      let {users} = res
      setLoadingOwner(false)
      setOptions([...users.map((user) => ({ value: user.id, label: user.first_name + ' ' + user.last_name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(member_options);
  }

const handleInputChange = (str) => {
  setInputValue(str)
  return str;
}

const handleAction = (event) => {
  console.log(event)
  setSelectedAction(event.value)
}

const handleSelectOption = (selectedOptions) => {
  setActivePage(1)
  setSelectedRecruiter(selectedOptions?.value || '')
  setSelectedRecruiterValue(selectedOptions || {})
  sessionStorage.setItem("status_rec", JSON.stringify(selectedOptions))
}

const handleSort = async (column, sortDirection) => {
  console.log(column.sortField,sortDirection)
  if(column.sortField != undefined && sortDirection != undefined){
      setSortField(column.sortField)
      setSortDirection(sortDirection)
  }
}

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Candidate View Analytic
        </P>
      </div>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                    <div className='d-flex justify-content-end p-0 w-100'>
                        <div className='d-flex align-items-end p-0' style={{ marginLeft: '10px', flex: 'auto' }}>
                            {totalAnalyticLog > 0 &&
                                <DisplayPagination>Displaying  {(activePage == 0) ? 1 : startRecord + 1} - {currentCount > totalAnalyticLog ? totalAnalyticLog : currentCount} of {totalAnalyticLog}</DisplayPagination>
                            }
                            
                        </div>
                        <div className="d-flex justify-content-end align-items-center filter_ui_fix">
                            <div style={{ display: 'grid', marginLeft: '10px' }}>
                                <label>
                                    Filter
                                </label>
                                <Select
                                    defaultValue={defaultActionselected}
                                    options={actionLists}
                                    onChange={(event) => handleAction(event)}
                                    name="perform_action"
                                    className={styles.colourStyles}
                                />
                            </div>
                            <div style= {{ minWidth: '260px' }}>
                              <label>
                                  Recruiter 
                              </label>
                              <AsyncSelect
                                  isLoading={isLoading}
                                  isClearable={true}
                                  cacheOptions
                                  loadOptions={loadOptions}
                                  defaultValue={selectedRecruiterValue}
                                  defaultOption={selectedRecruiter}
                                  onInputChange={handleInputChange}
                                  onChange={handleSelectOption}
                                  placeholder={'Search by recruiter'}
                                  styles={colourStyles}
                                  noOptionsMessage={() => 'start typing the name of recruiter'}
                              />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
      <DataTable
          persistTableHead={true}
          noDataComponent={<p className="text-center p-3">No record found</p>}
          columns={columns}
          data={candidateView}
          progressPending={isLoading}
          progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
              <Spinner className='spinner' animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>
          </P>}
          onSort={handleSort}
          sortServer
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          paginationPerPage={perPage}
          paginationComponentOptions={{
              noRowsPerPage: true
          }}
          onChangePage={page => {
              setActivePage(page)
          }}
      />
    </Card>
  )
}

export default CandidateViewAnalyticPage