import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/Team'
import Button from '../../Admin/shared/Button'

const TeamAdd = ({recruiters,teamEntry,onHide,method,fetchData}) => {
	const [validationError, setValidationError] = useState({})
	const [entryData, setEntryData] = useState({
		name: teamEntry?.team_name ? teamEntry?.team_name : '',
		owner: teamEntry?.owner_id ? teamEntry?.owner_id : '',
	})
	const [btnDisabled, setBtnDisabled] = useState(false)
	const handleChange = (e) => {
		setValidationError({})
	}
	const [modal,setModal] = useState('')
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/admin/teams'
			if(method === 'put'){
				URL = `/admin/teams/${teamEntry.id}`
			}
			const payload = new FormData()
			payload.append('team[name]', e.target.name.value)
			payload.append('team[owner_id]', e.target.owner.value)
			payload.append('team[status]', 'active')
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
						onHide()
						fetchData()
				}
			}) 
		}
		setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		if(!e?.target?.name?.value?.trim()){
				formIsValid = false;
				errors["name"] = "Team Name is required field."
		}else if(!regex.test(e?.target?.name?.value?.trim())){
			formIsValid = false;
			errors["name"] = "Please Enter Valid Team Name."
		}
		if(!e?.target?.owner?.value?.trim()){
			formIsValid = false;
			errors["owner"] = "Team Leader is required field."
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<Container> 
				<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
						<Col xs={12} sm={12} lg={12}>
							<span>Team Name* : </span>
							<input type='text' defaultValue={entryData.name} onChange={handleChange} className='form-control w-100' name='name' id="name" />
							<label className='error'>
									{validationError && validationError.name}        
							</label>
						</Col>
						<Col xs={12} sm={12} lg={12}>
							<span>Select Team Leader* : </span>
							<Select
								options={recruiters}
								name='owner'
								id='owner'
								placeholder={'Select Team Leader'}
								onChange={handleChange}
								defaultValue={recruiters.filter((row) => row.value == entryData.owner)}
								menuPortalTarget={modal}
							/>
							<label className='error'>
								{validationError && validationError.owner} 
							</label>
						</Col>
					</Row>
					<Row className="w-100 modal-entry-form-row" >
						<Col xs={12} sm={12} lg={12}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Save</Button>
						</Col>
					</Row>
				</form>
				</Container>
		</>
  )
}

export default TeamAdd