import { Select } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Col, Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component-footer'
import SearchBar from '../../../common/SearchBar/SearchBar'
import Card from '../../Admin/shared/Card'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import './styles/index.scss'
import './styles/DataTableCustom.scss'
import { Button } from '../../Admin/styles/UserManagementEditPage.styled'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { firstCharacterCapital } from '../../../../utils'
import feather from 'feather-icons'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from 'moment'

const index = () => {
    var active_page = window.sessionStorage.getItem('agency_user_activePage')
    var query = window.sessionStorage.getItem('agency_user_query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [pageCountUsers, setPageCountUsers] = useState(0)
    const [currentUserCount, setCurrentUserCount] = useState(0)
    const [totalUsers, setTotalusers] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [users, setUsers] = useState([])
    const [addNewRow, setAddNewRow] = useState(null)
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [selected, setSelected] = useState({ value: 'all', label: 'All' })
    const [selectedRole, setSelectedRole] = useState('all')
    const [loading, setLoading] = useState(true)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showSweetAlertMessage, setShowSweetAlertMessage] = useState()
    const [sortField, setSortField] = useState('users.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    // const [perPage, setPerPage] = useState(per_page || 25)
    const [selectUser, setSelectUser] = useState(false)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    
    useEffect(() => {
        feather.replace()
    })
    
    const fetchData = async () => {
        
        window.sessionStorage.setItem('agency_user_activePage',activePage)
        const url = '/agencies/users.json'
        const response = await makeRequest(
            `${url}?page=${activePage}&search=${searchTerm}&role=recruiter&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
            )
            setLoading(false)
        setTotalusers(response.data.total_users)
        let sr_no = (activePage - 1) * response.data.per_page
        setUsers(
            response.data.users.map((user) => (sr_no = sr_no + 1,{
                sr_no: sr_no,
                id: user.id,
                name: firstCharacterCapital(user.first_name) + ' ' + firstCharacterCapital(user.last_name),
                email: user.email,
                designation: firstCharacterCapital(user.role),
                email_confirmed: user.email_confirmed ? 'Approved' : 'Pending',
                created_at: moment(user.created_at).format('MMM DD, YYYY'),
                designation: user?.designation === 'recruiter' ? 'Recruiter' : 'Account Manager',
                user_approved: user.user_approved !== null ? (user.user_approved ? 'Approved' : 'Reject') : 'Pending',
                actions: (<div className='w-100 d-inline-flex justify-content-around'>
                                    <a  href={`/agencies/recruiters/${user.id}`} className='pr-4'><i data-feather="edit-2" /></a>
                                    <a  onClick={() =>showConfirmDelete(user)}  href={`javascript::void()`} className='pr-4'><i data-feather="trash-2" /></a>
                                {user?.confirm_token &&    <a onClick={() => resendMail(user?.id)} data-toggle="tooltip" title='Resend Mail' href={`javascript::void()`} className='pr-4'>
                                   <i data-feather="send" />
                                  </a> }
                                </div>)
            }))
        )
       
        setPageCountUsers(response.data.total_pages_users)
        setCurrentUserCount(response.data.current_users_counts)
        setCurrentCounts(response.data.current_users_counts)
        setStartRecord((activePage - 1) * response.data.per_page)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage, selectedRole, selected, sortDirection, sortField])
    
    const createUser = () => {
        window.location.href ='/agencies/recruiters/new'
    }
   
    const deleteUser = async () => {
        setShowDeleteConfirmation(false)
        var id = selectUser.id
        setAddNewRow(null)
        if (id == -1) return
        const url = `/agencies/recruiters/${id}`
        const response = await makeRequest(url, 'delete', {
            contentType: 'application/json',
            messageType:'success',
            loadingMessage: 'Submitting...',
            createSuccessMessage: (response) =>{
                response.data.message
            } 
        })
        if(response?.data?.errorMsg){
            setShowSweetAlertMessage(response?.data?.errorMsg)
            setShowRemoveConfirmation(true)
        }else if(response?.data?.success){
             setTimeout(() => {
                 fetchData()
             }, 2000);
        }
    }

    const showConfirmDelete = (user) => {
        setSelectUser(user)
        setShowDeleteConfirmation(true)
    }

    const resendMail = async (id) => {
        const url = `/agencies/recruiters/resend_email_for_confirmation/${id}`
        await makeRequest(url, 'post', '', {
            createResponseMessage: (res) => {
                return {
                    message: 'Mail Resent Successfully',
                    messageType: 'success'
                }
            }
        })
    }
    
    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('agency_query', value)
    }

    const columns = [
        {
            name: 'No.',
            selector: (row) => row.sr_no,
            maxWidth: '5%',
            minWidth: '5%',
        },
        {
            name: 'User Name',
            selector: (row) => row.name,
            maxWidth: '15%',
            minWidth: '15%',
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            maxWidth: '20%',
            minWidth: '20%',
           
        },
        {
            name: 'User Status',
            selector: (row) => row.user_approved,
            maxWidth: '15%',
            minWidth: '15%',
        },
        {
            name: 'Account Verification',
            selector: (row) => row.email_confirmed,
            maxWidth: '10%',
            minWidth: '10%',
        },
        {
            name: 'Designation',
            selector: (row) => row.designation,
            maxWidth: '10%',
            minWidth: '10%',
        },
        {
            name: 'Created on',
            selector: row => row.created_at,
            maxWidth: '10%',
            minWidth: '10%',
        },
        {
            name: 'Actions',
            selector: (row) => row.actions,
            maxWidth: '15%',
            minWidth: '15%',
        },
    ]
    return (
        <Card>
            {/* {showRemoveConfirmation &&
        <SweetAlert
            warning
            title={showSweetAlertMessage}
            onConfirm={()=>handleClose(true)}
            focusCancelBtn
            cancelBtnStyle={{textDecoration: 'none'}}
        />
    } */}
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                  Recruiters
                </P>
                <Button onClick={createUser} className="ml-auto">
                    Create Recruiters
                </Button>
            </div>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '10px' }}
            >
                <Col xs={12} sm={12} lg={6} style={{ float: 'left' }}>
                    {totalUsers > 0 &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalUsers ? totalUsers : currentCounts } of {totalUsers}</DisplayPagination>
            }
                </Col>
                <Col xs={12} sm={12} lg={4} style={{ float: 'left' }}>
                    <SearchBar
                        placeholder="Search Recruiters..."
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 1 ? fetchData() : setActivePage(1)
                            !searchTerm &&
                                window.sessionStorage.setItem('agency_user_query', '')
                        }}
                        onClick={(e) => setSearchTerm('')}
                    />
                </Col>
            </div>

            <DataTable
                title={false}
                persistTableHead={true}
                noDataComponent={
                    <p className="text-center p-3">No record found</p>
                }
                columns={columns}
                data={users}
                progressPending={loading}
                progressComponent={
                    <P className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner
                            className="spinner"
                            animation="border"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </P>
                }
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalUsers}
                paginationPerPage={25}
                paginationComponentOptions={{
                    noRowsPerPage: true,
                }}
                onChangePage={(page) => setActivePage(page)}
            />
            {showDeleteConfirmation &&
        <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure you want to delete?"
            onConfirm={()=>(deleteUser(),setShowDeleteConfirmation(false))}
            onCancel={()=>setShowDeleteConfirmation(false)}
            focusCancelBtn
            >
            You will not be able to recover this user!
        </SweetAlert>
    }
        </Card>
    )
}

export default index
