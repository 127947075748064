import React, { useState, useEffect } from 'react'
import './../styles/coursePage.scss'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Modal from "react-bootstrap/Modal"
import { Row, Spinner } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import Paginator_v2 from '../../../common/Paginator/Paginator_v2'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'
import ProfileUploaderV2 from '../../CandidateProfilePage/ProfileUploaderV2'
import BlankOrganizationImage from '../../../../../assets/images/logo.png';
import styled from 'styled-components'

const IndexPage = ({ communityId, isCommunityAdmin, currentUser}) => {
  
    const [courses, setCourses] = useState([])
    const [totalPages, setTotalPages] = useState()
    const [loading, setLoading] = useState([])
    const [activePage, setActivePage] = useState(0)
    const [totalCount, setTotalCount] = useState()
    const [currentCount, setCurrentCount] = useState()
    const [perPage, setPerPage] = useState(20)
    const [coverImage, setCoverImage] = useState(null);
    // MODEL RELATED
    const [entry, setEntry] = useState({})
    const [show, setShow] = useState(false)
    const [method, setMethod] = useState('post')
    const [initialFormData, setInitialFormData] = useState({
      name: '',
      description: ''
    })
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)

    const courseValidation = {
      name: Yup.string()
        .required('Course name is required.').trim()
        .max(50, 'Name has a max length of 50 characters.'),
      description: Yup.string().trim().max(150, 'Course Description has a max length of 150 characters.').required('Course Description is required.')
    }
  
    const validationSchema = Yup.object({ ...courseValidation })

    useEffect(() => {
        fetchData()
      }, [activePage])

    const fetchData = async () => {
      setLoading(true)
      setTotalPages(0)
  
      const url = '/communities/' + communityId + `/classroom.json?page=${activePage == 0 ? 1 : activePage + 1}`
      makeRequest(url, 'get', '', {
        contentType: 'application/json',
        loadingMessage: 'Fetching...',
        onSuccess: (response) => {
          setCourses(
            response.data.list.map((list) => ({
              id: list.id,
              name: list.name,
              status: list.status,
              cover_image: list.cover_image,
              description: list.description,
              created_at: moment(list.created_at).format('MMM DD YYYY'),
            }))
          )
          setPerPage(response.data.per_page)
          setTotalPages(response.data.total_pages)
          setTotalCount(response.data.total_count)
          setCurrentCount(response.data.current_counts)
          setLoading(false)
        },
      })
    }

    const handleSubmit = async (values) => {
      const payload = new FormData()
      let URL = `/communities/${communityId}/classroom/create`
      if (method === 'put') {
        URL = `/communities/${communityId}/classroom/${entry.id}`
      }
      payload.append('course[name]', values.name.trim())
      payload.append('course[description]', values.description.trim())
      payload.append('course[status]', entry.status)
      if(typeof coverImage == 'object' && coverImage != null) {
        payload.append('course[cover]', coverImage)
      }
      setLoading(true)
      const response = await makeRequest(URL, method, payload, {
        contentType: 'multipart/form-data',
        loadingMessage: 'Submitting...',
        createSuccessMessage: (response) => response.data.message,
        createResponseMessage: (response) => {
          return {
            message: response.message,
            messageType: response.success ? 'success' : 'failure',
            loading: false,
            autoClose: true,
          }
        },
        onSuccess: (res) => {
          setLoading(false)
          fetchData()
          // console.log(res)
          if(method == 'put'){
            onHide()
          }else{
            onHide()
            // window.open('/communities/'+communityId+'/classroom/'+communityId+'/chapters', "_self")
          }
        },
      })
    }

    const onHide = () => {
      setEntry({})
      setCoverImage(null)
      setInitialFormData({
        name: '',
        description: ''
      })
      setMethod('post')
      setShow(false)
    }

    const editCourse = (course) => {
      setEntry({
        id: course.id || '',
        name: course.name || '',
        description: course.description || '',
        status: course.status,
      });
      setInitialFormData({
        name: course.name || '',
        description: course.description || '',
      })
      setCoverImage(course.cover_image)
      setShow(true)
      setMethod('put')
    }
    
    const deleteCourse = (course) => {
      setEntry({
        id: course.id || '',
        name: course.name || '',
        description: course.description || '',
        status: course.status,
      });
      setShowRemoveConfirmation(true);
    }
    
    const handleDelete = (e) => {
      const url = `/communities/${communityId}/classroom/${entry.id}`
      makeRequest(url, 'delete', '', {
        createSuccessMessage: () => 'Course deleted successfully',
        createErrorMessage: (e) => e.response.data.msg,
        onSuccess: () => {
          setShowRemoveConfirmation(false)
          fetchData()
        },
      })
    }

    const handleCoverChange = (file) => {
      setCoverImage(file)
    };
  
    const format_logo_url = (avatar_url) => {
      if(typeof avatar_url == "object"){
        return(URL.createObjectURL(avatar_url))
      }
      return avatar_url
    }

    const handleStatusUpdate = (e) => {
      setEntry({
        id: entry.id,
        status: e ? 1 : 0
      });
    }

    const ImageContainer = styled.div`
      display: flex;
      border-radius: 10px;
      width: 150px;
      height: 150px;
      margin-bottom: 10px;

      > img {
          border-radius: 10px;
          width: 150px;
      }
    `
    
    return (
    <div className="container communities-detail">
        <div className="row">
            {(courses && courses.length) ? (
                courses.map((course) => {
                return (
                <div className="col-lg-4 col-md-6" key={course.id}>
                    <div className="card-grid-2 hover-up wow animate__ animate__fadeIn animated" data-wow-delay=".0s" style={{visibility: "visible",animationDelay: "0s",animationName: "fadeIn"}}>
                        <div className="text-center card-grid-2-image">
                            <a href={'/communities/'+communityId+'/classroom/'+course.id+'/chapters'}>
                              {(course.cover_image != null) ? (
                                  <figure><img alt="jobhub" className="course_cover" src={course.cover_image} /></figure>
                              ): (
                                <div className="course_cover" style={{backgroundColor: '#909090',marginBottom: '7px'}}></div>
                              )}
                            </a>
                        </div>
                        {(isCommunityAdmin) ? (
                        <div className="eNqYVN kVYybR">
                            <button type="button" className="cngPsE mt-1" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="fFRSzd" style={{padding: '2px'}}>
                                    <svg viewBox="0 0 40 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M30 5C30 3.67392 30.5268 2.40215 31.4645 1.46447C32.4021 0.526785 33.6739 0 35 0C36.3261 0 37.5979 0.526785 38.5355 1.46447C39.4732 2.40215 40 3.67392 40 5C40 6.32608 39.4732 7.59785 38.5355 8.53553C37.5979 9.47321 36.3261 10 35 10C33.6739 10 32.4021 9.47321 31.4645 8.53553C30.5268 7.59785 30 6.32608 30 5ZM15 5C15 3.67392 15.5268 2.40215 16.4645 1.46447C17.4021 0.526785 18.6739 0 20 0C21.3261 0 22.5979 0.526785 23.5355 1.46447C24.4732 2.40215 25 3.67392 25 5C25 6.32608 24.4732 7.59785 23.5355 8.53553C22.5979 9.47321 21.3261 10 20 10C18.6739 10 17.4021 9.47321 16.4645 8.53553C15.5268 7.59785 15 6.32608 15 5ZM0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526785 3.67392 0 5 0C6.32608 0 7.59785 0.526785 8.53553 1.46447C9.47322 2.40215 10 3.67392 10 5C10 6.32608 9.47322 7.59785 8.53553 8.53553C7.59785 9.47321 6.32608 10 5 10C3.67392 10 2.40215 9.47321 1.46447 8.53553C0.526784 7.59785 0 6.32608 0 5V5Z"></path></svg>
                                </div>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-light" style={{margin: '0px'}} >
                                <li><a className="dropdown-item" href="#" onClick={() => { editCourse(course) }}>Edit course</a></li>
                                <li><a className="dropdown-item" href="#" onClick={() => { deleteCourse(course) }}>Delete course</a></li>
                            </ul>
                        </div>
                        ) : (
                         <></>
                        )}
                        <div className="card-body">
                            <h5 className="card-title">{course.name}</h5>
                            <p className="card-text">{course.description}</p>
                            <a href={'/communities/'+communityId+'/classroom/'+course.id+'/chapters'} className="btn btn-default rounded-pill btn-lg btn-block mt-5 btn-ai-view">Open</a>
                        </div>
                    </div>
                </div>
                )
                })
            ) : (
              (!isCommunityAdmin) ? (
                <div style={{ textAlign: 'center' }}>
                  No Courses Available
                </div>
              ) : (
                <></>
              )
            )}
            {(isCommunityAdmin) ? (
            <div className="col-lg-4 col-md-6">
                <div className="card add_course_ui" onClick={() => {
                  setEntry({status: 1})
                  setMethod('post')
                  setCoverImage(null)
                  setInitialFormData({
                    name: '',
                    description: ''
                  })
                  setShow(true)
                }} >
                    <div x="50%" y="50%" fill="#dee2e6" dy=".3em"><svg viewBox="0 0 40 40" width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M40 22.8571H22.8571V40H17.1429V22.8571H0V17.1429H17.1429V0H22.8571V17.1429H40V22.8571Z"></path></svg> 
                    <span style={{position: 'relative', top: '2px', marginLeft: '10px',fontWeight: 'bold'}}>New Course</span>
                    </div>
                </div>
            </div>
            ) : (
              <></>
            )}
        </div>
        {Array.isArray(courses) && (
            <Row className="d-flex justify-content-center">
                <Paginator_v2
                pageCount={totalPages}
                pageWindowSize={5}
                activePage={activePage}
                setActivePage={setActivePage}
                />
            </Row>
        )}

    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='modal-entry-form'
    >
        <Modal.Header closeButton className='mt-2 mb-2'>
          <Modal.Title id="contained-modal-title-vcenter" className="font-weight-bold">
            {method === 'put' ? 'Edit' : 'Add'} Course
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <ImageContainer>
                        <img src={coverImage == null ? BlankOrganizationImage : format_logo_url(coverImage)} />
                    </ImageContainer>
                  </div>
                  <div className="col-lg-6">
                    <div  style={{ marginLeft: '12px' }}>
                      <ProfileUploaderV2
                        onFileSelectSuccess={(file) =>
                          handleCoverChange(file)
                        }
                        recomm={'1084x576'}
                        btnText={'Upload Cover'}
                        onFileSelectError={({ error }) => alert(error)}
                        isProfilePicture={'profilePicture'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Formik
                validateOnChange={true}
                validateOnBlur={false}
                initialValues={initialFormData}
                validationSchema={validationSchema}
                onSubmit={(values)  => { handleSubmit(values)}}
              >
                {(props) => {
                  const {
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      setValues,
                      handleChange,
                      handleSubmit,
                  } = props
                  return (
                    <Form>
                      <div className="form-group">
                        <label className="control-label">Name</label>
                        <div className="">
                          <input type="text" name="name" className="form-control" placeholder="Name"
                            value={values.name} 
                            onChange={handleChange} 
                            style={{ height: 'auto' }}
                            error={
                                Boolean(
                                    errors.name
                                ) &&
                                touched.name
                            }
                          />
                          {errors.name && touched.name && (
                              <span width="28rem" className='error'>{errors.name}</span>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label">Description</label>
                        <div className="">
                          <input type="text" name="description" className="form-control" placeholder="Description"
                            value={values.description} 
                            onChange={handleChange} 
                            style={{ height: 'auto' }}
                            error={
                                Boolean(
                                    errors.description
                                ) &&
                                touched.description
                            }
                          />
                          {errors.description && touched.description && (
                              <span width="28rem" className='error'>{errors.description}</span>
                          )}
                        </div>
                      </div>
                      <div className='d-flex justify-content-between' style={{marginTop: '40px'}}>
                        <div  className={`deduct`}> 
                          <label>
                              <strong>{entry.status ? 'Published' : 'Drafted'}</strong>
                              <input type="checkbox" defaultChecked={entry.status} onClick={()=>{
                                  handleStatusUpdate(!entry.status)
                              }}
                              />
                              <span />
                          </label>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button type="submit" disabled={loading} className="btn btn-default font-heading icon-send-letter w-25 btn-ai-view">{loading ? (
                            <Spinner animation="border" variant="light" />
                          ) : (
                            <>{method === 'put' ? 'SAVE' : 'ADD'}</>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
            </Formik>
          </Container>
        </Modal.Body>
    </Modal>

    {showRemoveConfirmation &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to delete course? "
          onConfirm={() => (handleDelete())}
          onCancel={() => setShowRemoveConfirmation(false)}
          focusCancelBtn
        >
          You will not be able to recover this course!
        </SweetAlert>
    }
    </div>
  );
}
export default IndexPage