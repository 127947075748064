import React, { useState, useEffect } from 'react'
import Card from '../shared/Card'
import Table from '../shared/Table'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../shared/Button'
import P from '../shared/P'
import { Row, Col } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal"
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import './styles/SourcingSchedules.scss'
import Badge from "react-bootstrap/Badge";
import SweetAlert from 'react-bootstrap-sweetalert'

const SourcingSchedules = ({}) => {
  sessionStorage.removeItem('timeReportDateRange')
  const [candidates, setCandidates] = useState([])
  const [show,setShow] = useState(false)
  const [showAdd,setShowAdd] = useState(false)
  const [entry,setEntry] = useState({})
  const [modal,setModal] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isLoadingSourcer, setLoadingSourcer] = useState(false)
  const [isLoadingMarketer, setLoadingMarketer] = useState(false)
  const [selectedOrganization, setSelectedOrganization]  = useState('')
  const [selectedSourcer, setSelectedSourcer]  = useState()
  const [selectedSourcerFilter, setSelectedSourcerFilter]  = useState('')
  const [selectedMarketer, setSelectedMarketer]  = useState()
  const [inputValue, setInputValue] = useState('')
  const [organizationOptions, setOrganizationOptions] = useState([])
  const [sourcerOptions, setSourcerOptions] = useState([])
  const [marketerOptions, setMarketerOptions] = useState([])
  const [loading, setLoader] = useState(true)
  const [validationError, setValidationError] = useState({})
  const [showSweetAlert, setShowSweetAlert] = useState(false)
  const [showSweetAlertUpdate, setShowSweetAlertUpdate] = useState(false)
  const [fethData, setFethData] = useState({})
  const [deletedData, setDeletedData] = useState()
  const [selectedWeek, setSelectedWeek] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const [WeekTotals, setWeekTotals] = useState([])
  const [sourcingStatus,setSourcingStatus]=useState([
    { label: 'Paused', value: 'paused' },
    { label: 'Ongoing', value: 'ongoing' },
    { label: 'Manual', value: 'manual' },
  ])
  const [week,setWeek]=useState([
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' }
  ])
  const [method,setMethod] = useState('post')
  const [singleOrganization, setSingleOrganization] = useState()

  const fetchData = () => {
    setLoader(true)
    const url = `/admin/sourcing_schedules.json`
    makeRequest(url, 'get',  {params: {sourcer:selectedSourcerFilter}},{   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        let data = res.data.candidate_list
        data.sort((a, b) => (a.campaign_manager > b.campaign_manager) ? 1 : -1)
        setCandidates(data)
        setWeekTotals(res.data.totals)
        setLoader(false)
      },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    if(validateForm(e,method)){
      let URL = `/admin/sourcing_schedules`
      const payload = new FormData()
      payload.append('sourcing_schedule[user_id]', e.target.user_id.value)
      payload.append('sourcing_schedule[organization_id]', e.target.organization_id.value)
      payload.append('sourcing_schedule[sourcer_id]', e.target.sourcer_id.value)
      payload.append('sourcing_schedule[week]',  selectedWeek)
      payload.append('sourcing_schedule[status]', e.target.status.value)
      //headers
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      const headers = {
        headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
        },
      }
      makeRequest(URL,method, payload, headers, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
      }).then((res) => {
        if(res.data.success){
            onHide()
            fetchData()
        }
      }) 
      setBtnDisabled(false)
    }
  }

  const handleUpdate = (e) =>{
    setBtnDisabled(true)
    let URL = `/admin/sourcing_schedules/${singleOrganization.id}`
    const payload = new FormData()
    payload.append('sourcing_schedule[status]', e.status.value)
    payload.append('sourcing_schedule[sourcer_id]', e.sourcer_id.value)
    payload.append('sourcing_schedule[user_id]', e.user_id.value)
    payload.append('sourcing_schedule[week]', e.week.value)
    //headers
    const CSRF_Token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const headers = {
      headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
      },
    }
    makeRequest(URL,'put', payload, headers, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
    }).then((res) => {
      if(res.data.success){
          onHide()
          fetchData()
      }
    }) 
    setBtnDisabled(false)
  }

  const handleDelete = (value) =>{
    setBtnDisabled(true)
    let URL = `/admin/sourcing_schedules/${singleOrganization.id}`
    //headers
    const CSRF_Token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const headers = {
      headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
      },
    }
    makeRequest(URL,'delete', headers, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
    }).then((res) => {
      if(res.data.success){
          onHide()
          fetchData()
      }
    }) 
    setBtnDisabled(false)
  }

  const confirmDelete = (e,value) =>{
    e.preventDefault()
    setDeletedData(value)
    setShowSweetAlert(true)
  }

  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [selectedSourcerFilter])

  useEffect(()=>{
    setModal(document.querySelector('.modal'))
  })

  const handleInputChange = (str) => {
    setInputValue(str)
    handleChange()
    return str;
  }

  const handleChange = (e) => {
		setValidationError({})
    setSelectedStatus([...week.map((week) => ({ value: week.user_id, label: week.week }))]);
	}

  const onHide = () => {
    setValidationError({})
    setMethod('')
    setShow(false)
    setShowAdd(false)
    setEntry({})
  }

  const addData = () => {
    setSingleOrganization()
    setSelectedSourcer('')
    setSelectedMarketer('')
    setSelectedOrganization('')
    setSelectedWeek('')
    setSelectedStatus('')
    setEntry({})
    setShowAdd(true)
    setMethod('post')
    setShow(true)
  }

  const updateData = (info) => {
    setSingleOrganization(info)
    setSelectedSourcer({value: info.sourcer_id, label: info.sourcer_name })
    setSelectedMarketer({value: info.user_id, label: info.user_name })
    setSelectedOrganization({value: info.id, label: info.name })
    setSelectedWeek(week.filter((w) => w.value == info.week)[0])
    setSelectedStatus(sourcingStatus.filter((w) => w.value == info.status)[0])
    setShowAdd(true)
    setEntry({})
    setMethod('put')
    setShow(true) 
  }

  const getUpdateData = (e) => {
    e.preventDefault()
    if(validateForm(e,'put')){
      setEntry(e.target)
      setShowSweetAlertUpdate(true)
    }
  }

  const handleSelectWeek = (selectedOptions) => {
    setValidationError({})
    setSelectedWeek([...selectedOptions.map( ({value, label}) =>value)])
    
  }

  const handleSelectWeekUpdate = (selectedOptions) => {
    setValidationError({})
    setSelectedWeek([...week.map((week) => ({ value: week.user_id, label: week.week }))]);
  }

  const loadOrganizations = (inputValue, callback) => {
    setLoading(true)
    fetch(`/admin/organizations/fetch_approved_organization?search=${inputValue}`)
    .then((res) => res.json())
    .then((res) => {
      let {organizations} = res
      setLoading(false)
      setOrganizationOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(organizationOptions);
  }

  const loadMarketerOption = (inputValue, callback) => {
    setLoadingMarketer(true)
    fetch(`/admin/users/fetch_users?search=${inputValue}&role=recruiter&designation=marketer`)
    .then((res) => res.json())
    .then((res) => {
      let {users} = res
      setLoadingMarketer(false)
      setMarketerOptions([...users.map((user) => ({ value: user.id, label: user.first_name +' '+ user.last_name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(marketerOptions);
  }

  const loadSourcerOption = (inputValue, callback) => {
    setLoadingSourcer(true)
    fetch(`/admin/users/fetch_sourcer?search=${inputValue}`)
    .then((res) => res.json())
    .then((res) => {
      let {sourcer} = res
      setLoadingSourcer(false)
      setSourcerOptions([...sourcer.map((sourcer) => ({ value: sourcer.id, label: sourcer.first_name +' '+ sourcer.last_name }))]);
    })
    .catch((err) => console.log("Request failed", err));
    callback(sourcerOptions);
  }

  const validateForm = (e,method) => {
		let errors = {}
		let formIsValid = true
    if(!e?.target?.user_id?.value){
				formIsValid = false;
				errors["user_id"] = "Campaign Manager is required field."
		}
		if(!e?.target?.organization_id?.value && method == 'post'){
			formIsValid = false;
			errors["organization_id"] = "Client is required field."
		}
		if(!e?.target?.sourcer_id?.value){
			formIsValid = false;
			errors["sourcer_id"] = "Sourcer is required field."
		}
		if(selectedWeek?.length <= 0){
      formIsValid = false;
      if(method == 'put'){
			  errors["week"] = "Day is required field."
      }else{
        errors["week"] = "Days is required field."
      }
		}
    if(!e?.target?.status?.value){
			formIsValid = false;
			errors["status"] = "Status is required field."
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#fff',width: '100%'})
  }

  return(
    <div>
      <>    
        <Modal.Body>      
          <Modal
            onHide={onHide}
            show={showAdd}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            scrollable
            className='modal-entry-form'
            >
              <Modal.Header closeButton className='mt-2 mb-2'>
                <Modal.Title id="contained-modal-title-vcenter" >
                  { method ==='put' ?'Update' : 'Add' }  Sourcing Schedule
                </Modal.Title>
              </Modal.Header>
            <Modal.Body>
              <form onSubmit={(e) => {method ==='put' ? getUpdateData(e) :handleSubmit(e)}} style={{width:"100%"}}>
                <Row className="w-100" style={{minHeight:'120px'}}>
                  <Col xs={12} sm={12} lg={6} className="mt-1">
                    <span>Select Campaign Manager* : </span>
                    <AsyncSelect
                      name='user_id'
                      id='user_id'
                      isLoading={isLoadingMarketer}
                      isClearable={true}
                      loadOptions={loadMarketerOption}
                      defaultValue={selectedMarketer}
                      onInputChange={handleInputChange}
                      onChange={(event)=>setSelectedMarketer(event?.value || '')}
                      placeholder={'Select Campaign Manager'}
                      styles={colourStyles}
                      noOptionsMessage={() => 'Start typing the name of sourcer'}
                      menuPosition='fixed'
                    />
                    <label className='error'>
                      {validationError && validationError.user_id} 
                    </label>
                  </Col>
                  <Col xs={12} sm={12} lg={6} className="mt-1">
                    <span>Select Sourcer* : </span>
                    <AsyncSelect
                        name='sourcer_id'
                        id='sourcer_id'
                        isLoading={isLoadingSourcer}
                        isClearable={true}
                        loadOptions={loadSourcerOption}
                        defaultValue={selectedSourcer}
                        onInputChange={handleInputChange}
                        onChange={(event)=>setSelectedSourcer(event?.value || '')}
                        placeholder={'Select Sourcer'}
                        styles={colourStyles}
                        noOptionsMessage={() => 'Start typing the name of campaign manager'}
                        menuPosition='fixed'
                    />
                    <label className='error'>
                      {validationError && validationError.sourcer_id} 
                    </label>
                  </Col>
                  <Col xs={12} sm={12} lg={6} className="mt-1">
                    <span>Select Client* : </span>
                    <AsyncSelect
                        name='organization_id'
                        id='organization_id'
                        isLoading={isLoading}
                        isClearable={true}
                        loadOptions={loadOrganizations}
                        defaultValue={selectedOrganization}
                        onInputChange={handleInputChange}
                        onChange={(event)=>setSelectedOrganization(event?.value || '')}
                        placeholder={'Select Client'}
                        styles={colourStyles}
                        noOptionsMessage={() => 'Start typing the name of client name'}
                        menuPosition='fixed'
                        isDisabled={method ==='put' ? true : false} 
                    />
                    <label className='error'>
                      {validationError && validationError.organization_id} 
                    </label>
                  </Col>
                  <Col xs={12} sm={12} lg={6} className="mt-1">
                    {method ==='put' ? <span name="sid" id="sid" value={fethData.id} >Select Day* : </span> :<span name="sid" id="sid" value={fethData.id} >Select Days* : </span>}
                    {method ==='put' ?
                      <Select
                        options={week}
                        defaultValue={selectedWeek}
                        name='week'
                        id='week'
                        placeholder={'Select Days'}
                        onChange={handleSelectWeekUpdate}
                        menuPosition='fixed'
                      />
                     :<Select
                        isMulti
                        options={week}
                        defaultValue={selectedWeek}
                        name='week'
                        id='week'
                        placeholder={'Select Days'}
                        onChange={handleSelectWeek}
                        menuPosition='fixed'
                      />
                    }
                    <label className='error'>
                      {validationError && validationError.week} 
                    </label>
                  </Col>
                  <Col xs={12} sm={12} lg={6} className="mt-1">
                    <span type='hidden' name="sid" id="sid" value={fethData.id}>Select Status* : </span>
                    <Select
                      options={sourcingStatus}
                      name='status'
                      id='status'
                      placeholder={'Select Status'}
                      defaultValue={selectedStatus}
                      onChange={(event)=>setSelectedStatus(event?.value || '')}
                      menuPosition='fixed'
                    />
                    <label className='error'>
                      {validationError && validationError.status} 
                    </label>
                  </Col>
                  <Col xs={12} sm={12} lg={12} style={{marginTop:'20px'}}>
                      <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius mr-1'>{ method ==='put' ?'Update' : 'Add' }</Button>
                    {method ==='post'  && (
                      <Button type='button' onClick={onHide} className='btn btn-primary btn-radius'>Cancel</Button>
                    )}
                    {method ==='put'  && (
                      <Button type='button' disabled={btnDisabled} onClick={(e)=>{confirmDelete(e,fethData.id)}} className='btn btn-denger '>Delete</Button>
                    )}
                  </Col>
                </Row>
              </form>
            </Modal.Body>
          </Modal>
        </Modal.Body>
        <Card>
      <div className="d-flex justify-content-between align-items-center w-100 mb-1">
        <P size="28px"> Sourcing Assignment Schedule</P>
        <button className={`ml-3 btn-add`} onClick={addData}>
          + Add Sourcing Assignment
        </button>
      </div>
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'end', }}>
        <Col lg="3" md="3" sm="3" xs="3" className='p-0'>
          <div>
            <label>
                Select Sourcer :
            </label>
            <AsyncSelect
                name='sourcer'
                id='sourcer'
                isLoading={isLoadingSourcer}
                isClearable={true}
                loadOptions={loadSourcerOption}
                defaultOptions={selectedSourcerFilter}
                onInputChange={handleInputChange}
                onChange={(event)=>setSelectedSourcerFilter(event?.value || '')}
                placeholder={'Select Sourcer Name'}
                styles={colourStyles}
                noOptionsMessage={() => 'Start typing the name of sourcer'}
            />
          </div>
        </Col>
        <Col lg="9" md="9" sm="9" xs="9" className='p-0'>
          <div className='text-right'>
            <Badge pill variant="secondary" className={`info-button paused`} >Paused</Badge>
            <Badge pill variant="secondary" className={`info-button ongoing`} >Ongoing</Badge>
            <Badge pill variant="secondary" className={`info-button manual`} >Manual</Badge>
          </div>
        </Col>

      </Row>
      <Table
        columNames={[
          {
            name: 'Campaign Manager',
            field: 'campaign_manager',
            editable: false,
            type: 'text',
          },
          {
            name: (<span>Monday {(WeekTotals?.monday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.monday?.ongoing}</Badge>)} {(WeekTotals?.monday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.monday?.manual}</Badge>))} {(WeekTotals?.monday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.monday?.paused}</Badge>))} </span> ),
            field: 'monday',
            editable: false,
            type: 'text',
          },
          {
            name: (<span>Tuesday {(WeekTotals?.tuesday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.tuesday?.ongoing}</Badge>)} {(WeekTotals?.tuesday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.tuesday?.manual}</Badge>))} {(WeekTotals?.tuesday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.tuesday?.paused}</Badge>))} </span> ),
            field: 'tuesday',
            editable: true,
            type: 'text',
          },
          {
            name: (<span>Wednesday {(WeekTotals?.wednesday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.wednesday?.ongoing}</Badge>)} {(WeekTotals?.wednesday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.wednesday?.manual}</Badge>))} {(WeekTotals?.wednesday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.wednesday?.paused}</Badge>))}</span> ),
            field: 'wednesday',
            editable: false,
            type: 'text',
          },
          {
            name: (<span>Thursday {(WeekTotals?.thursday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.thursday?.ongoing}</Badge>)} {(WeekTotals?.thursday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.thursday?.manual}</Badge>))} {(WeekTotals?.thursday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.thursday?.paused}</Badge>))}</span> ),
            field: 'thursday',
            editable: false,
            type: 'text',
          },
          {
            name: (<span>Friday {(WeekTotals?.friday?.ongoing > 0) && (<Badge pill variant="secondary" className="round-button ongoing" >{WeekTotals?.friday?.ongoing}</Badge>)} {(WeekTotals?.friday?.manual > 0 && (<Badge pill variant="secondary" className="round-button manual" >{WeekTotals?.friday?.manual}</Badge>))} {(WeekTotals?.friday?.paused > 0 && (<Badge pill variant="secondary" className="round-button paused" >{WeekTotals?.friday?.paused}</Badge>))}</span> ),
            field: 'friday',
            editable: false,
            type: 'text',
          },
        ]}
        rowValues={candidates?.map((candidate) => ({
          ...candidate,
          monday: (candidate?.monday) ? (candidate?.monday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
          thursday:  (candidate?.thursday) ? (candidate?.thursday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
          friday: (candidate?.friday) ? (candidate?.friday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
          tuesday: (candidate?.tuesday) ? (candidate?.tuesday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
          wednesday: (candidate?.wednesday) ? (candidate?.wednesday).sort((a, b) => (a?.name > b?.name) ? 1 : -1).map((info, index) => OrganizationList(info, index)) : '',
        }))}
        loading={loading}
      />
        {showSweetAlert && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure to delete?"
            onConfirm={()=>{
                handleDelete(deletedData)
                setShowSweetAlert(false)
              }
            }
            onCancel={()=>setShowSweetAlert(false)}
            focusCancelBtn
            cancelBtnStyle={{textDecoration: 'none'}}
          />
        )}
        {showSweetAlertUpdate && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, Update it!"
            confirmBtnBsStyle="danger"
            title="Are you sure to update?"
            onConfirm={()=>{
                handleUpdate(entry)
                setShowSweetAlertUpdate(false)
              }
            }
            onCancel={()=>{
              setShowSweetAlertUpdate(false)
              setBtnDisabled(false)
            }}
            focusCancelBtn
            cancelBtnStyle={{textDecoration: 'none'}}
          />
        )}
        </Card>
      </>
    </div>
  )
  
  function OrganizationList(info, index){
    return(
      <>
        <Badge
          pill
          key={index}
          variant="secondary"
          className={`skills-button ${info['status']}`}
          onClick={()=> updateData(info)}
        >
          <label onClick={()=> updateData(info)} className={`candidate-skills`}> {info['name']} </label>
        </Badge>
      </>
    )
  } 
}

export default SourcingSchedules