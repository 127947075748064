import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Modal from "react-bootstrap/Modal"
import Card from '../../Admin/shared/Card'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row,Col,Spinner } from 'react-bootstrap'
import './styles/CommissionRate.scss'
import Add from './Add'
import DataTable from "react-data-table-component-footer"
import   '../../Admin/styles/DataTableCustom.scss'
import feather from 'feather-icons'
import { capitalize } from "../../../../utils"
import SweetAlert from 'react-bootstrap-sweetalert'


const CommissionRate = (prop) => {
    var active_page = window.sessionStorage.getItem('cm_activePage')
    var query = window.sessionStorage.getItem('cm_query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [pageCount, setPageCount] = useState(0)
    const [rateList, setRateList] = useState([])
    const [totalJobs,setTotalJobs] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [loading, setLoading] = useState(true)
    const [show,setShow] = useState(false)
    const [method,setMethod] = useState('post')
    const [rate,setRate] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [perPage, setPerPage] = useState(prop.per_page)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [id,setId] = useState(0)
    
    useEffect(() => {
        feather.replace()
    })

    const fetchData = () => {
        setLoading(true)
        const url = `/cardinal/commission_rates.json`
        window.sessionStorage.setItem('cm_activePage',activePage)
        makeRequest(url, 'get',  {params: {page: (activePage), query: query, sortField: sortField, sortDirection: sortDirection}}, {   
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {

                setTotalJobs(res.data.total_count)
                let sr_no = (activePage - 1) * res.data.per_page
                setRateList(
                    res.data.list.map((data) => ( sr_no = sr_no + 1,{
                        sr_no:sr_no,
                        id: data.id,
                        organization_name: data.name,
                        commission_type: capitalize(data.commission_type),
                        commission_rate: data.commission_rate,
                        commission_to_hires: data.commission_to_hires,
                        commission_to_campaign_manager: data.commission_to_campaign_manager,
                        commission_to_submitter: data.commission_to_submitter,
                        fee_agreement:  data?.fee_agreement ?  <a  href={ data.fee_agreement} className='mr-3' target='_blank'>File</a> : '',
                        actions:(<div className='w-100 d-inline-flex justify-content-around'>
                        <a onClick={ () => onEditClick(data)} href={`javascript::void()`} className='mr-3'><i data-feather="edit-2" /></a>
                        <a onClick={ () => deleteRateCall(data.id)} href={`javascript::void()`}><i data-feather="trash-2" /></a>
                      </div>)
                    }))
                )
                setPageCount(res.data.total_pages)
                setCurrentCounts(res.data.current_counts)
                setStartRecord((activePage - 1) * res.data.per_page)
                setLoading(false)
            },
        })
    }

    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,sortDirection,sortField])

   
    const  columns= [
            {
                name: 'No.',
                selector: row => row.sr_no,
                maxWidth: '4%',
                minWidth: '4%'
            },
            {
                name: 'Organization Name',
                selector: row => row.organization_name,
                maxWidth: '16%',
                minWidth: '16%',
                sortable: true,
                sortField: 'organizations.name',
                wrap:true
            },
            {
                name: 'Commission On',
                selector: row => row.commission_type,
                maxWidth: '16%',
                minWidth: '16%',
            },
            {
                name: 'Commission Rate',
                selector: row => row.commission_rate,
                maxWidth: '10%',
                minWidth: '10%',
                sortable: true,
                sortField: 'commission_rate',
            },{
                name: 'Commission to CM (%)',
                selector: row => row.commission_to_campaign_manager,
                maxWidth: '13%',
                minWidth: '13%',
                sortable: true,
                sortField: 'commission_to_campaign_manager',
            },
            {
                name: 'Commission to Submitter (%)',
                selector: row => row.commission_to_submitter,
                maxWidth: '11%',
                minWidth: '11%',
                sortable: true,
                sortField: 'commission_to_submitter',
              
            },
            {
                name: 'Commission to Hires (%)',
                selector: row => row.commission_to_hires,
                maxWidth: '13%',
                minWidth: '13%',
                sortable: true,
                sortField: 'commission_to_hires',
            },
            {
                name: 'Fee Agreement File',
                selector: row => row.fee_agreement,
                maxWidth: '11%',
                minWidth: '11%',
            },
            {
              name: 'Actions',
              cell: row => row.actions,
              wrap: true,
              maxWidth: '6%',
              minWidth: '6%'
            }
        ]

    const addRate = () => {
        setRate({})
        setMethod('post')
        setShow(true)
    }
    
    const onHide = () => {
        setRate({})
        setMethod('post')
        setShow(false)
        setActivePage(1)
        fetchData()
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const onEditClick = (data) => {
        setRate(data)
        setMethod('put')
        setShow(true)
    }

    const deleteRate = async () => {
        const URL = `/cardinal/commission_rates/${id}`
        await makeRequest(URL, 'delete', '', {
            createResponseMessage: () => {
                return {
                    message: `Deleted successful`,
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: () => {
                setShowRemoveConfirmation(false)    
                fetchData()
            },      
        })      
    }

    const deleteRateCall = (id) => {
        setShowRemoveConfirmation(true)
        setId(id)
    }

    return (
        <>
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Commission Rates
                </P>
                <div style={{flexGrow:1}}/>
                <Button onClick={addRate} className="ml-3">
                    Add
                </Button>
            </div>
            
            <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    {totalJobs > 0  &&
                        <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalJobs} commission rates
                        </DisplayPagination>
                    }
                 </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={rateList}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalJobs}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
            />
        </Card>

        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            scrollable
            className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
            { method ==='put' ? 'Update' : '' } Commission Rate
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Add  rateData={rate} onHide={onHide} method={method}></Add>
        </Modal.Body>
        </Modal>
        {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to delete?"
                    onConfirm={()=>deleteRate()}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    >
                     You will not be able to recover this!
                    </SweetAlert>
        }
        </>
    )
}

export default CommissionRate