import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/Team'
import validator from 'validator'
import Button from '../../Admin/shared/Button'
import DateSelector from '../../../common/DateSelector.js/DateSelector'
import AsyncCreatableSelect from 'react-select/async-creatable'
import ReactSelect from 'react-select'
import moment from 'moment'
import axios from 'axios'

const TeamAdd = ({teamEntry,onHide,method,fetchData, team_lists, setFetchRecApi,fetchRecApi }) => {
	const recruiter_list = []
	const team_lead_list = []
	const [validationError, setValidationError] = useState({})
	const queryParams = new URLSearchParams(window.location.search)
    const [isLoading, setLoading] = useState(false)
    const urlRecruiterId = queryParams.get("recruiter")
	let store_location = sessionStorage.getItem("store_location")
    const [selectedLocation, setSelectedLocation] = useState((urlteamId)? store_location.filter((option) => option.value == urlteamId) :store_location && store_location != "null" ? JSON.parse(store_location).label : '')
	const locationChange = (str) => {
        setSelectedLocation(str.label)
		setValidationError({})
		sessionStorage.setItem("store_location", JSON.stringify(str))

    }
	const urlteamId = queryParams.get("team_id")
    let jobStatus_recruiter = sessionStorage.getItem("jobStatus_recruiter")
    let team_name_list = sessionStorage.getItem("team_name_list")
	let team_lead = sessionStorage.getItem("team_lead_list")
    const [recruiter,setRecruiter] = useState([...recruiter_list])
    const [defaultselected,setdefaultselected] = useState((jobStatus_recruiter)? recruiter_list.filter((option) => option.value == JSON.parse(jobStatus_recruiter).value) : entryData && entryData != "null" ? entryData.name : '')
	const [entryData, setEntryData] = useState({
		name: teamEntry?.user ? teamEntry?.user : '',
		title: teamEntry?.title ? teamEntry?.title : '',
		owner: teamEntry?.owner_id ? teamEntry?.owner_id : '',
		email: teamEntry?.email ? teamEntry?.email : '',
		phone: teamEntry?.phone ? teamEntry?.phone : '',
		linkedln: teamEntry?.linkedln ? teamEntry?.linkedln : '',
		department: teamEntry?.department ? teamEntry?.department : '',
		location: teamEntry?.location ? teamEntry?.location : '',
		manager: teamEntry?.manager ? teamEntry?.manager : '',
		start_date: teamEntry?.start_date ? teamEntry?.start_date : '',
		birthday: teamEntry?.birthday ? teamEntry?.birthday : '',
		team: teamEntry?.team?.id ? teamEntry?.team?.id : '',
	})
	const bday = entryData?.birthday.split(" ");
	const [selectedRecruiter,setSelectedRecruiter] = useState(entryData?.name.id ? entryData?.name.id : '')
    const [defaultDepartmentname, setdefaultDepartmentname] = useState({value: entryData?.department, label: entryData?.department })
	const [defaultRecruiter, setdefaultRecruiter] = useState([{ value: entryData?.name?.id, label: entryData.name?.first_name !== "null" ? entryData.name?.first_name : '' }])
	const [defaultLocation, setdefaultLocation] = useState([{ value: entryData?.location, label: entryData.location !== "null" ? entryData.location : 'No location found' }])
    const [defaultselectedteam,setdefaultselectedTeam] = useState((team_name_list)? team_lists.filter((option) => option.value == JSON.parse(team_name_list).value) : team_name_list && team_name_list != "null" ? JSON.parse(team_name_list) : '')
    const [selectedTeam,setSelectedTeam] = useState(entryData?.team ? entryData?.team : '' )
	const [btnDisabled, setBtnDisabled] = useState(false)
    const [selectedTeamLead,setSelectedTeamLead] = useState((urlteamId)? team_lead.filter((option) => option.value == urlteamId) :team_lead && team_lead != "null" ? JSON.parse(team_lead).value : '')
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedBirthDate, setSelectedBirthDate] = useState(new Date())
	const [memberName,setMemberName] = useState()
	const department_name = sessionStorage.getItem("department")
	const birthday_month =  sessionStorage.getItem("birthday_month")
	const day =  sessionStorage.getItem("day")
	const colourStyles = {
        control: styles => ({ ...styles, height: '45px' })
    };
    const Department_list = [
			{label:"Accounting" ,value: "Accounting"},
			{label:"Finance",value: "Finance"},
			{label:"Sales" ,value:"Sales"},
			{label:"Research and Development" ,value:"Research and Development" },
			{label:"IT", value:"IT"},
			{label:"Management", value:"Management"},
			{label:"Administration" ,value:"Administration"},
			{label:"Customer support", value:"Customer support"},
			{label:"Technical support", value:"Technical support"},
			{label:"Marketing", value:"Marketing"},
			{label:"Logistics" ,value:"Logistics"},
			{label:"Operations" ,value:"Operations"},
			{label:"Planning" ,value:"Planning"},
			{label:"Human resources" ,value:"Human resources"},
			{label:"Purchasing" ,value:"Purchasing"},
			{label:"Quality assurance", value:"Quality assurance"},
			{label:"Engineering" ,value:"Engineering"},
			{label:"Public relations" ,value:"Public relations"},
	]
    const [selectedDepartment,setSelectedDepartment] = useState(entryData?.department ? entryData?.department : ''
	)
    const [defaultDepartment,setdefaultDepartment] = useState((department_name)? Department_list.filter((option) => option.value == JSON.parse(department_name).value) : entryData && entryData != "null" ? entryData.name : '')
    const [selectedBirthdaymonth,setSelectedBirthdayMonth] = useState(bday[0] ? bday[0] : '' )
    const [selectedDay,setSelectedDay] = useState(bday[1] ? bday[1] : '' )
    const [selectTeamRecruiter, setSelectTeamRecruiter ] = useState('')
	const [memeber, setMember] = useState([])
	const  birthdayMonth = [
		{label:"January" ,value: "Jan"},
		{label:"February" ,value: "Feb"},
		{label:"March" ,value: "Mar"},
		{label:"April" ,value: "Apr"},
		{label:"May" ,value: "May"},
		{label:"June" ,value: "Jun"},
		{label:"July" ,value: "Jul"},
		{label:"August" ,value: "Aug"},
		{label:"September" ,value: "Sep"},
		{label:"October" ,value: "Oct"},
		{label:"November" ,value: "Nov"},
		{label:"December" ,value: "Dec"},
	]
	const birthdayDay = [
		{label:"01" ,value: "01"},{label:"02" ,value: "02"},{label:"03" ,value: "03"},{label:"04" ,value: "04"},{label:"05" ,value: "05"},{label:"06" ,value: "06"}, 
		{label:"07" ,value: "07"},{label:"08" ,value: "08"},{label:"09" ,value: "09"},{label:"10" ,value: "10"},{label:"11" ,value: "11"},{label:"12" ,value: "12"},
		{label:"13" ,value: "13"},{label:"14" ,value: "14"},{label:"15" ,value: "16"},{label:"17" ,value: "17"},{label:"18" ,value: "18"},{label:"19" ,value: "19"},
		{label:"20" ,value: "20"},{label:"21" ,value: "21"},{label:"22" ,value: "22"},{label:"23" ,value: "23"},{label:"24" ,value: "24"},{label:"25" ,value: "25"},,{label:"26" ,value: "26"},
		{label:"27" ,value: "27"},{label:"28" ,value: "28"},{label:"29" ,value: "29"},{label:"30" ,value: "30"},{label:"31" ,value: "31"}, 


	]

    const handleKeyPress = async (value, callback) => {
        const url = `/filter_candidate_on_location`;
        const formData = new FormData();
        formData.append('filter_word', value);
        setLoading(true);
        const response = await axios
            .post(url, formData)
            .then((res) => res)
            .catch((error) => console.log(error));
        const locationPreferrenceArray = response.data.filter.map(
            ({ id, state, city, country }) => {
                if (country === 'usa') {
                    return { value: id, label: `${city}, ${state} (US)` };
                } else {
                    return { value: id, label: `${city}, ${state}` };
                }
            }
        );
        callback(locationPreferrenceArray);
        setLoading(false);
    };
	const handleChange = (e) => {
		setValidationError({})
		// setSelectedRecruiter(e.value)
		sessionStorage.setItem("jobStatus_recruiter", JSON.stringify(e))
	}
	const [modal,setModal] = useState('')
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const handleSubmit = (e) => {
		// sessionStorage.setItem("jobStatus_recruiter", '')
		// sessionStorage.setItem("team_name_list",'')
		// sessionStorage.setItem("team_lead_list", '')
		e.preventDefault()
		// setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/admin/team_contacts'
			if(method === 'put'){
				URL = `/admin/team_contacts/edit/${teamEntry.id}`
			}
			const payload = new FormData()
			payload.append('team[user_id]', selectedRecruiter)
			payload.append('team[title]', e.target.title.value)
			payload.append('team[team_id]', selectedTeam)
			// payload.append('team[start_date]', selectedDate)
			// payload.append('team[manager]', memberName.owner)
			payload.append('team[department]', selectedDepartment)
			payload.append('team[phone]', e.target.phone.value)
			payload.append('team[email]', e.target.email.value)
			payload.append('team[linkedln]', e.target.linkedin.value)
			payload.append('team[location]', selectedLocation)
			payload.append('team[birthday]', selectedBirthdaymonth + " " + selectedDay)
			
			// payload.append('team[]', 'active')
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
						onHide()
           		setBtnDisabled(true)

						fetchData()
				}
			}) 
		}
		// setBtnDisabled(false)
	}

	useEffect(() => {
		setMember([])
		setFetchRecApi([])
	}, [])

  const onSelectRecruiter = async (event) => {
	setFetchRecApi("")
	setdefaultRecruiter([])
	setMember([])
	const url = `/admin/team_contacts/filter_team_recruiter`;
	const formData = new FormData();
	formData.append('filter_team', event?.value);
	setLoading(true);
	const response = await axios
		.post(url, formData)
		.then((res) => setMember(res?.data?.data))
		.catch((error) => console.log(error));
	setLoading(false)
  }
	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		const phoneRegex = /^\+?[0-9]{2}-?[0-9]{6,12}$/g
		const nameRegex = /^[a-zA-Z]*$/
		const regexLinkdin = new RegExp('www.linkedin.com');
        const regex_to = new RegExp('linkedin.com');
		if(!(e?.target?.department?.value?.trim())){
			formIsValid = false;
			errors["department"] = "Department is required field."
		}
		 if(!regex.test(e?.target?.title?.value?.trim())){
			formIsValid = false;
			errors["title"] = "Please enter valid title."
		}
		if(!phoneRegex.test(e?.target?.phone?.value?.trim())){
			formIsValid = false;
			errors["phone"] = "Please enter valid Phone."
		}
		if(!(e?.target?.email?.value?.trim())){
			formIsValid = false;
			errors["email"] = "Please enter valid email."
		}
		if(!(e?.target?.location?.value?.trim())){
			formIsValid = false;
			errors["location"] = "Please enter valid location."
		}
		// if(!regex.test(e?.target?.linkedin?.value?.trim())){
		// 	formIsValid = false;
		// 	errors["linkedin"] = "Please Enter Valid linkedin."
		// }
		if (
			( !(regexLinkdin.test(e?.target?.linkedin?.value)) && !(regex_to.test(e?.target?.linkedin?.value)) && e?.target?.linkedin?.value?.length && validator.trim(e?.target?.linkedin?.value))){
		    	formIsValid = false;
				errors["linkedin"] ='Invalid url, please add Linkedin url only.'
			}
		if(!regex.test(e?.target?.title?.value?.trim())){
			formIsValid = false;
			errors["birthday"] = "Please Enter Valid birthday."
		}
		if(!(e?.target?.name?.value?.trim())){
			formIsValid = false;
			errors["name"] = "Recruiter Name is required."
		}
		if(!(e?.target?.team?.value?.trim())){
			formIsValid = false;
			errors["team"] = "Team is required field."
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<Container> 
				<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
								<Col xs={6} sm={6} lg={6}>
									<span>Select Team* : </span>
									<Select
										defaultValue={team_lists.filter((row) => row.value == entryData.team)}
										options={team_lists}
										onChange={(event)=>{
											setMemberName(event)
											onSelectRecruiter(event)
											setSelectTeamRecruiter(event)
											setSelectedTeam(event.value)
											setBtnDisabled(false)
											// setSelectedRecruiter('')
											sessionStorage.setItem("team_name_list", JSON.stringify(event))
										}
									}
									styles={colourStyles}
										formatOptionLabel={function(data){
											return (<div style={{display: 'flex', flexDirection: 'column'}} dangerouslySetInnerHTML={{__html: `<span>${data.label}</span><small>${data.owner}</small>`}}></div>)
										}}	
										name="team"
										// className={styles.colourStyles}
									/>
									<label className='error'>
										{validationError && validationError.team} 
									</label>
								</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Select Recruiter* : </span>
							<Select
                                value={defaultRecruiter}
                                options={fetchRecApi ? fetchRecApi : memeber}
								// value={defaultselected}
                                onChange={(event)=>{
									// onSelectRecruiter(event)
								    setValidationError({})
									setBtnDisabled(false)
									setdefaultRecruiter(event)
                                    setSelectedRecruiter(event.value)
                                    sessionStorage.setItem("jobStatus_recruiter", JSON.stringify(event))
                                }
                                }
                                name="name"
								styles={colourStyles}
                                // className={styles.colourStyles}
                            />
							<label className='error'>
								{validationError && validationError.name} 
							</label>
						</Col>
						<Col xs={3} sm={3} lg={3}>
							<span>Birthday : </span>
									<Select
							    placeholder='Month'
							    defaultValue={birthdayMonth?.filter((row) => row.label == bday[0])}
						     	options={birthdayMonth}
						     	onChange={(event)=>{
								setValidationError({})
								setSelectedBirthdayMonth(event.label)
								sessionStorage.setItem("birthday_month", JSON.stringify(event))
							}
							}

									/>
						</Col>
						<Col xs={3} sm={3} lg={3}>
							<span>&nbsp;</span>
									<Select
									placeholder="Day"
							    defaultValue={birthdayDay?.filter((row) => row.label == bday[1])}
						     	options={birthdayDay}
						     	onChange={(event)=>{
								setValidationError({})
								setSelectedDay(event.label)
								sessionStorage.setItem("day", JSON.stringify(event))
							}
							}

									/>

						</Col>
						<Col xs={6} sm={6} lg={6}>
						<span>Select Department* : </span>
							<Select
							
							    classNamePrefix="select"
                                defaultValue={defaultDepartmentname}
                                options={Department_list}
                                minMenuHeight={6}
                                onChange={(event)=>{
                                    setValidationError({})
                                    setSelectedDepartment(event.value)
                                    sessionStorage.setItem("department", JSON.stringify(event))
                                }
                                }
								
                                name="department"
                              />
							<label className='error'>
									{validationError && validationError.department}        
							</label>
							
						</Col>
						<Col xs={6} sm={6} lg={6}>
						<span>Title*: </span>
							<input type='text' defaultValue={entryData.title} onChange={handleChange} className='form-control w-100' name='title' id="title" />
							<label className='error'>
									{validationError && validationError.title}        
							</label>
							
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Email*: </span>
							<input type='email' defaultValue={entryData.email} onChange={handleChange} className='form-control w-100' name='email' id="email" />
							<label className='error'>
									{validationError && validationError.email}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
							<span>Location*: </span>
                                        <AsyncCreatableSelect
                                            isLoading={isLoading}
                                            isClearable={false}
                                            cacheOptions
                                            loadOptions={handleKeyPress}
                                            defaultValue={defaultLocation.filter((row) => row.label == teamEntry.location)}
                                            onChange={locationChange}
                                            styles={colourStyles}
											name='location'
                                            placeholder="Search for location"
                                            noOptionsMessage={() => 'start typing the name of location'}
                                        />
										<label className='error'>
									{ validationError && validationError.location}        
							</label>
						</Col>
						<Col xs={6} sm={6} lg={6}>
						<span>Phone*: </span>
							<input type='text' defaultValue={entryData.phone} onChange={handleChange} className='form-control w-100' name='phone' id="phone" />
							<label className='error'>
									{validationError && validationError.phone}        
							</label>
						</Col>
						<Col xs={12} sm={12} lg={12}>
							<span>LinkedIn: </span>
							<input type='text' defaultValue={entryData.linkedln} onChange={handleChange} className='form-control w-100' name='linkedin' id="linkedin" />
							<label className='error'>
									{validationError && validationError.linkedin}        
							</label>
						</Col>
					</Row>
					<Row className="w-100 modal-entry-form-row" >
						<Col xs={3} sm={3} lg={3}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Save</Button>
						</Col>
						{/* <Col xs={3} sm={3} lg={3}>
								<Button onSubmit={() =>onHide()} disabled={btnDisabled} className='btn btn-primary btn-radius'>Clear</Button>
						</Col> */}
					</Row>
				</form>
				</Container>
		</>
  )
}

export default TeamAdd