import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Resizer from 'react-image-file-resizer'
import { Row, Col } from 'react-bootstrap'
import stateCities from 'state-cities'
import AsyncSelect from "react-select/async";
import './styles/OrganizationManagementEditPage.scss'
import feather from 'feather-icons'
import {
    Logo,
    Button,
    Member,
    RemoveButton,
    MemberButton,
    Input,
} from '../../Admin/styles/OrganizationManagementEditPage.styled'
import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { AgencyOrganizationContext, reducer } from '../../../../stores/Agency/AgencyOrganizationFormStore'
import TextInput from '../../Admin/shared/TextInput'
import Select from '../../Admin/shared/Select'
import { StyledForm, H1 } from '../../Admin/styles/UserManagementEditPage.styled'
import TableWrapper from '../../Admin/shared/TableWrapper'
import Table from '../../Admin//shared/Table'

const redirectToOrganizationsManagement = () => {
   window.location.href = '/agencies/organizations'
}
const capitalize = (s) => {
    if(s !== '')
    {
        let str = s.toLowerCase();
        if (typeof str !== 'string') return s
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    return ''
}

const AgencyOrganizationManagement = ({
    organization,
    contact,
    members,
    avatar_url,
    jobs,
    lookups
}) => {
    const [compnanySizes, setCompnanySizes] = useState([])
    const [industries, setIndustries] = useState([])
    const [logo, setLogo] = useState(avatar_url)
    const [logoError, setLogoError] = useState(null)
    const [logoUrl, setLogoUrl] = useState(logo ? logo : null)
    const inputRef = useRef()
    const [states, setStates] = useState(stateCities.getStates())
    const [selectState, setSelectState] = useState(organization.region ? capitalize(organization.region) : '')
    const [citys, setCitys] = useState(null)
    const [selectCity, setSelectCity] = useState(organization.city ? capitalize(organization.city) : '')
    const [member_options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [selectedMemberList, setMembersList]  = useState([])
    const {cardinalMemebr, name,industry,company_size,description,country,region,city,status,member_organization_id} = organization
    const [account_manager_id, setAccountManagerId] = useState(organization.account_manager_id)
    const [accountManagers, setAccountManagers] = useState([])
    const [selectOrganizationType,setSelectOrganizationType] = useState(organization.organization_type)
    const [organizationTypeError,setOrganizationTypeError] = useState()
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [selectedRecruiter, setSelectedRecruiter] = useState()
    const [errorAgreementDate, setErrorAgreementDate] = useState()
    var date = new Date();
    let initialState = {
        ...initialState,
        ...{
            organization,
            name,
            industry,
            company_size,
            description,
            country,
            region,
            city,
            status,
            logo,
            cardinalMemebr,
            account_manager_id
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const [isLoadingOwner, setLoadingOwner] = useState(false)
    const [owner_options, setOptionOwner] = useState([])
    const [selectedOwner,setSelectedOwner] = useState(organization.owner_id)
    const [selectedOwnerOption,setSelectedOwnerOption] = useState([])
    useEffect(() => {
        setCompnanySizes([...lookups.company_sizes])
        setIndustries([...lookups.industries])
        setStates(stateCities.getStates())
        if(organization.owner_id > 0){
            fetch(`/agencies/organizations/fetch_owner/${organization.owner_id}`)
            .then((res) => res.json())
            .then((res) => {
            let {user} = res
            setSelectedOwnerOption({ value: user.id, label: user.first_name + ' ' + user.last_name });
            })
            .catch((err) => console.log("Request failed", err));
        }
    }, [])
    useEffect(() => {
        let cities = stateCities.getCities(selectState)
        cities && cities.sort(function (a, b) {
            return a.localeCompare(b); //using String.prototype.localCompare()
        })
        setCitys(cities)
    },[organization.region,states])

    useEffect(() => {
        feather.replace()
    })

    useEffect(() => {
      const url = `/agencies/organizations/account_managers?id=${organization.id}`
      fetch(url)
      .then(res => res.json())
      .then((result) => {
          setAccountManagers(result.members)
        },
        (error) => {
          console.log(error);
        }
      )
    }, [])
      
    const saveOrganization = async (newOrg) => {
     await  dispatch({ type: 'save-organization', value: newOrg,id:organization.id })
            // redirectToOrganizationsManagement()
        
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                150,
                150,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri)
                },
                'file',
                150,
                150
            )
    })

    const onSelectState = (event) => {
        setSelectState(event.target.value)
        setSelectCity('')
        let cities = stateCities.getCities(event.target.value)
        cities && cities.sort(function (a, b) {
            return a.localeCompare(b); //using String.prototype.localCompare()
        })
        setCitys(cities)
    }

    const onSelectCity = (event) => {
        setSelectCity(event.target.value)
    }

    const onAccountManager = (event) => {
        setAccountManagerId(event.target.value)
    }

    const addLogo = async (e) => {
        e.persist()
        const compressedImage = await resizeFile(e.target.files[0])
        setLogoError(null)
        setLogo(compressedImage)
        setLogoUrl(URL.createObjectURL(compressedImage))
    }

    const handleInputChange = (str) => {
      setInputValue(str)
      return str;
    }

    const handleSelectOption = (selectedOptions) => {
      setMembersList([...selectedOptions.map( ({value, label}) =>({user_id: value, organization_id: organization.id}) )])
    }

    const loadOptions = (inputValue, callback) => {
      setLoading(true)
      fetch(`/agencies/organizations/get_recruiter_users.json?search=${inputValue}`)
      .then((res) => res.json())
      .then((res) => {
        let {users} = res
        setLoading(false)
        setOptions([...users.map((user) => ({ value: user.id, label: user.email }))]);
      })
      .catch((err) => console.log("Request failed", err));

      callback(member_options);
    }

    const addRecruiter = async (event) => {
        event.preventDefault()
        dispatch({ type: 'add-recruiter',value:selectedMemberList, id:organization.id })
    }

    const removeOrganizationRecruiter = async (event, recruiter_org_id) => {
        event.preventDefault()
        setSelectedRecruiter(recruiter_org_id)
        setShowRemoveConfirmation(true)
    }

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'20em',maxWidth:'20em',minHeight:'40px' }),
          menu: (styles) => ({
            ...styles,
            zIndex: 999999999,
            texTransform: 'capitalize',
            width: '20em'
          }),
      };

    return (
        <AgencyOrganizationContext.Provider value={(state, dispatch)}>
        <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
            <H1>Update Organization</H1>
            <Formik
                initialValues={{
                    name: organization.name || '',
                    email: contact || '',
                    industry: organization.industry || '',
                    companySize: organization.company_size || '',
                    description: organization.description || '',
                    logo: logo,
                    status: organization.status,
                    country: organization.country,
                    city: organization.city,
                    region: organization.region,
                    // owner_id: organization.owner_id,
                    agreement_start_date: moment(organization?.agreement_start_date).format('YYYY-MM-DD'),
                    agreement_end_date: moment(organization.agreement_end_date).format('YYYY-MM-DD'),
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required(
                        'Organization Name is required'
                    ).trim(''),
                    country: Yup.string().required(
                        'Country is required'
                    )
                    .test(
                        'Country alphabets only',
                        'Country can only contain alphabet characters',
                        function (value){
                            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                            return regex.test(value)
                        }
                    ).trim('Country is required'),
                    industry: Yup.string()
                        .required('Industry is required')
                        .oneOf(
                            industries.map(({ key, value }) => key),
                            'Invalid Industry'
                        ),
                    companySize: Yup.string()
                        .min(0, 'Company size is required')
                        .oneOf(
                            compnanySizes.map(({ key, value }) => key),
                            'Invalid Company Size'
                        ),
                    description: Yup.string().required(
                        'Description is required'
                    ).trim('Description is required'),
                    agreement_start_date: Yup.string()
                        .when("selectOrganizationType", {
                            is: 'hourly',
                            then: Yup.string().required('Agreement start date is required')
                        }
                    )
                })}
                validate={(values) => {
                    const errors = {}
                    if (typeof logo !== 'string') {
                        if (!logo) {
                            errors.logo = 'Logo is required'
                        } else if (
                            ![
                                'image/jpg',
                                'image/jpeg',
                                'image/gif',
                                'image/png',
                            ].includes(logo.type)
                        ) {
                            errors.logo =
                                'Logo can only ne of type jpg, png, gif, jpeg'
                        }
                    }
                    if(selectState === ''){
                        errors.region = 'State is required'
                    }
                    if(selectCity === ''){
                        errors.city = 'City is required'
                    }
                    setLogoError(errors.logo)
                    return errors
                }}
                onSubmit={(values) => {
                    setOrganizationTypeError('')
                    if(cardinalMemebr === true && (selectOrganizationType === '' || selectOrganizationType == null)){
                        setOrganizationTypeError('Organization Type is required field if organization is Cardinal Talent Member organization')
                        return false
                    }
                    if((!(account_manager_id > 0)) && (selectOrganizationType === 'hourly' || selectOrganizationType === 'contingency')){
                        setOrganizationTypeError('Account Manager is required field if organization type is selected')
                        return false
                    }
                    if(values.agreement_end_date){
                        if(moment(values.agreement_start_date) > moment(values.agreement_end_date)){
                            setOrganizationTypeError("The Agreement Start Date should not be greater than the Agreement End Date.")
                            return false
                        }
                    }
                    if(selectOrganizationType === 'hourly' && (values.agreement_start_date == '' || values.agreement_start_date == 'Invalid date')){
                        setErrorAgreementDate('Agreement start date is required')
                        return false
                    }
                    saveOrganization({
                        name: values.name,
                        industry: values.industry,
                        company_size: values.companySize,
                        description: values.description,
                        country: values.country,
                        region: selectState,
                        city: selectCity,
                        status: values.status,
                        logo: logo,
                        cardinal_member: cardinalMemebr,
                        account_manager_id: account_manager_id,
                        subdomain: values.subdomain,
                        slug: values.slug,
                        agreement_start_date: values.agreement_start_date,
                        agreement_end_date: values.agreement_end_date,
                        monitor_mailbox: values.monitor_mailbox,
                        // owner_id: selectedOwner
                    })
                }}
            >
                <StyledForm>
                    <Row>
                        <Col xs={12} sm={12} lg={8}>
                            <H1>Organization Details</H1>
                            <Row>
                                <Col xs={12} sm={12} lg={3}>
                                    <div style={{ display: 'grid' }}>
                                        <Logo image={logoUrl}>
                                            {logoUrl
                                                ? ''
                                                : 'Upload Company Logo'}
                                        </Logo>

                                        {logoError && (
                                            <span
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'red',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {logoError}
                                            </span>
                                        )}
                                        <input
                                            type="file"
                                            name="logo"
                                            id="logo"
                                            ref={inputRef}
                                            hidden
                                            onChange={addLogo}
                                        />

                                        <Button
                                            style={{
                                                marginTop: '15px',
                                                maxWidth: '150px',
                                                marginBottom: '20px',
                                            }}
                                            type="button"
                                            onClick={() =>
                                                inputRef.current.click()
                                            }
                                        >
                                            Upload File
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} lg={9}>
                                  <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                         <TextInput
                                            label="Organization Name*"
                                            name="name"
                                            type="text"
                                            id="name"
                                            width="100%"
                                        />
                                    </Col>
                                  </Row>
                                    <Row>
                                        <Col xs={12} sm={12} lg={6}>
                                            <TextInput
                                                as="select"
                                                label="Industry*"
                                                name="industry"
                                                type="text"
                                                id="industry"
                                                width="100%"
                                            >
                                                <option value="">Select</option>
                                                {industries.map(
                                                    ({ key, value }) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {value}
                                                            </option>
                                                        )
                                                    }
                                                )}
                                            </TextInput>
                                        </Col>
                                        <Col xs={12} sm={12} lg={6}>
                                            <TextInput
                                                as="select"
                                                label="Company Size*"
                                                name="companySize"
                                                type="text"
                                                id="companySize"
                                                width="100%"
                                            >
                                                {compnanySizes.map(
                                                    ({ key, value }) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {value}
                                                            </option>
                                                        )
                                                    }
                                                )}
                                            </TextInput>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        label="Country*"
                                        name="country"
                                        type="text"
                                        id="country"
                                        style={{ width: 'auto' }}
                                    />
                                </Col>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        as="select"
                                        label="State*"
                                        name="region"
                                        type="text"
                                        id="region"
                                        style={{ width: 'auto' }}
                                        value={selectState}
                                        onChange={onSelectState}
                                    >
                                        <option value="">Select</option>
                                        {states &&
                                            states.map((val,index) => {
                                                let value = capitalize(val)
                                                if(!(value === "American samoa" || value === "Guam" || value === "Howland island" || value === "Baker island" || value === "Jarvis island" || value === "Johnston atoll" || value === "Kingman reef" || value === "Navassa island" || value === "Palmyra atoll" || value === "Wake island"))
                                                {
                                                    return (
                                                        <option
                                                            key={index}
                                                            data-code={
                                                                value
                                                            }
                                                            value={value}
                                                        >
                                                            {value}
                                                        </option>
                                                    )
                                                }
                                            })}
                                    </TextInput>
                                </Col>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        as="select"
                                        label="City*"
                                        name="city"
                                        type="text"
                                        id="city"
                                        style={{ width: 'auto' }}
                                        value={selectCity}
                                        onChange={onSelectCity}
                                    >
                                        <option value="">Select</option>
                                        {citys &&
                                            citys.map((val,index) => {
                                                let value = capitalize(val)
                                                return (
                                                    <option
                                                        key={index}
                                                        code={value}
                                                        value={value}
                                                    >
                                                        {value}
                                                    </option>
                                                )
                                            })}
                                    </TextInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} lg={12}>
                                    <TextInput
                                        label="Description*"
                                        name="description"
                                        type="text"
                                        id="description"
                                        width="100%"
                                    />
                                </Col>
                            </Row>
                           
                            <Row>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        label="Status*"
                                        name="status"
                                        type="text"
                                        id="status"
                                        width="100%"
                                        as="select"
                                    >   
                                       {organization.status === 'pending' && (
                                          <option value="pending">Pending</option>
                                       )}
                                        <option value="approved">Approved</option>
                                        <option value="expired">Expired</option>
                                        <option value="declined">Rejected</option>
                                    </TextInput>
                                </Col>

                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        as="select"
                                        label="Account Manager"
                                        name="account_manager_id"
                                        type="text"
                                        id="account_manager_id"
                                        style={{ width: 'auto' }}
                                        value={account_manager_id}
                                        onChange={onAccountManager}
                                    >
                                        <option value="">Select</option>
                                        {accountManagers &&
                                            accountManagers.map((value,index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        code={value?.key}
                                                        value={value?.value}
                                                    >
                                                        {value?.label}
                                                    </option>
                                                )
                                            })}
                                    </TextInput>
                                </Col>
                            </Row>
                            <Row>
                                {selectOrganizationType == "hourly" && (
                                    <Col xs={8} sm={8} lg={8}>
                                    <Row>
                                        <Col>
                                            <TextInput
                                                label="Agreement Start Date*"
                                                name="agreement_start_date"
                                                type="date"
                                                id="agreement_start_date"
                                                width="100%"
                                            />
                                            {errorAgreementDate && (<small style={{color: 'red'}}>{errorAgreementDate}</small>)}
                                        </Col>
                                        <Col>
                                            <TextInput
                                                label="Agreement End Date"
                                                name="agreement_end_date"
                                                type="date"
                                                id="agreement_end_date"
                                                width="100%"
                                            />
                                        </Col>
                                        
                                    </Row>
                                    </Col>
                                )}

                            </Row>
                        </Col>
                        <Col xs={12} sm={12} lg={4}>
                        
                            <H1>Members</H1>
                            <Row className="mb-4">
                                <Col xs={12} sm={12} lg={8}>
                                <AsyncSelect
                                  isMulti
                                  isLoading={isLoading}
                                  isClearable={true}
                                  cacheOptions
                                  loadOptions={loadOptions}
                                  defaultOptions
                                  onInputChange={handleInputChange}
                                  onChange={handleSelectOption}
                                  placeholder={'search for recruiter'}
                                />
                                   
                                </Col>
                                <Col xs={12} sm={12} lg={4} className="text-center">
                                    <Button
                                        className=""
                                        onClick={(e) =>addRecruiter(e)}
                                        disabled={!selectedMemberList.length > 0 ? true : false}
                                    >
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                            {members && 
                                members.map((member, key) => {
                                return (
                                    <Member key={key}>
                                        <Row>
                                            <Col xs={12} sm={12} lg={8}>
                                                <h5>
                                                    {member?.first_name && capitalize(
                                                        member.first_name
                                                    )}{' '}
                                                    {member?.last_name && capitalize(
                                                        member.last_name
                                                    )}
                                                </h5>
                                                <h6>{member.email}</h6>
                                            </Col>
                                            <Col xs={12} sm={12} lg={4}>
                                                <MemberButton>
                                                    <RemoveButton
                                                        onClick={(event) =>
                                                            removeOrganizationRecruiter(
                                                                event,
                                                                member.id
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </RemoveButton>
                                                    {showRemoveConfirmation && (
                                                        <SweetAlert
                                                            warning
                                                            showCancel
                                                            confirmBtnText="Yes, delete it!"
                                                            confirmBtnBsStyle="danger"
                                                            title="Are you sure?"
                                                            onConfirm={()=>{
                                                                    dispatch({ type: 'remove-recruiter-organization', id: selectedRecruiter })
                                                                    setShowRemoveConfirmation(false)
                                                                }
                                                            }
                                                            onCancel={()=>setShowRemoveConfirmation(false)}
                                                            focusCancelBtn
                                                            cancelBtnStyle={{textDecoration: 'none'}}
                                                        />
                                                    )}
                                                </MemberButton>
                                            </Col>
                                        </Row>
                                    </Member>
                                )
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ marginTop: '18px' }}>
                                <Button type="submit">Update</Button>
                                <Button
                                    type="button"
                                    onClick={() => window.history.back()}
                                    className="ml-sm-3"
                                >
                                    Go Back
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label style={{fontSize: '15px',color: 'red',marginTop: '15px'}}>{organizationTypeError}</label>
                        </Col>
                    </Row>
                </StyledForm>
            </Formik>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
            <H1>Organization Jobs</H1>
            <TableWrapper>
                {jobs && jobs?.length > 0 ? (
                    <Table
                    columNames={[
                        {
                            name: 'No.',
                            field: 'id',
                            editable: false,
                            type: 'text',
                            size: 7,
                        },
                        {
                            name: 'Job Name',
                            field: 'name',
                            editable: true,
                            type: 'text',
                            size: 25,
                        },{
                            name: 'Location',
                            field: 'location',
                            editable: false,
                            type: 'text',
                            size: 22,
                        },
                        {
                            name: 'Skills',
                            field: 'skills',
                            editable: false,
                            type: 'text',
                            size: 21,
                        },
                        {
                            name: 'Created On',
                            field: 'created_at',
                            editable: false,
                            type: 'text',
                            size: 15,
                        },
                        {
                            name: 'Status',
                            field: 'status',
                            editable: false,
                            type: 'text',
                            size: 10,
                        },
                    ]}
                    rowValues={jobs.map((o) => ({
                        ...o,
                        created_at: moment(o.created_at).format('MMM DD, YYYY'),
                        image_url: <img src={o.image_url} style={{height: '70px', width: '80px',borderRadius:'10px',marginLeft:'18px'}}/>,
                        status : capitalize(o.status)
                    }))}
                    activePage={0}
                    goToEditPage={(id) =>
                        (window.location.href = `/agencies/jobs/${id}/edit`)
                    }
                />
                ): 'This Organization has no jobs.'}
            </TableWrapper>
        </div>
        </AgencyOrganizationContext.Provider>
    )
}

export default AgencyOrganizationManagement