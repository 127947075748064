import React, { useState, useEffect, useContext, useReducer } from 'react';
import axios from 'axios';
import moment from 'moment'
import Text from '../../common/Styled components/Text';
import { Card } from 'react-bootstrap';
import Button from '../../common/Styled components/Button';
import { Box, Row, Container } from './styles/TimeSheet.styled';
import { Modal } from 'react-bootstrap'
import AddTimeEntry from '../Cardinal/TimeReportEmployees/AddTimeEntry'
import styles from './styles/TimeSheetPage.module.scss';
import SweetAlert from 'react-bootstrap-sweetalert';
import ArrowButton from '../../common/Styled components/ArrowButton';


function TimeEntryReport({user}) {
   
  const [isOpen, setIsOpen] = useState(false)
  const [alertClose, setAlertClose] = useState(false)
  const [loading, setLoading] = useState(true)
  const [meta, setMeta] = useState({})
  const [entries, setEntries] = useState([])
  const [show,setShow] = useState(false)
  const [method,setMethod] = useState('post')
  const [employee,setEmployee] = useState({user_id:user.id})
  const [dateRange, setDateRange] = useState({startDate:  moment().startOf('week').format('MMM DD, YYYY') ,endDate: moment().format('MMM DD, YYYY')})
  const [entry,setEntry] = useState({})
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [missingDays, setMissingDays]  = useState(0)
  const [totalHours, setTotalHours] = useState(0)
  const [entryDate, setEntryDate] = useState()
  const [applicantData, setApplicantData] = useState({})

  const fetchData = (startDate,endDate) => {
    const URL = `/users/${user.id}/timesheet.json?start_date=${startDate}&end_date=${endDate}`
    axios.get(URL).then(res => {
      setMeta(res.data.meta)
      //sorting list date wise entry
      let sortEntries = res.data.entries.sort((a,b) => new Date(Object.values(a)[2]) - new Date(Object.values(b)[2]))
      setEntries(sortEntries)
      setLoading(false)
      setTo(res.data.meta.to)
      setFrom(res.data.meta.from)
      setMissingDays(res.data.meta.missing_days)
      setTotalHours(res.data.meta.total_hours)
    });
  }

  useEffect(() => {
    fetchData(dateRange.startDate,dateRange.endDate)
  }, dateRange);
  
  const toggleDate = (action) => {
    if (action === 'previous') {
      const prevStartDate = moment(new Date(dateRange.startDate))
        .subtract(1, 'weeks')
        .startOf('week')
        .format('MMM DD, YYYY');
      const prevEndDate = moment(new Date(dateRange.endDate))
        .subtract(1, 'weeks')
        .endOf('week')
        .format('MMM DD, YYYY');
       setDateRange({startDate: prevStartDate  ,endDate: prevEndDate})
       fetchData(prevStartDate,prevEndDate)
    }
    if (action === 'next') {
      const nextStartDate = moment(new Date(dateRange.startDate))
        .add(1, 'weeks')
        .startOf('week')
        .format('MMM DD, YYYY');
      const nextEndDate = moment(new Date(dateRange.endDate))
        .add(1, 'weeks')
        .endOf('week')
        .format('MMM DD, YYYY');
      if(moment(nextStartDate) > moment(new Date())){
        setAlertClose(true)
      }else{
        setDateRange({startDate: nextStartDate  ,endDate: nextEndDate})
        fetchData(nextStartDate,nextEndDate)
      }
    }
  };

  const handleEdit = (entry) => {
    setMethod('post')
    setEntry({})
    if(entry.id){
        setMethod('put')
        setEntry(entry)
    }
    setApplicantData({ 'submission': entry.submissions, 'confirmInterview': entry.confirmed_interview, 'inMails': entry.inmail_sent})
    setEntryDate(entry.entry_date)
   
    setShow(true)
  };
  
 const onHide = () => {
    setEntry({})
    setMethod('post')
    setShow(false)
    fetchData(moment(dateRange.startDate).format('MMM,DD, YYYY'),moment(dateRange.endDate).format('MMM,DD, YYYY'))
  }


  return (
   <>
      { loading ? (
        <div className='container' style={{textAlign: 'center', marginTop: '100px'}}>
          <h2>Loading....</h2> 
          <div id="loading" />
        </div>
      ) : (
      <div  className={styles.cardMainDiv} >
        <Row gutterButtom aItems="center" jContent="space-between">
          <Text varient={'h1'} color="primary" size={'28px'}>
            Time Entry
          </Text>
        </Row>
        <Row gutterButtom aItems="center" jContent="space-between">
          <Box>
            <Text varient={'p'} color="primary" size={'20px'}>
              Missing Days: {missingDays}
            </Text>
          </Box>
          <Box className='d-none'>
            <Button>Export</Button>
          </Box>
        </Row>
        <Row gutterButtom aItems="center" jContent="space-between">
          <Box>
            <Text varient={'p'} color="primary" size={'20px'}>
              Weekly Hours: {totalHours}
            </Text>
          </Box>
          <Box aItems="center" jContent="space-between">
          
            <ArrowButton varient="left"  color="primary" size="16px" onClick={() => toggleDate('previous')} shadow/>
               <Text varient="p" color="primary" size={'14px'} givePadding style={{ minWidth: '200px' }}>
                    {`${dateRange.startDate} - ${dateRange.endDate}`}
                </Text>
                <ArrowButton varient="right" color="primary"  size="16px" onClick={() => toggleDate('next')} shadow
                />
                {alertClose &&
                    <SweetAlert
                    style={{ fontWeight:'bold' }} 
                    onConfirm={() => setAlertClose(false)} 
                    btnSize="sm" 
                    onCancel={() => setAlertClose(false)}
                    >
                    you can't view time entries ahead of current week
                    </SweetAlert>
                }

          </Box>
        </Row>
  
        <Row>
         
        {entries.map( (entry, index) => (
            <Row key={index}>
            <Card className={styles.cardContainer} onClick={() => handleEdit(entry)}>
                <Card.Header>
                <Row aItems="center" jContent="space-between">
                    <Box>
                    <Text>{moment(entry.entry_date).format('MMM DD, YYYY')}</Text>
                    </Box>
                    <Box>
                    <Text>Total Hrs: {entry?.total_time ? entry.total_time : 0}</Text>
                    </Box>
                    <Box>
                    <Text>Emails: {entry?.emails ? entry.emails : 0 }</Text>
                    </Box>
                    <Box>
                    <Text>Calls: {entry?.calls ? entry.calls : 0}</Text>
                    </Box>
                    <Box>
                    <Text>Submissions : {entry?.submissions ? entry.submissions :0}</Text>
                    </Box>
                    <Box>
                    <Text>Inmail Sent : {entry?.inmail_sent ? entry.inmail_sent : 0 }</Text>
                    </Box>
                    <Box>
                    <Text>Confirmed Interviews : {entry?.confirmed_interview ? entry.confirmed_interview : 0}</Text>
                    </Box>
                    <Box>
                    <Text onClick={() => setIsOpen(true)}><i data-feather="edit-2" /></Text>
                    </Box>
                </Row>
                </Card.Header>
            </Card>
        </Row>
      ))}
           
        </Row>
        <Row jContent="flex-end" className='d-none'>
          <Button size="small" color="success" onClick={() => setIsOpen(true)} shadow={true}>
            <h2 style={{ margin: '0px 1px' }}>+</h2>
          </Button>
        </Row>
  
       
        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            scrollable
            className='modal-entry-form'
            >
            <Modal.Header closeButton className='mt-2 mb-2'>
                <Modal.Title id="contained-modal-title-vcenter" >
                  { method ==='put' ?'Update' : '' } Time Entry
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddTimeEntry employee={employee} timeEntry={entry} onHide={onHide} method={method} isAdminForm={false} entryDate={entryDate} applicantData={applicantData}></AddTimeEntry>
            </Modal.Body>
    </Modal>
      </div>
      )
      }
   </>
  );
}

export default TimeEntryReport;
