import React, { useState, useEffect, useRef } from 'react'
import '../styles/AiInterview.scss'
import { Row,Col,Spinner,Modal } from 'react-bootstrap'
import moment from 'moment'

const InterviewReport = ({organization, interview, aiInterviewInvite, job, report, reportDetail}) => {
   console.log(report,reportDetail)
   const [expandedQuestion, setExpandedQuestion] = useState(null);
   const [videoUrl, setVideoUrl] = useState('');
   const [videoPlayShow, setVideoPlayShow] = useState(false);
   const [scroreData, setScroreData]= useState(JSON.parse(report?.score));
   const videoRef = useRef(null); // Create a ref for the video element
   
    // Reset video when modal is closed
    useEffect(() => {
        if (!videoPlayShow && videoRef.current) {
            videoRef.current.pause(); // Pause the video
            videoRef.current.currentTime = 0; // Reset the video to the start
        }
    }, [videoPlayShow]);

   const toggleAnswer = (index) => {
        if (expandedQuestion === index) {
        setExpandedQuestion(null); // Collapse if the same question is clicked
        } else {
        setExpandedQuestion(index); // Expand if a different question is clicked
        }
   };
    return (
        <>
            <div className="container-fluid ai-containter mb-10">
                <div className="d-flex justify-content-between align-items-center pb-3 mt-5">
                    <div className="d-inline-flex align-items-center">
                        { organization && organization?.image_url && organization?.image_url != null &&
                            <img
                                alt={ organization.name} src={ organization.image_url}
                                width="50" height="50"
                                className="d-inline-block align-top"
                            />
                        }
                        {' '}
                        <div className="ml-2">
                            <p className="mb-n1">{ organization.name}</p>
                            <a href={ organization.website_url} target='_blank' className="text-decoration-underline">{ organization.website_url }</a>
                        </div>
                    </div>
                </div>
                <div className="border rounded-lg p-4 shadow">
                    
                    <div className="d-flex justify-content-between align-items-center">
                        <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb breadcrumb-ai">
                                <li className="breadcrumb-item"><a href="/ai_interviews/create">AI Interviews</a></li>
                                <li className="breadcrumb-item"> <a href={`/ai_interviews/${interview.id}/detail`}>{ interview.interview_title }</a></li>
                                <li className="breadcrumb-item"> <a href={`/ai_interviews/${interview.id}/invites`}>Invited Candidates</a></li>
                                <li className="breadcrumb-item active" aria-current="page"> Report</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="justify-content-between align-items-right">
                    <div className="d-flex align-items-right  pt-5 pl-4">
                        <div><h4 style={{textTransform:'uppercase',fontWeight: '700'}}>{ interview.interview_title}</h4></div>
                    </div>
                </div>
                <Row>
                    <Col xl={6} md={6} lg={6} sm={12}> 
                        <div className="justify-content-between align-items-right pt-2">
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Name: </label>
                                <p className='pl-2'>{ aiInterviewInvite.candidate_name }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Email: </label>
                                <p className='pl-2'>{ aiInterviewInvite.candidate_email }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Phone Number: </label>
                                <p className='pl-2'>{ (aiInterviewInvite.phone_num != null && aiInterviewInvite.phone_num != 'null') ? aiInterviewInvite.phone_num : '-' }</p>
                            </div>

                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Interview Taken At:  </label>
                                <p className='pl-2'>{ (report.int_start_at != null) ? moment(report.int_start_at).format('MMM DD, YYYY h:mm A')  : '-' }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Interview Completed At: </label>
                                <p className='pl-2'>{ (report.int_end_at != null) ? moment(report.int_end_at).format('MMM DD, YYYY h:mm A')  : '-'}</p>
                            </div>
                        </div>
                        
                        <div className="justify-content-between align-items-right">
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}>Job Title: </label>
                                <p className='pl-2'>{ job.name }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}>Interview Type: </label>
                                <p className='pl-2'>{ interview.interview_type }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}>Skill: </label>
                                { ((job.skills).split(',')).map((value) =>{
                                        return <span key={value} className='skill-bubble-custom mr-1'>{value}</span>
                                    })}
                                    { interview.addition_skill != '' && interview.addition_skill != null &&
                                    <>
                                        { ((interview.addition_skill).split(',')).map((value) =>{
                                            return <span key={value} className='skill-bubble-custom mr-1'>{value}</span>
                                        })}
                                    </>
                                    }
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} md={6} lg={6} sm={12}> 
                            <div className='scrore-header-report'>
                                <p size="16px" height="27px" className='p-score-report'>
                                    Score
                                </p>
                            </div>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Accuracy:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_accuracy}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Coherence:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_coherence}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Application:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_application}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                             Sentiment:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_sentiment}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Technical Depth:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_technical_depth}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Ratting:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {report?.rating}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                    </Col>
                </Row>
                <hr></hr>
                <div className="justify-content-between align-items-right">
                    <div className="d-flex align-items-right  pt-5 pl-4">
                        <div><h4 style={{fontWeight: '700'}}>RESULT SUMMARY</h4></div>
                    </div>
                </div>
                <div className="justify-content-between align-items-right mt-4">
                    <div className="d-flex align-items-right flex-column pt-1 pl-5 mt-2">
                        <>
                        <label className='mb-2' style={{ fontWeight: 600, fontSize: '15px' }}>Questions / Answers</label>
                            {reportDetail.map((value, index) => (
                                <div key={index} className='answer-box'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span className='pt-2' style={{fontWeight: '510'}}>
                                        {index + 1}.{'  '}{value.question}
                                        </span>
                                        
                                    </div>
                                    <div className="mt-3 mb-2 ml-3 border rounded-lg p-2 shadow rating-main-box">
                                            <span className="text-sm font-medium"><b>AI rating :</b> <label className='pl-2 rating-sub-box mb-0'>{value.rating}</label></span>
                                    </div>
                                    <div className="pl-3" style={{ color: 'rgb(85 85 94/1)'}}>{value.ai_feedback}</div>
                                    <div className='pl-3'>
                                    {/*<div className='d-flex justify-content-between align-items-center'>
                                        <span className='pt-2' style={{fontSize: '15px', fontWeight: '550px'}}>Answer</span>
                                         <span
                                            className={`question-arrow ${expandedQuestion === index ? 'question-rotate' : ''}`}
                                            onClick={() => toggleAnswer(index)}
                                            style={{ cursor: 'pointer' }}
                                            >
                                            ^
                                        </span> 
                                    </div>*/}
                                    {/* {expandedQuestion === index && ( */}
                                        <div className='border rounded-lg p-3 shadow mt-3 mb-2' style={{background: '#fff'}}>
                                            <div className='pt-2'>
                                                <div className="recored-play-btn rounded-full bg-blue grid place-items-center" onClick={(()=>(setVideoUrl(value.video_url),setVideoPlayShow(true)))}><svg width="17" height="17" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: '6px'}}><path d="M22.5 11.4019C24.5 12.5566 24.5 15.4434 22.5 16.5981L4.49999 26.9904C2.49999 28.1451 -1.34201e-06 26.7017 -1.24107e-06 24.3923L-3.32543e-07 3.60769C-2.31596e-07 1.29829 2.5 -0.145083 4.5 1.00962L22.5 11.4019Z" fill="#3444DA"></path></svg> View recording of this portion</div>
                                            </div>
                                            <p className='pl-2 pt-3 capitalize-first-word'>{value.transcript_text}</p>
                                        </div>
                                    {/* )} */}
                                    </div>
                                </div>
                            ))}
                        </>
                    </div>
                </div>

            </div>
            <Modal
                show={videoPlayShow}
                onHide={() => setVideoPlayShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-between">
                            <h4 className="font-weight-bold mb-3">Video</h4>

                            <span onClick={() => setVideoPlayShow(false)} role="button">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '30px', height: '30px' }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                                <video ref={videoRef} className="embed-responsive-item" controls style={{height: 'auto',width: '100%'}}>
                                    <source src={videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
      );

}
export default InterviewReport