import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { H1, StyledForm } from './styles/UserManagementEditPage.styled'
import TextInput from './shared/TextInput'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import './styles/RecruiterManagment.scss'
import CompanyPlaceholderImage from '../../../../assets/images/talent_page_assets/company-placeholder.png'
import FileButton from '../../common/FileButton/FileButton'
import './styles/RecruiterManagment.scss'
import Button from './shared/Button'
import Select from 'react-select'

const redirectToRecruiterManagement = () => {
  window.location.href = '/admin/recruiter_management'
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const RecruiterManagementEditPage = ({ recruiter, edit }) => {
  const [resume, setResume] = useState(recruiter.resume)
  const [resumeError, setResumeError] = useState(null)
  const [organizations, setOrganizations] = useState([])
  const [designations, setDesignations] = useState([{ value: '', label: 'SELECT' }, { value: 'marketer', label: 'MARKETER' }, { value: 'recruiter', label: 'RECRUITER' }, { value: 'account_manager', label: 'ACCOUNT MANAGER' }])
  const [designation, setDesignation] = useState(recruiter?.designation)
  const [defaultDesignation, setDefaultDesignation] = useState(`recruiter.designation` && designations.filter((row) => recruiter.designation == row.value).map((row) => ({ value: row.value, label: row?.label?.toUpperCase() })))

  const save = async (newRec) => {
    const formData = new FormData()
    formData.append('user[email]', newRec.email)
    formData.append('user[first_name]', newRec.first_name)
    formData.append('user[last_name]', newRec.last_name)
    formData.append('user[location]', newRec.location)
    formData.append('user[phone_number]', newRec.phone_number)
    formData.append('user[designation]', newRec.designation)
    formData.append('user[linkedin_profile_url]', newRec.linkedin_profile_url)
    formData.append('user[recruiter_rate]', newRec.recruiter_rate)
    const url = `/admin/recruiters/${recruiter.id}`
    const response = await makeRequest(url, 'put', formData, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createSuccessMessage: (response) => response.data.message,
    })
    redirectToRecruiterManagement()

  }
  const approveRecruiter = async (id) => {
    const url = `/admin/recruiters/${id}/approve`
    await makeRequest(url, 'put', '', {
      createSuccessMessage: () => 'Recruiter approved successfully',
      onSuccess: () => {
        redirectToRecruiterManagement()
      },
    })
  }

  const rejectRecruiter = async (id) => {
    const url = `/admin/recruiters/${id}/reject`
    await makeRequest(url, 'put', '', {
      createSuccessMessage: () => 'Recruiter rejected successfully',
      onSuccess: () => {
        redirectToRecruiterManagement()
      },
    })
  }

  const getFetchOrganization = async () => {
    const url = `/admin/recruiter_organization/organization/${recruiter.id}`
    await makeRequest(url, 'get', '', {
      loadingMessage: 'Fetching...',
      createSuccessMessage: () => 'Recruiter organization get successfully',
      onSuccess: (response) => {
        setOrganizations(response.data.oranization)
      },
    })
  }

  useEffect(() => {
    getFetchOrganization()
  }, [setOrganizations])

  const addCommissionRecruiter = async (recruiter_id,type) => {
    const url = `/admin/recruiter_call/${recruiter_id}/${type}`
    await makeRequest(url, 'put', '', {
      createSuccessMessage: () => 'Recruiter approved successfully',
      onSuccess: () => {
        redirectToRecruiterManagement()
      },
    })
  }

  const downloadResume = () => { }
  return (
    <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
      <H1>Update Recruiter</H1>
      <Formik
        initialValues={{
          firstName: recruiter.first_name,
          lastName: recruiter.last_name,
          email: recruiter.email || '',
          location: recruiter.location || '',
          phoneNumber: recruiter.phone_number || '',
          resume: recruiter.resume,
          linkedin_profile_url: recruiter?.linkedin_profile_url || '',
          recruiter_rate: recruiter?.recruiter_rate
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .required('First Name is required')
            .test(
              'first name alphabets only',
              'First Name can only contain alphabet characters and one space in between if there are two words',
              function (value) {
                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                return regex.test(value)
              }
            ),
          lastName: Yup.string()
            .required('Last Name is required')
            .test(
              'last name alphabets only',
              'Last Name can only contain alphabet characters and one space in between if there are two words',
              function (value) {
                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                return regex.test(value)
              }
            ),
          email: Yup.string().required('Email is required'),
          phoneNumber: Yup.number()
            .typeError(
              'Invalid Phone number, please add numbers only'
            )
            .required('Phone number is required')
            .test(
              'phone number digits only',
              'Phone number must contain 10 digits only',
              function (value) {
                const regex = /^\d{10}$/g
                return regex.test(value)
              }
            ),
        })}
        // validate={(values) => {
        //     const errors = {}

        //     if (!resume) {
        //         errors.resume = 'Resume is required'
        //         setResumeError(errors.resume)
        //     }

        //     return errors
        // }}
        onSubmit={(values) => {
          save({
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            location: values.location,
            phone_number: values.phoneNumber,
            linkedin_profile_url: values?.linkedin_profile_url,
            resume: resume,
            designation: designation || '',
            recruiter_rate: values.recruiter_rate
          })
        }}
      >
        <StyledForm>
          <Row>
            <Col xs={12} sm={12} lg={8}>
              <H1>Recruiter Details</H1>
              <TextInput
                label="First Name*"
                name="firstName"
                type="text"
                id="firstName"
                width="100%"
              />
              <TextInput
                label="Last Name*"
                name="lastName"
                type="text"
                id="lastName"
                width="100%"
              />
              <TextInput
                label="Recruiter Email*"
                name="email"
                type="email"
                id="email"
                disabled={edit}
                width="100%"
              />
              <TextInput
                label="Recruiter Location"
                name="location"
                type="location"
                id="location"
                width="100%"
              />
              <TextInput
                label="Recruiter Phone number*"
                name="phoneNumber"
                type="phoneNumber"
                id="phoneNumber"
                width="100%"
              />
              <TextInput
                label="Recruiter Linkedin Profile Url"
                name="linkedin_profile_url"
                type="text"
                id="linkedin_profile_url"
                width="100%"
              />
              <TextInput
                label="Recruiter Rate(in $)"
                name="recruiter_rate"
                type="number"
                id="recruiter_rate"
                width="100%"
              />
              
              <div style={{ 'marginBottom': '10px' }}>
                <label>
                  Designation
                </label>
                <Select
                  defaultValue={defaultDesignation}
                  options={designations}
                  onChange={(event) => setDesignation(event.value)}
                  name="designation"
                />
              </div>
              {/* {recruiter.resume ? (
                <div>
                  <a
                    onClick={downloadResume}
                    style={{ cursor: 'pointer' }}
                  >
                    Download resume
                  </a>
                </div>
              ) : (
                <FileButton
                  type="button"
                  label="Upload PDF"
                  file={resume}
                  resumeError={resumeError}
                  setResumeError={setResumeError}
                  getFile={(file) => setResume(file)}
                />
              )} */}
            </Col>
            <Col xs={12} sm={12} lg={4}>
              {(recruiter && (recruiter?.is_commission_recruiter == null)) &&
              <>
                <div style={{ textAlign: 'center' }}>
                    <H1>100% Commision Recruiter</H1>
                    <Button
                      type="button"
                      onClick={() => addCommissionRecruiter(recruiter.id,'Yes')}
                      className="ml-sm-3"
                      variant="success"
                    >
                      Yes
                    </Button>
                    <Button
                      type="button"
                      onClick={() => addCommissionRecruiter(recruiter.id,'No')}
                      variant="danger"
                      className="ml-sm-3"
                    >
                      No
                    </Button>
                  </div>
                  <hr style={{ textAlign: 'center',marginBottom: '30px',marginTop: '35px' }}/>
                </>
              }
              <div style={{ textAlign: 'center',display: 'none' }}>
                <H1>Mark as on demand recruiter</H1>
                <Button
                  type="button"
                  onClick={() => approveRecruiter(recruiter.id)}
                  className="ml-sm-3"
                  variant="success"
                >
                  Approve
                </Button>
                <Button
                  type="button"
                  onClick={() => rejectRecruiter(recruiter.id)}
                  variant="danger"
                  className="ml-sm-3"
                >
                  Reject
                </Button>
              </div>
              <hr style={{ textAlign: 'center',marginBottom: '30px',marginTop: '35px',display: 'none' }}/>
              {(organizations && (organizations?.length > 0)) &&
                <div style={{ marginTop: '25px' }}>
                  <H1>Recruiter Organizations</H1>
                  <Row  >
                    {organizations &&
                      organizations.map((org, key) => {
                        let img = (org.organization?.image_url != null && org?.organization?.image_url != 'N/A') ? org?.organization?.image_url : CompanyPlaceholderImage
                        return (
                          <Col xs={12} sm={12} lg={6} key={key}>
                            <div className="recruiter-org">
                              <img src={img} style={{ height: '45px', width: '55px', borderRadius: '10px' }} />
                              <h6>
                                {capitalize(
                                  org?.organization?.name
                                )}{' '}
                              </h6>
                            </div>
                          </Col>

                        )
                      })
                    }
                  </Row>
                </div>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ marginTop: '18px' }}>
                <Button type="submit">Update</Button>
                <Button
                  type="button"
                  onClick={() => window.history.back()}
                  className="ml-sm-3"
                >
                  Go Back
                </Button>
              </div>
            </Col>
          </Row>
        </StyledForm>
      </Formik>
    </div>
  )
}

export default RecruiterManagementEditPage