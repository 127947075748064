import React, {useEffect, useRef, useState} from 'react';
import style from '../../../pages/EmployerDashboard/Table/styles/JobsTable.module.scss'
import feather from 'feather-icons'
import styles from '../AddFeedback/styles/ScheduledTable.module.scss'
import { Button, Row ,Col, Modal, Alert} from 'react-bootstrap'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist';
import ScheduledDeleteModal from './ScheduledDeleteModal';
import axios from 'axios';

function ViewInterviewFeedback
({
  interviewId,
  updateInterviewModal,
  myInterview,
  feedbackCancelModal,
  useOnClickOutside,
  setFeedbackCancelModal,
  handleUpdateModalFeedback,
  setFeedbackId,
  handleModalFeedback,
  handlefeedbackCancel,
  setDeleteModal,
  feedbackId,
  viewfeedbackModal,
  setViewFeedbackModal,
  setShowCancelModal,
  setModal,
  setUpdateInterviewModal,
  setUpdateFeedData,
  updateFeedData,
  errormessageType,
  message
 }) {
  const [FeedbackError, setFeedbackError ] = useState(false)
  const [feedbackValue, setFeedbackValue] = useState()
  const ref = useRef();
  const [data, setData] = useState()
  useEffect(() => {
    feather.replace()
})
const fetchData = async () => {
    const url = '/candidate_interview_feedbacks.json'
    const response = await makeRequest(
        `${url}`,
        'get',
        {params:{interview_id:interviewId}}
    )
    setData(response?.data)
}
 
const onUpdateFeedbackSubmit= async () => {
  const url = `interview_feedbacks/${feedbackId}`
  const CSRF_Token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')

  const payload = JSON.stringify({
      feedback: feedbackValue
  })
  if (feedbackValue){
    const res = await makeRequest(url, 'put', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (responce) => {
        if(responce?.success){
          setModal(false)
          setViewFeedbackModal(false)
          setUpdateInterviewModal(false)
        }
        
          return {
              message: responce?.msg
                  ? responce.msg
                  : "Feedback updated successfully",
              messageType: 'success',
              loading: false,
              autoClose: true,
          }
      },
      })}
      else{
    setFeedbackError(true)
  }
}

useEffect(() =>{
    fetchData()
 },[])

  return(
         <>
          {viewfeedbackModal &&
            <div ref={ref}>
              <div className={`${styles.feedbackModalDialog}`} style={{zIndex: '9999', overflowX: 'hidden', maxWidth: '500px', overflowY: 'auto'}}>
                <Row className={`${styles.feedbackModalHeader}`}>
                  <h4>Feedback</h4>
                  <div onClick={() => setModal(true)}>
                    <button className={`${styles.feedbackAddButton}`}>
                      <div style={{color: 'white'}}> + Add Feedback </div>
                    </button>
                    </div>
                    <button type="button" className={`${styles.feedbackCloseButton}`} onClick={() =>{setViewFeedbackModal(false)}}><span aria-hidden="true"><i className={styles.cancelIcon} onClick={() => setViewFeedbackModal(false)} data-feather="x" /></span></button>
                  </Row>
              
              {/* {isErrorMessage && <Alert className={`${styles.feedbackAlert}`} variant={errormessageType}>{message}</Alert>} */}
              <div style={{padding: '2px 30px'}}>
                <div>
                  {data?.data?.length > 0 && 
                    <Row className={ style.tableHeadingText + ' d-flex align-items-center mt-3'}>
                      <Col xs={1} className="d-flex align-items-start mt-3">
                          <span style={{ marginLeft: '-26px' }}>Serial Number</span>
                      </Col>
                      <Col className={`${style.jobsPadding}`} xs={10}>
                          <span>Feedback</span>
                      </Col>
                      {data?.data?.length > 0 ?
                       <Col className={`${style.jobsPadding}`} xs={1} >
                         <span>Action</span>
                      </Col>
                      : ''}
                    </Row>
                  }
                  {data?.data?.length > 0 ? data?.data?.map((data, index) => (
                    <Row className={`${styles.tableHeadingText} ${styles.tableRow} d-flex align-items-center`} style={{paddingBottom : myInterview === false ? '30px' :''}} key={index}>
                      <Col md={1} className={`${styles.serialNumber}`}> 
                          {index + 1}
                      </Col>
                      <Col md={9} className={`${styles.feedBackText}`}>
                          {data?.feedback}
                      </Col>
                      {data ?
                        <Col className={`${styles.action}`} xs={2}>
                            <a
                            onClick={() => {
                              setUpdateInterviewModal(true)
                              handleUpdateModalFeedback(data?.feedback)
                              setFeedbackId(data.id)
                              console.log(data, 'data')
                              setUpdateFeedData(data?.feedback)
                            }}
                            >
                            <i data-feather="edit-2"/>
                            </a>
                          <a
                              onClick={()=>{
                                setFeedbackCancelModal(index)
                                setFeedbackId(data.id)
                              }}
                          >
                            <i data-feather="trash-2"/>
                          </a>
                        </Col>
                      : ''}  
                      <ScheduledDeleteModal
                      index={index}
                      feedbackCancelModal={feedbackCancelModal}
                      setFeedbackCancelModal={setFeedbackCancelModal}
                      handlefeedbackCancel={handlefeedbackCancel}
                      setDeleteModal={setDeleteModal}
                      feedbackId={feedbackId}
                      setShowCancelModal={setShowCancelModal}
                      />  
                    </Row> 
                    )) :
                      <div className={`${styles.feedbackNotAvaialble}`}>Feedback is not available!</div>
                  }
                </div>
                <Modal
            className="scheduleModal"
            show={updateInterviewModal}
            onHide={() => setUpdateInterviewModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            backdropClassName={styles.modalBackdrop}
            size="xl"
            style={{zIndex: '9999'}}
            centered
          >
            <Modal.Body>
              <div className={`${styles.feedbackModal}`} >
                {updateInterviewModal &&
                  <>
                    <h4 class="modalTitle">Update FeedBack</h4>
                    <div
                    onClick={() => setUpdateInterviewModal(false)}
                    className={styles.cancelFeedbackButton}
                  >    
                    <i className={styles.cancelIcon} data-feather="x" />
                  </div>
                    <div className={`${styles.modalFeedbackText}`}>
                      Please Enter Your Feedback Here
                    </div>
                    <textarea type="text" className={`${styles.feedbackInputBox}`}
                      onChange={(e)=>{ setFeedbackValue(e.target.value)
                        setFeedbackError(false)}}
                      placeholder="Write feeback here..."
                      defaultValue={updateFeedData}
                    />
                    <div className={`${styles.addModalFeedbackButton}`} onClick={onUpdateFeedbackSubmit}>
                      <div className={`${styles.addFeedbackText}`}> Update Feedback </div>
                    </div>
                    {FeedbackError && <p className={`${styles.feedbackUpdateError}`}>please update feedback</p>}
                  </>  
                }
              </div>
            </Modal.Body>
         </Modal>  
              </div>
            </div>
            </div> 
          } 
        </>
  )
}

export default ViewInterviewFeedback;