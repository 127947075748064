import React, { useEffect, useState, useRef } from 'react'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import Util from "../../../utils/util";
import Card from './shared/Card'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const EmailDatasOrganizationDateRange = ({currentUser,per_page, user_list, organization_list }) => {
	const queryParams = new URLSearchParams(window.location.search)
	const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
	const user_id = queryParams.get("user_id")
	const organization_id = queryParams.get("organization_id")
	const [userName, setUserName] = useState(user_list)
	const [organiztionName, setOrganizationName] = useState(organization_list.name)
	var active_page = window.sessionStorage.getItem('activePage')
	var query = window.sessionStorage.getItem('query')
	const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
	const [pageCount, setPageCount] = useState(0)
	const [perPage, setPerPage] = useState(per_page)
	const [tableDatas, setTableDatas] = useState([])
	const [totalDatas,setTotalDatas] = useState(0)
	const [currentCounts,setCurrentCounts] = useState(0)
	const [startRecord, setStartRecord] = useState(0)
	const [exportDatas, setExportDatas] = useState([])
	const csvExportRef = React.useRef();
	const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
	const [loading, setLoading] = useState(true)
	const [sortField, setSortField] = useState('created_at')
	const [sortDirection, setSortDirection] = useState('DESC')
	const [footer, setFooter] = useState({})
	const tableHeaders = [
		{ label: 'Date', key: 'created_at' },
		{ label: 'Email Sent', key: 'email_sent' },
		{ label: 'Text Sent', key: 'text_sent' },
		{ label: 'Response Rate', key: 'response_rate' },
		{ label: '# Of Replies', key: 'of_replies'},
		{ label: 'InMail Sent', key: 'in_email_sent'},
		{ label: 'InMail Remaining', key: 'inmail_remaining'},
		{ label: 'Submission', key: 'candidate_submitted'},
		{ label: 'Interview', key: 'confirmed_interview'},
		{ label: 'Hire', key: 'hire'},
		{ label: 'Sent To Client', key: 'email_metric_sent_to_client'},
		{ label: 'Sent To AM', key: 'email_metric_sent_to_am'}
	]
	const fetchData = () => {
		setLoading(true)
		let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
		let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
		const url = `/admin/marketer_report_date_ranges.json`
		window.sessionStorage.setItem('activePage',activePage)
		makeRequest(`${url}?start_date=${start_date}&end_date=${end_date}&user_id=${user_id}&organization_id=${organization_id}&sortField=${sortField}&sortDirection=${sortDirection}`, 'get',  {params: {page: (activePage), query: query}}, {   
			contentType: 'application/json',
			loadingMessage: 'Fetching...',
			createSuccessMessage: (response) => response.data.message,
			onSuccess: (res) => {
				setTotalDatas(res.data.total_count)
				let sr_no = (activePage - 1) * res.data.per_page
				setTableDatas(
					res.data.email_datas_list.map((inMails) => (sr_no = sr_no + 1,{
						id: sr_no,
						organization_id: inMails.organization_id,
						created_at: moment(inMails?.created_at).format('MMM DD, YYYY'),
						email_sent: inMails?.email_sent,
						text_sent: inMails?.text_sent,
						of_replies: inMails?.of_replies,
						response_rate: inMails?.response_rate,
						in_email_sent: inMails?.in_email_sent,
						inmail_remaining: inMails?.inmail_remaining,
						candidate_submitted: inMails?.candidate_submitted,
						confirmed_interview: inMails?.confirmed_interview,
						hire: inMails?.hire,
						email_metric_sent_to_client: (inMails?.email_metric_sent_to_client)? 'Yes' : 'No',
						email_metric_sent_to_am: (inMails?.email_metric_sent_to_am)? 'Yes' : 'No',
					}))
				)
				setFooter({
					id:'Total',
					email_sent:res?.data?.total_values?.email_sent,
					hire:res?.data?.total_values?.hire,
					text_sent:res?.data?.total_values?.text_sent,
					of_replies:res?.data?.total_values?.of_replies,
					response_rate:res?.data?.total_values?.response_rate,
					in_email_sent:res?.data?.total_values?.in_email_sent,
					inmail_remaining:res?.data?.total_values?.inmail_remaining,
					candidate_submitted:res?.data?.total_values?.candidate_submitted,
					confirmed_interview:res?.data?.total_values?.confirmed_interview,
				})
				setPageCount(res.data.total_pages)
				setCurrentCounts(res.data.current_counts)
				setStartRecord(activePage * res.data.per_page)
				setLoading(false)
			},
		})
	}
	useEffect(() => {
		fetchData()
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [activePage,dateRange,sortDirection,sortField])

	const handleDate = (date) => {
		setDateRange(date)
	}

	const exportDataMethod = async () => {
		let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
		let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
		const url = `/admin/marketer_report_date_ranges.json`
		const response = await makeRequest(
			`${url}?start_date=${start_date}&end_date=${end_date}&user_id=${user_id}&organization_id=${organization_id}&sortField=${sortField}&sortDirection=${sortDirection}`,
			'get',
			{}
		)
		setExportDatas(response?.data?.email_datas_list.map((data,key)=> ({
			created_at: moment(data?.created_at).format('MMM DD, YYYY'),
			email_sent: data?.email_sent,
			text_sent: data?.text_sent,
			of_replies: data?.of_replies,
			response_rate: data?.response_rate,
			in_email_sent: data?.in_email_sent,
			inmail_remaining: data?.inmail_remaining,
			candidate_submitted: data?.candidate_submitted,
			confirmed_interview: data?.confirmed_interview,
			hire: data?.hire,
			email_metric_sent_to_client: (data?.email_metric_sent_to_client) ? 'Yes' : 'No',
			email_metric_sent_to_am: (data?.email_metric_sent_to_am) ? 'Yes' : 'No',
			
		})))
		csvExportRef?.current?.link.click();
	}
	const columns = [
		{
			name: 'No.',
			selector: row => row.id,
			maxWidth: '3%',
			minWidth: '4%'
		},
		{
		  name: 'Date',
		  selector: row => row.created_at,
		  maxWidth: '0%',
		  minWidth: '7%',
		  wrap:true,
		  sortable: true,
		  sortField: 'created_at'
		},
		{
			name: 'Email Sent',
			selector: row => row.email_sent,
			minWidth: '10%',
			maxWidth: '7%',
			wrap:true,
			sortable: true,
			sortField: 'email_sent'
		},
		{
			name: 'Text Sent',
			selector: row => row.text_sent,
			maxWidth: '10%',
			minWidth: '7%',
			wrap:true,
			sortable: true,
			sortField: 'text_sent'
		},
		{
			name: 'Response Rate',
			selector: row => row.response_rate,
			maxWidth: '10%',
			minWidth: '8%',
			sortable: true,
			wrap:true,
			sortField: 'response_rate'
		},
		{
			name: '# Of Replies',
			selector: row => row.of_replies,
			maxWidth: '8%',
			minWidth: '8%',
			sortable: true,
			sortField: 'of_replies'
		},
		{
			name: 'InMail Sent',
			selector: row => row.in_email_sent,
			maxWidth: '10%',
			minWidth: '7%',
			sortable: true,
			sortField: 'in_email_sent'
		},
		{
			name: 'InMail Remaining',
			selector: row => row.inmail_remaining,
			maxWidth: '10%',
			minWidth: '7%',
			sortable: true,
			sortField: 'inmail_remaining'
		},
		{
			name: 'Submission',
			selector: row => row.candidate_submitted,
			maxWidth: '10%',
			minWidth: '7%',
			sortable: true,
			sortField: 'candidate_submitted'
		},
		{
			name: 'Interview',
			selector: row => row.confirmed_interview,
			maxWidth: '10%',
			minWidth: '7%',
			sortable: true,
			sortField: 'confirmed_interview'
		},
		{
			name: 'Hire',
			selector: row => row.hire,
			maxWidth: '10%',
			minWidth: '7%',
			sortable: true,
			sortField: 'hire'
		},
		{
			name: 'Sent To Client',
			selector: row => row.email_metric_sent_to_client,
			maxWidth: '7%',
			minWidth: '6%',
			sortable: false,
			sortField: 'email_metric_sent_to_client'
		},
		{
			name: 'Sent To AM',
			selector: row => row.email_metric_sent_to_am,
			maxWidth: '7%',
			minWidth: '6%',
			sortable: false,
			sortField: 'email_metric_sent_to_am'
		}
	]

	const handleSort = async (column, sortDirection) => {
		if(column.sortField != undefined && sortDirection != undefined){
				setSortField(column.sortField)
				setSortDirection(sortDirection)
		}
	}
	
	return (
		<>
		<Card>
			<div
				className="d-flex justify-content-between align-items-center w-100"
			>
				<P size="28px" height="38px" color="#1D2447">
				  Campaign Manager - Organization - Date Range Analytics
				</P>
				<div>
				<button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
					Export CSV
				</button>
				<a href={` /admin/marketer_organizations?user_id=${user_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}`} className={`ml-3 ${styles.exportCSVButton}`}>
					Back
				</a>
				</div>
			</div>
			<CSVLink
				className='hidden'
				data={exportDatas} 
				headers={tableHeaders}
				filename={"MarketerDateRangeReport.csv"}
				ref={csvExportRef}
			>
			</CSVLink>
			<Row className="w-100" style={{ marginBottom: '10px', marginTop: '20px'}}>
				<Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
						{totalDatas > 0 && loading == false &&
								<DisplayPagination>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalDatas}</DisplayPagination>
						 }
				</Col>
				<Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
					<div style={{display: 'grid',marginLeft:'30px'}}>
							<label>
								<b>Campaign Manager</b> 
							</label>
							<p>{userName}</p>
					</div>
					<div style={{display: 'grid',marginLeft:'30px'}}>
							<label>
								<b>Organization Name</b>
							</label>
							<p>{organiztionName}</p>
					</div>
					<div style={{display: 'grid',marginLeft:'55px'}}>
						<label style={{fontWeight: 'bold'}}>
								Select Date Range
						</label>
						<DateRangePicker
								handleOnSubmit={handleDate}
								defaultDate={dateRange}
						/>
					</div>
				</Col>
			</Row>
			<DataTable
					persistTableHead={true}
					noDataComponent={<p className="text-center p-3">No record found</p>}
					columns={columns}
					data={tableDatas}
					progressPending={loading}
					progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
							<Spinner className='spinner' animation="border" role="status">
									<span className="sr-only">Loading...</span>
							</Spinner>
					</P>}
					onSort={handleSort}
					sortServer
					highlightOnHover
					pagination
					paginationServer
					paginationTotalRows={totalDatas}
					paginationPerPage={perPage}
					paginationComponentOptions={{
							noRowsPerPage: true
					}}
					onChangePage={page => setActivePage(page)}
					footer={footer}
			/>
		</Card>
		</>
	)
}


export default EmailDatasOrganizationDateRange
