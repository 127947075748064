import React, { useContext, useState , useEffect} from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { StoreDispatchContext } from '../../../stores/JDPStore'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { allStages } from '../../../misc/gconst'
import isEmpty from 'lodash.isempty'

import './styles/StageSelector.scss'

function StageSelector({
    allSelectedCandidates,
    refreshCandidates,
    jobId,
    stage,
    currentOrganization,
    setMoveToHired,
    changeStage,
    setGetStage,
    setMoveToArchived,
    setCheckInteviewStage,
    setInterviewTypeId
}) {
    const { state } = useContext(StoreDispatchContext)
    const selectedCandidates = isEmpty(allSelectedCandidates) ? state['selectedCandidates'] : allSelectedCandidates

    return (
            <Dropdown>
                <Dropdown.Toggle className="changeStageDropdown stageRight">
                    Move selected to
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {menuItemsForStage(stage).map((itemData,index) => (
                        <Dropdown.Item
                            key={index}
                            onClick={async (e) => {
                                e.preventDefault()
                                setInterviewTypeId(itemData.id)
                                let isInterview = (itemData.id == 'second_interview' || itemData.id == 'first_interview') ? true : false
                                let isSubmissionStage = (itemData.id == 'submitted' || itemData.id == 'reject') ? true : false
                                setGetStage(itemData.id)
                                {itemData.id == 'hired' ? setMoveToHired(true) :
                                 itemData.id == 'reject' && stage == 'hired' ? setMoveToArchived(true):
                                 await changeStage(
                                    itemData.id,
                                    jobId,
                                    selectedCandidates,
                                    state.user.id,
                                    isInterview,
                                    isSubmissionStage
                                )}

                                // refreshCandidates()
                            }}
                        >
                            {itemData.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
    )
}

function menuItemsForStage(activeStage) {
    return allStages.filter((stageData) => stageData.id !== activeStage)
}

export default StageSelector
