import React, { useEffect, useReducer, useState } from 'react'
import styles from './styles/CompanyProfilePage.module.scss'
import './styles/CompanyProfilePage.scss'
import { Row, Col,  Image } from 'react-bootstrap'
// import Modal from '../../common/Styled components/Modal'
import Modal from 'react-bootstrap/Modal'

import EditCompanyProfile from './EditCompanyProfile'
import { reducer, StoreCompanyContext } from '../../../stores/CompanyProfileStore'
import CompanyPlaceholderImage from '../../../../assets/images/talent_page_assets/company-placeholder.png'
import CarrerSection from './CarrerSection'

const getCompanySize = (param) => {
  switch(param) {
    case 1:
      return '0-1';
    case 2:
      return '2-10';
    case 11:
      return '11-50';
    case 51:
      return '51-200';
    case 201:
      return '201-500';
    case 501:
      return '501-1,000';
    case 1001:
      return '1,001-5,000';
    case 5001:
      return '5,001-10,000';
    case 10001:
      return '10,001+'
    default:
      return '0';
  }
}

const CompanyProfilePage = (props) => {
    const {organization, jobs, logo_url, isEmployer, currentUser, isProfileComplete} = props
    const {id, name, description, industry, min_size, max_size, city, region, company_size, country, website_url, account_manager_id} = organization
    const [open,setOpen]=useState(false)
    let initialState = { 
      ...initialState, 
      ...{id, name, description,industry, min_size, max_size, region, city, country, jobs, logo_url: logo_url, company_size, isEmployer, website_url, account_manager_id},
      ...{companySizes: [], industries: []}
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const format_logo_url = (avatar_url) => {
      if(typeof avatar_url == "object"){
        return(URL.createObjectURL(avatar_url))
      }
      return avatar_url
    }

    useEffect(()=>{
      const lookupsUrl = '/signup/lookups'
      fetch(lookupsUrl)
      .then(res => res.json())
      .then(
        (result) => {
          const {company_sizes, industries} = result
          dispatch({
            type: 'set_company_size_and_industry_types',
            value: {companySizes: [...company_sizes], industries: [...industries] }
          })
        },
        (error) => {
          console.log(error);
        }
      )
    },[])

    return (
      <StoreCompanyContext.Provider value={{ state, dispatch }}>
        <div className="candidate-view-profile-page">
            <div className={`${styles.profileHeading} jumbotron jumbotron-fluid`}>
                <div className="container">
                </div>
            </div>
            <div className="container">
                <div className={`${styles.profileImageSection}`}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Image
                                src={state.logo_url == null ? CompanyPlaceholderImage : format_logo_url(state.logo_url)}
                                className={`${styles.profileImage}`}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            {state.isEmployer && 
                              <button className={`${styles.editProfileButton}`} onClick={()=>setOpen(!open)}>
                                  Edit Organization's Profile
                              </button>
                            }
                            <Modal size='xl' show={open}>
                              <Modal.Body className="edit-organization-modal">  
                                <EditCompanyProfile 
                                  open={open}
                                  setOpen={setOpen}
                                  format_logo_url={format_logo_url}
                                  organization={organization}
                                />
                                </Modal.Body>
                            </Modal>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col xs={12}>
                        <h2 className={`${styles.profileName} mt-2`}>
                            {state.name}
                        </h2>
                    </Col>
                    <Col xs={12} className={`${styles.profileheading} d-flex`}>
                      <div className={styles.main_label}>
                        <span className="pr-4">{state.industry}</span>
                        <span style={{color:'#E3E7FE'}}>&#9679;</span>
                        <span className="pl-4 pr-4">{state?.company_size === 0 ? '0-1 Employee' : state?.company_size !== 0 ? `${getCompanySize(state.company_size)} Employees` : ''}</span>
                        { (state.city || state.region) &&
                          <>
                           <span style={{color:'#E3E7FE'}}>&#9679;</span>
                           <span className="pl-4 pr-4">{state.city}{(state.city && state.region) && ', ' }{state.region}</span>
                          </>
                        }
                        {state?.website_url && (<><span style={{color:'#E3E7FE'}}>&#9679;</span>
                        <span className="pl-4 pr-4"><a href={state.website_url}>Website</a></span></>)}
                      </div>
                    </Col>
                </Row>
                <Row className="mt-5 mb-5">
                  <Col xs={12} sm={12} md={12} lg={12}>
                      <div className={styles.career_label}>
                          <div>
                              <h4 className={`${styles.jobTitle}`}>Careers</h4>
                          </div>
                      </div>
                        
                      <CarrerSection 
                        jobs={jobs}
                        currentUser={currentUser}
                        isProfileComplete={isProfileComplete}
                      />  
                    </Col> 
                </Row>
            </div>
        </div>
      </StoreCompanyContext.Provider>
    )
}

export default CompanyProfilePage