import React, { useState, useEffect } from 'react'
import Card from './shared/Card'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import P from './shared/P'
import SearchBar from '../../common/SearchBar/SearchBar'
import moment from 'moment'
import {firstCharacterCapital} from '../../../utils/index'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import { Row,Col,OverlayTrigger,Spinner, Tooltip } from 'react-bootstrap'
import feather from 'feather-icons'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import   './styles/AutoCandidateSubmission.scss'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'

const ProcessCandidateSubmission = ({per_page}) => {
    var active_page = window.sessionStorage.getItem('process_activePage')
    var query = window.sessionStorage.getItem('process_query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [totalUsers, setTotalusers] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [users, setUsers] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [loading, setLoading] = useState(true)
    const [sortField, setSortField] = useState('created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(per_page || 25)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [showModal, setShowModal] = useState(false)
    const [messageValue, setMessageValue] = useState('')
    const [submissionId, setSubmissionId] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [historyShow, setHistoryShow] = useState(false)
    const [messages, setMessages] = useState([])
    const [loadingMessage, setLoadingMessage] = useState(false)
~    useEffect(() => {
        feather.replace()
    })

    const fetchData = async () => {
        setLoading(true)
        window.sessionStorage.setItem('process_activePage',activePage)
        const url = '/admin/process_candidate_submission.json'
        const response = await makeRequest(
            `${url}?page=${activePage}&search=${searchTerm}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalusers(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        
        setUsers(
            response.data.data.map((user,index) => (sr_no = sr_no + 1,{
                sr_no: sr_no,
                id: user.id,
                name: firstCharacterCapital(user.first_name) + ' ' + firstCharacterCapital(user.last_name),
                msg: user.msg,
                created_at: moment(user.created_at).format('MMM DD, YYYY'),
                msg_type: firstCharacterCapital(user.msg_type),
                msg_sentiment: user.msg_sentiment != null ? firstCharacterCapital(user.msg_sentiment) : '',
                actions: (<div className='w-100 d-inline-flex justify-content-around'>
                                <a  onClick={() => openSendBox(user.candidate_auto_submission_id)}  href={`javascript::void()`} title='Send Message' className='pl-4'><i data-feather="send" /></a>
                            </div>)
            }))
        )
        setCurrentCounts(response.data.current_counts)
        setStartRecord((activePage - 1) * response.data.per_page)
        setLoading(false)
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#F6F7FC',marginLeft:'10px',border: 'none',height:'40px' })
    };

    useEffect(() => {
        fetchData(),
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,sortDirection,sortField])


    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('process_query',value)
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const onMessageChange = (val) => {
        setMessageValue(val)
        console.log(messages)
    }

    const openSendBox = (id) => {
        setSubmissionId(id)
        setShowModal(true)
    }

    const handleShowChat = (id) => {
        setHistoryShow(true)
    }

    const handleMessagesModal = () => {
        setMessageValue('')
        setHistoryShow(false)
    }

    const onAutoMessage = async() => {
        setErrorMessage('')
        setLoadingMessage(true)
        const newMessage = {
            msg: messageValue,
            msg_type: 'receive',
        }
        setMessages([...messages, newMessage])
        if(messageValue != ''){
            const url = `/admin/auto_reply_demo`
                try {
                    const  data  = await makeRequest(url, 'post', {msg: messageValue}, {
                        contentType: 'application/json',
                        loadingMessage: 'Applying...',
                        createResponseMessage: (response) => {
                            return {
                                message:response.message,
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        createErrorMessage:(error)=>error.response.data.error
                    }).then((res)=>{
                    if(res.data.type == 'success'){
                        setMessageValue('')
                        const newMessage = {
                            msg: messageValue,
                            msg_type: 'receive',
                        }
                        const resMessage = {
                            msg: res.data.response.message,
                            msg_type: 'send',
                        }
                        setMessages([...messages, newMessage, resMessage])
                    }
                    })
                    
                } catch (error) {
                    console.log(error)
                }
        }else{
            console.log('Error Validation')
            setErrorMessage('Message is reuired field')
        }
    } 

    const onSendMessage = async() => {
        setErrorMessage('')
        setLoadingMessage(true)
        if(messageValue != '' && submissionId != ''){
            const url = `/admin/send_manual_msg`
                try {
                    const  data  = await makeRequest(url, 'post', {msg: messageValue, candidate_auto_submission_id: submissionId}, {
                        contentType: 'application/json',
                        loadingMessage: 'Applying...',
                        createResponseMessage: (response) => {
                            return {
                                message:response.message,
                                messageType: 'success',
                                loading: false,
                                autoClose: true,
                            }
                        },
                        createErrorMessage:(error)=>error.response.data.error
                    }).then((res)=>{
                    if(res.data.type == 'success'){
                            setLoadingMessage(false)
                            setMessageValue('')
                    }
                    })
                    
                } catch (error) {
                    console.log(error)
                }
        }else{
            console.log('Error Validation')
            setErrorMessage('Message is reuired field')
        }
    } 

    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '4%',
            minWidth: '4%',
        },
        {
            name: 'Candidate Name',
            selector: row => row.name,
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true,
        },
        {
            name: 'Message',
            selector: row => row.msg,
            maxWidth: '22%',
            minWidth: '22%',
            wrap:true,
        },
        {
            name: 'Message Type',
            selector: row => row.msg_type,
            maxWidth: '15%',
            minWidth: '15%',
            wrap:true,
        },
        {
            name: 'Message Sentiment',
            selector: row => row.msg_sentiment,
            maxWidth: '16%',
            minWidth: '16%',
        },
        {
            name: 'Created On',
            selector: row => row.created_at,
            maxWidth: '15%',
            minWidth: '15%',
        },
        {
            name: 'Action',
            selector: row => row.actions,
            maxWidth: '8%',
            minWidth: '8%',
        }
    ]

    const handleClose = () =>{setShowModal(false); setErrorMessage(''); setSubmissionId(''); setMessageValue('') }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Process Candidate Submission
                </P>
            </div>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '10px' }}
            >
                <Col xs={12} sm={12} lg={6} style={{float:'left'}}>
                    {totalUsers > 0 &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalUsers ? totalUsers : currentCounts } of {totalUsers}</DisplayPagination>
                    }
                </Col>
                <Col xs={12} sm={12} lg={3} style={{float:'left'}}>
                    <SearchBar
                        placeholder="Search candidate..."
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 1 ? fetchData() : setActivePage(1)
                            !searchTerm && window.sessionStorage.setItem('process_query','')
                        }}
                        onClick={(e) => setSearchTerm('')}
                    />
                </Col>
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    <a style={{color: '#007bff' }} onClick={() => handleShowChat()}  href={`javascript::void()`} title='View Messages' className='pl-1'><i data-feather="message-circle" /> Auto Respond</a>
                </Col>
               
            </div>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
               <Col  lg="12" md="12" sm="12" xs="12">
                    <DataTable
                        title={false}
                        persistTableHead={true}
                        noDataComponent={<p className="text-center p-3">No record found</p>}
                        columns={columns}
                        data={users}
                        progressPending={loading}
                        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                            <Spinner className='spinner' animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </P>}
                        onSort={handleSort}
                        sortServer
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationServerOptions={{persistSelectedOnSort: true, persistSelectedOnPageChange: true}}
                        paginationTotalRows={totalUsers}
                        paginationPerPage={perPage}
                        paginationComponentOptions={{
                            noRowsPerPage: true
                        }}
                        onChangePage={page => setActivePage(page)}
                        clearSelectedRows={toggledClearRows}
                    />
                </Col>
            </Row>
            <Modal
                show={showModal}
                onHide={handleClose}
                dialogClassName="center-modal"
                centered
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>Send Message</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: '5%',paddingLeft: '10%',paddingRight: '10%'}}>
                    <Row className="mb-2">
                        <span>Message :</span>
                    </Row>
                    <Row className="mb-2">
                        <textarea
                            className="flex-grow-1"
                            placeholder='Enter message here'
                            rows="4"
                            name="msg"
                            onChange={(e)=> onMessageChange(e.target.value)}
                        ></textarea>
                    </Row>
                        {errorMessage && errorMessage != '' &&
                            <Row className="">
                                <span style={{color:'red'}}>{errorMessage}</span>
                            </Row>
                        }
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary"  onClick={()=> onSendMessage()}>
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={historyShow}
                size="lg"
                dismissible
                onHide={handleMessagesModal}
                className="ats-border messageModel"
                backdrop="static"
                centered
                keyboard={true}
                onEscapeKeyDown={handleMessagesModal}
                >
                    <Modal.Header style={{minHeight: 'min-height: 92px'}} closeButton><Modal.Title>Auto Respond Using FAQ</Modal.Title></Modal.Header>
                    <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 modal-messages-scrollable">
                                
                                { messages && messages?.length > 0 && 
                                    <>
                                        <div >
                                        {   messages.map((msg) => {
                                                return (
                                                    <>
                                                        {msg.msg_type == 'send' &&
                                                            <div className="message-body mr-33">
                                                                <p>{msg.msg}</p>
                                                                <span className="time-right">{moment(msg.created_at).format('llll')}</span>
                                                            </div>
                                                        }

                                                        {msg.msg_type != 'send' &&
                                                            <div className="message-body darker ml-33">
                                                                <p>{msg.msg}</p>
                                                                <span className="time-right">{moment(msg.created_at).format('llll')}</span>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                        </div>
                                    
                                    </>
                                }
                                { loadingMessage == true && messages?.length == 0 &&
                                    <div className='loader-msg'>
                                        <Spinner className='spinner' animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='footer-custom container pr-3 pl-3'>
                        <Row className="mb-2">
                            <span>Message :</span>
                        </Row>
                        <Row className="mb-2">
                            <textarea
                                className="flex-grow-1"
                                placeholder='Enter message here'
                                rows="3"
                                name="msg"
                                onChange={(e)=> onMessageChange(e.target.value)}
                                value={messageValue}
                            ></textarea>
                        </Row>
                    </div>
                    <Button variant="primary" onClick={ () => onAutoMessage() }>
                        <i data-feather="send" /> <b>Send</b> 
                    </Button>          
                </Modal.Footer>
            </Modal>

        </Card>
    )
}

export default ProcessCandidateSubmission