import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import Table from '../shared/Table'

const commonColumns = [
  {
    name: 'Submitted',
    field: 'total_submissions',
    type: 'text',
  },
  {
    name: 'Confirmed Interview',
    field: 'confirmed_interviews',
    type: 'text',
  },
  {
    name: 'Interviewed',
    field: 'interviewed',
    type: 'text',
  },
  {
    name: 'Hired',
    field: 'hired',
    type: 'text',
  },
]
const detailColumns = [
  {
    name: 'No.',
    field: 'id',
    type: 'text',
  },
  {
    name: 'Date',
    field: 'entry_date',
    type: 'text',
  },
  {
    name: 'Hours',
    field: 'hours',
    type: 'text',
  },
  ...commonColumns,
]
const columns = [
  {
    name: 'No.',
    field: 'id',
    type: 'text',
  },
  {
    name: 'Name',
    field: 'name',
    type: 'link',
  },
  {
    name: 'Total Hr',
    field: 'hours',
    type: 'text',
  },
  {
    name: 'Missed Days',
    field: 'missedDay',
    type: 'text',
  },

  ...commonColumns,

  {
    name: 'Action',
    field: 'action',
    type: 'text',
  },
]

function replace(list, request) {
  const index = _.findIndex(list, function (r) {
    return r.field === request.field
  })
  list.splice(index, 1, request)
  return list
}

let clientColumns = columns.filter((column) => column.field !== 'missedDay')
clientColumns = replace(clientColumns, { field: 'name', name: 'Client Name' })
let recruiterColumns = [...columns]
recruiterColumns = replace(recruiterColumns, {field: 'name', name: 'Recruiter Name'})

const tableTypes = {
  client: clientColumns,
  recruiter: recruiterColumns,
  details: detailColumns,
}

function  EntryTable({ tableType, data=[], date , loading}) {
  const selectedColumns = tableTypes[tableType]
  const startDate = moment(date.startDate).format('MMM,DD, YYYY')
  const endDate = moment(date.endDate).format('MMM,DD, YYYY')
  return (
    <>
      {tableType === 'details' ? (
        <Table
          columNames={selectedColumns}
          rowValues={data?.map((child) => ({
            ...child,
          }))}
          activePage={0}
          loading={loading}
        />
      ) : (
        <Table
          columNames={selectedColumns}
          rowValues={data?.map((item) => ({
            ...item,
            name:
              tableType === 'client' ? (
                <a href={`/admin/timesheets/${item.id}.html?tab=client&start_date=${startDate}&end_date=${endDate}&name=${item.name}`}>{item.name}</a>
              ) : (
                <a href={`/admin/timesheets/${item.id}.html?start_date=${startDate}&end_date=${endDate}&name=${item.name}`}>{item.name}</a>
              ),
          }))}
          activePage={0}
          loading={loading}
        />
      )}
    </>
  )
}

export default EntryTable
