import React, { useState, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import './styles/NavbarCommunity.scss'
import '../../../../assets/v2/css/main.css'
// import avatarImg from '../../../../assets/v2/imgs/avatar/ava_1.png'
import cardinalImg from '../../../../assets/images/tab_icon.png'
import discoverImg from '../../../../assets/v2/imgs/discover.png'
import plusImg from '../../../../assets/v2/imgs/add.png'
import avatarImage from '../../../../assets/images/placeholder.png'
import Util from '../../../utils/util'
import { getCommunitiesList } from '../../api/recruiter.api'

const NavbarCommunity = ({
    userId,
    isTalent,
    isEmployer,
    avatar_url,
    organization,
    currentUser,
    memberOrganization,
    base_url,
    host_url,
    active_community,
    community_id,
    is_groups_url
}) => {
    console.log('NavbarCommunity');
    const currentPathname = window.location.pathname
    const search = location.search
    const [communitiesList, setCommunitiesList] = useState([])
    const [communityExists, setCommunityExists] = useState(false);
    const [communityType, setCommunityType] = useState('');
    const homeURl = (currentUser.role == 'talent') ? '/talent_home' : '/dashboard';
    var SideName = (is_groups_url)?'Cardinal Groups':'CardinalTalent';

    const fetchCommunities = async () => {
        try {
            const response = await getCommunitiesList(community_id);
            setCommunitiesList(
                response.communities.map((community) => ({
                    id: community.id,
                    name: community.name,
                    avatar_image: community.avatar_image,
                }))
            )
            setCommunityType(response.community_type)
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchCommunities()
    }, [])

    useEffect(() => {
        const communityExists = communitiesList.some(item => item.id == community_id);
        setCommunityExists(communityExists);
    }, [communitiesList, community_id]);


    const authLinks = (
        <>
            <li className="has-children">
                <div className={"user-account mr-20"}>
                    {(active_community) ?
                    <img className='mr-10' src={active_community?.avatar_image || avatarImage} alt="CardinalTalent" />
                    :
                    <img className='mr-10 cardinalLogo' src={cardinalImg} alt={SideName} />
                    }
                    <div className={"content"}>
                        <h4 className={"user-name2"}>{active_community?.name || ''}</h4>
                    </div>
                    <div className="ml-10 mt-10"><i className="fi-rr-arrow-up"></i><i className="fi-rr-arrow-down"></i></div>
                </div>
                <ul className="sub-menu">
                    <li>
                        <div onClick={() => window.location.href = "/communities/new"} className={"user-account"}>
                            <img src={plusImg} alt="Create a community" />
                            <div className={"content"}>
                                <h6>Create a community</h6>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div onClick={() => window.location.href = "/communities"} className={"user-account"}>
                            <img src={discoverImg} alt="Discover communities" />
                            <div className={"content"}>
                                <h6>Discover communities</h6>
                            </div>
                        </div>
                    </li>
                    {communitiesList && communitiesList.map((community, index) => {
                        return (<li>
                            <div onClick={() => window.location.href = "/communities/" + community.id} className={"user-account"}>
                                <img src={community.avatar_image || avatarImage} alt={community.name} />
                                <div className={"content"}>
                                    <h6>{community.name}</h6>
                                </div>
                            </div>
                        </li>)
                    })}
                </ul>
            </li>
            {(community_id > 0 && (communityExists || communityType == 'public')) && (
                <>
                    <li>
                        <a
                            href={`${'/communities/' + community_id}`}
                            className={`${currentPathname === '/communities'
                                ? 'active'
                                : ''
                                }`}
                        >
                            Community
                        </a>
                    </li>
                    <li>
                        <a
                           href={`${'/communities/' + community_id + '/classroom'}`}
                            className={`${currentPathname === '/classroom'
                                ? 'active'
                                : ''
                                }`}
                        >
                            Classroom
                        </a>
                    </li>
                    {(communityExists) && (
                    <li>
                        <a
                            href={`${'/communities/' + community_id + '/members'}`}
                            className={`${currentPathname === '/members'
                                ? 'active'
                                : ''
                                }`}
                        >
                            Members
                        </a>
                    </li>
                    )}
                    <li>
                        <a
                            href={`${'/communities/' + community_id + '/about'}`}
                            className={`${currentPathname === '/about'
                                ? 'active'
                                : ''
                                }`}
                        >
                            About
                        </a>
                    </li>
                </>
            )}
            {(community_id == 0) &&
                <>
                    <li>
                        <a href='/communities'className={`${currentPathname === '/communities'
                                ? 'active'
                                : ''
                                }`}>Communities</a>
                    </li>
                    
                    <li>
                        <a href='/communities/v5'className={`${currentPathname === '/communities/v5'
                                ? 'active'
                                : ''
                                }`}>Companies</a>
                    </li>
                </>
            }
        </>
    )
    return (
        <>
            <header className={"header sticky-bar"}>
                <div className={"container community_navbar"}>
                    <div className={"main-header"}>
                        <div className={"header-left"}>

                            <div className={"header-nav"}>
                                <nav className={"nav-main-menu d-none d-xl-block"}>
                                    <Nav className="align-items-center flex-wrap">
                                        <ul className={'main-menu'} style={{ display: 'flex', alignItems: 'center' }}>
                                            {authLinks}
                                        </ul>
                                    </Nav>
                                </nav>

                            </div>
                        </div>
                        <div className={"burger-icon burger-icon-white"}>
                            <span className={"burger-icon-top"}></span>
                            <span className={"burger-icon-mid"}></span>
                            <span className={"burger-icon-bottom"}></span>
                        </div>
                    </div>
                </div>
            </header>
            <div className={"mobile-header-active mobile-header-wrapper-style perfect-scrollbar"}>
                <div className={"mobile-header-wrapper-inner"}>
                    <div className={"mobile-header-top"}>
                        <div className={"user-account"}>

                            <img src={(avatar_url) ? avatar_url : avatarImage} alt={(currentUser.first_name) ? (currentUser?.first_name && Util.getFirstCharater(currentUser?.first_name)) + (currentUser?.last_name && Util.getFirstCharater(currentUser?.last_name)) : (currentUser?.email && Util.getFirstCharater(currentUser?.email))} />
                            <div className={"content"}>
                                <h6 className={"user-name"}>Hello, <span className={"text-brand"}>{(currentUser.first_name) ? (currentUser?.first_name && Util.getFirstCharater(currentUser?.first_name)) + (currentUser?.last_name && Util.getFirstCharater(currentUser?.last_name)) : (currentUser?.email && Util.getFirstCharater(currentUser?.email))}</span></h6>
                            </div>
                        </div>
                        <div className={"burger-icon burger-icon-white"}>
                            <span className={"burger-icon-top"}></span>
                            <span className={"burger-icon-mid"}></span>
                            <span className={"burger-icon-bottom"}></span>
                        </div>
                    </div>
                    <div className={"mobile-header-content-area"}>
                        <div className={"perfect-scroll"}>
                            <div className={"mobile-menu-wrap mobile-header-border d-xl-none"}>
                                <nav>
                                    <Nav>
                                        <ul className={'mobile-menu font-heading'}>
                                            {authLinks}
                                        </ul>
                                    </Nav>
                                </nav>
                            </div>
                            <div className={"mobile-account"}>
                                <h6 className={"mb-10"}>Your Account</h6>
                                <ul className={"mobile-menu font-heading"}>
                                    <li><a href={homeURl}>Jobs</a></li>
                                    <li><a href={`/users/profile/${userId}`}>Profile</a></li>
                                    <li><a href={"/account/setting"}>Account Settings</a></li>
                                    <li><a href="#" onClick={signOut}>Sign Out</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    function signOut() {
        localStorage.removeItem("user")
        if (localStorage.getItem("meet-sourcer-showed")) {
            localStorage.removeItem("meet-sourcer-showed")
        }
        fetch('/users/sign_out', { method: 'DELETE' })
            .then((res) => {
                window.location.href = '/communities'
            })
    }
}

export default NavbarCommunity
