import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Card from './shared/Card'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import P from './shared/P'
import SearchBar from '../../common/SearchBar/SearchBar'
import moment from 'moment'
import {firstCharacterCapital} from '../../../utils/index'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import Select from 'react-select'
import { Row,Col,OverlayTrigger,Spinner, Tooltip } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import feather from 'feather-icons'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import ActionBar from './shared/ActionBar'
import Button from 'react-bootstrap/Button'
import search_styles from '../../common/SearchBar/styles/SearchBar.module.scss'


const A = styled.a`
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #7288ff;
    cursor: pointer;
    margin-left: 16px;
`

const UserManagement = ({per_page}) => {
    var active_page = window.sessionStorage.getItem('user_activePage')
    var query = window.sessionStorage.getItem('user_query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [pageCountUsers, setPageCountUsers] = useState(0)
    const [currentUserCount, setCurrentUserCount] = useState(0)
    const [totalUsers, setTotalusers] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [users, setUsers] = useState([])
    const [addNewRow, setAddNewRow] = useState(null)
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [selected,setSelected] = useState({value: 'all', label: 'All'})
    const [selectedRole, setSelectedRole]  = useState('all')
    const [loading, setLoading] = useState(true)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showBulkRemoveConfirmation, setShowBulkRemoveConfirmation] = useState(false)
    const [showSweetAlertMessage,setShowSweetAlertMessage]=useState()
    const [sortField, setSortField] = useState('users.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(per_page || 25)
    const [selectUser,setSelectUser] = useState(false)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [bulkIds, setBulkIds] = useState([])
    const [username, setUserName] = useState('')
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
~    useEffect(() => {
        feather.replace()
    })

    const handleRowSelected = React.useCallback(state => {
        setToggleClearRows(false)
        setBulkIds(state.selectedRows.map((data) => {
            setUserName('User')
          return data.id
        }));
      }, []);

    const fetchData = async () => {
        setLoading(true)
        window.sessionStorage.setItem('user_activePage',activePage)
        const url = '/admin/users.json'
        const response = await makeRequest(
            `${url}?page=${activePage}&search=${searchTerm}&role=${selectedRole}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalusers(response.data.total_users)
        let sr_no = (activePage - 1) * response.data.per_page
        
        setUsers(
            response.data.users.map((user,index) => (sr_no = sr_no + 1,{
                sr_no: sr_no,
                id: user.id,
                name: firstCharacterCapital(user.first_name) + ' ' + firstCharacterCapital(user.last_name),
                email: user.email,
                organization_id: user.organization_id,
                role: firstCharacterCapital(user.role == 'text_messenger' ? 'Text Messenger' : user.role),
                created_at: moment(user.created_at).format('MMM DD, YYYY'),
                email_confirmed: user.email_confirmed ? 'Approved' : 'Pending',
                user_approved: user.user_approved !== null ? (user.user_approved ? 'Approved' : 'Reject') : 'Pending',
                actions: (<div className='w-100 d-inline-flex justify-content-around'>
                                    <a  href={`/admin/users/${user.id}`} className='pr-4'><i data-feather="edit-2" /></a>
                                    <a  onClick={() =>showConfirmDelete(user)}  href={`javascript::void()`} className='pr-4'><i data-feather="trash-2" /></a>
                                 </div>)
            }))
        )
       
        setPageCountUsers(response.data.total_pages_users)
        setCurrentUserCount(response.data.current_users_counts)
        setCurrentCounts(response.data.current_users_counts)
        setStartRecord((activePage - 1) * response.data.per_page)
        setLoading(false)
    }

    const handleSelectRole = (selectedval) =>{
        setActivePage(1)
        setBulkIds([])
        setSelectedRole(selectedval.value)
    }

    const roleOptions = [
        {value: 'all', label: 'All'},
        { value: 'admin', label: 'Admin' },
        { value: 'employer', label: 'Employer' },
        { value: 'recruiter', label: 'Recruiter' },
        { value: 'talent', label: 'Talent' },
        { value: 'sales', label: 'Sales' },
        {value: 'text_messenger', label: 'Text Messenger'}
    ]

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#F6F7FC',marginLeft:'10px',border: 'none',height:'40px', width:'250px'})
    };

    useEffect(() => {
        fetchData(),
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,selectedRole,selected,sortDirection,sortField])

    const deleteUser = async () => {
        setShowDeleteConfirmation(false)
        var id = selectUser.id
        setAddNewRow(null)
        if (id == -1) return
        const url = `/admin/users/${id}`
        const response = await makeRequest(url, 'delete', {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (response) => {    
                return {
                    message: response.message,
                    messageType: response.type,
                    loading: false,
                    autoClose: true,
                }
            },
            createErrorMessage: (e) => e.response?.message,
        })
        if(response?.data?.errorMsg){
            setShowSweetAlertMessage(response?.data?.errorMsg)
            setShowRemoveConfirmation(true)
        }
        if(response?.data?.success){
            setTimeout(()=>{
                fetchData()
            },2000)
        }

    }

    const destroyBulk = async () => {
        const url = `/admin/users/${bulkIds}`
        await makeRequest(url, 'delete','', {
            createResponseMessage: (response) => {    
            return {
                message: response.message,
                messageType: response.type,
                loading: false,
                autoClose: true,
            }
            },
            createErrorMessage: (e) => e.response?.message,
            onSuccess: (response) => {
                if(response.data.success){
                setBulkIds([])
                setToggleClearRows(true)
                setShowRemoveConfirmation(false)
                setTimeout(()=>{
                    window.location.reload()
                },2000)
            }
            },
        })
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('user_query',value)
    }

    const handleClose = () => {
        setShowRemoveConfirmation(false)
    }

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const onSalesCreate = () => {
        window.location.href = '/admin/users/create_sales'
    }
    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '6%',
            minWidth: '6%',
        },
        {
            name: 'User Name',
            selector: row => row.name,
            cell: (row) => <div title={row.name}>{row.name}</div>,
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true,
            sortable: true,
            sortField: 'users.first_name',
        },
        {
            name: 'Email',
            selector: row => row.email,
            cell: (row) => <div title={row.email}>{row.email}</div>,
            maxWidth: '20%',
            minWidth: '20%',
            wrap:true,
            sortable: true,
            sortField: 'users.email',
        },
        {
            name: 'Role',
            selector: row => row.role,
            cell: (row) => <div title={row.role}>{row.role}</div>,
            maxWidth: '10%',
            minWidth: '10%'
        },
        {
            name: 'Joined On',
            selector: row => row.created_at,
            cell: (row) => <div title={row.created_at}>{row.created_at}</div>,
            maxWidth: '12%',
            minWidth: '12%',
            wrap:true,
            sortable: true,
            sortField: 'users.created_at',
        },
        {
            name: 'User Status',
            selector: row => row.user_approved,
            cell: (row) => <div title={row.user_approved}>{row.user_approved}</div>,
            maxWidth: '12%',
            minWidth: '12%',
            wrap:true,
        },
        {
            name: 'Account Verification',
            selector: row => row.email_confirmed,
            cell: (row) => <div title={row.email_confirmed}>{row.email_confirmed}</div>,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'users.email_confirmed',
        },
        {
            name: 'Actions',
            selector: row => row.actions,
            maxWidth: '10%',
            minWidth: '10%',
        },
    ]

    const showConfirmDelete = (user) => {
        setSelectUser(user)
        setShowDeleteConfirmation(true)
    }
    return (
        <Card>
            {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    title={showSweetAlertMessage}
                    onConfirm={()=>handleClose(true)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />
            }
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    User Management
                </P>
            </div>
            {/* <div
                className="d-flex  align-items-center w-100 filter_ui_fix"
                style={{ marginBottom: '10px' }}
            > */}
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex align-items-end'>
                {/* <Col xs={12} sm={12} lg={2} md={2} style={{float:'left'}}> */}
                    {totalUsers > 0 &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalUsers ? totalUsers : currentCounts } of {totalUsers}</DisplayPagination>
                    }
                </Col>
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
                    <Select
                    defaultValue={selected}
                    options={roleOptions} 
                    onChange={handleSelectRole} 
                    placeholder={'Select Role'} 
                    styles={colourStyles}
                    />
                    <SearchBar
                        placeholder="Search users..."
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 1 ? fetchData() : setActivePage(1)
                            !searchTerm && window.sessionStorage.setItem('user_query','')
                        }}
                        onClick={(e) => setSearchTerm('')}
                    />
                
                    <Button onClick={onSalesCreate} className={search_styles.searchButton}>
                        Create Sales User
                    </Button>
                </Col>
            </Row>
            {/* </div> */}

            {showBulkRemoveConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={()=>(destroyBulk(),setShowBulkRemoveConfirmation(false),setToggleClearRows(true))}
                    onCancel={()=>setShowBulkRemoveConfirmation(false)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />
            )}
            
                <DataTable
                    className='abcd'
                    title={false}
                    persistTableHead={true}
                    noDataComponent={<p className="text-center p-3">No record found</p>}
                    columns={columns}
                    data={users}
                    progressPending={loading}
                    progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner className='spinner' animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </P>}
                    onSort={handleSort}
                    selectableRows
                    contextMessage={{singular: 'user', plural: 'users', message: 'selected'}}
                    onSelectedRowsChange={handleRowSelected}
                    sortServer
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationServerOptions={{persistSelectedOnSort: true, persistSelectedOnPageChange: true}}
                    paginationTotalRows={totalUsers}
                    paginationPerPage={perPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    responsive={true}
                    onChangePage={page => setActivePage(page)}
                    clearSelectedRows={toggledClearRows}
                /> 
            {showDeleteConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to delete?"
                    onConfirm={()=>(deleteUser(),setShowDeleteConfirmation(false))}
                    onCancel={()=>setShowDeleteConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this user!
                </SweetAlert>
            }
            { bulkIds && bulkIds.length > 0 && (
              <ActionBar
                data={bulkIds}
                setBulkIds={setBulkIds}
                username={username}
                setToggleClearRows={setToggleClearRows}
              >
                <OverlayTrigger
                  placement='top'
                  overlay={
                      <Tooltip>Remove ({`${bulkIds.length}`})</Tooltip>
                  }
                >
                  <a className='mt-auto mb-auto cursor-pointer' onClick={()=>setShowBulkRemoveConfirmation(true)}><i className='text-light  mr-3' data-feather="trash-2"></i></a>
                </OverlayTrigger>
              </ActionBar>
            )}
        </Card>
    )
}

export default UserManagement