import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Resizer from 'react-image-file-resizer'
import { Row, Col } from 'react-bootstrap'
import stateCities from 'state-cities'
import AsyncSelect from "react-select/async";
import Card from './shared/Card'
import Table from './shared/Table'
import TableWrapper from './shared/TableWrapper'
import P from './shared/P'
import { H1, StyledForm } from './styles/UserManagementEditPage.styled'
import './styles/OrganizationManagementEditPage.scss'
import TextInput from './shared/TextInput'
import Select from './shared/Select'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import feather from 'feather-icons'
import {
    Logo,
    Button,
    Member,
    RemoveButton,
    MemberButton,
    Input,
} from './styles/OrganizationManagementEditPage.styled'
import { reducer, OrganizationContext } from '../../../stores/Admin/OrganizationStore' 
import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const redirectToOrganizationsManagement = () => {
   window.location.href = '/admin/organizations'
}
const capitalize = (s) => {
    if(s !== '')
    {
        let str = s?.toLowerCase();
        if (typeof str !== 'string') return s
        return str?.charAt(0).toUpperCase() + str?.slice(1)
    }
    return ''
}

const OrganizationManagementEditPage = ({
    organization,
    contact,
    members,
    avatar_url,
    jobs,
    lookups
}) => {
    const [compnanySizes, setCompnanySizes] = useState([])
    const [industries, setIndustries] = useState([])
    const [logo, setLogo] = useState(avatar_url)
    const [logoError, setLogoError] = useState(null)
    const [stateError, setStateError] = useState(null)
    const [cityError, setCityError] = useState(null)
    const [logoUrl, setLogoUrl] = useState(logo ? logo : null)
    const inputRef = useRef()
    const [recruiterEmail, setRecruiterEmail] = useState()
    const [states, setStates] = useState(stateCities.getStates())
    const [selectState, setSelectState] = useState(organization.region ? capitalize(organization.region) : '')
    const [citys, setCitys] = useState(null)
    const [selectCity, setSelectCity] = useState(organization.city ? capitalize(organization.city) : '')
    const [tags, setTags] = useState([]);
    const [suggestions,setSuggestions] = useState([]);
    const [member_options, setOptions] = useState([])
    const [marketing_options, setOptionMarketing] = useState([])
    const [selectedMarketer,setSelectedMarketer] = useState(organization.marketer_id )
    const [inputValue, setInputValue] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isLoadingMarketing, setLoadingMarketing] = useState(false)
    const [selectedMemberList, setMembersList]  = useState([])
    const { name,industry,company_size,description,country,region,city,status,member_organization_id} = organization
    const [cardinalMemebr, setCardinalMemebr] = useState(member_organization_id ? true : false)
    const [account_manager_id, setAccountManagerId] = useState(organization.account_manager_id)
    const [accountManagers, setAccountManagers] = useState([])
    const [selectOrganizationType,setSelectOrganizationType] = useState(organization.organization_type)
    const [organizationTypeError,setOrganizationTypeError] = useState()
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [selectedRecruiter, setSelectedRecruiter] = useState()
    const [selectedMarketerOption,setSelectedMarketerOption] = useState([])
    const [selectAgreementStartDate, setSelectAgreementStartDate] = useState()
    const [selectAgreementEndDate, setSelectAgreementEndDate] = useState()
    const [errorAgreementDate, setErrorAgreementDate] = useState()
    const [showCardinalConfirmation, setShowCardinalConfirmation] = useState(false)
    
    var date = new Date();
    let initialState = {
        ...initialState,
        ...{
            organization,
            name,
            industry,
            company_size,
            description,
            country,
            region,
            city,
            status,
            logo,
            cardinalMemebr,
            account_manager_id
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const [isLoadingOwner, setLoadingOwner] = useState(false)
    const [owner_options, setOptionOwner] = useState([])
    const [selectedOwner,setSelectedOwner] = useState(organization.owner_id)
    const [selectedOwnerOption,setSelectedOwnerOption] = useState([])

    useEffect(() => {
        setCompnanySizes([...lookups.company_sizes])
        setIndustries([...lookups.industries])
        setStates(stateCities.getStates())
        if(organization.marketer_id > 0){
            fetch(`/admin/fetch_marketer/${organization.marketer_id}`)
            .then((res) => res.json())
            .then((res) => {
            let {user} = res
            setSelectedMarketerOption({ value: user.id, label: user.first_name + ' ' + user.last_name });
            })
            .catch((err) => console.log("Request failed", err));
        }
        if(organization.owner_id > 0){
            fetch(`/admin/fetch_owner/${organization.owner_id}`)
            .then((res) => res.json())
            .then((res) => {
            let {user} = res
            setSelectedOwnerOption({ value: user.id, label: user.first_name + ' ' + user.last_name });
            })
            .catch((err) => console.log("Request failed", err));
        }
    }, [])
    useEffect(() => {
        let cities = stateCities.getCities(selectState)
        cities && cities.sort(function (a, b) {
            return a.localeCompare(b); //using String.prototype.localCompare()
        })
        setCitys(cities)
    },[organization.region,states])

    useEffect(() => {
        feather.replace()
    })

    useEffect(() => {
      const url = '/organizations/cardinal_account_managers'
      fetch(url)
      .then(res => res.json())
      .then((result) => {
          setAccountManagers(result.members)
        },
        (error) => {
          console.log(error);
        }
      )
    }, [])
    
    const saveOrganization = async (newOrg) => {
        if(dispatch({ type: 'save-organization', value: newOrg,id:organization.id })){
            redirectToOrganizationsManagement()
        }
    }

    const onCheckCardinal = (check) => {
        if(!check){
            setShowCardinalConfirmation(true)
        }
        setCardinalMemebr(check)
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                150,
                150,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri)
                },
                'file',
                150,
                150
            )
    })

    const onSelectState = (event) => {
        setSelectState(event.target.value)
        setSelectCity('')
        let cities = stateCities.getCities(event.target.value)
        cities && cities.sort(function (a, b) {
            return a.localeCompare(b); //using String.prototype.localCompare()
        })
        setCitys(cities)
    }

    const onSelectCity = (event) => {
        setSelectCity(event.target.value)
    }

    const onAccountManager = (event) => {
        setAccountManagerId(event.target.value)
    }

    const addLogo = async (e) => {
        e.persist()
        const compressedImage = await resizeFile(e.target.files[0])
        setLogoError(null)
        setLogo(compressedImage)
        setLogoUrl(URL.createObjectURL(compressedImage))
    }

    const approve = async () => {
        dispatch({ type: 'approve-organization',id:organization.id })
    }
    const reject = async () => {
        dispatch({ type: 'reject-organization',id:organization.id })
        redirectToOrganizationsManagement()
    }

    const handleInputChange = (str) => {
      setInputValue(str)
      return str;
    }

    const handleSelectOption = (selectedOptions) => {
      setMembersList([...selectedOptions.map( ({value, label}) =>({user_id: value, organization_id: organization.id}) )])
    }

    const handleSelectMarketerOption = (selectedOptions) => {
      setSelectedMarketer(selectedOptions?.value || '')
      setSelectedMarketerOption(selectedOptions)
    }

    const handleSelectOwnerOption = (selectedOptions) => {
        setSelectedOwner(selectedOptions?.value || '')
        setSelectedOwnerOption(selectedOptions)
      }

    const loadOptions = (inputValue, callback) => {
      setLoading(true)
      fetch(`/admin/recruiters/get_recruiter_users.json?search=${inputValue}`)
      .then((res) => res.json())
      .then((res) => {
        let {users} = res
        setLoading(false)
        setOptions([...users.map((user) => ({ value: user.id, label: user.email }))]);
      })
      .catch((err) => console.log("Request failed", err));

      callback(member_options);
    }

    const loadOptionMarketing = (inputValue, callback) => {
        setLoadingMarketing(true)
        fetch(`/admin/users/fetch_users.json?search=${inputValue}&role=recruiter&designation=marketer`)
        .then((res) => res.json())
        .then((res) => {
          let {users} = res
          setLoadingMarketing(false)
          setOptionMarketing([...users.map((user) => ({ value: user.id, label: user.first_name + ' ' + user.last_name }))]);
        })
        .catch((err) => console.log("Request failed", err));
  
        callback(marketing_options);
    }

    const loadOptionOwner = (inputValue, callback) => {
        setLoadingOwner(true)
        fetch(`/admin/users/fetch_users.json?search=${inputValue}&role=employer`)
        .then((res) => res.json())
        .then((res) => {
          let {users} = res
          setLoadingOwner(false)
          setOptionOwner([...users.map((user) => ({ value: user.id, label: user.first_name + ' ' + user.last_name }))]);
        })
        .catch((err) => console.log("Request failed", err));
  
        callback(owner_options);
    }

    const addRecruiter = async (event) => {
        event.preventDefault()
        dispatch({ type: 'add-recruiter',value:selectedMemberList, id:organization.id })
    }

    const removeOrganizationRecruiter = async (event, recruiter_org_id) => {
        event.preventDefault()
        setSelectedRecruiter(recruiter_org_id)
        setShowRemoveConfirmation(true)
    }

    const onOrganizationTypeChange = (event) => {
        setSelectOrganizationType(event.target.value)
        setOrganizationTypeError('')
    }

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'20em',maxWidth:'20em',minHeight:'40px' }),
          menu: (styles) => ({
            ...styles,
            zIndex: 999999999,
            texTransform: 'capitalize',
            width: '20em'
          }),
      };

    return (
        <OrganizationContext.Provider value={ state, dispatch }>
        <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
            <H1>Update organization</H1>
            <Formik
                initialValues={{
                    name: organization.name || '',
                    email: contact || '',
                    industry: organization.industry || '',
                    companySize: organization.company_size || '',
                    description: organization.description || '',
                    logo: logo,
                    status: organization.status,
                    country: 'USA',
                    city: organization.city,
                    region: organization.region,
                    subdomain: organization.subdomain,
                    slug: organization.slug,
                    organization_type: organization.organization_type,
                    marketer_id: organization.marketer_id,
                    owner_id: organization.owner_id,
                    monitor_mailbox: organization.monitor_mailbox,
                    agreement_start_date: moment(organization?.agreement_start_date).format('YYYY-MM-DD'),
                    agreement_end_date: moment(organization.agreement_end_date).format('YYYY-MM-DD'),
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required(
                        'Organization Name is required'
                    ),
                    owner_id: Yup.string().required(
                        'Owner is required'
                    ),
                    country: Yup.string().required(
                        'Country is required'
                    ),
                    email: Yup.string().required(
                        'Organization Email is required'
                    ),
                    industry: Yup.string()
                        .required('Industry is required')
                        .oneOf(
                            industries.map(({ key, value }) => key),
                            'Invalid Industry'
                        ),
                    companySize: Yup.string()
                        .min(0, 'Company size is required')
                        .oneOf(
                            compnanySizes.map(({ key, value }) => key),
                            'Invalid Company Size'
                        ),
                    description: Yup.string().required(
                        'Description is required'
                    ),
                    agreement_start_date: Yup.string()
                        .when("selectOrganizationType", {
                            is: 'hourly',
                            then: Yup.string().required('Agreement start date is required')
                        }
                    )
                })}
                validate={(values) => {
                    const errors = {}
                    if (typeof logo !== 'string') {
                        if (!logo) {
                            errors.logo = 'Logo is required'
                        } else if (
                            ![
                                'image/jpg',
                                'image/jpeg',
                                'image/gif',
                                'image/png',
                            ].includes(logo.type)
                        ) {
                            errors.logo =
                                'Logo can only ne of type jpg, png, gif, jpeg'
                        }
                    }
                    if(selectState === ''){
                        errors.region = 'State is required'
                    }
                    if(selectCity === ''){
                        errors.city = 'City is required'
                    }
                    setLogoError(errors.logo)
                    return errors
                }}
                onSubmit={(values) => {
                    console.table(values)
                    setOrganizationTypeError('')
                    if(cardinalMemebr === true && (selectOrganizationType === '' || selectOrganizationType == null)){
                        setOrganizationTypeError('Organization Type is required field if organization is Cardinal Talent Member organization')
                        return false
                    }
                    if((!(account_manager_id > 0)) && (selectOrganizationType === 'hourly' || selectOrganizationType === 'contingency')){
                        setOrganizationTypeError('Account Manager is required field if organization type is selected')
                        return false
                    }
                    if(values.agreement_end_date){
                        if(moment(values.agreement_start_date) > moment(values.agreement_end_date)){
                            setOrganizationTypeError("The Agreement Start Date should not be greater than the Agreement End Date.")
                            return false
                        }
                    }
                    if(selectOrganizationType === 'hourly' && (values.agreement_start_date == '' || values.agreement_start_date == 'Invalid date')){
                        setErrorAgreementDate('Agreement start date is required')
                        return false
                    }
                    saveOrganization({
                        name: values.name,
                        industry: values.industry,
                        company_size: values.companySize,
                        description: values.description,
                        country: values.country,
                        region: selectState,
                        city: selectCity,
                        status: values.status,
                        logo: logo,
                        cardinal_member: cardinalMemebr,
                        account_manager_id: account_manager_id,
                        old_account_manager_id :organization.account_manager_id,
                        subdomain: values.subdomain,
                        slug: values.slug,
                        organization_type: (selectOrganizationType === '' || selectOrganizationType === 'null' || selectOrganizationType === null) ? '' : selectOrganizationType,
                        marketer_id: selectedMarketer,
                        agreement_start_date: values.agreement_start_date,
                        agreement_end_date: values.agreement_end_date,
                        monitor_mailbox: values.monitor_mailbox,
                        owner_id: selectedOwner
                    })
                }}
            >
                <StyledForm>
                    <Row>
                        <Col xs={12} sm={12} lg={8}>
                            <H1>Organization Details</H1>
                            <Row>
                                <Col xs={12} sm={12} lg={3}>
                                    <div style={{ display: 'grid' }}>
                                        <Logo image={logoUrl}>
                                            {logoUrl
                                                ? ''
                                                : 'Upload Company Logo'}
                                        </Logo>

                                        {logoError && (
                                            <span
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'red',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {logoError}
                                            </span>
                                        )}
                                        <input
                                            type="file"
                                            name="logo"
                                            id="logo"
                                            ref={inputRef}
                                            hidden
                                            onChange={addLogo}
                                        />

                                        <Button
                                            style={{
                                                marginTop: '15px',
                                                maxWidth: '150px',
                                                marginBottom: '20px',
                                            }}
                                            type="button"
                                            onClick={() =>
                                                inputRef.current.click()
                                            }
                                        >
                                            Upload File
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} lg={9}>
                                  <Row>
                                    <Col xs={12} sm={12} lg={6}>
                                         <TextInput
                                            label="Organization Name*"
                                            name="name"
                                            type="text"
                                            id="name"
                                            width="100%"
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} lg={6}>
                                        <label for="Owner" className="sc-dIvrsQ jOIyhG">Select Owner*</label>
                                            <AsyncSelect
                                                name="owner_id"
                                                id="owner_id"
                                                styles={colourStyles}
                                                isLoading={isLoadingOwner}
                                                isClearable={true}
                                                loadOptions={loadOptionOwner}
                                                defaultOptions={selectedOwner}
                                                value={selectedOwnerOption}
                                                onInputChange={handleInputChange}
                                                onChange={handleSelectOwnerOption}
                                                placeholder={'Search Name of Owner'}
                                            />
                                    </Col>
                                  </Row>
                                    <Row>
                                        <Col xs={12} sm={12} lg={6}>
                                            <TextInput
                                                as="select"
                                                label="Industry*"
                                                name="industry"
                                                type="text"
                                                id="industry"
                                                width="100%"
                                            >
                                                <option value="">Select</option>
                                                {industries.map(
                                                    ({ key, value }) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {value}
                                                            </option>
                                                        )
                                                    }
                                                )}
                                            </TextInput>
                                        </Col>
                                        <Col xs={12} sm={12} lg={6}>
                                            <TextInput
                                                as="select"
                                                label="Company Size*"
                                                name="companySize"
                                                type="text"
                                                id="companySize"
                                                width="100%"
                                            >
                                                {compnanySizes.map(
                                                    ({ key, value }) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {value}
                                                            </option>
                                                        )
                                                    }
                                                )}
                                            </TextInput>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        label="Country*"
                                        name="country"
                                        type="text"
                                        id="country"
                                        style={{ width: 'auto' }}
                                    />
                                </Col>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        as="select"
                                        label="State*"
                                        name="region"
                                        type="text"
                                        id="region"
                                        style={{ width: 'auto' }}
                                        value={selectState}
                                        onChange={onSelectState}
                                    >
                                        <option value="">Select</option>
                                        {states &&
                                            states.map((val,index) => {
                                                let value = capitalize(val)
                                                if(!(value === "American samoa" || value === "Guam" || value === "Howland island" || value === "Baker island" || value === "Jarvis island" || value === "Johnston atoll" || value === "Kingman reef" || value === "Navassa island" || value === "Palmyra atoll" || value === "Wake island"))
                                                {
                                                    return (
                                                        <option
                                                            key={index}
                                                            data-code={
                                                                value
                                                            }
                                                            value={value}
                                                        >
                                                            {value}
                                                        </option>
                                                    )
                                                }
                                            })}
                                    </TextInput>
                                </Col>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        as="select"
                                        label="City*"
                                        name="city"
                                        type="text"
                                        id="city"
                                        style={{ width: 'auto' }}
                                        value={selectCity}
                                        onChange={onSelectCity}
                                    >
                                        <option value="">Select</option>
                                        {citys &&
                                            citys.map((val,index) => {
                                                let value = capitalize(val)
                                                return (
                                                    <option
                                                        key={index}
                                                        code={value}
                                                        value={value}
                                                    >
                                                        {value}
                                                    </option>
                                                )
                                            })}
                                    </TextInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} lg={12}>
                                    <TextInput
                                        label="Description*"
                                        name="description"
                                        type="text"
                                        id="description"
                                        width="100%"
                                    />
                                </Col>
                            </Row>
                           
                            <Row>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        label="Status*"
                                        name="status"
                                        type="text"
                                        id="status"
                                        width="100%"
                                        as="select"
                                    >   
                                       {organization.status === 'pending' && (
                                          <option value="pending">Pending</option>
                                       )}
                                        <option value="approved">Approved</option>
                                        <option value="expired">Expired</option>
                                        <option value="declined">Rejected</option>
                                    </TextInput>
                                </Col>

                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        as="select"
                                        label="Account Manager"
                                        name="account_manager_id"
                                        type="text"
                                        id="account_manager_id"
                                        style={{ width: 'auto' }}
                                        value={account_manager_id}
                                        onChange={onAccountManager}
                                    >
                                        <option value="">Select</option>
                                        {accountManagers &&
                                            accountManagers.map((value,index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        code={value.key}
                                                        value={value.key}
                                                    >
                                                        {value.value}
                                                    </option>
                                                )
                                            })}
                                    </TextInput>
                                </Col>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        label="Sub Domain"
                                        name="subdomain"
                                        type="text"
                                        id="subdomain"
                                        width="100%"
                                    />
                                </Col>
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        label="Slug"
                                        name="slug"
                                        type="text"
                                        id="slug"
                                        width="100%"
                                    />
                                </Col>
                                <Col xs={4} sm={4} lg={4}>
                                         <Select
                                            label="Organization Type"
                                            name="organization_type"
                                            width="100%"
                                            id="organization_type"
                                            onChange={onOrganizationTypeChange}
                                            value={selectOrganizationType}
                                        >
                                            <option value="">Select Type</option>
                                            <option value="hourly">Hourly</option>
                                            <option value="contingency">Contingency</option>
                                        </Select>
                                </Col>
                                <Col xs={4} sm={4} lg={4}>
                                    <br></br>
                                    <Input
                                        value="Cardinal Talent Member"
                                        name="cardinal_member"
                                        type="checkbox"
                                        checked={cardinalMemebr}
                                        onChange={(e) =>
                                            onCheckCardinal(e.target.checked)
                                        }
                                        id="cardinal_member"
                                    />
                                    <label htmlFor="terms_and_policy">
                                        {' '}
                                        Cardinal Talent Member
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} sm={4} lg={4}>
                                <label for="Marketing" className="sc-dIvrsQ jOIyhG">Select Marketer</label>
                                        <AsyncSelect
                                        name="marketer_id"
                                        styles={colourStyles}
                                        isLoading={isLoadingMarketing}
                                        isClearable={true}
                                        loadOptions={loadOptionMarketing}
                                        defaultOptions={selectedMarketer}
                                        value={selectedMarketerOption}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectMarketerOption}
                                        placeholder={'Search Name of Marketer'}
                                        
                                        />
                                </Col>
                                {selectOrganizationType == "hourly" && (
                                    <Col xs={8} sm={8} lg={8}>
                                    <Row>
                                        <Col>
                                            <TextInput
                                                label="Agreement Start Date*"
                                                name="agreement_start_date"
                                                type="date"
                                                id="agreement_start_date"
                                                width="100%"
                                            />
                                            {errorAgreementDate && (<small style={{color: 'red'}}>{errorAgreementDate}</small>)}
                                        </Col>
                                        <Col>
                                            <TextInput
                                                label="Agreement End Date"
                                                name="agreement_end_date"
                                                type="date"
                                                id="agreement_end_date"
                                                width="100%"
                                            />
                                        </Col>
                                        
                                    </Row>
                                    </Col>
                                )}
                                <Col xs={4} sm={4} lg={4}>
                                    <TextInput
                                        label="Monitor Mailbox"
                                        name="monitor_mailbox"
                                        type="text"
                                        id="monitor_mailbox"
                                        width="100%"
                                        as="select"
                                    >
                                        <option value="">Select</option>
                                        <option value="Account Manager">Account Manager</option>
                                        <option value="Marketer">Marketer</option>
                                    </TextInput>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} lg={4}>
                            <H1>Contact person</H1>
                            <TextInput
                                label="Organization Manager*"
                                name="email"
                                type="email"
                                id="email"
                                width="100%"
                            />

                            {(organization.status === 'pending' || organization.status === 'expired') && (
                                <div className='text-center'>
                                    <H1>Pending organization request</H1>
                                    <Button
                                        type="button"
                                        onClick={approve}
                                        className="ml-sm-3"
                                        variant="success"
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={reject}
                                        variant="danger"
                                        className="ml-sm-3"
                                    >
                                        Reject
                                    </Button>
                                </div>
                            )}
                            <hr></hr>
                            <H1>Members</H1>
                            <Row className="mb-4">
                                <Col xs={12} sm={12} lg={8}>
                                <AsyncSelect
                                  isMulti
                                  isLoading={isLoading}
                                  isClearable={true}
                                  cacheOptions
                                  loadOptions={loadOptions}
                                  defaultOptions
                                  onInputChange={handleInputChange}
                                  onChange={handleSelectOption}
                                  placeholder={'search for recruiter'}
                                />
                                   
                                </Col>
                                <Col xs={12} sm={12} lg={4} className="text-center">
                                    <Button
                                        className=""
                                        onClick={(e) =>addRecruiter(e)}
                                        disabled={!selectedMemberList.length > 0 ? true : false}
                                    >
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                            {members && 
                                members.map((member, key) => {
                                return (
                                    <Member key={key}>
                                        <Row>
                                            <Col xs={12} sm={12} lg={8}>
                                                <h5>
                                                    {member?.first_name && capitalize(
                                                        member.first_name
                                                    )}{' '}
                                                    {member?.last_name && capitalize(
                                                        member.last_name
                                                    )}
                                                </h5>
                                                <h6>{member.email}</h6>
                                            </Col>
                                            <Col xs={12} sm={12} lg={4}>
                                                <MemberButton>
                                                    <RemoveButton
                                                        onClick={(event) =>
                                                            removeOrganizationRecruiter(
                                                                event,
                                                                member.id
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </RemoveButton>
                                                    {showRemoveConfirmation && (
                                                        <SweetAlert
                                                            warning
                                                            showCancel
                                                            confirmBtnText="Yes, delete it!"
                                                            confirmBtnBsStyle="danger"
                                                            title="Are you sure?"
                                                            onConfirm={()=>{
                                                                    dispatch({ type: 'remove-recruiter-organization', id: selectedRecruiter })
                                                                    setShowRemoveConfirmation(false)
                                                                }
                                                            }
                                                            onCancel={()=>setShowRemoveConfirmation(false)}
                                                            focusCancelBtn
                                                            cancelBtnStyle={{textDecoration: 'none'}}
                                                        />
                                                    )}
                                                </MemberButton>
                                            </Col>
                                        </Row>
                                    </Member>
                                )
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ marginTop: '18px' }}>
                                <Button type="submit">Update</Button>
                                <Button
                                    type="button"
                                    onClick={() => window.history.back()}
                                    className="ml-sm-3"
                                >
                                    Go Back
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label style={{fontSize: '15px',color: 'red',marginTop: '15px'}}>{organizationTypeError}</label>
                        </Col>
                    </Row>
                </StyledForm>
            </Formik>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
            <H1>Organization Jobs</H1>
            <TableWrapper>
                {jobs && jobs?.length > 0 ? (
                    <Table
                    columNames={[
                        {
                            name: 'No.',
                            field: 'id',
                            editable: false,
                            type: 'text',
                            size: 7,
                        },
                        {
                            name: 'Job Name',
                            field: 'name',
                            editable: true,
                            type: 'text',
                            size: 25,
                        },{
                            name: 'Location',
                            field: 'location',
                            editable: false,
                            type: 'text',
                            size: 22,
                        },
                        {
                            name: 'Skills',
                            field: 'skills',
                            editable: false,
                            type: 'text',
                            size: 21,
                        },
                        {
                            name: 'Created On',
                            field: 'created_at',
                            editable: false,
                            type: 'text',
                            size: 15,
                        },
                        {
                            name: 'Status',
                            field: 'status',
                            editable: false,
                            type: 'text',
                            size: 10,
                        },
                    ]}
                    rowValues={jobs.map((o) => ({
                        ...o,
                        created_at: moment(o.created_at).format('MMM DD, YYYY'),
                        image_url: <img src={o.image_url} style={{height: '70px', width: '80px',borderRadius:'10px',marginLeft:'18px'}}/>,
                        status : capitalize(o.status)
                    }))}
                    activePage={0}
                    goToEditPage={(id) =>
                        (window.location.href = `/admin/jobs/${id}/edit`)
                    }
                />
                ): 'This Organization has no jobs.'}
            </TableWrapper>
        </div>

            {showCardinalConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={()=>(setCardinalMemebr(false),setShowCardinalConfirmation(false))}
                    onCancel={()=> (setShowCardinalConfirmation(false),setCardinalMemebr(true))}
                    focusCancelBtn
                    >
                    Removing this organization from CT member organizations will automatically remove all CT recruiters from the members list.
                </SweetAlert>
            }

        </OrganizationContext.Provider>
    )
}

export default OrganizationManagementEditPage