import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import NavLink from 'react-bootstrap/NavLink'

import styles from './styles/Footer.module.scss'
const Poweredby = () => {
    return (
        <Row
            className={`justify-content-between align-items-center py-3 px-3 mx-0 ${styles.footerContainer}`}
        >
            <Navbar  className="d-flex flex-column py-0 w-100">
                <Navbar.Toggle />
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    className={`w-100 flex-column`}
                >
                    
                    <div className={`d-flex ${styles.privacyP}`}>
                        <NavLink
                            href="https://cardinaltalent.com"
                            className={styles.footerLink}
                        >
                            Powered by <b>Cardinal Talent</b>
                        </NavLink>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </Row>
    )
}

export default Poweredby
