import React, { useState, useEffect, useContext, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import styles from './styles/CandidateInfo.module.scss'
import isEmpty from 'lodash.isempty'
import Util from '../../../utils/util'
import { Card, Container, Col, Button, DropdownButton, Image, Row, Modal } from 'react-bootstrap'
import profileImage from '../../../../assets/images/icons/profile.png'
import Badge from "react-bootstrap/Badge";
import './styles/CandidateInfo.scss'
import SubmittedIcon from '../../../../assets/images/icons/submitted.png'
import _ from 'lodash'
import LinkedinIcon from '../../../../assets/images/icons/linkedin.png'
import PdfIcon from '../../../../assets/images/icons/pdf.png'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import moment from 'moment'
import feather from 'feather-icons'
import EmployerMail from '../../common/EmailClient/EmployerMail'

const MAX_SELECTION_LIMIT = 25
const CANDIDATES_PER_PAGE = 25
function CandidateInfo({
  loading,
  candidates,
  handleIntrested,
  multiSelectCheckbox,
  multiIntrestedCandidates,
  current_user,
  setSelectCandidate,
  checkedItems,
  setCheckedItems,
  page,
  check,
  is_email_config,
  showSubcriptionAlert,
  setShowSubcriptionAlert,
  setCheckIsEmailConfigured,
  check_plan,
  checkIsEmailConfigured
}) {

  const [submissionData, setSubmissionData] = useState({ active: false, id: null })
  const [expanded, setExpanded] = useState({company: false, position: false, location: false, industry: false})
  function activeSubmission(candidateId) {
    setSubmissionData({ active: true, id: candidateId })
  }
  useEffect(() => {
    feather.replace();
  }, []);

  if (loading) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>Loading....</h2>
        <div id="loading" />
      </div>
    </>
    )
  }
  if (isEmpty(candidates)) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>No employer found....</h2>
      </div>
    </>
    )
  }
  const handleJobFullName = (name) => {
    return name.length < 45 ? name : name.slice(0, 42) + '...'
  }
  const candidateClickHandler = (candidate) => {
  }
  return (
    <>
    <div className='d-flex'>
      <Table style={{marginLeft:'58px' , width:'95%'}} className={styles.candidateTable} responsive hover>
        <tbody >
          {Array.isArray(candidates) &&
            candidates.map((candidate, index) => {
              return (
                <CandidateRow
                  candidate={candidate}
                  idx={candidate}
                  key={index}
                  clickHandler={candidateClickHandler}
                  setSubmissionData={setSubmissionData}
                  submissionData={submissionData}
                  activeSubmission={activeSubmission}
                  handleIntrested={handleIntrested}
                  multiSelectCheckbox={multiSelectCheckbox}
                  multiIntrestedCandidates={multiIntrestedCandidates}
                  current_user={current_user}
                  setSelectCandidate={setSelectCandidate}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  check={check}
                  is_email_config={is_email_config}
                  showSubcriptionAlert={showSubcriptionAlert}
                  setShowSubcriptionAlert={setShowSubcriptionAlert}
                  check_plan={check_plan}
                  setCheckIsEmailConfigured={setCheckIsEmailConfigured}
                  checkIsEmailConfigured={checkIsEmailConfigured}
                />
              )
            })
          }
        </tbody>
      </Table>
    </div>
    </>
  )
}

function CandidateRow({
  candidate,
  idx,
  clickHandler,
  current_user,
  checkedItems,
  setCheckedItems,
  check,
  multiIntrestedCandidates,
  multiSelectCheckbox,
  is_email_config,
  showSubcriptionAlert,
  setCheckIsEmailConfigured,
  setShowSubcriptionAlert,
  check_plan,
  checkIsEmailConfigured
  }) {
  const [validationError, setValidationError] = useState({})
  const checked = multiIntrestedCandidates.length > 0 ? multiIntrestedCandidates.includes(idx) : false
  const [bulkSelect, setBulkSelect] = useState(checked)
	const [btnDisabled, setBtnDisabled] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showSentEmailModal, setShowSentEmailModal] = useState(false)
  const [emailData, setEmailData] = useState()
  const [selectuser, setSelectUser] = useState([])
  const [childCheckbox, setChildCheckbox] = useState(false);
  useEffect(() => {
    setChildCheckbox(check);
  },[check])
  let candidates =  candidate?.user_id?.split(",").map(Number);
  useEffect(() => {
    feather.replace()
  })
  const transformedArray = candidates?.map((element) => {
    // Code to transform each element goes here
    // For example, you can perform some operation on each element
    return element ;
  });
  
  function getSelectCell(candidate, col) {
    return (
      <td
        key={'checkbox' + candidate.id}
        style={{ width: '1rem' }}
        className={styles.td + ' align-middle'}
      >
      {!candidates?.includes(current_user?.id) &&
        <Form.Check
          className={`${styles.candidateCheckbox} check_${candidate.id}`}
          type="checkbox"
          value={candidate.firstName}
          name={candidate.lastName}
          checked={checked}
          onChange={handleCheckboxChange}
          onClick={(event) => event.stopPropagation()}
        />
      }
      </td>
    )
  }

  const handleCheckboxChange = (event) => {
    setBulkSelect(!checked)
    multiSelectCheckbox(candidate)
  }
  return (
    <>
      <Card key={candidate.id} className={styles.candidateProfileCard} >
        <Card.Body className='candidate-list'>
        {getSelectCell(candidate)}
          <div  >
            <Row style={{ marginBottom: '11px',justifyContent: 'space-between'}} >
              <div className='d-flex'>
                {candidate?.image_url ?
                  <img className={styles.candidateProfileImage} src={candidate.image_url ? candidate.image_url : profileImage} onClick={(e) => clickHandler(candidate)} />
                  : <CandidateAvatarIcon clickHandler={clickHandler} candidate={candidate} firstName={candidate?.first_name} lastName={candidate?.last_name} />
                }
                <div className={styles.cardBody + 'container'} >
                  <div className='d-flex justify-content-space-between w-100'>
                    <h5 className={styles.cardBodyName}>
                      <span>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)} {(candidate.company_name != '' ? '-': ' ')} {Util.handleUndefinedFullName(candidate.company_name)}</span>
                      {candidate.linkedin_profile_url &&
                        <a href={candidate.linkedin_profile_url} target="_blank">
                          <Image
                            src={LinkedinIcon}
                            style={{
                              width: '16px',
                              height: '16px',
                              marginLeft: '15px',
                              marginTop: '-1px'
                            }}
                          />
                        </a>
                      }
                    </h5>
                  </div>
                     <span>{candidate?.email}</span><br/>
                     <span>{`${candidate.industry ? candidate.industry  + '-'  : '' } ${candidate?.position}`}</span><br/>
                      {/* <span>{${`candidate.industry -`}} {candidate?.position}</span><br/> */}
                      <span>{candidate?.location}</span>
                  <h5 style={{ fontSize: '16px' }} onClick={(e) => clickHandler(candidate)}>{candidate?.title}</h5>
                </div>
              </div>
              <div>
              {candidates?.includes(current_user?.id) ? 
              <div className='d-flex'>

                <div className={styles.interviewType}>
                Email Sent
                   </div> 
                   <div style={{marginTop:'2px', cursor: 'pointer'} } onClick={(() =>
                   {
                    setShowSentEmailModal(true)
                    setEmailData(candidate)
                   }
                    )}>
                   <i data-feather="mail"  />
                   </div>
              </div>
              :
                <Button
                  variant='primary'
                  className={styles.button}
                   onClick={() =>{
                    if (is_email_config == null){
                    setCheckIsEmailConfigured(true)
                    }
                    if ((check_plan?.max_limit > check_plan?.used_limit) && is_email_config != null){
                    setShowModal(true)
                    }
                    else{
                    setShowSubcriptionAlert(true)
                    }
                    }
                  }
                >
                  Send Email
                </Button> }
              </div>
            </Row>
          </div>
                  </Card.Body>
                  <Modal
                  style={{padding:'20px'}}
                    // className="free-candidate"
                    show={showSentEmailModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                    
                >
                    <Modal.Header
                    onHide={() => setShowSentEmailModal(false)}
                    closeButton
                    >
                        <Modal.Title>{'Last Email'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <EmployerMail
                          emailData={emailData}
                          seeEmail={true}
                          emailClientId={'emailclientfor_' + 'scheduler'}
                          userId={current_user?.id}
                          isEmailConfigured={true}
                          userEmail={current_user?.email || ''}
                          showByDefault={true}
                          mailSentCallback={() => {}}
                          sendList={[candidate]}
                          embedded={true}
                          setShowModal={setShowModal}
                          setShowSentEmailModal={setShowSentEmailModal}
                      />
                    </Modal.Body>
                </Modal>
                <Modal
                  style={{padding:'20px'}}
                    // className="free-candidate"
                    show={showModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                    
                >
                    <Modal.Header
                    onHide={() => setShowModal(false)}
                    closeButton
                    >
                        <Modal.Title>{'Send mail'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <EmployerMail
                          emailClientId={'emailclientfor_' + 'scheduler'}
                          userId={current_user?.id}
                          isEmailConfigured={true}
                          userEmail={current_user?.email || ''}
                          showByDefault={true}
                          mailSentCallback={() => {}}
                          sendList={[candidate]}
                          embedded={true}
                          setShowModal={setShowModal}
                      />
                    </Modal.Body>
                </Modal>
      </Card>
      <br />
    </>
  )
}


export default CandidateInfo