import React, {
    useContext,
    useState,
    Fragment,
    useEffect,
    useRef,
} from 'react'
import feather from 'feather-icons'
import validator from 'validator'
import CandidateTwoImage from '../../../../assets/images/img_avatar.png'
import CloseButton from '../../common/Styled components/CloseButton'
import Input from '../../common/Styled components/Input'
import EditExperience from './EditExperience'
import EditEducation from './EditEducation'
import ProfileUploader from '../CandidateProfilePage/ProfileUploader'
import Alert from 'react-bootstrap/Alert'
import styles from './styles/CandidateViewProfilePage.module.scss'
import SelectRect from 'react-select'
import moment from 'moment'
import LinkedinIcon from '../../../../assets/images/icons/linkedin-icon.svg'
import {
    Wrapper,
    Row,
    Container,
    InputContainer,
    Button,
    Button2,
    ImageContainer,
    Skill,
    W4text,
    W8text,
    W5text,
    ScrollContainer,
} from './styles/CandidateViewProfile.styled'
import { UserProfileContext } from '../../../stores/UserProfileStore'
import Modal from '../../common/Styled components/Modal'
import CropImageModal from './CropImageModal'
import FileButton from '../../common/FileButton/FileButton'

function EditCandidateProfile(props) {

    const { open, setOpen, format_logo_url, profileFlag, resume_url, persons, full_user, audio_video_url } = props
    const [openInner, setOpenInner] = useState(false)
    const [isAddSkill, setIsAddSkill] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const { state, dispatch } = useContext(UserProfileContext)
    const [initialState, setState] = useState({ ...state })
    const [profile, setProfile] = useState()
    const [resume, setResume] = useState()
    const [resumeError, setResumeError] = useState(null)
    const [exprienceDateInvalid, setExprienceDateInvalid] = useState('')
    const [educationDateInvalid, setEducationDateInvalid] = useState('')
    const [source, setSource] = React.useState();
    const [fileError, setFileError] = useState('')
    const {
        currentUser,
        person,
        first_name,
        last_name,
        description,
        experiences,
        educations,
        skills,
        title,
        employer,
        school,
        degree,
        school_name,
        to,
        from,
        location,
        avatar_url,
        deleteExperienceId,
        deleteEducationId,
        skillset,
        tech_group,
        portfolio,
        authorization,
        transfer,
        states,
        compensation,
        sponsorship
    } = initialState
    const [validationErrors, setValidationErrors] = useState([])
    const [linkedin_profile_url, setLinkedUrl] = useState(currentUser?.linkedin_profile_url)
    const [calendly_link, setCalendlyLink] = useState(currentUser?.calendly_link)
    const [selectedGroup, setSelectedGroup] = useState(person?.tech_group || "")
    const [defaultGroup, setDefaultGroup] = useState((person?.tech_group && person?.tech_group != '' && person?.tech_group != null) ? (person?.tech_group.split(',')).map((res) => ({
        value: res,
        label: res,
    })) : '')
    
    useEffect(() => {
        feather.replace()
    })
    
    const data = [
        { type: 'first_name', label: 'First Name*', value: first_name },
        { type: 'last_name', label: 'Last Name*', value: last_name },
        { type: 'title', label: 'Current Position*', value: title },
        { type: 'employer', label: 'Current Company*', value: employer },
        { type: 'linkedin_profile_url', label: 'Linkedin Profile Url', value: linkedin_profile_url },
        { type: 'calendly_link', label: 'Calendly Link', value: calendly_link },
    ]
    const locationData = [
        { type: 'location', label: 'Location*', value: location },
    ]
    const handleAddSkill = () => {
        setIsAddSkill(!isAddSkill)
        if (isAddSkill && skillset.trim() !== '') {
            setState({
                ...initialState,
                skills: [...skills, skillset],
                skillset: '',
            })
        }
    }

    const removeSkill = (item) => {
        const skillArr = skills.filter((skill) => skill != item)
        setState({ ...initialState, skills: [...skillArr] })
    }
    
    const handleOnSave = async () => {
        if (setValidationMessages()) {
            setIsSubmit(true)
            dispatch({ type: 'update_user_profile', value: initialState, resume: resume, source: source, linkdin_url: linkedin_profile_url, calendly_link: calendly_link })
            setOpen(false)
        }
    }
    
    const setValidationMessages = () => {
        let errors = []
        const first_regex = /^[a-zA-Z'`._-]+$/
        const last_regex = /^[a-zA-Z'`._-]+$/
        const regex = new RegExp('www.linkedin.com');
        const regex_to = new RegExp('linkedin.com');
        if (profileFlag == 1 || profileFlag == 0) {
            if (skills && skills.length <= 1) {
                errors.push('Minimum 2 skills are required')
            }
            if(resume?.size > 10000000){
                errors.push( 'File is not allowed more then 10MB')
			}
            if (resumeError?.length || !resume?.type.includes('doc') && !resume?.type.includes('pdf') && resume?.type.includes('text') ) {
                errors.push( 'Please upload valid file format')
            }
            if (!first_regex.test((first_name.length && validator.trim(first_name)))) {
                errors.push('Please enter first name in valid format')
            } else {
                let index = errors.indexOf('Please enter first name in valid format')
                delete errors[index];
            }
            if (
                ( !(regex.test(linkedin_profile_url)) && !(regex_to.test(linkedin_profile_url)) && linkedin_profile_url?.length && validator.trim(linkedin_profile_url))){
                    errors.push('Invalid url, please add Linkedin url only')
                }
            if (!(title?.length && validator.trim(title)))
                errors.push('Current Position is required')
            if (!(employer?.length && validator.trim(employer)))
                errors.push('Current Company is required')
            if (!(location?.length && validator.trim(location)))
                errors.push('Location is required')
            if (!(description?.length && validator.trim(description)))
                errors.push('Description is required')
            if (!last_regex.test((last_name.length && validator.trim(last_name)))) {
                errors.push('Please enter last name in valid format')
            } else {
                let index = errors.indexOf('Please enter last name in valid format')
                delete errors[index];
            }
            if (selectedGroup.split(',').length > 5) {
                errors.push('Max 5 Tech Group are allowed')
            }
        }
        if (profileFlag == 2 || profileFlag == 0) {
            if (exprienceDateInvalid != '')
                errors.push(exprienceDateInvalid)
            if (educationDateInvalid != '')
                errors.push(educationDateInvalid)

            if (experiences.length == 0) {
                errors.push('Minimum 1 experience detail is required')
            }

            if (experiences && experiences.length > 0) {
                experiences.forEach((element) => {
                    if ((experiences[0].title == '' && experiences[0].company_name == '' && experiences[0].location == '' && experiences[0].start_date == '' && experiences[0].end_date == '' && experiences[0].present == '')) {
                        errors.push('Minimum 1 experience detail is required')
                    }
                })
                experiences.forEach((e) => {

                    if (e.company_name?.trim().length == 0) {
                        errors.push('Please enter company for the job experience.');
                        return;
                    }
                    if (e.title?.trim().length == 0) {
                        errors.push('Please enter title for the job experience.');
                        return;
                    }
                    if (e.start_date?.trim().length == 0) {
                        errors.push('Please enter start date for the job experience')
                        return;
                    }

                    if (e.start_date?.trim().length > 0 && e.end_date?.trim().length > 0 && moment(e.end_date).isValid() == true && moment(e.start_date).isValid() == true) {
                        if (moment(e.end_date).diff(moment(e.start_date)) <= 0) {
                            errors.push('The end date added for Experience must be greater than the start date.')
                            return;
                        }
                    }

                    if (e.end_date?.trim().length == 0 && e.present == false) {
                        errors.push('Invalid experience end date.')
                        return;
                    }

                    if (e.end_date?.trim().length > 0 && moment(e.end_date).isValid()) {
                        if (moment(e.end_date).year() <= moment().year()) {
                        if (moment(e.end_date).year() == moment().year() && moment(e.end_date).month() > moment().month()) {
                            errors.push('Invalid experience end date, It must not be after the current date.')
                            return;
                        }
                        } else {
                             errors.push('Invalid experience end date, It must not be after the current date.')
                             return;
                        }
                    }
                })
            }
        }
        if (profileFlag == 3 || profileFlag == 0) {
            if (educations?.length == 0) {
                errors.push('Minimum 1 education detail is required')
            }
            if (educations && educations.length > 0) {
                educations.forEach((element) => {
                    if ((educations[0].school_name == '' && educations[0].degree == '' && educations[0].from == '' && educations[0].to == '')) {
                        errors.push('Minimum 1 education detail is required')
                    }
                })

                educations.forEach((element) => {
                    if ((element.school_name?.trim() == '' || element.degree?.trim() == '')) {
                        errors.push('Invalid education detail')
                    }
                })

                educations?.map((e, i) => {
                    if (educations?.filter(s => s?.from === e?.from  && s?.to === e?.to && s?.school_name?.trim() === e?.school_name?.trim() && s?.degree?.trim() === e?.degree?.trim()).length > 1) {
                        if(errors.indexOf('Duplicate education details are not allowed') == -1)
                        errors.push('Duplicate education details are not allowed')
                    }
                })
                educations.forEach((element) => {
                    var startSplit = element?.from?.split('-')
                    var endSplit = (element?.to)?.split('-')
                    var currentYear = moment().format('YYYY');
                    if(parseInt(endSplit[0]) < parseInt(startSplit[0])){
                        errors.push('Invalid education detail')
                    }else if(parseInt(startSplit[0]) == parseInt(endSplit[0])){
                        if(parseInt(startSplit[1]) >= parseInt(endSplit[1])){
                            errors.push('Invalid education detail')
                        }
                    }
                    if((currentYear < parseInt(startSplit[0])) ||  (currentYear < parseInt(endSplit[0]))){
                        errors.push('Invalid education detail')
                    }
                })
            }
        }
        setValidationErrors(errors)
        if (errors.length > 0) {
            document.getElementById('div').scrollTop = 0
            return false
        } else {
            return true
        }
    }

    const handlePictureError = (error) => {
        let errors = []
        errors.push(error)
        setValidationErrors(errors)
    }
    const handlePicture = (file) => {
        if (file && file.size > 1024 * 1024 * 4) {
            handlePictureError('File size cannot exceed more than 4MB')
        } else {
            let errors = []
            setValidationErrors(errors)
            setProfile(format_logo_url(file))
        }
    }
    const saveCropImage = (upImg) => {
        setState({ ...initialState, avatar_url: upImg })
        setOpenInner(!openInner)
    }

    const handelInput = (event) => {
        const { name, value } = event.target
        setValidationMessages()
        if(name === 'calendly_link'){     
            setCalendlyLink(value)
        }
        if(name === 'linkedin_profile_url'){     
            setLinkedUrl(value)
        }else{
            setState({ ...initialState, [name]: value })            
        }
    }

    const handelSecondInput = (event) => {
        const { name, value } = event.target
        const newPerson = { ...person, [name]: value }
        setValidationMessages()
        setState({ ...initialState, person: newPerson })
    }

    const handleNewExperience = () => {
        const newExperience = {
            title: '',
            location: '',
            description: '',
            company_name: '',
            start_date: '',
            end_date: '',
            present: false,
        }
        setState({
            ...initialState,
            experiences: [...experiences, newExperience],
        })
    }

    const handleNewEducation = () => {
        const newEducation = {
            school_name: '',
            degree: '',
            from: '',
            to: '',
        }
        setState({
            ...initialState,
            educations: [...educations, newEducation],
        })
    }

    const handleExperience = (event, index) => {
        const { name, value } = event.target
        const oldExperiences = experiences
        const experience = oldExperiences[index]
        const newExperience = { ...experience, [name]: value }
        oldExperiences[index] = newExperience
        setState({ ...initialState, experiences: [...oldExperiences] })
    }

    const handleEducation = (event, index) => {
        const { name, value } = event.target
        const oldEducations = educations
        const education = oldEducations[index]
        const newEducation = { ...education, [name]: value }
        oldEducations[index] = newEducation
        setState({ ...initialState, educations: [...oldEducations] })
    }

    const handleExperienceDate = (date, index, type) => {
        let oldExperiences = experiences
        let experience = oldExperiences[index]
        let updateExperience = {}
        if (type === 'startDate') {
            updateExperience = { ...experience, start_date: date }
        } else {
            updateExperience = { ...experience, end_date: date }
        }

        oldExperiences[index] = updateExperience
        setState({ ...initialState, experiences: oldExperiences })
    }

    const handleEducationDate = (date, index, type) => {
        let oldEducations = educations
        let education = oldEducations[index]
        let updateEducation = {}
        if (type === 'startDate') {
            updateEducation = { ...education, from: date }
        } else {
            updateEducation = { ...education, to: date }
        }

        oldEducations[index] = updateEducation
        setState({ ...initialState, educations: oldEducations })
    }

    const handleRemoveExperience = (experienceId, removeIndex) => {
        let prevExperiences = [...experiences]
        if(experiences.length > 1){
            let newExperences = prevExperiences.filter(
                (_, index) => index != removeIndex
            )
            deleteExperienceId.push(experienceId)
            setState({ ...initialState, experiences: newExperences })
        }
        else{
            document.getElementById('div').scrollTop = 0
            setValidationErrors(['Minimum 1 experience detail is required'])
        }
    }

    const handleRemoveEducation = (educationId, removeIndex) => {
        let prevEducations = [...educations]
        if(educations.length > 1){
            let newEducations = prevEducations.filter(
                (_, index) => index != removeIndex
            )
            deleteEducationId.push(educationId)
            setState({ ...initialState, educations: newEducations })    
        }
        else{
            document.getElementById('div').scrollTop = 0
            setValidationErrors(['Minimum 1 education detail is required'])
        }
    }


    const add = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            setState({
                ...initialState,
                skillset: e.target.value,
            })
            handleAddSkill()
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const url = URL.createObjectURL(file);
        if(file?.size > 50000000){
            setFileError( 'The uploaded file should be less than 50MB')
            setSource(file);
            
        }
        else{
            if((!file.type.includes('mp4')) && !(file.type.includes('mp3')) && !(file.type.includes('audio')) && !(file.type.includes('video'))){
                setFileError("invalid format")
                setSource(file);
            }else{              
                setFileError('')    
                setSource(file);
                }
            }
      };

    const techTalentList = [
        { value: 'Accounting / Finance', label: 'Accounting / Finance' },
        { value: 'Account Executives', label: 'Account Executives' },
        { value: 'Admin', label: 'Admin' },
        { value: 'Customer Success', label: 'Customer Success' },
        { value: 'Designers', label: 'Designers' },
        { value: 'HR', label: 'HR' },
        { value: 'Legal', label: 'Legal' },
        { value: 'Marketers', label: 'Marketers' },
        { value: 'Recruiters', label: 'Recruiters' },
        { value: 'SDRs', label: 'SDRs' },
        { value: 'Software Engineers', label: 'Software Engineers' },
    ]

    const handleTechTalentList = (event) => {
        setSelectedGroup(event.map((techTalent) => techTalent.value).join(','))
        setState({ ...initialState, tech_group: event.map((techTalent) => techTalent.value).join(',') })
    };

    return (
        <Wrapper>
            <Container direction="column" style={{ height: '100%' }}>
                <Row direction="row" jContent="space-between">
                    <W4text size="32px" color="#1D2447" className={styles.EditTittle}>
                        Edit Profile
                    </W4text>
                    <CloseButton handleClick={() => setOpen(!open)} />
                </Row>

                <ScrollContainer className='w-100' id='div'>
                    {validationErrors.length > 0 && (
                        <Alert
                            variant="danger"
                            onClose={() => setValidationErrors([])}
                            dismissible
                        >
                            {validationErrors.map((error, idx) => (
                                <p key={idx} className="mb-0">
                                    {error}
                                </p>
                            ))}
                        </Alert>
                    )}

                    <Row direction="row" className={`${styles.editRow}`}>
                        {(profileFlag == 1 || profileFlag == 0) && (
                            <Container
                                direction="column"
                                jContent="space-between"
                                flex="2"
                            >
                                <W4text
                                    size="12px"
                                    color="#7A8AC2"
                                    style={{
                                        alignSelf: 'center',
                                        marginBottom: '8px',
                                    }}
                                >
                                    Profile Picture
                                </W4text>
                                <ImageContainer>
                                    <img
                                        src={
                                            avatar_url == null || avatar_url == ''
                                                ? CandidateTwoImage
                                                : format_logo_url(avatar_url)
                                        }
                                    />

                                </ImageContainer>
                                <div style={{ marginLeft: '12px' }}>
                                    <ProfileUploader
                                        onFileSelectSuccess={(file) => {
                                            handlePicture(file)
                                            setOpenInner(!openInner)
                                        }
                                        }
                                        onFileSelectError={({ error }) =>
                                            handlePictureError(error)
                                        }
                                        isProfilePicture={'profilePicture'}
                                    />
                                    {profile && (
                                        <Modal
                                            width={'50%'}
                                            isOpen={openInner}
                                            onBlur={() => setOpenInner(!openInner)}
                                        >
                                            <CropImageModal
                                                setOpenInner={setOpenInner}
                                                profile={profile}
                                                saveCropImage={saveCropImage}
                                                handlePicture={(file) => { handlePicture(file) }}
                                            />
                                        </Modal>
                                    )}

                                </div>
                            </Container>
                        )}

                        {(profileFlag == 1 || profileFlag == 0) && (
                            <Container
                                direction="row"
                                flex="10"
                                jContent="space-around"
                            >
                                {data.map(({ label, type, value }, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <InputContainer width={'48%'} >
                                                <Input
                                                    name={type}
                                                    value={(value != null && value != 'null') ? value : ''}
                                                    label={label}
                                                    isValid={
                                                        isSubmit
                                                            ? type ===
                                                                'firstName' ||
                                                                type === 'lastName'
                                                                ? !!data[index]
                                                                    .value?.length
                                                                : true
                                                            : true
                                                    }
                                                    onChange={handelInput}
                                                    type="text"
                                                />
                                            </InputContainer>
                                        </Fragment>
                                    )
                                })}
                          {/* {linkedin_profile_url && <InputContainer style={{width: '98%'}}>
                            <a href={linkedin_profile_url}><img className="mr-3" src={LinkedinIcon} /></a>
                          </InputContainer>
                        } */}


                                {locationData.map(({ label, type, value }, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <InputContainer style={{width: '98%'}}>
                                                <Input
                                                    name={type}
                                                    value={(value != null && value != 'null') ? value : ''}
                                                    label={label}
                                                    isValid={
                                                        isSubmit
                                                            ? type ===
                                                                'firstName' ||
                                                                type === 'lastName'
                                                                ? !!data[index]
                                                                    .value?.length
                                                                : true
                                                            : true
                                                    }
                                                    onChange={handelInput}
                                                    type="text"
                                                />
                                            </InputContainer>
                                        </Fragment>
                                    )
                                })}
                            </Container>
                        )}
                    </Row>
                    {(profileFlag == 1 || profileFlag == 0) && (
                        <>
                            <Row direction="row">
                                <Input
                                    value={(description != null && description != 'null') ? description : ''}
                                    label="About Me*"
                                    name="description"
                                    isValid={true}
                                    onChange={handelInput}
                                    type="textarea"
                                    maxLength="700"
                                />
                            </Row>
                            { currentUser?.role == 'talent' &&
                            <>
                                <Row direction="row">
                                    <Input
                                        value={(person?.portfolio != null && person?.portfolio != 'null') ? person?.portfolio: ''}
                                        label="Portfolio and Password (if applicable)"
                                        name="portfolio"
                                        isValid={true}
                                        onChange={handelSecondInput}
                                        type="text"
                                        maxLength="50"
                                        width="80%"
                                    />
                                </Row>
                                <Row direction="row">
                                    <Input
                                        value= {(person?.transfer != null && person?.transfer != 'null') ? person?.transfer: ''}
                                        label="All roles posted in United States locations require that you be located within one of the 50 U.S. States. Will you perform the job responsibilities within one of the 50 U.S. States?"
                                        name="transfer"
                                        isValid={true}
                                        onChange={handelSecondInput}
                                        type="text"
                                        maxLength="50"
                                        width="80%"
                                    />
                                </Row>
                                <Row direction="row">
                                    <Input
                                        value= {(person?.authorization != null && person?.authorization != 'null') ? person?.authorization: ''}
                                        label="Are you authorized to work in the United States?"
                                        name="authorization"
                                        isValid={true}
                                        onChange={handelSecondInput}
                                        type="text"
                                        maxLength="50"
                                        width="80%"
                                    />
                                </Row>
                                <Row direction="row">
                                    <Input
                                        value= {(person?.state != null && person?.state != 'null') ? person?.state: ''}
                                        label="Which state do you reside in?"
                                        name="state"
                                        isValid={true}
                                        onChange={handelSecondInput}
                                        type="text"
                                        maxLength="50"
                                        width="80%"
                                    />
                                </Row>
                                <Row direction="row">
                                    <Input
                                        value= {(person?.compensation != null && person?.compensation != 'null') ? person?.compensation: ''}
                                        label="What's your expected compensation if you are willing to share?"
                                        name="compensation"
                                        isValid={true}
                                        onChange={handelSecondInput}
                                        type="text"
                                        maxLength="50"
                                        width="80%"
                                    />
                                </Row>
                                <Row direction="row">
                                    <Input
                                        value= {(person?.sponsorship != null && person?.sponsorship != 'null') ? person?.sponsorship: ''}
                                        label="Will you, now or in the future, require sponsorship for employment in the U.S.?"
                                        name="sponsorship"
                                        isValid={true}
                                        onChange={handelSecondInput}
                                        type="text"
                                        maxLength="50"
                                        width="80%"
                                    />
                                </Row>
                            </> }
                           {full_user?.agency_id == 0 && <Row>
                            <FileButton
                                type="button"
                                label="Upload Resume"
                                file={resume}
                                resumeError={resumeError}
                                setResumeError={setResumeError}
                                getFile={(file) => setResume(file)}
                            />
                            </Row>}
                            {full_user?.agency_id == 0 && <Row>
                          {resume_url &&   <a target='_blank' href={resume_url}>Resume</a> }
                            </Row> }
                           { currentUser?.role == 'talent' &&
                                        <>
                                        <div style={{display:'flex', flexDirection:'column'}}>
                                        <label>Video or Audio Intro</label>
                                          <div className='d-flex'>
                                      <label className={`${styles.EdituploadFileLabel}`}  for="actual-btn" >Upload file</label>
                                        <input
                                            id="actual-btn"
                                            hidden
                                            //  ref={inputRef}
                                            className="VideoInput_input"
                                            type="file"
                                            onChange={handleFileChange}
                                            accept=".mov,.mp4, .mp3"
                                            />
                                              <div style={{display:'flex'}}>
                                            <span className={`${styles.Editnofilechosen}`}>{(source?.name) || 'No file chosen'}</span>
                                               {source?.name && <span className={`${styles.closeFileIcon}`}
                                                 onClick={() => {
                                                     setSource(null)
                                                     setFileError('')
                                                    }}>  x </span> }
                                                    </div>

                                            </div>
                                                    </div>
                                            <p style={{color:'red'}}>{fileError}</p>
                                            {resume_url &&   <a style={{marginBottom:'5px'}} href={audio_video_url}>Download Audio/video</a> }
                                            </> }
                            <>
                                {currentUser && currentUser?.role == 'talent' && (
                                    <Row style={{ 'display': 'block', 'width': '50%' }}>
                                        <label className={styles.label}>{'Select Talent Tech Group'}</label>
                                        <SelectRect
                                            options={techTalentList}
                                            name='tech_group'
                                            id='tech_group'
                                            placeholder={'Select Talent Tech Group (Max 5)'}
                                            onChange={handleTechTalentList}
                                            defaultValue={defaultGroup}
                                            isMulti
                                        // menuPosition='fixed'
                                        />
                                    </Row>
                                )}
                            </>
                        </>
                    )}
                    {(profileFlag == 1 || profileFlag == 0) && (
                        <Row direction="column">
                            <W4text
                                size="12px"
                                color="#7A8AC2"
                                style={{ marginBottom: '8px' }}
                            >
                                {isAddSkill ? 'Skills (Max. 20)' : 'Skills'}
                            </W4text>

                            <Container direction="row">
                                {skills.map((item, index) => {
                                    return (
                                        <Skill key={index}>
                                            <W4text color="#768bff" size="12px">
                                                {item.trim() && item}
                                            </W4text>
                                            <span
                                                className="span"
                                                onClick={() => {
                                                    removeSkill(item)
                                                }}
                                            >
                                                <i data-feather="x" />
                                            </span>
                                        </Skill>
                                    )
                                })}
                                {isAddSkill && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <input
                                            value={skillset}
                                            label="no-label"
                                            name="skills"
                                            className={`${styles.skillInput} form-control`}
                                            width="300px"
                                            onChange={(e) => {
                                                setState({
                                                    ...initialState,
                                                    skillset: e.target.value,
                                                })
                                            }}
                                            type="text"
                                            placeholder="Press enter to add skills"
                                            onKeyDown={add}
                                        />
                                    </div>
                                )}
                                {skills.length < 20 && (
                                    <Button
                                        lr="16px"
                                        tb="11px"
                                        onClick={() => handleAddSkill()}
                                    >
                                        <W5text size="12px" color="#ffff">
                                            {isAddSkill ? 'Add' : '+ Add Skill'}
                                        </W5text>
                                    </Button>
                                )}
                            </Container>
                        </Row>
                    )}
                    {(profileFlag == 2 || profileFlag == 0) && (
                        <>
                            <Row direction="column">
                                <W4text size="18px" color="#283A7A">
                                    Experience
                                </W4text>
                            </Row>
                            <Row direction="column">
                                {experiences?.map((experience, index) => (
                                    <Fragment key={index}>
                                        <EditExperience
                                            experience={experience}
                                            setExprienceDateInvalid={setExprienceDateInvalid}
                                            dispatch={dispatch}
                                            index={index}
                                            isLastIndex={
                                                state.experiences?.length - 1 === index
                                            }
                                            handleExperience={handleExperience}
                                            handleExperienceDate={handleExperienceDate}
                                            handleRemoveExperience={
                                                handleRemoveExperience
                                            }
                                        />
                                    </Fragment>
                                ))}
                            </Row>

                            <Row direction="row">
                                <Button2
                                    lr="16px"
                                    tb="5px"
                                    onClick={() => handleNewExperience()}
                                >
                                    <W8text size="14px" color="#7286F0">
                                        Add Experience Section
                                    </W8text>
                                </Button2>
                            </Row>
                        </>
                    )}

                    {(profileFlag == 3 || profileFlag == 0) && (
                        <>
                            <Row direction="column">
                                <W4text size="18px" color="#283A7A">
                                    Education
                                </W4text>
                            </Row>
                            <Row direction="column">
                                {educations?.map((education, index) => (
                                    <Fragment key={index}>
                                        <EditEducation
                                            education={education}
                                            setEducationDateInvalid={setEducationDateInvalid}
                                            dispatch={dispatch}
                                            index={index}
                                            isLastIndex={
                                                state.educations?.length - 1 === index
                                            }
                                            handleEducation={handleEducation}
                                            handleEducationDate={handleEducationDate}
                                            handleRemoveEducation={
                                                handleRemoveEducation
                                            }
                                        />
                                    </Fragment>
                                ))}
                            </Row>
                            <Row direction="row">
                                <Button2
                                    lr="16px"
                                    tb="5px"
                                    onClick={() => handleNewEducation()}
                                >
                                    <W8text size="14px" color="#7286F0">
                                        Add Education Section
                                    </W8text>
                                </Button2>
                            </Row>
                        </>
                    )}
                <Row
                    direction="row"
                    jContent="flex-end"
                    style={{ marginBottom: '0px' }}
                >
                    <Button disabled={fileError} onClick={handleOnSave} lr="16px" tb="5px">
                        <W8text size="14px" color="#ffff">
                            Save Changes
                        </W8text>
                    </Button>
                </Row>
                </ScrollContainer>

              
            </Container>
        </Wrapper>
    )
}

export default EditCandidateProfile
