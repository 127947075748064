import React, { useEffect, useReducer, useRef, useState } from 'react'
import {
    AgencyContext,
    reducer,
} from '../../../../../stores/Agency/AgencyFormStore'
import './styles/index.scss'
import './styles/DataTableCustom.scss'
import feather from 'feather-icons'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeRequest } from '../../../../common/RequestAssist/RequestAssist'
import { Button, H1 } from '../../../Admin/styles/UserManagementEditPage.styled'
import Card from '../../../Admin/shared/Card'
import DataTable from 'react-data-table-component-footer'
import P from '../../../Admin/shared/P'
import {Col, Row, Spinner } from 'react-bootstrap'
import SearchBar from '../../../../common/SearchBar/SearchBar'
import { DisplayPagination } from '../../../Admin/styles/AdminDashboard.styled'
import moment from 'moment'

const AgencyList = () => {
    var active_page = window.sessionStorage.getItem('agency_activePage')
    var query = window.sessionStorage.getItem('agency_query')
    let agency = {}
    const { agencyName, ownerFirstName, ownerLastName, email, phone } = agency
    const [agencyDetails, setAgencyDetails] = useState([])
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [pageCount, setPageCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [startRecord, setStartRecord] = useState(0)
    const [currentCounts, setCurrentCounts] = useState(0)
    const [totalAgency, setTotalAgency] = useState()
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [agencyId, setAgencyId] = useState()
    const [searchTerm, setSearchTerm] = useState(query || '')

    useEffect(() => {
        feather.replace()
    })
    let initialState = {
        ...initialState,
        ...{
            agencyName,
            ownerFirstName,
            ownerLastName,
            email,
            phone,
        },
    }

    useEffect(() => {
        fetchData()
    }, [activePage])

    const fetchData = () => {
        setLoading(true)
        // setAgencyDetails([])
        setPageCount(0)
        window.sessionStorage.setItem('agency_activePage', activePage)
        const url = `/agencies/admin/agencies.json`
        makeRequest(url, 'get', { params: { page: activePage, keyword: searchTerm } }, {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                let sr_no = (activePage - 1) * res.data.per_page
                setPageCount(res.data.total_pages)
                setTotalAgency(res.data.total_count)
                setAgencyDetails(
                    res.data.agencies.map((data) => (sr_no = sr_no + 1, {
                        sr_no: sr_no,
                        agencyName: data?.name,
                        ownerFirstName: data?.first_name.concat("  ", data?.last_name),
                        ownerLastName: data?.last_name,
                        email: data?.email,
                        created_at: moment(data.created_at).format('MMM DD, YYYY'),
                        actions: (<div className='w-100 d-inline-flex justify-content-around'>
                            <a href={`/agencies/admin/agencies/${data?.id}`} className='pr-4'><i data-feather="edit-2" /></a>
                            <a onClick={() => showConfirmDelete(data?.id)} href={`javascript::void()`} className='pr-4'><i data-feather="trash-2" /></a>
                                {data?.confirm_token && <a onClick={() => resendMail(data?.user_id)} data-toggle="tooltip" title='Resend Mail' href={`javascript::void()`} className='pr-4'>
                                <i data-feather="send" /> 
                            </a> }
                        </div>)
                    }))
                )
                setLoading(false)
                setCurrentCounts(res?.data?.current_count)
               setStartRecord((activePage - 1) * res.data.per_page)    
            },

        })
    }

    const resendMail = async (id) => {
        const url = `/agencies/admin/agencies/resend_email_for_confirmation/${id}`
        await makeRequest(url, 'post', '', {
            createResponseMessage: (res) => {
                console.log(res, 'ressss')
                return {
                    message: 'Mail Resent Successfully',
                    messageType: 'success'
                }
            }
        })
    }

    const deleteOrganization = async () => {
        setShowRemoveConfirmation(false)
        const url = `/agencies/admin/agencies/${agencyId}`
        await makeRequest(url, 'delete', '', {
            createSuccessMessage: () => 'Agency deleted successfully',
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: () => {
                setShowRemoveConfirmation(false)
                fetchData()
            },
        })
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('agency_query', value)
    }

    const showConfirmDelete = (id) => {
        setAgencyId(id)
        setShowRemoveConfirmation(true)
    }

    const createAgency = () => {
        window.location.href ='/agencies/admin/agencies/new'
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    const columns = [
        {
            name: 'No.',
            selector: (row) => row.sr_no,
            maxWidth: '5%',
            minWidth: '5%',
        },
        {
            name: 'Agency Name',
            selector: (row) => row.agencyName,
            wrap: true,
            maxWidth: '24%',
            minWidth: '24%',
            sortable: true,
        },
        {
            name: 'Owner Name',
            selector: (row) => row.ownerFirstName,
            wrap: true,
            maxWidth: '15%',
            minWidth: '15%',
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            maxWidth: '17%',
            minWidth: '17%',
        },
        {
            name: 'Created on',
            selector: (row) => row.created_at,
            maxWidth: '15%',
            minWidth: '15%',
        },
        {
            name: 'Actions',
            selector: (row) => row.actions,
            maxWidth: '24%',
            minWidth: '24%',
        },
    ]
    return (
        <AgencyContext.Provider value={(state, dispatch)}>
            <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '15px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                     Agency Management
                </P>
                <Button onClick={createAgency} className="ml-auto">
                    Create Agency
                </Button>
            
            </div>
                <Row className="w-100" style={{ marginBottom: '15px', display: 'flex', alignItems: 'baseline', }}>
                    <Col xs={12} sm={12} lg={8} style={{ float: 'left' }}>
                    <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalAgency ? totalAgency : currentCounts } of {totalAgency}</DisplayPagination>
                    </Col>
                    <Col className='mr-0' xs={12} sm={12} lg={4} style={{ float: 'right' }}>
                        <SearchBar
                        placeholder="Search agency..."
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 1
                                ? fetchData()
                                : setActivePage(1)
                            !searchTerm && window.sessionStorage.setItem('agency_query','')
                        }}
                        onClick={(e) => setSearchTerm('')}
                        />
                    </Col>
                </Row>
                <div className="agency-list-container">
                    <DataTable
                        title={false}
                        persistTableHead={true}
                        noDataComponent={
                            <p className="text-center p-3">No record found</p>
                        }
                        columns={columns}
                        data={agencyDetails}
                        progressPending={loading}
                        loading={loading}
                        progressComponent={
                            <P
                                className="text-center p-3"
                                size="18px"
                                color="#6077f4"
                            >
                                <Spinner
                                    className="spinner"
                                    animation="border"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </P>
                        }
                        sortServer
                        highlightOnHover
                        pagination
                        paginationPerPage={25}
                        paginationServer
                        paginationTotalRows={totalAgency}
                        paginationComponentOptions={{
                            noRowsPerPage: true
                        }}
                        onChangePage={page => setActivePage(page)}
                    />
                </div>
                {showRemoveConfirmation &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="danger"
                        title="Are you sure you want to delete?"
                        onConfirm={() => (deleteOrganization(), setShowRemoveConfirmation(false))}
                        onCancel={() => setShowRemoveConfirmation(false)}
                        focusCancelBtn
                    >
                    </SweetAlert>
                }
            </Card>
        </AgencyContext.Provider>
    )
}

export default AgencyList
