import React, { useState, useEffect } from 'react'

function MultiBooleanInput({
    checkboxInfo,
    setFilter,
    testAttr,
    initialValues = [],
}) {
    const [checkedOptions, setCheckedOptions] = useState([...initialValues])

    useEffect(() => {
        setFilter(testAttr, checkedOptions)
    }, [checkedOptions])

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked
        const option = e.target.id

        const newCheckedBoxes = checked
            ? checkedOptions.concat(option)
            : checkedOptions.filter((cbOption) => cbOption !== option)

        setCheckedOptions(newCheckedBoxes)
    }

    useEffect(() => {
        setCheckedOptions([...initialValues])
    }, [initialValues.length])

    return (
        <>
            {checkboxInfo &&
                checkboxInfo.map((cb) => (
                    <div className="label-and-checkbox" key={cb.option}>
                        <input
                            id={cb.option}
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            checked={checkedOptions.includes(cb.option)}
                            style={{ marginRight: '0.5rem' }}
                        />
                        {cb.label}
                    </div>
                ))}
        </>
    )
}

export default MultiBooleanInput
