import React, { useState, useEffect } from 'react'
import { Card, Col, Image, Row, Spinner, Alert, Button } from 'react-bootstrap'
import styles from './styles/CandidateLookup.module.scss'
import JobSearchBar from '../../common/JobSearchBar/JobSearchBar'
import axios from 'axios'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import profileImage from '../../../../assets/images/icons/profile.png'
import Util from '../../../utils/util'
import feather from 'feather-icons'
import { CSVLink } from "react-csv";
import Paginator from '../../common/Paginator/Paginator'

const CandidateLookup = (props) => {
  const [inputValue, setInputValue] = useState('')
  const [inputValueValidation, setInputValueValidation] = useState('')
  const [sendMailMessage, setSendMailMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [personData, setPersonData] = useState([])
  const [activePage, setActivePage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const handleInputChange = (value) => {
    setInputValue(value)
  }
  const [show, setShow] = useState(false);
  const csvExportRef = React.useRef();
  useEffect(()=>{
    feather.replace()
  })
  useEffect(()=>{
      handleSearch()
      window.scrollTo({ top: 0, behavior: 'smooth' })
  },[activePage])
  const headers = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email Address', key: 'email_address' },
    { label: 'Current Title', key: 'title' },
    { label: 'Current Company', key: 'company_names' }
];
  const submitCandidateSearch = (event) => {
    event.preventDefault()
    setPersonData({})
    setShow(false)
    if (inputValue != '' && inputValue != undefined && inputValue != 'undefined') {
      setInputValueValidation('')
      setActivePage(0)
      handleSearch()
    }
    else {
      setInputValueValidation('Please Enter Skill.')
    }
  }
  const handleSearch = async () => {
    const url = `candidate_lookups/search`
    const formData = new FormData();
    formData.append('search', inputValue);
    formData.append('page', (activePage+1));
    setLoading(true)
    const CSRF_Token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    if(inputValue != '' && inputValue != undefined && inputValue != 'undefined'){
    try {
      const response = await axios.post(url, formData,
        {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
          },
        });
      if (response.data.success) {
        if (response.data.people) {
          setPersonData(response.data.people)
          // setTotalCount(response.data.total_count)
          setPageCount(response.data.total_pages)
        } else {
          setShow(true)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
    setLoading(false)
  }

  return (
    <div>
      <div className={`${styles.searchHeading}`}>
        <div className="container text-center">
          <h2>Find Candidates</h2>
          <p>
            Enter skill for getting candidates infomation
          </p>
        </div>
        <JobSearchBar
          placeholder="Enter Skill"
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          onEnterPressed={(e) => {
            submitCandidateSearch(e)
          }}
        />

      </div>
      <Alert className={styles.alert} >{inputValueValidation}</Alert>
      <div className={styles.mainSearchblock}>
        <div>
          {(loading) &&
            <div className='container' style={{ textAlign: 'center' }}>
              <h2>Loading....</h2>
              <div id="loading" className={styles.loading} />
            </div>
          }
          {personData && personData.length > 0 &&  (
            <div style={{width: '60%'}} className='d-flex justify-content-center m-auto'>
              <CSVLink
                className='hidden mb-2'
                data={personData} 
                headers={headers}
                filename="candidates.csv"
              >
                <Button className={styles.downloadFileBtn}>Download File</Button>
              </CSVLink>
            </div>
          )}
          {/* {Object.entries(personData).length > 0 && (
            personData.map((candidate, key) => (
              <Card
                key={key}
                className={styles.jobCard}
                style={{ cursor: 'pointer' }}
              >
                <Card.Body>
                  <Row>
                    <Col lg={10} md={10} sm={12} xs={12} className="px-0">
                  <div className='row' style={{ marginBottom: '11px' }} >
                    <div>{candidate.avatar_url ?
                      <img className={styles.candidateProfileImage} src={candidate.avatar_url ? candidate.avatar_url : profileImage} />
                      : <CandidateAvatarIcon firstName={candidate?.first_name} lastName={candidate?.last_name} />
                    }
                    </div>
                    <div style={{width: '60%'}}>
                      <h5>
                        <span>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</span>
                      </h5>
                      <h5 style={{ fontSize: '16px' }}>{candidate.title}</h5>
                    </div>
                  </div>
                  </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))
          )} */}
        </div>
      </div>
    </div>
  )
}
export default CandidateLookup
