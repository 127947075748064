import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Table from './shared/Table'
import { capitalize } from '../../../utils'
import Paginator from '../../common/Paginator/Paginator'
import { CSVLink } from "react-csv";
import { DisplayPagination } from './styles/AdminDashboard.styled'
import {Button} from './styles/OrganizationManagementEditPage.styled'

const OrganizationJobDashboard = (prop) => {
    const queryParams = new URLSearchParams(window.location.search)
    const [activePage, setActivePage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [perPage, setPerPage] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [organizationOnboards,setOrganizationOnboards] = useState([])
    const [owners,setOwners] = useState([{value: 'all', label: 'All'},...prop.owners])
    const [defaultOwner,setDefaultOwner] = useState({value: 'all', label: 'All'})
    const [defaultStatus,setDefaultStatus] = useState({value: 'all', label: 'All'})
    const [defaultSR,setDefaultSR] = useState({value: 'all', label: 'All'})
    const [defaultLinkedin,setDefaultLinkedin] = useState({value: 'all', label: 'All'})
    const [selectedOwner,setSelectedOwner] = useState('')
    const [selectedStatus,setSelectedStatus] = useState('')
    const [selectedSR,setSelectedSR] = useState('')
    const [selectedLinkedin,setSelectedLinkedin] = useState('')
    const csvExportRef = React.useRef()
    const [startRecord, setStartRecord] = useState(0)
    const [exportDatas, setExportDatas] = useState([])
    const [loading, setLoading] = useState(true)
    const headers = [
        { label: 'Job Name', key: 'job_name' },
        { label: 'Posted On', key: 'created_at' },
        { label: 'Owner Name', key: 'owner_name' },
        { label: 'Status', key: 'status' },
        { label: 'Jobs with SR', key: 'job_sr' },
        { label: 'Linkedin', key: 'job_linked' }
    ];
    const jobLinked = [
        { value: 'all', label: 'All' },
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' }
    ];
    const jobStatus = [
        { value: 'all', label: 'All' },
        { value: '1', label: 'Active' },
        { value: '0', label: 'Pending' }
    ];

    const fetchData = async () => {
        setLoading(true)
        const url = `/admin/job_organization_dashboard/${prop.organization.id}`
        const response = await makeRequest(
            `${url}?page=${
                activePage + 1 }&status=${selectedStatus}&sr=${selectedSR}&linkedin=${selectedLinkedin}&owner=${selectedOwner}`,
            'get',{}
        )
        setOrganizationOnboards(response?.data?.jobs.map((data,key)=> ({
            id: key,
            job_name: data?.name && capitalize(data?.name),
            job_id: data?.id && data.id,
            created_at: data?.created_at &&  data.created_at,
            owner_name: `${data?.first_name ? `${capitalize(data?.first_name)} ${capitalize(data?.last_name)}` : '-' }`,
            status: capitalize(data.status),
            job_sr: (data?.keywords || data?.location_preference || data?.nice_to_have_keywords || data?.prefered_industries || data?.prefered_titles || data?.school_names  ||  data?.keywords != null  ||  data?.location_preference != null  ||  data?.nice_to_have_keywords != null  ||  data?.prefered_industries != null  ||  data?.prefered_titles != null ||  data?.school_names != null) ?  'Yes' : 'No'  ,
            job_linked: (data.linkedin_url) ? 'Yes' : 'No'  
        }))) 
        setPageCount(response.data.total_pages)
        setTotalCount(response.data.total_count)
        setStartRecord((activePage * response.data.per_page)+1)
        setPerPage(response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoading(false)
    }

    const exportDataMethod = async () => {
        const url = `/admin/job_organization_dashboard/${prop.organization.id}`
        const response = await makeRequest(
            `${url}?status=${selectedStatus}&sr=${selectedSR}&linkedin=${selectedLinkedin}&owner=${selectedOwner}`,'get',{}
        )
        setExportDatas(response?.data?.jobs.map((data,key)=> ({
            id: key,
            job_name: data?.name && capitalize(data?.name),
            job_id: data?.id && data.id,
            created_at: data?.created_at && moment(data.created_at).format('MMM DD, YYYY'),
            owner_name: `${data?.first_name ? `${capitalize(data?.first_name)} ${capitalize(data?.last_name)}` : '-' }`,
            status: capitalize(data.status),
            job_sr: (data.keywords && data.location_preference && data.nice_to_have_keywords && data.prefered_industries && data.prefered_titles && data.school_names) ?  'Yes' : 'No'  ,
            job_linked: (data.linkedin_url) ? 'Yes' : 'No'  
        })))
        csvExportRef?.current?.link.click();
    }

    useEffect(()=>{
        fetchData()
    },[activePage,selectedStatus,selectedOwner,selectedSR,selectedLinkedin])

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Organization Job Dashboard
                </P>
                <div>
                    <button className={`ml-1 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                        Export CSV
                    </button>
                    <a
                        href={'/admin/organization_onboard'}
                        className={`ml-1 ${styles.exportCSVButton}`}
                    >
                        Go Back
                    </a>
                </div>     
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"job_dashboard.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
           
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 &&
                        <DisplayPagination>Displaying  {(startRecord == 0)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
                    }
                </Col>
                <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                    <div style={{display: 'flex'}}>
                        <div>
                            <label>
                                Select Owner
                            </label>
                            <Select
                                options={owners}
                                defaultValue={defaultOwner}
                                onChange={(event)=>setSelectedOwner(event.value)}
                                name="linkedin_val"
                                className={styles.colourStyles}
                            />
                        </div>
                         <div>
                            <label>
                                Job Status
                            </label>
                            <Select
                                options={jobStatus}
                                defaultValue={defaultStatus}
                                onChange={(event)=>setSelectedStatus(event.value)}
                                name="linkedin_val"
                                className={styles.colourStyles}
                            />
                         </div>
                         <div>
                            <label>
                                Jobs With SR 
                            </label>
                            <Select
                                options={jobLinked}
                                defaultValue={defaultSR}
                                onChange={(event)=>setSelectedSR(event.value)}
                                name="linkedin_val"
                                className={styles.colourStyles}
                            />
                         </div>
                         <div>
                            <label>
                                Linkedin 
                            </label>
                            <Select
                                options={jobLinked}
                                defaultValue={defaultLinkedin}
                                onChange={(event)=>setSelectedLinkedin(event.value)}
                                name="linkedin_val"
                                className={styles.colourStyles}
                            />
                         </div>
                    </div>
                </Col>
            </Row>
            <Table 
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Job Name',
                        field: 'job_name',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Posted On',
                        field: 'created_at',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Owner Name',
                        field: 'owner_name',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Status',
                        field: 'status',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Jobs with SR',
                        field: 'job_sr',
                        editable: false,
                        type: 'text',
                    },
                    {
                        name: 'Linkedin',
                        field: 'job_linked',
                        editable: false,
                        type: 'text',
                    }
                ]}
                rowValues={organizationOnboards.map((o)=>({
                    ...o,
                    created_at: moment(o.created_at).format(
                        'MMM DD, YYYY'
                    ),
                }))
                }
                activePage={activePage}
                perPage={perPage}
                loading={loading}
            />
            { pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
        </Card>
    )
}

export default OrganizationJobDashboard