import React, { useState,useRef, useEffect } from 'react'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col,Form } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import '../Cardinal/SalesLead/styles/Team.scss'
import Button from '../Admin/shared/Button'

const AgreementUpload = ({orgObj,onHide,method,fetchData}) => {
	console.log(orgObj.agreement)
	const [validationError, setValidationError] = useState({})
	const [entryData, setEntryData] = useState({
		file: ''
	})
	const [btnDisabled, setBtnDisabled] = useState(false)
	const handleChange = (e) => {
		setValidationError({})
	}
	const [modal,setModal] = useState('')
	useEffect(()=>{
		setModal(document.querySelector('.modal'))
	})

	const [responseFile, setResponseFile] = useState('')
  	const responseFileRef = useRef()

	const responseFileChange = () => {
		if (responseFileRef.current.files.length > 0) {
			setResponseFile(responseFileRef.current.files)
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
			let URL = '/admin/organizations/upload_agreement/'+orgObj.id
			console.log(URL)
			const payload = new FormData()
			payload.append('agreement', responseFile[0])
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
						onHide()
						fetchData()
				}
			}) 
		}
		setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
		if (responseFile?.length > 0) {
			const element = responseFile[0];
			// if (element?.size > 5000000) {
			// 	errors["file"] = "Attachment File should not be more than 5MB."
				
			// }
	
			let findName = element?.name?.split('.')
			console.log(findName)
			const splitName = findName[findName.length -1]
			if( (splitName !== 'pdf')) {
				errors["file"] = "Please attach file in a valid format."
				formIsValid = false;
			}
		}else{
			errors["file"] = "Please select pdf file to upload agreement."
			formIsValid = false;
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

	return (
		<>
			<Container> 
				<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
						<Col xs={12}>
							<Form.Group>
							<Col xs={13} >
								<span>Upload File (PDF) </span>
								<input ref={responseFileRef} type='file' style={{ padding: '3px' }} onChange={responseFileChange} className='form-control w-100' name='sign_agreement' id="sign_agreement_url" />
							</Col>
							</Form.Group>
							<label className='error'>
									{validationError && validationError.file}        
							</label>
						</Col>
					</Row>
					{  orgObj.agreement != null ? 
					<Row className="w-100" style={{ masrgin: '3px' }} >
						<Col xs={12}>
							<Form.Group>
								<a href={orgObj.agreement} className='pr-2'>View Uploaded Agreement</a>
							</Form.Group>
						</Col>
					</Row>
					: ''}
					
					<Row className="w-100 modal-entry-form-row" style={{marginTop: '5px'}}>
						<Col xs={12} sm={12} lg={12}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Upload</Button>
						</Col>
					</Row>
				</form>
				</Container>
		</>
  )
}

export default AgreementUpload