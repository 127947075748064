import React, { useState, useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import axios from 'axios';
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import '../styles/AiInterview.scss';
import PencilIcon from '../../../../../assets/images/icons/pencil-icon-v2.svg'
import DeleteIcon from '../../../../../assets/images/icons/trash-icon-v2.svg'
import { Button, Col, Form, Row } from 'react-bootstrap'
import _ from 'lodash';


const EditInterviewPage = ({job, currentUser, interview, custom_questions, ai_generate_questions }) => {
    const [currentActiveTab, setCurrentActiveTab] = useState('type')
    const [seletedJob, setSelectedJob] = useState({})
    const [jobId, setJobId] = useState(job.id)
    const [interviewTitle, setInterviewTitle] = useState(interview.interview_title)
    const [isNewSkill, setIsNewSkill] = useState(interview.addition_skill != null && interview.addition_skill != '' ? true : false);
    const [existingJobSkill, setExistingJobSkill] = useState(job?.skills.split(','))
    const [newJobSkill, setNewJobSkill] = useState([{ skill: ""}])
    const [jobQuestions, setJobQuestions] = useState([])
    const [newSkill, setNewSkill] = useState('')
    const [question, setQuestion] = useState('')
    const [isQuestionCustom, setIsQuestionCustom] = useState(true)
    const [questionCustomValue, setQuestionCustomValue] = useState(((interview.question_type).includes('AI Generate Questions')) ? ['Custom Questions','AI Generate Questions'] : ['Custom Questions'])
    const [typeSkillValue, setTypeSkillValue] = useState(['Existing skill of the job'])
    const [errorObj, setErrorObj] = useState({})
    const [questionObj, setQuestionObj] = useState({})
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [mode, setMode] = useState('add')
    const [jobList,setJobList] = useState([])
    const [jobSkillList,setJobSkillList] = useState(job.skills)
    const [interviewType, setInterviewType] = useState(interview.interview_type)
   
    useEffect(() => {
        if(interview.addition_skill != null && interview.addition_skill != ''){
            // Assuming interview.addition_skill is a comma-separated string or an array
            const existingSkills = Array.isArray(interview.addition_skill)
            ? interview.addition_skill
            : interview.addition_skill.split(',').map(skill => skill.trim());

            setNewJobSkill(existingSkills.map(skill => ({ skill })));
        }
        if(interview.addition_skill != null && interview.addition_skill != ''){
            setTypeSkillValue(['Existing skill of the job','Add additional skills'])
        }
        getInterviewQuestion(job.id)
    },[])
    
    const handleSkillSelect = (e) => {
        setIsNewSkill(false);
        const { value, checked } = e.target;
        if(typeSkillValue.includes('Add additional skills')){
            setIsNewSkill(true);
        }
        if(value == 'Add additional skills' && checked == false){
            setIsNewSkill(false);
        }
        if(value == 'Add additional skills' && checked == true){
            setIsNewSkill(true);
        }
        setTypeSkillValue((prevValues) => {
            if (checked) {
                return [...prevValues, value];
            } else {
                return prevValues.filter((val) => val !== value);
            }
        });
    }

    const removeNewSkill = (index) =>{
        const updatedCandidates = newJobSkill.filter((_, i) => i !== index);
        setNewJobSkill(updatedCandidates);
    }
    // Handler to add a new row for skill
    const addNewSkill = () => {
        if(newJobSkill.length < 5){
            setNewJobSkill([...newJobSkill, { skill: ""}]);
        }
    }

    const onChangeSkill = (index, event) => {
        const { name, value } = event.target;
        const updatedSkill = newJobSkill.map((jobSkill, i) =>
        i === index ? { ...jobSkill, ['skill']: value } : jobSkill
        );
        setNewJobSkill(updatedSkill);
       
    }

    const handleQuestionSelect = (e) => {
        const { value, checked } = e.target;
        if(questionCustomValue.includes('Custom Questions')){
            setIsQuestionCustom(true)
        }
        if(value == 'Custom Questions' && checked == false){
            setIsQuestionCustom(false)
        }
        if(value == 'Custom Questions' && checked == true){
            setIsQuestionCustom(true)
        }
        setQuestionCustomValue((prevValues) => {
            if (checked) {
                return [...prevValues, value];
            } else {
                return prevValues.filter((val) => val !== value);
            }
        });
    };


    const onCreateInterviewModalHide = () =>{
        setCurrentActiveTab('type')
    }

    // on job change handling skill
    const onJobChange = (e) =>{
        setErrorObj({})
        setJobQuestions([])
        setExistingJobSkill([])
        if(e?.value != undefined && e?.value != ''){
            setJobId(e.value)
            setSelectedJob(e)
            const jobId = e.value;
            const jobSkill = jobSkills[jobId]; // Access the skills by job ID
            if (jobSkill) {
                setExistingJobSkill(jobSkill.split(','))
            }
            getInterviewQuestion(jobId)
        }
    }

    const onChangeInterviewType = (e) => {
        setInterviewType(e.target.value)
    }

    const onChangeTitle = (e) => {
        setInterviewTitle(e.target.value)
    }
    
    // Fetch existing interview question 
    const getInterviewQuestion = async (job_id) =>{
      setJobQuestions([])
      const url =`/ai_interview/${job_id}`
      const responce = await axios.get(url).then(res=>res).catch(error=>console.log(error))
      const responceJob = responce?.data
      if(responceJob?.success){
        setJobQuestions(responceJob.list)
      }
    }

    const handleQuestion = (event) => {
        setQuestion(event.target.value)
     };

     // question management edit/add api call
     const handleQuestionSubmit = (event) => {
        if(question?.trim() !== '' ){ 
            let URL = '/add_ai_interview_question'
            let method = 'post'
			if(mode === 'edit'){
				URL = `/update_ai_interview_question/${questionObj.id}`
                method = 'put'
			}
			const payload = new FormData()
			payload.append('question_param[job_id]', jobId)
			payload.append('question_param[category]', 'Non-technical')
			payload.append('question_param[question]', question)
            if(mode != 'edit'){
                payload.append('question_param[created_by]', currentUser.id)
            }
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
                    getInterviewQuestion(jobId)
                    setQuestion('')
                    setQuestionObj({})
                    setMode('add')
				}
			})
        }
     }

     const onEditClick = (obj) => {
        setQuestionObj(obj)
        setQuestion(obj.question)
        setMode('edit')
      }

      const onDeleteClick = (obj) => {
        setQuestionObj(obj)
        setShowDeleteConfirmation(true)
      }
      
      // Delete interview question
      const onDeleteAIQuestion = () => {
        if(questionObj != {} && questionObj != null){
            const url = `/ai_interview/${questionObj.id}`
            makeRequest(url, 'delete',  {}, {   
            contentType: 'application/json',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                    if(res.data.success){
                        getInterviewQuestion(jobId)
                    }
            },
            })
        }
      }

      const onTypeSubmit = () => {
        if(interviewTitle.trim() != ''){
            getLookupIndustry()
            setCurrentActiveTab('job')
            if(jobList.length == 0){
                setIsJobSelectShow(false)
                setIsJobFormShow(true)
            }
        }else{
            setErrorObj({})
            let errorInterviewType = { title: true}
            setErrorObj(errorInterviewType)
        }
      }

      const onSkillSubmit = () => {
        setErrorObj({}); // Clear previous errors
        // Validate jobId
        if (jobId == '') {
            setErrorObj({ job_id: true });
            return;
        }

        // Check if 'Define additional skill' is selected
        if (typeSkillValue.includes('Define additional skill')) {
            const hasAtLeastOneSkill = newJobSkill.some(jobSkill => jobSkill.skill.trim() !== "");

            // Ensure at least one skill has a value
            if (!hasAtLeastOneSkill) {
            setErrorObj({ additional_skill: true });
            return;
            }
        }
        // Proceed to the next tab
        setCurrentActiveTab('question');
      }

      // intereview creation api
      const submitInterviewData = () => {
        let validate = true;
        let errorObj = {}; // Initialize an empty error object

        if (interviewTitle.trim() === '') {
            errorObj.title = true; // Add title error
            validate = false;
        }

        if (jobId === '') {
            errorObj.job_id = true; // Add job ID error
            validate = false;
        }

        // Check if 'Define additional skill' is selected
        if (typeSkillValue.includes('Define additional skill')) {
            const hasAtLeastOneSkill = newJobSkill.some(jobSkill => jobSkill.skill.trim() !== "");

            // Ensure at least one skill has a value
            if (!hasAtLeastOneSkill) {
                errorObj.additional_skill = true; // Add additional skill error
                validate = false;
            }
        }

        if (questionCustomValue.length === 0) {
            errorObj.questionOption = true; // Add question option error
            validate = false;
        }

        if (questionCustomValue.includes('Custom Questions')) {
            if (jobQuestions.length === 0) {
                errorObj.question = true; // Add custom question error
                validate = false;
            }
        }

        // Set all errors at once
        setErrorObj(errorObj);

        if(validate){
            let URL = `/ai_interviews/interview/${interview.id}/update`
            let method = 'put'  

            // Prepare the skills payload
            const skills = newJobSkill
                                .map(jobSkill => jobSkill.skill.trim()) // Extract and trim skill values
                                .filter(skill => skill !== "");          // Filter out empty skills

            const payload = new FormData()
            payload.append('interview_param[job_id]', jobId)
            payload.append('interview_param[interview_title]', interviewTitle)
            payload.append('interview_param[interview_type]', interviewType)
            payload.append('interview_param[user_id]', currentUser.id)
            payload.append('interview_param[addition_skill]', skills)
            payload.append('interview_param[question_type]', questionCustomValue.join(',') )

            //headers
            const CSRF_Token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')
            const headers = {
                headers: {
                        'content-type': 'application/json',
                        'X-CSRF-Token': CSRF_Token,
                },
            }
            makeRequest(URL,method, payload, headers, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
            }).then((res) => {
                if(res.data.success){
                    window.location.href="/ai_interviews/create"
                }
            })
        }
      }

    const cleanQuestion = (question) => {
        return question.replace(/^\s*([-]|\d+\.)\s*/, '');
    };

    return (
        <>
                <div className='container-fluid ai-containter'>
                    <div className="d-flex justify-content-between mt-5">
                        <h4 className="font-weight-bold mb-3">Update AI Interview</h4>
                    </div>
                    <Form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                      
                        <>
                            <Row className="d-flex mt-2">
                                <Col xs={6} sm={12} lg={6} md={6} className=" pr-2 text-left">
                                    <Form.Group>
                                        <Form.Label  className='font-weight-bold'>Interview Title</Form.Label>
                                        <Form.Control type="text" placeholder="Enter the interview title" onChange={onChangeTitle} value={interviewTitle}/>
                                        { errorObj && errorObj?.title &&
                                            <p className='text-error-dannger'>Title is required</p>
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='d-flex' style={{paddingTop: '1%'}}>
                                <Col className="w-100 pr-2 text-left">
                                    <Form.Label className='font-weight-bold'>Select the interview type</Form.Label>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col xs={2} sm={12} lg={2} md={2} className="w-20 pr-2 text-left">
                                        <div className="py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="radio" name="interview_type" label="Software Engineer Role"
                                                id={`default-custom-question-1`}  value="Software Engineer Role"
                                                checked={interviewType === 'Software Engineer Role'} onChange={onChangeInterviewType}
                                            />
                                        </div>
                                </Col>

                                <Col xs={2} sm={12} lg={2} md={2} className="w-20 pl-2 text-left">
                                        <div className="py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="radio" name="interview_type" label="Non-technical Role"
                                                id={`default-custom-question-2`} value="Non-technical Role"
                                                checked={interviewType === 'Non-technical Role'} onChange={onChangeInterviewType}
                                            />
                                        </div>
                                </Col>
                                <Col xs={3} sm={12} lg={3} md={3} className="w-20 pr-2 text-left">
                                        <div className="py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="radio" name="interview_type" label="STEM Role (Math, Chemistry, Physics, Biology)"
                                                id={`default-custom-question-3`}  value="STEM Role (Math, Chemistry, Physics, Biology)"
                                                checked={interviewType === 'STEM Role (Math, Chemistry, Physics, Biology)'} onChange={onChangeInterviewType}
                                            />
                                        </div>
                                </Col>

                                <Col xs={3} sm={12} lg={3} md={3}  className="w-20 pl-2 text-left">
                                        <div className="py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="radio" name="interview_type" label="Recruiter Role"
                                                id={`default-custom-question-4`}  value="Recruiter Role"
                                                checked={interviewType === 'Recruiter Role'} onChange={onChangeInterviewType}
                                            />
                                        </div>
                                </Col>
                            </Row>
                         </>
                      
                              <>
                                 <Row className="d-flex mt-2">
                                    <Col xs={6} sm={12} lg={6} md={6} className="pr-2 text-left">
                                        <Form.Group>
                                            <Form.Label className='font-weight-bold'> Job Title</Form.Label>
                                            <Form.Control type="text" placeholder="Enter the interview title" disabled={true} value={job.name}/>
                                            { errorObj && errorObj?.job_id &&
                                                <p className='text-error-dannger'>Job is required</p>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                               </>
                              
                        <>
                            <Row className='d-flex' style={{paddingTop: '2%'}}>
                                <Col className="w-100 pr-2 text-left">
                                    <Form.Label className='font-weight-bold'>Please define the skills</Form.Label>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col xs={3} sm={12} lg={3} md={3} className="w-50 pr-2 text-left">
                                    <div className="py-2 mb-4 px-3 rounded-lg">
                                        <Form.Check type="checkbox" name="skill"  label="Existing skill of the job" value="Existing skill of the job"
                                            id={`default-skill-1`} checked={typeSkillValue.includes('Existing skill of the job')} onChange={handleSkillSelect}
                                        />
                                    </div>
                                </Col>

                                <Col xs={3} sm={12} lg={3} md={3} className="w-50 pl-2 text-left">
                                    <div className="py-2 mb-4 px-3 rounded-lg">
                                        <Form.Check type="checkbox" name="skill" label="Add additional skills" value="Add additional skills"
                                            id={`default-skill-2`} checked={typeSkillValue.includes('Add additional skills')} onChange={handleSkillSelect}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='d-flex'>
                                <Col className="w-50 pr-2 text-left">
                                    { existingJobSkill.map((value,key) =>{
                                        return <span key={key} className='skill-bubble-custom ml-1'>{value}</span>
                                    })}
                                </Col>
                            </Row>
                            { isNewSkill  &&
                             <Col xs={6} sm={12} lg={6} md={6}>
                                <p className=' mt-3'>Please feel free add skill to increase the accuracy of the questions.</p>
                                {newJobSkill.map((jobSkill, index) => (
                                    <div className='border rounded-lg p-3 shadow  mt-3 mb-2'  key={index}>
                                        <Row className='d-flex'  key={index}>
                                            <Col className="col-md-11 pr-2 text-left">
                                                <Form.Group>
                                                    <Form.Label  className='font-weight-bold'>Enter Skill #{index+1}</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter new skill" name="skill" onChange={(e)=>onChangeSkill(index, event)} 
                                                    value={jobSkill.skill}
                                                />
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-md-1 pr-2 text-right">
                                                {newJobSkill.length > 1 && index !== 0 && (
                                                    <button className="d-inline-flex text-primary pr-2 mt-4" type="button" onClick={() => removeNewSkill(index)} style={{ marginRight: "10px",marginTop: '40px' }}>
                                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#C2040F"><path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18.8504 9.14001L18.2004 19.21C18.0904 20.78 18.0004 22 15.2104 22H8.79039C6.00039 22 5.91039 20.78 5.80039 19.21L5.15039 9.14001" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.3301 16.5H13.6601" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.5 12.5H14.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <div className="d-inline-flex text-primary pl-2 mt-2">
                                    <p role="button" className="d-inline-flex text-primary pr-2" onClick={addNewSkill} style={{ marginBottom: "10px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(0,129,255,1)" style={{width:'24px', height:'24px'}}><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg> Add another skill (up to 5)
                                    </p>
                                </div>
                            </Col>
                            }
                             { errorObj && errorObj?.additional_skill &&
                                    <p className='text-error-dannger mt-1 ml-2'>At least one addition skill is reuired if you select 'Define addition skill" option</p>
                             }
                          
                        </>
                      
                        <>  
                            <Row className='d-flex' style={{paddingTop: '2%'}}>
                                <Col className="w-100 pr-2 text-left">
                                    <Form.Label className='font-weight-bold'>Interview Questions</Form.Label>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col xs={3} sm={12} lg={3} md={3} className="w-20 pr-2 text-left">
                                        <div className="py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="checkbox" name="interview_type" label="Custom Questions"
                                              id={`default-custom-question-custom`} onChange={handleQuestionSelect} value="Custom Questions"
                                              checked={questionCustomValue.includes('Custom Questions')}
                                            />
                                        </div>
                                </Col>

                                <Col xs={3} sm={12} lg={3} md={3} className="w-20 pl-2 text-left">
                                        <div className="py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="checkbox" name="interview_type" label="AI Generate Questions"
                                                id={`default-custom-question-ai`} onChange={handleQuestionSelect}  value="AI Generate Questions"
                                                checked={questionCustomValue.includes('AI Generate Questions')}
                                            />
                                        </div>
                                </Col>
                            </Row>
                            { errorObj && errorObj?.question &&
                                 <Row className="d-flex">
                                         <p className='text-error-dannger  ml-3'>Custom question required</p>
                                 </Row>
                            }
                            { errorObj && errorObj?.questionOption &&
                                 <Row className="d-flex">
                                        <p className='text-error-dannger ml-3'>Question type is required</p>
                                 </Row>
                            }
                                
                            { isQuestionCustom &&
                            <div className='ml-3'>  
                                { jobQuestions && jobQuestions.length > 0 &&
                                <>
                                    <div className='mb-3'>
                                        <h6>Custom Questions</h6>
                                    </div>
                                    { jobQuestions.length > 0 && jobQuestions.map((ques, index) => ( 
                                        <Row className='d-flex'>
                                            <p className='p-lable-qiestion'> Question {index + 1} : { ques.question} 
                                                <img src={PencilIcon} height={16} width={16} className='ml-3 mr-2 hand-icon' onClick={()=> onEditClick(ques)}/>
                                                <img src={DeleteIcon}  height={16} width={16} className='hand-icon'  onClick={()=> onDeleteClick(ques)}/>
                                            </p>
                                            
                                        </Row>
                                    ))}
                                </>
                                }
                                { (jobQuestions.length <= 10 || mode == 'edit' )  && 
                                 <Col xs={6} sm={12} lg={6} md={6}>
                                    <div className='rounded-lg p-3 shadow  mt-3 mb-2'>
                                        <Row className='d-flex'>
                                            <Col className="col-10 pr-2 text-left">
                                                <Form.Group>
                                                    <Form.Label  className='font-weight-bold'>{ mode == 'add' ? 'Add New ' : 'Update' } Custom Question</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter question" name="question" onChange={(e)=>handleQuestion(e)}  value={question}/>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-2 pr-2 text-left">
                                                    <Button variant="outline-primary" onClick={handleQuestionSubmit} style={{marginTop: '26%'}}>{ mode == 'add' ? 'Add' : 'Update' }</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                }
                            </div>
                            }
                            { ai_generate_questions.length > 0 &&
                                <div className='ml-3'>
                                    <div className='mt-5 mb-3'>
                                        <h6>AI Generated Questions</h6>
                                    </div>
                                        { ai_generate_questions.length > 0 && ai_generate_questions.map((ques, index) => ( 
                                            <Row className='d-flex'><p className='p-lable-qiestion'> Question {index + 1} : { cleanQuestion(ques.question)}</p></Row>
                                        ))}
                                </div>
                            }
                            <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                                <Button variant="outline-primary" onClick={(e)=> (window.location.href="/ai_interviews/create")} className="mr-3">Cancel</Button>
                                <Button variant="primary" onClick={submitInterviewData} className='btn-pk-color'>Save</Button>
                            </div>
                        </>
                       
                    </Form>
                </div>
            
                {showDeleteConfirmation &&
                    <SweetAlert
                        title="Are you sure you want to delete?"
                        warning
                        showCancel
                        confirmBtnText="Yes, Make it!"
                        confirmBtnBsStyle="danger"
                        onConfirm={()=>(onDeleteAIQuestion(),setShowDeleteConfirmation(false))}
                        onCancel={()=>(setQuestionObj({}),setShowDeleteConfirmation(false))}
                        confirmBtnStyle={{ backgroundColor: "#005999", color: "white", borderColor: '#005999', fontWeight: 300, textDecoration: 'none', boxShadow: 'none' }} // Style for Invite Candidate button
                    cancelBtnStyle={{ backgroundColor: "rgb(255, 255, 255)", color: "rgb(0, 89, 153)", borderColor: 'rgb(0, 89, 153)', fontWeight: 300, textDecoration: 'none' }} // Style for Not Now button
                        >
                    </SweetAlert>
                }

            
        </>
    )
}
export default EditInterviewPage;