import React from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import TextInput from '../../common/TextInput/TextInput'

const ErrorMessage = styled.span`
  font-size: 12px;
  color: red;
  min-height: 20px;
  max-width: ${(props) => props.width || 400};
`
const Info = styled.span`
  margin-left: 5px;
`

function UserForm(props) {
  
  const TextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
        <div className="">
          <label htmlFor={props.id || props.name}>
                {label}
          </label>
          <input {...field} {...props} />
        </div>  
        <ErrorMessage width={props.width}>
            {meta.touched && meta.error ? meta.error : ''}
        </ErrorMessage>
      </>
    );
  };

  return (
    <>
      <TextField label="Email Address" type="email" name="email" className="form-control" style={{borderRadius: '10px', height: '45px'}} width="28rem"/>
      <TextField label="Password" type="password" name="password" className="form-control" style={{borderRadius: '10px', height: '45px'}} width="28rem"/>
      {props.signUp && (
      <div className="row">
        <div className="col">
          <TextField label="First Name" type="first_name" name="first_name" className="form-control" style={{borderRadius: '10px', height: '45px'}} width="28rem"/>
        </div>
        <div className="col">
          <TextField label="Last Name" type="last_name" name="last_name" className="form-control" style={{borderRadius: '10px', height: '45px'}} width="28rem"/>
        </div>
      </div>
      )}
    </>
  )
}

export default UserForm
