import React, { useState, useEffect} from 'react'
import _ from 'lodash'
import './styles/communities.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import '../../../../assets/v2/css/main.css'
import avatarImg from '../../../../assets/v2/imgs/avatar/ava_1.png'

function SettingLeftPenal ({ communityId}) {
  
  const [memberDetail, setMemberDetail] = useState({})
  const [community, setCommunity] = useState({})
  const currentPathname = window.location.pathname
  useEffect(() => { 
    getInfo()
  },[])

  const getInfo = () => {
    let URL = `/communities/member/detail/${communityId}`
    makeRequest(URL, 'get', '', {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      onSuccess: (response) => {
        let data = response.data
        setCommunity(data.community)
        setMemberDetail(data.member_detail)
      },
    })
  } 
  
  return (
    <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
      <div className="sidebar-shadow">
        <div className="sidebar-heading">
          <div className="author-bio">
            <div className="author-image mb-15">
              <a href={'/communities/'+communityId}><img src={community.avatar_image || avatarImg} alt="" className="avatar" /></a>
              <div className="author-infor">
                <h5 className="mb-5">{community.name}</h5>
                <p className="mb-0 text-muted font-xs">
                  <span className="mr-10">Group settings</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-list-job m-0">
          <ul>
            <li className={`${currentPathname === '/communities/'+communityId+'/dashboard' ? 'active': '' }`}>
              <div className="sidebar-icon-item"><i className="fi-rr-shield-check"></i></div>
              <div className="sidebar-text-info">
                <a href={'/communities/'+communityId+'/dashboard'} className="text-description">Dashboard</a>
              </div>
            </li>
            <li className={`${currentPathname === '/communities/'+communityId+'/payouts' ? 'active': '' }`}>
              <div className="sidebar-icon-item"><i className="fi-rr-user"></i></div>
              <div className="sidebar-text-info">
                <a href={'/communities/'+communityId+'/payouts'} className="text-description">Payouts</a>
              </div>
            </li>
            <li className={`${currentPathname === '/communities/'+communityId+'/invites' ? 'active': '' }`}>
              <div className="sidebar-icon-item"><i className="fi-rr-comment-user"></i></div>
              <div className="sidebar-text-info">
                <a href={'/communities/'+communityId+'/invites'} className="text-description">Invite Members</a>
              </div>
            </li>
            <li className={`${currentPathname === '/communities/'+communityId+'/earn_referrals' ? 'active': '' }`}>
              <div className="sidebar-icon-item"><i className="fi-rr-comment-user"></i></div>
              <div className="sidebar-text-info">
                <a href={'/communities/'+communityId+'/earn_referrals'} className="text-description">Referral Revenue</a>
              </div>
            </li>
            <li className={`${currentPathname === '/communities/'+communityId+'/general_setting' ? 'active': '' }`}>
              <div className="sidebar-icon-item"><i className="fi-rr-headset"></i></div>
              <div className="sidebar-text-info">
                <a href={'/communities/'+communityId+'/general_setting'} className="text-description">General</a>
              </div>
            </li>
            <li className={`${currentPathname === '/communities/'+communityId+'/subscription' ? 'active': '' }`}>
              <div className="sidebar-icon-item"><i className="fi-rr-clock"></i></div>
              <div className="sidebar-text-info">
                <a href={'/communities/'+communityId+'/subscription'} className="text-description">Subscription</a>
              </div>
            </li>
            <li className={`${currentPathname === '/communities/'+communityId+'/category' ? 'active': '' }`}>
              <div className="sidebar-icon-item"><i className="fi-rr-clock"></i></div>
              <div className="sidebar-text-info">
                <a href={'/communities/'+communityId+'/category'} className="text-description">Categories</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default SettingLeftPenal