import React, {useState} from "react";
import './styles/EmployerOrganization.scss';
import styles from './styles/EmployerOrganization.module.scss';
import Image from 'react-bootstrap/Image';
import { Col, Row } from 'react-bootstrap'
import profileImage from '../../../../assets/images/img_avatar.png'
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {firstCharacterCapital} from '../../../utils/index'
import { makeRequest } from "../../common/RequestAssist/RequestAssist";

function MembersList({members,deduct_details}) {
  const [memberName, setMemberName] = useState('');
  const [isToggled, toggle] = useState(true)
  const renderTooltip = name => (
    <Tooltip>{name}</Tooltip>
  );
  const handleClick = async (member_id,allow) => {
      let URL = '/subscription_deduct'
      const payload = new FormData()
      payload.append('user[user_id]', member_id)
      payload.append('user[allow]', allow)
    
      makeRequest(URL,'post',payload , {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createResponseMessage: (responce) => {
              if(responce['messageType'] == 'failure'){
                  return {
                      message: responce['msg'],
                      messageType: responce['messageType'],
                      loading: false,
                      autoClose: true,
                  }
              }else{
                  return {
                      message: responce['msg'],
                      messageType: responce['messageType'],
                      loading: false,
                      autoClose: true,
                  }
              }
          },
          createErrorMessage: (e) => 'Failed',
          onSuccess: (responce) => {
              deduct_details[member_id]['deduct_limit'] = allow
              toggle(true)
          },
      })
  }

    return(
      <>
        {members?.map((member) => {
          const member_name = firstCharacterCapital(member.first_name) + ' ' + firstCharacterCapital(member.last_name)
          return(
              <Col xs={4}>
                <div key={member.id} className={`${styles.MemberContainer}`}>
                  <Image
                    src={member.image_url ? member.image_url : profileImage}
                    className={`${styles.MemberProfilePhoto}`}
                  />
                  <div>
                    <OverlayTrigger placement="top" overlay={renderTooltip(member_name)}>
                      <div className={`${styles.MemberName}`}>{member && member_name }</div>
                    </OverlayTrigger>
                    <div className={`${styles.MemberDesignation}`}>{member.role? firstCharacterCapital(member.role) : ''}</div>
                    <div className={`${styles.MemberMainContact}`}>
                      <div  className={`${styles.MemberContact}`}> Contact </div>
                      <div  className={`${styles.MemberActivity}`}> Activity </div>
                    </div>
                    {(member.role == 'recruiter' && deduct_details[member.id] !== undefined) ?
                    <div className={`${styles.MemberMainContact}`}>
                      <div  className={`deduct`}> 
                        <label>
                            <input type="checkbox" defaultChecked={deduct_details[member.id]['deduct_limit']} onClick={()=>{
                                handleClick(member.id,!deduct_details[member.id]['deduct_limit'])
                            }}
                            />
                            <span />
                            <strong>Allow Deduct</strong>
                        </label>
                      </div>
                    </div>
                    : <></>}
                  </div>
                </div>
              </Col>
          )
        }) || '' }
      </>
    )
}
export default MembersList;
