import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import GoogleIcon from '../../../../assets/images/icons/icon-google.svg'
import styles from './styles/Signup.module.scss'
import { H1, P, A, GoogleButton } from './styles/Signup.styled'
// import SignUp from './SignUpV2'
import SignUp from './SignUpInterview'
import { capitalize } from '../../../utils'
import googleImage from '../../../../assets/v2/imgs/google1.png';
import { Form, Button, Card, Row, Col } from "react-bootstrap";
import './styles/default.css';

// import bannerImage from '../../../../assets/v2/imgs/banner/banner.png';
// import unionImage from '../../../../assets/v2/imgs/banner/union.svg';
// import docsImage from '../../../../assets/v2/imgs/banner/docs.svg';
// import tickImage from '../../../../assets/v2/imgs/banner/tick.svg';
// import LogoGroups from '../../../../assets/images/logos/cardinalgroups.png'

const CreateAccountPage = (props) => {
  const exactUrl = window.location.href
  const role = exactUrl.split('/').pop()
  let userRole;
  if (role.includes("talent2")) {
    userRole = "talent";
  } else {
    userRole = role.split('?').shift();
  }
  const [isV2, setIsV2] = useState(props.version == 2 ? true : false)

  const handleGoogleSignIn = () => {
    window.location.href = `/user/auth/google_oauth2?role=${userRole}`
  }

  const currentHost = window.location.host;
  if (currentHost == 'cardinalgroups.com' || currentHost == 'www.cardinalgroups.com') {
    var companyname = 'Cardinal Groups';
  } else {
    var companyname = 'CardinalTalent';
  }
  console.log(role.includes("employer"))

  return (
    <>
      {isV2 ? (
        <section className="container mt-80">
          <div className="banner-hero hero-1">
            <div className="banner-inner">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 communities-create">
                  {/* {(currentHost == 'cardinalgroups.com' || currentHost == 'www.cardinalgroups.com' ? */}
                  <>
                    <div className="build_community pl-40">
                      {(role.includes("talent") == true) &&
                        <>
                          <h1>Join our job referral group of 10,000+ talent, employers, and recruiters helping each other land jobs.</h1>
                          <div className="build_community_info">
                            <div>🌎 Browse jobs posted by fellow hiring members from our community. Send them a chat message. Get an interview.  And get hired.</div>
                            <div>❤️ Access to the best Tech Workers Job Referral Community</div>
                            <div>💫 Browse job groups with jobs posted directly by hiring managers and recruiters.</div>
                            <div>🔎 Get your resume reviewed and Job search support</div>
                            <div>🎓 Potential to connect with 100s of hiring managers</div>
                          </div>
                        </>

                      }
                      {(role.includes("recruiter") == true) && 
                        <>
                          {/* <h1>Everything you need to build community and make money online.</h1> */}
                          <div className="build_community_info">
                            <div> 💸 Earn placement fees of up to $40,000 per hire for candidates you source and submit to our account managers.</div>
                            <div> 📢 Earn 25% of revenue generated for the 1st year from anyone who you get to join.</div>
                            <div> 📈 Highly engaged</div>
                            <div> ❤️ Simple to setup</div>
                            <div> 🙂 It's fun</div>
                            <div> 💱 Create a community and charge for subscription membership</div>
                            <div> 🌎 Global distribution</div>
                          </div>
                        </>
                      }
                      {(role.includes("employer")  == true) && 
                        <>
                            <h1>Join our job referral groups of 10,000+ talent, employers, and recruiters!</h1>
                            <div className="build_community_info">
                              <div> 🌎 Search top talent from our community. Send them a chat message, interview them, and hire them!</div>
                              <div> ❤️ Access the best Tech Workers Job Referral Community.</div>
                              <div> 💫 Create or join job referral groups to attract top talent.</div>
                              <div> 🔎 Post your jobs and share with the community.</div>
                              <div> 🎓 Potential to connect with 100s of top talent.</div>
                            </div>
                        </>
                      }
                      <div>
                        <a target="_blank" rel="noopener noreferrer" href="mailto:help@cardinalgroups.ai">help@cardinalgroups.com</a>
                      </div>
                    </div>
                  </>
                  {/* :
                    <div className="banner-imgs">
                      <img alt={companyname} src={bannerImage} className="img-responsive shape-1" />
                      <span className="union-icon"><img alt={companyname} src={unionImage} className="img-responsive shape-3" /></span>
                      <span className="docs-icon"><img alt={companyname} src={docsImage} className="img-responsive shape-2" /></span>
                      <span className="tick-icon"><img alt={companyname} src={tickImage} className="img-responsive shape-3" /></span>
                    </div>
                  )} */}
                </div>
                <div className="col-lg-6 bg-white" style={{ maxWidth: '550px', margin: '10px auto' }}>
                  <div className="block-banner login">
                    <div className="right-content" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                      <h3 className="form-title text-center">Create {(userRole == 'employer') ? 'An' : 'A'} {capitalize(userRole)} Account</h3>
                      <p className="text-center mb-4 form-title" style={{ fontSize: '15px' }}>Create an account with {companyname}</p>
                      <SignUp userRole={userRole} />
                      <span className="signup-link text-center mt-0">Have an account? <a href="/users/sign_in" style={{ textDecoration: 'underline' }}>Log In</a></span>
                      <div className="hr-separator">
                        <span className="separator">OR</span>
                      </div>
                      <div className="text-center">
                        <a
                          className="button is-google hero_buttons w-button"
                          style={{ width: '50%', marginLeft: '25%', cursor: 'pointer' }}
                          onClick={() => handleGoogleSignIn()}
                        >
                          <img src={googleImage} alt="Google Logo" width={16} height={16} style={{ margin: '10px' }} />
                          Sign up with Google
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className={`${styles.mainForm}`}>
          <MainPanel>

            <H1>Create {(userRole == 'employer') ? 'an' : 'a'} {capitalize(userRole)} account</H1>
            <div className={styles.formContainer}>
              <SignUp userRole={userRole} />
              {userRole != "employer" && (

                <GoogleButton
                  onClick={() => handleGoogleSignIn()}
                >
                  <img src={GoogleIcon} />
                  Continue with Google
                </GoogleButton>
              )}
            </div>
          </MainPanel>
          <InfoPanel>
            <div className={`${styles.infopanelDiv}`}>
              <div className="d-flex flex-column">
                <P>Have an account already?</P>
                <A href="/users/sign_in">Sign In</A>
              </div>
            </div>
          </InfoPanel>
        </div>)}
    </>
  )
}

  const SignUpV2 = () => {
    const exactUrl = window.location.href
    const extrole = exactUrl.split('/').pop()
    let userRole;
    if (extrole.includes("talent2")) {
      userRole = "talent";
    } else {
      userRole = extrole.split('?').shift();
    }
    const [selectedRole, setSelectedRole] = useState(userRole); // Default role set to 'Talent'

    const handleRoleChange = (event) => {
      setSelectedRole(event.target.value); // Update the role when user changes the selection
    };

    const handleGoogleSignIn = () => {
      window.location.href = `/user/auth/google_oauth2?role=${selectedRole}`
    }
    
    return (
      <section className={`container mt-80 d-flex justify-content-center align-items-center ${styles.sectionMargin}`}>
        <Row className="d-flex w-75 shadow-lg">
                <Col className="col-md-6 justify-content-center d-flex flex-column text-left" style={{ background: "radial-gradient(124.94% 92.25% at 8.13% 89.83%, #D6C5F8 0%, #B0C0F2 33.09%, #7DB6F8 97%)",borderRadius: "20px 0px 0px 20px" }}>
                    <h3 className="text-white mb-3" style={{fontSize: '2rem'}}>Discover Your Path with CardinalTalent.AI</h3>
                    <h6 className="text-white mb-3">Seeking your next role, top talent, or building a dream team?</h6>

                    <p className="font-weight-bold mb-3">Select Your Role</p>
                    <Form className="space-y-6">
                            
                        {/* {['talent', 'recruiter', 'employer'].map((role) => ( */}
                            {['employer','recruiter','talent'].map((role) => (
                            <div className="bg-white py-2 mb-4 px-3 rounded-lg">
                                <Form.Check type="radio" name="role" label={role.charAt(0).toUpperCase() + role.slice(1)}
                                    id={`default-${role}`}
                                    value={role} // Set value to role
                                    checked={selectedRole === role}
                                    onChange={handleRoleChange}
                                />
                            </div>
                        ))}
                    </Form>
                </Col>

                <Col className="col-md-6 px-5 py-4">
                    <h5 className="font-weight-bold mb-3 text-left">Create {(selectedRole == 'employer') ? 'An' : 'A'} {capitalize(selectedRole)} Account</h5>

                    <div className="mb-3">
                      <SignUp userRole={selectedRole} />

                      <div className="d-flex align-items-center text-muted text-center my-2">
                        <span className="flex-grow-1 border-top"></span>
                        <span className="px-2">or</span>
                        <span className="flex-grow-1 border-top"></span>
                      </div>

                      <GoogleButton
                          onClick={() => handleGoogleSignIn()}
                        >
                        <img src={GoogleIcon} />
                        Sign up with Google
                      </GoogleButton>  

                      {/* <button type="button" onClick={() => handleGoogleSignIn()} className="btn-block btn btn-outline-primary"><span className="pr-2"><svg width="20" height="20" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4132_5805adfqfqdq121)"><path d="M32.2566 16.36C32.2566 15.04 32.1567 14.08 31.9171 13.08H16.9166V19.02H25.7251C25.5454 20.5 24.5866 22.72 22.4494 24.22L22.4294 24.42L27.1633 28.1L27.4828 28.14C30.5189 25.34 32.2566 21.22 32.2566 16.36Z" fill="#4285F4"></path><path d="M16.9166 32C21.231 32 24.8463 30.58 27.5028 28.12L22.4694 24.2C21.1111 25.14 19.3135 25.8 16.9366 25.8C12.7021 25.8 9.12677 23 7.84844 19.16L7.66867 19.18L2.71513 23L2.65521 23.18C5.2718 28.4 10.6648 32 16.9166 32Z" fill="#34A853"></path><path d="M7.82845 19.16C7.48889 18.16 7.28915 17.1 7.28915 16C7.28915 14.9 7.48889 13.84 7.80848 12.84V12.62L2.81499 8.73999L2.6552 8.81999C1.55663 10.98 0.937439 13.42 0.937439 16C0.937439 18.58 1.55663 21.02 2.63522 23.18L7.82845 19.16Z" fill="#FBBC05"></path><path d="M16.9166 6.18C19.9127 6.18 21.9501 7.48 23.0886 8.56L27.6027 4.16C24.8263 1.58 21.231 0 16.9166 0C10.6648 0 5.27181 3.6 2.63525 8.82L7.80851 12.84C9.10681 8.98 12.6821 6.18 16.9166 6.18Z" fill="#EB4335"></path></g><defs><clipPath id="clip0_4132_5805adfqfqdq121"><rect width="32" height="32" fill="white" transform="translate(0.937439)"></rect></clipPath></defs></svg>
                      </span>Continue with Google</button> */}
                    </div>

                    <p className="text-center mt-3 font-size-4">Already have an account? <a href="/users/sign_in">Sign In</a></p>
                </Col>
            </Row >
      </section>
    );
  };

export default SignUpV2
