import { Formik } from 'formik'
import React, { useState, useEffect, useReducer } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { AgencyContext } from '../../../stores/Agency/AgencyFormStore'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import TextInput from '../../common/TextInput/TextInput'
import { H1 } from '../Admin/styles/UserManagementEditPage.styled'
import { StyledForm } from '../CreateProfilePage/styles/CreateProfilePage.styled'
import '../Agency/styles/index.scss'

const ChangePasswordPage = ({user}) => {  
    useEffect(() => {
    if(user?.agency_password_changed){
        window.location.href ='/'
    }
    }, [])

    const onChangePassword = (value) => {
        let formData = new FormData()
        formData.append(`user[password]`, value?.password)
        formData.append(`user[password_confirmation]`, value?.confirm_password)
        const url = `/change/password/new.json`
        makeRequest(url, 'put', formData, {
            createResponseMessage: (res) => {
                return {
                    message: `${res?.msg}`,
                    messageType: 'success'
                }
            },
            onSuccess: (res) => {
                if(res.data.user.role == "employer"){
                    setTimeout(() => {
                        window.location.href ='/jobs'
                    }, 2000);
                }
                else if(res.data.user.role == "recruiter"){
                    setTimeout(() => {
                        window.location.href ='/complete/profile'
                    }, 2000);
                }
                else
                setTimeout(() => {
                    window.location.href ='/agencies/dashboard'
                }, 2000);
            },
        })
  }
    return (
        <AgencyContext.Provider>
        <div className="d-flex w-50 flex-column align-items-center justify-content-center pt-5 m-auto">
            <Formik
                initialValues={{
                    password: '',
                    confirm_password: ''
                }}
                validationSchema={Yup.object({
                    password: Yup.string()
                        .required('Password is required')
                        .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,25}$/,
                        'Password should have 8 to 25 characters, must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
                    ),
                    confirm_password: Yup.string()
                        .required('confirm Password is required')
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                })}
                onSubmit={(values) => {
                  onChangePassword({
                    password: values.password,
                    confirm_password: values.confirm_password
                  })
                }}
            >
                <StyledForm>
                    <Row>
                        <Col xs={12} sm={12} lg={8} className="m-auto">
                            <H1>Change Password</H1>
                        </Col>
                    </Row>
                    <Row className="w-100 d-flex justify-content-center">
                        <Col xs={12} sm={12} lg={6}>
                            <TextInput
                                label="New Password*"
                                name="password"
                                type="password"
                                id="password"
                                width="150%"
                            />
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                            <TextInput
                                label="Confirm Password*"
                                name="confirm_password"
                                type="password"
                                id="confirm_password"
                                width="150%"
                            />
                        </Col>
                    </Row>
                    <Row className="w-100">
                        <Col>
                            <div
                                style={{ marginTop: '18px' }}
                                className="d-flex justify-content-center"
                            >
                                <Button type="submit" className='button'>
                                    Update
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </StyledForm>
            </Formik>
        </div>
        </AgencyContext.Provider>
    )
}

export default ChangePasswordPage