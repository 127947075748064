import React, { useState, useEffect, useRef } from 'react'
import './styles/communities.scss'
import DocIcon from '../../../../assets/v2/imgs/docicon.png'
import PdfIcon from '../../../../assets/v2/imgs/pdficon.png'
import SweetAlert from 'react-bootstrap-sweetalert'

function MultiFileUpload({ setMultiFiles,attechedFileCount,setAttechedFileCount }) {
  const fileInputRef = useRef(null);
  const [fileLimitAlert, setFileLimitAlert] = useState('');
  const [filePreviews, setFilePreviews] = useState([]);
  const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png']; // Allowed MIME types
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const previews = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size >= 2000000) {
        setFileLimitAlert('Do not allow files larger than 2MB.');
        return; // Exit the function if file size exceeds limit
      }
      if (!allowedTypes.includes(file.type)) {
        setFileLimitAlert('Only PDF, DOC, JPG, and PNG files are allowed.');
        return; // Exit the function if file type is not allowed
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        previews.push({ file, previewUrl: event.target.result });
        if (previews.length === files.length) {
          setFilePreviews(filePreviews => [...filePreviews, ...previews]);
          setAttechedFileCount(parseInt(attechedFileCount)+1)
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleRemovePreview = (index) => {
    const updatedPreviews = [...filePreviews];
    updatedPreviews.splice(index, 1);
    setFilePreviews(updatedPreviews);
    setAttechedFileCount(parseInt(attechedFileCount)-1)
  };

  useEffect(() => {
    setMultiFiles(filePreviews)
  }, [filePreviews])

  return (
    <>
      {filePreviews.map((preview, index) => (
        <div className="col-lg-3 col-md-3">
          <div key={index} className="file-preview">
            <i onClick={() => handleRemovePreview(index)} className="fi fi-rr-trash upload_file_remove"></i>
            {preview.file.type &&
              (() => {
                switch (preview.file.type) {
                  case 'application/pdf':
                  case 'application/x-pdf': // Additional MIME type for PDF
                  case 'application/vnd.pdf': // Additional MIME type for PDF
                    return (
                      <img title={preview.file.name} src={PdfIcon} alt={preview.file.name} />
                    );
                  case 'application/msword':
                  case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  case 'application/vnd.ms-word.document.macroenabled.12': // Additional MIME type for DOCM (macro-enabled DOCX)
                    return (
                      <img title={preview.file.name} src={DocIcon} alt={preview.file.name} />
                    );
                  default:
                    return <img title={preview.file.name} src={preview.previewUrl} alt={preview.file.name} />;
                }
              })()
            }
          </div>
        </div>
      ))}
      {attechedFileCount <4 &&
        <div className="col-lg-3 col-md-3">
          <input accept='.pdf,.doc,.docx,image/*' type='file' ref={fileInputRef} onChange={handleFileInputChange} className='d-none'></input>
          <div className="card upload_file_ui" onClick={handleUploadClick}>
            <div x="50%" y="50%" fill="#dee2e6" dy=".3em">
              <svg viewBox="0 0 40 40" width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M40 22.8571H22.8571V40H17.1429V22.8571H0V17.1429H17.1429V0H22.8571V17.1429H40V22.8571Z"></path></svg>
              <span>Upload File</span>
            </div>
          </div>
        </div>
      }
      {fileLimitAlert != '' &&
        <SweetAlert
          warning
          confirmBtnBsStyle="primary"
          onConfirm={() => setFileLimitAlert('')} // Close the alert on confirm
        >
          {fileLimitAlert}
        </SweetAlert>
      }
    </>
  );
}
export default MultiFileUpload