import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import P from '../../Admin/shared/P'
import AsyncSelect from "react-select/async"
import CandidateSkills from '../../../common/CandidateSkills/CandidateSkills'
import Select from 'react-select'
import { Row,Col,Spinner,Modal } from 'react-bootstrap'
import '../../Admin/styles/Jobs.scss'
import { Formik } from 'formik'
import SearchTextBox from '../../Admin/shared/SearchTextBox'
import { capitalizeFirstLetter } from '../../../../utils'
import DataTable from "react-data-table-component-footer"
import   '../../Admin/styles/DataTableCustom.scss'
import feather from 'feather-icons'
import Button from '../../Admin/shared/Button'
import Card from '../../Admin/shared/Card'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import CandidateUpload from '../../Admin/Jobs/CandidateUpload'

const JobsIndex = () => {
    var active_page = window.localStorage.getItem('agency_admin_activePage')
    var query = window.localStorage.getItem('agency_admin_query')
    var status = window.localStorage.getItem('agency_admin_status')
    var organization = window.localStorage.getItem('agency_admin_organization')
    var owner = window.localStorage.getItem('agency_admin_owner')
    var wishlist = window.localStorage.getItem('agency_admin_wishlist')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [pageCount, setPageCount] = useState(0)
    const [jobs, setJobs] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [isLoading, setLoading] = useState(false)
    let organization_data = organization && JSON.parse('['+organization+']')
    let owner_data = owner && JSON.parse(owner)
    const [selectedOrganization, setSelectedOrganization]  = useState(organization_data ? organization_data.map( ({value, label}) =>value) :[])
    const [filterOrganization, setFilterOrganization] = useState(organization_data && organization_data || [])
    const [filterOwner, setFilterOwner] = useState(owner_data && owner_data)
    const [selectedStatus, setSelectedStatus]  = useState('all')
    const [member_options, setOptions] = useState([])
    const [owner_options, setOwnerOptions] = useState([])
    const [selectedOwner, setSelectedOwner] = useState(owner_data && owner_data.value)
    const [selected, setSelected] = useState({value: status || 'all', label: status && capitalizeFirstLetter(status) || 'All'})
    const [selectedWishlist, setSelectedWishlist] = useState({value: wishlist || 'all', label: (wishlist) &&  (wishlist == 'all' ? 'All' : ((wishlist == 'true' ? 'Yes' : 'No') ) || 'All')})
    const [totalJobs,setTotalJobs] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [loading, setLoader] = useState(true)
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const [selectedWishlistSourcing, setSelectedWishlistSourcing]  = useState(wishlist || 'all')
    const [sortField, setSortField] = useState('jobs.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(25)
    const [show,setShow] = useState(false)
    const [selectJob,setSelectJob] = useState(false)

    useEffect(() => {
        feather.replace()
    })

    const fetchData = (clearFlag=false) => {
        setLoader(true)
        const url = `/agencies/admin/reports/jobs.json`
        window.localStorage.setItem('agency_admin_activePage',activePage)
        makeRequest(url, 'get',  {params: {page: (activePage), query: query, status: status, organization: selectedOrganization, owner: selectedOwner, wishlist: selectedWishlistSourcing, sortField: sortField, sortDirection: sortDirection }}, {   
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setTotalJobs(res.data.total_count)
                let sr_no = (activePage - 1) * res.data.per_page
                setJobs(
                    res.data.jobs.map((job) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        id: job.id,
                        agecny_name: job.agecny_name,
                        name:(<a>{capitalize(job.name)}</a>),
                        skills:  (job.skills != '') ? <CandidateSkills skills = {job.skills} /> :job.skills,
                        organization: (<a>{job.organization_name}</a>),
                        expiry_date:  moment(job.expiry_date).format('MMM DD, YYYY'),
                        created_at: moment(job.created_at).format('MMM DD, YYYY'),
                        status:  (<label style={{color: (job.status == "closed" || job.status == "expired") ? "red" :""  }}>{capitalize(job.status)}</label>),
                        create_lead: (job.create_lead === true) ? 'True' : 'False',
                        organization_id: job.organization_id,
                        full_name: (job?.first_name) ? `${job?.first_name} ${job?.last_name}` : '',
                        wishlist_sourcing: (job.source_at) ? 'Yes' : 'No'
                    }))
                )
                setPageCount(res.data.total_pages)
                setCurrentCounts(res.data.current_counts)
                setStartRecord((activePage - 1) * res.data.per_page)
                setLoader(false)
            },
        })
    }
    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '4%',
            minWidth: '4%',
        },
        {
            name: 'Agency Name',
            selector: row => row.agecny_name,
            wrap:true,
            maxWidth: '14%',
            minWidth: '14%',
            sortable: true,
            sortField: 'agencies.name',
        },
        {
            name: 'Job Name',
            selector: row => row.name,
            wrap:true,
            maxWidth: '12%',
            minWidth: '12%',
            sortable: true,
            sortField: 'jobs.name',
        },
        {
            name: 'Primary Owner',
            selector: row => row.full_name,
            wrap:true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'users.first_name',
        },
        {
            name: 'Skills',
            selector: row => row.skills,
            wrap:true,
            maxWidth: '16%',
            minWidth: '16%',
        },
        {
            name: 'Organization',
            selector: row => row.organization,
            wrap:true,
            maxWidth: '18%',
            minWidth: '18%',
            sortable: true,
            sortField: 'organizations.name',
        },
        {
            name: 'Expiry On',
            selector: row => row.expiry_date,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'jobs.expiry_date',
        },
        {
            name: 'Created On',
            selector: row => row.created_at,
            wrap:true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'jobs.created_at',
        },
        {
            name: 'Status',
            selector: row => row.status,
            maxWidth: '6%',
            minWidth: '6%'
        }
    ]

    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,selectedStatus,selectedOrganization, selectedOwner,selectedWishlistSourcing,sortDirection,sortField])    

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/agencies/admin/reports/fetch_agency_organization?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {organizations} = res
          setLoading(false)
          setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(member_options);
    }

    const loadOwnerOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/agencies/admin/reports/job_owner?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {owners} = res
          setLoading(false)
          setOwnerOptions([...owners.map((owner) => ({ value: owner.id, label: `${owner.first_name} ${owner.last_name}` }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(owner_options);
    }

    const handleOwnerOption = (selectedOptions) => {
        setSelectedOwner(selectedOptions && selectedOptions.value)
        setActivePage(1)
        window.localStorage.setItem('agency_admin_activePage',1)
        window.localStorage.setItem('agency_admin_owner', JSON.stringify(selectedOptions && selectedOptions || ''))
    }
    
    const handleSelectOption = (selectedOptions) => {
        console.log(selectedOptions)
        setSelectedOrganization([...selectedOptions.map( ({value, label}) =>value)])
        // setFilterOrganization([...selectedOptions.map( ({value, label}) => ([...{'value':value, 'label':label}]))])
        setActivePage(1)
        window.localStorage.setItem('agency_admin_activePage',1)
        window.localStorage.setItem('agency_admin_organization',[...selectedOptions.map( ({value, label}) => JSON.stringify({'value':value, 'label':label}))])
    }
    const handleSelectStatus = (selectedval) =>{
        setActivePage(1)
        window.localStorage.setItem('agency_admin_activePage',0)
        setSelectedStatus(selectedval.value)
        window.localStorage.setItem('agency_admin_status',selectedval.value)
    }
    const handleSelectedWishlistSourcing = (selectedval) => {
        setActivePage(1)
        window.localStorage.setItem('agency_admin_activePage',0)
        setSelectedWishlistSourcing(selectedval.value)
        window.localStorage.setItem('agency_admin_wishlist',selectedval.value)
    }
    const filterOptions = [
        { value: 'all', label: 'All'},
        { value: 'active', label: 'Active' },
        { value: 'expired', label: 'Expired' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'closed', label: 'Closed' }
    ]
    const filterOptionsWishlist = [
        { value: 'all', label: 'All'},
        { value: 'true', label: 'Yes'},
        { value: 'false', label: 'No'}
    ]

    const onSearchChange = (val) =>{
        setSearchTerm(val)
        window.localStorage.setItem('agency_admin_query', val)
    }

    const onSearchClick = (val) => {
        if(searchTerm != ""){
            setSearchTerm("")
            window.localStorage.setItem('agency_admin_query', "")
            fetchData(true)
        }
    }

    const colourStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px' })
    };

    const colourStatusStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px' })
    };

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '20px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Jobs List
                </P>              
            </div>
            <Row className="w-100 justify-content-between" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline',flexDirection: 'row-reverse' }}>
            <Col xs={12} sm={12} lg={2} style={{display:'contents'}}>
                <div>
                    <Formik>
                        <SearchTextBox type="search" value={searchTerm} name="jobsearch" placeholder="Search job..."  onChange={(e) => onSearchChange(e.target.value)}  onClick={(e) => onSearchClick(e.target.value)}  onKeyPress={(e) => {
                            window.localStorage.setItem('agency_admin_activePage',0)
                            setActivePage(1)
                            e.key === 'Enter' && fetchData()
                    }}   />
                    </Formik>
                    <Button onClick={() =>
                            activePage == 0
                                ? fetchData()
                                : setActivePage(1)
                        } className="" style={{marginLeft: '10px'}}>
                        Search
                    </Button>
                </div>
                </Col>
            </Row>
            <Row className="w-100 justify-content-between mb-2" style={{ display: 'flex',alignItems: 'baseline', }}>
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    {totalJobs > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {totalJobs > currentCounts ? currentCounts : totalJobs }  of {totalJobs}</DisplayPagination>
                    }
                 </Col>
                 
                 <Col xs={12} sm={12} lg={2}>
                    <AsyncSelect
                        isMulti
                        // isLoading={isLoading}
                        isClearable={true}
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={handleSelectOption}
                        placeholder={'Organization'}
                        defaultValue={filterOrganization}
                        styles={colourStyles}
                        noOptionsMessage={() => 'start typing the name of organization'}
                    />
                 </Col>
                 <Col xs={12} sm={12} lg={2}>
                    <AsyncSelect
                        // isLoading={isLoading}
                        cacheOptions
                        isClearable={true}
                        loadOptions={loadOwnerOptions}
                        onChange={handleOwnerOption}
                        placeholder='Primary owner'
                        defaultValue={filterOwner}
                        styles={colourStyles}
                        noOptionsMessage={() => 'start typing the name of owner'}
                    />
                 </Col>
                <Col xs={12} sm={12} lg={2}>
                    <Select 
                    defaultValue={selected}
                    options={filterOptions} 
                    onChange={handleSelectStatus} 
                    placeholder={'Select Status'} 
                    styles={colourStatusStyles}
                    />
                </Col>
                <Col xs={12} sm={12} lg={3}>
                    <Select
                    defaultValue={selectedWishlist}
                    options={filterOptionsWishlist} 
                    onChange={handleSelectedWishlistSourcing} 
                    placeholder={'Sourcing Criteria'} 
                    styles={colourStyles}
                    />
                </Col>
            </Row>
            <DataTable
                title={false}
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobs}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalJobs}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
            />   

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size={'xl'}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <Modal.Body>
                <CandidateUpload
                    job={selectJob}
                    onClose={() => setShow(false)}
                />
                </Modal.Body>
            </Modal>
        </Card>
    )
}

export default JobsIndex