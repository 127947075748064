import React, { useEffect, useState, useRef } from 'react'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import Util from "../../../utils/util";
import Card from './shared/Card'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import './styles/MixmaxAnalyticCustom.scss'
import feather from 'feather-icons'
import SweetAlert from 'react-bootstrap-sweetalert'

const MixmaxJobAnalytics = ({job_list,per_page}) => { 

   const [selectedJobId, setSelectedJobId] = useState('')
   const [jobs,setJobs] = useState(job_list)
   const [loading, setLoading] = useState(true)
   const [sequenceDetail, setSequenceDetail] = useState({})
   const [sequenceRecipient, setSequenceRecipient] = useState([])
   const [candidate, setCandidate] = useState([])
   const [activePage, setActivePage] = useState(1)
   const [totalCandiadate, setTotalCandiadate]  = useState(0)
   const [perPage, setPerPage] = useState(10)
   const [showEmailTemplate, setShowEmailTemplate] = useState(false)
   const [emailTeplateValue,setEmailTeplateValue] = useState(false)
   const [showWarning, setShowWarning] = useState(false)
  
  useEffect(() => {
    feather.replace()
  })

  const fetchData = (id) => {
    setLoading(true)
    const url = `/admin/mixmax_job_analytic.json`
    makeRequest(`${url}`, 'get',  {params: {job_id:id}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
         if(res?.data?.api_data && JSON.parse(res.data.api_data.sequnce_detail)?.name){  
             setSequenceRecipient(JSON.parse(res.data.api_data.sequnce_recipients))
             setSequenceDetail(JSON.parse(res.data.api_data.sequnce_detail))
             let sr_no = (activePage - 1) * perPage
             setCandidate(
              JSON.parse(res.data.api_data.sequnce_recipients).map((recipient) => (sr_no = sr_no + 1,{
                  sr_no: sr_no,
                  name: recipient.to.name,
                  email:recipient.to.email,
                  email_1_sent: recipient?.stages[0]?.sentAt && moment(recipient?.stages[0]?.sentAt).format('MMM DD,YYYY hh:mm A'),
                  email_2_sent: (recipient?.stages[1]?.sentAt &&  recipient?.stages[1]?.sentAt != null) ? moment(recipient?.stages[1]?.sentAt).format('MMM DD,YYYY hh:mm A') : '',
                  email_3_sent: (recipient?.stages[2]?.sentAt &&  recipient?.stages[2]?.sentAt != null) ? moment(recipient?.stages[2]?.sentAt).format('MMM DD,YYYY hh:mm A') : '',
                  email_2_schedule: recipient?.stages[1]?.scheduledAt && moment(recipient?.stages[1]?.scheduledAt).format('MMM DD,YYYY hh:mm A'),
                  email_3_schedule: recipient?.stages[2]?.scheduledAt && moment(recipient?.stages[2]?.scheduledAt).format('MMM DD,YYYY hh:mm A'),
                  email_1_replay: (recipient?.stages[0]?.replied && recipient?.stages[0]?.replied > 0) ? (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="check"></i></div>) : (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="x"></i></div>),
                  email_2_replay:  (recipient?.stages[1]?.sentAt) ? (recipient?.stages[1]?.replied && recipient?.stages[1]?.replied > 0) ? (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="check"></i></div>) : (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="x"></i></div>) : '',
                  email_3_replay:  (recipient?.stages[2]?.sentAt) ? (recipient?.stages[2]?.replied && recipient?.stages[2]?.replied > 0) ? (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="check"></i></div>) : (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="x"></i></div>) : '',
                  bounse: (recipient?.stages[2]?.bounced && recipient?.stages[2]?.bounced)  + (recipient?.stages[2]?.bounced && recipient?.stages[2]?.bounced)  + (recipient?.stages[2]?.bounced && recipient?.stages[2]?.bounced),
                  email_1_open: (recipient?.stages[0]?.opens && recipient?.stages[0]?.opens > 0) ? (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="check"></i></div>) : (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="x"></i></div>),
                  email_2_open:  (recipient?.stages[1]?.sentAt) ? (recipient?.stages[1]?.opens && recipient?.stages[1]?.opens > 0) ? (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="check"></i></div>) : (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="x"></i></div>) : '',
                  email_3_open:  (recipient?.stages[2]?.sentAt) ? (recipient?.stages[2]?.opens && recipient?.stages[2]?.opens > 0) ? (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="check"></i></div>) : (<div className='w-100 d-inline-flex justify-content-around'><i data-feather="x"></i></div>) : '',  
                }))
              )
              setTotalCandiadate(JSON.parse(res.data.api_data.sequnce_recipients).length)
              setLoading(false)
          }else{
            setShowWarning(true)
          }
          
      },
    })
  }

  const onSelecteJob = (event) => {
    setSelectedJobId(event.value)
    fetchData(event.value)
  }

  const onTemplateView = (template) => {
    setShowEmailTemplate(true)
    setEmailTeplateValue(template)

  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'22em',maxWidth:'22em',marginLeft:'10px',minHeight:'40px' })
  }

  const  columns= [
    {
        name: 'No.',
        selector: row => row.sr_no,
        maxWidth: '4%',
        minWidth: '4%',
    },
    {
        name: 'Name',
        selector: row => row.name,
        maxWidth: '10%',
        minWidth: '10%',
        wrap:true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        maxWidth: '15%',
        minWidth: '15%',
        wrap:true,
        sortable: true,
        sortField: 'users.email',
    },
    {
        name: 'Email-1 Sent',
        selector: row => row.email_1_sent,
        maxWidth: '12%',
        minWidth: '12%',
        wrap:true,
    },
    {
      name: 'Email-1 Open',
      cell: row => row.email_1_open,
      maxWidth: '5%',
      minWidth: '5%',
      wrap:true,
    },
    {
        name: 'Email-1 Reply',
        selector: row => row.email_1_replay,
        maxWidth: '5%',
        minWidth: '5%',
        wrap:true,
    },
    {
        name: 'Email-2 Scheduled/Sent',
        selector: row => (row.email_2_sent != '' ? row.email_2_sent : row.email_2_schedule),
        maxWidth: '12%',
        minWidth: '12%',
        wrap:true,
    },
    {
      name: 'Email-2 Open',
      selector: row => row.email_2_open,
      maxWidth: '5%',
      minWidth: '5%',
      wrap:true,
    },
    {
        name: 'Email-2 Reply',
        selector: row => row.email_2_replay,
        maxWidth: '5%',
        minWidth: '5%',
        wrap:true,
    },
    {
        name: 'Email-3 Scheduled/Sent',
        selector: row => (row.email_3_sent != '' ? row.email_3_sent : row.email_3_schedule),
        maxWidth: '12%',
        minWidth: '12%',
        wrap:true,
    },
    {
      name: 'Email-3 Open',
      selector: row => row.email_3_open,
      maxWidth: '5%',
      minWidth: '5%',
      wrap:true,
    },
    {
        name: 'Email-3 Reply',
        selector: row => row.email_3_replay,
        maxWidth: '5%',
        minWidth: '5%',
        wrap:true,
    },
    {
        name: 'Bounce Email',
        selector: row => row.bounse,
        maxWidth: '5%',
        minWidth: '5%',
    }
  ]

  return (
    <>
    <Card style={{minHeight:'580px !important'}}>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
         Mixmax Job Analytics
        </P>
      </div>
      
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="9" md="9" sm="9" xs="9" className='d-flex align-items-end'>
            
        </Col>
        <Col lg="3" md="3" sm="3" xs="3">
            <label><b>Select Job </b> &nbsp; :</label>
            <Select
                options={jobs}
                onChange={onSelecteJob}
                name="job"
                style={colourStyles}
            />
         </Col>
      </Row>
      { loading == false && 
        <>
          <Row className="w-100" style={{ marginBottom: '10px' }}>
              <Col  lg="12" md="12" sm="12" xs="12">
                <div>
                      <h5>{sequenceDetail?.name }</h5>
                  </div>
              </Col>
          </Row>
          <Row className="w-100" style={{ marginBottom: '10px',marginTop: '20px' }}>
                <Col  lg="4" md="4" sm="12" xs="4" className='top-div'>
                    <div className="card text-center">
                      <div className="card-header">
                        Email-1
                      </div>
                      { (sequenceDetail?.stages && sequenceDetail?.stages[0])  && 
                        <>
                        <div className="card-body card-body-height">
                            <div>
                              <label><b>Created at : </b></label> <span> {sequenceDetail?.stages && moment(sequenceDetail?.stages[0]?.createdAt).format('MMM DD,YYYY hh:mm A') } </span>
                            </div>
                        </div>
                        <div className="card-footer text-muted">
                          <button className='btn btn-info' onClick={ () => onTemplateView(sequenceDetail?.stages && sequenceDetail?.stages[0])}>Email Template</button>
                        </div>
                        </>
                      }
                    </div>
                </Col>
                <Col  lg="4" md="4" sm="12" xs="4" className='top-div'>
                    <div className="card text-center">
                      <div class="card-header">
                         Email-2
                      </div>
                      { (sequenceDetail?.stages && sequenceDetail?.stages[1])  && 
                        <>
                        <div className="card-body card-body-height">
                          <div>
                            <label><b> Created at : </b></label> <span>{sequenceDetail?.stages &&   moment(sequenceDetail?.stages[1]?.createdAt).format('MMM DD,YYYY hh:mm A') }</span>
                            </div>
                            <div>
                              <label><b>Schedule at :  </b></label> <span> Sent email If candidate no reply of first email in  { sequenceDetail?.stages &&  sequenceDetail?.stages[1]?.schedule?.offset}  <b>{  sequenceDetail?.stages &&  sequenceDetail?.stages[1]?.schedule?.unit}</b></span>
                            </div>
                            <div>
                              <label><b>Trigger :  </b></label> <span> { (String(sequenceDetail?.stages[1]?.trigger) === 'noReply') ? 'No Reply' : sequenceDetail?.stages[1]?.trigger }</span>
                            </div>
                        </div>
                        <div className="card-footer text-muted">
                            <button className='btn btn-info' onClick={ () => onTemplateView(sequenceDetail?.stages && sequenceDetail?.stages[1])}>Email Template</button>
                        </div>
                      </>
                      }
                    </div>
                </Col>
                <Col  lg="4" md="4" sm="12" xs="4" className='top-div'>
                    <div className="card text-center">
                      <div className="card-header">
                         Email-3
                      </div>
                        { (sequenceDetail?.stages && sequenceDetail?.stages[1])  && 
                          <>
                            <div class="card-body card-body-height">
                                <div>
                                  <label><b> Created at : </b></label> <span>{ sequenceDetail?.stages &&  moment(sequenceDetail?.stages[2]?.createdAt).format('MMM DD,YYYY hh:mm A') }</span>
                                </div>
                                <div>
                                  <label><b> Schedule at : </b></label> <span>Sent email If candidate no reply of second email in { sequenceDetail?.stages && sequenceDetail?.stages[2]?.schedule?.offset}  {  sequenceDetail?.stages &&  sequenceDetail?.stages[2]?.schedule?.unit}</span>
                                </div>
                                <div>
                                  <label><b> Trigger :</b> </label> <span>{ sequenceDetail?.stages && (sequenceDetail?.stages[2]?.trigger == 'noReply') ? 'No Reply' : sequenceDetail?.stages[2]?.trigger }</span>
                                </div>
                            </div>
                            <div className="card-footer text-muted">
                            <button className='btn btn-info' onClick={ () => onTemplateView(sequenceDetail?.stages && sequenceDetail?.stages[2])}>Email Template</button>
                            </div>
                          </>
                        }
                    </div>
                </Col>
          </Row>
          <Row className="w-100" style={{ marginBottom: '10px', marginTop: '20px' }}>
              <Col  lg="9" md="9" sm="9" xs="9">
                  <div>
                      <h5>Candidate Details </h5>
                  </div>
              </Col>
          </Row>
          <Row className="w-100" style={{ marginBottom: '10px' }}>
              <Col  lg="12" md="12" sm="12" xs="12">
                <DataTable
                  title={false}
                  persistTableHead={true}
                  noDataComponent={<p className="text-center p-3">No record found</p>}
                  columns={columns}
                  data={candidate}
                  progressPending={loading}
                  progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                      <Spinner className='spinner' animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                      </Spinner>
                  </P>}
                  highlightOnHover
                  pagination
                  paginationTotalRows={totalCandiadate}
                  paginationPerPage={perPage}
                  paginationComponentOptions={{
                      noRowsPerPage: true
                  }}
                  onChangePage={page => setActivePage(page)}
                />
              </Col>
          </Row> 
        </>
      }
     
    </Card>
      {showEmailTemplate &&
          <SweetAlert
              confirmBtnBsStyle={'secondary'}
              onCancel={()=>setShowEmailTemplate(false)}
              onConfirm={()=>setShowEmailTemplate(false)}
          >
            <div style={{textAlign:'left'}}>
                <h4>Email Template</h4>
                <hr/>
              <div>
                  <label><b>Subject : </b></label>
                  <div><p className='sweet-alert-text-custom'>{emailTeplateValue.subject}</p></div> 
              </div>
                <br/>
              <div>
                  <label><b>Body : </b></label>
                  <div dangerouslySetInnerHTML={{ __html: emailTeplateValue.body }}  className='sweet-alert-text-custom'/> 
              </div>
              <hr/>
            </div>
          </SweetAlert>
      }
      {showWarning &&
          <SweetAlert
              warning
              title=''
              confirmBtnBsStyle={'secondary'}
              onConfirm={ () => setShowWarning(false)}
          >
            <div style={{textAlign:'center'}}>
                <h4>No Sequence Found !</h4>
                 <br/>
                 <p>Something wrong, Please contact to adminstration.</p>
            </div>
          </SweetAlert>
      }
    </>
  )
}

export default MixmaxJobAnalytics
