import React, { useState, useRef, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import styles from '../SignupPage/styles/Signup.module.scss'
import {Button,H1,Logo,Message,P,StyledForm,A,InfoText,Row} from './styles/CreateProfilePage.styled'
import {useCalendlyEventListener, InlineWidget} from "react-calendly"
import axios from 'axios'
import './styles/CalanderBookSlot.scss'

const CreateBookSlotPage = ({
    formData,
    setFormData,
    submitData,
    loading,
    result,
    setResult,
    selectedRole,
    hidePrevious,
    showInfoPanel
}) => {
    const [roleDescription, setRoleDescription] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [defaultType, setDefaultType] = useState('contingency')

    useEffect(() => {
        setResult({})
        if (isSubmitted) {
            submitData()
        }
        setIsSubmitted(false)
    }, [isSubmitted])

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            organization: {
                ...prev.organization,
                organization_type: defaultType,
            },
        }))
        fetchLookUp()
    }, [defaultType])

    const handleOnSubmit = (values) => {
        setIsSubmitted(true)
    }

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => console.log(e.data),
      });
    
    const fetchLookUp = async () => {
        const url = `/signup/contracts?name=org_note&role=${selectedRole}`
        await axios.get(url).then((res) => {
            setRoleDescription(res.data?.content)
        })

    }

    return (
        <>
            <div className={`${styles.signUpForm}`}>
                <MainPanel>
                    <H1>Intro with our Customer Success Manager</H1>

                    <Formik
                         initialValues={{
                            organization_type: 'contingency',
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            handleOnSubmit(values)
                        }}
                    >
                        <StyledForm display="flex" direction="column"  style={{height:'auto'}}>
                            <Row jContent="space-between">
                                <Col xs={12} lg={12} >
                                     <InlineWidget url="https://calendly.com/jinay_ahir/30min?month=2022-10"  styles={{height: '1020px'}}/>
                                </Col>
                            </Row>
                         
                            <Row jContent="space-between" className={`${styles.cardOrgBtnDivNew}`}>
                                <Col xs={12} lg={6} >
                                    <A
                                        style={{ marginRight: '20px' }}
                                        onClick={() =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                step: 'ORGANIZATION_TYPE',
                                            }))
                                        }
                                    >
                                    {!hidePrevious &&  'Previous'}
                                    </A>
                                </Col>
                                <Col xs={12} lg={6} >
                                    <Button type="submit" disabled={loading} className={`${styles.cardOrgBtn}`}>
                                        {loading ? (
                                            <Spinner
                                                animation="border"
                                                variant="light"
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                            {result && (
                                <Row jContent="center">
                                    <Message color={result.color}>
                                        {result.message}
                                    </Message>
                                </Row>
                            )}
                        </StyledForm>
                    </Formik>
                </MainPanel>
                { showInfoPanel &&
                    <InfoPanel>
                        <div className={`${styles.infopanelDiv}`}>
                            <div className="d-flex flex-column">
                                <P>Note</P>
                                <div
                                    style={{
                                        border: '1px solid #BFC5E2',
                                        width: '100%',
                                        marginBottom: '30px',
                                    }}
                                ></div>
                                <P
                                    weight={'normal'}
                                    dangerouslySetInnerHTML={{
                                        __html: roleDescription,
                                    }}
                                ></P>
                            </div>
                        </div>
                    </InfoPanel>
                }
            </div>
        </>
    )
}

export default CreateBookSlotPage
