import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/Team'
import Button from '../../Admin/shared/Button'
import Util from "../../../../utils/util";
import feather from 'feather-icons'
import isEmpty from 'lodash.isempty'

const TeamMember = ({recruiters,teamEntry,onHide,fetchData}) => {
	const [validationError, setValidationError] = useState({})
	const [teamMembers, setTeamMembers] = useState([])
	const [btnDisabled, setBtnDisabled] = useState(false)
	const [members, setMembers] = useState([])
	const handleChange = (e) => {
		setMembers([...e.map( ({value, label}) =>value)])
		setValidationError({})
	}
	const [modal,setModal] = useState('')
	let i = 1;
	useEffect(() => {
		setTeamMembers(
			teamEntry?.member?.map((team) => ({
				id: team.id,
				user_id: team?.user?.id,
				name: Util.fullName(team?.user?.first_name,team?.user?.last_name),
			}))
		)
	}, [])

	useEffect(() => {
		setModal(document.querySelector('.modal'))
		feather.replace()
	})
			
	const handleSubmit = (e) => {
			e.preventDefault()
			setBtnDisabled(true)
			if(validateForm(e)){
					let URL = '/agencies/team_members'
					const payload = new FormData()
					payload.append('team_member[user_id]', members)
					payload.append('team_member[status]', 'active')
					payload.append('team_member[team_id]', teamEntry.id)
					//headers
					const CSRF_Token = document
							.querySelector('meta[name="csrf-token"]')
							.getAttribute('content')
					const headers = {
							headers: {
									'content-type': 'application/json',
									'X-CSRF-Token': CSRF_Token,
							},
					}
					makeRequest(URL,'POST', payload, headers, {
							contentType: 'application/json',
							loadingMessage: 'Submitting...',
					}).then((res) => {
							if(res.data.success){
									onHide()
									fetchData()
							}
					}) 
			}
			setBtnDisabled(false)
	}

	const validateForm = (e) => {
			let errors = {}
			let formIsValid = true
			if(isEmpty(members)){
					formIsValid = false;
					errors["user"] = "Team Members is required field."
			}
			setValidationError(errors)
			setBtnDisabled(formIsValid)
			return formIsValid;
	}

	const deleteTeamMember = async (id) => {
		const URL = `/agencies/team_members/${id}`
		makeRequest(URL, 'delete', '', {
			contentType: 'application/json',
			loadingMessage: 'Submitting...',    
		}).then((res) => {
			if(res.data.success){
					onHide()
					fetchData()
			}
		})   
	}    

	return (
		<>
			<Container> 
				<form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
					<Row className="w-100">
						<Col xs={12} sm={12} lg={10}>
							<span>Select Team Members* : </span>
							<Select
								options={recruiters.filter((row) => row.value != teamEntry.owner_id)}
								name='user'
								id='user'
								placeholder={'Select Team Members'}
								onChange={handleChange}
								isMulti
								menuPortalTarget={modal}
							/>
							<label className='error'>
									{validationError && validationError.user} 
							</label>
						</Col>
						<Col xs={12} sm={12} lg={2}>
								<Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius' style={{marginTop:'20px',float:'right'}}>Save</Button>
						</Col>
					</Row>
					<Row className="w-100 modal-entry-form-row mt-4" >
						<Col xs={12} sm={12} lg={12}>
							<div className='table-responsive'>
								<table className="table table-bordered table-striped table-hover">
									<thead className="table-active">
										<tr className="tr">
												<th>No.</th>
												<th>Name</th>
												<th>Action</th>
										</tr>
									</thead>
									<tbody>
										
										{teamMembers?.map((team,key) => (
											(team?.user_id != teamEntry?.owner_id) ?
												<tr key={key+1}>
													<td>{i++}</td>
													<td>{team.name}</td>
													<td>
														<span onClick={() => deleteTeamMember(team.id)}  style={{cursor: 'pointer'}}>
																<i size='20' color="#4C68FF" data-feather='trash-2'/>
														</span>
													</td>
												</tr>
											: ''
										))}
									</tbody>
								</table>
							</div>							
						</Col>
					</Row>
				</form>
			</Container>
		</>
  )
}

export default TeamMember