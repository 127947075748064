import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/SubscriptionPlan'
import Button from '../shared/Button'
import { CKEditor } from 'ckeditor4-react';

const SendEmailModal = ({ planDetail, onHide }) => {
  const [validationError, setValidationError] = useState({})
  const [descriptionAdd, setDescriptionAdd] = useState('<p>Hi&nbsp;{{first_name}},<br /><br />I hope this message finds you well. It was great speaking with you earlier!<br /><br />I wanted to follow up with the payment link for our coaching and advancement agreement. Please take a moment to review the agreement I&#39;ve sent to you in the previous email thread and proceed with the payment using the link below:<br /><br />Payment Link:&nbsp;&nbsp;<a href="{{payment_link}}" target="_blank">{{payment_link}}</a><br /><br />Once you&#39;ve completed the payment and signed the agreement, kindly let me know, and we&#39;ll move forward from there.<br /><br />Should you have any questions or need further clarification, please don&#39;t hesitate to reach out. I&#39;m here to assist you every step of the way.<br /><br />Looking forward to hearing from you soon!<br /><br />Best regards,<br />Paul</p>')
  const [modal, setModal] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(false)
  const handleChange = (e) => {
    setValidationError({})
  }
  useEffect(() => {
    setModal(document.querySelector('.modal'))
  })
  const [entryData, setEntryData] = useState({
    email_subject: 'Payment Link: Coaching + Advancement Agreement',
    email_body: '<p>Hi&nbsp;{{first_name}},<br /><br />I hope this message finds you well. It was great speaking with you earlier!<br /><br />I wanted to follow up with the payment link for our coaching and advancement agreement. Please take a moment to review the agreement I&#39;ve sent to you in the previous email thread and proceed with the payment using the link below:<br /><br />Payment Link:&nbsp;&nbsp;<a href="{{payment_link}}" target="_blank">{{payment_link}}</a><br /><br />Once you&#39;ve completed the payment and signed the agreement, kindly let me know, and we&#39;ll move forward from there.<br /><br />Should you have any questions or need further clarification, please don&#39;t hesitate to reach out. I&#39;m here to assist you every step of the way.<br /><br />Looking forward to hearing from you soon!<br /><br />Best regards,<br />Paul</p>',
  })
  const handleSubmit = (e) => {
    e.preventDefault()    
    setBtnDisabled(true)
    if (validateForm(e)) {
      let URL = '/payment_link_email_sent'
      const payload = new FormData()
      payload.append('subscription_plan_email_sent[first_name]', e?.target?.first_name?.value?.trim())
      payload.append('subscription_plan_email_sent[last_name]', e?.target?.last_name?.value?.trim())
      payload.append('subscription_plan_email_sent[email_body]', descriptionAdd)
      payload.append('subscription_plan_email_sent[email_subject]', e?.target?.email_subject?.value?.trim())
      payload.append('subscription_plan_email_sent[to_email]', e?.target?.to_email?.value?.trim())
      payload.append('subscription_plan_email_sent[subscription_plan_id]', planDetail?.id)
      payload.append('subscription_plan_email_sent[payment_link]', planDetail?.payment_link)

      //headers
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      const headers = {
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
        },
      }
      makeRequest(URL, 'POST', payload, headers, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
      }).then((res) => {
        if (res.data.success) {
          onHide()
        }
      })
    }
    setBtnDisabled(false)
  }

  const validateForm = (e) => {
    let errors = {}
    let formIsValid = true
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!e?.target?.to_email?.value?.trim()) {
      formIsValid = false;
      errors["to_email"] = "To email is required field."
    } else if (!emailRegex.test(e?.target?.to_email?.value?.trim())) {
      formIsValid = false;
      errors["to_email"] = "Please Enter Valid To Email."
    }
    if (!descriptionAdd?.trim()) {
      formIsValid = false;
      errors["email_body"] = "Email Body is required field."
    }
    if (!e?.target?.first_name?.value?.trim()) {
      formIsValid = false;
      errors["first_name"] = "first name is required field."
    }
    if (!e?.target?.last_name?.value?.trim()) {
      formIsValid = false;
      errors["last_name"] = "Last name is required field."
    }
    if (!e?.target?.email_subject?.value?.trim()) {
      formIsValid = false;
      errors["email_subject"] = "Subject is required field."
    }
    setValidationError(errors)
    setBtnDisabled(formIsValid)
    return formIsValid;
  }

  const handleEditorChange = (content) => {
    setDescriptionAdd(content.editor.getData())
    setValidationError('')
  }
 
 return (
    <>
      <Container>
        <form onSubmit={(e) => { handleSubmit(e) }} style={{ width: "100%" }}>
          <Row className="w-100">
            <Col xs={6} sm={6} lg={6} md={6}>
              <span>First Name* : </span>
              <input type='text'  onChange={handleChange} className='form-control w-100' name='first_name' id="first_name" />
              <label className='error'>
                {validationError && validationError.first_name}
              </label>
            </Col>
            <Col xs={6} sm={6} lg={6} md={6}>
              <span>Last Name* : </span>
              <input type='text'  onChange={handleChange} className='form-control w-100' name='last_name' id="last_name" />
              <label className='error'>
                {validationError && validationError.last_name}
              </label>
            </Col>
            <Col xs={12} sm={12} lg={12} md={12}>
              <span>To Email* : </span>
              <input type='email' onChange={handleChange} className='form-control w-100' name='to_email' id="to_email" />
              <label className='error'>
                {validationError && validationError.to_email}
              </label>
            </Col>
            <Col xs={12} sm={12} lg={12} md={12}>
              <span>Subject* : </span>
              <input type='text' defaultValue={entryData.email_subject}  onChange={handleChange} className='form-control w-100' name='email_subject' id="email_subject" />
              <label className='error'>
                {validationError && validationError.email_subject}
              </label>
            </Col>
            <Col xs={12} sm={12} lg={12} md={12}>
              <span>Email Body* : </span>
              <CKEditor
                initData={entryData.email_body}
                config={{
                  versionCheck: false,
                  toolbar: [
                    ['Cut', 'Copy', 'Paste'],
                    ['Styles', 'Format', 'Font', 'FontSize'],
                    ['Bold', 'Italic', 'Strike'],
                    ['Undo', 'Redo'],
                    ['NumberedList', 'BulletedList'],
                    ['Link', 'Unlink'],
                    ['Table', 'HorizontalRule'],
                    ['Maximize']
                  ]
                }}
                onChange={handleEditorChange}
              />
              <label className='error'>
                {validationError && validationError.email_body}
              </label>
              <div className="alert alert-warning">
                <p>This variable is used to replace a value.</p>
                <span>{'{{first_name}}'} - First Name , {'{{second_name}}'} - Second Name, {'{{payment_link}}'} - Payment Link</span>
              </div>
            </Col>
          
          </Row>
          <Row className="w-100 modal-entry-form-row" >
            <Col xs={12} sm={12} lg={12}>
              <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Sent</Button>
              <Button type='button' disabled={btnDisabled} onClick={() => onHide()} className='ml-2 btn btn-primary btn-radius'>Cancel</Button>
            </Col>
          </Row>
        </form>
      </Container>
    </>
  )
}

export default SendEmailModal