import React, { useEffect, useState } from 'react'
import Util from "../../../utils/util"
import Card from './shared/Card'
import P from './shared/P'
import Button from './shared/Button'
import { Row,Col,Spinner, Form } from 'react-bootstrap'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import Select from 'react-select'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import Modal from "react-bootstrap/Modal"
import feather from 'feather-icons'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from 'moment'

const RecruiterSourcerMeeting = ({recruiters, sourcers}) => {
  var active_page = 1;//window.sessionStorage.getItem('salesListActivePage')
  var query = window.sessionStorage.getItem('query')
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [leads, setLeads] = useState([])
  const [totalTeams,setTotalTeams] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [show,setShow] = useState(false)
  const [method,setMethod] = useState('post')
  const [loading, setLoading] = useState(true)
  const [perPage, setPerPage] = useState(25)
  const [leadObject,setLeadObject] = useState(false)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [validationError, setValidationError] = useState({})
	const [btnDisabled, setBtnDisabled] = useState(false)
  const [obj, setObj] = useState({})
  const [dateUpdateValue, setDateUpdateValue] =  useState('')
	const handleChange = (e) => {
		setValidationError({})
	}

  const fetchData = () => {
    setLoading(true)
    const url = `/admin/recruiter_sourcer_meeting.json`
    window.sessionStorage.setItem('salesListActivePage',activePage)
    makeRequest(url, 'get',  {params: {page: (activePage), query: query}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        let sr_no = (activePage - 1) * res.data.per_page
        setLeads(
          res.data.meetings.map((team) => (sr_no = sr_no + 1,{
            id: sr_no,
            lead_id: team?.id,
            recruiter_name: Util.fullName(team?.user?.first_name,team?.user?.last_name),
            sourcer_name: Util.fullName(team?.sourcer?.first_name, team?.sourcer?.last_name),
            meeting_time: moment(team?.meeting_time).format('ddd DD-MMM-YYYY, hh:mm A'),
            actions: (<div className='w-100 d-inline-flex justify-content-around'>
                   <a onClick={()=> updateLead(team)} href={`javascript::void()`} className='pr-4'><i data-feather="edit-2" /></a>
                   <a onClick={() =>showConfirmDelete(team)}  href={`javascript::void()`} className='pr-4'><i data-feather="trash-2" /></a>
            </div>)
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalTeams(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord((activePage -1) * res.data.per_page)
        setPerPage(res.data.per_page)
        setLoading(false)
      },
    })
  }
    useEffect(() => {
      fetchData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage])

    useEffect(() => {
      feather.replace()
    })

    const deleteLead = async () => {
      const URL = `/admin/recruiter_sourcer_meeting/${leadObject.id}`
      await makeRequest(URL, 'delete', '', {
        createResponseMessage: () => {
          return {
            message: `Deleted successful`,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        },
        createErrorMessage: (e) => 'Failed',
        onSuccess: () => {      
            fetchData()
        },      
      })      
    }    

    const addLead = () => {
      setMethod('post')
      setObj({})
      setDateUpdateValue('')
      setShow(true)
    }

    const updateLead = (obj) => {
      setObj(obj)
      setDateUpdateValue(moment(obj?.meeting_time).format('YYYY-MM-DDTHH:mm'))
      setMethod('put')
      setShow(true)
    }

    const onHide = () => {
      setMethod('post')
      setShow(false)
    }

    const showConfirmDelete = (lead) => {
      setLeadObject(lead)
      setShowRemoveConfirmation(true)
    }

    const columns = [
      {
          name: 'No.',
          selector: row => row.id,
          maxWidth: '4%',
          minWidth: '4%'
      },
      {
          name: 'Recruiter',
          selector: row => row.recruiter_name,
          sortable: false,
          sortField: 'name',
          wrap:true
      },
      {
          name: 'Sourcer',
          selector: row => row.sourcer_name,
          sortable: false,
          sortField: 'email',
          wrap:true
      },
      {
          name: 'Date/Time',
          selector: row => row.meeting_time,
          sortable: false,
          sortField: 'designation',
          wrap:true
      },
      {
        name: 'Actions',
        selector: row => row.actions,
      }
  ]

  const handleSubmit = (e) => {
		e.preventDefault()
		setBtnDisabled(true)
		if(validateForm(e)){
      let URL = `/admin/recruiter_sourcer_meeting/`
      if(method == 'put'){
         URL = `/admin/recruiter_sourcer_meeting/`+obj?.id
      }
			const payload = new FormData()
			payload.append('recruiter_sourcer_meetings[user_id]',e.target.user_id.value )
      payload.append('recruiter_sourcer_meetings[sourcer_id]',e.target.sourcer_id.value )
      payload.append('recruiter_sourcer_meetings[meeting_time]',dateUpdateValue )
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
						onHide()
						fetchData()
				}
			}) 
		}
		setBtnDisabled(false)
	}

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		if(!e?.target?.user_id?.value?.trim()){
				formIsValid = false;
				errors["user_id"] = "Recruiter is required field."
		}
    
    if(!e?.target?.sourcer_id?.value?.trim()){
			formIsValid = false;
			errors["sourcer_id"] = "Sourcer is required field."
		}
    
		if(!dateUpdateValue?.trim()){
			formIsValid = false;
			errors["meeting_time"] = "Meeting Date-Time required field."
		}

		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

  const onDateChange = (val) => {
    setDateUpdateValue(val)
  }

  return (
    <>
    <Card>
      <div className="d-flex justify-content-between align-items-center w-100">
            <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                Recruiter-Sourcer Meeting Schedule
            </P>
        <div style={{flexGrow:1}}/>
            <Button onClick={addLead}  className="ml-3">
                Add Schedule
            </Button>
        </div>
      
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
        <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'left'}}>
          {totalTeams > 0  &&
            <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts}  of  {totalTeams} 
            </DisplayPagination>
        }
          </Col>
      </Row>
      <DataTable
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={leads}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        // onSort={handleSort}
        sortServer
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalTeams}
        paginationPerPage={perPage}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        onChangePage={page => setActivePage(page)}
        // footer={footer}
      />
      {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to delete?"
                    onConfirm={()=>(deleteLead(),setShowRemoveConfirmation(false))}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    >
                </SweetAlert>
            }
    </Card>

    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
              { method ==='put' ?'Update' : 'Add' } Schedule
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}}>
        <Row className="w-100">
						<Col xs={12} sm={12} lg={12}>
							<span>Select Recruiter* : </span>
							<Select
								options={recruiters}
								name='user_id'
								id='user_id'
								placeholder={'Select Recruiter'}
								onChange={handleChange}
                defaultValue={recruiters.filter((row) => row.value == obj?.user_id)}
							/>
							<label style={{color:'red'}}>
									{validationError && validationError.user_id} 
							</label>
						</Col>
            <Col xs={12} sm={12} lg={12}>
							<span>Select Sourcer* : </span>
							<Select
								options={sourcers}
								name='sourcer_id'
								id='sourcer_id'
								placeholder={'Select Sourcer'}
								onChange={handleChange}
                defaultValue={sourcers.filter((row) => row.value == obj?.sourcer_id)}
							/>
							<label style={{color:'red'}}>
									{validationError && validationError.sourcer_id} 
							</label>
						</Col>
            <Col xs={12} sm={12} lg={12}>
							<span>Select Date/Time * : </span>
               <input type='datetime-local'  min={new Date().toISOString().slice(0, -8)}  value={dateUpdateValue} onChange={(e)=>onDateChange(e.target.value)} className='form-control' name='meeting_time' id="meeting_time" ></input>
							<label style={{color:'red'}}>
									{validationError && validationError.meeting_time} 
							</label>
						</Col>
					</Row>
          <Row>
            <Col xs={12} sm={12} lg={12}>
                <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius' style={{marginTop:'20px',float:'right'}}> { method ==='put' ? 'Update' : 'Save' }</Button>
            </Col>
          </Row>
          </form>
        </Modal.Body>
    </Modal>

    </>
  )
}

export default RecruiterSourcerMeeting