import React, { useState,useEffect, useReducer } from 'react'
import { Row, Col } from 'react-bootstrap'
import '../Jobs/styles/index.scss'
import Card from '../../Admin/shared/Card'
import P from '../../Admin/shared/P'
import { PieChart, Pie, Tooltip, Cell,  BarChart, Bar, XAxis, YAxis, CartesianGrid,Legend,LabelList } from 'recharts'
import Moment from 'moment'

const JobShow = ({job,applicant_candidate,recruitor_screen_candidate,submitted_candidate,first_interview_candidate,second_interview_candidate,offer_candidate,archived_candidate,hired_candidate}) => {
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042","#dc3545", "#d988b8", "#28a745"];
    const stageData = [
        {
          "name": "Applicant",
          "value": applicant_candidate
        },
        {
          "name": "Recruiter screened",
          "value": recruitor_screen_candidate
        },
        {
          "name": "Submitted",
          "value": submitted_candidate
        },
        {
          "name": "In Interviews",
          "value": first_interview_candidate + second_interview_candidate
        },
        {
          "name": "Offers",
          "value": offer_candidate
        },
        {
          "name": "Hired",
          "value": hired_candidate
        },
        {
          "name": "Archived",
          "value": archived_candidate
        }
      ];
    
      const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;
        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
      };

      const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

    return (
            <>
            <div className='container-custom'>
              <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100 ml-5"
                style={{ marginBottom: '15px' }}
            >   <Row>
                    <Col xs={12} sm={12} lg={12}> 
                        <Row>
                            <Col xs={12}>
                                <h1>{job.name}</h1>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className=' justify-content-between align-items-center w-100 ml-5' >
                <div>
                    <Row>
                        <Col xs={12} sm={12} lg={12}> 
                            <Row>
                                <Col xs={8} sm={8} lg={8}>
                                { (job.portalcompanyname) ? <span className='label-job-status mr-5'>{job.portalcompanyname} </span> : '' } { (job.department) ? <span className='label-job-status mr-5'>{job.department} </span> : '' }  <span className='label-job-status'> {job.location}</span>
                                </Col>
                                <Col xs={3} sm={3} lg={3} >
                                   <span className='label-transform'>{job.status}</span> <span className={(job.status=='active') ? 'success-span' : (job.status=='pending') ? 'pending-span' : 'warning-span ' } >  </span>  
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col xs={12} sm={12} lg={12}>
                            <Row>
                                <Col xs={12} sm={12} lg={12}>
                                    <p ><b className='job-staus-date-info'>Since this posting is created </b> 
                                        <span className='ml-3'>
                                            {Moment(job.created_at).fromNow()}, on {Moment(job.created_at).format('l')}
                                        </span> 
                                    </p> 
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div> 
            <div className='justify-content-between align-items-center w-100 mt-5 ml-5'>
                <div style={{marginTop: '60px' }}>
                   
                    <Row>
                    <Col xs={12} sm={12} lg={12}>
                        <Row>
                            <Col xs={1} sm={1} lg={1} className="text-center">
                                <p  className='p-custom-label'><b>{applicant_candidate+recruitor_screen_candidate+submitted_candidate+first_interview_candidate + second_interview_candidate+offer_candidate+hired_candidate+archived_candidate}</b></p>
                                <p>TOTAL  <br></br> CANDIDATES</p>
                                <hr></hr>
                            </Col>
                            <Col xs={2} sm={2} lg={2} style={{marginTop:'-90px'}}>
                                <PieChart width={400} height={300}>
                                        <Pie data={stageData} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80}  label>
                                        {stageData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                        </Pie>
                                    <Tooltip/>
                                </PieChart>
                            </Col>
                            <Col xs={1} sm={1} lg={1}>
                               
                            </Col>
                            <Col xs={1} sm={1} lg={1} className="text-center">
                                <p className='p-custom-label'><b>{applicant_candidate}</b></p>
                                <p>APPLICANT</p>
                                <hr></hr>
                            </Col>
                            <Col xs={1} sm={1} lg={1} className="text-center">
                                <p className='p-custom-label'><b>{recruitor_screen_candidate}</b></p>
                                <p>RECRUITER SCREENED</p>
                                <hr></hr>
                            </Col>
                            <Col xs={1} sm={1} lg={1} className="text-center">
                                <p className='p-custom-label'><b>{submitted_candidate}</b></p>
                                <p>SUBMITTED</p>
                                <hr></hr>
                            </Col>
                            <Col xs={1} sm={1} lg={1} className="text-center">
                                <p className='p-custom-label'><b>{first_interview_candidate + second_interview_candidate}</b></p>
                                <p>IN INTERVIEWS</p>
                                <hr></hr>
                            </Col>
                            <Col xs={1} sm={1} lg={1} className="text-center">
                                <p className='p-custom-label'><b>{offer_candidate}</b></p>
                                <p>OFFERS</p>
                                <hr></hr>
                            </Col>
                            <Col xs={1} sm={1} lg={1} className="text-center">
                                <p className='p-custom-label'><b>{hired_candidate}</b></p>
                                <p>HIRED</p>
                                <hr></hr>
                            </Col>
                            <Col xs={1} sm={1} lg={1} className="text-center">
                                <p className='p-custom-label'><b>{archived_candidate}</b></p>
                                <p>ARCHIVED</p>
                                <hr></hr>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                                
                </div>
            </div>
            <div className='justify-content-between align-items-center w-100 mt-1'>
                <div style={{  marginTop: '90px' }}>
                    <Row>
                        <Col xs={12} sm={12} lg={12}>
                            <Row>
                                <Col xs={12} sm={12} lg={12}>
                                    <BarChart
                                        width={1250}
                                        height={400}
                                        data={stageData}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                        >
                                        

                                     <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Bar dataKey="value" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                                            {stageData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % 20]} />
                                            ))}
                                        </Bar>
                                        </BarChart>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>                                                   

            </Card>
            </div>
            </>
  )
}
export default JobShow