import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Dropdown, Spinner, Row, Col } from 'react-bootstrap'
import FileButton from '../../common/FileButton/FileButton'
import TextInput from '../../common/TextInput/TextInput'
import { StyledForm, Container,Button, ButtonRow} from './styles/CandidateForm.styled'
import { normalizeInput } from '../../../utils'
import PhoneInput from '../PhoneInput/PhoneInput'
import { makeRequest } from '../RequestAssist/RequestAssist'

function CandidateDetails({
    job,
    setApplied,
    setCandidateModal = () => {},
}) {
    const [resume, setResume] = useState()
    const [resumeError, setResumeError] = useState(null)
    const skills_asc = job.skills.split(',')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [loading, setLoading] = useState(false)
   
    const candidateValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        email: Yup.string()
            .email()
            .required('Email is required')
            .test(
                'email-unique',
                'This email is already in use',
                async function (value) {
                    // check if user exists with email
                    // call backend with email and see if it returns user
                    const res = await axios.get(
                        `/users/exists?email=${encodeURIComponent(value)}`
                    )
                    return !res.data.user_exists
                }
            ),
        linkedinProfile: Yup.string().test(
                'Invalid url, please add Linkedin url only',
                function (value) {
                if (value != '' && value != undefined && value != 'undefined') {
                        try {
                            let hostname = new URL(value).hostname
                        return (
                                hostname === 'linkedin.com' ||
                                hostname === 'www.linkedin.com'
                            )
                        } catch (error) {
                            return false
                        }
                } else {
                        return true
                    }
                }
            ),
    })

    const handleSave = async (candidate) => {
        const payload = new FormData()
        const url = `/job/apply/${job.id}`
        payload.append('candidate[first_name]', candidate.firstName)
        payload.append('candidate[last_name]', candidate.lastName)
        payload.append('candidate[email_address]', candidate.email)
        payload.append('candidate[linkedin_profile_url]',candidate.linkedinProfile)
        payload.append('candidate[phone_number]', phoneNumber)
        payload.append('candidate[skills]', skills_asc[0])
        payload.append('candidate[organization_id]', job.organization_id)
        payload.append('candidate[authorization]', candidate.authorization)
        payload.append('candidate[company_names]', candidate.company)
        payload.append('candidate[location]', candidate.location)
        payload.append('candidate[compensation]', candidate.compensation)
        payload.append('candidate[sponsorship]', candidate.sponsorship)
        
        if(resume){
          payload.append('candidate[resume]', resume)
        }
        payload.append('candidate[school]', [])
        if(phoneNumber.length === 14){
            try {
                setLoading(true)
                const { data } = await makeRequest(url, 'post', payload, {
                    contentType: 'application/json',
                    loadingMessage: 'Applying...',
                    createResponseMessage: (response) => {
                        return {
                            message:response.message,
                            messageType: 'success',
                            loading: false,
                            autoClose: true,
                        }
                    },
                    createErrorMessage:(error)=>error.response.data.error
                })
                setApplied(true)
                if (data.hasOwnProperty('error')) {
                    setLoading(false)
                } else {
                    setApplied(true)
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
            }
            setCandidateModal(false)
        }
    }
    const handleChange = ({ target: { value } }) => {
        setPhoneNumber(normalizeInput(value, ""));
    };
    return (
        <Formik
            initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                linkedinProfile: '',
                resume: resume,
                phoneNumber: '',
            }}
            validationSchema={candidateValidation}
            validate={(values) => {
                const errors = {}
                setResumeError('')
                if (
                    !resume &&
                    (values.linkedinProfile == '' ||
                        values.linkedinProfile == 'undefined' ||
                        values.linkedinProfile == undefined)
                ) {
                    errors.resume = 'Resume is required'
                    setResumeError(errors.resume)
                }
                return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
                if (
                    !resume &&
                    values.linkedinProfile == '' &&
                    values.linkedinProfile == 'undefined' &&
                    values.linkedinProfile == undefined
                ) {
                    setResumeError('Resume is required')
                    return
                }
                setTimeout(() => {
                    setSubmitting(false)
                }, 500)

                handleSave(values)
            }}
        >
            <StyledForm>
                <Row>
                    <Col xs={6} sm={6} lg={6}>
                    <TextInput
                        label="First Name*"
                        name="firstName"
                        type="text"
                        id="firstName"
                        width="100%"
                        maxLength={50}
                    />
                    </Col>
                    <Col xs={6} sm={6} lg={6}>
                    <TextInput
                        label="Last Name*"
                        name="lastName"
                        type="text"
                        id="lastName"
                        width="100%"
                        maxLength={50}
                    />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} sm={6} lg={6}>
                    <TextInput
                        label="Email Address*"
                        name="email"
                        type="text"
                        id="email"
                        width="40rem"
                        maxLength={50}
                        style={{ marginRight: '10px' }}
                    />
                    </Col>
                    <Col xs={6} sm={6} lg={6}>
                    <PhoneInput
                        label="Phone*"
                        name="phoneNumber"
                        type="text"
                        id="phoneNumber"
                        placeholder="(xxx) xxx-xxxx"
                        width="40rem"
                        onChange={handleChange}
                        value={phoneNumber}
                        style={{ marginRight: '10px' }}
                        isRequired
                    />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} sm={6} lg={6}>
                        <FileButton
                            label="Upload Resume"
                            type="button"
                            file={resume}
                            resumeError={resumeError}
                            setResumeError={setResumeError}
                            getFile={(file) => setResume(file)}
                        />
                    </Col>
                    <Col xs={6} sm={6} lg={6}>
                        <TextInput
                            label="Linkedin Profile"
                            name="linkedinProfile"
                            type="text"
                            placeholder="https://www.linkedin.com/in"
                            id="linkedinProfile"
                            width="20rem"
                            style={{ marginRight: '10px' }}
                        />
                    </Col>
                </Row>
                <TextInput
                    label="Would you, now or in the future, require sponsorship for work authorization?"
                    name="sponsorship"
                    type="text"
                    id="sponsorship"
                    width="40rem"
                    maxLength={50}
                    style={{ marginRight: '10px' }}
                />
                <TextInput
                    label="Current Company"
                    name="company"
                    type="text"
                    id="company"
                    width="40rem"
                    maxLength={50}
                    style={{ marginRight: '10px' }}
                />
                <TextInput
                    label="Current Location"
                    name="location"
                    type="text"
                    id="location"
                    width="40rem"
                    maxLength={50}
                    style={{ marginRight: '10px' }}
                />
                <TextInput
                    label="Desired Compensation"
                    name="compensation"
                    type="text"
                    id="compensation"
                    width="40rem"
                    maxLength={50}
                    style={{ marginRight: '10px' }}
                />
                {/* <Dropdown>
                    <Dropdown.Item>
                        Yes
                    </Dropdown.Item>
                    <Dropdown.Item>
                        No
                    </Dropdown.Item>
                </Dropdown> */}

                <ButtonRow>
                    <div />
                    <Button type="submit" disabled={loading}>
                        {loading ? (
                            <Spinner animation="border" variant="light" />
                        ) : (
                            'Apply'
                        )}
                    </Button>
                </ButtonRow>
            </StyledForm>
        </Formik>
    )
}

export default CandidateDetails
