// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EmailSequence-module__customModalmailSequence___RubMH{background:#7185F2 !important;border-radius:0px !important;float:right;-webkit-margin-end:0px}.EmailSequence-module__clicck_touplod___2RMtv{display:flex;justify-content:space-between}@media only screen and (min-device-width: 320px) and (max-device-width: 767px){.EmailSequence-module__clicck_touplod___2RMtv{display:block !important;text-align:center !important}}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20241115132749/app/javascript/components/common/EmailSequence/styles/EmailSequence.module.scss"],"names":[],"mappings":"AAEA,uDACE,6BAA8B,CAC9B,4BAA6B,CAC7B,WAAY,CACZ,sBAAuB,CACxB,8CAEC,YAAa,CACb,6BAA8B,CAC/B,+EAEC,8CACE,wBAAyB,CACzB,4BAA6B,CAC9B","file":"EmailSequence.module.scss","sourcesContent":["@import '../../../../styles/var';\n\n.customModalmailSequence{\n  background: #7185F2 !important;\n  border-radius: 0px !important;\n  float: right;\n  -webkit-margin-end: 0px;\n}\n.clicck_touplod{\n  display: flex;\n  justify-content: space-between;\n}\n@media only screen and (min-device-width : 320px) and (max-device-width : 767px){\n  .clicck_touplod{\n    display: block !important;\n    text-align: center !important;\n  }\n}"]}]);
// Exports
exports.locals = {
	"customModalmailSequence": "EmailSequence-module__customModalmailSequence___RubMH",
	"clicck_touplod": "EmailSequence-module__clicck_touplod___2RMtv"
};
module.exports = exports;
