import React, { useEffect } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import feather from 'feather-icons'
import styles from './styles/JobPositionCard.module.scss'
import CompanyPlaceholderImage from '../../../../assets/images/talent_page_assets/company-placeholder.png'
import CandidateSkills from '../CandidateSkills/CandidateSkills'
import {handelBrokenUrl} from '../../common/Utils'
import { capitalize } from '../../../utils'
import Moment from 'moment'
import MatchScore from '../MatchScore/MatchScore'
import shield from '../../../../assets/v2/imgs/theme/icons/shield-check.svg'
import bookmark from '../../../../assets/v2/imgs/theme/icons/bookmark.svg'

const JobPositionCard_v2 = (props) => {
    const {
        job,
        matchScore,
        showSkills = true,
        showSalary = true,
        showMatchScore,
        showCardinalJob = false,
        personId = false,
        onChangeBulkJobIds
    } = props
    const {
        id,
        name: jobTitle,
        portalcompanyname: companyName,
        compensation: salary,
        portalcity: location,
        skills,
        organization,
        created_at
    } = job

    // todo check if user already applied - waiting for backend support
    const alreadyApplied = false

    useEffect(() => {
        feather.replace()
    })

    const JobAvatarUrl = (logo = organization.image_url) => {
      return logo ? logo : CompanyPlaceholderImage
    }

    return (
   
        <div className="card-job hover-up wow animate__animated animate__fadeIn" style={{marginTop: '30px'}}>
            <div className="card-job-top">
                <div className="card-job-top--image">
                    <figure><img alt={organization?.name}
                            src={
                                JobAvatarUrl(job.logo)
                            } /></figure>
                </div>
                <div className="card-job-top--info">
                    <h6 className="card-job-top--info-heading"> 
                        <a href={ (showCardinalJob) ? `../jobs/${id}` : `jobs/${id}`} className={styles.descriptionLink} >{jobTitle}</a>
                    </h6>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <a >
                                <span className="card-job-top--company ml-12 mr-15 ">{organization?.name}</span>
                            </a>
                            { (job?.location !== "null" && job?.location) && 
                                <span className="card-job-top--location text-sm pr-5 mr-15"><i
                                    className="fi-rr-marker"></i> {job?.location !== "null" ? job?.location && capitalize(job?.location?.replace('(US)','')?.replace('(usa)','')?.replace('(USA)','')) : 'No location found'} </span>
                            }
                            <span className="card-job-top--type-job text-sm mr-15"><i
                                    className="fi-rr-briefcase"></i> Full
                                time</span>
                            <span className="card-job-top--post-time text-sm mr-15"><i
                                    className="fi-rr-clock"></i> {Moment(created_at).fromNow()}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-job-bottom mt-15">
                <div className="row">
                    <div className="col-lg-9 col-sm-8 col-12">
                            <CandidateSkills skills = {skills} />
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 text-lg-end d-lg-block d-none">
                        {/* <span><img src={shield}
                                alt="CardinalTalent" /></span> */}
                        {/* <span className="ml-5"><img src={bookmark}
                                alt="CardinalTalent" /></span> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobPositionCard_v2