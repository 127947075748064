import React, { Fragment, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Alert from 'react-bootstrap/Alert'
import styles from './styles/AccountSettingPage.module.scss'
import { capitalize } from '../../../utils'
import Input from '../../common/Styled components/Input'
import { W3text,Typography,Wrapper } from './styles/AccountSettingPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select'


const keyValidationSchema = Yup.object({
    mixmax_api_key: Yup.string().required('Mixmaa api key is required')
})
const MixMaxConfigPage = (props) => {
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState(false)
    const { currentUser } = props
    const { first_name, last_name } = currentUser
    const [itemList, setItemList] = useState( (props.list) ? props.list : [])
    const [itemValidationError, setItemValidationError] = useState({})
    const [btnDisabled, setBtnDisabled] = useState(false)
    const defaultStatusList = [{label:'Active',value:'active'},{label:'Inactive',value:'inactive'}]
    const [defaultStatus, setDefaultStatus ]= useState({label:'Active',value:'active'})
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [apikey, setApikey] = useState()
    const [operation, setOperation] = useState('Add')
    const [updateId, setUpdateId] = useState()

    const onChangeStatus = (e) => {
        setDefaultStatus({label:e.label,value:e.value})
    }

    const handleSubmitItem = (e) => {
        e.preventDefault()
        setBtnDisabled(true)
         // check validation 
        let errors = {}
        let formIsValid = true
        
        if((e.target.name.value === '')){
            errors["name"] = 'Name is required field.'
            formIsValid = false;
        }
        if(e.target.email.value === ''){
            errors["email"] = 'Email is required field.'
            formIsValid = false;
        }

        if(e.target.api_key.value === ''){
            errors["api_key"] = 'API Key is required field.'
            formIsValid = false;
        }

        if(e.target.api_key.status === ''){
            errors["status"] = 'Status is required field.'
            formIsValid = false;
        }
        
        setBtnDisabled(formIsValid)   
        setItemValidationError(errors)

        if(formIsValid){

            let URL = '/mixmax/configuration'
            let method = 'post'
            console.log(updateId)
            if(updateId != '' && updateId != undefined){
                URL = `/mixmax/configuration/${updateId}`
                method = 'put'
            }

            const payload = new FormData()
            payload.append('mixmax_configuration[user_id]', currentUser.id)
            payload.append('mixmax_configuration[name]', e.target.name.value)
            payload.append('mixmax_configuration[email]',e.target.email.value)
            payload.append('mixmax_configuration[api_key]',e.target.api_key.value)
            payload.append('mixmax_configuration[status]',e.target.status.value)
            
            //headers
            const CSRF_Token = document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content')
                
            const headers = {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-Token': CSRF_Token,
                },
            }
            makeRequest(URL,method, payload, headers, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
                createResponseMessage: () => {
                    let messsage = 'Save' 
                    return {
                        message: `${messsage} Successfully`,
                        messageType: 'success',
                        loading: false,
                        autoClose: true,
                    }
                },
                createErrorMessage: (e) => 'Failed',
                onSuccess: () => {},
            }).then((res) => {
                if(res.data.type === 'success'){
                   window.location.reload()
                }
            }) 

        }
        setBtnDisabled(false)
    }

    const onEditClick = (item) => {
        setUpdateId(item.id)
        setName(item.name)
        setEmail(item.email)
        setApikey(item.api_key)
        if(item.status === 'active'){
            setDefaultStatus({label:'Active',value:'active'})
        }else{
            setDefaultStatus({label:'Inactive',value:'inactive'})
        }
        setOperation('Update')
    }

    const onCancelEdit = () => {
        setUpdateId('')
        setName('')
        setEmail('')
        setApikey('')
        setOperation('Add')
        setDefaultStatus({label:'Active',value:'active'})
    }
    
    return (
        <div className={styles.accountSettingPage}>
            <Wrapper className={`${styles.accontSetting}`}>
                <div className={`${styles.sidebar}`}>
                    <div className={`${styles.sidebar_header}`}>
                        <p>{capitalize(`${first_name} ${last_name}`)}</p>
                    </div>
                    <a href="/account/setting">General Settings</a>
                    <a href="/account/security">
                        Security & Login
                    </a>
                    <a href="/account/email_verification">Email Verification</a>
                    <a className={`${styles.active}`} href="/account/mixmax_configuration">Mixmax Configuration</a>
                    { (currentUser.role == 'recruiter') && 
                        <a href="/account/organization_management">Organization Membership Management</a>
                    }
                    {
                     (currentUser.role == 'employer') && 
                        <a href="/account/organization_agreement">Organization Agreement</a>
                    }
               
                </div>
                <div className={`${styles.containt}`} style={{ paddingBottom: '12rem' }}>
                   
                    <h3>Mixmax Configuration</h3>
                    
                       <form onSubmit={(e) => {handleSubmitItem(e)}} style={{width:"100%"}} className="mt-5">
                            <Row className="w-100 modal-entry-form-row  " >
                                <Col xs={12} sm={12} lg={12} className='text-left'>
                                    <h4>Set Mixmax API Details</h4>
                                </Col>
                            </Row>
                            <Row className="w-100 modal-entry-form-row mt-3" >
                                <Col xs={6} sm={6} lg={6}>
                                    <span>Name : </span>
                                    <input type='text'  className='form-control' name='name' placeholder='Nick Name of Account' id="name" value={name}  onChange={ (e) => setName(e.target.value)} />
                                    <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                                {itemValidationError && itemValidationError.name}        
                                    </label>
                                </Col>
                                <Col xs={6} sm={6} lg={6}>
                                    <span>Account Email : </span>
                                    <input type='text' className='form-control' name='email' placeholder='Account Email Address' id="email"  value={email}   onChange={ (e) => setEmail(e.target.value)}/>
                                    <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                            {itemValidationError && itemValidationError.email}     
                                    </label>
                                </Col>
                                <Col xs={6} sm={6} lg={6}>
                                    <span>API Key : </span>
                                    <input type='text'  className='form-control' name='api_key' placeholder='API Key of Account' id="api_key"  value={apikey}  onChange={ (e) => setApikey(e.target.value)} />
                                    <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                                {itemValidationError && itemValidationError.api_key}        
                                    </label>
                                </Col>
                                <Col xs={6} sm={6} lg={6}>
                                    <span> Status : </span>
                                    <Select
                                        options={defaultStatusList}
                                        name='status'
                                        id='status'
                                        onChange={onChangeStatus}
                                        defaultValue={defaultStatus}
                                        value={defaultStatus}
                                        menuPortalTarget={document.querySelector('.modal')}
                                    />
                                    <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                            {itemValidationError && itemValidationError.status}     
                                    </label>
                                </Col>

                                <Col xs={6} sm={6} lg={6} className='text-left'>
                                    <br/>
                                    <input type='submit' value={operation} disabled={btnDisabled} className='btn btn-primary btn-md btn-radius' />
                                    { operation == 'Update' &&
                                        <input type='button' value={'Cancel'}  onClick={onCancelEdit} className='btn btn-danger btn-md btn-radius ml-3' />
                                    }
                                    </Col>
                                <Col xs={12} sm={12} lg={12} className='text-left'>
                                    <label style={{fontSize: '12px',color: 'red',marginTop: '5px'}}>
                                            {itemValidationError && itemValidationError.msg}     
                                    </label>
                                </Col>
                            </Row>
                        </form>
                    <Row className="w-100 modal-entry-form-row  mt-5 mb-3 " >
                            <Col xs={12} sm={12} lg={12} className='text-left'>
                                <h4>Mixmax Accounts </h4>
                            </Col>
                    </Row>               
                    <div>
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr className='text-center'>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>API Key</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr> 
                            </thead>
                            <tbody>
                                {itemList && itemList?.map((item,index)=> {
                                    return (
                                        <tr key={index} className='text-center'>
                                            <td>{index + 1}</td>
                                            <td>{capitalize(item.name)}</td>
                                            <td>{item.email}</td>
                                            <td>{item.api_key}</td>
                                            <td>{ (item.status == 'active') ? <span style={{color: 'green'}}>Active</span> : <span style={{color: 'red'}}>Inactive</span>}</td>
                                            <td><a style={{cursor:'pointer',color:'#8185ff'}} onClick={() => (onEditClick(item))} > Edit </a></td>
                                        </tr>
                                    )
                                })}
                                { itemList.length == 0 && 
                                        <tr className='text-center'>
                                            <td colSpan={6}> No Record Found.</td>
                                        </tr>
                                    
                                }
                                </tbody>
                        </table>
                     </div>
                </div>
                
            </Wrapper>
        </div>
    )
}
export default MixMaxConfigPage
