import React, { useEffect, useState, useRef, useContext } from 'react';
import { Card } from 'react-bootstrap';
import styles from './styles/TimeSheetPage.module.scss';
import { Modal } from 'react-bootstrap';
import CloseButton from '../../common/Styled components/CloseButton';
import { Box, Row } from './styles/TimeSheet.styled';
import Text from '../../common/Styled components/Text';
import TimeEntryFormA from './TimeEntryFormA';
import moment from 'moment';
import {TimeEntryContext} from '../../../stores/TimeEntryStore'

function EntryCard(props) {
  const [isOpen, setIsOpen] = useState(null);
  const [ref, setRef] = useState(false);
  const [selectedDate, setDate] = useState('');

  const {disptach, state} = useContext(TimeEntryContext);
  const {entries} = state

  const handleEdit = (date) => {
    setIsOpen(true)
    setDate(date)
  };

  return (
    <>
      {entries.map( (entry, index) => (
        <Row>
          <Card className={styles.cardContainer} onClick={() => handleEdit(entry.entry_date)}>
            <Card.Header>
              <Row aItems="center" jContent="space-between">
                <Box>
                  <Text>{moment(entry.entry_date).format('MMM DD, YYYY')}</Text>
                </Box>
                <Box>
                  <Text>Total Hrs: {entry.hours}</Text>
                </Box>
                <Box>
                  <Text>Submission: {entry.total_submissions}</Text>
                </Box>
                <Box>
                  <Text>Interview: {entry.interviewed}</Text>
                </Box>
                <Box>
                  <Text>Hire: {entry.hired}</Text>
                </Box>
                <Box>
                  <Text onClick={() => setIsOpen(true)}><i data-feather="edit-2" /></Text>
                </Box>
              </Row>
            </Card.Header>
          </Card>
      </Row>
      ))}

      <Modal show={isOpen} size="xl" onHide={() => setIsOpen(false)}  centered>
        <TimeEntryFormA date={selectedDate}>
          <Row jContent="space-between" gutterTop>
            <Text varient="h2" color="primary" size={'26px'} givePadding style={{ minWidth: '200px' }}>
              Edit Timesheet on {moment(selectedDate).format('MMM DD, YYYY')}
            </Text>
            <CloseButton handleClick={() => setIsOpen(false)} />
          </Row>
        </TimeEntryFormA>
      </Modal>
    </>
  );
}

export default EntryCard;