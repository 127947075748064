import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import _ from 'lodash'

function MultiSelect({ options,placeholder, onSelect, defultValue = [] }) {
    const [selectedOptions, setSelectedOptions] = useState()
    const uniquelist = [...new Set(options)]
    const list = uniquelist.map((industrie) => ({
        value: industrie,
        label: industrie,
    }))

    useEffect(() => {
        setSelectedOptions(defultValue)
    }, [])

    const handleMultiDepartment = (values) => {
        setSelectedOptions([...values])
        onSelect(values)
    }
    return (
        <div>
            <Select
                isMulti
                classNamePrefix="select"
                isSearchable={true}
                minMenuHeight={6}
                onChange={handleMultiDepartment}
                options={list}
                value={selectedOptions}
                placeholder={placeholder}
            />
        </div>
    )
}

export default MultiSelect
