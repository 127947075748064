import moment from 'moment';
import Container from 'react-bootstrap/Container'
import React, { useState, useEffect, useContext, useReducer } from 'react';
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Card from '../Admin/shared/Card';
import styles from './styles/WeeklyReferralPage.module.scss'
import CandidateInfo from './CandidateInfo';
import Util from '../../../utils/util';
import Paginator from '../../common/Paginator/Paginator';
import Select from "react-select"
import { Button, Modal } from 'react-bootstrap';
import IntrestedCandidate from './IntrestedCandidate';
import SearchBar from '../../common/SearchBar/SearchBar';

function WeeklyReferralPage({job_group,recruiter_org}) {
  const my_submission_only = sessionStorage.getItem('my_submission_only')
  const queryParams = new URLSearchParams(window.location.search)
  const email_date = queryParams.get("date")
  const pathname = window.location.pathname?.split('/')
  const categoryId = parseInt(pathname[2]) ? pathname[2] : ''
  const [activePage, setActivePage] = useState(0)
  const [loading, setLoader] = useState(true)
  const [candidates, setCandidates] = useState([])
  const [searchText, setSearchText] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(0)
  const [show,setShow] = useState(false)
  const [category, setCategory] = useState(categoryId)
  const [selectedCategory, setSelectedCategory] = useState()
  const [categoryOptions, setCategoryOptions] = useState([{value: "", label: "All"},...job_group])
  const [defaultCategory, setDefaultCategory] = useState(categoryId ? categoryOptions.filter((row)=>row.value == categoryId).map((row)=>({value: row.value, label: row.label})) : {value: "", label: "All"})
  const [singleIntrestedCandidates, setSingleIntrestedCandidates] = useState([])
  const [multiIntrestedCandidates, setMultiIntrestedCandidates] = useState([])
  const [mySubmissionOnly, setMySubmissionOnly] = useState(my_submission_only == 'true' ? true : false)
  const colourStyles = {
    control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',marginLeft:'10px',border: 'none',minHeight:'40px' })
  };

  const handleSelectCategory = (str) => {
    setSelectedCategory(str)
    setCategory(str.value)
  }

  const fetchData = async() => {
    setCandidates([])
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setLoader(true)
    const url = category ? `/weekly_referral/${category}.json`: `/weekly_referral.json`
    await makeRequest(url, 'get',  {params: {page: (activePage+1), search: searchText, submission: mySubmissionOnly, email_date:  email_date}}, {
        contentType: 'application/json',
        loadingMessage: 'Fetching...',
        createSuccessMessage: (response) => response.data.message,
        onSuccess: (res) => {
          setCandidates(
              res.data.candidates.map((candidate) => ({
                  id: candidate.id,
                  first_name: capitalize(candidate.first_name),
                  last_name: capitalize(candidate.last_name),
                  title: candidate.title,
                  company_name: candidate.company_names,
                  email: candidate.email_address,
                  phone_number: candidate.phone_number,
                  skills: candidate.skills,
                  linkedin_profile_url: candidate.linkedin_profile_url,
                  submissions: candidate.submissions,
                  resume: candidate.cv_url
              }))
          )
          setPageCount(res.data.total_pages)
          setTotalCount(res.data.total)
          setLoader(false)
        },
    })
  }

  const saveIntrestedPeople = (organization, job, stage) => {
    const url = '/weekly_referral.json'
    const formData = new FormData()
    if(multiIntrestedCandidates?.length > 0){
      let data = []
      multiIntrestedCandidates.map((row)=>{
        data.push({submission: {
          person_id: row,
          job_id: job,
          submission_type: stage,
          organization_id: organization
        }})  
      })
      formData.append('candidates', JSON.stringify(data))
    }
    else{
      formData.append('submission[person_id]',singleIntrestedCandidates.id)
      formData.append('submission[job_id]',job)
      formData.append('submission[stage]',stage)
      formData.append('submission[organization_id]',organization)
    }
    makeRequest(url, 'post', formData, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        if(res.data.type == "success"){
          onHide()
          setTimeout(()=>{
            window.location.href = "/weekly_referral"
          },3000)
        }
      },
    })
  }

  const handleCheckboxChange = (id) => {
    if(multiIntrestedCandidates?.includes(id))
    {
      setMultiIntrestedCandidates([...multiIntrestedCandidates.filter((row)=> row != id)])
    }
    else{
      setMultiIntrestedCandidates([...multiIntrestedCandidates, id])
    }
    
  }

  const handleIntrested = (candidate) => {
    var doc = document.getElementsByClassName('form-check-input')
    for (let i = 0; i < doc.length; i++) {
      if(doc[i].checked)
        doc[i].click()
    }
    setSingleIntrestedCandidates(candidate)
    setMultiIntrestedCandidates([])
    setShow(true)
  }

  const onHide = () => {
    setShow(false)
  }

  useEffect(()=>{
    fetchData()
  },[page,category,mySubmissionOnly,activePage])

  return (
    <>
    <Container className="p-0" fluid>
      <label
        className={styles.mainTitle + ' page-title'}
        style={{ marginBottom: '2rem' }}
      >
        <span>Active Candidates</span>
    </label>
    <div className='row' style={{marginTop: '82px'}}>
      <div className='col'>
    <SearchBar
        candidatePage={true}
        hideButton={false}
        placeholder={'Search...'}
        value={searchText}
        setValue={setSearchText}
        onClick={(e) => {
            setSearchText(searchText)
            }
        }
        onEnterPressed={() => {
          activePage === 0 ? fetchData() : setActivePage(0)
        }}
        onChange={(event) => {
            const text = event.target.value
            setSearchText(text)
          }}
          candidateSource={'Referral'}
          />
      </div>
    </div>
   </Container>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
        style={{ marginBottom: '15px' }}
      >
          <span className={styles.candidatecount}>
            Displaying{' '}
            {Util.displayNumberOfResults(
                totalCount,
                pageCount,
                activePage,
                25,
                totalCount >= 10000 ? `10000+` : totalCount,
                true,
                true
            )}
          </span>
          <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" checked={mySubmissionOnly} onClick={(e)=>{
                  setMySubmissionOnly(e.target.checked)
                  sessionStorage.setItem('my_submission_only',e.target.checked)
              }} id="my_submission_only" />
              <label class="custom-control-label" for="my_submission_only">My Submissions Only</label>
          </div>
          <div className='d-flex align-items-baseline'>
            <label><b>Job Group : </b></label>
            <Select 
              clearable={true}
              defaultValue={defaultCategory}
              options={categoryOptions}
              onChange={handleSelectCategory} 
              placeholder={'Select Status'} 
              styles={colourStyles}
            />
          </div>
      </div>

      <div
        className="d-flex justify-content-end align-items-center w-100"
        style={{ marginBottom: '15px' }}
      >
        {multiIntrestedCandidates.length > 0 && (
            <Button
              variant='primary'
              className={styles.button}
              onClick={()=>setShow(true)}
            >
              Submit
            </Button>
        )}
      </div>
      <CandidateInfo
        loading={false}
        candidates={candidates}
        handleIntrested={handleIntrested}
        multiSelectCheckbox={handleCheckboxChange}
        multiIntrestedCandidates={multiIntrestedCandidates}
      />
      {pageCount > 1 && (
          <div
              className="d-flex justify-content-center"
              style={{ marginTop: 'auto' }}
          >
              <Paginator
                  activePage={activePage}
                  setActivePage={setActivePage}
                  pageCount={pageCount}
                  pageWindowSize={5}
                  showGoToField={false}
              />
          </div>
      )} 
    </Card>
    <Modal
        onHide={onHide}
        show={show}
        className='modal-entry-form'
        backdrop="static"
        centered={true}
        keyboard={false}
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
              Submit Candidate(s)
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <IntrestedCandidate
              recruiter_org={recruiter_org} 
              saveIntrestedPeople={saveIntrestedPeople}  
              onHide={onHide}
            ></IntrestedCandidate>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default WeeklyReferralPage;