import React, { useReducer, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Row, Col, Button } from 'react-bootstrap'
import {
    AgencyContext,
    reducer,
} from '../../../../../stores/Agency/AgencyFormStore'
import './styles/index.scss'
import { H1, StyledForm } from '../../../Admin/styles/UserManagementEditPage.styled'
import TextInput from '../../../Admin/shared/TextInput'

const Create = ({agency, edit, user}) => {
    const { name, id, status } = agency
    const { first_name, last_name, email, phone_number } = user
    
    
    let initialState = {
        ...initialState,
        ...{
            name,
            first_name,
            last_name,
            email,
            phone_number,
            id
        },
    }
    const [state, dispatch] = useReducer(reducer, initialState)

    const CreateAgency = (values) => {
        console.log(values, 'values')
        dispatch({ type: 'add-agency', value: values })
    }

    const updateAgency = (values) => {
        dispatch({type: 'update-agency', value: values, id:id})
    }

    const goback = () => {
        window.location.href ='/agencies/admin/agencies'
    }

    return (
        <AgencyContext.Provider value={(state, dispatch)}>
            <>
                <div className="d-flex w-50 flex-column align-items-center justify-content-center pt-5 m-auto">
                    <Formik
                        initialValues={{
                            name: name || '',
                            email: email,
                            phone: phone_number || '',
                            ownerFirstName: first_name || '',
                            ownerLastName: last_name || '',
                            status: status || ''
                        }}
                        validationSchema={Yup.object({
                            name:
                                Yup.string().required(' Name is required')
                                .test(
                                    'Agency name alphabets only',
                                    'Agency name can only contain alphabet characters',
                                    function (value){
                                        // const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                                        const regex =  /^[a-zA-Z\s0-9!@#\$%\^\&*\)\(+=._-]+$/g
                                        return regex.test(value)
                                    }
                                ),
                            ownerFirstName: Yup.string().required(
                                ' First name is required'
                            )
                            // .max(50, 'Should not be exceed 50 characters')
                            .test(
                                'first name alphabets only',
                                'First Name can only contain alphabet characters',
                                function (value){
                                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                                    return regex.test(value)
                                }
                            ),
                            ownerLastName: Yup.string().required(
                                ' Last name is required'
                            ).test(
                                'last name alphabets only',
                                'last Name can only contain alphabet characters',
                                function (value){
                                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                                    return regex.test(value)
                                }
                            ),
                            email: Yup.string().email().required('Email is required'),
                            phone: Yup.string().required('Phone number is required').test(
                                'phone number digits only',
                                'Phone number must contain 10 to 15 digits only',
                                function (value) {
                                    if( value != '' && value != undefined){
                                        const regex = /^[0-9]{10,15}$/
                                        return regex.test(value.replace('(','').replace(')','').replace('-','').replace(' ','').trim())
                                    }
                                    return true
                                    
                                }
                            )
                        })}
                        onSubmit={(values) => {
                            if(edit === true){
                                updateAgency({
                                    name: values.name,
                                    ownerFirstName: values.ownerFirstName,
                                    ownerLastName: values.ownerLastName,
                                    email: values.email,
                                    phone: values.phone,
                                    id: agency?.id,
                                    status: values.status
                                })
                            }
                            else {
                                CreateAgency({
                                    name: values.name,
                                    ownerFirstName: values.ownerFirstName,
                                    ownerLastName: values.ownerLastName,
                                    email: values.email,
                                    phone: values.phone,
                                    status: values.status
                                })
                            }
                        }}
                    >
                        <StyledForm>
                            <Row>
                                <Col xs={12} sm={12} lg={8} className="m-auto">
                                    <H1>Agency Details</H1>
                                </Col>
                            </Row>
                            <Row className="w-100 d-flex justify-content-center">
                                <Col xs={12} sm={12} lg={6}>
                                    <TextInput
                                        label="Agency Name*"
                                        name="name"
                                        type="text"
                                        id="name"
                                        width="100%"
                                    />
                                </Col>
                                <Col xs={12} sm={12} lg={6}>
                                <TextInput
                                        label="Email*"
                                        name="email"
                                        type="email"
                                        id="email"
                                        width="100%"
                                        disabled={edit}
                                    />
                                </Col>
                                <Col xs={12} sm={6} lg={6}>
                                    <TextInput
                                        label="Owner First Name*"
                                        name="ownerFirstName"
                                        type="text"
                                        id="name"
                                        width="100%"
                                    />
                                </Col>
                                <Col xs={12} sm={6} lg={6}>
                                    <TextInput
                                        label="Owner Last Name*"
                                        name="ownerLastName"
                                        type="text"
                                        id="name"
                                        width="100%"
                                    />
                                </Col>
                                <Col xs={12} sm={12} lg={6}>
                                <TextInput
                                        label="Status*"
                                        name="status"
                                        type="text"
                                        id="status"
                                        width="100%"
                                        as="select"
                                        // value={status}
                                        // onChange={() =>}
                                    >
                                        <option value="active">Active</option>
                                        <option value="pending">Pending</option>
                                        <option value="inactive">Inactive</option>
                                    </TextInput>
                                </Col>
                                <Col xs={12} sm={12} lg={6}>
                                    <TextInput
                                        label="Phone*"
                                        name="phone"
                                        type="phoneNumber"
                                        id="phone"
                                        width="100%"
                                    />
                                </Col>
                            </Row>
                            <Row className="w-100">
                                <Col>
                                    <div
                                        style={{ marginTop: '18px' }}
                                        className="d-flex justify-content-center"
                                    >
                                      <Button className='button' 
                                        type="submit">
                                           {edit == true ? 'Update' : 'Create' }
                                            </Button>
                                        <Button
                                            type="button"
                                            onClick={goback}
                                            className="ml-sm-3 button"
                                        >
                                            Go Back
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </StyledForm>
                    </Formik>
                </div>
            </>
        </AgencyContext.Provider>
    )
}

export default Create
