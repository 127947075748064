import React, { useEffect, useState } from 'react'
import Card from '../../Admin/shared/Card'
import P from '../../Admin/shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from "react-select/async"
import styles from '../../Admin/styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../../utils'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   '../../Admin/styles/DataTableCustom.scss'

const JobsAnalyticPage = (prop) => {
    const queryParams = new URLSearchParams(window.location.search)
    const urlRecruiterId = queryParams.get("recruiter")
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    const jobId = queryParams.get("job")
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(prop.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [organization,setOrganization] = useState([])
    const [selectedOrganization, setSelectedOrganization]  = useState(prop.organization ? prop.organization[0].value  : '')
    const [selectedOrganizationValue, setSelectedOrganizationValue]  = useState(prop.organization ? prop.organization[0]  : '')
    const [jobs,setJobs] = useState([{value: 'all', label: 'All'},...prop.jobs_list])
    const [selectedJob,setSelectedJob] = useState(jobId || '')
    const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'},...prop.recruiter_list])
    const [account_managers,setAccountManagers] = useState([{value: 'all', label: 'All'},...prop.team_leads])
    const [selectedRecruiter,setSelectedRecruiter] = useState((urlRecruiterId)?urlRecruiterId:'')
    const [selectedTeamLead,setSelectedTeamLead] = useState('')
    const [selected,setSelected] = useState((jobId)? prop.jobs_list.filter((option) => option.value == jobId) :{value: 'all', label: 'All'})
    const [defaultselected,setdefaultselected] = useState((urlRecruiterId)? prop.recruiter_list.filter((option) => option.value == urlRecruiterId) :{value: 'all', label: 'All'})
    const [jobStatus, setJobStatus] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [member_options, setOptions] = useState(prop.organization ? [prop.organization]  : [])
    const [selectOrganizationType,setSelectOrganizationType] = useState('')
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
    const [loading, setLoder] = useState(true)
    const handleDate = (date) => {
        setDateRange(date)
    }
    const csvExportRef = React.useRef();
    const csvExportRef2 = React.useRef();
    const [exportDatas, setExportDatas] = useState([])
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    console.log(selectedOrganization)
    const headers = [
        { label: 'Job Name', key: 'jobName' },
        { label: 'Organization', key: 'organization' },
        { label: 'Primary Owner', key: 'recruiterName' },
        { label: 'Leads', key: 'leads' },
        { label: 'Applicants', key: 'applicants' },
        { label: 'Recruiter Screened', key: 'recruiterScreened' },
        { label: 'Submitted', key: 'submitted' },
        { label: 'First Interview', key: 'firstInterview' },
        { label: 'Second Interview', key: 'secondInterview' },
        { label: 'Offers', key: 'offers' },
        { label: 'Hired', key: 'hired' },
        { label: 'Archived', key: 'archived' },
    ];

    const headers2 = [
        { label: 'IDs.', key: 'id' },
        { label: 'Job Name', key: 'jobName' },
        { label: 'Job Link', key: 'jobLink' },
        { label: 'Organization', key: 'organization' },
        { label: 'Tech Group', key: 'recruiterName' },
    ];

    const organization_type_list = [
        { value: 'all', label: 'All' },
        { value: 'hourly', label: 'Hourly' },
        { value: 'contingency', label: 'Contingency' }
    ];
    const fetchData = async () => {
        setLoder(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = '/agencies/analytic/job.json'
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&organization=${selectedOrganization}&recruiter=${selectedRecruiter}&account_manager_id=${selectedTeamLead}&organization_type=${selectOrganizationType}&job=${selectedJob}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setJobStatus(response?.data?.jobs_status.map((data,key)=> (sr_no = sr_no + 1,{
            id: sr_no,
            jobName: capitalize(data?.job_name),
            organization:  (data?.organizations_name)?capitalize(data?.organizations_name):'',
            recruiterName: `${data?.user_name ? `${capitalize(data?.user_name)}` : '-' }`,
            leads: data.lead > 0 ? <a href={`/agencies/analytic/job/detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=lead`}>{data.lead}</a> : '',
            applicants: data.applicant > 0 ? <a href={`/agencies/analytic/job/detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=applicant`}>{data.applicant}</a> : '',
            recruiterScreened: data.recruitor_screened > 0 ? <a href={`/agencies/analytic/job/detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> : '',
            submitted: data.submitted > 0 ? <a href={`/agencies/analytic/job/detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a> : '',
            firstInterview: data.first_interview > 0 ? <a href={`/agencies/analytic/job/detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> : '',
            secondInterview: data.second_interview > 0 ? <a href={`/agencies/analytic/job/detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> : '',
            offers: data.offer > 0 ? <a href={`/agencies/analytic/job/detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a> : '',
            archived: data.reject > 0 ? <a href={`/agencies/analytic/job/detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> : '',
            organization_type: data.organization_type,
            hired: data.hired > 0 ? <a href={`/agencies/analytic/job/detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> : ''
        })))
        setFooter({id:'Total',
            applicants:response.data.total_values.applicants,
            leads:response.data.total_values.leads,
            recruiterScreened:response.data.total_values.recruiterScreened,
            submitted:response.data.total_values.submitted,
            firstInterview:response.data.total_values.firstInterview,
            secondInterview:response.data.total_values.secondInterview,
            offers:response.data.total_values.offers,
            archived:response.data.total_values.archived,
            no_of_jobs:response.data.total_values.no_of_jobs,
            no_of_clients:response.data.total_values.no_of_clients,
            hired:response.data.total_values.hired
        })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoder(false)
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            maxWidth: '4%',
            minWidth: '4%'
        },
        {
            name: 'Job name',
            selector: row => row.jobName,
            sortable: true,
            sortField: 'job_name',
            maxWidth: '18%',
            minWidth: '18%',
            wrap:true
        },
        {
            name: 'Organization',
            selector: row => row.organization,
            sortable: true,
            sortField: 'organizations_name',
            maxWidth: '12%',
            minWidth: '12%',
            wrap:true
        },
        {
            name: 'Primary Owner',
            selector: row => row.recruiterName,
            sortable: true,
            sortField: 'user_name',
            maxWidth: '12%',
            minWidth: '12%',
            wrap:true
        },
        {
            name: 'Leads',
            selector: row => row.leads,
            sortable: true,
            sortField: 'lead',
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Applicants',
            selector: row => row.applicants,
            sortable: true,
            sortField: 'applicant',
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Recruiter Screened',
            selector: row => row.recruiterScreened,
            sortable: true,
            sortField: 'recruitor_screened',
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Submitted',
            selector: row => row.submitted,
            sortable: true,
            sortField: 'submitted',
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'First Interview',
            selector: row => row.firstInterview,
            sortable: true,
            sortField: 'first_interview',
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Second Interview',
            selector: row => row.secondInterview,
            sortable: true,
            sortField: 'second_interview',
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Offers',
            selector: row => row.offers,
            sortable: true,
            sortField: 'offer',
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Hired',
            selector: row => row.hired,
            sortable: true,
            sortField: 'hired',
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Archived',
            selector: row => row.archived,
            sortable: true,
            sortField: 'reject',
            maxWidth: '6%',
            minWidth: '6%'
        },
    ]

    const exportDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = '/agencies/analytic/job.json'
        const response = await makeRequest(
            `${url}?organization=${selectedOrganization}&recruiter=${selectedRecruiter}&account_manager_id=${selectedTeamLead}&organization_type=${selectOrganizationType}&job=${selectedJob}&start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setExportDatas(response?.data?.jobs_status.map((data,key)=> ({
            id: key,
            jobName: data?.job_name && capitalize(data?.job_name),
            organization: (data?.organizations_name)?capitalize(data?.organizations_name)+' ('+(data?.organization_type)?.charAt(0).toUpperCase()+')':capitalize(data?.organizations_name),
            recruiterName: `${data?.user_name ? `${capitalize(data?.user_name)}` : '-' }`,
            leads: data.lead,
            applicants: data.applicant,
            recruiterScreened: data.recruitor_screened,
            submitted: data.submitted,
            firstInterview: data.first_interview,
            secondInterview: data.second_interview,
            offers: data.offer,
            archived: data.reject,
            organization_type: data.organization_type,
            hired: data.hired
        })))
        csvExportRef?.current?.link.click();
    }

    const exportAllDataMethod = async () => {
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const url = '/admin/all_jobs.json'
        const response = await makeRequest(
            `${url}`,
            'get',
            {}
        )
        setExportDatas(response?.data?.jobs?.map((data,key)=> ({
            id: data?.id,
            jobName: data?.name && capitalize(data?.name),
            jobLink: 'https://cardinaltalent.ai/admin/jobs/'+data.id+'/edit',
            organization: data?.organization?.name && capitalize(data?.organization?.name),
            recruiterName: "",
        })))
        csvExportRef2?.current?.link.click();
    }

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/agencies/jobs/fetch_agency_organization?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {organizations} = res
          setLoading(false)
          setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(member_options);
    }

    const handleInputChange = (str) => {
        setInputValue(str)
        return str;
    }

    const handleSelectOption = (selectedOptions) => {
        setActivePage(1)
        setSelectedOrganization(selectedOptions?.value || '')
        setSelectedOrganizationValue(selectedOptions || {})
    }

    useEffect(()=>{
        console.log(selectOrganizationType)
        fetchData()
    },[selectedOrganization,selectedRecruiter,selectedJob,dateRange,activePage, selectedTeamLead,selectOrganizationType,sortDirection,sortField])

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
    };

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Jobs Analytics
                </P>
                <div>
                    <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                        Export CSV
                    </button>
                </div>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={"jobs_analytic.csv"}
                ref={csvExportRef}
            >
            </CSVLink>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount}  Jobs </DisplayPagination>
                    }
                </Col>
                <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                    <div style={{display: 'flex'}}>
                        <div>
                            <label>
                                Organization
                            </label>
                            <AsyncSelect
                                isLoading={isLoading}
                                isClearable={true}
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultValue={selectedOrganizationValue}
                                defaultOption={selectedOrganization}
                                onInputChange={handleInputChange}
                                onChange={handleSelectOption}
                                placeholder={'Search by organization'}
                                styles={colourStyles}
                                noOptionsMessage={() => 'start typing the name of organization'}
                            />
                        </div>
                        <div style={{marginLeft:'10px'}}>
                            <label>
                                Job Name
                            </label>
                            <Select
                                defaultValue={selected}
                                options={jobs}
                                onChange={(event)=>setSelectedJob(event.value)}
                                name="job_name"
                                className={styles.colourStyles}
                            />
                        </div>
                        <div>
                            <label>
                                Recruiter
                            </label>
                            <Select
                                defaultValue={defaultselected}
                                options={recruiter}
                                onChange={(event)=>setSelectedRecruiter(event.value)}
                                name="recruiter"
                                className={styles.colourStyles}
                            />
                        </div>
                        <div>
                            <label>
                                Account Manager
                            </label>
                            <Select
                                options={account_managers}
                                onChange={(event)=>setSelectedTeamLead(event.value)}
                                name="team_lead"
                                className={styles.colourStyles}
                            />
                        </div>
                        <div style={{display:'grid'}}>
                            <label>
                                Date
                            </label>

                            <DateRangePicker
                                handleOnSubmit={handleDate}
                                defaultDate={dateRange}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobStatus}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
        
    )
}

export default JobsAnalyticPage