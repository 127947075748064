import React, { useState, useEffect, Fragment } from 'react';
import { Form, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable'
import axios from 'axios';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';

import './styles/JobModal.scss'
import CustomRichTextarea from '../inputs/CustomRichTextarea/CustomRichTextarea'
import CloseButton from '../Styled components/CloseButton'
import ImportJobsModal from '../ImportJobsModal/ImportJobsModal'
import { colourStyles } from './styles/JobSelect.styles'
import { Container,Box,Required,Error,Row,Button,W4text } from './styles/JobModal.styled'
import { CKEditor } from 'ckeditor4-react';
import TextInput from '../../pages/Admin/shared/TextInput';

const initialJob = {
    name: '',
    location: '',
    description: '',
    mustHaveSkills: [],
    department: '',
    notificationemails: '',
    job_salary: '',
    linkedin_url: '',
    linkedin_url_2: '',
    linkedin_url_3: '',
    creator_id: '',
    group: '',
    group_id: '',
    linkedin_job_url: '',
    flag: 'Updated a Job',
    tech_groups: [],
    create_lead: '',
    status:[],
    industry: []
};
const industries = [
    { value: 'Accounting', label: 'Accounting' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Research and Development', label: 'Research and Development' },
    { value: 'IT', label: 'IT' },
    { value: 'Management', label: 'Management' },
    { value: 'Administration', label: 'Administration' },
    { value: 'Customer support', label: 'Customer support' },
    { value: 'Technical support', label: 'Technical support' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Logistics', label: 'Logistics' },
    { value: 'Operations', label: 'Operations' },
    { value: 'Human resources', label: 'Human resources' },
    { value: 'Purchasing', label: 'Purchasing' },
    { value: 'Quality assurance', label: 'Quality assurance' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Public relations', label: 'Public relations' },
];

const techTalentList = [
  { value: 'Accounting / Finance', label: 'Accounting / Finance' },
  { value: 'Account Executives', label: 'Account Executives' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Customer Success', label: 'Customer Success' },
  { value: 'Designers', label: 'Designers' },
  { value: 'HR', label: 'HR' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Marketers', label: 'Marketers' },
  { value: 'Recruiters', label: 'Recruiters' },
  { value: 'SDRs', label: 'SDRs' },
  { value: 'Software Engineers', label: 'Software Engineers' },
];

const selectJobStatus = [
  {value: 'onhold', label: 'Yes'},
  {value: 'active', label: 'No'},
]

const createLeadList = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];

function JobModal({ job, organizationId, cardinalMember, currentUser, handleSubmitJob, onClose, label, jobGroup, members}) {
  const [newJob, setNewJob] = useState(initialJob)
  const [isLoading, setIsLoading] = useState(false)
  const [validationError, setValidationError] = useState({
      location: null,
      description: null,
      mustHaveSkills: null,
      linkedin_url: null,
      linkedin_url_2: null,
      linkedin_url_3: null,
      category: null,
      tech_groups:null,
      name:null,
      jobSalary:null,
      industry:null
  });
  
  const [jobGroupsOption, setJobGroupsOption] = useState([...jobGroup])
  const [defaultGroup, setDefaultGroup] = useState(job?.group_id && jobGroup && jobGroup.filter(el => el.value === job.group_id).map(category => ({value: category.value, label: category.label})))
  const [showImportJob, setShowImportJob] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [skillOptions, setSkillOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [matchedSkillOptions, setMatchedSkillOption] = useState([]);
  const [ownerUpdate, setOwnerUpdate] = useState(false)
  const [primaryOwnerUpdate, setPrimaryOwnerUpdate] = useState(false)
  const [recruiterMember, setRecruiterMember] = useState()
  const [linkedinURL, setLinkedinURL] = useState(job.linkedin_job_url || true)
  const [jobstatus, setJobStatus] = useState(job?.status == 'onhold' ? [{value: "onhold" , label: 'Yes'}] : [{value: "active" , label: 'No'}] )
   
  let holdSkillOptions = []

    useEffect(() => {
      setRecruiterMember(cardinalMember && cardinalMember?.map((el)=>(el.value == currentUser.id && true)))
    fetchSkillsByDepartment();
    getLookupIndustry()
  }, []);

    useEffect(() => {
        if (_.isEmpty(job)) {
      setNewJob({ ...newJob, notificationemails: currentUser.email });
        } else {
          // console.log(job);
            setNewJob((prev) => ({
                ...prev,
                name: job.name,
                location: job.location == "null" ? {} : { value: 9999, label: job.location },
                department: { value: job.department, label: job.department },
                notificationemails: job.notificationemails,
                description: job.description,
                linkedin_url: job.linkedin_url == "null" ? '' : job.linkedin_url,
                linkedin_url_2: job.linkedin_url_2 == "null" ? '' : job.linkedin_url_2,
                linkedin_url_3: job.linkedin_url_3 == "null" ? '' : job.linkedin_url_3,
                creator_id: '',
                status: {value: job?.status, label: job?.status},
                group: defaultGroup || null,
                linkedin_job_url: job.linkedin_job_url,
                job_salary: job.job_salary,
                create_lead: { value: job.create_lead.toString(), label: job.create_lead.toString().charAt(0).toUpperCase() + job.create_lead.toString().slice(1)},
                mustHaveSkills: [
                  ...job.skills.map((skill) => {
                    return {
                      value: skill.trim().toLowerCase(),
                      label: skill.trim().toLowerCase(),
                      autoMatched: false,
                    };
                  }),
                ],
                tech_groups: job?.tech_group != '' && job?.tech_group != null ? [
                  ...job.tech_group?.split(',').map((tech_group) => {
                    return {
                      value: tech_group.trim(),
                      label: tech_group.trim(),
                      autoMatched: false,
                    };
                  }),
                ] : [],
                industry: job?.industry != '' && job?.industry != null ? [
                  ...job.industry?.split(',').map((ind) => {
                    return {
                      value: ind.trim(),
                      label: ind.trim()
                    };
                  }),
                ] : [],
                // status: job?.status != '' && job?.status != null ? [
                //   ...job.status?.split(',').map((status) => {
                //     return {
                //       value: status.trim(),
                //       label: jobstatus?.label,
                //       autoMatched: false,
                //     };
                //   }),
                // ] : [],
      }));
        }
  }, [job]);

    const handleKeyPress = async (value, callback) => {
    const url = `/filter_candidate_on_location`;
    const formData = new FormData();
    formData.append('filter_word', value);
    setIsLoading(true);
        const response = await axios
            .post(url, formData)
            .then((res) => res)
      .catch((error) => console.log(error));
        const locationPreferrenceArray = response.data.filter.map(
            ({ id, state, city, country }) => {
              if(country === 'usa' ){
                return { value: id, label: `${city}, ${state} (US)` };
              }else{
                return { value: id, label: `${city}, ${state}` };
              }
            }
    );
    callback(locationPreferrenceArray);
    setIsLoading(false);
  };

  const checkValidation = () => {
    setValidationError({})
    let isValid = true
    

    if (_.isEmpty(newJob.location)) {
      console.log('In Side location')
      setValidationError((prev) => ({
        ...prev,
        location: 'Location is required',
      }));
      isValid = false
    }
    if ( (_.isEmpty(newJob.description.replace(/<[^>]+>/g, ''))) || ((newJob.description.trim()) == '' )) {
      setValidationError((prev) => ({
          ...prev,
          description: 'Job description is required',
      }));
      isValid = false
    }
    if (_.isEmpty(newJob.mustHaveSkills)) {
      setValidationError((prev) => ({
          ...prev,
          mustHaveSkills: 'Must have skills are required',
      }));
      isValid = false
    }
    // if (_.isEmpty(job)) {
      if (_.isEmpty(newJob.job_salary) || ((newJob.job_salary.trim()).length == 0)) {
        setValidationError((prev) => ({
            ...prev,
            jobSalary: 'Job salary is required',
        }));
        isValid = false
      }
    // }
    // if (_.isEmpty(newJob.tech_groups)) {
    //   setValidationError((prev) => ({
    //       ...prev,
    //       tech_groups: 'Job Tech Group is required',
    //   }));
    //   isValid = false
    // }
    if (newJob.tech_groups?.length > 5) {
      setValidationError((prev) => ({
          ...prev,
          tech_groups: 'Max 5 Job Tech Group are allowed',
      }));
      isValid = false
    }
    if (newJob.industry?.length == 0) {
      setValidationError((prev) => ({
          ...prev,
          industry: 'Industry is required',
      }));
      isValid = false
    }
    if(!_.isEmpty(newJob.linkedin_url)){
      const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'); 
      if(!(regex.test(newJob.linkedin_url) && newJob.linkedin_url.includes("linkedin")))
      {
        setValidationError((prev) => ({
          ...prev,
          linkedin_url: 'Invalid Linkedin URL',
        }));
        isValid = false
      }
    }
    if (_.isEmpty(newJob.linkedin_url)) {
      setValidationError((prev) => ({
        ...prev,
        linkedin_url: 'Linkedin URL is required',
      }));
      isValid = false
    }
    if(!_.isEmpty(newJob.linkedin_url_2)){
      const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'); 
      if(!(regex.test(newJob.linkedin_url_2) && newJob.linkedin_url_2.includes("linkedin")))
      {
        setValidationError((prev) => ({
          ...prev,
          linkedin_url_2: 'Invalid Linkedin URL',
        }));
        isValid = false
      }
    }
    if(!_.isEmpty(newJob.linkedin_url_3)){
      const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'); 
      if(!(regex.test(newJob.linkedin_url_3) && newJob.linkedin_url_3.includes("linkedin")))
      {
        setValidationError((prev) => ({
          ...prev,
          linkedin_url_3: 'Invalid Linkedin URL',
        }));
        isValid = false
      }
    }
    const regex = new RegExp(/^[a-zA-Z0-9_]+$/)
    const numberRegex = new RegExp(/^[0-9]+$/)
    const spaceRegex = new RegExp( /^[A-Za-z\s]*$/)
    if ( (_.isEmpty(newJob.name) ) || ((newJob.name.trim()).length == 0) || (!(regex.test(newJob.name))) && (!(numberRegex.test(newJob.name))) && (!(spaceRegex.test(newJob.name)))) {
      setValidationError((prev) => ({
        ...prev,
        name: 'Please enter valid position title',
      }));
      isValid = false
    } 
    return isValid
  };
  

  const handleLocation = (event) => {
    if((event.label)?.trim() != '' && ((event.label)?.trim()).length > 0){
      setNewJob({ ...newJob, location: event });
      setValidationError({ ...validationError, location: null });
    }
  };

  const handleDescriptionChange = (text) => {
    if (_.isEmpty(text)) {
      // To remove tags from empty string
      setNewJob((data) => ({...data, description:text}));
        } else {
            setNewJob((prev) => ({
                ...prev,
        description: text, //To replace empty tag
      }));
        }
        
      if (!job.id)
        autoGenerateSkills(text.replace(/<[^/>][^>]*><\/[^>]+>/gim, ''));

    setValidationError({ ...validationError, description: null });
  };

  const autoGenerateSkills = (text) => {
    const numberOfSkills = 5;
    if (
      newJob?.mustHaveSkills.filter((skill) => skill.autoMatched).length <
      numberOfSkills
    ) {
      const matchedSkills = getMatchedSkills(
        text.toLowerCase(),
        holdSkillOptions
      );
      const topSkills = matchedSkills.slice(0, numberOfSkills);
      const notSelectedSkills = matchedSkills
        .slice(numberOfSkills, matchedSkills.length)
        .map((item) => ({ ...item, autoMatched: false }));
      setMatchedSkillOption([...notSelectedSkills]);
      const userSkill = newJob?.mustHaveSkills.filter(
        (skill) => !skill.autoMatched
      );
      const uniqSkills = _.uniqBy([...userSkill, ...topSkills], 'value');
      setNewJob((prev) => ({ ...prev, mustHaveSkills: [...uniqSkills] }));
    } else {
      const matchedSkills = getMatchedSkills(
        text.toLowerCase(),
        holdSkillOptions
      );
      const matchedTopSkills = newJob?.mustHaveSkills.filter(
        (skill) => skill.autoMatched
      );

      const uniqSkills = _.uniqBy(
        [...matchedSkills, ...matchedTopSkills],
        'value'
      );
      const notSelectedSkills = uniqSkills.map((item) => ({...item, autoMatched: false,}));
      setMatchedSkillOption([...notSelectedSkills]);
    }
  };

  const getMatchedSkills = (description, skills) => {
    const descriptionArray = description.split(/[ ,.]+/); // splited description with whitespace, comma, fillstop
    let matchedSkills = skills.filter((skill) => {
      if (descriptionArray.includes(skill.value.toLowerCase())) {
        return skill.value.toLowerCase();
            }
    });
        
    return matchedSkills.map((skill) =>  ({ ...skill, autoMatched: true }));
  };

  const handleDepartment = (event) => {
    setNewJob({ ...newJob, department: event });
  };

  const handleCreateLead = (event) => {
    setNewJob({ ...newJob, create_lead: event });
  };

  const handlejobStatus = (event) => {
    // setJobStatus({state: event})
    setNewJob({ ...newJob, status: event });
  };

  const handleOwner = (event) => {
    setNewJob({ ...newJob, creator_id: event });
  };

  const handleTechGroup = (event) => {
    setNewJob({ ...newJob, tech_groups: [...event] });
    setValidationError({ ...validationError, tech_groups: null });
  };
  const handleIndustry = (event) => {
    setNewJob({ ...newJob, industry: [...event] });
    setValidationError({ ...validationError, industry: null });
  };
  
  const handleGroup = (event) => {
    setNewJob({ ...newJob, group: event, group_id: event.value });
  };
  const handleLinkedinUrlPost = (e) => {
    setLinkedinURL(e.target.checked)
    setNewJob({ ...newJob, 'linkedin_job_url': e.target.checked });
  }

  const handleMustHaveSkills = (event) => {
    if (newJob.mustHaveSkills.length > event.length) {
      const removedElements = _.difference(newJob.mustHaveSkills, event);
      setRemoved([...removed, ...removedElements]);
      const newSkillOptions = _.differenceBy(skillOptions, removedElements, 'value' );
      setSkillOptions([...newSkillOptions]);
        }
    setNewJob({ ...newJob, mustHaveSkills: [...event] });
    setValidationError({ ...validationError, mustHaveSkills: null });
  };

  const onCreateSkills = (event) => {
    if(event.trim() != '' && (event.trim()).length > 0){
      const newSkill = { value: event, label: event, autoMatched: false };
      const cloneSkills = newJob.mustHaveSkills;
      cloneSkills.push(newSkill);
      setNewJob({ ...newJob, mustHaveSkills: [...cloneSkills] });
      setValidationError({ ...validationError, mustHaveSkills: null });
    }
  };

  const getLookupIndustry = async () => {
    let fetchedIndustry = [];
    fetchedIndustry = await axios
            .get(`/lookup/industry`)
            .then((res) => {
                return res.data.industry;
            })
            .catch((error) => {
                return [];
            });

        const industryOptions = fetchedIndustry.map((ind) => {
          return { value: ind.value, label: ind.key};
        });
        setIndustryOptions([...industryOptions]);
  };
  const fetchSkillsByDepartment = async () => {
    let fetchedSkills = [];
        fetchedSkills = await axios
            .get(`/skills`)
            .then((res) => {
        const skills = res.data.skills;
        return skills.split(',');
            })
            .catch((error) => {
        return [];
      });

        const skillsOptions = fetchedSkills.map((skill) => {
      return { value: skill, label: skill, autoMatched: false };
    });
    setSkillOptions([...skillsOptions]);
    if (job.organization_manager_id == currentUser.id) {
      setOwnerUpdate(true);
    }
    if (job.creator_id == currentUser.id) {
      setPrimaryOwnerUpdate(true);
    }
    holdSkillOptions = [...skillsOptions]
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setNewJob({ ...newJob, [name]: value });
    setValidationError({ ...validationError, name: null });

  };

    const handleClick = async (event) => {
      event.preventDefault()
      setIsSubmit(true)
      const form = event.currentTarget
      const isValid = checkValidation()
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
      } else if (isValid) {
        console.log(newJob)
        await handleSubmitJob(newJob)
      }
      setValidated(true)
      event.preventDefault()
      event.stopPropagation()
      setIsSubmit(false)
    }

    return (
        <Fragment>
            <Row direction="row" aItems="center">
                <Box>
                    <W4text color="#16246B" size="24px">
                        {label}
                    </W4text>
                </Box>
                {/* <Box style={{ marginLeft: '5px' }}>
                    <Button
                        variant="info"
                        type="button"
                        onClick={() => setShowImportJob(true)}
                    >
                        Bulk Job import
                    </Button>
                </Box> */}
                <div style={{ flexGrow: '1' }} />
                <Box>
                    <CloseButton handleClick={() => onClose()} />
                </Box>
            </Row>
            <Container>
                <Form noValidate validated={validated} onSubmit={handleClick}>
                    <Form.Row>
                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>
                                    Position Title<Required>*</Required>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    value={newJob.name}
                                    name="name"
                                />
                                  {validationError.name && (
                                    <Error>{validationError.name}</Error>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Location<Required>*</Required>
                                </Form.Label>
                                <AsyncCreatableSelect
                                    isLoading={isLoading}
                                    styles={colourStyles}
                                    loadOptions={handleKeyPress}
                                    placeholder="Search for location"
                                    onChange={handleLocation}
                                    value={newJob.location}
                                />
                                {validationError.location && (
                                    <Error>{validationError.location}</Error>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Job Department</Form.Label>
                                <Select
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    styles={colourStyles}
                                    name="color"
                                    menuPlacement="auto"
                                    minMenuHeight={6}
                                    onChange={handleDepartment} 
                                    options={industries}
                                    value={newJob.department}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Where would you like to receive your applicants?
                                    <Required>*</Required>
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    name="notificationemails"
                                    onChange={handleChange}
                                    rows={4}
                                    value={newJob.notificationemails}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Email is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {currentUser?.role === "recruiter" && recruiterMember && (
                          <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Job Group</Form.Label>
                                <Select
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    styles={colourStyles}
                                    menuPlacement="auto"
                                    minMenuHeight={6}
                                    onChange={handleGroup}
                                    options={jobGroupsOption}
                                    value={newJob.group}
                                />
                            </Form.Group>
                          </Col>
                        )}
                        { primaryOwnerUpdate && (
                          <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Create Lead</Form.Label>
                                <Select
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      styles={colourStyles}
                                      name="color"
                                      menuPlacement="auto"
                                      minMenuHeight={6}
                                      onChange={handleCreateLead} 
                                      options={createLeadList}
                                      value={newJob.create_lead}
                                  />
                            </Form.Group>
                          </Col>
                          
                        )}

                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>Mark this Job as On Hold</Form.Label>
                                <Select
                                      classNamePrefix="select"
                                      // isSearchable={true}
                                      styles={colourStyles}
                                      name="color"
                                      menuPlacement="auto"
                                      minMenuHeight={6}
                                      onChange={handlejobStatus} 
                                      options={selectJobStatus}
                                      defaultValue={jobstatus}
                                  />
                                  
                            </Form.Group>
                          </Col>
                          <Col xs={12} lg={6}>
                            <Form.Group style={{zIndex: '1000000000000'}}>
                                <Form.Label>Industry<Required>*</Required></Form.Label>
                                <Select
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    styles={colourStyles}
                                    name="industry"
                                    menuPlacement="auto"
                                    minMenuHeight={6}
                                    isMulti
                                    onChange={handleIndustry}
                                    options={industryOptions}
                                    value={newJob.industry}
                                    style={{zIndex: '100000'}}
                                />
                                {validationError.industry && (
                                        <Error>{validationError.industry}</Error>
                                    )}
                            </Form.Group>
                         </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>
                                    Job Description<Required>*</Required>
                                </Form.Label>
                                <CustomRichTextarea
                                    fieldValue={job.description || ''}
                                    handleContentChange={handleDescriptionChange}
                                />
                                {validationError.description && (
                                    <Error>{validationError.description}</Error>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                          <Form.Group>
                              <Form.Label>
                                  Must have skills
                                  <Required>*</Required>
                              </Form.Label>
                              <CreatableSelect
                                  isMulti
                                  createOptionPosition="first"
                                  styles={colourStyles}
                                  value={newJob.mustHaveSkills}
                                  onChange={handleMustHaveSkills}
                                  options={matchedSkillOptions}
                                  menuPlacement="auto"
                                  onCreateOption={onCreateSkills}
                              />
                              {validationError.mustHaveSkills && (
                                  <Error>{validationError.mustHaveSkills}</Error>
                              )}
                          </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                          <Form.Group>
                            <Form.Label>
                                Salary<Required>*</Required>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                onChange={handleChange}
                                value={newJob.job_salary}
                                name="job_salary"
                            />
                              {validationError.jobSalary && (
                                <Error>{validationError.jobSalary}</Error>
                            )}
                          </Form.Group>
                        </Col>
                        {currentUser?.agency_id == 0 &&  
                        <Col xs={12}>
                          {/* <div className="custom-control custom-switch mb-2">
                            <input type="checkbox" class="custom-control-input" checked={linkedinURL} onClick={(e)=>handleLinkedinUrlPost(e)} id="linkedin_url"/>
                            <label class="custom-control-label" for="linkedin_url">Linkedin Job Post URL</label>
                          </div> */}
                        </Col> 
                        }
                        {/* {linkedinURL && ( */}
                        <Col xs={6}>
                          <Form.Group>
                                <Form.Label>
                                    Linkedin URL
                                    <Required>*</Required>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    value={newJob.linkedin_url}
                                    name="linkedin_url"
                                />
                                {validationError.linkedin_url && (
                                  <Error>{validationError.linkedin_url}</Error>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                                <Form.Label>
                                    Linkedin URL
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    value={newJob.linkedin_url_2}
                                    name="linkedin_url_2"
                                />
                                {validationError.linkedin_url_2 && (
                                  <Error>{validationError.linkedin_url_2}</Error>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                                <Form.Label>
                                    Linkedin URL
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    value={newJob.linkedin_url_3}
                                    name="linkedin_url_3"
                                />
                                {validationError.linkedin_url_3 && (
                                  <Error>{validationError.linkedin_url_3}</Error>
                                )}
                            </Form.Group>
                        </Col>
                        {/* )} */}
                        { ownerUpdate ?
                        <Col xs={12} lg={6}>
                          <Form.Group>
                              <Form.Label>Job Owner</Form.Label>
                              <Select
                                  classNamePrefix="select"
                                  isSearchable={true}
                                  styles={colourStyles}
                                  name="creator_id"
                                  menuPlacement="auto"
                                  minMenuHeight={6}
                                  onChange={handleOwner}
                                  options={members}
                                  //value={newJob.department}
                              />
                          </Form.Group>
                        </Col>
                        : (
                          <>
                          </>
                        )}
                        <Col xs={12} lg={6}>
                        <Form.Group style={{zIndex: '1000000000000'}}>
                            <Form.Label>Tech Group</Form.Label>
                            <Select
                                classNamePrefix="select"
                                isSearchable={true}
                                styles={colourStyles}
                                name="tech_group"
                                menuPlacement="auto"
                                minMenuHeight={6}
                                isMulti
                                onChange={handleTechGroup}
                                options={techTalentList}
                                value={newJob.tech_groups}
                                style={{zIndex: '100000'}}
                            />
                            {validationError.tech_groups && (
                                    <Error>{validationError.tech_groups}</Error>
                                )}
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Row jContent="flex-end">
                        <Button
                            type="submit"
                            style={{ marginRight: 0 }}
                            disabled={isSubmit}
                        >
                            { isSubmit ? <Spinner animation="border" variant="light" /> : _.isEmpty(job) ? 'Create' : 'Update'}
                        </Button>
                    </Row>
                </Form>
            </Container>
            <ImportJobsModal
                handleModalClose={() => setShowImportJob(false)}
                showModal={showImportJob}
                organization_id={organizationId}
                setShowModal={setShowImportJob}
            />
        </Fragment>
  );
}

export default JobModal;
