import React from 'react'
import Card from 'react-bootstrap/Card'
import styles from './styles/FilterGroup.module.scss'
import './styles/FilterGroup.scss'


function FilterGroup_v2({ children, filterCount, experienceYearsCount, candidatePage }) {

    const filtersActive = filterCount > 0

    const experienceYears = experienceYearsCount ? true : ''

    return (

        <Card className={`${styles.filtersPanel + ' shadow-sm'} ${candidatePage ? 'candidate-card' : 'filters-card'}`} style={{width:'100%'}}>
            <Card.Body className="p-0">{children}</Card.Body>
        </Card>
    )
}

export default FilterGroup_v2
