import React, { useEffect, useState } from 'react'
import Card from './shared/Card'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const LowMailLog = (prop) => {
  const queryParams = new URLSearchParams(window.location.search)
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  let Log_start_date = sessionStorage.getItem("Log_start_date");
  let Log_end_date = sessionStorage.getItem("Log_end_date")
  let recruiter_names = sessionStorage.getItem('recruiter_names');
  const recruiterId = queryParams.get("team_id")
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(prop.per_page || 50)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobStatus, setJobStatus] = useState([])
  const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate : Log_start_date ? Log_start_date : moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : Log_end_date ? Log_end_date : moment().toDate()})
  const handleDate = (date) => {
      setDateRange(date)
  }
  const [recruiterLists,setRecruiterLists] = useState([{value: '', label: 'All'},...prop.recruiter_lists])
  const [selectedRecruiter,setSelectedRecruiter] = useState((recruiterId)? prop.recruiter_lists.filter((option) => option.value == recruiterId) : recruiter_names && recruiter_names != "null" ? JSON.parse(recruiter_names).value : "")
  const [totalValues,setTotalValues] = useState({})
  const [defaultselected,setdefaultselected] = useState((recruiterId)? prop.recruiter_lists.filter((option) => option.value == recruiterId) : recruiter_names && recruiter_names != "null" ? JSON.parse(recruiter_names) : {value: 'all', label: 'All'})
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('recruiter_name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [footer, setFooter] = useState({})

  const tableHeaders = [
    { label: 'Recruiter Name', key: 'recruiter_name' },
    { label: 'Recruiter Emai', key: 'recruiter_email' },
    { label: 'Start Date', key: 'start_date' },
    { label: 'End Date', key: 'end_date' },
    { label: 'InMails Sent', key: 'mails' },
  ]
  
  const fetchData = async () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    sessionStorage.setItem("Log_start_date", start_date)
    sessionStorage.setItem("Log_end_date", end_date)
    const url = '/admin/low_inmail_log.json'
    const response = await makeRequest(
      `${url}?page=${activePage}&start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setTotalCount(response.data.total_count)
    let sr_no = (activePage - 1) * response.data.per_page
    setJobStatus(response?.data?.detail_list.map((data,key)=> (sr_no = sr_no + 1 ,{
      id: sr_no,
      recruiter_name: data?.recruiter_name && capitalize(data?.recruiter_name),
      recruiter_email: data.recruiter_email,
      mails: data.mails,
      start_date: data?.start_date ? moment(data.start_date).format('MMM DD, YYYY') : '',
      end_date: data?.end_date ? moment(data.end_date).format('MMM DD, YYYY') : '',
      recruiterId: data.recruiter_id
    })))
   
    setPageCount(response.data.total_pages)
    setStartRecord(((activePage - 1) * response.data.per_page)+1)
    setCurrentCounts(response.data.current_counts)
    setTotalValues(response.data.total_values)
    setLoading(false)
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/low_inmail_log.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.detail_list.map((data,key)=> ({
        recruiter_name: data?.recruiter_name && capitalize(data?.recruiter_name),
        recruiter_email: data.recruiter_email,
        mails: data.mails,
        start_date: data?.start_date ? moment(data.start_date).format('MMM DD, YYYY') : '',
        end_date: data?.end_date ? moment(data.end_date).format('MMM DD, YYYY') : '',
        recruiterId: data.recruiter_id
    })))
    csvExportRef?.current?.link.click();
  }
  const columns = [
    {
        name: 'No.',
        selector: row => row.id,
        maxWidth: '5%',
        minWidth: '5%'
    },
    {
        name: 'Recruiter Name',
        selector: row => row.recruiter_name,
        cell: (row) => <div title={row.recruiter_name}>{row.recruiter_name}</div>,
        maxWidth: '25%',
        minWidth: '25%',
        sortable: true,
        sortField: 'recruiter_name'
    },
    {
        name: 'Recruiter Email',
        selector: row => row.recruiter_email,
        cell: (row) => <div title={row.recruiter_email}>{row.recruiter_email}</div>,
        maxWidth: '25%',
        minWidth: '25beginning_of_week%',
        sortable: false,
        sortField: 'recruiter_email'
    },
    {
        name: 'Start Date',
        selector: row => row.start_date,
        cell: (row) => <div title={row.start_date}>{row.start_date}</div>,
        maxWidth: '15%',
        minWidth: '15%',
        sortable: false,
        sortField: 'start_date'
    },
    {
        name: 'End Date',
        selector: row => row.end_date,
        cell: (row) => <div title={row.end_date}>{row.end_date}</div>,
        maxWidth: '15%',
        minWidth: '15%',
        sortable: false,
        sortField: 'end_date'
    },
    {
        name: 'InMails Sent',
        selector: row => row.mails,
        cell: (row) => <div title={row.mails}>{row.mails}</div>,
        maxWidth: '15%',
        minWidth: '15%',
        sortable: true,
        sortField: 'mails'
    },
 ]

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage,selectedRecruiter,sortDirection,sortField])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }

  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }


  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
             Recruiters Low InMails Send Log
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"LowInMailSendLog.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                  Recruiter
            </label>
            <Select
                defaultValue={defaultselected}
                options={recruiterLists}
                onChange={(event)=>{
                  setSelectedRecruiter(event.value)
                  sessionStorage.setItem("recruiter_names", JSON.stringify(event))
                  
                }
                }
                name="team_lists"
                className={styles.colourStyles}
            />
          </div>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Select Date Range
            </label>
            <DateRangePicker
                handleOnSubmit={handleDate}
                defaultDate={dateRange}
            />
          </div>
        </Col>
      </Row>
      <DataTable
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={jobStatus}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        onSort={handleSort}
        sortServer
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalCount}
        paginationPerPage={perPage}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        responsive={true}
        onChangePage={page => setActivePage(page)}
      />
    </Card>
  )
}

export default LowMailLog