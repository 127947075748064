import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from '../Admin/shared/Card'
import TableRecruiter from '../Admin/shared/Table'
import Paginator from '../../common/Paginator/Paginator'
import styles from './styles/AnalyticPage.module.scss'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import P from '../Admin/shared/P'
import './styles/RecruiterManagment.scss'
import feather from 'feather-icons'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import ResumeIcon from '../../../../assets/images/icons/resume-icon'
import Modal from 'react-bootstrap/Modal'
import { Row,Col } from 'react-bootstrap'
import SearchBar from '../../common/SearchBar/SearchBar'
import { Input } from '@material-ui/icons'


const AutomationAnalyticReport = () => {
    var query = window.sessionStorage.getItem('automation_analytic_query')
    const queryParams = new URLSearchParams(window.location.search)
    const urlStartDate = sessionStorage.getItem("automation_analyticl_start_date")
    const urlEndDate = sessionStorage.getItem("automation_analyticl_end_date")
    const [activePage, setActivePage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [incomingMails, setIncomingMails] = useState([])
    const [open,setOpen] = useState(false)
    const [totalMails,setTotalMails] = useState()
    const [currentCounts,setCurrentCounts] = useState()
    const [loading, setLoading] = useState(true)
    const [startRecord, setStartRecord] = useState(0)
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
    const handleDate = (date) => {
        setDateRange(date)
    }
    const fetchData = () => {
        setLoading(true)
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        sessionStorage.setItem("automation_analyticl_start_date", start_date)
        sessionStorage.setItem("automation_analyticl_end_date", end_date)
        const url = ` /analytic/automation_analytic.json?page=${activePage + 1}&start_date=${start_date}&end_date=${end_date}&search=${searchTerm}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (response) => {
                setIncomingMails(
                    response.data.email_report.map((dt,key) => ({
                        id: key,
                        job_name: dt.job_name,
                        total_email: dt.total_email,
                        total_email_responed: dt.total_forwarded_email,
                        total_sms: dt.total_sms,
                        total_sms_received: dt.total_sms_received,
                        org_name: dt.org_name
                    }))
                )
                setPageCount(response.data.total_pages)
                setCurrentCounts(response.data.current_count)
                setTotalMails(response.data.total_mails)
                setStartRecord((activePage * 25) + 1) 
                setLoading(false)
            },
        })
    }

    useEffect(() => {
        fetchData()
        feather.replace()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,dateRange])

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('automation_analytic_query',value)
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{ width: '90%'}}>Automation Analytics</P>
            </div>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '15px' }}
            >
                <Col xs={12} sm={12} lg={6} style={{float:'left'}}>
                    {totalMails > 0 &&
                        <DisplayPagination>Displaying  {(activePage == 0)?1:startRecord} - {currentCounts} of {totalMails} Emails</DisplayPagination>
                    }
                </Col>
                <Col xs={12} sm={12} lg={3}>
                    <div style={{display: 'grid',marginLeft:'10px',float:'right'}}>
                        <label>
                            Select Date Range
                        </label>
                        <DateRangePicker
                            handleOnSubmit={handleDate}
                            defaultDate={dateRange}
                        />
                    </div>
                </Col>
                <Col xs={12} sm={12} lg={3} style={{marginTop:'1.2%',marginLeft: '3%'}}>
                    <SearchBar
                        placeholder="Search Job Name"
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onChange={(e) => onChangeSearchTerm(e.target.value)}
                        onEnterPressed={() => {
                            activePage === 0 ? fetchData() : setActivePage(0)
                            !searchTerm && window.sessionStorage.setItem('recruiter_incoming_mail_query','')
                        }}
                        onClick={(e) => setSearchTerm('')}
                    />
                </Col>
            </div>
            <TableRecruiter
                columNames={[
                    {
                        name: 'No.',
                        field: 'id',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Job Name',
                        field: 'job_name',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Organization',
                        field: 'org_name',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Email Sent',
                        field: 'total_email',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'Email Response',
                        field: 'total_email_responed',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'SMS Sent',
                        field: 'total_sms',
                        editable: false,
                        type: 'text',

                    },
                    {
                        name: 'SMS Response',
                        field: 'total_sms_received',
                        editable: false,
                        type: 'text',

                    }
                ]}
                rowValues={incomingMails.map((o) => ({
                    ...o,
                }))}
                activePage={activePage}
                loading={loading}
            />
            {pageCount > 1 && (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 'auto' }}
                >
                    <Paginator
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageCount={pageCount}
                        pageWindowSize={5}
                        showGoToField={false}
                    />
                </div>
            )}
        </Card>
    )

}

export default AutomationAnalyticReport