import React, { useEffect, useState } from 'react'
import Card from '../../Admin/shared/Card'
import P from '../../Admin/shared/P'
import { Row,Col,Spinner, Form } from 'react-bootstrap'
import styles from '../../Admin/styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import Select from 'react-select'
import DataTable from "react-data-table-component-footer"
import   '../../Admin/styles/DataTableCustom.scss'

const RecruiterAnalyticPage = (prop) => {
    const team_lead_only = sessionStorage.getItem('team_lead_only')
    const queryParams = new URLSearchParams(window.location.search)
    const urlteamId = queryParams.get("team")
    const urlStartDate = queryParams.get("start_date")
    const urlEndDate = queryParams.get("end_date")
    const localStorageDateRange = JSON.parse(sessionStorage.getItem('agency_admin_recruiterAnalysticDateRange'))
    const startDate = (urlStartDate ? urlStartDate : (localStorageDateRange?.startDate) ? localStorageDateRange?.startDate : moment().startOf('week').toDate())
    const endDate = (urlEndDate ? urlEndDate : (localStorageDateRange?.endDate) ? localStorageDateRange?.endDate : moment().toDate())
    const [activePage, setActivePage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [startRecord, setStartRecord] = useState(0)
    const [perPage, setPerPage] = useState(prop.per_page)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [jobStatus, setJobStatus] = useState([])
    const [exportDatas, setExportDatas] = useState([])
    const csvExportRef = React.useRef();
    const [dateRange, setDateRange] = useState({startDate: startDate ,endDate: endDate})
    const handleDate = (date) => {
        sessionStorage.setItem('agency_admin_recruiterAnalysticDateRange', JSON.stringify(date))
        setDateRange(date)
    }
    const [teamLists,setTeamLists] = useState([{value: 'All', label: 'All'},...prop.team_lists])
    const [selectedTeam,setSelectedTeam] = useState((urlteamId)?urlteamId:'')
    const [defaultselected,setdefaultselected] = useState((urlteamId)? prop.team_lists.filter((option) => option.value == urlteamId) :{value: 'all', label: 'All'})
    
    const [designationLists, setDesignationLists] = useState([{value: 'All', label: 'All'}, ...prop.designation_list])
    const [selectedDesignation, setSelectedDesignation] = useState('All')
    const [defaultDesignationselected, setdefaultDesignationselected] = useState({label: selectedDesignation, value: selectedDesignation})
    const [loading, setLoading] = useState(true)
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')
    const [footer, setFooter] = useState({})
    const [teamLeadOnly, setTeamLeadOnly] = useState(team_lead_only == 'true' ? true : false)

    const headers = [
        { label: 'Recruiter Name', key: 'accountManager' },
        { label: 'No of Clients', key: 'no_of_clients' },
        { label: 'No of Jobs', key: 'no_of_jobs' },
        { label: 'Recruiter Screened', key: 'recruiterScreened' },
        { label: 'Submitted', key: 'submitted' },
        { label: 'First Interview', key: 'firstInterview' },
        { label: 'Second Interview', key: 'secondInterview' },
        { label: 'Offers', key: 'offers' },
        { label: 'Hired', key: 'hired' },
        { label: 'Archived', key: 'archived' },
    ]
    const columns = [
        {
            name: 'No',
            selector: row => row.id
        },
        {
            name: 'Recruiter Name',
            selector: row => row.accountManager,
            sortable: true,
            sortField: 'recruiter_name',
            wrap:true
        },
        {
            name: 'No of Clients',
            selector: row => row.no_of_clients
        },
        {
            name: 'No of Jobs',
            selector: row =>  row.no_of_jobs
        },
        {
            name: 'Recruiter Screened',
            selector: row =>  row.recruiterScreened,
            sortable: true,
            sortField: 'recruitor_screened'
        },
        {
            name: 'Submitted',
            selector: row => row.submitted,
            sortable: true,
            sortField: 'submitted'
        },
        {
            name: 'First Interview',
            selector: row =>  row.firstInterview,
            sortable: true,
            sortField: 'first_interview'
           
        },
        {
            name: 'Second Interview',
            selector: row =>  row.secondInterview,
            sortable: true,
            sortField: 'second_interview'
        },
        {
            name: 'Offers',
            selector: row => row.offers,
            sortable: true,
            sortField: 'offer'
        },
        {
            name: 'Hired',
            selector: row =>   row.hired,
            sortable: true,
            sortField: 'hired'
        },
        {
            name: 'Archived',
            selector: row =>  row.archived,
            sortable: true,
            sortField: 'reject'
        },
    ]

    const fetchData = async () => {
        setLoading(true)
        const url = '/agencies/analytic/recruiter.json'
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        const response = await makeRequest(
            `${url}?page=${
                activePage
            }&team_lead=${teamLeadOnly}&start_date=${start_date}&end_date=${end_date}&team=${selectedTeam}&designation=${selectedDesignation}&sortField=${sortField}&sortDirection=${sortDirection}`,
            'get',
            {}
        )
        setTotalCount(response.data.total_count)
        let sr_no = (activePage - 1) * response.data.per_page
        setJobStatus(response?.data?.org_status.map((data,key)=> (sr_no = sr_no + 1,{
            id: sr_no ,
            recruiterScreened: (data.recruitor_screened > 0) ? <a href={`/agencies/analytic/recruiter/detail?recruiter=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> : '',
            submitted: (data.submitted > 0) ? <a href={`/agencies/analytic/recruiter/detail?recruiter=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a> : '',
            firstInterview: (data.first_interview > 0) ? <a href={`/agencies/analytic/recruiter/detail?recruiter=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> : '',
            secondInterview: (data.second_interview > 0) ? <a href={`/agencies/analytic/recruiter/detail?recruiter=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> : '',
            offers: (data.offer > 0) ? <a href={`/agencies/analytic/recruiter/detail?recruiter=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a> : '',
            archived: (data.reject > 0) ? <a href={`/agencies/analytic/recruiter/detail?recruiter=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> : '',
            accountManager: data.recruiter_name,
            recruiter_id:data.recruiter_id,
            no_of_jobs: data.no_of_jobs,
            no_of_clients: data.no_of_clients,
            hired: (data.hired > 0) ? <a href={`/agencies/analytic/recruiter/detail?recruiter=${data.recruiter_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> : ''
        })))
        setFooter({id:'Total',
                  recruiterScreened:response.data.total_values.recruiterScreened,
                  submitted:response.data.total_values.submitted,
                  firstInterview:response.data.total_values.firstInterview,
                  secondInterview:response.data.total_values.secondInterview,
                  offers:response.data.total_values.offers,
                  archived:response.data.total_values.archived,
                  no_of_jobs:response.data.total_values.no_of_jobs,
                  no_of_clients:response.data.total_values.no_of_clients,
                  hired:response.data.total_values.hired
                })
        setPageCount(response.data.total_pages)
        setStartRecord((activePage - 1) * response.data.per_page)
        setCurrentCounts(response.data.current_counts)
        setLoading(false)
    }

    const exportDataMethod = async () => {
        const url = '/agencies/analytic/recruiter.json'
        let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
        let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
        await makeRequest(`${url}?team_lead=${teamLeadOnly}&start_date=${start_date}&end_date=${end_date}&team=${selectedTeam}&designation=${selectedDesignation}&sortField=${sortField}&sortDirection=${sortDirection}`,'get',{}, {
                onSuccess: (response) => {
                    setExportDatas(response?.data?.org_status.map((data,key)=> ({
                        id: key,
                        recruiterScreened: data.recruitor_screened,
                        submitted: data.submitted,
                        firstInterview: data.first_interview,
                        secondInterview: data.second_interview,
                        offers: data.offer,
                        archived: data.reject,
                        accountManager:data.recruiter_name,
                        recruiter_id:data.recruiter_id,
                        no_of_jobs: data.no_of_jobs,
                        no_of_clients:data.no_of_clients,
                        hired: data.hired
                    })))
                    csvExportRef?.current?.link.click();
                }
            }
        )
    }
    useEffect(()=>{
        fetchData()
    },[dateRange,activePage,selectedTeam,teamLeadOnly,selectedDesignation,sortDirection,sortField])

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }    
    }
    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Recruiters Analytics
                </P>
                <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
                    Export CSV
                </button>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas}
                headers={headers}
                filename={"recruiter _analytic.csv"}
                ref={csvExportRef}
            />
            <Row className='w-100 justify-content-end mb-3'>
                <Col lg="2" md="2" sm="12" xs="12" className='d-flex justify-content-end align-items-center'>
                {/* <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitches"/>
                    <label class="custom-control-label font-weight-bold" for='dsds'>Team Lead Only</label>
                </div> */}
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" checked={teamLeadOnly} onClick={(e)=>{
                        setTeamLeadOnly(e.target.checked)
                        sessionStorage.setItem('team_lead_only',e.target.checked)
                    }} id="team_lead_only"/>
                    <label class="custom-control-label" for="team_lead_only">Team Leads Only</label>
                </div>
                </Col>
            </Row>
            <Row className="w-100" style={{ marginBottom: '10px' }}>
               <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
                    {totalCount > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalCount} Recruiters</DisplayPagination>
                    }
                </Col>
                <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'grid',marginLeft:'10px'}}>
                            <label>
                                Team Name
                            </label>
                            <Select
                                defaultValue={defaultselected}
                                options={teamLists}
                                onChange={(event)=>setSelectedTeam(event.value)}
                                name="team_lists"
                                className={styles.colourStyles}
                            />
                        </div>
                        <div style={{display: 'grid',marginLeft:'10px'}}>
                            <label>
                                Designation
                            </label>
                            <Select
                                defaultValue={defaultDesignationselected}
                                options={designationLists}
                                onChange={(event)=>setSelectedDesignation(event.value)}
                                name="designation"
                                className={styles.colourStyles}
                            />
                        </div>
                        <div style={{display: 'grid'}}>
                            <label>
                            Select Date Range
                            </label>

                            <DateRangePicker
                                handleOnSubmit={handleDate}
                                defaultDate={dateRange}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            
            <DataTable
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobStatus}
                progressPending={loading}
                progressComponent={ <P className="text-center  p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setActivePage(page)}
                footer={footer}
            />
        </Card>
    )
}

export default RecruiterAnalyticPage