import React, { useEffect, useState } from 'react'
import { Col, Spinner } from 'react-bootstrap'
import styles from './styles/InviteYourFriend.module.scss'
import Input from '../../common/Styled components/Input'
import { capitalize } from '../../../utils'
import {
  Row,
  Wrapper,
} from './styles/AccountSettingPage.styled'
import '../Admin/styles/DataTableCustom.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import DataTable from 'react-data-table-component-footer'
import P from '../Admin/shared/P'
import Util from '../../../utils/util'

const InviteYourFriendPage = (props) => {
  const { currentUser } = props
  const { is_cm } = props
  const { token } = props
  const [invitaions, setInvitations] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [loading, setLoading] = useState([])
  const { id, first_name, last_name, account_email_verified } = currentUser
  const [userEmail, setUserEmail] = useState("")
  const [activePage, setActivePage] = useState(0)
  const [totalCount, setTotalCount] = useState()
  const [sortDirection, setSortDirection] = useState('desc')
  const [currentCount, setCurrentCount] = useState()
  const [perPage, setPerPage] = useState(10)
  const [sortField, setSortField] = useState('list.created_at')
	const [btnDisabled, setBtnDisabled] = useState(false)
	const [validationError, setValidationError] = useState({})
  

  const fetchData = async () => {
    setLoading(true)
    setTotalPages(0)
    const url = `/account/invite_your_friend.json?page=${activePage == 0 ? 1 : activePage}`
    makeRequest(url, 'get', '', {
        contentType: 'application/json',
        loadingMessage: 'Fetching...',
        onSuccess: (response) => {
            let sr_no = (activePage == 0 ? 0 : (activePage - 1)) * 10
            setInvitations(
                response.data.list.map((list) => (sr_no = sr_no + 1, {
                    id: sr_no,
                    email: list.email,
                    status: ((list.status == 'Success') ? <span style={{color: 'green'}}>Success</span> : <span style={{color: 'red'}}>Pending</span>),
                    role: Util.capitalize(list.role),
                    created_at: moment(list.created_at).format('MMM DD YYYY'),
                    actions: ((list.status == 'Pending') ? (<a style={{cursor:'pointer',color:'#8185ff'}} onClick={() => (onEditClick(list))}> Resend Invitation </a>) : "")
                }))
            )
            setPerPage(response.data.per_page)
            setTotalPages(response.data.total_pages)
            setTotalCount(response.data.total_count)
            setCurrentCount(response.data.current_counts)
            setLoading(false)
        },
    })
}

  const columns = [
    {
        name: 'No.',
        selector: row => row.id,
        maxWidth: '4%',
        minWidth: '4%',
    },
    {
        name: 'Email',
        selector: row => row.email,
        wrap: true,
        maxWidth: '21%',
        minWidth: '21%',
    },
    {
        name: 'Status',
        selector: row => row.status,
        wrap: true,
        maxWidth: '17%',
        minWidth: '17%',
    },
    {
        name: 'Role',
        selector: row => row.role,
        wrap: true,
        maxWidth: '14%',
        minWidth: '14%',
    },
    {
        name: 'Invited Date',
        selector: row => row.created_at,
        maxWidth: '22%',
        minWidth: '22%'
    },
    {
        name: 'Action',
        selector: row => row.actions,
        maxWidth: '22%',
        minWidth: '22%',
    },
  ]


  
  const handleVerification = async (event) => {
    if(validateForm(userEmail)){
      const URL = '/account/invite_your_friend'
      const payload = new FormData()
    payload.append('email_invitation[email]', userEmail)
    payload.append('email_invitation[token]', token)
    makeRequest(URL, 'post',payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (res) => {
        if(res?.messageType == 'failure'){
          let errors = {}
          errors["email"] = res?.msg
          setValidationError(errors)
        }else{
          setTimeout(() => {
            window.open('/account/invite_your_friend', "_self")
          }, 1000);
          return {
            message: res?.msg,
            messageType: res?.messageType
          }
        }
      }
    })
  }
}
const validateForm = (e) => {
  let errors = {}
  let formIsValid = true
  const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
  if(!userEmail){
    formIsValid = false;
    errors["email"] = "Email is required."
     }
     setValidationError(errors)
     setBtnDisabled(formIsValid)
     return formIsValid;
}


  const handleSubmit = (event) => {
    event.preventDefault()
      handleVerification(event)
  }

  const onEditClick = (item) => {
    const URL = '/account/invite_your_friend/resend'
    const payload = new FormData()
    payload.append('email_re_invitation[email]', item.email)
    makeRequest(URL, 'post',payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (res) => {
        setTimeout(() => {
          window.open('/account/invite_your_friend', "_self")
              }, 1000);
      return {
          message: res?.msg,
          messageType: res?.messageType
      }
    }
   })
  }

  useEffect(()=>{
    fetchData()
  },[activePage])


  return (
    <div className={styles.accountSettingPage}>
    <Wrapper className={`${styles.accontSetting}`}>
        <div className={`${styles.sidebar}`}>
          <div className={`${styles.sidebar_header}`}>
            <p>{capitalize(`${first_name} ${last_name}`)}</p>
          </div>
          <a href="/account/setting">
            General Settings
          </a>
          <a href="/account/security">Security & Login</a>
          <a href="/account/email_verification">Email Verification</a>
          { (is_cm > 0) && 
              <a href="/account/mixmax_configuration">Mixmax Configuration</a>
          }
           { (currentUser.role == 'recruiter') && 
              <a href="/account/organization_management">Organization Membership Management</a>
           }
            {(currentUser.role == 'employer' || currentUser.role == 'recruiter') && 
                <a href="/account/organization_agreement">Organization Agreement</a>
            }
          <a className={`${styles.active}`} href="/account/invite_your_friend">Invite Your Friend</a>
          {currentUser.role == 'talent' &&
                  <a href="/account/email_configuration">SMTP Email Configuration</a>
                  }
        </div>
        <div className={`${styles.containt}`}>
          <div className={`${styles.containt_email_verify}`}>
            <h3>Invite Your Friend</h3>
            <div className={`${styles.basicinfodetail}`}>
              <>
                <form onSubmit={(e) => {handleSubmit(e)}}>
                  <Row>
                    <Input
                      label="Email"
                      value={userEmail}
                      name="email"
                      type="email"
                      width="20rem"
                      onChange={event =>{
                       setUserEmail(event.target.value)
                      setValidationError({})
                      }
                      }

                    />
                  
                    <div style={{marginLeft:'15px'}}>
                    <Input
                        disabled={true}
                        label="Token"
                        value={token}
                        name="Token"
                        type="text"
                        width="20rem"
                      />
                      </div>
                      <button
                          // disabled={userEmail.length > 0 ? false : true}
                          style={{marginLeft: '15px', marginTop: '15px', width: "200px", height: "40px"}}
                          className={`${styles.editButton}`}
                        >
                          Send Invitation
                      </button>
                  </Row>
                </form>
              </>
            <label className={`${styles.error}`}>
									{validationError && validationError.email}        
							</label>
            </div>
          </div>
          <Row className="w-100 modal-entry-form-row  mt-5 mb-3 " >
            <Col xs={12} sm={12} lg={12} className='text-left'>
                <h4>Invitations </h4>
            </Col>
          </Row> 
            <DataTable
            title={false}
            persistTableHead={true}
            noDataComponent={<p className="text-center p-3">No record found</p>}
            columns={columns}
            data={invitaions}
            progressPending={loading}
            progressComponent={<P className="text-center p-3" size="18px" color="#6077f4">
                <Spinner className='spinner' animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </P>}
            sortServer
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={perPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => {
                setActivePage(page)
            }}
          />
        </div>
      </Wrapper>
    </div>
   )
  }

export default InviteYourFriendPage