import React, { useState,useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Alert, Modal,Spinner } from 'react-bootstrap'
import axios from 'axios'
import './styles/communities.scss'
// import moment from 'moment'
import feather from 'feather-icons'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import LogoWithText from '../../../../assets/images/logos/navbar-logo.svg'
import '../../../../assets/v2/css/main.css'
import LogoGroups from '../../../../assets/images/logos/cardinalgroups.png'

const initialFormData = {
  name: '',
  // category_id: '',
}

const CommunityValidation = {
  name: Yup.string()
    .required('Please enter group name.').trim()
    .max(50, 'Group name has a max length of 50 characters')
    .test(
      'group-unique',
      'This group name is already in use',
      async function (value) {
        const res = await axios.get(
          `/communities/exists?name=${encodeURIComponent(value)}`
        )
        return !res.data.community_exists
      }
    ),
  // category_id: Yup.string().required('Category is required'),
}

const CreatePage = (props) => {
  const [showMoreCategory, setShowMoreCategory] = useState(false)

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(initialFormData)
  const [response, setResponse] = useState({})
  const [isSignUp, setIsSignUp] = useState(false)

  useEffect(() => {
    if (props.isError) {
      setIsSignUp(true)
    }
  }, [props])

  const validationSchema = Yup.object({ ...CommunityValidation })

  const handleCreate = async (values,resetForm) => {
    setResponse({})
    setFormData({ ...formData, ...values })
    const payload = new FormData()
    const url = '/communities/create'
    payload.append('community[name]', values.name)
    setLoading(true)
    await axios
      .post(url, payload)
      .then((response) => {
        setTimeout(() => {
          window.location = '/communities/'+response.data.comId
        }, 2000)
        localStorage.setItem('community', JSON.stringify(response.data))
      })
      .catch((error) => {
        console.log(error)
        setResponse(error?.response?.data)
        resetForm();
        setLoading(false)
      })
  }

  const TextField = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>
              {label}
        </label>
        <input {...field} {...props} />
      </>
    );
  };
  return (
    <div className="container communities-create mt-120 mb-120">
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="build_community" style={{width: '414px'}}>
            <a href={props.host_url} className={"d-flex img1 ml-10"}>
                <img src={LogoGroups} rounded />
            </a>
            <h1>Everything you need to build community and make money online.</h1>
            <div className="build_community_info">
              <div> 📈 Highly engaged</div>
              <div> ❤️ Simple to setup</div>
              <div> 🙂 It's fun</div>
              <div> 💰 Charge for subscription membership</div>
              <div> 🌎 Global distribution</div>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="mailto:help@cardinalgroups.ai">help@cardinalgroups.com</a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 bg-white" style={{maxWidth: '500px', margin: '0px auto'}}>
          <div className="block-banner login">   
            <div className="right-content" style={{paddingTop: '20px', paddingBottom: '20px'}}>
            <h3 className="form-title text-center">Create your community</h3>
            <p className="text-center mb-4 form-title" style={{fontSize: '15px'}}>All features. Unlimited everything. No hidden fees.</p>
              {!_.isEmpty(response) && (
                <Alert
                  variant={response.messageType}
                  onClose={() => setResponse({})}
                  style={{ fontSize: '15px',paddingRight: '1rem'}}
                  dismissible
                  className="candidate-info-close"
                >
                  <span className=''> {response.message} </span>
                </Alert>
              )}
              <Formik
                validateOnChange={true}
                validateOnBlur={false}
                initialValues={initialFormData}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm })  => { handleCreate(values,resetForm)}}
              >
                <Form>
                  <Field type="text" name="name" placeholder="Group Name" className={"mt-30"} />
                  <p className="group_note">You can change this later</p> 
                  <ErrorMessage name="name" render={msg => <span width="28rem" className='error'>{msg}</span>} />   
                  <p className="auth-secondary-text mt-30" style={{ margin: 0, color: "#666" }}>
                    By creating a community, you are agreeing to our
                    <a href='/terms_of_service' target='_blank' style={{ textDecoration: "underline" }}>
                      {" "}
                      Terms &amp; Conditions
                    </a>{" "}
                    and{" "}
                    <a href='/privacy_policy' target='_blank' style={{ textDecoration: "underline" }}>
                      Privacy Policy
                    </a>
                  </p>
                  <div className="text-center mt-4">
                    <button type="submit" disabled={loading} className="btn btn-default font-heading icon-send-letter btn1 signupbtn w-50">{loading ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        'Create'
                      )}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreatePage