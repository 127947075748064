import React, { useState, useEffect } from 'react'
import feather from 'feather-icons'

import Input from '../../common/Styled components/Input'
import moment from 'moment'
import {
    Row,
    W8text,
    InputContainer,
    Container,
    Label,
    DatePicker,
    CheckboxContainer,
    Icon,
} from './styles/CandidateViewProfile.styled'

function EditEducation(props) {
    const {
        education,
        setEducationDateInvalid,
        index,
        dispatch,
        isLastIndex,
        handleEducation,
        handleEducationDate,
        handleRemoveEducation
    } = props
    const [startDate, setStartDate] = useState(
        `${moment(education.from).format('YYYY')}-${moment(
            education.from
        ).format('MM')}`
    )
    const [endDate, setEndDate] = useState(
        `${moment(education.to).format('YYYY')}-${moment(
            education.to
        ).format('MM')}`
    )
    useEffect(() => {
        feather.replace()
    })
    
   

    const handleStartDate = (e) => {
        if(startDate != 'Invalid date-Invalid date'){
            handleEducationDate(`${startDate}-01`, index, 'startDate')
            e.target.type = 'text'
            e.target.value = moment(startDate).format('MM-YYYY')
        }
    }

    const handleEndDate = (e) => {
        if(endDate != 'Invalid date-Invalid date'){
            handleEducationDate(`${endDate}-01`, index, 'endDate')
            e.target.type = 'text'
            e.target.value = moment(endDate).format('MM-YYYY')
        }
    }

    const handleFocus = (e, date) => {
        e.target.type = 'month'
        e.target.value = moment(date).format('YYYY-MM')
    }
    
    const [invalidDate, setInvalidDate] = useState('')

    const onStartDateChange = (date) => {
        setStartDate(date)
        expireDateValidationCheck(date,endDate)
    }

    const onEndDateChange = (date) => {
        setEndDate(date)
        expireDateValidationCheck(startDate,date)
    }

    const expireDateValidationCheck = (sDate,eDate) => {
        setInvalidDate('')
        setEducationDateInvalid('')
        var startSplit = sDate?.split('-')
        var endSplit = (eDate)?.split('-')
        var currentYear = moment().format('YYYY');
        if(parseInt(endSplit[0]) < parseInt(startSplit[0])){
            setInvalidDate('Invalid Date')
            setEducationDateInvalid('Invalid Date')
        }else if(parseInt(startSplit[0]) == parseInt(endSplit[0])){
            if(parseInt(startSplit[1]) >= parseInt(endSplit[1])){
                setInvalidDate('Invalid Date')
                setEducationDateInvalid('Invalid Date')
            }
        }
        if((currentYear < parseInt(startSplit[0])) ||  (currentYear < parseInt(endSplit[0]))){
            setInvalidDate('Invalid Date')
            setEducationDateInvalid('Invalid Date')
        }
    }

    return (
        <>
            <Container key={education.id} direction="row">
                <Row direction="row">
                    <InputContainer width="40%" style={{ marginRight: '15px' }}>
                        <Input
                            value={education.school_name}
                            label="School/University"
                            name="school_name"
                            onChange={(e) => handleEducation(e, index)}
                            type="text"
                        />
                    </InputContainer>
                    <InputContainer width="40%">
                        <Input
                            value={education.degree}
                            label="Degree"
                            name="degree"
                            onChange={(e) => handleEducation(e, index)}
                            type="text"
                        />
                    </InputContainer>
                    <Icon style={{ flexGrow: 1 }}>
                        {/* <i data-feather="trash-2" /> */}
                    </Icon>
                </Row>
                <Row direction="row">
                    <InputContainer width="41%" style={{ marginRight: '10px' }}>
                        <Container direction="row">
                            <Label>Start Date - End Date</Label>
                        </Container>
                        <Container
                            direction="row"
                            style={{ alignItems: 'center' }}
                        >
                            <DatePicker
                                type="text"
                                style={{width: '47%'}}
                                onChange={(e) => onStartDateChange(e.target.value)}
                                placeholder={
                                    startDate != 'Invalid date-Invalid date'
                                        ? moment(startDate).format('MM-YYYY')
                                        : 'MM-YYYY'
                                }
                                onBlur={handleStartDate}
                                onFocus={(e) => handleFocus(e, startDate)}
                            />

                            <W8text
                                size="16px"
                                color="#889BFF"
                                style={{ margin: '0px 8px' }}
                            >
                                -
                            </W8text>

                            <DatePicker
                                type="text"
                                style={{width: '47%'}}
                                onChange={(e) => onEndDateChange(e.target.value) }
                                placeholder={
                                    endDate != 'Invalid date-Invalid date' 
                                        ? moment(endDate).format('MM-YYYY')
                                        : 'MM-YYYY'
                                }
                                min={startDate}
                                onBlur={handleEndDate}
                                onFocus={(e) => handleFocus(e, endDate)}
                            />
                            
                            <span style={{color:'#c73939',fontSize:'14px',padding:'10px'}} >{invalidDate}</span>
                        </Container>
                    </InputContainer>
                    <InputContainer width="41%">
                    </InputContainer> 
                    <InputContainer width="8%" style={{ display: 'flex' }}>
                    </InputContainer>
                </Row>

                <Row direction="row">
                    <InputContainer width="70%">
                    </InputContainer>
                    <Icon
                        onClick={() => handleRemoveEducation(education?.id,index)}
                        style={{ flexGrow: 1 }}
                    >
                        <i data-feather="trash-2" />
                    </Icon>
                </Row>
                {!isLastIndex && (
                    <div
                        style={{
                            width: '100%',
                            height: '2px',
                            background: '#d6d9e8',
                            marginRight: 50,
                            marginBottom: 20,
                        }}
                    />
                )}
            </Container>
        </>
    )
}

export default EditEducation