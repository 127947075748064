import React, { useEffect, useState } from 'react'
import Util from "../../../utils/util"
import Card from './shared/Card'
import P from './shared/P'
import Button from './shared/Button'
import { Row,Col,Spinner, Form } from 'react-bootstrap'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import Select from 'react-select'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import Modal from "react-bootstrap/Modal"
import feather from 'feather-icons'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from 'moment'

const RecruiterSourcerMeetingAnalytic = ({recruiters, sourcers}) => {
  var active_page = 1;//window.sessionStorage.getItem('salesListActivePage')
  var query = window.sessionStorage.getItem('query')
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [leads, setLeads] = useState([])
  const [totalTeams,setTotalTeams] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [method,setMethod] = useState('post')
  const [loading, setLoading] = useState(true)
  const [perPage, setPerPage] = useState(25)
  const [leadObject,setLeadObject] = useState(false)
  const [dateRange, setDateRange] = useState({startDate: moment().startOf('month').toDate() ,endDate: moment().toDate()})
  const [obj, setObj] = useState({})
  const [selectedRecruiter,setSelectedRecruiter] = useState('')
  const [selectedSourcer,setSelectedSourcer] = useState('')
  const [recruiterList,setRecruiterList] = useState([{value: '', label: 'All'},...recruiters])
  const [sourcerList,setSourcerList] = useState([{value: '', label: 'All'},...sourcers])

  const handleDate = (date) => {
    setDateRange(date)
  }

  const fetchData = () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date = moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = `/admin/sourcer_meeting_analytic.json?sourcer=${selectedSourcer}&recruiter=${selectedRecruiter}&start_date=${start_date}&end_date=${end_date}`
    window.sessionStorage.setItem('salesListActivePage',activePage)
    makeRequest(url, 'get',  {params: {page: (activePage), query: query}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        let sr_no = (activePage - 1) * res.data.per_page
        setLeads(
          res.data.meetings.map((team) => (sr_no = sr_no + 1,{
            id: sr_no,
            lead_id: team?.id,
            recruiter_name: Util.fullName(team?.user?.first_name,team?.user?.last_name) + ' / ' + Util.fullName(team?.sourcer?.first_name, team?.sourcer?.last_name),
            sourcer_name: Util.fullName(team?.sourcer?.first_name, team?.sourcer?.last_name),
            meeting_time: moment(team?.meeting_time).format('ddd DD-MMM-YYYY, hh:mm A'),
            created_at: moment(team?.meeting_time).format('MMM-DD-YYYY'),
            feedback: <p title={ team?.feedback }>{ team?.feedback }</p>
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalTeams(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord((activePage -1) * res.data.per_page)
        setPerPage(res.data.per_page)
        setLoading(false)
      },
    })
  }
    useEffect(() => {
      fetchData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,selectedSourcer,selectedRecruiter,dateRange])

    useEffect(() => {
      feather.replace()
    })  

    const columns = [
      {
          name: 'No.',
          selector: row => row.id,
          maxWidth: '4%',
          minWidth: '4%'
      },
      {
          name: 'Recruiter / Sourcer',
          selector: row => row.recruiter_name,
          cell: (row) => <div title={row.recruiter_name}>{row.recruiter_name}</div>,
          sortable: false,
          sortField: 'name',
          wrap:true,
          maxWidth: '20%',
          minWidth: '20%'
      },
      {
          name: 'Meeting Date/Time',
          selector: row => row.meeting_time,
          cell: (row) => <div title={row.meeting_time}>{row.meeting_time}</div>,
          sortable: false,
          wrap:true,
          maxWidth: '16%',
          minWidth: '16%'
      },
      {
          name: 'Added On',
          selector: row => row.created_at,
          cell: (row) => <div title={row.created_at}>{row.created_at}</div>,
          sortable: false,
          wrap:true,
          maxWidth: '10%',
          minWidth: '10%'
      },
      {
        name: 'Note',
        selector: row => row.feedback,
        wrap:true,
          maxWidth: '50%',
          minWidth: '50%'
      }
  ]

	const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }

  return (
    <>
    <Card>
      <div className="d-flex justify-content-between align-items-center w-100">
            <P size="28px" height="38px" color="#1D2447">
                Recruiter-Sourcer Meeting Dashboard
            </P>
        <div style={{flexGrow:1}}/>
        </div>
     
      <Row className="w-100" style={{ marginBottom: '10px' }}>
          <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
              <div>
                  <label>
                      Sourcer :
                  </label>
                    <Select
                      options={sourcerList}
                      name='sourcer_id'
                      id='sourcer_id'
                      placeholder={'Select Sourcer'}
                      onChange={(e)=> setSelectedSourcer(e.value)}
                      defaultValue={sourcerList.filter((row) => row.value == selectedSourcer)}
                      className={styles.colourStyles}
                    />
              </div>
              <div>
                  <label>
                      Recruiter :
                  </label>
                  <Select
                    options={recruiterList}
                    name='user_id'
                    id='user_id'
                    placeholder={'Select Recruiter'}
                    onChange={(e)=> setSelectedRecruiter(e.value)}
                    defaultValue={recruiterList.filter((row) => row.value == selectedRecruiter)}
                    className={styles.colourStyles}
                  />
              </div>
              <div style={{display:'grid'}}>
                  <label>
                      Meeting Date Range :
                  </label>
                  <DateRangePicker
                      handleOnSubmit={handleDate}
                      defaultDate={dateRange}
                  />
              </div>                                                                                                                                                                                                                                                  
          </Col>
          <Col lg="12" md="12" sm="12" xs="12" className='d-flex align-items-end'>
              {totalTeams > 0 && loading == false &&
                  <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord} - {currentCounts}  of  {totalTeams} Meeting Schedules</DisplayPagination>
              }
          </Col>
      </Row>
      <DataTable
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={leads}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        // onSort={handleSort}
        sortServer
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalTeams}
        paginationPerPage={perPage}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        responsive={true}
        onChangePage={page => setActivePage(page)}
        // footer={footer}
      />
      </Card>

    </>
  )
}

export default RecruiterSourcerMeetingAnalytic