import React, { Fragment, useState, useEffect } from 'react'
import styles from './styles/Header.module.scss'
import { ButtonGroup, Button, Row, Modal, Alert } from 'react-bootstrap'
import SearchBar from '../../../common/SearchBar/SearchBar'
import EmailConfirm from '../../../common/EmailConfirm/EmailConfirm'
import './styles/Header.scss'
import JobModal from '../../../common/JobModal/JobModal'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Badge from "react-bootstrap/Badge";
import SweetAlert from 'react-bootstrap-sweetalert'
import DownloadIcon from '../../../../../assets/images/icons/downloadicon.png'
import { CSVLink } from "react-csv"
import { capitalize} from '../../../../utils'
import moment from 'moment'
import axios from 'axios'

function Header({
    handleMyJobs,
    handleAllJobs,
    handleClosedJobs,
    setShowMyClosedJobs,
    setJobFilterText,
    setActivePage,
    handleSearch,
    showMyJobs,
    showMyClosedJobs,
    jobFilterText,
    loading,
    organization,
    currentUser,
    isProfileComplete,
    allJobsCount,
    closedJobsCount,
    myJobsCount,
    jobGroup,
    recruiterMember,
    jobCategory
}) {
    const [isAddJob, setIsAddJob] = useState(false)
    const [emailVerify, setEmailVerify] = useState(false)
    const [addJobError, setAddJobError] = useState({})
    const [sourcingRequirment, setSourcingRequirment] = useState(false)
    const [createJobId, setCreateJobId] = useState('')
    const [exportDatas, setExportDatas] = useState([])
    const csvExportRef = React.useRef()
    const stages = {lead: 'Lead', applicant: 'Applicant', recruitor_screen: 'Recruiter Screened', submitted: 'Submitted', first_interview: 'Interview', second_interview: 'Interview', offer: 'Offers', reject: 'Archived', hired: 'Hired'}

    const headers = [
        { label: 'CANDIDATE NAME', key: 'job_name' },
        { label: 'SCHOOL', key: 'school' },
        { label: 'RECENT COMPANY', key: 'company_names'},
        { label: 'Job Title', key: 'position' },
        { label: 'STATUS', key: 'submission_type' },
        { label: 'INTERVIEW SCHEDULED DATE', key: 'interview_date' },
        { label: 'SUBMISSION DATE', key: 'created_at' },
        { label: 'LINKEDIN URL', key: 'linkedin_profile_url' },
        { label: 'SOURCE', key: 'source' } 
    ];

    const handleCloseJobButton = () => {
        handleClosedJobs()
        setShowMyClosedJobs(true)
    }
    const handleAllJobsButton = () => {
        handleAllJobs()
        setShowMyClosedJobs(false)
    }
    const handleMyJobsButton = () => {
        handleMyJobs()
        setShowMyClosedJobs(false)
    }

    useEffect(() => {
        if(myJobsCount == 0){
            checkJobExist()
        }
    },[])

    const checkJobExist = async () =>{
        await axios
            .get(`/check/job_exist/${currentUser.id}`)
            .then((res) => {
                if(res.data.count == 0){
                    handleAddJob()
                }
            })
            .catch((error) => { console.log(error) });
    }

    const handleAddJob = () => {
        if (organization) {
            if (!currentUser.email_confirmed) {
                setEmailVerify(true)
            } else {
                setIsAddJob(!isAddJob)
            }
        } else {
            if (currentUser.role === 'employer') {
                if (!isProfileComplete) {
                    window.location.href = '/complete/profile'
                }
            } else {
                setAddJobError({
                    ...addJobError,
                    recruiterError:
                        'You do not belong to any Organization. Please select an Organization before adding a job.',
                })
            }
        }
    }

    const handleSubmitJob = async (job) => {
        console.log(job);
        let jobData = new FormData()
        const url = '/jobs.json'
        const skillsString = job.mustHaveSkills.map((skill)=> skill.value).join(',')
        const techGroupString = job?.tech_groups?.map((tech_group)=> tech_group.value).join(',')
        const industryString = job?.industry?.map((ind)=> ind.value).join(',')
        jobData.append('job[name]', job.name)
        jobData.append('job[location]', job.location.label)
        jobData.append('job[description]', job.description)
        jobData.append('job[skills]', skillsString)
        jobData.append('job[department]', job.department?.label || '')
        jobData.append('job[notificationemails]', job.notificationemails)
        jobData.append('job[linkedin_url]', job.linkedin_url)
        jobData.append('job[linkedin_url_2]', job.linkedin_url_2)
        jobData.append('job[linkedin_url_3]', job.linkedin_url_3)
        jobData.append('job[indeed_url]', job.indeed_url)
        jobData.append('job[group_id]', job.group_id)
        jobData.append('job[linkedin_job_url]',job.linkedin_job_url)
        jobData.append('job[tech_group]', techGroupString)
        jobData.append('job[industry]', industryString)
        jobData.append('job[job_salary]', job.job_salary)
        const responce = await makeRequest(url, 'post', jobData, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                setCreateJobId(responce?.id || '')
                return {
                    message: responce?.message
                        ? responce.message
                        : 'Job Created Successfully',
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
            },
        })
        setIsAddJob(!isAddJob)
        setSourcingRequirment(true)
        if(currentUser.agency_id === 0){
            setSourcingRequirment(true)
        }else{
            setSourcingRequirment(false)
            handleMyJobs()
            // handleMyJobs()
        }
    }

    const onConfirmSourcingRequirment = () => {
        if(createJobId != ''){
            window.localStorage.setItem('job_source_id',createJobId)
            window.open(`jobs/${createJobId}`, "_self")
        }else{
            handleMyJobs()
        }
    }

    const excelDownload = async () => {
        await makeRequest('/job_excel', 'get', {}, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
            },
        }).then((r) => {
            let temp_data = []
            let reject_data = []
            let name = ''
            if((r?.data?.data) && (r?.data?.data).length > 0){
                r.data.data.map((val,ind)=> {
                    let job_name = val.job_name
                    // if(val.submission_type == 'reject'){
                    //     if(reject_data.length == 0){
                    //         reject_data.push({job_name: ''})
                    //         reject_data.push({job_name: 'ARCHIVED CANDIDATES'})
                    //     }
                    //     else{
                    //         reject_data.push({job_name: capitalize(val.first_name +' '+ val.last_name), job_id: val.job_id, linkedin_profile_url: val.linkedin_profile_url, company_names: val.company_names,interview_date: val.interview_date, school: val.school, position: val.title, source: val.source, submission_type: stages[val.submission_type], created_at: moment(val.created_at).format('MM-DD-YYYY')})
                    //     }
                    // }
                    // else{
                        if(name != val.job_name){
                            temp_data.push({job_name: ''})
                            temp_data.push({job_name: job_name.toUpperCase()})
                            temp_data.push({job_name: capitalize(val.first_name +' '+ val.last_name), job_id: val.job_id, linkedin_profile_url: val.linkedin_profile_url, company_names: val.company_names,interview_date: val.interview_date, school: val.school, position: val.title, source: val.source, submission_type:  stages[val.submission_type], created_at: moment(val.created_at).format('MM-DD-YYYY')})
                        
                        }else{
                            temp_data.push({job_name: capitalize(val.first_name +' '+ val.last_name), job_id: val.job_id, linkedin_profile_url: val.linkedin_profile_url, company_names: val.company_names,interview_date: val.interview_date, school: val.school, position: val.title, source: val.source, submission_type: stages[val.submission_type], created_at: moment(val.created_at).format('MM-DD-YYYY')})
                        }
                        name = job_name
                    // }
                })
            }
            // console.log(temp_data, ...reject_data, '==>')
            // let exper_data =[temp_data, ...reject_data]
            setExportDatas(temp_data.concat(reject_data))
            csvExportRef?.current?.link.click();
        }) 
        .catch((e) => console.log(e))
    }
    return (
        <>
        <Fragment>
            <div className={styles.headerContainer}>
            <Row>
                    {addJobError.recruiterError && (
                        <Alert
                            variant="danger"
                            onClose={() => setAddJobError({})}
                            dismissible
                        >
                            {addJobError.recruiterError}
                        </Alert>
                    )}
                </Row>
                <div className={styles.headerWrapper}>
                    <ButtonGroup className={styles.buttonContainer}>
                        <Button
                            style={{
                                background: `${
                                    showMyJobs ? '#005999' : ' #EBEDFA'
                                }`,
                                color: `${showMyJobs ? ' #EBEDFA' : '#4C68FF'}`,
                                borderRadius: '10px',
                            }}
                            disabled={loading}
                            className={styles.jobButton}
                            onClick={() => handleMyJobsButton()}
                        >
                            My Jobs
                            <Badge className={styles.stageCount} variant='light'>{myJobsCount}</Badge>
                        </Button>
                        <Button
                            style={{
                                background: `${
                                    !showMyJobs && !showMyClosedJobs
                                        ? '#005999'
                                        : ' #EBEDFA'
                                }`,
                                color: `${
                                    !showMyJobs && !showMyClosedJobs
                                        ? ' #EBEDFA'
                                        : '#005999'
                                }`,
                                borderRadius: '10px',
                            }}
                            disabled={loading}
                            className={styles.jobButton}
                            onClick={() => handleAllJobsButton()}
                        >
                            {' '}
                            All Jobs
                            <Badge className={styles.stageCount} variant='light'>{allJobsCount}</Badge>
                        </Button>
                        <Button
                            style={{
                                background: `${
                                    !showMyJobs && showMyClosedJobs
                                        ? '#005999'
                                        : '#EBEDFA'
                                }`,
                                color: `${
                                    !showMyJobs && showMyClosedJobs
                                        ? '#EBEDFA'
                                        : '#005999'
                                }`,
                                borderRadius: '10px',
                            }}
                            disabled={loading}
                            className={styles.jobButton}
                            onClick={() => handleCloseJobButton()}
                        >
                            {' '}
                            Closed Jobs
                            <Badge className={styles.stageCount} variant='light'>{closedJobsCount}</Badge>
                        </Button>
                    </ButtonGroup>
                    <SearchBar
                        placeholder={'Search...'}
                        value={jobFilterText}
                        setValue={setJobFilterText}
                        onChange={(e) => setJobFilterText(e.target.value)}
                        onEnterPressed={() => {
                            setActivePage(0)
                            handleSearch()
                        }}
                        onClick={(e) => setJobFilterText('')}
                        onCancelPressed={(e) =>{ setJobFilterText('')}}
                    />

                    <div class="ml-auto">
                        <Button
                            style={{backgroundColor: '#fff',borderColor: '#005999', color: '#005999'}}
                            onClick={() => handleAddJob()}
                        >
                            +  Add New Job
                        </Button>
                        <Button
                            style={{backgroundColor: '#fff',borderColor: '#005999', color: '#005999', marginLeft: '15px'}}
                            onClick={() => excelDownload()}
                        >
                           <span onClick={() => setIsInterviewCreatedBefore(false)} role="button">
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{width: '22px', height: '22px', marginRight: '0px'}}>
                            <path strokeLinecap="square" strokeLinejoin="miter" d="M3 16.5v3a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-3M7.5 10.5l4.5 4.5m0 0 4.5-4.5m-4.5 4.5V3" />
                            </svg>
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
            <CSVLink
                className='hidden'
                data={exportDatas} 
                headers={headers}
                filename={organization?.name + "-" + moment().format("DD-MM-YYYY")}
                ref={csvExportRef}
            >
            </CSVLink>
            <Modal
                onHide={() => setIsAddJob(false)}
                show={isAddJob}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <JobModal
                    job={{}}
                    cardinalMember={recruiterMember}
                    organizationId={organization?.id}
                    currentUser={currentUser}
                    handleSubmitJob={handleSubmitJob}
                    onClose={() => setIsAddJob(false)}
                    label="Add Job"
                    jobGroup={jobGroup}
                    jobCategory={jobCategory}
                />
            </Modal>
            <Modal
                onHide={() => setEmailVerify(false)}
                show={emailVerify}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <EmailConfirm
                    currentUser={currentUser}
                    setEmailVerify={setEmailVerify}
                />
            </Modal>
            
        </Fragment>
        {(sourcingRequirment) &&
            <SweetAlert
                showCancel
                confirmBtnText="Add"
                cancelBtnText="Skip"
                onConfirm={()=> onConfirmSourcingRequirment()}
                onCancel={()=> (setSourcingRequirment(false),handleMyJobs())}
                cancelBtnStyle={{textDecoration: 'none'}}
                btnSize="sm"
                style={{textAlign:'justify'}}
            ><div className='sweetAlertText'>Please add Sourcing Requirements, Email Sequence and InMail Message.</div></SweetAlert>
        }
        </>
    )
}

export default Header
