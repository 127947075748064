import React, { useState, useEffect } from 'react'
import styles from './styles/JobsTable.module.scss'
import feather from 'feather-icons'
import ChartContainer from '../ChartContainer/ChartContainer'
import eyeImage from '../../../../../assets/images/icons/eye.png'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { getDueDays } from '../../../../utils'

function JobsTable({ jobs, currentUser, organization ,setReloaddata, memberOrganization }) {
  const [viweAnalyticID, setViweAnalyticID] = useState([])
  useEffect(() => {
    feather.replace()
  })
  const handleViweAnalytics = (event, id) => {
    event.stopPropagation()
    viweAnalyticID.push(id)
    setViweAnalyticID([...viweAnalyticID])
  }

  const handleCloseViweAnalitic = (id) => {
    let idList = viweAnalyticID.filter((_id) => _id !== id)
    setViweAnalyticID([...idList])
  }

  const handleAPi = (id,stage) => {
    localStorage.setItem('Stage',stage)
    window.location.href = `/jobs/${id}?stage=${stage}`
  }
  
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  
  const JobUpdateStatus = async (id,status) => {
    const url = '/jobs/'+id+'/'+status
    try {
        await makeRequest(url, 'put' ,{
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (response) => {
              return {message: response.message}
            },
        }).then((res) => {
          window.location.reload()
        })    
    } catch (e) {
        setErrorFetchingRecruiteer(e.message)
    }
  }
  
  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="mt-0">Position</th>
              {currentUser.agency_id == 0 && (<th className={styles.borderTopNone}>Leads</th>)}
              { memberOrganization &&
                <th className={styles.borderTopNone}>Active Candidates</th> 
              }
              <th className={styles.borderTopNone}>Applicants</th>
              <th className={styles.borderTopNone}>Recruiter Screened</th>
              <th className={styles.borderTopNone}>Submitted</th>
              <th className={styles.borderTopNone}>1st Interview</th>
              <th className={styles.borderTopNone}>2nd Interview</th>
              <th className={styles.borderTopNone}>On Hold</th>
              <th className={styles.borderTopNone}>Offers</th>
              <th className={styles.borderTopNone}>Hired</th>
              <th className={styles.borderTopNone}>Archived</th>
              {/* <th className={styles.borderTopNone}>Metrics</th> */}
            </tr>
          </thead>
          <thead>
            {jobs.map((job, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td className={`${styles.colWidth}`}
                    key={job.id}
                  >
                    <div>
                      <span className={`${styles.jobName} ${styles.pointer}`}  onClick={() =>
                      handleAPi(job.id,'lead')
                    }>{job.name}</span>
                      <a href={`/job/previews/${job.id}`} target="_blank" >
                        <img className={`${styles.eyeIcon} ${styles.pointer}`} src={eyeImage} />
                      </a>
                      <p className={styles.jobLocation}>
                        {job.location !== "null" ? job.location : 'Location not available'}
                      </p>
                      <p className={styles.jobLocation}>
                        Created By : {capitalize(job.full_name)}
                      </p>
                      <p className={styles.jobLocation}>
                        Expiry On  : {moment(job.expiry_date).format('DD-MMM-YYYY')}
                      </p>
                      
                      <span className={styles.jobStatus}>
                        {job.status == 'onhold' ? 'On Hold' : capitalize(job.status)}
                      </span>
                      {job.status === 'pending' && currentUser.role === "employer" && organization.status ==="approved" && (
                        <span className={`${styles.jobActive} ${styles.pointer}`}  onClick={() => JobUpdateStatus(job.id,'approve')}>
                          Active
                        </span> 
                      )}
                      {job.status === 'pending' && currentUser.role === "employer" && organization.status ==="approved" && (
                        <>
                          <span className={`${styles.jobReject} ${styles.pointer}`}  onClick={() => JobUpdateStatus(job.id,'reject')}>
                            Reject
                          </span>
                          <br></br>
                        </>
                      )}
                      {getDueDays(job.expiry_date) <= 7 && getDueDays(job.expiry_date) > 0 && (
                        <span className={styles.jobDue}>
                          Due on {getDueDays(job.expiry_date)} days
                       </span>)
                      }
                      {getDueDays(job.expiry_date) === 0 && (
                        <span className={styles.jobDue}>
                          Due on today
                       </span>)
                      }
                      
                    </div>
                     
                  </td>
                  <td className='text-center'>
                    <a href="#" onClick={() =>
                       handleAPi(job.id,'lead')
                    }>
                      {/* {job && job['metrics'] && job['metrics'][0]['leads'] || 0} */}
                      {job.leads == 'N/A' ? 0 : job.leads }
                    </a>
                  </td>
                  { memberOrganization && 
                    <td className='text-center'>
                      <a href="#" onClick={() => handleAPi(job.id,'active_candidates')
                      }>
                       {job.active_candidates == 'N/A' ? 0 : job.active_candidates }
                      </a>
                    </td>
                  }
                  <td className='text-center'>
                    <a href="#" onClick={() => handleAPi(job.id,'applicant')
                    }>
                      {job.applicant == 'N/A' ? 0 : job.applicant}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a href="#" onClick={() => handleAPi(job.id,'recruitor_screen') 
                    }>
                      {job.recruiter == 'N/A' ? 0 : job.recruiter}
                    </a>
                  </td>
                  <td className={`${styles.minWidth160} text-center`}>
                    <a href="#" onClick={() =>  handleAPi(job.id,'submitted')
                    }>
                      {job.submitted == 'N/A' ? 0 : job.submitted}
                    </a>
                  </td>
                  <td className={`${styles.minWidth160} text-center`}>
                    <a href="#" onClick={() => handleAPi(job.id,'first_interview')
                    }>
                      {job.first_interview == 'N/A' ? 0 : job.first_interview}
                    </a>
                  </td>
                  <td className={`${styles.minWidth160} text-center`}>
                    <a href="#" onClick={() => handleAPi(job.id,'second_interview')
                    }>
                      {job.second_interview == 'N/A' ? 0 : job.second_interview}
                    </a>
                  </td>
                  <td className={`${styles.minWidth160} text-center`}>
                    <a href="#" onClick={() => handleAPi(job.id,'onhold')
                    }>
                      {job.onhold == 'N/A' ? 0 : job?.onhold}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a href="#" onClick={() => handleAPi(job.id,'offer')
                    }>
                      {job.offer == 'N/A' ? 0 : job.offer}
                    </a>
                  </td>
                  <td className='text-center'>
                    <a href="#" onClick={() =>  handleAPi(job.id,'hired')
                    }>
                      {job.hired == 'N/A' ? 0 : job.hired}
                    </a>
                  </td>
                  
                  <td className='text-center'>
                    <a href="#" onClick={() =>  handleAPi(job.id,'reject')
                    }>
                      {job.archived == 'N/A' ? 0 : job.archived}
                    </a>
                  </td>
                  {/* <td  style={{ padding: '3px 1px 3px 5px' }}>
                    <button
                      className={styles.viweAnalitic}
                      style={{
                        marginTop: 10,
                        background: `${
                          viweAnalyticID.includes(job.id)
                            ? '#5263BE'
                            : '#EBEDFA'
                        }`,
                        color: `${
                          !viweAnalyticID.includes(job.id)
                            ? '#5263BE'
                            : '#EBEDFA'
                        }`,
                      }}
                      onClick={(e) => handleViweAnalytics(e, job.id)}
                    >
                      View analytics{' '}
                      <span>
                        <i
                          className={styles.iconArrow}
                          data-feather="chevron-down"
                        ></i>
                      </span>{' '}
                    </button>
                  </td> */}
              </tr>
                {viweAnalyticID.includes(job.id) && (
                  <tr>
                    <td colspan="10" style={{borderTop:'none'}}>
                      <ChartContainer
                        metricsData={job.metrics}
                        jobId={job.id}
                        handleCloseViweAnalitic={handleCloseViweAnalitic}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </thead>
        </table>
      </div>
    </>
  )
}

export default JobsTable
