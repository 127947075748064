import React, { useState, useEffect } from 'react'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from '../Admin/shared/Button'
import P from '../Admin/shared/P'
import { DisplayPagination } from '../Admin/styles/AdminDashboard.styled'
import './styles/JobLead.scss'
import Util from "../../../utils/util";
import { Row, Col,Spinner } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal"
import SweetAlert from 'react-bootstrap-sweetalert'
import DataTable from "react-data-table-component-footer"
import   '../Admin/styles/DataTableCustom.scss'
import feather from 'feather-icons'
import Badge from 'react-bootstrap/Badge'

const JobLeadList = ({job,organization,lead_file}) => {
  const [pendingReview, setPendingReview] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [reviewStatus, setReviewStatus] = useState()
  const [reviewNote,setReviewNote] = useState('')
  const [checkboxValue,setCheckboxValue] = useState(false)
  const [dataId,setDataId] = useState(0)
  const [noteError,setNoteError] = useState('')
  const [show,setShow] = useState(false)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [actionShow, setActionShow] = useState( (lead_file.review_status == 'pending') ? true : false)
  const [loading, setLoder] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);
  const [deleteMultiple, setDeleteMultiple] = useState(false)
  const [showMultiDeleteConfirmation, setShowMultiDeleteConfirmation] = useState(false)
  const [showApproveRejectConfirmation, setShowApproveRejectConfirmation] = useState(false)
  const [title, setTitle] = useState('')

  const [perPage, setPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)

  const fetchData = () => {
    setShowRemoveConfirmation(false)
    setShowApproveRejectConfirmation(false)
    setSelectedRows([])
    const url = `/job_leads/${lead_file.id}.json`
    setLoder(true)
    makeRequest(url, 'get',  {params: {page:activePage}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        let sr_no = (activePage - 1) * res.data.per_page
        setPendingReview(
          res.data.job_leads.map((data) => (sr_no = sr_no + 1,{
            sr_no: sr_no,
            id: data.id,
            candidate_name: data?.first_name + ' ' + data?.last_name,
            current_title: Util.capitalize(data?.current_position),
            current_company: data?.company_name,
            linkedIn: <a href={data?.person_linkedin_url} target="_blank"> Link </a>,
            skill: getSkillsCell(data),
            education: data?.education_experience,
            email: data?.email1,
            action: <a  onClick={()=> onDeleteClick(data.id)} className="trash-leads"> <i data-feather="trash-2" /> </a>
          }))
        )
        setPerPage(res.data.per_page)
        setPageCount(res.data.total_pages)
        setStartRecord((activePage - 1) * res.data.per_page)
        setCurrentCounts(res.data.current_counts)
        setTotalCount(res.data.total_count)
        setLoder(false)
      },
    })
    
  }

  useEffect(() => {
    feather.replace()
  })

  useEffect(() => {
      fetchData()
      setDataId(0)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    
  }, [])

  useEffect(()=>{
    console.log('Change Page')
    fetchData()
},[activePage])


  const deleteCandidate = async (id) => {
    const URL = `/job_leads/${id}`
    await makeRequest(URL, 'delete', '', {
      createResponseMessage: () => {
        return {
          message: `Deleted successful`,
          messageType: 'success',
          loading: false,
          autoClose: true,
        }
      },
      createErrorMessage: (e) => 'Failed',
      onSuccess: () => {      
          setDataId(0)
          setShowRemoveConfirmation(false)
          fetchData()
      },      
    })      
  }    

  const addData = (status,job,organization,id) => {
    console.log('Add Data')
    setReviewStatus(status)
    setDataId(id)
    setCheckboxValue(false)
    setNoteError('')
    setReviewNote('')
    setTitle(status == 'approve' ? 'Are you sure about approving ALL candidates ?' : 'Are you sure about rejecting ALL candidates ?')
    setShowApproveRejectConfirmation(true)
  }

  const updateStatus = async (id) => {
    const payload = new FormData()
    payload.append('job_leads_params[review_status]',reviewStatus)
    let reviewNoteValue = reviewNote
    if(checkboxValue === true && reviewNoteValue != ''){ 
        if(reviewNoteValue.length  < 10){
          setNoteError('Minimum 10 characters are required.')
          return false
        }
    }else if(checkboxValue === false && reviewNoteValue != ''){ 
      if(reviewNoteValue.length  < 10){
          setNoteError('Minimum 10 characters are required.')
          return false
      }
    }else if (checkboxValue === false && reviewNoteValue == ''){
      setNoteError('Note is required field.')
      return false
    }else{
      reviewNoteValue = 'Leads are not good.'
       if(reviewStatus == 'approve'){
        reviewNoteValue = 'Leads are good.'
       }
    }
    payload.append('job_leads_params[review_note]', reviewNoteValue)
    const URL = `/job_leads/${id}`
    await makeRequest(URL, 'post',payload, {
      createResponseMessage: () => {
        return {
          message: `Deleted successful`,
          messageType: 'success',
          loading: false,
          autoClose: true,
        }
      },
      createErrorMessage: (e) => 'Failed',
      onSuccess: () => { 
          onHide()   
          setActionShow(false)  
          fetchData()
      },      
    })      
  }
  const onHide = () => {
    setShow(false)
    setCheckboxValue(false)
    setNoteError('')
    setReviewStatus('')
    setDataId(0)
    setReviewNote('')
  }

  const onInputChange = (e) => {
    setReviewNote((e.target.value).trim())
  }

  const onCheckboxChange = (e) => {
    setCheckboxValue(e.target.checked)
  }

  const onDeleteClick = (id) =>{
    setShowRemoveConfirmation(true)
    setDataId(id)
  }

  const redirectBack = () => {
    window.location.href = '/job_leads'
  }

  const  columns= [
    {
      name: 'No.',
      selector: row => row.sr_no,
      maxWidth: '5%',
      minWidth: '5%',
    },
    {
      name: 'Candidate Name',
      selector: row => row.candidate_name,
      maxWidth: '12%',
      minWidth: '12%',
      wrap:true
    },
    {
      name: 'Email',
      selector: row => row.email,
      maxWidth: '12%',
      minWidth: '12%',
      wrap:true
    },
    {
      name: 'Current Title',
      selector: row => row.current_title,
      maxWidth: '12%',
      minWidth: '12%',
      wrap:true
    },
    {
      name: 'Current Company',
      selector: row => row.current_company,
      maxWidth: '11%',
      minWidth: '11%',
      wrap:true
    },
    {
      name: 'LinkedIn',
      selector: row => row.linkedIn,
      maxWidth: '7%',
      minWidth: '7%',
      wrap:true
    },
    {
      name: 'Skills',
      selector: row => row.skill,
      maxWidth: '18%',
      minWidth: '18%',
      wrap:true
    },
    {
      name: 'Education',
      selector: row => row.education,
      maxWidth: '14%',
      minWidth: '14%',
      wrap:true
    },
    {
      name: 'Actions',
      selector: row => row.action,
      maxWidth: '6%',
      minWidth: '6%',
    }
]

  const handleRowSelected = React.useCallback(state => {
    setDeleteMultiple(true)
    setSelectedRows(state.selectedRows.map((data) => {
      return data.id
    }));
  }, []);
   
  

  const deleteMultiRecord =  async() => {
    setShowApproveRejectConfirmation(false)
    const URL = `/job_leads`
    await makeRequest(URL, 'put', {list: selectedRows}, {
      createResponseMessage: () => {
        return {
          message: `Deleted successful`,
          messageType: 'success',
          loading: false,
          autoClose: true,
        }
      },
      createErrorMessage: (e) => 'Failed',
      onSuccess: () => {      
          setDeleteMultiple(false)
          fetchData()
      },      
    }) 
  }
  
  return (
    <>
    <div className="container">
      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="28px" height="38px" color="#1D2447" style={{width:'940px',marginBottom:'20px'}}>
          Review Leads for {Util.capitalize(organization.name)} - {Util.capitalize(job.name)}
        </P>
        <div>
            <Button  className="ml-3" onClick={ ()=> redirectBack()}>
                Go Back
            </Button>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center w-100">
        <P size="21px" height="38px" color="#1D2447" style={{width:'540px',marginBottom:'20px'}}>
          Pending to Review
        </P>
        <div>
         { deleteMultiple && selectedRows.length > 0 &&
           <Button  className="ml-3 rejectButton"  onClick={ () => setShowMultiDeleteConfirmation(true)}>
             Bulk Delete
          </Button>
         }
         { actionShow &&   
          <>
            <Button  className="ml-3 approveButton"  onClick={ () => addData('approve',Util.capitalize(job?.name),Util.capitalize(organization?.name),lead_file.id)}>
                Approve
            </Button>
            <Button className="ml-3 rejectButton" onClick={ () => addData('reject',Util.capitalize(job?.name),Util.capitalize(organization?.name),lead_file.id)}>
                Reject
            </Button>
            </>
          }
        </div>
       
      </div>
      <div className="d-flex justify-content-between align-items-center w-100">
        <p><strong>Note: </strong> Removing lead is irreversible process.</p>
      </div>
      <Row>
          <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {(currentCounts > totalCount) ? totalCount : currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
      </Row>
      <div className="w-100">
        <DataTable
            persistTableHead={true}
            noDataComponent={<p className="text-center p-3">No record found</p>}
            columns={columns}
            data={pendingReview}
            progressPending={loading}
            progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                <Spinner className='spinner' animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </P>}
            highlightOnHover
            selectableRows
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            pagination
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={perPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setActivePage(page)}
        />
      </div>
    </div>
    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
                  {Util.capitalize(organization.name)} - {Util.capitalize(job.name)} - {Util.capitalize(reviewStatus)}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
              <Row className="w-100 m-0">
                <Col xs={12} sm={12} lg={12} style={{textAlign:'center'}}>
                  <p> Please add note for marketing if you have any specific instruction for them, else select the no comment checkbox</p>
                </Col>
              </Row>
              <Row className="w-100 m-0">
					       <Col xs={12} sm={12} lg={2} style={{textAlign:'right'}}>
                    <span>Note :</span>
                 </Col>
                 <Col xs={12} sm={12} lg={8}>
                    <textarea rows="5" cols="60" placeholder='Enter note here' onChange={ (e)=> onInputChange(e)}></textarea>
                 </Col>
               </Row>
               <Row className="w-100 m-0">
                  <Col xs={12} sm={12} lg={2}>
                  </Col>
                  <Col xs={12} sm={12} lg={6}>
                     <input type='checkbox' className="mr-2" onClick={ (e)=> onCheckboxChange(e)}></input> { (reviewStatus == 'approve') ? 'No comments, Leads are good' : 'No comments, Leads are not good'}.
                     <br></br>
                     <span className='spanError'>{noteError}</span>
                  </Col>
                  
               </Row>
               <Row className="w-100 m-0"  >
                  <Col xs={12} sm={12} lg={11} style={{textAlign:'right'}}>
                    <Button className="mr-4 approveButton" onClick={ ()=> updateStatus(dataId)}>Submit</Button>
                  </Col>
               </Row>
            </div>
        </Modal.Body>
    </Modal>
    { showRemoveConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={()=>deleteCandidate(dataId)}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />
     )}

    { showMultiDeleteConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure about deleting multiple candidates?"
                    onConfirm={()=>(deleteMultiRecord(),setShowMultiDeleteConfirmation(false))}
                    onCancel={()=>setShowMultiDeleteConfirmation(false)}
                    focusCancelBtn
                    cancelBtnStyle={{textDecoration: 'none'}}
                />
     )}

      { showApproveRejectConfirmation && (
     
                <SweetAlert
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  title={title}
                  onConfirm={()=> (setShow(true),setShowApproveRejectConfirmation(false))}
                  onCancel={()=>setShowApproveRejectConfirmation(false)}
                />
         )}      
     

    </>
  )
}


function getSkillsCell(candidate) {
  return (
      <div
          key={'skills_' + candidate.id}
          className=''
          style={{ width: '10rem' }}
          title={candidate.person_skills}
      >
          {candidate.person_skills && (
              <div>
                  {candidate.person_skills
                      .split(',')
                      .slice(0, 5)
                      .map((skill, index) =>
                          canidateSkillATS(skill, index)
                      )}
                  {5 < candidate.person_skills.split(',').length && (
                              <label>
                                    ....
                              </label>
                        )}
              </div>
          )}
      </div>
  )
}

function canidateSkillATS(skill, index) {
  return (
      <>
          <Badge
              pill
              key={index}
              variant="secondary"
              className="skill-ats-button"
              style={{background: '#708597',fontSize: '11.5px'}}
          >
              <label> {skill} </label>
          </Badge>
          {'   '}
      </>
  )
}

export default JobLeadList