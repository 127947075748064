import React, { useState, useEffect } from 'react'
import '../../../../assets/v2/css/main.css'
import './styles/JoinCoachingIndex.scss'
import LogoWithText from '../../../../assets/images/logos/navbar-logo.svg'
import paulnew from '../../../../assets/images/join/paulnew.png'
import tesla from '../../../../assets/images/join/tesla.png'
import snowflake from '../../../../assets/images/join/snowflake.png'
import microsoft from '../../../../assets/images/join/microsoft.png'
import amazon from '../../../../assets/images/join/amazon.png'
import digital from '../../../../assets/images/join/digital-marketing.svg'
import creative from '../../../../assets/images/join/creative-layout.svg'
import market from '../../../../assets/images/join/market-research.svg'
import Ilona from '../../../../assets/images/join/Ilona.jpeg'
import { Modal } from 'react-bootstrap'
import JoinCoachingCandidateDetails from '../../common/CandidateForm/JoinCoachingCandidateDetails'

function JoinCoachingIndex({currentUser,recruiter}) {
  const { calendly_link, first_name, last_name } = recruiter || {};
  const [candidateModel, setCandidateModal] = useState(false)
  const handleApply = async () => {
    setCandidateModal(true)  
  }
  const calendly_url = calendly_link ?? 'https://calendly.com/paulchriscampbell';

  return (
    <>
      <section className="section-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="coaching-banner p-40">
                <img alt="CardinalTalent" src={LogoWithText}
                  style={{ height: "50px" }} />
                <p className="tagline mt-20">Increase your chances of standing out</p>
                <h1>Join My Coaching Program</h1>
                <p style={{ marginBottom: "16px" }}>You'll be paired with a recruitment partner who will advise
                  and send you positions that match your profile. All you have to do is approve the ones
                  you like.</p>
                {!currentUser?.id ?
                    <a href="#" onClick={handleApply} className="btn  btn-default mt-20">BOOK A CALL</a>
                    :
                    <a href={calendly_url} className="btn btn-default">BOOK A CALL</a>
                }
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
              <div className="job-card">
                <div className="card-header">
                  <img src={paulnew} alt="Profile Image"
                    className="profile-image" />
                  <h6>Hey, here is a list of jobs for you today.</h6>

                </div>
                <ul className="job-list ">
                  <li className="box1">
                    <span className="company-logo amazon"><img
                      src={amazon} /></span>
                    <div className="job-info">
                      <span className="company-name">Amazon</span>
                      <span className="job-title">Staff/Senior Product Designer</span>
                    </div>
                  </li>
                  <li className="box1">
                    <span className="company-logo microsoft"><img
                      src={microsoft} /></span>
                    <div className="job-info">
                      <span className="company-name">Microsoft</span>
                      <span className="job-title">Lead Product Designer</span>
                    </div>
                  </li>
                  <li className="box1">
                    <span className="company-logo snowflake"><img
                      src={snowflake} /></span>
                    <div className="job-info">
                      <span className="company-name">Snowflake</span>
                      <span className="job-title">Staff/Senior Product Designer</span>
                    </div>
                  </li>
                  <li className="box1">
                    <span className="company-logo tesla"><img
                      src={tesla} /></span>
                    <div className="job-info">
                      <span className="company-name">Tesla</span>
                      <span className="job-title">Staff/Senior Product Designer</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-20">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 header111">
              <div className="content-single sidebar-shadow">
                <h5 className="">How we ensure your success</h5>
                <h6 className="">Our team of specialists and AI powered tools will help increase your
                  chances to get interviews</h6>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                    <div className="card-grid-4 hover-up" style={{ margin: "10px", border: "none" }}>
                      <div className="image-top-feature">
                        <figure><img alt="jobhub"
                          src={market} />
                        </figure>
                      </div>
                      <div className="card-grid-4-info">
                        <h5 className="mt-20">1:1 coaching with our specialists</h5>
                        <h6>5 meetings with our recruitment consultant</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                    <div className="card-grid-4 hover-up" style={{ margin: "10px", border: "none" }}>
                      <div className="image-top-feature">
                        <figure><img alt="jobhub"
                          src={creative} />
                        </figure>
                      </div>
                      <div className="card-grid-4-info">
                        <h5 className="mt-20">Automated resume personalization</h5>
                        <h6>Each application gets a personalized resume</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                    <div className="card-grid-4 hover-up" style={{ margin: "10px", border: "none" }}>
                      <div className="image-top-feature">
                        <figure><img alt="jobhub"
                          src={digital} />
                        </figure>
                      </div>
                      <div className="card-grid-4-info">
                        <h5 className="mt-20">Auto-apply to 100+ jobs per week</h5>
                        <h6>AI will help you find the best jobs</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12 header111 p-0">
              <div className="content-single sidebar-shadow">
                <div>
                  <div className="approach-container" style={{ boxShadow: "0 0 0 0" }}>
                    <h2>Our approach really works</h2>
                    <p>Our mix of human expertise and smart tools will help you find your dream job</p>

                    <div className="stats">
                      <div className="stat-item d-flex align-items-center">
                        <span className="number">3x</span>
                        <span className="description">applications sent with AI auto-apply</span>
                      </div>
                      <div className="stat-item  d-flex align-items-center">
                        <span className="number">3x</span>
                        <span className="description">success rate with AI resume customization</span>
                      </div>
                      <div className="stat-item  d-flex align-items-center">
                        <span className="number">20%</span>
                        <span className="description">more offers with our preparation tools</span>
                      </div>
                      <div className="stat-item  d-flex align-items-center">
                        <span className="number">10-20%</span>
                        <span className="description">more compensation with our assistance</span>
                      </div>
                      <div className="stat-item  d-flex align-items-center">
                        <span className="number">10x</span>
                        <span className="description">the results in 10% of the time</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonial-container">
                  <div className="row align-items-center">
                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                      <img src={Ilona}
                        alt="Ilona Haradzetskaya" className="testimonial-image"
                        style={{ marginLeft: "20px" }} />
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">

                      <div className="testimonial-content">
                        <strong>Ilona Haradzetskaya</strong>
                        <p style={{ margin: "1px" }}>Working with CardinalTalent.ai was a fantastic
                          experience.
                          They were professional, responsive, and truly cared about finding the
                          right fit
                          for me. I appreciated their commitment to keeping me informed throughout
                          the
                          process and their willingness to go the extra mile to ensure that I was
                          happy
                          with the outcome. I would highly recommend CardinalTalent.ai to anyone
                          looking
                          for a recruiter who truly puts their clients first.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30 header11">
              <div className="sidebar-shadow">
                <h5 className="font-bold">Meet our team of recruitment partners
                </h5>
                <div className="sidebar-list-job mt-10 sidebar-people">
                  <div className="sidebar-people-row"
                    style={{ display: "flex", alignItems: "flex-start" }}>
                    <img style={{ width: "25%" }} src={paulnew} />
                    <div>
                      <h2 className="line_new">Paul Campbell</h2>
                      <p className="mb-20">CEO | MS in Engineering from Stanford | 15+ years of
                        recruiting experience</p>

                    </div>
                  </div>
                </div>

              </div>

              <div className="sidebar-shadow">
                <div className="mt-10 sidebar-people">
                  <div className="sidebar-filters">
                    <div className="filter-block mb-30">
                      <h5 className="medium-heading mb-15">Custom pricing</h5>
                      <h5 className="medium-heading mb-15">Based on what you will make in 1 week</h5>
                    </div>

                    <div className="filter-block mb-30">
                      <div className="">
                        <span className="number">1.9%</span>
                        <span className="description">of your yearly salary</span>
                      </div>
                    </div>
                    <div className="filter-block mb-30">
                    {!currentUser?.id ?
                    <a href="#" onClick={handleApply} className="btn  btn-default mt-20">BOOK A CALL</a>
                    :
                    <a href={calendly_url} className="btn btn-default">BOOK A CALL</a>
                }
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box">
        <div className="container">
          <div className="content-single sidebar-shadow" style={{ backgroundColor: "#e9e9e9" }}>
            <div className="row align-items-center">
              <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                <h4>Our team of coaches are here to help you</h4>
                <h6 className="mt-10 pb-10">Get access to take a look around with no obligations</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                <div className="mr-20">
                {!currentUser?.id ?
                    <a href="#" onClick={handleApply} className="btn  btn-default mt-20">BOOK A CALL</a>
                    :
                    <a href={calendly_url} className="btn btn-default">BOOK A CALL</a>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        onHide={() => setCandidateModal(false)}
        show={candidateModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <JoinCoachingCandidateDetails
          setCandidateModal={setCandidateModal}
          calendly_link={calendly_url}
        />
      </Modal>
    </>
  )
}
export default JoinCoachingIndex
