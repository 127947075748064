import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './styles/Policy.module.scss'

function PrivacyPolicy() {
    const history = useHistory()
    return (
        <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: 'calc(80vh - 100px)' }}>
            <div className={styles.customContainer}>
                <div className="d-flex justify-content-center row">
                    <h3 className={styles.optoutTitle}>Own your data</h3>
                </div>
                <div className="d-flex justify-content-center row">
                    <h3 className={styles.optoutDesc}>Choose the type of the request to submit</h3>
                </div>
                <div className="d-flex justify-content-center row">
                    <div className={styles.customCard}>
                        <a href='/opt_out_info?page=view'
                            tabIndex={0} role="button" className={styles.btnCustom} aria-disabled="false">
                            View your personal <br />information
                        </a>
                        <a
                            type="button" tabIndex={0} role="button"
                            className={styles.btnCustom} aria-disabled="false"
                            href='/opt_out_info?page=edit'
                        >
                            Correct your personal <br />information
                        </a>
                        <a
                            type="button" tabIndex={0} role="button"
                            className={styles.btnCustom}
                            aria-disabled="false"
                            href='/opt_out_info?page=remove'
                        >
                            Remove and opt-out of the sale of your personal information
                        </a>
                    </div>
                </div>
                <div className="mt-4 d-flex justify-content-center row">
                    <a href="/privacy_policy" className="text-center">
                        <button type="button" tabIndex={0} role="button" className={styles.btnlink} aria-disabled="false">Privacy policy</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
