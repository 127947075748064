import React, { useEffect, useState } from 'react'
import Card from '../Admin/shared/Card'
import P from '../Admin/shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from './styles/AnalyticPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { capitalize } from '../../../utils'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AnalyticPage.styled'
import DataTable from "react-data-table-component-footer"
import   '../Admin/styles/DataTableCustom.scss'

const JobRecruitersAnalyticPage = (prop) => {
  const {currentUser} = prop
  const queryParams = new URLSearchParams(window.location.search)
  const urlStartDate = queryParams.get("start_date")
  const urlEndDate = queryParams.get("end_date")
  const jobId = queryParams.get("job") || ''
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount,setTotalCount] = useState()
  const [startRecord, setStartRecord] = useState(0)
  const [perPage, setPerPage] = useState(prop.per_page)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobStatus, setJobStatus] = useState([])
  const [dateRange, setDateRange] = useState({startDate: (urlStartDate) ? urlStartDate: moment().startOf('week').toDate() ,endDate: (urlEndDate) ? urlEndDate : moment().toDate()})
  const handleDate = (date) => {
      setDateRange(date)
  }
  const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'},...prop.recruiterList])
  const [selectedRecruiter,setSelectedRecruiter] = useState('')
  const [defaultselected,setdefaultselected] = useState({value: 'all', label: 'All'})
  const csvExportRef = React.useRef();
  const [exportDatas, setExportDatas] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')

  let tableHeaders,tableColumn = []
  if(currentUser.role == "recruiter"){
    tableHeaders = [
      { label: 'Client', key: 'organization' },
      { label: 'Job Name', key: 'jobName' },
      { label: 'Recruiter Screened', key: 'recruiterScreened' },
      { label: 'Submitted', key: 'submitted' },
      { label: 'First Interview', key: 'firstInterview' },
      { label: 'Second Interview', key: 'secondInterview' },
      { label: 'Offers', key: 'offers' },
      { label: 'Hired', key: 'hired' },
      { label: 'Archived', key: 'archived' },
    ]
    tableColumn = [
      { name: 'No.',
        selector: row => row.id,
        maxWidth: '4%',
        minWidth: '4%'
      },
      { name: 'Client',
        selector: row => row.organization,
        maxWidth: '20%',
        minWidth: '20%',
        sortable: true,
        sortField: 'organizations_name' 
      },
      { name: 'Job name',
        selector: row => row.jobName,
        maxWidth: '20%',
        minWidth: '20%',
        sortable: true,
        sortField: 'job_name',
        wrap:true
      },
      { name: 'Recruiter Screened',
        selector: row => row.recruiterScreened,
        maxWidth: '8%',
        minWidth: '8%',
        sortable: true,
        sortField: 'recruitor_screened'
      },
      { name: 'Submitted',
        selector: row => row.submitted,
        maxWidth: '8%',
        minWidth: '8%',
        sortable: true,
        sortField: 'submitted'
      },
      { name: 'First Interview',
        selector: row => row.firstInterview,
        maxWidth: '8%',
        minWidth: '8%',
        sortable: true,
        sortField: 'first_interview'
      },
      { name: 'Second Interview',
        selector: row => row.secondInterview,
        maxWidth: '8%',
        minWidth: '8%',
        sortable: true,
        sortField: 'second_interview'
      },
      { name: 'Offers',
        selector: row => row.offers,
        maxWidth: '8%',
        minWidth: '8%',
        sortable: true,
        sortField: 'offer'
      },
      { name: 'Hired',
        selector: row => row.hired,
        maxWidth: '8%',
        minWidth: '8%',
        sortable: true,
        sortField: 'hired'
      },
      { name: 'Archived',
        selector: row => row.archived,
        maxWidth: '8%',
        minWidth: '8%',
        sortable: true,
        sortField: 'reject'
      },
    ]
  }else{
    tableHeaders = [
      { label: 'Job Name', key: 'jobName' },
      { label: 'Recruiter Name', key: 'recruiter' },
      { label: 'Recruiter Screened', key: 'recruiterScreened' },
      { label: 'Submitted', key: 'submitted' },
      { label: 'First Interview', key: 'firstInterview' },
      { label: 'Second Interview', key: 'secondInterview' },
      { label: 'Offers', key: 'offers' },
      { label: 'Hired', key: 'hired' },
      { label: 'Archived', key: 'archived' },
    ]
    tableColumn = [
      { name: 'No.',
        selector: row => row.id,
        maxWidth: '4%',
        minWidth: '4%'
      },
      { name: 'Job name',
        selector: row => row.jobName,
        maxWidth: '14%',
        minWidth: '14%',
        sortable: true,
        sortField: 'job_name',
        wrap:true
      },
      { name: 'Recruiter Name',
        selector: row => row.recruiter,
        maxWidth: '12%',
        minWidth: '12%',
        sortable: true,
        sortField: 'user_name' 
      },
      { name: 'Recruiter Screened',
        selector: row => row.recruiterScreened,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'recruitor_screened'
      },
      { name: 'Submitted',
        selector: row => row.submitted,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'submitted'
      },
      { name: 'First Interview',
        selector: row => row.firstInterview,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'first_interview'
      },
      { name: 'Second Interview',
        selector: row => row.secondInterview,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'second_interview'
      },
      { name: 'Offers',
        selector: row => row.offers,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'offer'
      },
      { name: 'Hired',
        selector: row => row.hired,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'hired'
      },
      { name: 'Archived',
        selector: row => row.archived,
        maxWidth: '10%',
        minWidth: '10%',
        sortable: true,
        sortField: 'reject'
      },
    ]
  }
  const columns = tableColumn
  const fetchData = async () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/analytic/job_recruiters_analytic.json'
    const response = await makeRequest(
      `${url}?page=${activePage}&start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}&job=${jobId}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setTotalCount(response.data.total_count)
    let sr_no = (activePage - 1) * response.data.per_page
    setJobStatus(response?.data?.jobs_status.map((data,key)=> (sr_no = sr_no + 1,{
      id: sr_no,
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organizations_name && capitalize(data?.organizations_name),
      recruiter: `${data?.user_name ? `${capitalize(data?.user_name)}` : '-' }`,
      recruiterScreened: (data.recruitor_screened  > 0) ? <a href={`/analytic/job_recruiters_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=recruitor_screen`}>{data.recruitor_screened}</a> :'',
      submitted: (data.submitted  > 0) ? <a href={`/analytic/job_recruiters_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=submitted`}>{data.submitted}</a> :'',
      firstInterview: (data.first_interview  > 0) ? <a href={`/analytic/job_recruiters_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=first_interview`}>{data.first_interview}</a> :'',
      secondInterview: (data.second_interview  > 0) ? <a href={`/analytic/job_recruiters_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=second_interview`}>{data.second_interview}</a> :'',
      offers: (data.offer > 0) ? <a href={`/analytic/job_recruiters_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=offer`}>{data.offer}</a> :'',
      archived: (data.reject > 0) ? <a href={`/analytic/job_recruiters_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=reject`}>{data.reject}</a> :'',
      hired: (data.hired > 0) ? <a href={`/analytic/job_recruiters_analytic_detail?job_id=${data.job_id}&start_date=${moment(dateRange.startDate).format('YYYY-MM-DD')}&end_date=${moment(dateRange.endDate).format('YYYY-MM-DD')}&stage=hired`}>{data.hired}</a> :''
    })))
    setPageCount(response.data.total_pages)
    setStartRecord((activePage - 1) * response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setLoading(false)
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/analytic/job_recruiters_analytic.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}&job=${jobId}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.jobs_status.map((data,key)=> ({
      id: key,
      jobName: data?.job_name && capitalize(data?.job_name),
      organization: data?.organizations_name && capitalize(data?.organizations_name),
      recruiter: `${data?.user_name ? `${capitalize(data?.user_name)}` : '-' }`,
      recruiterScreened: data.recruitor_screened,
      submitted: data.submitted,
      firstInterview: data.first_interview,
      secondInterview: data.second_interview,
      offers: data.offer,
      archived: data.reject,
      hired: data.hired
    })))
    csvExportRef?.current?.link.click();
  }

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage,selectedRecruiter,sortDirection,sortField])

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  }
  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }

  return (
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Job Analytics
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
          </button>
          {jobId && (
            <a
              href='javascript:void(0)'
              onClick={()=>history.back()}
              className={`ml-1 ${styles.exportCSVButton}`}
            >
              Back
            </a>
          )}
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"JobRecruitersAnalytic.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
             {totalCount > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord} - {currentCounts} of {totalCount}</DisplayPagination>
             }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0'>
          <div style={{display: 'flex'}}>
            {(currentUser.role === "employer" &&
              <div style={{display: 'grid',marginLeft:'10px'}}>
                <label>
                    Recruiter
                </label>
                <Select
                    defaultValue={defaultselected}
                    options={recruiter}
                    onChange={(event)=>setSelectedRecruiter(event.value)}
                    name="recruiter"
                    className={styles.colourStyles}
                />
              </div>
            )}
            <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Select Date Range
              </label>
              <DateRangePicker
                  handleOnSubmit={handleDate}
                  defaultDate={dateRange}
              />
            </div>
          </div>
        </Col>
      </Row>
      <DataTable
          persistTableHead={true}
          noDataComponent={<p className="text-center p-3">No record found</p>}
          columns={columns}
          data={jobStatus}
          progressPending={loading}
          progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
              <Spinner className='spinner' animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>
          </P>}
          onSort={handleSort}
          sortServer
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          paginationPerPage={perPage}
          paginationComponentOptions={{
              noRowsPerPage: true
          }}
          onChangePage={page => setActivePage(page)}
        />
    </Card>
  )
}

export default JobRecruitersAnalyticPage