import React, { useState, useEffect } from 'react'
import Card from '../../Admin/shared/Card'
import Table from '../../Admin/shared/Table'
import Paginator from '../../../common/Paginator/Paginator'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../../Admin/shared/Button'
import P from '../../Admin/shared/P'
import { DisplayPagination } from '../../Admin/styles/AdminDashboard.styled'
import { Row, Col ,Spinner} from 'react-bootstrap'
import './styles/Team.scss'
import Util from "../../../../utils/util";
import Modal from "react-bootstrap/Modal"
import LeadAdd from './LeadAdd'
import DataTable from "react-data-table-component-footer"
import './styles/DataTableCustom.scss'
import feather from 'feather-icons'
import SweetAlert from 'react-bootstrap-sweetalert'
import { AButton } from '../../Admin/styles/UserManagementEditPage.styled'


const LeadList = ({}) => {
  var active_page = 1;//window.sessionStorage.getItem('salesListActivePage')
  var query = window.sessionStorage.getItem('query')
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [leads, setLeads] = useState([])
  const [totalTeams,setTotalTeams] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [show,setShow] = useState(false)
  const [method,setMethod] = useState('post')
  const [loading, setLoading] = useState(true)
  const [perPage, setPerPage] = useState(25)
  const [leadObject,setLeadObject] = useState(false)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)

  const fetchData = () => {
    setLoading(true)
    const url = `/admin/sales_leads.json`
    window.sessionStorage.setItem('salesListActivePage',activePage)
    makeRequest(url, 'get',  {params: {page: (activePage), query: query}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        let sr_no = (activePage - 1) * res.data.per_page
        setLeads(
          res.data.teams.map((team) => (sr_no = sr_no + 1,{
            id: sr_no,
            lead_id: team?.id,
            name: Util.fullName(team?.first_name,team?.last_name),
            email: team?.email,
            designation: team?.designation,
            current_company: team?.current_company,
            actions: (<div className='w-100 d-inline-flex justify-content-around'>
                <a  onClick={() =>showConfirmDelete(team)}  href={`javascript::void()`} className='pr-4'><i data-feather="trash-2" /></a>
            </div>)
          }))
        )
        setPageCount(res.data.total_pages)
        setTotalTeams(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord((activePage -1) * res.data.per_page)
        setPerPage(res.data.per_page)
        setLoading(false)
      },
    })
  }
    useEffect(() => {
      fetchData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage])

    useEffect(() => {
      feather.replace()
    })

    const deleteLead = async () => {
      const URL = `/admin/sales_leads/${leadObject.id}`
      await makeRequest(URL, 'delete', '', {
        createResponseMessage: () => {
          return {
            message: `Deleted successful`,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        },
        createErrorMessage: (e) => 'Failed',
        onSuccess: () => {      
            fetchData()
        },      
      })      
    }    

    const addLead = () => {
      setMethod('post')
      setShow(true)
    }

    const onHide = () => {
      setMethod('post')
      setShow(false)
    }

    const showConfirmDelete = (lead) => {
      setLeadObject(lead)
      console.log(lead)
      setShowRemoveConfirmation(true)
    }

    const columns = [
      {
          name: 'No.',
          selector: row => row.id,
          maxWidth: '4%',
          minWidth: '4%'
      },
      {
          name: 'Name',
          selector: row => row.name,
          sortable: false,
          sortField: 'name',
          maxWidth: '20%',
          minWidth: '20%',
          wrap:true
      },
      {
          name: 'Email',
          selector: row => row.email,
          sortable: false,
          sortField: 'email',
          maxWidth: '20%',
          minWidth: '20%',
          wrap:true
      },
      {
          name: 'Designation',
          selector: row => row.designation,
          sortable: false,
          sortField: 'designation',
          maxWidth: '20%',
          minWidth: '20%',
          wrap:true
      },
      {
        name: 'Current Company',
        selector: row => row.current_company,
        sortable: false,
        sortField: 'current_company',
        maxWidth: '20%',
        minWidth: '20%',
        wrap:true
      },
      {
        name: 'Actions',
        selector: row => row.actions,
      }
  ]

  return (
    <>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
          Sales Lead List
        </P>
        <div style={{flexGrow:1}}/>
        <Button onClick={addLead} className="ml-3">
          Add Sales Lead
        </Button>
        <AButton href={'https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/sample_sales_lead.csv'} className="ml-3">
          Sample File
        </AButton>
      </div>

      
      <Row className="w-100" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline', }}>
        <Col xs={12} sm={12} lg={2} className="p-0" style={{float:'left'}}>
          {totalTeams > 0  &&
            <DisplayPagination>Displaying {(startRecord == 0)?1:startRecord + 1} - {currentCounts}  of  {totalTeams} leads
            </DisplayPagination>
        }
          </Col>
      </Row>
      <DataTable
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={leads}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        // onSort={handleSort}
        sortServer
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalTeams}
        paginationPerPage={perPage}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        onChangePage={page => setActivePage(page)}
        // footer={footer}
      />
      {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to delete?"
                    onConfirm={()=>(deleteLead(),setShowRemoveConfirmation(false))}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this lead!
                </SweetAlert>
            }
    </Card>

    <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
        className='modal-entry-form'
        >
        <Modal.Header closeButton className='mt-2 mb-2'>
            <Modal.Title id="contained-modal-title-vcenter" >
              { method ==='put' ?'Update' : 'Add' } Sales Lead
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <LeadAdd onHide={onHide} method={method} fetchData={fetchData}></LeadAdd>
        </Modal.Body>
    </Modal>

    </>
  )
}

export default LeadList