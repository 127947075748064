import React, { Fragment, useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import { Container,Box, Icon } from './styles/TimeSheet.styled';
import Select from 'react-select';
import Text from '../../common/Styled components/Text';
import axios from 'axios';
import { TimeEntryContext } from '../../../stores/TimeEntryStore';
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Button from '../../common/Styled components/Button';
import { Spinner, Button as SpinButton, Alert } from 'react-bootstrap'
import styles from './styles/TimeSheetPage.module.scss'
import moment from 'moment'

const _formState = [{
  _date: '',
  _orgName: 'select organization',
  _orgId: '',
  _org: {label: 'default', value: 'default'},
  _hour: 0.0,
  _submissionCount: 0,
  _interviewedCount:0,
  _hiredCount: 0,
  _job: {label: 'choose job', value: '0'},
  _jobs: [],
  _submitted: [],
  _confirmed: [],
  _interviewed: [],
  _hired: [],
  _candidates: [],
  _action: 'create',
  _isResponseStatus: '',
  _alertMessage: ''
}];

function TimeEntryFormA(props) {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState([{..._formState[0], ...{_date: props.date}}]);
  const {dispatch, state} = useContext(TimeEntryContext);
  const {recruiterName, recruiterId, organizationId} = state

  // side effect
  useEffect(() => {
    console.log('> fetching entry date entries...')
    const URL = `/users/${recruiterId}/timesheet/show?entry_date=${props.date}&org_id=${organizationId}`
    axios.get(URL).then(res => {
      const {entries, meta} = res.data
      const {candidates} = meta
      const arr = entries.map( entry => {
        const {id, entry_date, organization_id, organization_name, job_name, job_id, recruiter_id,
          hours, confirmed_interviewed_candidate_ids, hired_candidates_ids, interviewed_candidates_ids, 
          submitted_candidate_ids} = entry

        return({
          _date: entry_date,
          _orgName: organization_name,
          _orgId: organization_id,
          _org: {label: organization_name, value: organization_id},
          _hour: hours,
          _job: {label: job_name, value: job_id},
          _submitted: submitted_candidate_ids.map(str => JSON.parse(str) ),
          _confirmed: confirmed_interviewed_candidate_ids.map(str => JSON.parse(str) ),
          _interviewed: interviewed_candidates_ids.map(str => JSON.parse(str) ) ,
          _hired: hired_candidates_ids.map(str => JSON.parse(str) ),
          _candidates: [...candidates],
          _action: 'update'
        });
      });

      if(arr.length == 0) {
        setFormState([{...formState[0], _candidates: meta.candidates}]);
      }else {
        setFormState([...arr]);
      }

    });

  }, []);

  const handleChange = (value, field, index) => {
    console.log('> handel change..')
    const cloneState = JSON.parse( JSON.stringify(formState) );
    const form = {...cloneState[index] }
    const temp = new Object()

    if(field == '_date'){
      value = value.target.value // value is actually a event
    }
    if(field == '_hour'){
      value = value.target.value
    }

    if(field == '_org') {
      temp['_orgId'] = value['value']
      temp['_orgName'] = value['label']
    }
    temp[field] = value

    cloneState[index] = {...form, ...temp}
    console.log(cloneState);
    setFormState(cloneState);
  };

  const handleOrgChange = (obj, index) => {
    console.log('> handle org change and update jobs...')
    const URL = `/users/timesheet/${recruiterId}/new.json?org_id=${obj.value}`
    const response = axios.get(URL).then(res => {
      const {entry, meta} = res.data

      const cloneFormState = JSON.parse( JSON.stringify(formState) )
      const form = cloneFormState[index]
      cloneFormState[index] = {...form, _orgName: obj.label, _orgId: obj.value, _org: obj, _jobs: meta.jobs, _candidates: entry.candidates }
      setFormState([...cloneFormState])
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('submitting form ...');
    console.log(e.target.getAttribute('value'))
    const index = e.target.getAttribute('value') // get state of submitted form
    const {_action, _date, _hour, _orgId, _orgName, _org, _job, _submitted, _interviewed, _confirmed, _hired} = formState[index]
    const method = (_action == 'update') ? 'patch' : 'post'
    const URL = (method == 'post') ? '/users/timesheet' : `/users/timesheet/${recruiterId}`

    const payload = new FormData()
    payload.append('timesheet[entry_date]', _date)
    payload.append('timesheet[name]',recruiterName)
    payload.append('timesheet[organization_name]',_orgName)
    payload.append('timesheet[job_name]', _job.label)
    payload.append('timesheet[recruiter_id]',recruiterId)
    payload.append('timesheet[job_id]',_job.value)
    payload.append('timesheet[organization_id]', _orgId)

    payload.append('timesheet[hours]', _hour)
    payload.append('timesheet[total_submissions]', _submitted.length)
    payload.append('timesheet[confirmed_interviews]', _confirmed.length)
    payload.append('timesheet[interviewed]', _interviewed.length)
    payload.append('timesheet[hired]', _hired.length)

    payload.append('timesheet[submitted_candidate_ids]',             JSON.stringify( _submitted.map(obj => JSON.stringify(obj))   ) )
    payload.append('timesheet[confirmed_interviewed_candidate_ids]', JSON.stringify( _confirmed.map(obj => JSON.stringify(obj))   ) )
    payload.append('timesheet[interviewed_candidates_ids]',          JSON.stringify( _interviewed.map(obj => JSON.stringify(obj)) ) )
    payload.append('timesheet[hired_candidates_ids]',                JSON.stringify( _hired.map(obj => JSON.stringify(obj))       ) )

    //headers
    const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
        
    const headers = {
      headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
      },
    }
    setLoading(true)
    // sending dynamic request based on formState._action on diff endpoint
    makeRequest(URL, method, payload, headers).then(function(res) {
      const cloneFormState = JSON.parse( JSON.stringify(formState) )
      const form = cloneFormState[index]
      cloneFormState[index] = {...form, _isResponseStatus: res.data.msgType, _alertMessage: res.data.messages, _action: 'update'}
      setFormState([...cloneFormState])
      setLoading(false)
    });


  };

  const addEntry = () => {
    console.log('> add new timesheet entry');
    setFormState([...formState, ...[{..._formState[0], _date: props.date}]]);
  };

  const removeEntry = (e, action, index) => {
    console.log('> removing form entry :', index)
    formState.splice(index, 1) && setFormState([...formState])
  };

  return (
    <Container padding="50px" >
      {props.children}
      { formState.map( (form, index) => (
        <form value={index} onSubmit={(e) => {handleSubmit(e)}} style={{width:"100%"}} className={styles.inputForm}>
        <Row className='mb-3'>
          <Col className='d-none'>
            <Text>Date:</Text>
            <input type='date' id='entry_date' className='form-control' name='entry_date' value={form._date} onChange={ (event) => handleChange(event, '_date', index)} disabled={(form._action == 'update') ? true : false} />
          </Col>
  
          <Col>
            <Text>Organization:</Text>
            <Select
              className="basic-single"
              value={{value: form._orgId, label: form._orgName}}
              isDisabled={(form._action == 'update') ? true : false}
              name="color"
              options={state.meta.organizations}
              onChange={(event) => handleOrgChange(event, index)}
              placeholder="Choose organization"
          />
          </Col>
          <Col>
            <Text>Total Hours:</Text>
            <input type='input' className='form-control' id='total_hours' name='total_hours' value={form._hour} onChange={(event) => handleChange(event, '_hour', index)}/>
          </Col>
          <Col>
            <Text>Jobs:</Text>
            <Select
              value={form._job}
              name='_jobs'
              options={form._jobs}
              className='job_selec_box'
              minMenuHeight={6}
              onChange={(event) => handleChange(event, '_job', index)}
              name='job'
              placeholder='Select a job'
              isDisabled={(form._action == 'update') ? true : false}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Text>Submitted Candidates:</Text>
            <Select
              value={[...form._submitted]}
              isMulti
              options={form._candidates}
              onChange={(event) => handleChange(event, '_submitted', index)}
              minMenuHeight={6}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Text>Confirmed Interview:</Text>
            <Select
              value={form._confirmed}
              isMulti
              options={form._candidates}
              onChange={(event) => handleChange(event, '_confirmed', index)}
              name='_confirmed'
              minMenuHeight={6}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Text>Interviewed:</Text>
            <Select
              value={form._interviewed}
              isMulti
              onChange={(event) => handleChange(event, '_interviewed', index)}
              options={form._candidates}
              name='_interviewed'
              minMenuHeight={6}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Text>Hired:</Text>
            <Select
              value={form._hired}
              isMulti
              onChange={(event) => handleChange(event, '_hired', index)}
              options={form._candidates}
              name='_hired'
              minMenuHeight={6}
            />
          </Col>
        </Row>
        <Row className=''>
          <Col>
            <input type='submit' value='Submit' className={styles.submitButton} />
          </Col>
          <Col>{loading ? <Spinner /> : ''}</Col>
          <Col className={styles.removeButton}>
            {form._action == 'create' && <Icon onClick={(e) => removeEntry(e, form._action, index)}>Remove</Icon>}
          </Col>
        </Row>
        <Alert className={(form._isResponseStatus === 'success') ? `${styles.successMessage}` : `${styles.failedMessage}`} >{form._alertMessage}</Alert>
      </form>
      ))
      }
      <Row aItems="center" jContent="space-between" gutterButtom>
        <Col className='m-2'>
          <Button
            size="small"
            box="5px"
            onClick={() => {addEntry()}}
            varient="outlined"
          >
            <i data-feather="plus" style={{ marginRight: '10px' }} />
            Add Entry
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default TimeEntryFormA;
