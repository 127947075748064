import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from '../shared/Card'
import { Row, Box } from '../../../common/Styled components/styled'
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch'
import DateRangePicker from '../../../common/DateRangePicker/DateRangePicker'
import Text from '../../../common/Styled components/Text'
import Button from '../shared/Button'
import EntryTable from './EntryTable'
import axios from 'axios'

function EntryReportPage() {    
  const [isClient, setIsClient] = useState(true)
  const [dateRange, setDateRange] = useState({startDate:  moment().startOf('week').toDate() ,endDate: moment().toDate()})
  const [timesheet, setTimesheet] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimesheet([])
    const startDate = moment(dateRange.startDate).format('MMM,DD, YYYY')
    const endDate = moment(dateRange.endDate).format('MMM,DD, YYYY')
    let url = `/admin/timesheet.json?start_date=${startDate}&end_date=${endDate}`
    const fetchTimesheets = async () =>{
      setLoading(true)
      if(isClient){
         url = `/admin/timesheet.json?tab=client&start_date=${startDate}&end_date=${endDate}`
      }
      const response = await axios.get(url).then(res => res.data.data).catch(error=>console.log(error))
      setTimesheet(response)
      setLoading(false)
    }
    fetchTimesheets()
  }, [isClient, dateRange])
    
  
  const handleDate = (date) => {
    setDateRange(date)
  }

  return (
    <Card>
      <Row aItems="center" jContent="space-between" gutterButtom>
        <Box>
          <Text varient="h1" color="primary" size="28px" gutterButtom>
            Time Entry
          </Text>
        </Box>
      </Row>
      <Row direction="row" jContent="space-between" aItems="center" gutterButtom>
        <Box>
          <DateRangePicker
            handleOnSubmit={handleDate}
            defaultDate={dateRange}
          />
        </Box>
        <Box aItems="center" jContent="space-between">
          <Text varient="h6" color="primary" size="22px" givePadding>
            Client  
          </Text>
          <ToggleSwitch
            id="client"
            state={isClient}
            setState={setIsClient}
            size="medium"
          />
           <Text varient="h6" color="primary" size="22px" givePadding>
            Recruiter 
          </Text>
        </Box>
        <Box>
          <Button>View Generated Reports</Button>
        </Box>
      </Row>
      <Row>
        {isClient ? (
          <EntryTable tableType="client" data={timesheet} date={dateRange} loading={loading}/>
        ) : (
          <EntryTable tableType="recruiter" data={timesheet} date={dateRange} loading={loading}/>
        )}
      </Row>
    </Card>
  )
}

export default EntryReportPage
