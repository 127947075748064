import Container from 'react-bootstrap/Container'
import React, { useState, useEffect, useReducer } from 'react';
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Card from '../Admin/shared/Card';
import styles from './styles/WeeklyReferralPage.module.scss'
import CandidateInfo from './CandidateInfo';
import Util from '../../../utils/util';
import Paginator from '../../common/Paginator/Paginator';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select'
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  initialState,
  reducer,
  StoreDispatchContext,
} from '../../../stores/JDPStore'
import CandidateInfoPanel from '../../common/CandidateInfoPanel/CandidateInfoPanel';
import notmatch from '../../../../assets/images/icons/thumb_down_2.png'

function WeeklyRecommendationPage({job_name,organization_id,organization_name,recruiter_org,memberOrganization,organization,currentUser}) {
  const queryParams = new URLSearchParams(window.location.search)
  const email_date = queryParams.get("date")
  const pathname = window.location.pathname?.split('/')
  const jobId = parseInt(pathname[2]) ? pathname[2] : ''
  const [activePage, setActivePage] = useState(0)
  const [loading, setLoader] = useState(true)
  const [candidates, setCandidates] = useState([])
  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [show,setShow] = useState(false)
  const [showNotMatch,setShowNotMatch] = useState(false)
  const [message, setMessage] = useState('')
  const [check, setCheck] = useState([])
  const [category, setCategory] = useState(jobId)
  const [singleIntrestedCandidates, setSingleIntrestedCandidates] = useState([])
  const [multiIntrestedCandidates, setMultiIntrestedCandidates] = useState([])
  const checked = multiIntrestedCandidates.length > 0 ? true : false
  const [isCheckAll, setIsCheckAll] = useState(checked)
  const [state, dispatch] = useReducer(reducer, initialState)
  
  const [selectedAction, setSelectedAction] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('')
  
  const [defaultActionselected, setdefaultActionselected] = useState({ value: 'person_recommendations.created_at', label: 'Date' })
  const [defaultActionselectedFilter, setdefaultActionselectedFilter] = useState({ value: '', label: 'All' })

  const [actionLists, setActionLists] = useState([{ value: 'person_recommendations.created_at', label: 'Date' }, { value: 'person_recommendations.score', label: 'Score' }])
  const [filterLists, setFilterLists] = useState([{ value: '', label: 'All' }, { value: 1, label: 'Match' }, { value: 0, label: 'Unmatch' }])
  const checkList = ["Location does not match", "Skills does not match", "Experience does not match", "Not enough experience", "Education requirement does not match", "Employment authorization does not match", "Other"]


  const fetchData = async() => {
    setCandidates([])
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setLoader(true)
    const url = category ? `/weekly_recommendation/${category}.json?&select=${selectedAction}&filter=${selectedFilter}` : `/weekly_recommendation.json`
    await makeRequest(url, 'get',  {params: {page: (activePage+1), email_date:  email_date}}, {
        contentType: 'application/json',
        loadingMessage: 'Fetching...',
        createSuccessMessage: (response) => response.data.message,
        onSuccess: (res) => {
          setCandidates(
              res.data.candidates.map((candidate) => ({
                  id: candidate.id,
                  first_name: capitalize(candidate.first_name),
                  last_name: capitalize(candidate.last_name),
                  title: candidate.title,
                  company_name: candidate.company_names,
                  email_address: candidate.email_address,
                  phone_number: candidate.phone_number,
                  skills: candidate.skills,
                  location: candidate.location,
                  linkedin_profile_url: candidate.linkedin_profile_url,
                  submissions: candidate.submissions,
                  resume: candidate.cv_url,
                  score: candidate?.person_recommendations[0].score || '',
                  match: candidate?.person_recommendations[0].match || '',
                  reason: candidate?.person_recommendations[0].reason || '',
                  created_at: candidate?.person_recommendations[0].created_at
              }))
          )
          setPageCount(res.data.total_pages)
          setTotalCount(res.data.total)
          setLoader(false)
        },
    })
  }

  const saveIntrestedPeople = () => {
    const url = `/weekly_recommendation.json`
    const formData = new FormData()
    if(multiIntrestedCandidates?.length > 0){
      let data = []
      multiIntrestedCandidates.map((row)=>{
        data.push({submission: {
          person_id: row,
          job_id: jobId,
          submission_type: 'recruitor_screen'
        }})  
      })
      formData.append('candidates', JSON.stringify(data))
    }
    else{
      formData.append('submission[person_id]',singleIntrestedCandidates.id)
      formData.append('submission[job_id]',jobId)
      formData.append('submission[stage]','recruitor_screen')
    }
    makeRequest(url, 'post', formData, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        if(res.data.type == "success"){
          onHide()
          setTimeout(()=>{
            window.location.reload()
          },3000)
        }
      },
    })
  }

  const saveFeedback = () => {
    const url = `/weekly_recommendation/feedback`
    const formData = new FormData()
    if(check?.length > 0){
      let data = []
      if(multiIntrestedCandidates?.length > 0){
          if(message){
              multiIntrestedCandidates.map((row)=>{
              data.push({person_recommendations: {
                person_id: row,
                job_id: jobId,
                reason: [...check, message]
              }})  
            })
          }
          else{
              multiIntrestedCandidates.map((row)=>{
              data.push({person_recommendations: {
                person_id: row,
                job_id: jobId,
                reason: check
              }}) 
            })
          }
        }
        else
        {
          if(message){
              data.push({person_recommendations: {
                person_id: singleIntrestedCandidates.id,
                job_id: jobId,
                reason: [...check, message]
              }})
          }
          else{
              data.push({person_recommendations: {
                person_id: singleIntrestedCandidates.id,
                job_id: jobId,
                reason: check
              }})
          }
        }
        formData.append('feedback', JSON.stringify(data))
      }
    else{
      if(multiIntrestedCandidates?.length > 0){
        let data = []
        multiIntrestedCandidates.map((row)=>{
          data.push({person_recommendations: {
            person_id: row,
            job_id: jobId,
            reason: check
          }})
        })
      }
      else
      {
        formData.append('person_recommendations[person_id]',singleIntrestedCandidates.id)
        formData.append('person_recommendations[job_id]',jobId)
        formData.append('person_recommendations[reason]',check)
      }
    }
    makeRequest(url, 'post', formData, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        if(res.data.type == "success"){
          setShow(false)
          setTimeout(()=>{
            window.location.reload()
          },3000)
        }
      },
    })
  }

  const handleCheckboxChange = (id) => {
    if(multiIntrestedCandidates?.includes(id))
    {
      setMultiIntrestedCandidates([...multiIntrestedCandidates.filter((row)=> row != id)])
    }
    else{
      setMultiIntrestedCandidates([...multiIntrestedCandidates, id])
    }
    
  }

  const handleAction = (event) => {
    setSelectedAction(event.value)
  }

  const handleActionFilter = (event) => {
    setSelectedFilter(event.value)
  }

  const handleIntrested = (candidate) => {
    var doc = document.getElementsByClassName('form-check-input')
    for (let i = 0; i < doc.length; i++) {
      if(doc[i].checked)
        doc[i].click()
    }
    setSingleIntrestedCandidates(candidate)
    setMultiIntrestedCandidates([])
    setShow(true)
  }

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll)
    setMultiIntrestedCandidates(candidates.map(li => li.id))
    if (isCheckAll) {
      setMultiIntrestedCandidates([])
    }
  }

  const handleCheck = (event) => {
    var updatedList = [...check]
    if (event.target.checked) {
      updatedList = [...check, event.target.value]
    } else {
      updatedList.splice(check.indexOf(event.target.value), 1)
    }
    setCheck(updatedList)
  }

  const onHide = () => {
    setShow(false)
  }

  const closeFun = () => {
    setShowNotMatch(false)
    setCheck([])
  }

  useEffect(()=>{
    fetchData()
  },[category,activePage, selectedAction, selectedFilter])

  return (
    <StoreDispatchContext.Provider value={{ state, dispatch }}>
    <>
    <Modal
          onHide={() => setShowNotMatch(false)}
          centered
          show={showNotMatch}
          aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize: "26px"}}>Please Provide us a Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkList.map((item, index) => (
            <div key={index}>
            <input value={item} type="checkbox" onChange={handleCheck} />
            <span style={{fontSize: "20px"}}>&nbsp; {item} <br /></span>
          </div>
          ))}
          {check.includes('Other') && 
          <textarea name="Text1" cols="40" rows="3" placeholder='Type your reason here...' onChange={(e)=> setMessage(e.target.value)}></textarea>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeFun}>
            Close
          </Button>
          <Button variant="primary" onClick={saveFeedback}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    <Container className="p-0" fluid>
      <label
        className={styles.mainTitle + ' page-title'}
        style={{ marginBottom: '2rem' }}
      >
        <span>Recommended Candidates for <b style={{color: 'green'}}>{job_name}</b> at <b style={{color: 'green'}}>{organization_name}</b></span>
    </label>
   </Container>
    <Card>
    <Row className="w-100" style={{ marginBottom: '15px', display: 'flex', alignItems: 'end', }}>
      <Col xs={12} sm={12} lg={4} style={{ float: 'left' }}>
        <div
          className="d-flex justify-content-between align-items-end w-100"
          style={{ marginBottom: '15px' }}
          >
          <Form.Check
            className={`${styles.candidateCheckbox} check_${multiIntrestedCandidates}`}
            type="checkbox"
            value="selectAll"
            name="selectAll"
            checked={isCheckAll}
            onChange={handleSelectAll}
            onClick={(event) => event.stopPropagation()}
          />
          <label className={styles.labelHeader} style= {{position: 'absolute', left: '36px', marginBottom: '0px'}}>
            Select All
          </label>
        </div>
      </Col>
      <Col xs={12} sm={12} lg={2} style={{ justifyContent: 'center' }}>
        <div
          className="d-flex justify-content-center align-items-end w-100"
          style={{ marginBottom: '15px' }}
          >
            <span className={styles.candidatecount}>
              Displaying{' '}
              {Util.displayNumberOfResults(
                totalCount,
                pageCount,
                activePage,
                25,
                totalCount >= 10000 ? `10000+` : totalCount,
                true,
                true
                )}
            </span>
        </div>
      </Col>
      <Col className='mr-0' xs={12} sm={12} lg={2} style={{ float: 'right' }}>
        <Button
          style={{ display: 'grid', marginLeft: '10px', width: '70%', marginLeft: 'Auto' }}
          variant='primary'
          className={styles.button}
          onClick={event =>  window.location.href= `/jobs/${category}/${job_name}`}
          >
          View Job
        </Button>
      </Col>
      <Col className='mr-0' xs={12} sm={12} lg={2} style={{ float: 'right' }}>
        <div style={{ display: 'grid', marginLeft: '10px', width: '100%', marginLeft: 'Auto' }}>
          <label>
          Filter
          </label>
          <Select
          defaultValue={defaultActionselectedFilter}
          options={filterLists}
          onChange={(event) => handleActionFilter(event)}
          name="select"
          className={styles.colourStyles}
          />
        </div>
        </Col>
        <Col className='mr-0' xs={12} sm={12} lg={2} style={{ float: 'right' }}>
        <div style={{ display: 'grid', marginLeft: '10px', width: '100%', marginLeft: 'Auto' }}>
          <label>
          Sort By
          </label>
          <Select
          defaultValue={defaultActionselected}
          options={actionLists}
          onChange={(event) => handleAction(event)}
          name="select"
          className={styles.colourStyles}
          />
        </div>
      </Col>
    </Row>
      <div
        className="d-flex justify-content-end align-items-center w-100"
        style={{ marginBottom: '15px' }}
      >
        {multiIntrestedCandidates.length > 0 && (
            <>
            <Button
              variant='primary'
              className={styles.button}
              onClick={()=>setShow(true)}
            >
              Move to ATS
            </Button>
            <div className="img-block">
                <img
                    style={{width: '50px', cursor: 'pointer'}}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Mark not match"
                    src={notmatch}
                    alt=""
                    onClick={()=>setShowNotMatch(true)}
                />
            </div>
            </>
        )}
      </div>
      <CandidateInfo
        loading={loading}
        candidates={candidates}
        handleIntrested={handleIntrested}
        multiSelectCheckbox={handleCheckboxChange}
        multiIntrestedCandidates={multiIntrestedCandidates}
        organizationname={organization_name}
        memberOrganization={memberOrganization}
        organization={organization}
        showNotMatch={showNotMatch}
      />
      {pageCount > 1 && (
          <div
              className="d-flex justify-content-center"
              style={{ marginTop: 'auto' }}
          >
              <Paginator
                  activePage={activePage}
                  setActivePage={setActivePage}
                  pageCount={pageCount}
                  pageWindowSize={5}
                  showGoToField={false}
              />
          </div>
      )}
      <CandidateInfoPanel
                    user = {currentUser}
                    candidate={state.displayedCandidate}
                    closeFunc={  
                      () => {
                        dispatch({ type: 'hide_candidate' })
                            }
                    }        
                    // handleScheduleButtonClick={() => setShowScheduleModal(true)}
                    // showUploadCandidatePanel={showUploadCandidatePanel}
                    memberOrganization={memberOrganization}
                    page={page}
                    setPage={setPage}
                    pageCount={pageCount}
                    jobId={jobId}
                    candidates={candidates}
                    // setApiCallFrom={setApiCallFrom}
                    // currentOrganization={currentOrganization}
                    // reloadCandidateData={reloadCandidateData}
                    // setReloadCandidateData={setReloadCandidateData}
                    allowCandidateUpload={'weekly_recommendation'}
                    // recruitersList={ctRecruitersList || []}
                    // jobStatus={fullJob?.status}
                /> 
    </Card>
    <SweetAlert
     warning
     show={show}
     showCancel
     confirmBtnText={`Submit Candidate(s)`}
     confirmBtnBsStyle="warning"
     title="Are you sure?"
     onConfirm={()=>saveIntrestedPeople()}
     onCancel={()=>onHide()}
     focusCancelBtn
     cancelBtnStyle={{textDecoration: 'none'}}
    />
    </>
    </StoreDispatchContext.Provider>
  )
}

export default WeeklyRecommendationPage;