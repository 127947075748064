import React, { Fragment, useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Alert from 'react-bootstrap/Alert'

import styles from './styles/AccountSettingPage.module.scss'
import { capitalize } from '../../../utils'
import Input from '../../common/Styled components/Input'
import {
    W3text,
    Row,
    Typography,
    Wrapper,
} from './styles/AccountSettingPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { passwordSchema } from '../../common/ValidationSchema/PasswordSchema'
import axios from 'axios'

const passwordValidationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    from_email: Yup.string().required('Email is required'),
    host: Yup.string().required('Host is required'),
    port: Yup.string().required('Port is required'),
    user_email: Yup.string().required('Username/Email is required'),
    password: Yup.string().required('Password is required'),
})
const EmailConfigurationPage = (props) => {
    const [error, setError] = useState(false)
    const { currentUser, email_data } = props
    const { is_cm } = props
    const { first_name, last_name, id } = currentUser
   

    const handleReset = async (data) => {
        const { name, from_email, host, port, user_email,password  } = data
        const payload = new FormData()
        const url = '/account/add_email_configuration'
        payload.append('email_configuration[name]', name)
        payload.append('email_configuration[from_email]', from_email)
        payload.append('email_configuration[host]', host)
        payload.append('email_configuration[port]', port)
        payload.append('email_configuration[user_email]', user_email)
        payload.append('email_configuration[password]', password)
        payload.append('email_configuration[user_id]', id)

        await makeRequest(url, 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            onSuccess: (res) => {
                    window.location.reload()
                    setError(false)
            },
        })
    }

    const handleUpdate = async (data) => {
        const { name, from_email, host, port, user_email,password  } = data
        const payload = new FormData()
        const url = '/account/update_email_configuration'
        payload.append('email_configuration[name]', name)
        payload.append('email_configuration[id]', email_data?.id)
        payload.append('email_configuration[from_email]', from_email)
        payload.append('email_configuration[host]', host)
        payload.append('email_configuration[port]', port)
        payload.append('email_configuration[user_email]', user_email)
        payload.append('email_configuration[password]', password)
        payload.append('email_configuration[user_id]', id)

        await makeRequest(url, 'put', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            onSuccess: (res) => {
                    window.location.reload()
                    setError(false)
            },
        })
    }

    // TODO
    // Need a Nav component for account setting page nav
    
    return (
        <div className={styles.accountSettingPage}>
            <Wrapper className={`${styles.accontSetting}`}>
                <div className={`${styles.sidebar}`}>
                    <div className={`${styles.sidebar_header}`}>
                        <p>{capitalize(`${first_name} ${last_name}`)}</p>
                    </div>
                    <a href="/account/setting">General Settings</a>
                    <a href="/account/security">
                        Security & Login
                    </a>
                    <a href="/account/email_verification">Email Verification</a>
                    { (currentUser.role == 'recruiter') &&
                        <>
                            <a  className={`${styles.active}`} href="/account/email_configuration">SMTP Email Configuration</a>
                        </>
                    }
                    { (is_cm > 0) && 
                        <a href="/account/mixmax_configuration">Mixmax Configuration</a>
                    }
                    { (currentUser.role == 'recruiter') && 
                             <a href="/account/organization_management">Organization Membership Management</a>
                    }
                    {
                     (currentUser.role == 'employer') && 
                        <a href="/account/organization_agreement">Organization Agreement</a>
                    }
                  <a href="/account/invite_your_friend">Invite Your Friend</a>
                  {currentUser.role == 'talent' &&
                  <a  className={`${styles.active}`} href="/account/email_configuration">SMTP Email Configuration</a>
                  }
                </div>
              <div className={`${styles.containt}`} style={{ paddingBottom: '12rem' }}>
                    <h3>Email Configuration</h3>
                    <div className={`${styles.basicinfo}`}>
                            <Formik
                                initialValues={{
                                    name: email_data?.name || '',
                                    from_email: email_data?.from_email || '',
                                    host: email_data?.host || 'smtp.gmail.com',
                                    port: email_data?.port || '587',
                                    user_email: email_data?.user_email || '',
                                    password: email_data?.password || '',

                                }}
                                validationSchema={passwordValidationSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        setSubmitting(false)
                                    }, 500)
                                    if(email_data){
                                        handleUpdate(values)
                                    }else{
                                    handleReset(values)
                                    }
                                }}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        isSubmitting,
                                        handleChange,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Fragment>
                                            <div
                                                className={`${styles.basicinfodetail}`}
                                            >
                                                <Row>
                                                    <p>
                                                        <label>
                                                           From Name
                                                        </label>
                                                    </p>
                                                    <Input
                                                        label="no-label"
                                                        value={
                                                            values.name
                                                        }
                                                        name="name"
                                                        type="text"
                                                        onChange={handleChange}
                                                        width="20rem"
                                                        error={
                                                            Boolean(
                                                                errors.name
                                                            ) &&
                                                            touched.name
                                                        }
                                                        placeholder="Enter Name"
                                                    />
                                                    {errors.name &&
                                                        touched.name && (
                                                            <Typography>
                                                                {
                                                                    errors.name
                                                                }
                                                            </Typography>
                                                        )}
                                                </Row>
                                                <Row>
                                                    <p>
                                                        <label>
                                                           From Email
                                                        </label>{' '}
                                                    </p>
                                                    <Input
                                                        label="no-label"
                                                        value={
                                                            values.from_email
                                                        }
                                                        name="from_email"
                                                        type="email"
                                                        onChange={handleChange}
                                                        width="20rem"
                                                        error={
                                                            Boolean(
                                                                errors.from_email
                                                            ) &&
                                                            touched.from_email
                                                        }
                                                        placeholder="Enter Email"
                                                    />

                                                    {errors.from_email &&
                                                        touched.from_email && (
                                                            <Typography>
                                                                {
                                                                    errors.from_email
                                                                }
                                                            </Typography>
                                                        )}
                                                </Row>
                                                <Row>
                                                    <p>
                                                        <label>
                                                            Email Host
                                                        </label>{' '}
                                                    </p>
                                                    <Input
                                                        label="no-label"
                                                        value={
                                                            values.host
                                                        }
                                                        name="host"
                                                        type="text"
                                                        onChange={handleChange}
                                                        width="20rem"
                                                        error={
                                                            Boolean(
                                                                errors.host
                                                            ) &&
                                                            touched.host
                                                        }
                                                        placeholder="Enter Host"
                                                    />

                                                    {errors.host &&
                                                        touched.host && (
                                                            <Typography>
                                                                {
                                                                    errors.host
                                                                }
                                                            </Typography>
                                                        )}
                                                </Row>
                                                <Row>
                                                    <p>
                                                        <label>
                                                            Email Port
                                                        </label>{' '}
                                                    </p>
                                                    <Input
                                                        label="no-label"
                                                        value={
                                                            values.port
                                                        }
                                                        name="port"
                                                        type="number"
                                                        onChange={handleChange}
                                                        width="20rem"
                                                        error={
                                                            Boolean(
                                                                errors.port
                                                            ) &&
                                                            touched.port
                                                        }
                                                        placeholder="Enter Port"
                                                    />

                                                    {errors.port &&
                                                        touched.port && (
                                                            <Typography>
                                                                {
                                                                    errors.port
                                                                }
                                                            </Typography>
                                                        )}
                                                </Row>
                                                <Row>
                                                    <p>
                                                        <label>
                                                         Username/Email
                                                        </label>{' '}
                                                    </p>
                                                    <Input
                                                        label="no-label"
                                                        value={
                                                            values.user_email
                                                        }
                                                        name="user_email"
                                                        type="text"
                                                        onChange={handleChange}
                                                        width="20rem"
                                                        error={
                                                            Boolean(
                                                                errors.user_email
                                                            ) &&
                                                            touched.user_email
                                                        }
                                                        placeholder="Enter Username/Email"
                                                    />

                                                    {errors.user_email &&
                                                        touched.user_email && (
                                                            <Typography>
                                                                {
                                                                    errors.user_email
                                                                }
                                                            </Typography>
                                                        )}
                                                </Row>
                                                <Row>
                                                    <p>
                                                        <label>
                                                             Password
                                                        </label>{' '}
                                                    </p>
                                                    <Input
                                                        label="no-label"
                                                        value={
                                                            values.password
                                                        }
                                                        name="password"
                                                        type="password"
                                                        onChange={handleChange}
                                                        width="20rem"
                                                        error={
                                                            Boolean(
                                                                errors.password
                                                            ) &&
                                                            touched.password
                                                        }
                                                        placeholder="Enter Password"
                                                    />

                                                    {errors.password &&
                                                        touched.password && (
                                                            <Typography>
                                                                {
                                                                    errors.password
                                                                }
                                                            </Typography>
                                                        )}
                                                </Row>
                                            </div>
                                            <button
                                            type="submit"
                                                className={`${styles.editButton}`}
                                                onClick={handleSubmit}
                                            >
                                              {email_data ? 'Update' : 'Submit'}
                                            </button>
                                        </Fragment>
                                    )
                                }}
                            </Formik>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}
export default EmailConfigurationPage
