import React, { Fragment, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import feather from 'feather-icons';
import AsyncCreatableSelect from 'react-select/async-creatable';

import Expander from '../Expander/Expander';
import FilterGroup from '../FilterGroup/FilterGroup';
import { colourStyles } from './styles/FilterJob.styles';
import FilterBy from './FilterBy';
import { getCompany, getIndustry, getLocation, getPosition } from '../../api/filter/filter.activeJob.api';

const initialState = [
  { key: 'companyNames', label: 'Company' },
  { key: 'position', label: 'Position' },
  { key: 'locations', label: 'Location' },
  { key: 'industry', label: 'Industry' },
];

const FilterActiveJob = ({
  filterStack,
  setStackFilter,
  handleSearch,
  setActivePage = () => {},
  handleReset,
}) => {
  useEffect(() => {
    feather.replace();
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setActivePage(0);
    handleSearch();
  };

  const handleResetFilters = () => {
    if (handleReset && typeof handleReset === 'function') {
      setStackFilter({
          companyNames: [],
          locations:[],
          position:[],
          industry :[],
            });
      handleReset();
    } else {
      resetFilterStack();
    }
  };

  const resetFilterStack = () => {
    window.location.replace(`/search_employer`);
  };

  const handleFilter = (event, key) => {
    setStackFilter({ ...filterStack, [key]: event });
  };

  const handleKeyPress = async (inputValue, callback, key) => {
    let arrayOptions = [];

    if (key === 'companyNames') {
      arrayOptions = await getCompany(inputValue);
    }
    if (key === 'position') {
      arrayOptions = await getPosition(inputValue);
    }
    if (key === 'locations') {
      arrayOptions = await getLocation(inputValue);
    }
    if (key === 'industry') {
        arrayOptions = await getIndustry(inputValue);
    }
    callback(arrayOptions);
  };

  const handleCreate = (event, key) => {
    const newItem = { value: event, label: event };
    const cloneObject = filterStack[key];
    cloneObject.push(newItem);
    setStackFilter({ ...filterStack, [key]: [...cloneObject] });
  };

  function activeFilterCount(filters) {
    return Object.keys(filters)
      .map((key) => {
        if (Array.isArray(filterStack[key]))
          return filterStack[key].length > 0 ? 1 : 0;
        else if (typeof filterStack[key] === 'boolean')
          return filterStack[key] ? 1 : 0;
        else return 0;
      })
      .reduce((acc, current) => acc + current, 0);
  }

  return (
    <FilterGroup
      filterCount={activeFilterCount(filterStack)}
      experienceYearsCount={filterStack.experienceYears}
    >
      {initialState.map(({ key, label }) => (
        <Fragment key={key}>
          <Expander
            label={label}
            expanded={filterStack[key] && filterStack[key].length > 0}
          >
              <AsyncCreatableSelect
                isMulti
                createOptionPosition="first"
                styles={colourStyles}
                value={filterStack[key]}
                onChange={(event) => handleFilter(event, key)}
                loadOptions={(inputValue, callback) =>
                  handleKeyPress(inputValue, callback, key)
                }
                menuPlacement="auto"
                fmaxMenuHeight={30}
                onCreateOption={(event) => {
                  handleCreate(event, key);
                }}
                menuPortalTarget={document.querySelector('body')}
              />
          </Expander>
        </Fragment>
      ))}

      {/* <Button className={'filterBtn'} onClick={handleSubmit}>
        Filter
      </Button> */}
      <Button className={'filterBtn ml-2'} onClick={handleResetFilters}>
        Clear Filter
      </Button>
    </FilterGroup>
  );
};

export default FilterActiveJob;
