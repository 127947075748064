import React, { useState } from 'react'
import { useField , Formik, Field ,Form as FormSubmit } from 'formik'
import * as Yup from 'yup'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col, Form} from 'react-bootstrap'
import './styles/RecruiterHomePageModule.scss'
import PaulImage from '../../../../assets/images/on_demand_recruiter/paul-campbell.svg'
import PeterImage from '../../../../assets/images/on_demand_recruiter/peter-anthony.svg'
import MapIcon from '../../../../assets/images/recruiter_home_icons/icon-map.svg'
import CalendarIcon from '../../../../assets/images/recruiter_home_icons/icon-calendar.svg'
import LocationIcon from '../../../../assets/images/recruiter_home_icons/icon-location.svg'

const top_recruiter_list = [
    {id:1, name:"Paul Campbell", image:PaulImage},
    {id:2, name:"Peter Anthony", image:PeterImage},
    {id:3, name:"Paul Campbell", image:PaulImage},
    {id:4, name:"Peter Anthony", image:PeterImage}
];

const RecruiterHomePage = ({currentUser}) => {

    const [token, setToken] = useState('')
    const handleContactUs = (data,resetForm) => {
        let URL = '/recruiter/sendContact'
        let method = 'post'
        const payload = new FormData()
        payload.append('contact[first_name]', data.first_name)
        payload.append('contact[last_name]', data.last_name)
        payload.append('contact[email]',data.email)
        payload.append('contact[phone_number]',data.phone_number)
        payload.append('contact[message]',data?.message)
        payload.append('contact[token]',token)
        makeRequest(URL,'post',payload , {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: () => {
                return {
                    message: `Contact Request Send Successfully.`,
                    messageType: 'success',
                    loading: false,
                    autoClose: true,
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: () => {
                window.grecaptcha.reset();
                resetForm();
                //window.location.href = '/cardinal/employees'
            },
        })
    }
    
    return (
        <section className='talent-section'>
            <article className="talent-list">
                <h2 className="talent-title">Hire Freelance AWS Developers and Engineers</h2>
                {top_recruiter_list.map((data) => {
                    return (
                        <div className="talent-listing">
                            <div className="talent-card talent-card-border " style={{display: 'flex'}}>
                                <div className="talent-card-left">
                                    <a href="#" itemProp="url">
                                        <div className="talent-profile-img"><span>View Full Profile</span></div>
                                        <img src={data.image} className="" />
                                    </a>
                                    <a href="#" className="talent-button talent-button-ui">View {data.name}</a>
                                </div>
                                <div className="talent-card-right">
                                    <div>
                                        <h3 itemProp="name"><a href="#" className="talent-name ">{data.name}</a></h3>
                                        <p className="talent-position">Freelance AWS Developer</p>
                                        <div className="talent-details">
                                            <span className=""><img src={MapIcon} style={{height: '20px',width:'20px',marginRight: '10px'}}/> Armenia</span>
                                            <span className=""><img src={CalendarIcon} style={{height: '18px',width:'18px',marginRight: '10px'}}/> 
                                            Toptal Member{/* */} Since {/* */}November 12, 2018
                                            </span>
                                        </div>
                                        <p className="talent-desc" itemProp="description">Sergey is a hardworking and detail oriented full-stack web architect with 12 years experience. He is as comfortable working with both startups and enterprise solutions and focus on directed growth, technical solutions to reduce bottlenecks and creative strategy.</p>
                                        <a href="#" data-testid="read-more-link" className="talent_showmore">Show More</a>
                                    </div>
                                    <div className="">
                                        <div className="talent-skill">
                                            <div className="talent-skill-list">
                                                <a href="#" className="talent-skill-tag talent-tag-list">AWS</a>
                                                <a href="#" className="talent-skill-tag talent-tag-list">AWS</a>
                                                <a href="#" className="talent-skill-tag talent-tag-list">AWS</a>
                                                <a href="#" className="talent-skill-tag talent-tag-list">AWS</a>
                                                <a href="#" className="talent-skill-tag talent-tag-list">AWS</a>
                                                <a href="#" className="talent-skill-tag talent-tag-list">AWS</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                )}
            </article>
        </section>
    )
}
export default RecruiterHomePage
