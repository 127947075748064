import React, { useState, useEffect, useContext, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import styles from './styles/CandidateInfo.module.scss'
import isEmpty from 'lodash.isempty'
import Util from '../../../utils/util'
import { Card, Container, Col, Button, DropdownButton, Image, Row } from 'react-bootstrap'
import profileImage from '../../../../assets/images/icons/profile.png'
import Badge from "react-bootstrap/Badge";
import './styles/CandidateInfo.scss'
import SubmittedIcon from '../../../../assets/images/icons/submitted.png'
import _ from 'lodash'
import LinkedinIcon from '../../../../assets/images/icons/linkedin.png'
import PdfIcon from '../../../../assets/images/icons/pdf.png'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import moment from 'moment'

const MAX_SELECTION_LIMIT = 25

function CandidateInfo({
  loading,
  candidates,
  handleIntrested,
  multiSelectCheckbox,
  multiIntrestedCandidates
}) {

  const [submissionData, setSubmissionData] = useState({ active: false, id: null })
  function activeSubmission(candidateId) {
    setSubmissionData({ active: true, id: candidateId })
  }

  if (loading) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>Loading....</h2>
        <div id="loading" />
      </div>
    </>
    )
  }
  if (isEmpty(candidates)) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2>No candidate found....</h2>
      </div>
    </>
    )
  }
  const handleJobFullName = (name) => {
    return name.length < 45 ? name : name.slice(0, 42) + '...'
  }
  const candidateClickHandler = (candidate) => {
    console.log(candidate)
  }

  return (
    <>
      <Table className={styles.candidateTable} responsive hover>
        <tbody >
          {Array.isArray(candidates) &&
            candidates.map((candidate, index) => {
              return (
                <CandidateRow
                  candidate={candidate}
                  idx={candidate.id}
                  key={index}
                  clickHandler={candidateClickHandler}
                  setSubmissionData={setSubmissionData}
                  submissionData={submissionData}
                  activeSubmission={activeSubmission}
                  handleIntrested={handleIntrested}
                  multiSelectCheckbox={multiSelectCheckbox}
                  multiIntrestedCandidates={multiIntrestedCandidates}
                />
              )
            })
          }
        </tbody>
      </Table>
    </>
  )
}

function CandidateRow({ candidate, idx, clickHandler, setSubmissionData, submissionData, activeSubmission, handleIntrested, multiSelectCheckbox,multiIntrestedCandidates }) {
  const checked = multiIntrestedCandidates.length > 0 ? multiIntrestedCandidates.includes(idx) : false
  const [visible, setVisible] = useState(6)
  const [bulkSelect, setBulkSelect] = useState(checked)
  const [candidateId, setCandidateId] = useState([])
  const loadMore = () => {
    setVisible(visible + 6)
  }
  const loadLess = () => {
    setVisible(6)
  }
  function getSelectCell(candidate, col) {
    return (
      <td
        key={'checkbox' + candidate.id}
        style={{ width: '1rem' }}
        className={styles.td + ' align-middle'}
      >
        <Form.Check
          className={`${styles.candidateCheckbox} check_${candidate.id}`}
          type="checkbox"
          value={candidate.candidate_name}
          name={candidate.candidate_name}
          checked={bulkSelect}
          onChange={handleCheckboxChange}
          onClick={(event) => event.stopPropagation()}
        />
      </td>
    )
  }

  const handleCheckboxChange = (event) => {
    setBulkSelect(!bulkSelect)
    multiSelectCheckbox(candidate.id)
  }

  return (
    <>
      <Card key={candidate.id} className={styles.candidateProfileCard} >
        <Card.Body className='candidate-list'>
          {getSelectCell(candidate)}
          <div style={{ margin: '-48px 0 0 35px' }} >
            <Row style={{ marginBottom: '11px',justifyContent: 'space-between'}} >
              <div className='d-flex'>
                {candidate?.image_url ?
                  <img className={styles.candidateProfileImage} src={candidate.image_url ? candidate.image_url : profileImage} onClick={(e) => clickHandler(candidate)} />
                  : <CandidateAvatarIcon clickHandler={clickHandler} candidate={candidate} firstName={candidate?.first_name} lastName={candidate?.last_name} />
                }
                <div className={styles.cardBody + 'container'} >
                  <div className='d-flex justify-content-space-between w-100'>
                    <h5 className={styles.cardBodyName}>
                      <span>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</span>
                      {candidate.linkedin_profile_url &&
                        <a href={candidate.linkedin_profile_url} target="_blank">
                          <Image
                            src={LinkedinIcon}
                            style={{
                              width: '16px',
                              height: '16px',
                              marginLeft: '15px',
                              marginTop: '-1px'
                            }}
                          />
                        </a>
                      }
                      {candidate.resume && 
                        (
                          <a href={candidate.resume} target="_blank">
                            <Image
                                src={PdfIcon}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '5px',
                                    marginTop: '-1px'
                                }}
                            />
                          </a>)}
                    </h5>
                  </div>
                  <h5 style={{ fontSize: '16px' }} onClick={(e) => clickHandler(candidate)}>{candidate?.title}</h5>
                </div>
              </div>
              <div>
                <Button
                  variant='primary'
                  className={styles.button}
                  onClick={()=>handleIntrested(candidate)}
                >
                  Submit
                </Button>
              </div>
            </Row>
            {candidate?.skills &&
              <div className='container row flex-nowrap'>
                <div>
                  <h6 className={styles.skillTitle}>Skills</h6>
                </div>
                <div className={styles.badge}>
                  {candidate?.skills?.split(',').slice(0, visible).map((skill, index) => skill?.trim() && canidateSkills(skill, index))}
                  {visible < candidate?.skills?.split(',').length && (
                    <Badge
                      pill
                      variant="secondary"
                      className='skills-more-button  d-inline-block text-truncate'
                      onClick={loadMore}
                    >
                      <label>View More</label>
                    </Badge>
                  )}
                  {visible >= candidate?.skills?.split(',').length && candidate?.skills?.split(',').length > 6 && (
                    <Badge
                      pill
                      variant="secondary"
                      className='skills-more-button  d-inline-block text-truncate'
                      onClick={loadLess}
                    >
                      <label>View Less</label>
                    </Badge>
                  )}
                </div>
              </div>}
              <div className='container row w-100 flex-nowrap align-items-center'>
                <div style={{float: 'left'}}>
                  <p className={styles.submittedJob}>Submitted Jobs</p>
                  {candidate?.submissions?.length > 0 && candidate?.submissions?.map((application) => (
                    <ul style={{ marginBottom: '0' }}>
                      <li>
                        <div className='row contact_time'>
                          <b>{application?.job?.organization?.name}</b>  &nbsp; - &nbsp;
                          <a href={`/jobs/${application?.job?.id}/${application?.job?.name}`}>{application?.job.name}</a>  <span style={{ textTransform: "capitalize" }}><b style={{ marginLeft: "5px" }}> &nbsp; {application.submission_type != 'submitted' ? 'submitted to recruiter screened' : 'submitted on'}  &nbsp;</b> - &nbsp;{moment(application.created_at).format('MMM DD, YYYY')} </span>
                          <span style={{ textTransform: "capitalize" }}><b style={{ marginLeft: "5px" }}> &nbsp; Submitted By &nbsp;</b> - &nbsp;{`${application?.user?.first_name} ${application?.user?.last_name}`}  </span>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
          </div>
        </Card.Body>
      </Card>
      <br />
    </>
  )
}

function canidateSkills(skill, index) {
  return (
    <>
      <Badge
        pill
        key={index}
        variant="secondary"
        className="skills-button d-inline-block text-truncate"
      >
        <label className='candidate-skills'> {skill} </label>
      </Badge>{'   '}
    </>
  )
}

export default CandidateInfo
