import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from './shared/Card'
import Table from './shared/Table'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Button from './shared/Button'
import P from './shared/P'
import { BlockBody, BlockHeader, DisplayPagination } from './styles/AdminDashboard.styled'
import TableWrapper from './shared/TableWrapper'
import SearchBar from '../../common/SearchBar/SearchBar'
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import CreateProfilePage from '../CreateProfilePage/CreateProfilePage'
import Select from 'react-select'
import { Row,Col,Spinner } from 'react-bootstrap'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'
import feather from 'feather-icons'
import SweetAlert from 'react-bootstrap-sweetalert'
import AgreementUpload from './AgreementUpload'
import Modal from "react-bootstrap/Modal"

const OrganizationManagement = ({per_page}) => {
    var active_page = window.sessionStorage.getItem('org_activePage')
    var query = window.sessionStorage.getItem('org_query')
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [startRecord, setStartRecord] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [organizations, setOrganizations] = useState([])
    const [organizationRequests, setOrganizationRequests] = useState([])
    const [companySizes, setCompanySizes] = useState([])
    const [showOrganizationRequests, setShowOrganizationRequests] = useState(false)
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [todayPendingCount,setTodayPendingCount] = useState(0)
    const [pendingCount,setPendingCount] = useState(0)
    const [regionsCount,SetRegionsCount] = useState([])
    const [industriesCount,setIndustriesCount] = useState()
    const [totalOrganization,setTotalOrganization] = useState()
    const [totalApproved,setTotalApproved] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [status,setStatus] = useState('')
    const [orgCount,setOrgCount] = useState(0)
    const [showOrgCreatePage, setShowOrgCreatePage] = useState(false)
    const [orgRole, setOrgRole] = useState({role: 'employer'})
    const [loading, setLoading] = useState(true)
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]
    const [expiredCount,setExpiredCount] = useState(0)
    const [rejectCount,setRejectCount] = useState(0)
    const [selectedStatus,setSelectedStatus] = useState({value: '', label: 'All'})
    const [sortField, setSortField] = useState('organizations.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(per_page)
    const [organizationObject,setOrganizationObject] = useState(false)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showAgreementUpload, setShowAgreementUpload] = useState(false)

    useEffect(() => {
        feather.replace()
    })

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const statusOptions = [
        {value: '', label: 'All'},
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'declined', label: 'Reject' },
        { value: 'expired', label: 'Expired' }
    ]

    const fetchData = () => {
        setLoading(true)
        setOrganizations([])
        setPageCount(0)
        window.sessionStorage.setItem('org_activePage',activePage)
        const url = `/admin/organizations.json?page=${activePage}&search=${searchTerm}&status=${status}&sortField=${sortField}&sortDirection=${sortDirection}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setTotalOrganization(res.data.total_count)
                let sr_no = (activePage - 1) * res.data.per_page
                setOrganizations(
                    res.data.organizations.map((org) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        id: org.id,
                        name: capitalize(org.name),
                        industry: org.industry,
                        company_size: org.company_size,
                        owner: org.owner.full_name,
                        account_manager: org.account_manager.full_name,
                        created_at: moment(org.created_at).format('MMM DD, YYYY'),
                        status: capitalize((org.status ==='declined') ? 'rejected': org.status),
                        member_organization: !org?.member_organization_id ? '': 'CardinalTalent',
                        active_jobs: (<a onClick={()=>{
                            window.localStorage.setItem('status','active')
                            window.localStorage.setItem('organization',JSON.stringify({'value':org.id, 'label':org.name}))}
                        } href={`/admin/jobs`}>{org.active_jobs}</a>),
                        closed_jobs: (<a onClick={()=>{
                            window.localStorage.setItem('status','closed')
                            window.localStorage.setItem('organization',JSON.stringify({'value':org.id, 'label':org.name}))
                        }
                        } href={`/admin/jobs`}>{org.closed_jobs}</a>),
                        expired_job: (<a onClick={()=>{
                            window.localStorage.setItem('status','expired')
                            window.localStorage.setItem('organization',JSON.stringify({'value':org.id, 'label':org.name}))
                        }
                        } href={`/admin/jobs`}>{org.expired_job}</a>),
                        organization_type: capitalize((org.organization_type === 'N/A' ) ? '-': org.organization_type),
                        actions: (<div className='w-100 d-inline-flex justify-content-around'>
                                    <a  href={`/admin/organizations/${org.id}`} className='pr-2'><i data-feather="edit-2" /></a>
                                    <a  onClick={() =>showConfirmDelete(org)}  href={`javascript::void()`} className='pr-2'><i data-feather="trash-2" /></a>
                                    <a href={`/admin/organizations/organization_agreement/${org.id}`} className='pr-2'><i data-feather="file-text" /></a>
                                    <a onClick={() =>showUploadForm(org)} href={`javascript::void()`} className='pr-2' style={{color: org.agreement != null ? '#007bff': 'red'}}><i data-feather="upload" /></a>
                                </div>)
                    }))
                )
                setPageCount(res.data.meta.total_pages)
                setTodayPendingCount(res.data.today_pending_organization)
                setPendingCount(res.data.meta.pending)
                SetRegionsCount(res.data.meta.region_count)
                setIndustriesCount(res.data.meta.industry_count)
                setTotalApproved(res.data.meta.approved)
                setCurrentCounts(res.data.current_counts)
                setOrgCount(res.data.meta.org_count)
                setStartRecord((activePage - 1) * res.data.per_page)
                setExpiredCount(res.data.meta.expired)
                setRejectCount(res.data.meta.declined)
                setLoading(false)
            },
        })
    }

    const fetchOrganizationRequests = () => {
        setLoading(true)
        window.sessionStorage.setItem('org_activePage',activePage)
        const url = `/admin/organizations/pending.json?page=${activePage}&search=${searchTerm}`
        makeRequest(url, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setOrganizationRequests( 
                res.data.organizations.map((org) => ({
                    id: org.id,
                    name: capitalize(org.name),
                    industry: org.industry,
                    company_size: org.company_size,
                    owner: capitalize(org.owner_first_name)+' '+capitalize(org.owner_last_name),
                    created_at: org.created_at,
                    member_organization: !org?.member_organization_id ? '': 'CardinalTalent',
                    status: capitalize(org.status),
                }))
              )
                setPageCount(res.data.total_pages)
                setCurrentCounts(res.data.current_counts)
                setLoading(false)
            },
        })
    }

    const fetchAppropriateData = () => {
        if (showOrganizationRequests) {
            fetchOrganizationRequests()
        } else {
            fetchData()
        }
    }

    useEffect(() => {
        fetchAppropriateData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage, showOrganizationRequests,status,sortDirection,sortField])

    const deleteOrganization = async () => {
        setShowRemoveConfirmation(false)
        const url = `/admin/organizations/${organizationObject.id}`
        await makeRequest(url, 'delete', '', {
            createSuccessMessage: () => 'Organization deleted successfully',
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: () => {
                setShowRemoveConfirmation(false)
                fetchData()
            },
        })
    }

    const saveOrganization = async (orgData) => {
        if (orgData.data) {
            const formData = new FormData()
            for (var key in orgData.data) {
                formData.append(`organization[${key}]`, orgData.data[key])
            }

            const url = `/admin/organizations/${orgData.data.id}.json`
            await makeRequest(url, 'put', formData, {
                createSuccessMessage: () => 'Organization updated successfully',
                onSuccess: () => {
                    fetchData()
                },
            })
        }
    }

    const approveOrganization = async ({ data }) => {
        const url = `/admin/organizations/${data.id}/approve`
        await makeRequest(url, 'put', '', {
            createSuccessMessage: () => 'Organization approved successfully',
            onSuccess: () => {
                // fetchData()
                switchToOrganizations()
            },
        })
       
    }

    const rejectOrganization = async (id) => {
        const url = `/admin/organizations/${id}/reject`
        await makeRequest(url, 'put', '', {
            createSuccessMessage: () => 'Organization rejected successfully',
            onSuccess: () => {
                // fetchData()
                switchToOrganizations()
            },
        })
    }

    const switchToOrganizationRequests = () => {
        setShowOrganizationRequests(true)
        setActivePage(1)
    }

    const switchToOrganizationCreateRequests = () => {
        setShowOrgCreatePage(true)
        // setActivePage(0)
    }

    const switchToOrganizations = () => {
        setShowOrganizationRequests(false)
        setActivePage(1)
        // fetchData()
    }

    const approve = ({ data }) => {
        saveOrganization({ data: { id: data.id, status: 'approved' } })
        fetchOrganizationRequests()
        switchToOrganizations()
    }

    const reject = (id) => {
        saveOrganization({ data: { id: id, status: 'declined' } })
        switchToOrganizations()
    }

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value)
        window.sessionStorage.setItem('org_query', value)
    }

    const handleSelectStatus = (selectedval) =>{
        setActivePage(1)
        setStatus(selectedval.value)
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#F6F7FC',marginLeft:'10px',border: 'none',height:'40px',width: '230px' })
    }

    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '4%',
            minWidth: '4%',
        },
        {
            name: 'Name',
            selector: row => row.name,
            cell: (row) => <div title={row.name}>{row.name}</div>,
            wrap:true,
            maxWidth: '15%',
            minWidth: '15%',
            sortable: true,
            sortField: 'organizations.name',
        },
        {
            name: 'Owner Name',
            selector: row => row.owner,
            cell: (row) => <div title={row.owner}>{row.owner}</div>,
            wrap:true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'users.first_name',
        },
        {
            name: 'Account Manager Name',
            selector: row => row.account_manager,
            cell: (row) => <div title={row.account_manager}>{row.account_manager}</div>,
            wrap:true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'users.first_name',
        },
        {
            name: 'Active Jobs',
            selector: row => row.active_jobs,
            maxWidth: '6%',
            minWidth: '6%',
        },
        {
            name: 'Closed Jobs',
            selector: row => row.closed_jobs,
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Expired Jobs',
            selector: row => row.expired_job,
            maxWidth: '6%',
            minWidth: '6%'
        },
        {
            name: 'Created On',
            selector: row => row.created_at,
            cell: (row) => <div title={row.created_at}>{row.created_at}</div>,
            maxWidth: '8%',
            minWidth: '8%',
            sortable: true,
            sortField: 'organizations.created_at',
        },
        {
            name: 'Member',
            selector: row => row.member_organization,
            cell: (row) => <div title={row.member_organization}>{row.member_organization}</div>,
            maxWidth: '8%',
            minWidth: '8%',
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: (row) => <div title={row.status}>{row.status}</div>,
            maxWidth: '8%',
            minWidth: '8%'
        },
        {
            name: 'Organization Type',
            selector: row => row.organization_type,
            cell: (row) => <div title={row.organization_type}>{row.organization_type}</div>,
            maxWidth: '8%',
            minWidth: '8%',
            sortable: true,
            sortField: 'organizations.organization_type',
        },
        {
            name: 'Actions',
            selector: row => row.actions,
            maxWidth: '11%',
            minWidth: '11%',
        },
    ]

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }

    const showConfirmDelete = (org) => {
        setOrganizationObject(org)
        setShowRemoveConfirmation(true)
    }

    const showUploadForm = (org) => {
        setOrganizationObject(org)
        setShowAgreementUpload(true)
    }

    const onHide = () => {
        setShowAgreementUpload(false)
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '42px' }}
            >
                <P size="28px" height="38px" color="#1D2447">
                    Organization Management
                </P>
                <Button onClick={switchToOrganizationCreateRequests} className="ml-auto">
                    Create Organization
                </Button>
                <Button onClick={switchToOrganizationRequests} className="ml-3">
                    Organization Requests
                </Button>
            </div>

            { showOrgCreatePage ?
                (   <>
                        <CreateProfilePage 
                            user = {orgRole}
                            isProfileComplete = {false}
                            style = {"height: 100%; align-items: center; justify-content:center"}
                            showInfoPanel = {false}
                        />
                    </>
                ) : (
                <>
            <div style={{ marginTop: '50px', marginBottom:'50px', display: 'flex' }}>
                <div style={{ marginLeft: '50px' }}>
                    <BlockHeader>
                        <P size="16px" height="27px" color="#3F446E" style={{textAlign:'center'}}>
                            Organizations
                        </P>
                    </BlockHeader>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                            Total
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                            { totalApproved + pendingCount + rejectCount + expiredCount }
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                            Approved
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                           {totalApproved}
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                        Today
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                        {todayPendingCount}
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                        Pending
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                            {pendingCount}
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                        Reject
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                            {rejectCount}
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                        Expired
                        </P>
                    </BlockBody>
                    <BlockBody>
                        <P size="20px" height="14px" color="#3F446E">
                                {expiredCount}
                        </P>
                    </BlockBody>
                </div>
                <div style={{ marginLeft: '50px' }}>
                    {regionsCount &&
                        <>
                        <BlockHeader width={300} style={{marginLeft:'10%'}} marginBottom={-15}>
                            <P size="16px" height="27px" color="#3F446E">
                                Region Wise Organization
                            </P>    
                        </BlockHeader>
                        <PieChart width={380} height={380}>
                            <Pie
                                data={regionsCount}
                                labelLine={false}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {regionsCount.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip/>
                        </PieChart>
                        </>
                    }
                </div>
                <div style={{ marginLeft: '50px' }}>
                    {industriesCount &&
                        <>
                            <BlockHeader width={300} style={{marginLeft:'10%'}} marginBottom={-15}>
                                <P size="16px" height="27px" color="#3F446E">
                                Industry Wise Organization
                                </P>    
                            </BlockHeader>
                            <PieChart width={380} height={380}>
                                <Pie
                                data={industriesCount}
                                labelLine={false}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {industriesCount.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip/>
                            </PieChart>
                        </>
                    }
                </div>
            </div>

            <Row className="w-100" style={{ marginBottom: '10px' }}>
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex align-items-end'>
                    {  (!showOrganizationRequests) && totalOrganization > 0 ? 
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts > totalOrganization ? totalOrganization : currentCounts } of {totalOrganization}</DisplayPagination>
                    : ''
                    }
                </Col>
                <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
                
                    <Select
                        defaultValue={selectedStatus}
                        options={statusOptions}
                        onChange={handleSelectStatus} 
                        placeholder={'Select Status'} 
                        styles={colourStyles}
                    />
                    <SearchBar
                            placeholder="Search organizations..."
                            value={searchTerm}
                            onChange={(e) => onChangeSearchTerm(e.target.value)}
                            onEnterPressed={() =>
                                activePage == 1
                                    ? fetchAppropriateData()
                                    : setActivePage(1)
                            }
                            onButtonClick={() =>
                                activePage == 1
                                    ? fetchAppropriateData()
                                    : setActivePage(1)
                            }
                    />
                </Col>

              
            </Row>
            {showOrganizationRequests ? (
                <TableWrapper>
                    <div
                        className="d-flex justify-content-between align-items-center w-100"
                        style={{ marginBottom: '18px    ' }}
                    >
                        <P size="22px" height="30px" color="#1D2447">
                            Organization Requests
                        </P>
                        <Button
                            onClick={switchToOrganizations}
                            padding="1px 10px"
                            height="30px"
                            width="30px"
                            radius="15px"
                            background="#8599FF"
                        >
                            x
                        </Button>
                    </div>
                    <Table
                        columNames={[
                            {
                                name: 'No.',
                                field: 'id',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Name',
                                field: 'name',
                                editable: false,
                                type: 'text',

                            },{
                                name: 'Owner Name',
                                field: 'owner',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Requested On',
                                field: 'created_at',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Member',
                                field: 'member_organization',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Status',
                                field: 'status',
                                editable: false,
                                type: 'text',

                            },
                            {
                                name: 'Options',
                                field: 'options',
                                editable: false,
                                type: 'text',

                            },
                        ]}
                        rowValues={organizationRequests.map((o) => ({
                            ...o,
                            created_at: moment(o.created_at).format(
                                'MMM DD, YYYY'
                            ),
                        }))}
                        deleteAction={rejectOrganization}
                        saveAction={approveOrganization}
                        activePage={0}
                        goToEditPage={(id) =>
                            (window.location.href = '/admin/organizations/' + id)
                        }
                        loading={loading}
                    />
                </TableWrapper>
            ) : (
                <DataTable
                    title={false}
                    persistTableHead={true}
                    fixedHeader={true}
                    noDataComponent={<p className="text-center p-3">No record found</p>}
                    columns={columns}
                    data={organizations}
                    progressPending={loading}
                    progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                        <Spinner className='spinner' animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </P>}
                    onSort={handleSort}
                    sortServer
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalOrganization}
                    paginationPerPage={perPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    responsive={true}
                    onChangePage={page => setActivePage(page)}
                /> 
            )}
          </>
          )
      }
      {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to delete?"
                    onConfirm={()=>(deleteOrganization(),setShowRemoveConfirmation(false))}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this job!
                </SweetAlert>
            }
            <Modal
                onHide={onHide}
                show={showAgreementUpload}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                className='modal-entry-form'
                >
                <Modal.Header closeButton className='mt-2 mb-2'>
                    <Modal.Title id="contained-modal-title-vcenter" >
                    Upload Sign Agreement
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AgreementUpload orgObj={organizationObject} onHide={onHide} method={'post'} fetchData={fetchData}></AgreementUpload>
                </Modal.Body>
            </Modal>
        </Card>
    )
}

export default OrganizationManagement