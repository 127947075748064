import React, { useEffect, useState } from 'react'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import Util from "../../../utils/util";
import Card from './shared/Card'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const InMailsAnalyticsReport = ({currentUser,recruiters,per_page}) => {
  var active_page = window.sessionStorage.getItem('activePage')
  var query = window.sessionStorage.getItem('query')
  const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(per_page)
  const [tableDatas, setTableDatas] = useState([])
  const [totalDatas,setTotalDatas] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [exportDatas, setExportDatas] = useState([])
  const csvExportRef = React.useRef();
  const [dateRange, setDateRange] = useState({startDate: moment().startOf('week').toDate() ,endDate: moment().toDate()})
  const [selectedRecruiter,setSelectedRecruiter] = useState('')
  const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'},...recruiters])
  const [defaultselected,setdefaultselected] = useState({value: 'all', label: 'All'})
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('entry_date')
  const [sortDirection, setSortDirection] = useState('desc')
  const [footer, setFooter] = useState({})
  const tableHeaders = [
    { label: 'Recruiter Name', key: 'recruiter_name' },
    { label: 'Organization', key: 'organization_name' },
    { label: 'Date', key: 'entry_date' },
    { label: 'Expected', key: 'daily_target' },
    { label: 'Sent', key: 'sent' },
    { label: 'Responses', key: 'response_text' },
    { label: 'response_file', key: 'response_file'},
    { label: 'Note', key: 'note' },
    { label: 'attachment_file', key: 'attachment_file'}
  ]
  const fetchData = () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = `/admin/in_mails.json`
    window.sessionStorage.setItem('activePage',activePage)
    makeRequest(`${url}?start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}&sortField=${sortField}&sortDirection=${sortDirection}`, 'get',  {params: {page: (activePage), query: query}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {

        setTotalDatas(res.data.total_count)
        let sr_no = (activePage - 1) * res.data.per_page
        setTableDatas(
          res.data.inMails.map((inMails) => (sr_no = sr_no + 1,{
            id: sr_no,
            organization_id: inMails.organization_id,
            recruiter_name: Util.fullName(inMails?.user?.first_name,inMails?.user?.last_name),
            organization_name: Util.capitalize(inMails?.organization?.name),
            entry_date: inMails.entry_date ? moment(inMails?.entry_date).format('MMM DD, YYYY') : " ",
            sent: inMails?.sent,
            response_text: inMails?.response_text,
            response_file: (inMails?.response_attachment?.blob) ? <a target='_blank' href={inMails?.response_attachment?.blob?.service_url}>{inMails?.response_attachment?.blob?.filename}</a> : '',
            reason: inMails?.reason,
            note: inMails?.note,
            attachment_file: (inMails?.attachment_attachment?.blob) ? <a target='_blank' href={inMails?.attachment_attachment?.blob?.service_url}>{inMails?.attachment_attachment?.blob?.filename}</a> : '',
            daily_target: inMails?.daily_target,
          }))
        )
        setFooter({
          id:'Total',
          daily_target:res?.data?.total_values?.daily_target,
          response_text:res?.data?.total_values?.response_text,
          sent:res?.data?.total_values?.sent,
        })
        setPageCount(res.data.total_pages)
        setCurrentCounts(res.data.current_counts)
        setStartRecord((activePage - 1) * res.data.per_page)
        setLoading(false)
      },
    })
  }
  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activePage,dateRange,selectedRecruiter,sortDirection,sortField])

  const handleDate = (date) => {
    setDateRange(date)
  }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = `/admin/in_mails.json`
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.inMails.map((data,key)=> ({
      recruiter_name: Util.fullName(data?.user?.first_name,data?.user?.last_name),
      organization_name: Util.capitalize(data?.organization?.name),
      entry_date: moment(data?.entry_date).format('MMM DD, YYYY'),
      daily_target: data?.daily_target,
      sent: data?.sent,
      response_text: data?.response_text,
      reason: data?.reason,
      note: data?.note,
      response_file: (data?.response_attachment?.blob) ? (data?.response_attachment?.blob?.service_url) : '',
      attachment_file: (data?.attachment_attachment?.blob) ? (data?.attachment_attachment?.blob?.service_url) : '', 
    })))
    csvExportRef?.current?.link.click();
  }

  const columns = [
    {
      name: 'No.',
      selector: row => row.id,
      maxWidth: '4%',
      minWidth: '4%'
    },
    {
      name: 'Recruiter Name',
      selector: row => row.recruiter_name,
      cell: (row) => <div title={row.recruiter_name}>{row.recruiter_name}</div>,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true,
      sortable: true,
      sortField: 'users.first_name'
    },
    {
      name: 'Organization',
      selector: row => row.organization_name,
      cell: (row) => <div title={row.organization_name}>{row.organization_name}</div>,
      minWidth: '9%',
      maxWidth: '9%',
      wrap:true,
      sortable: true,
      sortField: 'organizations.name'
    },
    {
      name: 'Date',
      selector: row => row.entry_date,
      cell: (row) => <div title={row.entry_date}>{row.entry_date}</div>,
      maxWidth: '7%',
      minWidth: '7%',
      wrap:true,
      sortable: true,
      sortField: 'entry_date'
    },
    {
      name: 'Expected',
      selector: row => row.daily_target,
      cell: (row) => <div title={row.daily_target}>{row.daily_target}</div>,
      maxWidth: '7%',
      minWidth: '7%',
      wrap:true,
      sortable: true,
      sortField: 'daily_target'
    },
    {
      name: 'Sent',
      selector: row => row.sent,
      cell: (row) => <div title={row.sent}>{row.sent}</div>,
      maxWidth: '7%',
      minWidth: '7%',
      sortable: true,
      sortField: 'sent'
    },
    {
      name: 'Responses',
      selector: row => row.response_text,
      cell: (row) => <div title={row.response_text}>{row.response_text}</div>,
      maxWidth: '7%',
      minWidth: '7%',
      wrap:true
    },
    {
      name: 'Response File',
      selector: row => row.response_file,
      cell: (row) => <div title={row.response_file}>{row.response_file}</div>,
      maxWidth: '9%',
      minWidth: '9%',
      wrap:true
    },
    {
      name: 'Note',
      selector: row => row.note,
      cell: (row) => <div title={row.note}>{row.note}</div>,
      maxWidth: '30%',
      minWidth: '30%',
      wrap:true
    },
    {
      name: 'Attachment',
      selector: row => row.attachment_file,
      cell: (row) => <div title={row.attachment_file}>{row.attachment_file}</div>,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true
    }
  ]

  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }

  return (
    <>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
          InMails Analytics
        </P>
        <div>
        <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
        </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"InMailsAnalytics.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalDatas > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalDatas}</DisplayPagination>
             }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
          <div style={{display: 'grid',marginLeft:'10px'}}>
              <label>
                  Select Recruiter
              </label>
              <Select
                  defaultValue={defaultselected}
                  options={recruiter}
                  onChange={(event)=>setSelectedRecruiter(event.value)}
                  name="recruiter"
                  className={styles.colourStyles}
              />
          </div>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Select Date Range
            </label>
            <DateRangePicker
                handleOnSubmit={handleDate}
                defaultDate={dateRange}
            />
          </div>
        </Col>
      </Row>
      <DataTable
          persistTableHead={true}
          noDataComponent={<p className="text-center p-3">No record found</p>}
          columns={columns}
          data={tableDatas}
          progressPending={loading}
          progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
              <Spinner className='spinner' animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>
          </P>}
          onSort={handleSort}
          sortServer
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalDatas}
          paginationPerPage={per_page}
          paginationComponentOptions={{
              noRowsPerPage: true
          }}
          responsive={true}
          onChangePage={page => setActivePage(page)}
          footer={footer}
      />
    </Card>
    </>
  )
}

export default InMailsAnalyticsReport